<template>
  <div>
    <v-data-table
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="internalValue"
      item-key="id"
      sort-by="id"
      class="elevation-1 mb-2"
      show-select
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h3
          class="primary--text"
        >
          No {{ $attrs.displayAsPlural }} Have Been Setup To Replace
        </h3>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ $attrs.displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="internalValue.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ $attrs.displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.description="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.description }}
        </div>
      </template>

      <template v-slot:item.newValue_description="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          {{ item.newValue_description }}
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          color="primary"
          @click="openDeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <v-card>
        <v-card-title>
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <h2
                  class="primary--text"
                >
                  {{ formTitle }}
                </h2>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text>
          <global-replace-parts
            v-if="takeoffs.selectedDataType.value.toLowerCase() === takeoffs.dataType.part.value.toLowerCase()"
            ref="GlobalReplaceParts"
            v-model="editedItem"
            :headerValue2Replace="headerValue2Replace"
            :headerNewValue="headerNewValue"
            :displayAsSingular="$attrs.displayAsSingular"
            @change="field_onChange"
          />

          <global-replace-delivery-loads
            v-if="takeoffs.selectedDataType.value.toLowerCase() === takeoffs.dataType.delivery_load.value.toLowerCase()"
            ref="GlobalReplaceDeliveryLoads"
            v-model="editedItem"
            :headerValue2Replace="headerValue2Replace"
            :headerNewValue="headerNewValue"
            :displayAsSingular="$attrs.displayAsSingular"
            @change="field_onChange"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            @click="cancelEdit"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableEdit"
            @click="saveEdit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="$attrs.displayAsSingular"
      :display-as-plural="$attrs.displayAsPlural"
      :use-customer-detail="false"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="$attrs.displayAsSingular"
      :display-as-plural="$attrs.displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import GlobalReplaceParts from '@/components/maintenance/forms/GlobalReplaceParts'
  import GlobalReplaceDeliveryLoads from '@/components/maintenance/forms/GlobalReplaceDeliveryLoads'

  export default {
    name: 'GlobalReplaceTable',

    components: {
      DeleteDialog,
      ContextMenu,
      GlobalReplaceParts,
      GlobalReplaceDeliveryLoads,
    },

    props: {
      value: {
        type: Array,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        maintenanceState: {
          data: this.value,
          selected: [],
        },
        headerValue2Replace:
          {
            text: 'Value To Replace',
            align: 'start',
            value: 'description',
            sortable: true,
            filterable: true,
            width: '49%',
            class: 'primary--text font-weight-bold text-no-wrap',
          },
        headerNewValue:
          {
            text: 'New Value',
            align: 'start',
            value: 'newValue_description',
            sortable: true,
            filterable: true,
            width: '49%',
            class: 'primary--text font-weight-bold text-no-wrap',
          },
        headerActions:
          {
            text: 'Actions',
            align: 'end',
            value: 'actions',
            sortable: false,
            filterable: false,
            width: '2%',
            class: 'primary--text font-weight-bold',
          },
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getIndexById',
      ]),
      takeoffs: get('takeoff/takeoffs'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.$attrs.displayAsSingular}` : `Edit ${this.$attrs.displayAsSingular}`
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        const disabled = false

        return disabled
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.maintenanceState.data = this.internalValue
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.headers = []
      this.headers.push(this.headerValue2Replace)
      this.headers.push(this.headerNewValue)
      this.headers.push(this.headerActions)
    },

    methods: {
      setEditedEmpty () {
        this.editedIndex = -1

        let newValue = {}

        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            if (this.$refs.GlobalReplaceDeliveryLoads) {
              newValue = this.$refs.GlobalReplaceDeliveryLoads.getNewValue()
            }

            break
          default:
            if (this.$refs.GlobalReplaceParts) {
              newValue = this.$refs.GlobalReplaceParts.getNewValue()
            }

            break
        }

        const emptyEdit = {
          id: null,
          description: null,
          newValue_description: null,
          value2Replace: null,
          newValue: newValue,
        }

        this.editedItem = { ...emptyEdit }
        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      addItem () {
        this.disableEdit = true
        this.setEditedEmpty()
      },

      forceRerender () {
        this.renderKey += 1

        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            if (this.$refs.GlobalReplaceDeliveryLoads) {
              this.$refs.GlobalReplaceDeliveryLoads.forceRerender()
            }

            break
          default:
            if (this.$refs.GlobalReplaceParts) {
              this.$refs.GlobalReplaceParts.forceRerender()
            }

            break
        }
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.getIndexById(this.internalValue, dataItem.id)
        this.editedItem = { ...dataItem }
        this.disableEdit = this.getDisableEdit()
        this.dialogEdit = true
      },

      field_onChange () {
        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      getDisableEdit () {
        let disabled = true

        if (this.editedItem.value2Replace) {
          if ({}.hasOwnProperty.call(this.editedItem.value2Replace, 'id')) {
            disabled = this.editedItem.value2Replace.id.length <= 0
          }
        }

        switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
          case this.takeoffs.dataType.delivery_load.value.toLowerCase():
            if (this.$refs.GlobalReplaceDeliveryLoads) {
              disabled = this.$refs.GlobalReplaceDeliveryLoads.getDisableEdit()
            }

            break
          default:
            if (this.$refs.GlobalReplaceParts) {
              disabled = this.$refs.GlobalReplaceParts.getDisableEdit()
            }

            break
        }

        return disabled
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      deleteItem (dataItem) {
        if (dataItem) {
          this.internalValue.splice(this.internalValue.indexOf(dataItem), 1)
        }
      },

      item_onDelete (dataItem) {
        if (this.maintenanceState.selected.length > 0) {
          for (const item2Delete of this.maintenanceState.selected) {
            this.deleteItem(item2Delete)
          }
        } else {
          this.deleteItem(dataItem)
        }

        this.maintenanceState.selected = []
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.setEditedEmpty()
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.dialogEdit = false

          switch (this.takeoffs.selectedDataType.value.toLowerCase()) {
            case this.takeoffs.dataType.delivery_load.value.toLowerCase():
              if (this.$refs.GlobalReplaceDeliveryLoads) {
                this.$refs.GlobalReplaceDeliveryLoads.saveEdit()
              }

              break
            default:
              if (this.$refs.GlobalReplaceParts) {
                this.$refs.GlobalReplaceParts.saveEdit()
              }

              break
          }

          if (this.editedIndex < 0) {
            this.internalValue.push({ ...this.editedItem })
          } else {
            this.internalValue.splice(this.editedIndex, 1, { ...this.editedItem })
          }
        }
      },
    },
  }
</script>

<style scoped>

</style>
