<template>
  <div>
    <v-data-table
      ref="maintenanceTableArchivedPlans"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.plans"
      item-key="id"
      :item-class="getRowClass"
      sort-by="id"
      class="elevation-2"
      show-select
      group-by="folder_description"
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="maintenanceState.isBusy"
      :search="search"
      :footer-props="{'items-per-page-options':[5,10,15,30,-1]}"
      :options="options"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No Archived {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching archived {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading Archived {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.plans.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableRestore"
                @click="menuRestore_onClick"
              >
                Restore Selected
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:group.header="{items, isOpen, toggle}">
        <th
          colspan="100%"
          @click="toggle"
        >
          <v-icon
            color="secondary"
          >
            {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>

          {{ getFolderDescription (items[0]) }}
        </th>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length+1"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeaders"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - Archived {{ displayAsSingular }} Details
                    </h2>
                  </template>

                  <template v-slot:item.heated_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ item.heated_sqft.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.address>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address1 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address2 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.city }}, {{ item.state }} {{ item.zip }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <!-- TODO: Add Context menu to Restore and Permanently Delete -->
  </div>
</template>

<style lang="scss" scoped>
.rowClass {
  text-align: left;
}
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PlansArchivedTable',

    data () {
      return {
        search: null,
        options: {
          itemsPerPage: -1,
        },
        headers: [],
        detailHeaders: [],
      }
    },

    computed: {
      ...mapGetters('folder', [
        'getFolderDescription',
      ]),
      appHeaders: get('app/headers'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      maintenanceState: get('archive/archives'),
      plans: get('plan/plans'),
      disableRestore () {
        return (this.maintenanceState.selected.length <= 0)
      },
    },

    created () {
      this.initialize()
    },

    mounted () {
      this.collapseGroups()
    },

    methods: {
      setupHeaders () {
        const headerId = { ...this.appHeaders.headerId }

        headerId.width = '10%'
        this.plans.headers.headerDescription.width = '40%'
        this.plans.headers.headerEstimator.width = '24%'
        this.plans.headers.headerSalesperson.width = '25%'

        this.headers = []

        this.headers.push(headerId)
        this.headers.push(this.plans.headers.headerDescription)
        this.headers.push(this.plans.headers.headerEstimator)
        this.headers.push(this.plans.headers.headerSalesperson)
        this.headers.push(this.plans.headers.headerFolderId)
        this.headers.push(this.plans.headers.headerFolderDescription)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeaders () {
        this.plans.headers.headerSqFt.width = '20%'
        this.plans.headers.headerAddress.width = '25%'
        this.plans.headers.headerNote.width = '55%'

        this.detailHeaders = []

        this.detailHeaders.push(this.plans.headers.headerSqFt)
        this.detailHeaders.push(this.plans.headers.headerAddress)
        this.detailHeaders.push(this.plans.headers.headerNote)
      },

      retrieveAllPlans () {
        this.$store.dispatch('archive/retrieveAllPlans')
      },

      initialize () {
        this.retrieveAllPlans()

        this.setupHeaders()

        this.setupDetailHeaders()
      },

      collapseGroups () {
        const table = this.$refs.maintenanceTableArchivedPlans
        const keys = Object.keys(table.$vnode.componentInstance.openCache)
        keys.forEach(dataGroup => {
          if (dataGroup) {
            table.$vnode.componentInstance.openCache[dataGroup] = false
          }
        })
      },

      getRowClass () {
        return 'rowClass'
      },

      menuRestore_onClick () {
        this.$store.dispatch('archive/restorePlans')
      },
    },
  }
</script>
