<template>
  <v-container>
    <v-row
      no-gutters
    >
      <v-col
        cols="12"
        sm="2"
        class="primary--text text-body-1 font-weight-bold"
      >
        {{ label }}:
      </v-col>

      <v-col
        cols="12"
        sm="10"
        class="font-weight-medium text-body-1 pl-2 text-left"
      >
        <div
          v-for="file in docList"
          :key="file.key"
          class="pb-1"
        >
          <v-icon
            small
            color="secondary"
            class="d-inline-block pr-2"
            @click="removeDoc_onClick(file)"
          >
            mdi-delete
          </v-icon>

          <a
            :href="file.url"
            color="secondary"
            class="d-inline-block"
          >
            {{ file.fileName }}
          </a>
        </div>

        <confirmation-dialog
          ref="removeDocConfirmationDialog"
          @confirm="confirm_onClick"
        >
          <template #title>
            <h2 class="primary--text">
              Confirm Remove Document
            </h2>
          </template>

          <template #message>
            <div>
              Are you sure you want to remove the
              <span class="primary--text">{{ ( removeFile ? removeFile.fileName : '' ) }}</span>
              document?
            </div>
          </template>
        </confirmation-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { Storage } from 'aws-amplify'
  import ConfirmationDialog from '@/components/maintenance/ConfirmationDialog'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ListDocuments',

    components: {
      ConfirmationDialog,
    },

    props: {
      value: {
        type: Object,
        default: null,
        required: true,
      },
      label: {
        type: String,
        default: null,
        required: true,
      },
    },

    data () {
      return {
        internalValue: this.value,
        docList: [],
        removeFile: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDocumentsPath',
      ]),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.id === oldVal.id) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.id === oldVal.id) return

          this.internalValue = val
        },
        deep: true,
      },
    },

    created () {
      this.listPlanDocuments(this.internalValue)
    },

    methods: {
      async listPlanDocuments (dataItem) {
        const docPath = this.getDocumentsPath(dataItem)

        await Storage.list(docPath)
          .then(async (result) => {
            this.docList = []

            if (result) {
              this.internalValue.documents_count = result.length

              let doc2Push = { key: null, url: null, fileName: null }

              for (const currentFile of result) {
                Storage.get(currentFile.key)
                  .then(fileUrl => {
                    doc2Push = {
                      key: currentFile.key,
                      url: fileUrl,
                      fileName: currentFile.key.toString().replace(`${docPath}/`, ''),
                    }

                    this.docList.push(doc2Push)
                  })
                  .catch(err => {
                    this.$store.dispatch('error/setError', { name: 'Getting Document', details: err })
                  })
              }
            }
          })
          .catch(err => {
            this.$store.dispatch('error/setError', { name: 'Getting Document List', details: err })
            this.docList = []
          })
      },

      removeDoc_onClick (thisFile) {
        this.removeFile = thisFile

        this.$refs.removeDocConfirmationDialog.setDisplayDialog(true)
      },

      async confirm_onClick () {
        const fileName = this.removeFile.fileName

        await Storage.remove(this.removeFile.key)
          .then(result => {
            const showToast = {
              title: 'Document Removed',
              messages: [`Document '${fileName}' successfully removed.`],
              variant: 'success',
            }
            this.$store.dispatch('user/showToast', showToast)
            this.listPlanDocuments(this.internalValue)
          })
          .catch(err => {
            this.$store.dispatch('error/setError', { name: 'Removing Document', details: err })
          })
      },
    },
  }
</script>

<style scoped>

</style>
