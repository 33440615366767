<template>
  <v-menu
    v-model="menuFilter"
    transition="slide-y-transition"
    :close-on-content-click="false"
    class="d-print-none"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        v-on="on"
        color="secondary"
      >
        mdi-filter-variant
      </v-icon>
    </template>

    <v-list
      dense
      flat
      class="ma-0 pa-0"
    >
      <v-subheader><h2>Filter {{ header.text }}</h2></v-subheader>

      <v-list-item
        ripple
        @click="toggleSelectAll"
      >
        <v-list-item-action>
          <v-icon :color="internalValue.length > 0 ? 'secondary' : ''">
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Select All
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mt-1" />

      <v-list-item-group
        v-model="internalValue"
        multiple
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="i"
          dense
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox
                :input-value="active"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                class="ma-0 pa-0"
              >
                <span
                  :style="`${getStatusColor(i, $vuetify.theme.dark)}`"
                >
                  {{ item }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'StatusFilter',

    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
      header: {
        type: Object,
        default: null,
        required: true,
      },
      items: {
        type: Object,
        default: () => ({}),
        required: true,
      },
    },

    data () {
      return {
        internalValue: this.value,
        menuFilter: false,
      }
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    computed: {
      selectedAll () {
        let itemLength = 0

        for (const key in this.items) {
          itemLength++
        }

        return this.internalValue.length === itemLength
      },
      selectedSome () {
        return this.internalValue.length > 0 && !this.selectedAll
      },
      selectAllIcon () {
        if (this.selectedAll) return 'mdi-checkbox-marked'
        if (this.selectedSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },

    methods: {
      // Toggle is fired whenever an item(s) is selected. It updates the internalValue array with the items that are checked.
      toggleSelectAll () {
        this.$nextTick(() => {
          if (this.selectedAll) {
            this.internalValue = []
          } else {
            const selectedItems = []

            for (const key in this.items) {
              selectedItems.push(key)
            }

            this.internalValue = [...selectedItems]
          }
        })
      },
    },
  }
</script>

<style scoped>

</style>
