// Pathify
import { make } from 'vuex-pathify'
import { importChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://1f7t4o0eal.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Part',
  displayAsPlural: 'Parts',
  parts: {
    all: [],
    data: [],
    originalData: [],
    toImport: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    empty: {
      id: null,
      part_id: null,
      client_id: null,
      vendor_id: null,
      com_part_id: '',
      description: null,
      part_id_description: null,
      mfg_part_id: '',
      note: '',
      price_level_1: 0,
      price_level_2: 0,
      price_level_3: 0,
      price_level_4: 0,
      price_level_5: 0,
      service_type_id: '',
      special_order: false,
      unit: '',
      unit_cost: 0,
      weight: 0,
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerSpecialOrder: {
        text: 'Special Order',
        align: 'center',
        value: 'special_order',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerUnit: {
        text: 'Unit',
        align: 'start',
        value: 'unit',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerCost: {
        text: 'Cost',
        align: 'end',
        value: 'unit_cost',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerPriceLevel1: {
        text: 'Price Level 1',
        align: 'end',
        value: 'price_level_1',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerPriceLevel2: {
        text: 'Price Level 2',
        align: 'end',
        value: 'price_level_2',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerPriceLevel3: {
        text: 'Price Level 3',
        align: 'end',
        value: 'price_level_3',
        sortable: false,
        filterable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
    },
    tabbedColumns: {
      id_header: 'Part ID/SKU',
      description_header: 'Part Description',
      special_order_header: 'Special Order',
      unit_header: 'Unit',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.parts.isBusy = isBusy
  },

  push2All: (state, dataFromDB) => {
    state.parts.all = []

    for (const currentData of dataFromDB) {
      state.parts.all.push({ ...currentData })
    }

    state.parts.isBusy = false
  },

  push2Data: (state, payload) => {
    state.parts.data = []
    state.parts.originalData = []

    // Get a copy of the original data, not a pointer to it
    for (const currentData of state.parts.all) {
      if (currentData.vendor_id === payload.vendor_id) {
        currentData.special_order = (!!currentData.special_order)

        state.parts.data.push({ ...currentData })
        state.parts.originalData.push({ ...currentData })
      }
    }

    state.parts.selected = []

    state.parts.isBusy = false
  },

  push2Import: (state, data2Import) => {
    state.parts.toImport = []
    state.parts.toImport = data2Import
    state.parts.isBusy = false
  },

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: ({ commit }) => {
    state.parts.selected = []
    state.parts.selectedItem = { id: '', description: '' }
    state.parts.data = []

    commit(setIsBusy, false)
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllPart'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2All', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieve: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'Retrieve Part'
    commit(setIsBusy, true)

    try {
      if (payload && {}.hasOwnProperty.call(payload, 'vendor_id')) {
        commit('push2Data', payload)
      }
    } catch (error) {
      console.error(`${thisAction} failed`)
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: error }, { root: true })
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updatePart'
    commit(setIsBusy, true)

    payload.part_id = payload.id

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.parts.selectedItem && {}.hasOwnProperty.call(state.parts.selectedItem, 'id')) {
            if (payload.part_id === state.parts.selectedItem.id) {
              dispatch('setSelected', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.parts.selected.length > 0) {
      const thisAction = 'deletePart'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.parts.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.parts.selected.length > 1) {
        toastPrefix = `${state.parts.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.parts.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.parts.selectedItem && {}.hasOwnProperty.call(state.parts.selectedItem, 'id')) {
              for (const currentData of state.parts.selected) {
                if (currentData.part_id === state.parts.selectedItem.id) {
                  dispatch('setSelected', state.parts.data[0])
                  break
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: async ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updatePart'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.parts.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 300)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.id = currentItem.id
        item2Import.part_id = currentItem.id
        item2Import.vendor_id = currentItem.vendor_id
        item2Import.description = currentItem.description
        if ({}.hasOwnProperty.call(currentItem, 'special_order')) {
          item2Import.special_order = (!!currentItem.special_order)
        }
        item2Import.unit = currentItem.unit

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')
            dispatch('retrieveAll')

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed: items ${toastMessage}, variant: ${toastColor}`, responses)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = { id: null, part_id: null, vendor_id: null, description: null, special_order: null, unit: null }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = currentItem[state.parts.tabbedColumns.id_header]
          item2Import.part_id = currentItem[state.parts.tabbedColumns.id_header]
          item2Import.vendor_id = currentItem[rootState.category.categories.tabbedColumns.id_header]
          item2Import.description = currentItem[state.parts.tabbedColumns.description_header]
          item2Import.special_order = currentItem[state.parts.tabbedColumns.special_order_header]
          item2Import.unit = currentItem[state.parts.tabbedColumns.unit_header]

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.parts.toImport)
  },
}

const getters = {
  /*
  filterOnVendorId (array2Filter, vendorId) {
    return array2Filter.filter(part => part.vendor_id.toString() === vendorId.toString())
  },
   */
  getVendorPart: () => (vendorId, partId) => {
    for (const currentData of state.parts.all) {
      if (currentData.vendor_id === vendorId && currentData.part_id === partId) {
        return currentData
      }
    }

    return null
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
