<template>
  <v-data-table
    dense
    disable-sort
    :headers="headers"
    hide-default-footer
    :items="usageReports.data"
    item-key="index"
    group-by="phaseDescription"
    :footer-props="{'items-per-page-options':[-1]}"
    :options="options"
  >
    <template v-slot:group.header="{items}">
      <th
        colspan="5"
      >
        {{ items[0].phaseDescription }}
      </th>
    </template>

    <template v-slot:item.order_quantity="{ item }">
      <div style="font-size: 1.2em;">{{ item.order_quantity.toLocaleString("en-US") }}</div>
    </template>

    <template v-slot:item.itemDescription="{ item }">
      <div>{{ getPartDescription(item) }}</div>
    </template>

    <template v-slot:item.unit="{ item }">
      <div>{{ item.unit }}</div>
      <div
        v-if="item.note.length > 0 && item.note.trim() !== noteDelimiter"
        class="mt-2 primary--text font-italic font-weight-medium text-wrap"
      >
        <div
          v-for="(currentNote, i) in getTallyAndNote(item)"
          :key="i"
        >
          <span
            v-if="i === 0"
          >
            {{ (currentNote.trim().length > 0 ? `( Tally: ${currentNote}; )` : '' ) }}
          </span>

          <span
            v-else
            v-for="(currentNote, line) in currentNote.split(noteDelimiter)"
            :key="line"
          >
            <div
              v-if="currentNote.trim().length > 0"
              style="white-space: pre; overflow:hidden;"
            >
              {{ currentNote }}<br />
            </div>
          </span>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'UsageReportTable',

    props: {
      value: {
        type: Array,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        options: {
          itemsPerPage: -1,
        },
        noteDelimiter: '<br />',
        headers: [
          {
            text: 'Part Number',
            align: 'start',
            value: 'part_id',
            width: '10%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Quantity',
            align: 'end',
            value: 'order_quantity',
            width: '10%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Item Description',
            align: 'start',
            value: 'itemDescription',
            width: '40%',
            class: 'font-weight-bold text-no-wrap',
          },
          {
            text: 'Unit of Measure',
            align: 'start',
            value: 'unit',
            width: '10%',
            class: 'font-weight-bold text-no-wrap',
            cellClass: 'text-wrap',
          },
          {
            text: 'Item Usage',
            align: 'start',
            value: 'usageDescription',
            width: '30%',
            class: 'font-weight-bold text-no-wrap',
          },
        ],
      }
    },

    computed: {
      ...mapGetters('part', [
        'getVendorPart',
      ]),
      ...mapGetters('exportPOS', [
        'getTallyAndNote',
      ]),
      usageReports: get('usageReport/usageReports'),
    },

    watch: {
      internalValue: {
        handler () {
          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
      },
    },

    methods: {
      getPartDescription (item) {
        let selectedDescription = item.itemDescription
        const thisPart = this.getVendorPart(item.vendor_id, item.part_id)

        if (thisPart && {}.hasOwnProperty.call(thisPart, 'special_order') && {}.hasOwnProperty.call(thisPart, 'description')) {
          if (!thisPart.special_order) {
            selectedDescription = thisPart.description
          }
        }

        return selectedDescription
      },
    },
  }
</script>

<style scoped>

</style>
