<template>
  <div>
    <v-data-table
      ref="maintenanceTableUserSettings"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="email"
      class="elevation-2"
      show-select
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
            <!-- TODO: Add button to kick all users -->
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template #item.email="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <email-address
            ref="editedEmail"
            v-model="editedItem"
            :label="maintenanceState.headers.headerUserEmail.text"
            :originalData="maintenanceState.originalData"
            :required="true"
            autofocus
            @change="field_onChange"
          />
        </div>
        <span v-else>{{ item.email }}</span>
      </template>

      <template #item.default_customer="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <v-combobox
            id="default-customer-id"
            ref="editedDefaultCustomerId"
            v-model="editedDefaultCustomer"
            :items="customersData"
            item-text="description"
            item-value="id"
            dense
            clearable
            class="ml-1 mt-0 mb-0"
            :loading="customers.isBusy"
            :rules="[appRules.required, appRules.validOption(editedDefaultCustomer, customersData, `${displayAsSingularCustomer} does not exist in the list.`)]"
            @change="field_onChange"
          >
            <template v-slot:label>
              <v-icon
                class="required-icon"
                x-small
              >
                mdi-asterisk
              </v-icon>
              {{ maintenanceState.headers.headerDefaultCustomer.text }}
            </template>
          </v-combobox>
        </div>
        <span v-else>{{ getCustomerName(item.default_customer) }}</span>
      </template>

      <template #item.customers="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <multi-select
            id="allowed-customers"
            ref="allowedCustomers"
            v-model="editedItem.customers"
            :items="customers.data"
            item-text="description"
            item-value="id"
            :label="maintenanceState.headers.headerCustomers.text"
            :return-object="false"
            :show-select-all="false"
            @change="field_onChange"
          />
        </div>
        <span v-else>
          <div
            v-if="getLengthOf(item.customers) > 0"
          >
            <v-chip
              v-for="(currentItem, index) in item.customers"
              :key="index"
              class="mr-1"
              color="secondary"
              small
              outlined
              label
            >
              {{ displaySelectedCustomer(currentItem) }}
            </v-chip>
          </div>
          <div v-else>All</div>
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.id === editedItem.id">
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                small
                color="accent"
                class="mr-2"
                :disabled="disableEdit"
                v-on="on"
                @click="saveEdit(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <span>Save</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                small
                color="secondary"
                v-on="on"
                @click="cancelEdit(item)"
              >
                mdi-window-close
              </v-icon>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-icon
            small
            color="primary"
            class="mr-2"
            @click="menuEdit_onClick(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            small
            color="primary"
            @click="openDeleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="getLengthOf(headers)+1"
          @contextmenu:row="openContextMenu"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeaders"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ displayAsPlural }} - <span class="secondary--text">{{ item.email }}</span> Details
                    </h2>
                  </template>

                  <template #item.primary_color>
                    <span
                      :style="`color: ${item.primary_color}`"
                    >
                      {{ item.primary_color }}
                    </span>
                  </template>

                  <template #item.secondary_color>
                    <span
                      :style="`color: ${item.secondary_color}`"
                    >
                      {{ item.secondary_color }}
                    </span>
                  </template>

                  <template #item.accent_color>
                    <span
                      :style="`color: ${item.accent_color}`"
                    >
                      {{ item.accent_color }}
                    </span>
                  </template>

                  <template #item.dark>
                    <div
                      class="mt-3 pa-0"
                    >
                      <v-switch
                        v-model="item.dark"
                        class="ma-0 pa-0"
                        color="secondary"
                        dense
                        disabled
                        inset
                      />
                    </div>
                  </template>

                  <template #item.show_image>
                    <div
                      class="mt-3 pa-0"
                    >
                      <v-switch
                        v-model="item.show_image"
                        class="ma-0 pa-0"
                        color="secondary"
                        dense
                        disabled
                        inset
                      />
                    </div>
                  </template>

                  <!-- TODO: Add user groups from
                  <template v-slot:footer>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        class="ma-0 pa-0"
                      >
                        Groups:
                      </v-col>
                    </v-row>
                  </template>
                  -->
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :use-customer-detail="false"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { DataStore } from '@aws-amplify/datastore'
  import { UserSettings } from '@/models'
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import EmailAddress from '@/components/maintenance/controls/EmailAddress'
  import MultiSelect from '@/components/maintenance/controls/MultiSelect'

  export default {
    name: 'UserSettingsTable',

    components: {
      DeleteDialog,
      ContextMenu,
      EmailAddress,
      MultiSelect,
    },

    data () {
      return {
        isNewItem: false,
        editedIndex: -1,
        editedItem: {},
        editedDefaultCustomer: { id: '', description: '' },
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
        detailHeaders: [],
        subUserSettings: undefined,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingular: get('userSettings/displayAsSingular'),
      displayAsPlural: get('userSettings/displayAsPlural'),
      maintenanceState: get('userSettings/userSettings'),
      displayAsSingularCustomer: get('customer/displayAsSingular'),
      customers: get('customer/customers'),
      disableNew () {
        return false
      },
      selectedDefaultCustomer () {
        let selectedItem = null
        const selectedItems = this.getDataById(this.customersData, this.editedItem.default_customer)

        if (this.editedIndex >= 0 && this.getLengthOf(selectedItems) > 0) {
          selectedItem = selectedItems[0]
        }

        return selectedItem
      },
      customersData () {
        const payload = []
        const disabledUser = { ...this.customers.empty }

        disabledUser.id = '0000'
        disabledUser.client_id = disabledUser.id
        disabledUser.description = '0000-DISABLED'
        payload.push(disabledUser)

        for (const currentData of this.customers.data) {
          payload.push(currentData)
        }

        return payload
      },
    },

    watch: {
      editedIndex: {
        handler (val, oldVal) {
          if (!this.isNewItem && val >= 0) {
            this.editedDefaultCustomer = { ...this.selectedDefaultCustomer }
          } else {
            this.editedDefaultCustomer = null
          }
        },
      },
      editedDefaultCustomer: {
        handler (val, oldVal) {
          if (val) {
            if ({}.hasOwnProperty.call(val, 'client_id')) {
              this.editedItem.default_customer = val.client_id
            }
          }
        },
      },
    },

    created () {
      this.initialize()

      this.subUserSettings = DataStore.observe(UserSettings).subscribe(obj => {
        this.$store.dispatch('userSettings/retrieve')
      })
    },

    destroyed () {
      if (this.subUserSettings) this.subUserSettings.unsubscribe()
    },

    methods: {
      setupHeaders () {
        this.maintenanceState.headers.headerUserEmail.width = '30%'
        this.maintenanceState.headers.headerDefaultCustomer.width = '28%'
        this.maintenanceState.headers.headerCustomers.width = '40%'

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerUserEmail)
        this.headers.push(this.maintenanceState.headers.headerDefaultCustomer)
        this.headers.push(this.maintenanceState.headers.headerCustomers)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeaders () {
        const headerPrimaryColor = { ...this.maintenanceState.headers.headerPrimaryColor }
        const headerSecondaryColor = { ...this.maintenanceState.headers.headerSecondaryColor }
        const headerAccentColor = { ...this.maintenanceState.headers.headerAccentColor }
        const headerImage = { ...this.maintenanceState.headers.headerImage }
        const headerGradient = { ...this.maintenanceState.headers.headerGradient }
        const headerDarkMode = { ...this.maintenanceState.headers.headerDarkMode }
        const headerShowImage = { ...this.maintenanceState.headers.headerShowImage }

        headerPrimaryColor.width = '14%'
        headerPrimaryColor.sortable = false
        headerSecondaryColor.width = '14%'
        headerSecondaryColor.sortable = false
        headerAccentColor.width = '14%'
        headerAccentColor.sortable = false
        headerImage.width = '14%'
        headerImage.sortable = false
        headerGradient.width = '14%'
        headerGradient.sortable = false
        headerDarkMode.width = '15%'
        headerDarkMode.sortable = false
        headerShowImage.width = '15%'
        headerShowImage.sortable = false

        this.detailHeaders = []

        this.detailHeaders.push(headerPrimaryColor)
        this.detailHeaders.push(headerSecondaryColor)
        this.detailHeaders.push(headerAccentColor)
        this.detailHeaders.push(headerImage)
        this.detailHeaders.push(headerGradient)
        this.detailHeaders.push(headerDarkMode)
        this.detailHeaders.push(headerShowImage)
      },

      initialize () {
        this.$store.dispatch('userSettings/retrieve')

        this.setupHeaders()
        this.setupDetailHeaders()
      },

      forceRerender () {
        this.renderKey += 1
      },

      getDisableEdit () {
        let disabled = (this.editedItem.updated !== null ? !this.editedItem.updated : true)

        if (!disabled && this.$refs.editedEmail) {
          disabled = this.$refs.editedEmail.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDefaultCustomerId) {
          if ({}.hasOwnProperty.call(this.$refs.editedDefaultCustomerId, 'errorBucket')) {
            disabled = !this.editedDefaultCustomer || this.getLengthOf(this.$refs.editedDefaultCustomerId.errorBucket) > 0
          }
        }

        return disabled
      },

      displaySelectedCustomer (currentItem) {
        let displayItem = this.getCustomerName(currentItem)

        if (displayItem) {
          if (displayItem.trim().length <= 0) {
            displayItem = currentItem
          }
        } else {
          displayItem = currentItem
        }

        return displayItem
      },

      field_onChange () {
        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)

        this.$nextTick(() => {
          this.disableEdit = this.getDisableEdit()
        })
      },

      openDeleteDialog (dataItem) {
        dataItem.description = dataItem.email
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('userSettings/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        selectedRow.item.description = selectedRow.item.email

        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.isNewItem = true
        this.disableEdit = true
        this.search = ''

        const addObj = Object.assign({}, this.maintenanceState.empty)

        addObj.id = 0
        this.maintenanceState.data.unshift(addObj)

        this.menuEdit_onClick(addObj)
      },

      menuEdit_onClick (dataItem) {
        this.disableEdit = true
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.editedItem.description = this.editedItem.email
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        // If user did not complete or wants to cancel a new item, remove that incomplete new item
        if (this.isNewItem) {
          this.maintenanceState.data.splice(this.editedIndex, 1)
        }

        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
          this.isNewItem = false
        })
      },

      saveEdit () {
        if (this.editedItem.id === 0) {
          this.$store.dispatch('userSettings/create', { ...this.editedItem })
        } else {
          this.$store.dispatch('userSettings/update', { ...this.editedItem })
        }

        this.$nextTick(() => {
          this.cancelEdit()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
