<template>
  <div>
    <v-text-field
      ref="textField"
      v-model="internalValue.variable"
      :autofocus="$attrs.autofocus"
      :disabled="$attrs.disabled"
      :required="$attrs.required"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      :maxlength="($attrs.maxlength ? $attrs.maxlength : 15)"
      @blur="onBlur(internalValue)"
      @change="onChange(internalValue)"
      @click="input_onClick"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Variable',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.variable === oldVal.variable) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.variable === oldVal.variable) return

          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.variable !== originalData[0].variable) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      onChange (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      prepRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.noSpaces)

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
</style>
