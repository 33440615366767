<template>
  <v-menu
    v-model="menuFilter"
    transition="slide-y-transition"
    :close-on-content-click="false"
    class="non-printable"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        v-on="on"
        color="secondary"
      >
        mdi-filter-variant
      </v-icon>
    </template>

    <v-list
      dense
      flat
      class="ma-0 pa-0"
    >
      <v-subheader><h2>Filter {{ header.text }} Dates...</h2></v-subheader>
      <v-list-item dense>
        <v-date-picker
          v-model="internalValue"
          color="primary"
          elevation="5"
          no-title
          range
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: 'DateFilter',

    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
      header: {
        type: Object,
        default: null,
        required: true,
      },
    },

    data () {
      return {
        internalValue: this.value,
        menuFilter: false,
      }
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          // Re-order dates by earliest to latest, start to end (in case selected in wrong order by user)
          if (val) {
            if (val.length > 1) {
              const d0 = new Date(val[0])
              const d1 = new Date(val[1])

              if (d0 > d1) {
                this.internalValue = [val[1], val[0]]
              }
            }
          }

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },
  }
</script>

<style scoped>

</style>
