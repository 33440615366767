<template>
  <div>
    <v-data-table
      v-model="plans.selected"
      :headers="headers"
      :items="planData"
      item-key="client_plan_id"
      sort-by="client_plan_id"
      :show-select="users.isAdmin"
      :loading="plans.isBusy"
      :footer-props="{'items-per-page-options':[-1]}"
      :options="options"
      dense
      hide-default-footer
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsSingular }} Data Available
        </h2>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsSingular }} Summary... Please wait
        </h2>
      </template>

      <!-- Header slots -->
      <template v-slot:header.estimator_name="{ header }">
        {{ header.text }}
        <estimator-filter
          v-if="!users.isEstimator"
          v-model="estimators.filtered_ids"
          :header="header"
        />
      </template>

      <template v-slot:header.client_id="{ header }">
        {{ header.text }}
        <customer-filter
          v-model="customers.filtered_ids"
          :items="customers.data"
          :header="header"
        />
      </template>

      <template v-slot:header.finish_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_completed_dates"
          :header="header"
        />
      </template>

      <template v-slot:header.status_cd="{ header }">
        {{ header.text }}
        <status-filter
          v-model="plans.filter_report_status_codes"
          :items="plans.DashboardStatusCode"
          :header="header"
        />
      </template>

      <template v-slot:header.invoice_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_invoiced_dates"
          :header="header"
        />
      </template>

      <!-- Item slots -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.client_id="{ item }">
        {{ getCustomerName(item.client_id) }}
      </template>

      <template v-slot:item.finish_date="{ item }">
        {{ displayDate(item.finish_date) }}
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          class="d-inline font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? plans.StatusCode[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.invoice_date="{ item }">
        {{ displayDate(item.invoice_date) }}
      </template>

      <template v-slot:item.paid_date="{ item }">
        {{ displayDate(item.paid_date) }}
      </template>

      <template v-slot:item.total_rate="{ item }">
        ${{ item.total_rate }}
      </template>

      <template v-slot:item.fixed_pricing="{ item }">
        ${{ item.fixed_pricing }}
      </template>

      <!-- Totals Row -->
      <template v-slot:body.append>
        <tr>
          <th v-if="users.isAdmin"></th>
          <th
            v-for="(thisHeader, i) in headers"
            :key="i"
            :class="getFooterClass(thisHeader)"
          >
            {{ getFooterText(thisHeader) }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <!-- Menus -->
    <context-menu
      ref="reportContextMenu"
      :maintenance-items="plans"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :show-archive-option="false"
      :show-new-option="false"
      :show-edit-option="false"
      :show-paid-date-option="true"
      :show-delete-option="false"
      @payitem="menuPay_onClick"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import EstimatorFilter from '@/components/maintenance/filters/EstimatorFilter'
  import CustomerFilter from '@/components/maintenance/filters/CustomerFilter'
  import DateFilter from '@/components/maintenance/filters/DateFilter'
  import StatusFilter from '@/components/maintenance/filters/StatusFilter'
  import ContextMenu from '@/components/maintenance/ContextMenu'

  export default {
    name: 'ReportTableEstimatorSummary',

    components: {
      EstimatorFilter,
      CustomerFilter,
      DateFilter,
      StatusFilter,
      ContextMenu,
    },

    data () {
      return {
        display_sqft_sum: '',
        display_price_sum: '',
        sqft_sum: 0,
        price_sum: 0,
        filtered_completed_dates: [],
        filtered_invoiced_dates: [],
        options: {
          itemsPerPage: -1,
        },
        headers: [
          {
            text: 'Estimator',
            align: 'start',
            value: 'estimator_name',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '8%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Customer',
            align: 'start',
            value: 'client_id',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '8%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Plan',
            align: 'start',
            value: 'description',
            sortable: false,
            filterable: false,
            groupable: false,
            width: '12%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Completed',
            align: 'start',
            value: 'finish_date',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '12%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'SQFT',
            align: 'end',
            value: 'heated_sqft',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '8%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Rate',
            align: 'end',
            value: 'total_rate',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '8%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Price',
            align: 'end',
            value: 'fixed_pricing',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '10%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Status',
            align: 'start',
            value: 'status_cd',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '10%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Invoiced',
            align: 'start',
            value: 'invoice_date',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '12%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
          {
            text: 'Paid',
            align: 'start',
            value: 'paid_date',
            sortable: true,
            filterable: false,
            groupable: false,
            width: '12%',
            class: 'primary--text font-weight-bold',
            cellClass: 'font-weight-medium text-body-1',
          },
        ],
      }
    },

    computed: {
      ...mapGetters('app', [
        'formatDateForTimezone',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      displayAsSingular: get('estimator/displayAsSingular'),
      displayAsPlural: get('estimator/displayAsPlural'),
      users: get('user/users'),
      estimators: get('estimator/estimators'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      planData () {
        const payload = {
          plans: this.plans.all,
          client_ids: this.customers.filtered_ids,
          estimator_ids: this.estimators.filtered_ids,
          status_codes: this.plans.filter_report_status_codes,
          completed_dates: this.filtered_completed_dates,
          invoiced_dates: this.filtered_invoiced_dates,
        }

        this.$store.dispatch('plan/retrieveEstimatorsSummary', payload)

        return this.plans.estimatorsSummary
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/push2EstimatorsSummary':
            this.setSums()
            break
          case 'plan/successfulUpdate':
            this.initialize()
            break
        }
      })
    },

    mounted () {
      // Clear filtered status codes so that the user can start fresh by selecting the status(es) they want
      this.plans.filter_report_status_codes = []
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      initialize () {
        this.$store.dispatch('plan/retrieveAll')
      },

      setSums () {
        this.sqft_sum = 0
        this.price_sum = 0

        for (const currentItem of this.plans.estimatorsSummary) {
          if ({}.hasOwnProperty.call(currentItem, 'heated_sqft')) {
            if (currentItem.heated_sqft) {
              this.sqft_sum += currentItem.heated_sqft
            }
          }

          if ({}.hasOwnProperty.call(currentItem, 'fixed_pricing')) {
            if (currentItem.fixed_pricing) {
              this.price_sum += parseFloat(currentItem.fixed_pricing)
            }
          }
        }

        this.display_sqft_sum = parseInt(this.sqft_sum, 10).toLocaleString('en-US')
        this.display_price_sum = '$' + parseFloat(this.price_sum).toLocaleString('en-US', { minimumFractionDigits: 2 })
      },

      getSums () {
        return {
          display_sqft_sum: this.display_sqft_sum,
          display_price_sum: this.display_price_sum,
        }
      },

      displayDate (thisDate) {
        return this.formatDateForTimezone(thisDate)
      },

      getFooterClass (thisHeader) {
        let thisClass = null

        if (thisHeader) {
          switch (thisHeader.value) {
            case 'heated_sqft':
            case 'fixed_pricing':
              thisClass = 'text-right'
              break
            default:
              thisClass = null
          }
        }

        return thisClass
      },

      getFooterText (thisHeader) {
        let thisText = null

        if (thisHeader) {
          switch (thisHeader.value) {
            case 'description':
              thisText = `# of Plans: ${this.getLengthOf(this.planData)}`
              break
            case 'heated_sqft':
              thisText = this.display_sqft_sum
              break
            case 'fixed_pricing':
              thisText = this.display_price_sum
              break
            default:
              thisText = null
          }
        }

        return thisText
      },

      openContextMenu (evt, selectedRow) {
        if (this.users.isAdmin) {
          this.$refs.reportContextMenu.openContextMenu(evt, selectedRow.item)
        }
      },

      menuPay_onClick () {
        const payload = {
          paid_date: new Date(Date.now()),
          selected: this.plans.selected,
          update_program: '',
          update_user_id: '',
        }

        this.$store.dispatch('user/setStateAsUpdated', payload)
        this.$store.dispatch('plan/updatePaidDate', payload)
      },
    },
  }
</script>

<style scoped>

</style>
