// Pathify
import { make } from 'vuex-pathify'
import { importChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://w2ltbokcg9.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Usage',
  displayAsPlural: 'Usages',
  usages: {
    data: [],
    originalData: [],
    toImport: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    empty: {
      id: null,
      usage_id: null,
      client_id: null,
      description: null,
      note: '',
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
    tabbedColumns: {
      id_header: 'Usage ID',
      description_header: 'Usage Description',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.usages.isBusy = isBusy
  },

  setSelected: (state, payload) => {
    state.usages.selected = []
    state.usages.selectedItem = { ...payload }
  },

  push2Data: (state, dataFromDB) => {
    state.usages.data = []
    state.usages.data = dataFromDB

    // Get a copy of the original data, not a pointer to it
    state.usages.originalData = []
    for (const currentData of dataFromDB) {
      state.usages.originalData.push({ ...currentData })
    }

    state.usages.selected = []

    state.usages.isBusy = false
  },

  push2Import: (state, data2Import) => {
    state.usages.toImport = []
    state.usages.toImport = data2Import
    state.usages.isBusy = false
  },

  successfulUpdate: (state) => {},

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setSelected: async ({ commit, dispatch }, payload) => {
    let selectedItem = { id: null, description: '' }

    if (payload) {
      selectedItem = payload
    }

    commit('setSelected', selectedItem)
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllUsage'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          commit('push2Data', jsonResponse.data)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updateUsage'
    commit(setIsBusy, true)

    payload.usage_id = payload.id

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.usages.selectedItem && {}.hasOwnProperty.call(state.usages.selectedItem, 'id')) {
            if (payload.usage_id === state.usages.selectedItem.id) {
              dispatch('setSelected', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        commit('successfulUpdate')
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.usages.selected.length > 0) {
      const thisAction = 'deleteUsage'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${state.usages.selected[0].client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.usages.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.usages.selected.length > 1) {
        toastPrefix = `${state.usages.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.usages.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.usages.selectedItem && {}.hasOwnProperty.call(state.usages.selectedItem, 'id')) {
              for (const currentData of state.usages.selected) {
                if (currentData.usage_id === state.usages.selectedItem.id) {
                  dispatch('setSelected', state.usages.data[0])
                  break
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updateUsage'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.usages.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 100)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.id = currentItem.id
        item2Import.usage_id = currentItem.id
        item2Import.description = currentItem.description

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')
            dispatch('retrieveAll')

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed with url: ${url}`)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = { id: null, usage_id: null, description: null, note: '' }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = currentItem[state.usages.tabbedColumns.id_header]
          item2Import.usage_id = currentItem[state.usages.tabbedColumns.id_header]
          item2Import.description = currentItem[state.usages.tabbedColumns.description_header]

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.usages.toImport)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
