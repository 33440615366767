// Pathify
import { make } from 'vuex-pathify'
import XLSX from 'xlsx'

const _ = require('lodash')

const setIsBusy = 'setIsBusy'

const CATEGORY_HEADING = 'Product Category Listing'
const DELIVERY_LOAD_HEADING = 'Delivery Load Listing'
const USAGE_HEADING = 'Usage Summary'
const PLAN_HEADING = 'Plan Listing - Summary'
const CATEGORY_SUBHEADING = 'Category: '
const PROMPT_GROUP_SUBHEADING = 'Prompt Group: '
const PLAN_SUBHEADING = 'Plan: '
const DIVISION_SUBHEADING = 'Phase: '
const ASSEMBLY_SUBHEADING = 'Assembly: '
const FORMULA_SUBHEADING = 'Formula:'
const DESCRIPTION_DATA_HEADING = 'Description'
const PROMPT_GROUP_DATA_HEADING = 'Prompt Group'
const PROMPT_DESCRIPTION_DATA_HEADING = 'Prompt'
const CATEGORY_DATA_HEADING = 'Category'
const PART_DATA_HEADING = 'Part/SKU'
const UNIT_DATA_HEADING = 'Unit'
const DELIVERY_LOAD_DATA_HEADING = 'Load'
const USAGE_DATA_HEADING = 'Usage'
const WASTE_DATA_HEADING = 'Waste'
const QUANTITY_DATA_HEADING = 'Qty'

const state = {
  displayAsSingular: 'Import',
  displayAsPlural: 'Imports',
  imports: {
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    selectedImportType: { id: '', description: '', id_heading: '' },
    sheetNames: [],
    importTypes: [
      { id: 'Tabbed-Spreadsheet-Template', description: 'Tabbed Spreadsheet Template', id_heading: 'id', table_name: 'multiple' },
      { id: 'Legacy-Categories', description: 'Legacy Report: Categories', id_heading: 'Product Category', table_name: 'categories' },
      { id: 'Legacy-Parts', description: 'Legacy Report: Parts', id_heading: 'Part/SKU', table_name: 'part' },
      { id: 'Legacy-Delivery-Loads', description: 'Legacy Report: Delivery Loads', id_heading: 'Delivery Load Id', table_name: 'load' },
      { id: 'Legacy-Usages', description: 'Legacy Report: Usages', id_heading: 'Usage', table_name: 'usage' },
      { id: 'Legacy-Prompt-Groups', description: 'Legacy Report: Prompt Groups', id_heading: 'Prompt Group Id', table_name: 'prompt_variable' },
      { id: 'Legacy-Prompts', description: 'Legacy Report: Prompts', id_heading: 'Variable', table_name: 'prompt' },
      { id: 'Legacy-Plans', description: 'Legacy Report: Plans', id_heading: 'Plan Id', table_name: 'plan' },
      { id: 'Legacy-Divisions', description: 'Legacy Report: Divisions', id_heading: 'Phase Id', table_name: 'phase' },
      { id: 'Legacy-Assemblies', description: 'Legacy Report: Assemblies', id_heading: 'Assembly Id', table_name: 'assembly' },
      { id: 'Legacy-Assembly-Items', description: 'Legacy Report: Assembly Items', id_heading: 'Category', table_name: 'assemblyitem' },
    ],
    empty: {
      id: null,
      client_id: null,
      description: null,
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerCategoryId: {
        text: 'Category',
        align: 'start',
        value: 'vendor_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerPlanId: {
        text: 'Plan',
        align: 'start',
        value: 'plan_id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerDivisionId: {
        text: 'Division',
        align: 'start',
        value: 'phase_id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerAssemblyId: {
        text: 'Assembly',
        align: 'start',
        value: 'assembly_id',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerPartId: {
        text: 'Part/SKU',
        align: 'start',
        value: 'part_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerPromptGroup: {
        text: 'Prompt Group',
        align: 'start',
        value: 'pv_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerUnit: {
        text: 'Unit',
        align: 'start',
        value: 'unit',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerDeliveryLoadId: {
        text: 'Delivery Load',
        align: 'start',
        value: 'delivery_load_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerUsageId: {
        text: 'Usage',
        align: 'start',
        value: 'usage_id',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerWaste: {
        text: 'Waste',
        align: 'end',
        value: 'waste_factor',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerQuantity: {
        text: 'Quantity',
        align: 'end',
        value: 'order_quantity',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
      headerFormula: {
        text: 'Formula',
        align: 'start',
        value: 'formula',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
  },
}

function initializeImportItem (rootState, previousDataItem) {
  const emptyDataItem = {
    client_id: rootState.customer.customers.selectedItem.client_id,
    update_program: rootState.user.users.update_program,
    update_user_id: rootState.user.users.user.attributes.email,
  }
  const newDataItem = { ...emptyDataItem }

  if ({}.hasOwnProperty.call(previousDataItem, 'category_description')) {
    newDataItem.vendor_id = previousDataItem.vendor_id.trim()
    newDataItem.category_description = previousDataItem.category_description.trim()
  }

  if ({}.hasOwnProperty.call(previousDataItem, 'prompt_group_description')) {
    newDataItem.pv_id = previousDataItem.pv_id.trim()
    newDataItem.prompt_group_description = previousDataItem.prompt_group_description.trim()
  }

  if ({}.hasOwnProperty.call(previousDataItem, 'plan_description')) {
    newDataItem.plan_id = previousDataItem.plan_id.trim()
    newDataItem.plan_description = previousDataItem.plan_description.trim()
  }

  if ({}.hasOwnProperty.call(previousDataItem, 'phase_description')) {
    newDataItem.phase_id = previousDataItem.phase_id
    newDataItem.phase_description = previousDataItem.phase_description
  }

  if ({}.hasOwnProperty.call(previousDataItem, 'assembly_description')) {
    newDataItem.assembly_id = previousDataItem.assembly_id.trim()
    newDataItem.assembly_description = previousDataItem.assembly_description.trim()
  }

  return newDataItem
}

function getRowItemFor (headingText, colData, splitOn) {
  const dataItem = { id: '', description: '', isHeader: false }
  let stringWork
  let stringArray

  if (colData.startsWith(headingText)) {
    dataItem.id = ''
    dataItem.description = ''

    stringWork = colData.replace(headingText, '').replace(/ +(?= )/g, '')
    stringArray = stringWork.split(splitOn)
    if (stringArray.length > 0) {
      dataItem.id = stringArray[0].trim()
    }

    if (stringArray.length > 1) {
      dataItem.description = stringArray.join(' ').replace(dataItem.id, '').trim()
    }

    dataItem.isHeader = true
  }

  return dataItem
}

function getTableId (dataItem) {
  let tableId = ''

  if ({}.hasOwnProperty.call(dataItem, 'plan_description')) {
    tableId += dataItem.plan_id.trim() + '_'
  }

  if ({}.hasOwnProperty.call(dataItem, 'phase_description')) {
    tableId += dataItem.phase_id.trim() + '_'
  }

  if ({}.hasOwnProperty.call(dataItem, 'assembly_description')) {
    tableId += dataItem.assembly_id.trim() + '_'
  }

  if ({}.hasOwnProperty.call(dataItem, 'prompt_group_description')) {
    tableId += dataItem.pv_id.trim() + '_'
  }

  if ({}.hasOwnProperty.call(dataItem, 'category_description')) {
    tableId += dataItem.vendor_id.trim() + '_'
  }

  tableId += dataItem.id.trim()

  return tableId
}

function getIdSplitOn (splitOn, colData) {
  let id2Get = ''
  const stringWork = colData
  let stringArray

  if (stringWork !== undefined && stringWork.length > 0) {
    id2Get = stringWork

    stringArray = stringWork.split(splitOn)

    if (stringArray.length > 0) {
      id2Get = stringArray[0].trim()
    }
  }

  return id2Get.trim()
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.imports.isBusy = isBusy
  },

  setEmpty: (state) => {
    state.imports.selected = []
    state.imports.selectedItem = { id: '', description: '' }
    state.imports.data = []
    state.imports.sheetNames = []
  },

  setRows: (state, payload) => {
    for (const thisSheet of state.imports.sheetNames) {
      if (thisSheet.sheetName === payload.sheetName) {
        thisSheet.sheetRows = payload.sheetRows
        break
      }
    }
  },

  push2Sheets: (state, data2Push) => {
    state.imports.sheetNames.push(data2Push)
  },

  push2Data: (state, data2Push) => {
    if (data2Push && {}.hasOwnProperty.call(data2Push, 'id')) {
      if (data2Push.id !== undefined && data2Push.id.length > 0) {
        data2Push.table_id = getTableId(data2Push)

        // Don't push duplicate table_id keys
        const dataExists = state.imports.data.filter(data => data.table_id.trim() === data2Push.table_id.trim())
        if (dataExists.length <= 0) {
          state.imports.data.push(data2Push)
        }
      }
    }
  },

  successfulSetup: (state) => {},

  successfulImport: (state) => {},

  removeSelected: (state) => {
    const newData = state.imports.data.filter(function (currentItem) {
      return state.imports.selected.indexOf(currentItem) < 0
    })

    state.imports.data = newData
  },

  setSelectedIsComplete: (state, sheetName) => {
    const filteredImports = state.imports.selected.filter(data => data.sheetName === sheetName)

    if (filteredImports) {
      if (filteredImports.length > 0) {
        const completedImport = filteredImports[0]

        if ({}.hasOwnProperty.call(completedImport, 'isComplete')) {
          completedImport.isComplete = true
        }
      }
    }
  },

  setImportCount: (state, payload) => {
    const filteredImports = state.imports.selected.filter(data => data.sheetName === payload.sheetName)

    if (filteredImports) {
      if (filteredImports.length > 0) {
        const completedImport = filteredImports[0]

        if ({}.hasOwnProperty.call(completedImport, 'importCount')) {
          completedImport.importCount = payload.importCount
        }
      }
    }
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setIsBusy: ({ commit }, isBusy) => {
    commit(setIsBusy, isBusy)
  },

  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  setRows: ({ commit }, payload) => {
    commit('setRows', payload)
  },

  importCompleted: ({ commit, dispatch }) => {
    if (state.imports.data.length > 0) {
      dispatch('user/showToast',
        {
          title: `${state.displayAsSingular} Successful`,
          messages: [`${state.imports.data.length} rows imported successfully`],
          variant: 'success',
        },
        { root: true },
      )
    } else {
      let messageTimeout = 10000
      let importCount = 0
      let importMessage
      const successMessages = []
      const warningMessages = []

      for (const currentItem of state.imports.selected) {
        importCount = currentItem.sheetRows

        if ({}.hasOwnProperty.call(currentItem, 'importCount')) {
          if (currentItem.importCount > 0) {
            importCount = currentItem.importCount
          }
        }

        importMessage = `${importCount} of ${currentItem.sheetRows} `
        importMessage += (currentItem.sheetRows === 1 ? 'row' : 'rows')
        importMessage += ` imported successfully for ${currentItem.sheetName}`

        if (importCount.toString() === currentItem.sheetRows.toString()) {
          successMessages.push(importMessage)
        } else {
          warningMessages.push(importMessage)
        }

        messageTimeout += 2000
      }

      let importMessages = []
      if (warningMessages.length > 0) {
        importMessages = successMessages.concat([' ', 'Warnings...'], warningMessages)
      } else {
        importMessages = successMessages
      }

      if (importMessages.length > 0) {
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Successful${(warningMessages.length > 0 ? ' with warnings' : '')}`,
            messages: importMessages,
            variant: (warningMessages.length > 0 ? 'warning' : 'success'),
            timeout: messageTimeout,
          },
          { root: true },
        )
      }
    }

    state.imports.selectedImportType = { id: '', description: '', id_heading: '' }

    commit('setEmpty')
    commit('successfulImport')
    commit(setIsBusy, false)
  },

  buildFromLegacyData: async ({ commit, dispatch, rootState }, fromData) => {
    let dataKeys
    let headingData
    let rowData
    let colData
    let keyId
    let keyDesc
    let keyCategory
    let keyPart
    let keyPromptGroup
    let keyUnit
    let keyDeliveryLoad
    let keyUsage
    let keyWaste
    let keyQuantity
    let keyFormula
    let temporaryId = 0
    let rowItem = {}
    let look4Data = false
    let dataItem = initializeImportItem(rootState, {})
    let formulaItem

    // Loop through rows
    for (let i = 0; i < fromData.sheetRows; i++) {
      rowData = fromData.sheetData[i]

      if (rowData !== undefined) {
        // Reset dataKeys for this row
        dataKeys = Object.keys(rowData)
        headingData = rowData[Object.keys(rowData)[0]]

        rowItem = getRowItemFor(CATEGORY_HEADING, headingData, CATEGORY_HEADING)
        if (rowItem.isHeader) {
          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(DELIVERY_LOAD_HEADING, headingData, DELIVERY_LOAD_HEADING)
        if (rowItem.isHeader) {
          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(USAGE_HEADING, headingData, USAGE_HEADING)
        if (rowItem.isHeader) {
          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(PLAN_HEADING, headingData, PLAN_HEADING)
        if (rowItem.isHeader) {
          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(CATEGORY_SUBHEADING, headingData, ' - ')
        if (rowItem.isHeader) {
          dataItem.vendor_id = rowItem.id
          dataItem.category_description = rowItem.description

          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(PROMPT_GROUP_SUBHEADING, headingData, ' - ')
        if (rowItem.isHeader) {
          if (state.imports.selectedImportType.id === 'Legacy-Prompt-Groups') {
            dataItem.id = rowItem.id
            dataItem.description = rowItem.description

            await commit('push2Data', { ...dataItem })

            dataItem = initializeImportItem(rootState, dataItem)
          } else {
            dataItem.pv_id = rowItem.id
            dataItem.prompt_group_description = rowItem.description
          }

          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(PLAN_SUBHEADING, headingData, ' ')
        if (rowItem.isHeader) {
          dataItem.plan_id = rowItem.id
          dataItem.plan_description = rowItem.description

          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(DIVISION_SUBHEADING, headingData, ' ')
        if (rowItem.isHeader) {
          dataItem.phase_id = rowItem.id
          dataItem.phase_description = rowItem.description

          keyId = null
          keyDesc = null
          look4Data = true
        }

        rowItem = getRowItemFor(ASSEMBLY_SUBHEADING, headingData, ' ')
        if (rowItem.isHeader) {
          dataItem.assembly_id = rowItem.id
          dataItem.assembly_description = rowItem.description

          keyId = null
          keyDesc = null
          look4Data = true
        }

        if (look4Data && keyId && keyDesc) {
          rowItem = getRowItemFor(FORMULA_SUBHEADING, headingData, ' ')
          if (rowItem.isHeader && state.imports.selectedImportType.id === 'Legacy-Assembly-Items') {
            keyFormula = Object.keys(rowData)[1]
            formulaItem = state.imports.data.filter(data => data.id.toString() === temporaryId.toString())
            if (formulaItem && formulaItem.length > 0) {
              formulaItem[0].formula = rowData[keyFormula].replace(/ +(?= )/g, '').trim()
            }
          }

          if (rowData[keyId] !== undefined && rowData[keyDesc] !== undefined) {
            temporaryId++

            if (state.imports.selectedImportType.id === 'Legacy-Assembly-Items') {
              dataItem.id = temporaryId.toString()

              if (keyCategory) {
                dataItem.vendor_id = rowData[keyCategory].trim()
              }

              if (keyPart) {
                dataItem.part_id = rowData[keyPart].trim()
              }

              if (keyDeliveryLoad) {
                dataItem.delivery_load_id = rowData[keyDeliveryLoad].trim()
              }

              if (keyUsage) {
                dataItem.usage_id = rowData[keyUsage].trim()
              }

              if (keyWaste) {
                dataItem.waste_factor = rowData[keyWaste]
              }

              if (keyQuantity) {
                dataItem.order_quantity = rowData[keyQuantity]
              }

              dataItem.formula = ''
            } else {
              dataItem.id = rowData[keyId].trim()
            }

            dataItem.description = rowData[keyDesc].trim()

            if (keyPromptGroup) {
              dataItem.pv_id = getIdSplitOn(' - ', rowData[keyPromptGroup])
            }

            if (keyUnit) {
              dataItem.unit = rowData[keyUnit].trim()
            }

            await commit('push2Data', { ...dataItem })

            dataItem = initializeImportItem(rootState, dataItem)
          }
        } else {
          // Loop through columns to find data keys
          for (const colKey of dataKeys) {
            colData = rowData[colKey]

            if (look4Data) {
              // If we're looking for data and we find id & description headings, we know the next rows will be data
              if (colData.startsWith(state.imports.selectedImportType.id_heading)) {
                keyId = colKey
              }
              if (colData.startsWith(DESCRIPTION_DATA_HEADING)) {
                keyDesc = colKey
              }
              if (keyId && colData.startsWith(CATEGORY_DATA_HEADING)) {
                keyCategory = colKey
              }
              if (keyId && colData.startsWith(PART_DATA_HEADING)) {
                keyPart = colKey
              }
              if (keyId && colData.startsWith(PROMPT_GROUP_DATA_HEADING)) {
                keyPromptGroup = colKey
              }
              if (state.imports.selectedImportType.id === 'Legacy-Prompts' && keyId && colData.startsWith(PROMPT_DESCRIPTION_DATA_HEADING)) {
                keyDesc = colKey
              }
              if (keyId && colData.startsWith(UNIT_DATA_HEADING)) {
                keyUnit = colKey
              }
              if (keyId && colData.startsWith(DELIVERY_LOAD_DATA_HEADING)) {
                keyDeliveryLoad = colKey
              }
              if (keyId && colData.startsWith(USAGE_DATA_HEADING)) {
                keyUsage = colKey
              }
              if (keyId && colData.startsWith(WASTE_DATA_HEADING)) {
                keyWaste = colKey
              }
              if (keyId && colData.startsWith(QUANTITY_DATA_HEADING)) {
                keyQuantity = colKey
              }
            }
          }
        }
      }
    }
  },

  buildFromTabbedTemplate: async ({ commit, dispatch, rootState }, fromData) => {
    const thisAction = 'Build Data To Import'

    if (fromData && {}.hasOwnProperty.call(fromData, 'sheetName')) {
      switch (fromData.sheetName) {
        case rootState.category.displayAsPlural:
          dispatch('category/push2Import', fromData, { root: true })
          break
        case rootState.part.displayAsPlural:
          dispatch('part/push2Import', fromData, { root: true })
          break
        case rootState.deliveryload.displayAsPlural:
          dispatch('deliveryload/push2Import', fromData, { root: true })
          break
        case rootState.usage.displayAsPlural:
          dispatch('usage/push2Import', fromData, { root: true })
          break
        case rootState.promptgroup.displayAsPlural:
          dispatch('promptgroup/push2Import', fromData, { root: true })
          break
        case rootState.prompt.displayAsPlural:
          dispatch('prompt/push2Import', fromData, { root: true })
          break
        case rootState.plan.displayAsPlural:
          dispatch('plan/push2Import', fromData, { root: true })
          break
        case rootState.division.displayAsPlural:
          dispatch('division/push2Import', fromData, { root: true })
          break
        case rootState.assembly.displayAsPlural:
          dispatch('assembly/push2Import', fromData, { root: true })
          break
        case rootState.assemblyitem.displayAsPlural:
          dispatch('assemblyitem/push2Import', fromData, { root: true })
          break
        default:
          dispatch('error/setError', { name: thisAction, details: `${thisAction} failed with unhandled sheetName = "${fromData.sheetName}"` }, { root: true })
          break
      }
    } else {
      dispatch('error/setError', { name: thisAction, details: `${thisAction} failed with invalid fromData` }, { root: true })
    }
  },

  setup: async ({ commit, dispatch, getters, rootState }, workbook2Import) => {
    const thisAction = 'Setup Import Data'

    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      commit(setIsBusy, true)
      commit('setEmpty')

      try {
        // Get all of the sheet names for the workbook
        const sheetNames = workbook2Import.SheetNames

        let sheetRange
        let sheetRows
        let currentSheet
        let sheetData
        let validWorksheetFound = false

        for (const sheetName of sheetNames) {
          currentSheet = workbook2Import.Sheets[sheetName]

          // Get the number of rows for the current sheet
          sheetRange = XLSX.utils.decode_range(currentSheet['!ref'])
          sheetRows = sheetRange.e.r - sheetRange.s.r + 1

          // Only process the worksheet if it has more than 1 row. The first row is the header.
          if (sheetRows > 1) {
            validWorksheetFound = true

            commit('push2Sheets', { sheetName: sheetName, sheetRows: sheetRows, isComplete: false, importCount: 0 })

            if (state.imports.selectedImportType.id.includes('Legacy-')) {
              sheetData = await XLSX.utils.sheet_to_row_object_array(currentSheet)
              await dispatch('buildFromLegacyData', { sheetData: sheetData, sheetRows: sheetRows })
            } else {
              sheetData = await XLSX.utils.sheet_to_json(currentSheet, { defval: '' })
              await dispatch('buildFromTabbedTemplate', { sheetName: sheetName, sheetRows: sheetRows, sheetData: sheetData })
            }
          }
        }

        if (validWorksheetFound) {
          commit('successfulSetup')
        } else {
          const errorDetails = 'No valid worksheets found.'
          console.error(`${thisAction} failed with ${errorDetails}`)
          commit(setIsBusy, false)
          this.$store.dispatch('error/setError', { name: thisAction, details: errorDetails })
        }
      } catch (error) {
        console.error(`${thisAction} failed`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      }
    }

    commit(setIsBusy, false)
  },

  process: ({ commit, dispatch, rootState }) => {
    const thisAction = 'Process Import Data'

    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      commit(setIsBusy, true)

      try {
        let errorDetails

        switch (state.imports.selectedImportType.id) {
          case 'Legacy-Categories':
            dispatch('category/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Parts':
            dispatch('part/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Delivery-Loads':
            dispatch('deliveryload/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Usages':
            dispatch('usage/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Prompt-Groups':
            dispatch('promptgroup/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Prompts':
            dispatch('prompt/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Plans':
            dispatch('plan/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Divisions':
            dispatch('division/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Assemblies':
            dispatch('assembly/importAll', state.imports.data, { root: true })
            break
          case 'Legacy-Assembly-Items':
            dispatch('assemblyitem/importAll', state.imports.data, { root: true })
            break
          default:
            errorDetails = `Import Type ${state.imports.selectedImportType.description} not supported`
            console.error(`${thisAction} failed - ${errorDetails}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: errorDetails }, { root: true })
            break
        }
      } catch (error) {
        console.error(`${thisAction} failed`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      }
    }
  },

  removeSelected: ({ commit }) => {
    commit('removeSelected')
  },

  executeImport: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'Build Data To Import'

    if (payload && {}.hasOwnProperty.call(payload, 'sheetName')) {
      commit(setIsBusy, true)

      switch (payload.sheetName) {
        case rootState.category.displayAsPlural:
          dispatch('category/executeImport', null, { root: true })
          break
        case rootState.part.displayAsPlural:
          dispatch('part/executeImport', null, { root: true })
          break
        case rootState.deliveryload.displayAsPlural:
          dispatch('deliveryload/executeImport', null, { root: true })
          break
        case rootState.usage.displayAsPlural:
          dispatch('usage/executeImport', null, { root: true })
          break
        case rootState.promptgroup.displayAsPlural:
          dispatch('promptgroup/executeImport', null, { root: true })
          break
        case rootState.prompt.displayAsPlural:
          dispatch('prompt/executeImport', null, { root: true })
          break
        case rootState.plan.displayAsPlural:
          dispatch('plan/executeImport', null, { root: true })
          break
        case rootState.division.displayAsPlural:
          dispatch('division/executeImport', null, { root: true })
          break
        case rootState.assembly.displayAsPlural:
          dispatch('assembly/executeImport', null, { root: true })
          break
        case rootState.assemblyitem.displayAsPlural:
          dispatch('assemblyitem/executeImport', null, { root: true })
          break
        default:
          dispatch('error/setError', { name: thisAction, details: `${thisAction} failed with unhandled sheetName = "${payload.sheetName}"` }, { root: true })
          break
      }
    } else {
      dispatch('error/setError', { name: thisAction, details: `${thisAction} failed with invalid fromData` }, { root: true })
    }
  },

  setSelectedIsComplete: ({ commit }, sheetName) => {
    commit('setSelectedIsComplete', sheetName)
  },

  setImportCount: ({ commit }, payload) => {
    commit('setImportCount', payload)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
