<template>
  <div>
    <navigation-label
      :label="($attrs.label ? $attrs.label : displayAsSingular)"
      :displayAsSingular="displayAsSingular"
      :displayAsPlural="displayAsPlural"
      :tooltip="`${displayAsSingular} Maintenance`"
      :routeTo="{ name: 'Divisions' }"
    />

    <v-container
      class="pa-0 ma-0"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="11"
        >
          <v-combobox
            :id="`key-division-${$attrs.id}`"
            v-model="internalValue"
            :items="divisions.data"
            item-text="description"
            item-value="id"
            autofocus
            outlined
            dense
            clearable
            class="ml-1 mt-0 mb-0"
            :hint="`Select a ${displayAsSingular} ${$attrs.appendhint}`"
            :loading="divisions.isBusy"
            :rules="[appRules.validOption(internalValue, divisions.data, `${displayAsSingular} does not exist in the list.`)]"
            @change="onChange"
          />
        </v-col>

        <v-col
          cols="12"
          sm="1"
          :class="colClass"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disablePrevious"
                v-on="on"
                @click="previous_onClick"
              >
                <v-icon>mdi-skip-previous-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Previous {{ displayAsSingular }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disableNext"
                v-on="on"
                @click="next_onClick"
              >
                <v-icon>mdi-skip-next-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Next {{ displayAsSingular }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import NavigationLabel from '@/components/maintenance/controls/NavigationLabel'

  export default {
    name: 'SelectDivision',

    components: {
      NavigationLabel,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      appRules: get('app/rules'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      displayAsSingular: get('division/displayAsSingular'),
      displayAsPlural: get('division/displayAsPlural'),
      colClass () {
        let newClass = 'text-no-wrap d-inline'
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
            newClass = 'd-none'
            break
        }
        return newClass
      },
      disablePrevious () {
        let disabledButton = true

        if (this.divisions.data) {
          if (this.divisions.data.length > 0) {
            disabledButton = this.divisions.isFirst
          }
        }

        return disabledButton
      },
      disableNext () {
        let disabledButton = true

        if (this.divisions.data) {
          if (this.divisions.data.length > 0) {
            disabledButton = this.divisions.isLast
          }
        }

        return disabledButton
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.$emit('input', this.internalValue)
          }
        },
      },
      value: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.internalValue = val
          }
        },
      },
    },

    methods: {
      onChange (selectedItem) {
        this.$store.dispatch('division/setSelected', selectedItem)

        this.$emit('change', selectedItem)
      },

      previous_onClick () {
        this.$store.dispatch('division/previous')
      },

      next_onClick () {
        this.$store.dispatch('division/next')
      },
    },
  }
</script>

<style scoped>

</style>
