<template>
  <div>
    <v-combobox
      v-if="$attrs.combobox"
      ref="combobox"
      v-model="maintenanceState.selectedItem"
      :items="maintenanceState.data"
      item-text="description"
      item-value="id"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :loading="maintenanceState.isBusy"
      :rules="prepRules()"
      @change="onChange"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-combobox>
    <v-text-field
      v-else
      v-model="internalValue.delivery_load_id"
      :label="$attrs.label"
      clearable
      class="font-weight-medium text-body-1"
      @blur="onBlur(internalValue)"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DeliveryLoad',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      displayAsSingular: get('deliveryload/displayAsSingular'),
      maintenanceState: get('deliveryload/deliveryloads'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.delivery_load_id === oldVal.delivery_load_id) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.delivery_load_id === oldVal.delivery_load_id) return

          this.internalValue = val
          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        const selectedItem = this.getDataById(this.maintenanceState.data, this.internalValue.delivery_load_id)
        if (selectedItem && selectedItem.length > 0) {
          this.maintenanceState.selectedItem = selectedItem[0]
        } else {
          this.maintenanceState.selectedItem = null
        }
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.delivery_load_id !== originalData[0].delivery_load_id) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.emitChange(dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          this.internalValue.delivery_load_id = dataItem.id
          this.internalValue.delivery_load_description = dataItem.description
          this.prepareToUpdate(this.internalValue)
        } else {
          this.internalValue.delivery_load_id = ''
          this.emitChange(dataItem)
        }
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      prepRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validOption(this.maintenanceState.selectedItem, this.maintenanceState.data, `${this.displayAsSingular} does not exist in the list.`))

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
