// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ApplicationSupport, Notification, UserSettings, SystemMessages, Customer } = initSchema(schema);

export {
  ApplicationSupport,
  Notification,
  UserSettings,
  SystemMessages,
  Customer
};