<template>
  <div class="d-inline">
    <span
      v-if="users.isGuest && !users.isAdmin"
    >
      <div
        class="primary--text font-weight-medium text-body-5 ml-1"
      >
        {{ ($attrs.label ? $attrs.label : $attrs.displayAsSingular) }}
      </div>
    </span>
    <v-tooltip
      v-else
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="primary--text font-weight-medium text-body-5 mt-2 ml-1 navToLink"
          v-on="on"
          @click="routeToParent()"
        >
          {{ ($attrs.label ? $attrs.label : $attrs.displayAsSingular) }}
        </div>

        <div style="display: none;">
          <v-list-item
            :id="idNavTo"
            :to="$attrs.routeTo"
          />
        </div>
      </template>
      <span>{{ $attrs.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'NavigationLabel',

    computed: {
      idNavTo () {
        return `navTo${this.$attrs.displayAsPlural.toLowerCase().replaceAll(' ', '-')}`
      },
      users: get('user/users'),
    },

    methods: {
      routeToParent () {
        document.getElementById(this.idNavTo).click()
      },
    },
  }
</script>

<style scoped>

</style>
