<template>
  <v-menu
    v-model="displayMenu"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
  >
    <v-list
      dense
      rounded
    >
      <v-subheader>
        <h3
          class="primary--text"
        >
          {{ displayAsSingular }} Menu {{ showSelected }}
        </h3>
      </v-subheader>

      <v-list-item-group
        v-model="selectedMenu"
        color="primary"
      >
        <!-- Archive -->
        <v-list-item
          v-if="showArchiveOption"
          :disabled="maintenanceItems.selected.length > 0 ? false : true"
          @click="menuArchive_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-archive-arrow-down
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            Archive Selected
          </v-list-item-content>
        </v-list-item>
        <!-- New -->
        <v-list-item
          v-if="showNewOption"
          @click="menuNew_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-plus
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            New {{ displayAsSingular }}
          </v-list-item-content>
        </v-list-item>
        <!-- Edit -->
        <v-list-item
          v-if="showEditOption"
          :disabled="!hasValidEstimatorStatusCode(selectedItem)"
          @click="menuEdit_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-pencil
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <div
              v-if="selectedItem"
            >
              Edit {{ displayAsSingular }} - {{ selectedItem.description }}
            </div>
            <div v-else>
              Edit {{ displayAsSingular }}
            </div>
          </v-list-item-content>
        </v-list-item>
        <!-- Copy -->
        <v-list-item
          v-if="showCopyOption"
          :disabled="!hasValidEstimatorStatusCode(selectedItem)"
          @click="menuCopy_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-content-copy
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <div
              v-if="selectedItem"
            >
              Copy {{ displayAsPluralAssembly }} to {{ selectedItem.description }}
            </div>
            <div v-else>
              Copy { displayAsPluralAssembly }}
            </div>
          </v-list-item-content>
        </v-list-item>
        <!-- Invoiced -->
        <v-list-item
          v-if="showInvoicedOption"
          :disabled="maintenanceItems.selected.length > 0 ? false : true"
          @click="menuInvoice_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-file-check-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            Set Selected as Invoiced
          </v-list-item-content>
        </v-list-item>
        <!-- Paid Date -->
        <v-list-item
          v-if="showPaidDateOption"
          :disabled="maintenanceItems.selected.length > 0 ? false : true"
          @click="menuPaidDate_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-account-cash-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            Paid Selected
          </v-list-item-content>
        </v-list-item>
        <!-- Delete -->
        <v-list-item
          v-if="showDeleteOption"
          :disabled="maintenanceItems.selected.length > 0 ? false : true"
          @click="menuDelete_onClick()"
        >
          <v-list-item-icon>
            <v-icon
              small
              color="primary"
            >
              mdi-delete-sweep-outline
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            Delete Selected
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'

  export default {
    name: 'ContextMenu',

    props: {
      maintenanceItems: {
        type: Object,
        default: null,
      },
      displayAsSingular: {
        type: String,
        default: 'Item',
      },
      displayAsPlural: {
        type: String,
        default: 'Items',
      },
      showArchiveOption: {
        type: Boolean,
        default: false,
      },
      showNewOption: {
        type: Boolean,
        default: true,
      },
      showEditOption: {
        type: Boolean,
        default: true,
      },
      showCopyOption: {
        type: Boolean,
        default: false,
      },
      showInvoicedOption: {
        type: Boolean,
        default: false,
      },
      showPaidDateOption: {
        type: Boolean,
        default: false,
      },
      showDeleteOption: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        displayMenu: null,
        x: 0,
        y: 0,
        selectedMenu: null,
        selectedItem: null,
        selectedStatus: null,
      }
    },

    computed: {
      ...mapGetters('user', [
        'hasValidEstimatorStatusCode',
      ]),
      displayAsPluralAssembly: get('assembly/displayAsPlural'),
      showSelected () {
        let displayText = ''

        if (this.maintenanceItems) {
          if ({}.hasOwnProperty.call(this.maintenanceItems, 'selected')) {
            if (this.maintenanceItems.selected) {
              displayText = `- ${this.maintenanceItems.selected.length} Selected`
            }
          }
        }

        return displayText
      },
    },

    methods: {
      setSelectedMenu (val) {
        this.selectedMenu = val
      },

      openContextMenu (evt, dataItem) {
        evt.preventDefault()
        this.displayMenu = false
        this.x = evt.clientX
        this.y = evt.clientY
        this.selectedItem = dataItem
        this.$nextTick(() => {
          this.displayMenu = true
        })
      },

      menuArchive_onClick () {
        this.$emit('archiveitem')
      },

      menuNew_onClick () {
        this.$emit('newitem')
      },

      menuEdit_onClick () {
        this.$emit('edititem', this.selectedItem)
      },

      menuCopy_onClick () {
        this.$emit('copyitem', this.selectedItem)
      },

      menuInvoice_onClick () {
        this.$emit('invoiceitem')
      },

      menuPaidDate_onClick () {
        this.$emit('payitem')
      },

      menuDelete_onClick () {
        this.$emit('deleteitem')
      },
    },
  }
</script>
