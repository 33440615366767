<template>
  <v-container
    class="pl-1 pt-2 pr-1 pb-1"
  >
    <!-- Value To Replace -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
      >
        <h4
          class="primary--text"
        >
          {{ headerValue2Replace.text }}
        </h4>
      </v-col>
    </v-row>
    <!-- Id To Replace -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
      >
        <combo-box
          ref="editedId"
          v-model="internalValue.value2Replace"
          :label="`${displayAsSingularCategory}: ${displayAsSingularPart}`"
          :items="takeoffs.parts"
          :isBusy="takeoffs.isBusy"
          :displayAsSingular="displayAsSingular"
          @change="field_onChange"
        />
      </v-col>
    </v-row>
    <!-- New Value -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
      >
        <h4
          class="primary--text"
        >
          {{ headerNewValue.text }}
        </h4>
      </v-col>
    </v-row>
    <!-- New Category -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="6"
        offset-sm="1"
      >
        <category
          :key="renderKey"
          ref="editedCategory"
          v-model="internalValue.newValue"
          :label="`New ${displayAsSingularCategory}`"
          :originalData="[]"
          :combobox="true"
          @change="category_onChange"
        />
      </v-col>
    </v-row>
    <!-- New Part -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="8"
        offset-sm="1"
      >
        <part
          :key="renderKey"
          ref="editedPart"
          v-model="internalValue.newValue"
          :label="`New ${displayAsSingularPart}`"
          :originalData="[]"
          :combobox="true"
          @change="part_onChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import ComboBox from '@/components/maintenance/controls/ComboBox'
  import Category from '@/components/maintenance/controls/Category'
  import Part from '@/components/maintenance/controls/Part'

  export default {
    name: 'GlobalReplaceParts',

    components: {
      ComboBox,
      Category,
      Part,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
      headerValue2Replace: {
        type: Object,
        default: null,
      },
      headerNewValue: {
        type: Object,
        default: null,
      },
      displayAsSingular: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        internalValue: this.value,
        renderKey: 0,
      }
    },

    computed: {
      takeoffs: get('takeoff/takeoffs'),
      displayAsSingularCategory: get('category/displayAsSingular'),
      displayAsSingularPart: get('part/displayAsSingular'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      getNewValue () {
        return {
          vendor_id: null,
          category_description: null,
          part_id: null,
          part_description: null,
        }
      },

      forceRerender () {
        this.renderKey += 1
      },

      field_onChange () {
        this.$emit('change', this.internalValue)
      },

      getDisableEdit () {
        let disabled = true

        if (this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.combobox.hasError
        }

        if (!disabled) {
          if (this.$refs.editedCategory) {
            disabled = this.$refs.editedCategory.$refs.combobox.hasError
          } else {
            disabled = true
          }
        }

        if (!disabled) {
          if (this.$refs.editedPart) {
            disabled = this.$refs.editedPart.$refs.combobox.hasError
          } else {
            disabled = true
          }
        }

        if (this.internalValue.newValue) {
          if (!disabled) {
            if ({}.hasOwnProperty.call(this.internalValue.newValue, 'vendor_id')) {
              disabled = (this.internalValue.newValue.vendor_id ? this.internalValue.newValue.vendor_id.length <= 0 : true)
            } else {
              disabled = true
            }
          }

          if (!disabled) {
            if ({}.hasOwnProperty.call(this.internalValue.newValue, 'part_description')) {
              disabled = (this.internalValue.newValue.part_description ? this.internalValue.newValue.part_description.length <= 0 : true)
            } else {
              disabled = true
            }
          }
        } else {
          disabled = true
        }

        return disabled
      },

      saveEdit () {
        this.internalValue.id = this.internalValue.value2Replace.id
        this.internalValue.description = this.internalValue.value2Replace.description
        this.internalValue.newValue_description = `${this.internalValue.newValue.category_description}: ${this.internalValue.newValue.part_id} - ${this.internalValue.newValue.part_description}`
      },

      category_onChange (dataItem) {
        if (!dataItem) {
          this.internalValue.newValue.vendor_id = null
          this.internalValue.newValue.category_description = null
        }

        this.field_onChange()
      },

      part_onChange (dataItem) {
        if (!dataItem) {
          this.internalValue.newValue.part_id = null
          this.internalValue.newValue.part_description = null
        }

        this.field_onChange()
      },
    },
  }
</script>

<style scoped>

</style>
