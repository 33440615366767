<template>
  <div>
    <v-overlay :value="isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>

    <v-container>
      <!-- Select From & To Customer -->
      <v-row
        v-if="customers.data.length > 1"
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="5"
        >
          <select-customer
            id="from-cust"
            ref="fromCustomer"
            v-model="customers.selectedFrom"
            label="From Customer"
            :appendhint="`to select from its from ${displayAsPluralPlan}`"
            :disableIconNav="true"
            :useFrom="true"
            :disabled="!users.isAdmin"
            @change="fromClient_onChange"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="5"
          offset-md="1"
        >
          <select-customer
            id="to-cust"
            ref="toCustomer"
            v-model="customers.selectedItem"
            label="To Customer"
            :appendhint="`to select from its to ${displayAsPluralPlan}`"
            :disableIconNav="!users.isAdmin"
            @change="toClient_onChange"
          />
        </v-col>
      </v-row>
      <!-- Select From & To Plan -->
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="5"
        >
          <select-plan
            id="from"
            ref="fromPlan"
            v-model="plans.selectedFrom"
            :items="plans.dataFrom"
            label="From Plan"
            appendhint="to view its tree"
            :disableIconNav="true"
            :useFrom="true"
            @change="fromPlan_onChange"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="5"
          offset-md="1"
        >
          <select-plan
            id="to"
            ref="toPlan"
            v-model="plans.selectedItem"
            label="To Plan"
            appendhint="to copy to"
            :disableIconNav="!users.isAdmin"
            :filter-for-estimator="!users.isAdmin"
            @change="toPlan_onChange"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row
        no-gutters
      >
        <!-- Treeview -->
        <v-col
          cols="12"
          md="12"
          lg="6"
        >
          <v-treeview
            v-model="internalValue"
            return-object
            selectable
            selected-color="primary"
            item-disabled="locked"
            :items="takeoffs.treeData"
            item-text="text"
            shaped
            hoverable
            dense
            open-on-click
            transition
            :open="initiallyOpen"
            @input="tree_onChange"
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon
                v-if="!item.file"
                color="secondary"
              >
                {{ displayIcon(open, item) }}
              </v-icon>
              <v-icon
                v-else
                color="secondary"
              >
                {{ 'mdi-file-question' }}
              </v-icon>
            </template>

            <template v-slot:label="{ item, open }">
              <v-menu
                v-if="{}.hasOwnProperty.call(item, 'parts')"
                bottom
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.text }}
                  </div>
                </template>
                <v-list
                  dense
                >
                  <v-subheader
                    class="font-weight-bold"
                  >
                    {{ item.text }} - Parts
                  </v-subheader>
                  <v-divider />
                  <v-list-item
                    v-for="(part, i) in item.parts"
                    :key="i"
                  >
                    <v-list-item-title>
                      <v-icon
                        color="secondary"
                      >
                        {{ displayIcon(open, part) }}
                      </v-icon>
                      {{ part.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div v-else>{{ item.text }}</div>
            </template>
          </v-treeview>
        </v-col>
        <!-- Include Details & Support -->
        <v-col
          cols="12"
          md="12"
          lg="6"
        >
          <v-checkbox
            v-model="takeoffs.treeData[0].includeDetails"
          >
            <template v-slot:label>
              <div
                :class="($vuetify.theme.dark ? 'white--text' : 'black--text')"
              >
                Include Takeoff Details
              </div>
            </template>
          </v-checkbox>
          <v-row
            v-if="customers.selectedFrom.id !== customers.selectedItem.id"
          >
            <v-col>
              <multi-select
                id="SupportTables"
                v-model="takeoffs.selectedSupportTables"
                :items="takeoffs.supportTables"
                item-text="description"
                item-value="id"
                label="Support Tables to Copy"
                @change="supportTables_onChange"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import SelectPlan from '@/components/keys/SelectPlan'
  import MultiSelect from '@/components/maintenance/controls/MultiSelect'

  export default {
    name: 'CopyForTakeoff',

    components: {
      SelectCustomer,
      SelectPlan,
      MultiSelect,
    },

    props: {
      value: {
        type: Array,
        default: () => ([]),
      },
    },

    data () {
      return {
        internalValue: this.value,
        initiallyOpen: [],
      }
    },

    computed: {
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      takeoffs: get('takeoff/takeoffs'),
      displayAsPluralPlan: get('plan/displayAsPlural'),
      isBusy () {
        return this.customers.isBusy || this.plans.isBusy || this.takeoffs.isBusy
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    async created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/push2From':
            this.fromPlansLoaded(mutation.payload)
            break
          case 'takeoff/push2From':
            this.prepareTree()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      retrieve (dataItem) {
        this.$store.dispatch('takeoff/retrieveTakeoffTree', dataItem)
      },

      retrieveTakeoff (planType, plan2Retrieve) {
        if (plan2Retrieve) {
          this.retrieve({ planType: planType, plan_id: plan2Retrieve.plan_id })
        }
      },

      fromClient_onChange () {
        this.$store.dispatch('takeoff/setEmptySupportTables')
      },

      toClient_onChange () {
        if (!this.users.isAdmin) {
          this.$store.dispatch('customer/setFrom', this.customers.selectedItem)
        }

        this.$store.dispatch('takeoff/setEmptySupportTables')
      },

      fromPlansLoaded (fromPlans) {
        let fromPlan = null

        if (fromPlans.length > 0) {
          fromPlan = fromPlans[0]
        }

        if (this.plans.selectedFrom && {}.hasOwnProperty.call(this.plans.selectedFrom, 'id')) {
          if (this.plans.selectedFrom.id.length > 0) {
            fromPlan = this.plans.selectedFrom
          }
        }

        this.retrieveTakeoff('from', fromPlan)
      },

      fromPlan_onChange (fromPlan) {
        this.retrieveTakeoff('from', fromPlan)
      },

      toPlan_onChange (toPlan) {
        this.$store.dispatch('plan/setSelected', toPlan)
        this.retrieveTakeoff('to', toPlan)
      },

      tree_onChange () {
        this.$nextTick(() => {
          this.$emit('change', this.takeoffs.selected)
        })
      },

      supportTables_onChange (selectedTables) {
        this.$emit('change', selectedTables)
      },

      disableSubmit () {
        let disabled = true

        if (this.plans.selectedFrom && {}.hasOwnProperty.call(this.plans.selectedFrom, 'id') &&
          this.plans.selectedItem && {}.hasOwnProperty.call(this.plans.selectedItem, 'id')) {
          if (this.plans.selectedFrom.id.trim().length > 0 && this.plans.selectedItem.id.trim().length > 0) {
            if (this.internalValue.length > 0) {
              disabled = false
            }
          }
        }

        if (!disabled && this.$refs.fromCustomer) {
          disabled = this.$refs.fromCustomer.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.toCustomer) {
          disabled = this.$refs.toCustomer.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.fromPlan) {
          disabled = this.$refs.fromPlan.$refs.combobox.hasError
        }

        if (!disabled && this.$refs.toPlan) {
          disabled = this.$refs.toPlan.$refs.combobox.hasError
        }

        if (disabled) {
          disabled = this.takeoffs.selectedSupportTables.length <= 0
        }

        return disabled
      },

      submit_onClick () {
        this.$store.dispatch('takeoff/update')
      },

      displayIcon (open, item) {
        return open ? item.state.open_icon : item.state.close_icon
      },

      prepareTree () {
        this.$nextTick(() => {
          this.initiallyOpen.push({ id: 0, text: 'All Assemblies' })
          /*
          // TODO: PUSH toPlan to selected
          this.takeoffs.selected.push({
            id: '6',
            plan_id: '0001',
            phase_id: '05',
            assembly_id: '05050',
            assemblyitem_id: '6',
            text: 'COPPER BAY ROOF ALLOWANCE',
            state: { checked: false, editable: false, open_icon: 'mdi-factory', close_icon: 'mdi-factory' },
          })
           */
        })
      },

      initialize () {
        if (this.hasObjectAttribute(this.customers.selectedFrom, 'client_id') && this.hasObjectAttribute(this.customers.selectedItem, 'client_id')) {
          if (this.customers.selectedFrom.client_id && this.customers.selectedItem.client_id) {
            if (this.customers.selectedFrom.client_id.toString() !== this.customers.selectedItem.client_id.toString()) {
              this.$store.dispatch('customer/setFrom', this.customers.selectedItem)
            }
          }
        }

        if (this.plans.selectedItem && {}.hasOwnProperty.call(this.plans.selectedItem, 'id')) {
          if (this.plans.selectedItem.id.trim().length > 0) {
            this.retrieve({ planType: 'to', plan_id: this.plans.selectedItem.id })
          }
        }

        this.$store.dispatch('folder/retrieveAll')
        this.$store.dispatch('estimator/retrieveAll')
        this.$store.dispatch('salesperson/retrieve')
        this.$store.dispatch('takeoff/setEmptySupportTables')
      },
    },
  }
</script>

<style scoped>

</style>
