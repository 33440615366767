<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    persistent
    @keydown.esc.prevent="cancel_onClick"
    @keydown.enter.prevent="ok_onClick"
  >
    <v-card>
      <v-card-title>
        <h2 class="primary--text">
          Delete {{ (useCustomerDetail ? customers.selectedItem.description : '') }} {{ (deleteMultiple ? displayAsPlural : displayAsSingular) }}
        </h2>
      </v-card-title>

      <v-divider />

      <v-card-text class="font-weight-medium text-body-1 pt-5">
        <div
          v-if="deleteMultiple"
          class="d-inline"
        >
          Are you sure you want to remove the
          <span class="primary--text">{{  maintenanceItems.selected.length }}</span>
          selected
          <span class="secondary--text">{{  displayAsPlural.toLowerCase() }}</span>
        </div>
        <div
          v-else
          class="d-inline"
        >
          Are you sure you want to remove
          <span class="secondary--text">{{  displayAsSingular.toLowerCase() }}</span>,
          <span class="primary--text">{{ item2Delete.description }}</span>
        </div>
        <div
          v-if="useCustomerDetail"
          class="d-inline"
        >
          from <span class="accent--text">{{ customers.selectedItem.description }}</span>
        </div>?
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="secondary"
          @click="cancel_onClick"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click="ok_onClick"
        >
          OK
        </v-btn>

        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'DeleteDialog',

    props: {
      maintenanceItems: {
        type: Object,
        default: null,
      },
      displayAsSingular: {
        type: String,
        default: 'Item',
      },
      displayAsPlural: {
        type: String,
        default: 'Items',
      },
      useCustomerDetail: {
        type: Boolean,
        default: true,
      },
    },

    data () {
      return {
        displayDialog: null,
        index2Delete: null,
        item2Delete: {
          description: '',
        },
        deleteMultiple: false,
      }
    },

    computed: {
      customers: get('customer/customers'),
    },

    watch: {
      displayDialog (val) {
        val || this.initializeItem()
      },
    },

    methods: {
      setDisplayDialog (val) {
        this.displayDialog = val
      },

      setDeleteMultiple (val) {
        this.deleteMultiple = val
      },

      openDeleteDialog (dataItem) {
        this.setDeleteMultiple(false)
        this.index2Delete = this.maintenanceItems.data.indexOf(dataItem)
        this.item2Delete = Object.assign({}, dataItem)
        this.setDisplayDialog(true)
      },

      initializeItem () {
        this.$nextTick(() => {
          this.item2Delete = Object.assign({}, this.maintenanceItems.empty)
          this.index2Delete = -1
        })
      },

      cancel_onClick () {
        this.setDisplayDialog(false)
        this.$emit('cancel')
      },

      ok_onClick () {
        // TODO: Use ConfirmationDialog and tweak for DeleteDialog
        if (!this.deleteMultiple && this.index2Delete >= 0) {
          this.maintenanceItems.selected = []
          this.maintenanceItems.selected.push(this.maintenanceItems.data[this.index2Delete])
        }

        this.$emit('delete')

        this.setDisplayDialog(false)
      },
    },
  }
</script>
