<template>
  <v-container
    class="pa-0 ma-0"
  >
    <!-- Address Info -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
      >
        <h4
          class="primary--text"
        >
          Address Info
        </h4>
      </v-col>
    </v-row>
    <!-- Address 1 -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
      >
        <v-text-field
          ref="textAddress1"
          v-model="internalValue.address1"
          :autofocus="$attrs.autofocus"
          dense
          clearable
          class="font-weight-medium text-body-1 control-address"
          :rules="prepAddress1Rules()"
          :maxlength="($attrs.maxlength ? $attrs.maxlength : 50)"
          @blur="onBlur(internalValue)"
          @change="onChange(internalValue)"
          @click="input_onClick"
        >
          <template v-slot:label>
            <v-icon
              v-if="$attrs.required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            Address 1
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- Address 2 -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
      >
        <v-text-field
          ref="textAddress2"
          v-model="internalValue.address2"
          label="Address 2"
          dense
          clearable
          class="font-weight-medium text-body-1 control-address"
          :maxlength="($attrs.maxlength ? $attrs.maxlength : 50)"
          @blur="onBlur(internalValue)"
          @change="onChange(internalValue)"
          @click="input_onClick"
        />
      </v-col>
    </v-row>
    <!-- Zip -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="3"
        offset-sm="1"
      >
        <v-text-field
          ref="textZip"
          v-model="internalValue.zip"
          dense
          clearable
          class="font-weight-medium text-body-1 control-zip"
          :rules="prepZipRules()"
          :maxlength="($attrs.maxlength ? $attrs.maxlength : 10)"
          @blur="zip_onBlur(internalValue)"
          @click="input_onClick"
        >
          <template v-slot:label>
            <v-icon
              v-if="$attrs.required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            Zip
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <!-- City / State -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="6"
        offset-sm="1"
      >
        <v-text-field
          ref="textCity"
          v-model="internalValue.city"
          dense
          clearable
          class="font-weight-medium text-body-1 control-city"
          :rules="prepCityRules()"
          :maxlength="($attrs.maxlength ? $attrs.maxlength : 50)"
          @blur="onBlur(internalValue)"
          @change="onChange(internalValue)"
          @click="input_onClick"
        >
          <template v-slot:label>
            <v-icon
              v-if="$attrs.required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            City
          </template>
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="3"
        offset-sm="1"
      >
        <v-text-field
          ref="comboboxState"
          v-model="internalValue.state"
          dense
          clearable
          min-width="200px"
          class="font-weight-medium text-body-1 control-state"
          :rules="prepStateRules()"
          :maxlength="($attrs.maxlength ? $attrs.maxlength : 2)"
          @blur="onBlur(internalValue)"
          @change="onChange(internalValue)"
          @click="input_onClick"
        >
          <template v-slot:label>
            <v-icon
              v-if="$attrs.required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            State
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Address',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.address1 === oldVal.address1 &&
            val.address2 === oldVal.address2 &&
            val.city === oldVal.city &&
            val.state === oldVal.state &&
            val.zip === oldVal.zip) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.address1 === oldVal.address1 &&
            val.address2 === oldVal.address2 &&
            val.city === oldVal.city &&
            val.state === oldVal.state &&
            val.zip === oldVal.zip) return

          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      hasError () {
        let isError = false

        if (!isError && this.$refs.textAddress1) {
          isError = this.$refs.textAddress1.hasError
        }

        if (!isError && this.$refs.textZip) {
          isError = this.$refs.textZip.hasError
        }

        if (!isError && this.$refs.textCity) {
          isError = this.$refs.textCity.hasError
        }

        if (!isError && this.$refs.comboboxState) {
          isError = this.$refs.comboboxState.hasError
        }

        return isError
      },

      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.address1 !== originalData[0].address1 ||
              dataItem.address2 !== originalData[0].address2 ||
              dataItem.city !== originalData[0].city ||
              dataItem.state !== originalData[0].state ||
              dataItem.zip !== originalData[0].zip) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      onChange (dataItem) {
        this.prepareToUpdate(dataItem)
      },

      zip_onBlur (dataItem) {
        if (dataItem.zip && dataItem.zip.length > 0) {
          this.$store.dispatch('app/retrieveCityState', dataItem)
        }
        this.prepareToUpdate(dataItem)
      },

      prepAddress1Rules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validAddress1)

        return rules
      },

      prepZipRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validZip)

        return rules
      },

      prepCityRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validCity)

        return rules
      },

      prepStateRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validState)

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
  .control-address {
    min-width: 375px;
  }
  .control-zip {
    min-width: 75px;
  }
  .control-city {
    min-width: 75px;
  }
  .control-state {
    min-width: 75px;
  }
</style>
