<template>
  <div>
    <v-data-table
      v-model="plans.selected"
      :headers="headers"
      :items="planData"
      item-key="client_plan_id"
      sort-by="client_plan_id"
      :show-select="!users.isCustomer"
      :loading="plans.isBusy"
      :footer-props="{'items-per-page-options':[-1]}"
      :options="options"
      dense
      hide-default-footer
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsSingular }} Data Available
        </h2>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsSingular }} Summary... Please wait
        </h2>
      </template>

      <!-- Header slots -->
      <template v-slot:header.client_id="{ header }">
        {{ header.text }}
        <customer-filter
          v-model="customers.filtered_ids"
          :items="customers.data"
          :header="header"
        />
      </template>

      <template v-slot:header.status_cd="{ header }">
        {{ header.text }}
        <status-filter
          v-model="plans.filter_report_status_codes"
          :items="plans.DashboardStatusCode"
          :header="header"
        />
      </template>

      <template v-slot:header.submitted_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_submitted_dates"
          :header="header"
        />
      </template>

      <template v-slot:header.due_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_due_dates"
          :header="header"
        />
      </template>

      <template v-slot:header.finish_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_completed_dates"
          :header="header"
        />
      </template>

      <template v-slot:header.invoice_date="{ header }">
        {{ header.text }}
        <date-filter
          v-model="filtered_invoiced_dates"
          :header="header"
        />
      </template>

      <!-- Item slots -->
      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.client_id="{ item }">
        {{ getCustomerName(item.client_id) }}
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          class="d-inline font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? plans.StatusCode[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.submitted_date="{ item }">
        {{ displayDate(item.submitted_date) }}
      </template>

      <template v-slot:item.received_date="{ item }">
        {{ displayDate(item.received_date) }}
      </template>

      <template v-slot:item.due_date="{ item }">
        {{ displayDate(item.due_date) }}
      </template>

      <template v-slot:item.finish_date="{ item }">
        {{ displayDate(item.finish_date) }}
      </template>

      <template v-slot:item.invoice_date="{ item }">
        {{ displayDate(item.invoice_date) }}
      </template>

      <template v-slot:item.total_rate="{ item }">
        ${{ item.total_rate }}
      </template>

      <template v-slot:item.fixed_pricing="{ item }">
        ${{ item.fixed_pricing }}
      </template>

      <!-- Totals Row -->
      <template v-slot:body.append>
        <tr>
          <th v-if="!users.isCustomer"></th>
          <th
            v-for="(thisHeader, i) in headers"
            :key="i"
            :class="getFooterClass(thisHeader)"
          >
            {{ getFooterText(thisHeader) }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <!-- Menus -->
    <context-menu
      ref="reportContextMenu"
      :maintenance-items="plans"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :show-archive-option="false"
      :show-new-option="false"
      :show-edit-option="false"
      :show-invoiced-option="true"
      :show-delete-option="false"
      @invoiceitem="menuInvoice_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DateFilter from '@/components/maintenance/filters/DateFilter'
  import StatusFilter from '@/components/maintenance/filters/StatusFilter'
  import ContextMenu from '@/components/maintenance/ContextMenu'

  export default {
    name: 'ReportTableCustomerSummary',

    components: {
      DateFilter,
      StatusFilter,
      ContextMenu,
    },

    data () {
      return {
        display_sqft_sum: '',
        display_price_sum: '',
        sqft_sum: 0,
        price_sum: 0,
        filtered_submitted_dates: [],
        filtered_due_dates: [],
        filtered_completed_dates: [],
        filtered_invoiced_dates: [],
        options: {
          itemsPerPage: -1,
        },
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'formatDateForTimezone',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      displayAsSingular: get('customer/displayAsSingular'),
      displayAsPlural: get('customer/displayAsPlural'),
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      planData () {
        const payload = {
          plans: this.plans.all,
          client_ids: this.customers.filtered_ids,
          status_codes: this.plans.filter_report_status_codes,
          submitted_dates: this.filtered_submitted_dates,
          due_dates: this.filtered_due_dates,
          completed_dates: this.filtered_completed_dates,
          invoiced_dates: this.filtered_invoiced_dates,
        }

        this.$store.dispatch('plan/retrieveCustomersSummary', payload)

        return this.plans.customersSummary
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/push2CustomersSummary':
            this.setSums()
            break
          case 'plan/successfulUpdate':
            this.initialize()
            break
        }
      })
    },

    mounted () {
      // Clear filtered status codes so that the user can start fresh by selecting the status(es) they want
      this.plans.filter_report_status_codes = []
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setupHeaders () {
        this.headers = []

        // Get a copy of the header object to set up with this reports specs
        const headerCustomer = { ...this.plans.headers.headerCustomer }
        const headerPlan = { ...this.plans.headers.headerDescription }
        const headerSalesperson = { ...this.plans.headers.headerSalesperson }
        const headerEstimator = { ...this.plans.headers.headerEstimator }
        const headerSubmittedDate = { ...this.plans.headers.headerSubmittedDate }
        const headerReceivedDate = { ...this.plans.headers.headerReceivedDate }
        const headerStatus = { ...this.plans.headers.headerStatus }
        const headerCompletedDate = { ...this.plans.headers.headerCompletedDate }
        const headerInvoiceDate = { ...this.plans.headers.headerInvoiceDate }
        const headerDueDate = { ...this.plans.headers.headerDueDate }
        const headerSqFt = { ...this.plans.headers.headerSqFt }
        const headerRate = { ...this.plans.headers.headerRate }
        const headerPrice = { ...this.plans.headers.headerPrice }

        // Set attributes for this report
        headerCustomer.filterable = false
        headerPlan.text = 'Plan'
        headerPlan.filterable = false
        headerSalesperson.filterable = false
        headerEstimator.filterable = false
        headerSubmittedDate.text = 'Submitted'
        headerSubmittedDate.filterable = false
        headerReceivedDate.text = 'Received'
        headerReceivedDate.filterable = false
        headerStatus.filterable = false
        headerCompletedDate.text = 'Completed'
        headerCompletedDate.filterable = false
        headerInvoiceDate.text = 'Invoiced'
        headerInvoiceDate.filterable = false
        headerDueDate.text = 'Due'
        headerDueDate.filterable = false
        headerSqFt.text = 'SQFT'
        headerSqFt.sortable = true

        if (this.users.isCustomer) {
          // Set widths for Customer Report
          if (this.getLengthOf(this.customers.data) > 1) {
            headerCustomer.width = '14%'
            headerPlan.width = '14%'
            headerSalesperson.width = '15%'
            headerSubmittedDate.width = '14%'
            headerEstimator.width = '15%'
            headerStatus.width = '14%'
            headerDueDate.width = '14%'

            this.headers.push(headerCustomer)
          } else {
            headerPlan.width = '18%'
            headerSalesperson.width = '18%'
            headerSubmittedDate.width = '15%'
            headerEstimator.width = '18%'
            headerStatus.width = '16%'
            headerDueDate.width = '15%'
          }

          // Add headers for Customer Report
          this.headers.push(headerPlan)
          this.headers.push(headerSalesperson)
          this.headers.push(headerSubmittedDate)
          this.headers.push(headerEstimator)
          this.headers.push(headerStatus)
          this.headers.push(headerDueDate)
        } else {
          headerPlan.sortable = false

          // Set widths for Admin Report
          headerCustomer.width = '9%'
          headerPlan.width = '9%'
          headerSalesperson.width = '10%'
          headerReceivedDate.width = '12%'
          headerStatus.width = '10%'
          headerCompletedDate.width = '12%'
          headerInvoiceDate.width = '12%'
          headerSqFt.width = '8%'
          headerRate.width = '8%'
          headerPrice.width = '10%'

          // Add headers for Admin Report
          this.headers.push(headerCustomer)
          this.headers.push(headerPlan)
          this.headers.push(headerSalesperson)
          this.headers.push(headerReceivedDate)
          this.headers.push(headerStatus)
          this.headers.push(headerCompletedDate)
          this.headers.push(headerInvoiceDate)
          this.headers.push(headerSqFt)
          this.headers.push(headerRate)
          this.headers.push(headerPrice)
        }
      },

      initialize () {
        this.$store.dispatch('plan/retrieveAll')

        this.setupHeaders()
      },

      setSums () {
        this.sqft_sum = 0
        this.price_sum = 0

        for (const currentItem of this.plans.customersSummary) {
          if ({}.hasOwnProperty.call(currentItem, 'heated_sqft')) {
            if (currentItem.heated_sqft) {
              this.sqft_sum += currentItem.heated_sqft
            }
          }

          if ({}.hasOwnProperty.call(currentItem, 'fixed_pricing')) {
            if (currentItem.fixed_pricing) {
              this.price_sum += parseFloat(currentItem.fixed_pricing)
            }
          }
        }

        this.display_sqft_sum = parseInt(this.sqft_sum, 10).toLocaleString('en-US')
        this.display_price_sum = '$' + parseFloat(this.price_sum).toLocaleString('en-US', { minimumFractionDigits: 2 })
      },

      getSums () {
        return {
          display_sqft_sum: this.display_sqft_sum,
          display_price_sum: this.display_price_sum,
        }
      },

      displayDate (thisDate) {
        return this.formatDateForTimezone(thisDate)
      },

      getFooterClass (thisHeader) {
        let thisClass = null

        if (thisHeader) {
          switch (thisHeader.value) {
            case 'heated_sqft':
            case 'fixed_pricing':
              thisClass = 'text-right'
              break
            default:
              thisClass = null
          }
        }

        return thisClass
      },

      getFooterText (thisHeader) {
        let thisText = null

        if (thisHeader) {
          switch (thisHeader.value) {
            case 'description':
              thisText = `# of Plans: ${this.getLengthOf(this.planData)}`
              break
            case 'heated_sqft':
              thisText = this.display_sqft_sum
              break
            case 'fixed_pricing':
              thisText = this.display_price_sum
              break
            default:
              thisText = null
          }
        }

        return thisText
      },

      openContextMenu (evt, selectedRow) {
        if (!this.users.isCustomer) {
          this.$refs.reportContextMenu.openContextMenu(evt, selectedRow.item)
        }
      },

      menuInvoice_onClick () {
        // TODO: We should be able to make this status_cd dynamic here in the future with the proper UI/UX
        const payload = {
          status_cd: 'invoiced',
          selected: this.plans.selected,
          update_program: '',
          update_user_id: '',
        }

        this.$store.dispatch('user/setStateAsUpdated', payload)
        this.$store.dispatch('plan/updateStatus', payload)
      },
    },
  }
</script>

<style scoped>

</style>
