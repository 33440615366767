<template>
  <div>
    <v-overlay :value="maintenanceState.isBusy">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      />
    </v-overlay>

    <v-card>
      <v-card-title
        v-if="showTitle"
      >
        <v-container>
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <h2
                class="primary--text"
              >
                {{ formTitle }}
              </h2>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <v-divider />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text>
        <v-container
          class="pl-1 pt-2 pr-1 pb-1"
        >
          <!-- Id -->
          <v-row
            no-gutters
            class="mb-3"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <maintain-id
                ref="editedId"
                v-model="internalValue"
                :label="appHeaders.headerId.text"
                :originalData="maintenanceState.originalData"
                :autofocus="(editedIndex < 0)"
                :disabled="(editedIndex >= 0)"
                :required="true"
                :rules="[appRules.isNumeric]"
                @change="disableEdit = getDisableEdit()"
              />
            </v-col>
          </v-row>
          <!-- Description -->
          <v-row
            no-gutters
            class="mb-3"
          >
            <v-col
              cols="12"
              sm="12"
            >
              <maintain-description
                ref="editedDescription"
                v-model="internalValue"
                :label="maintenanceState.headers.headerDescription.text"
                :originalData="maintenanceState.originalData"
                :autofocus="(editedIndex >= 0)"
                :required="true"
                @change="disableEdit = getDisableEdit()"
              />
            </v-col>
          </v-row>
          <!-- Prompt Group -->
          <v-row
            no-gutters
            class="mb-3"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <maintain-prompt-group
                :key="renderKey"
                ref="editedPromptGroup"
                v-model="internalValue"
                :label="maintenanceState.headers.headerPromptGroup.text"
                :originalData="maintenanceState.originalData"
                :combobox="true"
                :disabled="!users.isAdmin"
                @change="disableEdit = getDisableEdit()"
              />
            </v-col>
          </v-row>
          <!-- Include Details -->
          <v-row
            v-if="duplicateMode"
            no-gutters
            class="mb-3"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <v-checkbox
                v-model="maintenanceState.includeDetails"
              >
                <template v-slot:label>
                  <div
                    :class="`${($vuetify.theme.dark ? 'white--text' : 'black--text')} font-weight-medium`"
                  >
                    Include Takeoff Details
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions
        v-if="showActions"
      >
        <v-spacer />
        <v-btn
          color="secondary"
          @click="cancelEdit"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="disableEdit"
          @click="saveEdit"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { get } from 'vuex-pathify'
  import MaintainId from '@/components/maintenance/controls/Id'
  import MaintainDescription from '@/components/maintenance/controls/Description'
  import MaintainPromptGroup from '@/components/maintenance/controls/PromptGroup'

  export default {
    name: 'EditAssembly',

    components: {
      MaintainId,
      MaintainDescription,
      MaintainPromptGroup,
    },

    props: {
      showTitle: {
        type: Boolean,
        default: true,
      },
      showActions: {
        type: Boolean,
        default: true,
      },
      editedIndex: {
        type: Number,
        default: -1,
      },
      duplicateMode: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        disableEdit: false,
        renderKey: 0,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      users: get('user/users'),
      divisions: get('division/divisions'),
      displayAsSingular: get('assembly/displayAsSingular'),
      displayAsPlural: get('assembly/displayAsPlural'),
      maintenanceState: get('assembly/assemblies'),
      formTitle () {
        return this.editedIndex === -1 ? `New ${this.displayAsSingular}` : `Edit ${this.displayAsSingular}`
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      forceRerender () {
        this.renderKey += 1
      },

      getDisableEdit () {
        let disabled = false

        if (!disabled && this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedPromptGroup) {
          disabled = this.$refs.editedPromptGroup.$refs.combobox.hasError
        }

        return disabled
      },

      doneEditing () {
        this.$emit('close')
      },

      cancelEdit () {
        this.$emit('cancel')

        this.$nextTick(() => {
          this.internalValue = Object.assign({}, this.maintenanceState.empty)
        })
      },

      saveEdit () {
        this.disableEdit = this.getDisableEdit()

        if (!this.disableEdit) {
          this.doneEditing()

          if (this.internalValue.updated) {
            this.internalValue.plan_id = this.divisions.selectedItem.plan_id
            this.internalValue.phase_id = this.divisions.selectedItem.phase_id

            if (this.duplicateMode) {
              this.$store.dispatch('assembly/duplicate', this.internalValue)
            } else {
              this.$store.dispatch('assembly/update', this.internalValue)
            }
          }

          this.$emit('save')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.numeric-input >>> input {
  text-align: right;
  min-width: 150px;
}
</style>
