// Pathify
import { make } from 'vuex-pathify'
import XLSX from 'xlsx'

const _ = require('lodash')

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'POS Export',
  displayAsPlural: 'POS Export',
  exports: {
    data: [],
    originalData: [],
    planLoads: [],
    isBusy: false,
    selected: [],
    empty: {
      id: null,
      load_id: null,
      client_id: null,
      description: null,
      note: '',
      updated: false,
      update_program: null,
      update_user_id: null,
    },
  },
}

const emptyLineBisTrack = {
  Line: '',
  LineType: '',
  ProductCode: '',
  Quantity: '',
  QuantityUOM: '',
  UnitPrice: '',
  UnitPriceUOM: '',
  UnitCost: '',
  UnitCostUOM: '',
  ManualOrderLineTypeName: '',
  ManualOrderLineUnitPrice: '',
  ManualOrderLineUnitCost: '',
  ManualDescription: '',
  OrderSectionDescription: '',
  TextLineType: '',
  QuantityTally: '',
  SpecialInstructions: '',
}

const emptyLineLiftoff = {
  'Product Number': '',
  Description: '',
  Notes: '',
  'Order QTY': '',
  UOM: '',
  'Sell Price': '',
  'SLI Group #': '',
  'SLI Group': '',
}

function WriteSectionOpening (holdSectionDescription, lineNumber, thisLine, posData) {
  thisLine = { ...emptyLineBisTrack }

  thisLine.LineType = 5
  thisLine.Line = lineNumber
  thisLine.OrderSectionDescription = holdSectionDescription
  thisLine.TextLineType = 1

  posData.push(thisLine)
  lineNumber++

  return lineNumber
}

function WriteSectionClosing (holdSectionDescription, lineNumber, thisLine, posData) {
  if (holdSectionDescription !== '') {
    thisLine = { ...emptyLineBisTrack }

    thisLine.LineType = 5
    thisLine.Line = lineNumber
    thisLine.OrderSectionDescription = holdSectionDescription
    thisLine.TextLineType = 2

    posData.push(thisLine)
    lineNumber++
  }

  return lineNumber
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.exports.isBusy = isBusy
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  toBisTrack: ({ commit, getters, rootState }) => {
    const thisAction = 'Export POS to BisTrack'
    commit(setIsBusy, true)

    if (rootState.customer.customers.selectedItem.description.length > 0 &&
      rootState.plan.plans.selectedItem.description.length > 0) {
      const forCustomer = rootState.customer.customers.selectedItem.description
      const forPlan = rootState.plan.plans.selectedItem.description
      const fileSafeRegEx = /([^a-z0-9\s_]+)/gi
      const replaceInvalidCharsWith = '-'
      const fileSafeCustomerName = forCustomer.replace(fileSafeRegEx, replaceInvalidCharsWith)
      let fileSafePlanName = forPlan.replace(fileSafeRegEx, replaceInvalidCharsWith)
      const posData = []
      let thisLine = {}
      let lineNumber = 1
      let holdSectionDescription = ''

      for (const currentData of rootState.shippingReport.shippingReports.data) {
        if (holdSectionDescription !== currentData.phaseDescription) {
          lineNumber = WriteSectionClosing(holdSectionDescription, lineNumber, thisLine, posData)

          holdSectionDescription = currentData.phaseDescription

          lineNumber = WriteSectionOpening(holdSectionDescription, lineNumber, thisLine, posData)
        }

        thisLine = { ...emptyLineBisTrack }
        thisLine.Line = lineNumber
        thisLine.LineType = 1
        thisLine.ProductCode = currentData.part_id
        thisLine.Quantity = currentData.order_quantity
        thisLine.QuantityUOM = currentData.unit

        currentData.special_order = (!!currentData.special_order)
        if (currentData.special_order) {
          thisLine.ManualDescription = currentData.itemDescription
        }

        const noteArray = getters.getTallyAndNote(currentData)

        if (noteArray && noteArray.length > 0) {
          thisLine.QuantityTally = noteArray[0].trim()
        }

        if (noteArray && noteArray.length > 1) {
          thisLine.SpecialInstructions = noteArray[1].trim()
        }

        posData.push(thisLine)
        lineNumber++
      }

      if (posData.length > 0) {
        lineNumber = WriteSectionClosing(holdSectionDescription, lineNumber, thisLine, posData)

        if (fileSafePlanName.length > 31) {
          fileSafePlanName = fileSafePlanName.substring(0, 31)
        }

        const ws = XLSX.utils.json_to_sheet(posData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, fileSafePlanName)
        const exportFileName = `${fileSafeCustomerName}-BisTrack POS Export.xls`
        XLSX.writeFile(wb, exportFileName)
      } else {
        console.error('No Report Data to Export')
      }
    }

    commit(setIsBusy, false)
  },

  toBMS: ({ commit, dispatch, rootState }, shippingReports) => {
    const thisAction = 'Export POS to BMS'
    commit(setIsBusy, true)

    const emptySpace = ' '
    const zeroValue = 0
    let recordType
    let filler
    const crLf = String.fromCharCode(13) + String.fromCharCode(10)
    let returnData = null

    // ==================================================================================
    // ToDo: RECORD TYPE 1:  COMPANY INFORMATION
    //
    // Record Type              Numeric   1   1 - 1    There must be a "1" in this field
    // POS Customer #           Alpha/Num 10  2 - 11   Left Justified
    // POS Job #                Alpha/Num 10 12 - 21   Left Justified
    // Estimating Company Name  Alpha/Num 30 22 - 51   Left Justified
    // Ship To Line #1  Alpha/Num         30 52 - 81   Left Justified
    // Ship To Line #2  Alpha/Num         30 82 - 111  Left Justified
    // Ship To Line #3  Alpha/Num         30 112 - 141 Left Justified
    // CRLF                               2  142 - 143 Carriage Return & Line Feed
    // ==================================================================================
    // const clientID = rootState.customer.customers.selectedItem.client_id
    recordType = '1'

    let bmsCustomerNumber = 'XYZBMS'
    if ({}.hasOwnProperty.call(rootState.customer.customers.selectedItem, 'bms_customer_nbr')) {
      bmsCustomerNumber = rootState.customer.customers.selectedItem.bms_customer_nbr
    }
    const customerNumber = bmsCustomerNumber.padEnd(10, ' ')
    const jobNumber = emptySpace.padEnd(10, ' ')
    const companyName = rootState.customer.customers.selectedItem.description.padEnd(30, ' ')
    const shipToLine1 = emptySpace.padEnd(30, ' ')
    const shipToLine2 = emptySpace.padEnd(30, ' ')
    const shipToLine3 = emptySpace.padEnd(30, ' ')

    const recordLayout1 = recordType + customerNumber + jobNumber + companyName + shipToLine1 + shipToLine2 + shipToLine3 + crLf

    // ==================================================================================
    // ToDo: RECORD TYPE 2:  JOB INFORMATION
    //
    // Record Type                  Numeric   1  1 - 1     There must be a "2" in this field
    // Date Requested               Alpha/Num 8  2 - 9     Must be in MMDDYYYY Format
    // Estimating Plan #            Alpha/Num 10 10 - 19   Left Justified
    // Estimating Plan Description  Alpha/Num 30 20 - 49   Left Justified
    // Filler                       Alpha/Num 92 50 - 141
    // CRLF                                   2  142 - 143 Carriage Return & Line Feed
    // ==================================================================================
    // ToDo: Move date formatting to separate function
    const today = new Date()
    let dd = today.getDate()

    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }
    const formattedDate = mm + dd + yyyy

    recordType = '2'
    const dateRequested = formattedDate.toString().padEnd(8, ' ')
    const estimatingPlanNumber = rootState.plan.plans.selectedItem.id.padEnd(10, ' ')
    const estimatingPlanDescription = rootState.plan.plans.selectedItem.description.padEnd(30, ' ')
    filler = emptySpace.padEnd(92, ' ')

    const recordLayout2 = recordType + dateRequested + estimatingPlanNumber + estimatingPlanDescription + filler + crLf
    returnData = recordLayout1 + recordLayout2

    // ==================================================================================
    // ToDo: RECORD TYPE 3:  LINE ITEM DETAIL
    //
    // Record Type          Numeric   1    1 - 1    There must be a "3" in this field
    // Line Type            Alpha/Num 1    2 - 2    If position 2 is an (*), the Enterprise
    //                                              POS system will interpret the entry as a comment
    //                                              line using the part description field as the comment.
    // Item # or SKU #      Alpha/Num 16   3 - 18   Left Justified
    // Product Category     Alpha/Num 6    19 - 24  Left Justified (Class Code)
    // Ordering Quantity    Numeric   5    25 - 29  Right Justified
    // Part Description     Alpha/Num 40   30 - 69  Left Justified
    // Ordering Unit        Alpha/Num 4    70 - 73  Left Justified
    // Selling Price        Numeric   10   74 - 83  Right justified.
    // Unit cost            Numeric   10   84 - 93  Right justified
    // Vendor ID            Alpha/Num 10   94 - 103 Left Justified
    // Delivery Load ID     Alpha/Num 8   104 - 111 Left Justified
    // Manufacturer Part #  Alpha/Num 16  112 - 127 Left Justified
    // Filler               Alpha/Num 14  128 - 141
    // CRLF                           2   142 - 143 Carriage Return & Line Feed
    // ==================================================================================
    let recordLayout3
    let currentPhase = null
    let lineType
    let skuNumber
    let productCategory
    let orderingQuantity
    let partDescription
    let orderingUnit
    let sellingPrice
    let unitCost
    let vendorId
    let deliveryLoadId
    let manufacturerPartNumber
    filler = emptySpace.padEnd(14, ' ')

    const arrayLength = shippingReports.data.length
    for (let i = 0; i < arrayLength; i++) {
      recordType = '3'

      // We need a comment row every time the phase changes
      if (currentPhase !== shippingReports.data[i].phaseDescription) {
         currentPhase = shippingReports.data[i].phaseDescription
         lineType = '*'
         recordLayout3 = recordType + lineType.padEnd(30, ' ') + currentPhase.padEnd(110, ' ') + crLf
         returnData = returnData + recordLayout3
      }

      lineType = emptySpace.padEnd(1, ' ')
      skuNumber = shippingReports.data[i].part_id.padEnd(16, ' ')
      productCategory = shippingReports.data[i].vendor_id.padEnd(6, ' ')
      orderingQuantity = shippingReports.data[i].order_quantity.toString().padStart(5, '0')
      partDescription = shippingReports.data[i].itemDescription.substring(0, 40).padEnd(40, ' ')
      orderingUnit = shippingReports.data[i].unit.padEnd(4, ' ')
      sellingPrice = zeroValue.toString().padStart(10, '0')
      unitCost = zeroValue.toString().padStart(10, '0')
      vendorId = shippingReports.data[i].vendor_id.padEnd(10, ' ')
      deliveryLoadId = shippingReports.data[i].delivery_load_id.padEnd(8, ' ')
      manufacturerPartNumber = emptySpace.padEnd(16, ' ')

      recordLayout3 = recordType + lineType + skuNumber + productCategory + orderingQuantity + partDescription + orderingUnit + sellingPrice + unitCost + vendorId + deliveryLoadId + manufacturerPartNumber + filler + crLf
      returnData = returnData + recordLayout3

      // if we have a note or tally, we need a record 4
      if (shippingReports.data[i].note.length > 0) {
        // ==================================================================================
        // ToDo: RECORD TYPE  4:  ITEM NOTES
        //
        // Record Type  Numeric    1      1 - 1   There must be a "4" in this field
        // Note         Alpha/Num  140    2 - 141 Left Justified
        // CRLF                    2      142 - 143 Carriage Return & Line Feed
        // This record type may occur multiple times for an item. If the note is more than 140
        // characters long another record type 4 will be created to store the subsequent part
        // of the note.
        // ==================================================================================
        recordType = '4'
        shippingReports.data[i].note = shippingReports.data[i].note.replaceAll(' / ', '/')
        shippingReports.data[i].note = shippingReports.data[i].note.replaceAll(';', '')
        shippingReports.data[i].note = '(' + shippingReports.data[i].note.trim() + ')'
        if (shippingReports.data[i].note.length > 140) {
          let numberOfRows = (shippingReports.data[i].note.length / 140)
          const remainder = shippingReports.data[i].note.length % 140
          let startPosition = 0
          let endPosition = 140

          if (remainder !== 0) {
            numberOfRows++
          }

          for (let i = 0; i < numberOfRows; i++) {
            console.log(startPosition)

            const currentRow = shippingReports.data[i].note.substring(startPosition, endPosition)

            if (currentRow !== '') {
              const posNote = shippingReports.data[i].note.padEnd(140, ' ')

              const recordLayout4 = recordType + posNote + crLf
              returnData = returnData + recordLayout4
            }

            startPosition = startPosition + 140
            endPosition = startPosition + 140
          }
        } else {
          const posNote = shippingReports.data[i].note.padEnd(140, ' ')

          const recordLayout4 = recordType + posNote + crLf
          returnData = returnData + recordLayout4
        }
      }
    }

    commit(setIsBusy, false)

    return returnData.toString()
  },

  /**
   * Create a POS Extract using the Liftoff format
   * @param commit    commit to mutations
   * @param rootState Root State object containing all modules' state
   */
  toLiftoff: ({ commit, rootState }) => {
    const thisAction = 'Export POS to Liftoff'
    commit(setIsBusy, true)

    if (rootState.customer.customers.selectedItem.description.length > 0 &&
      rootState.plan.plans.selectedItem.description.length > 0) {
      const forPlan = rootState.plan.plans.selectedItem.description
      const fileSafeRegEx = /([^a-z0-9\s_]+)/gi
      const replaceInvalidCharsWith = '-'
      const fileSafePlanName = forPlan.replace(fileSafeRegEx, replaceInvalidCharsWith)
      let wsSafePlanName = fileSafePlanName
      const posData = []
      let thisLine = {}

      for (const currentData of rootState.shippingReport.shippingReports.data) {
        thisLine = { ...emptyLineLiftoff }
        thisLine['Product Number'] = currentData.part_id
        thisLine.Description = currentData.itemDescription
        thisLine.Notes = currentData.note
        thisLine['Order QTY'] = currentData.order_quantity
        thisLine.UOM = currentData.unit
        thisLine['SLI Group #'] = currentData.delivery_load_id
        thisLine['SLI Group'] = currentData.phaseDescription

        posData.push(thisLine)
      }

      if (posData.length > 0) {
        if (fileSafePlanName.length > 31) {
          wsSafePlanName = fileSafePlanName.substring(0, 31)
        }

        const ws = XLSX.utils.json_to_sheet(posData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, wsSafePlanName)

        const exportFileName = `${fileSafePlanName}-Liftoff POS Export.csv`
        XLSX.writeFile(wb, exportFileName)
      } else {
        console.error('No Report Data to Export')
      }
    }

    commit(setIsBusy, false)
  },
}

const getters = {
  /***
   * Get an array containing the Tally at 0 index & the note at index 1
   * @param state export POS state
   * @returns {function(dataItem: Object): *[]}
   */
  getTallyAndNote: (state) => (dataItem) => {
    const noteArray = []
    const origNote = (dataItem.note ? dataItem.note : '')
    let manualNote = origNote
    const beginTally = 'Tally:'
    const endTally = '; '
    let formattedTally = ''

    if (origNote.includes(beginTally) && origNote.includes(endTally)) {
      const idxOfEndTally = origNote.indexOf(endTally)
      // +2 because indexOf is 0 based and substring is not, plus the space after the semi-colon
      manualNote = origNote.substring(idxOfEndTally + 2)
      formattedTally = origNote.replace(manualNote, '').replace(beginTally, '').replace(endTally, '').replaceAll(' / ', '/').trim()
    }

    noteArray.push(formattedTally)
    noteArray.push(manualNote)

    return noteArray
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
