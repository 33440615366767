<template>
  <div class="ma-0 pa-0">
    <div
      v-if="showReadOnlyDate"
      class="pa-0 ma-0 pb-1 font-weight-regular"
    >
      {{ ( label ? `${label}:` : '' ) }}
      <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
        {{ ( internalDate ? formatDateForTimezone(internalDate) : '') }}
      </span>
    </div>

    <v-menu
      v-else
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="nudgeRight"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          ref="textField"
          v-model="displayDate"
          class="font-weight-medium text-body-1"
          append-icon="mdi-calendar"
          :maxlength="10"
          readonly
          dense
          :clearable="$attrs.clearable"
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:label>
            <v-icon
              v-if="required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            {{ label }}
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="internalDate"
        @input="picker_onChange"
      />
    </v-menu>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DateField',

    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: 'Date',
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: null,
      },
      rules: {
        type: Array,
      },
      nudgeRight: {
        type: Number,
        default: 40,
      },
      showReadOnlyDate: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        displayDate: null,
        internalDate: null,
        internalTime: null,
        internalValue: this.value,
        menu: false,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getValidDate',
        'formatDateForTimezone',
      ]),
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.$emit('input', this.internalDate + 'T' + this.internalTime)
        },
      },
      value: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.internalValue = val
          this.setInternalDateTime()
        },
      },
      internalDate: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.displayDate = this.formatDateForTimezone(this.internalDate + 'T' + this.internalTime)
          this.internalValue = val
        },
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setInternalDateTime () {
        const thisDateTime = this.getValidDate(this.internalValue)
        // const timezoneOffset = new Date(thisDateTime).getTimezoneOffset() * 60000
        // const arrDateTime = new Date(thisDateTime - timezoneOffset).toISOString().split('T')
        const arrDateTime = thisDateTime.toISOString().split('T')

        if (arrDateTime !== null) {
          this.internalDate = arrDateTime[0]
          if (arrDateTime.length > 0) {
            this.internalTime = arrDateTime[1]
          }
        }
      },

      initialize () {
        this.setInternalDateTime()
      },

      onBlur (dataItem) {
        this.$nextTick(() => {
          this.$emit('blur', dataItem)
        })
      },

      onChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      picker_onChange () {
        this.menu = false
        this.onChange()
      },

      prepRules () {
        const allRules = []

        if (this.required && !this.disabled) {
          allRules.push(this.appRules.required)
        }

        if (this.rules) {
          for (const parentRule of this.rules) {
            allRules.push(parentRule)
          }
        }

        return allRules
      },
    },
  }
</script>

<style scoped>

</style>
