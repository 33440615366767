<template>
  <div>
    <v-data-table
      ref="maintenanceTableBilling"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="maintenanceState.data"
      sort-by="sort_order"
      class="elevation-2"
      show-select
      :loading="maintenanceState.isBusy"
      :search="search"
      @contextmenu:row="openContextMenu"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="maintenanceState.data.length > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template #item.sort_order="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <sort-order
            ref="editedSortOrder"
            v-model="editedItem"
            label=""
            :originalData="maintenanceState.originalData"
            :clearable="false"
            :rules="[appRules.required]"
            autofocus
            @change="disableEdit = getDisableEdit()"
          />
        </div>
        <span v-else>{{item.sort_order}}</span>
      </template>

      <template #item.value="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <string-value
            ref="editedValue"
            v-model="editedItem"
            label=""
            :originalData="maintenanceState.originalData"
            :clearable="false"
            :rules="[appRules.required]"
            @change="disableEdit = getDisableEdit()"
          />
        </div>

        <span v-else>{{item.value}}</span>
      </template>

      <template #item.description="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <description
            ref="editedDescription"
            v-model="editedItem"
            label=""
            :originalData="maintenanceState.originalData"
            :clearable="false"
            :rules="[appRules.required]"
            @change="disableEdit = getDisableEdit()"
          />
        </div>

        <span v-else>
          <div class="d-inline">
            {{ item.description }}
          </div>
          <v-icon
            class="d-inline"
            color="secondary"
            small
          >
            {{ item.icon }}
          </v-icon>
        </span>
      </template>

      <template #item.rate="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <rate
            ref="editedRate"
            v-model="editedItem"
            label=""
            :originalData="maintenanceState.originalData"
            :clearable="false"
            :rules="[appRules.required]"
            @change="disableEdit = getDisableEdit()"
          />
        </div>

        <span v-else>{{item.rate}}</span>
      </template>

      <template #item.icon="{ item }">
        <div
          v-if="item.id === editedItem.id"
          class="mt-4 mb-4"
        >
          <select-icon
            ref="editedIcon"
            v-model="editedItem.icon"
            label=""
            :clearable="false"
            @change="icon_onChange"
          />
        </div>

        <span v-else>{{item.icon}}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.id === editedItem.id">
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="accent"
                class="mr-2"
                :disabled="disableEdit"
                @click="saveEdit(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <span>Save</span>
          </v-tooltip>

          <!--
          mdi-window-close
          mdi-cancel
          mdi-file-cancel-outline
          mdi-format-color-marker-cancel
          mdi-pencil-off
          -->
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="secondary"
                @click="cancelEdit(item)"
              >
                mdi-window-close
              </v-icon>
            </template>
            <span>Cancel</span>
          </v-tooltip>
        </div>

        <div v-else>
          <v-icon
            small
            color="primary"
            class="mr-2"
            @click="menuEdit_onClick(item)"
          >
            mdi-pencil
          </v-icon>

          <v-icon
            small
            color="primary"
            @click="openDeleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :use-customer-detail="false"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @deleteitem="menuDelete_onClick"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import SortOrder from '@/components/maintenance/controls/SortOrder'
  import StringValue from '@/components/maintenance/controls/StringValue'
  import Description from '@/components/maintenance/controls/Description'
  import Rate from '@/components/maintenance/controls/Rate'
  import SelectIcon from '@/components/maintenance/controls/SelectIcon'

  export default {
    name: 'BillingTable',

    components: {
      DeleteDialog,
      ContextMenu,
      SortOrder,
      StringValue,
      Description,
      Rate,
      SelectIcon,
    },

    data () {
      return {
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        headers: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingular: get('billing/displayAsSingular'),
      displayAsPlural: get('billing/displayAsPlural'),
      maintenanceState: get('billing/billing'),
      disableNew () {
        return false
      },
    },

    watch: {
      editedIndex: {
        handler (val, oldVal) {
          this.setHeaderWidths()
        },
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setHeaderWidths () {
        if (this.editedIndex > -1) {
          this.maintenanceState.headers.headerSortOrder.width = '10%'
          this.maintenanceState.headers.headerValue.width = '10%'
          this.maintenanceState.headers.headerDescription.width = '35%'
          this.maintenanceState.headers.headerRate.width = '10%'
          this.maintenanceState.headers.headerIcon.width = '34%'
        } else {
          this.maintenanceState.headers.headerSortOrder.width = '5%'
          this.maintenanceState.headers.headerValue.width = '10%'
          this.maintenanceState.headers.headerDescription.width = '40%'
          this.maintenanceState.headers.headerRate.width = '10%'
          this.maintenanceState.headers.headerIcon.width = '34%'
        }
      },

      setupHeaders () {
        this.setHeaderWidths()

        this.headers = []

        this.headers.push(this.maintenanceState.headers.headerSortOrder)
        this.headers.push(this.maintenanceState.headers.headerValue)
        this.headers.push(this.maintenanceState.headers.headerDescription)
        this.headers.push(this.maintenanceState.headers.headerRate)
        this.headers.push(this.maintenanceState.headers.headerIcon)
        this.headers.push(this.appHeaders.headerActions)
      },

      initialize () {
        // TODO: Remove this if not necessary...
        // this.$store.dispatch('billing/retrieve')

        this.setupHeaders()
      },

      forceRerender () {
        this.renderKey += 1
      },

      getDisableEdit () {
        let disabled = (this.editedItem.updated !== null ? !this.editedItem.updated : true)

        if (!disabled && this.$refs.editedSortOrder) {
          disabled = this.$refs.editedSortOrder.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedDescription) {
          disabled = this.$refs.editedDescription.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedValue) {
          disabled = this.$refs.editedValue.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedRate) {
          disabled = this.$refs.editedRate.$refs.textField.hasError
        }

        if (!disabled && this.$refs.editedIcon) {
          disabled = this.$refs.editedIcon.$refs.combobox.hasError
        }

        return disabled
      },

      icon_onChange () {
        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)

        this.disableEdit = this.getDisableEdit()
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('billing/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.disableEdit = true

        const addObj = Object.assign({}, this.maintenanceState.empty)

        addObj.id = 0
        this.maintenanceState.data.unshift(addObj)

        this.menuEdit_onClick(addObj)
      },

      menuEdit_onClick (dataItem) {
        this.disableEdit = true
        this.editedIndex = this.maintenanceState.data.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        if (this.editedItem.id === 0) {
          this.$store.dispatch('billing/create', { ...this.editedItem })
        } else {
          this.$store.dispatch('billing/update', { ...this.editedItem })
        }

        this.$nextTick(() => {
          this.cancelEdit()
        })
      },
    },
  }
</script>

<style scoped>

</style>
