<template>
  <div class="ma-0 pa-0">
    <div
      v-if="showReadOnlyTime"
      class="pa-0 ma-0 pb-1 font-weight-regular"
    >
      {{ ( label ? `${label}:` : '' ) }}
      <span :class="($vuetify.theme.dark ? 'white--text' : 'black--text')">
        {{ ( internalValue ? formatTimeForTimezone(internalValue) : '') }}
      </span>
    </div>

    <v-menu
      v-else
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="nudgeRight"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          ref="textField"
          v-model="displayTime"
          class="font-weight-medium text-body-1"
          append-icon="mdi-clock-outline"
          :maxlength="10"
          readonly
          dense
          :clearable="$attrs.clearable"
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:label>
            <v-icon
              v-if="required"
              class="required-icon"
              x-small
            >
              mdi-asterisk
            </v-icon>
            {{ label }}
          </template>
        </v-text-field>
      </template>
      <v-time-picker
        v-model="internalTime"
        @input="picker_onChange"
      />
    </v-menu>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'TimeField',

    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: 'Date',
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: null,
      },
      rules: {
        type: Array,
      },
      nudgeRight: {
        type: Number,
        default: 40,
      },
      showReadOnlyTime: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        displayTime: null,
        internalDate: null,
        internalTime: null,
        internalValue: this.value,
        menu: false,
      }
    },

    computed: {
      ...mapGetters('app', [
        'formatTimeForTimezone',
      ]),
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.internalValue = val
          this.setInternalDateTime()
        },
      },
      internalTime: {
        handler (val, oldVal) {
          if (val === oldVal) return

          if (this.internalDate && val) {
            const [month, day, year] = this.internalDate.split('/').map(part => parseInt(part, 10))
            const paddedMonth = month < 10 ? `0${month}` : `${month}`
            const paddedDay = day < 10 ? `0${day}` : `${day}`
            const formattedTime = `${val}:00`
            const combinedDateTimeString = `${year}-${paddedMonth}-${paddedDay}T${formattedTime}`
            const newDateTime = new Date(combinedDateTimeString)

            this.internalValue = newDateTime.toISOString()
            this.displayTime = this.formatTimeForTimezone(this.internalValue)
          }
        },
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      setInternalDateTime () {
        const thisDateTime = new Date(this.internalValue)

        if (thisDateTime) {
          const zeroPad = (num, places) => String(num).padStart(places, '0')

          this.internalDate = thisDateTime.toLocaleDateString()
          this.internalTime = `${zeroPad(thisDateTime.getHours(), 2)}:${zeroPad(thisDateTime.getMinutes(), 2)}`
        }
      },

      initialize () {
        this.setInternalDateTime()
      },

      onBlur (dataItem) {
        this.$nextTick(() => {
          this.$emit('blur', dataItem)
        })
      },

      onChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      picker_onChange () {
        this.onChange()
      },

      prepRules () {
        const allRules = []

        if (this.required && !this.disabled) {
          allRules.push(this.appRules.required)
        }

        if (this.rules) {
          for (const parentRule of this.rules) {
            allRules.push(parentRule)
          }
        }

        return allRules
      },
    },
  }
</script>

<style scoped>

</style>
