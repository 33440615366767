// Pathify
import { make } from 'vuex-pathify'
import { DataStore } from '@aws-amplify/datastore'
import { ApplicationSupport } from '@/models'
import { SortDirection } from 'aws-amplify'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Billing Category',
  displayAsPlural: 'Billing Categories',
  table_name: 'Billing',
  billing: {
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', value: '', description: '' },
    empty: {
      id: '',
      value: '',
      description: '',
      rate: 0,
      icon: '',
      sort_order: 0,
      updated: false,
    },
    headers: {
      headerSortOrder: {
        text: 'Sort',
        align: 'end',
        value: 'sort_order',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
      },
      headerValue: {
        text: 'Value',
        align: 'start',
        value: 'value',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDescription: {
        text: 'Category',
        align: 'start',
        value: 'description',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerRate: {
        text: 'Rate',
        align: 'end',
        value: 'rate',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerIcon: {
        text: 'Icon',
        align: 'start',
        value: 'icon',
        sortable: false,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.billing.isBusy = isBusy
  },

  setEmpty: (state) => {
    state.billing.selected = []
    state.billing.data = []
  },

  // TODO: All modules should do this rather than setting selected in the action
  setSelected: (state, payload) => {
    state.billing.selected = []
    state.billing.selectedItem = { ...payload }
  },

  push2Data: (state, dataFromDB) => {
    state.billing.data = []

    let newItem
    let newDetails

    for (const currentData of dataFromDB) {
      newItem = { ...state.billing.empty }

      newItem.id = currentData.id
      newItem.value = currentData.value
      newItem.description = currentData.description
      newItem.sort_order = currentData.sort_order
      newItem.icon = currentData.icon

      if (currentData.details) {
        newDetails = JSON.parse(currentData.details)

        if ({}.hasOwnProperty.call(newDetails, 'rate')) {
          newItem.rate = newDetails.rate
        }
      }

      state.billing.data.push(newItem)
      state.billing.originalData.push(newItem)
    }

    state.billing.selected = []

    state.billing.isBusy = false
  },

  successfulCreate: (state) => {},

  successfulUpdate: (state) => {},

  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.billing.empty }
    }

    commit('setSelected', selectedItem)
  },

  create: async ({ commit, dispatch }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      const intSortOrder = parseInt(payload.sort_order * 1, 0)

      await DataStore.save(
        new ApplicationSupport({
          table_name: state.table_name,
          sort_order: intSortOrder,
          value: payload.value,
          description: payload.description,
          details: JSON.stringify({ rate: payload.rate }),
          icon: payload.icon,
        }),
      )
      // console.info(`${thisAction} successful, variant: success`)

      commit('successfulCreate')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  retrieve: async ({ commit, dispatch }) => {
    const thisAction = `Retrieve ${state.displayAsPlural}`
    commit(setIsBusy, true)

    try {
      const dataFromDB = await DataStore.query(ApplicationSupport,
          c => c.table_name('eq', state.table_name),
        { sort: s => s.sort_order(SortDirection.ASCENDING) })

      commit('push2Data', dataFromDB)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  update: async ({ commit, dispatch }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(ApplicationSupport, payload.id)

      if (original) {
        // We found the data in the DB to update
        const intSortOrder = parseInt(payload.sort_order * 1, 0)
        let supportDetails = null

        if (original.details) {
          // details is an object and we can update it
          supportDetails = JSON.parse(original.details)
          supportDetails.rate = payload.rate
        } else {
          supportDetails = { rate: payload.rate }
        }

        await DataStore.save(
          ApplicationSupport.copyOf(original, updated => {
            updated.sort_order = intSortOrder
            updated.value = payload.value
            updated.description = payload.description
            updated.details = JSON.stringify(supportDetails)
            updated.icon = payload.icon
          }),
        )
        // console.info(`${thisAction} successful, variant: success`)

        commit('successfulUpdate')
      } else {
        // TODO: Handle original data not found
      }
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  delete: async ({ commit, dispatch }) => {
    const thisAction = `Delete ${state.displayAsSingular}`

    try {
      for (const thisItem of state.billing.selected) {
        const modelToDelete = await DataStore.query(ApplicationSupport, thisItem.id)

        if (modelToDelete) {
          await DataStore.delete(modelToDelete)
          // console.info(`${thisAction} successful, variant: success`)
        }
      }

      commit('successfulDelete')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
