<template>
  <v-container
    :id="`${$attrs.id.toLowerCase().replaceAll(' ', '-')}-view`"
    fluid
    tag="section"
  >
    <v-row
      id="report-filters"
      justify="center"
      class="non-printable"
    >
      <v-col cols="12">
        <material-card
          color="primary"
          :icon="appItem.icon"
          :icon-small="true"
        >
          <template #title>
            <v-container class="py-0">
              <v-row class="mb-6 pb-6">
                <v-col sm="8">
                  <h3>{{ appItem.title }}</h3>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <slot name="filter-columns" />
              </v-row>

              <v-row
                no-gutters
                class="ml-1 mt-4 mb-3"
              >
                <!-- Additional Filter -->
                <v-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <slot name="additional-filter" />
                </v-col>

                <!-- Report Actions - Print to PDF -->
                <v-col
                  lg="2"
                  md="2"
                  class="d-flex justify-center"
                >
                  <v-btn
                    color="primary"
                    min-width="100"
                    @click="generatePDF"
                  >
                    Print
                  </v-btn>
                </v-col>

                <slot name="additional-action-columns" />
              </v-row>
            </v-container>
          </v-form>
        </material-card>
      </v-col>
    </v-row>

    <!-- Report Print Area -->
    <v-row
      no-gutters
      justify="center"
      class="printable"
      style="min-width: 100%;"
    >
      <v-col
        cols="12"
      >
        <v-container
          id="Report-Print-Area"
          ref="document"
          tag="section"
          class="pl-0 pt-0 pr-0 pb-0 ml-0 mt-0 mr-0 mb-0 printable"
          style="min-width: 100%"
        >
          <v-row
            no-gutters
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-card>
                <v-card-text
                  id="cardText"
                >
                  <slot name="print-area" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import MaterialCard from '@/components/MaterialCard'

  export default {
    name: 'ReportPage',

    components: {
      MaterialCard,
    },

    data () {
      return {
        appItem: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getAppItem',
      ]),
    },

    created () {
      this.appItem = this.getAppItem(this.$attrs.optionsname)
      this.$store.dispatch('user/setPageName', this.$attrs.optionsname)
    },

    methods: {
      // This wait is used by generatePDF to collapse the left navigation before we print the PDF
      // using the browser.
      async wait (ms = 0) {
        document.getElementById('leftNavIcon').click()
        return new Promise(resolve => setTimeout(resolve, ms))
      },

      // TODO: It seems the proper way to handle this is with css (@media print), but I can't get print to work properly without leftNavIcon.click
      // Here we collapse the left navigation (wait), before opening the print window for the browser.
      // This way the print window will only show the report and not the left frame.
      // After the print window is closed, we return navigation to the way it was.
      async generatePDF () {
        await this.wait(50)

        window.print()

        await this.wait(50)
      },
    },
  }
</script>

<style scoped>

</style>
