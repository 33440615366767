import { render, staticRenderFns } from "./PromptGroup.vue?vue&type=template&id=8f4f4208&scoped=true&"
import script from "./PromptGroup.vue?vue&type=script&lang=js&"
export * from "./PromptGroup.vue?vue&type=script&lang=js&"
import style0 from "./PromptGroup.vue?vue&type=style&index=0&id=8f4f4208&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f4f4208",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCombobox,VIcon,VTextField})
