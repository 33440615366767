const EN_LOCALE_ONLY = process.env.EN_LOCALE_ONLY === 'true'
const IN_BROWSER = typeof window !== 'undefined'
const IS_DEBUG = process.env.DEBUG === 'true'
const IS_PROD = process.env.NODE_ENV === 'production'
const QQ_PRIMARY_COLOR = 'qq-primary-color'
const QQ_SECONDARY_COLOR = 'qq-secondary-color'
const QQ_ACCENT_COLOR = 'qq-accent-color'
const DEFAULT_PRIMARY_COLOR = '#9A2A2A'
const DEFAULT_SECONDARY_COLOR = '#212121'
const DEFAULT_ACCENT_COLOR = '#0537ad'

module.exports = {
  EN_LOCALE_ONLY,
  IN_BROWSER,
  IS_DEBUG,
  IS_PROD,
  QQ_PRIMARY_COLOR,
  QQ_SECONDARY_COLOR,
  QQ_ACCENT_COLOR,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_SECONDARY_COLOR,
  DEFAULT_ACCENT_COLOR,
}
