var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mb-2",attrs:{"headers":_vm.headers,"items":_vm.internalValue.tallySheet,"item-key":"index","hide-default-footer":"","show-select":"","footer-props":{'items-per-page-options':[-1]},"options":_vm.options},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h3',{staticClass:"primary--text"},[_vm._v(" No "+_vm._s(_vm.displayAsPlural)+" Available ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"8"}},[_c('h3',{staticClass:"primary--text"},[_vm._v(" Tally Sheet ")])]),_c('v-col',{staticClass:"pa-0 ma-0 text-right",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"ml-0 mt-1 mr-1 mb-0 pt-0 pb-0",attrs:{"id":"newTally","small":"","color":"primary"},on:{"click":_vm.new_onClick}},[_vm._v(" New "+_vm._s(_vm.displayAsSingular)+" ")])],1)],1)],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium text-body-1"},[_c('v-text-field',{staticClass:"font-weight-medium text-body-1 numeric-input",attrs:{"dense":"","rules":[_vm.appRules.required]},on:{"change":function($event){return _vm.setUpdated(item)},"click":_vm.input_onClick},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)]}},{key:"item.linearFeet",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium text-body-1"},[_c('v-text-field',{staticClass:"font-weight-medium text-body-1 centered-input",attrs:{"dense":"","rules":[_vm.appRules.required]},on:{"change":function($event){return _vm.setUpdated(item)},"click":_vm.input_onClick},model:{value:(item.linearFeet),callback:function ($$v) {_vm.$set(item, "linearFeet", $$v)},expression:"item.linearFeet"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.item_onDelete(item)}}},[_vm._v(" mdi-delete ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }