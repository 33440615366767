import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import JsonExcel from 'vue-json-excel'

import Amplify from 'aws-amplify'
import AwsExports from './aws-exports'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'

applyPolyfills().then(() => {
  defineCustomElements(window)
})

/* needed for amplify authentication. tells vue to ignore the amplify- html elements on the page */
Vue.config.ignoredElements = [/amplify-\w*/]

Amplify.configure(AwsExports) /* Updates amplify options using the configuration values from AwsExports */

Vue.component('downloadExcel', JsonExcel)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    headersContains (headers, containsValue) {
      if (headers) {
        for (const header of headers) {
          if (header.value === containsValue) {
            return true
          }
        }
      }

      return false
    },

    showItem (item, users) {
      let showIt = true

      if ({}.hasOwnProperty.call(item, 'guest') && {}.hasOwnProperty.call(item, 'customer') && {}.hasOwnProperty.call(item, 'admin')) {
        const customerAdminUser = users.isCustomer && users.isAdmin

        if (users.isGuest) {
          // If user is Guest role, only show Guest Items
          showIt = item.guest
        } else {
          if (customerAdminUser) {
            // Customer Admins can see everything except Items not for Customers
            showIt = item.customer
          } else {
            // Not a Guest or Customer Admin
            if (item.admin) {
              // Only show Admin Items to Admin roles
              showIt = users.isAdmin
            } else {
              // Show everything else
              showIt = true
            }

            // Only show User Admin Items to User Admin roles
            if (showIt && item.userAdmin) {
              showIt = users.isUserAdmin
            }
          }
        }
      }

      return showIt
    },

    input_onClick (evt) {
      evt.target.setSelectionRange(0, evt.target.value.length)
    },

    /***
     * Get the length of an array, including if the array is null
     * @param thisArray   Array to check the length of
     * @param forEl       For debugging purposes provide the Element name
     * @returns {number}  The length of the Array
     */
    getLengthOf (thisArray, forEl = 'unknown') {
      let thisLength = 0

      if (thisArray !== null) {
        thisLength = thisArray.length
      }

      return thisLength
    },

    /***
     * Determine if a JSON Object has a particular attribute or not
     * @param thisObject    JSON Object to check for the attribute
     * @param thisAttribute Attribute string to check for
     * @returns {boolean}   True if the Object is not null and contains the attribute
     */
    hasObjectAttribute (thisObject, thisAttribute) {
      let hasAttr = false

      if (thisObject) {
        if ({}.hasOwnProperty.call(thisObject, thisAttribute)) {
          hasAttr = true
        }
      }

      return hasAttr
    },

    /**
     * Get the value of the active_ind property from a json object
     * @param dataItem    json object that should contain active_ind
     * @returns {boolean} True as default, otherwise return the value of the active_ind property
     */
    getActive_Ind (dataItem) {
      let active = true

      if (dataItem) {
        if ({}.hasOwnProperty.call(dataItem, 'active_ind')) {
          active = dataItem.active_ind
        }
      }

      return active
    },

    getStatusColor (statusCd, isDarkTheme) {
      let statusColor = null

      if (statusCd) {
        switch (statusCd.toString().toLowerCase()) {
          case 'submitted':
            if (isDarkTheme) {
              statusColor = 'color: lightgreen;'
            } else {
              statusColor = 'color: darkgreen;'
            }

            break
          case 'hold':
          case 'on-hold':
            if (isDarkTheme) {
              statusColor = 'color: yellow;'
            } else {
              statusColor = 'color: darkgoldenrod;'
            }

            break
          case 'cancelled':
            if (isDarkTheme) {
              statusColor = 'color: lightcoral;'
            } else {
              statusColor = 'color: darkred;'
            }

            break
          /*
          case 'complete':
            statusColor = 'color: green;'
            break
           */
          default:
            statusColor = ''
            break
        }
      }

      return statusColor
    },
  },
})
sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
