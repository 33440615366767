import _ from 'lodash'

export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function readCookie (name) {
  const nameEQ = `${name}=`
  const cookieAttributes = document.cookie.split(';')
  let cookieAttribute
  /*
  for (const cookieAttribute of cookieAttributes) {

  }
   */
  for (let i = 0; i < cookieAttributes.length; i++) {
    cookieAttribute = cookieAttributes[i]
    while (cookieAttribute.charAt(0) === ' ') cookieAttribute = cookieAttribute.substring(1, cookieAttribute.length)
    if (cookieAttribute.indexOf(nameEQ) === 0) return cookieAttribute.substring(nameEQ.length, cookieAttribute.length)
  }
  return null
}

export function updateCookie (name, value) {
  let expires = ''
  const days = 30

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toGMTString()}`
  }

  document.cookie = `${name}=${value}${expires}; path=/`
}

export function deleteCookie (name) {
  let expires = ''
  const value = ''
  const days = -1

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toGMTString()}`
  }

  document.cookie = `${name}=${value}${expires}; path=/`
}

export function escapeForRequest (value) {
  let escapedValue = ''

  if (value) {
    // Escape special characters that the request body doesn't like, ie. tick ', quotation marks
    escapedValue = value.replace(/'/g, "''")
    escapedValue = value.replace(/"/g, '\\"')
  }

  return escapedValue
}

export function getChunk (displayAsPlural, url) {
  return new Promise((resolve, reject) => {
    const thisAction = `Get Chunk of ${displayAsPlural}`

    const options = {
      method: 'get',
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        const thisResponse = { name: thisAction }

        if (jsonResponse) {
          if ({}.hasOwnProperty.call(jsonResponse, 'data')) {
            thisResponse.data = jsonResponse.data
          }

          if ({}.hasOwnProperty.call(jsonResponse, 'error')) {
            thisResponse.error = jsonResponse.error
          }
        }

        resolve(thisResponse)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function postChunk (displayAsPlural, url, payload) {
  return new Promise((resolve, reject) => {
    const thisAction = `Post Chunk for ${displayAsPlural}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        const thisResponse = { name: thisAction }

        if (_.isEmpty(jsonResponse.error)) {
          thisResponse.details = `${displayAsPlural} - ${payload.length}`
          thisResponse.data = [...jsonResponse.data]
          resolve(thisResponse)
        } else {
          thisResponse.error = jsonResponse.error
          resolve(thisResponse)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function importChunk (displayAsPlural, url, payload) {
  return new Promise((resolve, reject) => {
    const thisAction = `Import Chunk for ${displayAsPlural}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        const thisResponse = { name: thisAction }

        if (_.isEmpty(jsonResponse.error)) {
          thisResponse.details = `${displayAsPlural} Imported - ${payload.length}`
          resolve(thisResponse)
        } else {
          thisResponse.error = jsonResponse.error
          resolve(thisResponse)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function dateInRange (currentDate, dateRange) {
  let inRange = false

  // Neither variable is null nor undefined
  if (currentDate && dateRange) {
    // We have start AND end date
    if (dateRange.length > 1) {
      const currentDt = new Date(currentDate)
      const startDt = new Date(dateRange[0])
      const endDt = new Date(dateRange[1])

      // Add a day to end date to get the last hours, because time T00:00:00 doesn't work for this check
      endDt.setDate(endDt.getDate() + 1)

      // endDt is less than (not equal to) because of adding a day for time's sake
      if (currentDt >= startDt && currentDt < endDt) {
        inRange = true
      }
    }
  }

  return inRange
}
