// Pathify
import { make } from 'vuex-pathify'

const _ = require('lodash')
const mathExp = require('math-expression-evaluator')

const endpoint = 'https://n584wvjph4.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  takeoffs: {
    total_price: 0,
    fromPlan: [],
    toPlan: [],
    isBusy: false,
    selected: [],
    selectedDataType: null,
    parts: [],
    deliveryLoads: [],
    itemsReplaced: [],
    selectedSupportTables: [],
    with_quantities_only: true,
    treeData: [
      {
        id: 0,
        text: 'All Assemblies',
        checked: false,
        children: [],
        includeDetails: true,
        state: {
          open_icon: 'mdi-folder-open',
          close_icon: 'mdi-folder',
        },
      },
    ],
    supportTables: [
      { id: 'Categories', description: 'Categories' },
      { id: 'Parts', description: 'Parts' },
      { id: 'DeliveryLoads', description: 'Delivery Loads' },
      { id: 'Usages', description: 'Usages' },
      { id: 'PromptGroups', description: 'Prompt Groups' },
      { id: 'Prompts', description: 'Prompts' },
    ],
    dataType: {
      part: {
        value: 'PartSKU',
        text: 'Part/SKU',
        icon: 'mdi-factory',
      },
      delivery_load: {
        value: 'DeliveryLoad',
        text: 'Delivery Load',
        icon: 'mdi-truck-delivery-outline',
      },
      usage: {
        value: 'Usage',
        text: 'Usage',
        icon: 'mdi-wall',
        disabled: true,
      },
    },
  },
}

function copyChunk (displayAsSingular, url, payload) {
  return new Promise((resolve, reject) => {
    const thisAction = `Copy ${displayAsSingular}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        const thisResponse = { name: thisAction }

        if (_.isEmpty(jsonResponse.error)) {
          thisResponse.details = `${displayAsSingular} copied - ${payload.copyTakeoff.length} items`
          thisResponse.multiResult = []

          if (payload.copySupport.length > 0) {
            thisResponse.details = `Support Tables copied - ${payload.copySupport.length} tables`
            thisResponse.multiResult = jsonResponse.multiResult
          }

          resolve(thisResponse)
        } else {
          thisResponse.error = jsonResponse.error
          resolve(thisResponse)
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.takeoffs.isBusy = isBusy
  },

  setEmpty: () => {
    state.takeoffs.selected = []
  },

  setEmptySupportTables: () => {
    state.takeoffs.selectedSupportTables = []
  },

  push2From: (state, dataFromDB) => {
    state.takeoffs.fromPlan = []
    state.takeoffs.fromPlan = dataFromDB
  },

  push2To: (state, dataFromDB) => {
    state.takeoffs.toPlan = []
    state.takeoffs.toPlan = dataFromDB
  },

  push2Parts: (state, dataFromDB) => {
    state.takeoffs.parts = []
    state.takeoffs.parts = dataFromDB
    state.takeoffs.isBusy = false
  },

  push2DeliveryLoads: (state, dataFromDB) => {
    state.takeoffs.deliveryLoads = []
    state.takeoffs.deliveryLoads = dataFromDB
    state.takeoffs.isBusy = false
  },

  replaceResults: (state, dataFromDB) => {
    state.takeoffs.itemsReplaced = []

    dataFromDB.forEach(result => {
      if (result.length > 0) {
        if (result[0].length > 0) {
          state.takeoffs.itemsReplaced.push(result[0][0])
        }
      }
    })

    state.takeoffs.isBusy = false
  },

  initializeTreeData: (state) => {
    state.takeoffs.selected = []
    state.takeoffs.treeData[0].children = []

    let holdDivisionId = ''
    let divisionTree = {}
    let holdAssemblyId = ''
    let assemblyTree = {}
    let assemblyItemTree = {}
    const defaultState = {
      open_icon: 'mdi-folder-open',
      close_icon: 'mdi-folder',
    }

    for (const thisItem of state.takeoffs.fromPlan) {
      if (thisItem.phase_id && holdDivisionId !== thisItem.phase_id) {
        holdDivisionId = thisItem.phase_id
        divisionTree = {
          id: holdDivisionId,
          fromClient_id: null,
          toClient_id: null,
          fromPlan_id: thisItem.plan_id,
          toPlan_id: null,
          phase_id: holdDivisionId,
          text: thisItem.phase_description,
          state: { ...defaultState },
          children: [],
        }
        divisionTree.state.open_icon = 'mdi-home-minus'
        divisionTree.state.close_icon = 'mdi-home-plus'

        state.takeoffs.treeData[0].children.push(divisionTree)
      }

      if (thisItem.assembly_id && holdAssemblyId !== thisItem.assembly_id) {
        holdAssemblyId = thisItem.assembly_id
        assemblyTree = {
          id: `${holdDivisionId}_${holdAssemblyId}`,
          fromClient_id: null,
          toClient_id: null,
          fromPlan_id: thisItem.plan_id,
          toPlan_id: null,
          phase_id: thisItem.phase_id,
          assembly_id: holdAssemblyId,
          text: thisItem.assembly_description,
          state: { ...defaultState },
          parts: [],
        }
        assemblyTree.state.open_icon = 'mdi-toy-brick-minus'
        assemblyTree.state.close_icon = 'mdi-toy-brick-plus'

        divisionTree.children.push(assemblyTree)
      }

      if (thisItem.assemblyitem_id) {
        assemblyItemTree = {
          id: `${holdDivisionId}_${holdAssemblyId}_${thisItem.assemblyitem_id}`,
          fromClient_id: null,
          toClient_id: null,
          fromPlan_id: thisItem.plan_id,
          toPlan_id: null,
          phase_id: thisItem.phase_id,
          assembly_id: thisItem.assembly_id,
          assemblyItem_id: thisItem.assemblyitem_id,
          text: thisItem.description,
          state: { ...defaultState },
        }
        assemblyItemTree.state.open_icon = 'mdi-factory'
        assemblyItemTree.state.close_icon = 'mdi-factory'

        assemblyTree.parts.push({ ...assemblyItemTree })
      }
    }

    state.takeoffs.isBusy = false
  },

  setSelectedTree: (state, payload) => {
    state.takeoffs.selected = []

    for (const currentItem of payload) {
      if ({}.hasOwnProperty.call(currentItem, 'parts')) {
        if (currentItem.parts) {
          for (const currentPart of currentItem.parts) {
            state.takeoffs.selected.push(currentPart)
          }
        }
      }
    }
  },

  successfulUpdate: (state) => {
    state.takeoffs.isBusy = false
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  /**
   * Set takeoffs.selected to the items the user wishes to copy
   * @param commit  commit to mutations
   * @param payload array of selected assemblies from the copy tree
   */
  setSelectedTree: ({ commit }, payload) => {
    commit('setSelectedTree', payload)
  },

  setEmptySupportTables: ({ commit }) => {
    commit('setEmptySupportTables')
  },

  setIsBusy: ({ commit }, isBusy) => {
    commit(setIsBusy, isBusy)
  },

  retrieve: ({ commit, dispatch }, payload) => {
    const thisAction = 'Retrieve Takeoff Data'
    commit(setIsBusy, true)

    let goodResponses = false
    const dataItems = []

    dataItems.push(dispatch('assemblyitem/retrieve', payload, { root: true }))
    dataItems.push(dispatch('prompt/retrieve', payload, { root: true }))

    Promise.all(dataItems)
      .then(responses => {
        for (const thisResponse of responses) {
          if (thisResponse) {
            if (_.isEmpty(thisResponse.error)) {
              goodResponses = true
            } else {
              goodResponses = false
              console.error(`${thisAction} failed`)
              commit(setIsBusy, false)
              dispatch('error/setError', { name: thisAction, details: thisResponse.error }, { root: true })
              break
            }
          }
        }
      })
      .then(() => {
        if (goodResponses) {
          commit(setIsBusy, false)
          dispatch('recalculateTakeoff')
        }
      })
      .catch(error => {
        console.error(`${thisAction} failed`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  retrieveTakeoffTree: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'retrieveTakeoffTree'
    commit(setIsBusy, true)

    let clientId = ''
    let errMsg = ''
    switch (payload.planType) {
      case 'to':
        clientId = rootState.customer.customers.selectedItem.client_id
        break
      case 'from':
        clientId = rootState.customer.customers.selectedFrom.client_id
        break
      default:
        clientId = ''
        errMsg = ` and unhandled planType = "${payload.planType}"`
        break
    }

    if (clientId.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${clientId}/${payload.plan_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          switch (payload.planType) {
            case 'to':
              commit('push2To', jsonResponse.data)
              break
            case 'from':
              commit('push2From', jsonResponse.data)
              break
            default:
              errMsg = `${thisAction} failed with unhandled planType = "${payload.planType}"`
              commit(setIsBusy, false)
              dispatch('error/setError', { name: thisAction, details: errMsg }, { root: true })
              break
          }
        })
        .then(() => {
          commit('initializeTreeData')
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: `${thisAction} failed with an empty client id ${errMsg}` }, { root: true })
    }
  },
  // TODO: Rename to retrieveTakeoffGlobals
  retrieveTakeoffParts: ({ commit, dispatch, rootState }) => {
    const thisAction = 'retrieveTakeoffParts'
    commit(setIsBusy, true)

    const clientId = rootState.customer.customers.selectedItem.client_id

    if (clientId.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${clientId}`

      const requestBody = {
        selectedDataType: state.takeoffs.selectedDataType.value,
        with_quantities_only: state.takeoffs.with_quantities_only,
        treeBranchesSelected: rootState.plan.plans.treeBranchesSelected,
      }

      const options = {
        method: 'post',
        body: JSON.stringify(requestBody),
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            let errorMsg = ''

            switch (state.takeoffs.selectedDataType.value.toLowerCase()) {
              case state.takeoffs.dataType.part.value.toLowerCase():
                commit('push2Parts', jsonResponse.data)
                break
              case state.takeoffs.dataType.delivery_load.value.toLowerCase():
                commit('push2DeliveryLoads', jsonResponse.data)
                break
              default:
                errorMsg = `${thisAction} failed with unhandled data type: ${state.takeoffs.selectedDataType.value}`
                console.error(errorMsg)
                dispatch('error/setError', { name: thisAction, details: errorMsg }, { root: true })
                commit(setIsBusy, false)
                break
            }
          } else {
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          commit(setIsBusy, false)
        })
    } else {
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: 'Failed with an empty client id' }, { root: true })
    }
  },
  // TODO: Rename to replaceTakeoffGlobals
  replaceTakeoffParts: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'replaceTakeoffParts'
    commit(setIsBusy, true)

    const clientId = rootState.customer.customers.selectedItem.client_id

    if (clientId.length > 0) {
      // Dumb down the json for post body and mySQL handling
      const selectedAssemblies = []
      let selectedAssembly = {}
      for (const currentAssembly of rootState.plan.plans.treeBranchesSelected) {
        selectedAssembly = {
          plan_id: currentAssembly.plan_id,
          phase_id: currentAssembly.phase_id,
          assembly_id: currentAssembly.assembly_id,
        }
        selectedAssemblies.push(selectedAssembly)
      }

      const items2Replace = []
      let item2Replace = {}
      let value2Replace = {}
      let newValue = {}

      for (const currentItem of payload.items2Replace) {
        // Help reduce size & dumb down special characters the post body and mySQL don't like

        switch (state.takeoffs.selectedDataType.value.toLowerCase()) {
          case state.takeoffs.dataType.delivery_load.value.toLowerCase():
            value2Replace = {
              delivery_load_id: currentItem.value2Replace.id,
            }

            newValue = {
              delivery_load_id: currentItem.newValue.delivery_load_id,
            }

            break
          default:
            // Send blank part_description in order for the replace to lookup the description from the part table

            value2Replace = {
              vendor_id: currentItem.value2Replace.vendor_id,
              part_id: currentItem.value2Replace.part_id,
              part_description: '',
            }

            newValue = {
              vendor_id: currentItem.newValue.vendor_id,
              part_id: currentItem.newValue.part_id,
              part_description: '',
            }

            break
        }

        item2Replace = {
          id: currentItem.id,
          value2Replace: value2Replace,
          newValue: newValue,
        }
        items2Replace.push(item2Replace)
      }

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${clientId}`

      const requestBody = {
        selectedDataType: state.takeoffs.selectedDataType.value,
        selected_assemblies: selectedAssemblies,
        items2Replace: items2Replace,
      }

      const options = {
        method: 'post',
        body: JSON.stringify(requestBody),
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('replaceResults', jsonResponse.multiResult)
          } else {
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .then(() => {
          const selectedAssembly = { ...rootState.assembly.assemblies.selectedItem }

          dispatch('takeoff/retrieve', selectedAssembly, { root: true })
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          commit(setIsBusy, false)
        })
    } else {
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: 'Failed with an empty client id' }, { root: true })
    }
  },

  update: ({ commit, dispatch, rootState }) => {
    const thisAction = 'copyTakeoffTree'
    commit(setIsBusy, true)

    if (rootState.customer.customers.selectedFrom && rootState.customer.customers.selectedItem && rootState.plan.plans.selectedItem) {
      const fromClientId = rootState.customer.customers.selectedFrom.client_id
      const toClientId = rootState.customer.customers.selectedItem.client_id
      const toPlanId = rootState.plan.plans.selectedItem.plan_id

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${toClientId}/${state.takeoffs.treeData[0].includeDetails}`

      const displayAsSingular = 'Selected Items'
      const maxSize = 500
      let chunkSize = 0
      const chunks2Copy = []
      let items2Copy = []

      const updatedTree = state.takeoffs.selected
      const selectedSupportTables = [...state.takeoffs.selectedSupportTables]
      for (const selectedSupportTable of selectedSupportTables) {
        selectedSupportTable.fromClient_id = fromClientId
        selectedSupportTable.toClient_id = toClientId
        selectedSupportTable.update_user_id = rootState.user.users.user.attributes.email
      }

      let payloadCombined = {}

      // Copy Support Tables 1st and separate to keep results and/or errors separate
      if (selectedSupportTables.length > 0) {
        payloadCombined = {
          copySupport: selectedSupportTables,
          copyTakeoff: [],
        }

        chunks2Copy.push(copyChunk(displayAsSingular, url, payloadCombined))
      }

      for (const branch of updatedTree) {
        branch.fromClient_id = fromClientId
        branch.toClient_id = toClientId
        branch.toPlan_id = toPlanId
        delete branch.text
        delete branch.checked
        delete branch.state
        branch.update_user_id = rootState.user.users.user.attributes.email

        items2Copy.push(branch)
        ++chunkSize

        if (chunkSize >= maxSize) {
          payloadCombined = {
            copySupport: [],
            copyTakeoff: [...items2Copy],
          }

          chunks2Copy.push(copyChunk(displayAsSingular, url, payloadCombined))

          chunkSize = 0
          items2Copy = []
        }
      }

      if (items2Copy.length > 0) {
        payloadCombined = {
          copySupport: [],
          copyTakeoff: [...items2Copy],
        }

        chunks2Copy.push(copyChunk(displayAsSingular, url, payloadCombined))
      }

      const takeoffTitle = 'Copy Takeoff Status'
      const supportTitle = 'Copy Support Status'
      const detailMessages = []
      let toastColor = 'success'
      let toastMessage = 'Successful copy'

      if (chunks2Copy.length > 0) {
        Promise.all(chunks2Copy)
          .then(chunkResponses => {
            if (updatedTree.length > 0) {
              detailMessages.push(`${toastMessage} from ${updatedTree[0].fromClient_id}/${updatedTree[0].fromPlan_id} to ${updatedTree[0].toClient_id}/${updatedTree[0].toPlan_id}`)
              detailMessages.push(' ')

              dispatch('app/refreshKeys', null, { root: true })
            }

            for (const thisChunksResponse of chunkResponses) {
              toastColor = 'success'
              toastMessage = 'Successful copy'

              if (_.isEmpty(thisChunksResponse.error)) {
                console.info(`${thisChunksResponse.details} { ${toastMessage}, variant: ${toastColor} }`)

                if (thisChunksResponse.multiResult.length > 0) {
                  detailMessages.push(`Successfully copied the following support tables from ${fromClientId} to ${toClientId}...`)
                }

                for (const thisResult of thisChunksResponse.multiResult) {
                  toastColor = 'danger'
                  toastMessage = 'Unsuccessful copy: Empty Result'

                  if (thisResult.length > 0) {
                    if (thisResult[0].length > 0) {
                      const result = thisResult[0][0]

                      toastColor = 'success'
                      detailMessages.push(`  - ${result.insertedCount} ${result.support_type}`)
                    }
                  }

                  if (toastColor === 'danger') {
                    console.error(`${thisAction} - ${toastMessage}, variant: ${toastColor}`, thisResult)

                    dispatch('user/showToast',
                      {
                        title: supportTitle,
                        messages: [toastMessage],
                        variant: toastColor,
                      },
                      { root: true },
                    )
                  }
                }
              } else {
                toastColor = 'danger'
                toastMessage = 'Unsuccessful copy'
                console.error(`${thisAction} failed with url: ${url} - ${toastMessage}, variant: ${toastColor}`, chunkResponses)
                commit(setIsBusy, false)
                dispatch('error/setError', thisChunksResponse, { root: true })
                break
              }
            }
          })
          .then(() => {
            if (detailMessages.length > 0) {
              toastColor = 'success'

              dispatch('user/showToast',
                {
                  title: 'Copy Status',
                  messages: detailMessages,
                  variant: toastColor,
                  timeout: 9000,
                },
                { root: true },
              )
            }

            commit('successfulUpdate')
          })
          .catch(error => {
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        commit(setIsBusy, false)
      }
    } else {
      const errorMsg = `${thisAction} failed with no selected To Plan`
      console.error(errorMsg)
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: errorMsg }, { root: true })
    }
  },

  async recalculateTakeoffItem ({ commit, dispatch, getters, rootGetters, rootState, state }, thisItem) {
    const prompts = rootState.prompt.prompts.data
    let thisItemsFormula = ''
    let promptVariableNm = ''
    let promptValue = ''
    let calculatedQuantity = 0
    let wasteFactor = 0
    let price = 0

    try {
      if (prompts.length <= 0) {
        if (thisItem.tallySheet) {
          // No prompts, no tally and not quantity not manually changed, zero out order_quantity
          if (thisItem.tallySheet.length <= 0 && !thisItem.manual_quantity) {
            price = (calculatedQuantity * thisItem.unit_price).toFixed(2)

            thisItem.order_quantity = calculatedQuantity
            thisItem.extPrice = price

            state.takeoffs.total_price = mathExp.eval(`${state.takeoffs.total_price} + ${price}`)
          }
        }
      } else {
        thisItemsFormula = (thisItem.formula !== null ? thisItem.formula : '')

        // TODO: Handle Functions in calculation
        calculatedQuantity = 0
        if (thisItemsFormula.length > 0 || thisItem.manual_quantity) {
          // Sort prompts by longest string to shortest string in order to handle replacing similar strings
          const sortedPrompts = prompts.sort((a, b) => (b.variable !== null ? b.variable.toString().length : 0) - (a.variable !== null ? a.variable.toString().length : 0))
          sortedPrompts.forEach(thisPrompt => {
            promptVariableNm = ((thisPrompt.variable !== null) ? thisPrompt.variable.toString() : '')
            promptValue = ((thisPrompt.value !== null) ? thisPrompt.value.toString() : '0')
            thisItemsFormula = thisItemsFormula.replaceAll(promptVariableNm, promptValue)
          })

          if (thisItem.manual_quantity === true || thisItem.manual_quantity === 1) {
            calculatedQuantity = thisItem.order_quantity
          } else {
            if (thisItemsFormula.length > 0) {
              calculatedQuantity = Math.ceil(mathExp.eval(thisItemsFormula))
              wasteFactor = Math.ceil(calculatedQuantity * (thisItem.waste_factor * 0.01))
              calculatedQuantity += wasteFactor
            }
          }
        }

        price = (calculatedQuantity * thisItem.unit_price).toFixed(2)

        thisItem.order_quantity = calculatedQuantity
        thisItem.extPrice = price

        state.takeoffs.total_price = mathExp.eval(`${state.takeoffs.total_price} + ${price}`)

        const payload = {
          plan_id: thisItem.plan_id,
          phase_id: thisItem.phase_id,
          assembly_id: thisItem.assembly_id,
          assemblyitem_id: thisItem.assemblyitem_id,
        }

        const originalItem = await rootGetters['assemblyitem/getOriginalAssemblyItem'](payload)
        if (originalItem.length > 0) {
          if (!thisItem.updated) {
            if (thisItem.order_quantity !== originalItem[0].order_quantity ||
              thisItem.formula !== originalItem[0].formula) {
              dispatch('user/setStateAsUpdated', thisItem, { root: true })
            }
          }
        }
      }
    } catch (ex) {
      const thisError = rootState.error.empty
      thisError.component.name = 'Recalculate Takeoff Item'
      thisError.details.message = ex
      if (thisItemsFormula) {
        thisError.component.name = `Error calculating formula - '${thisItemsFormula}'`
      }
      // TODO: Fix calculation errors See DETEC-141
      // dispatch('error/setError', { name: thisError.component.name, details: thisError.details }, { root: true })
      // console.error(`TODO: put dispatch error/setError back and fix this error - ${thisError.component.name}`)
      // console.error(thisError.details)
      thisItem.formula_error = thisError.details.message
    }
  },

  async recalculateTakeoff ({ dispatch, rootGetters, rootState, state }) {
    const assemblyItems = rootState.assemblyitem.assemblyItems.data

    state.takeoffs.total_price = 0

    // TODO: Promise.all instead of await .forEach
    await assemblyItems.forEach(thisItem => {
      dispatch('recalculateTakeoffItem', thisItem)
    })

    const updatedItems = await rootGetters['app/getUpdated'](rootState.assemblyitem.assemblyItems.data)

    dispatch('assemblyitem/updateAll', updatedItems, { root: true })
  },

  recalculateTallySheet: ({ dispatch }, item2Recalculate) => {
    const thisAction = 'Recalculate Tally Sheet'
    let totalTally = 0
    let thisNote = ''
    const origitem2Recalculate = { ...item2Recalculate }
    const origNote = (item2Recalculate.note ? item2Recalculate.note : '')
    let manualNote = origNote
    const beginTally = 'Tally:'
    const endTally = '; '

    try {
      if (origNote.includes(beginTally) && origNote.includes(endTally)) {
        const idxOfEndTally = origNote.indexOf(endTally)
        // +2 because indexOf is 0 based and substring is not, plus the space after the semi-colon
        manualNote = origNote.substring(idxOfEndTally + 2)
      }

      if (item2Recalculate.tallySheet.length > 0) {
        item2Recalculate.tallySheet.forEach(thisTally => {
          const currentTally = mathExp.eval(`${thisTally.quantity} * ${thisTally.linearFeet}`)

          if (thisNote.length > 0) {
            thisNote = `${thisNote},`
          } else {
            thisNote = beginTally
          }
          thisNote = `${thisNote} ${thisTally.quantity} / ${thisTally.linearFeet}`

          totalTally = mathExp.eval(`${totalTally} + ${currentTally}`)
        })

        thisNote = `${thisNote}${endTally}`

        item2Recalculate.order_quantity = totalTally

        if (origitem2Recalculate.order_quantity !== item2Recalculate.order_quantity) {
          item2Recalculate.manual_quantity = true
        }
      }

      item2Recalculate.note = `${thisNote}${manualNote}`

      dispatch('recalculateTakeoffItem', item2Recalculate)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
