<template>
  <div>
    <v-data-table
      ref="maintenanceTablePlan"
      v-model="maintenanceState.selected"
      :headers="headers"
      :items="planData"
      item-key="client_plan_id"
      :item-class="getRowClass"
      sort-by="client_plan_id"
      class="elevation-2"
      :show-select="true"
      group-by="folder_id"
      :group-desc="true"
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="getIsBusy"
      :search="search"
      :footer-props="{'items-per-page-options':[5,10,15,30,-1]}"
      :options="options"
      hide-default-footer
      @contextmenu:row="openContextMenu"
      @dblclick:row="row_onDblClick"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <!-- TODO: Export to Excel -->
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="getLengthOf(planData) > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh_onClick"
                  >
                    <v-icon class="pr-8">
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </template>
                <span>Refresh {{ displayAsPlural }}</span>
              </v-tooltip>

              <v-btn
                v-if="!enableAllCustomers"
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Header slots -->
      <template v-slot:header.client_id="{ header }">
        {{ header.text }}
        <customer-filter
          v-model="customers.filtered_ids"
          :items="customers.data"
          :header="header"
        />
      </template>

      <template v-slot:header.estimator_name="{ header }">
        {{ header.text }}
      </template>

      <template v-slot:header.status_cd="{ header }">
        {{ header.text }}
        <status-filter
          v-model="filtered_status_codes"
          :items="statusCodes"
          :header="header"
        />
      </template>

      <template v-slot:group.header="{items, isOpen, toggle}">
        <!-- TODO: Double Click nav to Folder Maint -->
        <th
          colspan="100%"
          @click="toggle"
        >
          <v-icon
            color="secondary"
          >
            {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>

          {{ getFolderDescription (items[0]) }}
        </th>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.client_id="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-medium text-body-1"
              v-on="on"
            >
              {{ getCustomerName(item.client_id) }}
            </div>
          </template>
          <span>{{ displayAsSingularCustomer }} ID: {{ item.client_id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.due_date="{ item }">
        <div
          :class="`text-body-1 ${dueDateClass(item.return_cd)}`"
        >
          {{ displayDate(item.due_date) }}
        </div>
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          v-if="item.submitted_date || item.received_date || item.finish_date || item.invoice_date || item.paid_date"
          class="d-inline font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
                v-bind="attrs"
                v-on="on"
              >
                {{ (item.status_cd ? statusCodes[item.status_cd] : '') }}
              </span>
            </template>
            <div
              v-if="item.submitted_date"
            >
              Submitted: {{ displayDate(item.submitted_date) }}
            </div>
            <div
              v-if="item.received_date"
            >
              Received: {{ displayDate(item.received_date) }}
            </div>
            <div
              v-if="item.finish_date"
            >
              Completed: {{ displayDate(item.finish_date) }}
            </div>
            <div
              v-if="item.invoice_date && !users.isCustomer"
            >
              Invoiced: {{ displayDate(item.invoice_date) }}
            </div>
            <div
              v-if="item.paid_date && !users.isCustomer"
            >
              Paid: {{ displayDate(item.paid_date) }}
            </div>
          </v-tooltip>
        </div>
        <div
          v-else
          class="d-inline font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? statusCodes[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.documents_count="{ item }">
        <v-tooltip
          v-if="getPlanAttachmentCount(item) > 0"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="secondary"
              small
              v-bind="attrs"
              v-on="on"
            >
              mdi-paperclip
            </v-icon>
          </template>
          <span>{{ getPlanAttachmentCount(item) }} Attachment(s)</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          :color="(hasValidEstimatorStatusCode(item) ? 'primary' : null)"
          class="mr-2"
          @click="(hasValidEstimatorStatusCode(item) ? openEditDialog(item) : null)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          small
          :color="(hasValidEstimatorStatusCode(item) ? 'primary' : null)"
          @click="(hasValidEstimatorStatusCode(item) ? openDeleteDialog(item) : null)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="getLengthOf(headers)+1"
          @contextmenu:row="openContextMenu"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeadersDates"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - {{ displayAsSingular }} Details
                    </h2>
                  </template>

                  <template v-slot:item.due_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.due_date) }}
                    </div>
                  </template>

                  <template v-slot:item.submitted_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.submitted_date) }}
                    </div>
                  </template>

                  <template v-slot:item.received_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.received_date) }}
                    </div>
                  </template>

                  <template v-slot:item.finish_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.finish_date) }}
                    </div>
                  </template>

                  <template v-slot:item.invoice_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.invoice_date) }}
                    </div>
                  </template>

                  <template v-slot:item.paid_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.paid_date) }}
                    </div>
                  </template>
                </v-data-table>

                <v-data-table
                  :headers="detailHeadersNames"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                />

                <v-data-table
                  :headers="detailHeadersFooter"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:item.heated_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ item.heated_sqft.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.address>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address1 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address2 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ (item.city ? `${item.city},` : '') }} {{ item.state }} {{ item.zip }}
                    </div>
                  </template>

                  <template v-slot:item.note>
                    <div style="white-space: pre;">{{ item.note }}</div>
                  </template>

                  <template v-slot:footer>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        class="ma-0 pa-0"
                      >
                        <list-documents
                          :key="item.documents_count"
                          label="Documents"
                          :value="item"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="item.url"
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        offset-sm="3"
                        sm="5"
                        class="mb-2 text-left"
                      >
                        <url-value
                          ref="displayDocumentsUrl"
                          :value="item"
                          :label="`Link to ${displayAsSingular} Documents`"
                          :editable="false"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <edit-plan
        ref="formEditPlan"
        v-model="editedItem"
        :edited-index="editedIndex"
        :show-title="true"
        :show-actions="true"
        @cancel="cancelEdit"
        @close="closeDialog"
        @save="planSaved"
      />
    </v-dialog>

    <delete-dialog
      ref="maintenanceDeleteDialog"
      :maintenance-items="maintenanceState"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      @delete="item_onDelete()"
      @cancel="cancelDelete()"
    />

    <confirmation-dialog
      ref="archiveConfirmationDialog"
      @confirm="archive_onClick"
    >
      <template #title>
        <h2 class="primary--text">
          Confirm Archive
        </h2>
      </template>

      <template #message>
        <div>
          Are you sure you want to archive {{ getLengthOf(maintenanceState.selected) }} {{ (getLengthOf(maintenanceState.selected) === 1 ? displayAsSingular : displayAsPlural) }}?
        </div>
      </template>
    </confirmation-dialog>

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="maintenanceState"
      :display-as-singular="(enableAllCustomers ? 'Dashboard' : displayAsSingular)"
      :display-as-plural="displayAsPlural"
      :show-archive-option="true"
      :show-new-option="(enableAllCustomers ? null : true)"
      :show-edit-option="(enableAllCustomers ? null : true)"
      :show-copy-option="true"
      :show-invoiced-option="false"
      :show-delete-option="(enableAllCustomers ? null : true)"
      @archiveitem="menuArchive_onClick"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
      @copyitem="menuCopy_onClick"
      @invoiceitem="menuInvoice_onClick"
      @deleteitem="menuDelete_onClick"
    />

    <div style="display: none;">
      <v-list-item
        id="navToChildMaintenance"
        to="../divisions"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rowClass {
  text-align: left;
}
.numeric-input >>> input {
  text-align: right;
  min-width: 150px;
}
/*
  .tile:hover {
    background-color: var(--secondary-color);
  }
  .itemClass {
    @extend %font-weight-medium;
    @extend %text-body-1;
  }
 */
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ConfirmationDialog from '@/components/maintenance/ConfirmationDialog'
  import DeleteDialog from '@/components/maintenance/DeleteDialog'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import CustomerFilter from '@/components/maintenance/filters/CustomerFilter'
  import StatusFilter from '@/components/maintenance/filters/StatusFilter'
  import EditPlan from '@/components/maintenance/forms/EditPlan'
  import ListDocuments from '@/components/maintenance/controls/ListDocuments'
  import UrlValue from '@/components/maintenance/controls/UrlValue'

  export default {
    name: 'PlanTable',

    components: {
      ConfirmationDialog,
      DeleteDialog,
      ContextMenu,
      CustomerFilter,
      StatusFilter,
      EditPlan,
      ListDocuments,
      UrlValue,
    },

    props: {
      enableAllCustomers: {
        type: Boolean,
        default: false,
        required: false,
      },
    },

    data () {
      return {
        menuFilterCustomers: false,
        menuFilterEstimators: false,
        menuFilterStatuses: false,
        filtered_status_codes: [],
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        options: {
          itemsPerPage: -1,
        },
        headers: [],
        detailHeadersDates: [],
        detailHeadersNames: [],
        detailHeadersFooter: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
        'getValidDate',
        'formatDate',
        'formatDateForTimezone',
        'generatePlanId',
        'addDays',
      ]),
      ...mapGetters('user', [
        'hasValidEstimatorStatusCode',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      ...mapGetters('folder', [
        'getFolderDescription',
      ]),
      ...mapGetters('plan', [
        'filteredPlans',
        'getPlanAttachmentCount',
        'getDueDateClass',
      ]),
      appHeaders: get('app/headers'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      maintenanceState: get('plan/plans'),
      displayAsSingularCustomer: get('customer/displayAsSingular'),
      users: get('user/users'),
      customers: get('customer/customers'),
      archives: get('archive/archives'),
      estimators: get('estimator/estimators'),
      planData () {
        let useData = []
        let useFilteredClients = []

        if (this.enableAllCustomers) {
          useData = this.maintenanceState.all

          useFilteredClients = this.customers.filtered_ids
        } else {
          useData = this.maintenanceState.data

          for (const currentData of this.customers.data) {
            useFilteredClients.push(currentData.client_id)
          }
        }

        return this.filteredPlans({
          plans: useData,
          client_ids: useFilteredClients,
          status_codes: this.filtered_status_codes,
        })
      },
      getIsBusy () {
        return this.maintenanceState.isBusy || this.archives.isBusy
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        // TODO: Future use of disable new Plan may be needed
        return false
      },
      statusCodes () {
        let statuses = {}

        if (this.enableAllCustomers) {
          if (this.users.isCustomer) {
            statuses = this.maintenanceState.GuestStatusCode
          } else {
            statuses = this.maintenanceState.DashboardStatusCode
          }
        } else {
          if (this.users.isCustomer) {
            statuses = this.maintenanceState.CustomerStatusCode
          } else {
            statuses = this.maintenanceState.StatusCode
          }
        }

        return statuses
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
      filtered_status_codes (val) {
        if (this.enableAllCustomers) {
          if (this.users.isCustomer) {
            this.maintenanceState.filter_customer_all_status_codes = val
          } else {
            this.maintenanceState.filter_all_status_codes = val
          }
        } else {
          if (this.users.isCustomer) {
            this.maintenanceState.filter_customer_status_codes = val
          } else {
            this.maintenanceState.filter_data_status_codes = val
          }
        }
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/successfulUpdate':
            this.retrievePlans()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    mounted () {
      this.collapseGroups()

      this.maintenanceState.selected = []
    },

    methods: {
      setupHeaders () {
        const headerId = { ...this.appHeaders.headerId }

        this.headers = []

        if (this.enableAllCustomers) {
          if (this.getLengthOf(this.customers.data) > 1) {
            this.maintenanceState.headers.headerCustomer.width = '20%'
            this.maintenanceState.headers.headerDescription.width = '29%'
            this.maintenanceState.headers.headerEstimator.width = '20%'

            this.headers.push(this.maintenanceState.headers.headerCustomer)
          } else {
            this.maintenanceState.headers.headerDescription.width = '39%'
            this.maintenanceState.headers.headerEstimator.width = '30%'
          }
          this.maintenanceState.headers.headerStatus.width = '20%'
          this.maintenanceState.headers.headerDocCount.width = '1%'
          this.maintenanceState.headers.headerDueDate.width = '10%'

          this.headers.push(this.maintenanceState.headers.headerDescription)
          this.headers.push(this.maintenanceState.headers.headerEstimator)
          this.headers.push(this.maintenanceState.headers.headerDocCount)
          this.headers.push(this.maintenanceState.headers.headerStatus)
          this.headers.push(this.maintenanceState.headers.headerDueDate)
        } else {
          headerId.width = '10%'
          this.maintenanceState.headers.headerDescription.width = '37%'
          this.maintenanceState.headers.headerEstimator.width = '28%'
          this.maintenanceState.headers.headerStatus.width = '12%'
          this.maintenanceState.headers.headerDocCount.width = '1%'
          this.maintenanceState.headers.headerDueDate.width = '10%'

          this.headers.push(headerId)
          this.headers.push(this.maintenanceState.headers.headerDescription)
          this.headers.push(this.maintenanceState.headers.headerEstimator)
          this.headers.push(this.maintenanceState.headers.headerDocCount)
          this.headers.push(this.maintenanceState.headers.headerStatus)
          this.headers.push(this.maintenanceState.headers.headerDueDate)
        }

        this.headers.push(this.maintenanceState.headers.headerFolderId)
        this.headers.push(this.maintenanceState.headers.headerFolderDescription)
        if (!this.enableAllCustomers) {
          // TODO: For now, until edit/delete work from the dashboard, hide edit/delete
          this.headers.push(this.appHeaders.headerActions)
        }
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeadersDates () {
        const headerDueDate = { ...this.maintenanceState.headers.headerDueDate }
        const headerSubmittedDate = { ...this.maintenanceState.headers.headerSubmittedDate }
        const headerReceivedDate = { ...this.maintenanceState.headers.headerReceivedDate }
        const headerCompletedDate = { ...this.maintenanceState.headers.headerCompletedDate }
        const headerInvoiceDate = { ...this.maintenanceState.headers.headerInvoiceDate }
        const headerPaidDate = { ...this.maintenanceState.headers.headerPaidDate }

        headerDueDate.width = '16%'
        headerDueDate.sortable = false
        headerSubmittedDate.width = '16%'
        headerSubmittedDate.sortable = false
        headerReceivedDate.width = '16%'
        headerReceivedDate.sortable = false
        headerCompletedDate.width = '16%'
        headerCompletedDate.sortable = false
        headerInvoiceDate.width = '16%'
        headerInvoiceDate.sortable = false
        headerPaidDate.width = '20%'
        headerPaidDate.sortable = false

        this.detailHeadersDates = []

        this.detailHeadersDates.push(headerDueDate)
        this.detailHeadersDates.push(headerSubmittedDate)
        this.detailHeadersDates.push(headerReceivedDate)
        this.detailHeadersDates.push(headerCompletedDate)

        if (!this.users.isCustomer) {
          this.detailHeadersDates.push(headerInvoiceDate)
          this.detailHeadersDates.push(headerPaidDate)
        }
      },

      setupDetailHeadersNames () {
        const headerEstimator = { ...this.maintenanceState.headers.headerEstimator }
        const headerSalesperson = { ...this.maintenanceState.headers.headerSalesperson }

        headerEstimator.width = '40%'
        headerEstimator.sortable = false
        headerSalesperson.width = '60%'
        headerSalesperson.sortable = false

        this.detailHeadersNames = []

        this.detailHeadersNames.push(headerEstimator)
        this.detailHeadersNames.push(headerSalesperson)
      },

      setupDetailHeadersFooter () {
        this.maintenanceState.headers.headerSqFt.width = '20%'
        this.maintenanceState.headers.headerAddress.width = '25%'
        this.maintenanceState.headers.headerNote.width = '55%'

        this.detailHeadersFooter = []

        this.detailHeadersFooter.push(this.maintenanceState.headers.headerSqFt)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerAddress)
        this.detailHeadersFooter.push(this.maintenanceState.headers.headerNote)
      },

      retrieveSalespeople () {
        this.$store.dispatch('salesperson/retrieveAll')
      },

      retrievePlans () {
        if (this.enableAllCustomers) {
          this.$store.dispatch('plan/retrieveAll')
        } else {
          this.$store.dispatch('plan/retrieve')
        }
      },

      initialize () {
        // If we are coming from the Reports or Dashboard page, pre-fill the search and
        // set the customer dropdown
        if (localStorage.getItem('lastVisitedPage') === 'Reports' || localStorage.getItem('lastVisitedPage') === 'Dashboard') {
          if (localStorage.getItem('searchCustomerId') !== '' && localStorage.getItem('searchCustomerId') !== null) {
            const selectedItem = {
              id: localStorage.getItem('searchCustomerId'),
              client_id: localStorage.getItem('searchCustomerId'),
              description: localStorage.getItem('searchCustomerName'),
            }
            this.$store.dispatch('customer/setSelected', selectedItem)
            localStorage.setItem('searchCustomer', '')
          }
          if (localStorage.getItem('searchPlanDescription') !== '' && localStorage.getItem('searchPlanDescription') !== null) {
            this.search = localStorage.getItem('searchPlanDescription')
            localStorage.setItem('searchPlanDescription', '')
          }
        }

        this.retrievePlans()
        this.retrieveSalespeople()
        this.$store.dispatch('estimator/retrieveAll')

        this.setupHeaders()

        this.setupDetailHeadersDates()
        this.setupDetailHeadersNames()
        this.setupDetailHeadersFooter()

        if (this.enableAllCustomers) {
          if (this.users.isCustomer) {
            this.filtered_status_codes = this.maintenanceState.filter_customer_all_status_codes
          } else {
            this.filtered_status_codes = this.maintenanceState.filter_all_status_codes
          }
        } else {
          if (this.users.isCustomer) {
            this.filtered_status_codes = this.maintenanceState.filter_customer_status_codes
          } else {
            this.filtered_status_codes = this.maintenanceState.filter_data_status_codes
          }
        }
      },

      collapseGroups () {
        const table = this.$refs.maintenanceTablePlan
        const keys = Object.keys(table.$vnode.componentInstance.openCache)
        keys.forEach(dataGroup => {
          if (dataGroup) {
            table.$vnode.componentInstance.openCache[dataGroup] = false
          }
        })
      },

      getRowClass () {
        return 'rowClass'
        // return 'font-weight-medium text-body-1'
      },

      setDialogEdit (val) {
        this.dialogEdit = val
      },

      refresh_onClick () {
        this.retrievePlans()
      },

      dueDateClass (returnCd) {
        return this.getDueDateClass(returnCd)
      },

      displayDate (thisDate) {
        return this.formatDateForTimezone(thisDate)
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.maintenanceState.empty)
        this.editedItem.id = this.generatePlanId(this.maintenanceState.originalData)
        this.editedItem.plan_id = this.editedItem.id
        this.editedItem.status_cd = 'submitted'
        this.editedItem.submitted_date = this.getValidDate(null)
        this.editedItem.due_date = this.addDays(this.editedItem.submitted_date, this.editedItem.return_cd).toString()

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      sqft_onBlur (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.maintenanceState.originalData, dataItem.id)
          if (this.getLengthOf(originalData) > 0) {
            if (dataItem.heated_sqft !== originalData[0].heated_sqft) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
      },

      forceRerender () {
        this.renderKey += 1
        // this.$refs.formEditPlan.forceRerender()
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.planData.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = true
        this.dialogEdit = true
      },

      openDeleteDialog (dataItem) {
        this.$refs.maintenanceDeleteDialog.openDeleteDialog(dataItem)
      },

      item_onDelete () {
        this.$store.dispatch('plan/delete')
      },

      cancelDelete () {
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      archive_onClick () {
        this.$store.dispatch('archive/archivePlans', this.maintenanceState.selected)
      },

      menuArchive_onClick () {
        this.$refs.archiveConfirmationDialog.setDisplayDialog(true)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      menuCopy_onClick (dataItem) {
        if (dataItem && this.customers.selectedItem) {
          if ({}.hasOwnProperty.call(dataItem, 'client_id') && {}.hasOwnProperty.call(this.customers.selectedItem, 'client_id')) {
            if (dataItem.client_id && this.customers.selectedItem.client_id) {
              if (dataItem.client_id.toString() !== this.customers.selectedItem.client_id.toString()) {
                const selectedClient = {
                  id: dataItem.client_id,
                  client_id: dataItem.client_id,
                  description: this.getCustomerName(dataItem.client_id),
                }
                this.$store.dispatch('customer/setSelected', selectedClient)
              }
            }
          }
        }

        this.$store.dispatch('plan/setSelected', dataItem)
        this.$router.push('/components/copy/').catch(() => {})
      },

      menuInvoice_onClick () {
        // TODO: We should be able to make this status_cd dynamic here in the future with the proper UI/UX
        const payload = {
          status_cd: 'invoiced',
          selected: this.maintenanceState.selected,
          update_program: '',
          update_user_id: '',
        }

        this.$store.dispatch('user/setStateAsUpdated', payload)
        this.$store.dispatch('plan/updateStatus', payload)
      },

      menuDelete_onClick () {
        this.$refs.maintenanceDeleteDialog.setDeleteMultiple(true)
        this.$refs.maintenanceDeleteDialog.setDisplayDialog(true)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.maintenanceState.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.$refs.formEditPlan.saveEdit()
      },

      closeDialog () {
        this.dialogEdit = false
      },

      planSaved () {
        this.dialogEdit = false

        this.$nextTick(() => {
          this.refresh_onClick()
        })
      },

      row_onDblClick (event, { item }) {
        if (!this.enableAllCustomers) {
          this.$store.dispatch('plan/setSelected', item)

          this.$nextTick(() => {
            if (this.users.isAdmin) {
              document.getElementById('navToChildMaintenance').click()
            } else {
              if (this.hasValidEstimatorStatusCode(item)) {
                this.$router.push('/components/takeoff/').catch(() => {})
              } else {
                const showToast = {
                  title: `Double Clicked ${this.displayAsSingular}`,
                  messages: [`Not allowed to edit ${this.displayAsPlural} with a status of '${this.statusCodes[item.status_cd]}'.`],
                  variant: 'warning',
                }
                this.$store.dispatch('user/showToast', showToast)
              }
            }
          })
        }

        if (this.$route.name === 'Dashboard') {
          localStorage.setItem('searchCustomerId', item.client_id)
          localStorage.setItem('searchCustomerName', this.getCustomerName(item.client_id))
          localStorage.setItem('searchPlanDescription', item.description)
          this.$router.push('components/plans').catch(() => {})
        }
      },
    },
  }
</script>
