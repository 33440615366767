<template>
  <div>
    <navigation-label
      :label="($attrs.label ? $attrs.label : displayAsSingular)"
      :displayAsSingular="displayAsSingular"
      :displayAsPlural="displayAsPlural"
      :tooltip="`${displayAsSingular} Maintenance`"
      :routeTo="{ name: 'Plans' }"
    />

    <v-container
      class="pa-0 ma-0"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="11"
        >
          <v-combobox
            :id="`key-plan-${$attrs.id}`"
            ref="combobox"
            v-model="internalValue"
            :items="getItems"
            item-text="description"
            item-value="id"
            autofocus
            outlined
            dense
            clearable
            class="ml-1 mt-0 mb-0"
            :hint="`Select a ${displayAsSingular} ${$attrs.appendhint}`"
            :loading="($attrs.useFrom ? plans.isBusyFrom : plans.isBusy)"
            :rules="[appRules.validOption(internalValue, getItems, `${displayAsSingular} does not exist in the list.`)]"
            @change="onChange"
          />
        </v-col>

        <v-col
          cols="12"
          sm="1"
          :class="colClass"
        >
          <v-tooltip
            v-if="!$attrs.disableIconNav"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disablePrevious"
                v-on="on"
                @click="previous_onClick"
              >
                <v-icon>mdi-skip-previous-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Previous {{ displayAsSingular }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="!$attrs.disableIconNav"
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                :disabled="disableNext"
                v-on="on"
                @click="next_onClick"
              >
                <v-icon>mdi-skip-next-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>Next {{ displayAsSingular }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import NavigationLabel from '@/components/maintenance/controls/NavigationLabel'
  import { mapGetters } from 'vuex'

  export default {
    name: 'SelectPlan',

    components: {
      NavigationLabel,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
      filterForEstimator: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('user', [
        'hasValidEstimatorStatusCode',
      ]),
      appRules: get('app/rules'),
      plans: get('plan/plans'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      getItems () {
        let selectItems = []
        const filteredItems = []

        if (this.$attrs.items) {
          selectItems = [...this.$attrs.items]
        } else {
          selectItems = [...this.plans.data]
        }

        if (this.filterForEstimator) {
          for (const currentItem of selectItems) {
            if (this.hasValidEstimatorStatusCode(currentItem)) {
              filteredItems.push(currentItem)
            }
          }

          selectItems = [...filteredItems]
        }

        return selectItems
      },
      colClass () {
        let newClass = 'text-no-wrap d-inline'
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
            newClass = 'd-none'
            break
        }
        return newClass
      },
      disablePrevious () {
        let disabledButton = true

        if (disabledButton && this.getItems) {
          if (this.getItems.length > 0) {
            disabledButton = this.plans.isFirst
          }
        }

        return disabledButton
      },
      disableNext () {
        let disabledButton = true

        if (disabledButton && this.getItems) {
          if (this.getItems.length > 0) {
            disabledButton = this.plans.isLast
          }
        }

        return disabledButton
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.$emit('input', this.internalValue)
          }
        },
      },
      value: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.description === oldVal.description) return

            this.internalValue = val
          }
        },
      },
    },

    created () {
      if (this.$attrs.useFrom) {
        this.$store.dispatch('plan/retrieveFrom')
      } else {
        this.$store.dispatch('plan/retrieve')
      }

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/push2Data':
            if (this.getLengthOf(this.getItems) > 0) {
              if (this.plans.selectedItem) {
                if ({}.hasOwnProperty.call(this.plans.selectedItem, 'id')) {
                  if (this.plans.selectedItem.id.toString().length <= 0) {
                    this.$store.dispatch('plan/setSelected', this.getItems[0])
                  }
                } else {
                  this.$store.dispatch('plan/setSelected', this.getItems[0])
                }
              } else {
                this.$store.dispatch('plan/setSelected', this.getItems[0])
              }
            }

            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      onChange (selectedItem) {
        // This dispatch probably belongs in the parent's @change, but easier to handle setFrom here
        if (this.$attrs.useFrom) {
          this.$store.dispatch('plan/setFrom', selectedItem)
        } else {
          this.$store.dispatch('plan/setSelected', selectedItem)
        }

        this.$emit('change', selectedItem)
      },

      previous_onClick () {
        this.$store.dispatch('plan/previous')

        this.$nextTick(() => {
          this.$emit('navigate')
        })
      },

      next_onClick () {
        this.$store.dispatch('plan/next')

        this.$nextTick(() => {
          this.$emit('navigate')
        })
      },
    },
  }
</script>

<style scoped>

</style>
