<template>
  <div>
    <v-combobox
      ref="combobox"
      v-model="selectedExportType"
      :items="posExportTypes"
      item-text="description"
      item-value="id"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      @change="onChange"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-combobox>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PosExportType',

    props: {
      value: {
        type: String,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        selectedExportType: null,
        posExportTypes: [
          { id: 'BMS', description: 'BMS' },
          { id: 'BisTrack', description: 'BisTrack' },
          { id: 'Liftoff', description: 'Liftoff' },
        ],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      displayAsSingular: get('exportpos/displayAsSingular'),
      maintenanceState: get('exportpos/exports'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        const selectedItem = this.getDataById(this.posExportTypes, this.internalValue)

        if (selectedItem && selectedItem.length > 0) {
          this.selectedExportType = selectedItem[0]
        } else {
          this.selectedExportType = null
        }
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          this.internalValue = dataItem.id
        }

        this.emitChange(dataItem)
      },

      prepRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validOption(this.selectedExportType, this.posExportTypes, `${this.$attrs.label} does not exist in the list.`))

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
