// Pathify
import { make } from 'vuex-pathify'
import { errorMessage } from '@/data/constants.js'

const state = {
  empty: {
    component: {
      name: '',
    },
    message: [],
    details: {
      message: '',
    },
  },
  errors: {
    component: {
      name: '',
    },
    message: [],
    details: {
      message: '',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setError: (state, error2Set) => {
    state.errors.component.name = error2Set.name
    state.errors.message = error2Set.message
    state.errors.details = error2Set.details
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  // payload: { name, details }
  setError: ({ commit }, payload) => {
    if (payload.message) {
      commit('setError', { name: payload.name, message: payload.message, details: payload.details })
    } else {
      commit('setError', { name: payload.name, message: errorMessage.tryAgain, details: payload.details })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
