// Pathify
import { make } from 'vuex-pathify'
import { DataStore } from '@aws-amplify/datastore'
import { UserSettings } from '@/models'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'User Setting',
  displayAsPlural: 'User Settings',
  userSettings: {
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', value: '', description: '' },
    empty: {
      id: '',
      email: '',
      default_customer: null,
      customers: [],
      primary_color: '#9A2A2A',
      secondary_color: '#212121',
      accent_color: '#0537ad',
      image: '0',
      gradient: '0',
      dark: false,
      show_image: true,
      updated: false,
    },
    headers: {
      headerUserEmail: {
        text: 'User Email Address',
        align: 'start',
        value: 'email',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDefaultCustomer: {
        text: 'Default Customer',
        align: 'start',
        value: 'default_customer',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerCustomers: {
        text: 'Customers',
        align: 'start',
        value: 'customers',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerPrimaryColor: {
        text: 'Primary Color',
        align: 'start',
        value: 'primary_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerSecondaryColor: {
        text: 'Secondary Color',
        align: 'start',
        value: 'secondary_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerAccentColor: {
        text: 'Accent Color',
        align: 'start',
        value: 'accent_color',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerImage: {
        text: 'Selected Image',
        align: 'center',
        value: 'image',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerGradient: {
        text: 'Selected Gradient',
        align: 'center',
        value: 'gradient',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerDarkMode: {
        text: 'Dark Mode',
        align: 'start',
        value: 'dark',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1 align-center',
      },
      headerShowImage: {
        text: 'Show Image',
        align: 'start',
        value: 'show_image',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold text-no-wrap',
        cellClass: 'font-weight-medium text-body-1 align-center',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.userSettings.isBusy = isBusy
  },

  setEmpty: (state) => {
    state.userSettings.selected = []
    state.userSettings.data = []
  },

  // TODO: All modules should do this rather than setting selected in the action
  setSelected: (state, payload) => {
    state.userSettings.selected = []
    state.userSettings.selectedItem = { ...payload }
  },

  push2Data: (state, dataFromDB) => {
    state.userSettings.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.userSettings.empty }

      newItem.id = currentData.id
      newItem.email = currentData.user_email
      newItem.default_customer = currentData.default_customer
      newItem.customers = currentData.customers
      newItem.primary_color = currentData.primary_color
      newItem.secondary_color = currentData.secondary_color
      newItem.accent_color = currentData.accent_color
      newItem.image = currentData.image
      newItem.gradient = currentData.gradient
      newItem.dark = currentData.dark
      newItem.show_image = currentData.show_image

      state.userSettings.data.push(newItem)
      state.userSettings.originalData.push(newItem)
    }

    state.userSettings.selected = []

    state.userSettings.isBusy = false
  },

  successfulCreate: (state) => {},

  successfulUpdate: (state) => {},

  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.userSettings.empty }
    }

    commit('setSelected', selectedItem)
  },

  create: async ({ commit, dispatch }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      await DataStore.save(
        new UserSettings({
          user_email: payload.email,
          default_customer: payload.default_customer,
          customers: payload.customers,
          primary_color: payload.primary_color,
          secondary_color: payload.secondary_color,
          accent_color: payload.accent_color,
          image: payload.image,
          gradient: payload.gradient,
          dark: payload.dark,
          show_image: payload.show_image,
        }),
      )
      // console.info(`${thisAction} successful, variant: success`)

      commit('successfulCreate')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  retrieve: async ({ commit, dispatch }) => {
    const thisAction = `Retrieve ${state.displayAsPlural}`
    commit(setIsBusy, true)

    try {
      const dataFromDB = await DataStore.query(UserSettings)

      commit('push2Data', dataFromDB)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  update: async ({ commit, dispatch }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(UserSettings, payload.id)

      if (original) {
        // We found the data in the DB to update
        await DataStore.save(
          UserSettings.copyOf(original, updated => {
            updated.user_email = payload.email
            updated.default_customer = payload.default_customer
            updated.customers = payload.customers
            updated.primary_color = payload.primary_color
            updated.secondary_color = payload.secondary_color
            updated.accent_color = payload.accent_color
            updated.image = payload.image
            updated.gradient = payload.gradient
            updated.dark = payload.dark
            updated.show_image = payload.show_image
          }),
        )
        // console.info(`${thisAction} successful, variant: success`)

        commit('successfulUpdate')
      } else {
        // TODO: Handle original data not found
      }
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  delete: async ({ commit, dispatch }) => {
    const thisAction = `Delete ${state.displayAsSingular}`

    try {
      for (const thisItem of state.userSettings.selected) {
        const modelToDelete = await DataStore.query(UserSettings, thisItem.id)

        if (modelToDelete) {
          await DataStore.delete(modelToDelete)
          // console.info(`${thisAction} successful, variant: success`)
        }
      }

      commit('successfulDelete')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
