<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="12"
          lg="5"
        >
          <v-container>
            <v-row
              v-if="customers.data.length > 1"
              no-gutters
            >
              <v-col
                cols="12"
              >
                <select-customer
                  id="2-maintain-customer"
                  v-model="customers.selectedItem"
                  :appendhint="`to select from its ${displayAsPluralPlan}`"
                  :disableIconNav="!users.isAdmin"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <select-plan
                  id="2-maintain-plan"
                  v-model="plans.selectedItem"
                  :appendhint="`to select from its ${displayAsPluralDivision}`"
                  :disableIconNav="!users.isAdmin"
                  :filter-for-estimator="!users.isAdmin"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <select-division
                  id="2-maintain-division"
                  v-model="divisions.selectedItem"
                  :appendhint="`to select from its ${displayAsPluralAssembly}`"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="12"
              >
                <select-assembly
                  id="2-maintain-assembly"
                  v-model="assemblies.selectedItem"
                  :appendhint="`to view its ${displayAsPluralAssemblyItem}`"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          lg="7"
        >
          <prompt-table :parent-item="assemblies.selectedItem" />
        </v-col>
      </v-row>
    </v-container>

    <assembly-item-table />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import SelectCustomer from '@/components/keys/SelectCustomer'
  import SelectPlan from '@/components/keys/SelectPlan'
  import SelectDivision from '@/components/keys/SelectDivision'
  import SelectAssembly from '@/components/keys/SelectAssembly'
  import PromptTable from '@/components/maintenance/tables/PromptTable'
  import AssemblyItemTable from '@/components/maintenance/tables/AssemblyItemTable'

  export default {
    name: 'PerformTakeoff',

    components: {
      SelectCustomer,
      SelectPlan,
      SelectDivision,
      SelectAssembly,
      PromptTable,
      AssemblyItemTable,
    },

    computed: {
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      divisions: get('division/divisions'),
      assemblies: get('assembly/assemblies'),
      displayAsPluralPlan: get('plan/displayAsPlural'),
      displayAsPluralDivision: get('division/displayAsPlural'),
      displayAsPluralAssembly: get('assembly/displayAsPlural'),
      displayAsPluralAssemblyItem: get('assemblyitem/displayAsPlural'),
    },
  }
</script>

<style scoped>

</style>
