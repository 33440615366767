<template>
  <div>
    <v-data-table
      ref="tableDashboardEstimator"
      v-model="plans.selected"
      :headers="headers"
      :items="planData"
      item-key="client_plan_id"
      :item-class="getRowClass"
      sort-by="client_plan_id"
      class="elevation-2"
      group-by="folder_description"
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="isBusy"
      :search="search"
      :footer-props="{'items-per-page-options':[5,10,15,30,-1]}"
      :options="options"
      :expanded.sync="expanded"
      hide-default-footer
      @contextmenu:row="openContextMenu"
      @dblclick:row="row_onDblClick"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No Dashboard {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching dashboard {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading Dashboard {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="12"
              class="pa-0 ma-0 text-left"
            >
              <h2
                class="primary--text font-weight-bold pl-3 pt-2 pr-1"
              >
                {{ displayAsPlural }} assigned to {{ users.user.attributes.name }}
              </h2>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="planCount > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh_onClick"
                  >
                    <v-icon class="pr-8">mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh {{ displayAsPlural }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Header slots -->
      <template v-slot:header.client_id="{ header }">
        {{ header.text }}
        <customer-filter
          v-model="customers.filtered_ids"
          :items="customers.data"
          :header="header"
        />
      </template>

      <template v-slot:header.status_cd="{ header }">
        {{ header.text }}
        <status-filter
          v-model="plans.filter_all_status_codes"
          :items="plans.DashboardStatusCode"
          :header="header"
        />
      </template>

      <template v-slot:group.header="{items, isOpen, toggle}">
        <!-- TODO: Double Click nav to Folder Maint -->
        <th
          colspan="100%"
          @click="toggle"
        >
          <v-icon
            color="secondary"
          >
            {{ isOpen ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>

          {{ getFolderDescription (items[0]) }}
        </th>
      </template>

      <template v-slot:item.client_id="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-medium text-body-1"
              v-on="on"
            >
              {{ getCustomerName(item.client_id) }}
            </div>
          </template>
          <span>{{ displayAsSingularCustomer }} ID: {{ item.client_id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.due_date="{ item }">
        <div
          :class="`text-body-1 ${dueDateClass(item.return_cd)}`"
        >
          {{ displayDate(item.due_date) }}
        </div>
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          v-if="item.submitted_date || item.received_date || item.finish_date || item.invoice_date || item.paid_date"
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
                v-bind="attrs"
                v-on="on"
              >
                {{ (item.status_cd ? plans.DashboardStatusCode[item.status_cd] : '') }}
              </span>
            </template>
            <div
              v-if="item.submitted_date"
            >
              Submitted: {{ displayDate(item.submitted_date) }}
            </div>
            <div
              v-if="item.received_date"
            >
              Received: {{ displayDate(item.received_date) }}
            </div>
            <div
              v-if="item.finish_date"
            >
              Completed: {{ displayDate(item.finish_date) }}
            </div>
            <div
              v-if="item.invoice_date"
            >
              Invoiced: {{ displayDate(item.invoice_date) }}
            </div>
            <div
              v-if="item.paid_date"
            >
              Paid: {{ displayDate(item.paid_date) }}
            </div>
          </v-tooltip>
        </div>
        <div
          v-else
          class="font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? plans.DashboardStatusCode[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.documents_count="{ item }">
        <v-tooltip
          v-if="getPlanAttachmentCount(item) > 0"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="secondary"
              small
            >
              mdi-paperclip
            </v-icon>
          </template>
          <span>{{ getPlanAttachmentCount(item) }} Attachment(s)</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length+1"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeadersDates"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - Dashboard {{ displayAsSingular }} Details
                    </h2>
                  </template>

                  <template v-slot:item.due_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.due_date) }}
                    </div>
                  </template>

                  <template v-slot:item.submitted_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.submitted_date) }}
                    </div>
                  </template>

                  <template v-slot:item.received_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.received_date) }}
                    </div>
                  </template>

                  <template v-slot:item.finish_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.finish_date) }}
                    </div>
                  </template>

                  <template v-slot:item.invoice_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.invoice_date) }}
                    </div>
                  </template>

                  <template v-slot:item.paid_date="{ item }">
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ displayDate(item.paid_date) }}
                    </div>
                  </template>
                </v-data-table>

                <v-data-table
                  :headers="detailHeadersNames"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                />

                <v-data-table
                  :headers="detailHeadersFooter"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:item.heated_sqft>
                    <div
                      class="font-weight-medium text-body-1 text-right"
                    >
                      {{ item.heated_sqft.toLocaleString() }}
                    </div>
                  </template>

                  <template v-slot:item.address>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address1 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address2 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ (item.city ? `${item.city},` : '') }} {{ item.state }} {{ item.zip }}
                    </div>
                  </template>

                  <template v-slot:footer>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        class="ma-0 pa-0"
                      >
                        <list-documents
                          :key="item.documents_count"
                          label="Documents"
                          :value="item"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="item.url"
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        offset-sm="3"
                        sm="5"
                        class="mb-2 text-left"
                      >
                        <url-value
                          ref="displayDocumentsUrl"
                          :value="item"
                          :label="`Link to ${displayAsSingular} Documents`"
                          :editable="false"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Menus -->
    <context-menu
      ref="planContextMenu"
      :maintenance-items="plans"
      display-as-singular="Dashboard"
      display-as-plural="Dashboards"
      :show-archive-option="false"
      :show-new-option="false"
      :show-edit-option="false"
      :show-copy-option="true"
      :show-invoiced-option="false"
      :show-delete-option="false"
      @copyitem="menuCopy_onClick"
    />
  </div>
</template>

<style lang="scss" scoped>
.rowClass {
  text-align: left;
}
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import CustomerFilter from '@/components/maintenance/filters/CustomerFilter'
  import StatusFilter from '@/components/maintenance/filters/StatusFilter'
  import ListDocuments from '@/components/maintenance/controls/ListDocuments'
  import UrlValue from '@/components/maintenance/controls/UrlValue'

  export default {
    name: 'EstimatorDashboardTable',

    components: {
      ContextMenu,
      CustomerFilter,
      StatusFilter,
      ListDocuments,
      UrlValue,
    },

    data () {
      return {
        menuFilterCustomers: false,
        menuFilterStatuses: false,
        search: null,
        options: {
          itemsPerPage: -1,
        },
        expanded: [],
        headers: [],
        detailHeadersDates: [],
        detailHeadersNames: [],
        detailHeadersFooter: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'formatDateForTimezone',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      ...mapGetters('folder', [
        'getFolderDescription',
      ]),
      ...mapGetters('plan', [
        'getPlanAttachmentCount',
        'getDueDateClass',
      ]),
      ...mapGetters('estimator', [
        'filterEstimatorsPlans',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      displayAsSingularCustomer: get('customer/displayAsSingular'),
      users: get('user/users'),
      customers: get('customer/customers'),
      estimators: get('estimator/estimators'),
      plans: get('plan/plans'),
      planData () {
        return this.filterEstimatorsPlans({
          plans: this.plans.all,
          client_ids: this.customers.filtered_ids,
          estimator_ids: this.estimators.filtered_ids,
          status_codes: this.plans.filter_all_status_codes,
        })
      },
      planCount () {
        let thisLength = 0

        if (this.planData) {
          thisLength = this.planData.length
        }

        return thisLength
      },
      isBusy () {
        return this.estimators.isBusy || this.plans.isBusy
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'estimator/successfulRetrieveAll':
            this.$store.dispatch('estimator/retrieveDistinct')
            break
          case 'estimator/push2Distinct':
            this.getIds()
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    mounted () {
      this.collapseGroups()
    },

    methods: {
      setupHeaders () {
        this.plans.headers.headerCustomer.width = '20%'
        this.plans.headers.headerDescription.width = '48%'
        this.plans.headers.headerDocCount.width = '1%'
        this.plans.headers.headerStatus.width = '20%'
        this.plans.headers.headerDueDate.width = '10%'

        this.headers = []

        this.headers.push(this.plans.headers.headerCustomer)
        this.headers.push(this.plans.headers.headerDescription)
        this.headers.push(this.plans.headers.headerDocCount)
        this.headers.push(this.plans.headers.headerStatus)
        this.headers.push(this.plans.headers.headerDueDate)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeadersDates () {
        const headerDueDate = { ...this.plans.headers.headerDueDate }
        const headerSubmittedDate = { ...this.plans.headers.headerSubmittedDate }
        const headerReceivedDate = { ...this.plans.headers.headerReceivedDate }
        const headerCompletedDate = { ...this.plans.headers.headerCompletedDate }
        const headerInvoiceDate = { ...this.plans.headers.headerInvoiceDate }
        const headerPaidDate = { ...this.plans.headers.headerPaidDate }

        headerDueDate.width = '16%'
        headerDueDate.sortable = false
        headerSubmittedDate.width = '16%'
        headerSubmittedDate.sortable = false
        headerReceivedDate.width = '16%'
        headerReceivedDate.sortable = false
        headerCompletedDate.width = '16%'
        headerCompletedDate.sortable = false
        headerInvoiceDate.width = '16%'
        headerInvoiceDate.sortable = false
        headerPaidDate.width = '20%'
        headerPaidDate.sortable = false

        this.detailHeadersDates = []

        this.detailHeadersDates.push(headerDueDate)
        this.detailHeadersDates.push(headerSubmittedDate)
        this.detailHeadersDates.push(headerReceivedDate)
        this.detailHeadersDates.push(headerCompletedDate)
        this.detailHeadersDates.push(headerInvoiceDate)
        this.detailHeadersDates.push(headerPaidDate)
      },

      setupDetailHeadersNames () {
        const headerEstimator = { ...this.plans.headers.headerEstimator }
        const headerSalesperson = { ...this.plans.headers.headerSalesperson }

        headerEstimator.width = '40%'
        headerEstimator.sortable = false
        headerSalesperson.width = '60%'
        headerSalesperson.sortable = false

        this.detailHeadersNames = []

        this.detailHeadersNames.push(headerEstimator)
        this.detailHeadersNames.push(headerSalesperson)
      },

      setupDetailHeadersFooter () {
        this.plans.headers.headerSqFt.width = '20%'
        this.plans.headers.headerAddress.width = '25%'
        this.plans.headers.headerNote.width = '55%'

        this.detailHeadersFooter = []

        this.detailHeadersFooter.push(this.plans.headers.headerSqFt)
        this.detailHeadersFooter.push(this.plans.headers.headerAddress)
        this.detailHeadersFooter.push(this.plans.headers.headerNote)
      },

      initialize () {
        this.$store.dispatch('plan/retrieveAll')
        this.$store.dispatch('estimator/retrieveAllClientEstimators')

        this.setupHeaders()

        this.setupDetailHeadersDates()
        this.setupDetailHeadersNames()
        this.setupDetailHeadersFooter()
      },

      collapseGroups () {
        const table = this.$refs.tableDashboardEstimator
        const keys = Object.keys(table.$vnode.componentInstance.openCache)
        keys.forEach(dataGroup => {
          if (dataGroup) {
            table.$vnode.componentInstance.openCache[dataGroup] = false
          }
        })
      },

      getRowClass () {
        return 'rowClass'
      },

      getIds () {
        const thisEstimator = this.estimators.distinct.filter(thisItem =>
          (thisItem.email ? thisItem.email.toString() : 'null') === (this.users.user.attributes.email ? this.users.user.attributes.email.toString() : ''),
        )

        if (this.getLengthOf(thisEstimator) > 0) {
          if (this.hasObjectAttribute(thisEstimator[0], 'email')) {
            if (thisEstimator[0].email) {
              if (this.hasObjectAttribute(thisEstimator[0], 'key_id')) {
                this.estimators.filtered_ids = []
                this.estimators.filtered_ids.push(thisEstimator[0].key_id)

                this.$store.dispatch('estimator/retrieve')
              }
            }
          }
        }
      },

      refresh_onClick () {
        this.$store.dispatch('plan/retrieveAll')
      },

      dueDateClass (returnCd) {
        return this.getDueDateClass(returnCd)
      },

      displayDate (thisDate) {
        return this.formatDateForTimezone(thisDate)
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.planContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuCopy_onClick (dataItem) {
        if (dataItem && this.customers.selectedItem) {
          if ({}.hasOwnProperty.call(dataItem, 'client_id') && {}.hasOwnProperty.call(this.customers.selectedItem, 'client_id')) {
            if (dataItem.client_id && this.customers.selectedItem.client_id) {
              if (dataItem.client_id.toString() !== this.customers.selectedItem.client_id.toString()) {
                const selectedClient = {
                  id: dataItem.client_id,
                  client_id: dataItem.client_id,
                  description: this.getCustomerName(dataItem.client_id),
                }
                this.$store.dispatch('customer/setSelected', selectedClient)
              }
            }
          }
        }

        this.$store.dispatch('plan/setSelected', dataItem)
        this.$router.push('/components/copy/').catch(() => {})
      },

      row_onDblClick (event, { item }) {
        if (this.$route.name === 'Dashboard') {
          localStorage.setItem('searchCustomerId', item.client_id)
          localStorage.setItem('searchCustomerName', this.getCustomerName(item.client_id))
          localStorage.setItem('searchPlanDescription', item.description)
          this.$router.push('components/plans').catch(() => {})
        }
      },
    },
  }
</script>
