// Pathify
import { make } from 'vuex-pathify'
import { DataStore } from 'aws-amplify'
import { Notification } from '@/models'
import moment from 'moment/moment'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Notification',
  displayAsPlural: 'Notifications',
  insertCount: 0,
  lastInsert: null,
  config: {
    stageEmail: 'estimating@detecsolutionsllc.com',
    prodEmail: 'estimating@detecsolutionsllc.com',
  },
  notifications: {
    data: [],
    isBusy: false,
    empty: {
      id: null,
      email: null,
      bcc: [],
      type: null,
      icon: null,
      subject: null,
      message: null,
      client_id: null,
      plan_id: null,
      route: null,
      read_by: false,
      details: {
        environment: 'Stage',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.notifications.isBusy = isBusy
  },

  push2Data: (state, dataFromDB) => {
    state.notifications.data = []

    for (const currentData of dataFromDB) {
      state.notifications.data.push({ ...currentData })
    }

    state.notifications.isBusy = false
  },

  successfulUpdate: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    // create at 5:15
  },

  create: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      payload.details.environment = rootState.app.build_environment

      await DataStore.save(
        new Notification({
          email: payload.email,
          cc: payload.cc,
          bcc: payload.bcc,
          type: payload.type,
          icon: payload.icon,
          subject: payload.subject,
          message: payload.message,
          client_id: payload.client_id,
          plan_id: payload.plan_id,
          route: payload.route,
          read_by: [],
          details: JSON.stringify(payload.details),
        }),
      )

      console.info(`${thisAction} successful, variant: success`)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  createSubmitMessage: ({ dispatch, rootState }, payload) => {
    if (payload) {
      const newNotification = { ...state.notifications.empty }
      let env

      if (rootState.app.build_environment.toString() === 'Prod') {
        newNotification.email = state.config.prodEmail
        env = ''
      } else {
        newNotification.email = state.config.stageEmail
        env = 'Stage-'
      }

      // TODO: Show notifications to any bcc's and ask Dan who should be bcc'd on Upload/Submit Messages
      newNotification.cc = []
      newNotification.bcc = ['simlow2018@gmail.com']
      newNotification.icon = 'mdi-floor-plan'
      newNotification.subject = `${env}New Plan Submitted from ${rootState.customer.customers.selectedItem.description}`
      newNotification.message = `New Plan available, (${payload.plan_id}) ${payload.description}, submitted by ${rootState.user.users.user.attributes.email} from (${payload.client_id}) ${rootState.customer.customers.selectedItem.description}\n\nPlease schedule for Takeoff.`
      newNotification.client_id = payload.client_id
      newNotification.plan_id = payload.plan_id
      newNotification.route = '/components/plans/'
      newNotification.details.environment = rootState.app.build_environment

      dispatch('create', newNotification)
    } else {
      // TODO: Error?
    }
  },

  createCompletionMessage: async ({ dispatch, rootGetters, rootState }, payload) => {
    if (payload) {
      const newNotification = { ...state.notifications.empty }
      let env

      if (rootState.app.build_environment.toString() === 'Prod') {
        newNotification.email = state.config.prodEmail
        env = ''
      } else {
        newNotification.email = state.config.stageEmail
        env = 'Stage-'
      }

      if ({}.hasOwnProperty.call(payload, 'salesperson_id')) {
        newNotification.email = await rootGetters['salesperson/getEmail'](payload.salesperson_id)
      }

      // Add Additional Salespeople email addresses to cc
      newNotification.cc = []

      for (const currentItem of JSON.parse(payload.additional_salespeople)) {
        const salespersonEmail = await rootGetters['salesperson/getEmail'](currentItem)

        if (salespersonEmail) {
          if (salespersonEmail.length > 0) {
            newNotification.cc.push(salespersonEmail)
          }
        }
      }

      newNotification.icon = 'mdi-floor-plan'
      newNotification.subject = `${env}Plan Completed: ${payload.description}`
      newNotification.message = `Plan (${payload.plan_id}) ${payload.description} is complete and ready for your review.`
      newNotification.client_id = payload.client_id
      newNotification.plan_id = payload.plan_id
      newNotification.route = '/'
      newNotification.details.environment = rootState.app.build_environment

      if (newNotification.email) {
        if (newNotification.email.length > 0) {
          dispatch('create', newNotification)
        }
      }
    } else {
      // TODO: Error?
    }
  },

  retrieveAll: async ({ commit, rootGetters, rootState }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    commit(setIsBusy, true)

    if (rootState.user.users.authState === 'signedin') {
      try {
        let retrieveQuery = false

        if (state.insertCount > 1) {
          const currentTime = moment()
          const nextRetrieve = state.lastInsert.add(30, 'seconds')

          if (currentTime.isAfter(nextRetrieve)) {
            state.insertCount = 0
            state.lastInsert = null
            retrieveQuery = true
          }
        } else {
          retrieveQuery = true
        }

        if (retrieveQuery) {
          const dataFromDB = await DataStore.query(Notification)
          const filteredData = []
          const userEmail = rootGetters['user/getUserEmail']()

          if (dataFromDB !== null) {
            for (const currentData of dataFromDB) {
              if (userEmail !== null) {
                if (userEmail === currentData.email.toString().trim()) {
                  filteredData.push({ ...currentData })
                }
              }
            }
          }

          commit('push2Data', filteredData)
        }
      } catch (e) {
        // TODO: Properly handle exception here
        console.error(`${thisAction} failed`)
        commit(setIsBusy, false)
      }
    }
  },

  update: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(Notification, payload.id)
      const readBy = [...original.read_by]

      if (!readBy.includes(rootState.user.users.user.attributes.email)) {
        readBy.push(rootState.user.users.user.attributes.email)
      }

      await DataStore.save(
        Notification.copyOf(original, updated => {
          updated.read_by = readBy
        }),
      )

      commit('successfulUpdate')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  /**
   * Observe changes from the backend database to Notifications and determine if local state needs to be refreshed
   * @param dispatch  dispatch other actions
   * @param state     this notification state object
   * @param obj       observed object
   * @returns {Promise<void>}
   */
  observe: async ({ dispatch, state }, obj) => {
    const thisAction = `Observe ${state.displayAsSingular}`
    let elements = null
    // TODO: make observe common code to call from any module
    if (obj) {
      if ({}.hasOwnProperty.call(obj, 'opType')) {
        // console.log(`${thisAction} type = ${obj.opType}`, obj)
        switch (obj.opType) {
          case 'INSERT':
            state.insertCount++

            if (state.lastInsert === null) {
              state.lastInsert = moment()
            }

            dispatch('retrieveAll')
            break
          case 'UPDATE':
          case 'DELETE':
            if ({}.hasOwnProperty.call(obj, 'element')) {
              if (obj.element) {
                // console.info(`${thisAction} '${obj.opType}' obj.element =`, obj.element, state.notifications.data)
                if ({}.hasOwnProperty.call(obj.element, 'id')) {
                  elements = state.notifications.data.filter(item => item.id === obj.element.id)
                }
              }
            }
            // console.info(`${thisAction} '${obj.opType}' elements =`, elements)
            if (elements) {
              if (elements.length > 0) {
                const objElement = elements[0]
                // console.info(`${thisAction} '${obj.opType}' element =`, objElement, obj)
                if ({}.hasOwnProperty.call(objElement, 'version') && {}.hasOwnProperty.call(obj.element, '_version')) {
                  // console.info(`${thisAction} '${obj.opType}' `, `${objElement.version} !== ${obj.element._version}`)
                  if (objElement.version !== obj.element._version) {
                    dispatch('retrieveAll')
                  }
                }
              }
            }

            break
        }
      }
    }
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
