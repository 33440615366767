<template>
  <div>
    <v-combobox
      v-if="$attrs.combobox"
      ref="combobox"
      v-model="maintenanceState.selectedItem"
      :label="$attrs.label"
      :items="displayData"
      item-text="description"
      item-value="id"
      dense
      clearable
      :class="getActive_Ind(maintenanceState.selectedItem) ? appHeaders.headerActive.cellClass : appHeaders.headerActive.inactiveClass"
      :prepend-icon="getActive_Ind(maintenanceState.selectedItem) ? null : 'mdi-account-cancel-outline mdi-18px'"
      :loading="maintenanceState.isBusy"
      :rules="[appRules.validOption(maintenanceState.selectedItem, maintenanceState.data, `${displayAsSingular} does not exist in the list.`)]"
      @change="onChange"
      @click:clear="onClear"
    />
    <v-text-field
      v-else
      v-model="internalValue.estimator_id"
      :label="$attrs.label"
      clearable
      class="font-weight-medium text-body-1"
      @blur="onBlur(internalValue)"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Estimator',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingular: get('estimator/displayAsSingular'),
      maintenanceState: get('estimator/estimators'),
      displayData () {
        const allData = [...this.maintenanceState.data]
        const filteredItems = []

        for (const currentItem of allData) {
          if (this.getActive_Ind(currentItem)) {
            filteredItems.push(currentItem)
          }
        }

        return filteredItems
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.estimator_id === oldVal.estimator_id) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.estimator_id === oldVal.estimator_id) return

          this.internalValue = val
          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        const selectedItem = this.getDataById(this.maintenanceState.data, this.internalValue.estimator_id)
        if (selectedItem && selectedItem.length > 0) {
          this.maintenanceState.selectedItem = selectedItem[0]
        } else {
          this.maintenanceState.selectedItem = null
        }
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.estimator_id !== originalData[0].estimator_id) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.emitChange(dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          this.internalValue.estimator_id = dataItem.estimator_id
          this.prepareToUpdate(this.internalValue)
        } else {
          this.internalValue.estimator_id = ''
          this.emitChange(dataItem)
        }
      },

      onClear () {
        this.internalValue.estimator_id = null
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },
    },
  }
</script>
