<template>
  <div>
    <v-combobox
      ref="combobox"
      v-model="internalValue"
      :items="iconData"
      item-text="description"
      item-value="id"
      :disabled="$attrs.disabled"
      :required="$attrs.required"
      dense
      :clearable="isClearable()"
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      @change="onChange"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>

      <template v-slot:item="{ index, item }">
        <v-icon
          class="d-inline mr-1"
          color="secondary"
        >
          {{ item.id }}
        </v-icon>

        <div class="d-inline">
          {{ item.description }}
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import { mdiIcons } from '@/data/constants'

  export default {
    name: 'SelectIcon',

    props: {
      value: {
        type: String,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        selectedIcon: null,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      iconData () {
        const icons = []
        const iconPrefix = 'mdi-'
        let iconName
        let newIcon = {}

        for (const thisIcon of mdiIcons) {
          if ({}.hasOwnProperty.call(thisIcon, 'name')) {
            if (thisIcon.name.startsWith(iconPrefix)) {
              iconName = thisIcon.name
            } else {
              iconName = `${iconPrefix}${thisIcon.name}`
            }

            newIcon = { id: iconName, description: iconName }

            icons.push(newIcon)
          }
        }

        return icons
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.$emit('input', this.internalValue)
        },
      },
      value: {
        handler (val, oldVal) {
          if (val === oldVal) return

          this.internalValue = val

          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        const selectedItem = this.getDataById(this.iconData, this.internalValue)

        if (selectedItem && selectedItem.length > 0) {
          this.selectedIcon = selectedItem[0]
        } else {
          this.selectedIcon = null
        }
      },

      isClearable () {
        let clearable = true

        if (this.$attrs.clearable != null) {
          clearable = this.$attrs.clearable
        }

        return clearable
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          this.internalValue = dataItem.id
        }

        this.emitChange(dataItem)
      },

      prepRules () {
        const rules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          rules.push(this.appRules.required)
        }
        rules.push(this.appRules.validOption(this.selectedIcon, this.iconData, `${this.$attrs.label} does not exist in the list.`))

        if (this.$attrs.rules) {
          for (const parentRule of this.$attrs.rules) {
            rules.push(parentRule)
          }
        }

        return rules
      },
    },
  }
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
