// Pathify
import { make } from 'vuex-pathify'
import _ from 'lodash'
import { getChunk, postChunk } from '@/util/helpers'

const endpoint = 'https://1giaejlmcf.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Archive',
  displayAsPlural: 'Archives',
  archives: {
    all: [],
    data: [],
    plans: [],
    isBusy: false,
    selected: [],
    empty: {
      plan_id: null,
    },
  },
}

function getSuccessMessages (rootState, dataFromDB, actionText) {
  const successMessages = []

  if (dataFromDB.length > 0) {
    const dataResponse = dataFromDB[0]
    const totalPlanRows = dataResponse.total_plan_rows
    const totalDivisionRows = dataResponse.total_division_rows
    const totalAssemblyRows = dataResponse.total_assembly_rows
    const totalAssemblyItemRows = dataResponse.total_assembly_item_rows

    successMessages.push(`${totalPlanRows.toLocaleString()} ${(totalPlanRows === 1 ? rootState.plan.displayAsSingular : rootState.plan.displayAsPlural)} ${actionText} successfully`)
    successMessages.push(`${totalDivisionRows.toLocaleString()} ${(totalDivisionRows === 1 ? rootState.division.displayAsSingular : rootState.division.displayAsPlural)}`)
    successMessages.push(`${totalAssemblyRows.toLocaleString()} ${(totalAssemblyRows === 1 ? rootState.assembly.displayAsSingular : rootState.assembly.displayAsPlural)}`)
    successMessages.push(`${totalAssemblyItemRows.toLocaleString()} ${(totalAssemblyItemRows === 1 ? rootState.assemblyitem.displayAsSingular : rootState.assemblyitem.displayAsPlural)}`)
  }

  return successMessages
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.archives.isBusy = isBusy
  },

  // payload: { buildEnv, dataFromDB }
  push2All: (state, payload) => {
    for (const currentData of payload.dataFromDB) {
      state.archives.all.push({ ...currentData })
    }
  },

  push2Data: (state, dataFromDB) => {
    state.archives.data = []
    state.archives.data = dataFromDB
    state.archives.isBusy = false
  },

  push2Plans: (state, dataFromDB) => {
    state.archives.plans = []
    state.archives.plans = dataFromDB
    state.archives.isBusy = false
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: () => {
    state.archives.data = []
  },

  retrieveAll: ({ commit, dispatch, rootGetters, rootState }) => {
    const thisAction = 'retrieveArchivedPlans'
    commit(setIsBusy, true)

    const chunks2Get = []
    const buildEnv = rootState.app.build_environment
    const actionEndPoint = `${endpoint}/${buildEnv}/${thisAction}`
    let url

    for (const currentData of rootState.customer.customers.data) {
      url = `${actionEndPoint}/${currentData.client_id}`
      chunks2Get.push(getChunk(state.displayAsPlural, url))
    }

    if (chunks2Get.length > 0) {
      // Once all promises are resolved we have successfully gotten all client archives, or display rejected error
      Promise.all(chunks2Get)
        .then(responses => {
          state.archives.all = []

          for (const thisResponse of responses) {
            const data2Push = []

            if (_.isEmpty(thisResponse.error)) {
              for (const currentData of thisResponse.data) {
                currentData.billing_selected = JSON.parse(currentData.billing_selected)
                currentData.total_rate = rootGetters['plan/getTotalRate'](currentData)
                currentData.fixed_pricing = rootGetters['plan/getTotalPlanPrice'](currentData.heated_sqft, currentData.total_rate)

                data2Push.push({ ...currentData })
              }

              commit('push2All', { buildEnv: buildEnv, dataFromDB: data2Push })
            } else {
              console.error(`${thisAction} failed with url: ${url}`)
              commit(setIsBusy, false)
              dispatch('error/setError', thisResponse, { root: true })
              break
            }
          }

          commit(setIsBusy, false)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieveAllPlans: ({ commit, dispatch, rootGetters, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveArchivedPlans'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          const data2Push = []

          if (_.isEmpty(jsonResponse.error)) {
            for (const currentData of jsonResponse.data) {
              currentData.billing_selected = JSON.parse(currentData.billing_selected)
              currentData.total_rate = rootGetters['plan/getTotalRate'](currentData)
              currentData.fixed_pricing = rootGetters['plan/getTotalPlanPrice'](currentData.heated_sqft, currentData.total_rate)

              data2Push.push({ ...currentData })
            }

            commit('push2Plans', data2Push)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  archivePlans: ({ commit, dispatch, rootState }, payload) => {
    const thisTitle = `${rootState.plan.displayAsPlural} Archived`
    const thisAction = 'archivePlan'
    commit(setIsBusy, true)

    // Sort payload by client_id
    const workPayload = [...payload]
    const sortedPayload = workPayload.sort((a, b) => {
      const clientA = a.client_id
      const clientB = b.client_id

      if (clientA < clientB) return -1
      if (clientA > clientB) return 1

      // client_ids match
      return 0
    })

    const selectedPlans = []
    for (const thisItem of sortedPayload) {
      selectedPlans.push({
        client_id: thisItem.client_id,
        plan_id: thisItem.plan_id,
      })
    }

    if (selectedPlans.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/ALL`

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(selectedPlans.length / 5)
      const maxSize = Math.ceil(selectedPlans.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Post = []
      let items2Post = []

      for (const currentItem of selectedPlans) {
        items2Post.push(currentItem)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Post.push(postChunk(thisTitle, url, [...items2Post]))

          chunkSize = 0
          items2Post = []
        }
      }

      if (chunkSize > 0) {
        chunks2Post.push(postChunk(state.displayAsPlural, url, [...items2Post]))
      }

      let toastColor = 'success'
      const toastMessage = 'archived successfully'
      const responseData = {
        total_plan_rows: 0,
        total_division_rows: 0,
        total_assembly_rows: 0,
        total_assembly_item_rows: 0,
      }

      if (chunks2Post.length > 0) {
        // Once all promises are resolved we have successfully archived, or displayed rejected error
        Promise.all(chunks2Post)
          .then(responses => {
            let result
            let messageTimeout = 6000

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)

                if (thisResponse.data) {
                  if (thisResponse.data.length > 0) {
                    result = thisResponse.data[0]

                    if ({}.hasOwnProperty.call(result, 'total_plan_rows')) {
                      responseData.total_plan_rows += result.total_plan_rows
                    }

                    if ({}.hasOwnProperty.call(result, 'total_division_rows')) {
                      responseData.total_division_rows += result.total_division_rows
                    }

                    if ({}.hasOwnProperty.call(result, 'total_assembly_rows')) {
                      responseData.total_assembly_rows += result.total_assembly_rows
                    }

                    if ({}.hasOwnProperty.call(result, 'total_assembly_item_rows')) {
                      responseData.total_assembly_item_rows += result.total_assembly_item_rows
                    }
                  }
                }

                messageTimeout += 2000
              } else {
                console.error(`${thisAction} failed`, thisResponse.error)
                commit(setIsBusy, false)
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }

            const successMessages = getSuccessMessages(rootState, [responseData], 'archived')

            dispatch('user/showToast',
              {
                title: thisTitle,
                messages: successMessages,
                variant: toastColor,
                timeout: messageTimeout,
              },
              { root: true },
            )

            dispatch('plan/retrieveAll', null, { root: true })
            dispatch('plan/retrieve', null, { root: true })

            commit(setIsBusy, false)
          })
          .catch(() => {
            console.error(`${thisAction} failed`)
            commit(setIsBusy, false)
            // dispatch('error/setError', { name: thisAction, details: error }, { root: true })

            // TODO: This is a temp fix for long running/time out issues with this service
            const fullMessage = `The ${state.displayAsSingular} process is running longer than expected due to large ${rootState.plan.displayAsPlural} or the number of selected ${rootState.plan.displayAsPlural}. Please check the results in a few minutes by refreshing the ${rootState.plan.displayAsPlural}.`

            dispatch('user/showToast',
                {
                  title: `${rootState.plan.displayAsPlural} Not Archived`,
                  messages: [fullMessage],
                  variant: 'warning',
                },
                { root: true },
            )
          })
      } else {
        console.error(`${thisAction} failed to ${state.displayAsSingular.toLowerCase()} due to empty chunks`)
        commit(setIsBusy, false)

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} ${rootState.plan.displayAsPlural}`,
            messages: [`No ${rootState.plan.displayAsPlural} were archived`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } else {
      commit(setIsBusy, false)
    }
  },

  restorePlans: ({ commit, dispatch, rootState }) => {
    const thisAction = 'restorePlan'
    commit(setIsBusy, true)

    const selectedCustomerId = (typeof rootState.customer.customers.selectedItem.client_id === 'undefined' ? null : rootState.customer.customers.selectedItem.client_id)

    const selectedPlans = []
    for (const thisItem of state.archives.selected) {
      selectedPlans.push({ plan_id: thisItem.plan_id })
    }

    if (selectedCustomerId && selectedPlans.length > 0) {
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${selectedCustomerId}`

      const options = {
        method: 'post',
        body: JSON.stringify(selectedPlans),
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2Data', jsonResponse.data)

            const toastColor = 'success'
            const successMessages = getSuccessMessages(rootState, jsonResponse.data, 'restored')

            dispatch('user/showToast',
              {
                title: `${rootState.plan.displayAsPlural} Restored`,
                messages: successMessages,
                variant: toastColor,
                timeout: 8000,
              },
              { root: true },
            )
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .then(() => {
          dispatch('retrieveAllPlans')
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
    }
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
