// Pathify
import { make } from 'vuex-pathify'
import { getChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://bdoxfezzbj.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Estimator',
  displayAsPlural: 'Estimators',
  estimators: {
    ids: [],
    all: [],
    data: [],
    originalData: [],
    distinct: [],
    isBusy: false,
    selected: [],
    selectedItem: { key_id: '', id: '', name: '', description: '' },
    filtered_ids: [],
    empty: {
      key_id: null,
      id: null,
      estimator_id: null,
      client_id: null,
      active_ind: true,
      description: null,
      name: null,
      note: '',
      email: null,
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerName: {
        text: 'Name',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerEmail: {
        text: 'Email',
        align: 'start',
        value: 'email',
        sortable: true,
        filterable: true,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.estimators.isBusy = isBusy
  },

  setSelected: (state, payload) => {
    state.estimators.selected = []
    state.estimators.selectedItem = { ...payload }
  },

  push2Ids: (state, dataFromDB) => {
    for (const currentData of dataFromDB) {
      state.estimators.ids.push({ ...currentData })
    }
  },

  push2All: (state, dataFromDB) => {
    for (const currentData of dataFromDB) {
      state.estimators.all.push({ ...currentData })
    }
  },

  push2Data: (state, dataFromDB) => {
    state.estimators.data = []
    state.estimators.data = dataFromDB

    // Get a copy of the original data, not a pointer to it
    state.estimators.originalData = []
    for (const currentData of dataFromDB) {
      state.estimators.originalData.push({ ...currentData })
    }

    state.estimators.selected = []

    state.estimators.isBusy = false
  },

  push2Distinct: (state) => {
    state.estimators.distinct = []

    const emailsAdded = []
    let currentEmail = ''

    for (const currentData of state.estimators.all) {
      if ({}.hasOwnProperty.call(currentData, 'active_ind') && {}.hasOwnProperty.call(currentData, 'email')) {
        if (currentData.active_ind && currentData.email) {
          currentEmail = currentData.email.toString().trim()

          if (currentEmail.length > 0 && !emailsAdded.includes(currentEmail)) {
            emailsAdded.push(currentEmail)
            state.estimators.distinct.push(currentData)
          }
        }
      }
    }

    state.estimators.isBusy = false
  },

  successfulRetrieveAll: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setSelected: ({ commit, dispatch }, payload) => {
    if (payload) {
      commit('setSelected', payload)
    } else {
      state.estimators.selectedItem = { key_id: '', id: '', name: '', description: '', active_ind: true }
    }
  },

  retrieveAllClientEstimators: ({ commit, dispatch, rootState }) => {
    const thisAction = 'retrieveAllEstimator'
    commit(setIsBusy, true)

    const chunks2Get = []
    const buildEnv = rootState.app.build_environment
    const actionEndPoint = `${endpoint}/${buildEnv}/${thisAction}`
    let url

    for (const currentData of rootState.customer.customers.data) {
      url = `${actionEndPoint}/${currentData.client_id}`
      chunks2Get.push(getChunk(state.displayAsPlural, url))
    }

    if (chunks2Get.length > 0) {
      // Once all promises are resolved we have successfully gotten all client estimators, or display rejected error
      Promise.all(chunks2Get)
        .then(responses => {
          state.estimators.all = []

          for (const thisResponse of responses) {
            if (_.isEmpty(thisResponse.error)) {
              commit('push2All', thisResponse.data)
            } else {
              console.error(`${thisAction} failed with url: ${url}`)
              commit(setIsBusy, false)
              dispatch('error/setError', thisResponse, { root: true })
              break
            }
          }

          commit(setIsBusy, false)
          commit('successfulRetrieveAll')
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieveDistinct: ({ commit }) => {
    commit(setIsBusy, true)
    commit('push2Distinct')
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllEstimator'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2Data', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieve: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'retrieveEstimator'
    commit(setIsBusy, true)

    let thisEstimator = null
    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const chunks2Get = []
    let url

    for (const currentData of state.estimators.filtered_ids) {
      thisEstimator = state.estimators.distinct.filter(thisItem => (thisItem.key_id ? thisItem.key_id.toString() : '') === currentData)

      if (thisEstimator) {
        if (thisEstimator.length > 0) {
          url = `${actionEndPoint}/${thisEstimator[0].client_id}/${thisEstimator[0].email}`
          chunks2Get.push(getChunk(state.displayAsPlural, url))
        }
      }
    }

    state.estimators.ids = []

    if (chunks2Get.length > 0) {
      // Once all promises are resolved we have successfully gotten all of the estimator's Ids, or display rejected error
      Promise.all(chunks2Get)
        .then(responses => {
          for (const thisResponse of responses) {
            if (_.isEmpty(thisResponse.error)) {
              commit('push2Ids', thisResponse.data)
            } else {
              console.error(`${thisAction} failed with url: ${url}`)
              commit(setIsBusy, false)
              dispatch('error/setError', thisResponse, { root: true })
              break
            }
          }

          commit(setIsBusy, false)
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updateEstimator'
    commit(setIsBusy, true)

    payload.estimator_id = payload.id
    payload.name = payload.description

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.estimators.selectedItem && {}.hasOwnProperty.call(state.estimators.selectedItem, 'id')) {
            if (payload.estimator_id === state.estimators.selectedItem.id) {
              dispatch('setSelected', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.name}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.estimators.selected.length > 0) {
      const thisAction = 'deleteEstimator'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${state.estimators.selected[0].client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.estimators.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.estimators.selected.length > 1) {
        toastPrefix = `${state.estimators.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.estimators.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.estimators.selectedItem && {}.hasOwnProperty.call(state.estimators.selectedItem, 'id')) {
              for (const currentData of state.estimators.selected) {
                if (currentData.estimator_id === state.estimators.selectedItem.id) {
                  dispatch('setSelected', state.estimators.data[0])
                  break
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.name}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
           */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },
}

const getters = {
  filterEstimatorsPlans: (state) => (payload) => {
    const planData = []
    const estimatorIds = (state.estimators.ids ? [...state.estimators.ids] : [])
    let estimatorId
    let clientsEstimator

    for (const currentItem of payload.plans) {
      estimatorId = null
      clientsEstimator = estimatorIds.filter(data => data.client_id.toString() === currentItem.client_id.toString())

      if (clientsEstimator) {
        if (clientsEstimator.length > 0) {
          estimatorId = clientsEstimator[0].estimator_id
        }
      }

      // If both values are not null, let's compare them
      if (estimatorId && currentItem.estimator_id) {
        // If this estimator is assigned to this plan, let's add it
        if (estimatorId.toString().trim() === currentItem.estimator_id.toString().trim()) {
          // Check status filtering
          if (currentItem.status_cd) {
            if (payload.status_codes.includes(currentItem.status_cd) &&
              payload.client_ids.includes(currentItem.client_id)) {
              planData.push(currentItem)
            }
          } else {
            // If null status_cd still push it
            planData.push(currentItem)
          }
        }
      }
    }

    return planData
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
