<template>
  <v-app>
    <div
      v-if="users.authState !== 'signedin'"
      :style="{
        'background-image': 'url(' + require('@/assets/BLUE-PRINT.jpg') + ')',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }"
    >
      <v-alert
        v-if="alert.show"
        shaped
        prominent
        :icon="alert.icon"
        :type="alert.variant"
      >
        <h2>
          {{ alert.title }}
        </h2>
        <v-divider />
        <div
          v-for="(thisMessage, index) in alert.messages"
          :key="index"
          class="pt-1"
        >
          {{ thisMessage }}
        </div>
      </v-alert>

      <amplify-authenticator
        username-alias="email"
      >
        <amplify-sign-in
          slot="sign-in"
          :header-text="`${app_name} - Sign In`"
          :hide-sign-up="true"
          :username-alias="signInConfig.usernameAlias"
          :formFields="signInConfig.formFields"
        >
          <div slot="federated-buttons"></div>
        </amplify-sign-in>

        <amplify-forgot-password
          slot="forgot-password"
          :header-text="`${app_name} - Reset your password`"
          :username-alias="signInConfig.usernameAlias"
        />
      </amplify-authenticator>
    </div>
    <div v-else>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
  </v-app>
</template>

<style lang="scss">
:root {
  --amplify-primary-color: #9A2A2A;
  --amplify-primary-contrast: var(–amplify-white);
  --amplify-primary-tint: #9A2A2A;
  --amplify-primary-shade: #9A2A2A;
  --amplify-secondary-color: #152939;
  --amplify-secondary-contrast: var(–amplify-white);
  --amplify-secondary-tint: #31465f;
  --amplify-secondary-shade: #1F2A37;
  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: var(–amplify-white);
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537BE5;
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #ffffff;
  --amplify-red: #dd3f5b;
  /*

  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: #ffac31;
  --amplify-primary-shade: #e88b01;
  */
}
</style>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import { onAuthUIStateChange } from '@aws-amplify/ui-components'
  import { get } from 'vuex-pathify'

  export default {
    name: 'App',
    metaInfo: {
      title: 'Detec',
      titleTemplate: '%s | Quick Quote',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },

    components: {
    },

    data () {
      return {
        alert: {
          icon: 'mdi-shield-lock-outline',
          show: false,
          messages: '',
          title: '',
          variant: 'error',
        },
        unsubscribeAuth: undefined,
        signInConfig: {
          usernameAlias: 'email',
          formFields: [
            {
              type: 'email',
              label: 'Email',
              placeholder: 'Email',
              hint: 'Enter Your Email',
              required: true,
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Enter Your Password',
              hint: 'Password',
              required: true,
            },
          ],
        },
      }
    },

    computed: {
      users: get('user/users'),
      app_name: get('app/app_name'),
    },

    created () {
      this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        this.$store.dispatch('user/setAuth', { authState: authState, authData: authData })
      })

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'user/successfulLogin':
            this.alert.show = false
            break
          case 'user/showToast':
            if (mutation.payload.title === 'Unauthorized') {
              this.alert.title = mutation.payload.title
              this.alert.messages = mutation.payload.messages
              this.alert.variant = mutation.payload.variant
              this.alert.show = true
            }
            break
        }
      })
    },

    beforeDestroy () {
      this.unsubscribeAuth()
      this.unsubscribe()
    },
  }
</script>
