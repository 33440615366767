var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.planData,"item-key":"client_plan_id","sort-by":"client_plan_id","show-select":_vm.users.isAdmin,"loading":_vm.plans.isBusy,"footer-props":{'items-per-page-options':[-1]},"options":_vm.options,"dense":"","hide-default-footer":""},on:{"contextmenu:row":_vm.openContextMenu},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" No "+_vm._s(_vm.displayAsSingular)+" Data Available ")])]},proxy:true},{key:"loading",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" Loading "+_vm._s(_vm.displayAsSingular)+" Summary... Please wait ")])]},proxy:true},{key:"header.estimator_name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(!_vm.users.isEstimator)?_c('estimator-filter',{attrs:{"header":header},model:{value:(_vm.estimators.filtered_ids),callback:function ($$v) {_vm.$set(_vm.estimators, "filtered_ids", $$v)},expression:"estimators.filtered_ids"}}):_vm._e()]}},{key:"header.client_id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('customer-filter',{attrs:{"items":_vm.customers.data,"header":header},model:{value:(_vm.customers.filtered_ids),callback:function ($$v) {_vm.$set(_vm.customers, "filtered_ids", $$v)},expression:"customers.filtered_ids"}})]}},{key:"header.finish_date",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('date-filter',{attrs:{"header":header},model:{value:(_vm.filtered_completed_dates),callback:function ($$v) {_vm.filtered_completed_dates=$$v},expression:"filtered_completed_dates"}})]}},{key:"header.status_cd",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('status-filter',{attrs:{"items":_vm.plans.DashboardStatusCode,"header":header},model:{value:(_vm.plans.filter_report_status_codes),callback:function ($$v) {_vm.$set(_vm.plans, "filter_report_status_codes", $$v)},expression:"plans.filter_report_status_codes"}})]}},{key:"header.invoice_date",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('date-filter',{attrs:{"header":header},model:{value:(_vm.filtered_invoiced_dates),callback:function ($$v) {_vm.filtered_invoiced_dates=$$v},expression:"filtered_invoiced_dates"}})]}},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCustomerName(item.client_id))+" ")]}},{key:"item.finish_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(item.finish_date))+" ")]}},{key:"item.status_cd",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline font-weight-medium text-body-1",style:(("" + (_vm.getStatusColor(item.status_cd, _vm.$vuetify.theme.dark))))},[_vm._v(" "+_vm._s((item.status_cd ? _vm.plans.StatusCode[item.status_cd] : ''))+" ")])]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(item.invoice_date))+" ")]}},{key:"item.paid_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayDate(item.paid_date))+" ")]}},{key:"item.total_rate",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.total_rate)+" ")]}},{key:"item.fixed_pricing",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.fixed_pricing)+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[(_vm.users.isAdmin)?_c('th'):_vm._e(),_vm._l((_vm.headers),function(thisHeader,i){return _c('th',{key:i,class:_vm.getFooterClass(thisHeader)},[_vm._v(" "+_vm._s(_vm.getFooterText(thisHeader))+" ")])})],2)]},proxy:true}]),model:{value:(_vm.plans.selected),callback:function ($$v) {_vm.$set(_vm.plans, "selected", $$v)},expression:"plans.selected"}}),_c('context-menu',{ref:"reportContextMenu",attrs:{"maintenance-items":_vm.plans,"display-as-singular":_vm.displayAsSingular,"display-as-plural":_vm.displayAsPlural,"show-archive-option":false,"show-new-option":false,"show-edit-option":false,"show-paid-date-option":true,"show-delete-option":false},on:{"payitem":_vm.menuPay_onClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }