<template>
  <div>
    <v-combobox
      v-if="$attrs.combobox"
      ref="combobox"
      v-model="maintenanceState.selectedItem"
      :label="$attrs.label"
      :items="maintenanceState.data"
      item-text="description"
      item-value="id"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :loading="maintenanceState.isBusy"
      :rules="[appRules.validOption(maintenanceState.selectedItem, maintenanceState.data, `${displayAsSingular} does not exist in the list.`)]"
      @change="onChange"
      @click:clear="onClear"
    />
    <v-text-field
      v-else
      v-model="internalValue.salesperson_id"
      :label="$attrs.label"
      clearable
      class="font-weight-medium text-body-1"
      @blur="onBlur(internalValue)"
    />
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Salesperson',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        new_description: '',
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      displayAsSingular: get('salesperson/displayAsSingular'),
      maintenanceState: get('salesperson/salespeople'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.salesperson_id === oldVal.salesperson_id) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.salesperson_id === oldVal.salesperson_id) return

          this.internalValue = val
          this.load()
        },
        deep: true,
      },
    },

    mounted () {
      this.load()
    },

    methods: {
      load () {
        this.$store.dispatch('salesperson/setData', this.internalValue.client_id)

        this.$nextTick(() => {
          const selectedItem = this.getDataById(this.maintenanceState.data, this.internalValue.salesperson_id)
          if (selectedItem && selectedItem.length > 0) {
            this.maintenanceState.selectedItem = selectedItem[0]
          } else {
            this.maintenanceState.selectedItem = null
          }
        })
      },

      getNewDescription () {
        return this.new_description
      },

      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)

          if (originalData.length > 0) {
            if (dataItem.salesperson_id !== originalData[0].salesperson_id) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }

        this.$nextTick(() => {
          this.emitChange(dataItem)
        })
      },

      onChange (dataItem) {
        if (dataItem) {
          if ({}.hasOwnProperty.call(dataItem, 'salesperson_id')) {
            this.internalValue.salesperson_id = dataItem.salesperson_id
          } else {
            this.internalValue.salesperson_id = ''
            this.new_description = dataItem
          }

          this.prepareToUpdate(this.internalValue)
        } else {
          this.internalValue.salesperson_id = ''
          this.emitChange(dataItem)
        }
      },

      onClear () {
        this.internalValue.salesperson_id = null
        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)
      },
    },
  }
</script>
