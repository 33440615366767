var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"maintenanceTableBilling",staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.maintenanceState.data,"sort-by":"sort_order","show-select":"","loading":_vm.maintenanceState.isBusy,"search":_vm.search},on:{"contextmenu:row":_vm.openContextMenu},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" No "+_vm._s(_vm.displayAsPlural)+" Available ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('h3',{staticClass:"primary--text"},[_vm._v(" No matching "+_vm._s(_vm.displayAsSingular)+" data found containing '"+_vm._s(_vm.search)+"' ")])]},proxy:true},{key:"loading",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" Loading "+_vm._s(_vm.displayAsPlural)+"... Please wait ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"10"}},[(_vm.maintenanceState.data.length > 0)?_c('v-text-field',{staticClass:"pl-10 pr-10",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},on:{"click":_vm.input_onClick},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0 ma-0 text-right",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-btn',{staticClass:"ml-0 mt-1 mr-1 mb-0 pt-4 pb-4",attrs:{"small":"","color":"primary","disabled":_vm.disableNew},on:{"click":_vm.menuNew_onClick}},[_vm._v(" New "+_vm._s(_vm.displayAsSingular)+" ")])],1)],1)],1)]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","value":isSelected},on:{"input":function($event){return select($event)}}})]}},{key:"item.sort_order",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',{staticClass:"mt-4 mb-4"},[_c('sort-order',{ref:"editedSortOrder",attrs:{"label":"","originalData":_vm.maintenanceState.originalData,"clearable":false,"rules":[_vm.appRules.required],"autofocus":""},on:{"change":function($event){_vm.disableEdit = _vm.getDisableEdit()}},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1):_c('span',[_vm._v(_vm._s(item.sort_order))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',{staticClass:"mt-4 mb-4"},[_c('string-value',{ref:"editedValue",attrs:{"label":"","originalData":_vm.maintenanceState.originalData,"clearable":false,"rules":[_vm.appRules.required]},on:{"change":function($event){_vm.disableEdit = _vm.getDisableEdit()}},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1):_c('span',[_vm._v(_vm._s(item.value))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',{staticClass:"mt-4 mb-4"},[_c('description',{ref:"editedDescription",attrs:{"label":"","originalData":_vm.maintenanceState.originalData,"clearable":false,"rules":[_vm.appRules.required]},on:{"change":function($event){_vm.disableEdit = _vm.getDisableEdit()}},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1):_c('span',[_c('div',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('v-icon',{staticClass:"d-inline",attrs:{"color":"secondary","small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',{staticClass:"mt-4 mb-4"},[_c('rate',{ref:"editedRate",attrs:{"label":"","originalData":_vm.maintenanceState.originalData,"clearable":false,"rules":[_vm.appRules.required]},on:{"change":function($event){_vm.disableEdit = _vm.getDisableEdit()}},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})],1):_c('span',[_vm._v(_vm._s(item.rate))])]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',{staticClass:"mt-4 mb-4"},[_c('select-icon',{ref:"editedIcon",attrs:{"label":"","clearable":false},on:{"change":_vm.icon_onChange},model:{value:(_vm.editedItem.icon),callback:function ($$v) {_vm.$set(_vm.editedItem, "icon", $$v)},expression:"editedItem.icon"}})],1):_c('span',[_vm._v(_vm._s(item.icon))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"accent","disabled":_vm.disableEdit},on:{"click":function($event){return _vm.saveEdit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-save ")])]}}],null,true)},[_c('span',[_vm._v("Save")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.cancelEdit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-window-close ")])]}}],null,true)},[_c('span',[_vm._v("Cancel")])])],1):_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.menuEdit_onClick(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])],1)]}}]),model:{value:(_vm.maintenanceState.selected),callback:function ($$v) {_vm.$set(_vm.maintenanceState, "selected", $$v)},expression:"maintenanceState.selected"}}),_c('delete-dialog',{ref:"maintenanceDeleteDialog",attrs:{"maintenance-items":_vm.maintenanceState,"display-as-singular":_vm.displayAsSingular,"display-as-plural":_vm.displayAsPlural,"use-customer-detail":false},on:{"delete":function($event){return _vm.item_onDelete()},"cancel":function($event){return _vm.cancelDelete()}}}),_c('context-menu',{ref:"maintenanceContextMenu",attrs:{"maintenance-items":_vm.maintenanceState,"display-as-singular":_vm.displayAsSingular,"display-as-plural":_vm.displayAsPlural},on:{"newitem":_vm.menuNew_onClick,"edititem":_vm.menuEdit_onClick,"deleteitem":_vm.menuDelete_onClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }