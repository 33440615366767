<template>
  <div>
    <div
      v-if="!editable"
      class="ma-0 pa-0"
    >
      <a
        v-if="internalValue.url"
        :href="internalValue.url"
        target="_blank"
        class="font-weight-medium text-body-1 align-start"
      >
        {{ $attrs.label }}
      </a>
    </div>

    <v-text-field
      v-else
      ref="textField"
      v-model="internalValue.url"
      :autofocus="$attrs.autofocus"
      :disabled="$attrs.disabled"
      :required="$attrs.required"
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="prepRules()"
      :maxlength="($attrs.maxlength ? $attrs.maxlength : 255)"
      prepend-icon="mdi-web"
      @blur="onBlur(internalValue)"
      @change="onChange(internalValue)"
      @click="input_onClick"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'UrlValue',

    props: {
      value: {
        type: Object,
        default: null,
      },
      editable: {
        type: Boolean,
        default: false,
        required: true,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.url === oldVal.url) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.url === oldVal.url) return

          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)

          if (originalData.length > 0) {
            if (dataItem.url !== originalData[0].url) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
      },

      onBlur (dataItem) {
        this.prepareToUpdate(dataItem)

        this.$nextTick(() => {
          this.$emit('blur', dataItem)
        })
      },

      onChange (dataItem) {
        this.prepareToUpdate(dataItem)

        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      prepRules () {
        const allRules = []

        if (this.$attrs.required && !this.$attrs.disabled) {
          allRules.push(this.appRules.required)
        }
        allRules.push(this.appRules.isURL)

        if (this.$attrs.rules) {
          for (const parentRule of this.$attrs.rules) {
            allRules.push(parentRule)
          }
        }

        return allRules
      },
    },
  }
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
</style>
