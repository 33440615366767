<template>
  <v-container
    class="pl-1 pt-2 pr-1 pb-1"
  >
    <!-- Value To Replace -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
      >
        <h4
          class="primary--text"
        >
          {{ headerValue2Replace.text }}
        </h4>
      </v-col>
    </v-row>
    <!-- Id To Replace -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="10"
        offset-sm="1"
      >
        <combo-box
          ref="editedId"
          v-model="internalValue.value2Replace"
          :label="`${displayAsSingularDeliveryLoad}`"
          :items="takeoffs.deliveryLoads"
          :isBusy="takeoffs.isBusy"
          :displayAsSingular="displayAsSingular"
          @change="field_onChange"
        />
      </v-col>
    </v-row>
    <!-- New Value -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
      >
        <h4
          class="primary--text"
        >
          {{ headerNewValue.text }}
        </h4>
      </v-col>
    </v-row>
    <!-- New Category -->
    <v-row
      no-gutters
      class="mb-3"
    >
      <v-col
        cols="12"
        sm="6"
        offset-sm="1"
      >
        <delivery-load
          :key="renderKey"
          ref="editedDeliveryLoad"
          v-model="internalValue.newValue"
          :label="`New ${displayAsSingularDeliveryLoad}`"
          :originalData="[]"
          :combobox="true"
          @change="deliveryLoad_onChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import ComboBox from '@/components/maintenance/controls/ComboBox'
  import DeliveryLoad from '@/components/maintenance/controls/DeliveryLoad'

  export default {
    name: 'GlobalReplaceParts',

    components: {
      ComboBox,
      DeliveryLoad,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
      headerValue2Replace: {
        type: Object,
        default: null,
      },
      headerNewValue: {
        type: Object,
        default: null,
      },
      displayAsSingular: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        internalValue: this.value,
        renderKey: 0,
      }
    },

    computed: {
      takeoffs: get('takeoff/takeoffs'),
      displayAsSingularDeliveryLoad: get('deliveryload/displayAsSingular'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      getNewValue () {
        return {
          delivery_load_id: null,
          delivery_load_description: null,
        }
      },

      forceRerender () {
        this.renderKey += 1
      },

      field_onChange () {
        this.$emit('change', this.internalValue)
      },

      getDisableEdit () {
        let disabled = true

        if (this.$refs.editedId) {
          disabled = this.$refs.editedId.$refs.combobox.hasError
        }

        if (!disabled) {
          if (this.$refs.editedDeliveryLoad) {
            disabled = this.$refs.editedDeliveryLoad.$refs.combobox.hasError
          } else {
            disabled = true
          }
        }

        if (this.internalValue.newValue) {
          if (!disabled) {
            if ({}.hasOwnProperty.call(this.internalValue.newValue, 'delivery_load_id')) {
              disabled = (this.internalValue.newValue.delivery_load_id ? this.internalValue.newValue.delivery_load_id.length <= 0 : true)
            } else {
              disabled = true
            }
          }
        } else {
          disabled = true
        }

        return disabled
      },

      saveEdit () {
        this.internalValue.id = this.internalValue.value2Replace.id
        this.internalValue.description = this.internalValue.value2Replace.description
        this.internalValue.newValue_description = `${this.internalValue.newValue.delivery_load_id} - ${this.internalValue.newValue.delivery_load_description}`
      },

      deliveryLoad_onChange (dataItem) {
        if (!dataItem) {
          this.internalValue.newValue.delivery_load_id = null
          this.internalValue.newValue.delivery_load_description = null
        }

        this.field_onChange()
      },
    },
  }
</script>

<style scoped>

</style>
