// Pathify
import { make } from 'vuex-pathify'
import { DataStore } from '@aws-amplify/datastore'
import { SystemMessages } from '@/models'
import moment from 'moment'

const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'System Message',
  displayAsPlural: 'System Messages',
  systemMessages: {
    all: [],
    data: [],
    originalData: [],
    isBusy: false,
    selected: [],
    selectedItem: { id: '', type: '', icon: '', message: '', version: 0 },
    empty: {
      id: '',
      type: 'warning',
      icon: 'mdi-information',
      message: '',
      start: '',
      end: '',
      updated: false,
      version: 0,
    },
    headers: {
      headerType: {
        text: 'Type',
        align: 'start',
        value: 'type',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerIcon: {
        text: 'Icon',
        align: 'start',
        value: 'icon',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerMessage: {
        text: 'Message',
        align: 'start',
        value: 'message',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerStart: {
        text: 'Start',
        align: 'start',
        value: 'start',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
      headerEnd: {
        text: 'End',
        align: 'start',
        value: 'end',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
        cellClass: 'font-weight-medium text-body-1',
      },
    },
    TypeCode: {
      warning: 'Warning',
      info: 'Info',
      success: 'Success',
      error: 'Error',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  /**
   * Mutate the busy switch to indicate if the state is busy
   * @param state   this systemMessages state object
   * @param isBusy  data is in CRUD transit
   */
  setIsBusy: (state, isBusy) => {
    state.systemMessages.isBusy = isBusy
  },

  /**
   * Mutate the data and selected state arrays to empty
   * @param state this systemMessages state object
   */
  setEmpty: (state) => {
    state.systemMessages.selected = []
    state.systemMessages.data = []
  },

  /**
   * Mutate the selectedItem to be the payload object
   * @param state   this systemMessages state object
   * @param payload selected systemMessage object
   */
  setSelected: (state, payload) => {
    state.systemMessages.selected = []
    state.systemMessages.selectedItem = { ...payload }
  },

  /**
   * Mutate the all and data state arrays with the retrieved data
   * @param state       this systemMessages state object
   * @param dataFromDB  data retrieved from the database
   */
  push2All: (state, dataFromDB) => {
    state.systemMessages.all = []
    state.systemMessages.data = []

    let newItem

    for (const currentData of dataFromDB) {
      newItem = { ...state.systemMessages.empty }

      newItem.id = currentData.id
      newItem.type = currentData.type
      newItem.icon = currentData.icon
      newItem.message = currentData.message
      newItem.start = currentData.start
      newItem.end = currentData.end
      newItem.version = currentData._version

      state.systemMessages.all.push(newItem)
      state.systemMessages.originalData.push(newItem)

      const messageStart = moment(currentData.start)
      const messageEnd = moment(currentData.end)

      if (messageStart !== null && messageEnd !== null) {
        if (moment().isAfter(messageStart) && moment().isBefore(messageEnd)) {
          state.systemMessages.data.push({ ...currentData })
        }
      }
    }

    state.systemMessages.selected = []

    state.systemMessages.isBusy = false
  },

  /**
   * Mutate the all array by removing the requested object
   * @param state   this systemMessages state object
   * @param payload systemMessage object to remove
   */
  remove: (state, payload) => {
    // Filter out the object with the specified ID
    const cleanData = state.systemMessages.all.filter(item => item.id !== payload.id)

    state.systemMessages.all = [...cleanData]
  },

  /**
   * A System Message has been successfully created
   * @param state this systemMessages state object
   */
  successfulCreate: (state) => {},

  /**
   * A System Message has been successfully updated
   * @param state this systemMessages state object
   */
  successfulUpdate: (state) => {},

  /**
   * A System Message has been successfully deleted
   * @param state this systemMessages state object
   */
  successfulDelete: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {

  },

  /**
   * Set the data state and selected state to empty array
   * @param commit  commit to mutations
   */
  setEmpty: ({ commit }) => {
    commit('setEmpty')
  },

  /**
   * Set the selectedItem to the payload object
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   selected systemMessage object
   */
  setSelected: ({ commit, dispatch }, payload) => {
    let selectedItem

    if (payload) {
      selectedItem = { ...payload }
    } else {
      selectedItem = { ...state.systemMessages.empty }
    }

    commit('setSelected', selectedItem)
  },

  /**
   * Create a System Message in the backend database
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   systemMessage object to create
   * @returns {Promise<void>}
   */
  create: async ({ commit, dispatch }, payload) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      await DataStore.save(
        new SystemMessages({
          type: payload.type,
          icon: payload.icon,
          message: payload.message,
          start: payload.start,
          end: payload.end,
        }),
      )
      // console.info(`${thisAction} successful, variant: success`)

      commit('successfulCreate')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  /**
   * Retrieve all System Messages and store in state
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @returns {Promise<void>}
   */
  retrieveAll: async ({ commit, dispatch }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    commit(setIsBusy, true)

    try {
      const dataFromDB = await DataStore.query(SystemMessages)

      commit('push2All', dataFromDB)
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  /**
   * Update a System Message in the backend database
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @param payload   systemMessage object to update
   * @returns {Promise<void>}
   */
  update: async ({ commit, dispatch }, payload) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(SystemMessages, payload.id)

      if (original) {
        // We found the data in the DB to update
        await DataStore.save(
          SystemMessages.copyOf(original, updated => {
            updated.type = payload.type
            updated.icon = payload.icon
            updated.message = payload.message
            updated.start = payload.start
            updated.end = payload.end
          }),
        )
        // console.info(`${thisAction} successful, variant: success`)

        commit('successfulUpdate')
      } else {
        // TODO: Handle original data not found
      }
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  /**
   * Delete the selected System Message(s) in the backend database
   * @param commit    commit to mutations
   * @param dispatch  dispatch other actions
   * @returns {Promise<void>}
   */
  delete: async ({ commit, dispatch }) => {
    const thisAction = `Delete ${state.displayAsSingular}`

    try {
      for (const thisItem of state.systemMessages.selected) {
        const modelToDelete = await DataStore.query(SystemMessages, thisItem.id)

        if (modelToDelete) {
          await DataStore.delete(modelToDelete)
          // console.info(`${thisAction} successful, variant: success`)
        }
      }

      commit('successfulDelete')
    } catch (ex) {
      console.error(`${thisAction} failed`)
      dispatch('error/setError', { name: thisAction, details: ex }, { root: true })
    }
  },

  /**
   * Observe changes from the backend database to SystemMessages and determine if local state needs to be refreshed
   * @param dispatch  dispatch other actions
   * @param state     this systemMessage state object
   * @param obj       observed object
   * @returns {Promise<void>}
   */
  observe: async ({ dispatch, state }, obj) => {
    const thisAction = `Observe ${state.displayAsSingular}`
    let elements = null

    if (obj) {
      if ({}.hasOwnProperty.call(obj, 'opType')) {
        // console.log(`${thisAction} type = ${obj.opType}`, obj)
        switch (obj.opType) {
          case 'INSERT':
            dispatch('retrieveAll')
            break
          case 'UPDATE':
          case 'DELETE':
            if ({}.hasOwnProperty.call(obj, 'element')) {
              if (obj.element) {
                if ({}.hasOwnProperty.call(obj.element, 'id')) {
                  elements = state.systemMessages.all.filter(item => item.id === obj.element.id)
                }
              }
            }

            if (elements) {
              if (elements.length > 0) {
                const objElement = elements[0]
                // console.info(`${thisAction} '${obj.opType}' element =`, objElement, obj)
                if ({}.hasOwnProperty.call(objElement, 'version') && {}.hasOwnProperty.call(obj.element, '_version')) {
                  if (objElement.version !== obj.element._version) {
                    dispatch('retrieveAll')
                  }
                }
              }
            }

            break
        }
      }
    }
  },

  /**
   * Remove the requested object from systemMessages all
   * @param commit  commit to mutations
   * @param payload an object to remove from state
   */
  remove: ({ commit }, payload) => {
    commit('remove', payload)
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
