<template>
  <div>
    <v-textarea
      id="maintainFormula"
      ref="textField"
      v-model="internalValue.formula"
      :autofocus="$attrs.autofocus"
      :disabled="$attrs.disabled"
      :required="$attrs.required"
      rows="3"
      auto-grow
      dense
      clearable
      class="font-weight-medium text-body-1"
      :rules="[isFormulaError]"
      @blur="onBlur(internalValue)"
    >
      <template v-slot:label>
        <v-icon
          v-if="$attrs.required"
          class="required-icon"
          x-small
        >
          mdi-asterisk
        </v-icon>
        {{ $attrs.label }}
      </template>

      <template #append-outer>
        <v-icon
          color="primary"
          @click="expand = !expand"
        >
          mdi-calculator
        </v-icon>
      </template>
    </v-textarea>

    <v-expand-transition>
      <v-container
        v-show="expand"
        class="pa-0 ma-0"
      >
        <v-row
          no-gutters
        >
          <!-- Calculator -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-card>
              <v-card-title>
                <v-container>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <h2
                        class="primary--text"
                      >
                        Calculator
                      </h2>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container
                  class="pa-0 ma-0"
                >
                  <!-- 7, 8, 9, / -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center text-nowrap"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('7')"
                      >
                        7
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('8')"
                      >
                        8
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('9')"
                      >
                        9
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('/')"
                      >
                        /
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- 4, 5, 6, * -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center text-nowrap"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('4')"
                      >
                        4
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('5')"
                      >
                        5
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('6')"
                      >
                        6
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('*')"
                      >
                        *
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- 1, 2, 3, - -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center text-nowrap"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('1')"
                      >
                        1
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('2')"
                      >
                        2
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('3')"
                      >
                        3
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('-')"
                      >
                        -
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- 0, ., + -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center text-nowrap"
                    >
                      <v-btn
                        color="primary"
                        class="ml-2 mr-2 font-weight-medium text-body-1"
                        style="width:80px;"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('0')"
                      >
                        0
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('.')"
                      >
                        .
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('+')"
                      >
                        +
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Functions -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-card>
              <v-card-title>
                <v-container>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <h2
                        class="primary--text"
                      >
                        Functions
                      </h2>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container
                  class="pa-0 ma-0"
                >
                  <!-- IF, RND, LA -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('IF')"
                      >
                        IF
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('RND')"
                      >
                        RND
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('LA')"
                      >
                        LA
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- =, <, > -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('=')"
                      >
                        =
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('<')"
                      >
                        <v-icon
                          x-small
                        >
                          mdi-less-than
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('>')"
                      >
                        <v-icon
                          x-small
                        >
                          mdi-greater-than
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- AND, OR -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('AND')"
                      >
                        AND
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('OR')"
                      >
                        OR
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- (, ) -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('(')"
                      >
                        (
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick(')')"
                      >
                        )
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- ^, sq, pi -->
                  <v-row
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('^')"
                      >
                        <v-icon
                          dense
                        >
                          mdi-exponent
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mr-2 mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('sq')"
                      >
                        <v-icon
                          dense
                        >
                          mdi-square-root
                        </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        elevation="2"
                        x-small
                        @click="calculatorButton_onClick('pi')"
                      >
                        <v-icon
                          dense
                        >
                          mdi-format-pilcrow
                        </v-icon>
                        <!--
                        &#8508;
                        <v-icon
                          x-small
                        >
                          mdi-format-pilcrow
                        </v-icon>
                        -->
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Variables -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-card>
              <v-card-title>
                <v-container>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-center"
                    >
                      <h2
                        class="primary--text"
                      >
                        Variables
                      </h2>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col
                      cols="12"
                    >
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>

              <v-card-text>
                <v-container
                  class="pa-0 ma-0"
                >
                  <v-row
                    v-for="(currentPrompt, i) in sortArray(prompts.data)"
                    :key="i"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      class="text-center text-nowrap"
                    >
                      <v-btn
                        color="primary"
                        class="mb-2 font-weight-medium text-body-1"
                        block
                        elevation="2"
                        small
                        @click="calculatorButton_onClick(currentPrompt.variable)"
                      >
                        {{ currentPrompt.variable }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-expand-transition>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'Formula',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        expand: false,
      }
    },

    computed: {
      ...mapGetters('app', [
        'getDataById',
      ]),
      appRules: get('app/rules'),
      prompts: get('prompt/prompts'),
      isFormulaError (dataItem, errorMessage = 'Formula Error.') {
        let isValid = true
        if ({}.hasOwnProperty.call(this.internalValue, 'formula_error')) {
          isValid = false
          if ({}.hasOwnProperty.call(this.internalValue.formula_error, 'message')) {
            errorMessage = this.internalValue.formula_error.message
          }
        }
        return isValid || errorMessage
      },
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val.formula === oldVal.formula) return

          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          if (val.formula === oldVal.formula) return

          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      sortArray (arrays) {
        return _.orderBy(arrays, 'sort_order', 'asc')
      },
      setExpand (val) {
        this.expand = val
      },
      prepareToUpdate (dataItem) {
        if (!dataItem.updated) {
          const originalData = this.getDataById(this.$attrs.originalData, dataItem.id)
          if (originalData.length > 0) {
            if (dataItem.formula !== originalData[0].formula) {
              this.$store.dispatch('user/setStateAsUpdated', dataItem)
            }
          }
        }
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      selectAll () {
        const calcFormulaElement = this.$refs.textField.$el.querySelector('textarea')
        calcFormulaElement.focus()
        this.$nextTick(() => {
          // TODO: Formula.selectAll
          // calcFormulaElement.select()
          // calcFormulaElement.setSelectionRange(0, this.internalValue.formula.length)
          document.getElementById('maintainFormula').select()
        })
      },

      onBlur (dataItem) {
        if (dataItem && {}.hasOwnProperty.call(dataItem, 'formula')) {
          if (dataItem.formula) {
            dataItem.formula = dataItem.formula.replace(/ +(?= )/g, '').trim()
          }
        }

        this.prepareToUpdate(dataItem)
      },

      setCursorPosition (el, pos) {
        el.focus()
        el.setSelectionRange(pos, pos)
      },

      calculatorButton_onClick (value2Insert) {
        const calcFormulaElement = this.$refs.textField.$el.querySelector('textarea')

        // get cursor's position:
        const startPos = calcFormulaElement.selectionStart
        const endPos = calcFormulaElement.selectionEnd
        let cursorPos = startPos
        const tempFormula = calcFormulaElement.value

        // insert:
        this.internalValue.formula = tempFormula.substring(0, startPos) + value2Insert + tempFormula.substring(endPos, tempFormula.length)

        cursorPos += value2Insert.length

        this.$nextTick().then(() => {
          this.setCursorPosition(calcFormulaElement, cursorPos)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
</style>
