// Pathify
import { make } from 'vuex-pathify'

const endpoint = 'https://yjhwago8o8.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'ShippingReport',
  displayAsPlural: 'ShippingReports',
  shippingReports: {
    all: [],
    data: [],
    originalData: [],
    isBusy: false,
    isFirst: false,
    isLast: false,
    selected: [],
    setLast: false,
    empty: {
      phaseDescription: null,
      part_id: null,
      order_quantity: null,
      unit: null,
      itemDescription: null,
      note: null,
      vendor_id: null,
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.shippingReports.isBusy = isBusy
  },

  push2Data: (state, dataFromDB) => {
    state.shippingReports.data = []
    state.shippingReports.data = dataFromDB

    // Get a copy of the original data, not a pointer to it
    state.shippingReports.originalData = []
    for (const currentData of dataFromDB) {
      state.shippingReports.originalData.push({ ...currentData })
    }

    state.shippingReports.isBusy = false
  },
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: () => {
    state.shippingReports.data = []
  },

  setDataFilteredOnDeliveryLoads: ({ rootState }) => {
    let filteredData = state.shippingReports.originalData
    const selectedPlanLoads = rootState.deliveryload.deliveryloads.selectedPlanLoads
    let filterLoads = ''

    if (selectedPlanLoads) {
      if (selectedPlanLoads.length > 0) {
        filteredData = []
        for (const currentLoad of selectedPlanLoads) {
          filterLoads += currentLoad.load_id + ','
        }

        for (const currentData of state.shippingReports.originalData) {
          if (filterLoads.includes(currentData.delivery_load_id)) {
            filteredData.push(currentData)
          }
        }
      }
    }

    state.shippingReports.data = []
    state.shippingReports.data = filteredData
  },

  retrieveAll: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'retrieveShipping'
    commit(setIsBusy, true)

    const selectedCustomerId = (typeof rootState.customer.customers.selectedItem.client_id === 'undefined' ? null : rootState.customer.customers.selectedItem.client_id)
    const selectedPlanId = (typeof rootState.plan.plans.selectedItem.plan_id === 'undefined' ? null : rootState.plan.plans.selectedItem.plan_id)

    if (selectedCustomerId && selectedPlanId) {
      let filterLoads = null

      if (payload) {
        for (const [index, currentLoad] of payload.entries()) {
          if (index === 0) {
            filterLoads = currentLoad.load_id + ','
          } else {
            filterLoads = filterLoads + currentLoad.load_id + ','
          }
        }
        if (filterLoads !== null) {
          filterLoads = filterLoads.slice(0, -1)
        }
      }

      // TODO: Remove filterLoads from API and sproc, filter clientside for performance and reduced API calls
      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${selectedCustomerId}/${selectedPlanId}/${filterLoads}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          commit('push2Data', jsonResponse.data)
        })
        .then(() => {
          dispatch('setDataFilteredOnDeliveryLoads')
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    } else {
      commit(setIsBusy, false)
    }
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
