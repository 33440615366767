export const errorMessage = {
  tryAgain: [
    { line: 'Please try again or refresh the page.' },
    { line: 'If the error persists contact the help desk at (864) 987-1007' },
  ],
  contactCustomerSupport: [
    { line: 'We apologize, but a serious error has occurred.' },
    { line: 'Please contact customer support at (864) 987-1007.' },
  ],
}

// Save as csv https://jsonformatter.org/#
// Remove unwanted columns and revert the csv back to json https://csvjson.com/csv2json
// In vscode or some json editor remove all unwanted columns & replace double quotes for single quotes
export const mdiIcons = [
  {
    id: 'CBFA6722-0EE6-49B4-B5C2-0B177A5523C2',
    name: 'ab-testing',
    codepoint: 'F01C9',
    deprecated: 'FALSE',
  },
  {
    id: '5B09B959-4A49-4674-9035-6CFD3D157C3F',
    name: 'abacus',
    codepoint: 'F16E0',
    deprecated: 'FALSE',
  },
  {
    id: 'E20665F3-5354-46C2-9162-4202B20DCA05',
    name: 'abjad-arabic',
    codepoint: 'F1328',
    deprecated: 'FALSE',
  },
  {
    id: 'FC508376-0233-498A-ABF8-B289241031FF',
    name: 'abjad-hebrew',
    codepoint: 'F1329',
    deprecated: 'FALSE',
  },
  {
    id: 'E211A028-22EA-4A78-9306-FC8FF8494D02',
    name: 'abugida-devanagari',
    codepoint: 'F132A',
    deprecated: 'FALSE',
  },
  {
    id: 'F782C16A-0558-4347-AB62-3E4A8DD299AD',
    name: 'abugida-thai',
    codepoint: 'F132B',
    deprecated: 'FALSE',
  },
  {
    id: '4F013652-22DE-48CF-886B-A0FB995E8B41',
    name: 'access-point',
    codepoint: 'F0003',
    deprecated: 'FALSE',
  },
  {
    id: '45F0D360-E817-4152-A66E-EF019E10ED47',
    name: 'access-point-check',
    codepoint: 'F1538',
    deprecated: 'FALSE',
  },
  {
    id: 'AAFAB208-B7D5-44A0-915F-761A83D295A3',
    name: 'access-point-minus',
    codepoint: 'F1539',
    deprecated: 'FALSE',
  },
  {
    id: '06048943-9EE5-4FE2-91D7-7DA162E55203',
    name: 'access-point-network',
    codepoint: 'F0002',
    deprecated: 'FALSE',
  },
  {
    id: '6DC5A262-63FE-4C77-A66C-B323DF4F82BF',
    name: 'access-point-network-off',
    codepoint: 'F0BE1',
    deprecated: 'FALSE',
  },
  {
    id: 'ED942A44-4B13-47E6-A674-E376C338F671',
    name: 'access-point-off',
    codepoint: 'F1511',
    deprecated: 'FALSE',
  },
  {
    id: '41E38A13-4B9B-4260-81EA-1DB407986154',
    name: 'access-point-plus',
    codepoint: 'F153A',
    deprecated: 'FALSE',
  },
  {
    id: '2E102C4D-6301-46CA-A3D8-35270CE751C5',
    name: 'access-point-remove',
    codepoint: 'F153B',
    deprecated: 'FALSE',
  },
  {
    id: 'E76EC23F-AB71-49B3-9173-841544527A20',
    name: 'account',
    codepoint: 'F0004',
    deprecated: 'FALSE',
  },
  {
    id: '3D869F3A-8C3E-4ECB-829E-7785230FA680',
    name: 'account-alert',
    codepoint: 'F0005',
    deprecated: 'FALSE',
  },
  {
    id: '858CE593-C905-42C6-ABA5-99379EBD95AE',
    name: 'account-alert-outline',
    codepoint: 'F0B50',
    deprecated: 'FALSE',
  },
  {
    id: '785965F1-58C0-42F8-9433-9B6A3ACF1AE6',
    name: 'account-arrow-down',
    codepoint: 'F1868',
    deprecated: 'FALSE',
  },
  {
    id: '8149AE23-C281-4BB3-8150-A5988CE17CD2',
    name: 'account-arrow-down-outline',
    codepoint: 'F1869',
    deprecated: 'FALSE',
  },
  {
    id: '34EAE057-F0E4-476A-B988-9BD149263BDF',
    name: 'account-arrow-left',
    codepoint: 'F0B51',
    deprecated: 'FALSE',
  },
  {
    id: 'D46BABF7-60F1-41B8-BE5E-5D52367FE58C',
    name: 'account-arrow-left-outline',
    codepoint: 'F0B52',
    deprecated: 'FALSE',
  },
  {
    id: '6C4EDCE5-0741-4BF4-B16A-112892C6AB98',
    name: 'account-arrow-right',
    codepoint: 'F0B53',
    deprecated: 'FALSE',
  },
  {
    id: '7CB2DA6F-D0F6-4A7A-A761-E53B564744E8',
    name: 'account-arrow-right-outline',
    codepoint: 'F0B54',
    deprecated: 'FALSE',
  },
  {
    id: '9F3DF21B-6A76-4916-BA8D-E9EF40D4A01F',
    name: 'account-arrow-up',
    codepoint: 'F1867',
    deprecated: 'FALSE',
  },
  {
    id: 'A5BAE236-3B16-41B5-B779-435AD2A6DAEE',
    name: 'account-arrow-up-outline',
    codepoint: 'F186A',
    deprecated: 'FALSE',
  },
  {
    id: '67BA915E-B44A-434E-95D3-9BD94A174164',
    name: 'account-badge',
    codepoint: 'F1B0A',
    deprecated: 'FALSE',
  },
  {
    id: '897CF76D-01B6-47B8-B917-542077D94343',
    name: 'account-badge-outline',
    codepoint: 'F1B0B',
    deprecated: 'FALSE',
  },
  {
    id: '474D0A31-E56A-4780-9417-DCF8D9A38830',
    name: 'account-box',
    codepoint: 'F0006',
    deprecated: 'FALSE',
  },
  {
    id: 'D612088F-9D2A-424A-9403-046993A3EB14',
    name: 'account-box-multiple',
    codepoint: 'F0934',
    deprecated: 'FALSE',
  },
  {
    id: '31302999-CD15-4F1F-A5D2-374D90F15099',
    name: 'account-box-multiple-outline',
    codepoint: 'F100A',
    deprecated: 'FALSE',
  },
  {
    id: '537261C5-1AF6-478F-A8BA-4349865D9C1C',
    name: 'account-box-outline',
    codepoint: 'F0007',
    deprecated: 'FALSE',
  },
  {
    id: '31CE9FF1-9698-4ED6-8E9B-392840E55DDE',
    name: 'account-cancel',
    codepoint: 'F12DF',
    deprecated: 'FALSE',
  },
  {
    id: 'D2CDB839-C734-46EA-A4DE-085CBF29DD0F',
    name: 'account-cancel-outline',
    codepoint: 'F12E0',
    deprecated: 'FALSE',
  },
  {
    id: '1A83D97E-DB88-4EAA-9EDB-28B4E68D77E7',
    name: 'account-card',
    codepoint: 'F1BA4',
    deprecated: 'FALSE',
  },
  {
    id: '47B6009B-B618-4A96-83FA-57D7D093952D',
    name: 'account-card-outline',
    codepoint: 'F1BA5',
    deprecated: 'FALSE',
  },
  {
    id: 'E3270EE8-3F41-481F-9B15-E8925C62A83C',
    name: 'account-cash',
    codepoint: 'F1097',
    deprecated: 'FALSE',
  },
  {
    id: '582FFF95-D87F-4A58-A3C1-F1A352BF8F56',
    name: 'account-cash-outline',
    codepoint: 'F1098',
    deprecated: 'FALSE',
  },
  {
    id: 'F3C76352-A033-4969-BBC4-99E2BEE84FAE',
    name: 'account-check',
    codepoint: 'F0008',
    deprecated: 'FALSE',
  },
  {
    id: '50F80B8D-C424-4FC1-9A2E-81715787C240',
    name: 'account-check-outline',
    codepoint: 'F0BE2',
    deprecated: 'FALSE',
  },
  {
    id: '431A5DA2-1B7E-4B86-8EAE-BEE1099048A1',
    name: 'account-child',
    codepoint: 'F0A89',
    deprecated: 'FALSE',
  },
  {
    id: 'AF386BC9-683F-42B4-97DA-C256CF82C79F',
    name: 'account-child-circle',
    codepoint: 'F0A8A',
    deprecated: 'FALSE',
  },
  {
    id: 'B8194820-B46C-41EB-921A-4DD7DDF9BE1F',
    name: 'account-child-outline',
    codepoint: 'F10C8',
    deprecated: 'FALSE',
  },
  {
    id: '1D7E8F31-998D-442A-80E6-EBB8DFA8CBA2',
    name: 'account-circle',
    codepoint: 'F0009',
    deprecated: 'FALSE',
  },
  {
    id: '17CE7627-5016-43F9-B42D-AFAAFA0A0564',
    name: 'account-circle-outline',
    codepoint: 'F0B55',
    deprecated: 'FALSE',
  },
  {
    id: 'E9851E02-790A-40FD-918A-BB1145051470',
    name: 'account-clock',
    codepoint: 'F0B56',
    deprecated: 'FALSE',
  },
  {
    id: 'AA800DB2-3369-412C-B881-0F99863D13F7',
    name: 'account-clock-outline',
    codepoint: 'F0B57',
    deprecated: 'FALSE',
  },
  {
    id: 'CA7B4B4E-B909-48F5-B93B-7A024D6A9AAA',
    name: 'account-cog',
    codepoint: 'F1370',
    deprecated: 'FALSE',
  },
  {
    id: '7B40CAE5-6B01-4E2E-8F23-60ECAB9DAC9F',
    name: 'account-cog-outline',
    codepoint: 'F1371',
    deprecated: 'FALSE',
  },
  {
    id: 'A6964E10-1CF0-41FA-BDCA-A77C9C6F30AF',
    name: 'account-convert',
    codepoint: 'F000A',
    deprecated: 'FALSE',
  },
  {
    id: 'BED42D20-9EEE-4297-81DC-A72F2F9D935D',
    name: 'account-convert-outline',
    codepoint: 'F1301',
    deprecated: 'FALSE',
  },
  {
    id: '18963ABD-E908-4E3C-B8DA-D87916F269F7',
    name: 'account-cowboy-hat',
    codepoint: 'F0E9B',
    deprecated: 'FALSE',
  },
  {
    id: 'E1C851E1-3BBD-4661-A6FA-C77AD370DC6A',
    name: 'account-cowboy-hat-outline',
    codepoint: 'F17F3',
    deprecated: 'FALSE',
  },
  {
    id: '5AE7D856-B33F-4C37-B7A3-F05DD4D345DD',
    name: 'account-credit-card',
    codepoint: 'F1BA6',
    deprecated: 'FALSE',
  },
  {
    id: 'A19CDCEF-1CFD-4BFE-A323-F66C6EBF0FCE',
    name: 'account-credit-card-outline',
    codepoint: 'F1BA7',
    deprecated: 'FALSE',
  },
  {
    id: '3696B7DF-3E12-44EF-B27F-E171AEB5241F',
    name: 'account-details',
    codepoint: 'F0631',
    deprecated: 'FALSE',
  },
  {
    id: 'B9137734-8DC1-41D1-9B34-CEDBB1742A39',
    name: 'account-details-outline',
    codepoint: 'F1372',
    deprecated: 'FALSE',
  },
  {
    id: '66271DF7-FF11-4998-9C03-F096CE407E4C',
    name: 'account-edit',
    codepoint: 'F06BC',
    deprecated: 'FALSE',
  },
  {
    id: '2374A15A-0DC7-458D-A8BD-766E55DFB0AF',
    name: 'account-edit-outline',
    codepoint: 'F0FFB',
    deprecated: 'FALSE',
  },
  {
    id: '5FFF2FB6-08B2-4860-ACCF-72D614641882',
    name: 'account-eye',
    codepoint: 'F0420',
    deprecated: 'FALSE',
  },
  {
    id: '32388085-89F4-4C2C-BE98-1AEBED8F9B95',
    name: 'account-eye-outline',
    codepoint: 'F127B',
    deprecated: 'FALSE',
  },
  {
    id: '839928F4-21EA-472B-94A0-C7DFBD6C263A',
    name: 'account-filter',
    codepoint: 'F0936',
    deprecated: 'FALSE',
  },
  {
    id: '4C7A5C71-F3C9-4BD7-B123-C71BE81CAFB7',
    name: 'account-filter-outline',
    codepoint: 'F0F9D',
    deprecated: 'FALSE',
  },
  {
    id: '7B8F1133-B47B-46C0-AAC0-FD87F06B038D',
    name: 'account-group',
    codepoint: 'F0849',
    deprecated: 'FALSE',
  },
  {
    id: '7DFB1292-669B-4769-A107-FA3359281270',
    name: 'account-group-outline',
    codepoint: 'F0B58',
    deprecated: 'FALSE',
  },
  {
    id: '54665F14-32CD-41E3-B5C5-0E9ABDD3A317',
    name: 'account-hard-hat',
    codepoint: 'F05B5',
    deprecated: 'FALSE',
  },
  {
    id: '5A21DE4E-D502-4D12-8B2A-58FB95BE0964',
    name: 'account-hard-hat-outline',
    codepoint: 'F1A1F',
    deprecated: 'FALSE',
  },
  {
    id: '15C54239-5327-4962-95CD-721512C6B27F',
    name: 'account-heart',
    codepoint: 'F0899',
    deprecated: 'FALSE',
  },
  {
    id: '66013F5C-F7FD-4974-AB80-E624C3E0A5E9',
    name: 'account-heart-outline',
    codepoint: 'F0BE3',
    deprecated: 'FALSE',
  },
  {
    id: 'E2BDD02C-EBDD-4394-9C2B-20279DBDF180',
    name: 'account-injury',
    codepoint: 'F1815',
    deprecated: 'FALSE',
  },
  {
    id: 'DB3273BF-116D-4AD9-8302-16CE79B64498',
    name: 'account-injury-outline',
    codepoint: 'F1816',
    deprecated: 'FALSE',
  },
  {
    id: '645E04E6-A328-45DD-9B22-03E2296837B1',
    name: 'account-key',
    codepoint: 'F000B',
    deprecated: 'FALSE',
  },
  {
    id: '85086B0D-4678-4B8E-9824-308F59F09C0F',
    name: 'account-key-outline',
    codepoint: 'F0BE4',
    deprecated: 'FALSE',
  },
  {
    id: 'BD94D7C7-DFDF-419F-862E-FB1AC3263967',
    name: 'account-lock',
    codepoint: 'F115E',
    deprecated: 'FALSE',
  },
  {
    id: '255D3923-5FE0-4BDD-AAF4-D71735D52E53',
    name: 'account-lock-open',
    codepoint: 'F1960',
    deprecated: 'FALSE',
  },
  {
    id: '0E6C1E1C-7F80-445A-92CB-230F04F09093',
    name: 'account-lock-open-outline',
    codepoint: 'F1961',
    deprecated: 'FALSE',
  },
  {
    id: 'F0E25FF6-2E45-4F3D-B9E3-6F251A6B1651',
    name: 'account-lock-outline',
    codepoint: 'F115F',
    deprecated: 'FALSE',
  },
  {
    id: '9CF96408-1A24-4040-AD04-011183CC9ABC',
    name: 'account-minus',
    codepoint: 'F000D',
    deprecated: 'FALSE',
  },
  {
    id: 'C03476FC-2655-4137-862E-28D714E93A50',
    name: 'account-minus-outline',
    codepoint: 'F0AEC',
    deprecated: 'FALSE',
  },
  {
    id: 'A8C2EE6C-31B9-4D51-B941-8128FDD77A96',
    name: 'account-multiple',
    codepoint: 'F000E',
    deprecated: 'FALSE',
  },
  {
    id: '3B3E2DF7-1E21-476A-8473-7F1EAA514D1F',
    name: 'account-multiple-check',
    codepoint: 'F08C5',
    deprecated: 'FALSE',
  },
  {
    id: '4391B451-368F-4E41-82C5-63A89A2CDE69',
    name: 'account-multiple-check-outline',
    codepoint: 'F11FE',
    deprecated: 'FALSE',
  },
  {
    id: 'BD31FA9F-EC76-44E9-A9F3-A85F83045D78',
    name: 'account-multiple-minus',
    codepoint: 'F05D3',
    deprecated: 'FALSE',
  },
  {
    id: '45C692AD-F1E8-404C-BED2-9FD58F0690CD',
    name: 'account-multiple-minus-outline',
    codepoint: 'F0BE5',
    deprecated: 'FALSE',
  },
  {
    id: '30C6B7FE-4496-4961-B36F-E0FBC4F2696E',
    name: 'account-multiple-outline',
    codepoint: 'F000F',
    deprecated: 'FALSE',
  },
  {
    id: '047F8AC1-524D-4077-BCEB-434FE1C50C8B',
    name: 'account-multiple-plus',
    codepoint: 'F0010',
    deprecated: 'FALSE',
  },
  {
    id: 'AFF0AB6B-616A-4C18-8E28-EE8067224EED',
    name: 'account-multiple-plus-outline',
    codepoint: 'F0800',
    deprecated: 'FALSE',
  },
  {
    id: '998E10E4-B96A-4DB2-9793-3E1C31D42501',
    name: 'account-multiple-remove',
    codepoint: 'F120A',
    deprecated: 'FALSE',
  },
  {
    id: '618B092A-7228-4E70-A95C-7EB4EC1BA2FB',
    name: 'account-multiple-remove-outline',
    codepoint: 'F120B',
    deprecated: 'FALSE',
  },
  {
    id: '306F2F2C-4B76-40E9-9561-BC3AD271D6BA',
    name: 'account-music',
    codepoint: 'F0803',
    deprecated: 'FALSE',
  },
  {
    id: 'A8CC9CFF-A5DE-4FC8-A4AC-BCE153F767F8',
    name: 'account-music-outline',
    codepoint: 'F0CE9',
    deprecated: 'FALSE',
  },
  {
    id: '8F49C741-4ED8-4DD3-A22E-310C039EDDA6',
    name: 'account-network',
    codepoint: 'F0011',
    deprecated: 'FALSE',
  },
  {
    id: 'AF848CDB-6C2E-4920-9C30-9135EE7AEEFA',
    name: 'account-network-off',
    codepoint: 'F1AF1',
    deprecated: 'FALSE',
  },
  {
    id: 'F3B94B38-61D9-4894-A615-8E36FEC40D47',
    name: 'account-network-off-outline',
    codepoint: 'F1AF2',
    deprecated: 'FALSE',
  },
  {
    id: '2EEA7799-E0DA-4E9B-880E-B5975A6FC11F',
    name: 'account-network-outline',
    codepoint: 'F0BE6',
    deprecated: 'FALSE',
  },
  {
    id: '2306EFC9-3375-4D03-A631-A32BD72075EC',
    name: 'account-off',
    codepoint: 'F0012',
    deprecated: 'FALSE',
  },
  {
    id: '231E3EA7-A7EC-41A0-A594-032040488448',
    name: 'account-off-outline',
    codepoint: 'F0BE7',
    deprecated: 'FALSE',
  },
  {
    id: '79ECAAC6-71DC-406C-A9B7-57C5F0BAA9E0',
    name: 'account-outline',
    codepoint: 'F0013',
    deprecated: 'FALSE',
  },
  {
    id: '66556BA4-6B65-4ED9-B813-AD33CA8C2CCF',
    name: 'account-plus',
    codepoint: 'F0014',
    deprecated: 'FALSE',
  },
  {
    id: '1095C7C6-C264-4415-BD6D-A2078242477E',
    name: 'account-plus-outline',
    codepoint: 'F0801',
    deprecated: 'FALSE',
  },
  {
    id: '163F4ADF-F6CC-42D0-AEED-DB61351C624C',
    name: 'account-question',
    codepoint: 'F0B59',
    deprecated: 'FALSE',
  },
  {
    id: 'C5217A29-708E-4340-AE2D-89A33F364A97',
    name: 'account-question-outline',
    codepoint: 'F0B5A',
    deprecated: 'FALSE',
  },
  {
    id: 'AD1CDB51-AAD9-411A-A81F-8D718A843FDE',
    name: 'account-reactivate',
    codepoint: 'F152B',
    deprecated: 'FALSE',
  },
  {
    id: '9CB5E1B3-D7AA-4BD2-9966-89CDAC0BF710',
    name: 'account-reactivate-outline',
    codepoint: 'F152C',
    deprecated: 'FALSE',
  },
  {
    id: '0A1FDBBB-9E1C-480E-BC1D-725778D0851B',
    name: 'account-remove',
    codepoint: 'F0015',
    deprecated: 'FALSE',
  },
  {
    id: '9F89EDE8-E95A-401D-9532-7F0DCD6AE321',
    name: 'account-remove-outline',
    codepoint: 'F0AED',
    deprecated: 'FALSE',
  },
  {
    id: 'BE45DEDF-3BBC-442F-9EDD-4A16570B91A0',
    name: 'account-school',
    codepoint: 'F1A20',
    deprecated: 'FALSE',
  },
  {
    id: 'DB767D52-F1C9-4986-B785-98314B70B2FF',
    name: 'account-school-outline',
    codepoint: 'F1A21',
    deprecated: 'FALSE',
  },
  {
    id: '48E9C474-C544-4868-9966-C3A8D7808FCA',
    name: 'account-search',
    codepoint: 'F0016',
    deprecated: 'FALSE',
  },
  {
    id: 'F6808872-4EB9-43AF-BD74-BDA6861E1862',
    name: 'account-search-outline',
    codepoint: 'F0935',
    deprecated: 'FALSE',
  },
  {
    id: 'A975CEEA-FDFD-49A2-A618-E74A3FF87A63',
    name: 'account-settings',
    codepoint: 'F0630',
    deprecated: 'FALSE',
  },
  {
    id: 'CA95C904-F98B-43D4-A896-DF5E174A0F72',
    name: 'account-settings-outline',
    codepoint: 'F10C9',
    deprecated: 'FALSE',
  },
  {
    id: '1E04960E-FFFE-439D-A5FD-C51E7291DA6F',
    name: 'account-star',
    codepoint: 'F0017',
    deprecated: 'FALSE',
  },
  {
    id: '3B63C1BC-9FB2-4F41-86CF-93D6117585E9',
    name: 'account-star-outline',
    codepoint: 'F0BE8',
    deprecated: 'FALSE',
  },
  {
    id: 'DF632613-259D-4C5E-9718-0EB3057290AF',
    name: 'account-supervisor',
    codepoint: 'F0A8B',
    deprecated: 'FALSE',
  },
  {
    id: '741E5459-89DC-4045-B02E-3FB74F636143',
    name: 'account-supervisor-circle',
    codepoint: 'F0A8C',
    deprecated: 'FALSE',
  },
  {
    id: '51B2A4EF-1F20-44EA-BC57-D1B3129C496C',
    name: 'account-supervisor-circle-outline',
    codepoint: 'F14EC',
    deprecated: 'FALSE',
  },
  {
    id: 'B640886A-F3F0-4FE4-AC74-4AE1407E264F',
    name: 'account-supervisor-outline',
    codepoint: 'F112D',
    deprecated: 'FALSE',
  },
  {
    id: 'FC4F4C4D-83C2-493F-9606-CA80E44BFEF8',
    name: 'account-switch',
    codepoint: 'F0019',
    deprecated: 'FALSE',
  },
  {
    id: '82D545A0-9A8E-4363-A462-883526E48330',
    name: 'account-switch-outline',
    codepoint: 'F04CB',
    deprecated: 'FALSE',
  },
  {
    id: '85F768C4-8659-414E-8C96-F2E90EB36971',
    name: 'account-sync',
    codepoint: 'F191B',
    deprecated: 'FALSE',
  },
  {
    id: '4D5C282D-E452-4544-BD36-6EBF5DE7FD85',
    name: 'account-sync-outline',
    codepoint: 'F191C',
    deprecated: 'FALSE',
  },
  {
    id: 'CD3C08D4-514C-4F3C-B3D7-EF6F74F79817',
    name: 'account-tag',
    codepoint: 'F1C1B',
    deprecated: 'FALSE',
  },
  {
    id: '253469B8-B07E-44ED-A834-1CA67428169E',
    name: 'account-tag-outline',
    codepoint: 'F1C1C',
    deprecated: 'FALSE',
  },
  {
    id: '5EF0B9EA-FECB-409C-999C-FE1ECF0C8B01',
    name: 'account-tie',
    codepoint: 'F0CE3',
    deprecated: 'FALSE',
  },
  {
    id: '07487BB8-778A-4B30-8129-FC2780463D86',
    name: 'account-tie-hat',
    codepoint: 'F1898',
    deprecated: 'FALSE',
  },
  {
    id: '8097E3AA-0C0F-47BE-9700-DEAF4986E6AE',
    name: 'account-tie-hat-outline',
    codepoint: 'F1899',
    deprecated: 'FALSE',
  },
  {
    id: '0D809FB8-6A27-4972-B4E5-6A308963FD8B',
    name: 'account-tie-outline',
    codepoint: 'F10CA',
    deprecated: 'FALSE',
  },
  {
    id: '7EAF1288-141D-4C8E-91E6-891D04BAE32C',
    name: 'account-tie-voice',
    codepoint: 'F1308',
    deprecated: 'FALSE',
  },
  {
    id: 'F835F775-8FC4-4E85-9B26-7C95F8F45B05',
    name: 'account-tie-voice-off',
    codepoint: 'F130A',
    deprecated: 'FALSE',
  },
  {
    id: '6CDB4F0E-41EC-409E-A2E0-87785BDAC858',
    name: 'account-tie-voice-off-outline',
    codepoint: 'F130B',
    deprecated: 'FALSE',
  },
  {
    id: '5712C24C-E8EE-48CD-9E6F-9712AD2BA502',
    name: 'account-tie-voice-outline',
    codepoint: 'F1309',
    deprecated: 'FALSE',
  },
  {
    id: '7D6F703D-10AF-459F-9349-69B6D05BD7CE',
    name: 'account-tie-woman',
    codepoint: 'F1A8C',
    deprecated: 'FALSE',
  },
  {
    id: 'F1EEA910-2CD7-448A-A12F-A1BD0FDC6010',
    name: 'account-voice',
    codepoint: 'F05CB',
    deprecated: 'FALSE',
  },
  {
    id: 'C90AED93-FCBD-4FDC-A15B-26CD066775BD',
    name: 'account-voice-off',
    codepoint: 'F0ED4',
    deprecated: 'FALSE',
  },
  {
    id: 'C3712CBF-1E1C-4794-95B1-F77380FCEB7C',
    name: 'account-wrench',
    codepoint: 'F189A',
    deprecated: 'FALSE',
  },
  {
    id: '9AD54A3C-987A-4ED9-A416-7432C9540239',
    name: 'account-wrench-outline',
    codepoint: 'F189B',
    deprecated: 'FALSE',
  },
  {
    id: '3334E19F-962D-45B4-BE11-F009E1192165',
    name: 'adjust',
    codepoint: 'F001A',
    deprecated: 'FALSE',
  },
  {
    id: 'F368D23C-0F09-44D6-8487-F3229BEB6F1C',
    name: 'advertisements',
    codepoint: 'F192A',
    deprecated: 'FALSE',
  },
  {
    id: '28583E81-1315-4397-8FC5-46325CF1FB94',
    name: 'advertisements-off',
    codepoint: 'F192B',
    deprecated: 'FALSE',
  },
  {
    id: 'FACEA69B-49F0-4588-BDEC-693965AD4649',
    name: 'air-conditioner',
    codepoint: 'F001B',
    deprecated: 'FALSE',
  },
  {
    id: 'C9AF90F5-7CE7-4251-9BA2-3A614B4882AA',
    name: 'air-filter',
    codepoint: 'F0D43',
    deprecated: 'FALSE',
  },
  {
    id: '41485B7B-F175-4627-90EB-640C9C60B27C',
    name: 'air-horn',
    codepoint: 'F0DAC',
    deprecated: 'FALSE',
  },
  {
    id: 'C35492EC-7A43-444E-86A1-B3E960A86950',
    name: 'air-humidifier',
    codepoint: 'F1099',
    deprecated: 'FALSE',
  },
  {
    id: '9B86205A-73F4-4DB6-9F2C-73654EDF4D52',
    name: 'air-humidifier-off',
    codepoint: 'F1466',
    deprecated: 'FALSE',
  },
  {
    id: 'C1621283-2EDC-43C5-9108-812DCAD0BC12',
    name: 'air-purifier',
    codepoint: 'F0D44',
    deprecated: 'FALSE',
  },
  {
    id: 'EFF08C2C-BF4C-4A0E-A261-9DEF2A92C8D6',
    name: 'air-purifier-off',
    codepoint: 'F1B57',
    deprecated: 'FALSE',
  },
  {
    id: '26929F83-C254-41C2-8B0D-B4D95EACAD3E',
    name: 'airbag',
    codepoint: 'F0BE9',
    deprecated: 'FALSE',
  },
  {
    id: '3E1D81B2-7DF1-4AAF-9598-4995B8D8E5CC',
    name: 'airballoon',
    codepoint: 'F001C',
    deprecated: 'FALSE',
  },
  {
    id: '67E3294D-960F-4CD8-B4DB-3AAB13D6E17C',
    name: 'airballoon-outline',
    codepoint: 'F100B',
    deprecated: 'FALSE',
  },
  {
    id: '5AE3D483-1E1C-49DE-92FF-21C6B93FD52F',
    name: 'airplane',
    codepoint: 'F001D',
    deprecated: 'FALSE',
  },
  {
    id: 'C0734996-651C-4CEE-A216-E1D03B2FA6F6',
    name: 'airplane-alert',
    codepoint: 'F187A',
    deprecated: 'FALSE',
  },
  {
    id: '3771D65E-17FD-41E0-B8E7-D1136B28D672',
    name: 'airplane-check',
    codepoint: 'F187B',
    deprecated: 'FALSE',
  },
  {
    id: '8C33AFFF-209E-41AC-A403-B1A46B321917',
    name: 'airplane-clock',
    codepoint: 'F187C',
    deprecated: 'FALSE',
  },
  {
    id: 'BBFABE86-364F-44BA-861F-0EA4502F2E14',
    name: 'airplane-cog',
    codepoint: 'F187D',
    deprecated: 'FALSE',
  },
  {
    id: '7B7C1A74-3C6D-4CF8-8F29-62F99BD9AC24',
    name: 'airplane-edit',
    codepoint: 'F187E',
    deprecated: 'FALSE',
  },
  {
    id: '634EE7E8-8280-4CF1-B642-0921E740B6DB',
    name: 'airplane-landing',
    codepoint: 'F05D4',
    deprecated: 'FALSE',
  },
  {
    id: '792432E4-3913-4118-80E9-C8F348DEFBA4',
    name: 'airplane-marker',
    codepoint: 'F187F',
    deprecated: 'FALSE',
  },
  {
    id: 'B92E8598-1A85-4EC5-9082-2CF13684EB13',
    name: 'airplane-minus',
    codepoint: 'F1880',
    deprecated: 'FALSE',
  },
  {
    id: 'D2D1D38E-ABE7-47B6-9405-DC6BDBA1C335',
    name: 'airplane-off',
    codepoint: 'F001E',
    deprecated: 'FALSE',
  },
  {
    id: '4D5B4816-F71A-4413-A065-A58F2A907656',
    name: 'airplane-plus',
    codepoint: 'F1881',
    deprecated: 'FALSE',
  },
  {
    id: '244A039A-5ABD-46F5-8E5E-8F6D84FC116C',
    name: 'airplane-remove',
    codepoint: 'F1882',
    deprecated: 'FALSE',
  },
  {
    id: '4DBDF7D5-FD3A-4CE2-BCC6-306A09694221',
    name: 'airplane-search',
    codepoint: 'F1883',
    deprecated: 'FALSE',
  },
  {
    id: '7B89A30A-F211-4B69-9629-9148A539D41D',
    name: 'airplane-settings',
    codepoint: 'F1884',
    deprecated: 'FALSE',
  },
  {
    id: '2B9BB7A1-A94B-4D24-8308-5599504317C2',
    name: 'airplane-takeoff',
    codepoint: 'F05D5',
    deprecated: 'FALSE',
  },
  {
    id: '62C9DAA4-6718-44A9-BCD3-2401D96BE1E7',
    name: 'airport',
    codepoint: 'F084B',
    deprecated: 'FALSE',
  },
  {
    id: '9A1AAF33-6F36-4987-9E8C-798E34D919A9',
    name: 'alarm',
    codepoint: 'F0020',
    deprecated: 'FALSE',
  },
  {
    id: '02423F51-52A3-4F45-8373-05CA8E8D57B7',
    name: 'alarm-bell',
    codepoint: 'F078E',
    deprecated: 'FALSE',
  },
  {
    id: '6DCCA48F-E413-4C45-BFE5-8E3C8BE61A4E',
    name: 'alarm-check',
    codepoint: 'F0021',
    deprecated: 'FALSE',
  },
  {
    id: 'B6D88F9D-A85A-4943-A61C-7D33FB0EBF4F',
    name: 'alarm-light',
    codepoint: 'F078F',
    deprecated: 'FALSE',
  },
  {
    id: 'E328D226-0D09-402D-BCAA-A7630396F168',
    name: 'alarm-light-off',
    codepoint: 'F171E',
    deprecated: 'FALSE',
  },
  {
    id: '70D649B6-0FA1-4465-A92C-BB362D4D5FAD',
    name: 'alarm-light-off-outline',
    codepoint: 'F171F',
    deprecated: 'FALSE',
  },
  {
    id: 'F6F4DACD-B15B-49FD-8ACC-FAE0E994435A',
    name: 'alarm-light-outline',
    codepoint: 'F0BEA',
    deprecated: 'FALSE',
  },
  {
    id: '67D410AB-495A-41BD-B9D8-20A7DFBBF623',
    name: 'alarm-multiple',
    codepoint: 'F0022',
    deprecated: 'FALSE',
  },
  {
    id: '2C35D0DC-0191-4DFF-AF19-066F88DE56FE',
    name: 'alarm-note',
    codepoint: 'F0E71',
    deprecated: 'FALSE',
  },
  {
    id: '2ECA27D9-8F96-4B03-A7BF-B74468CFCBF9',
    name: 'alarm-note-off',
    codepoint: 'F0E72',
    deprecated: 'FALSE',
  },
  {
    id: 'DD224DD6-9A65-47AB-8838-90FE346A7768',
    name: 'alarm-off',
    codepoint: 'F0023',
    deprecated: 'FALSE',
  },
  {
    id: 'AB8BCA23-EA1C-4A27-B535-4F373F1451E6',
    name: 'alarm-panel',
    codepoint: 'F15C4',
    deprecated: 'FALSE',
  },
  {
    id: '60F9887C-36C5-4E6A-B282-4BACB99120BC',
    name: 'alarm-panel-outline',
    codepoint: 'F15C5',
    deprecated: 'FALSE',
  },
  {
    id: '6D93F44C-83F7-4446-A5CD-E4135C8644D7',
    name: 'alarm-plus',
    codepoint: 'F0024',
    deprecated: 'FALSE',
  },
  {
    id: '28F85724-265B-4C24-9975-DDDF788A99E5',
    name: 'alarm-snooze',
    codepoint: 'F068E',
    deprecated: 'FALSE',
  },
  {
    id: '8529D611-F581-4808-95BE-271FB1A6C2FD',
    name: 'album',
    codepoint: 'F0025',
    deprecated: 'FALSE',
  },
  {
    id: '9FF7DF3F-D575-433B-9C9A-69C58363C186',
    name: 'alert',
    codepoint: 'F0026',
    deprecated: 'FALSE',
  },
  {
    id: '7B5D47A1-5658-4169-94C7-0C41E7179DD2',
    name: 'alert-box',
    codepoint: 'F0027',
    deprecated: 'FALSE',
  },
  {
    id: 'D37BD379-9466-4E87-AD27-087CF72F076D',
    name: 'alert-box-outline',
    codepoint: 'F0CE4',
    deprecated: 'FALSE',
  },
  {
    id: 'C7EDD5B7-95D4-4D83-A4E3-95D8A16A4887',
    name: 'alert-circle',
    codepoint: 'F0028',
    deprecated: 'FALSE',
  },
  {
    id: '68F19F47-B38B-4370-B299-4EE2BF2619A5',
    name: 'alert-circle-check',
    codepoint: 'F11ED',
    deprecated: 'FALSE',
  },
  {
    id: 'BB715CDA-DE45-4983-A33F-10C6141F4FEA',
    name: 'alert-circle-check-outline',
    codepoint: 'F11EE',
    deprecated: 'FALSE',
  },
  {
    id: '0F4002B4-3C3D-43C4-939F-A998B2C59177',
    name: 'alert-circle-outline',
    codepoint: 'F05D6',
    deprecated: 'FALSE',
  },
  {
    id: 'B4866C08-2DAE-4B0A-8942-E057094B3C76',
    name: 'alert-decagram',
    codepoint: 'F06BD',
    deprecated: 'FALSE',
  },
  {
    id: 'B2984FA7-4B68-4191-8835-EB768C58DD10',
    name: 'alert-decagram-outline',
    codepoint: 'F0CE5',
    deprecated: 'FALSE',
  },
  {
    id: '233D24FC-6BAE-44C4-BE98-7178560C4A32',
    name: 'alert-minus',
    codepoint: 'F14BB',
    deprecated: 'FALSE',
  },
  {
    id: '044D1C2C-2EA5-4058-A3B0-0550BD2E3170',
    name: 'alert-minus-outline',
    codepoint: 'F14BE',
    deprecated: 'FALSE',
  },
  {
    id: '9BC1A338-CD03-4D39-BE46-E9DE5EE51A2F',
    name: 'alert-octagon',
    codepoint: 'F0029',
    deprecated: 'FALSE',
  },
  {
    id: '90994A54-72AA-498F-8806-AAD466CC21BD',
    name: 'alert-octagon-outline',
    codepoint: 'F0CE6',
    deprecated: 'FALSE',
  },
  {
    id: '124F37BE-27F6-4524-9655-A18D28E91CA6',
    name: 'alert-octagram',
    codepoint: 'F0767',
    deprecated: 'FALSE',
  },
  {
    id: '2AD21B3E-77C1-4027-9AE9-7655246D9DFF',
    name: 'alert-octagram-outline',
    codepoint: 'F0CE7',
    deprecated: 'FALSE',
  },
  {
    id: '4E574535-954C-42F5-BE5B-DF3716DB7C5A',
    name: 'alert-outline',
    codepoint: 'F002A',
    deprecated: 'FALSE',
  },
  {
    id: '6D804890-36C6-43DC-B93D-B289A4C04D8F',
    name: 'alert-plus',
    codepoint: 'F14BA',
    deprecated: 'FALSE',
  },
  {
    id: 'C5512AAF-943C-4025-8FAB-F14D896F2C14',
    name: 'alert-plus-outline',
    codepoint: 'F14BD',
    deprecated: 'FALSE',
  },
  {
    id: 'EF9A06FC-5167-4AE0-AB4E-FAD8C705E8C2',
    name: 'alert-remove',
    codepoint: 'F14BC',
    deprecated: 'FALSE',
  },
  {
    id: '926FBBF9-9299-4E27-BB55-9CE300F50FDE',
    name: 'alert-remove-outline',
    codepoint: 'F14BF',
    deprecated: 'FALSE',
  },
  {
    id: 'C8354F93-0DA4-48B4-97B3-4BDE2C0046AB',
    name: 'alert-rhombus',
    codepoint: 'F11CE',
    deprecated: 'FALSE',
  },
  {
    id: 'C9724C30-FDBB-4A14-BC0B-59F0E6826DC3',
    name: 'alert-rhombus-outline',
    codepoint: 'F11CF',
    deprecated: 'FALSE',
  },
  {
    id: 'CD43AC33-8EE0-42BE-A7E0-3CCB31D8C5D3',
    name: 'alien',
    codepoint: 'F089A',
    deprecated: 'FALSE',
  },
  {
    id: 'F673BEC2-F616-4A5A-B33B-4CD776D80819',
    name: 'alien-outline',
    codepoint: 'F10CB',
    deprecated: 'FALSE',
  },
  {
    id: '05901A9E-71D5-47D9-B5E1-DC55B687E8E0',
    name: 'align-horizontal-center',
    codepoint: 'F11C3',
    deprecated: 'FALSE',
  },
  {
    id: 'B4B2B3EB-A8C5-48C3-8F82-4F295424B396',
    name: 'align-horizontal-distribute',
    codepoint: 'F1962',
    deprecated: 'FALSE',
  },
  {
    id: '91ED2206-4D41-48DB-964F-5E3AC50F899D',
    name: 'align-horizontal-left',
    codepoint: 'F11C2',
    deprecated: 'FALSE',
  },
  {
    id: '5FEBC8BB-061B-4D91-BD96-88285B5C2E23',
    name: 'align-horizontal-right',
    codepoint: 'F11C4',
    deprecated: 'FALSE',
  },
  {
    id: 'AA5D11B7-D45D-4505-938E-8BEF8C1A79F0',
    name: 'align-vertical-bottom',
    codepoint: 'F11C5',
    deprecated: 'FALSE',
  },
  {
    id: '04AE9704-928E-4272-B7FA-BD2ABC38ABE0',
    name: 'align-vertical-center',
    codepoint: 'F11C6',
    deprecated: 'FALSE',
  },
  {
    id: 'CB64EDBD-09A0-4085-BD1E-0D84EAA56297',
    name: 'align-vertical-distribute',
    codepoint: 'F1963',
    deprecated: 'FALSE',
  },
  {
    id: '9069B66B-C7CA-478D-BE02-BC9863E27FDB',
    name: 'align-vertical-top',
    codepoint: 'F11C7',
    deprecated: 'FALSE',
  },
  {
    id: '5596E6E7-916E-4870-9346-5D46BC86C0D5',
    name: 'all-inclusive',
    codepoint: 'F06BE',
    deprecated: 'FALSE',
  },
  {
    id: 'C8E30B1A-135A-4B8A-900C-D93FE1699720',
    name: 'all-inclusive-box',
    codepoint: 'F188D',
    deprecated: 'FALSE',
  },
  {
    id: 'C3D587B3-D1F0-4C6F-9E4F-5FEE39AFFE33',
    name: 'all-inclusive-box-outline',
    codepoint: 'F188E',
    deprecated: 'FALSE',
  },
  {
    id: '76B6381F-14CA-4453-8B28-184C508D003B',
    name: 'allergy',
    codepoint: 'F1258',
    deprecated: 'FALSE',
  },
  {
    id: '0B93DD30-B279-4997-BD6D-AC86E29D34CA',
    name: 'alpha',
    codepoint: 'F002B',
    deprecated: 'FALSE',
  },
  {
    id: '8A55BEB9-D57B-43DA-9905-7216E3EB7057',
    name: 'alpha-a',
    codepoint: 'F0AEE',
    deprecated: 'FALSE',
  },
  {
    id: '1AC5B76A-B93F-4F9B-851D-C3DAF9743046',
    name: 'alpha-a-box',
    codepoint: 'F0B08',
    deprecated: 'FALSE',
  },
  {
    id: 'B501FBBD-91FA-4567-8D4A-BD933FECCE7B',
    name: 'alpha-a-box-outline',
    codepoint: 'F0BEB',
    deprecated: 'FALSE',
  },
  {
    id: '71A79E36-29B9-4543-B386-399F950641E2',
    name: 'alpha-a-circle',
    codepoint: 'F0BEC',
    deprecated: 'FALSE',
  },
  {
    id: '6BB0D642-F5B7-4903-977C-325FE1F068FF',
    name: 'alpha-a-circle-outline',
    codepoint: 'F0BED',
    deprecated: 'FALSE',
  },
  {
    id: '910DF87E-809E-40D6-886F-34E581899455',
    name: 'alpha-b',
    codepoint: 'F0AEF',
    deprecated: 'FALSE',
  },
  {
    id: '057EDDAB-65B4-495C-A473-71F759BC6FD9',
    name: 'alpha-b-box',
    codepoint: 'F0B09',
    deprecated: 'FALSE',
  },
  {
    id: 'ACEAA38C-D83E-4812-AFF3-A063F0226825',
    name: 'alpha-b-box-outline',
    codepoint: 'F0BEE',
    deprecated: 'FALSE',
  },
  {
    id: '433A1CBB-57A4-44DA-8B93-B65AF6E54B1D',
    name: 'alpha-b-circle',
    codepoint: 'F0BEF',
    deprecated: 'FALSE',
  },
  {
    id: '5CBD1C34-B372-4DA7-9FF1-19DD9B3D9C3A',
    name: 'alpha-b-circle-outline',
    codepoint: 'F0BF0',
    deprecated: 'FALSE',
  },
  {
    id: 'F5F53184-51BB-46A3-AB67-D799A1D15F7F',
    name: 'alpha-c',
    codepoint: 'F0AF0',
    deprecated: 'FALSE',
  },
  {
    id: 'F7A4DF3D-18C8-4717-AD58-247CEFF7EDE3',
    name: 'alpha-c-box',
    codepoint: 'F0B0A',
    deprecated: 'FALSE',
  },
  {
    id: '9EB832F8-B389-47AA-ADDA-A2163E75DC20',
    name: 'alpha-c-box-outline',
    codepoint: 'F0BF1',
    deprecated: 'FALSE',
  },
  {
    id: '19229F43-9ACD-477E-9A30-9D2731080026',
    name: 'alpha-c-circle',
    codepoint: 'F0BF2',
    deprecated: 'FALSE',
  },
  {
    id: '23362597-17A2-4413-BE28-65B36F4591E7',
    name: 'alpha-c-circle-outline',
    codepoint: 'F0BF3',
    deprecated: 'FALSE',
  },
  {
    id: '0A79E0A4-CE86-431C-A152-57492815C31E',
    name: 'alpha-d',
    codepoint: 'F0AF1',
    deprecated: 'FALSE',
  },
  {
    id: '5299B671-D7CF-4D68-B439-92E3056A104A',
    name: 'alpha-d-box',
    codepoint: 'F0B0B',
    deprecated: 'FALSE',
  },
  {
    id: '5D512B06-6DF4-4BC7-A307-81512F828192',
    name: 'alpha-d-box-outline',
    codepoint: 'F0BF4',
    deprecated: 'FALSE',
  },
  {
    id: '758D2479-2950-4C5E-8FDC-C0FFA2EBE114',
    name: 'alpha-d-circle',
    codepoint: 'F0BF5',
    deprecated: 'FALSE',
  },
  {
    id: '6B6C0FE9-544A-4098-A5B2-823160140EE7',
    name: 'alpha-d-circle-outline',
    codepoint: 'F0BF6',
    deprecated: 'FALSE',
  },
  {
    id: '3B3F5AA2-8A97-4A66-BD4A-7AA105901B61',
    name: 'alpha-e',
    codepoint: 'F0AF2',
    deprecated: 'FALSE',
  },
  {
    id: '15C859D5-210D-4C8B-BB14-7A2B92ADB1C2',
    name: 'alpha-e-box',
    codepoint: 'F0B0C',
    deprecated: 'FALSE',
  },
  {
    id: '6C02F23D-4684-4018-9E47-624103B0653E',
    name: 'alpha-e-box-outline',
    codepoint: 'F0BF7',
    deprecated: 'FALSE',
  },
  {
    id: 'D1367C3A-1884-4A3E-A90F-0AAB3DDCACF1',
    name: 'alpha-e-circle',
    codepoint: 'F0BF8',
    deprecated: 'FALSE',
  },
  {
    id: 'CD51D79A-B691-4B46-BABF-6A419EE2716A',
    name: 'alpha-e-circle-outline',
    codepoint: 'F0BF9',
    deprecated: 'FALSE',
  },
  {
    id: '6E5FB32C-27B6-4ECE-8F88-65D8E29D1CD8',
    name: 'alpha-f',
    codepoint: 'F0AF3',
    deprecated: 'FALSE',
  },
  {
    id: '1E5D6AAB-6056-4CEE-88EC-87FBE2C84FF0',
    name: 'alpha-f-box',
    codepoint: 'F0B0D',
    deprecated: 'FALSE',
  },
  {
    id: '304C0008-2989-4DC9-8927-AE51F7B44F6C',
    name: 'alpha-f-box-outline',
    codepoint: 'F0BFA',
    deprecated: 'FALSE',
  },
  {
    id: '317A9E55-62CC-4A55-B851-483EB546D022',
    name: 'alpha-f-circle',
    codepoint: 'F0BFB',
    deprecated: 'FALSE',
  },
  {
    id: '46706811-B9CA-4F96-B201-63E658BE1F6D',
    name: 'alpha-f-circle-outline',
    codepoint: 'F0BFC',
    deprecated: 'FALSE',
  },
  {
    id: '4B7C3083-DFDF-402E-9D71-CABE04305297',
    name: 'alpha-g',
    codepoint: 'F0AF4',
    deprecated: 'FALSE',
  },
  {
    id: 'A2781290-35AD-441F-B02C-15A0AB49B74D',
    name: 'alpha-g-box',
    codepoint: 'F0B0E',
    deprecated: 'FALSE',
  },
  {
    id: 'D9ED1661-B57E-47E9-B727-F03BC035DE4D',
    name: 'alpha-g-box-outline',
    codepoint: 'F0BFD',
    deprecated: 'FALSE',
  },
  {
    id: '5DB072AE-CE38-4FA8-A41D-0C7443DA3481',
    name: 'alpha-g-circle',
    codepoint: 'F0BFE',
    deprecated: 'FALSE',
  },
  {
    id: '780BAB12-D647-4E55-BD56-487367D70FD0',
    name: 'alpha-g-circle-outline',
    codepoint: 'F0BFF',
    deprecated: 'FALSE',
  },
  {
    id: '99A5A78F-6759-4C02-A5D2-DA8FA3DDBBA9',
    name: 'alpha-h',
    codepoint: 'F0AF5',
    deprecated: 'FALSE',
  },
  {
    id: '180E5F6A-8C7E-4B29-92C1-94C97D1266B9',
    name: 'alpha-h-box',
    codepoint: 'F0B0F',
    deprecated: 'FALSE',
  },
  {
    id: '7568D810-145E-41E7-8447-E2257339D5D8',
    name: 'alpha-h-box-outline',
    codepoint: 'F0C00',
    deprecated: 'FALSE',
  },
  {
    id: 'FC1C719F-CE44-4B8A-A2A8-5DE6E02405D2',
    name: 'alpha-h-circle',
    codepoint: 'F0C01',
    deprecated: 'FALSE',
  },
  {
    id: '29BA4374-825B-46E4-8E81-FC027000A16F',
    name: 'alpha-h-circle-outline',
    codepoint: 'F0C02',
    deprecated: 'FALSE',
  },
  {
    id: 'C34618B6-174E-42C7-AD23-B65FCAC93D74',
    name: 'alpha-i',
    codepoint: 'F0AF6',
    deprecated: 'FALSE',
  },
  {
    id: '4D933A0E-88F5-4712-8BA1-0E60290A6623',
    name: 'alpha-i-box',
    codepoint: 'F0B10',
    deprecated: 'FALSE',
  },
  {
    id: 'E7CF351D-BC47-44AC-BA3E-D61F99D11026',
    name: 'alpha-i-box-outline',
    codepoint: 'F0C03',
    deprecated: 'FALSE',
  },
  {
    id: '0CAD67D2-EFF3-442E-91D6-1C27D759C1DD',
    name: 'alpha-i-circle',
    codepoint: 'F0C04',
    deprecated: 'FALSE',
  },
  {
    id: '4AAE84B4-CF3E-4736-A420-DD188452F819',
    name: 'alpha-i-circle-outline',
    codepoint: 'F0C05',
    deprecated: 'FALSE',
  },
  {
    id: 'C964FFC4-AA90-4AF6-B604-352082207789',
    name: 'alpha-j',
    codepoint: 'F0AF7',
    deprecated: 'FALSE',
  },
  {
    id: '1472FB12-4CBA-476C-8F57-E1CB5E4213A6',
    name: 'alpha-j-box',
    codepoint: 'F0B11',
    deprecated: 'FALSE',
  },
  {
    id: '52E82BF4-8023-49C2-A4CB-AF3A88B43D1A',
    name: 'alpha-j-box-outline',
    codepoint: 'F0C06',
    deprecated: 'FALSE',
  },
  {
    id: '90C7D800-47CA-4E5E-860D-8AAB0F6C51DC',
    name: 'alpha-j-circle',
    codepoint: 'F0C07',
    deprecated: 'FALSE',
  },
  {
    id: '0A0B3839-C0D3-422A-B688-E59F4927AA6F',
    name: 'alpha-j-circle-outline',
    codepoint: 'F0C08',
    deprecated: 'FALSE',
  },
  {
    id: '358C06F7-7E39-4023-A9FC-2C02F275B84B',
    name: 'alpha-k',
    codepoint: 'F0AF8',
    deprecated: 'FALSE',
  },
  {
    id: 'EF778205-36FD-4C55-BDFB-0373983C95D4',
    name: 'alpha-k-box',
    codepoint: 'F0B12',
    deprecated: 'FALSE',
  },
  {
    id: 'FD913715-7C1E-4E80-9A44-34992AADB3E6',
    name: 'alpha-k-box-outline',
    codepoint: 'F0C09',
    deprecated: 'FALSE',
  },
  {
    id: '1F260841-CE76-48D8-BACE-8935459D850E',
    name: 'alpha-k-circle',
    codepoint: 'F0C0A',
    deprecated: 'FALSE',
  },
  {
    id: 'F4E2D505-4C0D-4184-9F62-FDD7A450F6E7',
    name: 'alpha-k-circle-outline',
    codepoint: 'F0C0B',
    deprecated: 'FALSE',
  },
  {
    id: 'C4CFE00B-0978-482B-8E9E-F1DD4B21B45B',
    name: 'alpha-l',
    codepoint: 'F0AF9',
    deprecated: 'FALSE',
  },
  {
    id: '7E7BF31C-C3FA-4744-B214-FF8D15753ED5',
    name: 'alpha-l-box',
    codepoint: 'F0B13',
    deprecated: 'FALSE',
  },
  {
    id: 'C0044FE0-893A-4429-A57F-9C58DFBE36CC',
    name: 'alpha-l-box-outline',
    codepoint: 'F0C0C',
    deprecated: 'FALSE',
  },
  {
    id: 'B1456398-80C2-4D10-B7D3-A79BE026E457',
    name: 'alpha-l-circle',
    codepoint: 'F0C0D',
    deprecated: 'FALSE',
  },
  {
    id: 'F62EB8C2-6037-474B-8D48-FD635163D3A6',
    name: 'alpha-l-circle-outline',
    codepoint: 'F0C0E',
    deprecated: 'FALSE',
  },
  {
    id: '31B62839-D838-41FE-86B8-313070BBB0A9',
    name: 'alpha-m',
    codepoint: 'F0AFA',
    deprecated: 'FALSE',
  },
  {
    id: 'AB678336-D11A-4426-92A8-AA520A4F282B',
    name: 'alpha-m-box',
    codepoint: 'F0B14',
    deprecated: 'FALSE',
  },
  {
    id: '147229CF-1402-4406-8E94-B83CC64248E2',
    name: 'alpha-m-box-outline',
    codepoint: 'F0C0F',
    deprecated: 'FALSE',
  },
  {
    id: '88D09175-8E63-4542-BBE7-6B8D7EC16EBE',
    name: 'alpha-m-circle',
    codepoint: 'F0C10',
    deprecated: 'FALSE',
  },
  {
    id: 'F85ABB66-FEE7-46ED-9DE3-AB3B24597863',
    name: 'alpha-m-circle-outline',
    codepoint: 'F0C11',
    deprecated: 'FALSE',
  },
  {
    id: '528E55D8-A774-4678-9D76-42EEFDF5FE3E',
    name: 'alpha-n',
    codepoint: 'F0AFB',
    deprecated: 'FALSE',
  },
  {
    id: '8A547BE1-B4F5-4080-824D-998618AF2542',
    name: 'alpha-n-box',
    codepoint: 'F0B15',
    deprecated: 'FALSE',
  },
  {
    id: 'DF0B3005-5E38-4E53-9025-981EFA45D8FD',
    name: 'alpha-n-box-outline',
    codepoint: 'F0C12',
    deprecated: 'FALSE',
  },
  {
    id: '929903C6-6867-4884-8A02-7D72F1C44F2D',
    name: 'alpha-n-circle',
    codepoint: 'F0C13',
    deprecated: 'FALSE',
  },
  {
    id: '8DA6710E-B31C-42C1-BB43-1B903D4B5CA5',
    name: 'alpha-n-circle-outline',
    codepoint: 'F0C14',
    deprecated: 'FALSE',
  },
  {
    id: '67F8B291-3AA9-4C70-AEAB-0150A40909FB',
    name: 'alpha-o',
    codepoint: 'F0AFC',
    deprecated: 'FALSE',
  },
  {
    id: '53B24801-62B0-46AC-9474-90DCA470E506',
    name: 'alpha-o-box',
    codepoint: 'F0B16',
    deprecated: 'FALSE',
  },
  {
    id: '9563FB80-4D77-4800-B7DF-CCFDD00A49DD',
    name: 'alpha-o-box-outline',
    codepoint: 'F0C15',
    deprecated: 'FALSE',
  },
  {
    id: '5DB486E7-89A5-45A6-8926-007B37DBC7D9',
    name: 'alpha-o-circle',
    codepoint: 'F0C16',
    deprecated: 'FALSE',
  },
  {
    id: '485D936E-F9F7-4D89-A2CC-2C11268EAC1A',
    name: 'alpha-o-circle-outline',
    codepoint: 'F0C17',
    deprecated: 'FALSE',
  },
  {
    id: '750E27F3-36FF-423A-8AAD-F2FE833D9028',
    name: 'alpha-p',
    codepoint: 'F0AFD',
    deprecated: 'FALSE',
  },
  {
    id: 'A2775802-5936-4F9A-9E7F-54D2EA8CC0E9',
    name: 'alpha-p-box',
    codepoint: 'F0B17',
    deprecated: 'FALSE',
  },
  {
    id: 'B9B61AA9-C657-4C73-8C75-2A01C08672A6',
    name: 'alpha-p-box-outline',
    codepoint: 'F0C18',
    deprecated: 'FALSE',
  },
  {
    id: '172BAE9F-C813-407E-A1D0-CBB548C3F9D8',
    name: 'alpha-p-circle',
    codepoint: 'F0C19',
    deprecated: 'FALSE',
  },
  {
    id: '5FF2B25B-8414-4FC9-BBEB-5D51901FB709',
    name: 'alpha-p-circle-outline',
    codepoint: 'F0C1A',
    deprecated: 'FALSE',
  },
  {
    id: '7DAD6E06-5D6E-4827-A20E-526F2F5AF4C9',
    name: 'alpha-q',
    codepoint: 'F0AFE',
    deprecated: 'FALSE',
  },
  {
    id: '4B916C98-B981-4822-90D2-3EADCAE01FA3',
    name: 'alpha-q-box',
    codepoint: 'F0B18',
    deprecated: 'FALSE',
  },
  {
    id: 'C30942DA-E2FB-41A1-A0A5-3FB004E8FC67',
    name: 'alpha-q-box-outline',
    codepoint: 'F0C1B',
    deprecated: 'FALSE',
  },
  {
    id: '03AA79A3-ADEF-4B0E-8023-455FAD721515',
    name: 'alpha-q-circle',
    codepoint: 'F0C1C',
    deprecated: 'FALSE',
  },
  {
    id: '43F96FE9-53D0-4C4E-AEC3-773656C97E41',
    name: 'alpha-q-circle-outline',
    codepoint: 'F0C1D',
    deprecated: 'FALSE',
  },
  {
    id: 'A1C326AD-B2A8-4A9A-98AA-B657C101EB2F',
    name: 'alpha-r',
    codepoint: 'F0AFF',
    deprecated: 'FALSE',
  },
  {
    id: '0089397E-6983-4161-9D06-77F3598119DD',
    name: 'alpha-r-box',
    codepoint: 'F0B19',
    deprecated: 'FALSE',
  },
  {
    id: '206DB27B-1F78-481F-8E8E-1A78F8221EF7',
    name: 'alpha-r-box-outline',
    codepoint: 'F0C1E',
    deprecated: 'FALSE',
  },
  {
    id: 'A0CA4573-5B82-4760-BB23-7D1413DE7257',
    name: 'alpha-r-circle',
    codepoint: 'F0C1F',
    deprecated: 'FALSE',
  },
  {
    id: '3EFEFA7D-EE69-4813-93AB-096C148CC74B',
    name: 'alpha-r-circle-outline',
    codepoint: 'F0C20',
    deprecated: 'FALSE',
  },
  {
    id: '5E4ECB99-9DA0-419C-AADE-F2F7AE91FF6A',
    name: 'alpha-s',
    codepoint: 'F0B00',
    deprecated: 'FALSE',
  },
  {
    id: '2BC531B2-987E-411F-B3AE-3686F24F3B05',
    name: 'alpha-s-box',
    codepoint: 'F0B1A',
    deprecated: 'FALSE',
  },
  {
    id: '839A6E89-0555-4B05-BADA-B0D0C665E885',
    name: 'alpha-s-box-outline',
    codepoint: 'F0C21',
    deprecated: 'FALSE',
  },
  {
    id: '2F13C710-300C-4287-9320-C84024C1002D',
    name: 'alpha-s-circle',
    codepoint: 'F0C22',
    deprecated: 'FALSE',
  },
  {
    id: '17018533-C274-4E75-86DB-2D0DB1F4B45E',
    name: 'alpha-s-circle-outline',
    codepoint: 'F0C23',
    deprecated: 'FALSE',
  },
  {
    id: 'DEAF6AD9-9AB9-4B59-B47D-830357A4B665',
    name: 'alpha-t',
    codepoint: 'F0B01',
    deprecated: 'FALSE',
  },
  {
    id: '3FDF524A-6293-4401-BEDE-7768370743CE',
    name: 'alpha-t-box',
    codepoint: 'F0B1B',
    deprecated: 'FALSE',
  },
  {
    id: '5AB643B8-7A4C-470B-9C0C-3A3E1E730623',
    name: 'alpha-t-box-outline',
    codepoint: 'F0C24',
    deprecated: 'FALSE',
  },
  {
    id: '6A41607E-FD2D-49EB-B6A5-67472ECF381E',
    name: 'alpha-t-circle',
    codepoint: 'F0C25',
    deprecated: 'FALSE',
  },
  {
    id: '63897F2D-C1BC-46D0-96D8-82CCC8AB8E9C',
    name: 'alpha-t-circle-outline',
    codepoint: 'F0C26',
    deprecated: 'FALSE',
  },
  {
    id: '7404138B-11FC-441F-9F64-2CADF3EED84F',
    name: 'alpha-u',
    codepoint: 'F0B02',
    deprecated: 'FALSE',
  },
  {
    id: 'B383312A-D001-4F27-95D9-9C705B7529E7',
    name: 'alpha-u-box',
    codepoint: 'F0B1C',
    deprecated: 'FALSE',
  },
  {
    id: 'CAA94F37-3C12-4A17-A0CF-F093E39F5463',
    name: 'alpha-u-box-outline',
    codepoint: 'F0C27',
    deprecated: 'FALSE',
  },
  {
    id: 'C47AD771-D938-41ED-B07A-28499959E3BF',
    name: 'alpha-u-circle',
    codepoint: 'F0C28',
    deprecated: 'FALSE',
  },
  {
    id: '12B388EE-036B-45AF-8390-122E6BA125CF',
    name: 'alpha-u-circle-outline',
    codepoint: 'F0C29',
    deprecated: 'FALSE',
  },
  {
    id: '6A261917-D952-4EFD-B8A4-9A91BCF67DE1',
    name: 'alpha-v',
    codepoint: 'F0B03',
    deprecated: 'FALSE',
  },
  {
    id: 'C3B68231-EFA7-4988-BED2-9C158A812AC4',
    name: 'alpha-v-box',
    codepoint: 'F0B1D',
    deprecated: 'FALSE',
  },
  {
    id: '784EF15E-8476-47B9-BACA-D440F60A371E',
    name: 'alpha-v-box-outline',
    codepoint: 'F0C2A',
    deprecated: 'FALSE',
  },
  {
    id: 'C97A782B-26C7-4732-804F-D7D5BE126D5D',
    name: 'alpha-v-circle',
    codepoint: 'F0C2B',
    deprecated: 'FALSE',
  },
  {
    id: 'F6CE363B-41A6-411A-9FDE-D36E24013012',
    name: 'alpha-v-circle-outline',
    codepoint: 'F0C2C',
    deprecated: 'FALSE',
  },
  {
    id: 'DFACCE73-8433-472E-AAB6-6471BBFDC82C',
    name: 'alpha-w',
    codepoint: 'F0B04',
    deprecated: 'FALSE',
  },
  {
    id: 'E63E39FF-8E9F-470F-9CDD-55B2703E620A',
    name: 'alpha-w-box',
    codepoint: 'F0B1E',
    deprecated: 'FALSE',
  },
  {
    id: 'B844FAFE-5491-4441-9C3E-23F2AB148E10',
    name: 'alpha-w-box-outline',
    codepoint: 'F0C2D',
    deprecated: 'FALSE',
  },
  {
    id: '8718E128-006E-4009-A718-A104B4C8E3A4',
    name: 'alpha-w-circle',
    codepoint: 'F0C2E',
    deprecated: 'FALSE',
  },
  {
    id: '24152C0F-8019-42D1-B4F2-3917388A8987',
    name: 'alpha-w-circle-outline',
    codepoint: 'F0C2F',
    deprecated: 'FALSE',
  },
  {
    id: '44344AAE-07D8-455B-AA2E-68474E8829FE',
    name: 'alpha-x',
    codepoint: 'F0B05',
    deprecated: 'FALSE',
  },
  {
    id: '25BBD26F-C8D4-4E4D-899C-57D910EECA55',
    name: 'alpha-x-box',
    codepoint: 'F0B1F',
    deprecated: 'FALSE',
  },
  {
    id: '98537673-0A9D-4982-A0E0-BFD69A0C1401',
    name: 'alpha-x-box-outline',
    codepoint: 'F0C30',
    deprecated: 'FALSE',
  },
  {
    id: 'BF0C5599-EFCE-44ED-AD08-3CE99DFC9802',
    name: 'alpha-x-circle',
    codepoint: 'F0C31',
    deprecated: 'FALSE',
  },
  {
    id: '8B9761E9-9101-4850-8DCA-D8B63193A20B',
    name: 'alpha-x-circle-outline',
    codepoint: 'F0C32',
    deprecated: 'FALSE',
  },
  {
    id: 'CD6A13CC-DA14-42AD-A4A0-2A99EA3E5E3D',
    name: 'alpha-y',
    codepoint: 'F0B06',
    deprecated: 'FALSE',
  },
  {
    id: '00418BEF-11B7-4644-8F57-3CBB7D8F9760',
    name: 'alpha-y-box',
    codepoint: 'F0B20',
    deprecated: 'FALSE',
  },
  {
    id: '929FF46B-C6B6-4973-BB7E-44C232F149AC',
    name: 'alpha-y-box-outline',
    codepoint: 'F0C33',
    deprecated: 'FALSE',
  },
  {
    id: '07D04396-8CE9-47A0-80BF-9D7055655F81',
    name: 'alpha-y-circle',
    codepoint: 'F0C34',
    deprecated: 'FALSE',
  },
  {
    id: '94DF1C7C-7FDE-4AD8-B6F9-B23C3C4DA478',
    name: 'alpha-y-circle-outline',
    codepoint: 'F0C35',
    deprecated: 'FALSE',
  },
  {
    id: 'E82C7336-9ECE-4BA9-84BA-AA229FBF4E37',
    name: 'alpha-z',
    codepoint: 'F0B07',
    deprecated: 'FALSE',
  },
  {
    id: '175F93C9-39AA-4076-A997-B1984D5CE960',
    name: 'alpha-z-box',
    codepoint: 'F0B21',
    deprecated: 'FALSE',
  },
  {
    id: 'EB6335C7-9EA1-4EB3-BCA5-2DF54F8B50C8',
    name: 'alpha-z-box-outline',
    codepoint: 'F0C36',
    deprecated: 'FALSE',
  },
  {
    id: '1F60D37E-2D88-48D1-A291-D3F59D171E55',
    name: 'alpha-z-circle',
    codepoint: 'F0C37',
    deprecated: 'FALSE',
  },
  {
    id: '63AF9496-BE67-43F7-9DE4-341E0EDC0C62',
    name: 'alpha-z-circle-outline',
    codepoint: 'F0C38',
    deprecated: 'FALSE',
  },
  {
    id: '68AC0195-57C1-4759-8814-FA18DFE0351B',
    name: 'alphabet-aurebesh',
    codepoint: 'F132C',
    deprecated: 'FALSE',
  },
  {
    id: '197B613F-AF17-41DE-B2A6-7FCB8D86C366',
    name: 'alphabet-cyrillic',
    codepoint: 'F132D',
    deprecated: 'FALSE',
  },
  {
    id: '5E1EDFBD-2E67-41E1-8E23-F835614DBBD9',
    name: 'alphabet-greek',
    codepoint: 'F132E',
    deprecated: 'FALSE',
  },
  {
    id: '07851190-1E8F-43D3-A5BF-01A120C0A3FB',
    name: 'alphabet-latin',
    codepoint: 'F132F',
    deprecated: 'FALSE',
  },
  {
    id: '169593B8-F579-4167-B49C-5F353BBABB00',
    name: 'alphabet-piqad',
    codepoint: 'F1330',
    deprecated: 'FALSE',
  },
  {
    id: '48C4C59F-80F3-454B-94B2-1951506F38C1',
    name: 'alphabet-tengwar',
    codepoint: 'F1337',
    deprecated: 'FALSE',
  },
  {
    id: 'EAD8BAC5-EE7C-4553-9316-F45094CF9C7A',
    name: 'alphabetical',
    codepoint: 'F002C',
    deprecated: 'FALSE',
  },
  {
    id: '5C6F0A6B-362A-4EBF-9E0C-57E7405429AE',
    name: 'alphabetical-off',
    codepoint: 'F100C',
    deprecated: 'FALSE',
  },
  {
    id: '87F9C7D3-2B22-45A0-A1A8-1060ECA2B0DD',
    name: 'alphabetical-variant',
    codepoint: 'F100D',
    deprecated: 'FALSE',
  },
  {
    id: '5C408382-5124-4A1E-9603-C510614D4C63',
    name: 'alphabetical-variant-off',
    codepoint: 'F100E',
    deprecated: 'FALSE',
  },
  {
    id: '1F7A2F31-3549-4F3C-A95E-E7356033A601',
    name: 'altimeter',
    codepoint: 'F05D7',
    deprecated: 'FALSE',
  },
  {
    id: 'B0DB559B-A9D4-473A-AF6D-C72559CCC9D9',
    name: 'ambulance',
    codepoint: 'F002F',
    deprecated: 'FALSE',
  },
  {
    id: '0F3F828D-7F80-4571-A5B5-296FAC62FFB9',
    name: 'ammunition',
    codepoint: 'F0CE8',
    deprecated: 'FALSE',
  },
  {
    id: 'CC5AC69B-0EAA-454A-8757-4F670F478952',
    name: 'ampersand',
    codepoint: 'F0A8D',
    deprecated: 'FALSE',
  },
  {
    id: '4A952C8C-7A6F-4028-A974-48E4DCE299CF',
    name: 'amplifier',
    codepoint: 'F0030',
    deprecated: 'FALSE',
  },
  {
    id: 'E2914725-030B-4979-BCDE-87FCD4435F7F',
    name: 'amplifier-off',
    codepoint: 'F11B5',
    deprecated: 'FALSE',
  },
  {
    id: '8A3C8CCE-D798-446B-A283-90129AD847C9',
    name: 'anchor',
    codepoint: 'F0031',
    deprecated: 'FALSE',
  },
  {
    id: '4633B767-FF29-411D-8C04-69057C6B65B2',
    name: 'android',
    codepoint: 'F0032',
    deprecated: 'TRUE',
  },
  {
    id: 'B156B15D-86F9-40E0-8B8E-421F78BE5509',
    name: 'android-studio',
    codepoint: 'F0034',
    deprecated: 'TRUE',
  },
  {
    id: 'B4A236B3-E14B-4A64-8EDD-A48128013388',
    name: 'angle-acute',
    codepoint: 'F0937',
    deprecated: 'FALSE',
  },
  {
    id: '7A6B88D9-B385-4A6B-84C8-8280456944E4',
    name: 'angle-obtuse',
    codepoint: 'F0938',
    deprecated: 'FALSE',
  },
  {
    id: '77550809-9FBB-4283-BA57-7C48AB2B86C0',
    name: 'angle-right',
    codepoint: 'F0939',
    deprecated: 'FALSE',
  },
  {
    id: '549F8173-7E91-411F-9ECF-D04D2B64B386',
    name: 'angular',
    codepoint: 'F06B2',
    deprecated: 'TRUE',
  },
  {
    id: '8A82CBEA-C1B3-40C2-96C2-DED8D83DA446',
    name: 'angularjs',
    codepoint: 'F06BF',
    deprecated: 'TRUE',
  },
  {
    id: '4FE8F135-8FF9-427B-8857-122FBB0A300A',
    name: 'animation',
    codepoint: 'F05D8',
    deprecated: 'FALSE',
  },
  {
    id: '5DCFE57B-82CA-401A-A797-3C12F1BF4080',
    name: 'animation-outline',
    codepoint: 'F0A8F',
    deprecated: 'FALSE',
  },
  {
    id: '093AE881-42E2-46B9-B833-894DDC2FE368',
    name: 'animation-play',
    codepoint: 'F093A',
    deprecated: 'FALSE',
  },
  {
    id: '423AD280-5A46-49AF-B4C7-20C5E1640002',
    name: 'animation-play-outline',
    codepoint: 'F0A90',
    deprecated: 'FALSE',
  },
  {
    id: '34EEAB55-08C0-4C84-A580-CAF1FA926D94',
    name: 'ansible',
    codepoint: 'F109A',
    deprecated: 'TRUE',
  },
  {
    id: 'B6F97155-147F-4D03-9C71-7668E5EA2712',
    name: 'antenna',
    codepoint: 'F1119',
    deprecated: 'FALSE',
  },
  {
    id: 'C72FF17B-D0E1-4641-9802-B6BA993D5910',
    name: 'anvil',
    codepoint: 'F089B',
    deprecated: 'FALSE',
  },
  {
    id: 'B94C01C7-0851-4FD4-AEFD-CCD63F65C093',
    name: 'apache-kafka',
    codepoint: 'F100F',
    deprecated: 'TRUE',
  },
  {
    id: 'ABE80259-E64D-4BF4-BC6E-1D8FFA16636F',
    name: 'api',
    codepoint: 'F109B',
    deprecated: 'FALSE',
  },
  {
    id: '22B88F3A-28F4-41FA-A63B-721699E2A8B2',
    name: 'api-off',
    codepoint: 'F1257',
    deprecated: 'FALSE',
  },
  {
    id: '2829275C-A01C-42CD-A195-447629791D04',
    name: 'apple',
    codepoint: 'F0035',
    deprecated: 'TRUE',
  },
  {
    id: 'FAEC5B00-46A8-49AD-87DA-765891B9E387',
    name: 'apple-finder',
    codepoint: 'F0036',
    deprecated: 'TRUE',
  },
  {
    id: 'BDB8AF3D-28BD-4FF2-AED0-36108C1B0543',
    name: 'apple-icloud',
    codepoint: 'F0038',
    deprecated: 'TRUE',
  },
  {
    id: '9B0B7248-3416-4D43-9CE1-13D70949EAA1',
    name: 'apple-ios',
    codepoint: 'F0037',
    deprecated: 'TRUE',
  },
  {
    id: '070FF4A9-7726-4682-8ABD-AC1800B8D83E',
    name: 'apple-keyboard-caps',
    codepoint: 'F0632',
    deprecated: 'FALSE',
  },
  {
    id: '03043022-A59D-4E31-B385-E6D94D3D9B92',
    name: 'apple-keyboard-command',
    codepoint: 'F0633',
    deprecated: 'FALSE',
  },
  {
    id: 'F24769F6-25C3-4244-8C64-13A514E7CAAD',
    name: 'apple-keyboard-control',
    codepoint: 'F0634',
    deprecated: 'FALSE',
  },
  {
    id: 'C06A94E2-9606-484B-BC0D-D0B9A2FBA76F',
    name: 'apple-keyboard-option',
    codepoint: 'F0635',
    deprecated: 'FALSE',
  },
  {
    id: '5AF5B660-DDD5-446A-8AC9-102776DBBBC9',
    name: 'apple-keyboard-shift',
    codepoint: 'F0636',
    deprecated: 'FALSE',
  },
  {
    id: 'F79DE68B-BB98-41F7-9B5D-69C8926AB9B0',
    name: 'apple-safari',
    codepoint: 'F0039',
    deprecated: 'TRUE',
  },
  {
    id: 'DD3492E1-1F83-4E41-8BA9-9E6A1380D235',
    name: 'application',
    codepoint: 'F08C6',
    deprecated: 'FALSE',
  },
  {
    id: 'B5216901-ECCB-41B3-8F35-BFA701ECDDBC',
    name: 'application-array',
    codepoint: 'F10F5',
    deprecated: 'FALSE',
  },
  {
    id: 'D07A9442-941E-47BB-8B31-F2EB41DE069E',
    name: 'application-array-outline',
    codepoint: 'F10F6',
    deprecated: 'FALSE',
  },
  {
    id: '6453ED46-EC58-4FE8-94C3-1AC170E42152',
    name: 'application-braces',
    codepoint: 'F10F7',
    deprecated: 'FALSE',
  },
  {
    id: '3727A772-B282-4785-B291-A7C7EBCB25A8',
    name: 'application-braces-outline',
    codepoint: 'F10F8',
    deprecated: 'FALSE',
  },
  {
    id: '86A1CD17-B112-46F8-A076-424D528A25AA',
    name: 'application-brackets',
    codepoint: 'F0C8B',
    deprecated: 'FALSE',
  },
  {
    id: '87F83807-58BE-49C5-981B-CBDFD8EABE4A',
    name: 'application-brackets-outline',
    codepoint: 'F0C8C',
    deprecated: 'FALSE',
  },
  {
    id: '38278159-AC2C-433A-9335-95B05C1F6F56',
    name: 'application-cog',
    codepoint: 'F0675',
    deprecated: 'FALSE',
  },
  {
    id: 'A3B1663D-87F0-4E57-BAF4-5CB9D0CC6B17',
    name: 'application-cog-outline',
    codepoint: 'F1577',
    deprecated: 'FALSE',
  },
  {
    id: 'AAF23976-7C9D-4AFA-BE25-CCD225A95EEA',
    name: 'application-edit',
    codepoint: 'F00AE',
    deprecated: 'FALSE',
  },
  {
    id: 'FBD11E2A-7F1F-4CE2-B17D-B3623D4FEA43',
    name: 'application-edit-outline',
    codepoint: 'F0619',
    deprecated: 'FALSE',
  },
  {
    id: '19852AF4-396B-4BA7-B9A1-3E412D49290A',
    name: 'application-export',
    codepoint: 'F0DAD',
    deprecated: 'FALSE',
  },
  {
    id: '0870BDBC-060F-49E5-B72C-CA06949643AF',
    name: 'application-import',
    codepoint: 'F0DAE',
    deprecated: 'FALSE',
  },
  {
    id: '55C0C50F-9F4D-44C4-A84B-C79F645A8604',
    name: 'application-outline',
    codepoint: 'F0614',
    deprecated: 'FALSE',
  },
  {
    id: '2A524868-FBD4-442F-8E4C-413A1C8DDDEE',
    name: 'application-parentheses',
    codepoint: 'F10F9',
    deprecated: 'FALSE',
  },
  {
    id: '9FA63D08-5057-4AA3-9FD0-367B8AE55352',
    name: 'application-parentheses-outline',
    codepoint: 'F10FA',
    deprecated: 'FALSE',
  },
  {
    id: '0AA8EAC5-6700-47B6-A38B-0E58CFA0CFE7',
    name: 'application-settings',
    codepoint: 'F0B60',
    deprecated: 'FALSE',
  },
  {
    id: 'EB7E0628-A5CC-4E23-9AD1-CC35CC4E3722',
    name: 'application-settings-outline',
    codepoint: 'F1555',
    deprecated: 'FALSE',
  },
  {
    id: 'A42EE2E1-D139-426F-9B59-C21E8C185DA7',
    name: 'application-variable',
    codepoint: 'F10FB',
    deprecated: 'FALSE',
  },
  {
    id: 'DF02C215-E96B-40CA-B372-9767965F11D3',
    name: 'application-variable-outline',
    codepoint: 'F10FC',
    deprecated: 'FALSE',
  },
  {
    id: 'B5A9F91C-21A0-415A-AC77-B4318137C32D',
    name: 'approximately-equal',
    codepoint: 'F0F9E',
    deprecated: 'FALSE',
  },
  {
    id: '8EE13AEC-B8FF-4E25-9D5B-8447B0310133',
    name: 'approximately-equal-box',
    codepoint: 'F0F9F',
    deprecated: 'FALSE',
  },
  {
    id: '7F5B9650-26E7-4A88-A6D1-2C338778651D',
    name: 'apps',
    codepoint: 'F003B',
    deprecated: 'FALSE',
  },
  {
    id: '3EEC66E4-C03F-4701-B151-1C8048D19505',
    name: 'apps-box',
    codepoint: 'F0D46',
    deprecated: 'FALSE',
  },
  {
    id: 'B7F8E18D-C18D-4EA1-BABE-BE0E48BDCF2D',
    name: 'arch',
    codepoint: 'F08C7',
    deprecated: 'TRUE',
  },
  {
    id: '9C2AD144-9997-4169-983C-879DCCD62760',
    name: 'archive',
    codepoint: 'F003C',
    deprecated: 'FALSE',
  },
  {
    id: '41367FBC-DCE3-4202-AF33-3AD576C3FD7C',
    name: 'archive-alert',
    codepoint: 'F14FD',
    deprecated: 'FALSE',
  },
  {
    id: '1D17B03B-9147-4067-8F5E-88773D68289E',
    name: 'archive-alert-outline',
    codepoint: 'F14FE',
    deprecated: 'FALSE',
  },
  {
    id: '237B205E-C03C-424A-93F9-8C771FE01A38',
    name: 'archive-arrow-down',
    codepoint: 'F1259',
    deprecated: 'FALSE',
  },
  {
    id: '133BA980-E797-4DB3-9C92-F59AC3DB3399',
    name: 'archive-arrow-down-outline',
    codepoint: 'F125A',
    deprecated: 'FALSE',
  },
  {
    id: '43E8F715-D3DB-4CAA-9355-88E22C7E4D28',
    name: 'archive-arrow-up',
    codepoint: 'F125B',
    deprecated: 'FALSE',
  },
  {
    id: '643EF8E3-148D-4858-85C6-A965822B74CF',
    name: 'archive-arrow-up-outline',
    codepoint: 'F125C',
    deprecated: 'FALSE',
  },
  {
    id: '656CE7E5-1C31-4428-A609-703A2AA39DA7',
    name: 'archive-cancel',
    codepoint: 'F174B',
    deprecated: 'FALSE',
  },
  {
    id: '2D1FAE99-E9DD-480C-B144-8B7FC7AB99C4',
    name: 'archive-cancel-outline',
    codepoint: 'F174C',
    deprecated: 'FALSE',
  },
  {
    id: '03F9B9A5-04B8-4A24-AE38-87BE165BBE35',
    name: 'archive-check',
    codepoint: 'F174D',
    deprecated: 'FALSE',
  },
  {
    id: 'F5A70DE2-BC31-4FCA-AE8E-5295D2F5DB7B',
    name: 'archive-check-outline',
    codepoint: 'F174E',
    deprecated: 'FALSE',
  },
  {
    id: '8DBB6350-60C3-4433-9805-C8AB712950C4',
    name: 'archive-clock',
    codepoint: 'F174F',
    deprecated: 'FALSE',
  },
  {
    id: '0087686D-DB93-46D3-AD96-C8BA2F17E0D6',
    name: 'archive-clock-outline',
    codepoint: 'F1750',
    deprecated: 'FALSE',
  },
  {
    id: '3B5F3441-7A3E-4CF1-933B-F5FCF801CB8E',
    name: 'archive-cog',
    codepoint: 'F1751',
    deprecated: 'FALSE',
  },
  {
    id: 'FD6CA806-C4D6-4D5E-AA3B-4292EE225734',
    name: 'archive-cog-outline',
    codepoint: 'F1752',
    deprecated: 'FALSE',
  },
  {
    id: '6FC882E2-B34D-47ED-8E18-6CD197A0D709',
    name: 'archive-edit',
    codepoint: 'F1753',
    deprecated: 'FALSE',
  },
  {
    id: '18D5853A-26A8-416D-9029-9F216DA3316B',
    name: 'archive-edit-outline',
    codepoint: 'F1754',
    deprecated: 'FALSE',
  },
  {
    id: '82B3AAAD-92D1-4524-98F4-F29AFB1A5BFA',
    name: 'archive-eye',
    codepoint: 'F1755',
    deprecated: 'FALSE',
  },
  {
    id: 'A61C4171-FA5D-4273-A1F5-72CC6506BE70',
    name: 'archive-eye-outline',
    codepoint: 'F1756',
    deprecated: 'FALSE',
  },
  {
    id: '52016536-6E90-4C85-891F-5A9183313497',
    name: 'archive-lock',
    codepoint: 'F1757',
    deprecated: 'FALSE',
  },
  {
    id: 'DE180687-AD0B-4DE0-8156-EC22729FE7C3',
    name: 'archive-lock-open',
    codepoint: 'F1758',
    deprecated: 'FALSE',
  },
  {
    id: '5128174B-10F3-4057-96AE-4DD993B4317F',
    name: 'archive-lock-open-outline',
    codepoint: 'F1759',
    deprecated: 'FALSE',
  },
  {
    id: 'E5F9A56B-CB7E-4EEC-B03A-571F9704E32E',
    name: 'archive-lock-outline',
    codepoint: 'F175A',
    deprecated: 'FALSE',
  },
  {
    id: 'FDC11926-2947-4451-A7CE-2466D7CB8E07',
    name: 'archive-marker',
    codepoint: 'F175B',
    deprecated: 'FALSE',
  },
  {
    id: '8639E3F6-3A01-47DF-9787-F80E273C99A2',
    name: 'archive-marker-outline',
    codepoint: 'F175C',
    deprecated: 'FALSE',
  },
  {
    id: '0C3AB42B-5B81-46D3-A4BB-DB367FC99B3F',
    name: 'archive-minus',
    codepoint: 'F175D',
    deprecated: 'FALSE',
  },
  {
    id: 'F0B3A03C-4591-4F17-8FD7-CD65BC990596',
    name: 'archive-minus-outline',
    codepoint: 'F175E',
    deprecated: 'FALSE',
  },
  {
    id: '30D759AA-6F1F-463A-9768-8D92F607207F',
    name: 'archive-music',
    codepoint: 'F175F',
    deprecated: 'FALSE',
  },
  {
    id: 'B039CC1C-A987-4383-874C-587493FD807D',
    name: 'archive-music-outline',
    codepoint: 'F1760',
    deprecated: 'FALSE',
  },
  {
    id: 'C99E212A-CA2F-4E72-B727-B3B87C17B6B2',
    name: 'archive-off',
    codepoint: 'F1761',
    deprecated: 'FALSE',
  },
  {
    id: '6E4F081A-2C98-4B93-B617-F1077839E94E',
    name: 'archive-off-outline',
    codepoint: 'F1762',
    deprecated: 'FALSE',
  },
  {
    id: '4B8AAB0C-9EB8-44D9-B1DF-C2EE6ACB91BE',
    name: 'archive-outline',
    codepoint: 'F120E',
    deprecated: 'FALSE',
  },
  {
    id: 'FA4EDA1A-8E9B-47EA-9436-B75CF8BB5B21',
    name: 'archive-plus',
    codepoint: 'F1763',
    deprecated: 'FALSE',
  },
  {
    id: 'C2898E88-9D7F-44D1-A653-539A4B5481EC',
    name: 'archive-plus-outline',
    codepoint: 'F1764',
    deprecated: 'FALSE',
  },
  {
    id: '8BFF742C-7BD5-46C0-B280-0813A2431850',
    name: 'archive-refresh',
    codepoint: 'F1765',
    deprecated: 'FALSE',
  },
  {
    id: 'AFCA7BDE-7A75-4C5E-BC61-32662E4B011C',
    name: 'archive-refresh-outline',
    codepoint: 'F1766',
    deprecated: 'FALSE',
  },
  {
    id: 'F856A6DD-E894-4B78-BE97-4239C01CECF7',
    name: 'archive-remove',
    codepoint: 'F1767',
    deprecated: 'FALSE',
  },
  {
    id: '68E7CD1A-7530-4710-8A10-D9D1CF5EFB7F',
    name: 'archive-remove-outline',
    codepoint: 'F1768',
    deprecated: 'FALSE',
  },
  {
    id: '28C866C2-139E-4AF4-8E3D-F23CFE556B59',
    name: 'archive-search',
    codepoint: 'F1769',
    deprecated: 'FALSE',
  },
  {
    id: '6705AD82-194D-411F-8822-EC3DA2379582',
    name: 'archive-search-outline',
    codepoint: 'F176A',
    deprecated: 'FALSE',
  },
  {
    id: '1F72C97E-9F21-4096-B59B-CD23519B1367',
    name: 'archive-settings',
    codepoint: 'F176B',
    deprecated: 'FALSE',
  },
  {
    id: 'E07DE8F7-F3D7-4FCB-95EC-5D6C52CAFE32',
    name: 'archive-settings-outline',
    codepoint: 'F176C',
    deprecated: 'FALSE',
  },
  {
    id: '08484938-1011-46B2-9756-2C227B9BCAAC',
    name: 'archive-star',
    codepoint: 'F176D',
    deprecated: 'FALSE',
  },
  {
    id: '05768C3B-09EA-40A8-AC27-F13F734FBB4A',
    name: 'archive-star-outline',
    codepoint: 'F176E',
    deprecated: 'FALSE',
  },
  {
    id: 'BCB51994-105E-404E-96D2-1242AEB13CDB',
    name: 'archive-sync',
    codepoint: 'F176F',
    deprecated: 'FALSE',
  },
  {
    id: 'BB297982-E355-4AEE-9D17-CD57BDB0625A',
    name: 'archive-sync-outline',
    codepoint: 'F1770',
    deprecated: 'FALSE',
  },
  {
    id: 'F7613EF8-368F-4AA6-B833-7246A01C3961',
    name: 'arm-flex',
    codepoint: 'F0FD7',
    deprecated: 'FALSE',
  },
  {
    id: 'D64BE31F-BA1D-48E0-B747-A1422392EC2D',
    name: 'arm-flex-outline',
    codepoint: 'F0FD6',
    deprecated: 'FALSE',
  },
  {
    id: '7F57A003-5F4E-46CF-9099-51B8D05381F8',
    name: 'arrange-bring-forward',
    codepoint: 'F003D',
    deprecated: 'FALSE',
  },
  {
    id: '679C0678-9688-49FD-BA71-BED740A09267',
    name: 'arrange-bring-to-front',
    codepoint: 'F003E',
    deprecated: 'FALSE',
  },
  {
    id: '677B1C90-BB0B-4F73-8EEA-D6E8FA709A6D',
    name: 'arrange-send-backward',
    codepoint: 'F003F',
    deprecated: 'FALSE',
  },
  {
    id: '86145497-4CAA-41D6-A14E-0DD3FEE7721E',
    name: 'arrange-send-to-back',
    codepoint: 'F0040',
    deprecated: 'FALSE',
  },
  {
    id: '5540E986-54E7-4FBF-A62F-9937B9E4F78D',
    name: 'arrow-all',
    codepoint: 'F0041',
    deprecated: 'FALSE',
  },
  {
    id: '25DD11B1-DE14-49E3-A54F-B01DCC45798D',
    name: 'arrow-bottom-left',
    codepoint: 'F0042',
    deprecated: 'FALSE',
  },
  {
    id: '2EB5C181-D681-49D8-8D2D-C57840531E7F',
    name: 'arrow-bottom-left-bold-box',
    codepoint: 'F1964',
    deprecated: 'FALSE',
  },
  {
    id: 'EDB8949D-3883-4491-BD88-C3D7C8BA2A3E',
    name: 'arrow-bottom-left-bold-box-outline',
    codepoint: 'F1965',
    deprecated: 'FALSE',
  },
  {
    id: 'B34E6142-64AF-43FE-A565-2FAEEA35B800',
    name: 'arrow-bottom-left-bold-outline',
    codepoint: 'F09B7',
    deprecated: 'FALSE',
  },
  {
    id: '73858F0F-F08D-4D54-A3C1-54E794E9C097',
    name: 'arrow-bottom-left-thick',
    codepoint: 'F09B8',
    deprecated: 'FALSE',
  },
  {
    id: '33169DB7-00C0-42E0-8C2D-2F50902FA15F',
    name: 'arrow-bottom-left-thin',
    codepoint: 'F19B6',
    deprecated: 'FALSE',
  },
  {
    id: 'D2582EA8-570A-4CB9-81A0-5DB3308BF6BD',
    name: 'arrow-bottom-left-thin-circle-outline',
    codepoint: 'F1596',
    deprecated: 'FALSE',
  },
  {
    id: 'F7CB7031-655F-43BE-9165-F6C2CEC047B4',
    name: 'arrow-bottom-right',
    codepoint: 'F0043',
    deprecated: 'FALSE',
  },
  {
    id: '0A3C9B3E-5A92-45A9-AE11-63B0512981FB',
    name: 'arrow-bottom-right-bold-box',
    codepoint: 'F1966',
    deprecated: 'FALSE',
  },
  {
    id: 'C7024F9B-C057-444C-828C-5881C59183AE',
    name: 'arrow-bottom-right-bold-box-outline',
    codepoint: 'F1967',
    deprecated: 'FALSE',
  },
  {
    id: 'ABD34714-B566-4EDF-B876-93BFB6D254CB',
    name: 'arrow-bottom-right-bold-outline',
    codepoint: 'F09B9',
    deprecated: 'FALSE',
  },
  {
    id: '01AD77CD-8F92-4E32-A752-387203BC9FF8',
    name: 'arrow-bottom-right-thick',
    codepoint: 'F09BA',
    deprecated: 'FALSE',
  },
  {
    id: '38BFAB4E-F7D3-45AE-BD45-39385BB23EE2',
    name: 'arrow-bottom-right-thin',
    codepoint: 'F19B7',
    deprecated: 'FALSE',
  },
  {
    id: '26479C31-47EF-4EDA-B11E-323B136B7A6B',
    name: 'arrow-bottom-right-thin-circle-outline',
    codepoint: 'F1595',
    deprecated: 'FALSE',
  },
  {
    id: '86BB43B4-910B-4174-8455-661875054171',
    name: 'arrow-collapse',
    codepoint: 'F0615',
    deprecated: 'FALSE',
  },
  {
    id: '54364780-8F31-4B23-8085-ABE21EA55926',
    name: 'arrow-collapse-all',
    codepoint: 'F0044',
    deprecated: 'FALSE',
  },
  {
    id: '9989309E-22E1-46F6-B710-6717D1C5EA11',
    name: 'arrow-collapse-down',
    codepoint: 'F0792',
    deprecated: 'FALSE',
  },
  {
    id: '56953ABE-D834-4DC6-A44B-87A3BF1181BA',
    name: 'arrow-collapse-horizontal',
    codepoint: 'F084C',
    deprecated: 'FALSE',
  },
  {
    id: '9F794864-D790-48F3-A8B4-31AEC3BB17FD',
    name: 'arrow-collapse-left',
    codepoint: 'F0793',
    deprecated: 'FALSE',
  },
  {
    id: 'FFED25A5-FD40-4274-9EE3-6B26F5C03584',
    name: 'arrow-collapse-right',
    codepoint: 'F0794',
    deprecated: 'FALSE',
  },
  {
    id: '1E02D94D-6931-4CB8-8836-5276B076D811',
    name: 'arrow-collapse-up',
    codepoint: 'F0795',
    deprecated: 'FALSE',
  },
  {
    id: '1D7700E7-2963-479F-81E1-910E2566894F',
    name: 'arrow-collapse-vertical',
    codepoint: 'F084D',
    deprecated: 'FALSE',
  },
  {
    id: '61D65F86-CEB6-4CCC-8B88-00A4D0AD37C4',
    name: 'arrow-decision',
    codepoint: 'F09BB',
    deprecated: 'FALSE',
  },
  {
    id: 'B115DB5B-AAF9-4C39-BC44-9E573E3154A1',
    name: 'arrow-decision-auto',
    codepoint: 'F09BC',
    deprecated: 'FALSE',
  },
  {
    id: 'FA34CDF8-9B5D-40D2-97D5-00F41438A185',
    name: 'arrow-decision-auto-outline',
    codepoint: 'F09BD',
    deprecated: 'FALSE',
  },
  {
    id: '7731818A-2C14-4B22-8758-9C7604BA6F3B',
    name: 'arrow-decision-outline',
    codepoint: 'F09BE',
    deprecated: 'FALSE',
  },
  {
    id: '3821BF79-5857-47BE-84E3-A100B7247535',
    name: 'arrow-down',
    codepoint: 'F0045',
    deprecated: 'FALSE',
  },
  {
    id: 'CA8684F7-5654-4AE0-9840-9C3AD9611D19',
    name: 'arrow-down-bold',
    codepoint: 'F072E',
    deprecated: 'FALSE',
  },
  {
    id: '545DF72E-705C-46AC-BABE-94F6C304CFAB',
    name: 'arrow-down-bold-box',
    codepoint: 'F072F',
    deprecated: 'FALSE',
  },
  {
    id: '2D68AA5F-ACA1-45AA-873B-AD077377E698',
    name: 'arrow-down-bold-box-outline',
    codepoint: 'F0730',
    deprecated: 'FALSE',
  },
  {
    id: 'E4AFC38F-01B3-452D-BDD7-A08EA66D53FA',
    name: 'arrow-down-bold-circle',
    codepoint: 'F0047',
    deprecated: 'FALSE',
  },
  {
    id: '1DA60E82-C1F0-449C-8157-7014A7C9E749',
    name: 'arrow-down-bold-circle-outline',
    codepoint: 'F0048',
    deprecated: 'FALSE',
  },
  {
    id: '1F47DB52-28B0-40B7-836F-F6F2C58F2412',
    name: 'arrow-down-bold-hexagon-outline',
    codepoint: 'F0049',
    deprecated: 'FALSE',
  },
  {
    id: '517A69FA-E66C-469C-908F-E0CD723EBE8A',
    name: 'arrow-down-bold-outline',
    codepoint: 'F09BF',
    deprecated: 'FALSE',
  },
  {
    id: '04449961-F211-4FB7-BD87-EDA5F828B41D',
    name: 'arrow-down-box',
    codepoint: 'F06C0',
    deprecated: 'FALSE',
  },
  {
    id: '51D021A8-C3E1-477C-8BBF-DD4B84549515',
    name: 'arrow-down-circle',
    codepoint: 'F0CDB',
    deprecated: 'FALSE',
  },
  {
    id: 'E7EF8487-25AE-4CD6-8D04-4F90C60B3DCC',
    name: 'arrow-down-circle-outline',
    codepoint: 'F0CDC',
    deprecated: 'FALSE',
  },
  {
    id: '34BD0C0B-AF54-4BFC-A539-35D67612C4AC',
    name: 'arrow-down-drop-circle',
    codepoint: 'F004A',
    deprecated: 'FALSE',
  },
  {
    id: '65BE2EE5-23A5-49F8-A7A2-AEDD25C9C661',
    name: 'arrow-down-drop-circle-outline',
    codepoint: 'F004B',
    deprecated: 'FALSE',
  },
  {
    id: '366A6689-1806-4A63-942C-C7CB82AD6C9C',
    name: 'arrow-down-left',
    codepoint: 'F17A1',
    deprecated: 'FALSE',
  },
  {
    id: 'ADB762F4-4A0D-46D2-8B63-979B496DD373',
    name: 'arrow-down-left-bold',
    codepoint: 'F17A2',
    deprecated: 'FALSE',
  },
  {
    id: '28795CBE-011D-440C-9D9C-A1D50C4121B7',
    name: 'arrow-down-right',
    codepoint: 'F17A3',
    deprecated: 'FALSE',
  },
  {
    id: '2104E4B3-3A34-44AE-B073-B041FDFE5D41',
    name: 'arrow-down-right-bold',
    codepoint: 'F17A4',
    deprecated: 'FALSE',
  },
  {
    id: 'E345145F-AD51-4B6F-8F7B-EC22C34E4E89',
    name: 'arrow-down-thick',
    codepoint: 'F0046',
    deprecated: 'FALSE',
  },
  {
    id: '3419199D-CEAB-4084-AB62-A2EA908D40EB',
    name: 'arrow-down-thin',
    codepoint: 'F19B3',
    deprecated: 'FALSE',
  },
  {
    id: '6BDA2640-A009-416F-95F8-EE91CB931B66',
    name: 'arrow-down-thin-circle-outline',
    codepoint: 'F1599',
    deprecated: 'FALSE',
  },
  {
    id: 'F75C0670-7074-4756-BF30-7840D1C47E09',
    name: 'arrow-expand',
    codepoint: 'F0616',
    deprecated: 'FALSE',
  },
  {
    id: '14842517-5BC4-49B6-86F2-8D6DC8870786',
    name: 'arrow-expand-all',
    codepoint: 'F004C',
    deprecated: 'FALSE',
  },
  {
    id: '2338FC6A-1234-4A1F-AF6D-CFD45C92A2DF',
    name: 'arrow-expand-down',
    codepoint: 'F0796',
    deprecated: 'FALSE',
  },
  {
    id: 'B48C3976-1304-4E32-9D69-0062A48018FB',
    name: 'arrow-expand-horizontal',
    codepoint: 'F084E',
    deprecated: 'FALSE',
  },
  {
    id: 'FDF3C8E5-7AD6-4428-90EC-9426E56B5478',
    name: 'arrow-expand-left',
    codepoint: 'F0797',
    deprecated: 'FALSE',
  },
  {
    id: 'FF7885EC-2A34-4149-A4F8-626135606D7F',
    name: 'arrow-expand-right',
    codepoint: 'F0798',
    deprecated: 'FALSE',
  },
  {
    id: 'C13A62FF-DCC7-40DA-9326-C7FA77E4610F',
    name: 'arrow-expand-up',
    codepoint: 'F0799',
    deprecated: 'FALSE',
  },
  {
    id: '3B6EC96B-24CB-4CCE-AE9F-AA1787987C63',
    name: 'arrow-expand-vertical',
    codepoint: 'F084F',
    deprecated: 'FALSE',
  },
  {
    id: 'AE7BA87E-D5CF-415E-8641-ADE156760E93',
    name: 'arrow-horizontal-lock',
    codepoint: 'F115B',
    deprecated: 'FALSE',
  },
  {
    id: 'C21995A9-094F-4EA8-9127-BA0506B240A6',
    name: 'arrow-left',
    codepoint: 'F004D',
    deprecated: 'FALSE',
  },
  {
    id: 'E990CF7B-A7D8-444E-99FE-24930ABF2FA6',
    name: 'arrow-left-bold',
    codepoint: 'F0731',
    deprecated: 'FALSE',
  },
  {
    id: 'B7333E10-D6D6-4F86-A163-3A486C0B839D',
    name: 'arrow-left-bold-box',
    codepoint: 'F0732',
    deprecated: 'FALSE',
  },
  {
    id: 'E95F06DD-DD66-4CD1-AAAC-AAA870CE6AC7',
    name: 'arrow-left-bold-box-outline',
    codepoint: 'F0733',
    deprecated: 'FALSE',
  },
  {
    id: 'D40EF576-5116-4176-9F9D-C9E491517321',
    name: 'arrow-left-bold-circle',
    codepoint: 'F004F',
    deprecated: 'FALSE',
  },
  {
    id: 'E5ED912C-8075-477C-B62B-60184A436F2C',
    name: 'arrow-left-bold-circle-outline',
    codepoint: 'F0050',
    deprecated: 'FALSE',
  },
  {
    id: '5B274371-4B53-4E82-9CE3-42EADBDCCF92',
    name: 'arrow-left-bold-hexagon-outline',
    codepoint: 'F0051',
    deprecated: 'FALSE',
  },
  {
    id: 'F88B9364-AE49-4AF6-A891-E20068064832',
    name: 'arrow-left-bold-outline',
    codepoint: 'F09C0',
    deprecated: 'FALSE',
  },
  {
    id: '94A5B560-135A-48EE-AF4D-89E19600F072',
    name: 'arrow-left-bottom',
    codepoint: 'F17A5',
    deprecated: 'FALSE',
  },
  {
    id: 'F1A75B07-42E2-4C15-A17C-264E9E18D12B',
    name: 'arrow-left-bottom-bold',
    codepoint: 'F17A6',
    deprecated: 'FALSE',
  },
  {
    id: '67481609-631A-45C8-AB97-679B3D95F9FB',
    name: 'arrow-left-box',
    codepoint: 'F06C1',
    deprecated: 'FALSE',
  },
  {
    id: '57F5D624-D5B0-4F19-AFAB-676F822C87DC',
    name: 'arrow-left-circle',
    codepoint: 'F0CDD',
    deprecated: 'FALSE',
  },
  {
    id: 'DEE2CC7E-5349-49CE-9700-6EF0D935A2E6',
    name: 'arrow-left-circle-outline',
    codepoint: 'F0CDE',
    deprecated: 'FALSE',
  },
  {
    id: '199C908D-09C4-47C6-968A-74582AC4AF26',
    name: 'arrow-left-drop-circle',
    codepoint: 'F0052',
    deprecated: 'FALSE',
  },
  {
    id: '37B06F58-3BD0-413F-B86D-2EFD008B3D69',
    name: 'arrow-left-drop-circle-outline',
    codepoint: 'F0053',
    deprecated: 'FALSE',
  },
  {
    id: 'D6011A13-DCCB-4DF7-B5A8-335A404C6B71',
    name: 'arrow-left-right',
    codepoint: 'F0E73',
    deprecated: 'FALSE',
  },
  {
    id: '917B10C1-CFB6-4066-9977-0FA6DD835C0E',
    name: 'arrow-left-right-bold',
    codepoint: 'F0E74',
    deprecated: 'FALSE',
  },
  {
    id: '341A3007-7214-4634-924D-1FFEA455AD5E',
    name: 'arrow-left-right-bold-outline',
    codepoint: 'F09C1',
    deprecated: 'FALSE',
  },
  {
    id: 'FA880DCB-AC6F-4C9C-9B6D-6E8236272C31',
    name: 'arrow-left-thick',
    codepoint: 'F004E',
    deprecated: 'FALSE',
  },
  {
    id: 'B3A70192-FB1E-4CC9-AA89-44AD6C921BCE',
    name: 'arrow-left-thin',
    codepoint: 'F19B1',
    deprecated: 'FALSE',
  },
  {
    id: 'B0495FDB-2123-4D72-9D15-F44ED6FBF8CD',
    name: 'arrow-left-thin-circle-outline',
    codepoint: 'F159A',
    deprecated: 'FALSE',
  },
  {
    id: '4FB19260-E56B-411D-B0A6-22D15C3299A3',
    name: 'arrow-left-top',
    codepoint: 'F17A7',
    deprecated: 'FALSE',
  },
  {
    id: '1239A338-77EE-47E5-A50B-2BC667AD7BB1',
    name: 'arrow-left-top-bold',
    codepoint: 'F17A8',
    deprecated: 'FALSE',
  },
  {
    id: 'A673515F-F8A6-41DC-9CB2-05C315FBDA53',
    name: 'arrow-projectile',
    codepoint: 'F1840',
    deprecated: 'FALSE',
  },
  {
    id: 'F3A249F5-9D86-4A3E-A197-C8C410C3721B',
    name: 'arrow-projectile-multiple',
    codepoint: 'F183F',
    deprecated: 'FALSE',
  },
  {
    id: '05F25B05-A6D6-4863-8B21-6969E10329CF',
    name: 'arrow-right',
    codepoint: 'F0054',
    deprecated: 'FALSE',
  },
  {
    id: '8C7A25B6-878E-4BDB-A5B7-2927257E1B7D',
    name: 'arrow-right-bold',
    codepoint: 'F0734',
    deprecated: 'FALSE',
  },
  {
    id: '7225998A-DEF1-40C6-A05C-FA4119A643D9',
    name: 'arrow-right-bold-box',
    codepoint: 'F0735',
    deprecated: 'FALSE',
  },
  {
    id: '1830D607-FDD5-4CC1-8D74-2A92C16B9E51',
    name: 'arrow-right-bold-box-outline',
    codepoint: 'F0736',
    deprecated: 'FALSE',
  },
  {
    id: 'AF178779-F8E7-4F77-A9D1-A4675713C440',
    name: 'arrow-right-bold-circle',
    codepoint: 'F0056',
    deprecated: 'FALSE',
  },
  {
    id: '8B60DF03-2440-4D73-98DE-A8666666864E',
    name: 'arrow-right-bold-circle-outline',
    codepoint: 'F0057',
    deprecated: 'FALSE',
  },
  {
    id: '1EAE1304-ED67-4607-A5C3-833B9CF7D943',
    name: 'arrow-right-bold-hexagon-outline',
    codepoint: 'F0058',
    deprecated: 'FALSE',
  },
  {
    id: '86D895A6-8B2C-4757-A2CC-C506B402C2BB',
    name: 'arrow-right-bold-outline',
    codepoint: 'F09C2',
    deprecated: 'FALSE',
  },
  {
    id: 'C46163EC-EF8C-4352-939E-FA980174E6B7',
    name: 'arrow-right-bottom',
    codepoint: 'F17A9',
    deprecated: 'FALSE',
  },
  {
    id: 'CBE2124B-2BE8-4891-9637-FC0E603E61F9',
    name: 'arrow-right-bottom-bold',
    codepoint: 'F17AA',
    deprecated: 'FALSE',
  },
  {
    id: '8BA08E5C-7ED5-49E2-BDDE-1F1425C9FE1A',
    name: 'arrow-right-box',
    codepoint: 'F06C2',
    deprecated: 'FALSE',
  },
  {
    id: 'DF8E13FB-5C26-4C43-A6DC-F01185A97E42',
    name: 'arrow-right-circle',
    codepoint: 'F0CDF',
    deprecated: 'FALSE',
  },
  {
    id: 'A0626F15-BC40-422F-B978-EEDFB5543961',
    name: 'arrow-right-circle-outline',
    codepoint: 'F0CE0',
    deprecated: 'FALSE',
  },
  {
    id: '39AEA4FF-B65C-4368-B582-98108E3F9F06',
    name: 'arrow-right-drop-circle',
    codepoint: 'F0059',
    deprecated: 'FALSE',
  },
  {
    id: '1E7109A9-3178-4B5E-87A9-CD1054B38BE9',
    name: 'arrow-right-drop-circle-outline',
    codepoint: 'F005A',
    deprecated: 'FALSE',
  },
  {
    id: '9F7385FB-733C-4C1F-AC88-684BE98F381B',
    name: 'arrow-right-thick',
    codepoint: 'F0055',
    deprecated: 'FALSE',
  },
  {
    id: '61B5D6E3-5706-401F-8D18-3145A446C543',
    name: 'arrow-right-thin',
    codepoint: 'F19B0',
    deprecated: 'FALSE',
  },
  {
    id: '5ADB8DD0-2F9D-4F1B-ABC9-093579E960A0',
    name: 'arrow-right-thin-circle-outline',
    codepoint: 'F1598',
    deprecated: 'FALSE',
  },
  {
    id: '0FD8FA03-2B86-4B8E-8650-075591AC024C',
    name: 'arrow-right-top',
    codepoint: 'F17AB',
    deprecated: 'FALSE',
  },
  {
    id: '45C175E3-90FF-4F10-B6E2-6A08B0F70FAA',
    name: 'arrow-right-top-bold',
    codepoint: 'F17AC',
    deprecated: 'FALSE',
  },
  {
    id: 'ED7981BC-0B25-4657-BB7C-FB14A3D91D99',
    name: 'arrow-split-horizontal',
    codepoint: 'F093B',
    deprecated: 'FALSE',
  },
  {
    id: '7234382E-CB5D-4EB3-8BA2-0C50BC2171DB',
    name: 'arrow-split-vertical',
    codepoint: 'F093C',
    deprecated: 'FALSE',
  },
  {
    id: 'DD1AC44D-81B8-42EF-8714-7E7D29F2BC3F',
    name: 'arrow-top-left',
    codepoint: 'F005B',
    deprecated: 'FALSE',
  },
  {
    id: '3D49377B-0A47-4029-B384-F74C68234D41',
    name: 'arrow-top-left-bold-box',
    codepoint: 'F1968',
    deprecated: 'FALSE',
  },
  {
    id: '63353DF0-B961-42CD-88D6-FA40887FD3DC',
    name: 'arrow-top-left-bold-box-outline',
    codepoint: 'F1969',
    deprecated: 'FALSE',
  },
  {
    id: '4D43349E-EF13-4BB1-9684-0CEDE55DE9DE',
    name: 'arrow-top-left-bold-outline',
    codepoint: 'F09C3',
    deprecated: 'FALSE',
  },
  {
    id: '3F3EFD86-07D4-495B-9E20-66826FF54E1D',
    name: 'arrow-top-left-bottom-right',
    codepoint: 'F0E75',
    deprecated: 'FALSE',
  },
  {
    id: 'D6AFEFE4-0E22-4197-B094-53E9528FFF0A',
    name: 'arrow-top-left-bottom-right-bold',
    codepoint: 'F0E76',
    deprecated: 'FALSE',
  },
  {
    id: 'D1D2B0DA-2EAD-451B-A9D9-B4C52E16FEDC',
    name: 'arrow-top-left-thick',
    codepoint: 'F09C4',
    deprecated: 'FALSE',
  },
  {
    id: 'E88C3E97-A92F-4222-B649-01ABFB105FAF',
    name: 'arrow-top-left-thin',
    codepoint: 'F19B5',
    deprecated: 'FALSE',
  },
  {
    id: 'A3FB09A5-28B7-4A90-AA48-E6D0AA5031EB',
    name: 'arrow-top-left-thin-circle-outline',
    codepoint: 'F1593',
    deprecated: 'FALSE',
  },
  {
    id: '27AB7B33-64F3-49A5-8082-CA7B465152A9',
    name: 'arrow-top-right',
    codepoint: 'F005C',
    deprecated: 'FALSE',
  },
  {
    id: '249364CD-93CE-4A11-9556-65579FA8910F',
    name: 'arrow-top-right-bold-box',
    codepoint: 'F196A',
    deprecated: 'FALSE',
  },
  {
    id: '7E8DD1EB-A1B7-48A5-B0B7-AF201BE6769E',
    name: 'arrow-top-right-bold-box-outline',
    codepoint: 'F196B',
    deprecated: 'FALSE',
  },
  {
    id: 'D6A6FF61-6566-4C1D-BBCC-30E00B0655DD',
    name: 'arrow-top-right-bold-outline',
    codepoint: 'F09C5',
    deprecated: 'FALSE',
  },
  {
    id: '6B94201F-4E9F-486E-B3A6-484A6C266E55',
    name: 'arrow-top-right-bottom-left',
    codepoint: 'F0E77',
    deprecated: 'FALSE',
  },
  {
    id: '815927F0-0D89-4D10-B336-2B918B321994',
    name: 'arrow-top-right-bottom-left-bold',
    codepoint: 'F0E78',
    deprecated: 'FALSE',
  },
  {
    id: '37C83BAE-6330-4E45-A976-3E2642997035',
    name: 'arrow-top-right-thick',
    codepoint: 'F09C6',
    deprecated: 'FALSE',
  },
  {
    id: '500822ED-8EE6-427C-A4CE-AE56A4CB4C61',
    name: 'arrow-top-right-thin',
    codepoint: 'F19B4',
    deprecated: 'FALSE',
  },
  {
    id: '83068FC2-BF4B-4B99-956D-46EC07F6738F',
    name: 'arrow-top-right-thin-circle-outline',
    codepoint: 'F1594',
    deprecated: 'FALSE',
  },
  {
    id: 'D16AF4C4-90FC-42AB-8847-7F04DFDC6DF4',
    name: 'arrow-u-down-left',
    codepoint: 'F17AD',
    deprecated: 'FALSE',
  },
  {
    id: 'EE6C5398-B666-4BE6-9728-AF35B5C77BA9',
    name: 'arrow-u-down-left-bold',
    codepoint: 'F17AE',
    deprecated: 'FALSE',
  },
  {
    id: 'C2A75394-FCF6-4BFB-868F-3FC14C48B6FC',
    name: 'arrow-u-down-right',
    codepoint: 'F17AF',
    deprecated: 'FALSE',
  },
  {
    id: '0B6ECB18-2663-4365-BBD1-3FE6A0A2A45C',
    name: 'arrow-u-down-right-bold',
    codepoint: 'F17B0',
    deprecated: 'FALSE',
  },
  {
    id: '1130F0DA-3590-4221-BB00-33BED5B6EF10',
    name: 'arrow-u-left-bottom',
    codepoint: 'F17B1',
    deprecated: 'FALSE',
  },
  {
    id: '8793E254-1126-4A01-937C-C1827F173538',
    name: 'arrow-u-left-bottom-bold',
    codepoint: 'F17B2',
    deprecated: 'FALSE',
  },
  {
    id: 'CA98908E-09FA-4F06-80AF-57851BF95AD3',
    name: 'arrow-u-left-top',
    codepoint: 'F17B3',
    deprecated: 'FALSE',
  },
  {
    id: 'A249FE80-1743-4D6F-BF99-3311DB7B299B',
    name: 'arrow-u-left-top-bold',
    codepoint: 'F17B4',
    deprecated: 'FALSE',
  },
  {
    id: '4D16B4CD-BA9A-4B92-AF88-7CD0154655B2',
    name: 'arrow-u-right-bottom',
    codepoint: 'F17B5',
    deprecated: 'FALSE',
  },
  {
    id: 'FF7E431E-FEA7-4953-AC5F-877269DE3B16',
    name: 'arrow-u-right-bottom-bold',
    codepoint: 'F17B6',
    deprecated: 'FALSE',
  },
  {
    id: '78232953-CC68-48A1-A4A5-DC64B46216BC',
    name: 'arrow-u-right-top',
    codepoint: 'F17B7',
    deprecated: 'FALSE',
  },
  {
    id: '31D886C9-7CC3-4E48-B829-D7870D11641C',
    name: 'arrow-u-right-top-bold',
    codepoint: 'F17B8',
    deprecated: 'FALSE',
  },
  {
    id: 'A5C0B876-8E0A-4792-A2EF-101CB41C989B',
    name: 'arrow-u-up-left',
    codepoint: 'F17B9',
    deprecated: 'FALSE',
  },
  {
    id: 'EDF2047D-119E-4FD0-A936-67B372A4905F',
    name: 'arrow-u-up-left-bold',
    codepoint: 'F17BA',
    deprecated: 'FALSE',
  },
  {
    id: 'B743ECCA-F8DA-4F54-BF8F-8558F370AECB',
    name: 'arrow-u-up-right',
    codepoint: 'F17BB',
    deprecated: 'FALSE',
  },
  {
    id: 'EFF50AE6-BF7E-4BF5-AA67-F8BBE9B10588',
    name: 'arrow-u-up-right-bold',
    codepoint: 'F17BC',
    deprecated: 'FALSE',
  },
  {
    id: '3AF49B97-A909-4961-9FBB-82C60D7CC773',
    name: 'arrow-up',
    codepoint: 'F005D',
    deprecated: 'FALSE',
  },
  {
    id: '36199A40-CCCB-44B9-A6B0-73F7315E5716',
    name: 'arrow-up-bold',
    codepoint: 'F0737',
    deprecated: 'FALSE',
  },
  {
    id: '062452EE-7669-4C36-85E6-A85BBA735348',
    name: 'arrow-up-bold-box',
    codepoint: 'F0738',
    deprecated: 'FALSE',
  },
  {
    id: '5386BD1B-8959-4312-8739-5B08021F319D',
    name: 'arrow-up-bold-box-outline',
    codepoint: 'F0739',
    deprecated: 'FALSE',
  },
  {
    id: 'D6719240-C87E-4185-B694-1EA5FFC94F4E',
    name: 'arrow-up-bold-circle',
    codepoint: 'F005F',
    deprecated: 'FALSE',
  },
  {
    id: '3337B267-94EC-426B-ABC9-5842F75B13B1',
    name: 'arrow-up-bold-circle-outline',
    codepoint: 'F0060',
    deprecated: 'FALSE',
  },
  {
    id: '7C3BD067-49AB-41C8-9091-FBD2D533045E',
    name: 'arrow-up-bold-hexagon-outline',
    codepoint: 'F0061',
    deprecated: 'FALSE',
  },
  {
    id: '664AA4B5-DD9C-40AD-A69E-3CD314025AD3',
    name: 'arrow-up-bold-outline',
    codepoint: 'F09C7',
    deprecated: 'FALSE',
  },
  {
    id: '82958DF7-5F5F-4470-BFB4-4389937B4D29',
    name: 'arrow-up-box',
    codepoint: 'F06C3',
    deprecated: 'FALSE',
  },
  {
    id: '208441AD-DDC5-4EC8-A48D-746DB5AE3CFD',
    name: 'arrow-up-circle',
    codepoint: 'F0CE1',
    deprecated: 'FALSE',
  },
  {
    id: 'D41B6302-5A2E-4B55-93A4-E30E07F761C1',
    name: 'arrow-up-circle-outline',
    codepoint: 'F0CE2',
    deprecated: 'FALSE',
  },
  {
    id: 'DF1BCEA6-C202-477E-A3CF-2054D93E5F2A',
    name: 'arrow-up-down',
    codepoint: 'F0E79',
    deprecated: 'FALSE',
  },
  {
    id: '478A5E36-5EC0-4DC5-B663-AFB1F84C83A9',
    name: 'arrow-up-down-bold',
    codepoint: 'F0E7A',
    deprecated: 'FALSE',
  },
  {
    id: '1F1BE05B-E67A-4646-A8B3-08423BD58673',
    name: 'arrow-up-down-bold-outline',
    codepoint: 'F09C8',
    deprecated: 'FALSE',
  },
  {
    id: '73B211DB-05CF-4025-B035-AD03454C47A6',
    name: 'arrow-up-drop-circle',
    codepoint: 'F0062',
    deprecated: 'FALSE',
  },
  {
    id: '42277E7D-D87A-4DE9-982B-37E6A8AE4E70',
    name: 'arrow-up-drop-circle-outline',
    codepoint: 'F0063',
    deprecated: 'FALSE',
  },
  {
    id: '7FBBA0AD-B8E1-4C13-8BD7-B37D28135DD2',
    name: 'arrow-up-left',
    codepoint: 'F17BD',
    deprecated: 'FALSE',
  },
  {
    id: 'DDA1FF1D-BD25-4698-8D9B-7F0E6B71C274',
    name: 'arrow-up-left-bold',
    codepoint: 'F17BE',
    deprecated: 'FALSE',
  },
  {
    id: '5C5BEA1F-BAA5-4F6A-AE84-F3EAD16C936C',
    name: 'arrow-up-right',
    codepoint: 'F17BF',
    deprecated: 'FALSE',
  },
  {
    id: '491E8E53-19DC-4736-AA7F-F95AEB0F1696',
    name: 'arrow-up-right-bold',
    codepoint: 'F17C0',
    deprecated: 'FALSE',
  },
  {
    id: '4EDBF232-461F-4F4C-82BB-64A89BA08405',
    name: 'arrow-up-thick',
    codepoint: 'F005E',
    deprecated: 'FALSE',
  },
  {
    id: '62E619DA-599C-486F-AD85-9B5BAB4F5A92',
    name: 'arrow-up-thin',
    codepoint: 'F19B2',
    deprecated: 'FALSE',
  },
  {
    id: '9733F983-7DD1-4230-B792-413D46E0B422',
    name: 'arrow-up-thin-circle-outline',
    codepoint: 'F1597',
    deprecated: 'FALSE',
  },
  {
    id: '8B00DF03-0754-4D7F-B0C9-8CF21D42989F',
    name: 'arrow-vertical-lock',
    codepoint: 'F115C',
    deprecated: 'FALSE',
  },
  {
    id: '11D80B18-006C-4929-8CEA-29D2C8BED5B7',
    name: 'artboard',
    codepoint: 'F1B9A',
    deprecated: 'FALSE',
  },
  {
    id: '548CCDA7-0E99-4568-9997-2ECFD33392BB',
    name: 'artstation',
    codepoint: 'F0B5B',
    deprecated: 'TRUE',
  },
  {
    id: 'AE294E7D-3AC0-4C7D-AFAF-8DDA0AB5BF52',
    name: 'aspect-ratio',
    codepoint: 'F0A24',
    deprecated: 'FALSE',
  },
  {
    id: '0C612FE5-D7CC-44F3-945A-4BD15A7E39BA',
    name: 'assistant',
    codepoint: 'F0064',
    deprecated: 'FALSE',
  },
  {
    id: 'E8C373FE-4258-4341-B52A-E33D9A484968',
    name: 'asterisk',
    codepoint: 'F06C4',
    deprecated: 'FALSE',
  },
  {
    id: '1A9A6BE7-2B58-458C-9DA5-3CCA65D67414',
    name: 'asterisk-circle-outline',
    codepoint: 'F1A27',
    deprecated: 'FALSE',
  },
  {
    id: '9FC24609-9C8B-4DBA-A473-F5EFAFCC90DF',
    name: 'at',
    codepoint: 'F0065',
    deprecated: 'FALSE',
  },
  {
    id: '25806C84-E41E-43A7-93AF-EA4ABACA982E',
    name: 'atlassian',
    codepoint: 'F0804',
    deprecated: 'TRUE',
  },
  {
    id: 'A35ABE08-C956-4177-935C-1E1FB835772D',
    name: 'atm',
    codepoint: 'F0D47',
    deprecated: 'FALSE',
  },
  {
    id: 'C366C8B8-44FB-481E-88F2-AE0C3B1E8C74',
    name: 'atom',
    codepoint: 'F0768',
    deprecated: 'FALSE',
  },
  {
    id: 'AD030F55-7B31-45F9-94F7-738CC84B2009',
    name: 'atom-variant',
    codepoint: 'F0E7B',
    deprecated: 'FALSE',
  },
  {
    id: 'E3C20B38-A6D3-4EF0-817D-863B25E555BA',
    name: 'attachment',
    codepoint: 'F0066',
    deprecated: 'FALSE',
  },
  {
    id: '8B6BEB96-8C6B-4271-B87F-A568DF587900',
    name: 'attachment-check',
    codepoint: 'F1AC1',
    deprecated: 'FALSE',
  },
  {
    id: '08E6060D-0A4F-4A3C-867E-192E737972C6',
    name: 'attachment-lock',
    codepoint: 'F19C4',
    deprecated: 'FALSE',
  },
  {
    id: '2FE2F795-05F3-416B-8B74-ABC795E644EF',
    name: 'attachment-minus',
    codepoint: 'F1AC2',
    deprecated: 'FALSE',
  },
  {
    id: 'B9845DE1-0804-459F-8333-A741EEDACC29',
    name: 'attachment-off',
    codepoint: 'F1AC3',
    deprecated: 'FALSE',
  },
  {
    id: 'F0017157-A798-401D-9B19-464C1BB25EA4',
    name: 'attachment-plus',
    codepoint: 'F1AC4',
    deprecated: 'FALSE',
  },
  {
    id: 'E6D6388D-6764-49ED-8DA7-6F4D27646237',
    name: 'attachment-remove',
    codepoint: 'F1AC5',
    deprecated: 'FALSE',
  },
  {
    id: '0086E21E-2ABC-4983-8BA7-C9C31B55D6B9',
    name: 'atv',
    codepoint: 'F1B70',
    deprecated: 'FALSE',
  },
  {
    id: '2CB292B6-3835-44B4-857B-5231B87B5968',
    name: 'audio-input-rca',
    codepoint: 'F186B',
    deprecated: 'FALSE',
  },
  {
    id: '45243722-DE4F-4339-878C-4990A62A1990',
    name: 'audio-input-stereo-minijack',
    codepoint: 'F186C',
    deprecated: 'FALSE',
  },
  {
    id: '030EBE1B-EB48-4332-9E3D-BA6EF9C0AF79',
    name: 'audio-input-xlr',
    codepoint: 'F186D',
    deprecated: 'FALSE',
  },
  {
    id: '79CBF0E5-365C-466B-B162-2EBD1531F182',
    name: 'audio-video',
    codepoint: 'F093D',
    deprecated: 'FALSE',
  },
  {
    id: '09FB4D77-A411-4CBF-9E88-21807D804CB3',
    name: 'audio-video-off',
    codepoint: 'F11B6',
    deprecated: 'FALSE',
  },
  {
    id: 'FC29D627-D661-4F31-9BEC-2F4BE355DFE7',
    name: 'augmented-reality',
    codepoint: 'F0850',
    deprecated: 'FALSE',
  },
  {
    id: '3ED13D0F-1EC7-4EBF-99F4-B686272A00AC',
    name: 'aurora',
    codepoint: 'F1BB9',
    deprecated: 'FALSE',
  },
  {
    id: '2EAC4201-2055-4755-84A9-958FDDF7732B',
    name: 'auto-download',
    codepoint: 'F137E',
    deprecated: 'FALSE',
  },
  {
    id: 'FAAFAACC-4E0D-4688-B583-FC88A782A9B6',
    name: 'auto-fix',
    codepoint: 'F0068',
    deprecated: 'FALSE',
  },
  {
    id: '3DEAD47C-90B3-485E-943C-CE2D70C85154',
    name: 'auto-upload',
    codepoint: 'F0069',
    deprecated: 'FALSE',
  },
  {
    id: '00598C19-1255-479D-914A-1AADFDBC4868',
    name: 'autorenew',
    codepoint: 'F006A',
    deprecated: 'FALSE',
  },
  {
    id: 'E140F6DB-8C47-4F0F-9293-0D8EE1106B64',
    name: 'autorenew-off',
    codepoint: 'F19E7',
    deprecated: 'FALSE',
  },
  {
    id: 'A118E3CD-FF18-48FD-B26C-B4A48641E4C5',
    name: 'av-timer',
    codepoint: 'F006B',
    deprecated: 'FALSE',
  },
  {
    id: '8A405D94-6047-4B9B-BB52-24BD1416441F',
    name: 'awning',
    codepoint: 'F1B87',
    deprecated: 'FALSE',
  },
  {
    id: 'D2598CD1-6354-4499-9E67-EEF50D9B5226',
    name: 'awning-outline',
    codepoint: 'F1B88',
    deprecated: 'FALSE',
  },
  {
    id: '851E2C6C-16AE-4C0F-B99A-AB6BE37C4F55',
    name: 'aws',
    codepoint: 'F0E0F',
    deprecated: 'TRUE',
  },
  {
    id: 'B6119C36-82C4-4C6F-98D5-B339A73C837E',
    name: 'axe',
    codepoint: 'F08C8',
    deprecated: 'FALSE',
  },
  {
    id: 'C2FB1086-E609-4BD6-BF91-F703CDE00209',
    name: 'axe-battle',
    codepoint: 'F1842',
    deprecated: 'FALSE',
  },
  {
    id: '78DCA9F5-C22C-407A-ABC1-15510C2F977A',
    name: 'axis',
    codepoint: 'F0D48',
    deprecated: 'FALSE',
  },
  {
    id: '3D0AC996-0A31-4D68-AD38-A8507967B269',
    name: 'axis-arrow',
    codepoint: 'F0D49',
    deprecated: 'FALSE',
  },
  {
    id: 'FCB87FD8-4522-47AF-AC10-2DF8A59F632D',
    name: 'axis-arrow-info',
    codepoint: 'F140E',
    deprecated: 'FALSE',
  },
  {
    id: '1EE0C03F-9BCA-4AB4-92C3-209EA92FA54F',
    name: 'axis-arrow-lock',
    codepoint: 'F0D4A',
    deprecated: 'FALSE',
  },
  {
    id: 'D2BAA555-22F2-447F-89F1-95D11AE09126',
    name: 'axis-lock',
    codepoint: 'F0D4B',
    deprecated: 'FALSE',
  },
  {
    id: '933B83F8-4F97-436F-89E0-574315FF1E2E',
    name: 'axis-x-arrow',
    codepoint: 'F0D4C',
    deprecated: 'FALSE',
  },
  {
    id: 'E113D418-BFDB-44FE-A809-61072FEAEF39',
    name: 'axis-x-arrow-lock',
    codepoint: 'F0D4D',
    deprecated: 'FALSE',
  },
  {
    id: '7EE23AE2-F41C-4CDA-9B52-B8A432CA7E20',
    name: 'axis-x-rotate-clockwise',
    codepoint: 'F0D4E',
    deprecated: 'FALSE',
  },
  {
    id: 'B500E4B0-397A-4BA2-926F-5B8BDF10632F',
    name: 'axis-x-rotate-counterclockwise',
    codepoint: 'F0D4F',
    deprecated: 'FALSE',
  },
  {
    id: '6620039A-2405-48BB-9619-143BAF7CF8DD',
    name: 'axis-x-y-arrow-lock',
    codepoint: 'F0D50',
    deprecated: 'FALSE',
  },
  {
    id: 'F12198D8-62C4-4E6A-A201-5D33DB690736',
    name: 'axis-y-arrow',
    codepoint: 'F0D51',
    deprecated: 'FALSE',
  },
  {
    id: '4C02BFA9-8AF8-4FC3-AAC7-F4D4AEF9BE06',
    name: 'axis-y-arrow-lock',
    codepoint: 'F0D52',
    deprecated: 'FALSE',
  },
  {
    id: '6A8E42CA-4236-496C-9C0A-81FADCDA6506',
    name: 'axis-y-rotate-clockwise',
    codepoint: 'F0D53',
    deprecated: 'FALSE',
  },
  {
    id: '70E8F92A-9AAA-44CB-AB76-527463DE2D06',
    name: 'axis-y-rotate-counterclockwise',
    codepoint: 'F0D54',
    deprecated: 'FALSE',
  },
  {
    id: '2BAA5FEA-713E-44D6-A44F-B36C92BB7F60',
    name: 'axis-z-arrow',
    codepoint: 'F0D55',
    deprecated: 'FALSE',
  },
  {
    id: '870F3E6C-6957-498E-AC62-BAE40C9FA7EA',
    name: 'axis-z-arrow-lock',
    codepoint: 'F0D56',
    deprecated: 'FALSE',
  },
  {
    id: 'D3A59461-2A63-4C62-B528-564252BBAB29',
    name: 'axis-z-rotate-clockwise',
    codepoint: 'F0D57',
    deprecated: 'FALSE',
  },
  {
    id: 'DB0819A2-5E99-483B-BBC8-AEAD0DE9D196',
    name: 'axis-z-rotate-counterclockwise',
    codepoint: 'F0D58',
    deprecated: 'FALSE',
  },
  {
    id: '3F7EAFAC-7A39-4051-B58D-2CBA6687110C',
    name: 'babel',
    codepoint: 'F0A25',
    deprecated: 'TRUE',
  },
  {
    id: '2CD51EFC-51B6-4323-B1EA-429EB58BFF7F',
    name: 'baby',
    codepoint: 'F006C',
    deprecated: 'FALSE',
  },
  {
    id: 'B3497A75-EE3B-4A8A-8F27-39A458423659',
    name: 'baby-bottle',
    codepoint: 'F0F39',
    deprecated: 'FALSE',
  },
  {
    id: '5E06191D-D9AC-412E-B21B-C3AF1F28D76F',
    name: 'baby-bottle-outline',
    codepoint: 'F0F3A',
    deprecated: 'FALSE',
  },
  {
    id: '46AF3844-1F3F-43B5-A93B-424EC83BCADB',
    name: 'baby-buggy',
    codepoint: 'F13E0',
    deprecated: 'FALSE',
  },
  {
    id: '9E57CC9C-C471-4A84-A750-D4BE9C94D989',
    name: 'baby-buggy-off',
    codepoint: 'F1AF3',
    deprecated: 'FALSE',
  },
  {
    id: 'FA711E97-6083-45AA-9C92-FCCDABC57D37',
    name: 'baby-carriage',
    codepoint: 'F068F',
    deprecated: 'FALSE',
  },
  {
    id: 'D495FD84-4476-4E2E-B099-4E0A07AA9D90',
    name: 'baby-carriage-off',
    codepoint: 'F0FA0',
    deprecated: 'FALSE',
  },
  {
    id: '78A84DE8-3ED5-4F81-991B-8EAE452908D3',
    name: 'baby-face',
    codepoint: 'F0E7C',
    deprecated: 'FALSE',
  },
  {
    id: '24C6F5BD-6965-4CDB-B3AC-02CA82F24216',
    name: 'baby-face-outline',
    codepoint: 'F0E7D',
    deprecated: 'FALSE',
  },
  {
    id: '5A5BC824-220B-4998-883A-0E57FB2AB75F',
    name: 'backburger',
    codepoint: 'F006D',
    deprecated: 'FALSE',
  },
  {
    id: '2AAC06E6-8ABD-418B-B9A4-8554B62D7C04',
    name: 'backspace',
    codepoint: 'F006E',
    deprecated: 'FALSE',
  },
  {
    id: '7CD351DA-560B-42B5-86F3-7F782EA70FEA',
    name: 'backspace-outline',
    codepoint: 'F0B5C',
    deprecated: 'FALSE',
  },
  {
    id: '49AA61F3-2E3D-4EEA-BFB1-32D59C6906F0',
    name: 'backspace-reverse',
    codepoint: 'F0E7E',
    deprecated: 'FALSE',
  },
  {
    id: '287700E2-130D-484B-B56D-0162BE6DEEEE',
    name: 'backspace-reverse-outline',
    codepoint: 'F0E7F',
    deprecated: 'FALSE',
  },
  {
    id: '36F8C2B4-C82C-4546-AFFC-601ECA54A603',
    name: 'backup-restore',
    codepoint: 'F006F',
    deprecated: 'FALSE',
  },
  {
    id: '059B29E7-34BA-402E-B06A-B9EBF021FA8B',
    name: 'bacteria',
    codepoint: 'F0ED5',
    deprecated: 'FALSE',
  },
  {
    id: '548C05E8-98DD-4D5C-8308-FA997A2C9016',
    name: 'bacteria-outline',
    codepoint: 'F0ED6',
    deprecated: 'FALSE',
  },
  {
    id: 'AE2E869B-B420-4505-9EE0-EE3751478D64',
    name: 'badge-account',
    codepoint: 'F0DA7',
    deprecated: 'FALSE',
  },
  {
    id: '610A9983-6737-4876-B1E2-09C22D3888D9',
    name: 'badge-account-alert',
    codepoint: 'F0DA8',
    deprecated: 'FALSE',
  },
  {
    id: '3F5E356C-AD32-46E2-835F-ECFC043C73AD',
    name: 'badge-account-alert-outline',
    codepoint: 'F0DA9',
    deprecated: 'FALSE',
  },
  {
    id: '99CC4A89-E39E-460D-B199-227045E4A57D',
    name: 'badge-account-horizontal',
    codepoint: 'F0E0D',
    deprecated: 'FALSE',
  },
  {
    id: '089F503E-00BF-4103-9475-B0E3955CE09D',
    name: 'badge-account-horizontal-outline',
    codepoint: 'F0E0E',
    deprecated: 'FALSE',
  },
  {
    id: '37856000-4337-42D9-B273-9C9656E9CFCD',
    name: 'badge-account-outline',
    codepoint: 'F0DAA',
    deprecated: 'FALSE',
  },
  {
    id: 'AF478988-12FA-460A-AE5B-01536D6D5B97',
    name: 'badminton',
    codepoint: 'F0851',
    deprecated: 'FALSE',
  },
  {
    id: '0B935A7D-05EB-4687-8E4B-BC075AA2FA85',
    name: 'bag-carry-on',
    codepoint: 'F0F3B',
    deprecated: 'FALSE',
  },
  {
    id: '57DD596E-AFB6-46D2-8415-2DED40839B11',
    name: 'bag-carry-on-check',
    codepoint: 'F0D65',
    deprecated: 'FALSE',
  },
  {
    id: 'F11D5411-E08A-483D-B04C-A75B523C717C',
    name: 'bag-carry-on-off',
    codepoint: 'F0F3C',
    deprecated: 'FALSE',
  },
  {
    id: '2D40D813-D1C8-4190-8010-012467F9E48F',
    name: 'bag-checked',
    codepoint: 'F0F3D',
    deprecated: 'FALSE',
  },
  {
    id: 'FB55F27F-9D0D-4EA7-ACD7-22C85E799248',
    name: 'bag-personal',
    codepoint: 'F0E10',
    deprecated: 'FALSE',
  },
  {
    id: '9B14800C-E104-4A89-9773-B220CA30A403',
    name: 'bag-personal-off',
    codepoint: 'F0E11',
    deprecated: 'FALSE',
  },
  {
    id: '822DE3FA-6BBB-4DF9-B586-A63CA5162EF1',
    name: 'bag-personal-off-outline',
    codepoint: 'F0E12',
    deprecated: 'FALSE',
  },
  {
    id: 'E95F21EF-993D-462F-BA6E-21F7665BC34C',
    name: 'bag-personal-outline',
    codepoint: 'F0E13',
    deprecated: 'FALSE',
  },
  {
    id: '59865159-47DA-4B5F-8F5D-5E73D32BD4EA',
    name: 'bag-personal-tag',
    codepoint: 'F1B0C',
    deprecated: 'FALSE',
  },
  {
    id: '49BAA4CB-38E1-4792-93ED-8298AFB304A1',
    name: 'bag-personal-tag-outline',
    codepoint: 'F1B0D',
    deprecated: 'FALSE',
  },
  {
    id: '0DBD5DC4-4D5B-48B9-951C-249BB05C3E7E',
    name: 'bag-suitcase',
    codepoint: 'F158B',
    deprecated: 'FALSE',
  },
  {
    id: '44E2AE1A-79ED-43C0-9A29-B18DFCB9B915',
    name: 'bag-suitcase-off',
    codepoint: 'F158D',
    deprecated: 'FALSE',
  },
  {
    id: 'E89335F8-3A20-489F-A974-FF591F8A147E',
    name: 'bag-suitcase-off-outline',
    codepoint: 'F158E',
    deprecated: 'FALSE',
  },
  {
    id: '4977C376-1E6B-41F4-92F4-3410E9699F2D',
    name: 'bag-suitcase-outline',
    codepoint: 'F158C',
    deprecated: 'FALSE',
  },
  {
    id: 'B6469241-E347-42D8-BF71-8DF29F1CA897',
    name: 'baguette',
    codepoint: 'F0F3E',
    deprecated: 'FALSE',
  },
  {
    id: '005E0382-9004-4606-AA2A-07E27AF1E77E',
    name: 'balcony',
    codepoint: 'F1817',
    deprecated: 'FALSE',
  },
  {
    id: '31D1D255-3E73-41A8-93E2-12DF16DA0C40',
    name: 'balloon',
    codepoint: 'F0A26',
    deprecated: 'FALSE',
  },
  {
    id: '27AB71F3-1EFD-484F-8E63-C1E4C694E392',
    name: 'ballot',
    codepoint: 'F09C9',
    deprecated: 'FALSE',
  },
  {
    id: 'B9CDE0FA-F96A-413C-9BFD-DAB4DBC3DDE3',
    name: 'ballot-outline',
    codepoint: 'F09CA',
    deprecated: 'FALSE',
  },
  {
    id: 'D0C76005-3272-4189-A061-E344D9BAB0C6',
    name: 'ballot-recount',
    codepoint: 'F0C39',
    deprecated: 'FALSE',
  },
  {
    id: '0B0AD153-4696-4ED2-9AE1-309926B23287',
    name: 'ballot-recount-outline',
    codepoint: 'F0C3A',
    deprecated: 'FALSE',
  },
  {
    id: '6CDF527A-26C1-4175-A56B-5D65FD5E46E2',
    name: 'bandage',
    codepoint: 'F0DAF',
    deprecated: 'FALSE',
  },
  {
    id: 'FE08919F-CFFC-4A25-994C-CEDEB299908B',
    name: 'bank',
    codepoint: 'F0070',
    deprecated: 'FALSE',
  },
  {
    id: '1642BE6C-C1B6-49EA-833D-BB5E20BC1C69',
    name: 'bank-check',
    codepoint: 'F1655',
    deprecated: 'FALSE',
  },
  {
    id: 'B8F96A7D-408E-4AB0-A137-E89DD4EC743E',
    name: 'bank-circle',
    codepoint: 'F1C03',
    deprecated: 'FALSE',
  },
  {
    id: '43EACF91-9449-480F-9F4B-C3EECD964C4D',
    name: 'bank-circle-outline',
    codepoint: 'F1C04',
    deprecated: 'FALSE',
  },
  {
    id: 'DAC4946E-1A07-44B4-977F-E51D740DA5FB',
    name: 'bank-minus',
    codepoint: 'F0DB0',
    deprecated: 'FALSE',
  },
  {
    id: '9379EA96-CBC9-4A84-BF1A-18A80E7BDBE2',
    name: 'bank-off',
    codepoint: 'F1656',
    deprecated: 'FALSE',
  },
  {
    id: '4590ED1A-1E67-42F4-8D4B-E2B39E5EFC63',
    name: 'bank-off-outline',
    codepoint: 'F1657',
    deprecated: 'FALSE',
  },
  {
    id: '952ADDE3-5EC1-4AB1-80F1-9FA7AA14AD74',
    name: 'bank-outline',
    codepoint: 'F0E80',
    deprecated: 'FALSE',
  },
  {
    id: 'DCB74822-6C93-473B-88B4-1818CFDE2B69',
    name: 'bank-plus',
    codepoint: 'F0DB1',
    deprecated: 'FALSE',
  },
  {
    id: '14C747D0-C356-403D-862F-09A2F30276E7',
    name: 'bank-remove',
    codepoint: 'F0DB2',
    deprecated: 'FALSE',
  },
  {
    id: '5692748F-52FC-41F9-97B2-D0F251F4619D',
    name: 'bank-transfer',
    codepoint: 'F0A27',
    deprecated: 'FALSE',
  },
  {
    id: 'F8E6FE4D-8CE9-41A5-A26A-FEC3A572DA3F',
    name: 'bank-transfer-in',
    codepoint: 'F0A28',
    deprecated: 'FALSE',
  },
  {
    id: '02A1C3B5-91EE-43B9-9BDB-3EF67F540722',
    name: 'bank-transfer-out',
    codepoint: 'F0A29',
    deprecated: 'FALSE',
  },
  {
    id: '5231CD58-9C30-40A8-993C-A24C6B70F6B8',
    name: 'barcode',
    codepoint: 'F0071',
    deprecated: 'FALSE',
  },
  {
    id: 'AF7144E7-5004-4685-A100-78425F035F51',
    name: 'barcode-off',
    codepoint: 'F1236',
    deprecated: 'FALSE',
  },
  {
    id: '40365CB0-4664-4648-8C51-2E6E20A0D096',
    name: 'barcode-scan',
    codepoint: 'F0072',
    deprecated: 'FALSE',
  },
  {
    id: '5126A5A7-ECBC-49FC-BBC6-1703DCACF5E4',
    name: 'barley',
    codepoint: 'F0073',
    deprecated: 'FALSE',
  },
  {
    id: '6627D247-B25C-48F3-925D-BF282D7B4295',
    name: 'barley-off',
    codepoint: 'F0B5D',
    deprecated: 'FALSE',
  },
  {
    id: '678BDE48-30CE-4897-BA06-9991001FA842',
    name: 'barn',
    codepoint: 'F0B5E',
    deprecated: 'FALSE',
  },
  {
    id: 'CD3098D2-892F-49F5-9FA7-1A5FDC53A15C',
    name: 'barrel',
    codepoint: 'F0074',
    deprecated: 'FALSE',
  },
  {
    id: 'DBEE4FC0-D708-4293-B06B-E46FAC217031',
    name: 'barrel-outline',
    codepoint: 'F1A28',
    deprecated: 'FALSE',
  },
  {
    id: 'EDAC5CB7-39B3-4DAD-89ED-38D7EA914211',
    name: 'baseball',
    codepoint: 'F0852',
    deprecated: 'FALSE',
  },
  {
    id: '20D31EB1-5C7F-4597-9B42-80E08E0192F8',
    name: 'baseball-bat',
    codepoint: 'F0853',
    deprecated: 'FALSE',
  },
  {
    id: '360632A2-1E37-4A89-BC24-166CF4824357',
    name: 'baseball-diamond',
    codepoint: 'F15EC',
    deprecated: 'FALSE',
  },
  {
    id: '55F744E2-2059-41A4-B909-748029558069',
    name: 'baseball-diamond-outline',
    codepoint: 'F15ED',
    deprecated: 'FALSE',
  },
  {
    id: '53BA6F8E-FC99-4B2A-8497-EA732C1880E4',
    name: 'bash',
    codepoint: 'F1183',
    deprecated: 'FALSE',
  },
  {
    id: '4E334FE9-DF93-469A-B7D7-AC88BBF25D84',
    name: 'basket',
    codepoint: 'F0076',
    deprecated: 'FALSE',
  },
  {
    id: '22A624ED-8F58-41BC-B82D-C1750626AA18',
    name: 'basket-check',
    codepoint: 'F18E5',
    deprecated: 'FALSE',
  },
  {
    id: '6B0573EB-F745-409B-B065-C492537579C6',
    name: 'basket-check-outline',
    codepoint: 'F18E6',
    deprecated: 'FALSE',
  },
  {
    id: '9B7DC550-62F9-4FB0-9B21-0379AA925A19',
    name: 'basket-fill',
    codepoint: 'F0077',
    deprecated: 'FALSE',
  },
  {
    id: 'C41B2071-6BBF-458A-B73A-409D231A9F4D',
    name: 'basket-minus',
    codepoint: 'F1523',
    deprecated: 'FALSE',
  },
  {
    id: '8F4899A8-07F0-4C00-90EE-1509BE7A0D4C',
    name: 'basket-minus-outline',
    codepoint: 'F1524',
    deprecated: 'FALSE',
  },
  {
    id: 'FC2C74AA-57B1-4E13-A499-AA81CBE6670A',
    name: 'basket-off',
    codepoint: 'F1525',
    deprecated: 'FALSE',
  },
  {
    id: '2D28FB7D-8AEA-4ED6-BB7D-DA76EC3DA4EB',
    name: 'basket-off-outline',
    codepoint: 'F1526',
    deprecated: 'FALSE',
  },
  {
    id: 'A9AC5A33-FD7D-49C8-9C5E-C41335F6E833',
    name: 'basket-outline',
    codepoint: 'F1181',
    deprecated: 'FALSE',
  },
  {
    id: '3B02F0C6-F80B-479B-ABA7-CFBDBA843EB4',
    name: 'basket-plus',
    codepoint: 'F1527',
    deprecated: 'FALSE',
  },
  {
    id: 'DC1E93FF-94C4-4B14-9152-ADDE374A44BB',
    name: 'basket-plus-outline',
    codepoint: 'F1528',
    deprecated: 'FALSE',
  },
  {
    id: 'C730C431-5377-4B0E-933B-5307E736B75A',
    name: 'basket-remove',
    codepoint: 'F1529',
    deprecated: 'FALSE',
  },
  {
    id: '8DB42CB2-5A41-489E-BC04-1DFADF917DE6',
    name: 'basket-remove-outline',
    codepoint: 'F152A',
    deprecated: 'FALSE',
  },
  {
    id: 'D46B98B3-4045-4EAD-A26E-659195B5CC65',
    name: 'basket-unfill',
    codepoint: 'F0078',
    deprecated: 'FALSE',
  },
  {
    id: 'FD797202-9EF1-407E-A290-97C014B8F37E',
    name: 'basketball',
    codepoint: 'F0806',
    deprecated: 'FALSE',
  },
  {
    id: '7792E2EB-0A99-44CF-AEBE-6683490594B0',
    name: 'basketball-hoop',
    codepoint: 'F0C3B',
    deprecated: 'FALSE',
  },
  {
    id: '3310ED1D-3E70-48C0-AE7B-14BB42E0CD16',
    name: 'basketball-hoop-outline',
    codepoint: 'F0C3C',
    deprecated: 'FALSE',
  },
  {
    id: '06925257-791B-4F7F-9A8F-A2D958EE8784',
    name: 'bat',
    codepoint: 'F0B5F',
    deprecated: 'FALSE',
  },
  {
    id: '4DB5FCB7-2D9E-4612-8087-4F78B0053305',
    name: 'bathtub',
    codepoint: 'F1818',
    deprecated: 'FALSE',
  },
  {
    id: 'EE0AF53A-2C79-48C3-842C-4AD04DB14D84',
    name: 'bathtub-outline',
    codepoint: 'F1819',
    deprecated: 'FALSE',
  },
  {
    id: 'C12D919F-7D0D-4355-8109-912BA37154B5',
    name: 'battery',
    codepoint: 'F0079',
    deprecated: 'FALSE',
  },
  {
    id: 'D753B56C-E806-4F17-B2A0-EBC4B4B78D19',
    name: 'battery-10',
    codepoint: 'F007A',
    deprecated: 'FALSE',
  },
  {
    id: '9C8DAC10-97C6-476E-8122-25D7709F7AF6',
    name: 'battery-10-bluetooth',
    codepoint: 'F093E',
    deprecated: 'FALSE',
  },
  {
    id: '95AEB324-9D57-4D27-B463-0C8077C04E24',
    name: 'battery-20',
    codepoint: 'F007B',
    deprecated: 'FALSE',
  },
  {
    id: '9BA1E9FB-BB6A-447E-9F35-615CCC81CD81',
    name: 'battery-20-bluetooth',
    codepoint: 'F093F',
    deprecated: 'FALSE',
  },
  {
    id: '970E1F42-4594-42CD-BB4E-6F5CBDBF5D21',
    name: 'battery-30',
    codepoint: 'F007C',
    deprecated: 'FALSE',
  },
  {
    id: '6128BD10-A4CD-440E-BF98-151777783110',
    name: 'battery-30-bluetooth',
    codepoint: 'F0940',
    deprecated: 'FALSE',
  },
  {
    id: 'EE290FAF-A1D6-4A0D-8BBA-8DD2455AFB82',
    name: 'battery-40',
    codepoint: 'F007D',
    deprecated: 'FALSE',
  },
  {
    id: '058D5094-AB60-4412-8C71-6CF4A56784A6',
    name: 'battery-40-bluetooth',
    codepoint: 'F0941',
    deprecated: 'FALSE',
  },
  {
    id: 'A79598F9-7FDF-46A2-9F8D-1DBD6BAB1A1D',
    name: 'battery-50',
    codepoint: 'F007E',
    deprecated: 'FALSE',
  },
  {
    id: '313BE145-A7FB-4620-915C-4B2298C70EA1',
    name: 'battery-50-bluetooth',
    codepoint: 'F0942',
    deprecated: 'FALSE',
  },
  {
    id: 'B6EB700B-9288-474C-9A5C-C269680BFC5F',
    name: 'battery-60',
    codepoint: 'F007F',
    deprecated: 'FALSE',
  },
  {
    id: 'B422BB57-AC2D-43C2-B7A0-67160D90519E',
    name: 'battery-60-bluetooth',
    codepoint: 'F0943',
    deprecated: 'FALSE',
  },
  {
    id: '5DC968CB-1DC2-42B1-96E2-80AC6B23BB9C',
    name: 'battery-70',
    codepoint: 'F0080',
    deprecated: 'FALSE',
  },
  {
    id: '426481A7-5387-4385-9D2D-BEB1BCF5900D',
    name: 'battery-70-bluetooth',
    codepoint: 'F0944',
    deprecated: 'FALSE',
  },
  {
    id: '54485D0C-49B3-424D-8283-4CE90E16F88D',
    name: 'battery-80',
    codepoint: 'F0081',
    deprecated: 'FALSE',
  },
  {
    id: '176EE56B-73A9-44C7-94FF-90C11A48127E',
    name: 'battery-80-bluetooth',
    codepoint: 'F0945',
    deprecated: 'FALSE',
  },
  {
    id: '58205285-7EAA-400A-A8E5-A9373E7D986A',
    name: 'battery-90',
    codepoint: 'F0082',
    deprecated: 'FALSE',
  },
  {
    id: 'AEB32E6B-7E3D-4C3A-AE1A-96C63868E208',
    name: 'battery-90-bluetooth',
    codepoint: 'F0946',
    deprecated: 'FALSE',
  },
  {
    id: 'ABFAD078-0E10-4293-94AD-16871B75E51F',
    name: 'battery-alert',
    codepoint: 'F0083',
    deprecated: 'FALSE',
  },
  {
    id: '7C1C81FB-9E38-4220-B225-460056043383',
    name: 'battery-alert-bluetooth',
    codepoint: 'F0947',
    deprecated: 'FALSE',
  },
  {
    id: 'DD0191F8-8AE0-4D80-A29C-AB7451DAE464',
    name: 'battery-alert-variant',
    codepoint: 'F10CC',
    deprecated: 'FALSE',
  },
  {
    id: 'C384D39D-C672-454D-964E-8AEF06C2381F',
    name: 'battery-alert-variant-outline',
    codepoint: 'F10CD',
    deprecated: 'FALSE',
  },
  {
    id: 'E57A937E-29A3-4FE5-8B47-6E678F3D0539',
    name: 'battery-arrow-down',
    codepoint: 'F17DE',
    deprecated: 'FALSE',
  },
  {
    id: '3CD83E68-9EEE-4DB8-B6D9-CCC8123BA198',
    name: 'battery-arrow-down-outline',
    codepoint: 'F17DF',
    deprecated: 'FALSE',
  },
  {
    id: 'DA4F5288-0BD2-4D9F-A49D-8E40E9893789',
    name: 'battery-arrow-up',
    codepoint: 'F17E0',
    deprecated: 'FALSE',
  },
  {
    id: 'D81D0F0F-E856-4F7A-BB00-B4462105D5DB',
    name: 'battery-arrow-up-outline',
    codepoint: 'F17E1',
    deprecated: 'FALSE',
  },
  {
    id: '3DB7EA89-8F0C-40DA-B45A-E5B588F2CC01',
    name: 'battery-bluetooth',
    codepoint: 'F0948',
    deprecated: 'FALSE',
  },
  {
    id: '910BCFA6-ED67-48A5-97EB-BA61969629E9',
    name: 'battery-bluetooth-variant',
    codepoint: 'F0949',
    deprecated: 'FALSE',
  },
  {
    id: 'F365ACF3-9453-4516-B592-AECD72AB7433',
    name: 'battery-charging',
    codepoint: 'F0084',
    deprecated: 'FALSE',
  },
  {
    id: 'EBDD7C45-CE9F-4503-9CE4-0682E4E8E6BF',
    name: 'battery-charging-10',
    codepoint: 'F089C',
    deprecated: 'FALSE',
  },
  {
    id: 'C1379E5C-2335-4C37-9A73-EE54D6747512',
    name: 'battery-charging-100',
    codepoint: 'F0085',
    deprecated: 'FALSE',
  },
  {
    id: '3E25E8BE-5097-4414-A7E4-9CDF02CD4F84',
    name: 'battery-charging-20',
    codepoint: 'F0086',
    deprecated: 'FALSE',
  },
  {
    id: '04D00269-7E8F-40D1-BFC4-36A01720260B',
    name: 'battery-charging-30',
    codepoint: 'F0087',
    deprecated: 'FALSE',
  },
  {
    id: 'D40803B3-43CB-4680-A7BD-56CC83ED54B8',
    name: 'battery-charging-40',
    codepoint: 'F0088',
    deprecated: 'FALSE',
  },
  {
    id: '1681D3BD-4990-4571-87B0-1C846DC8D600',
    name: 'battery-charging-50',
    codepoint: 'F089D',
    deprecated: 'FALSE',
  },
  {
    id: '23E8809B-FB62-4573-83CF-9C33E980FD41',
    name: 'battery-charging-60',
    codepoint: 'F0089',
    deprecated: 'FALSE',
  },
  {
    id: 'C8C75ED1-4B80-42A7-B590-DA3BE7567E86',
    name: 'battery-charging-70',
    codepoint: 'F089E',
    deprecated: 'FALSE',
  },
  {
    id: '20455031-CCDD-40F8-A7A4-5E1F9E55F20E',
    name: 'battery-charging-80',
    codepoint: 'F008A',
    deprecated: 'FALSE',
  },
  {
    id: '0EC45F7B-7A0A-43AD-ABD3-10E4209659C0',
    name: 'battery-charging-90',
    codepoint: 'F008B',
    deprecated: 'FALSE',
  },
  {
    id: '2BD37CBE-629C-41E8-8631-CD438C1E7704',
    name: 'battery-charging-high',
    codepoint: 'F12A6',
    deprecated: 'FALSE',
  },
  {
    id: 'C0158CBD-1F8D-491B-9825-9DB96D901908',
    name: 'battery-charging-low',
    codepoint: 'F12A4',
    deprecated: 'FALSE',
  },
  {
    id: 'FD7F7486-1E30-44A5-A4B2-C908372835CD',
    name: 'battery-charging-medium',
    codepoint: 'F12A5',
    deprecated: 'FALSE',
  },
  {
    id: 'A057A8D0-A442-4948-B2BF-63CECE073AF5',
    name: 'battery-charging-outline',
    codepoint: 'F089F',
    deprecated: 'FALSE',
  },
  {
    id: '60EEA5B6-8070-482D-ABE3-54A9D2A80875',
    name: 'battery-charging-wireless',
    codepoint: 'F0807',
    deprecated: 'FALSE',
  },
  {
    id: '5D2990D5-D3D4-4E8F-AED8-B90CE6164068',
    name: 'battery-charging-wireless-10',
    codepoint: 'F0808',
    deprecated: 'FALSE',
  },
  {
    id: '5C194C6F-8CFE-4F1A-AFD3-11D7E8CB89B4',
    name: 'battery-charging-wireless-20',
    codepoint: 'F0809',
    deprecated: 'FALSE',
  },
  {
    id: '567818F9-23C0-41C4-A3BD-2BA37F8BFF29',
    name: 'battery-charging-wireless-30',
    codepoint: 'F080A',
    deprecated: 'FALSE',
  },
  {
    id: '1ABBC4EA-32D6-4FBA-AA00-E19707123AE5',
    name: 'battery-charging-wireless-40',
    codepoint: 'F080B',
    deprecated: 'FALSE',
  },
  {
    id: '9BC0DBFF-A3F6-4061-B6E1-685880D6B653',
    name: 'battery-charging-wireless-50',
    codepoint: 'F080C',
    deprecated: 'FALSE',
  },
  {
    id: 'F09D15A6-1E87-488C-9F75-24EC677E72FF',
    name: 'battery-charging-wireless-60',
    codepoint: 'F080D',
    deprecated: 'FALSE',
  },
  {
    id: '45966E8F-2801-4ED6-970F-12D9FD6C6290',
    name: 'battery-charging-wireless-70',
    codepoint: 'F080E',
    deprecated: 'FALSE',
  },
  {
    id: 'EB5E0801-60FA-4DD3-8709-2A6F0AC9C3ED',
    name: 'battery-charging-wireless-80',
    codepoint: 'F080F',
    deprecated: 'FALSE',
  },
  {
    id: 'E8C0AC60-D7AB-4FA3-9596-CE002E798180',
    name: 'battery-charging-wireless-90',
    codepoint: 'F0810',
    deprecated: 'FALSE',
  },
  {
    id: 'F835E187-444F-43B8-A057-9915F1388A87',
    name: 'battery-charging-wireless-alert',
    codepoint: 'F0811',
    deprecated: 'FALSE',
  },
  {
    id: '4AFDE54E-1A6D-4F7F-BD0D-AB67FB0F12CC',
    name: 'battery-charging-wireless-outline',
    codepoint: 'F0812',
    deprecated: 'FALSE',
  },
  {
    id: '0D73145E-B849-4DA8-AC86-8DA3840ACC38',
    name: 'battery-check',
    codepoint: 'F17E2',
    deprecated: 'FALSE',
  },
  {
    id: '75AE3FD5-ADC3-41FD-BC86-882A771C396A',
    name: 'battery-check-outline',
    codepoint: 'F17E3',
    deprecated: 'FALSE',
  },
  {
    id: '11C7A5CA-DA77-453A-BAA8-6345949394C7',
    name: 'battery-clock',
    codepoint: 'F19E5',
    deprecated: 'FALSE',
  },
  {
    id: '5756A4F3-FBED-4F81-8B97-AF391EC4D893',
    name: 'battery-clock-outline',
    codepoint: 'F19E6',
    deprecated: 'FALSE',
  },
  {
    id: 'DEE88AFA-DFF3-4100-8475-EB5EB72CCFCB',
    name: 'battery-heart',
    codepoint: 'F120F',
    deprecated: 'FALSE',
  },
  {
    id: '0D342AE3-0BE6-40C3-BAF9-D519508C4546',
    name: 'battery-heart-outline',
    codepoint: 'F1210',
    deprecated: 'FALSE',
  },
  {
    id: '2DE04585-054B-4E09-AC31-33E28DD80942',
    name: 'battery-heart-variant',
    codepoint: 'F1211',
    deprecated: 'FALSE',
  },
  {
    id: '253E4BDF-CE79-459D-B06D-915B34A12227',
    name: 'battery-high',
    codepoint: 'F12A3',
    deprecated: 'FALSE',
  },
  {
    id: 'F24949D4-ED65-4517-8AA1-71160F05D0F8',
    name: 'battery-lock',
    codepoint: 'F179C',
    deprecated: 'FALSE',
  },
  {
    id: '20BE6E36-64DD-49BD-BA06-F90480EF33A5',
    name: 'battery-lock-open',
    codepoint: 'F179D',
    deprecated: 'FALSE',
  },
  {
    id: '4BDC283A-A6B7-4B2B-A18A-2B25410AF35C',
    name: 'battery-low',
    codepoint: 'F12A1',
    deprecated: 'FALSE',
  },
  {
    id: '59FF99D5-138F-4D6B-90B2-4782A4B3F8EB',
    name: 'battery-medium',
    codepoint: 'F12A2',
    deprecated: 'FALSE',
  },
  {
    id: 'C34C0934-799C-40A5-8AD4-CE7A417C170B',
    name: 'battery-minus',
    codepoint: 'F17E4',
    deprecated: 'FALSE',
  },
  {
    id: 'BDFA54F2-D16E-441C-A039-3E6DB60EE182',
    name: 'battery-minus-outline',
    codepoint: 'F17E5',
    deprecated: 'FALSE',
  },
  {
    id: '15E0E37E-564A-40D5-9D4A-948C932C8E52',
    name: 'battery-minus-variant',
    codepoint: 'F008C',
    deprecated: 'FALSE',
  },
  {
    id: '447DBB2A-31ED-4856-A7A2-A61BF0780C7B',
    name: 'battery-negative',
    codepoint: 'F008D',
    deprecated: 'FALSE',
  },
  {
    id: 'C0939A88-BF53-4323-A12F-E6A92F7BE327',
    name: 'battery-off',
    codepoint: 'F125D',
    deprecated: 'FALSE',
  },
  {
    id: '66A0E28A-53F2-4E22-B14A-BADBE10E0CA9',
    name: 'battery-off-outline',
    codepoint: 'F125E',
    deprecated: 'FALSE',
  },
  {
    id: '925B02B4-CA05-4545-A4E3-421F8412DA37',
    name: 'battery-outline',
    codepoint: 'F008E',
    deprecated: 'FALSE',
  },
  {
    id: '07911E7E-BA7E-49FD-90B1-7B8D273D9278',
    name: 'battery-plus',
    codepoint: 'F17E6',
    deprecated: 'FALSE',
  },
  {
    id: 'E9A097FD-0484-4E92-BC8F-2726EF5CAB5B',
    name: 'battery-plus-outline',
    codepoint: 'F17E7',
    deprecated: 'FALSE',
  },
  {
    id: 'A4BD7F0D-E95C-413C-B853-435A53854844',
    name: 'battery-plus-variant',
    codepoint: 'F008F',
    deprecated: 'FALSE',
  },
  {
    id: '195F51E4-5B02-4BE3-B407-4B7FC1445F18',
    name: 'battery-positive',
    codepoint: 'F0090',
    deprecated: 'FALSE',
  },
  {
    id: 'C0E3D38A-A3B2-4010-8B9B-9CB97F67FE50',
    name: 'battery-remove',
    codepoint: 'F17E8',
    deprecated: 'FALSE',
  },
  {
    id: 'FC6E9489-07CC-4D0D-B93C-44E0E257A365',
    name: 'battery-remove-outline',
    codepoint: 'F17E9',
    deprecated: 'FALSE',
  },
  {
    id: 'A78E1E92-F129-4050-AA6F-B308D171A2DC',
    name: 'battery-sync',
    codepoint: 'F1834',
    deprecated: 'FALSE',
  },
  {
    id: '5250464D-70A3-4BC2-8969-878E57526CDF',
    name: 'battery-sync-outline',
    codepoint: 'F1835',
    deprecated: 'FALSE',
  },
  {
    id: '80257130-A38B-4BC1-B194-2EB16048D763',
    name: 'battery-unknown',
    codepoint: 'F0091',
    deprecated: 'FALSE',
  },
  {
    id: 'B30963B2-893D-43F8-9EFD-918DCB654B75',
    name: 'battery-unknown-bluetooth',
    codepoint: 'F094A',
    deprecated: 'FALSE',
  },
  {
    id: 'FB1FEB37-FA9B-40BF-A7EC-B4A1690E6B77',
    name: 'beach',
    codepoint: 'F0092',
    deprecated: 'FALSE',
  },
  {
    id: 'AC08F348-ACA6-440E-A28D-FCC9BFC85E27',
    name: 'beaker',
    codepoint: 'F0CEA',
    deprecated: 'FALSE',
  },
  {
    id: '22743DE0-B662-44CD-8707-91ABF624D0E3',
    name: 'beaker-alert',
    codepoint: 'F1229',
    deprecated: 'FALSE',
  },
  {
    id: '327C0F93-7EBD-4B24-A31C-285F6FC14DF3',
    name: 'beaker-alert-outline',
    codepoint: 'F122A',
    deprecated: 'FALSE',
  },
  {
    id: '252089E7-6AA1-400C-99DF-B8F2090BE78A',
    name: 'beaker-check',
    codepoint: 'F122B',
    deprecated: 'FALSE',
  },
  {
    id: '23EFC5EC-7DAE-46FC-8E1C-488CF2EFFEB3',
    name: 'beaker-check-outline',
    codepoint: 'F122C',
    deprecated: 'FALSE',
  },
  {
    id: 'FC008372-099E-45EB-BD51-13D1CA45A9BF',
    name: 'beaker-minus',
    codepoint: 'F122D',
    deprecated: 'FALSE',
  },
  {
    id: '15C31E14-F9D4-49E7-A9FA-266E3C2C7F1B',
    name: 'beaker-minus-outline',
    codepoint: 'F122E',
    deprecated: 'FALSE',
  },
  {
    id: '95E06F22-3EA5-448F-B260-E555C1DCD18E',
    name: 'beaker-outline',
    codepoint: 'F0690',
    deprecated: 'FALSE',
  },
  {
    id: 'CDF9CCFD-BDE5-4BBF-94DA-BF61466906A6',
    name: 'beaker-plus',
    codepoint: 'F122F',
    deprecated: 'FALSE',
  },
  {
    id: '210E9002-74BB-44CB-9D93-7CC8EE95D519',
    name: 'beaker-plus-outline',
    codepoint: 'F1230',
    deprecated: 'FALSE',
  },
  {
    id: '21A03544-7009-4069-8BF0-17168DF93ED9',
    name: 'beaker-question',
    codepoint: 'F1231',
    deprecated: 'FALSE',
  },
  {
    id: '3C151400-745C-44BE-A94E-A2F5365C7DD6',
    name: 'beaker-question-outline',
    codepoint: 'F1232',
    deprecated: 'FALSE',
  },
  {
    id: '0F41805A-67FE-4ADB-A398-B22032BF0C89',
    name: 'beaker-remove',
    codepoint: 'F1233',
    deprecated: 'FALSE',
  },
  {
    id: 'DF5AF4E0-65C2-492A-AEDD-0297AE36BA2B',
    name: 'beaker-remove-outline',
    codepoint: 'F1234',
    deprecated: 'FALSE',
  },
  {
    id: 'EA62EF73-ACC7-4E54-87EF-D5E1683BE58F',
    name: 'bed',
    codepoint: 'F02E3',
    deprecated: 'FALSE',
  },
  {
    id: '91A8F1FF-F40E-44EC-A96B-776517F69769',
    name: 'bed-clock',
    codepoint: 'F1B94',
    deprecated: 'FALSE',
  },
  {
    id: '76E0CC3D-3FF4-421A-A7F0-0F38C68C92A2',
    name: 'bed-double',
    codepoint: 'F0FD4',
    deprecated: 'FALSE',
  },
  {
    id: '6061EF08-3805-4F30-88A1-0DC3419E6989',
    name: 'bed-double-outline',
    codepoint: 'F0FD3',
    deprecated: 'FALSE',
  },
  {
    id: '15DC169E-459C-4EDB-BF8F-3E1D4F34B2BF',
    name: 'bed-empty',
    codepoint: 'F08A0',
    deprecated: 'FALSE',
  },
  {
    id: 'D6763D57-A47E-4BFA-A493-5F25C164FB2F',
    name: 'bed-king',
    codepoint: 'F0FD2',
    deprecated: 'FALSE',
  },
  {
    id: 'E84472BF-7730-4700-B468-E5096FD65531',
    name: 'bed-king-outline',
    codepoint: 'F0FD1',
    deprecated: 'FALSE',
  },
  {
    id: '860292B0-C722-4829-9E30-AACF00D09C8C',
    name: 'bed-outline',
    codepoint: 'F0099',
    deprecated: 'FALSE',
  },
  {
    id: '0DB69F3A-8C9B-48D0-8D37-5A1CF6B03B68',
    name: 'bed-queen',
    codepoint: 'F0FD0',
    deprecated: 'FALSE',
  },
  {
    id: 'DC8A5BF4-BEA0-4D7D-B364-4765854CD4CE',
    name: 'bed-queen-outline',
    codepoint: 'F0FDB',
    deprecated: 'FALSE',
  },
  {
    id: 'A9C924A7-EAA8-42FC-963A-C74E36448A1B',
    name: 'bed-single',
    codepoint: 'F106D',
    deprecated: 'FALSE',
  },
  {
    id: 'C9FEB434-571C-41C6-A0E5-1FA68368ACC3',
    name: 'bed-single-outline',
    codepoint: 'F106E',
    deprecated: 'FALSE',
  },
  {
    id: '8B580447-CD93-41E8-BDB0-F37DAE420D3A',
    name: 'bee',
    codepoint: 'F0FA1',
    deprecated: 'FALSE',
  },
  {
    id: 'ED379BAE-ED56-474D-87C3-8B43D5207766',
    name: 'bee-flower',
    codepoint: 'F0FA2',
    deprecated: 'FALSE',
  },
  {
    id: '47006F8F-3A47-4D8A-9224-CF1F823E1B35',
    name: 'beehive-off-outline',
    codepoint: 'F13ED',
    deprecated: 'FALSE',
  },
  {
    id: '667E2F2C-B896-48EF-B5F9-EF9436A8BE4B',
    name: 'beehive-outline',
    codepoint: 'F10CE',
    deprecated: 'FALSE',
  },
  {
    id: '520FBDF0-4EB1-4413-89F9-AB4E3518FA38',
    name: 'beekeeper',
    codepoint: 'F14E2',
    deprecated: 'FALSE',
  },
  {
    id: '9D9A73D2-0780-4141-95A3-CDFE21E6D4D6',
    name: 'beer',
    codepoint: 'F0098',
    deprecated: 'FALSE',
  },
  {
    id: '524E1789-7B3A-4BAD-9D04-2E8FD838DCFB',
    name: 'beer-outline',
    codepoint: 'F130C',
    deprecated: 'FALSE',
  },
  {
    id: '45fea174-07db-11e4-bf19-842b2b6cfe1b',
    name: 'bell',
    codepoint: 'F009A',
    deprecated: 'FALSE',
  },
  {
    id: '376D7457-2DC3-463A-B4D9-4F170E0B19FB',
    name: 'bell-alert',
    codepoint: 'F0D59',
    deprecated: 'FALSE',
  },
  {
    id: '2E15CEB7-FC9C-4822-94CF-D84F99824F3C',
    name: 'bell-alert-outline',
    codepoint: 'F0E81',
    deprecated: 'FALSE',
  },
  {
    id: '9DA90BB6-B525-47A8-879F-66EC572FE31B',
    name: 'bell-badge',
    codepoint: 'F116B',
    deprecated: 'FALSE',
  },
  {
    id: '42F0733E-F36F-491B-ADC9-AC8ABAF2C0B7',
    name: 'bell-badge-outline',
    codepoint: 'F0178',
    deprecated: 'FALSE',
  },
  {
    id: '61CFFDEC-BE16-404E-8ECA-F911F8105C7C',
    name: 'bell-cancel',
    codepoint: 'F13E7',
    deprecated: 'FALSE',
  },
  {
    id: '5CA70271-00B4-4412-B082-FED05EFF165A',
    name: 'bell-cancel-outline',
    codepoint: 'F13E8',
    deprecated: 'FALSE',
  },
  {
    id: 'A00A7DBE-44BB-4843-B740-FAAB76357B03',
    name: 'bell-check',
    codepoint: 'F11E5',
    deprecated: 'FALSE',
  },
  {
    id: '510AFFA1-DEEE-4A0B-B596-611969C5934D',
    name: 'bell-check-outline',
    codepoint: 'F11E6',
    deprecated: 'FALSE',
  },
  {
    id: 'D811F01E-BDFB-496F-866C-70533A343B57',
    name: 'bell-circle',
    codepoint: 'F0D5A',
    deprecated: 'FALSE',
  },
  {
    id: '204DDC9C-1546-44DF-829D-2EA5AA299804',
    name: 'bell-circle-outline',
    codepoint: 'F0D5B',
    deprecated: 'FALSE',
  },
  {
    id: '8191437C-298C-4A5F-8EC3-A2DA4584BF0D',
    name: 'bell-cog',
    codepoint: 'F1A29',
    deprecated: 'FALSE',
  },
  {
    id: '51BF300C-2921-4364-B362-56E4D81DA629',
    name: 'bell-cog-outline',
    codepoint: 'F1A2A',
    deprecated: 'FALSE',
  },
  {
    id: 'C25507E4-8865-47BF-A6AB-D97978AB4397',
    name: 'bell-minus',
    codepoint: 'F13E9',
    deprecated: 'FALSE',
  },
  {
    id: '7344702F-0680-4095-BF7E-D9AFDE650703',
    name: 'bell-minus-outline',
    codepoint: 'F13EA',
    deprecated: 'FALSE',
  },
  {
    id: '69686D8F-75FE-4091-9061-88DB64D95C01',
    name: 'bell-off',
    codepoint: 'F009B',
    deprecated: 'FALSE',
  },
  {
    id: '6C0FD819-5174-4CCA-8A63-2CEE9F6EE71E',
    name: 'bell-off-outline',
    codepoint: 'F0A91',
    deprecated: 'FALSE',
  },
  {
    id: '70A190E9-6F1D-40DB-81D4-C9516949DF0B',
    name: 'bell-outline',
    codepoint: 'F009C',
    deprecated: 'FALSE',
  },
  {
    id: 'FC07FF46-2FD1-4E4E-8D35-BADDEEB5F531',
    name: 'bell-plus',
    codepoint: 'F009D',
    deprecated: 'FALSE',
  },
  {
    id: '45FB036D-61D0-4390-9F15-480121F2CE3B',
    name: 'bell-plus-outline',
    codepoint: 'F0A92',
    deprecated: 'FALSE',
  },
  {
    id: '16A7DF47-4674-4439-9E33-ADE491DFC806',
    name: 'bell-remove',
    codepoint: 'F13EB',
    deprecated: 'FALSE',
  },
  {
    id: 'BFCC1A57-A94F-4104-BCBF-980E9D8B2B6C',
    name: 'bell-remove-outline',
    codepoint: 'F13EC',
    deprecated: 'FALSE',
  },
  {
    id: 'CD75C484-B1AD-492B-8CAA-9F002398C3CA',
    name: 'bell-ring',
    codepoint: 'F009E',
    deprecated: 'FALSE',
  },
  {
    id: 'EE7DADC1-CF66-4922-B0C9-9B8F753514F9',
    name: 'bell-ring-outline',
    codepoint: 'F009F',
    deprecated: 'FALSE',
  },
  {
    id: '43BD9E91-2044-4B80-AF87-2FC810C42A1B',
    name: 'bell-sleep',
    codepoint: 'F00A0',
    deprecated: 'FALSE',
  },
  {
    id: '91193FC6-AF35-46FD-A05E-6AA99D6EA99F',
    name: 'bell-sleep-outline',
    codepoint: 'F0A93',
    deprecated: 'FALSE',
  },
  {
    id: '4F83A19F-BAB5-4894-AC5A-B288AFD592DC',
    name: 'beta',
    codepoint: 'F00A1',
    deprecated: 'FALSE',
  },
  {
    id: '5FA9C13A-087C-452E-966F-4C2FCE336C68',
    name: 'betamax',
    codepoint: 'F09CB',
    deprecated: 'FALSE',
  },
  {
    id: '7E88D677-5560-4DF1-A3D0-FC2814BF6612',
    name: 'biathlon',
    codepoint: 'F0E14',
    deprecated: 'FALSE',
  },
  {
    id: '90DDBB60-0BBB-4DFA-9626-32D4D1FB34C5',
    name: 'bicycle',
    codepoint: 'F109C',
    deprecated: 'FALSE',
  },
  {
    id: 'CF4876E1-DEBA-4C41-B5B7-4C90C3EF68F4',
    name: 'bicycle-basket',
    codepoint: 'F1235',
    deprecated: 'FALSE',
  },
  {
    id: '63EFAAFD-2EB5-4600-930D-33CEDDC182A5',
    name: 'bicycle-cargo',
    codepoint: 'F189C',
    deprecated: 'FALSE',
  },
  {
    id: '3923C397-5A00-472A-8FDC-FBBAAF3FA323',
    name: 'bicycle-electric',
    codepoint: 'F15B4',
    deprecated: 'FALSE',
  },
  {
    id: 'A7A362D7-385E-4026-A43F-BBF13E8D4B37',
    name: 'bicycle-penny-farthing',
    codepoint: 'F15E9',
    deprecated: 'FALSE',
  },
  {
    id: '627C7FF1-BCAD-4741-966E-7CED73B35DC2',
    name: 'bike',
    codepoint: 'F00A3',
    deprecated: 'FALSE',
  },
  {
    id: '2C312B6E-73D4-4A98-A701-D49E31F95E7B',
    name: 'bike-fast',
    codepoint: 'F111F',
    deprecated: 'FALSE',
  },
  {
    id: '645C4EDB-9339-4784-8AE9-BD60F8460CAE',
    name: 'billboard',
    codepoint: 'F1010',
    deprecated: 'FALSE',
  },
  {
    id: '6FFE2C93-4350-4706-9694-1674F96855EA',
    name: 'billiards',
    codepoint: 'F0B61',
    deprecated: 'FALSE',
  },
  {
    id: '2ED0EE4C-D928-41C6-8D5D-3AA3A2DF7AF6',
    name: 'billiards-rack',
    codepoint: 'F0B62',
    deprecated: 'FALSE',
  },
  {
    id: '75806AA4-294B-4A0A-8BDB-FC4CABCA1768',
    name: 'binoculars',
    codepoint: 'F00A5',
    deprecated: 'FALSE',
  },
  {
    id: 'F5D76655-0F6E-4FF2-AC74-CC3A235B7FC6',
    name: 'bio',
    codepoint: 'F00A6',
    deprecated: 'FALSE',
  },
  {
    id: '96BDE572-9D10-47D0-919F-68EB6CD3DFA9',
    name: 'biohazard',
    codepoint: 'F00A7',
    deprecated: 'FALSE',
  },
  {
    id: '18622D72-42B2-4919-BDB5-DCC77310045B',
    name: 'bird',
    codepoint: 'F15C6',
    deprecated: 'FALSE',
  },
  {
    id: 'B48BB714-F66F-4D86-9864-051DB90AE197',
    name: 'bitbucket',
    codepoint: 'F00A8',
    deprecated: 'TRUE',
  },
  {
    id: '9C8A35A5-86B5-4B10-BEE9-93CDFAF15B9C',
    name: 'bitcoin',
    codepoint: 'F0813',
    deprecated: 'TRUE',
  },
  {
    id: '1F233821-5ED4-4CB3-8933-9A16C05D7AC9',
    name: 'black-mesa',
    codepoint: 'F00A9',
    deprecated: 'TRUE',
  },
  {
    id: 'AC980D29-7F19-4356-970E-A147CFEB64E3',
    name: 'blender',
    codepoint: 'F0CEB',
    deprecated: 'FALSE',
  },
  {
    id: 'C8C79C9B-59FC-417E-9516-E5351594A994',
    name: 'blender-outline',
    codepoint: 'F181A',
    deprecated: 'FALSE',
  },
  {
    id: 'F365BC90-C8EC-4A5C-99FB-C2ED27B5593A',
    name: 'blender-software',
    codepoint: 'F00AB',
    deprecated: 'TRUE',
  },
  {
    id: '926ACBEB-C5F6-4EF4-829E-E0B05501DDD6',
    name: 'blinds',
    codepoint: 'F00AC',
    deprecated: 'FALSE',
  },
  {
    id: '2E6100FD-8F6C-423C-BC18-372F8D57DC59',
    name: 'blinds-horizontal',
    codepoint: 'F1A2B',
    deprecated: 'FALSE',
  },
  {
    id: '18949103-543E-4756-83E4-394E60DD1036',
    name: 'blinds-horizontal-closed',
    codepoint: 'F1A2C',
    deprecated: 'FALSE',
  },
  {
    id: '5DCCF3FA-F418-4052-9B93-6FA85C2659F5',
    name: 'blinds-open',
    codepoint: 'F1011',
    deprecated: 'FALSE',
  },
  {
    id: 'F57CEB13-5A41-498C-BCF5-D7036E7B1122',
    name: 'blinds-vertical',
    codepoint: 'F1A2D',
    deprecated: 'FALSE',
  },
  {
    id: '293F404C-5568-4F17-A6EB-0750931EE2B6',
    name: 'blinds-vertical-closed',
    codepoint: 'F1A2E',
    deprecated: 'FALSE',
  },
  {
    id: '5832B598-DAAA-4497-B995-CF940A1C7F20',
    name: 'block-helper',
    codepoint: 'F00AD',
    deprecated: 'FALSE',
  },
  {
    id: 'FA540210-0F58-4D2A-86BA-E202B520C8DA',
    name: 'blood-bag',
    codepoint: 'F0CEC',
    deprecated: 'FALSE',
  },
  {
    id: '7CBB03E7-EAC6-44C2-8C28-A78728F8BB7B',
    name: 'bluetooth',
    codepoint: 'F00AF',
    deprecated: 'FALSE',
  },
  {
    id: '6639774A-9027-4D20-AE57-24C78EF41141',
    name: 'bluetooth-audio',
    codepoint: 'F00B0',
    deprecated: 'FALSE',
  },
  {
    id: '1E123F41-EA93-4085-8446-88F50C501A6F',
    name: 'bluetooth-connect',
    codepoint: 'F00B1',
    deprecated: 'FALSE',
  },
  {
    id: 'CEB015C9-455E-4551-A898-7A992658CF72',
    name: 'bluetooth-off',
    codepoint: 'F00B2',
    deprecated: 'FALSE',
  },
  {
    id: 'E5DEA4AB-58E1-45CF-81EF-1A98594C5884',
    name: 'bluetooth-settings',
    codepoint: 'F00B3',
    deprecated: 'FALSE',
  },
  {
    id: 'A0D9FDB0-B6D8-43F9-A418-01B78336C84C',
    name: 'bluetooth-transfer',
    codepoint: 'F00B4',
    deprecated: 'FALSE',
  },
  {
    id: 'FAFCB184-2AAD-4E83-924F-1C37F1EDF956',
    name: 'blur',
    codepoint: 'F00B5',
    deprecated: 'FALSE',
  },
  {
    id: 'B23B281D-C753-468E-A0D1-79C2963346C8',
    name: 'blur-linear',
    codepoint: 'F00B6',
    deprecated: 'FALSE',
  },
  {
    id: '4F541CC2-7659-4230-A307-6E5D515C529B',
    name: 'blur-off',
    codepoint: 'F00B7',
    deprecated: 'FALSE',
  },
  {
    id: '29F42A3B-BCD5-45BA-9B87-35F97FE9438D',
    name: 'blur-radial',
    codepoint: 'F00B8',
    deprecated: 'FALSE',
  },
  {
    id: '4371183D-0D2B-4512-8069-93834BC85DE7',
    name: 'bolt',
    codepoint: 'F0DB3',
    deprecated: 'FALSE',
  },
  {
    id: '7DC40C3F-3733-48AF-94C7-5454FB849A49',
    name: 'bomb',
    codepoint: 'F0691',
    deprecated: 'FALSE',
  },
  {
    id: 'DEC98F6C-E85D-400C-87CD-6AE692583838',
    name: 'bomb-off',
    codepoint: 'F06C5',
    deprecated: 'FALSE',
  },
  {
    id: '7FCD9594-B235-45D5-8504-A41F251FD50E',
    name: 'bone',
    codepoint: 'F00B9',
    deprecated: 'FALSE',
  },
  {
    id: '2ECDAB32-FFDA-4E29-9465-D932794C53EF',
    name: 'bone-off',
    codepoint: 'F19E0',
    deprecated: 'FALSE',
  },
  {
    id: 'FFF82791-D12F-4643-8128-71CF5FE38C9B',
    name: 'book',
    codepoint: 'F00BA',
    deprecated: 'FALSE',
  },
  {
    id: 'D4247A9D-BD96-40AC-8C4E-A9A255DF5160',
    name: 'book-account',
    codepoint: 'F13AD',
    deprecated: 'FALSE',
  },
  {
    id: 'C9126B03-23E9-457D-9249-ADB88EA6F0E4',
    name: 'book-account-outline',
    codepoint: 'F13AE',
    deprecated: 'FALSE',
  },
  {
    id: '08CE9E71-4426-4186-8573-7B2EDFE28F7D',
    name: 'book-alert',
    codepoint: 'F167C',
    deprecated: 'FALSE',
  },
  {
    id: '6DDFDD69-EBA5-4BFB-A44F-6B84A7B3A4BC',
    name: 'book-alert-outline',
    codepoint: 'F167D',
    deprecated: 'FALSE',
  },
  {
    id: '250BF541-072A-4773-8BC2-B1943DF1EDBB',
    name: 'book-alphabet',
    codepoint: 'F061D',
    deprecated: 'FALSE',
  },
  {
    id: '7B6400CF-D8CF-418B-A400-CDCA1D58E813',
    name: 'book-arrow-down',
    codepoint: 'F167E',
    deprecated: 'FALSE',
  },
  {
    id: '7B9920BE-2895-4D38-A981-5E416D76403A',
    name: 'book-arrow-down-outline',
    codepoint: 'F167F',
    deprecated: 'FALSE',
  },
  {
    id: '5D776E4B-7F6C-4385-A892-964B3F800F30',
    name: 'book-arrow-left',
    codepoint: 'F1680',
    deprecated: 'FALSE',
  },
  {
    id: '1730A6B3-D405-4F4B-A12D-0957B5B268D7',
    name: 'book-arrow-left-outline',
    codepoint: 'F1681',
    deprecated: 'FALSE',
  },
  {
    id: '2780AAEC-BE71-4C4A-8C02-7BCDBE521D74',
    name: 'book-arrow-right',
    codepoint: 'F1682',
    deprecated: 'FALSE',
  },
  {
    id: 'CCBCE4D7-2799-433B-AF47-A5105E45779A',
    name: 'book-arrow-right-outline',
    codepoint: 'F1683',
    deprecated: 'FALSE',
  },
  {
    id: 'E724EA9A-002E-4A3E-9275-690258105F1D',
    name: 'book-arrow-up',
    codepoint: 'F1684',
    deprecated: 'FALSE',
  },
  {
    id: 'D3DE3980-8DF9-4FED-B08D-F04B5610B84D',
    name: 'book-arrow-up-outline',
    codepoint: 'F1685',
    deprecated: 'FALSE',
  },
  {
    id: '8D3C8531-8EAD-43FC-9139-B1A9D2BEB63D',
    name: 'book-cancel',
    codepoint: 'F1686',
    deprecated: 'FALSE',
  },
  {
    id: 'FA64FF4E-C720-4635-90EF-D4A913168329',
    name: 'book-cancel-outline',
    codepoint: 'F1687',
    deprecated: 'FALSE',
  },
  {
    id: 'ADF6BB0F-12F3-498F-B31A-07F6BFFFDF9D',
    name: 'book-check',
    codepoint: 'F14F3',
    deprecated: 'FALSE',
  },
  {
    id: '32A94643-61F6-4F21-BB4D-68932CB98FB4',
    name: 'book-check-outline',
    codepoint: 'F14F4',
    deprecated: 'FALSE',
  },
  {
    id: '97CCD006-4DE0-4AEE-BB95-D9EBCCDE8012',
    name: 'book-clock',
    codepoint: 'F1688',
    deprecated: 'FALSE',
  },
  {
    id: 'EFEC2FA7-18B1-4753-88C4-E2531FA96DD5',
    name: 'book-clock-outline',
    codepoint: 'F1689',
    deprecated: 'FALSE',
  },
  {
    id: '36C20B8A-AA61-49D5-9500-16917BBDF388',
    name: 'book-cog',
    codepoint: 'F168A',
    deprecated: 'FALSE',
  },
  {
    id: 'B63BFA22-6A8E-48FD-8BDB-E82BB550411D',
    name: 'book-cog-outline',
    codepoint: 'F168B',
    deprecated: 'FALSE',
  },
  {
    id: 'CC30F9D0-3076-4189-A00D-F570D5B0C5D1',
    name: 'book-cross',
    codepoint: 'F00A2',
    deprecated: 'FALSE',
  },
  {
    id: 'CF11FAB5-CEE6-4F8D-9E64-67285419FF62',
    name: 'book-edit',
    codepoint: 'F168C',
    deprecated: 'FALSE',
  },
  {
    id: '615E9F0E-F80A-4806-8023-F3B5E47A0F07',
    name: 'book-edit-outline',
    codepoint: 'F168D',
    deprecated: 'FALSE',
  },
  {
    id: 'B115D616-1428-4D6B-84D7-643900FBEF77',
    name: 'book-education',
    codepoint: 'F16C9',
    deprecated: 'FALSE',
  },
  {
    id: '2C5308E6-760F-4CD8-A2CF-DD3B5434B0A0',
    name: 'book-education-outline',
    codepoint: 'F16CA',
    deprecated: 'FALSE',
  },
  {
    id: '4A830C97-1A09-495F-91A5-8F02571C54C6',
    name: 'book-heart',
    codepoint: 'F1A1D',
    deprecated: 'FALSE',
  },
  {
    id: '72206137-0CFE-4A3C-BA21-16EB71D39821',
    name: 'book-heart-outline',
    codepoint: 'F1A1E',
    deprecated: 'FALSE',
  },
  {
    id: '4A6A20D1-3F8E-440A-BFB1-38CFB3FB3EC6',
    name: 'book-information-variant',
    codepoint: 'F106F',
    deprecated: 'FALSE',
  },
  {
    id: '121AF367-3AE5-48D1-9868-8DC60342D4A0',
    name: 'book-lock',
    codepoint: 'F079A',
    deprecated: 'FALSE',
  },
  {
    id: '90B49606-B4FF-4D4B-AE05-4769659094CE',
    name: 'book-lock-open',
    codepoint: 'F079B',
    deprecated: 'FALSE',
  },
  {
    id: 'F4804DDA-AD18-4DAF-A916-F0F6DC23F0A5',
    name: 'book-lock-open-outline',
    codepoint: 'F168E',
    deprecated: 'FALSE',
  },
  {
    id: '2E088838-572B-45AC-B250-1C86900B787E',
    name: 'book-lock-outline',
    codepoint: 'F168F',
    deprecated: 'FALSE',
  },
  {
    id: 'D5DCE615-BCFA-4922-8F95-5CEA756FD378',
    name: 'book-marker',
    codepoint: 'F1690',
    deprecated: 'FALSE',
  },
  {
    id: '69EBC2C1-A3D3-42E6-9868-3E97DD6DD301',
    name: 'book-marker-outline',
    codepoint: 'F1691',
    deprecated: 'FALSE',
  },
  {
    id: '7367B5F0-8155-4F3D-9C7E-BFDF677442D8',
    name: 'book-minus',
    codepoint: 'F05D9',
    deprecated: 'FALSE',
  },
  {
    id: '6B536CF6-98D8-44A5-9094-43C1BE41EC91',
    name: 'book-minus-multiple',
    codepoint: 'F0A94',
    deprecated: 'FALSE',
  },
  {
    id: '2AF5ACED-6980-4A66-B010-629C3E371315',
    name: 'book-minus-multiple-outline',
    codepoint: 'F090B',
    deprecated: 'FALSE',
  },
  {
    id: '402AE77A-79CC-42EF-9FB7-7D11D2C949DA',
    name: 'book-minus-outline',
    codepoint: 'F1692',
    deprecated: 'FALSE',
  },
  {
    id: '637C4988-5432-4F5E-ADD5-EBDC397D0622',
    name: 'book-multiple',
    codepoint: 'F00BB',
    deprecated: 'FALSE',
  },
  {
    id: 'B58005AE-8DFA-4C34-AC4D-C14DEF3D35C2',
    name: 'book-multiple-outline',
    codepoint: 'F0436',
    deprecated: 'FALSE',
  },
  {
    id: '10BCB9E5-2E1F-4CD0-871F-B36FA0595004',
    name: 'book-music',
    codepoint: 'F0067',
    deprecated: 'FALSE',
  },
  {
    id: '22B56C5C-000D-49DB-9D8A-DDD312662DFB',
    name: 'book-music-outline',
    codepoint: 'F1693',
    deprecated: 'FALSE',
  },
  {
    id: '52463F43-10D2-41DC-B4EB-45DDE9A4E655',
    name: 'book-off',
    codepoint: 'F1694',
    deprecated: 'FALSE',
  },
  {
    id: 'C9B4D51E-AA3F-49D4-9603-CC5CA33EB28A',
    name: 'book-off-outline',
    codepoint: 'F1695',
    deprecated: 'FALSE',
  },
  {
    id: '7EFAD89C-D84E-41F0-A8A4-F72AC8DB470C',
    name: 'book-open',
    codepoint: 'F00BD',
    deprecated: 'FALSE',
  },
  {
    id: '08C8BAF9-6B91-458D-BE03-F6A7AABEA2AB',
    name: 'book-open-blank-variant',
    codepoint: 'F00BE',
    deprecated: 'FALSE',
  },
  {
    id: 'BA5E15E3-923B-464B-ACEA-F2958F6782F7',
    name: 'book-open-outline',
    codepoint: 'F0B63',
    deprecated: 'FALSE',
  },
  {
    id: '113421A1-241F-4479-B22B-5F56EFD63D4C',
    name: 'book-open-page-variant',
    codepoint: 'F05DA',
    deprecated: 'FALSE',
  },
  {
    id: '3653D788-AC33-42D4-ADE4-F91A1C373973',
    name: 'book-open-page-variant-outline',
    codepoint: 'F15D6',
    deprecated: 'FALSE',
  },
  {
    id: 'E5928511-4156-4086-B58D-A2B235A3F824',
    name: 'book-open-variant',
    codepoint: 'F14F7',
    deprecated: 'FALSE',
  },
  {
    id: 'D1558D49-BCE0-4539-9560-DB05153356AF',
    name: 'book-outline',
    codepoint: 'F0B64',
    deprecated: 'FALSE',
  },
  {
    id: '89E22D2B-FF72-4242-BEE1-7865F974CF51',
    name: 'book-play',
    codepoint: 'F0E82',
    deprecated: 'FALSE',
  },
  {
    id: '8A87B5DD-D8DF-43A8-BFDD-D127E6F48792',
    name: 'book-play-outline',
    codepoint: 'F0E83',
    deprecated: 'FALSE',
  },
  {
    id: '3C49BDB2-C3A3-4E0C-9314-19FE66414A5B',
    name: 'book-plus',
    codepoint: 'F05DB',
    deprecated: 'FALSE',
  },
  {
    id: '9A754066-7A7A-4362-9530-C8500B178DED',
    name: 'book-plus-multiple',
    codepoint: 'F0A95',
    deprecated: 'FALSE',
  },
  {
    id: '129A1ABB-F616-4750-8814-018A12FE9D88',
    name: 'book-plus-multiple-outline',
    codepoint: 'F0ADE',
    deprecated: 'FALSE',
  },
  {
    id: '58B6134A-73CF-4E0E-AB85-DCBFB6B6766B',
    name: 'book-plus-outline',
    codepoint: 'F1696',
    deprecated: 'FALSE',
  },
  {
    id: 'CFAA64B9-8047-4713-AD53-574A398F187F',
    name: 'book-refresh',
    codepoint: 'F1697',
    deprecated: 'FALSE',
  },
  {
    id: '7F62E9BE-F358-4CF8-B3DB-938805F83089',
    name: 'book-refresh-outline',
    codepoint: 'F1698',
    deprecated: 'FALSE',
  },
  {
    id: 'DAB52460-1820-4BD0-9695-67EBF9E84167',
    name: 'book-remove',
    codepoint: 'F0A97',
    deprecated: 'FALSE',
  },
  {
    id: '414C568C-94B4-4EC3-9D81-22F747B9D0FC',
    name: 'book-remove-multiple',
    codepoint: 'F0A96',
    deprecated: 'FALSE',
  },
  {
    id: '32DEB304-15CC-4B30-832E-A1377A57EC47',
    name: 'book-remove-multiple-outline',
    codepoint: 'F04CA',
    deprecated: 'FALSE',
  },
  {
    id: 'CDCF29D7-CED3-45DB-BBC8-ACEA8262738C',
    name: 'book-remove-outline',
    codepoint: 'F1699',
    deprecated: 'FALSE',
  },
  {
    id: 'AE0D918C-8BC9-4D85-B445-62039AD16FB2',
    name: 'book-search',
    codepoint: 'F0E84',
    deprecated: 'FALSE',
  },
  {
    id: '62AB37D9-3483-4F21-BD4C-44214B668599',
    name: 'book-search-outline',
    codepoint: 'F0E85',
    deprecated: 'FALSE',
  },
  {
    id: 'E48C6D20-C8BC-43A0-9F90-A9FBADAB3454',
    name: 'book-settings',
    codepoint: 'F169A',
    deprecated: 'FALSE',
  },
  {
    id: 'E090B509-20FE-405D-9811-221E533F79F3',
    name: 'book-settings-outline',
    codepoint: 'F169B',
    deprecated: 'FALSE',
  },
  {
    id: 'ACB38262-FB32-456E-AE84-6BCE160F56C4',
    name: 'book-sync',
    codepoint: 'F169C',
    deprecated: 'FALSE',
  },
  {
    id: '8C2A47E9-6B8B-4504-A1C7-37EDFD9DC979',
    name: 'book-sync-outline',
    codepoint: 'F16C8',
    deprecated: 'FALSE',
  },
  {
    id: '1B9F45CE-CA39-4ACF-8961-4B4B56D6F94A',
    name: 'book-variant',
    codepoint: 'F00BF',
    deprecated: 'FALSE',
  },
  {
    id: '690787DD-F7B0-4D32-A668-32657D3A3AE8',
    name: 'bookmark',
    codepoint: 'F00C0',
    deprecated: 'FALSE',
  },
  {
    id: 'BBEAE49B-BA87-4375-A642-83196A4999F6',
    name: 'bookmark-box',
    codepoint: 'F1B75',
    deprecated: 'FALSE',
  },
  {
    id: '84C06231-55E7-4450-B88D-964B33FCACDA',
    name: 'bookmark-box-multiple',
    codepoint: 'F196C',
    deprecated: 'FALSE',
  },
  {
    id: '1F563201-43D7-4CDA-8DB5-5F82A438965D',
    name: 'bookmark-box-multiple-outline',
    codepoint: 'F196D',
    deprecated: 'FALSE',
  },
  {
    id: '93A0AE0D-58F7-4A60-8A24-A0E131A036E2',
    name: 'bookmark-box-outline',
    codepoint: 'F1B76',
    deprecated: 'FALSE',
  },
  {
    id: '65F155F1-7A9F-49AF-B570-FD6D24553CE5',
    name: 'bookmark-check',
    codepoint: 'F00C1',
    deprecated: 'FALSE',
  },
  {
    id: 'EFBBDF1F-1497-4BA3-AE93-614B2BB496F7',
    name: 'bookmark-check-outline',
    codepoint: 'F137B',
    deprecated: 'FALSE',
  },
  {
    id: 'AB2B19C4-5114-4D8A-A2EB-4E112B43F383',
    name: 'bookmark-minus',
    codepoint: 'F09CC',
    deprecated: 'FALSE',
  },
  {
    id: '491E1C5C-650E-41BB-9D44-8F8F2474B657',
    name: 'bookmark-minus-outline',
    codepoint: 'F09CD',
    deprecated: 'FALSE',
  },
  {
    id: '28FFD085-27EC-47A7-88A7-989BECA3DDE0',
    name: 'bookmark-multiple',
    codepoint: 'F0E15',
    deprecated: 'FALSE',
  },
  {
    id: '4C37E778-2438-4F54-8D08-5B25A7659FB7',
    name: 'bookmark-multiple-outline',
    codepoint: 'F0E16',
    deprecated: 'FALSE',
  },
  {
    id: 'DF4ECEB8-EF5C-4ACC-BC7D-B4FEEE75D17D',
    name: 'bookmark-music',
    codepoint: 'F00C2',
    deprecated: 'FALSE',
  },
  {
    id: '140B0E5A-4F75-4C53-A9D5-867E4DC020E7',
    name: 'bookmark-music-outline',
    codepoint: 'F1379',
    deprecated: 'FALSE',
  },
  {
    id: 'CE74B9A9-5962-4127-ACBE-A1AD4F130A2F',
    name: 'bookmark-off',
    codepoint: 'F09CE',
    deprecated: 'FALSE',
  },
  {
    id: '998B6488-F375-4297-9941-6D8ADC4E7F3C',
    name: 'bookmark-off-outline',
    codepoint: 'F09CF',
    deprecated: 'FALSE',
  },
  {
    id: '0EB6BB85-4BE2-4608-BABF-2EF135D420A7',
    name: 'bookmark-outline',
    codepoint: 'F00C3',
    deprecated: 'FALSE',
  },
  {
    id: '596822BD-8373-402C-9D27-C341405F55F9',
    name: 'bookmark-plus',
    codepoint: 'F00C5',
    deprecated: 'FALSE',
  },
  {
    id: 'DB2B7624-66CB-4E7E-A65F-4CEE8870C6CC',
    name: 'bookmark-plus-outline',
    codepoint: 'F00C4',
    deprecated: 'FALSE',
  },
  {
    id: '89594C9C-18ED-45EA-A3AC-A91B570D1541',
    name: 'bookmark-remove',
    codepoint: 'F00C6',
    deprecated: 'FALSE',
  },
  {
    id: '0634B479-2104-4AF4-B442-2E5C407EBEE1',
    name: 'bookmark-remove-outline',
    codepoint: 'F137A',
    deprecated: 'FALSE',
  },
  {
    id: '562F5DDE-E65C-4ACC-98B7-54C0D5406AED',
    name: 'bookshelf',
    codepoint: 'F125F',
    deprecated: 'FALSE',
  },
  {
    id: 'D24517D5-27A6-4BE4-B9C8-C575A1431310',
    name: 'boom-gate',
    codepoint: 'F0E86',
    deprecated: 'FALSE',
  },
  {
    id: '2C3B8F49-7A38-49EE-8288-FDA6AC188727',
    name: 'boom-gate-alert',
    codepoint: 'F0E87',
    deprecated: 'FALSE',
  },
  {
    id: 'B138EF8B-B8ED-4792-9967-D4FB4C8E8A98',
    name: 'boom-gate-alert-outline',
    codepoint: 'F0E88',
    deprecated: 'FALSE',
  },
  {
    id: 'D9BD19B7-12DD-4557-9DDA-998688CA3CB5',
    name: 'boom-gate-arrow-down',
    codepoint: 'F0E89',
    deprecated: 'FALSE',
  },
  {
    id: 'F0ED697E-DAA8-4530-8403-C614C8EBCE3B',
    name: 'boom-gate-arrow-down-outline',
    codepoint: 'F0E8A',
    deprecated: 'FALSE',
  },
  {
    id: 'F9ADF5B2-435F-4820-8790-EAA72D6D5C75',
    name: 'boom-gate-arrow-up',
    codepoint: 'F0E8C',
    deprecated: 'FALSE',
  },
  {
    id: '24E7CECC-7FAC-4FE4-8EAE-B727489040E8',
    name: 'boom-gate-arrow-up-outline',
    codepoint: 'F0E8D',
    deprecated: 'FALSE',
  },
  {
    id: 'EF1F53B3-DA99-4410-9A20-E12C42FC2461',
    name: 'boom-gate-outline',
    codepoint: 'F0E8B',
    deprecated: 'FALSE',
  },
  {
    id: '615182F4-7F56-403E-8FDA-7DE1A2041F93',
    name: 'boom-gate-up',
    codepoint: 'F17F9',
    deprecated: 'FALSE',
  },
  {
    id: 'C42EF32C-0E5A-498E-A633-A2E9C739F514',
    name: 'boom-gate-up-outline',
    codepoint: 'F17FA',
    deprecated: 'FALSE',
  },
  {
    id: 'DAA06443-AE25-4881-BC62-8033C4420B8E',
    name: 'boombox',
    codepoint: 'F05DC',
    deprecated: 'FALSE',
  },
  {
    id: '090EBF34-3104-4D3C-B3C8-819C332A43E1',
    name: 'boomerang',
    codepoint: 'F10CF',
    deprecated: 'FALSE',
  },
  {
    id: '9DAC7258-AB2D-49E4-98A9-E84A5E3E474B',
    name: 'bootstrap',
    codepoint: 'F06C6',
    deprecated: 'TRUE',
  },
  {
    id: 'A3D0E8B6-96B3-4281-B31C-0DDFD729903B',
    name: 'border-all',
    codepoint: 'F00C7',
    deprecated: 'FALSE',
  },
  {
    id: 'AD3DDFBC-21FE-4D6E-AE09-E9EB7D289459',
    name: 'border-all-variant',
    codepoint: 'F08A1',
    deprecated: 'FALSE',
  },
  {
    id: '9441B8BD-25C8-4F46-AFD4-6B2DA0C4A599',
    name: 'border-bottom',
    codepoint: 'F00C8',
    deprecated: 'FALSE',
  },
  {
    id: '29792687-63C3-41A6-8182-CF829969BD48',
    name: 'border-bottom-variant',
    codepoint: 'F08A2',
    deprecated: 'FALSE',
  },
  {
    id: 'CCD99C36-5D88-4696-8193-56F11C4B20A7',
    name: 'border-color',
    codepoint: 'F00C9',
    deprecated: 'FALSE',
  },
  {
    id: '0F804E73-90EF-4175-984D-603698F0AF09',
    name: 'border-horizontal',
    codepoint: 'F00CA',
    deprecated: 'FALSE',
  },
  {
    id: 'F7471447-8501-40A6-9FFB-AB353B55AA89',
    name: 'border-inside',
    codepoint: 'F00CB',
    deprecated: 'FALSE',
  },
  {
    id: '6B8A1379-9497-4B1B-A8CF-51CB1AD67757',
    name: 'border-left',
    codepoint: 'F00CC',
    deprecated: 'FALSE',
  },
  {
    id: '063FAD96-7055-4D1E-8E50-B9288BB9D0B0',
    name: 'border-left-variant',
    codepoint: 'F08A3',
    deprecated: 'FALSE',
  },
  {
    id: 'F6333BD9-AC00-428D-B8A8-FA3B9187487F',
    name: 'border-none',
    codepoint: 'F00CD',
    deprecated: 'FALSE',
  },
  {
    id: 'C8EC415C-81DE-4399-AEAB-FE102959F619',
    name: 'border-none-variant',
    codepoint: 'F08A4',
    deprecated: 'FALSE',
  },
  {
    id: 'F8EA9B00-82F1-4DE8-B8B5-0A71B934C44D',
    name: 'border-outside',
    codepoint: 'F00CE',
    deprecated: 'FALSE',
  },
  {
    id: '3BF9D4F8-DD9D-4D5E-A1B4-A1AA22172E47',
    name: 'border-radius',
    codepoint: 'F1AF4',
    deprecated: 'FALSE',
  },
  {
    id: 'BC67506B-CAE4-4EDA-B0C8-614436AFDCBF',
    name: 'border-right',
    codepoint: 'F00CF',
    deprecated: 'FALSE',
  },
  {
    id: '6284DA6C-DB95-4747-BB40-DE69D5970C5B',
    name: 'border-right-variant',
    codepoint: 'F08A5',
    deprecated: 'FALSE',
  },
  {
    id: '5A750E96-F9A0-4C38-B601-8BA770E89E01',
    name: 'border-style',
    codepoint: 'F00D0',
    deprecated: 'FALSE',
  },
  {
    id: 'AE703F65-F8D5-43B9-9B6B-B5966260E799',
    name: 'border-top',
    codepoint: 'F00D1',
    deprecated: 'FALSE',
  },
  {
    id: 'E9426CB0-FC83-40AA-B429-F55AD3F72E47',
    name: 'border-top-variant',
    codepoint: 'F08A6',
    deprecated: 'FALSE',
  },
  {
    id: '540E7940-BA85-4EED-BEAB-C50CEF348D50',
    name: 'border-vertical',
    codepoint: 'F00D2',
    deprecated: 'FALSE',
  },
  {
    id: 'D938FAC2-CC48-431D-8019-FA41677470DD',
    name: 'bottle-soda',
    codepoint: 'F1070',
    deprecated: 'FALSE',
  },
  {
    id: 'E62A084A-2136-4FD3-8803-A72DE26D60CB',
    name: 'bottle-soda-classic',
    codepoint: 'F1071',
    deprecated: 'FALSE',
  },
  {
    id: '7682F8D6-3FFD-4591-BFC5-44BF9166FB49',
    name: 'bottle-soda-classic-outline',
    codepoint: 'F1363',
    deprecated: 'FALSE',
  },
  {
    id: '54C4C95B-E05C-4992-8365-96586E864FF9',
    name: 'bottle-soda-outline',
    codepoint: 'F1072',
    deprecated: 'FALSE',
  },
  {
    id: '4FEF379F-2EBC-49EA-87B8-068B695A67B6',
    name: 'bottle-tonic',
    codepoint: 'F112E',
    deprecated: 'FALSE',
  },
  {
    id: '44193E2D-1EAB-4BA1-9D32-C7E149F53E89',
    name: 'bottle-tonic-outline',
    codepoint: 'F112F',
    deprecated: 'FALSE',
  },
  {
    id: '9524D285-4F39-4FEA-9EC1-9F4B6ACB6615',
    name: 'bottle-tonic-plus',
    codepoint: 'F1130',
    deprecated: 'FALSE',
  },
  {
    id: '8508B85B-4238-4FC5-90F8-3C7E5612174B',
    name: 'bottle-tonic-plus-outline',
    codepoint: 'F1131',
    deprecated: 'FALSE',
  },
  {
    id: 'CE430764-D7C8-4DC8-97F4-DF1473B2C52C',
    name: 'bottle-tonic-skull',
    codepoint: 'F1132',
    deprecated: 'FALSE',
  },
  {
    id: '24EF4783-9CDC-402E-9F8D-28CCDB50F216',
    name: 'bottle-tonic-skull-outline',
    codepoint: 'F1133',
    deprecated: 'FALSE',
  },
  {
    id: 'E27EBD86-D80B-473B-BEAB-A22005475F38',
    name: 'bottle-wine',
    codepoint: 'F0854',
    deprecated: 'FALSE',
  },
  {
    id: 'B04C5F90-A3FB-4A60-B773-25AE19D293C7',
    name: 'bottle-wine-outline',
    codepoint: 'F1310',
    deprecated: 'FALSE',
  },
  {
    id: '3B0FFEAC-477B-436C-A964-F537EC6ADF94',
    name: 'bow-arrow',
    codepoint: 'F1841',
    deprecated: 'FALSE',
  },
  {
    id: '741B300C-06EF-459D-BB3E-95DB8E21194E',
    name: 'bow-tie',
    codepoint: 'F0678',
    deprecated: 'FALSE',
  },
  {
    id: '84EB4644-EC38-431B-B55B-12363EB63765',
    name: 'bowl',
    codepoint: 'F028E',
    deprecated: 'FALSE',
  },
  {
    id: '95E70948-C85D-41AC-887C-62752FBA7B6B',
    name: 'bowl-mix',
    codepoint: 'F0617',
    deprecated: 'FALSE',
  },
  {
    id: '3C547C40-FF5E-4BB6-B45E-2B26AD990B20',
    name: 'bowl-mix-outline',
    codepoint: 'F02E4',
    deprecated: 'FALSE',
  },
  {
    id: 'D0021FC3-865F-45B4-8542-4937DC2952E2',
    name: 'bowl-outline',
    codepoint: 'F02A9',
    deprecated: 'FALSE',
  },
  {
    id: '0CAB5DF9-E4A7-418D-B317-E792FF2DF8D1',
    name: 'bowling',
    codepoint: 'F00D3',
    deprecated: 'FALSE',
  },
  {
    id: '1E6B8A9E-921F-4695-B1CA-0A87AC157E88',
    name: 'box',
    codepoint: 'F00D4',
    deprecated: 'TRUE',
  },
  {
    id: 'A4C51C21-DEB0-4247-AEA3-B5F454641BDC',
    name: 'box-cutter',
    codepoint: 'F00D5',
    deprecated: 'FALSE',
  },
  {
    id: '0E77ABEB-007D-4458-BEB9-F2C4607ECFC7',
    name: 'box-cutter-off',
    codepoint: 'F0B4A',
    deprecated: 'FALSE',
  },
  {
    id: '9DBE04CB-C425-4556-9046-15CBD88C866B',
    name: 'box-shadow',
    codepoint: 'F0637',
    deprecated: 'FALSE',
  },
  {
    id: 'C36FCD4A-D073-4BCA-96D1-90CD81E4C080',
    name: 'boxing-glove',
    codepoint: 'F0B65',
    deprecated: 'FALSE',
  },
  {
    id: '3B62C43D-F1BC-45AB-AB68-B1B854009FD8',
    name: 'braille',
    codepoint: 'F09D0',
    deprecated: 'FALSE',
  },
  {
    id: '8EC09BCA-7C35-4FAB-9000-4A7795CC0930',
    name: 'brain',
    codepoint: 'F09D1',
    deprecated: 'FALSE',
  },
  {
    id: 'ED0E86DB-C4A5-477B-82BF-FC5849EE0B9C',
    name: 'bread-slice',
    codepoint: 'F0CEE',
    deprecated: 'FALSE',
  },
  {
    id: 'FAC52832-EA67-4ABF-9841-7D3DD07C6EFF',
    name: 'bread-slice-outline',
    codepoint: 'F0CEF',
    deprecated: 'FALSE',
  },
  {
    id: '9FD9D8F0-D777-4B33-9CF7-E32FD8C25655',
    name: 'bridge',
    codepoint: 'F0618',
    deprecated: 'FALSE',
  },
  {
    id: '15A9F4A5-3EB6-443C-8283-547A077CB0C4',
    name: 'briefcase',
    codepoint: 'F00D6',
    deprecated: 'FALSE',
  },
  {
    id: 'B0F37881-7339-4CBC-9DB5-833EDCB6B049',
    name: 'briefcase-account',
    codepoint: 'F0CF0',
    deprecated: 'FALSE',
  },
  {
    id: 'F92EB0E4-257F-4200-A18E-0F0C02C8DB51',
    name: 'briefcase-account-outline',
    codepoint: 'F0CF1',
    deprecated: 'FALSE',
  },
  {
    id: '6DB6F4EA-1ACC-481A-8EC5-3D9FA65408BE',
    name: 'briefcase-arrow-left-right',
    codepoint: 'F1A8D',
    deprecated: 'FALSE',
  },
  {
    id: 'FA334ED0-09E4-4FB3-AF2A-20715D19A1E5',
    name: 'briefcase-arrow-left-right-outline',
    codepoint: 'F1A8E',
    deprecated: 'FALSE',
  },
  {
    id: '2F146073-D872-47E2-BB41-404CFDF65D89',
    name: 'briefcase-arrow-up-down',
    codepoint: 'F1A8F',
    deprecated: 'FALSE',
  },
  {
    id: '8C3B52BE-A1AC-4D59-B88C-D50B6C088827',
    name: 'briefcase-arrow-up-down-outline',
    codepoint: 'F1A90',
    deprecated: 'FALSE',
  },
  {
    id: '4182182E-F79B-4153-B652-2A4B4AC231E1',
    name: 'briefcase-check',
    codepoint: 'F00D7',
    deprecated: 'FALSE',
  },
  {
    id: '3889AEB1-7257-4D20-ACD4-78CB27879639',
    name: 'briefcase-check-outline',
    codepoint: 'F131E',
    deprecated: 'FALSE',
  },
  {
    id: '1BC68384-3EB0-4296-A3B2-9F348DD7AF1E',
    name: 'briefcase-clock',
    codepoint: 'F10D0',
    deprecated: 'FALSE',
  },
  {
    id: 'EBE7A5FD-4D46-4A78-BA72-816546ABB880',
    name: 'briefcase-clock-outline',
    codepoint: 'F10D1',
    deprecated: 'FALSE',
  },
  {
    id: 'D4705E48-0920-451E-BFF3-2DA1BBA5C21D',
    name: 'briefcase-download',
    codepoint: 'F00D8',
    deprecated: 'FALSE',
  },
  {
    id: 'DD87CBA1-4687-45A7-B470-EC75EC4C0D32',
    name: 'briefcase-download-outline',
    codepoint: 'F0C3D',
    deprecated: 'FALSE',
  },
  {
    id: '485F9428-A4BF-422D-A624-61AB07D37890',
    name: 'briefcase-edit',
    codepoint: 'F0A98',
    deprecated: 'FALSE',
  },
  {
    id: 'EE4B8481-FF0C-44BC-9D23-64DFDF499F73',
    name: 'briefcase-edit-outline',
    codepoint: 'F0C3E',
    deprecated: 'FALSE',
  },
  {
    id: '15362BD1-6DB7-4334-B6CF-35A61D7EE2A0',
    name: 'briefcase-eye',
    codepoint: 'F17D9',
    deprecated: 'FALSE',
  },
  {
    id: '6C1979BB-3BFD-4466-9934-945FAB608E90',
    name: 'briefcase-eye-outline',
    codepoint: 'F17DA',
    deprecated: 'FALSE',
  },
  {
    id: '5999899F-7FE5-44EF-87F4-DD1E2F9556B3',
    name: 'briefcase-minus',
    codepoint: 'F0A2A',
    deprecated: 'FALSE',
  },
  {
    id: 'C185E5E9-F652-41C2-9FBA-6673FE7CEB10',
    name: 'briefcase-minus-outline',
    codepoint: 'F0C3F',
    deprecated: 'FALSE',
  },
  {
    id: '2059EB01-3552-45CF-A2A1-64DA37F124D0',
    name: 'briefcase-off',
    codepoint: 'F1658',
    deprecated: 'FALSE',
  },
  {
    id: 'EE25B28C-2F16-4BE0-95BB-8328DF0F0CA2',
    name: 'briefcase-off-outline',
    codepoint: 'F1659',
    deprecated: 'FALSE',
  },
  {
    id: '1E7F3689-287C-40DC-B0DD-CF65987E9B53',
    name: 'briefcase-outline',
    codepoint: 'F0814',
    deprecated: 'FALSE',
  },
  {
    id: 'C08C7899-5B7E-4E6F-9861-C319CFABA7DC',
    name: 'briefcase-plus',
    codepoint: 'F0A2B',
    deprecated: 'FALSE',
  },
  {
    id: 'CA6D7ED5-38E6-4B63-915A-431CDDA27062',
    name: 'briefcase-plus-outline',
    codepoint: 'F0C40',
    deprecated: 'FALSE',
  },
  {
    id: '9B02F5A1-D171-49A4-91DB-5F47E03D7B65',
    name: 'briefcase-remove',
    codepoint: 'F0A2C',
    deprecated: 'FALSE',
  },
  {
    id: '1754B039-7D56-4722-BEE1-8A8BA735FCD5',
    name: 'briefcase-remove-outline',
    codepoint: 'F0C41',
    deprecated: 'FALSE',
  },
  {
    id: 'E6071C37-85E7-45F7-868D-A12234D0B979',
    name: 'briefcase-search',
    codepoint: 'F0A2D',
    deprecated: 'FALSE',
  },
  {
    id: '62511811-5FA9-4E69-8513-62828BE493DF',
    name: 'briefcase-search-outline',
    codepoint: 'F0C42',
    deprecated: 'FALSE',
  },
  {
    id: '3D48B9FF-A060-47DA-809E-32D5ED68CA64',
    name: 'briefcase-upload',
    codepoint: 'F00D9',
    deprecated: 'FALSE',
  },
  {
    id: 'AE06D41B-19EB-4E10-92DE-2A285163F034',
    name: 'briefcase-upload-outline',
    codepoint: 'F0C43',
    deprecated: 'FALSE',
  },
  {
    id: '1BB4FAD5-B3AE-47FD-95C4-B1713FE05FF2',
    name: 'briefcase-variant',
    codepoint: 'F1494',
    deprecated: 'FALSE',
  },
  {
    id: '2C5F52C7-AC1B-417F-8035-DB25264DA0A4',
    name: 'briefcase-variant-off',
    codepoint: 'F165A',
    deprecated: 'FALSE',
  },
  {
    id: 'E89FB55E-396B-4E43-BC41-512B597CF474',
    name: 'briefcase-variant-off-outline',
    codepoint: 'F165B',
    deprecated: 'FALSE',
  },
  {
    id: '5280D1B7-9418-4673-BB9E-6AB3BD640C92',
    name: 'briefcase-variant-outline',
    codepoint: 'F1495',
    deprecated: 'FALSE',
  },
  {
    id: 'B3FF12D1-1C76-4E1D-BAD0-468E424BADA7',
    name: 'brightness-1',
    codepoint: 'F00DA',
    deprecated: 'FALSE',
  },
  {
    id: 'FAEBEE4C-295C-475F-AE7D-11CFA4688017',
    name: 'brightness-2',
    codepoint: 'F00DB',
    deprecated: 'FALSE',
  },
  {
    id: '825E1BB2-1E01-4E6E-9CF0-04412FDECDA6',
    name: 'brightness-3',
    codepoint: 'F00DC',
    deprecated: 'FALSE',
  },
  {
    id: '10EC7F50-BDC1-45C4-A6D2-2CB5FB4DE902',
    name: 'brightness-4',
    codepoint: 'F00DD',
    deprecated: 'FALSE',
  },
  {
    id: '17314413-99ED-480C-B739-8572B6AE5477',
    name: 'brightness-5',
    codepoint: 'F00DE',
    deprecated: 'FALSE',
  },
  {
    id: '34818392-81EE-4210-8F86-9C448596C912',
    name: 'brightness-6',
    codepoint: 'F00DF',
    deprecated: 'FALSE',
  },
  {
    id: '014CCAA3-4B30-4C3D-921C-078A078A5198',
    name: 'brightness-7',
    codepoint: 'F00E0',
    deprecated: 'FALSE',
  },
  {
    id: '6E47CC3B-6D95-4311-A8DD-7B50AC24781C',
    name: 'brightness-auto',
    codepoint: 'F00E1',
    deprecated: 'FALSE',
  },
  {
    id: 'CBC38577-44FC-45B5-9454-3D04AA9CEAE3',
    name: 'brightness-percent',
    codepoint: 'F0CF2',
    deprecated: 'FALSE',
  },
  {
    id: 'F5E24182-366C-4D23-BA0E-8D2E25B88230',
    name: 'broadcast',
    codepoint: 'F1720',
    deprecated: 'FALSE',
  },
  {
    id: 'E1851914-B294-4FC0-A94B-C5C8D0F52DCB',
    name: 'broadcast-off',
    codepoint: 'F1721',
    deprecated: 'FALSE',
  },
  {
    id: '8159ED8F-4D1C-4845-8297-0FE41260D199',
    name: 'broom',
    codepoint: 'F00E2',
    deprecated: 'FALSE',
  },
  {
    id: 'B5C72984-BCCF-4729-A7D3-2A07D5794014',
    name: 'brush',
    codepoint: 'F00E3',
    deprecated: 'FALSE',
  },
  {
    id: '9A11CA67-107C-4D0A-A244-34E60B1915A0',
    name: 'brush-off',
    codepoint: 'F1771',
    deprecated: 'FALSE',
  },
  {
    id: '0C2A4D14-A68C-444C-B01C-70CBD6DE0A69',
    name: 'brush-outline',
    codepoint: 'F1A0D',
    deprecated: 'FALSE',
  },
  {
    id: '8B433DEE-0DAC-4C0E-80A0-068A00269BEE',
    name: 'brush-variant',
    codepoint: 'F1813',
    deprecated: 'FALSE',
  },
  {
    id: '1024F530-CFE4-4869-BAA4-062593D42DC9',
    name: 'bucket',
    codepoint: 'F1415',
    deprecated: 'FALSE',
  },
  {
    id: '1A620C83-4D7F-4175-998B-E3460C77510D',
    name: 'bucket-outline',
    codepoint: 'F1416',
    deprecated: 'FALSE',
  },
  {
    id: 'B68F24D9-0E45-4729-9DB9-46E0D73589F2',
    name: 'buffet',
    codepoint: 'F0578',
    deprecated: 'FALSE',
  },
  {
    id: '09A131FB-EE06-49B8-80C3-49B294BCA612',
    name: 'bug',
    codepoint: 'F00E4',
    deprecated: 'FALSE',
  },
  {
    id: '3EDC1018-2B2B-4B3A-AE65-3C74678C807B',
    name: 'bug-check',
    codepoint: 'F0A2E',
    deprecated: 'FALSE',
  },
  {
    id: '1DF96664-B3AE-4028-BB22-C9DE9273A641',
    name: 'bug-check-outline',
    codepoint: 'F0A2F',
    deprecated: 'FALSE',
  },
  {
    id: 'CDBE2126-E455-498B-B5CC-945A42F78B0D',
    name: 'bug-outline',
    codepoint: 'F0A30',
    deprecated: 'FALSE',
  },
  {
    id: '49752F69-A574-46AF-A02C-88E6CE5FB41B',
    name: 'bug-pause',
    codepoint: 'F1AF5',
    deprecated: 'FALSE',
  },
  {
    id: '3DB48B1D-904F-4348-9C30-016841242026',
    name: 'bug-pause-outline',
    codepoint: 'F1AF6',
    deprecated: 'FALSE',
  },
  {
    id: '2F0E44DF-D6F1-487A-9427-BBAA20E7B43D',
    name: 'bug-play',
    codepoint: 'F1AF7',
    deprecated: 'FALSE',
  },
  {
    id: '7084E94D-43E2-4718-BFF1-B568182C1F65',
    name: 'bug-play-outline',
    codepoint: 'F1AF8',
    deprecated: 'FALSE',
  },
  {
    id: '285762ED-0B89-40E5-92A7-04B8C38E29C8',
    name: 'bug-stop',
    codepoint: 'F1AF9',
    deprecated: 'FALSE',
  },
  {
    id: 'A88E0967-B37B-4785-AA7F-0D9F95856375',
    name: 'bug-stop-outline',
    codepoint: 'F1AFA',
    deprecated: 'FALSE',
  },
  {
    id: 'CBC37A12-44AA-4CA8-976A-F26ED26849D4',
    name: 'bugle',
    codepoint: 'F0DB4',
    deprecated: 'FALSE',
  },
  {
    id: '5CCE8821-5952-49CA-A3C2-53AB5E50CA19',
    name: 'bulkhead-light',
    codepoint: 'F1A2F',
    deprecated: 'FALSE',
  },
  {
    id: '76D6F847-8DC7-41CB-B88C-9407CC2B18AA',
    name: 'bulldozer',
    codepoint: 'F0B22',
    deprecated: 'FALSE',
  },
  {
    id: 'AED3CA58-6CAA-45AF-9791-F61AC32DAFBA',
    name: 'bullet',
    codepoint: 'F0CF3',
    deprecated: 'FALSE',
  },
  {
    id: '094C9BF2-9EEE-434C-9CA9-8280FBDFBEF7',
    name: 'bulletin-board',
    codepoint: 'F00E5',
    deprecated: 'FALSE',
  },
  {
    id: 'A5D2E199-03A1-4C23-9C63-954D63DEBEB4',
    name: 'bullhorn',
    codepoint: 'F00E6',
    deprecated: 'FALSE',
  },
  {
    id: 'B48B1E77-750E-4A16-9154-12D25E572A9D',
    name: 'bullhorn-outline',
    codepoint: 'F0B23',
    deprecated: 'FALSE',
  },
  {
    id: 'ED822193-44D0-481D-B373-F99518240597',
    name: 'bullhorn-variant',
    codepoint: 'F196E',
    deprecated: 'FALSE',
  },
  {
    id: 'D38A39A0-E788-49D7-802B-BE2BF5BB2274',
    name: 'bullhorn-variant-outline',
    codepoint: 'F196F',
    deprecated: 'FALSE',
  },
  {
    id: 'ED26BD4D-ACDB-45DC-A0DA-18ECA3B2008E',
    name: 'bullseye',
    codepoint: 'F05DD',
    deprecated: 'FALSE',
  },
  {
    id: 'FAC8D1F3-C466-41F3-B3BF-A08519BBC909',
    name: 'bullseye-arrow',
    codepoint: 'F08C9',
    deprecated: 'FALSE',
  },
  {
    id: '103BB26D-356A-4F6D-9F98-787CEC908FAD',
    name: 'bulma',
    codepoint: 'F12E7',
    deprecated: 'TRUE',
  },
  {
    id: '8428337D-0DED-4E4D-8F89-326FCAD97884',
    name: 'bunk-bed',
    codepoint: 'F1302',
    deprecated: 'FALSE',
  },
  {
    id: 'D3DFF539-E101-40B2-BA6D-B62327AFE6AC',
    name: 'bunk-bed-outline',
    codepoint: 'F0097',
    deprecated: 'FALSE',
  },
  {
    id: 'F6516F38-B29E-4CC9-A7E2-706817121D45',
    name: 'bus',
    codepoint: 'F00E7',
    deprecated: 'FALSE',
  },
  {
    id: '85DD64A3-9215-41F0-9C94-92C180D7BA05',
    name: 'bus-alert',
    codepoint: 'F0A99',
    deprecated: 'FALSE',
  },
  {
    id: '44D15383-D6D2-46CC-A977-7921DA98C7E8',
    name: 'bus-articulated-end',
    codepoint: 'F079C',
    deprecated: 'FALSE',
  },
  {
    id: '5C34E429-3B80-46CD-8DC8-44D5D9769682',
    name: 'bus-articulated-front',
    codepoint: 'F079D',
    deprecated: 'FALSE',
  },
  {
    id: 'B37694EA-F48B-4F48-ACF1-0E48FB334473',
    name: 'bus-clock',
    codepoint: 'F08CA',
    deprecated: 'FALSE',
  },
  {
    id: '1F4327A8-1E93-4490-B992-C9D04DFFB144',
    name: 'bus-double-decker',
    codepoint: 'F079E',
    deprecated: 'FALSE',
  },
  {
    id: '67F98777-D4FA-454C-96D6-835BD7A1D2D5',
    name: 'bus-electric',
    codepoint: 'F191D',
    deprecated: 'FALSE',
  },
  {
    id: '42DCF950-C2F2-4989-8E0B-ACF16326A6A7',
    name: 'bus-marker',
    codepoint: 'F1212',
    deprecated: 'FALSE',
  },
  {
    id: 'E007EC1E-915B-4A12-89A0-8F7249AEF9FC',
    name: 'bus-multiple',
    codepoint: 'F0F3F',
    deprecated: 'FALSE',
  },
  {
    id: '774EC563-DF29-498C-8270-ABB051396687',
    name: 'bus-school',
    codepoint: 'F079F',
    deprecated: 'FALSE',
  },
  {
    id: 'D944705B-8F7B-441F-8753-6E51E4378D7D',
    name: 'bus-side',
    codepoint: 'F07A0',
    deprecated: 'FALSE',
  },
  {
    id: '79F04208-0844-4987-9CFA-8870EBB6CD1D',
    name: 'bus-stop',
    codepoint: 'F1012',
    deprecated: 'FALSE',
  },
  {
    id: 'E299AA19-1C5F-4D7F-B101-0FBFC05A7130',
    name: 'bus-stop-covered',
    codepoint: 'F1013',
    deprecated: 'FALSE',
  },
  {
    id: 'E1CA2856-1C0E-4487-BAF3-C64A4EA94C45',
    name: 'bus-stop-uncovered',
    codepoint: 'F1014',
    deprecated: 'FALSE',
  },
  {
    id: 'CC37A3EE-B084-4CD4-AF41-1E5C6D83D839',
    name: 'butterfly',
    codepoint: 'F1589',
    deprecated: 'FALSE',
  },
  {
    id: '6BD6BDCD-0C73-40DF-9A9B-127C1DDFF467',
    name: 'butterfly-outline',
    codepoint: 'F158A',
    deprecated: 'FALSE',
  },
  {
    id: 'D9990C98-4D95-4E98-9289-3582ABC30351',
    name: 'button-cursor',
    codepoint: 'F1B4F',
    deprecated: 'FALSE',
  },
  {
    id: 'ECCE41F5-955D-49FD-BE39-855E1B9D2044',
    name: 'button-pointer',
    codepoint: 'F1B50',
    deprecated: 'FALSE',
  },
  {
    id: '5243C745-48FC-4DE9-83F8-138464C7D0D8',
    name: 'cabin-a-frame',
    codepoint: 'F188C',
    deprecated: 'FALSE',
  },
  {
    id: '1D18C000-0A31-4A28-BA96-B47103D727DD',
    name: 'cable-data',
    codepoint: 'F1394',
    deprecated: 'FALSE',
  },
  {
    id: '7DFE345F-A336-43BC-89BE-18FE3C902BFC',
    name: 'cached',
    codepoint: 'F00E8',
    deprecated: 'FALSE',
  },
  {
    id: 'C7CDAEB3-FE9D-4A53-937B-F778CEA157E0',
    name: 'cactus',
    codepoint: 'F0DB5',
    deprecated: 'FALSE',
  },
  {
    id: 'E5BFDCE4-1033-4062-9677-94B95E028B6E',
    name: 'cake',
    codepoint: 'F00E9',
    deprecated: 'FALSE',
  },
  {
    id: '934C9B6C-0DA5-4D71-9283-C960ED537D02',
    name: 'cake-layered',
    codepoint: 'F00EA',
    deprecated: 'FALSE',
  },
  {
    id: '2F9E87A6-B7DB-48B1-9528-C8A941FBC3AF',
    name: 'cake-variant',
    codepoint: 'F00EB',
    deprecated: 'FALSE',
  },
  {
    id: '0819B4B2-9D58-438F-943E-E628A3D5BF52',
    name: 'cake-variant-outline',
    codepoint: 'F17F0',
    deprecated: 'FALSE',
  },
  {
    id: '3E9895C8-BCCB-464C-ACF1-CCA86113DA37',
    name: 'calculator',
    codepoint: 'F00EC',
    deprecated: 'FALSE',
  },
  {
    id: 'FB05A4AF-E47A-4F82-BFFE-7C1F587839B6',
    name: 'calculator-variant',
    codepoint: 'F0A9A',
    deprecated: 'FALSE',
  },
  {
    id: '87123F5D-C9AC-43B3-9E97-10EE3F9C1158',
    name: 'calculator-variant-outline',
    codepoint: 'F15A6',
    deprecated: 'FALSE',
  },
  {
    id: '23C8742A-612C-4C55-B184-2A0ABB387746',
    name: 'calendar',
    codepoint: 'F00ED',
    deprecated: 'FALSE',
  },
  {
    id: 'CD7D41D2-4BD2-4482-85C2-314A283EB57F',
    name: 'calendar-account',
    codepoint: 'F0ED7',
    deprecated: 'FALSE',
  },
  {
    id: 'B852A287-2C5E-4468-9A83-6B5E0ABD1219',
    name: 'calendar-account-outline',
    codepoint: 'F0ED8',
    deprecated: 'FALSE',
  },
  {
    id: '38F5B0F1-FAC2-4389-9CE0-BDDEBEC868F4',
    name: 'calendar-alert',
    codepoint: 'F0A31',
    deprecated: 'FALSE',
  },
  {
    id: '6721AEBE-3E28-460D-A11A-0298B1163384',
    name: 'calendar-alert-outline',
    codepoint: 'F1B62',
    deprecated: 'FALSE',
  },
  {
    id: '9FB11BA5-47F5-40B8-9BD2-259FEA4E0A6E',
    name: 'calendar-arrow-left',
    codepoint: 'F1134',
    deprecated: 'FALSE',
  },
  {
    id: '571CF297-7E85-4808-A76A-72952F1A4976',
    name: 'calendar-arrow-right',
    codepoint: 'F1135',
    deprecated: 'FALSE',
  },
  {
    id: '69D7BB19-D394-4C73-AC78-672AC78FD0A0',
    name: 'calendar-badge',
    codepoint: 'F1B9D',
    deprecated: 'FALSE',
  },
  {
    id: '8E92EA84-88B1-4A46-BA36-E82CF91FE9D6',
    name: 'calendar-badge-outline',
    codepoint: 'F1B9E',
    deprecated: 'FALSE',
  },
  {
    id: '57984F1A-6CA5-4928-B221-466C0CC11562',
    name: 'calendar-blank',
    codepoint: 'F00EE',
    deprecated: 'FALSE',
  },
  {
    id: 'BD09D9F0-FF38-4D14-8798-2CB5091CABE1',
    name: 'calendar-blank-multiple',
    codepoint: 'F1073',
    deprecated: 'FALSE',
  },
  {
    id: '9A461143-6DF3-49CB-A238-24CFE7181183',
    name: 'calendar-blank-outline',
    codepoint: 'F0B66',
    deprecated: 'FALSE',
  },
  {
    id: '62008F88-6B17-4277-9155-B596476629A1',
    name: 'calendar-check',
    codepoint: 'F00EF',
    deprecated: 'FALSE',
  },
  {
    id: '62AD0407-3DB5-48B0-8A26-3C5A07C78582',
    name: 'calendar-check-outline',
    codepoint: 'F0C44',
    deprecated: 'FALSE',
  },
  {
    id: '921F6084-24E6-4D62-8BF9-9258D8FADE8E',
    name: 'calendar-clock',
    codepoint: 'F00F0',
    deprecated: 'FALSE',
  },
  {
    id: '9101C5D4-90CA-4C18-B66E-611B3B0A3877',
    name: 'calendar-clock-outline',
    codepoint: 'F16E1',
    deprecated: 'FALSE',
  },
  {
    id: 'D40760C0-3030-4ADC-AF91-6646565212FC',
    name: 'calendar-collapse-horizontal',
    codepoint: 'F189D',
    deprecated: 'FALSE',
  },
  {
    id: '1E0BDF03-34A2-4082-A718-7B69944CCCFF',
    name: 'calendar-collapse-horizontal-outline',
    codepoint: 'F1B63',
    deprecated: 'FALSE',
  },
  {
    id: 'A5F0BBFB-DA5E-4068-B2D5-3C0F3EB26B14',
    name: 'calendar-cursor',
    codepoint: 'F157B',
    deprecated: 'FALSE',
  },
  {
    id: '5DCA7EB2-7A5E-4F58-9A0A-39CCD6737215',
    name: 'calendar-cursor-outline',
    codepoint: 'F1B64',
    deprecated: 'FALSE',
  },
  {
    id: '2976EA1B-60A3-46FB-97E2-95BB74B0DD22',
    name: 'calendar-edit',
    codepoint: 'F08A7',
    deprecated: 'FALSE',
  },
  {
    id: '600D756C-664E-4213-B29F-F884E0DE0AE0',
    name: 'calendar-edit-outline',
    codepoint: 'F1B65',
    deprecated: 'FALSE',
  },
  {
    id: '5837A731-F9AE-433E-AB31-12EE3D2D13D1',
    name: 'calendar-end',
    codepoint: 'F166C',
    deprecated: 'FALSE',
  },
  {
    id: '80F02E2F-3A1A-4A99-9C80-00A3DF4E518F',
    name: 'calendar-end-outline',
    codepoint: 'F1B66',
    deprecated: 'FALSE',
  },
  {
    id: '61805380-C5C2-4A69-A0BC-356A642F7188',
    name: 'calendar-expand-horizontal',
    codepoint: 'F189E',
    deprecated: 'FALSE',
  },
  {
    id: 'FE6C3F26-1FE6-4681-BCFD-D9D78E9DE636',
    name: 'calendar-expand-horizontal-outline',
    codepoint: 'F1B67',
    deprecated: 'FALSE',
  },
  {
    id: '14692438-9215-4003-AF95-04AA7C27DA48',
    name: 'calendar-export',
    codepoint: 'F0B24',
    deprecated: 'FALSE',
  },
  {
    id: 'F6BD858F-4E31-45D6-AC2F-6E2C42843D29',
    name: 'calendar-export-outline',
    codepoint: 'F1B68',
    deprecated: 'FALSE',
  },
  {
    id: '56F3CE33-D13F-43F3-B626-2728FF09D778',
    name: 'calendar-filter',
    codepoint: 'F1A32',
    deprecated: 'FALSE',
  },
  {
    id: '0EE2D0EE-8A23-4A80-98AC-0AEE15333062',
    name: 'calendar-filter-outline',
    codepoint: 'F1A33',
    deprecated: 'FALSE',
  },
  {
    id: 'EF6AC5FE-7E6E-47AC-B5D8-E802CBB94E3B',
    name: 'calendar-heart',
    codepoint: 'F09D2',
    deprecated: 'FALSE',
  },
  {
    id: '754FE282-68CE-4E9E-BDFB-22C24815C06E',
    name: 'calendar-heart-outline',
    codepoint: 'F1B69',
    deprecated: 'FALSE',
  },
  {
    id: '04EEB2AA-AB71-4124-8AC4-FA65985AF37E',
    name: 'calendar-import',
    codepoint: 'F0B25',
    deprecated: 'FALSE',
  },
  {
    id: 'A3C7C111-C3C4-456D-B631-649D9D99E714',
    name: 'calendar-import-outline',
    codepoint: 'F1B6A',
    deprecated: 'FALSE',
  },
  {
    id: '8189159C-0869-4B40-809C-ECE7C33A2EC0',
    name: 'calendar-lock',
    codepoint: 'F1641',
    deprecated: 'FALSE',
  },
  {
    id: '2A1D736B-88EB-4E0A-8FEE-69112C3578AA',
    name: 'calendar-lock-open',
    codepoint: 'F1B5B',
    deprecated: 'FALSE',
  },
  {
    id: 'ABB53620-E8DA-4C49-BE82-4052B617AA75',
    name: 'calendar-lock-open-outline',
    codepoint: 'F1B5C',
    deprecated: 'FALSE',
  },
  {
    id: '38B4A077-A3EA-4AF2-8553-9888BA25589F',
    name: 'calendar-lock-outline',
    codepoint: 'F1642',
    deprecated: 'FALSE',
  },
  {
    id: '12439778-1F4B-4498-8716-5BE128D194E1',
    name: 'calendar-minus',
    codepoint: 'F0D5C',
    deprecated: 'FALSE',
  },
  {
    id: 'CF434321-1488-401F-B090-67822ABAB9D8',
    name: 'calendar-minus-outline',
    codepoint: 'F1B6B',
    deprecated: 'FALSE',
  },
  {
    id: '73BD287F-15E4-46D3-997F-0F2A9E641EE9',
    name: 'calendar-month',
    codepoint: 'F0E17',
    deprecated: 'FALSE',
  },
  {
    id: '80D8D5AB-B9A9-4A26-8E9A-A2D4DBDD77AC',
    name: 'calendar-month-outline',
    codepoint: 'F0E18',
    deprecated: 'FALSE',
  },
  {
    id: 'F4851A5B-6F19-4C18-9A13-4BF2617FF0E0',
    name: 'calendar-multiple',
    codepoint: 'F00F1',
    deprecated: 'FALSE',
  },
  {
    id: '7329D294-805D-4843-AB03-B87C661C296A',
    name: 'calendar-multiple-check',
    codepoint: 'F00F2',
    deprecated: 'FALSE',
  },
  {
    id: 'EFABC8FA-93A2-43FB-A9C3-B03F1324B2E2',
    name: 'calendar-multiselect',
    codepoint: 'F0A32',
    deprecated: 'FALSE',
  },
  {
    id: '10FF1625-E2D7-4E57-B339-8FB1801B0ECD',
    name: 'calendar-multiselect-outline',
    codepoint: 'F1B55',
    deprecated: 'FALSE',
  },
  {
    id: '3703C79F-7F34-41D6-A12F-6247144FEB02',
    name: 'calendar-outline',
    codepoint: 'F0B67',
    deprecated: 'FALSE',
  },
  {
    id: '34AFE807-D724-4A5A-A3FE-910928179BE8',
    name: 'calendar-plus',
    codepoint: 'F00F3',
    deprecated: 'FALSE',
  },
  {
    id: '52E522A1-E89B-47DE-8C27-EC85140AA13A',
    name: 'calendar-plus-outline',
    codepoint: 'F1B6C',
    deprecated: 'FALSE',
  },
  {
    id: '1A7293FC-E6E7-4C86-8919-32D0C5F60B08',
    name: 'calendar-question',
    codepoint: 'F0692',
    deprecated: 'FALSE',
  },
  {
    id: '38D715EB-E9A7-48E9-A283-0F09D440AD9F',
    name: 'calendar-question-outline',
    codepoint: 'F1B6D',
    deprecated: 'FALSE',
  },
  {
    id: '740D7A91-7492-4922-96C7-A719E2BCD62A',
    name: 'calendar-range',
    codepoint: 'F0679',
    deprecated: 'FALSE',
  },
  {
    id: 'CD7FA7FC-1BB0-4DB6-82C6-DF13E5A55EB2',
    name: 'calendar-range-outline',
    codepoint: 'F0B68',
    deprecated: 'FALSE',
  },
  {
    id: '4147C0BC-E3AE-4324-AA38-D2EC7A7AAD5E',
    name: 'calendar-refresh',
    codepoint: 'F01E1',
    deprecated: 'FALSE',
  },
  {
    id: 'BC0BD74D-06D4-458A-B0CE-90B96A2ADCBF',
    name: 'calendar-refresh-outline',
    codepoint: 'F0203',
    deprecated: 'FALSE',
  },
  {
    id: '2FAF3098-4BD1-4C70-94A8-F75E1EA54F49',
    name: 'calendar-remove',
    codepoint: 'F00F4',
    deprecated: 'FALSE',
  },
  {
    id: '10475FB2-142C-48FF-AB95-B93CF2680208',
    name: 'calendar-remove-outline',
    codepoint: 'F0C45',
    deprecated: 'FALSE',
  },
  {
    id: '1C61A1FD-56B6-41F1-B677-84D32425225A',
    name: 'calendar-search',
    codepoint: 'F094C',
    deprecated: 'FALSE',
  },
  {
    id: 'D6113F31-EAA4-4050-AC56-8FC216173F89',
    name: 'calendar-search-outline',
    codepoint: 'F1B6E',
    deprecated: 'FALSE',
  },
  {
    id: '96505BCC-D522-45A6-A642-85F1B4248FAA',
    name: 'calendar-star',
    codepoint: 'F09D3',
    deprecated: 'FALSE',
  },
  {
    id: 'AC50F06A-D098-4FE2-87CB-E5B82FB077EF',
    name: 'calendar-star-four-points',
    codepoint: 'F1C1F',
    deprecated: 'FALSE',
  },
  {
    id: '2F59B304-E3CE-4857-9EF8-B75BC591E3E8',
    name: 'calendar-star-outline',
    codepoint: 'F1B53',
    deprecated: 'FALSE',
  },
  {
    id: 'D88F139B-9D00-462D-8AA5-C23A5A02FCC6',
    name: 'calendar-start',
    codepoint: 'F166D',
    deprecated: 'FALSE',
  },
  {
    id: 'BE59CDE6-84BE-4489-BAD7-E5B9FE7FE3B2',
    name: 'calendar-start-outline',
    codepoint: 'F1B6F',
    deprecated: 'FALSE',
  },
  {
    id: '7343C074-F974-4083-89C5-3C2BABF4E3C9',
    name: 'calendar-sync',
    codepoint: 'F0E8E',
    deprecated: 'FALSE',
  },
  {
    id: '24C0F48E-A354-4835-A2D4-B1E348ACF5D5',
    name: 'calendar-sync-outline',
    codepoint: 'F0E8F',
    deprecated: 'FALSE',
  },
  {
    id: '1A873476-B82D-4E23-BF19-897F0A0C0B93',
    name: 'calendar-text',
    codepoint: 'F00F5',
    deprecated: 'FALSE',
  },
  {
    id: 'F49AE0DB-5AAF-4574-BF4C-121E99A95BEF',
    name: 'calendar-text-outline',
    codepoint: 'F0C46',
    deprecated: 'FALSE',
  },
  {
    id: 'A566BA98-D35A-443F-AD31-0D31EF0B85EC',
    name: 'calendar-today',
    codepoint: 'F00F6',
    deprecated: 'FALSE',
  },
  {
    id: '9ACB9882-BE7B-44A3-AFCC-FDA2A5061EF1',
    name: 'calendar-today-outline',
    codepoint: 'F1A30',
    deprecated: 'FALSE',
  },
  {
    id: 'F2D23B1E-EA12-4EF5-9365-E698D1CD1319',
    name: 'calendar-week',
    codepoint: 'F0A33',
    deprecated: 'FALSE',
  },
  {
    id: '7C244D7F-9332-4C73-AF3D-1DE3F6E365ED',
    name: 'calendar-week-begin',
    codepoint: 'F0A34',
    deprecated: 'FALSE',
  },
  {
    id: '420E6A80-6C58-48A6-A8A8-8119D53633D7',
    name: 'calendar-week-begin-outline',
    codepoint: 'F1A31',
    deprecated: 'FALSE',
  },
  {
    id: '9B0A106E-1157-40C2-9A68-97E1BA92A658',
    name: 'calendar-week-outline',
    codepoint: 'F1A34',
    deprecated: 'FALSE',
  },
  {
    id: 'E873A542-F4E7-44F4-B7CD-19474188D5BC',
    name: 'calendar-weekend',
    codepoint: 'F0ED9',
    deprecated: 'FALSE',
  },
  {
    id: '3E8B49C8-08CA-4D96-BC1B-EB21909E0311',
    name: 'calendar-weekend-outline',
    codepoint: 'F0EDA',
    deprecated: 'FALSE',
  },
  {
    id: 'B5ACE913-DBF1-4E73-BFE5-BB86F0EBF76E',
    name: 'call-made',
    codepoint: 'F00F7',
    deprecated: 'FALSE',
  },
  {
    id: '8BEE5A5E-EC0A-4FC5-96BE-7F8C2A2EE52F',
    name: 'call-merge',
    codepoint: 'F00F8',
    deprecated: 'FALSE',
  },
  {
    id: 'CA2546B9-70B0-4E1A-B646-17163B0C76E4',
    name: 'call-missed',
    codepoint: 'F00F9',
    deprecated: 'FALSE',
  },
  {
    id: '008CD0E5-C47B-44FB-AF43-A1221C527056',
    name: 'call-received',
    codepoint: 'F00FA',
    deprecated: 'FALSE',
  },
  {
    id: '790BCBB6-8B08-4AEC-A322-99F584BB394B',
    name: 'call-split',
    codepoint: 'F00FB',
    deprecated: 'FALSE',
  },
  {
    id: '89EE8EAB-CA5B-40C5-B114-1EC5248634BF',
    name: 'camcorder',
    codepoint: 'F00FC',
    deprecated: 'FALSE',
  },
  {
    id: 'BE1C767B-C032-487C-AB85-E2E960C9A89D',
    name: 'camcorder-off',
    codepoint: 'F00FF',
    deprecated: 'FALSE',
  },
  {
    id: '236D9E8E-E38C-4EA9-9539-0E8B1447003A',
    name: 'camera',
    codepoint: 'F0100',
    deprecated: 'FALSE',
  },
  {
    id: 'E6F67D5D-0EED-450C-B39F-75D37E9B63CA',
    name: 'camera-account',
    codepoint: 'F08CB',
    deprecated: 'FALSE',
  },
  {
    id: '4FCD7DBE-6B4C-41DB-BDD8-40ED820EC7F1',
    name: 'camera-burst',
    codepoint: 'F0693',
    deprecated: 'FALSE',
  },
  {
    id: '28AACAD9-8E72-41FA-B736-55A4AFDAD6FF',
    name: 'camera-control',
    codepoint: 'F0B69',
    deprecated: 'FALSE',
  },
  {
    id: '8A2FFEED-01B4-44C0-A82A-2E03D8C2A447',
    name: 'camera-document',
    codepoint: 'F1871',
    deprecated: 'FALSE',
  },
  {
    id: 'E18549B4-97EC-49C7-A870-5CA1FBDD86EA',
    name: 'camera-document-off',
    codepoint: 'F1872',
    deprecated: 'FALSE',
  },
  {
    id: '45456446-14D9-49F2-8842-93BAF8233F47',
    name: 'camera-enhance',
    codepoint: 'F0101',
    deprecated: 'FALSE',
  },
  {
    id: '5D466962-4E59-4103-8556-25032439677A',
    name: 'camera-enhance-outline',
    codepoint: 'F0B6A',
    deprecated: 'FALSE',
  },
  {
    id: 'D4175840-8B51-4C1E-AFF2-A8142F5CCF2E',
    name: 'camera-flip',
    codepoint: 'F15D9',
    deprecated: 'FALSE',
  },
  {
    id: 'A40CFB47-3650-42BF-874C-C5BF09641708',
    name: 'camera-flip-outline',
    codepoint: 'F15DA',
    deprecated: 'FALSE',
  },
  {
    id: '574F4B2D-70FE-41E3-AFB4-EC1E2AB897AB',
    name: 'camera-front',
    codepoint: 'F0102',
    deprecated: 'FALSE',
  },
  {
    id: '2C6477B5-AA9E-4EDD-A73A-79F2ED5E5F2A',
    name: 'camera-front-variant',
    codepoint: 'F0103',
    deprecated: 'FALSE',
  },
  {
    id: 'B4EDBFFC-CF00-45F3-93A7-6ADD45108FEE',
    name: 'camera-gopro',
    codepoint: 'F07A1',
    deprecated: 'FALSE',
  },
  {
    id: '3399CF92-5E35-4C1A-A796-FB641666AB63',
    name: 'camera-image',
    codepoint: 'F08CC',
    deprecated: 'FALSE',
  },
  {
    id: 'E011977B-432E-470C-A4F6-9C7A4F8BB519',
    name: 'camera-iris',
    codepoint: 'F0104',
    deprecated: 'FALSE',
  },
  {
    id: '46E0AE7F-ECCB-4EAC-BADC-176B2A1FD338',
    name: 'camera-lock',
    codepoint: 'F1A14',
    deprecated: 'FALSE',
  },
  {
    id: '2CB53F43-4731-40F1-993B-90F7D92A4789',
    name: 'camera-lock-open',
    codepoint: 'F1C0D',
    deprecated: 'FALSE',
  },
  {
    id: '26438E72-F4E7-4C38-B373-0661EC4C1616',
    name: 'camera-lock-open-outline',
    codepoint: 'F1C0E',
    deprecated: 'FALSE',
  },
  {
    id: '662A0A34-3FEC-47DB-BD66-608D95F912EF',
    name: 'camera-lock-outline',
    codepoint: 'F1A15',
    deprecated: 'FALSE',
  },
  {
    id: '304F1239-9ADE-4371-AFA5-AD2BC3343D24',
    name: 'camera-marker',
    codepoint: 'F19A7',
    deprecated: 'FALSE',
  },
  {
    id: 'FC72F661-E548-4755-9919-B9960175B831',
    name: 'camera-marker-outline',
    codepoint: 'F19A8',
    deprecated: 'FALSE',
  },
  {
    id: 'D178034B-0B93-40F5-BBF1-D28B25A86647',
    name: 'camera-metering-center',
    codepoint: 'F07A2',
    deprecated: 'FALSE',
  },
  {
    id: '700D143A-8347-4A79-9EF8-844E1B436524',
    name: 'camera-metering-matrix',
    codepoint: 'F07A3',
    deprecated: 'FALSE',
  },
  {
    id: 'EA33D5EE-5E13-4D82-A552-C3B16772926F',
    name: 'camera-metering-partial',
    codepoint: 'F07A4',
    deprecated: 'FALSE',
  },
  {
    id: 'C1313085-6C7F-4D60-A278-A39361B57DA9',
    name: 'camera-metering-spot',
    codepoint: 'F07A5',
    deprecated: 'FALSE',
  },
  {
    id: '461A25B1-59F6-49F1-A917-BED92315AA63',
    name: 'camera-off',
    codepoint: 'F05DF',
    deprecated: 'FALSE',
  },
  {
    id: 'F90640B1-1F16-41D0-98CF-B4C342BB5E61',
    name: 'camera-off-outline',
    codepoint: 'F19BF',
    deprecated: 'FALSE',
  },
  {
    id: '5ABA45CF-038B-4665-BC3B-7D34CEF3882E',
    name: 'camera-outline',
    codepoint: 'F0D5D',
    deprecated: 'FALSE',
  },
  {
    id: 'B58A0D5C-B137-42DD-A43B-8DD296D7684A',
    name: 'camera-party-mode',
    codepoint: 'F0105',
    deprecated: 'FALSE',
  },
  {
    id: '262BE971-8B8E-4DAC-8897-A841E5EBEB27',
    name: 'camera-plus',
    codepoint: 'F0EDB',
    deprecated: 'FALSE',
  },
  {
    id: 'AD12EDBC-2323-410D-B0F2-F84ED6AEC170',
    name: 'camera-plus-outline',
    codepoint: 'F0EDC',
    deprecated: 'FALSE',
  },
  {
    id: 'B4CA0FB9-D119-426C-9043-16D90F5086B8',
    name: 'camera-rear',
    codepoint: 'F0106',
    deprecated: 'FALSE',
  },
  {
    id: 'B7DB5F20-5D64-4096-959F-60BB040B320F',
    name: 'camera-rear-variant',
    codepoint: 'F0107',
    deprecated: 'FALSE',
  },
  {
    id: 'CA03F06D-AD3C-4D69-B6A4-721668AB111C',
    name: 'camera-retake',
    codepoint: 'F0E19',
    deprecated: 'FALSE',
  },
  {
    id: '0C602606-75CC-4D76-B79B-6550127ED464',
    name: 'camera-retake-outline',
    codepoint: 'F0E1A',
    deprecated: 'FALSE',
  },
  {
    id: '68BB6884-5CEA-4B2A-8F22-46B6A98CDA75',
    name: 'camera-switch',
    codepoint: 'F0108',
    deprecated: 'FALSE',
  },
  {
    id: 'CABA2832-65F9-4196-9681-51C5E2C1CFB2',
    name: 'camera-switch-outline',
    codepoint: 'F084A',
    deprecated: 'FALSE',
  },
  {
    id: '6A8FC2AF-724C-46A2-9F04-C34E7D658679',
    name: 'camera-timer',
    codepoint: 'F0109',
    deprecated: 'FALSE',
  },
  {
    id: '5125EF68-0BAB-4C17-BA18-022EAAAE36D5',
    name: 'camera-wireless',
    codepoint: 'F0DB6',
    deprecated: 'FALSE',
  },
  {
    id: 'A8BB090E-25FC-4517-A1C9-B2AA1ABD8AFA',
    name: 'camera-wireless-outline',
    codepoint: 'F0DB7',
    deprecated: 'FALSE',
  },
  {
    id: 'E5958680-B397-40E1-BDB0-49B05339C973',
    name: 'campfire',
    codepoint: 'F0EDD',
    deprecated: 'FALSE',
  },
  {
    id: '02F70DD2-E16E-43FD-9C2A-267C965E3919',
    name: 'cancel',
    codepoint: 'F073A',
    deprecated: 'FALSE',
  },
  {
    id: '1D56356A-1601-41FC-9D98-67736772260B',
    name: 'candelabra',
    codepoint: 'F17D2',
    deprecated: 'FALSE',
  },
  {
    id: 'CF2B4CE1-69FF-472B-BE94-620D71F5A42F',
    name: 'candelabra-fire',
    codepoint: 'F17D3',
    deprecated: 'FALSE',
  },
  {
    id: '4FB0AA38-406A-4877-8B0C-15C7ED95F22C',
    name: 'candle',
    codepoint: 'F05E2',
    deprecated: 'FALSE',
  },
  {
    id: 'AC3D0F93-E7EB-42DE-BBFE-FEBAE7966B35',
    name: 'candy',
    codepoint: 'F1970',
    deprecated: 'FALSE',
  },
  {
    id: '9C3124FB-E9F8-468A-9D51-2CAD3342BEE7',
    name: 'candy-off',
    codepoint: 'F1971',
    deprecated: 'FALSE',
  },
  {
    id: '7BA63C38-546A-47FE-8FA7-D3E744B2C96E',
    name: 'candy-off-outline',
    codepoint: 'F1972',
    deprecated: 'FALSE',
  },
  {
    id: 'B2A0C20C-2A90-4A9F-8C95-179D2ED89002',
    name: 'candy-outline',
    codepoint: 'F1973',
    deprecated: 'FALSE',
  },
  {
    id: '8CAC723B-0FF5-493B-A3A2-5772FDE46A0F',
    name: 'candycane',
    codepoint: 'F010A',
    deprecated: 'FALSE',
  },
  {
    id: '4C2DC909-0C3F-431F-A37B-5B66C9F33E37',
    name: 'cannabis',
    codepoint: 'F07A6',
    deprecated: 'FALSE',
  },
  {
    id: '1DA6A01B-2804-4C68-A60E-20DBBB6938E5',
    name: 'cannabis-off',
    codepoint: 'F166E',
    deprecated: 'FALSE',
  },
  {
    id: '74D4E706-53B2-4349-95B2-026B3057D9B2',
    name: 'caps-lock',
    codepoint: 'F0A9B',
    deprecated: 'FALSE',
  },
  {
    id: 'FCF4FF57-4547-4C11-A20A-3D26252780EC',
    name: 'car',
    codepoint: 'F010B',
    deprecated: 'FALSE',
  },
  {
    id: '02A38C46-7F26-4A95-9025-33181E528B1C',
    name: 'car-2-plus',
    codepoint: 'F1015',
    deprecated: 'FALSE',
  },
  {
    id: '9E971816-54E0-4C43-A6BB-AD821E17DC30',
    name: 'car-3-plus',
    codepoint: 'F1016',
    deprecated: 'FALSE',
  },
  {
    id: 'F647E77D-3416-4DFD-8E9C-0BB1A1FDA6A8',
    name: 'car-arrow-left',
    codepoint: 'F13B2',
    deprecated: 'FALSE',
  },
  {
    id: '04AA17AE-F0E4-4B44-9440-812E1F875884',
    name: 'car-arrow-right',
    codepoint: 'F13B3',
    deprecated: 'FALSE',
  },
  {
    id: 'E41B22B2-CAC1-4D56-877B-2DC4E5E4BEC2',
    name: 'car-back',
    codepoint: 'F0E1B',
    deprecated: 'FALSE',
  },
  {
    id: '4AF128D8-1E70-4396-BB5A-F96A7D0F9BCB',
    name: 'car-battery',
    codepoint: 'F010C',
    deprecated: 'FALSE',
  },
  {
    id: '170D1972-AFA7-417C-BCC4-B07689D8B6F4',
    name: 'car-brake-abs',
    codepoint: 'F0C47',
    deprecated: 'FALSE',
  },
  {
    id: '843DC5E8-723D-44A1-A959-A49C662712D1',
    name: 'car-brake-alert',
    codepoint: 'F0C48',
    deprecated: 'FALSE',
  },
  {
    id: '4FBEBCCE-0C62-45B7-AD52-06CD18B8927C',
    name: 'car-brake-fluid-level',
    codepoint: 'F1909',
    deprecated: 'FALSE',
  },
  {
    id: '4F3D37E3-F34C-4720-BC92-1173A85F88FC',
    name: 'car-brake-hold',
    codepoint: 'F0D5E',
    deprecated: 'FALSE',
  },
  {
    id: 'A7AFFF81-AC39-478A-AF75-E33E20A44D09',
    name: 'car-brake-low-pressure',
    codepoint: 'F190A',
    deprecated: 'FALSE',
  },
  {
    id: 'CBCA05E4-718D-4727-8A1B-EDA815D00AE1',
    name: 'car-brake-parking',
    codepoint: 'F0D5F',
    deprecated: 'FALSE',
  },
  {
    id: '8627DD5D-1BE6-4FBB-81A6-7D40ED3C1E69',
    name: 'car-brake-retarder',
    codepoint: 'F1017',
    deprecated: 'FALSE',
  },
  {
    id: '663F3528-508B-49EE-8437-9B2E757E7AAD',
    name: 'car-brake-temperature',
    codepoint: 'F190B',
    deprecated: 'FALSE',
  },
  {
    id: '7858E89E-A9E6-490D-ABCF-42591D9BBA5C',
    name: 'car-brake-worn-linings',
    codepoint: 'F190C',
    deprecated: 'FALSE',
  },
  {
    id: '6A83FE13-CCAF-473E-B660-F2AD8EE561AD',
    name: 'car-child-seat',
    codepoint: 'F0FA3',
    deprecated: 'FALSE',
  },
  {
    id: '3AB7B60B-344F-4F2B-AFAD-7DB7BA167E68',
    name: 'car-clock',
    codepoint: 'F1974',
    deprecated: 'FALSE',
  },
  {
    id: '862F1CFE-643B-44BB-9B46-33BF1C75CEE2',
    name: 'car-clutch',
    codepoint: 'F1018',
    deprecated: 'FALSE',
  },
  {
    id: 'B854CD8C-6731-4C70-917F-9033015E94F4',
    name: 'car-cog',
    codepoint: 'F13CC',
    deprecated: 'FALSE',
  },
  {
    id: '8C8468C7-8A41-42A1-8C33-4AAE55FF9E2A',
    name: 'car-connected',
    codepoint: 'F010D',
    deprecated: 'FALSE',
  },
  {
    id: 'D7E88B5B-B6F3-4C55-AE93-39A78593B351',
    name: 'car-convertible',
    codepoint: 'F07A7',
    deprecated: 'FALSE',
  },
  {
    id: '1DA47513-FB2A-4A20-926F-3D2E90D40F26',
    name: 'car-coolant-level',
    codepoint: 'F1019',
    deprecated: 'FALSE',
  },
  {
    id: '7FCD959A-B0AA-43AD-B448-5B51A1A2C023',
    name: 'car-cruise-control',
    codepoint: 'F0D60',
    deprecated: 'FALSE',
  },
  {
    id: '1B289520-CE81-4385-88B3-923CB0FD6233',
    name: 'car-defrost-front',
    codepoint: 'F0D61',
    deprecated: 'FALSE',
  },
  {
    id: '8B6938C0-6C97-41A4-A22F-83D6A9F1AC3F',
    name: 'car-defrost-rear',
    codepoint: 'F0D62',
    deprecated: 'FALSE',
  },
  {
    id: 'AA43B04E-7C56-4DFB-83FC-081E3A4A3F48',
    name: 'car-door',
    codepoint: 'F0B6B',
    deprecated: 'FALSE',
  },
  {
    id: '76A1EC75-D6D2-465E-89F7-EBE2AF145C4C',
    name: 'car-door-lock',
    codepoint: 'F109D',
    deprecated: 'FALSE',
  },
  {
    id: '48583637-1477-451A-8E75-765EC4EB0585',
    name: 'car-electric',
    codepoint: 'F0B6C',
    deprecated: 'FALSE',
  },
  {
    id: 'D97919E2-BB44-4ABE-86AB-68CD49AC4BE7',
    name: 'car-electric-outline',
    codepoint: 'F15B5',
    deprecated: 'FALSE',
  },
  {
    id: '355D6842-8858-4C79-B684-0E0F5E03801D',
    name: 'car-emergency',
    codepoint: 'F160F',
    deprecated: 'FALSE',
  },
  {
    id: '5BF118F1-1115-4928-8755-34AF926CF13D',
    name: 'car-esp',
    codepoint: 'F0C49',
    deprecated: 'FALSE',
  },
  {
    id: '90BBBADD-0FCD-4147-9D6E-5DAFCACF2B0B',
    name: 'car-estate',
    codepoint: 'F07A8',
    deprecated: 'FALSE',
  },
  {
    id: '21A2527E-9AC0-4FFA-84CB-246608BEB91D',
    name: 'car-hatchback',
    codepoint: 'F07A9',
    deprecated: 'FALSE',
  },
  {
    id: '6C179C94-30C5-4DD8-B80E-9FFF69ABE0E1',
    name: 'car-info',
    codepoint: 'F11BE',
    deprecated: 'FALSE',
  },
  {
    id: '3B659932-957C-4DD8-81CF-7ECABC572BFC',
    name: 'car-key',
    codepoint: 'F0B6D',
    deprecated: 'FALSE',
  },
  {
    id: '10F38BAD-F9E1-4715-9BE9-3784C788D59A',
    name: 'car-lifted-pickup',
    codepoint: 'F152D',
    deprecated: 'FALSE',
  },
  {
    id: '311C671E-9503-485D-8C7D-359E2A3A6D36',
    name: 'car-light-alert',
    codepoint: 'F190D',
    deprecated: 'FALSE',
  },
  {
    id: 'DA708BEF-4826-49CF-8872-C42996C9616F',
    name: 'car-light-dimmed',
    codepoint: 'F0C4A',
    deprecated: 'FALSE',
  },
  {
    id: '3DAC6713-744B-485F-A985-7E2A16F14D6A',
    name: 'car-light-fog',
    codepoint: 'F0C4B',
    deprecated: 'FALSE',
  },
  {
    id: '7EF72FB8-3767-495E-9F80-888F6924B856',
    name: 'car-light-high',
    codepoint: 'F0C4C',
    deprecated: 'FALSE',
  },
  {
    id: '43758944-946B-4883-A77C-F6F28F175920',
    name: 'car-limousine',
    codepoint: 'F08CD',
    deprecated: 'FALSE',
  },
  {
    id: 'B31CB4B0-9987-4F99-8409-FA4601E34064',
    name: 'car-multiple',
    codepoint: 'F0B6E',
    deprecated: 'FALSE',
  },
  {
    id: '5426595C-DCD3-4CFE-990E-775C80BDCDC6',
    name: 'car-off',
    codepoint: 'F0E1C',
    deprecated: 'FALSE',
  },
  {
    id: '8EDBF463-60A3-4F49-9198-B52F8E6FB68B',
    name: 'car-outline',
    codepoint: 'F14ED',
    deprecated: 'FALSE',
  },
  {
    id: '03116932-7E19-45C7-9E34-02308FCDD7F4',
    name: 'car-parking-lights',
    codepoint: 'F0D63',
    deprecated: 'FALSE',
  },
  {
    id: '76BB371D-335C-4F53-A439-2A681778C6BC',
    name: 'car-pickup',
    codepoint: 'F07AA',
    deprecated: 'FALSE',
  },
  {
    id: '6BF272B9-40CF-45E8-8CE4-8E186BFE95BF',
    name: 'car-search',
    codepoint: 'F1B8D',
    deprecated: 'FALSE',
  },
  {
    id: 'B64ACFFE-836E-4F68-8D1B-3F6FCC263602',
    name: 'car-search-outline',
    codepoint: 'F1B8E',
    deprecated: 'FALSE',
  },
  {
    id: 'F0D6A2CA-711D-440E-B4A2-586C071E1192',
    name: 'car-seat',
    codepoint: 'F0FA4',
    deprecated: 'FALSE',
  },
  {
    id: '3B08983F-F281-48F5-8A34-383591971006',
    name: 'car-seat-cooler',
    codepoint: 'F0FA5',
    deprecated: 'FALSE',
  },
  {
    id: '300D30D3-28E2-4D45-A31D-9FF1E92AC5BE',
    name: 'car-seat-heater',
    codepoint: 'F0FA6',
    deprecated: 'FALSE',
  },
  {
    id: '611D7DCF-0792-430C-AB3E-27DB222A197D',
    name: 'car-select',
    codepoint: 'F1879',
    deprecated: 'FALSE',
  },
  {
    id: '4ED4D6FD-9E30-4D99-9FD0-11B316BACB96',
    name: 'car-settings',
    codepoint: 'F13CD',
    deprecated: 'FALSE',
  },
  {
    id: 'DF11B72C-D029-49BB-9657-6A267CC1DEA4',
    name: 'car-shift-pattern',
    codepoint: 'F0F40',
    deprecated: 'FALSE',
  },
  {
    id: 'CB49A868-3317-4445-BFED-13CB6533000E',
    name: 'car-side',
    codepoint: 'F07AB',
    deprecated: 'FALSE',
  },
  {
    id: '69EE759D-1F0E-414B-A014-5EB611D6286E',
    name: 'car-speed-limiter',
    codepoint: 'F190E',
    deprecated: 'FALSE',
  },
  {
    id: '97F5ED58-F2AB-40AC-A882-4A90DB5D2018',
    name: 'car-sports',
    codepoint: 'F07AC',
    deprecated: 'FALSE',
  },
  {
    id: '93700192-0A90-4FC4-944C-894F29BB478F',
    name: 'car-tire-alert',
    codepoint: 'F0C4D',
    deprecated: 'FALSE',
  },
  {
    id: 'D9DC5111-14E6-4585-A79B-489FC04C1CE3',
    name: 'car-traction-control',
    codepoint: 'F0D64',
    deprecated: 'FALSE',
  },
  {
    id: '1D1E80DE-A3A0-4487-9A94-7C9C3F5E0A59',
    name: 'car-turbocharger',
    codepoint: 'F101A',
    deprecated: 'FALSE',
  },
  {
    id: '95E617D7-9A48-458E-96C5-A8CBBEF3A852',
    name: 'car-wash',
    codepoint: 'F010E',
    deprecated: 'FALSE',
  },
  {
    id: '6E8AA995-39A0-44C4-A0FF-3BC1CA7787DE',
    name: 'car-windshield',
    codepoint: 'F101B',
    deprecated: 'FALSE',
  },
  {
    id: '55F7CDA2-F41C-42C4-8154-1F8B1C603F25',
    name: 'car-windshield-outline',
    codepoint: 'F101C',
    deprecated: 'FALSE',
  },
  {
    id: 'E0C3B158-D502-4354-94AC-994A48BD90FE',
    name: 'car-wireless',
    codepoint: 'F1878',
    deprecated: 'FALSE',
  },
  {
    id: '34DABCA7-B5EF-42DD-9731-ABAA7F3DD322',
    name: 'car-wrench',
    codepoint: 'F1814',
    deprecated: 'FALSE',
  },
  {
    id: '077EABE1-1DBC-4AC9-AA0E-B45386391968',
    name: 'carabiner',
    codepoint: 'F14C0',
    deprecated: 'FALSE',
  },
  {
    id: '2E5B9249-0FFB-48D0-8A61-2019FD9ED32F',
    name: 'caravan',
    codepoint: 'F07AD',
    deprecated: 'FALSE',
  },
  {
    id: 'AB226798-9B8A-4929-8880-6665C1114776',
    name: 'card',
    codepoint: 'F0B6F',
    deprecated: 'FALSE',
  },
  {
    id: '4ACD18FB-87D4-46F1-9231-8BD0A91867A9',
    name: 'card-account-details',
    codepoint: 'F05D2',
    deprecated: 'FALSE',
  },
  {
    id: '6BD2BC65-C99A-42FB-A37E-85F5E8C91D32',
    name: 'card-account-details-outline',
    codepoint: 'F0DAB',
    deprecated: 'FALSE',
  },
  {
    id: '951C68F1-876F-440E-A4D9-B724BB6D6181',
    name: 'card-account-details-star',
    codepoint: 'F02A3',
    deprecated: 'FALSE',
  },
  {
    id: '97C9CAC2-9F94-40EA-9A08-1A9BDD208743',
    name: 'card-account-details-star-outline',
    codepoint: 'F06DB',
    deprecated: 'FALSE',
  },
  {
    id: '81840940-8672-42C0-B657-15E94B3638D6',
    name: 'card-account-mail',
    codepoint: 'F018E',
    deprecated: 'FALSE',
  },
  {
    id: 'F43579AB-0098-44B4-910C-3B24193C3B79',
    name: 'card-account-mail-outline',
    codepoint: 'F0E98',
    deprecated: 'FALSE',
  },
  {
    id: 'D992EB08-7BDB-4D44-B3C1-A123F80C17FA',
    name: 'card-account-phone',
    codepoint: 'F0E99',
    deprecated: 'FALSE',
  },
  {
    id: 'ABC0498A-9F66-489B-A78E-91B3415EE3E4',
    name: 'card-account-phone-outline',
    codepoint: 'F0E9A',
    deprecated: 'FALSE',
  },
  {
    id: 'BFFB5C41-DA26-4980-8B25-1DCB21B4673E',
    name: 'card-bulleted',
    codepoint: 'F0B70',
    deprecated: 'FALSE',
  },
  {
    id: '487C3E75-13DF-4C18-98E4-55CB048367EA',
    name: 'card-bulleted-off',
    codepoint: 'F0B71',
    deprecated: 'FALSE',
  },
  {
    id: 'A6845501-AB5C-4511-863D-25E6E0F4F74C',
    name: 'card-bulleted-off-outline',
    codepoint: 'F0B72',
    deprecated: 'FALSE',
  },
  {
    id: '5A5D5F82-1BBA-4661-A4B7-6AC29484EBB3',
    name: 'card-bulleted-outline',
    codepoint: 'F0B73',
    deprecated: 'FALSE',
  },
  {
    id: 'A8645D1A-6D02-401F-82F6-2564C6EC20D8',
    name: 'card-bulleted-settings',
    codepoint: 'F0B74',
    deprecated: 'FALSE',
  },
  {
    id: '5C008C35-201B-4DDB-A37B-6E513C9304F2',
    name: 'card-bulleted-settings-outline',
    codepoint: 'F0B75',
    deprecated: 'FALSE',
  },
  {
    id: '7538FC94-FD58-41FD-9585-1F6B6A0AA784',
    name: 'card-minus',
    codepoint: 'F1600',
    deprecated: 'FALSE',
  },
  {
    id: 'DBAC0B3F-A11E-4BFF-B5A5-802C28802CFC',
    name: 'card-minus-outline',
    codepoint: 'F1601',
    deprecated: 'FALSE',
  },
  {
    id: 'CCB6A7E7-E036-462A-80BB-8EBFBADD61CB',
    name: 'card-multiple',
    codepoint: 'F17F1',
    deprecated: 'FALSE',
  },
  {
    id: '5A952723-5033-43DA-B1A8-A9DD1A5B3DB6',
    name: 'card-multiple-outline',
    codepoint: 'F17F2',
    deprecated: 'FALSE',
  },
  {
    id: 'B426A851-795C-4627-9F65-EF585020DE63',
    name: 'card-off',
    codepoint: 'F1602',
    deprecated: 'FALSE',
  },
  {
    id: 'DD1C1DF5-C30E-421A-9FA7-AC8F0D021B95',
    name: 'card-off-outline',
    codepoint: 'F1603',
    deprecated: 'FALSE',
  },
  {
    id: 'EB9242D1-9D25-4141-A1A0-06636EC84541',
    name: 'card-outline',
    codepoint: 'F0B76',
    deprecated: 'FALSE',
  },
  {
    id: '3B4FC4CD-DB60-407A-B230-C6CA593977DD',
    name: 'card-plus',
    codepoint: 'F11FF',
    deprecated: 'FALSE',
  },
  {
    id: 'DD8BE7C3-7AE2-420C-8DA2-B3AA4EEC5614',
    name: 'card-plus-outline',
    codepoint: 'F1200',
    deprecated: 'FALSE',
  },
  {
    id: '6902F560-9012-4F0B-8E93-6B0B7641C019',
    name: 'card-remove',
    codepoint: 'F1604',
    deprecated: 'FALSE',
  },
  {
    id: '84C0BF2A-B9FB-4E01-9961-A4146CB9BCC3',
    name: 'card-remove-outline',
    codepoint: 'F1605',
    deprecated: 'FALSE',
  },
  {
    id: '2A09F869-B221-4ABC-B803-16560B7BA900',
    name: 'card-search',
    codepoint: 'F1074',
    deprecated: 'FALSE',
  },
  {
    id: '3EE58157-57AF-420D-9F5B-C545BCE7D395',
    name: 'card-search-outline',
    codepoint: 'F1075',
    deprecated: 'FALSE',
  },
  {
    id: 'EF0C4465-ACEB-44FA-BA7F-A0422E4481F6',
    name: 'card-text',
    codepoint: 'F0B77',
    deprecated: 'FALSE',
  },
  {
    id: '45A9D669-8244-476D-A148-21EAB63F819B',
    name: 'card-text-outline',
    codepoint: 'F0B78',
    deprecated: 'FALSE',
  },
  {
    id: '7E04A92C-1DC5-4A5F-84B0-57A50545EBE4',
    name: 'cards',
    codepoint: 'F0638',
    deprecated: 'FALSE',
  },
  {
    id: 'FD5114FD-37CB-4346-BC73-58E1EED49CF3',
    name: 'cards-club',
    codepoint: 'F08CE',
    deprecated: 'FALSE',
  },
  {
    id: '6F2A121B-8E9C-43C3-8566-5AA696997778',
    name: 'cards-club-outline',
    codepoint: 'F189F',
    deprecated: 'FALSE',
  },
  {
    id: '0934FB0D-8585-4064-989F-054E52D16FDB',
    name: 'cards-diamond',
    codepoint: 'F08CF',
    deprecated: 'FALSE',
  },
  {
    id: '529FA74E-39AF-4AF4-BAD3-637FDFBD53AE',
    name: 'cards-diamond-outline',
    codepoint: 'F101D',
    deprecated: 'FALSE',
  },
  {
    id: '152E7662-6FDD-478C-A945-68F6B04CB073',
    name: 'cards-heart',
    codepoint: 'F08D0',
    deprecated: 'FALSE',
  },
  {
    id: 'BA1E8534-7828-4FE0-A0D3-E016FA76CE8C',
    name: 'cards-heart-outline',
    codepoint: 'F18A0',
    deprecated: 'FALSE',
  },
  {
    id: 'BB07EFB4-5078-47BA-B441-93D4957AF7F9',
    name: 'cards-outline',
    codepoint: 'F0639',
    deprecated: 'FALSE',
  },
  {
    id: 'F3847846-75CC-462A-A4E7-74A2AF93AC5A',
    name: 'cards-playing',
    codepoint: 'F18A1',
    deprecated: 'FALSE',
  },
  {
    id: 'CCBA1AB3-8133-4FB0-BB19-DE8D42AD1ADD',
    name: 'cards-playing-club',
    codepoint: 'F18A2',
    deprecated: 'FALSE',
  },
  {
    id: '52FA2B41-3111-4D72-800A-91E9665CDA06',
    name: 'cards-playing-club-multiple',
    codepoint: 'F18A3',
    deprecated: 'FALSE',
  },
  {
    id: '2521E853-62E5-4CDD-9EE9-8F43FC2FB8EB',
    name: 'cards-playing-club-multiple-outline',
    codepoint: 'F18A4',
    deprecated: 'FALSE',
  },
  {
    id: 'BDB29BCE-FA8C-4CFB-8B23-A0DC648BC203',
    name: 'cards-playing-club-outline',
    codepoint: 'F18A5',
    deprecated: 'FALSE',
  },
  {
    id: 'AF3E53EC-69B3-470E-820E-F106CF69D453',
    name: 'cards-playing-diamond',
    codepoint: 'F18A6',
    deprecated: 'FALSE',
  },
  {
    id: 'FB787ECA-1A04-48A9-8438-F3EC3475D81C',
    name: 'cards-playing-diamond-multiple',
    codepoint: 'F18A7',
    deprecated: 'FALSE',
  },
  {
    id: '84440A36-7850-44EB-A269-B58893D0FEDC',
    name: 'cards-playing-diamond-multiple-outline',
    codepoint: 'F18A8',
    deprecated: 'FALSE',
  },
  {
    id: '546D65EC-4458-49DA-AC96-54D8DB955FCA',
    name: 'cards-playing-diamond-outline',
    codepoint: 'F18A9',
    deprecated: 'FALSE',
  },
  {
    id: '638C8BA0-9D3F-46FF-98F2-2A732D4116F4',
    name: 'cards-playing-heart',
    codepoint: 'F18AA',
    deprecated: 'FALSE',
  },
  {
    id: '53152CB4-487D-4D6C-8E52-E5A1B3F38289',
    name: 'cards-playing-heart-multiple',
    codepoint: 'F18AB',
    deprecated: 'FALSE',
  },
  {
    id: '74B2938C-1AC3-4022-AAF2-3983F3EECE7D',
    name: 'cards-playing-heart-multiple-outline',
    codepoint: 'F18AC',
    deprecated: 'FALSE',
  },
  {
    id: '7951C9AF-05E8-4A77-B682-AD1F5D1C04B8',
    name: 'cards-playing-heart-outline',
    codepoint: 'F18AD',
    deprecated: 'FALSE',
  },
  {
    id: 'C388C342-49AA-4577-A968-12BD804E5A8E',
    name: 'cards-playing-outline',
    codepoint: 'F063A',
    deprecated: 'FALSE',
  },
  {
    id: 'E3254FE1-D955-4E3D-AD8E-385E5F307BA1',
    name: 'cards-playing-spade',
    codepoint: 'F18AE',
    deprecated: 'FALSE',
  },
  {
    id: '46EAB9B6-C359-4D80-AA5E-96A05E42F63B',
    name: 'cards-playing-spade-multiple',
    codepoint: 'F18AF',
    deprecated: 'FALSE',
  },
  {
    id: 'FECC9B9C-2115-4613-926B-C22A16ABCFE8',
    name: 'cards-playing-spade-multiple-outline',
    codepoint: 'F18B0',
    deprecated: 'FALSE',
  },
  {
    id: 'A10D94B9-210F-453F-B9C7-65175CDD81B1',
    name: 'cards-playing-spade-outline',
    codepoint: 'F18B1',
    deprecated: 'FALSE',
  },
  {
    id: 'B2DEB97A-B08E-4E10-8E24-A4AF2CF98C74',
    name: 'cards-spade',
    codepoint: 'F08D1',
    deprecated: 'FALSE',
  },
  {
    id: '2A12FA98-57B3-43FC-A927-384419B791EF',
    name: 'cards-spade-outline',
    codepoint: 'F18B2',
    deprecated: 'FALSE',
  },
  {
    id: 'A000BD20-147F-43FB-AD99-08AE6BA2E80B',
    name: 'cards-variant',
    codepoint: 'F06C7',
    deprecated: 'FALSE',
  },
  {
    id: '6BD10A61-9A45-4A5A-ABEF-404135975917',
    name: 'carrot',
    codepoint: 'F010F',
    deprecated: 'FALSE',
  },
  {
    id: '97563623-CF1C-43AE-87D8-DF54802D442B',
    name: 'cart',
    codepoint: 'F0110',
    deprecated: 'FALSE',
  },
  {
    id: '0DCB3032-271E-4522-ABEE-386F49E6B4ED',
    name: 'cart-arrow-down',
    codepoint: 'F0D66',
    deprecated: 'FALSE',
  },
  {
    id: '31173BE4-3D5E-45EB-BD45-3D19E86AF6B2',
    name: 'cart-arrow-right',
    codepoint: 'F0C4E',
    deprecated: 'FALSE',
  },
  {
    id: '8ED82219-BC37-41C0-9012-E316AB7BD809',
    name: 'cart-arrow-up',
    codepoint: 'F0D67',
    deprecated: 'FALSE',
  },
  {
    id: '549C956B-9A3A-489B-BBC3-F9FF7AF0EB14',
    name: 'cart-check',
    codepoint: 'F15EA',
    deprecated: 'FALSE',
  },
  {
    id: 'CB11E82A-C290-44A7-B9FC-88F47CEF5BC5',
    name: 'cart-heart',
    codepoint: 'F18E0',
    deprecated: 'FALSE',
  },
  {
    id: 'D4AF47D5-214B-4856-8450-0ED7C524991D',
    name: 'cart-minus',
    codepoint: 'F0D68',
    deprecated: 'FALSE',
  },
  {
    id: '05455801-C523-420A-A961-868ABA3DF636',
    name: 'cart-off',
    codepoint: 'F066B',
    deprecated: 'FALSE',
  },
  {
    id: 'F290ABB0-4CD2-4C45-B543-E3AEBA1FE358',
    name: 'cart-outline',
    codepoint: 'F0111',
    deprecated: 'FALSE',
  },
  {
    id: '0E548E6D-946B-4BBE-97FA-2021E2DFF61D',
    name: 'cart-percent',
    codepoint: 'F1BAE',
    deprecated: 'FALSE',
  },
  {
    id: '2BF13245-A7BF-49EC-8EB0-CA10F7AF11B2',
    name: 'cart-plus',
    codepoint: 'F0112',
    deprecated: 'FALSE',
  },
  {
    id: 'DCB745AA-0ECB-4584-88DA-52CC447555A0',
    name: 'cart-remove',
    codepoint: 'F0D69',
    deprecated: 'FALSE',
  },
  {
    id: 'E7E79B59-68A1-4629-9353-A2E64A4A18B4',
    name: 'cart-variant',
    codepoint: 'F15EB',
    deprecated: 'FALSE',
  },
  {
    id: '250BE86F-4D41-4F22-AECD-DD8A2533DA62',
    name: 'case-sensitive-alt',
    codepoint: 'F0113',
    deprecated: 'FALSE',
  },
  {
    id: '59956D81-BF6B-4DCA-ABAD-A890412F22F1',
    name: 'cash',
    codepoint: 'F0114',
    deprecated: 'FALSE',
  },
  {
    id: '18E513D3-5D34-4A4B-B600-D7173CB30DDA',
    name: 'cash-100',
    codepoint: 'F0115',
    deprecated: 'FALSE',
  },
  {
    id: '4AF1798E-6400-40B9-8DAB-4482C07C62E9',
    name: 'cash-check',
    codepoint: 'F14EE',
    deprecated: 'FALSE',
  },
  {
    id: 'D9D0A7DF-0450-4F67-9F50-D46733A1DF3B',
    name: 'cash-clock',
    codepoint: 'F1A91',
    deprecated: 'FALSE',
  },
  {
    id: 'A10E9739-AA80-4671-B744-ECB9934BB7E4',
    name: 'cash-fast',
    codepoint: 'F185C',
    deprecated: 'FALSE',
  },
  {
    id: '03BA1D57-6374-41F7-A781-C0615B91178B',
    name: 'cash-lock',
    codepoint: 'F14EA',
    deprecated: 'FALSE',
  },
  {
    id: 'C9CB2DC9-3F57-4D99-81D8-154F348746E5',
    name: 'cash-lock-open',
    codepoint: 'F14EB',
    deprecated: 'FALSE',
  },
  {
    id: '080E0DBC-A7E4-40C0-A126-9CE83BDE2FDF',
    name: 'cash-marker',
    codepoint: 'F0DB8',
    deprecated: 'FALSE',
  },
  {
    id: '20240E77-778A-4B82-9975-585C647AE81C',
    name: 'cash-minus',
    codepoint: 'F1260',
    deprecated: 'FALSE',
  },
  {
    id: '80D4170E-FDA3-40D0-9B05-1020D5FCA817',
    name: 'cash-multiple',
    codepoint: 'F0116',
    deprecated: 'FALSE',
  },
  {
    id: 'C2E340E3-EA0A-4726-B595-6149C8C662B8',
    name: 'cash-plus',
    codepoint: 'F1261',
    deprecated: 'FALSE',
  },
  {
    id: 'EADBBD96-B2BD-4C31-969E-BFBA6DF519B1',
    name: 'cash-refund',
    codepoint: 'F0A9C',
    deprecated: 'FALSE',
  },
  {
    id: '6B63F864-415A-4679-9126-363E5254B175',
    name: 'cash-register',
    codepoint: 'F0CF4',
    deprecated: 'FALSE',
  },
  {
    id: 'C4DA23E6-9BE6-490B-83FB-B39A3E22E9E1',
    name: 'cash-remove',
    codepoint: 'F1262',
    deprecated: 'FALSE',
  },
  {
    id: '6F96F219-FD7D-4EB5-B341-777F00AF6EBB',
    name: 'cash-sync',
    codepoint: 'F1A92',
    deprecated: 'FALSE',
  },
  {
    id: 'EF35081A-EABF-4C6B-B858-7F2177B60F95',
    name: 'cassette',
    codepoint: 'F09D4',
    deprecated: 'FALSE',
  },
  {
    id: '996A1644-29FB-4221-9225-FD5CF8AAD5CE',
    name: 'cast',
    codepoint: 'F0118',
    deprecated: 'FALSE',
  },
  {
    id: 'E464AD1F-6036-43F9-9D9F-22390B2CBECB',
    name: 'cast-audio',
    codepoint: 'F101E',
    deprecated: 'FALSE',
  },
  {
    id: '38E64DD7-18F9-4D85-9D62-023BDA44F642',
    name: 'cast-audio-variant',
    codepoint: 'F1749',
    deprecated: 'FALSE',
  },
  {
    id: '80EC7FF6-03C0-4D61-8858-D2D94426D2DE',
    name: 'cast-connected',
    codepoint: 'F0119',
    deprecated: 'FALSE',
  },
  {
    id: '31B2064A-79C9-44A7-8C5E-0490162A7864',
    name: 'cast-education',
    codepoint: 'F0E1D',
    deprecated: 'FALSE',
  },
  {
    id: 'F6AC6C9C-C82B-48C0-84AB-BEE76FC7BB5B',
    name: 'cast-off',
    codepoint: 'F078A',
    deprecated: 'FALSE',
  },
  {
    id: 'CD5DD59A-965F-455E-912B-9535BFD134E0',
    name: 'cast-variant',
    codepoint: 'F001F',
    deprecated: 'FALSE',
  },
  {
    id: '53FF9F06-A3F7-4B04-9D54-207FE8B56B8E',
    name: 'castle',
    codepoint: 'F011A',
    deprecated: 'FALSE',
  },
  {
    id: '5D3A753E-1FA9-42F4-8A71-E686A36D1CB6',
    name: 'cat',
    codepoint: 'F011B',
    deprecated: 'FALSE',
  },
  {
    id: '04FF8A0D-0B5F-426B-ABAF-001EAC16559F',
    name: 'cctv',
    codepoint: 'F07AE',
    deprecated: 'FALSE',
  },
  {
    id: '4B6324CC-D2F7-4CDA-B05F-AC05CA7E238D',
    name: 'cctv-off',
    codepoint: 'F185F',
    deprecated: 'FALSE',
  },
  {
    id: '580D06BE-1E88-47D7-9DEE-D1929637B0E3',
    name: 'ceiling-fan',
    codepoint: 'F1797',
    deprecated: 'FALSE',
  },
  {
    id: '326FF6B4-E497-4C9C-8EA5-D3FEACF0B2AE',
    name: 'ceiling-fan-light',
    codepoint: 'F1798',
    deprecated: 'FALSE',
  },
  {
    id: 'C45A21A3-671C-4B1A-B405-D625ECA93C11',
    name: 'ceiling-light',
    codepoint: 'F0769',
    deprecated: 'FALSE',
  },
  {
    id: '95F3D89F-8AE6-4B86-BFE7-7D6A27018E7D',
    name: 'ceiling-light-multiple',
    codepoint: 'F18DD',
    deprecated: 'FALSE',
  },
  {
    id: '11968A7F-D159-497F-9D97-A25CACD6AE8D',
    name: 'ceiling-light-multiple-outline',
    codepoint: 'F18DE',
    deprecated: 'FALSE',
  },
  {
    id: 'DD107169-7A9D-452F-8DE9-2736B6FB7851',
    name: 'ceiling-light-outline',
    codepoint: 'F17C7',
    deprecated: 'FALSE',
  },
  {
    id: '8898488F-1BE6-40A0-84B7-1C0832AA4754',
    name: 'cellphone',
    codepoint: 'F011C',
    deprecated: 'FALSE',
  },
  {
    id: '04D5C9C6-6CE4-4D2E-A759-07933F9B7932',
    name: 'cellphone-arrow-down',
    codepoint: 'F09D5',
    deprecated: 'FALSE',
  },
  {
    id: 'AF03DF0A-3E57-412D-B72E-67938C4FD599',
    name: 'cellphone-arrow-down-variant',
    codepoint: 'F19C5',
    deprecated: 'FALSE',
  },
  {
    id: 'E22A55C0-983F-499E-8B43-4A66DB278B4E',
    name: 'cellphone-basic',
    codepoint: 'F011E',
    deprecated: 'FALSE',
  },
  {
    id: 'FE245F0F-24CE-497E-BA73-19936BD14716',
    name: 'cellphone-charging',
    codepoint: 'F1397',
    deprecated: 'FALSE',
  },
  {
    id: '0F2A2942-A58B-428B-B641-8AF2FF3A845B',
    name: 'cellphone-check',
    codepoint: 'F17FD',
    deprecated: 'FALSE',
  },
  {
    id: '134C82D3-528D-46FA-9107-7F3D5AD8FBC3',
    name: 'cellphone-cog',
    codepoint: 'F0951',
    deprecated: 'FALSE',
  },
  {
    id: '4FBBF196-CF97-436B-A3E6-A08D695E6986',
    name: 'cellphone-dock',
    codepoint: 'F011F',
    deprecated: 'FALSE',
  },
  {
    id: '5B692392-DA8F-40F2-948F-D81832ABA465',
    name: 'cellphone-information',
    codepoint: 'F0F41',
    deprecated: 'FALSE',
  },
  {
    id: '29BA2DA3-CED6-4D52-92EF-D9152F63B7AB',
    name: 'cellphone-key',
    codepoint: 'F094E',
    deprecated: 'FALSE',
  },
  {
    id: 'E29A590D-51B8-471B-B23B-E0A41046EDA6',
    name: 'cellphone-link',
    codepoint: 'F0121',
    deprecated: 'FALSE',
  },
  {
    id: '7E4265ED-151D-45CE-BDD2-08B6028B6006',
    name: 'cellphone-link-off',
    codepoint: 'F0122',
    deprecated: 'FALSE',
  },
  {
    id: '2261E999-4B3A-4540-BB30-B4C130137916',
    name: 'cellphone-lock',
    codepoint: 'F094F',
    deprecated: 'FALSE',
  },
  {
    id: '487DEBB1-A270-44B5-A2B9-D7E11F32C0CC',
    name: 'cellphone-marker',
    codepoint: 'F183A',
    deprecated: 'FALSE',
  },
  {
    id: '4C511E7E-D658-493D-B34B-B73A4DEE8FF9',
    name: 'cellphone-message',
    codepoint: 'F08D3',
    deprecated: 'FALSE',
  },
  {
    id: '039B41EB-85E3-458B-9C51-AADE4C4071FE',
    name: 'cellphone-message-off',
    codepoint: 'F10D2',
    deprecated: 'FALSE',
  },
  {
    id: 'A4EFE1A4-034F-473F-9076-D8DFF6167067',
    name: 'cellphone-nfc',
    codepoint: 'F0E90',
    deprecated: 'FALSE',
  },
  {
    id: 'E036E842-B41C-410B-BF8A-85B10A7E26D0',
    name: 'cellphone-nfc-off',
    codepoint: 'F12D8',
    deprecated: 'FALSE',
  },
  {
    id: '2AD1736A-2142-4E3D-BA70-9CF8584C366D',
    name: 'cellphone-off',
    codepoint: 'F0950',
    deprecated: 'FALSE',
  },
  {
    id: 'B89607FB-8199-48B0-AF59-E048CAEB210B',
    name: 'cellphone-play',
    codepoint: 'F101F',
    deprecated: 'FALSE',
  },
  {
    id: 'EC642A5A-34A2-4E29-85F1-60ACAA546FB1',
    name: 'cellphone-remove',
    codepoint: 'F094D',
    deprecated: 'FALSE',
  },
  {
    id: '84F994FA-DF0C-4A5A-88F6-8A2AD1DC1189',
    name: 'cellphone-screenshot',
    codepoint: 'F0A35',
    deprecated: 'FALSE',
  },
  {
    id: 'E7458A8C-66F2-41D7-81AE-4B2B961DFE1A',
    name: 'cellphone-settings',
    codepoint: 'F0123',
    deprecated: 'FALSE',
  },
  {
    id: 'F5D685BD-7BA8-4F4C-8701-06F1B1E57064',
    name: 'cellphone-sound',
    codepoint: 'F0952',
    deprecated: 'FALSE',
  },
  {
    id: 'D89FFCD9-0592-4760-98C9-5EDDA77826F5',
    name: 'cellphone-text',
    codepoint: 'F08D2',
    deprecated: 'FALSE',
  },
  {
    id: '071CA76A-0ED5-42CD-9BE9-46AFA9D5459E',
    name: 'cellphone-wireless',
    codepoint: 'F0815',
    deprecated: 'FALSE',
  },
  {
    id: 'BAC7F15C-844C-4FEB-A4C0-2CDF80738979',
    name: 'centos',
    codepoint: 'F111A',
    deprecated: 'TRUE',
  },
  {
    id: '2DBEB397-D80B-445D-A11E-7B8362CFBEF4',
    name: 'certificate',
    codepoint: 'F0124',
    deprecated: 'FALSE',
  },
  {
    id: '6F755EB5-B2E1-413A-A919-3F0DCE69E48A',
    name: 'certificate-outline',
    codepoint: 'F1188',
    deprecated: 'FALSE',
  },
  {
    id: '822CF3D7-FEB4-4988-A037-0866756D4ADE',
    name: 'chair-rolling',
    codepoint: 'F0F48',
    deprecated: 'FALSE',
  },
  {
    id: '47FCC651-522C-4786-B518-91A03A721A90',
    name: 'chair-school',
    codepoint: 'F0125',
    deprecated: 'FALSE',
  },
  {
    id: 'C0F0A8D1-34A5-462C-B417-733507042380',
    name: 'chandelier',
    codepoint: 'F1793',
    deprecated: 'FALSE',
  },
  {
    id: 'B5589411-20E7-4552-BEC5-A394068B23A7',
    name: 'charity',
    codepoint: 'F0C4F',
    deprecated: 'FALSE',
  },
  {
    id: '98939E5B-1528-4A2F-B35A-C37C60C75D93',
    name: 'chart-arc',
    codepoint: 'F0126',
    deprecated: 'FALSE',
  },
  {
    id: '00654F5E-1B8B-4BD7-933B-0BD65B2C9BAC',
    name: 'chart-areaspline',
    codepoint: 'F0127',
    deprecated: 'FALSE',
  },
  {
    id: 'C55BFF6F-469B-4D28-835B-537BED8DE99D',
    name: 'chart-areaspline-variant',
    codepoint: 'F0E91',
    deprecated: 'FALSE',
  },
  {
    id: '85D4DC49-5359-4867-A5D1-52C44699387F',
    name: 'chart-bar',
    codepoint: 'F0128',
    deprecated: 'FALSE',
  },
  {
    id: 'FAA40519-5517-44DF-AF2E-30D07874BEB7',
    name: 'chart-bar-stacked',
    codepoint: 'F076A',
    deprecated: 'FALSE',
  },
  {
    id: '35760CC7-EBA2-4445-B88B-7E8224535BB7',
    name: 'chart-bell-curve',
    codepoint: 'F0C50',
    deprecated: 'FALSE',
  },
  {
    id: 'D4E38BC9-5A85-4890-B3DD-92F3D263E26E',
    name: 'chart-bell-curve-cumulative',
    codepoint: 'F0FA7',
    deprecated: 'FALSE',
  },
  {
    id: '381420B2-B2EE-4E9F-9DEA-EA0C1F92A226',
    name: 'chart-box',
    codepoint: 'F154D',
    deprecated: 'FALSE',
  },
  {
    id: '4115E465-01E5-4F3F-929A-81C239289B21',
    name: 'chart-box-outline',
    codepoint: 'F154E',
    deprecated: 'FALSE',
  },
  {
    id: '2F2AA4D1-DB33-4B54-8052-2A2EFCE59168',
    name: 'chart-box-plus-outline',
    codepoint: 'F154F',
    deprecated: 'FALSE',
  },
  {
    id: '9D79A433-CF5A-45A2-971F-4FBF72DB9821',
    name: 'chart-bubble',
    codepoint: 'F05E3',
    deprecated: 'FALSE',
  },
  {
    id: 'C7BED6AB-8D91-42EC-A22F-D4B922B0EB14',
    name: 'chart-donut',
    codepoint: 'F07AF',
    deprecated: 'FALSE',
  },
  {
    id: 'FE9CBE2A-4D6F-4A1F-B817-26607BB9561B',
    name: 'chart-donut-variant',
    codepoint: 'F07B0',
    deprecated: 'FALSE',
  },
  {
    id: 'A2839B3A-760A-4061-80CA-41B3FA5817E0',
    name: 'chart-gantt',
    codepoint: 'F066C',
    deprecated: 'FALSE',
  },
  {
    id: '8DCC14D7-057F-4DA4-89B2-B26D6B6F0C00',
    name: 'chart-histogram',
    codepoint: 'F0129',
    deprecated: 'FALSE',
  },
  {
    id: 'D996574D-5B42-47C5-91D6-F86555C2FBC5',
    name: 'chart-line',
    codepoint: 'F012A',
    deprecated: 'FALSE',
  },
  {
    id: 'B53C0CF7-F923-409F-91C3-420513E44231',
    name: 'chart-line-stacked',
    codepoint: 'F076B',
    deprecated: 'FALSE',
  },
  {
    id: '2FAF8E0B-5AB4-4914-A0A1-CECFFAF85427',
    name: 'chart-line-variant',
    codepoint: 'F07B1',
    deprecated: 'FALSE',
  },
  {
    id: '746FB7AE-B8AC-4356-8B79-D95F54B410D7',
    name: 'chart-multiline',
    codepoint: 'F08D4',
    deprecated: 'FALSE',
  },
  {
    id: '42E3BF4B-FFE9-449F-AFEE-66F565602A9B',
    name: 'chart-multiple',
    codepoint: 'F1213',
    deprecated: 'FALSE',
  },
  {
    id: '9C6B53C5-698B-4428-9738-A6700BF8698C',
    name: 'chart-pie',
    codepoint: 'F012B',
    deprecated: 'FALSE',
  },
  {
    id: 'B7CE40ED-002C-475A-ADAC-8110FE260F65',
    name: 'chart-pie-outline',
    codepoint: 'F1BDF',
    deprecated: 'FALSE',
  },
  {
    id: '42A6B073-CC7E-4539-9313-E60FAE81BC70',
    name: 'chart-ppf',
    codepoint: 'F1380',
    deprecated: 'FALSE',
  },
  {
    id: 'D0F312B5-8B3B-4E68-BF0B-691D4F129749',
    name: 'chart-sankey',
    codepoint: 'F11DF',
    deprecated: 'FALSE',
  },
  {
    id: '5FA4943E-5956-4668-ADDD-984914DD745C',
    name: 'chart-sankey-variant',
    codepoint: 'F11E0',
    deprecated: 'FALSE',
  },
  {
    id: 'C268C5A5-0D35-4963-9151-61D2C442015B',
    name: 'chart-scatter-plot',
    codepoint: 'F0E92',
    deprecated: 'FALSE',
  },
  {
    id: '852E3EF2-942E-42C5-87BB-E1193EBD3E0C',
    name: 'chart-scatter-plot-hexbin',
    codepoint: 'F066D',
    deprecated: 'FALSE',
  },
  {
    id: '922A0C03-C46D-45E7-8B44-9D53F1292360',
    name: 'chart-timeline',
    codepoint: 'F066E',
    deprecated: 'FALSE',
  },
  {
    id: 'F41D980A-439A-4F1B-A3B7-850EB1A70416',
    name: 'chart-timeline-variant',
    codepoint: 'F0E93',
    deprecated: 'FALSE',
  },
  {
    id: 'F2256261-8218-48B2-BACA-9E23E42EEC15',
    name: 'chart-timeline-variant-shimmer',
    codepoint: 'F15B6',
    deprecated: 'FALSE',
  },
  {
    id: 'B7E7AEAC-4A67-4C0F-8A4C-C0955AE5F655',
    name: 'chart-tree',
    codepoint: 'F0E94',
    deprecated: 'FALSE',
  },
  {
    id: 'ABA3B2FE-7270-466B-86F6-00A87B947F6F',
    name: 'chart-waterfall',
    codepoint: 'F1918',
    deprecated: 'FALSE',
  },
  {
    id: '7FF15164-E575-4024-ABAC-A183179F0217',
    name: 'chat',
    codepoint: 'F0B79',
    deprecated: 'FALSE',
  },
  {
    id: '7E35E8CF-E958-43D7-9B28-072E3161618A',
    name: 'chat-alert',
    codepoint: 'F0B7A',
    deprecated: 'FALSE',
  },
  {
    id: 'C0C2065A-8B0C-4DE2-AD4E-D909080FFB42',
    name: 'chat-alert-outline',
    codepoint: 'F12C9',
    deprecated: 'FALSE',
  },
  {
    id: '45A636B1-89B7-45B8-B68A-65DB813B7927',
    name: 'chat-minus',
    codepoint: 'F1410',
    deprecated: 'FALSE',
  },
  {
    id: '69E4B1D9-C479-47CB-8354-A61FCCC51A0B',
    name: 'chat-minus-outline',
    codepoint: 'F1413',
    deprecated: 'FALSE',
  },
  {
    id: 'E6394813-3C45-4D08-A207-9008A2E5CECB',
    name: 'chat-outline',
    codepoint: 'F0EDE',
    deprecated: 'FALSE',
  },
  {
    id: '25FEEB21-3351-4743-994B-1DA08DEAB2B7',
    name: 'chat-plus',
    codepoint: 'F140F',
    deprecated: 'FALSE',
  },
  {
    id: 'F9BDF971-6385-4B8F-98AD-B3FE8D376855',
    name: 'chat-plus-outline',
    codepoint: 'F1412',
    deprecated: 'FALSE',
  },
  {
    id: '95003FC4-C655-4687-AF82-660A49B95978',
    name: 'chat-processing',
    codepoint: 'F0B7B',
    deprecated: 'FALSE',
  },
  {
    id: 'F753E08E-F7C9-4BAF-8647-9328D56D1837',
    name: 'chat-processing-outline',
    codepoint: 'F12CA',
    deprecated: 'FALSE',
  },
  {
    id: '8B7833C6-EB36-4F0C-A554-AF7189A7AB34',
    name: 'chat-question',
    codepoint: 'F1738',
    deprecated: 'FALSE',
  },
  {
    id: '7EC5A46D-1E6A-475C-A76F-19F9F4B9CD90',
    name: 'chat-question-outline',
    codepoint: 'F1739',
    deprecated: 'FALSE',
  },
  {
    id: '3C021F95-3463-45CD-9908-A0D84B2793FB',
    name: 'chat-remove',
    codepoint: 'F1411',
    deprecated: 'FALSE',
  },
  {
    id: '4D147E9B-52BB-446B-8355-0CEA377C0631',
    name: 'chat-remove-outline',
    codepoint: 'F1414',
    deprecated: 'FALSE',
  },
  {
    id: '4B555315-27A3-4DD2-8A85-0F05260CDA33',
    name: 'chat-sleep',
    codepoint: 'F12D1',
    deprecated: 'FALSE',
  },
  {
    id: 'ABE1573F-24A9-4AC1-9ADB-6A9A32FB10AA',
    name: 'chat-sleep-outline',
    codepoint: 'F12D2',
    deprecated: 'FALSE',
  },
  {
    id: 'F789CE44-7EA3-4E97-88FB-BEC22EB2030C',
    name: 'check',
    codepoint: 'F012C',
    deprecated: 'FALSE',
  },
  {
    id: '48BA3B7B-1EF7-4160-A0BB-54EBCF999A13',
    name: 'check-all',
    codepoint: 'F012D',
    deprecated: 'FALSE',
  },
  {
    id: '2070D6C6-EC09-4507-BE0A-07BFFFAF6DFB',
    name: 'check-bold',
    codepoint: 'F0E1E',
    deprecated: 'FALSE',
  },
  {
    id: '93D7B6DC-17D2-4A2B-B787-79E2E8BEF304',
    name: 'check-circle',
    codepoint: 'F05E0',
    deprecated: 'FALSE',
  },
  {
    id: 'F609EE7E-AAB4-4CE7-A002-D0F7ECF89609',
    name: 'check-circle-outline',
    codepoint: 'F05E1',
    deprecated: 'FALSE',
  },
  {
    id: '64E327CF-3FCB-4742-AB82-789B2C27D31B',
    name: 'check-decagram',
    codepoint: 'F0791',
    deprecated: 'FALSE',
  },
  {
    id: '1CAE9177-18C6-497A-B6B9-900C00A14D0E',
    name: 'check-decagram-outline',
    codepoint: 'F1740',
    deprecated: 'FALSE',
  },
  {
    id: 'F1CE2683-FE3F-4AA1-89A2-A935D6FD403C',
    name: 'check-network',
    codepoint: 'F0C53',
    deprecated: 'FALSE',
  },
  {
    id: '98A3D662-2D11-40DB-8557-E1DD0E877091',
    name: 'check-network-outline',
    codepoint: 'F0C54',
    deprecated: 'FALSE',
  },
  {
    id: '38BFE155-0352-4907-A578-B5D0D7F5B78D',
    name: 'check-outline',
    codepoint: 'F0855',
    deprecated: 'FALSE',
  },
  {
    id: '862B14E6-BA7A-4736-8047-1C836B2F4EC1',
    name: 'check-underline',
    codepoint: 'F0E1F',
    deprecated: 'FALSE',
  },
  {
    id: '4B7477D9-418A-40E7-811A-50DFFEA14A03',
    name: 'check-underline-circle',
    codepoint: 'F0E20',
    deprecated: 'FALSE',
  },
  {
    id: '8C448942-B521-4C50-B8AD-0233EF30B12C',
    name: 'check-underline-circle-outline',
    codepoint: 'F0E21',
    deprecated: 'FALSE',
  },
  {
    id: 'F35E17AA-A0E8-4AEE-9504-6DC07F3ACEAD',
    name: 'checkbook',
    codepoint: 'F0A9D',
    deprecated: 'FALSE',
  },
  {
    id: 'E1AF54E0-A2C9-41D4-89E0-62CD301F4B71',
    name: 'checkbook-arrow-left',
    codepoint: 'F1C1D',
    deprecated: 'FALSE',
  },
  {
    id: '1EE03F8D-8B51-464B-84DE-6079644BA583',
    name: 'checkbook-arrow-right',
    codepoint: 'F1C1E',
    deprecated: 'FALSE',
  },
  {
    id: '2B75FE22-5209-4DF8-B16A-B6F74272337B',
    name: 'checkbox-blank',
    codepoint: 'F012E',
    deprecated: 'FALSE',
  },
  {
    id: '22ECB099-6A57-4DA8-9E42-0E3D8000C003',
    name: 'checkbox-blank-badge',
    codepoint: 'F1176',
    deprecated: 'FALSE',
  },
  {
    id: '219B8956-E48B-4D11-9257-422E563955DB',
    name: 'checkbox-blank-badge-outline',
    codepoint: 'F0117',
    deprecated: 'FALSE',
  },
  {
    id: 'F031DE59-CC30-47BF-B689-BC993893B03A',
    name: 'checkbox-blank-circle',
    codepoint: 'F012F',
    deprecated: 'FALSE',
  },
  {
    id: '7C094780-770D-44B6-981C-C701784192D6',
    name: 'checkbox-blank-circle-outline',
    codepoint: 'F0130',
    deprecated: 'FALSE',
  },
  {
    id: '8F505717-7778-4909-81E9-0714F01B44AF',
    name: 'checkbox-blank-off',
    codepoint: 'F12EC',
    deprecated: 'FALSE',
  },
  {
    id: '06F6526C-6D2A-45F5-A5DF-46860DE27EAC',
    name: 'checkbox-blank-off-outline',
    codepoint: 'F12ED',
    deprecated: 'FALSE',
  },
  {
    id: 'C7D0E43D-6393-429B-BDCE-B522419B7B3D',
    name: 'checkbox-blank-outline',
    codepoint: 'F0131',
    deprecated: 'FALSE',
  },
  {
    id: 'EFD12742-D508-4531-A9A4-EDCF3148C748',
    name: 'checkbox-intermediate',
    codepoint: 'F0856',
    deprecated: 'FALSE',
  },
  {
    id: '409261FA-D055-4E16-88A0-94C31667F218',
    name: 'checkbox-intermediate-variant',
    codepoint: 'F1B54',
    deprecated: 'FALSE',
  },
  {
    id: 'C3E50D88-07AB-491F-B21E-9AA11A1E5886',
    name: 'checkbox-marked',
    codepoint: 'F0132',
    deprecated: 'FALSE',
  },
  {
    id: '4336ADDD-7A8E-46E7-A886-3873CCCFD3DA',
    name: 'checkbox-marked-circle',
    codepoint: 'F0133',
    deprecated: 'FALSE',
  },
  {
    id: '7DC53566-5640-4124-A2C2-5070D7FBA591',
    name: 'checkbox-marked-circle-outline',
    codepoint: 'F0134',
    deprecated: 'FALSE',
  },
  {
    id: '488DC51C-ABAF-4017-885B-84D3BBDE4B42',
    name: 'checkbox-marked-circle-plus-outline',
    codepoint: 'F1927',
    deprecated: 'FALSE',
  },
  {
    id: 'AB384DA7-C0DA-4A68-96EA-FC54BF0EDED1',
    name: 'checkbox-marked-outline',
    codepoint: 'F0135',
    deprecated: 'FALSE',
  },
  {
    id: '2B01AE9D-AFA4-4CDA-82BD-21076D27C006',
    name: 'checkbox-multiple-blank',
    codepoint: 'F0136',
    deprecated: 'FALSE',
  },
  {
    id: '0AE5C836-2A43-4706-81C5-5053A73B9CAA',
    name: 'checkbox-multiple-blank-circle',
    codepoint: 'F063B',
    deprecated: 'FALSE',
  },
  {
    id: 'CCBAD6F8-5D3D-420A-94AF-CC85AA12397E',
    name: 'checkbox-multiple-blank-circle-outline',
    codepoint: 'F063C',
    deprecated: 'FALSE',
  },
  {
    id: 'CA74F33C-3AEE-4EA9-93B4-643099DFD92D',
    name: 'checkbox-multiple-blank-outline',
    codepoint: 'F0137',
    deprecated: 'FALSE',
  },
  {
    id: '4F160BFE-433B-49D1-B2DC-E7F71AF7070B',
    name: 'checkbox-multiple-marked',
    codepoint: 'F0138',
    deprecated: 'FALSE',
  },
  {
    id: '85C35F15-1AC0-407B-BCBF-AD79B6E6DDEA',
    name: 'checkbox-multiple-marked-circle',
    codepoint: 'F063D',
    deprecated: 'FALSE',
  },
  {
    id: '5158D0E3-0E77-4CC3-A9A4-727825812D00',
    name: 'checkbox-multiple-marked-circle-outline',
    codepoint: 'F063E',
    deprecated: 'FALSE',
  },
  {
    id: '16BECBE7-BD8E-4301-B5CB-5EE534FBDAD3',
    name: 'checkbox-multiple-marked-outline',
    codepoint: 'F0139',
    deprecated: 'FALSE',
  },
  {
    id: 'E0A0FF12-A5AD-40E3-B286-8958154F0537',
    name: 'checkbox-multiple-outline',
    codepoint: 'F0C51',
    deprecated: 'FALSE',
  },
  {
    id: 'EA5B8CC8-FFD6-4128-AC25-DEDCF1097A34',
    name: 'checkbox-outline',
    codepoint: 'F0C52',
    deprecated: 'FALSE',
  },
  {
    id: 'A5F7D4AF-C7A3-4F38-B21B-B9F3E68770DE',
    name: 'checkerboard',
    codepoint: 'F013A',
    deprecated: 'FALSE',
  },
  {
    id: '925C7A7F-E7D0-4260-B131-D96FF2F7B8DB',
    name: 'checkerboard-minus',
    codepoint: 'F1202',
    deprecated: 'FALSE',
  },
  {
    id: '020A5F88-EE7B-4FE7-ADC7-999A0ADB16EE',
    name: 'checkerboard-plus',
    codepoint: 'F1201',
    deprecated: 'FALSE',
  },
  {
    id: 'CDCAA6DE-0885-474F-992F-6A7F46563A15',
    name: 'checkerboard-remove',
    codepoint: 'F1203',
    deprecated: 'FALSE',
  },
  {
    id: '618E3DC0-F776-4CFF-BE9F-D6A23D580F5B',
    name: 'cheese',
    codepoint: 'F12B9',
    deprecated: 'FALSE',
  },
  {
    id: '7EF3B22A-CA40-400B-AA25-00B9A6777651',
    name: 'cheese-off',
    codepoint: 'F13EE',
    deprecated: 'FALSE',
  },
  {
    id: 'E22BD22B-9748-4CA8-A748-B5598B08A7DE',
    name: 'chef-hat',
    codepoint: 'F0B7C',
    deprecated: 'FALSE',
  },
  {
    id: '7916D1CB-2F73-4C27-8A6B-B14D593F8F59',
    name: 'chemical-weapon',
    codepoint: 'F013B',
    deprecated: 'FALSE',
  },
  {
    id: '3B0E25CE-ADB0-4601-BFBD-87C7B6D63E7F',
    name: 'chess-bishop',
    codepoint: 'F085C',
    deprecated: 'FALSE',
  },
  {
    id: '073FDF74-5305-45AB-8AC3-42A0FE6BBF9C',
    name: 'chess-king',
    codepoint: 'F0857',
    deprecated: 'FALSE',
  },
  {
    id: '99D5E230-2040-4811-BEF1-17B867DA5F75',
    name: 'chess-knight',
    codepoint: 'F0858',
    deprecated: 'FALSE',
  },
  {
    id: 'EDF049A9-1170-411B-923E-D19BD5EADB92',
    name: 'chess-pawn',
    codepoint: 'F0859',
    deprecated: 'FALSE',
  },
  {
    id: '0B9D0593-D546-4DDD-AEB1-289943FB12AE',
    name: 'chess-queen',
    codepoint: 'F085A',
    deprecated: 'FALSE',
  },
  {
    id: 'D1FA251F-4332-4BA9-8742-F194C0B0BF28',
    name: 'chess-rook',
    codepoint: 'F085B',
    deprecated: 'FALSE',
  },
  {
    id: '74ACC301-AA62-492B-92C1-8021BDD89A02',
    name: 'chevron-double-down',
    codepoint: 'F013C',
    deprecated: 'FALSE',
  },
  {
    id: '76350FC3-7629-4247-9D09-8AB9AF9BC6CF',
    name: 'chevron-double-left',
    codepoint: 'F013D',
    deprecated: 'FALSE',
  },
  {
    id: 'E4B5D36C-A663-4D53-BE18-09393497556F',
    name: 'chevron-double-right',
    codepoint: 'F013E',
    deprecated: 'FALSE',
  },
  {
    id: '53D302C9-3A0D-4104-967E-21D174E3C0B2',
    name: 'chevron-double-up',
    codepoint: 'F013F',
    deprecated: 'FALSE',
  },
  {
    id: '84FE90C1-076D-4EA1-B56F-4C2582A39AC3',
    name: 'chevron-down',
    codepoint: 'F0140',
    deprecated: 'FALSE',
  },
  {
    id: 'E2EFD734-594F-40ED-A263-3DFBB8E1B381',
    name: 'chevron-down-box',
    codepoint: 'F09D6',
    deprecated: 'FALSE',
  },
  {
    id: 'D299D24D-E65D-4A1F-953B-0AC936D25C58',
    name: 'chevron-down-box-outline',
    codepoint: 'F09D7',
    deprecated: 'FALSE',
  },
  {
    id: 'F312962F-0720-4145-89EA-6E3E9D60131D',
    name: 'chevron-down-circle',
    codepoint: 'F0B26',
    deprecated: 'FALSE',
  },
  {
    id: '3968656C-5ADA-44B4-AFAD-9437B1363C5D',
    name: 'chevron-down-circle-outline',
    codepoint: 'F0B27',
    deprecated: 'FALSE',
  },
  {
    id: 'CF5607F2-4106-4D47-8FE6-B8F5A16E6D98',
    name: 'chevron-left',
    codepoint: 'F0141',
    deprecated: 'FALSE',
  },
  {
    id: '7DC0F3E7-2E3F-49BD-874D-B17250FBA187',
    name: 'chevron-left-box',
    codepoint: 'F09D8',
    deprecated: 'FALSE',
  },
  {
    id: '22B3AE2E-C625-4325-8FBE-A98AC004CEE6',
    name: 'chevron-left-box-outline',
    codepoint: 'F09D9',
    deprecated: 'FALSE',
  },
  {
    id: '842FCAE6-0FE2-495D-980C-1ABA85257C77',
    name: 'chevron-left-circle',
    codepoint: 'F0B28',
    deprecated: 'FALSE',
  },
  {
    id: 'DDBD357B-6E94-483F-9A09-0E2131FA8533',
    name: 'chevron-left-circle-outline',
    codepoint: 'F0B29',
    deprecated: 'FALSE',
  },
  {
    id: 'A6D25AD4-462B-4185-B739-FBFCD3ACF3C3',
    name: 'chevron-right',
    codepoint: 'F0142',
    deprecated: 'FALSE',
  },
  {
    id: 'D9C230F8-C44C-4CE7-8DEF-891A531A1619',
    name: 'chevron-right-box',
    codepoint: 'F09DA',
    deprecated: 'FALSE',
  },
  {
    id: 'E96D0BE5-31CA-4C47-B91A-B8FB9FCB5D15',
    name: 'chevron-right-box-outline',
    codepoint: 'F09DB',
    deprecated: 'FALSE',
  },
  {
    id: 'A0F96469-2C19-48B6-A054-3F48656CE24F',
    name: 'chevron-right-circle',
    codepoint: 'F0B2A',
    deprecated: 'FALSE',
  },
  {
    id: '9AA7CA11-776D-413A-A08F-9E21AF0F963B',
    name: 'chevron-right-circle-outline',
    codepoint: 'F0B2B',
    deprecated: 'FALSE',
  },
  {
    id: 'D922FD44-139A-4DEE-92A6-1BFCA39C8461',
    name: 'chevron-triple-down',
    codepoint: 'F0DB9',
    deprecated: 'FALSE',
  },
  {
    id: '09E1A31D-448C-42D3-A220-2ACBD0D8B211',
    name: 'chevron-triple-left',
    codepoint: 'F0DBA',
    deprecated: 'FALSE',
  },
  {
    id: 'F80C6B02-D422-44FE-9199-B9E12EF523C8',
    name: 'chevron-triple-right',
    codepoint: 'F0DBB',
    deprecated: 'FALSE',
  },
  {
    id: 'C04D4C82-E95C-45E1-8582-89A24536026F',
    name: 'chevron-triple-up',
    codepoint: 'F0DBC',
    deprecated: 'FALSE',
  },
  {
    id: 'AECCC94F-C38A-4A97-879E-ACD09F89169A',
    name: 'chevron-up',
    codepoint: 'F0143',
    deprecated: 'FALSE',
  },
  {
    id: 'C721C684-43CC-4B3B-B91A-A574D5215FF3',
    name: 'chevron-up-box',
    codepoint: 'F09DC',
    deprecated: 'FALSE',
  },
  {
    id: '7660602A-34FB-4A1A-818B-A086CA1783BD',
    name: 'chevron-up-box-outline',
    codepoint: 'F09DD',
    deprecated: 'FALSE',
  },
  {
    id: '981C36F0-6F33-4A92-B20E-66E70BF474FA',
    name: 'chevron-up-circle',
    codepoint: 'F0B2C',
    deprecated: 'FALSE',
  },
  {
    id: '625319CF-2277-486A-9FB6-902F3F3FE212',
    name: 'chevron-up-circle-outline',
    codepoint: 'F0B2D',
    deprecated: 'FALSE',
  },
  {
    id: 'E39C0619-71BD-4EE7-932C-2025F7174550',
    name: 'chili-alert',
    codepoint: 'F17EA',
    deprecated: 'FALSE',
  },
  {
    id: '783609FD-2EDD-4273-9524-6FB6CAD6697B',
    name: 'chili-alert-outline',
    codepoint: 'F17EB',
    deprecated: 'FALSE',
  },
  {
    id: 'E868226C-7BE8-4A22-8A9A-8E040004016A',
    name: 'chili-hot',
    codepoint: 'F07B2',
    deprecated: 'FALSE',
  },
  {
    id: 'AB432B2A-F29D-4F6D-B6E3-46C19C544B40',
    name: 'chili-hot-outline',
    codepoint: 'F17EC',
    deprecated: 'FALSE',
  },
  {
    id: 'C9DA0BF5-09DD-46A9-AE62-1612B26F0BB1',
    name: 'chili-medium',
    codepoint: 'F07B3',
    deprecated: 'FALSE',
  },
  {
    id: '6C57AD0C-11EA-4710-87D4-462B94821A4C',
    name: 'chili-medium-outline',
    codepoint: 'F17ED',
    deprecated: 'FALSE',
  },
  {
    id: '3CE5B420-F103-47EA-800C-A4D145B89508',
    name: 'chili-mild',
    codepoint: 'F07B4',
    deprecated: 'FALSE',
  },
  {
    id: '21ECB1D3-C620-4FEF-A6A9-4210D859DE1F',
    name: 'chili-mild-outline',
    codepoint: 'F17EE',
    deprecated: 'FALSE',
  },
  {
    id: 'F92E010E-F45D-44CE-8F13-F6419010B0D2',
    name: 'chili-off',
    codepoint: 'F1467',
    deprecated: 'FALSE',
  },
  {
    id: '64F2DC11-3647-4EEC-AADF-3EAE1AA47B65',
    name: 'chili-off-outline',
    codepoint: 'F17EF',
    deprecated: 'FALSE',
  },
  {
    id: 'A535271B-1114-44AD-9D9D-1984905026FF',
    name: 'chip',
    codepoint: 'F061A',
    deprecated: 'FALSE',
  },
  {
    id: '82E4FDFE-C9C8-4087-ABCC-DF51F760379A',
    name: 'church',
    codepoint: 'F0144',
    deprecated: 'FALSE',
  },
  {
    id: '4430C123-70EA-4AC2-BEC4-5919786D4EBC',
    name: 'church-outline',
    codepoint: 'F1B02',
    deprecated: 'FALSE',
  },
  {
    id: '4E50E8E9-4283-4B08-9AB2-6283088898BB',
    name: 'cigar',
    codepoint: 'F1189',
    deprecated: 'FALSE',
  },
  {
    id: 'BC451ECE-B21F-43F6-AE1C-D9E3614E4406',
    name: 'cigar-off',
    codepoint: 'F141B',
    deprecated: 'FALSE',
  },
  {
    id: '59803983-F6CE-4D40-B83C-36D42C9464A9',
    name: 'circle',
    codepoint: 'F0765',
    deprecated: 'FALSE',
  },
  {
    id: '3E57AA64-D7C2-4C4E-BE87-58A6D775BC21',
    name: 'circle-box',
    codepoint: 'F15DC',
    deprecated: 'FALSE',
  },
  {
    id: '4A709F16-7813-44DA-AB19-EBEBB4A6718A',
    name: 'circle-box-outline',
    codepoint: 'F15DD',
    deprecated: 'FALSE',
  },
  {
    id: '02A447DB-D9DB-4568-B839-841F1401FD31',
    name: 'circle-double',
    codepoint: 'F0E95',
    deprecated: 'FALSE',
  },
  {
    id: '7C0E615F-A0DC-4689-A094-4E59D27675B3',
    name: 'circle-edit-outline',
    codepoint: 'F08D5',
    deprecated: 'FALSE',
  },
  {
    id: '6262A121-B53E-4429-8E8E-F05A6820EDC7',
    name: 'circle-expand',
    codepoint: 'F0E96',
    deprecated: 'FALSE',
  },
  {
    id: '901AA3C4-B9A4-4764-ABA4-FD51FA6D68ED',
    name: 'circle-half',
    codepoint: 'F1395',
    deprecated: 'FALSE',
  },
  {
    id: 'D066B111-84BC-4682-864B-CAFEE4A93F66',
    name: 'circle-half-full',
    codepoint: 'F1396',
    deprecated: 'FALSE',
  },
  {
    id: 'AB8D0574-712C-4403-A5A5-51E16C081605',
    name: 'circle-medium',
    codepoint: 'F09DE',
    deprecated: 'FALSE',
  },
  {
    id: 'C05A4C2F-8128-474F-B204-D782A332CB73',
    name: 'circle-multiple',
    codepoint: 'F0B38',
    deprecated: 'FALSE',
  },
  {
    id: 'BAD0A147-6CA0-4BCF-88DF-8E8A4C247B0E',
    name: 'circle-multiple-outline',
    codepoint: 'F0695',
    deprecated: 'FALSE',
  },
  {
    id: '5DC46127-B07A-4E2B-B3BA-A98C0D2942F4',
    name: 'circle-off-outline',
    codepoint: 'F10D3',
    deprecated: 'FALSE',
  },
  {
    id: '912F4CEF-F852-49C4-B9BB-A6504988C11E',
    name: 'circle-opacity',
    codepoint: 'F1853',
    deprecated: 'FALSE',
  },
  {
    id: '00E1BEF8-DD84-4DCE-BE3C-8AA411DCE5FD',
    name: 'circle-outline',
    codepoint: 'F0766',
    deprecated: 'FALSE',
  },
  {
    id: '40F6544B-8BBE-44AD-85A2-FDFA1AD8091E',
    name: 'circle-slice-1',
    codepoint: 'F0A9E',
    deprecated: 'FALSE',
  },
  {
    id: '520A592A-21BB-4D8C-835F-BEF1EF0B73D0',
    name: 'circle-slice-2',
    codepoint: 'F0A9F',
    deprecated: 'FALSE',
  },
  {
    id: 'FE0AA2ED-07F6-4BC4-876E-1FA6BA283130',
    name: 'circle-slice-3',
    codepoint: 'F0AA0',
    deprecated: 'FALSE',
  },
  {
    id: '12C46927-0235-4A3A-965B-287879A4C2E3',
    name: 'circle-slice-4',
    codepoint: 'F0AA1',
    deprecated: 'FALSE',
  },
  {
    id: '4AA85ADE-6180-453B-A62E-2819E92E779A',
    name: 'circle-slice-5',
    codepoint: 'F0AA2',
    deprecated: 'FALSE',
  },
  {
    id: '658050F8-5C9A-4C5C-BE99-8EB492524586',
    name: 'circle-slice-6',
    codepoint: 'F0AA3',
    deprecated: 'FALSE',
  },
  {
    id: 'A051B0B2-A0CB-4E51-87F8-242EA4A4777F',
    name: 'circle-slice-7',
    codepoint: 'F0AA4',
    deprecated: 'FALSE',
  },
  {
    id: 'CB0FC186-2B53-4802-A9DB-98CF07B78CF1',
    name: 'circle-slice-8',
    codepoint: 'F0AA5',
    deprecated: 'FALSE',
  },
  {
    id: '0EA34D8C-1A02-4A92-8293-991702ABD065',
    name: 'circle-small',
    codepoint: 'F09DF',
    deprecated: 'FALSE',
  },
  {
    id: '4CC37629-C3B9-4F3B-BA36-4B93A3CDE297',
    name: 'circular-saw',
    codepoint: 'F0E22',
    deprecated: 'FALSE',
  },
  {
    id: '5A5AC571-1818-45D0-8259-A9177DBED615',
    name: 'city',
    codepoint: 'F0146',
    deprecated: 'FALSE',
  },
  {
    id: '2F6976DF-1008-496D-802D-917F6EA5F7DB',
    name: 'city-variant',
    codepoint: 'F0A36',
    deprecated: 'FALSE',
  },
  {
    id: '1DECCCEC-93F2-4BD8-BEFD-370D5020DCDB',
    name: 'city-variant-outline',
    codepoint: 'F0A37',
    deprecated: 'FALSE',
  },
  {
    id: '423D4239-D8DC-4D8B-9365-9D51E2A0FDF7',
    name: 'clipboard',
    codepoint: 'F0147',
    deprecated: 'FALSE',
  },
  {
    id: 'C45D118E-4FFA-42E5-A18B-B8A7B58BF42F',
    name: 'clipboard-account',
    codepoint: 'F0148',
    deprecated: 'FALSE',
  },
  {
    id: '80D8F0AA-0B3A-47ED-B342-8681E0DD7450',
    name: 'clipboard-account-outline',
    codepoint: 'F0C55',
    deprecated: 'FALSE',
  },
  {
    id: '57AC4C5E-B2AB-42B5-9826-BCFEACCB2E7C',
    name: 'clipboard-alert',
    codepoint: 'F0149',
    deprecated: 'FALSE',
  },
  {
    id: '321F2AC5-C710-4AD5-A493-279435DF0074',
    name: 'clipboard-alert-outline',
    codepoint: 'F0CF7',
    deprecated: 'FALSE',
  },
  {
    id: '863E0318-495E-437A-B22B-3C1274D108E6',
    name: 'clipboard-arrow-down',
    codepoint: 'F014A',
    deprecated: 'FALSE',
  },
  {
    id: '0FB11B14-8890-432D-A4B0-7D359DE681D8',
    name: 'clipboard-arrow-down-outline',
    codepoint: 'F0C56',
    deprecated: 'FALSE',
  },
  {
    id: '1D6DD9E5-61EA-40E6-A19A-EF4BA71B0FC0',
    name: 'clipboard-arrow-left',
    codepoint: 'F014B',
    deprecated: 'FALSE',
  },
  {
    id: '38CF4CD8-7203-4E66-BAD3-A3A7B4900BD4',
    name: 'clipboard-arrow-left-outline',
    codepoint: 'F0CF8',
    deprecated: 'FALSE',
  },
  {
    id: '4BD76170-F83F-4145-982F-3F1EBAD22D59',
    name: 'clipboard-arrow-right',
    codepoint: 'F0CF9',
    deprecated: 'FALSE',
  },
  {
    id: '7DB95067-893D-4472-8D74-79E521AE0DD1',
    name: 'clipboard-arrow-right-outline',
    codepoint: 'F0CFA',
    deprecated: 'FALSE',
  },
  {
    id: '5F084570-4F64-4A94-A762-602350ACEDAA',
    name: 'clipboard-arrow-up',
    codepoint: 'F0C57',
    deprecated: 'FALSE',
  },
  {
    id: '15ED4DA3-08C5-4913-9FEB-A88CEED7DDE9',
    name: 'clipboard-arrow-up-outline',
    codepoint: 'F0C58',
    deprecated: 'FALSE',
  },
  {
    id: '2FA574EF-C285-465F-A254-868506FA4273',
    name: 'clipboard-check',
    codepoint: 'F014E',
    deprecated: 'FALSE',
  },
  {
    id: '6DBE3F30-C444-443C-A515-75657C468338',
    name: 'clipboard-check-multiple',
    codepoint: 'F1263',
    deprecated: 'FALSE',
  },
  {
    id: '2FB7020B-64CC-4CA8-A46E-0D3E5B65A697',
    name: 'clipboard-check-multiple-outline',
    codepoint: 'F1264',
    deprecated: 'FALSE',
  },
  {
    id: '6A0DFB73-2DBF-4D3C-8C6B-2B4758B11782',
    name: 'clipboard-check-outline',
    codepoint: 'F08A8',
    deprecated: 'FALSE',
  },
  {
    id: 'E276DDBC-9E61-4CE3-8168-6E896E6ADD98',
    name: 'clipboard-clock',
    codepoint: 'F16E2',
    deprecated: 'FALSE',
  },
  {
    id: 'D9132053-ADBE-417D-A00B-207B8F1C85A7',
    name: 'clipboard-clock-outline',
    codepoint: 'F16E3',
    deprecated: 'FALSE',
  },
  {
    id: 'BA08B631-B169-4C0F-A99B-BBECC9F18CAA',
    name: 'clipboard-edit',
    codepoint: 'F14E5',
    deprecated: 'FALSE',
  },
  {
    id: 'E316EB8B-FF99-4B85-AE09-C7E5A9656738',
    name: 'clipboard-edit-outline',
    codepoint: 'F14E6',
    deprecated: 'FALSE',
  },
  {
    id: 'CE6923D5-218F-4817-B904-E03A842CE696',
    name: 'clipboard-file',
    codepoint: 'F1265',
    deprecated: 'FALSE',
  },
  {
    id: 'B9BE1636-2925-4AA2-B21A-1EE197FA7774',
    name: 'clipboard-file-outline',
    codepoint: 'F1266',
    deprecated: 'FALSE',
  },
  {
    id: '5A450EF8-D2F0-4F32-8D10-A606DD847E19',
    name: 'clipboard-flow',
    codepoint: 'F06C8',
    deprecated: 'FALSE',
  },
  {
    id: '5EA9A77D-401F-48C8-B804-EE8B9A7CB229',
    name: 'clipboard-flow-outline',
    codepoint: 'F1117',
    deprecated: 'FALSE',
  },
  {
    id: '754100C6-667F-49AD-A5C5-0FF2AD94C6DF',
    name: 'clipboard-list',
    codepoint: 'F10D4',
    deprecated: 'FALSE',
  },
  {
    id: '9169E4CD-137E-4743-96A7-80B5764630FF',
    name: 'clipboard-list-outline',
    codepoint: 'F10D5',
    deprecated: 'FALSE',
  },
  {
    id: '7C5309A2-5DA1-4393-8846-0CF5CB43BF88',
    name: 'clipboard-minus',
    codepoint: 'F1618',
    deprecated: 'FALSE',
  },
  {
    id: 'F83888C2-F7CF-423E-9101-2D9A49A6597B',
    name: 'clipboard-minus-outline',
    codepoint: 'F1619',
    deprecated: 'FALSE',
  },
  {
    id: '8CD0EB09-AC6D-43C7-8DD6-4F2FD9A1DBC5',
    name: 'clipboard-multiple',
    codepoint: 'F1267',
    deprecated: 'FALSE',
  },
  {
    id: '81D9B376-8054-4DB5-BA79-274E4B3EF79D',
    name: 'clipboard-multiple-outline',
    codepoint: 'F1268',
    deprecated: 'FALSE',
  },
  {
    id: '376FB5C9-DF1A-455B-9824-5D0A3CE61481',
    name: 'clipboard-off',
    codepoint: 'F161A',
    deprecated: 'FALSE',
  },
  {
    id: '86166B5D-05DD-423B-B10E-E67010AEBE58',
    name: 'clipboard-off-outline',
    codepoint: 'F161B',
    deprecated: 'FALSE',
  },
  {
    id: '54F34575-7348-4032-B072-EC8F9A6C843D',
    name: 'clipboard-outline',
    codepoint: 'F014C',
    deprecated: 'FALSE',
  },
  {
    id: 'F0E26160-95CF-4270-8FD2-134391B88CA8',
    name: 'clipboard-play',
    codepoint: 'F0C59',
    deprecated: 'FALSE',
  },
  {
    id: 'E78AF95D-A3B1-483C-9F46-B503AEF6B4C0',
    name: 'clipboard-play-multiple',
    codepoint: 'F1269',
    deprecated: 'FALSE',
  },
  {
    id: '2FCD745F-4980-4CAA-A51D-94574CAB1DA4',
    name: 'clipboard-play-multiple-outline',
    codepoint: 'F126A',
    deprecated: 'FALSE',
  },
  {
    id: 'B2D48D43-940B-4758-A9E1-00E13202B239',
    name: 'clipboard-play-outline',
    codepoint: 'F0C5A',
    deprecated: 'FALSE',
  },
  {
    id: 'A5AA9DEA-AAAE-4C79-965F-6828F823E2CD',
    name: 'clipboard-plus',
    codepoint: 'F0751',
    deprecated: 'FALSE',
  },
  {
    id: '4662F629-FEF3-4F7A-A901-56FF827CF051',
    name: 'clipboard-plus-outline',
    codepoint: 'F131F',
    deprecated: 'FALSE',
  },
  {
    id: '8E3D93CC-DE0A-4828-AC9A-9A6CFD932978',
    name: 'clipboard-pulse',
    codepoint: 'F085D',
    deprecated: 'FALSE',
  },
  {
    id: '8D6CA1DD-35D6-4E8E-BDA0-6192728A24C4',
    name: 'clipboard-pulse-outline',
    codepoint: 'F085E',
    deprecated: 'FALSE',
  },
  {
    id: '474C3746-90C6-456C-9B18-6101FFC8075B',
    name: 'clipboard-remove',
    codepoint: 'F161C',
    deprecated: 'FALSE',
  },
  {
    id: '57C00F9A-7204-402F-9AF9-908A02C23681',
    name: 'clipboard-remove-outline',
    codepoint: 'F161D',
    deprecated: 'FALSE',
  },
  {
    id: '785666E6-8CB0-4875-A289-1A9329614E69',
    name: 'clipboard-search',
    codepoint: 'F161E',
    deprecated: 'FALSE',
  },
  {
    id: '9D8E08D1-6F72-4729-AF64-ED099D109B7D',
    name: 'clipboard-search-outline',
    codepoint: 'F161F',
    deprecated: 'FALSE',
  },
  {
    id: '81453216-6EC0-4751-82A4-BFFF63B922FC',
    name: 'clipboard-text',
    codepoint: 'F014D',
    deprecated: 'FALSE',
  },
  {
    id: 'EEFDB836-DFC2-43ED-9246-05B972EA2B57',
    name: 'clipboard-text-clock',
    codepoint: 'F18F9',
    deprecated: 'FALSE',
  },
  {
    id: '9A710A09-DB02-441E-A5E6-204937893D50',
    name: 'clipboard-text-clock-outline',
    codepoint: 'F18FA',
    deprecated: 'FALSE',
  },
  {
    id: '37090A31-A50D-43AB-92C4-BFB2C17273E6',
    name: 'clipboard-text-multiple',
    codepoint: 'F126B',
    deprecated: 'FALSE',
  },
  {
    id: '4FF17874-C828-4EA8-9F4D-BEAEB614D69F',
    name: 'clipboard-text-multiple-outline',
    codepoint: 'F126C',
    deprecated: 'FALSE',
  },
  {
    id: '237A2797-5013-4A50-9CB9-59FFEEC5BE4E',
    name: 'clipboard-text-off',
    codepoint: 'F1620',
    deprecated: 'FALSE',
  },
  {
    id: 'A5B09C9E-6430-4F6E-BA1C-D855ED6AB74C',
    name: 'clipboard-text-off-outline',
    codepoint: 'F1621',
    deprecated: 'FALSE',
  },
  {
    id: '9C5136D5-E614-4DBD-AB36-EBB044178B6D',
    name: 'clipboard-text-outline',
    codepoint: 'F0A38',
    deprecated: 'FALSE',
  },
  {
    id: '15B53AD7-FB72-4D70-8F70-B2259281EA2B',
    name: 'clipboard-text-play',
    codepoint: 'F0C5B',
    deprecated: 'FALSE',
  },
  {
    id: 'A1587F0D-C189-4886-9CB0-FBA5E3FA70E6',
    name: 'clipboard-text-play-outline',
    codepoint: 'F0C5C',
    deprecated: 'FALSE',
  },
  {
    id: '347494DF-0250-4F0E-9E64-23CB8F35B8C2',
    name: 'clipboard-text-search',
    codepoint: 'F1622',
    deprecated: 'FALSE',
  },
  {
    id: '1E23BABF-F9E8-42C6-9199-0B3B6C43C290',
    name: 'clipboard-text-search-outline',
    codepoint: 'F1623',
    deprecated: 'FALSE',
  },
  {
    id: '6B346FB6-F563-4AC4-B769-06A143B42152',
    name: 'clippy',
    codepoint: 'F014F',
    deprecated: 'FALSE',
  },
  {
    id: '90E7BBD9-12C3-4339-94DE-99A049C8D4B1',
    name: 'clock',
    codepoint: 'F0954',
    deprecated: 'FALSE',
  },
  {
    id: 'A4250284-2784-405C-A62F-E6C546D66777',
    name: 'clock-alert',
    codepoint: 'F0955',
    deprecated: 'FALSE',
  },
  {
    id: 'CB400FB2-95E5-4420-B579-80DCA5C0F8B0',
    name: 'clock-alert-outline',
    codepoint: 'F05CE',
    deprecated: 'FALSE',
  },
  {
    id: '46C840CA-A3F7-4293-8ABC-28C285723A8A',
    name: 'clock-check',
    codepoint: 'F0FA8',
    deprecated: 'FALSE',
  },
  {
    id: 'DC1289EA-B14C-403D-9A76-4C28CB042FAA',
    name: 'clock-check-outline',
    codepoint: 'F0FA9',
    deprecated: 'FALSE',
  },
  {
    id: 'EFE4304F-7BAB-4DB6-846C-31B368CA3BDC',
    name: 'clock-digital',
    codepoint: 'F0E97',
    deprecated: 'FALSE',
  },
  {
    id: '2F115531-58CA-4BC0-A271-C8202C4ADE37',
    name: 'clock-edit',
    codepoint: 'F19BA',
    deprecated: 'FALSE',
  },
  {
    id: 'E528826E-0ACC-494C-AFA3-B0AFF7FD802A',
    name: 'clock-edit-outline',
    codepoint: 'F19BB',
    deprecated: 'FALSE',
  },
  {
    id: 'F7F5CA75-E8B5-45E1-B0BA-040F225D877D',
    name: 'clock-end',
    codepoint: 'F0151',
    deprecated: 'FALSE',
  },
  {
    id: '111210EB-0EF4-4DCB-ABBD-6A75F15EEB44',
    name: 'clock-fast',
    codepoint: 'F0152',
    deprecated: 'FALSE',
  },
  {
    id: '6483D8DB-4170-4104-8A69-40D28401EF4C',
    name: 'clock-in',
    codepoint: 'F0153',
    deprecated: 'FALSE',
  },
  {
    id: '009901AA-479B-419A-89F8-DA4453B5991B',
    name: 'clock-minus',
    codepoint: 'F1863',
    deprecated: 'FALSE',
  },
  {
    id: 'F7112A68-9EE1-4B5E-AD2B-30013EA7053A',
    name: 'clock-minus-outline',
    codepoint: 'F1864',
    deprecated: 'FALSE',
  },
  {
    id: '7C3F10AF-CB56-4A35-B22A-C833D5C823A3',
    name: 'clock-out',
    codepoint: 'F0154',
    deprecated: 'FALSE',
  },
  {
    id: 'E3A4F70D-92EF-4115-BBB3-3BE68A788F5D',
    name: 'clock-outline',
    codepoint: 'F0150',
    deprecated: 'FALSE',
  },
  {
    id: '60639801-D950-4C9E-86B7-E8C2928F356D',
    name: 'clock-plus',
    codepoint: 'F1861',
    deprecated: 'FALSE',
  },
  {
    id: 'DCE6FA3E-EEB6-4AB9-B4D7-60F71B92A489',
    name: 'clock-plus-outline',
    codepoint: 'F1862',
    deprecated: 'FALSE',
  },
  {
    id: '6B8FD378-5837-45BF-A5A6-B94C37641451',
    name: 'clock-remove',
    codepoint: 'F1865',
    deprecated: 'FALSE',
  },
  {
    id: '29131074-EFF5-4A47-88A2-D856CB92432F',
    name: 'clock-remove-outline',
    codepoint: 'F1866',
    deprecated: 'FALSE',
  },
  {
    id: '85A4435A-CDCF-42BC-8F2C-B46EDF8820BD',
    name: 'clock-start',
    codepoint: 'F0155',
    deprecated: 'FALSE',
  },
  {
    id: 'A215B597-8863-41CD-93D7-3258F35E0323',
    name: 'clock-time-eight',
    codepoint: 'F1446',
    deprecated: 'FALSE',
  },
  {
    id: 'BD5AEE68-BAA7-498A-9C16-E4BAEEC10758',
    name: 'clock-time-eight-outline',
    codepoint: 'F1452',
    deprecated: 'FALSE',
  },
  {
    id: 'F00F4BD4-FA53-4F36-A6EF-20C61E728DE4',
    name: 'clock-time-eleven',
    codepoint: 'F1449',
    deprecated: 'FALSE',
  },
  {
    id: 'A157DC0E-2973-49C5-83A6-A59B395000B0',
    name: 'clock-time-eleven-outline',
    codepoint: 'F1455',
    deprecated: 'FALSE',
  },
  {
    id: '5EADCD37-E186-4701-B55D-490373E248DE',
    name: 'clock-time-five',
    codepoint: 'F1443',
    deprecated: 'FALSE',
  },
  {
    id: '8F93B149-9B68-43A0-9DB6-BCE3AF32DA17',
    name: 'clock-time-five-outline',
    codepoint: 'F144F',
    deprecated: 'FALSE',
  },
  {
    id: '5850EAB7-9808-4FC7-B107-ECBC0D74321A',
    name: 'clock-time-four',
    codepoint: 'F1442',
    deprecated: 'FALSE',
  },
  {
    id: '3EB1620A-C31A-4570-A0CA-4D4B1194B5FC',
    name: 'clock-time-four-outline',
    codepoint: 'F144E',
    deprecated: 'FALSE',
  },
  {
    id: 'DA361B2F-72DD-46ED-9920-558A9AD81B3B',
    name: 'clock-time-nine',
    codepoint: 'F1447',
    deprecated: 'FALSE',
  },
  {
    id: 'B3B33DCF-9627-4286-8D43-674E066D5CF8',
    name: 'clock-time-nine-outline',
    codepoint: 'F1453',
    deprecated: 'FALSE',
  },
  {
    id: 'FE16FC41-411F-423D-96AC-835A334051DF',
    name: 'clock-time-one',
    codepoint: 'F143F',
    deprecated: 'FALSE',
  },
  {
    id: 'CD2F6CA1-A725-4997-9EDF-7CDD3875997D',
    name: 'clock-time-one-outline',
    codepoint: 'F144B',
    deprecated: 'FALSE',
  },
  {
    id: '814B2984-F25E-40EE-98E7-9CDCE300AA0C',
    name: 'clock-time-seven',
    codepoint: 'F1445',
    deprecated: 'FALSE',
  },
  {
    id: '0249D6C9-BACD-4514-96DB-6ABF4C8756C4',
    name: 'clock-time-seven-outline',
    codepoint: 'F1451',
    deprecated: 'FALSE',
  },
  {
    id: '58E0112F-0E05-4C53-8AF2-663009045010',
    name: 'clock-time-six',
    codepoint: 'F1444',
    deprecated: 'FALSE',
  },
  {
    id: 'D8D8CBCB-DCCF-4FB0-A6E2-3D45E732AD90',
    name: 'clock-time-six-outline',
    codepoint: 'F1450',
    deprecated: 'FALSE',
  },
  {
    id: '5EE154C9-B581-4AB9-A086-38AE96A919E1',
    name: 'clock-time-ten',
    codepoint: 'F1448',
    deprecated: 'FALSE',
  },
  {
    id: 'DDACEAB9-676D-4D4A-9508-CE1241D983BC',
    name: 'clock-time-ten-outline',
    codepoint: 'F1454',
    deprecated: 'FALSE',
  },
  {
    id: 'DA0656E4-D609-441D-93C3-488D36BB9BDA',
    name: 'clock-time-three',
    codepoint: 'F1441',
    deprecated: 'FALSE',
  },
  {
    id: '465F6D88-CD51-4BA8-AFBE-0D20845906DD',
    name: 'clock-time-three-outline',
    codepoint: 'F144D',
    deprecated: 'FALSE',
  },
  {
    id: 'CEA21098-6201-4E2F-A24E-865B4FFD7870',
    name: 'clock-time-twelve',
    codepoint: 'F144A',
    deprecated: 'FALSE',
  },
  {
    id: '375B6D36-CFC4-4DB4-BFDE-364E129EDFBB',
    name: 'clock-time-twelve-outline',
    codepoint: 'F1456',
    deprecated: 'FALSE',
  },
  {
    id: '3F6D9697-9536-4D51-87D3-839E7579451B',
    name: 'clock-time-two',
    codepoint: 'F1440',
    deprecated: 'FALSE',
  },
  {
    id: '20918EE1-9806-4E1D-BD7C-A723AC2A97FF',
    name: 'clock-time-two-outline',
    codepoint: 'F144C',
    deprecated: 'FALSE',
  },
  {
    id: 'FEA26660-7D6A-4C2A-9F13-7AE125FBA543',
    name: 'close',
    codepoint: 'F0156',
    deprecated: 'FALSE',
  },
  {
    id: '0823D18B-529A-4B7E-8090-A913399A3D5E',
    name: 'close-box',
    codepoint: 'F0157',
    deprecated: 'FALSE',
  },
  {
    id: '63F3E252-98F2-4851-A887-E99FACD6B280',
    name: 'close-box-multiple',
    codepoint: 'F0C5D',
    deprecated: 'FALSE',
  },
  {
    id: '596F26BB-8E10-4530-98CE-FFDACBF07F6B',
    name: 'close-box-multiple-outline',
    codepoint: 'F0C5E',
    deprecated: 'FALSE',
  },
  {
    id: '05B8E566-B224-4ECC-AE70-2BF8BE90C82A',
    name: 'close-box-outline',
    codepoint: 'F0158',
    deprecated: 'FALSE',
  },
  {
    id: 'DC0E1491-A8A0-4563-ACEC-C795D96602A7',
    name: 'close-circle',
    codepoint: 'F0159',
    deprecated: 'FALSE',
  },
  {
    id: '77B97AED-2613-434C-BD9C-82755C8B468C',
    name: 'close-circle-multiple',
    codepoint: 'F062A',
    deprecated: 'FALSE',
  },
  {
    id: 'B77F1750-F3D8-43A5-A2D1-EEF2E3EB584B',
    name: 'close-circle-multiple-outline',
    codepoint: 'F0883',
    deprecated: 'FALSE',
  },
  {
    id: 'EA41E6BB-A176-4E0E-896A-36F9FA5AA62F',
    name: 'close-circle-outline',
    codepoint: 'F015A',
    deprecated: 'FALSE',
  },
  {
    id: '4043C369-E619-47E9-9363-E5E9C43457FB',
    name: 'close-network',
    codepoint: 'F015B',
    deprecated: 'FALSE',
  },
  {
    id: '6BB0D686-C2A2-47D0-A50C-91E0300AF12E',
    name: 'close-network-outline',
    codepoint: 'F0C5F',
    deprecated: 'FALSE',
  },
  {
    id: 'BA7E9F56-A5F5-4BEE-8934-90CA070A6029',
    name: 'close-octagon',
    codepoint: 'F015C',
    deprecated: 'FALSE',
  },
  {
    id: '9676C4C3-D8C3-44A6-A447-59A56A15DA34',
    name: 'close-octagon-outline',
    codepoint: 'F015D',
    deprecated: 'FALSE',
  },
  {
    id: '2FEE0020-4271-487C-897D-75529B20C4C6',
    name: 'close-outline',
    codepoint: 'F06C9',
    deprecated: 'FALSE',
  },
  {
    id: 'C6B8442F-446D-4690-866F-AF0F27C1EAB2',
    name: 'close-thick',
    codepoint: 'F1398',
    deprecated: 'FALSE',
  },
  {
    id: '5367B460-F7E5-46C5-A4F5-40B339BF9DAD',
    name: 'closed-caption',
    codepoint: 'F015E',
    deprecated: 'FALSE',
  },
  {
    id: '08F32D33-77C4-4FEB-AAF6-04468AF0875F',
    name: 'closed-caption-outline',
    codepoint: 'F0DBD',
    deprecated: 'FALSE',
  },
  {
    id: 'CF07C502-388E-4A65-BD89-FACCFD00C245',
    name: 'cloud',
    codepoint: 'F015F',
    deprecated: 'FALSE',
  },
  {
    id: '4ACCBA06-78DB-492F-98F6-08AEE743744A',
    name: 'cloud-alert',
    codepoint: 'F09E0',
    deprecated: 'FALSE',
  },
  {
    id: '3FC7BE84-D3FE-4DF8-88D2-19E85A7BF661',
    name: 'cloud-alert-outline',
    codepoint: 'F1BE0',
    deprecated: 'FALSE',
  },
  {
    id: '19C8B174-393F-4E5B-ADC4-C5598DE914F8',
    name: 'cloud-arrow-down',
    codepoint: 'F1BE1',
    deprecated: 'FALSE',
  },
  {
    id: '5F0D6116-B303-4C57-82F0-224ADA97EBD5',
    name: 'cloud-arrow-down-outline',
    codepoint: 'F1BE2',
    deprecated: 'FALSE',
  },
  {
    id: '6FD0F6B8-7E48-4EAB-99C4-AFD58D95E942',
    name: 'cloud-arrow-left',
    codepoint: 'F1BE3',
    deprecated: 'FALSE',
  },
  {
    id: 'DD5C0F60-69F6-469D-A285-3527C9CBCEC9',
    name: 'cloud-arrow-left-outline',
    codepoint: 'F1BE4',
    deprecated: 'FALSE',
  },
  {
    id: '3B3EF4D2-339A-43D7-AAEE-394D74AA7C85',
    name: 'cloud-arrow-right',
    codepoint: 'F1BE5',
    deprecated: 'FALSE',
  },
  {
    id: '043852F0-723D-45FF-8C83-D7C87009089F',
    name: 'cloud-arrow-right-outline',
    codepoint: 'F1BE6',
    deprecated: 'FALSE',
  },
  {
    id: '043AFCE7-AA8A-4E51-AD77-96957407218E',
    name: 'cloud-arrow-up',
    codepoint: 'F1BE7',
    deprecated: 'FALSE',
  },
  {
    id: 'B1E36C6D-4638-4FE5-854E-B6E3DCB6D6D1',
    name: 'cloud-arrow-up-outline',
    codepoint: 'F1BE8',
    deprecated: 'FALSE',
  },
  {
    id: '6ED02FDD-323E-4004-9D7B-336F2402DF8E',
    name: 'cloud-braces',
    codepoint: 'F07B5',
    deprecated: 'FALSE',
  },
  {
    id: '8F5247BF-407F-4460-9C6E-3C7A467DF01A',
    name: 'cloud-cancel',
    codepoint: 'F1BE9',
    deprecated: 'FALSE',
  },
  {
    id: '52D9AC34-84C1-4DF6-A173-3F7726954A71',
    name: 'cloud-cancel-outline',
    codepoint: 'F1BEA',
    deprecated: 'FALSE',
  },
  {
    id: '29F10EFC-FC56-4D02-88E6-FC856F54390D',
    name: 'cloud-check',
    codepoint: 'F1BEB',
    deprecated: 'FALSE',
  },
  {
    id: 'B99F649D-4AB4-4A56-97EA-A52B6CE26B16',
    name: 'cloud-check-outline',
    codepoint: 'F1BEC',
    deprecated: 'FALSE',
  },
  {
    id: '5B607B90-B492-43AD-A5B0-4AC800A9F7DA',
    name: 'cloud-check-variant',
    codepoint: 'F0160',
    deprecated: 'FALSE',
  },
  {
    id: 'A56D0A20-FC19-455F-B1E8-3CA24270957A',
    name: 'cloud-check-variant-outline',
    codepoint: 'F12CC',
    deprecated: 'FALSE',
  },
  {
    id: '06BCD41B-8BFF-43C0-88A1-69F262C51C5F',
    name: 'cloud-circle',
    codepoint: 'F0161',
    deprecated: 'FALSE',
  },
  {
    id: '451E7715-6D54-47B1-9228-DBD53D8CBF07',
    name: 'cloud-circle-outline',
    codepoint: 'F1BED',
    deprecated: 'FALSE',
  },
  {
    id: 'F718D38F-3628-42E3-864B-787F62811754',
    name: 'cloud-clock',
    codepoint: 'F1BEE',
    deprecated: 'FALSE',
  },
  {
    id: '0BE13210-76BC-47EA-8506-E42859826A01',
    name: 'cloud-clock-outline',
    codepoint: 'F1BEF',
    deprecated: 'FALSE',
  },
  {
    id: 'DFCB974D-269A-4ADE-BFDF-58C4D12E947C',
    name: 'cloud-cog',
    codepoint: 'F1BF0',
    deprecated: 'FALSE',
  },
  {
    id: 'D03FD5FA-4F08-491D-8459-1EDFE1CED4C6',
    name: 'cloud-cog-outline',
    codepoint: 'F1BF1',
    deprecated: 'FALSE',
  },
  {
    id: 'D8C49CD8-61B2-4F34-A4B8-543396B4A610',
    name: 'cloud-download',
    codepoint: 'F0162',
    deprecated: 'FALSE',
  },
  {
    id: '41CC8340-22F2-4C91-BA1E-97E3A0EA461F',
    name: 'cloud-download-outline',
    codepoint: 'F0B7D',
    deprecated: 'FALSE',
  },
  {
    id: '8F0ED421-A552-4920-A46D-13F246A25689',
    name: 'cloud-lock',
    codepoint: 'F11F1',
    deprecated: 'FALSE',
  },
  {
    id: '5985650B-077C-4473-8749-A98E0F731EB9',
    name: 'cloud-lock-open',
    codepoint: 'F1BF2',
    deprecated: 'FALSE',
  },
  {
    id: '54073599-B365-465E-89D6-2081A5D0EB1F',
    name: 'cloud-lock-open-outline',
    codepoint: 'F1BF3',
    deprecated: 'FALSE',
  },
  {
    id: '9ADC05C4-6BC2-4D66-80B0-CB6677A08271',
    name: 'cloud-lock-outline',
    codepoint: 'F11F2',
    deprecated: 'FALSE',
  },
  {
    id: 'EC87820F-EE63-45B9-9F7C-6AC83EECCDA8',
    name: 'cloud-minus',
    codepoint: 'F1BF4',
    deprecated: 'FALSE',
  },
  {
    id: 'B6D110F4-1A69-4C3E-9045-E14E28AB1EF6',
    name: 'cloud-minus-outline',
    codepoint: 'F1BF5',
    deprecated: 'FALSE',
  },
  {
    id: 'EFE048B4-42B0-40BD-A7BB-E0E20FB0D80F',
    name: 'cloud-off',
    codepoint: 'F1BF6',
    deprecated: 'FALSE',
  },
  {
    id: 'E19C3062-FFE5-441C-91C8-B1792E3AA3CA',
    name: 'cloud-off-outline',
    codepoint: 'F0164',
    deprecated: 'FALSE',
  },
  {
    id: '6EBE020D-405B-4E36-8B47-CAC6FAF1166C',
    name: 'cloud-outline',
    codepoint: 'F0163',
    deprecated: 'FALSE',
  },
  {
    id: '63C6361B-587A-4EF6-9308-8E54DD1BB68E',
    name: 'cloud-percent',
    codepoint: 'F1A35',
    deprecated: 'FALSE',
  },
  {
    id: 'CB1A76AC-F998-48D8-A4DC-C29CD02CDFF7',
    name: 'cloud-percent-outline',
    codepoint: 'F1A36',
    deprecated: 'FALSE',
  },
  {
    id: '392BB6A6-E468-4F36-8F7B-8259754E80C4',
    name: 'cloud-plus',
    codepoint: 'F1BF7',
    deprecated: 'FALSE',
  },
  {
    id: '278039EE-3E18-4BBC-924E-F8785DA32520',
    name: 'cloud-plus-outline',
    codepoint: 'F1BF8',
    deprecated: 'FALSE',
  },
  {
    id: 'DB32F759-BA18-488B-9260-9A38529BE7A8',
    name: 'cloud-print',
    codepoint: 'F0165',
    deprecated: 'FALSE',
  },
  {
    id: '7B3F8F72-1F4B-429A-B969-8E42ADBBD3E1',
    name: 'cloud-print-outline',
    codepoint: 'F0166',
    deprecated: 'FALSE',
  },
  {
    id: 'C36FF6E2-EF4E-4FD1-9620-9580BC48B325',
    name: 'cloud-question',
    codepoint: 'F0A39',
    deprecated: 'FALSE',
  },
  {
    id: '9114B506-531D-4840-9803-E541F716995C',
    name: 'cloud-question-outline',
    codepoint: 'F1BF9',
    deprecated: 'FALSE',
  },
  {
    id: '546F9706-77E9-4C0A-9B7F-A035A0F1DB8C',
    name: 'cloud-refresh',
    codepoint: 'F1BFA',
    deprecated: 'FALSE',
  },
  {
    id: '59C1725E-CC9E-4AF1-B215-8FECD1E93A39',
    name: 'cloud-refresh-outline',
    codepoint: 'F1BFB',
    deprecated: 'FALSE',
  },
  {
    id: '1771EA83-96FC-4A06-8A03-E4048C9F9C27',
    name: 'cloud-refresh-variant',
    codepoint: 'F052A',
    deprecated: 'FALSE',
  },
  {
    id: '79C50275-0698-42E7-998B-3B0F8E093362',
    name: 'cloud-refresh-variant-outline',
    codepoint: 'F1BFC',
    deprecated: 'FALSE',
  },
  {
    id: '31A2A45B-65A1-46F4-BE9A-573F7469E390',
    name: 'cloud-remove',
    codepoint: 'F1BFD',
    deprecated: 'FALSE',
  },
  {
    id: 'DFA9EF34-6410-46FB-8126-F10449D71D31',
    name: 'cloud-remove-outline',
    codepoint: 'F1BFE',
    deprecated: 'FALSE',
  },
  {
    id: 'BF384FFB-7E5D-4C2E-ADC5-4BEEAF61A81C',
    name: 'cloud-search',
    codepoint: 'F0956',
    deprecated: 'FALSE',
  },
  {
    id: '5C72BE2A-A04D-4B36-9390-024C5A1214D9',
    name: 'cloud-search-outline',
    codepoint: 'F0957',
    deprecated: 'FALSE',
  },
  {
    id: 'BEA2DB85-AD43-4208-8F5E-5029997B23B7',
    name: 'cloud-sync',
    codepoint: 'F063F',
    deprecated: 'FALSE',
  },
  {
    id: 'FA6B7B54-7817-40EE-9A64-3D30EAC0F4CF',
    name: 'cloud-sync-outline',
    codepoint: 'F12D6',
    deprecated: 'FALSE',
  },
  {
    id: 'AE3A5033-BA77-4E82-BFD0-4C23275B602F',
    name: 'cloud-tags',
    codepoint: 'F07B6',
    deprecated: 'FALSE',
  },
  {
    id: 'BB4F16F6-B596-4725-8831-A794321542EB',
    name: 'cloud-upload',
    codepoint: 'F0167',
    deprecated: 'FALSE',
  },
  {
    id: '88ACA1F5-0FDE-4E89-8144-28BA5E5F1B51',
    name: 'cloud-upload-outline',
    codepoint: 'F0B7E',
    deprecated: 'FALSE',
  },
  {
    id: '43494F1E-8C56-4AEE-BB9F-AAE4BD44A6AE',
    name: 'clouds',
    codepoint: 'F1B95',
    deprecated: 'FALSE',
  },
  {
    id: '0B70716D-3C56-43F1-85D4-B264C05DCC32',
    name: 'clover',
    codepoint: 'F0816',
    deprecated: 'FALSE',
  },
  {
    id: '113768A1-5B09-41DD-9570-31434A34823D',
    name: 'coach-lamp',
    codepoint: 'F1020',
    deprecated: 'FALSE',
  },
  {
    id: 'D4A857FB-53DF-4726-9B49-986C83844E40',
    name: 'coach-lamp-variant',
    codepoint: 'F1A37',
    deprecated: 'FALSE',
  },
  {
    id: '21D89E60-3522-4F1F-A1D2-60E67B2C9552',
    name: 'coat-rack',
    codepoint: 'F109E',
    deprecated: 'FALSE',
  },
  {
    id: '04020C45-A5ED-4D33-A7BD-AE986516C0DE',
    name: 'code-array',
    codepoint: 'F0168',
    deprecated: 'FALSE',
  },
  {
    id: '1F6661BC-6538-4EF0-92B5-46EFAE5E0C77',
    name: 'code-braces',
    codepoint: 'F0169',
    deprecated: 'FALSE',
  },
  {
    id: 'A65C5C08-F94C-4A68-BA7B-5557C94C5C37',
    name: 'code-braces-box',
    codepoint: 'F10D6',
    deprecated: 'FALSE',
  },
  {
    id: 'C08498C1-E43B-4E79-817C-121AD2827689',
    name: 'code-brackets',
    codepoint: 'F016A',
    deprecated: 'FALSE',
  },
  {
    id: '16C07036-EE56-4447-8495-EB2FAD143072',
    name: 'code-equal',
    codepoint: 'F016B',
    deprecated: 'FALSE',
  },
  {
    id: '2C70B20E-4B20-4CF6-8775-339C1184E032',
    name: 'code-greater-than',
    codepoint: 'F016C',
    deprecated: 'FALSE',
  },
  {
    id: '3C9D2060-53C2-4D66-BE38-C46EE87D8D46',
    name: 'code-greater-than-or-equal',
    codepoint: 'F016D',
    deprecated: 'FALSE',
  },
  {
    id: '24C1AA84-85C2-41F4-A5E3-8FE7421CD359',
    name: 'code-json',
    codepoint: 'F0626',
    deprecated: 'FALSE',
  },
  {
    id: '92266B3A-6EC4-4CE7-9904-2A793B2A4084',
    name: 'code-less-than',
    codepoint: 'F016E',
    deprecated: 'FALSE',
  },
  {
    id: '3D003F4A-7BCB-4946-AE70-CCAA2B7D5B10',
    name: 'code-less-than-or-equal',
    codepoint: 'F016F',
    deprecated: 'FALSE',
  },
  {
    id: '1A01458F-59E8-4AF9-B398-8058900EE85F',
    name: 'code-not-equal',
    codepoint: 'F0170',
    deprecated: 'FALSE',
  },
  {
    id: '05E12366-E7AA-46B9-8D6E-3664EBD34BB3',
    name: 'code-not-equal-variant',
    codepoint: 'F0171',
    deprecated: 'FALSE',
  },
  {
    id: 'A4E1DE1B-754D-4884-9902-8D5DDE2182B0',
    name: 'code-parentheses',
    codepoint: 'F0172',
    deprecated: 'FALSE',
  },
  {
    id: '2609C9BB-3F62-432A-9D27-0D30845F8086',
    name: 'code-parentheses-box',
    codepoint: 'F10D7',
    deprecated: 'FALSE',
  },
  {
    id: '76686B91-00D8-4ADD-A502-8D1788C9C17B',
    name: 'code-string',
    codepoint: 'F0173',
    deprecated: 'FALSE',
  },
  {
    id: '609A0AB0-05EE-4824-BD40-A4E78F4A74D3',
    name: 'code-tags',
    codepoint: 'F0174',
    deprecated: 'FALSE',
  },
  {
    id: '7F098441-8B1D-4FB1-88EC-95726397BA7C',
    name: 'code-tags-check',
    codepoint: 'F0694',
    deprecated: 'FALSE',
  },
  {
    id: 'FB9F6D89-8A07-407E-AD97-AAEF0E022585',
    name: 'codepen',
    codepoint: 'F0175',
    deprecated: 'TRUE',
  },
  {
    id: 'C95D04C5-F5EE-411A-88F7-A9872B2B4021',
    name: 'coffee',
    codepoint: 'F0176',
    deprecated: 'FALSE',
  },
  {
    id: '62F4C783-4BE1-4EB6-B454-E41A50B91857',
    name: 'coffee-maker',
    codepoint: 'F109F',
    deprecated: 'FALSE',
  },
  {
    id: 'A82510E6-E7E7-4E2C-96A6-E041EFC72897',
    name: 'coffee-maker-check',
    codepoint: 'F1931',
    deprecated: 'FALSE',
  },
  {
    id: 'DDF7AAA6-C0A9-428C-9A2A-1B491714AF82',
    name: 'coffee-maker-check-outline',
    codepoint: 'F1932',
    deprecated: 'FALSE',
  },
  {
    id: '0E490D96-1352-4592-A544-F80D43681A9E',
    name: 'coffee-maker-outline',
    codepoint: 'F181B',
    deprecated: 'FALSE',
  },
  {
    id: 'E3C8FAA1-E273-44BC-8C43-1CB0E5E5C2AB',
    name: 'coffee-off',
    codepoint: 'F0FAA',
    deprecated: 'FALSE',
  },
  {
    id: 'B8D17AA8-7B65-4CA7-B9A9-6960C2A64164',
    name: 'coffee-off-outline',
    codepoint: 'F0FAB',
    deprecated: 'FALSE',
  },
  {
    id: '8994C73B-937C-4541-AAFF-6FA2CE0573DF',
    name: 'coffee-outline',
    codepoint: 'F06CA',
    deprecated: 'FALSE',
  },
  {
    id: '8093CDE7-C795-4415-94C6-2EF40ED6D057',
    name: 'coffee-to-go',
    codepoint: 'F0177',
    deprecated: 'FALSE',
  },
  {
    id: 'DC66752A-65C6-461D-AAA6-65E8389E7D3B',
    name: 'coffee-to-go-outline',
    codepoint: 'F130E',
    deprecated: 'FALSE',
  },
  {
    id: 'AC6E8289-8D3C-4CE0-ADA6-D17DC639B7FB',
    name: 'coffin',
    codepoint: 'F0B7F',
    deprecated: 'FALSE',
  },
  {
    id: 'E4A14909-3821-4DB1-A739-4DA464ABEEB7',
    name: 'cog',
    codepoint: 'F0493',
    deprecated: 'FALSE',
  },
  {
    id: '6F15658D-723C-4BE9-85D1-F170E4B90281',
    name: 'cog-box',
    codepoint: 'F0494',
    deprecated: 'FALSE',
  },
  {
    id: '3E835EBE-D768-428D-8440-E162EC9F90E4',
    name: 'cog-clockwise',
    codepoint: 'F11DD',
    deprecated: 'FALSE',
  },
  {
    id: 'C9468BA3-4E63-41DB-BACE-4AED3A543147',
    name: 'cog-counterclockwise',
    codepoint: 'F11DE',
    deprecated: 'FALSE',
  },
  {
    id: '7251B1CF-41C8-46F7-991D-83F5996CF0C1',
    name: 'cog-off',
    codepoint: 'F13CE',
    deprecated: 'FALSE',
  },
  {
    id: '83E26995-9267-4A7B-8271-885FEF6303D8',
    name: 'cog-off-outline',
    codepoint: 'F13CF',
    deprecated: 'FALSE',
  },
  {
    id: 'D29B92ED-BC81-44F0-8031-2E48A90ABAD8',
    name: 'cog-outline',
    codepoint: 'F08BB',
    deprecated: 'FALSE',
  },
  {
    id: 'FE314FE9-CDD6-42DF-9F62-F470E3F0E2BF',
    name: 'cog-pause',
    codepoint: 'F1933',
    deprecated: 'FALSE',
  },
  {
    id: '7BC10816-C8A9-4B8D-B11B-B51E0CCB9F72',
    name: 'cog-pause-outline',
    codepoint: 'F1934',
    deprecated: 'FALSE',
  },
  {
    id: 'FF5D0943-FA1E-423E-B6A7-0210449D2C29',
    name: 'cog-play',
    codepoint: 'F1935',
    deprecated: 'FALSE',
  },
  {
    id: '0238FC32-F3C6-46F5-8223-8B63D96B9749',
    name: 'cog-play-outline',
    codepoint: 'F1936',
    deprecated: 'FALSE',
  },
  {
    id: '5F716B9D-4A30-4D1A-B452-B6B0D8B27EDE',
    name: 'cog-refresh',
    codepoint: 'F145E',
    deprecated: 'FALSE',
  },
  {
    id: 'C9E15196-FEB0-4F26-96C2-0E050F959806',
    name: 'cog-refresh-outline',
    codepoint: 'F145F',
    deprecated: 'FALSE',
  },
  {
    id: 'FC49E1B1-ED0E-46CB-B29F-ED444E9B85F0',
    name: 'cog-stop',
    codepoint: 'F1937',
    deprecated: 'FALSE',
  },
  {
    id: 'F937057A-B769-4F5E-A079-7D9BF4679807',
    name: 'cog-stop-outline',
    codepoint: 'F1938',
    deprecated: 'FALSE',
  },
  {
    id: 'BEC36759-CFB0-4475-A65B-B10F860E850A',
    name: 'cog-sync',
    codepoint: 'F1460',
    deprecated: 'FALSE',
  },
  {
    id: '204F6FF9-EFD1-4B79-9DDF-2642984189F0',
    name: 'cog-sync-outline',
    codepoint: 'F1461',
    deprecated: 'FALSE',
  },
  {
    id: '17CD5A77-2809-4DDC-B876-877D8B913217',
    name: 'cog-transfer',
    codepoint: 'F105B',
    deprecated: 'FALSE',
  },
  {
    id: 'AD008794-9094-40B5-91E8-6648E1CBADAD',
    name: 'cog-transfer-outline',
    codepoint: 'F105C',
    deprecated: 'FALSE',
  },
  {
    id: '2EF56130-ECE9-4432-B59E-36F646DE1C86',
    name: 'cogs',
    codepoint: 'F08D6',
    deprecated: 'FALSE',
  },
  {
    id: '8FD44CCD-5073-4C73-98BE-139F777F6E94',
    name: 'collage',
    codepoint: 'F0640',
    deprecated: 'FALSE',
  },
  {
    id: 'C66052C9-BB2A-4E5F-A890-C6D8156E5621',
    name: 'collapse-all',
    codepoint: 'F0AA6',
    deprecated: 'FALSE',
  },
  {
    id: '8672E491-9415-4259-A9E5-E0E3552187CB',
    name: 'collapse-all-outline',
    codepoint: 'F0AA7',
    deprecated: 'FALSE',
  },
  {
    id: 'F27490D2-F7BE-4B35-83E6-40D1F7247755',
    name: 'color-helper',
    codepoint: 'F0179',
    deprecated: 'FALSE',
  },
  {
    id: '55F32835-0982-43F9-9F2D-42585183016A',
    name: 'comma',
    codepoint: 'F0E23',
    deprecated: 'FALSE',
  },
  {
    id: 'CEC57068-18E1-42DA-8788-EDCC009DFB7D',
    name: 'comma-box',
    codepoint: 'F0E2B',
    deprecated: 'FALSE',
  },
  {
    id: '19DFF0F1-FA41-4345-8494-32F42055155B',
    name: 'comma-box-outline',
    codepoint: 'F0E24',
    deprecated: 'FALSE',
  },
  {
    id: '71A7159C-EFDE-4B44-B956-47D588CF74D3',
    name: 'comma-circle',
    codepoint: 'F0E25',
    deprecated: 'FALSE',
  },
  {
    id: '0958EE2B-BD3A-4548-A641-759FC170847A',
    name: 'comma-circle-outline',
    codepoint: 'F0E26',
    deprecated: 'FALSE',
  },
  {
    id: 'B42FD735-27ED-47EC-887B-F5108774E6BC',
    name: 'comment',
    codepoint: 'F017A',
    deprecated: 'FALSE',
  },
  {
    id: 'E0D4328B-7A71-46E7-804B-DFC3753BEDF6',
    name: 'comment-account',
    codepoint: 'F017B',
    deprecated: 'FALSE',
  },
  {
    id: '18A9FFE4-B768-4A8F-BD55-778C8E8C41FC',
    name: 'comment-account-outline',
    codepoint: 'F017C',
    deprecated: 'FALSE',
  },
  {
    id: '04CF8C3D-0D65-41CA-8D0D-53DD7EAB25B8',
    name: 'comment-alert',
    codepoint: 'F017D',
    deprecated: 'FALSE',
  },
  {
    id: 'CBAD69A4-A6FF-4A8A-AA85-B5962910106E',
    name: 'comment-alert-outline',
    codepoint: 'F017E',
    deprecated: 'FALSE',
  },
  {
    id: 'AECA1A18-D8EA-4130-B33C-86D608E030BB',
    name: 'comment-arrow-left',
    codepoint: 'F09E1',
    deprecated: 'FALSE',
  },
  {
    id: '192F008B-D02E-4C2A-A43F-058A19E41D30',
    name: 'comment-arrow-left-outline',
    codepoint: 'F09E2',
    deprecated: 'FALSE',
  },
  {
    id: '256DE915-25D5-4777-AA1A-A432ABB6E039',
    name: 'comment-arrow-right',
    codepoint: 'F09E3',
    deprecated: 'FALSE',
  },
  {
    id: 'E34BA65C-995B-4E2F-A40E-8833E55187FC',
    name: 'comment-arrow-right-outline',
    codepoint: 'F09E4',
    deprecated: 'FALSE',
  },
  {
    id: 'AEB3B1E9-D2B4-497C-9939-BFB549F57019',
    name: 'comment-bookmark',
    codepoint: 'F15AE',
    deprecated: 'FALSE',
  },
  {
    id: '342B6E5C-FE9B-4A5A-940C-3918AB609441',
    name: 'comment-bookmark-outline',
    codepoint: 'F15AF',
    deprecated: 'FALSE',
  },
  {
    id: '5D2CEEF1-3F19-4AD2-8965-13DB365B0013',
    name: 'comment-check',
    codepoint: 'F017F',
    deprecated: 'FALSE',
  },
  {
    id: 'D9BBEE92-5775-4364-8ECD-89C0872BE79F',
    name: 'comment-check-outline',
    codepoint: 'F0180',
    deprecated: 'FALSE',
  },
  {
    id: '8729ADA4-D1A0-477A-B670-EC5CFF2A3A7E',
    name: 'comment-edit',
    codepoint: 'F11BF',
    deprecated: 'FALSE',
  },
  {
    id: '3A7903C6-EA94-4FF6-8B95-FECC91058756',
    name: 'comment-edit-outline',
    codepoint: 'F12C4',
    deprecated: 'FALSE',
  },
  {
    id: '7CDBE4A1-9E98-419D-A309-4ECB9476DA12',
    name: 'comment-eye',
    codepoint: 'F0A3A',
    deprecated: 'FALSE',
  },
  {
    id: '470DE0A7-8431-42B7-BC50-E46D0DEBE759',
    name: 'comment-eye-outline',
    codepoint: 'F0A3B',
    deprecated: 'FALSE',
  },
  {
    id: '27CE0231-2BF3-43E1-9852-3044D59DA0F2',
    name: 'comment-flash',
    codepoint: 'F15B0',
    deprecated: 'FALSE',
  },
  {
    id: '68AA7A36-BF66-4F18-8BEE-78448FDBF5B9',
    name: 'comment-flash-outline',
    codepoint: 'F15B1',
    deprecated: 'FALSE',
  },
  {
    id: 'B3DD4217-5AB7-4E9F-979E-7E16A2DA8E76',
    name: 'comment-minus',
    codepoint: 'F15DF',
    deprecated: 'FALSE',
  },
  {
    id: 'C63B509C-5F87-42E1-A53B-0623A2EA4461',
    name: 'comment-minus-outline',
    codepoint: 'F15E0',
    deprecated: 'FALSE',
  },
  {
    id: '63AC824E-16A4-4FBE-A6DF-588DEBCA47C1',
    name: 'comment-multiple',
    codepoint: 'F085F',
    deprecated: 'FALSE',
  },
  {
    id: 'A5FAC5DC-088F-485C-A885-FBBEEC70B352',
    name: 'comment-multiple-outline',
    codepoint: 'F0181',
    deprecated: 'FALSE',
  },
  {
    id: '9BBD8848-EA4F-4052-9361-B5D7AFCEBFF6',
    name: 'comment-off',
    codepoint: 'F15E1',
    deprecated: 'FALSE',
  },
  {
    id: '14104E5E-1FA3-441C-9705-66D86F8E28DB',
    name: 'comment-off-outline',
    codepoint: 'F15E2',
    deprecated: 'FALSE',
  },
  {
    id: 'B1A0A26E-3E9C-4718-8A26-2837A981D9B5',
    name: 'comment-outline',
    codepoint: 'F0182',
    deprecated: 'FALSE',
  },
  {
    id: '6C595408-3938-4111-9566-7149810D4A73',
    name: 'comment-plus',
    codepoint: 'F09E5',
    deprecated: 'FALSE',
  },
  {
    id: '813C3AFA-56D8-472B-8C1C-C365AC752663',
    name: 'comment-plus-outline',
    codepoint: 'F0183',
    deprecated: 'FALSE',
  },
  {
    id: 'D07698E0-AB53-4E7A-9C25-9A25D1D5CDA9',
    name: 'comment-processing',
    codepoint: 'F0184',
    deprecated: 'FALSE',
  },
  {
    id: '0CDF5E61-25C4-4E30-8E16-32E4E7EDE705',
    name: 'comment-processing-outline',
    codepoint: 'F0185',
    deprecated: 'FALSE',
  },
  {
    id: '49412CCB-C5F6-460F-A2DB-76BCCB668BCC',
    name: 'comment-question',
    codepoint: 'F0817',
    deprecated: 'FALSE',
  },
  {
    id: '7BD335A6-C4A7-48F1-B04F-F1457B7B1F20',
    name: 'comment-question-outline',
    codepoint: 'F0186',
    deprecated: 'FALSE',
  },
  {
    id: '6A2BA297-7401-4727-B79F-8C8DD0F56FE7',
    name: 'comment-quote',
    codepoint: 'F1021',
    deprecated: 'FALSE',
  },
  {
    id: '5510BB9F-2D63-471A-86F3-732E91A513E4',
    name: 'comment-quote-outline',
    codepoint: 'F1022',
    deprecated: 'FALSE',
  },
  {
    id: '834998BB-D7F4-41F3-86B8-5EE5631B524A',
    name: 'comment-remove',
    codepoint: 'F05DE',
    deprecated: 'FALSE',
  },
  {
    id: '520544E5-1693-4084-BB9C-B6FA70204B2C',
    name: 'comment-remove-outline',
    codepoint: 'F0187',
    deprecated: 'FALSE',
  },
  {
    id: '02BA858D-5D62-487B-B423-22EBB73E10A9',
    name: 'comment-search',
    codepoint: 'F0A3C',
    deprecated: 'FALSE',
  },
  {
    id: '17A45B1C-290F-4654-9C8D-3F3F641AB5CD',
    name: 'comment-search-outline',
    codepoint: 'F0A3D',
    deprecated: 'FALSE',
  },
  {
    id: '483321DB-C7E5-4E48-B739-8500F6153446',
    name: 'comment-text',
    codepoint: 'F0188',
    deprecated: 'FALSE',
  },
  {
    id: '88EDEE2E-AB1B-4287-A082-E3B139F8E69A',
    name: 'comment-text-multiple',
    codepoint: 'F0860',
    deprecated: 'FALSE',
  },
  {
    id: 'B7CBD1E2-9677-4D9B-B7BD-CA5493920BE6',
    name: 'comment-text-multiple-outline',
    codepoint: 'F0861',
    deprecated: 'FALSE',
  },
  {
    id: '3D9E4543-670E-4434-8C82-3A80122ED4EE',
    name: 'comment-text-outline',
    codepoint: 'F0189',
    deprecated: 'FALSE',
  },
  {
    id: 'A9786B48-6664-4170-B56C-6F2868D5000D',
    name: 'compare',
    codepoint: 'F018A',
    deprecated: 'FALSE',
  },
  {
    id: 'C2F3461E-363D-42B9-AEF0-3E8A92EB2569',
    name: 'compare-horizontal',
    codepoint: 'F1492',
    deprecated: 'FALSE',
  },
  {
    id: '89337179-88B1-4CD0-BA70-F18FC41514DF',
    name: 'compare-remove',
    codepoint: 'F18B3',
    deprecated: 'FALSE',
  },
  {
    id: '2E95C78F-AAF9-4E1F-8241-4F781389EFF7',
    name: 'compare-vertical',
    codepoint: 'F1493',
    deprecated: 'FALSE',
  },
  {
    id: '8FE64B61-0FD6-4056-9A4F-E62408CE70FE',
    name: 'compass',
    codepoint: 'F018B',
    deprecated: 'FALSE',
  },
  {
    id: '2D283145-478B-4863-9889-CFDDB88342A3',
    name: 'compass-off',
    codepoint: 'F0B80',
    deprecated: 'FALSE',
  },
  {
    id: 'ACF5388A-9C33-4466-A6E7-E0EFE1508C28',
    name: 'compass-off-outline',
    codepoint: 'F0B81',
    deprecated: 'FALSE',
  },
  {
    id: 'EDA6D9AB-4AAE-4080-A1D3-78762666D7B1',
    name: 'compass-outline',
    codepoint: 'F018C',
    deprecated: 'FALSE',
  },
  {
    id: 'D7B6D7B4-F143-477C-A402-963791DB0029',
    name: 'compass-rose',
    codepoint: 'F1382',
    deprecated: 'FALSE',
  },
  {
    id: '75C5A894-CC25-4326-A270-6F70BE0D8AAB',
    name: 'compost',
    codepoint: 'F1A38',
    deprecated: 'FALSE',
  },
  {
    id: '4819FE23-EEAA-496B-A8DC-C4101A1AC994',
    name: 'cone',
    codepoint: 'F194C',
    deprecated: 'FALSE',
  },
  {
    id: '76F233E1-C7AA-4C0F-BDFC-717BF22D40BB',
    name: 'cone-off',
    codepoint: 'F194D',
    deprecated: 'FALSE',
  },
  {
    id: 'C66F7470-8691-4B30-A8F6-5250389E1D48',
    name: 'connection',
    codepoint: 'F1616',
    deprecated: 'FALSE',
  },
  {
    id: 'DCD0A183-A5DC-43BD-BC48-8FFF0CA0FA9C',
    name: 'console',
    codepoint: 'F018D',
    deprecated: 'FALSE',
  },
  {
    id: 'D6FF21C5-201A-43E0-B8AF-276A6BED653A',
    name: 'console-line',
    codepoint: 'F07B7',
    deprecated: 'FALSE',
  },
  {
    id: '19A41CF5-CA4E-411C-8C34-14895B4B90DD',
    name: 'console-network',
    codepoint: 'F08A9',
    deprecated: 'FALSE',
  },
  {
    id: '48F71C58-812D-40AA-BC4D-9D4381684E90',
    name: 'console-network-outline',
    codepoint: 'F0C60',
    deprecated: 'FALSE',
  },
  {
    id: '79D6D751-2470-4ACC-B67E-40F5FF12BC25',
    name: 'consolidate',
    codepoint: 'F10D8',
    deprecated: 'FALSE',
  },
  {
    id: '45F3DDED-3C5E-4B6B-941E-7283657A1577',
    name: 'contactless-payment',
    codepoint: 'F0D6A',
    deprecated: 'FALSE',
  },
  {
    id: '445CF970-F03C-4DDC-A326-F77DA919CB39',
    name: 'contactless-payment-circle',
    codepoint: 'F0321',
    deprecated: 'FALSE',
  },
  {
    id: 'E7D24172-A5A7-4BAE-A679-D2191E8E7F51',
    name: 'contactless-payment-circle-outline',
    codepoint: 'F0408',
    deprecated: 'FALSE',
  },
  {
    id: '5A68EA78-9792-4223-808D-17E5205765E6',
    name: 'contacts',
    codepoint: 'F06CB',
    deprecated: 'FALSE',
  },
  {
    id: '5D5E7753-B3F1-4D0C-B175-62749D448492',
    name: 'contacts-outline',
    codepoint: 'F05B8',
    deprecated: 'FALSE',
  },
  {
    id: '44029D6D-5640-4AE5-907B-8388B0438FF0',
    name: 'contain',
    codepoint: 'F0A3E',
    deprecated: 'FALSE',
  },
  {
    id: 'B786A80C-161C-492C-B405-A10087092CDC',
    name: 'contain-end',
    codepoint: 'F0A3F',
    deprecated: 'FALSE',
  },
  {
    id: '5161A499-05B1-444F-84E6-359F5C46F84C',
    name: 'contain-start',
    codepoint: 'F0A40',
    deprecated: 'FALSE',
  },
  {
    id: '2B3B8FC2-C8AB-40AF-9B3F-AFFC93482676',
    name: 'content-copy',
    codepoint: 'F018F',
    deprecated: 'FALSE',
  },
  {
    id: 'D4028362-37C3-4EED-BE0B-C78927C3F55D',
    name: 'content-cut',
    codepoint: 'F0190',
    deprecated: 'FALSE',
  },
  {
    id: 'B1A86989-2CB4-47DC-BC93-67A077792F72',
    name: 'content-duplicate',
    codepoint: 'F0191',
    deprecated: 'FALSE',
  },
  {
    id: '75856C98-A0D6-4C78-A323-BD170B6E6094',
    name: 'content-paste',
    codepoint: 'F0192',
    deprecated: 'FALSE',
  },
  {
    id: '29F946C0-CA58-4287-AF0B-964961E5CC2C',
    name: 'content-save',
    codepoint: 'F0193',
    deprecated: 'FALSE',
  },
  {
    id: 'E4BAD28B-B196-42B5-BD4D-92C81A8EADAE',
    name: 'content-save-alert',
    codepoint: 'F0F42',
    deprecated: 'FALSE',
  },
  {
    id: '21B9916D-3D1A-43AF-91B2-56A4D5C493C2',
    name: 'content-save-alert-outline',
    codepoint: 'F0F43',
    deprecated: 'FALSE',
  },
  {
    id: 'B9BE7889-D858-41A1-AB4E-9C58325261E9',
    name: 'content-save-all',
    codepoint: 'F0194',
    deprecated: 'FALSE',
  },
  {
    id: 'B65ADBE9-DE1B-4879-A34F-602A3DA4D12A',
    name: 'content-save-all-outline',
    codepoint: 'F0F44',
    deprecated: 'FALSE',
  },
  {
    id: 'B52FC179-01B3-4E41-821E-DC162E402B5B',
    name: 'content-save-check',
    codepoint: 'F18EA',
    deprecated: 'FALSE',
  },
  {
    id: '4A940492-A6C2-4481-BDB0-485920679D1D',
    name: 'content-save-check-outline',
    codepoint: 'F18EB',
    deprecated: 'FALSE',
  },
  {
    id: 'E909F872-44CC-4D23-8AD2-95BFE02A5E2D',
    name: 'content-save-cog',
    codepoint: 'F145B',
    deprecated: 'FALSE',
  },
  {
    id: 'A145BFEF-B18C-49C5-B24C-7918C878307E',
    name: 'content-save-cog-outline',
    codepoint: 'F145C',
    deprecated: 'FALSE',
  },
  {
    id: '12E2DE0D-BF3F-402D-8044-B18E269D32C2',
    name: 'content-save-edit',
    codepoint: 'F0CFB',
    deprecated: 'FALSE',
  },
  {
    id: 'B8D3719E-E67F-4008-9F43-458D75857769',
    name: 'content-save-edit-outline',
    codepoint: 'F0CFC',
    deprecated: 'FALSE',
  },
  {
    id: '0FA06948-5C0F-498D-BDCD-15EB208057F9',
    name: 'content-save-minus',
    codepoint: 'F1B43',
    deprecated: 'FALSE',
  },
  {
    id: '186EE3B9-AFE9-47BA-A3FA-0ED59797D38C',
    name: 'content-save-minus-outline',
    codepoint: 'F1B44',
    deprecated: 'FALSE',
  },
  {
    id: 'FC4F757F-2540-4853-89A3-7FE07FC710C0',
    name: 'content-save-move',
    codepoint: 'F0E27',
    deprecated: 'FALSE',
  },
  {
    id: '12F9DD45-1D74-45E3-84B7-CDE90A3FD174',
    name: 'content-save-move-outline',
    codepoint: 'F0E28',
    deprecated: 'FALSE',
  },
  {
    id: 'E5889AAE-7F3E-46F3-BEF6-36F4BE570DE8',
    name: 'content-save-off',
    codepoint: 'F1643',
    deprecated: 'FALSE',
  },
  {
    id: '178D08A9-7C54-4F7A-9335-726030578718',
    name: 'content-save-off-outline',
    codepoint: 'F1644',
    deprecated: 'FALSE',
  },
  {
    id: '707F4114-BD2F-4601-82C4-CFB6B2D2F359',
    name: 'content-save-outline',
    codepoint: 'F0818',
    deprecated: 'FALSE',
  },
  {
    id: 'F8794210-E83F-451C-AADE-35F71688F142',
    name: 'content-save-plus',
    codepoint: 'F1B41',
    deprecated: 'FALSE',
  },
  {
    id: '56051C42-5524-47EE-B8A9-66AE8CFC43C2',
    name: 'content-save-plus-outline',
    codepoint: 'F1B42',
    deprecated: 'FALSE',
  },
  {
    id: '67E755F6-209A-41EF-98C5-423739A3AC10',
    name: 'content-save-settings',
    codepoint: 'F061B',
    deprecated: 'FALSE',
  },
  {
    id: '2EDEEDF4-2AE3-4365-8F49-DC6F869EC520',
    name: 'content-save-settings-outline',
    codepoint: 'F0B2E',
    deprecated: 'FALSE',
  },
  {
    id: 'B50D388A-4647-4780-83E7-2C5AD8375FB9',
    name: 'contrast',
    codepoint: 'F0195',
    deprecated: 'FALSE',
  },
  {
    id: 'B7FCC914-C6E9-4919-8574-F0161C2CF28D',
    name: 'contrast-box',
    codepoint: 'F0196',
    deprecated: 'FALSE',
  },
  {
    id: 'A7CBFD5D-FDFD-4202-A290-840219A79F2F',
    name: 'contrast-circle',
    codepoint: 'F0197',
    deprecated: 'FALSE',
  },
  {
    id: '605CAD46-2EA0-47AE-8A41-953C1F27F9CD',
    name: 'controller',
    codepoint: 'F02B4',
    deprecated: 'FALSE',
  },
  {
    id: '2D97B0F0-9662-4323-8F5B-0677507461EE',
    name: 'controller-classic',
    codepoint: 'F0B82',
    deprecated: 'FALSE',
  },
  {
    id: '053ECCDF-BF30-417F-85D4-FF2614402B5A',
    name: 'controller-classic-outline',
    codepoint: 'F0B83',
    deprecated: 'FALSE',
  },
  {
    id: '8C28F332-2037-476E-8FAA-D5E6BC81D8B2',
    name: 'controller-off',
    codepoint: 'F02B5',
    deprecated: 'FALSE',
  },
  {
    id: '8E3FB537-B293-41A6-B6C5-B2BE927091F5',
    name: 'cookie',
    codepoint: 'F0198',
    deprecated: 'FALSE',
  },
  {
    id: 'D37F1726-BDD9-4AFB-84E0-A62587AAA6CC',
    name: 'cookie-alert',
    codepoint: 'F16D0',
    deprecated: 'FALSE',
  },
  {
    id: '927FC7C7-8DF3-47FE-BAC4-7DF8DAF3F936',
    name: 'cookie-alert-outline',
    codepoint: 'F16D1',
    deprecated: 'FALSE',
  },
  {
    id: 'DB07C270-5421-41A3-BBFF-350874DE3534',
    name: 'cookie-check',
    codepoint: 'F16D2',
    deprecated: 'FALSE',
  },
  {
    id: '65B2C1ED-992F-45D8-A0C0-4481F99748BF',
    name: 'cookie-check-outline',
    codepoint: 'F16D3',
    deprecated: 'FALSE',
  },
  {
    id: '9DEBBFC6-6734-4D46-A7C0-3A11793C4708',
    name: 'cookie-clock',
    codepoint: 'F16E4',
    deprecated: 'FALSE',
  },
  {
    id: '5F7A7D4A-D4ED-4AE6-987D-A735A7403C00',
    name: 'cookie-clock-outline',
    codepoint: 'F16E5',
    deprecated: 'FALSE',
  },
  {
    id: '5923CF0C-4D34-4BF1-8401-3AEEDC0DE9BC',
    name: 'cookie-cog',
    codepoint: 'F16D4',
    deprecated: 'FALSE',
  },
  {
    id: '96AE483D-C5CD-424A-A553-C9D5399CAD7F',
    name: 'cookie-cog-outline',
    codepoint: 'F16D5',
    deprecated: 'FALSE',
  },
  {
    id: 'E36B77B1-913A-45CC-A23B-02EA12161352',
    name: 'cookie-edit',
    codepoint: 'F16E6',
    deprecated: 'FALSE',
  },
  {
    id: '0A7C1A34-8BC9-4A44-93F3-59F9235274AB',
    name: 'cookie-edit-outline',
    codepoint: 'F16E7',
    deprecated: 'FALSE',
  },
  {
    id: 'FD4F6EA6-712D-4E1E-AF1E-25CB27AE66CE',
    name: 'cookie-lock',
    codepoint: 'F16E8',
    deprecated: 'FALSE',
  },
  {
    id: 'CB5D57DF-BA59-4C98-9FA7-96536EF9FAA0',
    name: 'cookie-lock-outline',
    codepoint: 'F16E9',
    deprecated: 'FALSE',
  },
  {
    id: '215F338E-A35C-4C54-9AC7-C4F4BB9A7206',
    name: 'cookie-minus',
    codepoint: 'F16DA',
    deprecated: 'FALSE',
  },
  {
    id: 'E5590F64-7B4E-4506-AF93-ED7F3E6AB988',
    name: 'cookie-minus-outline',
    codepoint: 'F16DB',
    deprecated: 'FALSE',
  },
  {
    id: '9B401BB3-9510-457F-9C35-BAC5A30BF91F',
    name: 'cookie-off',
    codepoint: 'F16EA',
    deprecated: 'FALSE',
  },
  {
    id: '4355ECEC-E157-4BC8-AF80-45FAC597F188',
    name: 'cookie-off-outline',
    codepoint: 'F16EB',
    deprecated: 'FALSE',
  },
  {
    id: 'AF77B3DF-8565-4A84-A58F-83FD2E55EB10',
    name: 'cookie-outline',
    codepoint: 'F16DE',
    deprecated: 'FALSE',
  },
  {
    id: '272BE458-B01F-4437-B083-65175B327D05',
    name: 'cookie-plus',
    codepoint: 'F16D6',
    deprecated: 'FALSE',
  },
  {
    id: 'AB2BBE22-85B2-4D5B-9F99-099DE970A9EC',
    name: 'cookie-plus-outline',
    codepoint: 'F16D7',
    deprecated: 'FALSE',
  },
  {
    id: '184857B9-228F-4DD2-AF53-603277AEEB7F',
    name: 'cookie-refresh',
    codepoint: 'F16EC',
    deprecated: 'FALSE',
  },
  {
    id: 'CA84B827-73C5-4C28-AC8B-82AADD5469E9',
    name: 'cookie-refresh-outline',
    codepoint: 'F16ED',
    deprecated: 'FALSE',
  },
  {
    id: '25272102-387C-4333-BF16-3308E6EFF94F',
    name: 'cookie-remove',
    codepoint: 'F16D8',
    deprecated: 'FALSE',
  },
  {
    id: '12200D0C-1B01-4165-A2D1-C5BE30B3E3A7',
    name: 'cookie-remove-outline',
    codepoint: 'F16D9',
    deprecated: 'FALSE',
  },
  {
    id: 'B8F9243C-188F-4EC1-9051-5BAA942D7F42',
    name: 'cookie-settings',
    codepoint: 'F16DC',
    deprecated: 'FALSE',
  },
  {
    id: 'FF72A11B-A9E5-47B7-967F-C520DC965CE2',
    name: 'cookie-settings-outline',
    codepoint: 'F16DD',
    deprecated: 'FALSE',
  },
  {
    id: 'A260896B-178E-4E3A-9F2C-793AD68154F4',
    name: 'coolant-temperature',
    codepoint: 'F03C8',
    deprecated: 'FALSE',
  },
  {
    id: '430A9380-A9BB-43C3-8A16-3F41AE22C3D7',
    name: 'copyleft',
    codepoint: 'F1939',
    deprecated: 'FALSE',
  },
  {
    id: '34B43A77-9873-4723-AA65-45D42992748E',
    name: 'copyright',
    codepoint: 'F05E6',
    deprecated: 'FALSE',
  },
  {
    id: 'C3F97D60-41A7-49A3-A8B7-C63D724B12FF',
    name: 'cordova',
    codepoint: 'F0958',
    deprecated: 'TRUE',
  },
  {
    id: 'B49614ED-B7AB-4123-BD9C-B50CE119C76C',
    name: 'corn',
    codepoint: 'F07B8',
    deprecated: 'FALSE',
  },
  {
    id: 'E2947B22-1F7A-4638-8B16-27DA49F5BD52',
    name: 'corn-off',
    codepoint: 'F13EF',
    deprecated: 'FALSE',
  },
  {
    id: '4969E6E7-4311-4F1E-B9ED-070DE4473A65',
    name: 'cosine-wave',
    codepoint: 'F1479',
    deprecated: 'FALSE',
  },
  {
    id: 'F789F2E4-022E-4A5A-B548-30D21BC3A7F6',
    name: 'counter',
    codepoint: 'F0199',
    deprecated: 'FALSE',
  },
  {
    id: '795A2085-78E1-4721-BF6F-007B20CF92AF',
    name: 'countertop',
    codepoint: 'F181C',
    deprecated: 'FALSE',
  },
  {
    id: '31920D18-0B33-4CE8-B5D9-7E879DB4E6AA',
    name: 'countertop-outline',
    codepoint: 'F181D',
    deprecated: 'FALSE',
  },
  {
    id: '1D459B7E-A98C-4E02-929B-FA0BA329B97F',
    name: 'cow',
    codepoint: 'F019A',
    deprecated: 'FALSE',
  },
  {
    id: '8FA2AEE7-EBCA-4943-AF59-FF3C4D762C53',
    name: 'cow-off',
    codepoint: 'F18FC',
    deprecated: 'FALSE',
  },
  {
    id: 'BC9DA934-ED6D-4EC4-9FA4-2B6FF26B3058',
    name: 'cpu-32-bit',
    codepoint: 'F0EDF',
    deprecated: 'FALSE',
  },
  {
    id: 'BC47ABAD-66D6-4EEA-9512-CB33318F62EF',
    name: 'cpu-64-bit',
    codepoint: 'F0EE0',
    deprecated: 'FALSE',
  },
  {
    id: '74B57630-EBF9-48DB-886C-36FA79D4350B',
    name: 'cradle',
    codepoint: 'F198B',
    deprecated: 'FALSE',
  },
  {
    id: '3CDAD9E8-C4DC-42CA-9262-A0C45B94B139',
    name: 'cradle-outline',
    codepoint: 'F1991',
    deprecated: 'FALSE',
  },
  {
    id: '8C0FE6E3-3D82-4FCA-9CCC-73CA8C5299D1',
    name: 'crane',
    codepoint: 'F0862',
    deprecated: 'FALSE',
  },
  {
    id: '598B7931-3DF8-4CFA-A6E6-80550CA31372',
    name: 'creation',
    codepoint: 'F0674',
    deprecated: 'FALSE',
  },
  {
    id: '8973B8C3-527C-48D1-9E5B-CB0A35DE2B77',
    name: 'creative-commons',
    codepoint: 'F0D6B',
    deprecated: 'TRUE',
  },
  {
    id: '91CCFF94-D3F6-48B2-B62C-02DC1B6DBC1E',
    name: 'credit-card',
    codepoint: 'F0FEF',
    deprecated: 'FALSE',
  },
  {
    id: '1CE6C99F-BA66-4069-B014-67E4FB6E5EB9',
    name: 'credit-card-check',
    codepoint: 'F13D0',
    deprecated: 'FALSE',
  },
  {
    id: '96023451-FCAA-40E0-839C-A7F98C3553F1',
    name: 'credit-card-check-outline',
    codepoint: 'F13D1',
    deprecated: 'FALSE',
  },
  {
    id: 'B116C077-E89C-4079-8CEB-7946D054613D',
    name: 'credit-card-chip',
    codepoint: 'F190F',
    deprecated: 'FALSE',
  },
  {
    id: 'DF35C024-A010-482D-8B28-D567A1C9389D',
    name: 'credit-card-chip-outline',
    codepoint: 'F1910',
    deprecated: 'FALSE',
  },
  {
    id: '0FBFF192-59C3-447E-A987-4BE568865C8E',
    name: 'credit-card-clock',
    codepoint: 'F0EE1',
    deprecated: 'FALSE',
  },
  {
    id: '851D92B4-4326-4913-AA2F-05F3595614C2',
    name: 'credit-card-clock-outline',
    codepoint: 'F0EE2',
    deprecated: 'FALSE',
  },
  {
    id: '5EF28B8C-72F7-4C83-8270-7DA1E535022C',
    name: 'credit-card-edit',
    codepoint: 'F17D7',
    deprecated: 'FALSE',
  },
  {
    id: '4678D0B5-4FF5-4729-9096-2F8EA74447FE',
    name: 'credit-card-edit-outline',
    codepoint: 'F17D8',
    deprecated: 'FALSE',
  },
  {
    id: '896151D2-B1B7-42C5-95E7-78517BEC5676',
    name: 'credit-card-fast',
    codepoint: 'F1911',
    deprecated: 'FALSE',
  },
  {
    id: 'B08C2F32-A29A-4429-BFDA-97F81B704A65',
    name: 'credit-card-fast-outline',
    codepoint: 'F1912',
    deprecated: 'FALSE',
  },
  {
    id: '7FD3C251-310E-4A0D-B6B9-4BAC16F9909B',
    name: 'credit-card-lock',
    codepoint: 'F18E7',
    deprecated: 'FALSE',
  },
  {
    id: '1B3B929E-82A7-438D-823D-4046F3C5F9A1',
    name: 'credit-card-lock-outline',
    codepoint: 'F18E8',
    deprecated: 'FALSE',
  },
  {
    id: '21D860AE-A880-4ED2-9E11-617D888BD744',
    name: 'credit-card-marker',
    codepoint: 'F06A8',
    deprecated: 'FALSE',
  },
  {
    id: 'B026E6BC-FFD6-44FA-96AC-6F4A902FD596',
    name: 'credit-card-marker-outline',
    codepoint: 'F0DBE',
    deprecated: 'FALSE',
  },
  {
    id: '97D593C8-1C57-4597-AB1C-C6B487633BBD',
    name: 'credit-card-minus',
    codepoint: 'F0FAC',
    deprecated: 'FALSE',
  },
  {
    id: 'E35A35BC-A59A-440D-820A-3BB61E1AA9BA',
    name: 'credit-card-minus-outline',
    codepoint: 'F0FAD',
    deprecated: 'FALSE',
  },
  {
    id: '25C1F858-600D-42A4-801F-5F85F22ACB52',
    name: 'credit-card-multiple',
    codepoint: 'F0FF0',
    deprecated: 'FALSE',
  },
  {
    id: 'D7C8ADB6-B7C9-4653-9EA7-EB98552304BA',
    name: 'credit-card-multiple-outline',
    codepoint: 'F019C',
    deprecated: 'FALSE',
  },
  {
    id: '5DC17348-DD99-40EA-804E-CB20BEE93C07',
    name: 'credit-card-off',
    codepoint: 'F0FF1',
    deprecated: 'FALSE',
  },
  {
    id: 'CD527CB6-15F0-41BF-BF50-2BCB8B6C769F',
    name: 'credit-card-off-outline',
    codepoint: 'F05E4',
    deprecated: 'FALSE',
  },
  {
    id: 'A121DBBB-C9E6-4534-AA7B-F59496787BD2',
    name: 'credit-card-outline',
    codepoint: 'F019B',
    deprecated: 'FALSE',
  },
  {
    id: 'DE6FDD30-069D-4377-96D1-9DAC014B6A8F',
    name: 'credit-card-plus',
    codepoint: 'F0FF2',
    deprecated: 'FALSE',
  },
  {
    id: 'FA914D65-0F2C-463D-A54F-9B0DE9FBEED3',
    name: 'credit-card-plus-outline',
    codepoint: 'F0676',
    deprecated: 'FALSE',
  },
  {
    id: 'F768F993-4A1F-4A1E-8EED-11526704C22F',
    name: 'credit-card-refresh',
    codepoint: 'F1645',
    deprecated: 'FALSE',
  },
  {
    id: '0ECF7DE7-9F12-4635-902C-1BBF1064DC21',
    name: 'credit-card-refresh-outline',
    codepoint: 'F1646',
    deprecated: 'FALSE',
  },
  {
    id: '90A38F47-9EFD-4468-A1B9-E31A6781F69D',
    name: 'credit-card-refund',
    codepoint: 'F0FF3',
    deprecated: 'FALSE',
  },
  {
    id: '4C2633A8-8E84-4301-A470-0408DCFFEEF8',
    name: 'credit-card-refund-outline',
    codepoint: 'F0AA8',
    deprecated: 'FALSE',
  },
  {
    id: '6CD92C52-D816-4930-B504-ECB4B8744CEA',
    name: 'credit-card-remove',
    codepoint: 'F0FAE',
    deprecated: 'FALSE',
  },
  {
    id: '003200AA-03EB-4AFA-BE66-B1858B39DA19',
    name: 'credit-card-remove-outline',
    codepoint: 'F0FAF',
    deprecated: 'FALSE',
  },
  {
    id: 'A3BF84E0-A214-410C-9890-851855025247',
    name: 'credit-card-scan',
    codepoint: 'F0FF4',
    deprecated: 'FALSE',
  },
  {
    id: 'CF494E4E-F1FC-411D-87FD-933CC17EE08C',
    name: 'credit-card-scan-outline',
    codepoint: 'F019D',
    deprecated: 'FALSE',
  },
  {
    id: '8E913524-BAAE-48B9-A45A-69192B159EE4',
    name: 'credit-card-search',
    codepoint: 'F1647',
    deprecated: 'FALSE',
  },
  {
    id: '8A0B7BE3-D653-4009-8E42-B3E280F7152C',
    name: 'credit-card-search-outline',
    codepoint: 'F1648',
    deprecated: 'FALSE',
  },
  {
    id: '194059D5-3FF9-4DA7-8C7E-81092D38889B',
    name: 'credit-card-settings',
    codepoint: 'F0FF5',
    deprecated: 'FALSE',
  },
  {
    id: '8EF337DF-4858-4BC2-8C48-BD0911AF0FEB',
    name: 'credit-card-settings-outline',
    codepoint: 'F08D7',
    deprecated: 'FALSE',
  },
  {
    id: '76407FAE-6EB4-4486-9A60-5D62801B78E4',
    name: 'credit-card-sync',
    codepoint: 'F1649',
    deprecated: 'FALSE',
  },
  {
    id: 'FB29966A-E0BE-41FC-92D0-4218B160DCF4',
    name: 'credit-card-sync-outline',
    codepoint: 'F164A',
    deprecated: 'FALSE',
  },
  {
    id: '7894F13C-362E-4628-B0D4-E9AC990CCB41',
    name: 'credit-card-wireless',
    codepoint: 'F0802',
    deprecated: 'FALSE',
  },
  {
    id: '9BDEAB2C-8D25-43FA-9722-9FD68D21E76A',
    name: 'credit-card-wireless-off',
    codepoint: 'F057A',
    deprecated: 'FALSE',
  },
  {
    id: '00E657E0-F05A-423A-813F-3DC14C5E6439',
    name: 'credit-card-wireless-off-outline',
    codepoint: 'F057B',
    deprecated: 'FALSE',
  },
  {
    id: '694399D0-1348-4EB0-BA34-ECD2FE4987C2',
    name: 'credit-card-wireless-outline',
    codepoint: 'F0D6C',
    deprecated: 'FALSE',
  },
  {
    id: '9C7DBA7B-099C-460D-BD51-2B2F70EBE39A',
    name: 'cricket',
    codepoint: 'F0D6D',
    deprecated: 'FALSE',
  },
  {
    id: 'FA68ACCA-389F-453E-B886-FF3DAFE10530',
    name: 'crop',
    codepoint: 'F019E',
    deprecated: 'FALSE',
  },
  {
    id: '17AB799B-F531-4800-A7DE-23C5D95238F4',
    name: 'crop-free',
    codepoint: 'F019F',
    deprecated: 'FALSE',
  },
  {
    id: 'DE641C3F-FEF6-4764-A276-F6FECFE031DA',
    name: 'crop-landscape',
    codepoint: 'F01A0',
    deprecated: 'FALSE',
  },
  {
    id: '02E45E84-72D2-4FE1-BF98-6A9521E31A8A',
    name: 'crop-portrait',
    codepoint: 'F01A1',
    deprecated: 'FALSE',
  },
  {
    id: '03774672-2612-4A56-9E32-69060B32B94C',
    name: 'crop-rotate',
    codepoint: 'F0696',
    deprecated: 'FALSE',
  },
  {
    id: '72BA6354-04A5-4BE9-A84C-843B9441164E',
    name: 'crop-square',
    codepoint: 'F01A2',
    deprecated: 'FALSE',
  },
  {
    id: '5644CD16-FA4D-4F68-8DAC-996169C778E1',
    name: 'cross',
    codepoint: 'F0953',
    deprecated: 'FALSE',
  },
  {
    id: '00B9DE12-462A-483B-90D3-8FA89B3D9FD5',
    name: 'cross-bolnisi',
    codepoint: 'F0CED',
    deprecated: 'FALSE',
  },
  {
    id: '63D4C7DD-5BEF-41F2-921F-48EB987F0ED3',
    name: 'cross-celtic',
    codepoint: 'F0CF5',
    deprecated: 'FALSE',
  },
  {
    id: 'E7B0A94D-5F09-4E77-A1E0-3DA131398A26',
    name: 'cross-outline',
    codepoint: 'F0CF6',
    deprecated: 'FALSE',
  },
  {
    id: '33845FDE-0003-4780-8DC9-07FC29D46599',
    name: 'crosshairs',
    codepoint: 'F01A3',
    deprecated: 'FALSE',
  },
  {
    id: 'D3A1EC2E-98D5-46B6-BF33-DECBBAACEFBE',
    name: 'crosshairs-gps',
    codepoint: 'F01A4',
    deprecated: 'FALSE',
  },
  {
    id: 'BD55C800-ECAF-4839-82BD-980B3891ADF5',
    name: 'crosshairs-off',
    codepoint: 'F0F45',
    deprecated: 'FALSE',
  },
  {
    id: '0842BFCE-4511-40B1-8E66-33CFED0A139B',
    name: 'crosshairs-question',
    codepoint: 'F1136',
    deprecated: 'FALSE',
  },
  {
    id: 'FEE6305B-081C-429E-9584-5A5C6D8FD38F',
    name: 'crowd',
    codepoint: 'F1975',
    deprecated: 'FALSE',
  },
  {
    id: '266349FD-1B26-4BCD-A682-D25C4469B682',
    name: 'crown',
    codepoint: 'F01A5',
    deprecated: 'FALSE',
  },
  {
    id: '8D0845BB-6518-4178-BE14-BDE99D614A8C',
    name: 'crown-circle',
    codepoint: 'F17DC',
    deprecated: 'FALSE',
  },
  {
    id: 'A25B1BFF-7DEC-4A6C-B91A-3B2A149997F1',
    name: 'crown-circle-outline',
    codepoint: 'F17DD',
    deprecated: 'FALSE',
  },
  {
    id: 'D0B6994F-78AD-4D69-ADED-CD0C15D0D62B',
    name: 'crown-outline',
    codepoint: 'F11D0',
    deprecated: 'FALSE',
  },
  {
    id: '803712F9-6CAD-47C9-8B54-08B134949FB3',
    name: 'cryengine',
    codepoint: 'F0959',
    deprecated: 'TRUE',
  },
  {
    id: '730684B8-2187-4AD1-A257-CECF232856FD',
    name: 'crystal-ball',
    codepoint: 'F0B2F',
    deprecated: 'FALSE',
  },
  {
    id: '64E19922-572B-41F8-9139-BE6CBC8A0F80',
    name: 'cube',
    codepoint: 'F01A6',
    deprecated: 'FALSE',
  },
  {
    id: 'C2D996BC-2459-44E7-94FB-EE181E03B8C3',
    name: 'cube-off',
    codepoint: 'F141C',
    deprecated: 'FALSE',
  },
  {
    id: '0A2F7725-8D0E-4C67-8EC3-03D8F92EEB33',
    name: 'cube-off-outline',
    codepoint: 'F141D',
    deprecated: 'FALSE',
  },
  {
    id: '11B7F7B5-8813-48DE-9469-B1159355DDC3',
    name: 'cube-outline',
    codepoint: 'F01A7',
    deprecated: 'FALSE',
  },
  {
    id: '101B8439-F611-400E-A2DF-0D7AE40C1005',
    name: 'cube-scan',
    codepoint: 'F0B84',
    deprecated: 'FALSE',
  },
  {
    id: 'F0726D57-782C-44E8-A404-336480FC9E7B',
    name: 'cube-send',
    codepoint: 'F01A8',
    deprecated: 'FALSE',
  },
  {
    id: 'A22472A9-D83E-4234-AAF0-F60AFB7C57DB',
    name: 'cube-unfolded',
    codepoint: 'F01A9',
    deprecated: 'FALSE',
  },
  {
    id: 'D5DA2C54-C8D0-469F-99A9-C8DB9D3A6F15',
    name: 'cup',
    codepoint: 'F01AA',
    deprecated: 'FALSE',
  },
  {
    id: 'FD336A31-52C0-41AF-A0F3-BA475DEA1A54',
    name: 'cup-off',
    codepoint: 'F05E5',
    deprecated: 'FALSE',
  },
  {
    id: 'C3BC4A94-7F95-4E12-A715-45830DEDA014',
    name: 'cup-off-outline',
    codepoint: 'F137D',
    deprecated: 'FALSE',
  },
  {
    id: 'FEA3388A-F985-482C-8CE0-0B4ABCD31691',
    name: 'cup-outline',
    codepoint: 'F130F',
    deprecated: 'FALSE',
  },
  {
    id: 'AC4AD054-775A-4D59-BF92-A57A6A952607',
    name: 'cup-water',
    codepoint: 'F01AB',
    deprecated: 'FALSE',
  },
  {
    id: '112A3BDB-8748-4118-8315-326E140C101B',
    name: 'cupboard',
    codepoint: 'F0F46',
    deprecated: 'FALSE',
  },
  {
    id: '13FBEF67-6AB8-471D-BC66-DE521D91D0FC',
    name: 'cupboard-outline',
    codepoint: 'F0F47',
    deprecated: 'FALSE',
  },
  {
    id: 'CF09105E-AB0E-4393-ADCC-4E6DFC513C9C',
    name: 'cupcake',
    codepoint: 'F095A',
    deprecated: 'FALSE',
  },
  {
    id: '75F948EB-5A9C-4F7A-9841-2B93AC222605',
    name: 'curling',
    codepoint: 'F0863',
    deprecated: 'FALSE',
  },
  {
    id: 'FB0C738C-21DE-4643-9B5D-690CE7AAEAEE',
    name: 'currency-bdt',
    codepoint: 'F0864',
    deprecated: 'FALSE',
  },
  {
    id: '20E50505-DD23-4A07-AAE7-024BE3774018',
    name: 'currency-brl',
    codepoint: 'F0B85',
    deprecated: 'FALSE',
  },
  {
    id: 'CCAF9A48-2989-47C7-B4A5-1CF424A339CC',
    name: 'currency-btc',
    codepoint: 'F01AC',
    deprecated: 'FALSE',
  },
  {
    id: '0F5809A3-18DD-4B42-9A7E-B35B65EF3751',
    name: 'currency-cny',
    codepoint: 'F07BA',
    deprecated: 'FALSE',
  },
  {
    id: '222B8F14-2217-4FC1-8940-AC1CF8AB150B',
    name: 'currency-eth',
    codepoint: 'F07BB',
    deprecated: 'FALSE',
  },
  {
    id: '94C667B3-E2EB-428D-B422-751C234CCF44',
    name: 'currency-eur',
    codepoint: 'F01AD',
    deprecated: 'FALSE',
  },
  {
    id: '010841EE-1569-425A-9849-9FC6E4D05C3B',
    name: 'currency-eur-off',
    codepoint: 'F1315',
    deprecated: 'FALSE',
  },
  {
    id: '7FFA202C-D65F-4F9C-AC9B-6A17AB4C611E',
    name: 'currency-fra',
    codepoint: 'F1A39',
    deprecated: 'FALSE',
  },
  {
    id: 'F9BD830F-EBB6-45A4-A576-0DEC27084A95',
    name: 'currency-gbp',
    codepoint: 'F01AE',
    deprecated: 'FALSE',
  },
  {
    id: 'A55AD931-CE89-49C0-9F1C-F699C422843C',
    name: 'currency-ils',
    codepoint: 'F0C61',
    deprecated: 'FALSE',
  },
  {
    id: 'AB8EAA72-9A34-4F87-937A-9A8FDEFA1FEF',
    name: 'currency-inr',
    codepoint: 'F01AF',
    deprecated: 'FALSE',
  },
  {
    id: '639A8D4C-086C-4038-A569-F9387E851262',
    name: 'currency-jpy',
    codepoint: 'F07BC',
    deprecated: 'FALSE',
  },
  {
    id: '72750D9E-229F-4D77-B1CB-9C2C0FD12335',
    name: 'currency-krw',
    codepoint: 'F07BD',
    deprecated: 'FALSE',
  },
  {
    id: 'FDA284E5-BF4A-47DC-B68F-255E07DB89CF',
    name: 'currency-kzt',
    codepoint: 'F0865',
    deprecated: 'FALSE',
  },
  {
    id: 'D0B20B31-ADBD-4F8A-9B72-52CC2FE4C3F4',
    name: 'currency-mnt',
    codepoint: 'F1512',
    deprecated: 'FALSE',
  },
  {
    id: '2F091499-4472-423E-A0C5-8C96CDDE1ABF',
    name: 'currency-ngn',
    codepoint: 'F01B0',
    deprecated: 'FALSE',
  },
  {
    id: '734CB783-C788-4F5E-BB90-BFBF4E3C22DC',
    name: 'currency-php',
    codepoint: 'F09E6',
    deprecated: 'FALSE',
  },
  {
    id: '55D42287-2705-451B-B87A-91C23AF3A945',
    name: 'currency-rial',
    codepoint: 'F0E9C',
    deprecated: 'FALSE',
  },
  {
    id: '57057EA1-1BA7-49F3-A8C7-51AF633DED68',
    name: 'currency-rub',
    codepoint: 'F01B1',
    deprecated: 'FALSE',
  },
  {
    id: '4C5FB412-6649-4F26-A075-2865BAF41B5F',
    name: 'currency-rupee',
    codepoint: 'F1976',
    deprecated: 'FALSE',
  },
  {
    id: '17CFE8D4-18D7-4414-B8EE-244D5A8FF791',
    name: 'currency-sign',
    codepoint: 'F07BE',
    deprecated: 'FALSE',
  },
  {
    id: 'F9875997-F68E-4AE3-9B85-E8DFB6E0D054',
    name: 'currency-thb',
    codepoint: 'F1C05',
    deprecated: 'FALSE',
  },
  {
    id: '94DAFCB6-FA70-4DAE-B3DF-A107031F81E8',
    name: 'currency-try',
    codepoint: 'F01B2',
    deprecated: 'FALSE',
  },
  {
    id: 'E0103EBC-20BF-48EA-BC8F-BEF04BF2A581',
    name: 'currency-twd',
    codepoint: 'F07BF',
    deprecated: 'FALSE',
  },
  {
    id: 'F3A1F9C4-307D-40D8-A210-0ECFAC01BDCD',
    name: 'currency-uah',
    codepoint: 'F1B9B',
    deprecated: 'FALSE',
  },
  {
    id: '3FA4946C-029B-42D3-AA31-3D607FB4A2F1',
    name: 'currency-usd',
    codepoint: 'F01C1',
    deprecated: 'FALSE',
  },
  {
    id: '5E9D35E4-9487-4A9B-9D4B-E1BBCA52AE0C',
    name: 'currency-usd-off',
    codepoint: 'F067A',
    deprecated: 'FALSE',
  },
  {
    id: 'BB0EDAF7-B538-4DAA-8406-36470F472EA1',
    name: 'current-ac',
    codepoint: 'F1480',
    deprecated: 'FALSE',
  },
  {
    id: '2A23AD1A-C4F1-47B1-B482-05836097CE51',
    name: 'current-dc',
    codepoint: 'F095C',
    deprecated: 'FALSE',
  },
  {
    id: '2B463003-089D-47DE-97F7-434F16A4735B',
    name: 'cursor-default',
    codepoint: 'F01C0',
    deprecated: 'FALSE',
  },
  {
    id: 'C5C0F16E-6D8B-49DB-8374-9B26C9E76B0D',
    name: 'cursor-default-click',
    codepoint: 'F0CFD',
    deprecated: 'FALSE',
  },
  {
    id: 'CE0F1A1D-5BE5-48A7-9DF2-3021489E4937',
    name: 'cursor-default-click-outline',
    codepoint: 'F0CFE',
    deprecated: 'FALSE',
  },
  {
    id: '450BD54E-A467-41D8-B428-50779BB451C4',
    name: 'cursor-default-gesture',
    codepoint: 'F1127',
    deprecated: 'FALSE',
  },
  {
    id: 'ABCE3774-DAC9-494D-99D3-1054160ECC7E',
    name: 'cursor-default-gesture-outline',
    codepoint: 'F1128',
    deprecated: 'FALSE',
  },
  {
    id: 'E6A56AAF-22C5-4DAE-9E97-184501755DF5',
    name: 'cursor-default-outline',
    codepoint: 'F01BF',
    deprecated: 'FALSE',
  },
  {
    id: 'EDAD5377-8923-4D6C-AC12-CBDC6C78DC7F',
    name: 'cursor-move',
    codepoint: 'F01BE',
    deprecated: 'FALSE',
  },
  {
    id: 'B5465B23-729A-405E-9155-486A36D8135F',
    name: 'cursor-pointer',
    codepoint: 'F01BD',
    deprecated: 'FALSE',
  },
  {
    id: '76C56C15-C2A3-4042-95C7-F143A3B58765',
    name: 'cursor-text',
    codepoint: 'F05E7',
    deprecated: 'FALSE',
  },
  {
    id: '8C3A8BC3-9144-4D24-A41D-BBE2B557BF51',
    name: 'curtains',
    codepoint: 'F1846',
    deprecated: 'FALSE',
  },
  {
    id: 'EB23B6A0-2021-405D-83F2-D69D9A5CF1CD',
    name: 'curtains-closed',
    codepoint: 'F1847',
    deprecated: 'FALSE',
  },
  {
    id: '10ABF378-1E4C-479B-A99E-921605698123',
    name: 'cylinder',
    codepoint: 'F194E',
    deprecated: 'FALSE',
  },
  {
    id: '0CD686F7-F7EF-416B-A74D-8FDC54EAB7BF',
    name: 'cylinder-off',
    codepoint: 'F194F',
    deprecated: 'FALSE',
  },
  {
    id: '82D8E45B-5E04-4094-818B-DE18775F9A91',
    name: 'dance-ballroom',
    codepoint: 'F15FB',
    deprecated: 'FALSE',
  },
  {
    id: '45E2144E-5240-4557-B9A9-45DD7B514738',
    name: 'dance-pole',
    codepoint: 'F1578',
    deprecated: 'FALSE',
  },
  {
    id: '7727E2AF-BD33-4C14-9FF4-6FA4BBA8594C',
    name: 'data-matrix',
    codepoint: 'F153C',
    deprecated: 'FALSE',
  },
  {
    id: '7D6DC2D9-EDDF-4C82-AA10-CEC85C4DF7BF',
    name: 'data-matrix-edit',
    codepoint: 'F153D',
    deprecated: 'FALSE',
  },
  {
    id: 'EA63161B-B010-4BEC-A5B0-774C2007C0D0',
    name: 'data-matrix-minus',
    codepoint: 'F153E',
    deprecated: 'FALSE',
  },
  {
    id: '0B183EEE-8B38-4B8E-A049-96AC93BCD1EA',
    name: 'data-matrix-plus',
    codepoint: 'F153F',
    deprecated: 'FALSE',
  },
  {
    id: '0809BEAA-8884-4116-B319-9AD6C11A86BE',
    name: 'data-matrix-remove',
    codepoint: 'F1540',
    deprecated: 'FALSE',
  },
  {
    id: '8B555E52-AD00-4ECF-8533-02D9AC92E7F5',
    name: 'data-matrix-scan',
    codepoint: 'F1541',
    deprecated: 'FALSE',
  },
  {
    id: 'C8FC48A7-AE19-4BD8-B57E-14B76D93937B',
    name: 'database',
    codepoint: 'F01BC',
    deprecated: 'FALSE',
  },
  {
    id: '7B3AB47E-C134-4AE9-82A2-DD0F95D6EF3F',
    name: 'database-alert',
    codepoint: 'F163A',
    deprecated: 'FALSE',
  },
  {
    id: '27B8C966-DE22-4B45-99A6-5CF769BECD07',
    name: 'database-alert-outline',
    codepoint: 'F1624',
    deprecated: 'FALSE',
  },
  {
    id: '82BC4255-42E2-4B69-ADE4-A3519DE84E22',
    name: 'database-arrow-down',
    codepoint: 'F163B',
    deprecated: 'FALSE',
  },
  {
    id: '8A490A09-A803-4333-BB10-5758643AD4F4',
    name: 'database-arrow-down-outline',
    codepoint: 'F1625',
    deprecated: 'FALSE',
  },
  {
    id: 'FB778AE2-E52D-4C2A-BEF7-C48A805F8A57',
    name: 'database-arrow-left',
    codepoint: 'F163C',
    deprecated: 'FALSE',
  },
  {
    id: 'A51D37EB-0B92-4F4A-8402-E0C33825D05B',
    name: 'database-arrow-left-outline',
    codepoint: 'F1626',
    deprecated: 'FALSE',
  },
  {
    id: 'AE5DDB91-CE2C-4EE3-8247-E4818F488EE0',
    name: 'database-arrow-right',
    codepoint: 'F163D',
    deprecated: 'FALSE',
  },
  {
    id: '097A9369-2179-49DB-891C-5A61C88A985C',
    name: 'database-arrow-right-outline',
    codepoint: 'F1627',
    deprecated: 'FALSE',
  },
  {
    id: 'EC1BF4CB-2B6B-4B69-96E6-599E77D02885',
    name: 'database-arrow-up',
    codepoint: 'F163E',
    deprecated: 'FALSE',
  },
  {
    id: '2E041168-F122-45CC-84AD-2A05428C211A',
    name: 'database-arrow-up-outline',
    codepoint: 'F1628',
    deprecated: 'FALSE',
  },
  {
    id: 'C241F52F-EA70-4784-9455-AA7D05663C90',
    name: 'database-check',
    codepoint: 'F0AA9',
    deprecated: 'FALSE',
  },
  {
    id: '1B7F6C62-E464-42F6-B7C0-CDD637A520F6',
    name: 'database-check-outline',
    codepoint: 'F1629',
    deprecated: 'FALSE',
  },
  {
    id: '1401D168-E1ED-403A-9154-F89133195BF9',
    name: 'database-clock',
    codepoint: 'F163F',
    deprecated: 'FALSE',
  },
  {
    id: '093C4D34-DC23-4FE7-9AD0-05FCFFBB41A6',
    name: 'database-clock-outline',
    codepoint: 'F162A',
    deprecated: 'FALSE',
  },
  {
    id: '2D941DD1-7B07-4E4B-B164-42AF6F751D34',
    name: 'database-cog',
    codepoint: 'F164B',
    deprecated: 'FALSE',
  },
  {
    id: 'A15AD70B-D874-46B1-BD26-2A5627DBF201',
    name: 'database-cog-outline',
    codepoint: 'F164C',
    deprecated: 'FALSE',
  },
  {
    id: 'A9C5BB4C-C89F-4ADE-80C2-FB39A942DE6A',
    name: 'database-edit',
    codepoint: 'F0B86',
    deprecated: 'FALSE',
  },
  {
    id: '6428E607-E036-481B-AF42-B09894C68E19',
    name: 'database-edit-outline',
    codepoint: 'F162B',
    deprecated: 'FALSE',
  },
  {
    id: '6E50F0F8-1116-4090-9468-54B2CE8FBB19',
    name: 'database-export',
    codepoint: 'F095E',
    deprecated: 'FALSE',
  },
  {
    id: 'E620893F-9644-4E75-8904-C81B85B6D58D',
    name: 'database-export-outline',
    codepoint: 'F162C',
    deprecated: 'FALSE',
  },
  {
    id: 'E08D8382-BE58-41F4-B00C-4B6926C1F550',
    name: 'database-eye',
    codepoint: 'F191F',
    deprecated: 'FALSE',
  },
  {
    id: 'B2F93B0D-CC43-4DA0-A01F-778FA9982235',
    name: 'database-eye-off',
    codepoint: 'F1920',
    deprecated: 'FALSE',
  },
  {
    id: 'F80F0F20-77A0-426B-9354-D6F5C2B77473',
    name: 'database-eye-off-outline',
    codepoint: 'F1921',
    deprecated: 'FALSE',
  },
  {
    id: '67DAC11C-DFB0-40C7-B8CF-26A41469DD80',
    name: 'database-eye-outline',
    codepoint: 'F1922',
    deprecated: 'FALSE',
  },
  {
    id: '733A4C8A-FAA5-42BE-882E-98EC734E8493',
    name: 'database-import',
    codepoint: 'F095D',
    deprecated: 'FALSE',
  },
  {
    id: '4D0E6B34-7EAB-4EC4-9782-F2318139A3E2',
    name: 'database-import-outline',
    codepoint: 'F162D',
    deprecated: 'FALSE',
  },
  {
    id: '3A6B098D-F7AD-4D2C-A039-201319B5FDD7',
    name: 'database-lock',
    codepoint: 'F0AAA',
    deprecated: 'FALSE',
  },
  {
    id: 'A03B26F4-54BB-4E3D-B4E7-EAFE3351A6BA',
    name: 'database-lock-outline',
    codepoint: 'F162E',
    deprecated: 'FALSE',
  },
  {
    id: '91AD02DB-7EBD-4B39-AACB-8037B50728AC',
    name: 'database-marker',
    codepoint: 'F12F6',
    deprecated: 'FALSE',
  },
  {
    id: '163262B4-672A-4194-B002-3F414DF6C7A5',
    name: 'database-marker-outline',
    codepoint: 'F162F',
    deprecated: 'FALSE',
  },
  {
    id: '31CE0431-ABF2-4650-8439-515C0E9012CD',
    name: 'database-minus',
    codepoint: 'F01BB',
    deprecated: 'FALSE',
  },
  {
    id: '96D5DD75-E7C1-444F-AD36-AB601BB9C504',
    name: 'database-minus-outline',
    codepoint: 'F1630',
    deprecated: 'FALSE',
  },
  {
    id: '5E0B7DCE-B912-4789-A0F9-86DBA5D27DBE',
    name: 'database-off',
    codepoint: 'F1640',
    deprecated: 'FALSE',
  },
  {
    id: '4DD86D39-4EC5-44BD-BA05-AA763999E390',
    name: 'database-off-outline',
    codepoint: 'F1631',
    deprecated: 'FALSE',
  },
  {
    id: '76AAFFAC-C2C7-4375-A175-765848BF08AA',
    name: 'database-outline',
    codepoint: 'F1632',
    deprecated: 'FALSE',
  },
  {
    id: '940D2514-B309-4CE0-A2E4-D02121415CA3',
    name: 'database-plus',
    codepoint: 'F01BA',
    deprecated: 'FALSE',
  },
  {
    id: '2250D162-41A7-4852-B3E2-555180A5A57C',
    name: 'database-plus-outline',
    codepoint: 'F1633',
    deprecated: 'FALSE',
  },
  {
    id: 'C43C676A-F2BE-489A-8613-BE6EF651F0AC',
    name: 'database-refresh',
    codepoint: 'F05C2',
    deprecated: 'FALSE',
  },
  {
    id: '03B12B1B-7AF3-42C7-89CA-482DC21F12CE',
    name: 'database-refresh-outline',
    codepoint: 'F1634',
    deprecated: 'FALSE',
  },
  {
    id: '20B59D03-2EF5-4C5D-BB00-2CC611297AC0',
    name: 'database-remove',
    codepoint: 'F0D00',
    deprecated: 'FALSE',
  },
  {
    id: '32FBEA36-9F09-4D8D-A355-2DD6FB2AA837',
    name: 'database-remove-outline',
    codepoint: 'F1635',
    deprecated: 'FALSE',
  },
  {
    id: '58E36A40-053B-42EB-8879-9CB0948B58D4',
    name: 'database-search',
    codepoint: 'F0866',
    deprecated: 'FALSE',
  },
  {
    id: 'EFAB71E6-7F67-40B9-B388-0B4AFFC6B799',
    name: 'database-search-outline',
    codepoint: 'F1636',
    deprecated: 'FALSE',
  },
  {
    id: 'CBD96BE0-807C-4102-AC0B-AEFFE4D322F7',
    name: 'database-settings',
    codepoint: 'F0D01',
    deprecated: 'FALSE',
  },
  {
    id: '9AB5AF13-E028-49FA-B81E-EFFF8A8EB144',
    name: 'database-settings-outline',
    codepoint: 'F1637',
    deprecated: 'FALSE',
  },
  {
    id: '5E954303-C1CF-406B-A9B5-A72E5C25E499',
    name: 'database-sync',
    codepoint: 'F0CFF',
    deprecated: 'FALSE',
  },
  {
    id: '9E1F0B0A-332A-473D-8CA2-DD30E03E76B9',
    name: 'database-sync-outline',
    codepoint: 'F1638',
    deprecated: 'FALSE',
  },
  {
    id: '309A4ABA-E450-437D-8AD1-EE6A850D421B',
    name: 'death-star',
    codepoint: 'F08D8',
    deprecated: 'FALSE',
  },
  {
    id: '51D35A62-F308-48B6-B471-D58CFE4CD6BC',
    name: 'death-star-variant',
    codepoint: 'F08D9',
    deprecated: 'FALSE',
  },
  {
    id: 'BC446853-45C9-4374-8980-579BCD93355A',
    name: 'deathly-hallows',
    codepoint: 'F0B87',
    deprecated: 'FALSE',
  },
  {
    id: '250883A1-65B8-4092-9B92-C9BD810ADB5A',
    name: 'debian',
    codepoint: 'F08DA',
    deprecated: 'TRUE',
  },
  {
    id: '316468B3-C3F6-4A7B-B7B2-061E5C35FFE9',
    name: 'debug-step-into',
    codepoint: 'F01B9',
    deprecated: 'FALSE',
  },
  {
    id: '08B9B3C7-BB6B-4110-ACEC-3BB947FFCFBA',
    name: 'debug-step-out',
    codepoint: 'F01B8',
    deprecated: 'FALSE',
  },
  {
    id: '8242E7C8-1400-476D-AC27-DCA7B6292C70',
    name: 'debug-step-over',
    codepoint: 'F01B7',
    deprecated: 'FALSE',
  },
  {
    id: 'DA734DBD-1239-4E30-B688-ADF6A9D222D0',
    name: 'decagram',
    codepoint: 'F076C',
    deprecated: 'FALSE',
  },
  {
    id: '5F4B9A38-F090-4FA3-A138-1F758A9D446E',
    name: 'decagram-outline',
    codepoint: 'F076D',
    deprecated: 'FALSE',
  },
  {
    id: '4A055D0D-18CA-45D9-96E1-B25448138888',
    name: 'decimal',
    codepoint: 'F10A1',
    deprecated: 'FALSE',
  },
  {
    id: 'A8C5A79A-EA59-4AA8-B432-98F66AFDAFD3',
    name: 'decimal-comma',
    codepoint: 'F10A2',
    deprecated: 'FALSE',
  },
  {
    id: 'E9237D99-5BD3-434A-B574-97C60B544EE1',
    name: 'decimal-comma-decrease',
    codepoint: 'F10A3',
    deprecated: 'FALSE',
  },
  {
    id: '3B4E5DF0-02B3-469D-9C6E-754683655184',
    name: 'decimal-comma-increase',
    codepoint: 'F10A4',
    deprecated: 'FALSE',
  },
  {
    id: '3A6FCF00-DE3C-4CD7-BF06-1D26E37E0C47',
    name: 'decimal-decrease',
    codepoint: 'F01B6',
    deprecated: 'FALSE',
  },
  {
    id: 'E99CB4D3-330D-493C-9C6B-FD43B27522FD',
    name: 'decimal-increase',
    codepoint: 'F01B5',
    deprecated: 'FALSE',
  },
  {
    id: '71CD492D-A1E8-4A7A-961A-51DA30952BAD',
    name: 'delete',
    codepoint: 'F01B4',
    deprecated: 'FALSE',
  },
  {
    id: '2FF4634B-095D-4827-9735-E345A46FC488',
    name: 'delete-alert',
    codepoint: 'F10A5',
    deprecated: 'FALSE',
  },
  {
    id: 'CFE12054-7E04-4144-B0CA-0DA9FE494E82',
    name: 'delete-alert-outline',
    codepoint: 'F10A6',
    deprecated: 'FALSE',
  },
  {
    id: '1158F80B-E934-4E74-A4C6-7750BDCC409E',
    name: 'delete-circle',
    codepoint: 'F0683',
    deprecated: 'FALSE',
  },
  {
    id: '854068FB-F9DA-40B4-A427-F2317075CD95',
    name: 'delete-circle-outline',
    codepoint: 'F0B88',
    deprecated: 'FALSE',
  },
  {
    id: '3B615510-4D78-4743-9FB0-A3D45F267B04',
    name: 'delete-clock',
    codepoint: 'F1556',
    deprecated: 'FALSE',
  },
  {
    id: '7544FA9D-AFFB-4038-8D9F-FEE3EB918342',
    name: 'delete-clock-outline',
    codepoint: 'F1557',
    deprecated: 'FALSE',
  },
  {
    id: 'E39FB607-9058-4C00-840C-AF666F372071',
    name: 'delete-empty',
    codepoint: 'F06CC',
    deprecated: 'FALSE',
  },
  {
    id: '69108916-6CE9-456A-A1BE-9113F49B425E',
    name: 'delete-empty-outline',
    codepoint: 'F0E9D',
    deprecated: 'FALSE',
  },
  {
    id: '52AFD546-8C5D-4E77-8D83-D4D058A742FE',
    name: 'delete-forever',
    codepoint: 'F05E8',
    deprecated: 'FALSE',
  },
  {
    id: '103DF12F-6279-4967-8BF8-F72ED139E8E7',
    name: 'delete-forever-outline',
    codepoint: 'F0B89',
    deprecated: 'FALSE',
  },
  {
    id: 'C0E55306-EBD8-46D9-BF50-7285DC72D4B7',
    name: 'delete-off',
    codepoint: 'F10A7',
    deprecated: 'FALSE',
  },
  {
    id: '4D2C0D3A-46A6-42B9-974E-F1B677C61D21',
    name: 'delete-off-outline',
    codepoint: 'F10A8',
    deprecated: 'FALSE',
  },
  {
    id: '59ADF7EF-9F3E-4957-A789-95788D13B9E4',
    name: 'delete-outline',
    codepoint: 'F09E7',
    deprecated: 'FALSE',
  },
  {
    id: '7837B9B7-2A6F-42E3-894D-C40D0080FB7B',
    name: 'delete-restore',
    codepoint: 'F0819',
    deprecated: 'FALSE',
  },
  {
    id: '3B3D514C-AFC3-4D04-91EA-E18F03BB494A',
    name: 'delete-sweep',
    codepoint: 'F05E9',
    deprecated: 'FALSE',
  },
  {
    id: '18C79874-01B2-4A65-AD4E-B8600C3F3ADD',
    name: 'delete-sweep-outline',
    codepoint: 'F0C62',
    deprecated: 'FALSE',
  },
  {
    id: 'DC3862AC-6945-4104-9F15-50BD46D13705',
    name: 'delete-variant',
    codepoint: 'F01B3',
    deprecated: 'FALSE',
  },
  {
    id: 'D4B29470-8EE3-4ED8-BB01-6C942CE7E748',
    name: 'delta',
    codepoint: 'F01C2',
    deprecated: 'FALSE',
  },
  {
    id: '35E2D33D-B4A8-4E12-B844-8EC1172F15A9',
    name: 'desk',
    codepoint: 'F1239',
    deprecated: 'FALSE',
  },
  {
    id: '80459D13-2662-4F47-AB31-F6A2B84C8DEB',
    name: 'desk-lamp',
    codepoint: 'F095F',
    deprecated: 'FALSE',
  },
  {
    id: '3CC7EBFA-A595-4E15-8ACF-7C43267B162C',
    name: 'desk-lamp-off',
    codepoint: 'F1B1F',
    deprecated: 'FALSE',
  },
  {
    id: 'C5747413-9B53-48DD-8B30-A2C861EFC675',
    name: 'desk-lamp-on',
    codepoint: 'F1B20',
    deprecated: 'FALSE',
  },
  {
    id: 'C05777DC-F874-4231-B9EB-5675C808F7BB',
    name: 'deskphone',
    codepoint: 'F01C3',
    deprecated: 'FALSE',
  },
  {
    id: '40B89929-0709-461F-8C7A-ED94CDDCC4AC',
    name: 'desktop-classic',
    codepoint: 'F07C0',
    deprecated: 'FALSE',
  },
  {
    id: '362BCE24-8F91-491A-8EE8-BD08C296511A',
    name: 'desktop-tower',
    codepoint: 'F01C5',
    deprecated: 'FALSE',
  },
  {
    id: 'D7BED84E-B592-46F0-8A5B-DD8677088837',
    name: 'desktop-tower-monitor',
    codepoint: 'F0AAB',
    deprecated: 'FALSE',
  },
  {
    id: '21A2819F-3B97-49D0-A588-30CF7B8915F5',
    name: 'details',
    codepoint: 'F01C6',
    deprecated: 'FALSE',
  },
  {
    id: 'D3E5B7D2-2301-4D35-A95E-1C1B0A6C9224',
    name: 'dev-to',
    codepoint: 'F0D6E',
    deprecated: 'TRUE',
  },
  {
    id: '7D1C43A0-CDE0-416B-A1A9-1CD07F930284',
    name: 'developer-board',
    codepoint: 'F0697',
    deprecated: 'FALSE',
  },
  {
    id: 'ECD72042-9BA7-4C24-A740-7F538B443302',
    name: 'deviantart',
    codepoint: 'F01C7',
    deprecated: 'TRUE',
  },
  {
    id: 'D8990892-9C02-46AC-9A91-3D4D9A2EFF19',
    name: 'devices',
    codepoint: 'F0FB0',
    deprecated: 'FALSE',
  },
  {
    id: '18A94F7F-1BC5-48EC-9CAF-6D2D663287DC',
    name: 'dharmachakra',
    codepoint: 'F094B',
    deprecated: 'FALSE',
  },
  {
    id: 'B0973EC9-B27B-4666-8E9E-C766A601BB0A',
    name: 'diabetes',
    codepoint: 'F1126',
    deprecated: 'FALSE',
  },
  {
    id: 'B732FAD7-93A7-478E-810F-835069DE6EDB',
    name: 'dialpad',
    codepoint: 'F061C',
    deprecated: 'FALSE',
  },
  {
    id: '64CBC510-072A-477D-99DD-F8114E7E312E',
    name: 'diameter',
    codepoint: 'F0C63',
    deprecated: 'FALSE',
  },
  {
    id: 'ABE01F05-E04E-4C5F-8F70-F8A9DB484E89',
    name: 'diameter-outline',
    codepoint: 'F0C64',
    deprecated: 'FALSE',
  },
  {
    id: 'B1744A41-3289-4535-81F8-E23617C701FE',
    name: 'diameter-variant',
    codepoint: 'F0C65',
    deprecated: 'FALSE',
  },
  {
    id: 'BC337E6C-8077-4D79-9596-6B9EDDA6DE0D',
    name: 'diamond',
    codepoint: 'F0B8A',
    deprecated: 'FALSE',
  },
  {
    id: '4D922F91-E62D-42BB-86E4-BE8A96BC2B80',
    name: 'diamond-outline',
    codepoint: 'F0B8B',
    deprecated: 'FALSE',
  },
  {
    id: '7AEA1B64-9B6D-4D55-91F8-05BA1BF3BF89',
    name: 'diamond-stone',
    codepoint: 'F01C8',
    deprecated: 'FALSE',
  },
  {
    id: 'F8E5E9B6-9953-46F8-84DC-CFBB11DEFFB7',
    name: 'dice-1',
    codepoint: 'F01CA',
    deprecated: 'FALSE',
  },
  {
    id: '430F434F-E5F5-4D54-97B0-F6D1CE844365',
    name: 'dice-1-outline',
    codepoint: 'F114A',
    deprecated: 'FALSE',
  },
  {
    id: '07927207-23B1-4A93-BB8A-E6B5F41B919A',
    name: 'dice-2',
    codepoint: 'F01CB',
    deprecated: 'FALSE',
  },
  {
    id: 'BA4A2CA9-22E9-450E-B8CA-99A8ED5E0A1B',
    name: 'dice-2-outline',
    codepoint: 'F114B',
    deprecated: 'FALSE',
  },
  {
    id: '2948109E-F9DA-4306-8247-665CC05BDB21',
    name: 'dice-3',
    codepoint: 'F01CC',
    deprecated: 'FALSE',
  },
  {
    id: 'D4689BAD-0911-4832-9A8E-AD334D834773',
    name: 'dice-3-outline',
    codepoint: 'F114C',
    deprecated: 'FALSE',
  },
  {
    id: 'F7FA1307-CC98-4B93-B42A-533F1F6E851C',
    name: 'dice-4',
    codepoint: 'F01CD',
    deprecated: 'FALSE',
  },
  {
    id: 'A24BDBB1-DF5E-4022-8AF0-3D4989B0F2EE',
    name: 'dice-4-outline',
    codepoint: 'F114D',
    deprecated: 'FALSE',
  },
  {
    id: '9B9F7FE0-5AA9-4B5F-B865-D7F21C1382AF',
    name: 'dice-5',
    codepoint: 'F01CE',
    deprecated: 'FALSE',
  },
  {
    id: '07A26D74-6460-4AD8-9470-E4E0A39E9DAB',
    name: 'dice-5-outline',
    codepoint: 'F114E',
    deprecated: 'FALSE',
  },
  {
    id: '015F173B-CA59-4125-8F19-925ED108BE07',
    name: 'dice-6',
    codepoint: 'F01CF',
    deprecated: 'FALSE',
  },
  {
    id: '359B124F-4377-4580-9C7C-494539D087DB',
    name: 'dice-6-outline',
    codepoint: 'F114F',
    deprecated: 'FALSE',
  },
  {
    id: 'F80FBAEF-6EE5-4F02-AAA8-7E3FBA618708',
    name: 'dice-d10',
    codepoint: 'F1153',
    deprecated: 'FALSE',
  },
  {
    id: '47BB1E5C-0CCD-4A50-B1EF-2437411217C0',
    name: 'dice-d10-outline',
    codepoint: 'F076F',
    deprecated: 'FALSE',
  },
  {
    id: '87285D65-197B-46D6-BAD6-F9EE53DD5F28',
    name: 'dice-d12',
    codepoint: 'F1154',
    deprecated: 'FALSE',
  },
  {
    id: 'E27CCA75-CE3E-4C7D-81AA-D3A1391A819E',
    name: 'dice-d12-outline',
    codepoint: 'F0867',
    deprecated: 'FALSE',
  },
  {
    id: '948F2639-1E9E-478F-BCC9-4EC7147F8616',
    name: 'dice-d20',
    codepoint: 'F1155',
    deprecated: 'FALSE',
  },
  {
    id: '926FAA94-AB58-44AA-916C-66AF56A5BCCF',
    name: 'dice-d20-outline',
    codepoint: 'F05EA',
    deprecated: 'FALSE',
  },
  {
    id: '876805AB-7EC6-4CD8-A9D4-17213004A83A',
    name: 'dice-d4',
    codepoint: 'F1150',
    deprecated: 'FALSE',
  },
  {
    id: 'DCC141FA-CA87-48E1-969D-BB010C973DD5',
    name: 'dice-d4-outline',
    codepoint: 'F05EB',
    deprecated: 'FALSE',
  },
  {
    id: 'A62117B1-58C5-4A44-B6B1-C6E1DCEB7E00',
    name: 'dice-d6',
    codepoint: 'F1151',
    deprecated: 'FALSE',
  },
  {
    id: 'A0F00FDF-6648-475B-819B-29E4C0769FFA',
    name: 'dice-d6-outline',
    codepoint: 'F05ED',
    deprecated: 'FALSE',
  },
  {
    id: '5EA075B9-0455-42DA-AB57-47BAFADF0160',
    name: 'dice-d8',
    codepoint: 'F1152',
    deprecated: 'FALSE',
  },
  {
    id: '64967BE6-CAAF-4F28-BF55-C5451D4E8B94',
    name: 'dice-d8-outline',
    codepoint: 'F05EC',
    deprecated: 'FALSE',
  },
  {
    id: '8C9D5084-1B34-4AB9-BE23-E44B03AE85E5',
    name: 'dice-multiple',
    codepoint: 'F076E',
    deprecated: 'FALSE',
  },
  {
    id: '4F078165-F841-4013-B24C-4FA639C7F708',
    name: 'dice-multiple-outline',
    codepoint: 'F1156',
    deprecated: 'FALSE',
  },
  {
    id: '58BA7D31-6B34-46E6-B325-080C95B32A6F',
    name: 'digital-ocean',
    codepoint: 'F1237',
    deprecated: 'TRUE',
  },
  {
    id: '2356EE38-11BE-44B1-BAD9-D74ECE86F8E7',
    name: 'dip-switch',
    codepoint: 'F07C1',
    deprecated: 'FALSE',
  },
  {
    id: '4C84603B-BEB6-4282-B6CE-D9AD3C098627',
    name: 'directions',
    codepoint: 'F01D0',
    deprecated: 'FALSE',
  },
  {
    id: '8F53DAC3-E72D-473E-ABD1-AC1849EB660A',
    name: 'directions-fork',
    codepoint: 'F0641',
    deprecated: 'FALSE',
  },
  {
    id: '95CEA0DD-5123-4FFA-B906-E6C55766030D',
    name: 'disc',
    codepoint: 'F05EE',
    deprecated: 'FALSE',
  },
  {
    id: '63A41E0F-CCDB-42F8-848B-F64AA94F9BDF',
    name: 'disc-alert',
    codepoint: 'F01D1',
    deprecated: 'FALSE',
  },
  {
    id: 'DA84CDD7-3881-4DE0-8CEB-51F1BD086A3A',
    name: 'disc-player',
    codepoint: 'F0960',
    deprecated: 'FALSE',
  },
  {
    id: '41CB517E-41F5-47D4-A8A2-E9512CB065DD',
    name: 'dishwasher',
    codepoint: 'F0AAC',
    deprecated: 'FALSE',
  },
  {
    id: 'A72D6C1A-A953-4F9B-BD38-0E0ACF1A6C58',
    name: 'dishwasher-alert',
    codepoint: 'F11B8',
    deprecated: 'FALSE',
  },
  {
    id: '5BB3E0C6-278F-4008-8327-0124D7A209A6',
    name: 'dishwasher-off',
    codepoint: 'F11B9',
    deprecated: 'FALSE',
  },
  {
    id: '28670432-3B76-43FC-B879-558B338F1E1F',
    name: 'disqus',
    codepoint: 'F01D2',
    deprecated: 'TRUE',
  },
  {
    id: 'BA55B495-6775-4070-9ADC-FF56EB8E067D',
    name: 'distribute-horizontal-center',
    codepoint: 'F11C9',
    deprecated: 'FALSE',
  },
  {
    id: '22C7EA3A-483D-4BFD-A115-42F86F164135',
    name: 'distribute-horizontal-left',
    codepoint: 'F11C8',
    deprecated: 'FALSE',
  },
  {
    id: '771135B5-F856-4AAE-9FD1-8F728964DE2F',
    name: 'distribute-horizontal-right',
    codepoint: 'F11CA',
    deprecated: 'FALSE',
  },
  {
    id: 'DDF54E4F-65E8-407A-961F-5B02A3A0DC37',
    name: 'distribute-vertical-bottom',
    codepoint: 'F11CB',
    deprecated: 'FALSE',
  },
  {
    id: '35AAECBA-CF24-4DCC-99F0-20D9CB21B58B',
    name: 'distribute-vertical-center',
    codepoint: 'F11CC',
    deprecated: 'FALSE',
  },
  {
    id: 'F1C73D50-8C76-4269-8CBB-D232C55BD100',
    name: 'distribute-vertical-top',
    codepoint: 'F11CD',
    deprecated: 'FALSE',
  },
  {
    id: 'A951E9F9-30A8-4B95-AAFC-1B926A4F1E17',
    name: 'diversify',
    codepoint: 'F1877',
    deprecated: 'FALSE',
  },
  {
    id: '813D489B-3127-4F32-9D6E-4BB8A6F51605',
    name: 'diving',
    codepoint: 'F1977',
    deprecated: 'FALSE',
  },
  {
    id: 'B57B062B-66A2-4F90-85A3-97446E1934D2',
    name: 'diving-flippers',
    codepoint: 'F0DBF',
    deprecated: 'FALSE',
  },
  {
    id: '4732B7F8-F8EF-4E5A-A889-BA5770B1B4FF',
    name: 'diving-helmet',
    codepoint: 'F0DC0',
    deprecated: 'FALSE',
  },
  {
    id: '8BE8E8A6-2A2A-490D-91AB-D7C6AEFEC68C',
    name: 'diving-scuba',
    codepoint: 'F1B77',
    deprecated: 'FALSE',
  },
  {
    id: '7B600593-08CB-42D0-B106-0EDF12132BFC',
    name: 'diving-scuba-flag',
    codepoint: 'F0DC2',
    deprecated: 'FALSE',
  },
  {
    id: '6C2E4BEE-6D01-4B09-A501-CE976EE20740',
    name: 'diving-scuba-mask',
    codepoint: 'F0DC1',
    deprecated: 'FALSE',
  },
  {
    id: '64055E84-7F77-4020-A105-313AAD36353E',
    name: 'diving-scuba-tank',
    codepoint: 'F0DC3',
    deprecated: 'FALSE',
  },
  {
    id: '868AEE51-0552-43D0-97FA-9F783BB2A67C',
    name: 'diving-scuba-tank-multiple',
    codepoint: 'F0DC4',
    deprecated: 'FALSE',
  },
  {
    id: '227F84AF-3BC0-4C9D-BADB-DC8B1A110ED8',
    name: 'diving-snorkel',
    codepoint: 'F0DC5',
    deprecated: 'FALSE',
  },
  {
    id: 'FB313489-5EA3-4F07-ABA4-F84995D7DD13',
    name: 'division',
    codepoint: 'F01D4',
    deprecated: 'FALSE',
  },
  {
    id: '1A0BABA0-5F5E-45ED-A4BB-DCBD569F6E43',
    name: 'division-box',
    codepoint: 'F01D5',
    deprecated: 'FALSE',
  },
  {
    id: '15C7622F-855B-4FFA-BA1A-ED0ED470078B',
    name: 'dlna',
    codepoint: 'F0A41',
    deprecated: 'TRUE',
  },
  {
    id: '2C726E7E-53C8-410C-82F4-FE5DE9EF4BC1',
    name: 'dna',
    codepoint: 'F0684',
    deprecated: 'FALSE',
  },
  {
    id: 'D4A20191-F5B8-4323-A0A8-F4C15A86A83B',
    name: 'dns',
    codepoint: 'F01D6',
    deprecated: 'FALSE',
  },
  {
    id: 'D81BA495-F83D-4CFF-A9A5-B398D7E53DC7',
    name: 'dns-outline',
    codepoint: 'F0B8C',
    deprecated: 'FALSE',
  },
  {
    id: 'E6B5C99C-7C0F-4F61-AEF9-7FDF6851D426',
    name: 'dock-bottom',
    codepoint: 'F10A9',
    deprecated: 'FALSE',
  },
  {
    id: '59BABD0B-E745-4389-B318-4E166F2ED0C0',
    name: 'dock-left',
    codepoint: 'F10AA',
    deprecated: 'FALSE',
  },
  {
    id: '3AF2E71C-A865-4512-8B41-3803BFA84C48',
    name: 'dock-right',
    codepoint: 'F10AB',
    deprecated: 'FALSE',
  },
  {
    id: '4014E87F-B3F7-465B-B84D-ED2408AA95EE',
    name: 'dock-top',
    codepoint: 'F1513',
    deprecated: 'FALSE',
  },
  {
    id: '6EF77419-3085-4805-804C-096C6D8A4450',
    name: 'dock-window',
    codepoint: 'F10AC',
    deprecated: 'FALSE',
  },
  {
    id: '8EE88381-B350-4C63-B26D-F340FE54D668',
    name: 'docker',
    codepoint: 'F0868',
    deprecated: 'TRUE',
  },
  {
    id: '3772A377-C71A-429B-A4A6-3979DA3BC588',
    name: 'doctor',
    codepoint: 'F0A42',
    deprecated: 'FALSE',
  },
  {
    id: '8D6EB954-B4C9-44D5-9E70-C4E186C35957',
    name: 'dog',
    codepoint: 'F0A43',
    deprecated: 'FALSE',
  },
  {
    id: 'C0CF99F2-5D8E-4478-9602-9866AF83B94B',
    name: 'dog-service',
    codepoint: 'F0AAD',
    deprecated: 'FALSE',
  },
  {
    id: '4A3EEFF6-77EF-498F-9450-7AE3D3EC7BC6',
    name: 'dog-side',
    codepoint: 'F0A44',
    deprecated: 'FALSE',
  },
  {
    id: '74FE76CF-E5A5-42F3-97BD-0BCDF17DBFFF',
    name: 'dog-side-off',
    codepoint: 'F16EE',
    deprecated: 'FALSE',
  },
  {
    id: 'FE71CD75-B2E7-49A5-B00B-732BACB52F6F',
    name: 'dolby',
    codepoint: 'F06B3',
    deprecated: 'TRUE',
  },
  {
    id: '6F2EAC1C-802A-48CF-A9E1-7EBFD4893BAF',
    name: 'dolly',
    codepoint: 'F0E9E',
    deprecated: 'FALSE',
  },
  {
    id: '578351FA-0F35-41B0-BC7C-A9B33EBEBC4D',
    name: 'dolphin',
    codepoint: 'F18B4',
    deprecated: 'FALSE',
  },
  {
    id: 'CA264BA4-62A9-4823-A172-22DD413A6CF5',
    name: 'domain',
    codepoint: 'F01D7',
    deprecated: 'FALSE',
  },
  {
    id: '77232563-20F2-4C73-B30E-5F83D237E7D7',
    name: 'domain-off',
    codepoint: 'F0D6F',
    deprecated: 'FALSE',
  },
  {
    id: 'C19B8872-80EF-439B-A7B8-5F043DC66991',
    name: 'domain-plus',
    codepoint: 'F10AD',
    deprecated: 'FALSE',
  },
  {
    id: 'BB1C669D-D6EA-47D7-B15C-10A236D214AA',
    name: 'domain-remove',
    codepoint: 'F10AE',
    deprecated: 'FALSE',
  },
  {
    id: 'E17E8B8F-361D-4467-97BE-71C3ABE9355A',
    name: 'dome-light',
    codepoint: 'F141E',
    deprecated: 'FALSE',
  },
  {
    id: '4621ACB1-6F3D-414D-8724-B276982DB087',
    name: 'domino-mask',
    codepoint: 'F1023',
    deprecated: 'FALSE',
  },
  {
    id: '7027E5FC-228D-4E32-B2BB-02287E94D132',
    name: 'donkey',
    codepoint: 'F07C2',
    deprecated: 'FALSE',
  },
  {
    id: '858F8782-5001-4950-96F2-65032091A847',
    name: 'door',
    codepoint: 'F081A',
    deprecated: 'FALSE',
  },
  {
    id: 'DFE96305-5114-4F53-AB08-08F99F37C2FE',
    name: 'door-closed',
    codepoint: 'F081B',
    deprecated: 'FALSE',
  },
  {
    id: '792A98B0-E031-43CF-AF8B-ACF21C97C4C2',
    name: 'door-closed-lock',
    codepoint: 'F10AF',
    deprecated: 'FALSE',
  },
  {
    id: 'A64D06DE-1EE4-400F-9E17-2B5D319475A3',
    name: 'door-open',
    codepoint: 'F081C',
    deprecated: 'FALSE',
  },
  {
    id: '73FBEFFD-7EE3-4272-AB1F-A480C698FD5E',
    name: 'door-sliding',
    codepoint: 'F181E',
    deprecated: 'FALSE',
  },
  {
    id: '933F6908-12B7-4448-9A83-1FAD909ECC53',
    name: 'door-sliding-lock',
    codepoint: 'F181F',
    deprecated: 'FALSE',
  },
  {
    id: 'B8358F0C-19A7-4E55-AF98-F4FEFA983148',
    name: 'door-sliding-open',
    codepoint: 'F1820',
    deprecated: 'FALSE',
  },
  {
    id: '91D0BB43-E5D4-4F0B-89E7-B379A6F9A455',
    name: 'doorbell',
    codepoint: 'F12E6',
    deprecated: 'FALSE',
  },
  {
    id: '63848BB0-8CBA-491A-9D3A-5964BAA67CC2',
    name: 'doorbell-video',
    codepoint: 'F0869',
    deprecated: 'FALSE',
  },
  {
    id: 'E05A9EE4-A599-4195-93F9-0E4024670E81',
    name: 'dot-net',
    codepoint: 'F0AAE',
    deprecated: 'TRUE',
  },
  {
    id: 'FDC05366-F73E-4D60-B048-8AA4732B2C1F',
    name: 'dots-circle',
    codepoint: 'F1978',
    deprecated: 'FALSE',
  },
  {
    id: '9BBDE78A-ADFF-4FFB-AC79-C7B1FDAB633E',
    name: 'dots-grid',
    codepoint: 'F15FC',
    deprecated: 'FALSE',
  },
  {
    id: '92CE1CCF-7652-4292-831D-CFE1ADBB54B6',
    name: 'dots-hexagon',
    codepoint: 'F15FF',
    deprecated: 'FALSE',
  },
  {
    id: '9156AFAA-25BB-4E97-9EC8-BB9BE9AF60B0',
    name: 'dots-horizontal',
    codepoint: 'F01D8',
    deprecated: 'FALSE',
  },
  {
    id: '2ED6A664-DAE9-427C-8810-496C331C9BE0',
    name: 'dots-horizontal-circle',
    codepoint: 'F07C3',
    deprecated: 'FALSE',
  },
  {
    id: '378E8311-F963-41EC-9AFF-5B30EF975313',
    name: 'dots-horizontal-circle-outline',
    codepoint: 'F0B8D',
    deprecated: 'FALSE',
  },
  {
    id: '57A090F9-EBDF-4A60-8B83-1D5E5A61B73E',
    name: 'dots-square',
    codepoint: 'F15FD',
    deprecated: 'FALSE',
  },
  {
    id: '37FA940A-635C-48A5-988B-D1F4BBC0A7E0',
    name: 'dots-triangle',
    codepoint: 'F15FE',
    deprecated: 'FALSE',
  },
  {
    id: '5D70B3D1-8407-42C9-A3C1-42EE68FF18F3',
    name: 'dots-vertical',
    codepoint: 'F01D9',
    deprecated: 'FALSE',
  },
  {
    id: '5D23E65D-271A-463B-972F-E8524E76E6D7',
    name: 'dots-vertical-circle',
    codepoint: 'F07C4',
    deprecated: 'FALSE',
  },
  {
    id: 'E2CD4D50-D64C-47B9-AF3B-C464D65CD4EB',
    name: 'dots-vertical-circle-outline',
    codepoint: 'F0B8E',
    deprecated: 'FALSE',
  },
  {
    id: '704F7397-0E85-4213-8D76-FE156DF1795F',
    name: 'download',
    codepoint: 'F01DA',
    deprecated: 'FALSE',
  },
  {
    id: '49E1E432-8C28-43AA-818C-EB62ACF5EC06',
    name: 'download-box',
    codepoint: 'F1462',
    deprecated: 'FALSE',
  },
  {
    id: '48A14899-8641-40E7-BF9D-06BD6AE9BB54',
    name: 'download-box-outline',
    codepoint: 'F1463',
    deprecated: 'FALSE',
  },
  {
    id: 'B4B33DFC-4361-478D-A3A3-3FD224B95C16',
    name: 'download-circle',
    codepoint: 'F1464',
    deprecated: 'FALSE',
  },
  {
    id: '0F2CB73D-1357-4004-88DE-6E57549B8F1C',
    name: 'download-circle-outline',
    codepoint: 'F1465',
    deprecated: 'FALSE',
  },
  {
    id: 'F70D7D6A-B496-4182-A423-939E2CCF21AC',
    name: 'download-lock',
    codepoint: 'F1320',
    deprecated: 'FALSE',
  },
  {
    id: 'CDB71553-149F-452B-A0DC-2CD8EFA41150',
    name: 'download-lock-outline',
    codepoint: 'F1321',
    deprecated: 'FALSE',
  },
  {
    id: 'EEECC393-B78C-43DE-BDA4-F31DACA25F56',
    name: 'download-multiple',
    codepoint: 'F09E9',
    deprecated: 'FALSE',
  },
  {
    id: '8DD93DC3-060D-4CB3-AB41-8A1A5243BFFD',
    name: 'download-network',
    codepoint: 'F06F4',
    deprecated: 'FALSE',
  },
  {
    id: 'EACBF539-FF17-4F63-8782-AB4146D9B072',
    name: 'download-network-outline',
    codepoint: 'F0C66',
    deprecated: 'FALSE',
  },
  {
    id: 'C582FB3E-A708-411C-91C8-FA660E313AAE',
    name: 'download-off',
    codepoint: 'F10B0',
    deprecated: 'FALSE',
  },
  {
    id: '0FFB4F5B-34FB-4E1F-AA6B-96C5CD8F9632',
    name: 'download-off-outline',
    codepoint: 'F10B1',
    deprecated: 'FALSE',
  },
  {
    id: 'AB9770C4-6886-4A46-8F4E-B96CBCCCF839',
    name: 'download-outline',
    codepoint: 'F0B8F',
    deprecated: 'FALSE',
  },
  {
    id: '2366C232-208F-4A03-9FC9-D9C8C52622CA',
    name: 'drag',
    codepoint: 'F01DB',
    deprecated: 'FALSE',
  },
  {
    id: '544A0702-E7DC-4E1F-9A6E-8785AA3048D8',
    name: 'drag-horizontal',
    codepoint: 'F01DC',
    deprecated: 'FALSE',
  },
  {
    id: 'FA5B1F76-C0C4-4F08-B545-D28E78781F67',
    name: 'drag-horizontal-variant',
    codepoint: 'F12F0',
    deprecated: 'FALSE',
  },
  {
    id: '5B3C7AEE-2539-4DD7-AA86-D5B3ED1EB097',
    name: 'drag-variant',
    codepoint: 'F0B90',
    deprecated: 'FALSE',
  },
  {
    id: '4B8D8E9B-547D-4DE3-B8E1-5023867EF965',
    name: 'drag-vertical',
    codepoint: 'F01DD',
    deprecated: 'FALSE',
  },
  {
    id: 'BAD1F323-E74E-4E04-A1F6-4CCC7CAC2E34',
    name: 'drag-vertical-variant',
    codepoint: 'F12F1',
    deprecated: 'FALSE',
  },
  {
    id: 'ACAF4137-3EA8-4B7C-A1DB-C34759C70A5D',
    name: 'drama-masks',
    codepoint: 'F0D02',
    deprecated: 'FALSE',
  },
  {
    id: 'ECDE04BF-F8A5-4DAD-B4F9-887CAE97CBE3',
    name: 'draw',
    codepoint: 'F0F49',
    deprecated: 'FALSE',
  },
  {
    id: '152EF3D9-A7C4-40AC-8691-D5A4D48CFD37',
    name: 'draw-pen',
    codepoint: 'F19B9',
    deprecated: 'FALSE',
  },
  {
    id: 'E8557F02-8241-47FB-B546-402947A5818C',
    name: 'drawing',
    codepoint: 'F01DE',
    deprecated: 'FALSE',
  },
  {
    id: 'BF9750EC-EB85-4670-A4DE-4808729249B5',
    name: 'drawing-box',
    codepoint: 'F01DF',
    deprecated: 'FALSE',
  },
  {
    id: 'BBA92DC7-D461-44FA-8FA2-6D8846368E5C',
    name: 'dresser',
    codepoint: 'F0F4A',
    deprecated: 'FALSE',
  },
  {
    id: 'B70C7178-DB6F-4172-8440-3A9003AA040D',
    name: 'dresser-outline',
    codepoint: 'F0F4B',
    deprecated: 'FALSE',
  },
  {
    id: 'B6A0EC44-BDF3-4001-ABBF-51E0F2522BD3',
    name: 'drone',
    codepoint: 'F01E2',
    deprecated: 'FALSE',
  },
  {
    id: 'C8E22510-9369-4C7C-B598-8799DB9FE2A2',
    name: 'dropbox',
    codepoint: 'F01E3',
    deprecated: 'TRUE',
  },
  {
    id: '5FCCAA35-0339-4B8F-984A-D4112C3277C1',
    name: 'drupal',
    codepoint: 'F01E4',
    deprecated: 'TRUE',
  },
  {
    id: '74F300CC-D7A5-45AF-A946-B219E82F8997',
    name: 'duck',
    codepoint: 'F01E5',
    deprecated: 'FALSE',
  },
  {
    id: 'E792948B-8E66-49B5-83E7-8CDF1ECE6C5D',
    name: 'dumbbell',
    codepoint: 'F01E6',
    deprecated: 'FALSE',
  },
  {
    id: '50BCAB01-5182-4FBA-9F6D-62DF6872B743',
    name: 'dump-truck',
    codepoint: 'F0C67',
    deprecated: 'FALSE',
  },
  {
    id: '9F02DA93-0C4D-4A77-8F6B-E4220480AB55',
    name: 'ear-hearing',
    codepoint: 'F07C5',
    deprecated: 'FALSE',
  },
  {
    id: 'E95219F9-AA02-4031-940F-D76757183F96',
    name: 'ear-hearing-loop',
    codepoint: 'F1AEE',
    deprecated: 'FALSE',
  },
  {
    id: '78ACE34A-8AC2-4259-AD8D-1118A11C6800',
    name: 'ear-hearing-off',
    codepoint: 'F0A45',
    deprecated: 'FALSE',
  },
  {
    id: 'F809C249-6B8A-4A3E-B945-7EA3D0F71AB0',
    name: 'earbuds',
    codepoint: 'F184F',
    deprecated: 'FALSE',
  },
  {
    id: '3938606F-6B8D-45D2-A835-8F3A08E97CB1',
    name: 'earbuds-off',
    codepoint: 'F1850',
    deprecated: 'FALSE',
  },
  {
    id: 'E9DB591B-AB1E-4C5B-9596-63EA899D54AF',
    name: 'earbuds-off-outline',
    codepoint: 'F1851',
    deprecated: 'FALSE',
  },
  {
    id: '15AB1BAE-A458-4107-9C96-07BF755F3EBA',
    name: 'earbuds-outline',
    codepoint: 'F1852',
    deprecated: 'FALSE',
  },
  {
    id: '0D1618BF-A3AD-4B4A-92EA-5DBF93BF7625',
    name: 'earth',
    codepoint: 'F01E7',
    deprecated: 'FALSE',
  },
  {
    id: 'E6C7261B-1CC0-41EA-844B-0DA761CDCC12',
    name: 'earth-arrow-right',
    codepoint: 'F1311',
    deprecated: 'FALSE',
  },
  {
    id: '3F4C3330-49D5-4F2B-8FCC-A3448BEF521E',
    name: 'earth-box',
    codepoint: 'F06CD',
    deprecated: 'FALSE',
  },
  {
    id: 'C6EEACD5-6E55-4A4A-8E66-0CC8233470DA',
    name: 'earth-box-minus',
    codepoint: 'F1407',
    deprecated: 'FALSE',
  },
  {
    id: 'CAE8B8BC-E89F-49C3-8178-6F09ACED1FC3',
    name: 'earth-box-off',
    codepoint: 'F06CE',
    deprecated: 'FALSE',
  },
  {
    id: '5CEC6008-A993-410B-985A-4E41291BE614',
    name: 'earth-box-plus',
    codepoint: 'F1406',
    deprecated: 'FALSE',
  },
  {
    id: 'A7F1974D-8084-4EBF-A932-2051938CB985',
    name: 'earth-box-remove',
    codepoint: 'F1408',
    deprecated: 'FALSE',
  },
  {
    id: '1C8EADAE-667F-4048-A393-B05980A7CA11',
    name: 'earth-minus',
    codepoint: 'F1404',
    deprecated: 'FALSE',
  },
  {
    id: '519E0041-FD5F-4DAD-96A4-BED1EDE9A20B',
    name: 'earth-off',
    codepoint: 'F01E8',
    deprecated: 'FALSE',
  },
  {
    id: '046CC069-3771-4172-A9E6-F8D18161551F',
    name: 'earth-plus',
    codepoint: 'F1403',
    deprecated: 'FALSE',
  },
  {
    id: '75FA6A35-88AE-4790-9B1A-0D0FD453E472',
    name: 'earth-remove',
    codepoint: 'F1405',
    deprecated: 'FALSE',
  },
  {
    id: '993BB952-DF8E-47E6-AF9A-1FCBF15C6B63',
    name: 'egg',
    codepoint: 'F0AAF',
    deprecated: 'FALSE',
  },
  {
    id: '5A81CAFD-E110-476D-9F72-70CC1E05D164',
    name: 'egg-easter',
    codepoint: 'F0AB0',
    deprecated: 'FALSE',
  },
  {
    id: 'F7C1B0EE-A1E2-4376-BFB2-C0EEAF251B2C',
    name: 'egg-fried',
    codepoint: 'F184A',
    deprecated: 'FALSE',
  },
  {
    id: 'AF15DB15-3F15-4D7B-9EBA-EFA8E0735D30',
    name: 'egg-off',
    codepoint: 'F13F0',
    deprecated: 'FALSE',
  },
  {
    id: '1813E684-616A-403C-9D59-B1ED3373B750',
    name: 'egg-off-outline',
    codepoint: 'F13F1',
    deprecated: 'FALSE',
  },
  {
    id: '9C36DCFC-FBBE-4AE2-8E27-952D10E296E3',
    name: 'egg-outline',
    codepoint: 'F13F2',
    deprecated: 'FALSE',
  },
  {
    id: '2E11359F-82B1-469C-9A51-F7B0EFAA3171',
    name: 'eiffel-tower',
    codepoint: 'F156B',
    deprecated: 'FALSE',
  },
  {
    id: '589595AF-C8FF-451B-A623-2A22D0D2A181',
    name: 'eight-track',
    codepoint: 'F09EA',
    deprecated: 'FALSE',
  },
  {
    id: '4B57E9DF-354F-460D-9551-65C0DB55D788',
    name: 'eject',
    codepoint: 'F01EA',
    deprecated: 'FALSE',
  },
  {
    id: 'B9D113A6-7D1C-4290-9B95-D051DDBAB680',
    name: 'eject-circle',
    codepoint: 'F1B23',
    deprecated: 'FALSE',
  },
  {
    id: '300F7913-D559-4679-A7ED-A6F4C311E5BA',
    name: 'eject-circle-outline',
    codepoint: 'F1B24',
    deprecated: 'FALSE',
  },
  {
    id: '6E8EC35B-2DF2-4451-9649-E0DBA823E3B7',
    name: 'eject-outline',
    codepoint: 'F0B91',
    deprecated: 'FALSE',
  },
  {
    id: '70653C90-39FA-4FA7-81D9-F1D792253067',
    name: 'electric-switch',
    codepoint: 'F0E9F',
    deprecated: 'FALSE',
  },
  {
    id: '7F2511AE-AD30-41EB-AEEA-5CF18C81CF91',
    name: 'electric-switch-closed',
    codepoint: 'F10D9',
    deprecated: 'FALSE',
  },
  {
    id: 'B17B6901-E163-48A2-8101-E1986C1400C0',
    name: 'electron-framework',
    codepoint: 'F1024',
    deprecated: 'TRUE',
  },
  {
    id: '0332819B-1686-4375-86E9-0866AE19C2BF',
    name: 'elephant',
    codepoint: 'F07C6',
    deprecated: 'FALSE',
  },
  {
    id: '6057DE08-41EC-4DE6-9ACC-7FB5AF3D4536',
    name: 'elevation-decline',
    codepoint: 'F01EB',
    deprecated: 'FALSE',
  },
  {
    id: 'CEFE04E6-CBE8-4DDD-BCF2-F445579B68FE',
    name: 'elevation-rise',
    codepoint: 'F01EC',
    deprecated: 'FALSE',
  },
  {
    id: '8A9045D4-D6AC-4660-8C55-D622156A1B8C',
    name: 'elevator',
    codepoint: 'F01ED',
    deprecated: 'FALSE',
  },
  {
    id: '57DB3FF8-34D8-46A7-8D41-1A99EB31AD59',
    name: 'elevator-down',
    codepoint: 'F12C2',
    deprecated: 'FALSE',
  },
  {
    id: '559121CE-CD01-4B96-9C0B-66DF7EED8944',
    name: 'elevator-passenger',
    codepoint: 'F1381',
    deprecated: 'FALSE',
  },
  {
    id: 'CD32CB18-7B1F-4150-B4F4-322C3326BC48',
    name: 'elevator-passenger-off',
    codepoint: 'F1979',
    deprecated: 'FALSE',
  },
  {
    id: '2F62C19F-24FE-45C0-A43F-9A148C4B8042',
    name: 'elevator-passenger-off-outline',
    codepoint: 'F197A',
    deprecated: 'FALSE',
  },
  {
    id: '8343D53B-57D1-46B5-AC0F-295E3F261E74',
    name: 'elevator-passenger-outline',
    codepoint: 'F197B',
    deprecated: 'FALSE',
  },
  {
    id: '8EA26066-2C66-4A38-996B-8F23E288E0B7',
    name: 'elevator-up',
    codepoint: 'F12C1',
    deprecated: 'FALSE',
  },
  {
    id: '0CAAACF8-3D1C-4CCA-A3E4-3590CDEEC5D6',
    name: 'ellipse',
    codepoint: 'F0EA0',
    deprecated: 'FALSE',
  },
  {
    id: 'EA110B23-B08F-4DAE-8F23-37A298B89A74',
    name: 'ellipse-outline',
    codepoint: 'F0EA1',
    deprecated: 'FALSE',
  },
  {
    id: '14AAE073-8399-4D67-9F7E-8E9424328681',
    name: 'email',
    codepoint: 'F01EE',
    deprecated: 'FALSE',
  },
  {
    id: '8031577D-2528-4278-9F7B-93BD5DED18E3',
    name: 'email-alert',
    codepoint: 'F06CF',
    deprecated: 'FALSE',
  },
  {
    id: '91B50C6E-CEBB-42A7-8D07-8D73F2EB20C1',
    name: 'email-alert-outline',
    codepoint: 'F0D42',
    deprecated: 'FALSE',
  },
  {
    id: '68040E6F-6BCD-4505-94D6-55D1E11E82ED',
    name: 'email-arrow-left',
    codepoint: 'F10DA',
    deprecated: 'FALSE',
  },
  {
    id: '2DF250FF-F6DC-4C7D-A5A6-AFEAEBAD45F4',
    name: 'email-arrow-left-outline',
    codepoint: 'F10DB',
    deprecated: 'FALSE',
  },
  {
    id: '7C66176F-E1BF-4F04-A761-377F67916FDA',
    name: 'email-arrow-right',
    codepoint: 'F10DC',
    deprecated: 'FALSE',
  },
  {
    id: '795B1734-652A-4DCC-98B6-433C79C33001',
    name: 'email-arrow-right-outline',
    codepoint: 'F10DD',
    deprecated: 'FALSE',
  },
  {
    id: '6930A4EF-08EB-4649-B2BB-369269DA918F',
    name: 'email-box',
    codepoint: 'F0D03',
    deprecated: 'FALSE',
  },
  {
    id: '04876E3C-0FEA-43D5-9B5C-9CFFC711B180',
    name: 'email-check',
    codepoint: 'F0AB1',
    deprecated: 'FALSE',
  },
  {
    id: '465CA7E2-56E8-4AC8-9A0D-CFF225C3948A',
    name: 'email-check-outline',
    codepoint: 'F0AB2',
    deprecated: 'FALSE',
  },
  {
    id: '99DE8F28-37C2-4784-8B62-5E5FD499A664',
    name: 'email-edit',
    codepoint: 'F0EE3',
    deprecated: 'FALSE',
  },
  {
    id: 'D2C2975C-678E-40BB-A313-44340B286BBE',
    name: 'email-edit-outline',
    codepoint: 'F0EE4',
    deprecated: 'FALSE',
  },
  {
    id: '3BDD7A0B-DA90-459E-8219-58EDC08661FE',
    name: 'email-fast',
    codepoint: 'F186F',
    deprecated: 'FALSE',
  },
  {
    id: '8A3DDFE7-F2D8-47F1-8FFB-2B0C2145CE60',
    name: 'email-fast-outline',
    codepoint: 'F1870',
    deprecated: 'FALSE',
  },
  {
    id: '04288F6D-7E3C-4BB6-8FDE-B8951C0D0BDF',
    name: 'email-lock',
    codepoint: 'F01F1',
    deprecated: 'FALSE',
  },
  {
    id: 'F4DE5AAA-B343-46ED-B1D3-CBDA05A61E87',
    name: 'email-lock-outline',
    codepoint: 'F1B61',
    deprecated: 'FALSE',
  },
  {
    id: 'F5FF3FDF-2BEC-4DCB-A6AB-9FF483126C70',
    name: 'email-mark-as-unread',
    codepoint: 'F0B92',
    deprecated: 'FALSE',
  },
  {
    id: 'FCBACFB7-0431-4F59-BCEB-75D4325DC82C',
    name: 'email-minus',
    codepoint: 'F0EE5',
    deprecated: 'FALSE',
  },
  {
    id: 'F2B06422-BB50-4C83-B1F2-591120544972',
    name: 'email-minus-outline',
    codepoint: 'F0EE6',
    deprecated: 'FALSE',
  },
  {
    id: '7DCBC245-D966-4018-8E53-272058C73507',
    name: 'email-multiple',
    codepoint: 'F0EE7',
    deprecated: 'FALSE',
  },
  {
    id: 'A92BBC88-FCE7-41A7-813E-873B81C7F4E9',
    name: 'email-multiple-outline',
    codepoint: 'F0EE8',
    deprecated: 'FALSE',
  },
  {
    id: '97179388-624E-48F5-9E21-1179BE74829B',
    name: 'email-newsletter',
    codepoint: 'F0FB1',
    deprecated: 'FALSE',
  },
  {
    id: 'D14EAB52-8253-40C3-AABC-43267D588B01',
    name: 'email-off',
    codepoint: 'F13E3',
    deprecated: 'FALSE',
  },
  {
    id: '8495D960-EBB0-487C-A97B-64CF696DCDF4',
    name: 'email-off-outline',
    codepoint: 'F13E4',
    deprecated: 'FALSE',
  },
  {
    id: '1E550A45-9D97-4918-9DEC-F536EC5C7A1C',
    name: 'email-open',
    codepoint: 'F01EF',
    deprecated: 'FALSE',
  },
  {
    id: '36BC0D33-4834-41EA-8EA2-90285635005A',
    name: 'email-open-multiple',
    codepoint: 'F0EE9',
    deprecated: 'FALSE',
  },
  {
    id: 'A45E544D-6002-4F11-A03C-05221DE5C502',
    name: 'email-open-multiple-outline',
    codepoint: 'F0EEA',
    deprecated: 'FALSE',
  },
  {
    id: '4F7B4311-FC6B-463D-A2A3-760554AF144E',
    name: 'email-open-outline',
    codepoint: 'F05EF',
    deprecated: 'FALSE',
  },
  {
    id: '53CE3621-DBCC-45E8-B105-2BEA7493CCA0',
    name: 'email-outline',
    codepoint: 'F01F0',
    deprecated: 'FALSE',
  },
  {
    id: '910E199F-4A9F-473A-90A9-F69C05BD67F1',
    name: 'email-plus',
    codepoint: 'F09EB',
    deprecated: 'FALSE',
  },
  {
    id: '156DF0DC-6BDA-4204-BDDB-5FA69911CDDB',
    name: 'email-plus-outline',
    codepoint: 'F09EC',
    deprecated: 'FALSE',
  },
  {
    id: 'AB3FC883-2737-4D9D-AAF4-3695BD6C7882',
    name: 'email-remove',
    codepoint: 'F1661',
    deprecated: 'FALSE',
  },
  {
    id: '5EDCFB59-BBEC-407A-B079-5EC7E7686800',
    name: 'email-remove-outline',
    codepoint: 'F1662',
    deprecated: 'FALSE',
  },
  {
    id: '9671987F-A6B4-4934-90B1-E2C590CEB1B9',
    name: 'email-seal',
    codepoint: 'F195B',
    deprecated: 'FALSE',
  },
  {
    id: '5E7FB53A-EDC8-4FF3-AA66-708C617ECCD6',
    name: 'email-seal-outline',
    codepoint: 'F195C',
    deprecated: 'FALSE',
  },
  {
    id: 'AE2AF6F2-9E5A-4949-901F-0B5F599428C7',
    name: 'email-search',
    codepoint: 'F0961',
    deprecated: 'FALSE',
  },
  {
    id: '3BC4A964-0137-4E75-B25E-7F01EDFEE1A8',
    name: 'email-search-outline',
    codepoint: 'F0962',
    deprecated: 'FALSE',
  },
  {
    id: '72B2D41E-42AA-44E7-8F1E-42AC62577F73',
    name: 'email-sync',
    codepoint: 'F12C7',
    deprecated: 'FALSE',
  },
  {
    id: '0FAB1B0E-FEAB-4F12-A7FC-58D48CB9682F',
    name: 'email-sync-outline',
    codepoint: 'F12C8',
    deprecated: 'FALSE',
  },
  {
    id: '80BB05EB-8F75-4205-9952-60B09204A9F6',
    name: 'email-variant',
    codepoint: 'F05F0',
    deprecated: 'FALSE',
  },
  {
    id: '37F09EC6-7F8F-4DDB-BB18-774880A5C312',
    name: 'ember',
    codepoint: 'F0B30',
    deprecated: 'TRUE',
  },
  {
    id: '7CACE4E9-A4B1-461E-A8E5-8032C22E86A1',
    name: 'emby',
    codepoint: 'F06B4',
    deprecated: 'TRUE',
  },
  {
    id: 'E8F2B8BE-F4EC-4B9C-BB15-001752B29FFD',
    name: 'emoticon',
    codepoint: 'F0C68',
    deprecated: 'FALSE',
  },
  {
    id: '5DD5C53F-8D3F-43DE-81B9-667A5372A808',
    name: 'emoticon-angry',
    codepoint: 'F0C69',
    deprecated: 'FALSE',
  },
  {
    id: 'EEE3E9C1-D1B5-4D57-AD8B-7F6AD4852EE6',
    name: 'emoticon-angry-outline',
    codepoint: 'F0C6A',
    deprecated: 'FALSE',
  },
  {
    id: 'DD5A65E9-E309-457F-9662-94AAB236A703',
    name: 'emoticon-confused',
    codepoint: 'F10DE',
    deprecated: 'FALSE',
  },
  {
    id: 'CB195387-50AC-46A3-B1C4-F3DA96E0EE42',
    name: 'emoticon-confused-outline',
    codepoint: 'F10DF',
    deprecated: 'FALSE',
  },
  {
    id: '77FAFB8C-4322-4A18-8E92-0CE311F775D3',
    name: 'emoticon-cool',
    codepoint: 'F0C6B',
    deprecated: 'FALSE',
  },
  {
    id: '62BB1B4E-67AC-4DFE-B37C-65D0F04F71D0',
    name: 'emoticon-cool-outline',
    codepoint: 'F01F3',
    deprecated: 'FALSE',
  },
  {
    id: '39704938-DF4F-4E60-8C20-0569B4B1819C',
    name: 'emoticon-cry',
    codepoint: 'F0C6C',
    deprecated: 'FALSE',
  },
  {
    id: 'BA4B161E-72CB-476B-9894-20A90B28B4A3',
    name: 'emoticon-cry-outline',
    codepoint: 'F0C6D',
    deprecated: 'FALSE',
  },
  {
    id: '187185AC-5567-44DC-AC70-14B0D89C270C',
    name: 'emoticon-dead',
    codepoint: 'F0C6E',
    deprecated: 'FALSE',
  },
  {
    id: '47AD308C-C9D8-43DB-8E92-B0FB02B906CF',
    name: 'emoticon-dead-outline',
    codepoint: 'F069B',
    deprecated: 'FALSE',
  },
  {
    id: '7781F4B7-849F-42A4-82B1-791DBDE91295',
    name: 'emoticon-devil',
    codepoint: 'F0C6F',
    deprecated: 'FALSE',
  },
  {
    id: '916B1319-9E91-48C0-AD65-B21B599D8FCA',
    name: 'emoticon-devil-outline',
    codepoint: 'F01F4',
    deprecated: 'FALSE',
  },
  {
    id: '86E664CC-39C1-4E36-81BB-AC3BFFE65281',
    name: 'emoticon-excited',
    codepoint: 'F0C70',
    deprecated: 'FALSE',
  },
  {
    id: '828FF1EE-1B9B-4407-A45B-A071FA938158',
    name: 'emoticon-excited-outline',
    codepoint: 'F069C',
    deprecated: 'FALSE',
  },
  {
    id: 'D5AC778B-0269-4AC5-A2D8-FD5F4D27785F',
    name: 'emoticon-frown',
    codepoint: 'F0F4C',
    deprecated: 'FALSE',
  },
  {
    id: 'AE6333C6-8334-4F90-9230-6527F3A38C8A',
    name: 'emoticon-frown-outline',
    codepoint: 'F0F4D',
    deprecated: 'FALSE',
  },
  {
    id: 'CCA0187D-B8BA-4AC3-8523-B6B8F05EA5BA',
    name: 'emoticon-happy',
    codepoint: 'F0C71',
    deprecated: 'FALSE',
  },
  {
    id: 'F51C2659-3EE9-471C-A919-190D3C1A9681',
    name: 'emoticon-happy-outline',
    codepoint: 'F01F5',
    deprecated: 'FALSE',
  },
  {
    id: '01F0C520-7626-4BA6-A678-1EAB14AE2C1B',
    name: 'emoticon-kiss',
    codepoint: 'F0C72',
    deprecated: 'FALSE',
  },
  {
    id: '806569AC-841D-4B4E-849E-43BACC3D2268',
    name: 'emoticon-kiss-outline',
    codepoint: 'F0C73',
    deprecated: 'FALSE',
  },
  {
    id: '11CEDEB3-DB1A-45F4-92C9-6AF6FCEE4FC5',
    name: 'emoticon-lol',
    codepoint: 'F1214',
    deprecated: 'FALSE',
  },
  {
    id: '2A7BA4B1-C436-4EE6-BF5A-34B150A9A746',
    name: 'emoticon-lol-outline',
    codepoint: 'F1215',
    deprecated: 'FALSE',
  },
  {
    id: '922B06B7-9C78-452C-AF29-79A47F0F6931',
    name: 'emoticon-neutral',
    codepoint: 'F0C74',
    deprecated: 'FALSE',
  },
  {
    id: '17F30B32-37E2-41BB-876C-70E568D04623',
    name: 'emoticon-neutral-outline',
    codepoint: 'F01F6',
    deprecated: 'FALSE',
  },
  {
    id: '69AAECAA-0B17-4C53-8345-4F172B7FEF50',
    name: 'emoticon-outline',
    codepoint: 'F01F2',
    deprecated: 'FALSE',
  },
  {
    id: '968319FB-0E94-4F04-A6D8-9E71DF200E1F',
    name: 'emoticon-poop',
    codepoint: 'F01F7',
    deprecated: 'FALSE',
  },
  {
    id: '1E402C80-69DB-432F-BB0F-C05ACB6A7839',
    name: 'emoticon-poop-outline',
    codepoint: 'F0C75',
    deprecated: 'FALSE',
  },
  {
    id: 'CDA3C920-C286-4000-A7FC-C530CBC62CF7',
    name: 'emoticon-sad',
    codepoint: 'F0C76',
    deprecated: 'FALSE',
  },
  {
    id: '6D8A3A11-7A90-424C-82A6-93C455379743',
    name: 'emoticon-sad-outline',
    codepoint: 'F01F8',
    deprecated: 'FALSE',
  },
  {
    id: '523F5AB1-3598-40F1-AAB5-6DF361FF6970',
    name: 'emoticon-sick',
    codepoint: 'F157C',
    deprecated: 'FALSE',
  },
  {
    id: 'CBB4F1C3-3479-4F94-9F15-3D844889609A',
    name: 'emoticon-sick-outline',
    codepoint: 'F157D',
    deprecated: 'FALSE',
  },
  {
    id: '57288378-59EE-4705-ACD5-3B424ACA70C1',
    name: 'emoticon-tongue',
    codepoint: 'F01F9',
    deprecated: 'FALSE',
  },
  {
    id: 'A08556AE-A0AD-4C7E-9A14-0CD05EE66E03',
    name: 'emoticon-tongue-outline',
    codepoint: 'F0C77',
    deprecated: 'FALSE',
  },
  {
    id: '652D2244-1764-4B42-B04D-157DDD9DC464',
    name: 'emoticon-wink',
    codepoint: 'F0C78',
    deprecated: 'FALSE',
  },
  {
    id: 'C8E56EC6-52DA-4BC7-81C0-1E5B14509086',
    name: 'emoticon-wink-outline',
    codepoint: 'F0C79',
    deprecated: 'FALSE',
  },
  {
    id: '290CA835-F009-4630-87D3-D4515E1F5527',
    name: 'engine',
    codepoint: 'F01FA',
    deprecated: 'FALSE',
  },
  {
    id: '114C29E0-B66E-49EF-8D45-77FF8EED43B1',
    name: 'engine-off',
    codepoint: 'F0A46',
    deprecated: 'FALSE',
  },
  {
    id: '81317A61-047B-47D4-A154-CEA3C6B9F61C',
    name: 'engine-off-outline',
    codepoint: 'F0A47',
    deprecated: 'FALSE',
  },
  {
    id: '75A5F242-D136-49B9-9DFF-25F0631743A7',
    name: 'engine-outline',
    codepoint: 'F01FB',
    deprecated: 'FALSE',
  },
  {
    id: 'C0C7DFD3-F590-4965-A38F-167F48469E6F',
    name: 'epsilon',
    codepoint: 'F10E0',
    deprecated: 'FALSE',
  },
  {
    id: '119DD58E-769B-47C2-9AB8-1671DEC4FE4A',
    name: 'equal',
    codepoint: 'F01FC',
    deprecated: 'FALSE',
  },
  {
    id: 'F98CD2ED-FD62-414E-9116-94BA99713526',
    name: 'equal-box',
    codepoint: 'F01FD',
    deprecated: 'FALSE',
  },
  {
    id: '4F6A532E-58ED-4CB5-9BBB-9E77C128D536',
    name: 'equalizer',
    codepoint: 'F0EA2',
    deprecated: 'FALSE',
  },
  {
    id: 'D3AB11FD-F0D5-4228-A6C7-69AC34649A53',
    name: 'equalizer-outline',
    codepoint: 'F0EA3',
    deprecated: 'FALSE',
  },
  {
    id: '758CA292-BBF5-4C08-BCBD-88B7A84C8BC6',
    name: 'eraser',
    codepoint: 'F01FE',
    deprecated: 'FALSE',
  },
  {
    id: '8E45C70B-EE1A-48AE-B97F-204F1958E788',
    name: 'eraser-variant',
    codepoint: 'F0642',
    deprecated: 'FALSE',
  },
  {
    id: '2DB352CF-8F2F-43FD-BCA0-675F7C9D298C',
    name: 'escalator',
    codepoint: 'F01FF',
    deprecated: 'FALSE',
  },
  {
    id: 'B4972D96-B8A1-4964-8A58-FAEA2D0C5FF9',
    name: 'escalator-box',
    codepoint: 'F1399',
    deprecated: 'FALSE',
  },
  {
    id: 'F29A47AC-C3B6-4568-8ADA-94C5F92F0DC0',
    name: 'escalator-down',
    codepoint: 'F12C0',
    deprecated: 'FALSE',
  },
  {
    id: '4BA51088-9403-4E34-A829-1F31D8DE2646',
    name: 'escalator-up',
    codepoint: 'F12BF',
    deprecated: 'FALSE',
  },
  {
    id: '9D305B93-7722-4784-A9D3-8F64824A67AB',
    name: 'eslint',
    codepoint: 'F0C7A',
    deprecated: 'TRUE',
  },
  {
    id: 'C3E4BC72-74F1-450D-8B6F-FCF0CB60AA27',
    name: 'et',
    codepoint: 'F0AB3',
    deprecated: 'FALSE',
  },
  {
    id: 'D59C885E-233F-4472-AB8C-00C41F96134E',
    name: 'ethereum',
    codepoint: 'F086A',
    deprecated: 'TRUE',
  },
  {
    id: '3AB3C243-0FD3-4903-A6EA-B0B29ABFAD6C',
    name: 'ethernet',
    codepoint: 'F0200',
    deprecated: 'FALSE',
  },
  {
    id: 'FC900F32-A228-4E42-9E09-EA7CCDEC08F2',
    name: 'ethernet-cable',
    codepoint: 'F0201',
    deprecated: 'FALSE',
  },
  {
    id: '79E1B678-8458-4750-9EE0-C5CE7E57E9F1',
    name: 'ethernet-cable-off',
    codepoint: 'F0202',
    deprecated: 'FALSE',
  },
  {
    id: '7C687673-B2F0-43B6-A5E3-E25B9FFA0F8E',
    name: 'ev-plug-ccs1',
    codepoint: 'F1519',
    deprecated: 'FALSE',
  },
  {
    id: '4FE92E92-170B-495E-A16E-0897AD787E02',
    name: 'ev-plug-ccs2',
    codepoint: 'F151A',
    deprecated: 'FALSE',
  },
  {
    id: 'C2C4C1DC-0FB1-4A1A-AA85-8FD1D8DF4532',
    name: 'ev-plug-chademo',
    codepoint: 'F151B',
    deprecated: 'FALSE',
  },
  {
    id: '04B5C8D6-9732-4D4D-AC1B-0C9ED9E3B8DF',
    name: 'ev-plug-tesla',
    codepoint: 'F151C',
    deprecated: 'FALSE',
  },
  {
    id: '781567F7-B41B-4CB2-BDD8-DDF3AB75CC57',
    name: 'ev-plug-type1',
    codepoint: 'F151D',
    deprecated: 'FALSE',
  },
  {
    id: 'F5687378-A23D-4D4C-98A2-55E95642FD06',
    name: 'ev-plug-type2',
    codepoint: 'F151E',
    deprecated: 'FALSE',
  },
  {
    id: '7C859DE1-16F6-4EF2-9097-A1D0C14CC3A2',
    name: 'ev-station',
    codepoint: 'F05F1',
    deprecated: 'FALSE',
  },
  {
    id: '193F4AB4-12B2-4E3C-965F-284967A7F736',
    name: 'evernote',
    codepoint: 'F0204',
    deprecated: 'TRUE',
  },
  {
    id: '0BD56A12-3F3B-4A3E-96E4-868B5414FD06',
    name: 'excavator',
    codepoint: 'F1025',
    deprecated: 'FALSE',
  },
  {
    id: '06E68A9B-2ED4-4697-82A2-02F1FA342D55',
    name: 'exclamation',
    codepoint: 'F0205',
    deprecated: 'FALSE',
  },
  {
    id: '2DEA5CA1-8412-4DA4-95C4-7E1AA2C27404',
    name: 'exclamation-thick',
    codepoint: 'F1238',
    deprecated: 'FALSE',
  },
  {
    id: '9F68722F-B500-48AD-BE3A-EB7591496084',
    name: 'exit-run',
    codepoint: 'F0A48',
    deprecated: 'FALSE',
  },
  {
    id: 'DD2BE6C6-A15C-44D4-807A-C29DE965CF22',
    name: 'exit-to-app',
    codepoint: 'F0206',
    deprecated: 'FALSE',
  },
  {
    id: '85080AB7-D860-4D74-86D8-1A2BFF0C0514',
    name: 'expand-all',
    codepoint: 'F0AB4',
    deprecated: 'FALSE',
  },
  {
    id: '934C9329-AC58-499E-BE89-A5E43FA71C6F',
    name: 'expand-all-outline',
    codepoint: 'F0AB5',
    deprecated: 'FALSE',
  },
  {
    id: '6FFC7116-2E5B-40A8-A612-90706DC5EECF',
    name: 'expansion-card',
    codepoint: 'F08AE',
    deprecated: 'FALSE',
  },
  {
    id: 'A644DCA0-BDC5-480E-AA15-EFD1B1D4BDEB',
    name: 'expansion-card-variant',
    codepoint: 'F0FB2',
    deprecated: 'FALSE',
  },
  {
    id: 'E26684D6-5292-4B09-98A9-C6C416FD236D',
    name: 'exponent',
    codepoint: 'F0963',
    deprecated: 'FALSE',
  },
  {
    id: 'F061DE5C-F495-4EDF-BD16-DAD6B07605BC',
    name: 'exponent-box',
    codepoint: 'F0964',
    deprecated: 'FALSE',
  },
  {
    id: '670C2CEA-D9B1-4126-89F4-C881D032F3AD',
    name: 'export',
    codepoint: 'F0207',
    deprecated: 'FALSE',
  },
  {
    id: 'ECCA4152-AD73-4986-ADC4-9FACDC925868',
    name: 'export-variant',
    codepoint: 'F0B93',
    deprecated: 'FALSE',
  },
  {
    id: '3C2B7DF3-97FE-4EC4-B6A9-69A75FB26B67',
    name: 'eye',
    codepoint: 'F0208',
    deprecated: 'FALSE',
  },
  {
    id: 'B3FA5237-3FE4-4822-87EE-0001DD784EE7',
    name: 'eye-arrow-left',
    codepoint: 'F18FD',
    deprecated: 'FALSE',
  },
  {
    id: 'D979FDDF-45AE-4F37-AC6C-A92B2860BC02',
    name: 'eye-arrow-left-outline',
    codepoint: 'F18FE',
    deprecated: 'FALSE',
  },
  {
    id: 'FFAF8600-64E9-4DEF-B689-D14B95B608CA',
    name: 'eye-arrow-right',
    codepoint: 'F18FF',
    deprecated: 'FALSE',
  },
  {
    id: 'A682E4C4-6048-4BF7-BBCC-57167CE33687',
    name: 'eye-arrow-right-outline',
    codepoint: 'F1900',
    deprecated: 'FALSE',
  },
  {
    id: '7534EBC5-1A5B-4837-8C13-88CFA5FB4D49',
    name: 'eye-check',
    codepoint: 'F0D04',
    deprecated: 'FALSE',
  },
  {
    id: 'A2829948-6FFA-48BB-B387-959A7E3658DE',
    name: 'eye-check-outline',
    codepoint: 'F0D05',
    deprecated: 'FALSE',
  },
  {
    id: '9E70DE8C-C973-4A18-A68D-DB6670F5F38A',
    name: 'eye-circle',
    codepoint: 'F0B94',
    deprecated: 'FALSE',
  },
  {
    id: '4F1A805C-7120-44A2-9A3F-8285411EFE87',
    name: 'eye-circle-outline',
    codepoint: 'F0B95',
    deprecated: 'FALSE',
  },
  {
    id: 'D0F73A84-81BB-47B2-993B-0FB019C854B4',
    name: 'eye-lock',
    codepoint: 'F1C06',
    deprecated: 'FALSE',
  },
  {
    id: 'BC6FEA4D-DD3C-4EAD-9402-CF1153E8AE52',
    name: 'eye-lock-open',
    codepoint: 'F1C07',
    deprecated: 'FALSE',
  },
  {
    id: '89FC8E98-DF54-42AA-99BA-4AC234151FC6',
    name: 'eye-lock-open-outline',
    codepoint: 'F1C08',
    deprecated: 'FALSE',
  },
  {
    id: 'EA9ACDD8-28EF-41E1-BE27-64979D52FB0D',
    name: 'eye-lock-outline',
    codepoint: 'F1C09',
    deprecated: 'FALSE',
  },
  {
    id: '67AEA391-FE01-41D0-B594-36433AAB099E',
    name: 'eye-minus',
    codepoint: 'F1026',
    deprecated: 'FALSE',
  },
  {
    id: 'F36594D9-9F22-4C8B-BB09-6B89CF203881',
    name: 'eye-minus-outline',
    codepoint: 'F1027',
    deprecated: 'FALSE',
  },
  {
    id: '4D1385CF-1389-43D3-BD88-A83018434FB6',
    name: 'eye-off',
    codepoint: 'F0209',
    deprecated: 'FALSE',
  },
  {
    id: 'D88D00BE-0B70-40F5-AFD0-51C65DE8D674',
    name: 'eye-off-outline',
    codepoint: 'F06D1',
    deprecated: 'FALSE',
  },
  {
    id: 'F2AA59FA-23F6-4FE8-A52A-AEC674A9E864',
    name: 'eye-outline',
    codepoint: 'F06D0',
    deprecated: 'FALSE',
  },
  {
    id: '644FD0C7-1B46-48D8-A410-FDA22E63BFCF',
    name: 'eye-plus',
    codepoint: 'F086B',
    deprecated: 'FALSE',
  },
  {
    id: 'C82BEE88-7AAC-4F8D-9A8A-4B0C7CCDF216',
    name: 'eye-plus-outline',
    codepoint: 'F086C',
    deprecated: 'FALSE',
  },
  {
    id: '2CC60524-7C0C-4AE3-A996-E24066985409',
    name: 'eye-refresh',
    codepoint: 'F197C',
    deprecated: 'FALSE',
  },
  {
    id: 'B9A630A0-AF26-4EBC-A822-DFFE9AA01878',
    name: 'eye-refresh-outline',
    codepoint: 'F197D',
    deprecated: 'FALSE',
  },
  {
    id: '4CAD2E1D-F56B-40AA-B008-46DEFF9C9540',
    name: 'eye-remove',
    codepoint: 'F15E3',
    deprecated: 'FALSE',
  },
  {
    id: 'A1F300D2-BA71-410B-BB70-F18FC666CF35',
    name: 'eye-remove-outline',
    codepoint: 'F15E4',
    deprecated: 'FALSE',
  },
  {
    id: '1155E86D-5CA5-4CC0-B089-23DFFC1A3DDD',
    name: 'eye-settings',
    codepoint: 'F086D',
    deprecated: 'FALSE',
  },
  {
    id: '45112AC7-F34D-40E8-8C37-D8660B7017E9',
    name: 'eye-settings-outline',
    codepoint: 'F086E',
    deprecated: 'FALSE',
  },
  {
    id: '89350468-16D8-4F39-AA78-C3E00EE8FB2C',
    name: 'eyedropper',
    codepoint: 'F020A',
    deprecated: 'FALSE',
  },
  {
    id: '384E765A-8184-4E9C-8300-FB4928CADA31',
    name: 'eyedropper-minus',
    codepoint: 'F13DD',
    deprecated: 'FALSE',
  },
  {
    id: 'F4A65E44-6B1F-4E9C-954A-B05FAAB3EE6D',
    name: 'eyedropper-off',
    codepoint: 'F13DF',
    deprecated: 'FALSE',
  },
  {
    id: 'FF767930-F19C-4935-8ABB-566DD8B8CF0D',
    name: 'eyedropper-plus',
    codepoint: 'F13DC',
    deprecated: 'FALSE',
  },
  {
    id: '1224B69E-00A5-422C-A358-61201346175F',
    name: 'eyedropper-remove',
    codepoint: 'F13DE',
    deprecated: 'FALSE',
  },
  {
    id: '24A1E7A8-D8A4-4216-B467-72C144972358',
    name: 'eyedropper-variant',
    codepoint: 'F020B',
    deprecated: 'FALSE',
  },
  {
    id: '3E0B74E0-1F0F-4820-A02F-B370CD56E463',
    name: 'face-agent',
    codepoint: 'F0D70',
    deprecated: 'FALSE',
  },
  {
    id: 'D41E7F40-FF48-422F-A64B-4908C6C10F00',
    name: 'face-man',
    codepoint: 'F0643',
    deprecated: 'FALSE',
  },
  {
    id: 'CFDA13BC-C61A-4FE0-8094-7B51E8EB3F82',
    name: 'face-man-outline',
    codepoint: 'F0B96',
    deprecated: 'FALSE',
  },
  {
    id: '97E9CDDB-45B3-4592-8E1F-0DB66E16E1A3',
    name: 'face-man-profile',
    codepoint: 'F0644',
    deprecated: 'FALSE',
  },
  {
    id: '4680BCD4-CD85-45A0-9F1C-FB367E768664',
    name: 'face-man-shimmer',
    codepoint: 'F15CC',
    deprecated: 'FALSE',
  },
  {
    id: 'CC7A66A4-2118-4635-B227-C184D8903867',
    name: 'face-man-shimmer-outline',
    codepoint: 'F15CD',
    deprecated: 'FALSE',
  },
  {
    id: '8E3EC802-4BC1-44CA-83AB-9A43E55E238C',
    name: 'face-mask',
    codepoint: 'F1586',
    deprecated: 'FALSE',
  },
  {
    id: '0143A0CC-E3FA-485F-B3CA-6B61EE86F066',
    name: 'face-mask-outline',
    codepoint: 'F1587',
    deprecated: 'FALSE',
  },
  {
    id: 'FDC4CFE0-5CA9-4F8E-A126-EC98BF900228',
    name: 'face-recognition',
    codepoint: 'F0C7B',
    deprecated: 'FALSE',
  },
  {
    id: 'C591017E-E3D0-48CA-A517-86B21DE853A0',
    name: 'face-woman',
    codepoint: 'F1077',
    deprecated: 'FALSE',
  },
  {
    id: 'F8B0D936-DFD2-469C-B671-599D3B277B51',
    name: 'face-woman-outline',
    codepoint: 'F1078',
    deprecated: 'FALSE',
  },
  {
    id: '144C6FB3-DBAC-4CA7-B07C-51265D8EE619',
    name: 'face-woman-profile',
    codepoint: 'F1076',
    deprecated: 'FALSE',
  },
  {
    id: '97F4C885-AB98-45C3-85C7-E5DA25ECD271',
    name: 'face-woman-shimmer',
    codepoint: 'F15CE',
    deprecated: 'FALSE',
  },
  {
    id: '43851D4C-D310-45C3-9A86-6BF5DFD51055',
    name: 'face-woman-shimmer-outline',
    codepoint: 'F15CF',
    deprecated: 'FALSE',
  },
  {
    id: '95CC9E46-A553-42B3-B44E-2E88DB13C1E1',
    name: 'facebook',
    codepoint: 'F020C',
    deprecated: 'TRUE',
  },
  {
    id: '62F96579-80AE-4827-9C18-1C1FD239DE49',
    name: 'facebook-gaming',
    codepoint: 'F07DD',
    deprecated: 'TRUE',
  },
  {
    id: '1989DA16-DAA9-4C95-A61F-BA512FE5592D',
    name: 'facebook-messenger',
    codepoint: 'F020E',
    deprecated: 'TRUE',
  },
  {
    id: '767ACA6B-1ECD-4D4E-AE5D-4E1EB3C87D72',
    name: 'facebook-workplace',
    codepoint: 'F0B31',
    deprecated: 'TRUE',
  },
  {
    id: 'B74F1DE6-5DEC-4866-AEED-DE85FE09C52F',
    name: 'factory',
    codepoint: 'F020F',
    deprecated: 'FALSE',
  },
  {
    id: 'AB0F3BD7-1D6E-4ED6-8CC4-28E5ECCEF017',
    name: 'family-tree',
    codepoint: 'F160E',
    deprecated: 'FALSE',
  },
  {
    id: '2606F8A3-AFD7-41EB-BD2D-C3546FEADB2F',
    name: 'fan',
    codepoint: 'F0210',
    deprecated: 'FALSE',
  },
  {
    id: '94B156B3-7C70-4C0A-B1A8-99BEEA9C6357',
    name: 'fan-alert',
    codepoint: 'F146C',
    deprecated: 'FALSE',
  },
  {
    id: 'E695511A-9F45-44DC-9F98-9E946CC02ECC',
    name: 'fan-auto',
    codepoint: 'F171D',
    deprecated: 'FALSE',
  },
  {
    id: '33DAA830-9E16-4AF0-A1B8-F44EA4EF9787',
    name: 'fan-chevron-down',
    codepoint: 'F146D',
    deprecated: 'FALSE',
  },
  {
    id: '1FC6584B-6B87-40C8-BA9D-C18E221A247D',
    name: 'fan-chevron-up',
    codepoint: 'F146E',
    deprecated: 'FALSE',
  },
  {
    id: 'BF3F7855-4971-4FA7-9BB8-8ED99E697D7A',
    name: 'fan-clock',
    codepoint: 'F1A3A',
    deprecated: 'FALSE',
  },
  {
    id: 'FB53FDB5-A8D0-42F6-882F-63C1A683577E',
    name: 'fan-minus',
    codepoint: 'F1470',
    deprecated: 'FALSE',
  },
  {
    id: 'BB761A30-6F3C-484D-A0C9-501972F544AB',
    name: 'fan-off',
    codepoint: 'F081D',
    deprecated: 'FALSE',
  },
  {
    id: 'DD3CD93C-5877-4234-9944-61C10457FDCE',
    name: 'fan-plus',
    codepoint: 'F146F',
    deprecated: 'FALSE',
  },
  {
    id: '3E4A1DFD-92C2-4CA0-BD74-A98496721825',
    name: 'fan-remove',
    codepoint: 'F1471',
    deprecated: 'FALSE',
  },
  {
    id: 'F01D5822-D36A-4A41-9544-3B1E8702582F',
    name: 'fan-speed-1',
    codepoint: 'F1472',
    deprecated: 'FALSE',
  },
  {
    id: 'AFC8D4DB-4196-41E6-8EBC-A6509F96EA71',
    name: 'fan-speed-2',
    codepoint: 'F1473',
    deprecated: 'FALSE',
  },
  {
    id: '0EA7825B-6236-40D5-A8D9-858B2B4DB595',
    name: 'fan-speed-3',
    codepoint: 'F1474',
    deprecated: 'FALSE',
  },
  {
    id: '98B9D071-70A9-422B-BCC1-55DC4C2E0744',
    name: 'fast-forward',
    codepoint: 'F0211',
    deprecated: 'FALSE',
  },
  {
    id: '960E88C3-D86B-4A9A-8581-799816D12194',
    name: 'fast-forward-10',
    codepoint: 'F0D71',
    deprecated: 'FALSE',
  },
  {
    id: '5C0FCEDF-44B3-4F3A-8D6A-6AB54EBC55F6',
    name: 'fast-forward-15',
    codepoint: 'F193A',
    deprecated: 'FALSE',
  },
  {
    id: '23895783-905C-45DE-BF43-D58214199B75',
    name: 'fast-forward-30',
    codepoint: 'F0D06',
    deprecated: 'FALSE',
  },
  {
    id: '4F6C14E9-E2B0-4A04-B5F8-F33035191724',
    name: 'fast-forward-45',
    codepoint: 'F1B12',
    deprecated: 'FALSE',
  },
  {
    id: 'C57CDDE7-5F5E-44B4-B1E2-E5FEFBB17A85',
    name: 'fast-forward-5',
    codepoint: 'F11F8',
    deprecated: 'FALSE',
  },
  {
    id: 'CF6C15FF-1B2F-4E4B-AE45-C1487815CA07',
    name: 'fast-forward-60',
    codepoint: 'F160B',
    deprecated: 'FALSE',
  },
  {
    id: '05435BA8-4BB4-4581-A2BA-D2D7679B831F',
    name: 'fast-forward-outline',
    codepoint: 'F06D2',
    deprecated: 'FALSE',
  },
  {
    id: 'AEE38946-ADDE-4AFA-B450-D13590926CA4',
    name: 'faucet',
    codepoint: 'F1B29',
    deprecated: 'FALSE',
  },
  {
    id: '17559442-5EF5-416D-A96A-51ED8526C530',
    name: 'faucet-variant',
    codepoint: 'F1B2A',
    deprecated: 'FALSE',
  },
  {
    id: '2C398569-5DBF-4405-AF73-6F5CA629AEE1',
    name: 'fax',
    codepoint: 'F0212',
    deprecated: 'FALSE',
  },
  {
    id: 'A0C975EA-A25D-4882-96DE-39CB72DAC2CA',
    name: 'feather',
    codepoint: 'F06D3',
    deprecated: 'FALSE',
  },
  {
    id: 'FC8210F4-FE5E-4A83-86DE-DA1B19739118',
    name: 'feature-search',
    codepoint: 'F0A49',
    deprecated: 'FALSE',
  },
  {
    id: 'AE8C42B6-43EE-433C-83A3-83CE7464240E',
    name: 'feature-search-outline',
    codepoint: 'F0A4A',
    deprecated: 'FALSE',
  },
  {
    id: '15F2366C-5930-4473-B298-A5B952F07D59',
    name: 'fedora',
    codepoint: 'F08DB',
    deprecated: 'TRUE',
  },
  {
    id: 'CAFE2FF6-D1D5-4411-9AA1-33C5076F0E4E',
    name: 'fence',
    codepoint: 'F179A',
    deprecated: 'FALSE',
  },
  {
    id: '9B54CB49-9186-4EB1-80D2-A92E616B9603',
    name: 'fence-electric',
    codepoint: 'F17F6',
    deprecated: 'FALSE',
  },
  {
    id: '325210DA-E889-4CBD-B0A7-8A32D706322B',
    name: 'fencing',
    codepoint: 'F14C1',
    deprecated: 'FALSE',
  },
  {
    id: 'AD285056-9A97-4C52-8D7D-06D4D1DA4213',
    name: 'ferris-wheel',
    codepoint: 'F0EA4',
    deprecated: 'FALSE',
  },
  {
    id: 'F138AC6A-406B-4EB0-BB85-0FDB5415B6F8',
    name: 'ferry',
    codepoint: 'F0213',
    deprecated: 'FALSE',
  },
  {
    id: 'BFF59001-B52D-47E6-A217-C9095F81C3B8',
    name: 'file',
    codepoint: 'F0214',
    deprecated: 'FALSE',
  },
  {
    id: '94A8A0E0-B69E-4099-A32E-34B83449AFBC',
    name: 'file-account',
    codepoint: 'F073B',
    deprecated: 'FALSE',
  },
  {
    id: 'E0043E71-ED74-419B-82A9-10D678DF74B3',
    name: 'file-account-outline',
    codepoint: 'F1028',
    deprecated: 'FALSE',
  },
  {
    id: '33050FDD-7F04-44B1-AF59-BFED56FE34CF',
    name: 'file-alert',
    codepoint: 'F0A4B',
    deprecated: 'FALSE',
  },
  {
    id: '0FF64B42-C9F6-4A0A-B09D-38D2AED8C81C',
    name: 'file-alert-outline',
    codepoint: 'F0A4C',
    deprecated: 'FALSE',
  },
  {
    id: '05D3C734-5B81-429E-8D2D-45E1AF5CD6A7',
    name: 'file-arrow-left-right',
    codepoint: 'F1A93',
    deprecated: 'FALSE',
  },
  {
    id: 'F926E7AB-0C0A-45B6-BC16-F922C8CB6BED',
    name: 'file-arrow-left-right-outline',
    codepoint: 'F1A94',
    deprecated: 'FALSE',
  },
  {
    id: '8D429186-1CB7-4DC1-AD90-43F2F41AF424',
    name: 'file-arrow-up-down',
    codepoint: 'F1A95',
    deprecated: 'FALSE',
  },
  {
    id: '4D08B9C5-576C-430B-9319-597190ABA71D',
    name: 'file-arrow-up-down-outline',
    codepoint: 'F1A96',
    deprecated: 'FALSE',
  },
  {
    id: '1B650467-2000-4A2D-B16D-9BD8A8F32B85',
    name: 'file-cabinet',
    codepoint: 'F0AB6',
    deprecated: 'FALSE',
  },
  {
    id: 'AA9999FD-3434-42B9-B589-606EF1D563EA',
    name: 'file-cad',
    codepoint: 'F0EEB',
    deprecated: 'FALSE',
  },
  {
    id: '7FF119BB-AE68-49A0-8104-0C4CA4FE829F',
    name: 'file-cad-box',
    codepoint: 'F0EEC',
    deprecated: 'FALSE',
  },
  {
    id: '1C9C326F-B0B6-41A9-825D-83A458689345',
    name: 'file-cancel',
    codepoint: 'F0DC6',
    deprecated: 'FALSE',
  },
  {
    id: '1DCEA17F-A76F-412E-8C25-52C9F3D63B48',
    name: 'file-cancel-outline',
    codepoint: 'F0DC7',
    deprecated: 'FALSE',
  },
  {
    id: '5BEF006A-53C0-4401-87DB-F21642667712',
    name: 'file-certificate',
    codepoint: 'F1186',
    deprecated: 'FALSE',
  },
  {
    id: '06595A20-8863-4B8C-B74D-C1DA1D32D88F',
    name: 'file-certificate-outline',
    codepoint: 'F1187',
    deprecated: 'FALSE',
  },
  {
    id: '303BB890-634F-405C-B77C-4AC831BCAEC4',
    name: 'file-chart',
    codepoint: 'F0215',
    deprecated: 'FALSE',
  },
  {
    id: 'B7778AA5-6982-4820-A733-996314D07B82',
    name: 'file-chart-check',
    codepoint: 'F19C6',
    deprecated: 'FALSE',
  },
  {
    id: '8051BED5-0582-4A3A-91F3-E29484840427',
    name: 'file-chart-check-outline',
    codepoint: 'F19C7',
    deprecated: 'FALSE',
  },
  {
    id: 'E8F550E7-DE55-47CE-B548-4B6987B9FDE7',
    name: 'file-chart-outline',
    codepoint: 'F1029',
    deprecated: 'FALSE',
  },
  {
    id: '9E66E6CF-74AD-4DB5-953B-DC135B4AD44C',
    name: 'file-check',
    codepoint: 'F0216',
    deprecated: 'FALSE',
  },
  {
    id: '8F78A49B-57D2-4DCC-8A49-8E07F3F5C0D5',
    name: 'file-check-outline',
    codepoint: 'F0E29',
    deprecated: 'FALSE',
  },
  {
    id: 'AA0F1B90-E844-4BE2-BD8F-6D7AAB2451AB',
    name: 'file-clock',
    codepoint: 'F12E1',
    deprecated: 'FALSE',
  },
  {
    id: '90AB6510-AEEC-4C00-BCFD-71B3F6E591CC',
    name: 'file-clock-outline',
    codepoint: 'F12E2',
    deprecated: 'FALSE',
  },
  {
    id: '452A53B0-3718-4483-ABB0-2897EBE97312',
    name: 'file-cloud',
    codepoint: 'F0217',
    deprecated: 'FALSE',
  },
  {
    id: '99B45E9A-4FCA-429F-B98D-1E9C0EAAE0CF',
    name: 'file-cloud-outline',
    codepoint: 'F102A',
    deprecated: 'FALSE',
  },
  {
    id: 'F11E3989-CBFD-4431-BDC1-44AEBBCDD8F6',
    name: 'file-code',
    codepoint: 'F022E',
    deprecated: 'FALSE',
  },
  {
    id: 'B226F0F1-F2C9-45C1-A940-2517FAC9F623',
    name: 'file-code-outline',
    codepoint: 'F102B',
    deprecated: 'FALSE',
  },
  {
    id: '73890E82-6C92-4578-B1D4-51D83CB5DDDE',
    name: 'file-cog',
    codepoint: 'F107B',
    deprecated: 'FALSE',
  },
  {
    id: '6ABE5D01-4204-46C0-B0D8-B3904C2E64C2',
    name: 'file-cog-outline',
    codepoint: 'F107C',
    deprecated: 'FALSE',
  },
  {
    id: '2169C66B-967F-4AB4-9123-A8A2C6129D6C',
    name: 'file-compare',
    codepoint: 'F08AA',
    deprecated: 'FALSE',
  },
  {
    id: '46650D6B-318F-4202-AB77-79A113EC77AA',
    name: 'file-delimited',
    codepoint: 'F0218',
    deprecated: 'FALSE',
  },
  {
    id: '35CE4750-7CA9-4C9B-AB11-5C1F2A092C9C',
    name: 'file-delimited-outline',
    codepoint: 'F0EA5',
    deprecated: 'FALSE',
  },
  {
    id: 'B8F65B26-E57F-4879-852E-D894E4ACAB65',
    name: 'file-document',
    codepoint: 'F0219',
    deprecated: 'FALSE',
  },
  {
    id: '67AF0ACC-DE69-4E39-8226-B913764EAFE5',
    name: 'file-document-alert',
    codepoint: 'F1A97',
    deprecated: 'FALSE',
  },
  {
    id: 'CF5F798F-BB0B-4F5A-98C4-3CFADF57BAA3',
    name: 'file-document-alert-outline',
    codepoint: 'F1A98',
    deprecated: 'FALSE',
  },
  {
    id: '31314AE5-195A-4F48-847C-AC6D8A69360D',
    name: 'file-document-arrow-right',
    codepoint: 'F1C0F',
    deprecated: 'FALSE',
  },
  {
    id: 'DF8391C4-0B76-4A72-A39E-4DDB40092B8C',
    name: 'file-document-arrow-right-outline',
    codepoint: 'F1C10',
    deprecated: 'FALSE',
  },
  {
    id: 'F51E0270-13FD-4DA5-945B-FE8C4C45AF60',
    name: 'file-document-check',
    codepoint: 'F1A99',
    deprecated: 'FALSE',
  },
  {
    id: 'AC9C336A-83D0-4FB0-B61A-C6AC0FC2A069',
    name: 'file-document-check-outline',
    codepoint: 'F1A9A',
    deprecated: 'FALSE',
  },
  {
    id: '83E06672-D36B-41FF-8463-080EB639F00F',
    name: 'file-document-edit',
    codepoint: 'F0DC8',
    deprecated: 'FALSE',
  },
  {
    id: 'E33F3608-CA6A-4BC3-BA3B-16AC369F10FD',
    name: 'file-document-edit-outline',
    codepoint: 'F0DC9',
    deprecated: 'FALSE',
  },
  {
    id: 'A7695AE5-5CF1-42B3-BBAE-516D143CF87C',
    name: 'file-document-minus',
    codepoint: 'F1A9B',
    deprecated: 'FALSE',
  },
  {
    id: '17759ECD-FE1E-446F-96C1-ED8C0F5EA783',
    name: 'file-document-minus-outline',
    codepoint: 'F1A9C',
    deprecated: 'FALSE',
  },
  {
    id: '05A5AF1D-055E-4FBB-BDD4-D20F3EF105DC',
    name: 'file-document-multiple',
    codepoint: 'F1517',
    deprecated: 'FALSE',
  },
  {
    id: 'F0545588-B29A-454E-B023-4FC4ED30BCDD',
    name: 'file-document-multiple-outline',
    codepoint: 'F1518',
    deprecated: 'FALSE',
  },
  {
    id: '5CB4D11F-6B3A-465D-A2CD-F4172AD287B0',
    name: 'file-document-outline',
    codepoint: 'F09EE',
    deprecated: 'FALSE',
  },
  {
    id: '2382A261-E45B-429D-A2E2-29DA654064E1',
    name: 'file-document-plus',
    codepoint: 'F1A9D',
    deprecated: 'FALSE',
  },
  {
    id: '99FAD22E-FB80-45AA-9423-3E4E2A369F1F',
    name: 'file-document-plus-outline',
    codepoint: 'F1A9E',
    deprecated: 'FALSE',
  },
  {
    id: 'E5F73E5B-9876-469A-B992-EC6DA553DE1B',
    name: 'file-document-remove',
    codepoint: 'F1A9F',
    deprecated: 'FALSE',
  },
  {
    id: '9125073F-13EB-4FAD-AE78-30E02AC5B906',
    name: 'file-document-remove-outline',
    codepoint: 'F1AA0',
    deprecated: 'FALSE',
  },
  {
    id: '305DC085-34BD-4CC1-8943-E8A3B64FD110',
    name: 'file-download',
    codepoint: 'F0965',
    deprecated: 'FALSE',
  },
  {
    id: '50DFA593-C997-41BD-B77E-2468649E2C1B',
    name: 'file-download-outline',
    codepoint: 'F0966',
    deprecated: 'FALSE',
  },
  {
    id: '65092A6A-E7E4-42BF-91F7-9AE7F50727D4',
    name: 'file-edit',
    codepoint: 'F11E7',
    deprecated: 'FALSE',
  },
  {
    id: '2EE03BED-7F6F-422E-9AAE-AAED4B591266',
    name: 'file-edit-outline',
    codepoint: 'F11E8',
    deprecated: 'FALSE',
  },
  {
    id: 'C3AADE94-4136-4D8E-8DFE-9AA7C88EE5D5',
    name: 'file-excel',
    codepoint: 'F021B',
    deprecated: 'FALSE',
  },
  {
    id: 'A2FE83DC-3544-4D13-85BD-2C54CDC1A460',
    name: 'file-excel-box',
    codepoint: 'F021C',
    deprecated: 'FALSE',
  },
  {
    id: '0C257A18-698B-4FF4-BC68-828FFF2A9C34',
    name: 'file-excel-box-outline',
    codepoint: 'F102C',
    deprecated: 'FALSE',
  },
  {
    id: '73706EB5-5497-459A-B9C7-1C64089F31CE',
    name: 'file-excel-outline',
    codepoint: 'F102D',
    deprecated: 'FALSE',
  },
  {
    id: '5693BB52-A00A-45E6-8DDD-C84FE3D9D440',
    name: 'file-export',
    codepoint: 'F021D',
    deprecated: 'FALSE',
  },
  {
    id: '25475106-D60D-4D23-BF07-4BAADB0EA9AA',
    name: 'file-export-outline',
    codepoint: 'F102E',
    deprecated: 'FALSE',
  },
  {
    id: '1417ADBB-4A11-448B-A6BA-E5D4B5A20BFF',
    name: 'file-eye',
    codepoint: 'F0DCA',
    deprecated: 'FALSE',
  },
  {
    id: 'A2B55937-B45F-441A-802F-4FAD69EB2144',
    name: 'file-eye-outline',
    codepoint: 'F0DCB',
    deprecated: 'FALSE',
  },
  {
    id: 'A9D2E13D-A9F6-48AC-9998-22756DA6C709',
    name: 'file-find',
    codepoint: 'F021E',
    deprecated: 'FALSE',
  },
  {
    id: '6CB8D807-BD25-4270-80F7-F71A8D4D57C7',
    name: 'file-find-outline',
    codepoint: 'F0B97',
    deprecated: 'FALSE',
  },
  {
    id: '7FCFB449-8096-408A-88E4-B73343DB201F',
    name: 'file-gif-box',
    codepoint: 'F0D78',
    deprecated: 'FALSE',
  },
  {
    id: '22033CAF-C8AF-4C33-9FCD-9D6FC4EE7EAF',
    name: 'file-hidden',
    codepoint: 'F0613',
    deprecated: 'FALSE',
  },
  {
    id: '2318EAB3-F03A-40C3-A245-668B244A9AC1',
    name: 'file-image',
    codepoint: 'F021F',
    deprecated: 'FALSE',
  },
  {
    id: '5E5F502C-C492-45EC-886C-CC55C6E4C504',
    name: 'file-image-marker',
    codepoint: 'F1772',
    deprecated: 'FALSE',
  },
  {
    id: 'BE46BBCE-2872-4DBE-976E-AC9BC2CE19D3',
    name: 'file-image-marker-outline',
    codepoint: 'F1773',
    deprecated: 'FALSE',
  },
  {
    id: 'C287BF1A-101C-42E3-BD19-3F1D8D3FDAA6',
    name: 'file-image-minus',
    codepoint: 'F193B',
    deprecated: 'FALSE',
  },
  {
    id: '533EE300-7DC5-4F89-898D-E1E005303AFD',
    name: 'file-image-minus-outline',
    codepoint: 'F193C',
    deprecated: 'FALSE',
  },
  {
    id: 'C86DDD85-B79F-43C4-9497-839FF4BD7526',
    name: 'file-image-outline',
    codepoint: 'F0EB0',
    deprecated: 'FALSE',
  },
  {
    id: '687ED5E3-8466-48F0-A5D3-1D5EBB95B9F4',
    name: 'file-image-plus',
    codepoint: 'F193D',
    deprecated: 'FALSE',
  },
  {
    id: '0D25D768-827E-4FE8-9A45-67D883E3B6FA',
    name: 'file-image-plus-outline',
    codepoint: 'F193E',
    deprecated: 'FALSE',
  },
  {
    id: 'EB4547CB-7AEA-4FB0-B840-5ABA6FA4F1F8',
    name: 'file-image-remove',
    codepoint: 'F193F',
    deprecated: 'FALSE',
  },
  {
    id: '472463C9-3A6A-47E9-9ED9-9DEF17C3ACDA',
    name: 'file-image-remove-outline',
    codepoint: 'F1940',
    deprecated: 'FALSE',
  },
  {
    id: '1294020B-960A-4390-916F-BF8118A6E7C6',
    name: 'file-import',
    codepoint: 'F0220',
    deprecated: 'FALSE',
  },
  {
    id: 'EA70B568-90C1-4512-BF97-00F4AD27F459',
    name: 'file-import-outline',
    codepoint: 'F102F',
    deprecated: 'FALSE',
  },
  {
    id: 'D5A23F56-0398-40EF-8B0D-640CD1F39669',
    name: 'file-jpg-box',
    codepoint: 'F0225',
    deprecated: 'FALSE',
  },
  {
    id: 'E854DC61-449E-4BA2-BAC7-656A3BB3D775',
    name: 'file-key',
    codepoint: 'F1184',
    deprecated: 'FALSE',
  },
  {
    id: '4E2214AD-9EB5-498C-BA9B-38072C463B43',
    name: 'file-key-outline',
    codepoint: 'F1185',
    deprecated: 'FALSE',
  },
  {
    id: 'AD9A7D67-1BCF-42D6-8321-AFE1705DE2A6',
    name: 'file-link',
    codepoint: 'F1177',
    deprecated: 'FALSE',
  },
  {
    id: '337D2BB6-6230-4896-B51B-9A8527FF3DFA',
    name: 'file-link-outline',
    codepoint: 'F1178',
    deprecated: 'FALSE',
  },
  {
    id: '1D017133-E895-4103-98C1-E90D26126003',
    name: 'file-lock',
    codepoint: 'F0221',
    deprecated: 'FALSE',
  },
  {
    id: 'E59676A5-2E82-4B91-8887-4ADDEB5E2204',
    name: 'file-lock-open',
    codepoint: 'F19C8',
    deprecated: 'FALSE',
  },
  {
    id: 'D94F03DE-9FA8-4AF4-AB95-3EDB4DB97522',
    name: 'file-lock-open-outline',
    codepoint: 'F19C9',
    deprecated: 'FALSE',
  },
  {
    id: '658B01A1-E16D-4D8E-B274-C66465BF1CE3',
    name: 'file-lock-outline',
    codepoint: 'F1030',
    deprecated: 'FALSE',
  },
  {
    id: 'C6C876E6-1A2C-4776-9A4C-5114EF50D3DA',
    name: 'file-marker',
    codepoint: 'F1774',
    deprecated: 'FALSE',
  },
  {
    id: '96A7C92B-3EDA-40C8-B21D-45355968345C',
    name: 'file-marker-outline',
    codepoint: 'F1775',
    deprecated: 'FALSE',
  },
  {
    id: '64C92887-5567-486D-97DC-C0353EA9EA98',
    name: 'file-minus',
    codepoint: 'F1AA1',
    deprecated: 'FALSE',
  },
  {
    id: '02C02D19-06BA-45DF-AE33-40EA2B7F5E19',
    name: 'file-minus-outline',
    codepoint: 'F1AA2',
    deprecated: 'FALSE',
  },
  {
    id: 'BB72067F-4002-409B-BD46-6FD6F9CEBA51',
    name: 'file-move',
    codepoint: 'F0AB9',
    deprecated: 'FALSE',
  },
  {
    id: 'B1BE9CC2-F8BF-4EB9-94BB-0B3041D0FBB4',
    name: 'file-move-outline',
    codepoint: 'F1031',
    deprecated: 'FALSE',
  },
  {
    id: '7696AF65-B943-4BA3-94DB-5E85077F7BA9',
    name: 'file-multiple',
    codepoint: 'F0222',
    deprecated: 'FALSE',
  },
  {
    id: '13BC9B18-661D-4517-84E9-756EC2536F4E',
    name: 'file-multiple-outline',
    codepoint: 'F1032',
    deprecated: 'FALSE',
  },
  {
    id: 'C6CD26A5-C5F6-4C5D-AF2C-919E8ED4166C',
    name: 'file-music',
    codepoint: 'F0223',
    deprecated: 'FALSE',
  },
  {
    id: 'C40C225E-F046-442C-BA4D-0531B626609F',
    name: 'file-music-outline',
    codepoint: 'F0E2A',
    deprecated: 'FALSE',
  },
  {
    id: '36FCB7D4-D7DE-4B44-BD28-53615689D2C6',
    name: 'file-outline',
    codepoint: 'F0224',
    deprecated: 'FALSE',
  },
  {
    id: 'C09B20B5-3650-4F36-AC40-540ACE36EB6E',
    name: 'file-pdf-box',
    codepoint: 'F0226',
    deprecated: 'FALSE',
  },
  {
    id: '267C5350-A0FC-4683-9FA1-30CD64AAA7E5',
    name: 'file-percent',
    codepoint: 'F081E',
    deprecated: 'FALSE',
  },
  {
    id: '5ED4C3D2-3E7E-4C28-8FC7-63DE741E186A',
    name: 'file-percent-outline',
    codepoint: 'F1033',
    deprecated: 'FALSE',
  },
  {
    id: 'DA6C6F33-3262-442B-8E07-C22ACA9F3F94',
    name: 'file-phone',
    codepoint: 'F1179',
    deprecated: 'FALSE',
  },
  {
    id: '2ED01F6A-EC44-442B-925B-F3A194574EBA',
    name: 'file-phone-outline',
    codepoint: 'F117A',
    deprecated: 'FALSE',
  },
  {
    id: '9C42F83F-4C75-4E0A-A1C2-F10FA032E495',
    name: 'file-plus',
    codepoint: 'F0752',
    deprecated: 'FALSE',
  },
  {
    id: '3BC5B0CB-8FEA-4C63-A9E3-59471DF11FA1',
    name: 'file-plus-outline',
    codepoint: 'F0EED',
    deprecated: 'FALSE',
  },
  {
    id: '4DEDCDD8-4C71-46DA-A8C6-04B424DF621C',
    name: 'file-png-box',
    codepoint: 'F0E2D',
    deprecated: 'FALSE',
  },
  {
    id: '62D44147-9B6F-4B4A-A06B-1AF92C2E7F3E',
    name: 'file-powerpoint',
    codepoint: 'F0227',
    deprecated: 'FALSE',
  },
  {
    id: '2C917F0E-C35E-45B2-9E60-0C3D7C2630CF',
    name: 'file-powerpoint-box',
    codepoint: 'F0228',
    deprecated: 'FALSE',
  },
  {
    id: '1620AEF6-DDC2-4FC0-9117-6A3DC4C80FEB',
    name: 'file-powerpoint-box-outline',
    codepoint: 'F1034',
    deprecated: 'FALSE',
  },
  {
    id: '6A605E14-B9F9-4753-A439-C9F38C0B561D',
    name: 'file-powerpoint-outline',
    codepoint: 'F1035',
    deprecated: 'FALSE',
  },
  {
    id: 'A7F6B3BE-8D61-4AF3-BBFC-15A795254F6A',
    name: 'file-presentation-box',
    codepoint: 'F0229',
    deprecated: 'FALSE',
  },
  {
    id: 'C5EBED58-BD25-4E0C-AEB8-C6316B88B620',
    name: 'file-question',
    codepoint: 'F086F',
    deprecated: 'FALSE',
  },
  {
    id: 'B2E908FB-D29C-4F80-BD90-B1F6676C6337',
    name: 'file-question-outline',
    codepoint: 'F1036',
    deprecated: 'FALSE',
  },
  {
    id: '6AAC6C2E-4B51-4A21-8CDC-BA516D7BAB6B',
    name: 'file-refresh',
    codepoint: 'F0918',
    deprecated: 'FALSE',
  },
  {
    id: 'B48358A6-983D-4E15-8FE1-905D706A4A4B',
    name: 'file-refresh-outline',
    codepoint: 'F0541',
    deprecated: 'FALSE',
  },
  {
    id: '1D2987A7-950D-45DF-AF0C-F465350C6374',
    name: 'file-remove',
    codepoint: 'F0B98',
    deprecated: 'FALSE',
  },
  {
    id: '26E534DA-72D9-48CD-8748-4E3924E0A974',
    name: 'file-remove-outline',
    codepoint: 'F1037',
    deprecated: 'FALSE',
  },
  {
    id: '8C05288A-4D13-4252-ABD1-CB288AFE9B4E',
    name: 'file-replace',
    codepoint: 'F0B32',
    deprecated: 'FALSE',
  },
  {
    id: 'C47F7F19-362E-49BA-8BC4-2FE6161AD44D',
    name: 'file-replace-outline',
    codepoint: 'F0B33',
    deprecated: 'FALSE',
  },
  {
    id: 'EE0E5547-D368-43CB-8BDD-7F3774985BEF',
    name: 'file-restore',
    codepoint: 'F0670',
    deprecated: 'FALSE',
  },
  {
    id: '3D9EE6A3-0ECC-49C6-84E1-438D2CF2C053',
    name: 'file-restore-outline',
    codepoint: 'F1038',
    deprecated: 'FALSE',
  },
  {
    id: '1111BC67-6DD5-4AE2-9541-9D5E890D823A',
    name: 'file-rotate-left',
    codepoint: 'F1A3B',
    deprecated: 'FALSE',
  },
  {
    id: '7DC8418D-3AE2-4BC3-9A5B-068FE316D172',
    name: 'file-rotate-left-outline',
    codepoint: 'F1A3C',
    deprecated: 'FALSE',
  },
  {
    id: '599A2E2D-E84A-4BF5-A835-9B13E2765606',
    name: 'file-rotate-right',
    codepoint: 'F1A3D',
    deprecated: 'FALSE',
  },
  {
    id: '221C1942-DB8B-40D9-AED7-B202C0085832',
    name: 'file-rotate-right-outline',
    codepoint: 'F1A3E',
    deprecated: 'FALSE',
  },
  {
    id: '8FA2DBB9-E874-4CBB-A466-2691223D0FEF',
    name: 'file-search',
    codepoint: 'F0C7C',
    deprecated: 'FALSE',
  },
  {
    id: '499004C4-2F88-4681-A8AC-EDE9A4ECE922',
    name: 'file-search-outline',
    codepoint: 'F0C7D',
    deprecated: 'FALSE',
  },
  {
    id: 'CEB97477-6633-4486-BDD4-9E7D7C747805',
    name: 'file-send',
    codepoint: 'F022A',
    deprecated: 'FALSE',
  },
  {
    id: 'B828E75F-BBA9-41F9-B877-CB1F1BF29ECA',
    name: 'file-send-outline',
    codepoint: 'F1039',
    deprecated: 'FALSE',
  },
  {
    id: 'DAD983AE-28E9-481D-B0F0-38E1EFD0CAFE',
    name: 'file-settings',
    codepoint: 'F1079',
    deprecated: 'FALSE',
  },
  {
    id: '6F77BE81-0AD4-4886-93FF-0FA1382B8501',
    name: 'file-settings-outline',
    codepoint: 'F107A',
    deprecated: 'FALSE',
  },
  {
    id: 'F6BB607C-B55A-4E62-8C45-490F6AE9F0A2',
    name: 'file-sign',
    codepoint: 'F19C3',
    deprecated: 'FALSE',
  },
  {
    id: 'E7E04B34-BC51-466F-92A1-78C8A00DDC9D',
    name: 'file-star',
    codepoint: 'F103A',
    deprecated: 'FALSE',
  },
  {
    id: 'B418C938-EE23-4073-B10C-C1D93D54B288',
    name: 'file-star-outline',
    codepoint: 'F103B',
    deprecated: 'FALSE',
  },
  {
    id: '01893B96-44C2-43EF-B979-5768B6AF7695',
    name: 'file-swap',
    codepoint: 'F0FB4',
    deprecated: 'FALSE',
  },
  {
    id: '22D7F491-0873-43A4-9EE1-330F5BDC0829',
    name: 'file-swap-outline',
    codepoint: 'F0FB5',
    deprecated: 'FALSE',
  },
  {
    id: 'C17D4D8C-F028-4EED-9C77-B9E1A66FE372',
    name: 'file-sync',
    codepoint: 'F1216',
    deprecated: 'FALSE',
  },
  {
    id: 'F642DE27-0183-4EF7-87FD-70A1D8E031D4',
    name: 'file-sync-outline',
    codepoint: 'F1217',
    deprecated: 'FALSE',
  },
  {
    id: '663EC5E6-16C5-4075-AB5C-1215864F3B2B',
    name: 'file-table',
    codepoint: 'F0C7E',
    deprecated: 'FALSE',
  },
  {
    id: '7CBD5B51-1AE9-4FCA-905F-719540F0EA40',
    name: 'file-table-box',
    codepoint: 'F10E1',
    deprecated: 'FALSE',
  },
  {
    id: '6847431F-22A9-4EAD-A9C6-645C6694D050',
    name: 'file-table-box-multiple',
    codepoint: 'F10E2',
    deprecated: 'FALSE',
  },
  {
    id: '1C6DE56C-E364-4D47-BA2F-4CE1DF34ACF4',
    name: 'file-table-box-multiple-outline',
    codepoint: 'F10E3',
    deprecated: 'FALSE',
  },
  {
    id: '3598E989-EAC7-4255-A78F-BB1914D3153F',
    name: 'file-table-box-outline',
    codepoint: 'F10E4',
    deprecated: 'FALSE',
  },
  {
    id: 'D365B2EB-0B21-42D3-BC9E-7A51DE5F0A57',
    name: 'file-table-outline',
    codepoint: 'F0C7F',
    deprecated: 'FALSE',
  },
  {
    id: 'FE2B1AE3-7158-473B-B1EE-45FB9CAE1137',
    name: 'file-tree',
    codepoint: 'F0645',
    deprecated: 'FALSE',
  },
  {
    id: '86EB0B3A-E46E-4439-B7BA-67A88B280961',
    name: 'file-tree-outline',
    codepoint: 'F13D2',
    deprecated: 'FALSE',
  },
  {
    id: '5CCC54A4-04AF-4241-80AB-177C8365A905',
    name: 'file-undo',
    codepoint: 'F08DC',
    deprecated: 'FALSE',
  },
  {
    id: '6B690FE5-C7C6-49FE-90C1-B021BDB1B2A6',
    name: 'file-undo-outline',
    codepoint: 'F103C',
    deprecated: 'FALSE',
  },
  {
    id: '38FC6E1D-C2F0-4B73-BA30-2C7FAFC0C48E',
    name: 'file-upload',
    codepoint: 'F0A4D',
    deprecated: 'FALSE',
  },
  {
    id: '0719AAB6-7E18-402E-9E27-C51D44C0A856',
    name: 'file-upload-outline',
    codepoint: 'F0A4E',
    deprecated: 'FALSE',
  },
  {
    id: '7BEB068A-898D-43FF-AAA6-BC239FBA74F2',
    name: 'file-video',
    codepoint: 'F022B',
    deprecated: 'FALSE',
  },
  {
    id: '6F2D3BA3-B23D-4476-B3D8-920033F6B68B',
    name: 'file-video-outline',
    codepoint: 'F0E2C',
    deprecated: 'FALSE',
  },
  {
    id: '05004893-1DB7-4A34-AC50-D4B46133A603',
    name: 'file-word',
    codepoint: 'F022C',
    deprecated: 'FALSE',
  },
  {
    id: 'AE6B9B24-DD1E-4896-B385-8DBB9D21C9E7',
    name: 'file-word-box',
    codepoint: 'F022D',
    deprecated: 'FALSE',
  },
  {
    id: '09E0675C-053B-4548-9FC1-2F8DC3B49364',
    name: 'file-word-box-outline',
    codepoint: 'F103D',
    deprecated: 'FALSE',
  },
  {
    id: 'C261E28B-72D8-4D0E-B74A-93333AE4EF4D',
    name: 'file-word-outline',
    codepoint: 'F103E',
    deprecated: 'FALSE',
  },
  {
    id: 'BEFCABB1-11BE-4D8D-A2F4-48E80E404C90',
    name: 'file-xml-box',
    codepoint: 'F1B4B',
    deprecated: 'FALSE',
  },
  {
    id: '2EFEE436-9663-4C09-AD65-6727A0ED2AFD',
    name: 'film',
    codepoint: 'F022F',
    deprecated: 'FALSE',
  },
  {
    id: '10A06B6D-A551-425C-B084-DA3F2239DFA1',
    name: 'filmstrip',
    codepoint: 'F0230',
    deprecated: 'FALSE',
  },
  {
    id: 'FB635DB5-18DA-448E-81C7-0602181EE21A',
    name: 'filmstrip-box',
    codepoint: 'F0332',
    deprecated: 'FALSE',
  },
  {
    id: '392DCBD5-0766-4719-B198-42A9A66C18B4',
    name: 'filmstrip-box-multiple',
    codepoint: 'F0D18',
    deprecated: 'FALSE',
  },
  {
    id: 'CB040E6C-85FD-4C09-9ACD-C41EE5FB770E',
    name: 'filmstrip-off',
    codepoint: 'F0231',
    deprecated: 'FALSE',
  },
  {
    id: '39CAC4E6-CC95-42C8-94E9-E29089F1E91E',
    name: 'filter',
    codepoint: 'F0232',
    deprecated: 'FALSE',
  },
  {
    id: '176E2D7F-C16A-4941-9A42-3DBE264E4148',
    name: 'filter-check',
    codepoint: 'F18EC',
    deprecated: 'FALSE',
  },
  {
    id: 'FFDED2FF-7CB6-4D02-BEE6-A82C16625386',
    name: 'filter-check-outline',
    codepoint: 'F18ED',
    deprecated: 'FALSE',
  },
  {
    id: 'F8F482FA-5AA4-462B-8B38-717FF8568270',
    name: 'filter-cog',
    codepoint: 'F1AA3',
    deprecated: 'FALSE',
  },
  {
    id: '6E54B42B-896D-4598-83D7-96E592B1B15F',
    name: 'filter-cog-outline',
    codepoint: 'F1AA4',
    deprecated: 'FALSE',
  },
  {
    id: '36805F11-BE23-49C3-8D72-682859DD5551',
    name: 'filter-menu',
    codepoint: 'F10E5',
    deprecated: 'FALSE',
  },
  {
    id: '18CFDA0C-648C-4513-AE67-F5BB93817E0B',
    name: 'filter-menu-outline',
    codepoint: 'F10E6',
    deprecated: 'FALSE',
  },
  {
    id: 'BCA7EE0A-BADF-441A-B660-C37C0DFEF83A',
    name: 'filter-minus',
    codepoint: 'F0EEE',
    deprecated: 'FALSE',
  },
  {
    id: '7AB99674-5A9A-40BE-94B4-B8CFA4A97434',
    name: 'filter-minus-outline',
    codepoint: 'F0EEF',
    deprecated: 'FALSE',
  },
  {
    id: 'EC7474F7-BE1F-4076-8CDB-DD5779095168',
    name: 'filter-multiple',
    codepoint: 'F1A3F',
    deprecated: 'FALSE',
  },
  {
    id: '9124AC57-0F66-4F90-ABF4-167CDA8E46BC',
    name: 'filter-multiple-outline',
    codepoint: 'F1A40',
    deprecated: 'FALSE',
  },
  {
    id: '049BC23B-DE87-4AB6-AFC3-38AC296045A3',
    name: 'filter-off',
    codepoint: 'F14EF',
    deprecated: 'FALSE',
  },
  {
    id: '156D4779-7EC6-4BD9-A7B1-F123A387B109',
    name: 'filter-off-outline',
    codepoint: 'F14F0',
    deprecated: 'FALSE',
  },
  {
    id: '524BE3DE-F5C8-4E2E-A329-8480136DCBDB',
    name: 'filter-outline',
    codepoint: 'F0233',
    deprecated: 'FALSE',
  },
  {
    id: '3587C73B-5120-4206-9036-2FA87974681B',
    name: 'filter-plus',
    codepoint: 'F0EF0',
    deprecated: 'FALSE',
  },
  {
    id: '69D6704C-5371-48C4-AAE1-A53EB581CF72',
    name: 'filter-plus-outline',
    codepoint: 'F0EF1',
    deprecated: 'FALSE',
  },
  {
    id: 'F0D392A7-69A4-4301-8E3B-46A8FC7C3DF9',
    name: 'filter-remove',
    codepoint: 'F0234',
    deprecated: 'FALSE',
  },
  {
    id: 'C6DD0143-A7E3-464D-B0AC-F97E5C51F74C',
    name: 'filter-remove-outline',
    codepoint: 'F0235',
    deprecated: 'FALSE',
  },
  {
    id: '2F76CF21-B111-4B8D-A09A-6B6B98366375',
    name: 'filter-settings',
    codepoint: 'F1AA5',
    deprecated: 'FALSE',
  },
  {
    id: 'FC722964-4A11-4A22-94B1-D4812E1FE2C1',
    name: 'filter-settings-outline',
    codepoint: 'F1AA6',
    deprecated: 'FALSE',
  },
  {
    id: 'AECECEA4-9E56-4B4B-ACD6-2C85AB09788A',
    name: 'filter-variant',
    codepoint: 'F0236',
    deprecated: 'FALSE',
  },
  {
    id: '10D650FB-B7AA-4439-A3BF-3F5DE934A22C',
    name: 'filter-variant-minus',
    codepoint: 'F1112',
    deprecated: 'FALSE',
  },
  {
    id: '5F4F40C3-F04B-47BE-BB4F-13F080A81C6A',
    name: 'filter-variant-plus',
    codepoint: 'F1113',
    deprecated: 'FALSE',
  },
  {
    id: 'AE1B79F5-59D9-4FA4-8006-CE60BB5F5207',
    name: 'filter-variant-remove',
    codepoint: 'F103F',
    deprecated: 'FALSE',
  },
  {
    id: 'C39421CD-2A2D-4D92-B59B-69D70A328DF4',
    name: 'finance',
    codepoint: 'F081F',
    deprecated: 'FALSE',
  },
  {
    id: 'D093813C-B59B-42BD-B6C7-B451FA51E87E',
    name: 'find-replace',
    codepoint: 'F06D4',
    deprecated: 'FALSE',
  },
  {
    id: 'F71C897D-9858-40AE-8DB4-253CE675D823',
    name: 'fingerprint',
    codepoint: 'F0237',
    deprecated: 'FALSE',
  },
  {
    id: 'EC570B4F-91D7-4478-9B9F-4C61BC709652',
    name: 'fingerprint-off',
    codepoint: 'F0EB1',
    deprecated: 'FALSE',
  },
  {
    id: '5259DBA5-B2FB-4112-B1F2-5DC17002205C',
    name: 'fire',
    codepoint: 'F0238',
    deprecated: 'FALSE',
  },
  {
    id: '69B65938-BBEB-4E19-9D12-3924EEAAACF0',
    name: 'fire-alert',
    codepoint: 'F15D7',
    deprecated: 'FALSE',
  },
  {
    id: '942AE48C-F54D-4CD3-87A0-DC8DDDAD6F44',
    name: 'fire-circle',
    codepoint: 'F1807',
    deprecated: 'FALSE',
  },
  {
    id: 'D056C9A3-AA4C-4220-BEE7-79E189C12711',
    name: 'fire-extinguisher',
    codepoint: 'F0EF2',
    deprecated: 'FALSE',
  },
  {
    id: '78842C69-653C-40B5-BA05-578361EA0620',
    name: 'fire-hydrant',
    codepoint: 'F1137',
    deprecated: 'FALSE',
  },
  {
    id: '506CEB31-5A28-4D8F-947E-A0B0B5211DA1',
    name: 'fire-hydrant-alert',
    codepoint: 'F1138',
    deprecated: 'FALSE',
  },
  {
    id: 'B09CB3ED-6B3A-4B3D-B795-92E96DE01F4B',
    name: 'fire-hydrant-off',
    codepoint: 'F1139',
    deprecated: 'FALSE',
  },
  {
    id: 'DAB67757-2065-4741-A03A-563D0EA202E0',
    name: 'fire-off',
    codepoint: 'F1722',
    deprecated: 'FALSE',
  },
  {
    id: '1C8EC9A6-0545-4A59-B9F8-5E498E40529D',
    name: 'fire-truck',
    codepoint: 'F08AB',
    deprecated: 'FALSE',
  },
  {
    id: '3A3D9EFD-ABA4-444F-9CB5-A2D8C1322B2C',
    name: 'firebase',
    codepoint: 'F0967',
    deprecated: 'TRUE',
  },
  {
    id: '2A7EE574-62BE-4000-B8D8-B601C29D2960',
    name: 'firefox',
    codepoint: 'F0239',
    deprecated: 'TRUE',
  },
  {
    id: 'B69CC506-E44A-4B60-A170-F4FFD53C67A6',
    name: 'fireplace',
    codepoint: 'F0E2E',
    deprecated: 'FALSE',
  },
  {
    id: 'E2D6EF7A-6C31-4DCD-B385-FC8C9B113538',
    name: 'fireplace-off',
    codepoint: 'F0E2F',
    deprecated: 'FALSE',
  },
  {
    id: '5415674F-E6F6-433D-9A9F-E976FD7245F8',
    name: 'firewire',
    codepoint: 'F05BE',
    deprecated: 'FALSE',
  },
  {
    id: '6385008C-99E4-4459-8B18-0CD594EC5C7E',
    name: 'firework',
    codepoint: 'F0E30',
    deprecated: 'FALSE',
  },
  {
    id: '16CFC5D9-C749-4148-BF17-D002AFFF9D09',
    name: 'firework-off',
    codepoint: 'F1723',
    deprecated: 'FALSE',
  },
  {
    id: '91D8C321-95BC-4AC6-9B3E-D38D457BFF75',
    name: 'fish',
    codepoint: 'F023A',
    deprecated: 'FALSE',
  },
  {
    id: '4F854008-B48E-4134-829A-9D3086C8F1E9',
    name: 'fish-off',
    codepoint: 'F13F3',
    deprecated: 'FALSE',
  },
  {
    id: '50A773A8-4349-4B7F-A211-6CB14E9C090B',
    name: 'fishbowl',
    codepoint: 'F0EF3',
    deprecated: 'FALSE',
  },
  {
    id: '860D1C47-AD7E-4424-9CB7-BA9D2F86C129',
    name: 'fishbowl-outline',
    codepoint: 'F0EF4',
    deprecated: 'FALSE',
  },
  {
    id: '05E31726-71FE-41C1-B2CC-93E5B4DF25CE',
    name: 'fit-to-page',
    codepoint: 'F0EF5',
    deprecated: 'FALSE',
  },
  {
    id: '0DB84D18-659E-4E93-A078-B3822742C0D4',
    name: 'fit-to-page-outline',
    codepoint: 'F0EF6',
    deprecated: 'FALSE',
  },
  {
    id: '2491A740-6415-4CDB-805F-675D8EBF89A3',
    name: 'fit-to-screen',
    codepoint: 'F18F4',
    deprecated: 'FALSE',
  },
  {
    id: 'E7D43483-A3ED-4B9B-AEB8-72BF8BC189E4',
    name: 'fit-to-screen-outline',
    codepoint: 'F18F5',
    deprecated: 'FALSE',
  },
  {
    id: '55271063-77EF-43FA-917C-657E57135A24',
    name: 'flag',
    codepoint: 'F023B',
    deprecated: 'FALSE',
  },
  {
    id: 'B7386AC8-4DBF-4948-AD45-49234A8A019B',
    name: 'flag-checkered',
    codepoint: 'F023C',
    deprecated: 'FALSE',
  },
  {
    id: '55982621-FCB3-4A76-8F8C-50E7B6B1C93D',
    name: 'flag-minus',
    codepoint: 'F0B99',
    deprecated: 'FALSE',
  },
  {
    id: '370C68CC-A001-4368-9617-B7D683361B27',
    name: 'flag-minus-outline',
    codepoint: 'F10B2',
    deprecated: 'FALSE',
  },
  {
    id: 'D670073A-229E-474A-BDF8-ED5F07C1FC41',
    name: 'flag-off',
    codepoint: 'F18EE',
    deprecated: 'FALSE',
  },
  {
    id: '07AE6191-FF49-4F10-A6E1-F4BD0E6C9029',
    name: 'flag-off-outline',
    codepoint: 'F18EF',
    deprecated: 'FALSE',
  },
  {
    id: '3C0BD546-A778-4BDC-A30A-A627D61CBA47',
    name: 'flag-outline',
    codepoint: 'F023D',
    deprecated: 'FALSE',
  },
  {
    id: 'EF2E3070-75EC-44C3-9ECE-780BB330D51B',
    name: 'flag-plus',
    codepoint: 'F0B9A',
    deprecated: 'FALSE',
  },
  {
    id: '32EF248B-1462-486E-AEF1-A0ED29670568',
    name: 'flag-plus-outline',
    codepoint: 'F10B3',
    deprecated: 'FALSE',
  },
  {
    id: 'D0FB8B1E-3270-4562-A660-968995E1B9A9',
    name: 'flag-remove',
    codepoint: 'F0B9B',
    deprecated: 'FALSE',
  },
  {
    id: '65132686-1842-48E2-9AD3-17FEB5EA56F5',
    name: 'flag-remove-outline',
    codepoint: 'F10B4',
    deprecated: 'FALSE',
  },
  {
    id: '3E14D0C4-25AA-405E-89A7-37E9A224BA93',
    name: 'flag-triangle',
    codepoint: 'F023F',
    deprecated: 'FALSE',
  },
  {
    id: '9A9DB93D-CE2E-4887-B648-9C70E1922092',
    name: 'flag-variant',
    codepoint: 'F0240',
    deprecated: 'FALSE',
  },
  {
    id: '7FAF4B80-9616-4F37-98FF-DC02DD98AA08',
    name: 'flag-variant-minus',
    codepoint: 'F1BB4',
    deprecated: 'FALSE',
  },
  {
    id: '2181A952-90BC-49E9-BC57-40CADFEF6E89',
    name: 'flag-variant-minus-outline',
    codepoint: 'F1BB5',
    deprecated: 'FALSE',
  },
  {
    id: 'C90BB119-B3C8-4D8B-9652-7CE62A1861F7',
    name: 'flag-variant-off',
    codepoint: 'F1BB0',
    deprecated: 'FALSE',
  },
  {
    id: '24434A29-5FE9-40F3-9BD0-DDA50E1B7900',
    name: 'flag-variant-off-outline',
    codepoint: 'F1BB1',
    deprecated: 'FALSE',
  },
  {
    id: '756B772A-F638-48EB-918C-2FA4237C7767',
    name: 'flag-variant-outline',
    codepoint: 'F023E',
    deprecated: 'FALSE',
  },
  {
    id: '4005BD44-56B4-4F6E-962D-EF83ED5C69EC',
    name: 'flag-variant-plus',
    codepoint: 'F1BB2',
    deprecated: 'FALSE',
  },
  {
    id: '98EECB14-D708-4096-958C-22B3C7BDFB78',
    name: 'flag-variant-plus-outline',
    codepoint: 'F1BB3',
    deprecated: 'FALSE',
  },
  {
    id: '302B6AE5-07C8-4C6C-BB99-6AA7482F1604',
    name: 'flag-variant-remove',
    codepoint: 'F1BB6',
    deprecated: 'FALSE',
  },
  {
    id: '121F3FEE-B1F0-4818-9512-2923E7E90AE3',
    name: 'flag-variant-remove-outline',
    codepoint: 'F1BB7',
    deprecated: 'FALSE',
  },
  {
    id: '8F4F8B2A-9E7D-432F-978E-F3F108DA701B',
    name: 'flare',
    codepoint: 'F0D72',
    deprecated: 'FALSE',
  },
  {
    id: 'EF3D6923-793B-410C-B349-90A6A338F06C',
    name: 'flash',
    codepoint: 'F0241',
    deprecated: 'FALSE',
  },
  {
    id: '59E94B6E-589B-4DB9-AAB7-397E17E2B272',
    name: 'flash-alert',
    codepoint: 'F0EF7',
    deprecated: 'FALSE',
  },
  {
    id: '2770517A-B0A3-4AC5-A6E6-6EFD9DA2C3E5',
    name: 'flash-alert-outline',
    codepoint: 'F0EF8',
    deprecated: 'FALSE',
  },
  {
    id: 'D3251831-8AEF-420A-9AA8-2BA0A0621538',
    name: 'flash-auto',
    codepoint: 'F0242',
    deprecated: 'FALSE',
  },
  {
    id: 'A6D1B09D-EDB0-4241-B42D-E56052A070DE',
    name: 'flash-off',
    codepoint: 'F0243',
    deprecated: 'FALSE',
  },
  {
    id: '39EECEAB-80E1-486A-972E-CFBF84E02A26',
    name: 'flash-off-outline',
    codepoint: 'F1B45',
    deprecated: 'FALSE',
  },
  {
    id: '088BF56F-9281-43DB-879E-EB5158428EB7',
    name: 'flash-outline',
    codepoint: 'F06D5',
    deprecated: 'FALSE',
  },
  {
    id: 'C25CEF12-DF9C-45A4-8399-3102B79BDF14',
    name: 'flash-red-eye',
    codepoint: 'F067B',
    deprecated: 'FALSE',
  },
  {
    id: 'E5881C80-1B80-472E-AFD9-A337D38C7A2F',
    name: 'flash-triangle',
    codepoint: 'F1B1D',
    deprecated: 'FALSE',
  },
  {
    id: '825BA6A5-367F-42F8-A2B2-EBE84D7CBCF3',
    name: 'flash-triangle-outline',
    codepoint: 'F1B1E',
    deprecated: 'FALSE',
  },
  {
    id: '0EC7CDC8-86F4-4C72-AC2E-1DEDB196043F',
    name: 'flashlight',
    codepoint: 'F0244',
    deprecated: 'FALSE',
  },
  {
    id: '043DEA8B-1F5B-49CE-BC9F-AA3FC802A58A',
    name: 'flashlight-off',
    codepoint: 'F0245',
    deprecated: 'FALSE',
  },
  {
    id: '5E171637-949C-4091-9A6E-1A56C1DC8347',
    name: 'flask',
    codepoint: 'F0093',
    deprecated: 'FALSE',
  },
  {
    id: 'D036C297-DB1B-42F5-A856-13EEBDB48613',
    name: 'flask-empty',
    codepoint: 'F0094',
    deprecated: 'FALSE',
  },
  {
    id: '845569FD-A57A-4F03-9048-707A046521E9',
    name: 'flask-empty-minus',
    codepoint: 'F123A',
    deprecated: 'FALSE',
  },
  {
    id: '500AF1DD-69C7-4C42-AE74-B6AC589BA8A8',
    name: 'flask-empty-minus-outline',
    codepoint: 'F123B',
    deprecated: 'FALSE',
  },
  {
    id: '26005876-74DC-411D-BB27-557623A42F8A',
    name: 'flask-empty-off',
    codepoint: 'F13F4',
    deprecated: 'FALSE',
  },
  {
    id: 'E04A35A9-0B00-4FCF-A524-CB3331FFA346',
    name: 'flask-empty-off-outline',
    codepoint: 'F13F5',
    deprecated: 'FALSE',
  },
  {
    id: 'E9FFE6A9-C641-47BE-988B-A94B34A90CED',
    name: 'flask-empty-outline',
    codepoint: 'F0095',
    deprecated: 'FALSE',
  },
  {
    id: '716D7945-ED6F-40B5-890A-18A1F93247B0',
    name: 'flask-empty-plus',
    codepoint: 'F123C',
    deprecated: 'FALSE',
  },
  {
    id: 'DD0BAE86-6060-4A90-8A57-32CCFBC7D617',
    name: 'flask-empty-plus-outline',
    codepoint: 'F123D',
    deprecated: 'FALSE',
  },
  {
    id: '64BC4DF4-E408-446C-975F-20A2F1646F1F',
    name: 'flask-empty-remove',
    codepoint: 'F123E',
    deprecated: 'FALSE',
  },
  {
    id: '5B25EC76-4EB5-46AC-9445-7411CD0567DD',
    name: 'flask-empty-remove-outline',
    codepoint: 'F123F',
    deprecated: 'FALSE',
  },
  {
    id: 'E4673F66-5785-4A63-855D-92A9F6B0E4AE',
    name: 'flask-minus',
    codepoint: 'F1240',
    deprecated: 'FALSE',
  },
  {
    id: 'B8EB4BF1-B423-4C0D-9DE5-24D691E9EAF3',
    name: 'flask-minus-outline',
    codepoint: 'F1241',
    deprecated: 'FALSE',
  },
  {
    id: 'D886A9E0-E952-4DF0-97B0-0C630E956766',
    name: 'flask-off',
    codepoint: 'F13F6',
    deprecated: 'FALSE',
  },
  {
    id: '4AA0C752-79B8-4EF6-A4E2-7B610599DEE3',
    name: 'flask-off-outline',
    codepoint: 'F13F7',
    deprecated: 'FALSE',
  },
  {
    id: '14C068EE-90AC-42DA-AA1E-D24F74EE7263',
    name: 'flask-outline',
    codepoint: 'F0096',
    deprecated: 'FALSE',
  },
  {
    id: '6FA94A7A-788F-44B6-90A7-B4EACF9B6FD3',
    name: 'flask-plus',
    codepoint: 'F1242',
    deprecated: 'FALSE',
  },
  {
    id: 'AE491BF6-EA26-4732-AF63-8A3D83379CC6',
    name: 'flask-plus-outline',
    codepoint: 'F1243',
    deprecated: 'FALSE',
  },
  {
    id: 'ED698844-E3E9-4FD3-89F2-A41163ACD80A',
    name: 'flask-remove',
    codepoint: 'F1244',
    deprecated: 'FALSE',
  },
  {
    id: '67E2EA68-867E-4514-A952-2E0A90D5C2AC',
    name: 'flask-remove-outline',
    codepoint: 'F1245',
    deprecated: 'FALSE',
  },
  {
    id: 'E7D0BD51-A831-4355-AE75-1D903C121D3F',
    name: 'flask-round-bottom',
    codepoint: 'F124B',
    deprecated: 'FALSE',
  },
  {
    id: 'A08CBFD8-DD21-47F9-B213-7EF3AD89EDAD',
    name: 'flask-round-bottom-empty',
    codepoint: 'F124C',
    deprecated: 'FALSE',
  },
  {
    id: '2BD05FF6-4E09-40EC-9D34-59FB9CCD09FF',
    name: 'flask-round-bottom-empty-outline',
    codepoint: 'F124D',
    deprecated: 'FALSE',
  },
  {
    id: '383DEA4E-9820-4C4C-A5FD-05C151C7B82A',
    name: 'flask-round-bottom-outline',
    codepoint: 'F124E',
    deprecated: 'FALSE',
  },
  {
    id: '1BB3488A-A7B4-4DA5-99A2-AD23A184606B',
    name: 'fleur-de-lis',
    codepoint: 'F1303',
    deprecated: 'FALSE',
  },
  {
    id: '9D29290D-A243-4C72-BC8C-ABCDFB4448B2',
    name: 'flip-horizontal',
    codepoint: 'F10E7',
    deprecated: 'FALSE',
  },
  {
    id: 'F9DD0BF3-1CF8-456E-A542-2D22131A5761',
    name: 'flip-to-back',
    codepoint: 'F0247',
    deprecated: 'FALSE',
  },
  {
    id: '6CEAE4F9-F6CB-4DA8-A25C-1F41494C6DD5',
    name: 'flip-to-front',
    codepoint: 'F0248',
    deprecated: 'FALSE',
  },
  {
    id: 'D5DC28B3-CD69-465C-9333-9463A75072DC',
    name: 'flip-vertical',
    codepoint: 'F10E8',
    deprecated: 'FALSE',
  },
  {
    id: 'BEF1218E-7AF7-45F9-930E-915791D51EDC',
    name: 'floor-lamp',
    codepoint: 'F08DD',
    deprecated: 'FALSE',
  },
  {
    id: '08F74DD4-A57F-4CC8-9568-88E826BE71E5',
    name: 'floor-lamp-dual',
    codepoint: 'F1040',
    deprecated: 'FALSE',
  },
  {
    id: 'E53D8FB5-A010-4EFA-91EB-731E8C6B7321',
    name: 'floor-lamp-dual-outline',
    codepoint: 'F17CE',
    deprecated: 'FALSE',
  },
  {
    id: '9768571C-CF87-4146-A9AF-A4E76BBD9248',
    name: 'floor-lamp-outline',
    codepoint: 'F17C8',
    deprecated: 'FALSE',
  },
  {
    id: '9042476D-79AC-4D50-AA53-EE71D3416BF3',
    name: 'floor-lamp-torchiere',
    codepoint: 'F1747',
    deprecated: 'FALSE',
  },
  {
    id: 'F2BD0434-FE75-43EB-BA3A-AFCE445DE335',
    name: 'floor-lamp-torchiere-outline',
    codepoint: 'F17D6',
    deprecated: 'FALSE',
  },
  {
    id: '85969E2D-500B-4565-8B80-A59FB6C8A73B',
    name: 'floor-lamp-torchiere-variant',
    codepoint: 'F1041',
    deprecated: 'FALSE',
  },
  {
    id: '03B09B13-4FA7-46CD-9E3B-48D460AB3986',
    name: 'floor-lamp-torchiere-variant-outline',
    codepoint: 'F17CF',
    deprecated: 'FALSE',
  },
  {
    id: '131D3C5D-880D-486B-A041-039B1F1E5284',
    name: 'floor-plan',
    codepoint: 'F0821',
    deprecated: 'FALSE',
  },
  {
    id: 'B4AC5BF4-AF66-4DC5-9743-F62A80489149',
    name: 'floppy',
    codepoint: 'F0249',
    deprecated: 'FALSE',
  },
  {
    id: '8A4453F7-03E3-4F10-B93F-3213645300F8',
    name: 'floppy-variant',
    codepoint: 'F09EF',
    deprecated: 'FALSE',
  },
  {
    id: 'D73BB486-A112-48C1-A4EE-993825164C43',
    name: 'flower',
    codepoint: 'F024A',
    deprecated: 'FALSE',
  },
  {
    id: '0BF283D1-7C12-4E5F-B136-558BE534AC8D',
    name: 'flower-outline',
    codepoint: 'F09F0',
    deprecated: 'FALSE',
  },
  {
    id: '00031D77-25BC-41B3-A6A3-F83EF92743A5',
    name: 'flower-pollen',
    codepoint: 'F1885',
    deprecated: 'FALSE',
  },
  {
    id: '24A80A28-E963-4E59-BA21-9D97C0434950',
    name: 'flower-pollen-outline',
    codepoint: 'F1886',
    deprecated: 'FALSE',
  },
  {
    id: '9C310133-C0AC-4456-B8A0-9ED17F2C08BA',
    name: 'flower-poppy',
    codepoint: 'F0D08',
    deprecated: 'FALSE',
  },
  {
    id: '4CD0F242-7779-4B61-B13C-876236FE92B4',
    name: 'flower-tulip',
    codepoint: 'F09F1',
    deprecated: 'FALSE',
  },
  {
    id: 'D6449362-3D4B-42FB-BD25-020B104B4602',
    name: 'flower-tulip-outline',
    codepoint: 'F09F2',
    deprecated: 'FALSE',
  },
  {
    id: 'F04E0123-6365-491F-9A98-1D634D2F3071',
    name: 'focus-auto',
    codepoint: 'F0F4E',
    deprecated: 'FALSE',
  },
  {
    id: '4B35FE7D-BD1E-46D1-9C4C-DCCFC3DCEC44',
    name: 'focus-field',
    codepoint: 'F0F4F',
    deprecated: 'FALSE',
  },
  {
    id: 'B7F6C8DE-200D-4BCA-9E71-2AF633968C43',
    name: 'focus-field-horizontal',
    codepoint: 'F0F50',
    deprecated: 'FALSE',
  },
  {
    id: 'CC4C833A-A1B9-4847-9BCB-3EAC3CCF15F5',
    name: 'focus-field-vertical',
    codepoint: 'F0F51',
    deprecated: 'FALSE',
  },
  {
    id: '9D8C1CEE-19BE-4B0B-85CC-DEB829F9F045',
    name: 'folder',
    codepoint: 'F024B',
    deprecated: 'FALSE',
  },
  {
    id: '0C56278E-06DE-4D32-BA08-C78F3EB572CC',
    name: 'folder-account',
    codepoint: 'F024C',
    deprecated: 'FALSE',
  },
  {
    id: 'B905BF65-7312-4F62-8979-EDB2906B38E6',
    name: 'folder-account-outline',
    codepoint: 'F0B9C',
    deprecated: 'FALSE',
  },
  {
    id: 'D26F1A90-B76E-4C13-A956-2EBA33493563',
    name: 'folder-alert',
    codepoint: 'F0DCC',
    deprecated: 'FALSE',
  },
  {
    id: '9CF67925-5A21-47F8-8EF5-F567D902C1D2',
    name: 'folder-alert-outline',
    codepoint: 'F0DCD',
    deprecated: 'FALSE',
  },
  {
    id: '40D3B512-FD3C-4F69-922E-DE4EC33AB3A5',
    name: 'folder-arrow-down',
    codepoint: 'F19E8',
    deprecated: 'FALSE',
  },
  {
    id: '65D7B690-9532-44AC-B537-82B136036F15',
    name: 'folder-arrow-down-outline',
    codepoint: 'F19E9',
    deprecated: 'FALSE',
  },
  {
    id: '25BB41F5-0A33-4F15-8C92-FBA086F0C6EA',
    name: 'folder-arrow-left',
    codepoint: 'F19EA',
    deprecated: 'FALSE',
  },
  {
    id: '79455D10-F086-4724-A89A-CA23815D9A2A',
    name: 'folder-arrow-left-outline',
    codepoint: 'F19EB',
    deprecated: 'FALSE',
  },
  {
    id: 'BCD77C22-F711-42AD-BFD7-46EA0DCA2AD0',
    name: 'folder-arrow-left-right',
    codepoint: 'F19EC',
    deprecated: 'FALSE',
  },
  {
    id: '51BC71E5-596D-4FAC-A254-5E3F9E0C8AA2',
    name: 'folder-arrow-left-right-outline',
    codepoint: 'F19ED',
    deprecated: 'FALSE',
  },
  {
    id: '56C26607-0AD8-47DC-8ECE-5180A20BE4B2',
    name: 'folder-arrow-right',
    codepoint: 'F19EE',
    deprecated: 'FALSE',
  },
  {
    id: '7FCB1874-45D5-4DD4-8F82-172FCADE562A',
    name: 'folder-arrow-right-outline',
    codepoint: 'F19EF',
    deprecated: 'FALSE',
  },
  {
    id: '90B8DA2E-A381-4226-8F27-E085B391B5A1',
    name: 'folder-arrow-up',
    codepoint: 'F19F0',
    deprecated: 'FALSE',
  },
  {
    id: 'F710484C-04A2-41A6-82BA-D0900A94E2DB',
    name: 'folder-arrow-up-down',
    codepoint: 'F19F1',
    deprecated: 'FALSE',
  },
  {
    id: '0D1FD0C5-6A2C-475F-8824-A1EC1B93E85F',
    name: 'folder-arrow-up-down-outline',
    codepoint: 'F19F2',
    deprecated: 'FALSE',
  },
  {
    id: '320546E6-E809-47EA-BF2A-7EE3369AA89D',
    name: 'folder-arrow-up-outline',
    codepoint: 'F19F3',
    deprecated: 'FALSE',
  },
  {
    id: 'EF125AA8-4CB8-4608-9E99-F50F7756F375',
    name: 'folder-cancel',
    codepoint: 'F19F4',
    deprecated: 'FALSE',
  },
  {
    id: '54D65B1D-7337-435B-8E69-BA2E721F9DE5',
    name: 'folder-cancel-outline',
    codepoint: 'F19F5',
    deprecated: 'FALSE',
  },
  {
    id: 'B231DD3B-3FD4-4D82-9BD4-329B5A1F59A5',
    name: 'folder-check',
    codepoint: 'F197E',
    deprecated: 'FALSE',
  },
  {
    id: '87616DE6-0ADE-412A-B67F-C115DF131B06',
    name: 'folder-check-outline',
    codepoint: 'F197F',
    deprecated: 'FALSE',
  },
  {
    id: 'A3CB34A0-7E6E-4DF8-B17A-20C9180B3C76',
    name: 'folder-clock',
    codepoint: 'F0ABA',
    deprecated: 'FALSE',
  },
  {
    id: '3EC459AC-889B-4D24-A6E2-978BA7A908BB',
    name: 'folder-clock-outline',
    codepoint: 'F0ABB',
    deprecated: 'FALSE',
  },
  {
    id: 'C5113090-DFDF-461C-BD5F-885DF9938A9E',
    name: 'folder-cog',
    codepoint: 'F107F',
    deprecated: 'FALSE',
  },
  {
    id: 'FDC5F42F-066B-4833-B8E9-E2E245FC80F6',
    name: 'folder-cog-outline',
    codepoint: 'F1080',
    deprecated: 'FALSE',
  },
  {
    id: 'E10E0982-353D-484A-886B-30D343261422',
    name: 'folder-download',
    codepoint: 'F024D',
    deprecated: 'FALSE',
  },
  {
    id: '20FEED41-F560-4872-82F4-4ED5D01EA474',
    name: 'folder-download-outline',
    codepoint: 'F10E9',
    deprecated: 'FALSE',
  },
  {
    id: '1C40CBC9-118F-4145-B0A5-B10007870272',
    name: 'folder-edit',
    codepoint: 'F08DE',
    deprecated: 'FALSE',
  },
  {
    id: 'A990E58F-2E88-4293-892E-F58252416749',
    name: 'folder-edit-outline',
    codepoint: 'F0DCE',
    deprecated: 'FALSE',
  },
  {
    id: 'D36D03A5-01AD-4F0B-9C63-ACA149117B73',
    name: 'folder-eye',
    codepoint: 'F178A',
    deprecated: 'FALSE',
  },
  {
    id: '7589978B-4D0B-4C1E-965A-38B5077BB7E1',
    name: 'folder-eye-outline',
    codepoint: 'F178B',
    deprecated: 'FALSE',
  },
  {
    id: '92D3CAE7-0191-42CE-8D9F-7AE3C8D067DA',
    name: 'folder-file',
    codepoint: 'F19F6',
    deprecated: 'FALSE',
  },
  {
    id: '9DEB2414-C7DC-4AE8-831C-32FE8C5ECFCF',
    name: 'folder-file-outline',
    codepoint: 'F19F7',
    deprecated: 'FALSE',
  },
  {
    id: 'B8ACEEFF-E80A-42A9-BCAE-A804D47D8929',
    name: 'folder-google-drive',
    codepoint: 'F024E',
    deprecated: 'TRUE',
  },
  {
    id: 'E67BA814-6FCF-48CB-BBB3-359070145BCF',
    name: 'folder-heart',
    codepoint: 'F10EA',
    deprecated: 'FALSE',
  },
  {
    id: 'B8BBFB97-F4D8-4FE1-B2CE-6590CBA2A337',
    name: 'folder-heart-outline',
    codepoint: 'F10EB',
    deprecated: 'FALSE',
  },
  {
    id: '95C4CD8D-86FA-4F13-91AA-3AAEF0CD5CEE',
    name: 'folder-hidden',
    codepoint: 'F179E',
    deprecated: 'FALSE',
  },
  {
    id: 'EF46E7D8-3062-41E5-8FC6-92D66DEF7603',
    name: 'folder-home',
    codepoint: 'F10B5',
    deprecated: 'FALSE',
  },
  {
    id: '5CFE89BB-F160-4C01-95C8-CFDF3D2099DA',
    name: 'folder-home-outline',
    codepoint: 'F10B6',
    deprecated: 'FALSE',
  },
  {
    id: '726DE98D-9BA1-4E73-8632-FF3F3768A3B3',
    name: 'folder-image',
    codepoint: 'F024F',
    deprecated: 'FALSE',
  },
  {
    id: 'A2C97734-F322-430B-BA09-02B0EB2D2CB5',
    name: 'folder-information',
    codepoint: 'F10B7',
    deprecated: 'FALSE',
  },
  {
    id: 'C31ED401-6424-4436-84A8-3E82C95B1E79',
    name: 'folder-information-outline',
    codepoint: 'F10B8',
    deprecated: 'FALSE',
  },
  {
    id: '30D99FD0-397C-49B6-A5CE-B9D6CFD7E66E',
    name: 'folder-key',
    codepoint: 'F08AC',
    deprecated: 'FALSE',
  },
  {
    id: '0C6934F1-2CC9-4689-B388-B3F2A78E026A',
    name: 'folder-key-network',
    codepoint: 'F08AD',
    deprecated: 'FALSE',
  },
  {
    id: '59629095-DF90-4405-8164-0EFB00808958',
    name: 'folder-key-network-outline',
    codepoint: 'F0C80',
    deprecated: 'FALSE',
  },
  {
    id: 'DE3EB621-D1A5-4F61-96F4-C8794191D34F',
    name: 'folder-key-outline',
    codepoint: 'F10EC',
    deprecated: 'FALSE',
  },
  {
    id: 'FABB67CE-5F69-4D45-95B9-02D7BFC9EABD',
    name: 'folder-lock',
    codepoint: 'F0250',
    deprecated: 'FALSE',
  },
  {
    id: 'F89AD3B3-DD09-44F7-81A7-0433ECD44A8F',
    name: 'folder-lock-open',
    codepoint: 'F0251',
    deprecated: 'FALSE',
  },
  {
    id: '27E16474-6081-4DA7-AD05-707CC64369C7',
    name: 'folder-lock-open-outline',
    codepoint: 'F1AA7',
    deprecated: 'FALSE',
  },
  {
    id: 'E201B591-1235-409C-A620-862909114374',
    name: 'folder-lock-outline',
    codepoint: 'F1AA8',
    deprecated: 'FALSE',
  },
  {
    id: '00FD0F50-240F-4654-9712-C219B0FF9A97',
    name: 'folder-marker',
    codepoint: 'F126D',
    deprecated: 'FALSE',
  },
  {
    id: 'A542AF06-5931-4B8A-8DE0-777F11304ACC',
    name: 'folder-marker-outline',
    codepoint: 'F126E',
    deprecated: 'FALSE',
  },
  {
    id: '8C184D42-0C9C-402E-9B8E-EB78E5A5A21D',
    name: 'folder-minus',
    codepoint: 'F1B49',
    deprecated: 'FALSE',
  },
  {
    id: 'A8D2A9D7-8B44-4BFE-B84E-B1FFB889759C',
    name: 'folder-minus-outline',
    codepoint: 'F1B4A',
    deprecated: 'FALSE',
  },
  {
    id: 'B7AD0302-5738-4474-9679-AC118E566A7E',
    name: 'folder-move',
    codepoint: 'F0252',
    deprecated: 'FALSE',
  },
  {
    id: 'F3985E52-B82C-4008-98E3-48DCDBC21749',
    name: 'folder-move-outline',
    codepoint: 'F1246',
    deprecated: 'FALSE',
  },
  {
    id: '7116841C-C243-4A44-8C68-267048AAF939',
    name: 'folder-multiple',
    codepoint: 'F0253',
    deprecated: 'FALSE',
  },
  {
    id: '29BE785E-BAAB-4824-A0BF-6892D8027B1E',
    name: 'folder-multiple-image',
    codepoint: 'F0254',
    deprecated: 'FALSE',
  },
  {
    id: 'F91AD38D-33E3-46A4-83BC-17B671750D6C',
    name: 'folder-multiple-outline',
    codepoint: 'F0255',
    deprecated: 'FALSE',
  },
  {
    id: 'C7BBAA2A-AE24-41E9-AED3-6932D2D0CCAF',
    name: 'folder-multiple-plus',
    codepoint: 'F147E',
    deprecated: 'FALSE',
  },
  {
    id: 'CF776532-C79F-4319-B2FB-F9EDD407E063',
    name: 'folder-multiple-plus-outline',
    codepoint: 'F147F',
    deprecated: 'FALSE',
  },
  {
    id: '4D36C1AA-F109-485C-8D3C-EDDD054057AF',
    name: 'folder-music',
    codepoint: 'F1359',
    deprecated: 'FALSE',
  },
  {
    id: '629FB453-89C2-4B3F-A12E-2FDC427FCD0D',
    name: 'folder-music-outline',
    codepoint: 'F135A',
    deprecated: 'FALSE',
  },
  {
    id: '7C4EED9D-D897-4B8C-9C0B-FE9A9D820A2D',
    name: 'folder-network',
    codepoint: 'F0870',
    deprecated: 'FALSE',
  },
  {
    id: '1C9D27B7-BBF8-45BD-AF63-200A7E67E8DA',
    name: 'folder-network-outline',
    codepoint: 'F0C81',
    deprecated: 'FALSE',
  },
  {
    id: 'A792FD60-AF2F-4EDD-8CCB-42FFBEE61286',
    name: 'folder-off',
    codepoint: 'F19F8',
    deprecated: 'FALSE',
  },
  {
    id: '3DA5D076-3943-4968-A375-3DB3A73C3E69',
    name: 'folder-off-outline',
    codepoint: 'F19F9',
    deprecated: 'FALSE',
  },
  {
    id: '7BBC57AE-0EBA-4198-8B85-5082A38522DE',
    name: 'folder-open',
    codepoint: 'F0770',
    deprecated: 'FALSE',
  },
  {
    id: 'DFC9E65E-7019-4520-9797-418E410FEABC',
    name: 'folder-open-outline',
    codepoint: 'F0DCF',
    deprecated: 'FALSE',
  },
  {
    id: '0524FF60-B273-4675-9B69-043730244645',
    name: 'folder-outline',
    codepoint: 'F0256',
    deprecated: 'FALSE',
  },
  {
    id: 'C3FACC18-E546-4E6F-A140-48541C0B808E',
    name: 'folder-play',
    codepoint: 'F19FA',
    deprecated: 'FALSE',
  },
  {
    id: '63119A3C-5E35-4BC5-9DA6-74EB4DD748DB',
    name: 'folder-play-outline',
    codepoint: 'F19FB',
    deprecated: 'FALSE',
  },
  {
    id: 'BAE32170-6509-4D1F-A6A5-A2FD1A0C679B',
    name: 'folder-plus',
    codepoint: 'F0257',
    deprecated: 'FALSE',
  },
  {
    id: '5615B9DB-A490-4977-9513-B21B086BB542',
    name: 'folder-plus-outline',
    codepoint: 'F0B9D',
    deprecated: 'FALSE',
  },
  {
    id: 'FEA28688-AA8C-4CEE-937C-9110076B3214',
    name: 'folder-pound',
    codepoint: 'F0D09',
    deprecated: 'FALSE',
  },
  {
    id: 'AA3153A7-917B-4D2B-9D8E-297AE296F195',
    name: 'folder-pound-outline',
    codepoint: 'F0D0A',
    deprecated: 'FALSE',
  },
  {
    id: '772568CD-A665-4EBA-ACEA-6D6D5D23CDFB',
    name: 'folder-question',
    codepoint: 'F19CA',
    deprecated: 'FALSE',
  },
  {
    id: 'DF184C5C-BF3B-47C7-8E37-6992E1E7480E',
    name: 'folder-question-outline',
    codepoint: 'F19CB',
    deprecated: 'FALSE',
  },
  {
    id: '9B23FB77-FAF2-4F8E-B071-D23726EA8632',
    name: 'folder-refresh',
    codepoint: 'F0749',
    deprecated: 'FALSE',
  },
  {
    id: 'DBC01AAD-8FE1-4668-9E76-7AF716D30C32',
    name: 'folder-refresh-outline',
    codepoint: 'F0542',
    deprecated: 'FALSE',
  },
  {
    id: '3FF41316-69DC-4913-A299-84206E12FE42',
    name: 'folder-remove',
    codepoint: 'F0258',
    deprecated: 'FALSE',
  },
  {
    id: '1990909C-A843-4BE0-83B9-83FB51707CAD',
    name: 'folder-remove-outline',
    codepoint: 'F0B9E',
    deprecated: 'FALSE',
  },
  {
    id: '8F02F34C-2C77-43BF-BF20-5EDCA4C37A85',
    name: 'folder-search',
    codepoint: 'F0968',
    deprecated: 'FALSE',
  },
  {
    id: '05CF7DE7-1D45-45C3-9BCC-363735A36550',
    name: 'folder-search-outline',
    codepoint: 'F0969',
    deprecated: 'FALSE',
  },
  {
    id: 'E9863879-31CD-48D3-B40B-FD49B692A787',
    name: 'folder-settings',
    codepoint: 'F107D',
    deprecated: 'FALSE',
  },
  {
    id: 'BE00F5DD-75FB-43C5-BE8F-56117E1EC823',
    name: 'folder-settings-outline',
    codepoint: 'F107E',
    deprecated: 'FALSE',
  },
  {
    id: '58C8B00C-897C-4381-9AD2-33B754B96865',
    name: 'folder-star',
    codepoint: 'F069D',
    deprecated: 'FALSE',
  },
  {
    id: '407F7D88-25F6-4EDF-8489-3F8B7996F704',
    name: 'folder-star-multiple',
    codepoint: 'F13D3',
    deprecated: 'FALSE',
  },
  {
    id: 'C7DD0CB0-EEA4-4D24-BCE2-8184382EF419',
    name: 'folder-star-multiple-outline',
    codepoint: 'F13D4',
    deprecated: 'FALSE',
  },
  {
    id: '3CE11413-D500-4DFA-8913-A22A16D6DAD5',
    name: 'folder-star-outline',
    codepoint: 'F0B9F',
    deprecated: 'FALSE',
  },
  {
    id: 'B8D54537-7FBC-44B4-8091-2F85D8ACF05B',
    name: 'folder-swap',
    codepoint: 'F0FB6',
    deprecated: 'FALSE',
  },
  {
    id: 'EE80C9C6-6C17-4049-9C87-B6E7E3D956EA',
    name: 'folder-swap-outline',
    codepoint: 'F0FB7',
    deprecated: 'FALSE',
  },
  {
    id: '8E493781-E2CB-48C6-8C07-24BD1F5F3BF0',
    name: 'folder-sync',
    codepoint: 'F0D0B',
    deprecated: 'FALSE',
  },
  {
    id: 'AE853799-5608-4541-BD65-C7AC6AD09256',
    name: 'folder-sync-outline',
    codepoint: 'F0D0C',
    deprecated: 'FALSE',
  },
  {
    id: '58AC43F6-6A0B-43AC-B681-A68B6714B4F1',
    name: 'folder-table',
    codepoint: 'F12E3',
    deprecated: 'FALSE',
  },
  {
    id: '1C994AC5-D1CA-457D-8A8C-F6E40821815F',
    name: 'folder-table-outline',
    codepoint: 'F12E4',
    deprecated: 'FALSE',
  },
  {
    id: '2A24B473-B162-4B0E-9723-6867EEA702A9',
    name: 'folder-text',
    codepoint: 'F0C82',
    deprecated: 'FALSE',
  },
  {
    id: 'D80E4CB4-F5E6-4BA8-A8C9-B24A3C31E56F',
    name: 'folder-text-outline',
    codepoint: 'F0C83',
    deprecated: 'FALSE',
  },
  {
    id: '9F84EC25-D8C7-4BB0-B39F-E71919F4EE6D',
    name: 'folder-upload',
    codepoint: 'F0259',
    deprecated: 'FALSE',
  },
  {
    id: '841EF884-AC6E-4930-8DEA-6C87F91BE11F',
    name: 'folder-upload-outline',
    codepoint: 'F10ED',
    deprecated: 'FALSE',
  },
  {
    id: '4A9F791A-45FC-42C8-BBBB-1963868C4E44',
    name: 'folder-wrench',
    codepoint: 'F19FC',
    deprecated: 'FALSE',
  },
  {
    id: 'D489D931-A6B8-4363-B4E4-C76190FE4B18',
    name: 'folder-wrench-outline',
    codepoint: 'F19FD',
    deprecated: 'FALSE',
  },
  {
    id: '1573C575-7FBA-4BCD-A960-02D42043FE6F',
    name: 'folder-zip',
    codepoint: 'F06EB',
    deprecated: 'FALSE',
  },
  {
    id: '55AE86E1-C6DB-4F41-BC21-1750DB608D23',
    name: 'folder-zip-outline',
    codepoint: 'F07B9',
    deprecated: 'FALSE',
  },
  {
    id: '0D7EBC60-1884-41C7-B37E-E0EED68E896E',
    name: 'font-awesome',
    codepoint: 'F003A',
    deprecated: 'TRUE',
  },
  {
    id: '140954DE-20F5-4A8B-BFC3-DB8E49922AF3',
    name: 'food',
    codepoint: 'F025A',
    deprecated: 'FALSE',
  },
  {
    id: 'D5B1A45F-8C43-4E3B-8E75-09EAF1984021',
    name: 'food-apple',
    codepoint: 'F025B',
    deprecated: 'FALSE',
  },
  {
    id: 'F1E2E692-7E18-4D28-8F6C-6E6D16161CF2',
    name: 'food-apple-outline',
    codepoint: 'F0C84',
    deprecated: 'FALSE',
  },
  {
    id: '28363B2E-72C8-481E-B702-7DE52F72717C',
    name: 'food-croissant',
    codepoint: 'F07C8',
    deprecated: 'FALSE',
  },
  {
    id: '83BA130B-E603-4455-B7C6-EDE9BE1FCC7E',
    name: 'food-drumstick',
    codepoint: 'F141F',
    deprecated: 'FALSE',
  },
  {
    id: 'BC4982E1-86E2-48A1-AE1B-8FD95D6BA850',
    name: 'food-drumstick-off',
    codepoint: 'F1468',
    deprecated: 'FALSE',
  },
  {
    id: 'CC221369-59E9-45B7-9CBD-79AB2828689A',
    name: 'food-drumstick-off-outline',
    codepoint: 'F1469',
    deprecated: 'FALSE',
  },
  {
    id: 'D60C4F90-EE53-4A92-84F7-076DAC23FD31',
    name: 'food-drumstick-outline',
    codepoint: 'F1420',
    deprecated: 'FALSE',
  },
  {
    id: '4CE93EC4-0938-4CB2-AB0E-0DCA0AF27B91',
    name: 'food-fork-drink',
    codepoint: 'F05F2',
    deprecated: 'FALSE',
  },
  {
    id: 'F576B145-F06D-49FA-9616-2423C5D322AD',
    name: 'food-halal',
    codepoint: 'F1572',
    deprecated: 'FALSE',
  },
  {
    id: '71DB0C17-56B2-4664-9AD2-BF6EDB375D59',
    name: 'food-hot-dog',
    codepoint: 'F184B',
    deprecated: 'FALSE',
  },
  {
    id: 'BC887CBF-E298-4E05-8C7D-B13539C4BA15',
    name: 'food-kosher',
    codepoint: 'F1573',
    deprecated: 'FALSE',
  },
  {
    id: '3731F58E-CAEA-4B54-87A6-7F4E039F2B31',
    name: 'food-off',
    codepoint: 'F05F3',
    deprecated: 'FALSE',
  },
  {
    id: '339A2EDA-7C62-4D1F-AB2E-2E5F7F5200FC',
    name: 'food-off-outline',
    codepoint: 'F1915',
    deprecated: 'FALSE',
  },
  {
    id: '7E16BC17-6002-4EB2-A8FA-7982CF05426E',
    name: 'food-outline',
    codepoint: 'F1916',
    deprecated: 'FALSE',
  },
  {
    id: '1A357DD0-01F0-4FF9-83D3-41B9AA22E5D1',
    name: 'food-steak',
    codepoint: 'F146A',
    deprecated: 'FALSE',
  },
  {
    id: 'A0482E2B-FA1E-4478-BA16-A7EF749920B3',
    name: 'food-steak-off',
    codepoint: 'F146B',
    deprecated: 'FALSE',
  },
  {
    id: '12052E35-8005-45AD-8901-7861C8404EF8',
    name: 'food-takeout-box',
    codepoint: 'F1836',
    deprecated: 'FALSE',
  },
  {
    id: 'BEF6335C-F745-472D-9C83-F0ED033B38B6',
    name: 'food-takeout-box-outline',
    codepoint: 'F1837',
    deprecated: 'FALSE',
  },
  {
    id: 'D5900301-8742-4D92-8E56-525DCDE97E0F',
    name: 'food-turkey',
    codepoint: 'F171C',
    deprecated: 'FALSE',
  },
  {
    id: '1D25DDEC-2656-4729-BEBD-BB17F972DD09',
    name: 'food-variant',
    codepoint: 'F025C',
    deprecated: 'FALSE',
  },
  {
    id: 'C24FE6CB-D1AA-4BAB-BB6B-956307D28D1D',
    name: 'food-variant-off',
    codepoint: 'F13E5',
    deprecated: 'FALSE',
  },
  {
    id: 'DAAA09E2-055A-4D74-BF60-C46DC6019035',
    name: 'foot-print',
    codepoint: 'F0F52',
    deprecated: 'FALSE',
  },
  {
    id: '3C0488C6-E807-401D-9689-7770550EC2E7',
    name: 'football',
    codepoint: 'F025D',
    deprecated: 'FALSE',
  },
  {
    id: '97B1FB51-7C30-4B83-AEED-95700B38F65F',
    name: 'football-australian',
    codepoint: 'F025E',
    deprecated: 'FALSE',
  },
  {
    id: '8F719D4B-9A91-46E1-81B0-00C23AA0D313',
    name: 'football-helmet',
    codepoint: 'F025F',
    deprecated: 'FALSE',
  },
  {
    id: '70EFA358-6CE3-4CC1-9F46-6283D491F2E2',
    name: 'forest',
    codepoint: 'F1897',
    deprecated: 'FALSE',
  },
  {
    id: '9CB941AF-8C23-4201-B891-A23521B011CD',
    name: 'forklift',
    codepoint: 'F07C9',
    deprecated: 'FALSE',
  },
  {
    id: '34C52C66-C35A-470B-A618-303A8B0DBD17',
    name: 'form-dropdown',
    codepoint: 'F1400',
    deprecated: 'FALSE',
  },
  {
    id: '09F44862-B3EA-4F67-8C4F-67C830FC8CF3',
    name: 'form-select',
    codepoint: 'F1401',
    deprecated: 'FALSE',
  },
  {
    id: '9C901687-3535-4B54-B963-7E92EB9CE229',
    name: 'form-textarea',
    codepoint: 'F1095',
    deprecated: 'FALSE',
  },
  {
    id: 'BB7E18FD-F473-4A80-B6A9-A17D3648E0A4',
    name: 'form-textbox',
    codepoint: 'F060E',
    deprecated: 'FALSE',
  },
  {
    id: '32C3C391-9B80-4659-BCB3-6653FE4E5108',
    name: 'form-textbox-lock',
    codepoint: 'F135D',
    deprecated: 'FALSE',
  },
  {
    id: 'F35B5B3C-762F-473C-B229-B6818D50E045',
    name: 'form-textbox-password',
    codepoint: 'F07F5',
    deprecated: 'FALSE',
  },
  {
    id: 'CF22AACF-228D-4C3A-9BE7-D5DE853A0139',
    name: 'format-align-bottom',
    codepoint: 'F0753',
    deprecated: 'FALSE',
  },
  {
    id: '9B295DCA-8352-407E-84F1-34890975D010',
    name: 'format-align-center',
    codepoint: 'F0260',
    deprecated: 'FALSE',
  },
  {
    id: '521B67DD-EC96-4B1D-B884-BE155D850F85',
    name: 'format-align-justify',
    codepoint: 'F0261',
    deprecated: 'FALSE',
  },
  {
    id: '56F0424F-3D53-486E-BC4C-0738457ACBA8',
    name: 'format-align-left',
    codepoint: 'F0262',
    deprecated: 'FALSE',
  },
  {
    id: '59384E78-B7EF-4BDA-B537-35E537C00130',
    name: 'format-align-middle',
    codepoint: 'F0754',
    deprecated: 'FALSE',
  },
  {
    id: '7289388F-3768-4833-B282-0662FD9B9A73',
    name: 'format-align-right',
    codepoint: 'F0263',
    deprecated: 'FALSE',
  },
  {
    id: '71140AD8-A0A6-45DD-9414-5DB2FBC875F1',
    name: 'format-align-top',
    codepoint: 'F0755',
    deprecated: 'FALSE',
  },
  {
    id: '55770606-AFB4-441F-B3AA-2CDE46136F4B',
    name: 'format-annotation-minus',
    codepoint: 'F0ABC',
    deprecated: 'FALSE',
  },
  {
    id: '591A45AB-511C-4791-957C-303186C666AA',
    name: 'format-annotation-plus',
    codepoint: 'F0646',
    deprecated: 'FALSE',
  },
  {
    id: '2107C153-0FEC-4171-8602-6549ACABA768',
    name: 'format-bold',
    codepoint: 'F0264',
    deprecated: 'FALSE',
  },
  {
    id: 'D5213C5D-AC53-40A5-BBCC-C8B3F849D6B4',
    name: 'format-clear',
    codepoint: 'F0265',
    deprecated: 'FALSE',
  },
  {
    id: 'C4E2DE69-EC92-490F-814A-5D8A20E7E595',
    name: 'format-color-fill',
    codepoint: 'F0266',
    deprecated: 'FALSE',
  },
  {
    id: '3681D567-0B40-47FF-843A-3258A16F935B',
    name: 'format-color-highlight',
    codepoint: 'F0E31',
    deprecated: 'FALSE',
  },
  {
    id: 'C6D32152-B2F3-43D3-A78E-D69CBDC5E054',
    name: 'format-color-marker-cancel',
    codepoint: 'F1313',
    deprecated: 'FALSE',
  },
  {
    id: 'D28EF658-3CE7-4F5B-99B3-50F60F5B0BA0',
    name: 'format-color-text',
    codepoint: 'F069E',
    deprecated: 'FALSE',
  },
  {
    id: 'FB93EA15-A9E8-432C-BC34-419DDCE44E3B',
    name: 'format-columns',
    codepoint: 'F08DF',
    deprecated: 'FALSE',
  },
  {
    id: '3BC78F10-A7C3-4F15-9328-138E35CFC259',
    name: 'format-float-center',
    codepoint: 'F0267',
    deprecated: 'FALSE',
  },
  {
    id: 'E595D32A-5108-4E9A-BF98-52DEFC1A50EE',
    name: 'format-float-left',
    codepoint: 'F0268',
    deprecated: 'FALSE',
  },
  {
    id: '22DE1517-7E6C-43B9-B0E1-F3EDAEFE6398',
    name: 'format-float-none',
    codepoint: 'F0269',
    deprecated: 'FALSE',
  },
  {
    id: '8F5AF64B-3A3D-449C-9FCC-374EAF6D523C',
    name: 'format-float-right',
    codepoint: 'F026A',
    deprecated: 'FALSE',
  },
  {
    id: 'C7D57EF8-207C-48F9-953A-EAFB03308A1E',
    name: 'format-font',
    codepoint: 'F06D6',
    deprecated: 'FALSE',
  },
  {
    id: '4FA696A5-8843-4E3F-B9BF-5BCF5B6C4D2C',
    name: 'format-font-size-decrease',
    codepoint: 'F09F3',
    deprecated: 'FALSE',
  },
  {
    id: '934DCE7F-D2A8-40F5-B945-A35DBD3F207B',
    name: 'format-font-size-increase',
    codepoint: 'F09F4',
    deprecated: 'FALSE',
  },
  {
    id: 'F64E046B-8690-4ACF-90F7-98896159BB34',
    name: 'format-header-1',
    codepoint: 'F026B',
    deprecated: 'FALSE',
  },
  {
    id: '00E2A358-A6A9-493C-BFC2-A259AA0092CF',
    name: 'format-header-2',
    codepoint: 'F026C',
    deprecated: 'FALSE',
  },
  {
    id: 'EE76BE9D-B10D-43C8-9B5A-746BE228C344',
    name: 'format-header-3',
    codepoint: 'F026D',
    deprecated: 'FALSE',
  },
  {
    id: 'CC669D6C-53AD-43C2-A660-EFF783847D6F',
    name: 'format-header-4',
    codepoint: 'F026E',
    deprecated: 'FALSE',
  },
  {
    id: 'FFC1FF08-CEFF-4623-B715-4731CDEF27D4',
    name: 'format-header-5',
    codepoint: 'F026F',
    deprecated: 'FALSE',
  },
  {
    id: '854D0158-05E0-47A7-8E6E-2F66DCD415EE',
    name: 'format-header-6',
    codepoint: 'F0270',
    deprecated: 'FALSE',
  },
  {
    id: 'CF17BB12-9537-4F2E-9E88-3E1DE3D0DCE6',
    name: 'format-header-decrease',
    codepoint: 'F0271',
    deprecated: 'FALSE',
  },
  {
    id: '99554AA1-419F-420F-9AEB-0826D411B420',
    name: 'format-header-equal',
    codepoint: 'F0272',
    deprecated: 'FALSE',
  },
  {
    id: '20BCC8DA-F055-46B0-9C27-68791E3BA80E',
    name: 'format-header-increase',
    codepoint: 'F0273',
    deprecated: 'FALSE',
  },
  {
    id: 'C632F7EE-B560-4FAA-802E-968DAB3EA979',
    name: 'format-header-pound',
    codepoint: 'F0274',
    deprecated: 'FALSE',
  },
  {
    id: '83EE24A9-9D1A-45AA-B4CD-F75F1174F0EB',
    name: 'format-horizontal-align-center',
    codepoint: 'F061E',
    deprecated: 'FALSE',
  },
  {
    id: 'AEA5545F-4C74-4A14-8F9E-92CC258216AD',
    name: 'format-horizontal-align-left',
    codepoint: 'F061F',
    deprecated: 'FALSE',
  },
  {
    id: '7EFCB642-F9B5-41C8-B2CF-2566C6AC45C2',
    name: 'format-horizontal-align-right',
    codepoint: 'F0620',
    deprecated: 'FALSE',
  },
  {
    id: '148FD97C-E3C6-4E41-87B8-488036159CC6',
    name: 'format-indent-decrease',
    codepoint: 'F0275',
    deprecated: 'FALSE',
  },
  {
    id: '18B9F49B-7A69-4E93-AEA6-8CB30F22E100',
    name: 'format-indent-increase',
    codepoint: 'F0276',
    deprecated: 'FALSE',
  },
  {
    id: 'F766C5E3-1BBB-4ADE-9D33-170A223DB6F9',
    name: 'format-italic',
    codepoint: 'F0277',
    deprecated: 'FALSE',
  },
  {
    id: 'B9EB5A17-979C-47C5-8F20-E2436511E0C4',
    name: 'format-letter-case',
    codepoint: 'F0B34',
    deprecated: 'FALSE',
  },
  {
    id: '79F06165-5718-4618-8056-1BEB689BF7CF',
    name: 'format-letter-case-lower',
    codepoint: 'F0B35',
    deprecated: 'FALSE',
  },
  {
    id: '0C748102-1ABB-4762-A407-B64C960618D7',
    name: 'format-letter-case-upper',
    codepoint: 'F0B36',
    deprecated: 'FALSE',
  },
  {
    id: 'B2B22BA8-6F5A-45F8-9A48-B6CC932DFD62',
    name: 'format-letter-ends-with',
    codepoint: 'F0FB8',
    deprecated: 'FALSE',
  },
  {
    id: '62D729FA-8643-4FAF-9D2D-176AE4ED3774',
    name: 'format-letter-matches',
    codepoint: 'F0FB9',
    deprecated: 'FALSE',
  },
  {
    id: '868506A4-50DB-4C9C-9CEA-278969298EF4',
    name: 'format-letter-spacing',
    codepoint: 'F1956',
    deprecated: 'FALSE',
  },
  {
    id: '5EC5D417-1D27-4B48-A2DD-DC4DDCF96BA3',
    name: 'format-letter-spacing-variant',
    codepoint: 'F1AFB',
    deprecated: 'FALSE',
  },
  {
    id: '870F391E-A069-461E-9296-904B34308D98',
    name: 'format-letter-starts-with',
    codepoint: 'F0FBA',
    deprecated: 'FALSE',
  },
  {
    id: '87E942A2-9A34-4EF0-9025-63CA97919465',
    name: 'format-line-height',
    codepoint: 'F1AFC',
    deprecated: 'FALSE',
  },
  {
    id: '24F69EED-721D-48A1-BA3C-3C1657B65C04',
    name: 'format-line-spacing',
    codepoint: 'F0278',
    deprecated: 'FALSE',
  },
  {
    id: '2E9CEB4F-7C1F-4572-9A8E-830A3258407A',
    name: 'format-line-style',
    codepoint: 'F05C8',
    deprecated: 'FALSE',
  },
  {
    id: '1BA8A6A0-348E-4268-B02B-6708E1E30373',
    name: 'format-line-weight',
    codepoint: 'F05C9',
    deprecated: 'FALSE',
  },
  {
    id: '2FBAE8A7-BAC7-44C8-85DC-EB458871BF29',
    name: 'format-list-bulleted',
    codepoint: 'F0279',
    deprecated: 'FALSE',
  },
  {
    id: '4AA98685-EEBB-4816-801E-3B12DCB4413F',
    name: 'format-list-bulleted-square',
    codepoint: 'F0DD0',
    deprecated: 'FALSE',
  },
  {
    id: 'C436DF00-A54E-48D1-9A4A-EA21A90E4FF5',
    name: 'format-list-bulleted-triangle',
    codepoint: 'F0EB2',
    deprecated: 'FALSE',
  },
  {
    id: '535ABAF7-65D8-4CAB-A3B0-D5B732B6C36F',
    name: 'format-list-bulleted-type',
    codepoint: 'F027A',
    deprecated: 'FALSE',
  },
  {
    id: '45A8DC8E-AD79-4205-AC8B-4BCC17B6F465',
    name: 'format-list-checkbox',
    codepoint: 'F096A',
    deprecated: 'FALSE',
  },
  {
    id: '2B19EECF-71BF-41F3-B62A-468607BA0B47',
    name: 'format-list-checks',
    codepoint: 'F0756',
    deprecated: 'FALSE',
  },
  {
    id: '894BF6B9-23A6-4C91-813C-9867E63DA5AD',
    name: 'format-list-group',
    codepoint: 'F1860',
    deprecated: 'FALSE',
  },
  {
    id: '6272EE7E-4A0B-4A08-AFC4-6FD3845FAD6B',
    name: 'format-list-group-plus',
    codepoint: 'F1B56',
    deprecated: 'FALSE',
  },
  {
    id: 'EFEB4AB3-780B-4C2D-B673-36BB0A4FE0B2',
    name: 'format-list-numbered',
    codepoint: 'F027B',
    deprecated: 'FALSE',
  },
  {
    id: '8480FB3F-93B8-4445-BE6D-56A47441D6A6',
    name: 'format-list-numbered-rtl',
    codepoint: 'F0D0D',
    deprecated: 'FALSE',
  },
  {
    id: '5458AB97-471F-4A96-92E0-FB0AE72AFF33',
    name: 'format-list-text',
    codepoint: 'F126F',
    deprecated: 'FALSE',
  },
  {
    id: '5D87D3E1-C59B-47A2-9ADE-C96FF1C7CA21',
    name: 'format-overline',
    codepoint: 'F0EB3',
    deprecated: 'FALSE',
  },
  {
    id: '4C2390EF-6AE7-4868-945B-AACD2E581F46',
    name: 'format-page-break',
    codepoint: 'F06D7',
    deprecated: 'FALSE',
  },
  {
    id: '841FF2DA-C31C-48B1-A566-10238590DAF9',
    name: 'format-page-split',
    codepoint: 'F1917',
    deprecated: 'FALSE',
  },
  {
    id: '70232BC6-D883-48CD-B992-9EE1DABEBFD7',
    name: 'format-paint',
    codepoint: 'F027C',
    deprecated: 'FALSE',
  },
  {
    id: '252574CE-020B-461A-995B-D4BA81741BBC',
    name: 'format-paragraph',
    codepoint: 'F027D',
    deprecated: 'FALSE',
  },
  {
    id: 'D92E7B40-A6F6-479D-9FFD-807AE3E470E6',
    name: 'format-paragraph-spacing',
    codepoint: 'F1AFD',
    deprecated: 'FALSE',
  },
  {
    id: '9AF465C3-5FEF-48E4-8D38-5A5A3D2A9799',
    name: 'format-pilcrow',
    codepoint: 'F06D8',
    deprecated: 'FALSE',
  },
  {
    id: 'C317FCCF-DCDB-4429-B1A7-850FED29F5B3',
    name: 'format-pilcrow-arrow-left',
    codepoint: 'F0286',
    deprecated: 'FALSE',
  },
  {
    id: 'D6B79C3B-22FB-4B52-90B8-6979AB48C3E8',
    name: 'format-pilcrow-arrow-right',
    codepoint: 'F0285',
    deprecated: 'FALSE',
  },
  {
    id: '0450C081-EE6B-4CB1-A7F7-B6F0F524CA99',
    name: 'format-quote-close',
    codepoint: 'F027E',
    deprecated: 'FALSE',
  },
  {
    id: '55118B26-26AC-43F3-B85C-1FF22306A77E',
    name: 'format-quote-close-outline',
    codepoint: 'F11A8',
    deprecated: 'FALSE',
  },
  {
    id: 'CF5D126B-7DC1-4BE7-B5A1-E31B715EDBAA',
    name: 'format-quote-open',
    codepoint: 'F0757',
    deprecated: 'FALSE',
  },
  {
    id: 'B9143BB9-06E0-40A3-8B38-D0687A1BEA69',
    name: 'format-quote-open-outline',
    codepoint: 'F11A7',
    deprecated: 'FALSE',
  },
  {
    id: '99448D5B-50D3-4F49-8E88-DD75F7DFB231',
    name: 'format-rotate-90',
    codepoint: 'F06AA',
    deprecated: 'FALSE',
  },
  {
    id: '6F0198AB-0903-4B36-AC27-B72EBC7A8A83',
    name: 'format-section',
    codepoint: 'F069F',
    deprecated: 'FALSE',
  },
  {
    id: '3F6E54F6-CF3E-46C8-BD72-A9BCF6DCF80C',
    name: 'format-size',
    codepoint: 'F027F',
    deprecated: 'FALSE',
  },
  {
    id: '0BE83BB5-3D22-478D-AAC2-F586A4E5FA69',
    name: 'format-strikethrough',
    codepoint: 'F0280',
    deprecated: 'FALSE',
  },
  {
    id: 'CB728339-FA66-440F-9E88-8A6FFE827EA1',
    name: 'format-strikethrough-variant',
    codepoint: 'F0281',
    deprecated: 'FALSE',
  },
  {
    id: '4765CE92-9095-4C00-9137-1C35761C7B59',
    name: 'format-subscript',
    codepoint: 'F0282',
    deprecated: 'FALSE',
  },
  {
    id: '41BF2594-97C2-481A-AA66-029CE1BF4C2D',
    name: 'format-superscript',
    codepoint: 'F0283',
    deprecated: 'FALSE',
  },
  {
    id: 'FE7F03F1-79D3-4113-A35E-088C3F2AB8AD',
    name: 'format-text',
    codepoint: 'F0284',
    deprecated: 'FALSE',
  },
  {
    id: 'C40131F3-E8A5-44CB-90A0-E230114C0DC3',
    name: 'format-text-rotation-angle-down',
    codepoint: 'F0FBB',
    deprecated: 'FALSE',
  },
  {
    id: '9C650D6F-20FE-4496-8973-B19509A47A18',
    name: 'format-text-rotation-angle-up',
    codepoint: 'F0FBC',
    deprecated: 'FALSE',
  },
  {
    id: '580E85A8-696F-46FE-B141-F13A94078F22',
    name: 'format-text-rotation-down',
    codepoint: 'F0D73',
    deprecated: 'FALSE',
  },
  {
    id: 'D6CC1545-8778-4A0D-9B2B-7A34DB91ADD9',
    name: 'format-text-rotation-down-vertical',
    codepoint: 'F0FBD',
    deprecated: 'FALSE',
  },
  {
    id: 'D5935D28-A7D0-4F35-9917-625E5A9666CD',
    name: 'format-text-rotation-none',
    codepoint: 'F0D74',
    deprecated: 'FALSE',
  },
  {
    id: '2A113842-ED83-4F44-B7DE-A0AFF5DDB452',
    name: 'format-text-rotation-up',
    codepoint: 'F0FBE',
    deprecated: 'FALSE',
  },
  {
    id: 'A8232518-D348-46AF-953C-907F7F0F3D51',
    name: 'format-text-rotation-vertical',
    codepoint: 'F0FBF',
    deprecated: 'FALSE',
  },
  {
    id: 'E79B26A6-CAA3-4CD8-B5C8-1D5797C4C765',
    name: 'format-text-variant',
    codepoint: 'F0E32',
    deprecated: 'FALSE',
  },
  {
    id: '98078792-391E-4CB0-8C7A-8F76868FA289',
    name: 'format-text-variant-outline',
    codepoint: 'F150F',
    deprecated: 'FALSE',
  },
  {
    id: 'DD6BADDA-B656-424C-AF6D-509CB7E382D2',
    name: 'format-text-wrapping-clip',
    codepoint: 'F0D0E',
    deprecated: 'FALSE',
  },
  {
    id: 'EF57DF3A-9441-45FD-B124-5247DB8E04C2',
    name: 'format-text-wrapping-overflow',
    codepoint: 'F0D0F',
    deprecated: 'FALSE',
  },
  {
    id: 'AB362496-50E2-4978-BA63-41F90057BB64',
    name: 'format-text-wrapping-wrap',
    codepoint: 'F0D10',
    deprecated: 'FALSE',
  },
  {
    id: '8A502343-16E9-4FF5-A0FE-28328B37E7C2',
    name: 'format-textbox',
    codepoint: 'F0D11',
    deprecated: 'FALSE',
  },
  {
    id: '4B4B86CC-C989-4629-9B9D-C31243A29DB9',
    name: 'format-title',
    codepoint: 'F05F4',
    deprecated: 'FALSE',
  },
  {
    id: 'DD218F3D-C302-477A-9B9F-482813328DE6',
    name: 'format-underline',
    codepoint: 'F0287',
    deprecated: 'FALSE',
  },
  {
    id: '27068E58-4DC3-4C61-96A2-876C621B6039',
    name: 'format-underline-wavy',
    codepoint: 'F18E9',
    deprecated: 'FALSE',
  },
  {
    id: '723F4461-61B3-4D53-A03D-302F5D9401E1',
    name: 'format-vertical-align-bottom',
    codepoint: 'F0621',
    deprecated: 'FALSE',
  },
  {
    id: '4C8985BA-B48F-4015-9389-35672ECDE544',
    name: 'format-vertical-align-center',
    codepoint: 'F0622',
    deprecated: 'FALSE',
  },
  {
    id: '06A3A04F-F7F7-4707-86FC-5DEBA497B79E',
    name: 'format-vertical-align-top',
    codepoint: 'F0623',
    deprecated: 'FALSE',
  },
  {
    id: '556AFB1F-1857-4CB9-94B8-2E7E8A8DD292',
    name: 'format-wrap-inline',
    codepoint: 'F0288',
    deprecated: 'FALSE',
  },
  {
    id: 'D5787449-1950-4A7C-8CD9-CABC628AFBCE',
    name: 'format-wrap-square',
    codepoint: 'F0289',
    deprecated: 'FALSE',
  },
  {
    id: '4AA96763-2A6C-4BF0-BA64-32AD08B530F6',
    name: 'format-wrap-tight',
    codepoint: 'F028A',
    deprecated: 'FALSE',
  },
  {
    id: '0378C7CD-8331-42A0-9C0F-114960FD5966',
    name: 'format-wrap-top-bottom',
    codepoint: 'F028B',
    deprecated: 'FALSE',
  },
  {
    id: '3AC84477-2308-4445-868C-9666AE25B054',
    name: 'forum',
    codepoint: 'F028C',
    deprecated: 'FALSE',
  },
  {
    id: '7FA0128C-C4DF-413B-9D6A-070344D26FFD',
    name: 'forum-minus',
    codepoint: 'F1AA9',
    deprecated: 'FALSE',
  },
  {
    id: 'B62A5E78-9D61-47C7-97DA-B1DFFDACFACC',
    name: 'forum-minus-outline',
    codepoint: 'F1AAA',
    deprecated: 'FALSE',
  },
  {
    id: 'E6469D88-74CE-4242-8226-85BB8F8EFFB0',
    name: 'forum-outline',
    codepoint: 'F0822',
    deprecated: 'FALSE',
  },
  {
    id: '148303DA-1AF8-48FF-82C4-A4B91108B231',
    name: 'forum-plus',
    codepoint: 'F1AAB',
    deprecated: 'FALSE',
  },
  {
    id: '9AFD97C2-4B94-49EB-8D08-E2802D6FC377',
    name: 'forum-plus-outline',
    codepoint: 'F1AAC',
    deprecated: 'FALSE',
  },
  {
    id: 'E7013FF6-E962-4ED4-BCA9-5BE12DF03146',
    name: 'forum-remove',
    codepoint: 'F1AAD',
    deprecated: 'FALSE',
  },
  {
    id: 'D58EAFB0-F7C3-4541-A585-43F80F273833',
    name: 'forum-remove-outline',
    codepoint: 'F1AAE',
    deprecated: 'FALSE',
  },
  {
    id: 'EAE07425-A6D5-4B33-A9C5-6D7E9E9E1908',
    name: 'forward',
    codepoint: 'F028D',
    deprecated: 'FALSE',
  },
  {
    id: 'A626AD09-C436-4622-905C-9C7E8164CD54',
    name: 'forwardburger',
    codepoint: 'F0D75',
    deprecated: 'FALSE',
  },
  {
    id: '9267CA84-62FD-4346-BCA8-42B4617CCB43',
    name: 'fountain',
    codepoint: 'F096B',
    deprecated: 'FALSE',
  },
  {
    id: 'E7415545-8386-424D-8CF5-933EE43D74CB',
    name: 'fountain-pen',
    codepoint: 'F0D12',
    deprecated: 'FALSE',
  },
  {
    id: '892B5243-DF37-4511-B4A1-1D688A39ED52',
    name: 'fountain-pen-tip',
    codepoint: 'F0D13',
    deprecated: 'FALSE',
  },
  {
    id: '2B87DDFB-9230-4374-BE31-996E5D21639C',
    name: 'fraction-one-half',
    codepoint: 'F1992',
    deprecated: 'FALSE',
  },
  {
    id: '54CA035A-3BAB-4349-8FD1-D50A1990D301',
    name: 'freebsd',
    codepoint: 'F08E0',
    deprecated: 'TRUE',
  },
  {
    id: 'C7BD4B3A-4B63-4FDE-99D5-87B4DA460492',
    name: 'french-fries',
    codepoint: 'F1957',
    deprecated: 'FALSE',
  },
  {
    id: 'A53E5953-2094-419A-A418-57BE05F751C8',
    name: 'frequently-asked-questions',
    codepoint: 'F0EB4',
    deprecated: 'FALSE',
  },
  {
    id: '148520BB-2172-4261-9336-3E85E5131869',
    name: 'fridge',
    codepoint: 'F0290',
    deprecated: 'FALSE',
  },
  {
    id: '032BF3C7-46C6-40D8-BBD4-4CDE8CBCC48B',
    name: 'fridge-alert',
    codepoint: 'F11B1',
    deprecated: 'FALSE',
  },
  {
    id: '9BC979D8-9012-4CB1-A041-7A19ADC8E08D',
    name: 'fridge-alert-outline',
    codepoint: 'F11B2',
    deprecated: 'FALSE',
  },
  {
    id: 'F88B80D0-1521-48F8-BC15-94BA5F14BFC1',
    name: 'fridge-bottom',
    codepoint: 'F0292',
    deprecated: 'FALSE',
  },
  {
    id: '48D0CA48-04CB-4C87-8F19-DBD5286A6E7B',
    name: 'fridge-industrial',
    codepoint: 'F15EE',
    deprecated: 'FALSE',
  },
  {
    id: '3A45CCD3-F520-4A5D-BF83-2F0C28F66337',
    name: 'fridge-industrial-alert',
    codepoint: 'F15EF',
    deprecated: 'FALSE',
  },
  {
    id: '8AAF0069-BCC9-4922-A32B-C1625C27AF98',
    name: 'fridge-industrial-alert-outline',
    codepoint: 'F15F0',
    deprecated: 'FALSE',
  },
  {
    id: '6670F529-AA9D-41B4-9D0B-D435209CE045',
    name: 'fridge-industrial-off',
    codepoint: 'F15F1',
    deprecated: 'FALSE',
  },
  {
    id: '5A046326-A491-45B3-A524-E9CA61BC7255',
    name: 'fridge-industrial-off-outline',
    codepoint: 'F15F2',
    deprecated: 'FALSE',
  },
  {
    id: 'C3B23703-739D-45E6-9FEB-CEB630D638CC',
    name: 'fridge-industrial-outline',
    codepoint: 'F15F3',
    deprecated: 'FALSE',
  },
  {
    id: '417BCDA5-DCA7-4EFF-B2BF-670E9DD10961',
    name: 'fridge-off',
    codepoint: 'F11AF',
    deprecated: 'FALSE',
  },
  {
    id: '4430029A-EC83-4078-A287-AD986DE6B2AE',
    name: 'fridge-off-outline',
    codepoint: 'F11B0',
    deprecated: 'FALSE',
  },
  {
    id: '214668FF-C50D-4D69-B916-AE66E73CB3B0',
    name: 'fridge-outline',
    codepoint: 'F028F',
    deprecated: 'FALSE',
  },
  {
    id: '3D6FCDAF-F7F8-42C2-87C6-6DF36EB8C935',
    name: 'fridge-top',
    codepoint: 'F0291',
    deprecated: 'FALSE',
  },
  {
    id: '3066672D-30F0-44BF-B396-C0A2E5BCC1FF',
    name: 'fridge-variant',
    codepoint: 'F15F4',
    deprecated: 'FALSE',
  },
  {
    id: '091DA8FF-AD64-4F13-BF05-E21B39C1E171',
    name: 'fridge-variant-alert',
    codepoint: 'F15F5',
    deprecated: 'FALSE',
  },
  {
    id: '782A053A-D6FF-4B8E-B3A2-4ADCB6A7BA9A',
    name: 'fridge-variant-alert-outline',
    codepoint: 'F15F6',
    deprecated: 'FALSE',
  },
  {
    id: '99BB3B9D-0011-4493-B80C-539874638521',
    name: 'fridge-variant-off',
    codepoint: 'F15F7',
    deprecated: 'FALSE',
  },
  {
    id: '7B4DCE91-4647-4646-A93C-D7542FEAC5AC',
    name: 'fridge-variant-off-outline',
    codepoint: 'F15F8',
    deprecated: 'FALSE',
  },
  {
    id: 'B1E31148-2318-459A-AA91-1C87D306D71A',
    name: 'fridge-variant-outline',
    codepoint: 'F15F9',
    deprecated: 'FALSE',
  },
  {
    id: '11A7EAA3-B398-4F36-80B0-A4C50965E812',
    name: 'fruit-cherries',
    codepoint: 'F1042',
    deprecated: 'FALSE',
  },
  {
    id: '693BA957-6E56-406A-B061-CCA1B916E408',
    name: 'fruit-cherries-off',
    codepoint: 'F13F8',
    deprecated: 'FALSE',
  },
  {
    id: '1F2DF3F1-F380-491D-BC09-B3262612DF23',
    name: 'fruit-citrus',
    codepoint: 'F1043',
    deprecated: 'FALSE',
  },
  {
    id: 'AA24652D-2BA2-4C09-87A4-9E71C1A18CA9',
    name: 'fruit-citrus-off',
    codepoint: 'F13F9',
    deprecated: 'FALSE',
  },
  {
    id: 'C2A76F16-19FC-4D88-B131-B90E5B3ED517',
    name: 'fruit-grapes',
    codepoint: 'F1044',
    deprecated: 'FALSE',
  },
  {
    id: 'D2E95893-08FB-4C63-9420-AD5C890EBAE6',
    name: 'fruit-grapes-outline',
    codepoint: 'F1045',
    deprecated: 'FALSE',
  },
  {
    id: 'E17D31CC-F130-4832-95AD-EBC299E1B8E5',
    name: 'fruit-pear',
    codepoint: 'F1A0E',
    deprecated: 'FALSE',
  },
  {
    id: 'F28FDEF7-E169-45AA-84E7-13DB0A6CA241',
    name: 'fruit-pineapple',
    codepoint: 'F1046',
    deprecated: 'FALSE',
  },
  {
    id: '0219370F-F54F-444F-8312-288CB435784E',
    name: 'fruit-watermelon',
    codepoint: 'F1047',
    deprecated: 'FALSE',
  },
  {
    id: '8E016660-0C3A-46EA-B3A5-8DC490238EC7',
    name: 'fuel',
    codepoint: 'F07CA',
    deprecated: 'FALSE',
  },
  {
    id: 'AF7C2BB9-3C7B-4309-89BD-58158E0CDF2A',
    name: 'fuel-cell',
    codepoint: 'F18B5',
    deprecated: 'FALSE',
  },
  {
    id: 'A4F7E2E4-704C-4A71-99D5-4BD19AD8E755',
    name: 'fullscreen',
    codepoint: 'F0293',
    deprecated: 'FALSE',
  },
  {
    id: 'CE6DC9C1-A636-4B3E-B838-34069330D3B2',
    name: 'fullscreen-exit',
    codepoint: 'F0294',
    deprecated: 'FALSE',
  },
  {
    id: '76B75225-53BF-4523-BD48-CB555FFF0C67',
    name: 'function',
    codepoint: 'F0295',
    deprecated: 'FALSE',
  },
  {
    id: '8424F867-9F66-42FB-92D3-91724FB2CA38',
    name: 'function-variant',
    codepoint: 'F0871',
    deprecated: 'FALSE',
  },
  {
    id: 'D9CC1449-7E53-4305-A521-C4E705390012',
    name: 'furigana-horizontal',
    codepoint: 'F1081',
    deprecated: 'FALSE',
  },
  {
    id: '68042B53-6F94-4EE1-A40D-9415AE0EE14A',
    name: 'furigana-vertical',
    codepoint: 'F1082',
    deprecated: 'FALSE',
  },
  {
    id: 'E1A77AA6-9DC6-49C8-8049-89EEE3545A4A',
    name: 'fuse',
    codepoint: 'F0C85',
    deprecated: 'FALSE',
  },
  {
    id: 'EDE0F403-427B-468D-9CCA-75608159964C',
    name: 'fuse-alert',
    codepoint: 'F142D',
    deprecated: 'FALSE',
  },
  {
    id: '84BD762F-B0EC-4195-A6E2-7BF1E9386AE6',
    name: 'fuse-blade',
    codepoint: 'F0C86',
    deprecated: 'FALSE',
  },
  {
    id: '174BB611-F232-474B-B563-DCC770985930',
    name: 'fuse-off',
    codepoint: 'F142C',
    deprecated: 'FALSE',
  },
  {
    id: '5C29EFA9-0FF3-4101-BC87-C9C15ACBA7AB',
    name: 'gamepad',
    codepoint: 'F0296',
    deprecated: 'FALSE',
  },
  {
    id: 'C2CE3BF5-B623-4170-AD29-CAC89C4EBB93',
    name: 'gamepad-circle',
    codepoint: 'F0E33',
    deprecated: 'FALSE',
  },
  {
    id: 'A7E881F0-76BD-4711-A238-5ECAEA02EAFC',
    name: 'gamepad-circle-down',
    codepoint: 'F0E34',
    deprecated: 'FALSE',
  },
  {
    id: 'AE90913A-5172-4699-98FC-7B935C753E1E',
    name: 'gamepad-circle-left',
    codepoint: 'F0E35',
    deprecated: 'FALSE',
  },
  {
    id: 'D227F063-202F-4B7D-9175-790055F07506',
    name: 'gamepad-circle-outline',
    codepoint: 'F0E36',
    deprecated: 'FALSE',
  },
  {
    id: '97C7D65D-6073-473D-9D21-7DCB3CA7556D',
    name: 'gamepad-circle-right',
    codepoint: 'F0E37',
    deprecated: 'FALSE',
  },
  {
    id: '233618F0-A837-4938-AEF8-93DA6A92A70E',
    name: 'gamepad-circle-up',
    codepoint: 'F0E38',
    deprecated: 'FALSE',
  },
  {
    id: 'E007AB79-ED6E-4746-9D55-A35F6BD2C93C',
    name: 'gamepad-down',
    codepoint: 'F0E39',
    deprecated: 'FALSE',
  },
  {
    id: 'D8A2D400-73A0-467E-9144-581D51735E0F',
    name: 'gamepad-left',
    codepoint: 'F0E3A',
    deprecated: 'FALSE',
  },
  {
    id: '57E03360-C609-4F40-85D0-10A2019A1EE4',
    name: 'gamepad-outline',
    codepoint: 'F1919',
    deprecated: 'FALSE',
  },
  {
    id: 'D513E54E-6492-4C2A-99E9-3E50C5662B88',
    name: 'gamepad-right',
    codepoint: 'F0E3B',
    deprecated: 'FALSE',
  },
  {
    id: '3AD46D54-4369-483C-A0B5-E03D7CF62F3B',
    name: 'gamepad-round',
    codepoint: 'F0E3C',
    deprecated: 'FALSE',
  },
  {
    id: 'ABC572E9-CE00-450F-A1E0-E59BCF0C3DC3',
    name: 'gamepad-round-down',
    codepoint: 'F0E3D',
    deprecated: 'FALSE',
  },
  {
    id: '07D26BD5-0B09-4FAF-9710-DA1F19C40C39',
    name: 'gamepad-round-left',
    codepoint: 'F0E3E',
    deprecated: 'FALSE',
  },
  {
    id: '808C141C-877E-49D3-93D6-A69F985A7881',
    name: 'gamepad-round-outline',
    codepoint: 'F0E3F',
    deprecated: 'FALSE',
  },
  {
    id: '4D10A9D6-5E25-457D-B4ED-25BCC647E62E',
    name: 'gamepad-round-right',
    codepoint: 'F0E40',
    deprecated: 'FALSE',
  },
  {
    id: 'D3C1F57B-FF1A-4CD9-AD39-E3F3AB752779',
    name: 'gamepad-round-up',
    codepoint: 'F0E41',
    deprecated: 'FALSE',
  },
  {
    id: '0FE0FB42-286B-4D56-AE93-6D93EA514C4B',
    name: 'gamepad-square',
    codepoint: 'F0EB5',
    deprecated: 'FALSE',
  },
  {
    id: '1370B995-F9FF-4B39-9414-055908214152',
    name: 'gamepad-square-outline',
    codepoint: 'F0EB6',
    deprecated: 'FALSE',
  },
  {
    id: '56D5F5A6-18F7-4FA5-8D16-48058EBD98D3',
    name: 'gamepad-up',
    codepoint: 'F0E42',
    deprecated: 'FALSE',
  },
  {
    id: 'A4DB98F5-FFD6-4F36-BB90-1B06AB202096',
    name: 'gamepad-variant',
    codepoint: 'F0297',
    deprecated: 'FALSE',
  },
  {
    id: '25A261F0-9C5E-4103-88C8-70CEBA27270D',
    name: 'gamepad-variant-outline',
    codepoint: 'F0EB7',
    deprecated: 'FALSE',
  },
  {
    id: '9D9227EC-5170-4765-832D-CDCF4AD79CAE',
    name: 'gamma',
    codepoint: 'F10EE',
    deprecated: 'FALSE',
  },
  {
    id: 'CB6D190E-D7B6-4B40-804A-B868BE682C4D',
    name: 'gantry-crane',
    codepoint: 'F0DD1',
    deprecated: 'FALSE',
  },
  {
    id: 'FC744BA5-2E98-459F-BBD6-60788B23D93E',
    name: 'garage',
    codepoint: 'F06D9',
    deprecated: 'FALSE',
  },
  {
    id: '03ED4E2F-41CE-47D7-ABB4-C8515C202499',
    name: 'garage-alert',
    codepoint: 'F0872',
    deprecated: 'FALSE',
  },
  {
    id: '25425A4D-48BD-4139-BA72-81D0DAB54020',
    name: 'garage-alert-variant',
    codepoint: 'F12D5',
    deprecated: 'FALSE',
  },
  {
    id: 'E0AE077B-AE61-4760-95DD-7ACC9423327B',
    name: 'garage-lock',
    codepoint: 'F17FB',
    deprecated: 'FALSE',
  },
  {
    id: '6B34986A-5D17-4C64-9840-994B66EC00AB',
    name: 'garage-open',
    codepoint: 'F06DA',
    deprecated: 'FALSE',
  },
  {
    id: '8AE7213F-00BD-40D9-A524-2CCB60511BBA',
    name: 'garage-open-variant',
    codepoint: 'F12D4',
    deprecated: 'FALSE',
  },
  {
    id: '11A3EA2E-E0A4-435D-B53D-55EB0E8A5F10',
    name: 'garage-variant',
    codepoint: 'F12D3',
    deprecated: 'FALSE',
  },
  {
    id: '53BD8FE6-E6BE-48B5-A68C-3064A5C0710E',
    name: 'garage-variant-lock',
    codepoint: 'F17FC',
    deprecated: 'FALSE',
  },
  {
    id: 'BB98FED5-2967-4D6D-B133-8447CE90C089',
    name: 'gas-burner',
    codepoint: 'F1A1B',
    deprecated: 'FALSE',
  },
  {
    id: '7CEC48C5-3A70-4BA2-9FB8-A383387DC4A1',
    name: 'gas-cylinder',
    codepoint: 'F0647',
    deprecated: 'FALSE',
  },
  {
    id: '50E30598-BCD8-4167-BDAC-FF6971E745D7',
    name: 'gas-station',
    codepoint: 'F0298',
    deprecated: 'FALSE',
  },
  {
    id: '7ED35A9C-D8FD-4892-84E5-3700C907AE28',
    name: 'gas-station-off',
    codepoint: 'F1409',
    deprecated: 'FALSE',
  },
  {
    id: '7C77CE4A-4C13-4853-8F4D-0F6F99EC0248',
    name: 'gas-station-off-outline',
    codepoint: 'F140A',
    deprecated: 'FALSE',
  },
  {
    id: 'E489326F-90B9-43E6-91CF-9123E914E84F',
    name: 'gas-station-outline',
    codepoint: 'F0EB8',
    deprecated: 'FALSE',
  },
  {
    id: '340B7941-DB9B-40A2-9968-100AB599F204',
    name: 'gate',
    codepoint: 'F0299',
    deprecated: 'FALSE',
  },
  {
    id: 'C9B04A2B-FB69-4F05-B2E9-32468822F4DD',
    name: 'gate-alert',
    codepoint: 'F17F8',
    deprecated: 'FALSE',
  },
  {
    id: '01E01742-E5ED-4E63-9F19-48CBE619C7A5',
    name: 'gate-and',
    codepoint: 'F08E1',
    deprecated: 'FALSE',
  },
  {
    id: '1D07255B-B5EF-4715-9926-B91757893552',
    name: 'gate-arrow-left',
    codepoint: 'F17F7',
    deprecated: 'FALSE',
  },
  {
    id: '314B9DB4-1D98-4394-BAD4-8FA5FB2EA160',
    name: 'gate-arrow-right',
    codepoint: 'F1169',
    deprecated: 'FALSE',
  },
  {
    id: 'AC9017C0-F5E5-4AD8-819F-5B540AC12FED',
    name: 'gate-buffer',
    codepoint: 'F1AFE',
    deprecated: 'FALSE',
  },
  {
    id: 'FF2BA1EF-7AAE-4271-B799-6A51FE84427E',
    name: 'gate-nand',
    codepoint: 'F08E2',
    deprecated: 'FALSE',
  },
  {
    id: '9BFED3B5-B327-480D-BFCD-18F9CC52D82A',
    name: 'gate-nor',
    codepoint: 'F08E3',
    deprecated: 'FALSE',
  },
  {
    id: '3A4475B4-3774-4A2A-B672-59DAF8AE3398',
    name: 'gate-not',
    codepoint: 'F08E4',
    deprecated: 'FALSE',
  },
  {
    id: 'F5A29B6C-A003-423D-912A-A23D40E51D3C',
    name: 'gate-open',
    codepoint: 'F116A',
    deprecated: 'FALSE',
  },
  {
    id: '13B88427-8795-48CF-B647-597997C6C541',
    name: 'gate-or',
    codepoint: 'F08E5',
    deprecated: 'FALSE',
  },
  {
    id: 'DA573FDA-7BEE-4B80-90BA-AA04932B43C6',
    name: 'gate-xnor',
    codepoint: 'F08E6',
    deprecated: 'FALSE',
  },
  {
    id: 'AFA3ABA2-1237-417E-8277-8509C7A58C1C',
    name: 'gate-xor',
    codepoint: 'F08E7',
    deprecated: 'FALSE',
  },
  {
    id: '2D5D41CF-9332-42E5-B6E9-C3A62E3A8135',
    name: 'gatsby',
    codepoint: 'F0E43',
    deprecated: 'TRUE',
  },
  {
    id: 'EC7B7499-25F1-43D4-9A09-9742F5B6D3F2',
    name: 'gauge',
    codepoint: 'F029A',
    deprecated: 'FALSE',
  },
  {
    id: '2ED34069-D2CE-427F-8E96-C493C3D9DC5D',
    name: 'gauge-empty',
    codepoint: 'F0873',
    deprecated: 'FALSE',
  },
  {
    id: '3AA721AB-566F-461F-812B-CC6652E9E800',
    name: 'gauge-full',
    codepoint: 'F0874',
    deprecated: 'FALSE',
  },
  {
    id: 'BFF1F377-BBAF-4354-A914-D0FD1BC9DA22',
    name: 'gauge-low',
    codepoint: 'F0875',
    deprecated: 'FALSE',
  },
  {
    id: 'AB600896-534E-4A82-8E7F-99E0F22B54AE',
    name: 'gavel',
    codepoint: 'F029B',
    deprecated: 'FALSE',
  },
  {
    id: '0441EBEF-476C-420E-A04E-58367F99BD93',
    name: 'gender-female',
    codepoint: 'F029C',
    deprecated: 'FALSE',
  },
  {
    id: '78C91AE2-F79B-48EF-9CCD-5E6874BA20CA',
    name: 'gender-male',
    codepoint: 'F029D',
    deprecated: 'FALSE',
  },
  {
    id: '9B06F269-893F-4F1C-910D-D26578C6D976',
    name: 'gender-male-female',
    codepoint: 'F029E',
    deprecated: 'FALSE',
  },
  {
    id: '1599350F-AA11-47A2-8D1C-B60DF1A0A5AD',
    name: 'gender-male-female-variant',
    codepoint: 'F113F',
    deprecated: 'FALSE',
  },
  {
    id: '85F577F7-626B-4D2D-A392-2671C979BEF3',
    name: 'gender-non-binary',
    codepoint: 'F1140',
    deprecated: 'FALSE',
  },
  {
    id: '47F759DA-8ECB-4BAA-8102-A843844EEBB3',
    name: 'gender-transgender',
    codepoint: 'F029F',
    deprecated: 'FALSE',
  },
  {
    id: 'D6664050-0082-4C50-B5AD-D6D0D23B842D',
    name: 'gentoo',
    codepoint: 'F08E8',
    deprecated: 'TRUE',
  },
  {
    id: 'FFC2833D-65AB-47BB-AC7C-8CFA41DF9250',
    name: 'gesture',
    codepoint: 'F07CB',
    deprecated: 'FALSE',
  },
  {
    id: 'D02C7FA0-E8E6-48B7-BEB7-4678B8D9DCA1',
    name: 'gesture-double-tap',
    codepoint: 'F073C',
    deprecated: 'FALSE',
  },
  {
    id: '17A555A5-0518-42D0-87F8-6C332F81DF18',
    name: 'gesture-pinch',
    codepoint: 'F0ABD',
    deprecated: 'FALSE',
  },
  {
    id: '82C8D8F3-13FE-4B69-A554-1FA74E516480',
    name: 'gesture-spread',
    codepoint: 'F0ABE',
    deprecated: 'FALSE',
  },
  {
    id: '0C51419E-7710-4BE2-B509-425E4E7E2CC5',
    name: 'gesture-swipe',
    codepoint: 'F0D76',
    deprecated: 'FALSE',
  },
  {
    id: '92FD3A8C-813E-4AC1-8D0B-85B89747C598',
    name: 'gesture-swipe-down',
    codepoint: 'F073D',
    deprecated: 'FALSE',
  },
  {
    id: '5032C271-1D07-4318-9FC6-813C3ECA11FF',
    name: 'gesture-swipe-horizontal',
    codepoint: 'F0ABF',
    deprecated: 'FALSE',
  },
  {
    id: 'D0887CD1-2BF1-4A5A-B573-2BA6FF8A6129',
    name: 'gesture-swipe-left',
    codepoint: 'F073E',
    deprecated: 'FALSE',
  },
  {
    id: '9B3D3150-CF71-473E-AF65-403C389C308A',
    name: 'gesture-swipe-right',
    codepoint: 'F073F',
    deprecated: 'FALSE',
  },
  {
    id: 'CE26D04A-C6AC-4379-AA25-A702F49F1DC2',
    name: 'gesture-swipe-up',
    codepoint: 'F0740',
    deprecated: 'FALSE',
  },
  {
    id: '8883FDF0-A6B8-4252-BC2A-D12F99D20501',
    name: 'gesture-swipe-vertical',
    codepoint: 'F0AC0',
    deprecated: 'FALSE',
  },
  {
    id: 'D881AFBC-7477-46C2-9269-324676F19E51',
    name: 'gesture-tap',
    codepoint: 'F0741',
    deprecated: 'FALSE',
  },
  {
    id: 'FC2EE3B3-9885-4278-9BCE-491B27B4666E',
    name: 'gesture-tap-box',
    codepoint: 'F12A9',
    deprecated: 'FALSE',
  },
  {
    id: '51E5488F-71C4-4E08-950D-EAAAB7B22D53',
    name: 'gesture-tap-button',
    codepoint: 'F12A8',
    deprecated: 'FALSE',
  },
  {
    id: 'B502F4A5-5545-42F7-A0B4-E90BF4421D17',
    name: 'gesture-tap-hold',
    codepoint: 'F0D77',
    deprecated: 'FALSE',
  },
  {
    id: '96C02705-84D1-4677-9DDE-CCDE1D43FE11',
    name: 'gesture-two-double-tap',
    codepoint: 'F0742',
    deprecated: 'FALSE',
  },
  {
    id: 'AA47E759-39DE-4A5C-B3CF-FAEB215EAB91',
    name: 'gesture-two-tap',
    codepoint: 'F0743',
    deprecated: 'FALSE',
  },
  {
    id: '6B39D7DA-255C-4615-84D4-FFCF34D76A4A',
    name: 'ghost',
    codepoint: 'F02A0',
    deprecated: 'FALSE',
  },
  {
    id: '5322EB23-9081-4E99-9735-7C31003C0167',
    name: 'ghost-off',
    codepoint: 'F09F5',
    deprecated: 'FALSE',
  },
  {
    id: '9B371D94-3C53-410F-A4A5-024415B30244',
    name: 'ghost-off-outline',
    codepoint: 'F165C',
    deprecated: 'FALSE',
  },
  {
    id: '71BF426E-71FE-4AEC-946A-A2CCAC371C31',
    name: 'ghost-outline',
    codepoint: 'F165D',
    deprecated: 'FALSE',
  },
  {
    id: '62403C7B-F20E-489B-8835-28F7544D9712',
    name: 'gift',
    codepoint: 'F0E44',
    deprecated: 'FALSE',
  },
  {
    id: '17729CE1-90B8-4C63-A142-99056CDDC860',
    name: 'gift-off',
    codepoint: 'F16EF',
    deprecated: 'FALSE',
  },
  {
    id: 'E1260280-2F0F-4D7D-AA15-0322C8699541',
    name: 'gift-off-outline',
    codepoint: 'F16F0',
    deprecated: 'FALSE',
  },
  {
    id: '04D976AC-875C-42F1-A9F6-B86FDAAD6990',
    name: 'gift-open',
    codepoint: 'F16F1',
    deprecated: 'FALSE',
  },
  {
    id: '64BCFFC7-8E6B-4644-B14D-5B1F9EF16899',
    name: 'gift-open-outline',
    codepoint: 'F16F2',
    deprecated: 'FALSE',
  },
  {
    id: '22D0C782-CD05-4FEB-845F-BBA7126C7326',
    name: 'gift-outline',
    codepoint: 'F02A1',
    deprecated: 'FALSE',
  },
  {
    id: '4CB8AF2D-5373-4247-AB9D-3B28B72EB7BC',
    name: 'git',
    codepoint: 'F02A2',
    deprecated: 'TRUE',
  },
  {
    id: '5C6175DD-8549-4004-AB77-A545D721A0DE',
    name: 'github',
    codepoint: 'F02A4',
    deprecated: 'TRUE',
  },
  {
    id: '6C0EA9E0-8F4F-40CB-A2B2-A2D84C97EE6A',
    name: 'gitlab',
    codepoint: 'F0BA0',
    deprecated: 'TRUE',
  },
  {
    id: '9291BDB1-CE57-4802-8CD2-7AD2ED9D9959',
    name: 'glass-cocktail',
    codepoint: 'F0356',
    deprecated: 'FALSE',
  },
  {
    id: '5CB87909-09B4-47FD-B4BF-EB4A949F3CA2',
    name: 'glass-cocktail-off',
    codepoint: 'F15E6',
    deprecated: 'FALSE',
  },
  {
    id: 'CD1215BB-D889-4A86-8944-AF724C71DF21',
    name: 'glass-flute',
    codepoint: 'F02A5',
    deprecated: 'FALSE',
  },
  {
    id: '8D20FB31-71AC-4697-899B-2156B6193F75',
    name: 'glass-fragile',
    codepoint: 'F1873',
    deprecated: 'FALSE',
  },
  {
    id: '9081982B-5EB6-47C1-A1F3-A83A0ED1B609',
    name: 'glass-mug',
    codepoint: 'F02A6',
    deprecated: 'FALSE',
  },
  {
    id: 'BEAE85BE-708C-4D0D-82AE-370BB43B3D0D',
    name: 'glass-mug-off',
    codepoint: 'F15E7',
    deprecated: 'FALSE',
  },
  {
    id: '04D0C33A-12E7-4DC1-96FA-A83A481962D4',
    name: 'glass-mug-variant',
    codepoint: 'F1116',
    deprecated: 'FALSE',
  },
  {
    id: '61B2CF99-322E-448E-A1DB-E27B94600BC4',
    name: 'glass-mug-variant-off',
    codepoint: 'F15E8',
    deprecated: 'FALSE',
  },
  {
    id: 'AFF2782E-A558-40EF-9B8C-787BAE055D57',
    name: 'glass-pint-outline',
    codepoint: 'F130D',
    deprecated: 'FALSE',
  },
  {
    id: 'E26942A0-61AE-4D1A-941C-895D92E94C5D',
    name: 'glass-stange',
    codepoint: 'F02A7',
    deprecated: 'FALSE',
  },
  {
    id: '43E8B624-633D-472F-991E-4AEDB20A5454',
    name: 'glass-tulip',
    codepoint: 'F02A8',
    deprecated: 'FALSE',
  },
  {
    id: '0AF778AB-1048-4307-84AD-CB88D259DC02',
    name: 'glass-wine',
    codepoint: 'F0876',
    deprecated: 'FALSE',
  },
  {
    id: 'BE4C1227-C47E-4839-85AA-4EA6928BAAAE',
    name: 'glasses',
    codepoint: 'F02AA',
    deprecated: 'FALSE',
  },
  {
    id: '2C23A3F9-54C7-4C7F-8C2F-182A4F75F894',
    name: 'globe-light',
    codepoint: 'F066F',
    deprecated: 'FALSE',
  },
  {
    id: 'A498671D-3C09-4874-B7BA-3F371208F4A0',
    name: 'globe-light-outline',
    codepoint: 'F12D7',
    deprecated: 'FALSE',
  },
  {
    id: '3E5EE2B1-AE6A-4675-BBAB-1D6B70FBFC36',
    name: 'globe-model',
    codepoint: 'F08E9',
    deprecated: 'FALSE',
  },
  {
    id: '259F5EC9-395F-40C8-9389-6B6A82D9997C',
    name: 'gmail',
    codepoint: 'F02AB',
    deprecated: 'TRUE',
  },
  {
    id: '2C4AF25A-8EC5-4B3B-BBCD-60F801F553B8',
    name: 'gnome',
    codepoint: 'F02AC',
    deprecated: 'TRUE',
  },
  {
    id: '9E6D217A-874E-4295-9F85-9490FF14201C',
    name: 'go-kart',
    codepoint: 'F0D79',
    deprecated: 'FALSE',
  },
  {
    id: '04D32441-2FD3-4273-8C9A-D5C7BD1F261E',
    name: 'go-kart-track',
    codepoint: 'F0D7A',
    deprecated: 'FALSE',
  },
  {
    id: '3B6D59D1-6329-4453-A11D-FB1D53B745F9',
    name: 'gog',
    codepoint: 'F0BA1',
    deprecated: 'TRUE',
  },
  {
    id: '8B0AC7A2-11DB-43DF-96AF-D6193A2DCEFE',
    name: 'gold',
    codepoint: 'F124F',
    deprecated: 'FALSE',
  },
  {
    id: '80A4F47A-3512-4C76-AA10-C0232C57E624',
    name: 'golf',
    codepoint: 'F0823',
    deprecated: 'FALSE',
  },
  {
    id: '70C7E562-43DC-4F7A-BF4C-77162406605A',
    name: 'golf-cart',
    codepoint: 'F11A4',
    deprecated: 'FALSE',
  },
  {
    id: 'A37545D0-964F-4975-A004-8EEF7CE91ED4',
    name: 'golf-tee',
    codepoint: 'F1083',
    deprecated: 'FALSE',
  },
  {
    id: '9FE486F5-20A0-4CB5-A820-93AD82F52EB6',
    name: 'gondola',
    codepoint: 'F0686',
    deprecated: 'FALSE',
  },
  {
    id: 'DECFD1E8-72A4-41F7-AF7F-68ED710AC1AD',
    name: 'goodreads',
    codepoint: 'F0D7B',
    deprecated: 'TRUE',
  },
  {
    id: 'F787FBCA-3C54-43CC-AE16-E23436AD0720',
    name: 'google',
    codepoint: 'F02AD',
    deprecated: 'TRUE',
  },
  {
    id: '300B71B3-499A-4998-BA9D-22F2190229AA',
    name: 'google-ads',
    codepoint: 'F0C87',
    deprecated: 'TRUE',
  },
  {
    id: 'B328003D-F2FD-4C31-8171-BD6EB5A81A8A',
    name: 'google-analytics',
    codepoint: 'F07CC',
    deprecated: 'TRUE',
  },
  {
    id: '3DD7794E-8603-49D0-B34E-DD0939225AAF',
    name: 'google-assistant',
    codepoint: 'F07CD',
    deprecated: 'TRUE',
  },
  {
    id: '8CC1E909-6274-4738-AF1D-0E2C3239E853',
    name: 'google-cardboard',
    codepoint: 'F02AE',
    deprecated: 'TRUE',
  },
  {
    id: '0B7D7D86-21E1-4759-8E4D-5D2BB291BB3F',
    name: 'google-chrome',
    codepoint: 'F02AF',
    deprecated: 'TRUE',
  },
  {
    id: 'FAF03601-DED3-4AD2-B252-DF739AC96986',
    name: 'google-circles',
    codepoint: 'F02B0',
    deprecated: 'TRUE',
  },
  {
    id: '073A8D2A-C834-4655-AA4F-792D7ADD7E6E',
    name: 'google-circles-communities',
    codepoint: 'F02B1',
    deprecated: 'TRUE',
  },
  {
    id: '9ABBC701-54DE-48B3-A579-6FB3503F8929',
    name: 'google-circles-extended',
    codepoint: 'F02B2',
    deprecated: 'TRUE',
  },
  {
    id: '00CC15EA-653E-4F50-B681-3176664F9B3C',
    name: 'google-circles-group',
    codepoint: 'F02B3',
    deprecated: 'TRUE',
  },
  {
    id: '432C2FB2-AA01-40F8-AAB1-CB964967D3BD',
    name: 'google-classroom',
    codepoint: 'F02C0',
    deprecated: 'TRUE',
  },
  {
    id: '14A23373-DFBF-443C-9143-90E6C78D144C',
    name: 'google-cloud',
    codepoint: 'F11F6',
    deprecated: 'TRUE',
  },
  {
    id: 'D9285386-5BA5-423A-8F3B-435E7A57363A',
    name: 'google-downasaur',
    codepoint: 'F1362',
    deprecated: 'FALSE',
  },
  {
    id: 'EE98C0AD-0A55-4A6B-BB7A-01CBD3D819A2',
    name: 'google-drive',
    codepoint: 'F02B6',
    deprecated: 'TRUE',
  },
  {
    id: '8A61F8F2-511B-45E9-9459-27BBD58936F8',
    name: 'google-earth',
    codepoint: 'F02B7',
    deprecated: 'TRUE',
  },
  {
    id: '0BBD8E67-124A-4788-B44F-9583BC6E7503',
    name: 'google-fit',
    codepoint: 'F096C',
    deprecated: 'TRUE',
  },
  {
    id: '6A73A89B-2C02-4DB5-89DF-0449750171BC',
    name: 'google-glass',
    codepoint: 'F02B8',
    deprecated: 'TRUE',
  },
  {
    id: '3EA0D516-133D-4A5E-865B-82B7CC2763D5',
    name: 'google-hangouts',
    codepoint: 'F02C9',
    deprecated: 'TRUE',
  },
  {
    id: '73BACA3B-710C-4F23-9730-D88ADA3EA914',
    name: 'google-keep',
    codepoint: 'F06DC',
    deprecated: 'TRUE',
  },
  {
    id: 'BB7275FF-E0B1-4D63-8EF5-467F23BC5D33',
    name: 'google-lens',
    codepoint: 'F09F6',
    deprecated: 'TRUE',
  },
  {
    id: '21FBF51C-0081-4987-8129-CFB0BB81729F',
    name: 'google-maps',
    codepoint: 'F05F5',
    deprecated: 'TRUE',
  },
  {
    id: '8DBB2A33-94F4-4676-95F2-F81D87C21FE1',
    name: 'google-my-business',
    codepoint: 'F1048',
    deprecated: 'FALSE',
  },
  {
    id: '576EFEB3-D7E0-463F-A783-19A0F97D45EE',
    name: 'google-nearby',
    codepoint: 'F02B9',
    deprecated: 'FALSE',
  },
  {
    id: '5FE693A7-2A33-46A5-B502-D42588072E56',
    name: 'google-play',
    codepoint: 'F02BC',
    deprecated: 'TRUE',
  },
  {
    id: '0D3A668A-E3B7-4512-B22D-10B13362DC2B',
    name: 'google-plus',
    codepoint: 'F02BD',
    deprecated: 'TRUE',
  },
  {
    id: '2C6E6048-97ED-4CA1-B8CE-31CEB31670C3',
    name: 'google-podcast',
    codepoint: 'F0EB9',
    deprecated: 'FALSE',
  },
  {
    id: '837DFB0D-633B-4667-9C40-272186D68351',
    name: 'google-spreadsheet',
    codepoint: 'F09F7',
    deprecated: 'FALSE',
  },
  {
    id: 'EC38CB3D-A8A1-48E8-9A71-9BE93D6847FC',
    name: 'google-street-view',
    codepoint: 'F0C88',
    deprecated: 'FALSE',
  },
  {
    id: 'DFF485C3-0A13-4546-A267-7687AF1AB82F',
    name: 'google-translate',
    codepoint: 'F02BF',
    deprecated: 'TRUE',
  },
  {
    id: '525D2C64-FE7A-44F8-84E2-B1DDDF3FA668',
    name: 'gradient-horizontal',
    codepoint: 'F174A',
    deprecated: 'FALSE',
  },
  {
    id: 'F10A05F8-FD09-4BDA-B906-747E9A89AB6E',
    name: 'gradient-vertical',
    codepoint: 'F06A0',
    deprecated: 'FALSE',
  },
  {
    id: 'D1784DB9-E2F2-44AA-AF8D-E562D8E3CBBC',
    name: 'grain',
    codepoint: 'F0D7C',
    deprecated: 'FALSE',
  },
  {
    id: '76AC59FA-C58D-4A55-AE8F-A351AF2CD43A',
    name: 'graph',
    codepoint: 'F1049',
    deprecated: 'FALSE',
  },
  {
    id: '2B8A2BB1-0887-493F-A644-88015BFA507A',
    name: 'graph-outline',
    codepoint: 'F104A',
    deprecated: 'FALSE',
  },
  {
    id: '50FEA1C7-FB0B-4920-BDA4-D56A87928A75',
    name: 'graphql',
    codepoint: 'F0877',
    deprecated: 'TRUE',
  },
  {
    id: '8688646A-F06F-421F-83B1-8AA8FABF7BD8',
    name: 'grass',
    codepoint: 'F1510',
    deprecated: 'FALSE',
  },
  {
    id: '4986CAC4-4886-4868-8D81-28F00376536A',
    name: 'grave-stone',
    codepoint: 'F0BA2',
    deprecated: 'FALSE',
  },
  {
    id: '137F01F1-86AC-4923-A9D7-C72057367BB7',
    name: 'grease-pencil',
    codepoint: 'F0648',
    deprecated: 'FALSE',
  },
  {
    id: '8E839EA1-A50F-41C5-BB89-14904048B885',
    name: 'greater-than',
    codepoint: 'F096D',
    deprecated: 'FALSE',
  },
  {
    id: '62C57FBE-8457-442F-AD8D-510CF2080FFF',
    name: 'greater-than-or-equal',
    codepoint: 'F096E',
    deprecated: 'FALSE',
  },
  {
    id: '9AE6BA3C-694B-47D5-99C3-7D063228F053',
    name: 'greenhouse',
    codepoint: 'F002D',
    deprecated: 'FALSE',
  },
  {
    id: '8D4C203D-A1F0-42DA-997A-AB7BAC63D97B',
    name: 'grid',
    codepoint: 'F02C1',
    deprecated: 'FALSE',
  },
  {
    id: '4A3863FA-620C-4564-8A7B-57681117B81D',
    name: 'grid-large',
    codepoint: 'F0758',
    deprecated: 'FALSE',
  },
  {
    id: '4A8DDCEF-7E60-4864-9CC3-64C9A5B0D6D4',
    name: 'grid-off',
    codepoint: 'F02C2',
    deprecated: 'FALSE',
  },
  {
    id: '22C8B00E-9BA8-4C66-994B-A2F0B4D369DD',
    name: 'grill',
    codepoint: 'F0E45',
    deprecated: 'FALSE',
  },
  {
    id: '36BD13EA-FFEC-48CF-855C-C30831680961',
    name: 'grill-outline',
    codepoint: 'F118A',
    deprecated: 'FALSE',
  },
  {
    id: 'D1B31666-9F9A-4CAB-843B-4BFEDBCC5347',
    name: 'group',
    codepoint: 'F02C3',
    deprecated: 'FALSE',
  },
  {
    id: 'C4015804-7FF3-4483-94CA-CEB79F7B34BC',
    name: 'guitar-acoustic',
    codepoint: 'F0771',
    deprecated: 'FALSE',
  },
  {
    id: 'C2F16EBD-E72B-45E0-9A34-0BAF7ADB87B3',
    name: 'guitar-electric',
    codepoint: 'F02C4',
    deprecated: 'FALSE',
  },
  {
    id: '68377A86-6428-4553-B768-7E9FC306E962',
    name: 'guitar-pick',
    codepoint: 'F02C5',
    deprecated: 'FALSE',
  },
  {
    id: '0FA22632-A0B2-49E0-A779-56022111C9D8',
    name: 'guitar-pick-outline',
    codepoint: 'F02C6',
    deprecated: 'FALSE',
  },
  {
    id: 'FDDC802D-37F5-4E15-96DA-8A6D553B1D67',
    name: 'guy-fawkes-mask',
    codepoint: 'F0825',
    deprecated: 'FALSE',
  },
  {
    id: '450F9873-EC55-4361-99EB-BBFF202647C6',
    name: 'gymnastics',
    codepoint: 'F1A41',
    deprecated: 'FALSE',
  },
  {
    id: 'B4AC04DD-9F29-4F55-ABB6-A4AE4F272EC4',
    name: 'hail',
    codepoint: 'F0AC1',
    deprecated: 'FALSE',
  },
  {
    id: '57248AE7-78F7-4493-8909-5EA0A48821ED',
    name: 'hair-dryer',
    codepoint: 'F10EF',
    deprecated: 'FALSE',
  },
  {
    id: 'AC508D19-C3A8-4EC3-A46B-21F15DB6A8B1',
    name: 'hair-dryer-outline',
    codepoint: 'F10F0',
    deprecated: 'FALSE',
  },
  {
    id: 'CE482051-152D-4A7F-A696-74D8CD398FEF',
    name: 'halloween',
    codepoint: 'F0BA3',
    deprecated: 'FALSE',
  },
  {
    id: 'F5BC2290-D719-4974-9920-DF8E08E5F955',
    name: 'hamburger',
    codepoint: 'F0685',
    deprecated: 'FALSE',
  },
  {
    id: '15C2280B-B4C8-436A-8D57-80FFD3C4FB62',
    name: 'hamburger-check',
    codepoint: 'F1776',
    deprecated: 'FALSE',
  },
  {
    id: 'CCD3F888-3CB7-4FB2-AB04-E7D8F803ABE9',
    name: 'hamburger-minus',
    codepoint: 'F1777',
    deprecated: 'FALSE',
  },
  {
    id: '540219BD-FDFC-484B-8ACC-D75760F31FD7',
    name: 'hamburger-off',
    codepoint: 'F1778',
    deprecated: 'FALSE',
  },
  {
    id: 'AD90D2EE-E142-45C3-977E-4D8299E12930',
    name: 'hamburger-plus',
    codepoint: 'F1779',
    deprecated: 'FALSE',
  },
  {
    id: '52F5DBCE-78B0-49ED-BBDB-73B8F34084A0',
    name: 'hamburger-remove',
    codepoint: 'F177A',
    deprecated: 'FALSE',
  },
  {
    id: 'A3F55E77-82C2-48F6-B1B1-92C9259C9C26',
    name: 'hammer',
    codepoint: 'F08EA',
    deprecated: 'FALSE',
  },
  {
    id: 'CDD24BC0-E8F8-4B6E-B160-BC8DABC286A9',
    name: 'hammer-screwdriver',
    codepoint: 'F1322',
    deprecated: 'FALSE',
  },
  {
    id: 'B990F624-1A91-424E-9AC0-08D95788FC9C',
    name: 'hammer-sickle',
    codepoint: 'F1887',
    deprecated: 'FALSE',
  },
  {
    id: '86D5756B-DE86-4273-A475-4E2D9856B0C3',
    name: 'hammer-wrench',
    codepoint: 'F1323',
    deprecated: 'FALSE',
  },
  {
    id: 'D2652D4F-E184-4BCC-A8A3-E53145D27911',
    name: 'hand-back-left',
    codepoint: 'F0E46',
    deprecated: 'FALSE',
  },
  {
    id: 'FFD6548D-6F8F-4C98-A918-19213C6E30AD',
    name: 'hand-back-left-off',
    codepoint: 'F1830',
    deprecated: 'FALSE',
  },
  {
    id: '20F7B0D0-5EB2-4F42-9EFD-2F7CA2C1D49D',
    name: 'hand-back-left-off-outline',
    codepoint: 'F1832',
    deprecated: 'FALSE',
  },
  {
    id: '07ED0125-29A3-4DFE-8838-AABAE30A98EE',
    name: 'hand-back-left-outline',
    codepoint: 'F182C',
    deprecated: 'FALSE',
  },
  {
    id: '35FBF26D-56C0-4699-A2A8-19BC2C37D6AF',
    name: 'hand-back-right',
    codepoint: 'F0E47',
    deprecated: 'FALSE',
  },
  {
    id: 'B7FB9D4E-CCF7-4368-88FC-5BDDE33E3FAF',
    name: 'hand-back-right-off',
    codepoint: 'F1831',
    deprecated: 'FALSE',
  },
  {
    id: 'EBFF43CF-9625-46EF-A223-CC17DBF78CBE',
    name: 'hand-back-right-off-outline',
    codepoint: 'F1833',
    deprecated: 'FALSE',
  },
  {
    id: 'F1DF9FAF-F8B8-4B11-BDFB-EE6BEFAC322A',
    name: 'hand-back-right-outline',
    codepoint: 'F182D',
    deprecated: 'FALSE',
  },
  {
    id: '8FEFA10A-395F-47B9-B5B9-0040905E9E1F',
    name: 'hand-clap',
    codepoint: 'F194B',
    deprecated: 'FALSE',
  },
  {
    id: '9B126394-E75C-416A-8959-F76B5FE2A5E0',
    name: 'hand-clap-off',
    codepoint: 'F1A42',
    deprecated: 'FALSE',
  },
  {
    id: '861C015B-47CA-41A6-8994-E43D980ECD58',
    name: 'hand-coin',
    codepoint: 'F188F',
    deprecated: 'FALSE',
  },
  {
    id: '11B36B9E-D054-4A10-996E-62F897EF12C7',
    name: 'hand-coin-outline',
    codepoint: 'F1890',
    deprecated: 'FALSE',
  },
  {
    id: 'CC8C7CF5-103C-4D0F-A209-05A19DF80F24',
    name: 'hand-cycle',
    codepoint: 'F1B9C',
    deprecated: 'FALSE',
  },
  {
    id: '94B9D1E7-C84C-413B-B999-92BD6508D013',
    name: 'hand-extended',
    codepoint: 'F18B6',
    deprecated: 'FALSE',
  },
  {
    id: 'F0A0EEAD-9751-407D-87C5-AA9300E15043',
    name: 'hand-extended-outline',
    codepoint: 'F18B7',
    deprecated: 'FALSE',
  },
  {
    id: '9A2B8032-A5D9-4E2A-8865-511EFE742611',
    name: 'hand-front-left',
    codepoint: 'F182B',
    deprecated: 'FALSE',
  },
  {
    id: '09E98CF1-3EEC-4A94-B5F1-A6A734631959',
    name: 'hand-front-left-outline',
    codepoint: 'F182E',
    deprecated: 'FALSE',
  },
  {
    id: 'C30FC465-2DF1-4716-AAC9-F48213F23D65',
    name: 'hand-front-right',
    codepoint: 'F0A4F',
    deprecated: 'FALSE',
  },
  {
    id: 'EC0CC485-BD34-49FB-AB06-D8244F1B9166',
    name: 'hand-front-right-outline',
    codepoint: 'F182F',
    deprecated: 'FALSE',
  },
  {
    id: '5B605565-DBBC-4141-8B07-D8009B74D4E1',
    name: 'hand-heart',
    codepoint: 'F10F1',
    deprecated: 'FALSE',
  },
  {
    id: '169736D6-CE5B-4143-A4A2-5D95088493F3',
    name: 'hand-heart-outline',
    codepoint: 'F157E',
    deprecated: 'FALSE',
  },
  {
    id: 'F81DD09B-46EB-466D-A0E3-DE4CAEC86793',
    name: 'hand-okay',
    codepoint: 'F0A50',
    deprecated: 'FALSE',
  },
  {
    id: '19740ECA-C3A2-49D1-B7BA-91C97EA150D7',
    name: 'hand-peace',
    codepoint: 'F0A51',
    deprecated: 'FALSE',
  },
  {
    id: '1B7E8570-378E-4099-9683-505B4E2744EA',
    name: 'hand-peace-variant',
    codepoint: 'F0A52',
    deprecated: 'FALSE',
  },
  {
    id: '1D015884-E9B8-401E-8931-E2DA1604BF1B',
    name: 'hand-pointing-down',
    codepoint: 'F0A53',
    deprecated: 'FALSE',
  },
  {
    id: '26BC12CA-4558-4C45-BBDB-04BE1E13E814',
    name: 'hand-pointing-left',
    codepoint: 'F0A54',
    deprecated: 'FALSE',
  },
  {
    id: 'C18F21E1-2363-42DD-8FFF-3381AC368C23',
    name: 'hand-pointing-right',
    codepoint: 'F02C7',
    deprecated: 'FALSE',
  },
  {
    id: '457F3CB5-7B39-417F-A1CE-D1DB5A22159D',
    name: 'hand-pointing-up',
    codepoint: 'F0A55',
    deprecated: 'FALSE',
  },
  {
    id: 'A0B645B5-20F6-4682-9E88-96784D8732CF',
    name: 'hand-saw',
    codepoint: 'F0E48',
    deprecated: 'FALSE',
  },
  {
    id: '4C201756-1101-4C58-9FBE-5F781347F535',
    name: 'hand-wash',
    codepoint: 'F157F',
    deprecated: 'FALSE',
  },
  {
    id: 'C5EA3223-8736-4F38-82A1-B3FC5F206879',
    name: 'hand-wash-outline',
    codepoint: 'F1580',
    deprecated: 'FALSE',
  },
  {
    id: 'C3BBE9FF-7FF7-4C8F-8185-A8F3825F3CAE',
    name: 'hand-water',
    codepoint: 'F139F',
    deprecated: 'FALSE',
  },
  {
    id: '2AE6B41D-EC56-4851-BEB2-B6A22B1E9D44',
    name: 'hand-wave',
    codepoint: 'F1821',
    deprecated: 'FALSE',
  },
  {
    id: '0E2992F4-2F2E-4A96-8402-FBA2B9C8675C',
    name: 'hand-wave-outline',
    codepoint: 'F1822',
    deprecated: 'FALSE',
  },
  {
    id: '845C7000-7198-490F-8043-EC1F61CE4F53',
    name: 'handball',
    codepoint: 'F0F53',
    deprecated: 'FALSE',
  },
  {
    id: '1451F3F4-D421-4A26-A22D-0ED80A815DF6',
    name: 'handcuffs',
    codepoint: 'F113E',
    deprecated: 'FALSE',
  },
  {
    id: '9098736F-D298-4724-AE04-6BEA124F3854',
    name: 'hands-pray',
    codepoint: 'F0579',
    deprecated: 'FALSE',
  },
  {
    id: 'A41E35E2-6756-46D9-BE48-4DFA705A6A6A',
    name: 'handshake',
    codepoint: 'F1218',
    deprecated: 'FALSE',
  },
  {
    id: '11A33144-9847-4ED2-A429-682B79AAE06F',
    name: 'handshake-outline',
    codepoint: 'F15A1',
    deprecated: 'FALSE',
  },
  {
    id: '2FB0994B-BBA8-4F59-93AF-87F2065F34EA',
    name: 'hanger',
    codepoint: 'F02C8',
    deprecated: 'FALSE',
  },
  {
    id: 'C0F69308-03DA-446D-9B7F-A5B2B5BF1D90',
    name: 'hard-hat',
    codepoint: 'F096F',
    deprecated: 'FALSE',
  },
  {
    id: '6CBB2398-E7ED-4A08-8489-1FFF87D0605C',
    name: 'harddisk',
    codepoint: 'F02CA',
    deprecated: 'FALSE',
  },
  {
    id: 'C4EE3559-8772-4A8D-A549-A1A11DEEC830',
    name: 'harddisk-plus',
    codepoint: 'F104B',
    deprecated: 'FALSE',
  },
  {
    id: '07449A81-73AE-484C-8739-E008C38502F1',
    name: 'harddisk-remove',
    codepoint: 'F104C',
    deprecated: 'FALSE',
  },
  {
    id: '65EED4C7-8306-4688-83ED-7D1D527B39AB',
    name: 'hat-fedora',
    codepoint: 'F0BA4',
    deprecated: 'FALSE',
  },
  {
    id: 'AEB30C8D-5159-4897-8D17-6EFB9715D3CD',
    name: 'hazard-lights',
    codepoint: 'F0C89',
    deprecated: 'FALSE',
  },
  {
    id: '96185CFC-B966-48B1-AC7A-EF12F73C2096',
    name: 'hdmi-port',
    codepoint: 'F1BB8',
    deprecated: 'FALSE',
  },
  {
    id: '81182C14-380B-4F56-AFF9-EF636EC7BF7E',
    name: 'hdr',
    codepoint: 'F0D7D',
    deprecated: 'FALSE',
  },
  {
    id: 'D0ACA2D8-09A4-4C13-81FE-26F7D9C617E5',
    name: 'hdr-off',
    codepoint: 'F0D7E',
    deprecated: 'FALSE',
  },
  {
    id: '6C83ACD1-95E8-4DE0-837A-4C66E07533DD',
    name: 'head',
    codepoint: 'F135E',
    deprecated: 'FALSE',
  },
  {
    id: '783B2BB8-1ECA-4E19-8F45-9FCC8D749F99',
    name: 'head-alert',
    codepoint: 'F1338',
    deprecated: 'FALSE',
  },
  {
    id: 'BF11BF96-445C-478B-8A66-ECBD839415EA',
    name: 'head-alert-outline',
    codepoint: 'F1339',
    deprecated: 'FALSE',
  },
  {
    id: 'E10C1F47-D7CB-458A-A374-467CF9462045',
    name: 'head-check',
    codepoint: 'F133A',
    deprecated: 'FALSE',
  },
  {
    id: '0A0ADE26-77AE-4BC3-8F9D-3D25669197FE',
    name: 'head-check-outline',
    codepoint: 'F133B',
    deprecated: 'FALSE',
  },
  {
    id: '58C540DF-30BA-449B-8426-61AD2C43959D',
    name: 'head-cog',
    codepoint: 'F133C',
    deprecated: 'FALSE',
  },
  {
    id: 'C4C50873-9844-42F6-A37B-16FE905873D9',
    name: 'head-cog-outline',
    codepoint: 'F133D',
    deprecated: 'FALSE',
  },
  {
    id: 'DE191219-96D0-4189-8BE1-8F24A5E3E492',
    name: 'head-dots-horizontal',
    codepoint: 'F133E',
    deprecated: 'FALSE',
  },
  {
    id: 'E07B33BA-5E14-45A2-AE38-EA80CE0ED909',
    name: 'head-dots-horizontal-outline',
    codepoint: 'F133F',
    deprecated: 'FALSE',
  },
  {
    id: '86AC6B0F-62ED-4691-88DB-A95DF86CBFF9',
    name: 'head-flash',
    codepoint: 'F1340',
    deprecated: 'FALSE',
  },
  {
    id: '28D19749-AD74-4017-B4B1-AFA65AA7B47F',
    name: 'head-flash-outline',
    codepoint: 'F1341',
    deprecated: 'FALSE',
  },
  {
    id: '619BBE2E-46B1-4FC8-91CF-13FE138FC601',
    name: 'head-heart',
    codepoint: 'F1342',
    deprecated: 'FALSE',
  },
  {
    id: '0DA85FAF-88F1-4DF1-8DA6-CB3CEFA2A505',
    name: 'head-heart-outline',
    codepoint: 'F1343',
    deprecated: 'FALSE',
  },
  {
    id: 'A94C16AE-DBA8-4DC4-B52E-589D1605C765',
    name: 'head-lightbulb',
    codepoint: 'F1344',
    deprecated: 'FALSE',
  },
  {
    id: 'A7FCF7AB-00BF-404B-8AF6-B384B034B6F7',
    name: 'head-lightbulb-outline',
    codepoint: 'F1345',
    deprecated: 'FALSE',
  },
  {
    id: 'ACE0E86B-603A-4B3A-9AF5-D6C2F3B4F121',
    name: 'head-minus',
    codepoint: 'F1346',
    deprecated: 'FALSE',
  },
  {
    id: '9550EB76-4099-4179-9310-339C2D7B0358',
    name: 'head-minus-outline',
    codepoint: 'F1347',
    deprecated: 'FALSE',
  },
  {
    id: 'FC339D79-C2E3-4F70-BDEE-E8DB99E06050',
    name: 'head-outline',
    codepoint: 'F135F',
    deprecated: 'FALSE',
  },
  {
    id: '84EDC185-89D4-4F6A-9C28-9911ADA41950',
    name: 'head-plus',
    codepoint: 'F1348',
    deprecated: 'FALSE',
  },
  {
    id: 'FDEA8867-C576-4065-87D9-945ADE51C543',
    name: 'head-plus-outline',
    codepoint: 'F1349',
    deprecated: 'FALSE',
  },
  {
    id: '1E4E72A9-FC83-4971-8B21-4FCACC4DD679',
    name: 'head-question',
    codepoint: 'F134A',
    deprecated: 'FALSE',
  },
  {
    id: 'B396941C-27C0-4386-96AF-D0AB15842EC2',
    name: 'head-question-outline',
    codepoint: 'F134B',
    deprecated: 'FALSE',
  },
  {
    id: '277090C1-C626-4DA8-B620-1A709668E5BA',
    name: 'head-remove',
    codepoint: 'F134C',
    deprecated: 'FALSE',
  },
  {
    id: '7BB28522-3861-439B-A804-D86C580A8FCB',
    name: 'head-remove-outline',
    codepoint: 'F134D',
    deprecated: 'FALSE',
  },
  {
    id: '9EB1D22F-C7FC-43FB-8689-40AB3FFB98E7',
    name: 'head-snowflake',
    codepoint: 'F134E',
    deprecated: 'FALSE',
  },
  {
    id: 'C7A97A26-B7C3-406A-9087-2463AC624926',
    name: 'head-snowflake-outline',
    codepoint: 'F134F',
    deprecated: 'FALSE',
  },
  {
    id: 'CB0FD2AA-6EA8-4324-8207-6C4FFCCB5143',
    name: 'head-sync',
    codepoint: 'F1350',
    deprecated: 'FALSE',
  },
  {
    id: '8EC8806B-0E06-4EA4-915E-C061D4930ECC',
    name: 'head-sync-outline',
    codepoint: 'F1351',
    deprecated: 'FALSE',
  },
  {
    id: '51F72F83-1559-4037-8BF9-2D8AE3BCB1A8',
    name: 'headphones',
    codepoint: 'F02CB',
    deprecated: 'FALSE',
  },
  {
    id: 'B14FD2A1-C292-49CC-B0AC-78E22C2BD611',
    name: 'headphones-bluetooth',
    codepoint: 'F0970',
    deprecated: 'FALSE',
  },
  {
    id: '5B608320-1BEB-499C-9CAB-0897D3A2D2B6',
    name: 'headphones-box',
    codepoint: 'F02CC',
    deprecated: 'FALSE',
  },
  {
    id: '678F779A-DE83-4DC0-A49D-657CBAF631F0',
    name: 'headphones-off',
    codepoint: 'F07CE',
    deprecated: 'FALSE',
  },
  {
    id: '54A22337-9D03-4F52-8D3E-B2A0A32147B7',
    name: 'headphones-settings',
    codepoint: 'F02CD',
    deprecated: 'FALSE',
  },
  {
    id: 'AFF98802-E02B-4EC8-8AFF-F0F5373D86C5',
    name: 'headset',
    codepoint: 'F02CE',
    deprecated: 'FALSE',
  },
  {
    id: '36F33522-9167-42DD-BA0A-8920F480F38C',
    name: 'headset-dock',
    codepoint: 'F02CF',
    deprecated: 'FALSE',
  },
  {
    id: 'E2A86F6D-6E4D-4C61-B54A-85BEDACA052F',
    name: 'headset-off',
    codepoint: 'F02D0',
    deprecated: 'FALSE',
  },
  {
    id: '32B56DB2-B6BF-4B54-AD0C-9444106B1C1D',
    name: 'heart',
    codepoint: 'F02D1',
    deprecated: 'FALSE',
  },
  {
    id: '558E28AE-47E7-4C95-9846-7653C79CBA33',
    name: 'heart-box',
    codepoint: 'F02D2',
    deprecated: 'FALSE',
  },
  {
    id: '24811FE6-6C95-4CAB-B708-71862BA40C05',
    name: 'heart-box-outline',
    codepoint: 'F02D3',
    deprecated: 'FALSE',
  },
  {
    id: '64D15AD4-07A4-4D43-8730-6C9860C89B44',
    name: 'heart-broken',
    codepoint: 'F02D4',
    deprecated: 'FALSE',
  },
  {
    id: '365602DD-C53F-42AC-AE3E-2058F47B5C53',
    name: 'heart-broken-outline',
    codepoint: 'F0D14',
    deprecated: 'FALSE',
  },
  {
    id: '2EFEF959-D5E7-4E69-863D-4E12C413A6D9',
    name: 'heart-circle',
    codepoint: 'F0971',
    deprecated: 'FALSE',
  },
  {
    id: 'DD5AC3DA-1CA0-4F53-9A50-C095417D7346',
    name: 'heart-circle-outline',
    codepoint: 'F0972',
    deprecated: 'FALSE',
  },
  {
    id: '759FA294-1DB6-4B54-BA13-AD852764E3C9',
    name: 'heart-cog',
    codepoint: 'F1663',
    deprecated: 'FALSE',
  },
  {
    id: 'A28DF1A9-5A1F-49AB-959D-249313639228',
    name: 'heart-cog-outline',
    codepoint: 'F1664',
    deprecated: 'FALSE',
  },
  {
    id: '9B033C3C-210D-4EFD-AD4E-FF9E36E44509',
    name: 'heart-flash',
    codepoint: 'F0EF9',
    deprecated: 'FALSE',
  },
  {
    id: '1B01BAE0-095E-424E-9552-6ECEF5E4FC62',
    name: 'heart-half',
    codepoint: 'F06DF',
    deprecated: 'FALSE',
  },
  {
    id: '0FC24699-3C95-4319-A60B-286B0D328D7A',
    name: 'heart-half-full',
    codepoint: 'F06DE',
    deprecated: 'FALSE',
  },
  {
    id: 'F4384972-741C-4BB6-91BF-9509E75C8AF8',
    name: 'heart-half-outline',
    codepoint: 'F06E0',
    deprecated: 'FALSE',
  },
  {
    id: '3FD1EF68-1D57-436E-9EF5-D6721DCCF5CF',
    name: 'heart-minus',
    codepoint: 'F142F',
    deprecated: 'FALSE',
  },
  {
    id: '12C099C0-0232-49B7-A909-F91594FD7FBA',
    name: 'heart-minus-outline',
    codepoint: 'F1432',
    deprecated: 'FALSE',
  },
  {
    id: 'BDAF5217-358E-4673-BF16-FFFBD09EAD8F',
    name: 'heart-multiple',
    codepoint: 'F0A56',
    deprecated: 'FALSE',
  },
  {
    id: 'A4672312-BF75-4CE0-88FF-C7D3BC724AA0',
    name: 'heart-multiple-outline',
    codepoint: 'F0A57',
    deprecated: 'FALSE',
  },
  {
    id: '012A92C0-E5DE-4FB4-9AB1-9BD5F53861E0',
    name: 'heart-off',
    codepoint: 'F0759',
    deprecated: 'FALSE',
  },
  {
    id: 'E84E6A73-A238-4F6D-9A63-658358BB4A06',
    name: 'heart-off-outline',
    codepoint: 'F1434',
    deprecated: 'FALSE',
  },
  {
    id: '9BCD22D2-7304-4BB3-9C41-3431ED13542A',
    name: 'heart-outline',
    codepoint: 'F02D5',
    deprecated: 'FALSE',
  },
  {
    id: '412C8571-A7A4-4029-B5D4-7C67D2B243CC',
    name: 'heart-plus',
    codepoint: 'F142E',
    deprecated: 'FALSE',
  },
  {
    id: '644CF687-E873-46D4-9595-FF3F1C455861',
    name: 'heart-plus-outline',
    codepoint: 'F1431',
    deprecated: 'FALSE',
  },
  {
    id: '389486F3-5A73-4C65-8EA6-43D432F6490E',
    name: 'heart-pulse',
    codepoint: 'F05F6',
    deprecated: 'FALSE',
  },
  {
    id: 'B01C8EB2-7765-4858-846F-078ADBFDD33B',
    name: 'heart-remove',
    codepoint: 'F1430',
    deprecated: 'FALSE',
  },
  {
    id: '3FB606F6-D5C4-4036-80D0-EFD3F8A0E06B',
    name: 'heart-remove-outline',
    codepoint: 'F1433',
    deprecated: 'FALSE',
  },
  {
    id: 'E0A4635A-2115-425E-8103-4C88CBDB10F3',
    name: 'heart-settings',
    codepoint: 'F1665',
    deprecated: 'FALSE',
  },
  {
    id: '9B32C2D5-B2BE-4646-9C41-53F10F7C1628',
    name: 'heart-settings-outline',
    codepoint: 'F1666',
    deprecated: 'FALSE',
  },
  {
    id: '12F2787E-C3CE-4321-98D5-3376DFD0606D',
    name: 'heat-pump',
    codepoint: 'F1A43',
    deprecated: 'FALSE',
  },
  {
    id: '926E83A9-0AF9-4933-B30E-E7D17680B611',
    name: 'heat-pump-outline',
    codepoint: 'F1A44',
    deprecated: 'FALSE',
  },
  {
    id: '8AD5E52C-320C-4071-B891-552B4D71A42C',
    name: 'heat-wave',
    codepoint: 'F1A45',
    deprecated: 'FALSE',
  },
  {
    id: 'FD4E8AC3-C868-429A-A7B9-5460AD347BC2',
    name: 'heating-coil',
    codepoint: 'F1AAF',
    deprecated: 'FALSE',
  },
  {
    id: '47397A0C-273D-4D13-A4FD-61572F3E4953',
    name: 'helicopter',
    codepoint: 'F0AC2',
    deprecated: 'FALSE',
  },
  {
    id: '62675A10-D453-40EB-8AED-A789A39EEF11',
    name: 'help',
    codepoint: 'F02D6',
    deprecated: 'FALSE',
  },
  {
    id: '6EFBBE96-37EA-4372-98CC-9B0D84B92C34',
    name: 'help-box',
    codepoint: 'F078B',
    deprecated: 'FALSE',
  },
  {
    id: '52053B96-D72E-4C97-8841-EC5F646F08BB',
    name: 'help-box-multiple',
    codepoint: 'F1C0A',
    deprecated: 'FALSE',
  },
  {
    id: 'F2D1319A-DF90-41CD-8791-B9B5685EFAC8',
    name: 'help-box-multiple-outline',
    codepoint: 'F1C0B',
    deprecated: 'FALSE',
  },
  {
    id: 'C7559D85-14BB-4C5C-B37C-D37E85FEE258',
    name: 'help-box-outline',
    codepoint: 'F1C0C',
    deprecated: 'FALSE',
  },
  {
    id: '49B41103-DD15-4FF4-A18A-44D8586704B6',
    name: 'help-circle',
    codepoint: 'F02D7',
    deprecated: 'FALSE',
  },
  {
    id: '60E15D8D-3956-4461-91D8-E8059D02ECE6',
    name: 'help-circle-outline',
    codepoint: 'F0625',
    deprecated: 'FALSE',
  },
  {
    id: 'FE0A3DAC-0D3D-4D49-A869-F78B44B26E35',
    name: 'help-network',
    codepoint: 'F06F5',
    deprecated: 'FALSE',
  },
  {
    id: '918BA71B-ED4F-4259-9089-0CF2A1EDCFD1',
    name: 'help-network-outline',
    codepoint: 'F0C8A',
    deprecated: 'FALSE',
  },
  {
    id: 'FC7F2A59-0296-45CC-966D-EF69FB042DD9',
    name: 'help-rhombus',
    codepoint: 'F0BA5',
    deprecated: 'FALSE',
  },
  {
    id: 'B08F035A-9304-4D3B-AADD-395971E74294',
    name: 'help-rhombus-outline',
    codepoint: 'F0BA6',
    deprecated: 'FALSE',
  },
  {
    id: '4FB1FDA2-96F8-4983-83B9-470C1526042F',
    name: 'hexadecimal',
    codepoint: 'F12A7',
    deprecated: 'FALSE',
  },
  {
    id: '229D267D-F77F-4F33-ADD4-325647EC2662',
    name: 'hexagon',
    codepoint: 'F02D8',
    deprecated: 'FALSE',
  },
  {
    id: '60636193-AC27-470B-BCA5-94CEA29719A8',
    name: 'hexagon-multiple',
    codepoint: 'F06E1',
    deprecated: 'FALSE',
  },
  {
    id: 'D0D73659-5BB0-4109-A7E1-E4B09D31B940',
    name: 'hexagon-multiple-outline',
    codepoint: 'F10F2',
    deprecated: 'FALSE',
  },
  {
    id: '0A85BE3F-ACF1-4D93-A66B-34C7F29D10A3',
    name: 'hexagon-outline',
    codepoint: 'F02D9',
    deprecated: 'FALSE',
  },
  {
    id: '6FD9B907-A06F-4923-9ABB-C977E8F14D0E',
    name: 'hexagon-slice-1',
    codepoint: 'F0AC3',
    deprecated: 'FALSE',
  },
  {
    id: 'B711E5EB-95CC-4835-A18A-066E1A242DB5',
    name: 'hexagon-slice-2',
    codepoint: 'F0AC4',
    deprecated: 'FALSE',
  },
  {
    id: '0F1666BA-D919-4230-96C9-6DD64A82EB3D',
    name: 'hexagon-slice-3',
    codepoint: 'F0AC5',
    deprecated: 'FALSE',
  },
  {
    id: '4AD333BE-32D1-4EA2-BA76-05896A9D2698',
    name: 'hexagon-slice-4',
    codepoint: 'F0AC6',
    deprecated: 'FALSE',
  },
  {
    id: '4DE91E6B-4F94-40E8-BB70-2B54105C67D0',
    name: 'hexagon-slice-5',
    codepoint: 'F0AC7',
    deprecated: 'FALSE',
  },
  {
    id: '0EE5E66B-A73E-455F-BF0B-5FEBC93A58E9',
    name: 'hexagon-slice-6',
    codepoint: 'F0AC8',
    deprecated: 'FALSE',
  },
  {
    id: 'A965D069-BF40-4F96-B64D-6C1C93F0E2E3',
    name: 'hexagram',
    codepoint: 'F0AC9',
    deprecated: 'FALSE',
  },
  {
    id: 'C1F53791-B46C-4327-B355-AF3175766CB8',
    name: 'hexagram-outline',
    codepoint: 'F0ACA',
    deprecated: 'FALSE',
  },
  {
    id: '8FC812E7-2B7B-412B-9114-1899203DECC4',
    name: 'high-definition',
    codepoint: 'F07CF',
    deprecated: 'FALSE',
  },
  {
    id: 'BD1F0404-AA98-4FCF-8947-D0589C91A610',
    name: 'high-definition-box',
    codepoint: 'F0878',
    deprecated: 'FALSE',
  },
  {
    id: '81E5D79D-2ECD-4040-9B07-3A0C19792348',
    name: 'highway',
    codepoint: 'F05F7',
    deprecated: 'FALSE',
  },
  {
    id: 'DA3D64F2-C874-420A-917A-0F66C9854D84',
    name: 'hiking',
    codepoint: 'F0D7F',
    deprecated: 'FALSE',
  },
  {
    id: '55AF2D53-7FAD-4A1D-8440-10301C95D3B8',
    name: 'history',
    codepoint: 'F02DA',
    deprecated: 'FALSE',
  },
  {
    id: '7A41C6CC-578B-4125-9647-548A298D5B3C',
    name: 'hockey-puck',
    codepoint: 'F0879',
    deprecated: 'FALSE',
  },
  {
    id: '1D4E526E-EA07-4493-AF7D-C9B62B4CBC77',
    name: 'hockey-sticks',
    codepoint: 'F087A',
    deprecated: 'FALSE',
  },
  {
    id: '826E9B16-2A0D-4E8C-9C3A-BBBE3319AE4D',
    name: 'hololens',
    codepoint: 'F02DB',
    deprecated: 'FALSE',
  },
  {
    id: '5D085274-15B9-42EC-8CCE-2F94C5EC039C',
    name: 'home',
    codepoint: 'F02DC',
    deprecated: 'FALSE',
  },
  {
    id: '9A7BCD6C-B4FB-4AE5-B22F-C5B8E2FB3B66',
    name: 'home-account',
    codepoint: 'F0826',
    deprecated: 'FALSE',
  },
  {
    id: 'A13368F4-66F6-4014-968E-7A7B2D99F038',
    name: 'home-alert',
    codepoint: 'F087B',
    deprecated: 'FALSE',
  },
  {
    id: '0A46973B-0029-4BCF-9728-F3E13DC9FE91',
    name: 'home-alert-outline',
    codepoint: 'F15D0',
    deprecated: 'FALSE',
  },
  {
    id: '381A61B9-0D73-4CB8-B525-C67697443F43',
    name: 'home-analytics',
    codepoint: 'F0EBA',
    deprecated: 'FALSE',
  },
  {
    id: '80923357-29F3-429E-A186-F3561451398C',
    name: 'home-assistant',
    codepoint: 'F07D0',
    deprecated: 'TRUE',
  },
  {
    id: 'FD2567BF-09EC-4871-B3E8-DAE9CDCF3A38',
    name: 'home-automation',
    codepoint: 'F07D1',
    deprecated: 'FALSE',
  },
  {
    id: '3BCACB97-3EEE-453B-81A7-1136F167B23D',
    name: 'home-battery',
    codepoint: 'F1901',
    deprecated: 'FALSE',
  },
  {
    id: 'EFDF1C9C-3566-4312-ACBC-AC5B10EA28DE',
    name: 'home-battery-outline',
    codepoint: 'F1902',
    deprecated: 'FALSE',
  },
  {
    id: '59CB69E0-6F23-4572-90C7-9B360121C266',
    name: 'home-circle',
    codepoint: 'F07D2',
    deprecated: 'FALSE',
  },
  {
    id: '2AD26260-A0C1-42BE-99A2-889F460EF25D',
    name: 'home-circle-outline',
    codepoint: 'F104D',
    deprecated: 'FALSE',
  },
  {
    id: '4D49A420-B80E-48FA-A331-A556002EA46F',
    name: 'home-city',
    codepoint: 'F0D15',
    deprecated: 'FALSE',
  },
  {
    id: '69A854BB-0CC9-4E1B-855E-FD26E9E6D119',
    name: 'home-city-outline',
    codepoint: 'F0D16',
    deprecated: 'FALSE',
  },
  {
    id: 'F3391DCE-6EC9-4C63-869D-36ED374054FC',
    name: 'home-clock',
    codepoint: 'F1A12',
    deprecated: 'FALSE',
  },
  {
    id: '9ADA7472-66EE-4F9A-8494-C4292C29207C',
    name: 'home-clock-outline',
    codepoint: 'F1A13',
    deprecated: 'FALSE',
  },
  {
    id: '6D381D99-24F0-46B5-AD04-F527B97830AE',
    name: 'home-edit',
    codepoint: 'F1159',
    deprecated: 'FALSE',
  },
  {
    id: 'B56AB73F-0A3C-4562-88DF-ED70B51877DE',
    name: 'home-edit-outline',
    codepoint: 'F115A',
    deprecated: 'FALSE',
  },
  {
    id: '6D0FFE6B-65D9-4D72-9743-C2537F3E6307',
    name: 'home-export-outline',
    codepoint: 'F0F9B',
    deprecated: 'FALSE',
  },
  {
    id: 'AC4506FC-3368-416C-8E9A-BADD6F66E7D5',
    name: 'home-flood',
    codepoint: 'F0EFA',
    deprecated: 'FALSE',
  },
  {
    id: '6732D314-97CA-4433-9CB5-260359AC131F',
    name: 'home-floor-0',
    codepoint: 'F0DD2',
    deprecated: 'FALSE',
  },
  {
    id: 'B83F1FBD-0D66-4CEE-91FC-640B78AABF4E',
    name: 'home-floor-1',
    codepoint: 'F0D80',
    deprecated: 'FALSE',
  },
  {
    id: 'FB09500A-A871-409C-B7D4-B18BDF0D8511',
    name: 'home-floor-2',
    codepoint: 'F0D81',
    deprecated: 'FALSE',
  },
  {
    id: 'E7714A6B-EE3B-4819-81B8-1E9FF342598F',
    name: 'home-floor-3',
    codepoint: 'F0D82',
    deprecated: 'FALSE',
  },
  {
    id: '5A227D09-2828-4606-81B8-7622D7C8724C',
    name: 'home-floor-a',
    codepoint: 'F0D83',
    deprecated: 'FALSE',
  },
  {
    id: '004E747A-FD00-4B6E-8AEF-2031D217D035',
    name: 'home-floor-b',
    codepoint: 'F0D84',
    deprecated: 'FALSE',
  },
  {
    id: 'B2D19D07-ACC6-462B-BD11-50CC805E104B',
    name: 'home-floor-g',
    codepoint: 'F0D85',
    deprecated: 'FALSE',
  },
  {
    id: 'D6B418D4-1B34-4E5C-B406-0E27B72DC0AC',
    name: 'home-floor-l',
    codepoint: 'F0D86',
    deprecated: 'FALSE',
  },
  {
    id: '8809DA4E-CB12-48C9-AA91-8E377701862F',
    name: 'home-floor-negative-1',
    codepoint: 'F0DD3',
    deprecated: 'FALSE',
  },
  {
    id: 'B4E54DBF-164E-41D3-8C9F-D7E67C9F9B10',
    name: 'home-group',
    codepoint: 'F0DD4',
    deprecated: 'FALSE',
  },
  {
    id: '786A8373-A89F-4905-9698-B8AD3CF4D230',
    name: 'home-group-minus',
    codepoint: 'F19C1',
    deprecated: 'FALSE',
  },
  {
    id: '6A0C18F6-510F-4AC3-88FE-6B24C7669BAD',
    name: 'home-group-plus',
    codepoint: 'F19C0',
    deprecated: 'FALSE',
  },
  {
    id: 'A4438B5F-FA4C-4C73-8514-3D0364BBB7A5',
    name: 'home-group-remove',
    codepoint: 'F19C2',
    deprecated: 'FALSE',
  },
  {
    id: '513D2CF1-31F5-4F3B-8543-2499ABB20924',
    name: 'home-heart',
    codepoint: 'F0827',
    deprecated: 'FALSE',
  },
  {
    id: '041C4C4F-6D85-4F8B-90CC-FB17E9202862',
    name: 'home-import-outline',
    codepoint: 'F0F9C',
    deprecated: 'FALSE',
  },
  {
    id: '06C5C86B-2918-4501-B247-890B18874E8E',
    name: 'home-lightbulb',
    codepoint: 'F1251',
    deprecated: 'FALSE',
  },
  {
    id: '5F0E0727-76E9-4793-9406-FD513FF7CB58',
    name: 'home-lightbulb-outline',
    codepoint: 'F1252',
    deprecated: 'FALSE',
  },
  {
    id: '077E7728-7FDE-4846-9F55-9818EB397D58',
    name: 'home-lightning-bolt',
    codepoint: 'F1903',
    deprecated: 'FALSE',
  },
  {
    id: '12538408-E644-4222-84BF-9A64670E9187',
    name: 'home-lightning-bolt-outline',
    codepoint: 'F1904',
    deprecated: 'FALSE',
  },
  {
    id: 'ECB8FF23-47EC-4038-9673-1A3A1619C071',
    name: 'home-lock',
    codepoint: 'F08EB',
    deprecated: 'FALSE',
  },
  {
    id: '0E63785D-BB37-4A60-B09D-F985DC4FAD5F',
    name: 'home-lock-open',
    codepoint: 'F08EC',
    deprecated: 'FALSE',
  },
  {
    id: 'D543BDC6-BC40-4A36-B2F0-60313D8F4EEB',
    name: 'home-map-marker',
    codepoint: 'F05F8',
    deprecated: 'FALSE',
  },
  {
    id: '825AF439-6839-48DA-A6D4-B62BB2710103',
    name: 'home-minus',
    codepoint: 'F0974',
    deprecated: 'FALSE',
  },
  {
    id: '8DB9C230-99DF-4137-9A32-2D1058D824EA',
    name: 'home-minus-outline',
    codepoint: 'F13D5',
    deprecated: 'FALSE',
  },
  {
    id: '6949FE9A-5B8C-4EDF-B74E-E0268A4F69F1',
    name: 'home-modern',
    codepoint: 'F02DD',
    deprecated: 'FALSE',
  },
  {
    id: 'D9B08A72-EDEB-459D-89C9-C12DFB088E98',
    name: 'home-off',
    codepoint: 'F1A46',
    deprecated: 'FALSE',
  },
  {
    id: '8AFA6E09-694A-4C0B-9F34-6567B3CA1690',
    name: 'home-off-outline',
    codepoint: 'F1A47',
    deprecated: 'FALSE',
  },
  {
    id: 'B4E2287E-8872-48AD-B765-83D82AF66CA3',
    name: 'home-outline',
    codepoint: 'F06A1',
    deprecated: 'FALSE',
  },
  {
    id: 'E844FA32-E45E-4D71-B74C-34DFAD7F6830',
    name: 'home-plus',
    codepoint: 'F0975',
    deprecated: 'FALSE',
  },
  {
    id: 'CDAF170C-BB2C-4217-9B96-D99EE9B725F9',
    name: 'home-plus-outline',
    codepoint: 'F13D6',
    deprecated: 'FALSE',
  },
  {
    id: 'FEF8AC85-F9E6-4C9B-B0C2-A5158AAD8A8B',
    name: 'home-remove',
    codepoint: 'F1247',
    deprecated: 'FALSE',
  },
  {
    id: 'CD3129FD-EDCB-4B65-85F8-900E59E308B7',
    name: 'home-remove-outline',
    codepoint: 'F13D7',
    deprecated: 'FALSE',
  },
  {
    id: '32EF139A-14EB-4767-ADDD-08120FB530A0',
    name: 'home-roof',
    codepoint: 'F112B',
    deprecated: 'FALSE',
  },
  {
    id: '156F3CF1-D7CD-487B-AF83-4A7270413A62',
    name: 'home-search',
    codepoint: 'F13B0',
    deprecated: 'FALSE',
  },
  {
    id: 'D261AFB1-2C65-41D6-AE5A-E25D2FC120ED',
    name: 'home-search-outline',
    codepoint: 'F13B1',
    deprecated: 'FALSE',
  },
  {
    id: '1F67690D-B143-4CF0-9888-54F647E62F33',
    name: 'home-silo',
    codepoint: 'F1BA0',
    deprecated: 'FALSE',
  },
  {
    id: '8943314B-A992-48BD-B030-700F2190B781',
    name: 'home-silo-outline',
    codepoint: 'F1BA1',
    deprecated: 'FALSE',
  },
  {
    id: '1DBBF908-7F93-4FAE-8A2A-555851E7C3BE',
    name: 'home-switch',
    codepoint: 'F1794',
    deprecated: 'FALSE',
  },
  {
    id: '4C8FD8F1-0690-4B2D-A614-A03F58D48370',
    name: 'home-switch-outline',
    codepoint: 'F1795',
    deprecated: 'FALSE',
  },
  {
    id: '833E3484-3C40-4F98-96CB-537FB6225711',
    name: 'home-thermometer',
    codepoint: 'F0F54',
    deprecated: 'FALSE',
  },
  {
    id: '2782AA6E-C46A-4CEC-B753-35768CA30163',
    name: 'home-thermometer-outline',
    codepoint: 'F0F55',
    deprecated: 'FALSE',
  },
  {
    id: '7AFA8EEF-6A41-4A7D-97D2-F9F03DB24DB0',
    name: 'home-variant',
    codepoint: 'F02DE',
    deprecated: 'FALSE',
  },
  {
    id: '5DF1214D-79EC-4EA0-B435-CB73B877DCA9',
    name: 'home-variant-outline',
    codepoint: 'F0BA7',
    deprecated: 'FALSE',
  },
  {
    id: '8A2A8364-B33C-4931-BF78-EB171C569D5F',
    name: 'hook',
    codepoint: 'F06E2',
    deprecated: 'FALSE',
  },
  {
    id: '984487C2-EBB2-474C-8914-046E6BDC6EC6',
    name: 'hook-off',
    codepoint: 'F06E3',
    deprecated: 'FALSE',
  },
  {
    id: '088F07BD-BEA9-46D5-A2DF-0B9FA37BBF99',
    name: 'hoop-house',
    codepoint: 'F0E56',
    deprecated: 'FALSE',
  },
  {
    id: 'E82A026A-DD54-4C5F-8961-A7C97754CF04',
    name: 'hops',
    codepoint: 'F02DF',
    deprecated: 'FALSE',
  },
  {
    id: '7B242778-9748-4E06-8738-E3325EE88F08',
    name: 'horizontal-rotate-clockwise',
    codepoint: 'F10F3',
    deprecated: 'FALSE',
  },
  {
    id: 'C6DC8E07-B9BA-4F4E-B415-40AAD8AE17A4',
    name: 'horizontal-rotate-counterclockwise',
    codepoint: 'F10F4',
    deprecated: 'FALSE',
  },
  {
    id: 'EA8A87CE-C2FE-4C05-8CD0-32C9716737D4',
    name: 'horse',
    codepoint: 'F15BF',
    deprecated: 'FALSE',
  },
  {
    id: '784CE1E7-3523-4E04-910C-3E969FEA4E55',
    name: 'horse-human',
    codepoint: 'F15C0',
    deprecated: 'FALSE',
  },
  {
    id: '359CB8BA-79AF-43F7-AE85-562432388C2E',
    name: 'horse-variant',
    codepoint: 'F15C1',
    deprecated: 'FALSE',
  },
  {
    id: 'B78A53B2-16C0-40FF-8923-9EE03B7D6059',
    name: 'horse-variant-fast',
    codepoint: 'F186E',
    deprecated: 'FALSE',
  },
  {
    id: '1955609E-8F53-41B3-9C71-0A71E4FBEED3',
    name: 'horseshoe',
    codepoint: 'F0A58',
    deprecated: 'FALSE',
  },
  {
    id: 'D7402851-FBF5-4861-8B6E-D141EBBDBB70',
    name: 'hospital',
    codepoint: 'F0FF6',
    deprecated: 'FALSE',
  },
  {
    id: '83D0E9E7-B196-4515-A9C8-6E6FEB03ADD1',
    name: 'hospital-box',
    codepoint: 'F02E0',
    deprecated: 'FALSE',
  },
  {
    id: '2D50B6E0-C01B-4EDE-BA85-A14757A27D54',
    name: 'hospital-box-outline',
    codepoint: 'F0FF7',
    deprecated: 'FALSE',
  },
  {
    id: 'D4FAA416-4429-451F-B4EE-647CCABDF3EB',
    name: 'hospital-building',
    codepoint: 'F02E1',
    deprecated: 'FALSE',
  },
  {
    id: 'FA30BC13-BE77-44F6-BEB1-942749E019A7',
    name: 'hospital-marker',
    codepoint: 'F02E2',
    deprecated: 'FALSE',
  },
  {
    id: '832FBA55-F65E-4706-93B1-9018D651CAA3',
    name: 'hot-tub',
    codepoint: 'F0828',
    deprecated: 'FALSE',
  },
  {
    id: '0AAC6125-3343-4D4A-AF22-69BC930FA96F',
    name: 'hours-24',
    codepoint: 'F1478',
    deprecated: 'FALSE',
  },
  {
    id: '04125F27-F2CB-4304-8C9D-81E4D72A0B6E',
    name: 'hubspot',
    codepoint: 'F0D17',
    deprecated: 'FALSE',
  },
  {
    id: '788D7C34-3E50-468B-A205-D953D844F1B7',
    name: 'hulu',
    codepoint: 'F0829',
    deprecated: 'TRUE',
  },
  {
    id: 'B05E458D-886A-491D-9969-156562C52105',
    name: 'human',
    codepoint: 'F02E6',
    deprecated: 'FALSE',
  },
  {
    id: 'CF40C1CD-0F3A-4BC7-A1D7-7C33C8EFD307',
    name: 'human-baby-changing-table',
    codepoint: 'F138B',
    deprecated: 'FALSE',
  },
  {
    id: '40DD914F-1089-4A7B-AA3D-630C643E8FFF',
    name: 'human-cane',
    codepoint: 'F1581',
    deprecated: 'FALSE',
  },
  {
    id: '98227CA7-A56F-4C3F-85FC-0FAD616B9762',
    name: 'human-capacity-decrease',
    codepoint: 'F159B',
    deprecated: 'FALSE',
  },
  {
    id: 'AFFFC442-E5B7-4D2C-9B87-81D2AD52997A',
    name: 'human-capacity-increase',
    codepoint: 'F159C',
    deprecated: 'FALSE',
  },
  {
    id: 'ABBC3761-B437-4800-9C57-BCBE5AC8FA31',
    name: 'human-child',
    codepoint: 'F02E7',
    deprecated: 'FALSE',
  },
  {
    id: '24AA9CD4-6B25-4B7C-A3A2-0C1A29DC5E1F',
    name: 'human-dolly',
    codepoint: 'F1980',
    deprecated: 'FALSE',
  },
  {
    id: '1B83707C-D3DF-4181-93D5-5B513B649E1C',
    name: 'human-edit',
    codepoint: 'F14E8',
    deprecated: 'FALSE',
  },
  {
    id: '059421A9-4F27-493A-8835-4B49B5286366',
    name: 'human-female',
    codepoint: 'F0649',
    deprecated: 'FALSE',
  },
  {
    id: '6FAD3199-1AC7-4265-84B0-E72818AD2447',
    name: 'human-female-boy',
    codepoint: 'F0A59',
    deprecated: 'FALSE',
  },
  {
    id: 'DCBC03CA-8A3A-41FD-9C96-BA738EDA9F05',
    name: 'human-female-dance',
    codepoint: 'F15C9',
    deprecated: 'FALSE',
  },
  {
    id: '63BC56A3-B593-4789-BEAE-2696E6DC2CB6',
    name: 'human-female-female',
    codepoint: 'F0A5A',
    deprecated: 'FALSE',
  },
  {
    id: '0F41B01E-9981-4BF8-8CC9-A44593ECB3BA',
    name: 'human-female-girl',
    codepoint: 'F0A5B',
    deprecated: 'FALSE',
  },
  {
    id: '59728070-C172-44A6-9076-5D5C29D08880',
    name: 'human-greeting',
    codepoint: 'F17C4',
    deprecated: 'FALSE',
  },
  {
    id: '200F7489-F20F-4506-A27A-081A0CA8A312',
    name: 'human-greeting-proximity',
    codepoint: 'F159D',
    deprecated: 'FALSE',
  },
  {
    id: '1C9856E1-6945-464E-85E7-A46C4A974C9D',
    name: 'human-greeting-variant',
    codepoint: 'F064A',
    deprecated: 'FALSE',
  },
  {
    id: '10E7CAB4-7B15-4F73-82B1-E42562B9103C',
    name: 'human-handsdown',
    codepoint: 'F064B',
    deprecated: 'FALSE',
  },
  {
    id: 'FA427178-750A-425C-81F2-B46DDC061771',
    name: 'human-handsup',
    codepoint: 'F064C',
    deprecated: 'FALSE',
  },
  {
    id: '1369FF9C-0E57-4644-BE89-7B5EED0131B0',
    name: 'human-male',
    codepoint: 'F064D',
    deprecated: 'FALSE',
  },
  {
    id: 'A989F68B-23B9-4B50-BE4D-E3DF6E9420C9',
    name: 'human-male-board',
    codepoint: 'F0890',
    deprecated: 'FALSE',
  },
  {
    id: '7016027D-C32A-4462-A9E5-98132FA518B4',
    name: 'human-male-board-poll',
    codepoint: 'F0846',
    deprecated: 'FALSE',
  },
  {
    id: '07AB2970-AE77-417B-BEB3-8B6FDEF43EDE',
    name: 'human-male-boy',
    codepoint: 'F0A5C',
    deprecated: 'FALSE',
  },
  {
    id: '4FDB5D5D-02F8-4B99-AA54-C9344F1DB02D',
    name: 'human-male-child',
    codepoint: 'F138C',
    deprecated: 'FALSE',
  },
  {
    id: '0FC16450-7A7E-43C5-AED4-E27C4A6BFF99',
    name: 'human-male-female',
    codepoint: 'F02E8',
    deprecated: 'FALSE',
  },
  {
    id: '26B43897-F5DF-4CBC-A89F-514E200B5937',
    name: 'human-male-female-child',
    codepoint: 'F1823',
    deprecated: 'FALSE',
  },
  {
    id: '764B2DA8-53A8-4D7D-A156-D73CDA4F0C63',
    name: 'human-male-girl',
    codepoint: 'F0A5D',
    deprecated: 'FALSE',
  },
  {
    id: '38807077-1E35-4599-83CA-110DA473794A',
    name: 'human-male-height',
    codepoint: 'F0EFB',
    deprecated: 'FALSE',
  },
  {
    id: '51DB457C-C26A-4579-8C34-9ED95D270EA4',
    name: 'human-male-height-variant',
    codepoint: 'F0EFC',
    deprecated: 'FALSE',
  },
  {
    id: 'AB5B56A8-CB56-4502-B388-BA02ECEB2D1A',
    name: 'human-male-male',
    codepoint: 'F0A5E',
    deprecated: 'FALSE',
  },
  {
    id: '0993FCF5-5C89-4F54-ABFC-310CDF2AED15',
    name: 'human-non-binary',
    codepoint: 'F1848',
    deprecated: 'FALSE',
  },
  {
    id: '05DB88BC-FA28-4DF1-8308-541E338E0234',
    name: 'human-pregnant',
    codepoint: 'F05CF',
    deprecated: 'FALSE',
  },
  {
    id: 'FAD84EF2-E1C0-4834-8FFD-677E424F2693',
    name: 'human-queue',
    codepoint: 'F1571',
    deprecated: 'FALSE',
  },
  {
    id: '08B3209B-7D10-4C1C-9962-E4DAC71DD06F',
    name: 'human-scooter',
    codepoint: 'F11E9',
    deprecated: 'FALSE',
  },
  {
    id: '6248F0D3-5347-4AEF-A1FE-903BDD78D4AA',
    name: 'human-walker',
    codepoint: 'F1B71',
    deprecated: 'FALSE',
  },
  {
    id: 'B0529853-AE3D-475C-94EB-0C775E934806',
    name: 'human-wheelchair',
    codepoint: 'F138D',
    deprecated: 'FALSE',
  },
  {
    id: 'D85C481D-1B39-47A3-92D6-975E862AF70A',
    name: 'human-white-cane',
    codepoint: 'F1981',
    deprecated: 'FALSE',
  },
  {
    id: '9A2A1178-7E30-49E7-A639-2D16464EA7EA',
    name: 'humble-bundle',
    codepoint: 'F0744',
    deprecated: 'TRUE',
  },
  {
    id: 'EE07113F-BD81-424C-B228-7EC802F7CFB2',
    name: 'hvac',
    codepoint: 'F1352',
    deprecated: 'FALSE',
  },
  {
    id: '30019019-F955-40EE-B811-C634718E6FAD',
    name: 'hvac-off',
    codepoint: 'F159E',
    deprecated: 'FALSE',
  },
  {
    id: 'FBFEC6FC-C311-4B53-8445-18B604AA7C12',
    name: 'hydraulic-oil-level',
    codepoint: 'F1324',
    deprecated: 'FALSE',
  },
  {
    id: '54AC2207-9F8D-4A1D-B8B4-ED626D0C8C38',
    name: 'hydraulic-oil-temperature',
    codepoint: 'F1325',
    deprecated: 'FALSE',
  },
  {
    id: '97D5D644-DB37-4DA8-8B37-444B242A88EF',
    name: 'hydro-power',
    codepoint: 'F12E5',
    deprecated: 'FALSE',
  },
  {
    id: 'B1407DA8-3E25-4878-A31F-34112A2D586B',
    name: 'hydrogen-station',
    codepoint: 'F1894',
    deprecated: 'FALSE',
  },
  {
    id: 'C8D5186F-BF39-40DA-9F7A-747DB166EABD',
    name: 'ice-cream',
    codepoint: 'F082A',
    deprecated: 'FALSE',
  },
  {
    id: '9620A768-2570-423E-8D3C-D65C9CACA7EC',
    name: 'ice-cream-off',
    codepoint: 'F0E52',
    deprecated: 'FALSE',
  },
  {
    id: '58AA127F-3523-4B91-AFED-018FA36D0876',
    name: 'ice-pop',
    codepoint: 'F0EFD',
    deprecated: 'FALSE',
  },
  {
    id: '44423F53-F28B-49F5-86F1-D71FAC4B4C07',
    name: 'id-card',
    codepoint: 'F0FC0',
    deprecated: 'FALSE',
  },
  {
    id: '58048473-346E-4473-A18D-36C29B789322',
    name: 'identifier',
    codepoint: 'F0EFE',
    deprecated: 'FALSE',
  },
  {
    id: '86B5194A-AB94-4C20-8110-FA463B705B39',
    name: 'ideogram-cjk',
    codepoint: 'F1331',
    deprecated: 'FALSE',
  },
  {
    id: '1D315F53-EBD0-42C7-89D6-5E682B756B22',
    name: 'ideogram-cjk-variant',
    codepoint: 'F1332',
    deprecated: 'FALSE',
  },
  {
    id: '3238BA72-FEE8-4C90-A440-AD701359A7BC',
    name: 'image',
    codepoint: 'F02E9',
    deprecated: 'FALSE',
  },
  {
    id: 'DD2262AA-A145-4788-899D-46D807B4FC0D',
    name: 'image-album',
    codepoint: 'F02EA',
    deprecated: 'FALSE',
  },
  {
    id: 'D23B9039-C6D4-45F8-B625-EEDE883FF5BD',
    name: 'image-area',
    codepoint: 'F02EB',
    deprecated: 'FALSE',
  },
  {
    id: '3BE3AA75-C604-45F3-9DD3-5E8731201F08',
    name: 'image-area-close',
    codepoint: 'F02EC',
    deprecated: 'FALSE',
  },
  {
    id: 'F528B8DF-1FE3-47C0-B406-70FAC5718CE1',
    name: 'image-auto-adjust',
    codepoint: 'F0FC1',
    deprecated: 'FALSE',
  },
  {
    id: '40DCC37F-8A3F-4C15-97E8-2AB88B4DC756',
    name: 'image-broken',
    codepoint: 'F02ED',
    deprecated: 'FALSE',
  },
  {
    id: 'D243B11E-D824-4185-B14F-B1C53DB11C24',
    name: 'image-broken-variant',
    codepoint: 'F02EE',
    deprecated: 'FALSE',
  },
  {
    id: '92E042AA-1660-4069-AA56-F2D749C778AF',
    name: 'image-check',
    codepoint: 'F1B25',
    deprecated: 'FALSE',
  },
  {
    id: '32FD6900-16FE-45E0-932C-EBCF6834ACA6',
    name: 'image-check-outline',
    codepoint: 'F1B26',
    deprecated: 'FALSE',
  },
  {
    id: '3E168631-4E4A-47D7-839A-CEB6B4640510',
    name: 'image-edit',
    codepoint: 'F11E3',
    deprecated: 'FALSE',
  },
  {
    id: 'DD3FFC3D-A4AF-4F1D-8239-8F5195BC4C29',
    name: 'image-edit-outline',
    codepoint: 'F11E4',
    deprecated: 'FALSE',
  },
  {
    id: '7D3932BA-9061-40D7-B624-044EE7A7B445',
    name: 'image-filter-black-white',
    codepoint: 'F02F0',
    deprecated: 'FALSE',
  },
  {
    id: 'E3745997-1AD2-4D79-83FE-72ADE96E3C69',
    name: 'image-filter-center-focus',
    codepoint: 'F02F1',
    deprecated: 'FALSE',
  },
  {
    id: '3A8D45DA-5897-4B18-BDA5-951967BD4CF8',
    name: 'image-filter-center-focus-strong',
    codepoint: 'F0EFF',
    deprecated: 'FALSE',
  },
  {
    id: '0012643E-5753-42C0-A1CF-E4FBB5D5E311',
    name: 'image-filter-center-focus-strong-outline',
    codepoint: 'F0F00',
    deprecated: 'FALSE',
  },
  {
    id: '50F3F396-4286-4D09-B726-4F626649BC04',
    name: 'image-filter-center-focus-weak',
    codepoint: 'F02F2',
    deprecated: 'FALSE',
  },
  {
    id: '882F82B8-EDF8-4F1A-A6BA-9C6DAEDBB259',
    name: 'image-filter-drama',
    codepoint: 'F02F3',
    deprecated: 'FALSE',
  },
  {
    id: 'D4F6DC2A-643B-4690-8E0A-BF56C4585BC4',
    name: 'image-filter-drama-outline',
    codepoint: 'F1BFF',
    deprecated: 'FALSE',
  },
  {
    id: '8EC8847F-E96F-4D14-BF88-F4BE47856865',
    name: 'image-filter-frames',
    codepoint: 'F02F4',
    deprecated: 'FALSE',
  },
  {
    id: '999CF81E-71B7-48E1-8B8F-70FDE391F647',
    name: 'image-filter-hdr',
    codepoint: 'F02F5',
    deprecated: 'FALSE',
  },
  {
    id: '56164312-7555-4017-8A1A-48954BE7F7C5',
    name: 'image-filter-none',
    codepoint: 'F02F6',
    deprecated: 'FALSE',
  },
  {
    id: '368537EA-9261-4097-A1FA-D33EE48A7D59',
    name: 'image-filter-tilt-shift',
    codepoint: 'F02F7',
    deprecated: 'FALSE',
  },
  {
    id: '0E6F8B1C-14D2-4A98-9414-FFC4B2146748',
    name: 'image-filter-vintage',
    codepoint: 'F02F8',
    deprecated: 'FALSE',
  },
  {
    id: '27E31ADA-EF15-4A4E-A17A-45F4C032256F',
    name: 'image-frame',
    codepoint: 'F0E49',
    deprecated: 'FALSE',
  },
  {
    id: '77796DB4-93FF-45E6-AC26-AE74713E6758',
    name: 'image-lock',
    codepoint: 'F1AB0',
    deprecated: 'FALSE',
  },
  {
    id: '1F5591F3-542A-4369-9F1B-721E54C513B2',
    name: 'image-lock-outline',
    codepoint: 'F1AB1',
    deprecated: 'FALSE',
  },
  {
    id: '39EFA2D6-FC32-491C-8ABE-384AA47B94D3',
    name: 'image-marker',
    codepoint: 'F177B',
    deprecated: 'FALSE',
  },
  {
    id: '754F7051-3979-499F-B7E6-9513619D7609',
    name: 'image-marker-outline',
    codepoint: 'F177C',
    deprecated: 'FALSE',
  },
  {
    id: 'ED40AC55-00BA-4086-8906-1FA1EDA65839',
    name: 'image-minus',
    codepoint: 'F1419',
    deprecated: 'FALSE',
  },
  {
    id: '293B433C-5A31-4802-9516-6CF1D649158A',
    name: 'image-minus-outline',
    codepoint: 'F1B47',
    deprecated: 'FALSE',
  },
  {
    id: '4E0C0467-BF17-4C60-8834-4627DE6D79D5',
    name: 'image-move',
    codepoint: 'F09F8',
    deprecated: 'FALSE',
  },
  {
    id: 'E7CA4672-9863-4015-B3C5-D2C8DEBD2F2F',
    name: 'image-multiple',
    codepoint: 'F02F9',
    deprecated: 'FALSE',
  },
  {
    id: 'BCCF75AE-F73D-4B7A-BDFD-9E767DED0B28',
    name: 'image-multiple-outline',
    codepoint: 'F02EF',
    deprecated: 'FALSE',
  },
  {
    id: '73924BC5-DB17-49B2-BB88-E40C1E45E010',
    name: 'image-off',
    codepoint: 'F082B',
    deprecated: 'FALSE',
  },
  {
    id: '8C0E595D-8D0E-4D1E-8E62-850DB25103B3',
    name: 'image-off-outline',
    codepoint: 'F11D1',
    deprecated: 'FALSE',
  },
  {
    id: 'F6A782BB-5FAB-4FC4-A61B-A0F399A0A5AC',
    name: 'image-outline',
    codepoint: 'F0976',
    deprecated: 'FALSE',
  },
  {
    id: '8D6FECF2-E4C4-4858-81E2-502104BE2E55',
    name: 'image-plus',
    codepoint: 'F087C',
    deprecated: 'FALSE',
  },
  {
    id: 'EAB45270-C25D-4493-A71B-9268CC7D2E88',
    name: 'image-plus-outline',
    codepoint: 'F1B46',
    deprecated: 'FALSE',
  },
  {
    id: 'F91A5FFA-04D3-40A5-AFD1-C7F79D1CE20D',
    name: 'image-refresh',
    codepoint: 'F19FE',
    deprecated: 'FALSE',
  },
  {
    id: '243DB52F-654F-488F-86DB-BD7EA47B3BA8',
    name: 'image-refresh-outline',
    codepoint: 'F19FF',
    deprecated: 'FALSE',
  },
  {
    id: '95B5A056-E7FE-4C3F-95A6-1E826DD80076',
    name: 'image-remove',
    codepoint: 'F1418',
    deprecated: 'FALSE',
  },
  {
    id: 'BC17E5CC-2CF0-4BB8-B467-2672A62E9CCA',
    name: 'image-remove-outline',
    codepoint: 'F1B48',
    deprecated: 'FALSE',
  },
  {
    id: 'F00F5E5A-AD8A-440B-9B31-00D266A538A8',
    name: 'image-search',
    codepoint: 'F0977',
    deprecated: 'FALSE',
  },
  {
    id: 'A36A87C1-E35B-4BCA-AD53-700F6F937EE8',
    name: 'image-search-outline',
    codepoint: 'F0978',
    deprecated: 'FALSE',
  },
  {
    id: '84644CCB-31DB-471C-AF2C-6E8AD1DE94CA',
    name: 'image-size-select-actual',
    codepoint: 'F0C8D',
    deprecated: 'FALSE',
  },
  {
    id: '58C0E0A5-F760-4893-99C4-3C1E92DBC6DB',
    name: 'image-size-select-large',
    codepoint: 'F0C8E',
    deprecated: 'FALSE',
  },
  {
    id: 'A0771C0A-E46B-4985-98D8-C6EC748D03A2',
    name: 'image-size-select-small',
    codepoint: 'F0C8F',
    deprecated: 'FALSE',
  },
  {
    id: '8597FAE8-2093-48A1-8B87-9717D9AB968B',
    name: 'image-sync',
    codepoint: 'F1A00',
    deprecated: 'FALSE',
  },
  {
    id: 'DB8F481F-1215-4093-8B6F-C50C34C93A4C',
    name: 'image-sync-outline',
    codepoint: 'F1A01',
    deprecated: 'FALSE',
  },
  {
    id: 'E404BB7C-724B-4390-9DE4-D08DA29B151A',
    name: 'image-text',
    codepoint: 'F160D',
    deprecated: 'FALSE',
  },
  {
    id: 'FB8BEE36-EF77-4820-92B3-5B4C4291D751',
    name: 'import',
    codepoint: 'F02FA',
    deprecated: 'FALSE',
  },
  {
    id: '4DDB79C2-C3AE-41DC-B560-AFBF3F689E86',
    name: 'inbox',
    codepoint: 'F0687',
    deprecated: 'FALSE',
  },
  {
    id: 'A20D1ABC-DD99-404B-8BEE-967BB1C636B0',
    name: 'inbox-arrow-down',
    codepoint: 'F02FB',
    deprecated: 'FALSE',
  },
  {
    id: 'B7B431BB-E448-47F4-A7DC-03CCCA93C3D1',
    name: 'inbox-arrow-down-outline',
    codepoint: 'F1270',
    deprecated: 'FALSE',
  },
  {
    id: '5317ED5A-9D37-4C50-AA7D-83491547D214',
    name: 'inbox-arrow-up',
    codepoint: 'F03D1',
    deprecated: 'FALSE',
  },
  {
    id: 'B84765D3-091A-483D-A788-C0269238CF6D',
    name: 'inbox-arrow-up-outline',
    codepoint: 'F1271',
    deprecated: 'FALSE',
  },
  {
    id: '1CB38744-AE8F-4B42-ACED-10749BBF81F5',
    name: 'inbox-full',
    codepoint: 'F1272',
    deprecated: 'FALSE',
  },
  {
    id: 'BD7DE99A-F6EC-46CF-BE18-8371E6BCD9CA',
    name: 'inbox-full-outline',
    codepoint: 'F1273',
    deprecated: 'FALSE',
  },
  {
    id: '6FF18A17-48D4-4173-9CBD-8DB77F5A6E2A',
    name: 'inbox-multiple',
    codepoint: 'F08B0',
    deprecated: 'FALSE',
  },
  {
    id: 'AA22D582-9319-4AB0-BBF8-08824D7605B8',
    name: 'inbox-multiple-outline',
    codepoint: 'F0BA8',
    deprecated: 'FALSE',
  },
  {
    id: '7082C01E-4FF9-489E-BD05-53140FCD382A',
    name: 'inbox-outline',
    codepoint: 'F1274',
    deprecated: 'FALSE',
  },
  {
    id: '2166DBC6-19EC-4A1D-BE92-8366CEE53B8F',
    name: 'inbox-remove',
    codepoint: 'F159F',
    deprecated: 'FALSE',
  },
  {
    id: '628C4376-372F-4880-875B-DE838A6B4B70',
    name: 'inbox-remove-outline',
    codepoint: 'F15A0',
    deprecated: 'FALSE',
  },
  {
    id: '1350E7BA-9354-4F62-9B21-0238E3DF4D81',
    name: 'incognito',
    codepoint: 'F05F9',
    deprecated: 'FALSE',
  },
  {
    id: '80DE3C45-2A19-412F-9FA2-DCFB853F60ED',
    name: 'incognito-circle',
    codepoint: 'F1421',
    deprecated: 'FALSE',
  },
  {
    id: '3F362E64-C60F-416B-834D-E5C2A88ED23F',
    name: 'incognito-circle-off',
    codepoint: 'F1422',
    deprecated: 'FALSE',
  },
  {
    id: '26B94EEC-5090-4DF4-99FC-C4A90830720A',
    name: 'incognito-off',
    codepoint: 'F0075',
    deprecated: 'FALSE',
  },
  {
    id: '9B626C0C-73AD-4209-BEB6-70AE9BA4D1FF',
    name: 'induction',
    codepoint: 'F184C',
    deprecated: 'FALSE',
  },
  {
    id: '8C4EBD04-376D-41AC-86D3-2BBDD56768C7',
    name: 'infinity',
    codepoint: 'F06E4',
    deprecated: 'FALSE',
  },
  {
    id: '2BC4A4B6-119B-4637-BF3D-1FB9394E1F38',
    name: 'information',
    codepoint: 'F02FC',
    deprecated: 'FALSE',
  },
  {
    id: '68E3EA61-207C-444B-9CC5-8A7016D9953C',
    name: 'information-off',
    codepoint: 'F178C',
    deprecated: 'FALSE',
  },
  {
    id: '25B8EEBF-60AA-422C-8E9C-E8D6004E9614',
    name: 'information-off-outline',
    codepoint: 'F178D',
    deprecated: 'FALSE',
  },
  {
    id: 'F7C01A96-16E0-44C0-A261-2CF22D056A7F',
    name: 'information-outline',
    codepoint: 'F02FD',
    deprecated: 'FALSE',
  },
  {
    id: 'D991D5FA-BD60-48E1-979D-A103D005725A',
    name: 'information-variant',
    codepoint: 'F064E',
    deprecated: 'FALSE',
  },
  {
    id: '2AA07220-461B-4070-AA95-17DEFE501444',
    name: 'instagram',
    codepoint: 'F02FE',
    deprecated: 'TRUE',
  },
  {
    id: '7A8E4735-E738-4ECE-8386-889694014C22',
    name: 'instrument-triangle',
    codepoint: 'F104E',
    deprecated: 'FALSE',
  },
  {
    id: 'D9D79C34-BE5A-4EA2-BB40-07E941C1632B',
    name: 'integrated-circuit-chip',
    codepoint: 'F1913',
    deprecated: 'FALSE',
  },
  {
    id: '2B0100AC-D0EB-4B85-A9AB-EB921C9A65AC',
    name: 'invert-colors',
    codepoint: 'F0301',
    deprecated: 'FALSE',
  },
  {
    id: 'B3F155FA-57B1-4781-AC45-98A1A47673DC',
    name: 'invert-colors-off',
    codepoint: 'F0E4A',
    deprecated: 'FALSE',
  },
  {
    id: 'A360D72F-7DC7-4E24-B7F0-1856C918CB8A',
    name: 'iobroker',
    codepoint: 'F12E8',
    deprecated: 'TRUE',
  },
  {
    id: '4560EF32-158B-40B1-829E-A078219A808A',
    name: 'ip',
    codepoint: 'F0A5F',
    deprecated: 'FALSE',
  },
  {
    id: '54CCA164-149B-4050-9747-529EF134460A',
    name: 'ip-network',
    codepoint: 'F0A60',
    deprecated: 'FALSE',
  },
  {
    id: 'BB20E65D-DA61-4A5E-8B2E-52CB92DE3771',
    name: 'ip-network-outline',
    codepoint: 'F0C90',
    deprecated: 'FALSE',
  },
  {
    id: 'C251F57E-6443-4109-A61E-2F98D6609A47',
    name: 'ip-outline',
    codepoint: 'F1982',
    deprecated: 'FALSE',
  },
  {
    id: '63BFCC93-117D-45ED-8E42-4019C6D7B970',
    name: 'ipod',
    codepoint: 'F0C91',
    deprecated: 'FALSE',
  },
  {
    id: '43DA7407-CFBA-44CE-9857-D75DF5581CE2',
    name: 'iron',
    codepoint: 'F1824',
    deprecated: 'FALSE',
  },
  {
    id: '60A790B9-4A75-4778-98A4-84C46B827924',
    name: 'iron-board',
    codepoint: 'F1838',
    deprecated: 'FALSE',
  },
  {
    id: '82FEA1DA-69DE-436E-9D68-9116ADBE7D9B',
    name: 'iron-outline',
    codepoint: 'F1825',
    deprecated: 'FALSE',
  },
  {
    id: 'A39024B8-5991-409C-8E68-DC9562D52F86',
    name: 'island',
    codepoint: 'F104F',
    deprecated: 'FALSE',
  },
  {
    id: '0921F3AE-3BFF-4913-BB68-CB48587D14E4',
    name: 'iv-bag',
    codepoint: 'F10B9',
    deprecated: 'FALSE',
  },
  {
    id: '5F75012D-D248-4275-B274-6D6AB3B95F3B',
    name: 'jabber',
    codepoint: 'F0DD5',
    deprecated: 'TRUE',
  },
  {
    id: '2C0278A5-D43D-4B8B-8870-878BEAF6BE17',
    name: 'jeepney',
    codepoint: 'F0302',
    deprecated: 'FALSE',
  },
  {
    id: '2F73C05F-4D6E-49B3-B41B-BCDA372E1774',
    name: 'jellyfish',
    codepoint: 'F0F01',
    deprecated: 'FALSE',
  },
  {
    id: '4F49A0D7-8E63-411E-97E0-7CA054831A38',
    name: 'jellyfish-outline',
    codepoint: 'F0F02',
    deprecated: 'FALSE',
  },
  {
    id: '2F0CB359-6C54-4723-9E8D-94481F0A8E30',
    name: 'jira',
    codepoint: 'F0303',
    deprecated: 'TRUE',
  },
  {
    id: '3A14C142-9D2F-44BC-882E-333745FACAA3',
    name: 'jquery',
    codepoint: 'F087D',
    deprecated: 'TRUE',
  },
  {
    id: 'B6B10BEF-B1E3-43D7-AAAA-85566157B30B',
    name: 'jsfiddle',
    codepoint: 'F0304',
    deprecated: 'TRUE',
  },
  {
    id: 'FC2D912E-451A-4AE8-8399-67F116ED12D9',
    name: 'jump-rope',
    codepoint: 'F12FF',
    deprecated: 'FALSE',
  },
  {
    id: '24CB8442-6875-4A22-A265-0C625118C7E7',
    name: 'kabaddi',
    codepoint: 'F0D87',
    deprecated: 'FALSE',
  },
  {
    id: '70EC6F3B-31E9-4F1A-8E48-408CEB706FB8',
    name: 'kangaroo',
    codepoint: 'F1558',
    deprecated: 'FALSE',
  },
  {
    id: '85144563-B019-4B8A-AB76-3DE0797D517E',
    name: 'karate',
    codepoint: 'F082C',
    deprecated: 'FALSE',
  },
  {
    id: '21C7383B-5839-4F3E-A4EC-81A9980F531B',
    name: 'kayaking',
    codepoint: 'F08AF',
    deprecated: 'FALSE',
  },
  {
    id: 'EEF9C07A-019A-49B4-805D-0DF8601F09DF',
    name: 'keg',
    codepoint: 'F0305',
    deprecated: 'FALSE',
  },
  {
    id: 'C4476901-9B29-410C-BCA7-67814F5421B4',
    name: 'kettle',
    codepoint: 'F05FA',
    deprecated: 'FALSE',
  },
  {
    id: '123DA4E5-7243-4495-8CE4-2CDA32A65C01',
    name: 'kettle-alert',
    codepoint: 'F1317',
    deprecated: 'FALSE',
  },
  {
    id: '4F1F1AC0-68D1-4C74-8F03-DF6EBC2C8B29',
    name: 'kettle-alert-outline',
    codepoint: 'F1318',
    deprecated: 'FALSE',
  },
  {
    id: 'A28C7FA8-2D3A-482A-ADC8-46391CF201E1',
    name: 'kettle-off',
    codepoint: 'F131B',
    deprecated: 'FALSE',
  },
  {
    id: '15C6F7FE-65D4-4116-AF5C-7A171407E460',
    name: 'kettle-off-outline',
    codepoint: 'F131C',
    deprecated: 'FALSE',
  },
  {
    id: 'FCE0A2B1-414F-4E9F-AF73-F878AE890F38',
    name: 'kettle-outline',
    codepoint: 'F0F56',
    deprecated: 'FALSE',
  },
  {
    id: '30064882-D796-4A8E-8BD1-CAED8C9A49DA',
    name: 'kettle-pour-over',
    codepoint: 'F173C',
    deprecated: 'FALSE',
  },
  {
    id: 'B976C4B0-D50C-49ED-B072-CD8E8A38A4AF',
    name: 'kettle-steam',
    codepoint: 'F1319',
    deprecated: 'FALSE',
  },
  {
    id: '6D9BDFFF-63EE-45DE-A6F5-712B0EE12534',
    name: 'kettle-steam-outline',
    codepoint: 'F131A',
    deprecated: 'FALSE',
  },
  {
    id: 'E3220479-E885-4EDD-9678-E60F670DFBAA',
    name: 'kettlebell',
    codepoint: 'F1300',
    deprecated: 'FALSE',
  },
  {
    id: 'D6208581-CF7F-438A-8578-B6ADEE462212',
    name: 'key',
    codepoint: 'F0306',
    deprecated: 'FALSE',
  },
  {
    id: '62432542-54AA-42CB-B866-EFF80DDEB97C',
    name: 'key-alert',
    codepoint: 'F1983',
    deprecated: 'FALSE',
  },
  {
    id: '1038F818-4017-42DB-A290-6FD069D3989C',
    name: 'key-alert-outline',
    codepoint: 'F1984',
    deprecated: 'FALSE',
  },
  {
    id: '7A79C7BB-6669-46CA-8AB0-E3B537D83EA2',
    name: 'key-arrow-right',
    codepoint: 'F1312',
    deprecated: 'FALSE',
  },
  {
    id: '80B6B5A5-0FE3-4CA0-924C-05311090832D',
    name: 'key-chain',
    codepoint: 'F1574',
    deprecated: 'FALSE',
  },
  {
    id: '01B0561A-56B4-4215-A811-1572D35331F6',
    name: 'key-chain-variant',
    codepoint: 'F1575',
    deprecated: 'FALSE',
  },
  {
    id: 'FEEA71CA-4810-46DD-B459-6D9327720D17',
    name: 'key-change',
    codepoint: 'F0307',
    deprecated: 'FALSE',
  },
  {
    id: '08C0F709-4E1C-4BFD-8CC6-BC12FE5CDD9B',
    name: 'key-link',
    codepoint: 'F119F',
    deprecated: 'FALSE',
  },
  {
    id: 'F58302B9-82F4-46AC-9A60-4640CFCE33B2',
    name: 'key-minus',
    codepoint: 'F0308',
    deprecated: 'FALSE',
  },
  {
    id: '83238148-C76D-4C83-904D-911A9E9C2715',
    name: 'key-outline',
    codepoint: 'F0DD6',
    deprecated: 'FALSE',
  },
  {
    id: '000AAEC6-5E6C-40F4-B589-AE85920E7EC2',
    name: 'key-plus',
    codepoint: 'F0309',
    deprecated: 'FALSE',
  },
  {
    id: '5A1609F7-424E-417F-B1C4-7EDA3051A4BA',
    name: 'key-remove',
    codepoint: 'F030A',
    deprecated: 'FALSE',
  },
  {
    id: '7C312A2D-A502-4F06-89D4-0783EADC3B3F',
    name: 'key-star',
    codepoint: 'F119E',
    deprecated: 'FALSE',
  },
  {
    id: '967692C3-B061-425E-A0E4-FF1F580275E5',
    name: 'key-variant',
    codepoint: 'F030B',
    deprecated: 'FALSE',
  },
  {
    id: '196889AF-CD00-44BC-BAD3-932B936E4EAE',
    name: 'key-wireless',
    codepoint: 'F0FC2',
    deprecated: 'FALSE',
  },
  {
    id: '520C8488-DD34-43E1-AEA0-A188B2DE6B01',
    name: 'keyboard',
    codepoint: 'F030C',
    deprecated: 'FALSE',
  },
  {
    id: '21C8B558-8398-43E8-A79A-8BA99BE693D6',
    name: 'keyboard-backspace',
    codepoint: 'F030D',
    deprecated: 'FALSE',
  },
  {
    id: '0F441009-7A1F-45BF-A239-B9591B0A5404',
    name: 'keyboard-caps',
    codepoint: 'F030E',
    deprecated: 'FALSE',
  },
  {
    id: '8545C0A1-5625-422F-AF38-624FB54237D3',
    name: 'keyboard-close',
    codepoint: 'F030F',
    deprecated: 'FALSE',
  },
  {
    id: '6F1662E0-9571-4412-9075-6982D2636788',
    name: 'keyboard-close-outline',
    codepoint: 'F1C00',
    deprecated: 'FALSE',
  },
  {
    id: '3EB07099-2309-466E-8BC0-EA835C273E37',
    name: 'keyboard-esc',
    codepoint: 'F12B7',
    deprecated: 'FALSE',
  },
  {
    id: '512A5D44-18A8-4644-AA17-E8E026E0F1B0',
    name: 'keyboard-f1',
    codepoint: 'F12AB',
    deprecated: 'FALSE',
  },
  {
    id: '29296B9C-9A28-4F04-A2AD-550CB1EC89E1',
    name: 'keyboard-f10',
    codepoint: 'F12B4',
    deprecated: 'FALSE',
  },
  {
    id: 'DDF92650-C04B-4826-BD5A-FB12B06F7E6E',
    name: 'keyboard-f11',
    codepoint: 'F12B5',
    deprecated: 'FALSE',
  },
  {
    id: '7C97B45E-8039-41C4-A188-A21475A4FB3F',
    name: 'keyboard-f12',
    codepoint: 'F12B6',
    deprecated: 'FALSE',
  },
  {
    id: '3DFC5B29-C785-4D97-A04D-E63FAD987C25',
    name: 'keyboard-f2',
    codepoint: 'F12AC',
    deprecated: 'FALSE',
  },
  {
    id: '7776F4B4-2F61-47FF-83F5-A768239E3F80',
    name: 'keyboard-f3',
    codepoint: 'F12AD',
    deprecated: 'FALSE',
  },
  {
    id: 'C227FD26-6801-4545-AC74-66C63B56807A',
    name: 'keyboard-f4',
    codepoint: 'F12AE',
    deprecated: 'FALSE',
  },
  {
    id: 'C50601AF-7AD6-4677-B8B3-181B978613A8',
    name: 'keyboard-f5',
    codepoint: 'F12AF',
    deprecated: 'FALSE',
  },
  {
    id: '9B4318C8-A2CC-43A9-8706-C9D3DDF33D5E',
    name: 'keyboard-f6',
    codepoint: 'F12B0',
    deprecated: 'FALSE',
  },
  {
    id: 'DDE4A6E4-BDE8-4E63-8F77-E3D06BF29BF1',
    name: 'keyboard-f7',
    codepoint: 'F12B1',
    deprecated: 'FALSE',
  },
  {
    id: '3392982E-1464-49EF-B22F-EE0FBA421D4E',
    name: 'keyboard-f8',
    codepoint: 'F12B2',
    deprecated: 'FALSE',
  },
  {
    id: 'BBFF3508-533C-4378-B9FC-60EC20CAB214',
    name: 'keyboard-f9',
    codepoint: 'F12B3',
    deprecated: 'FALSE',
  },
  {
    id: 'A7DFF78C-C6DD-4BB6-BBFA-A88242516A74',
    name: 'keyboard-off',
    codepoint: 'F0310',
    deprecated: 'FALSE',
  },
  {
    id: '4DC89B68-BD60-4D89-BD32-F15C0CFCEF79',
    name: 'keyboard-off-outline',
    codepoint: 'F0E4B',
    deprecated: 'FALSE',
  },
  {
    id: '6E751B9F-72AD-4554-8C62-24E457B80FDB',
    name: 'keyboard-outline',
    codepoint: 'F097B',
    deprecated: 'FALSE',
  },
  {
    id: '7816CE91-EBD1-4136-8523-CF47F677F4C6',
    name: 'keyboard-return',
    codepoint: 'F0311',
    deprecated: 'FALSE',
  },
  {
    id: '4894BA3C-C713-4ACA-9918-1AF74B5E5427',
    name: 'keyboard-settings',
    codepoint: 'F09F9',
    deprecated: 'FALSE',
  },
  {
    id: '7D765BD9-7B7E-4288-89E5-4A74E72E1083',
    name: 'keyboard-settings-outline',
    codepoint: 'F09FA',
    deprecated: 'FALSE',
  },
  {
    id: '803E5E8E-04C6-41E1-9EE0-B8C3C723D507',
    name: 'keyboard-space',
    codepoint: 'F1050',
    deprecated: 'FALSE',
  },
  {
    id: 'D7C0D37A-BF75-4542-8AFF-614FA0EC498C',
    name: 'keyboard-tab',
    codepoint: 'F0312',
    deprecated: 'FALSE',
  },
  {
    id: 'CFF782E5-F36B-4F80-B105-68E6B4B91C64',
    name: 'keyboard-tab-reverse',
    codepoint: 'F0325',
    deprecated: 'FALSE',
  },
  {
    id: 'BCAA42FF-BF36-42D8-B8FD-5DE2968162D8',
    name: 'keyboard-variant',
    codepoint: 'F0313',
    deprecated: 'FALSE',
  },
  {
    id: '8F5B2685-A656-45ED-89C2-032E76322358',
    name: 'khanda',
    codepoint: 'F10FD',
    deprecated: 'FALSE',
  },
  {
    id: 'D881D589-79F0-4255-B050-13E396293D1F',
    name: 'kickstarter',
    codepoint: 'F0745',
    deprecated: 'TRUE',
  },
  {
    id: '8B89609E-5F12-4A3B-99AE-F6698FEA48E4',
    name: 'kite',
    codepoint: 'F1985',
    deprecated: 'FALSE',
  },
  {
    id: 'C95E036C-B786-490E-B7A7-9704E049DCE1',
    name: 'kite-outline',
    codepoint: 'F1986',
    deprecated: 'FALSE',
  },
  {
    id: '2B23BD24-9796-4D72-85CC-76BE23854F0F',
    name: 'kitesurfing',
    codepoint: 'F1744',
    deprecated: 'FALSE',
  },
  {
    id: '1A131BAA-CFEE-41F3-9B15-01A4A9F1B341',
    name: 'klingon',
    codepoint: 'F135B',
    deprecated: 'FALSE',
  },
  {
    id: 'AF35DB46-1B52-4721-8475-F3CD107325B4',
    name: 'knife',
    codepoint: 'F09FB',
    deprecated: 'FALSE',
  },
  {
    id: 'D31C18C1-776C-4444-9A12-F2BD366CF929',
    name: 'knife-military',
    codepoint: 'F09FC',
    deprecated: 'FALSE',
  },
  {
    id: 'BF72875E-F657-4469-B7B6-CC8116EC20D1',
    name: 'knob',
    codepoint: 'F1B96',
    deprecated: 'FALSE',
  },
  {
    id: '180CACBF-4272-4D07-B9A0-63A5625DCB0C',
    name: 'koala',
    codepoint: 'F173F',
    deprecated: 'FALSE',
  },
  {
    id: 'AD953D07-5F33-439B-895F-7AD5615EA14A',
    name: 'kodi',
    codepoint: 'F0314',
    deprecated: 'TRUE',
  },
  {
    id: '9823C48B-5D2E-4058-9836-F2C6DCACAB71',
    name: 'kubernetes',
    codepoint: 'F10FE',
    deprecated: 'TRUE',
  },
  {
    id: 'C749E06B-E75E-4D9B-97A6-47E9399D01FC',
    name: 'label',
    codepoint: 'F0315',
    deprecated: 'FALSE',
  },
  {
    id: '9373446C-95C3-4160-9CBC-FF1DEF5C9F28',
    name: 'label-multiple',
    codepoint: 'F1375',
    deprecated: 'FALSE',
  },
  {
    id: 'D504ED4D-5F02-4501-A223-59BF2D4CD9EB',
    name: 'label-multiple-outline',
    codepoint: 'F1376',
    deprecated: 'FALSE',
  },
  {
    id: 'BCBB111E-0DF2-4498-831D-E547AE9EE0FB',
    name: 'label-off',
    codepoint: 'F0ACB',
    deprecated: 'FALSE',
  },
  {
    id: '94A3D797-3936-4FBE-A6B5-6C88934B40BD',
    name: 'label-off-outline',
    codepoint: 'F0ACC',
    deprecated: 'FALSE',
  },
  {
    id: '91C2FA0C-2273-42F9-ACBF-BD418B8F970D',
    name: 'label-outline',
    codepoint: 'F0316',
    deprecated: 'FALSE',
  },
  {
    id: '62FCE66D-4003-4B22-8C47-501947EA4CC8',
    name: 'label-percent',
    codepoint: 'F12EA',
    deprecated: 'FALSE',
  },
  {
    id: '7EBE7C17-4905-43F9-A084-0756FBB3905D',
    name: 'label-percent-outline',
    codepoint: 'F12EB',
    deprecated: 'FALSE',
  },
  {
    id: '3A54255E-EDE1-4622-87F4-9FCB31B1B688',
    name: 'label-variant',
    codepoint: 'F0ACD',
    deprecated: 'FALSE',
  },
  {
    id: '75106002-9EFE-4771-8B68-C60B60589C42',
    name: 'label-variant-outline',
    codepoint: 'F0ACE',
    deprecated: 'FALSE',
  },
  {
    id: 'F2C62DAD-9011-4495-B542-87407FFB7EA1',
    name: 'ladder',
    codepoint: 'F15A2',
    deprecated: 'FALSE',
  },
  {
    id: '97AFF594-E421-4757-BE72-1D6C428E27AE',
    name: 'ladybug',
    codepoint: 'F082D',
    deprecated: 'FALSE',
  },
  {
    id: '1815038E-B466-4F5F-91D9-9D03DDA6A9DA',
    name: 'lambda',
    codepoint: 'F0627',
    deprecated: 'FALSE',
  },
  {
    id: 'A39CC0FD-32DA-4AB2-8483-9E4C4F03D220',
    name: 'lamp',
    codepoint: 'F06B5',
    deprecated: 'FALSE',
  },
  {
    id: 'F2FF97D8-A51F-4F32-A18A-6A14437EF5CC',
    name: 'lamp-outline',
    codepoint: 'F17D0',
    deprecated: 'FALSE',
  },
  {
    id: '9AC5919E-2271-42D0-A5FA-ACB9BE022547',
    name: 'lamps',
    codepoint: 'F1576',
    deprecated: 'FALSE',
  },
  {
    id: '7BDA9890-697C-4E97-A604-D34803DAAD39',
    name: 'lamps-outline',
    codepoint: 'F17D1',
    deprecated: 'FALSE',
  },
  {
    id: 'A20B1B0B-DC60-4ED1-A7E6-F390BA31D613',
    name: 'lan',
    codepoint: 'F0317',
    deprecated: 'FALSE',
  },
  {
    id: '9DF15E25-DE45-4D9B-8E3B-EA6D643EA0CC',
    name: 'lan-check',
    codepoint: 'F12AA',
    deprecated: 'FALSE',
  },
  {
    id: 'B52D6944-3AC2-4113-A91C-199C0CD577A6',
    name: 'lan-connect',
    codepoint: 'F0318',
    deprecated: 'FALSE',
  },
  {
    id: '10DCD7DC-998B-4631-B8A6-A0D8C93412A3',
    name: 'lan-disconnect',
    codepoint: 'F0319',
    deprecated: 'FALSE',
  },
  {
    id: 'B5E302E3-DF81-430E-AAE0-4CAF44AFAD00',
    name: 'lan-pending',
    codepoint: 'F031A',
    deprecated: 'FALSE',
  },
  {
    id: 'A06B998D-336F-4FDC-A0C8-04BD7AE6AAB3',
    name: 'land-fields',
    codepoint: 'F1AB2',
    deprecated: 'FALSE',
  },
  {
    id: 'B6AD150F-5C19-421E-9A2A-D3F43F38D440',
    name: 'land-plots',
    codepoint: 'F1AB3',
    deprecated: 'FALSE',
  },
  {
    id: '9D236600-B0B0-41E8-9F93-91F0E97B3C81',
    name: 'land-plots-circle',
    codepoint: 'F1AB4',
    deprecated: 'FALSE',
  },
  {
    id: 'B2285EFF-7160-43A7-8037-B3C1F96987D0',
    name: 'land-plots-circle-variant',
    codepoint: 'F1AB5',
    deprecated: 'FALSE',
  },
  {
    id: '6D69ADDA-D5A1-47F9-9597-D4529C893789',
    name: 'land-rows-horizontal',
    codepoint: 'F1AB6',
    deprecated: 'FALSE',
  },
  {
    id: '0CE60AA4-FF6E-4899-AE30-830BD1E4F898',
    name: 'land-rows-vertical',
    codepoint: 'F1AB7',
    deprecated: 'FALSE',
  },
  {
    id: 'AD048AA5-6C71-49DF-B5B2-427F634C3538',
    name: 'landslide',
    codepoint: 'F1A48',
    deprecated: 'FALSE',
  },
  {
    id: '847329AA-B841-48A2-9700-6363FA219184',
    name: 'landslide-outline',
    codepoint: 'F1A49',
    deprecated: 'FALSE',
  },
  {
    id: '082F25B3-8413-4CC7-B908-8BBD40BB32D8',
    name: 'language-c',
    codepoint: 'F0671',
    deprecated: 'TRUE',
  },
  {
    id: '74446CD2-562D-44E7-AAC1-62D4CCDB9F43',
    name: 'language-cpp',
    codepoint: 'F0672',
    deprecated: 'TRUE',
  },
  {
    id: 'EFF1E516-3470-456C-AC79-0F9C82C79391',
    name: 'language-csharp',
    codepoint: 'F031B',
    deprecated: 'TRUE',
  },
  {
    id: 'DA72E30D-C562-4E93-B8C8-CAFA686A1D23',
    name: 'language-css3',
    codepoint: 'F031C',
    deprecated: 'TRUE',
  },
  {
    id: 'F1DEF74B-8116-4E0E-98BE-97A699812E5A',
    name: 'language-fortran',
    codepoint: 'F121A',
    deprecated: 'TRUE',
  },
  {
    id: 'ACAFCF05-E04D-4212-AB53-C72BD8DB5768',
    name: 'language-go',
    codepoint: 'F07D3',
    deprecated: 'TRUE',
  },
  {
    id: '87E5CF33-FA30-4CCA-8FAD-AAD3D7C17904',
    name: 'language-haskell',
    codepoint: 'F0C92',
    deprecated: 'TRUE',
  },
  {
    id: '98D9A5A1-626A-4273-B895-4C838936613C',
    name: 'language-html5',
    codepoint: 'F031D',
    deprecated: 'TRUE',
  },
  {
    id: 'CF5C2532-318B-4866-B8CE-319601ADD95E',
    name: 'language-java',
    codepoint: 'F0B37',
    deprecated: 'TRUE',
  },
  {
    id: '9D3ED5EB-8951-4E75-A623-EEF618ED4172',
    name: 'language-javascript',
    codepoint: 'F031E',
    deprecated: 'TRUE',
  },
  {
    id: 'D3EC3384-B91D-46C9-8D43-7D333DB2273D',
    name: 'language-kotlin',
    codepoint: 'F1219',
    deprecated: 'TRUE',
  },
  {
    id: '0B0DE3A6-7ED1-497C-BAF4-CDAA3F9713C9',
    name: 'language-lua',
    codepoint: 'F08B1',
    deprecated: 'TRUE',
  },
  {
    id: '42875A08-46EC-4077-95A9-86D16888F2D4',
    name: 'language-markdown',
    codepoint: 'F0354',
    deprecated: 'TRUE',
  },
  {
    id: 'A8970A35-C4F5-452B-B8CA-13BACF5C29F5',
    name: 'language-markdown-outline',
    codepoint: 'F0F5B',
    deprecated: 'TRUE',
  },
  {
    id: '2A153653-6727-4DE1-95DC-AC6801A7DC01',
    name: 'language-php',
    codepoint: 'F031F',
    deprecated: 'TRUE',
  },
  {
    id: '4E453C2B-40D4-4D60-8EAF-750091283FAC',
    name: 'language-python',
    codepoint: 'F0320',
    deprecated: 'TRUE',
  },
  {
    id: '2F197131-0666-4F07-8BDE-B6FB3C4BB39B',
    name: 'language-r',
    codepoint: 'F07D4',
    deprecated: 'TRUE',
  },
  {
    id: '2BC47DF3-B959-44C0-BE1A-8D1EB0B18FEF',
    name: 'language-ruby',
    codepoint: 'F0D2D',
    deprecated: 'TRUE',
  },
  {
    id: '6898147E-618C-4A16-A06B-5270C63AAB2B',
    name: 'language-ruby-on-rails',
    codepoint: 'F0ACF',
    deprecated: 'TRUE',
  },
  {
    id: 'D88C59AC-3310-4AA7-8432-BFA51BF2DCE4',
    name: 'language-rust',
    codepoint: 'F1617',
    deprecated: 'TRUE',
  },
  {
    id: '9F060254-CB0B-4DD9-87C5-BADAE8F2F8C4',
    name: 'language-swift',
    codepoint: 'F06E5',
    deprecated: 'TRUE',
  },
  {
    id: 'BFE7B3A3-4B61-4583-B22D-395E1FCE1A07',
    name: 'language-typescript',
    codepoint: 'F06E6',
    deprecated: 'TRUE',
  },
  {
    id: '1AC4596D-76B2-4F11-AF23-157736CABB86',
    name: 'language-xaml',
    codepoint: 'F0673',
    deprecated: 'TRUE',
  },
  {
    id: 'A8B6481F-EE91-4F90-81AC-DEB8A096F45A',
    name: 'laptop',
    codepoint: 'F0322',
    deprecated: 'FALSE',
  },
  {
    id: '63808048-CB00-41F6-AE66-3CB683197A9E',
    name: 'laptop-account',
    codepoint: 'F1A4A',
    deprecated: 'FALSE',
  },
  {
    id: 'C9A08F85-B996-498D-ADDC-20A9B77B5489',
    name: 'laptop-off',
    codepoint: 'F06E7',
    deprecated: 'FALSE',
  },
  {
    id: 'B8CF1F73-D020-43C3-83CB-34DB20820628',
    name: 'laravel',
    codepoint: 'F0AD0',
    deprecated: 'TRUE',
  },
  {
    id: 'D202901C-7C33-48DF-925A-BB3506158E99',
    name: 'laser-pointer',
    codepoint: 'F1484',
    deprecated: 'FALSE',
  },
  {
    id: '5A1E325C-36BD-4BAC-BF4F-E349D9016B28',
    name: 'lasso',
    codepoint: 'F0F03',
    deprecated: 'FALSE',
  },
  {
    id: '65AC32C4-911E-4005-8BD1-663AE0000988',
    name: 'lastpass',
    codepoint: 'F0446',
    deprecated: 'TRUE',
  },
  {
    id: 'E72ED7A1-8133-4E0F-8EC6-28F053DF51B1',
    name: 'latitude',
    codepoint: 'F0F57',
    deprecated: 'FALSE',
  },
  {
    id: 'ED8AF9CE-E555-49C9-8E7E-F9B85CD94CDF',
    name: 'launch',
    codepoint: 'F0327',
    deprecated: 'FALSE',
  },
  {
    id: 'B1E2F856-253B-465C-861A-40C5C7D6C8DB',
    name: 'lava-lamp',
    codepoint: 'F07D5',
    deprecated: 'FALSE',
  },
  {
    id: '36A9B462-C487-458E-BFB6-2415739BC968',
    name: 'layers',
    codepoint: 'F0328',
    deprecated: 'FALSE',
  },
  {
    id: 'D091B0C7-B40D-4F9B-836A-CCA680354ACE',
    name: 'layers-edit',
    codepoint: 'F1892',
    deprecated: 'FALSE',
  },
  {
    id: 'E72B7CB5-1A02-49C0-AC64-DF2559F026F1',
    name: 'layers-minus',
    codepoint: 'F0E4C',
    deprecated: 'FALSE',
  },
  {
    id: '1ADB303D-B8D9-49EF-8EB0-C02AFD269F02',
    name: 'layers-off',
    codepoint: 'F0329',
    deprecated: 'FALSE',
  },
  {
    id: 'FAC5EA16-5C62-49FA-8BE4-7D797604A425',
    name: 'layers-off-outline',
    codepoint: 'F09FD',
    deprecated: 'FALSE',
  },
  {
    id: '9026C3EC-9903-40FE-B3DF-EDB7FE10191A',
    name: 'layers-outline',
    codepoint: 'F09FE',
    deprecated: 'FALSE',
  },
  {
    id: '74FE9D86-D461-4C92-8981-E5A67954D6FA',
    name: 'layers-plus',
    codepoint: 'F0E4D',
    deprecated: 'FALSE',
  },
  {
    id: '4FE9D22C-A632-4B2E-8257-D6789764B1F6',
    name: 'layers-remove',
    codepoint: 'F0E4E',
    deprecated: 'FALSE',
  },
  {
    id: '8502BBC1-8C93-4D63-97C6-1BA163C1DDC0',
    name: 'layers-search',
    codepoint: 'F1206',
    deprecated: 'FALSE',
  },
  {
    id: '6EC54CC3-3FAD-4692-B123-9FA5A8F3D80E',
    name: 'layers-search-outline',
    codepoint: 'F1207',
    deprecated: 'FALSE',
  },
  {
    id: 'E13ACA71-A398-4E3F-A1DF-ED7E4A1E1A84',
    name: 'layers-triple',
    codepoint: 'F0F58',
    deprecated: 'FALSE',
  },
  {
    id: 'B5AC4711-8218-4B94-9372-628616C5CBD8',
    name: 'layers-triple-outline',
    codepoint: 'F0F59',
    deprecated: 'FALSE',
  },
  {
    id: '5EB00CD5-91CA-438E-B569-2D680E6102D6',
    name: 'lead-pencil',
    codepoint: 'F064F',
    deprecated: 'FALSE',
  },
  {
    id: '30AC82B4-6073-462B-B63E-0F64706AAC2F',
    name: 'leaf',
    codepoint: 'F032A',
    deprecated: 'FALSE',
  },
  {
    id: '30A6559D-F917-44DE-B7C7-9C1AB4DD8BD4',
    name: 'leaf-circle',
    codepoint: 'F1905',
    deprecated: 'FALSE',
  },
  {
    id: 'C480BC5A-402E-46EB-B913-51AFA64FA26E',
    name: 'leaf-circle-outline',
    codepoint: 'F1906',
    deprecated: 'FALSE',
  },
  {
    id: 'C7FF59F8-8D2F-4866-B8D3-27ED83C324F0',
    name: 'leaf-maple',
    codepoint: 'F0C93',
    deprecated: 'FALSE',
  },
  {
    id: 'D1DE0E7A-928A-430D-BD2B-B80C9FA2D2F4',
    name: 'leaf-maple-off',
    codepoint: 'F12DA',
    deprecated: 'FALSE',
  },
  {
    id: '96F48C59-4C64-4584-9E07-73AAD3533FAA',
    name: 'leaf-off',
    codepoint: 'F12D9',
    deprecated: 'FALSE',
  },
  {
    id: 'D648ECFA-B185-4041-8B54-6D1C125CD08E',
    name: 'leak',
    codepoint: 'F0DD7',
    deprecated: 'FALSE',
  },
  {
    id: 'EDA0BABB-A354-481B-8E60-108E3B20D64C',
    name: 'leak-off',
    codepoint: 'F0DD8',
    deprecated: 'FALSE',
  },
  {
    id: '8EBB4944-6CD1-40E3-A5B7-6962BD23C0E6',
    name: 'lectern',
    codepoint: 'F1AF0',
    deprecated: 'FALSE',
  },
  {
    id: '8A29D85F-1BAD-4610-AF2F-B4AF0F0D2755',
    name: 'led-off',
    codepoint: 'F032B',
    deprecated: 'FALSE',
  },
  {
    id: '5DACA128-6BEE-424D-83BA-8C94E01F5C0A',
    name: 'led-on',
    codepoint: 'F032C',
    deprecated: 'FALSE',
  },
  {
    id: '423F2C55-8E79-441A-A987-45A97FAA3F53',
    name: 'led-outline',
    codepoint: 'F032D',
    deprecated: 'FALSE',
  },
  {
    id: 'F301BDB9-027C-40D0-A21F-3111A7AB218F',
    name: 'led-strip',
    codepoint: 'F07D6',
    deprecated: 'FALSE',
  },
  {
    id: 'E4D78AC1-63FD-4BF0-89CD-91D80CCAAE40',
    name: 'led-strip-variant',
    codepoint: 'F1051',
    deprecated: 'FALSE',
  },
  {
    id: '6EAFDD56-E5C5-459A-B31A-0BAC693931E0',
    name: 'led-strip-variant-off',
    codepoint: 'F1A4B',
    deprecated: 'FALSE',
  },
  {
    id: 'BBDD4244-D5C9-4150-9936-31C775AA463F',
    name: 'led-variant-off',
    codepoint: 'F032E',
    deprecated: 'FALSE',
  },
  {
    id: '9929F712-7241-468C-B10B-5739356EC85C',
    name: 'led-variant-on',
    codepoint: 'F032F',
    deprecated: 'FALSE',
  },
  {
    id: 'AEACBB35-5466-4C5B-8AA5-B366D0322A27',
    name: 'led-variant-outline',
    codepoint: 'F0330',
    deprecated: 'FALSE',
  },
  {
    id: '48662A59-F777-4513-9023-17D826B11F5C',
    name: 'leek',
    codepoint: 'F117D',
    deprecated: 'FALSE',
  },
  {
    id: '098E9BF3-3E1F-43E3-ACFF-2D35123A3186',
    name: 'less-than',
    codepoint: 'F097C',
    deprecated: 'FALSE',
  },
  {
    id: '9CA22101-6F3D-4EE1-B9F1-C64CE0EFC911',
    name: 'less-than-or-equal',
    codepoint: 'F097D',
    deprecated: 'FALSE',
  },
  {
    id: '45FA5A9C-DFF1-4D7E-B683-DB7DE2BAEFC7',
    name: 'library',
    codepoint: 'F0331',
    deprecated: 'FALSE',
  },
  {
    id: '1A100B37-6238-49F0-81EB-05FDE23DAA3E',
    name: 'library-outline',
    codepoint: 'F1A22',
    deprecated: 'FALSE',
  },
  {
    id: '282A591C-4D91-4AAF-B401-00F3AE4B73F2',
    name: 'library-shelves',
    codepoint: 'F0BA9',
    deprecated: 'FALSE',
  },
  {
    id: '8398466C-F905-444F-81E6-B5B2103BD873',
    name: 'license',
    codepoint: 'F0FC3',
    deprecated: 'FALSE',
  },
  {
    id: '7936EB1E-FCE1-4501-A339-C5EB7E4301E9',
    name: 'lifebuoy',
    codepoint: 'F087E',
    deprecated: 'FALSE',
  },
  {
    id: '25729A2F-D0FA-4C82-AEEC-3A771EB4D12A',
    name: 'light-flood-down',
    codepoint: 'F1987',
    deprecated: 'FALSE',
  },
  {
    id: '9CE460EF-B246-4833-AF6D-B6ACB2A1E9A8',
    name: 'light-flood-up',
    codepoint: 'F1988',
    deprecated: 'FALSE',
  },
  {
    id: 'F309BA75-39A7-45AC-9B1A-22D1F7D41345',
    name: 'light-recessed',
    codepoint: 'F179B',
    deprecated: 'FALSE',
  },
  {
    id: '647E0001-A60B-4D74-B127-BF522712922F',
    name: 'light-switch',
    codepoint: 'F097E',
    deprecated: 'FALSE',
  },
  {
    id: '39A7777B-C9F7-4468-95B0-FE0037A635E9',
    name: 'light-switch-off',
    codepoint: 'F1A24',
    deprecated: 'FALSE',
  },
  {
    id: 'ECC94305-DFA6-4D9B-85C8-CC79F3F05FB5',
    name: 'lightbulb',
    codepoint: 'F0335',
    deprecated: 'FALSE',
  },
  {
    id: '1FED2710-D8D6-41FC-9318-51A23EDD13CF',
    name: 'lightbulb-alert',
    codepoint: 'F19E1',
    deprecated: 'FALSE',
  },
  {
    id: 'AC5BBA36-C64C-4E2F-B0E9-2B35A4D9FFE8',
    name: 'lightbulb-alert-outline',
    codepoint: 'F19E2',
    deprecated: 'FALSE',
  },
  {
    id: 'DB38FD91-5875-4D53-81A9-A0B5400D53AE',
    name: 'lightbulb-auto',
    codepoint: 'F1800',
    deprecated: 'FALSE',
  },
  {
    id: '34AE1531-6F13-40A5-8E15-ABDD1227DB19',
    name: 'lightbulb-auto-outline',
    codepoint: 'F1801',
    deprecated: 'FALSE',
  },
  {
    id: '7D6FF098-D459-4B89-A13C-C9145A1EB992',
    name: 'lightbulb-cfl',
    codepoint: 'F1208',
    deprecated: 'FALSE',
  },
  {
    id: '9E3FA5DD-7C93-4283-A7BD-30AE71ACD2C6',
    name: 'lightbulb-cfl-off',
    codepoint: 'F1209',
    deprecated: 'FALSE',
  },
  {
    id: '0F61D2EB-5321-4D49-B9DD-4F205DA010A2',
    name: 'lightbulb-cfl-spiral',
    codepoint: 'F1275',
    deprecated: 'FALSE',
  },
  {
    id: '49D60773-F400-491A-AD3F-B50110089F63',
    name: 'lightbulb-cfl-spiral-off',
    codepoint: 'F12C3',
    deprecated: 'FALSE',
  },
  {
    id: '43164D2A-705D-4D6E-9195-E2D559E493B1',
    name: 'lightbulb-fluorescent-tube',
    codepoint: 'F1804',
    deprecated: 'FALSE',
  },
  {
    id: '5D974435-3D3A-4BE6-8422-AD3F6E908BD7',
    name: 'lightbulb-fluorescent-tube-outline',
    codepoint: 'F1805',
    deprecated: 'FALSE',
  },
  {
    id: 'DB267C0E-F298-4380-99C8-E18FA8E06554',
    name: 'lightbulb-group',
    codepoint: 'F1253',
    deprecated: 'FALSE',
  },
  {
    id: 'CA2B5020-40AD-44C1-8868-F14927FCD535',
    name: 'lightbulb-group-off',
    codepoint: 'F12CD',
    deprecated: 'FALSE',
  },
  {
    id: '54E68537-16C8-48C4-82F8-4A1000DA605D',
    name: 'lightbulb-group-off-outline',
    codepoint: 'F12CE',
    deprecated: 'FALSE',
  },
  {
    id: 'C4751630-A657-4A0B-89C5-6FD3037A8CD6',
    name: 'lightbulb-group-outline',
    codepoint: 'F1254',
    deprecated: 'FALSE',
  },
  {
    id: '33D321D2-E427-469E-9646-D5758B4D5038',
    name: 'lightbulb-multiple',
    codepoint: 'F1255',
    deprecated: 'FALSE',
  },
  {
    id: 'B336C5E4-48AB-4620-9DA4-DB1144885F7C',
    name: 'lightbulb-multiple-off',
    codepoint: 'F12CF',
    deprecated: 'FALSE',
  },
  {
    id: '827B8418-9667-4429-A4D3-7C0553D1946E',
    name: 'lightbulb-multiple-off-outline',
    codepoint: 'F12D0',
    deprecated: 'FALSE',
  },
  {
    id: '7AAEC297-D587-4D13-9397-50C8C93B48C8',
    name: 'lightbulb-multiple-outline',
    codepoint: 'F1256',
    deprecated: 'FALSE',
  },
  {
    id: 'E544DBA9-FBF4-4563-82BB-2D6F37788E2F',
    name: 'lightbulb-night',
    codepoint: 'F1A4C',
    deprecated: 'FALSE',
  },
  {
    id: '2C6EE261-B451-4F1F-A260-D34AA0EB341A',
    name: 'lightbulb-night-outline',
    codepoint: 'F1A4D',
    deprecated: 'FALSE',
  },
  {
    id: '14D09F5A-C467-428B-9C5A-F2B3939D1678',
    name: 'lightbulb-off',
    codepoint: 'F0E4F',
    deprecated: 'FALSE',
  },
  {
    id: '02757DC7-3D66-4244-B115-C69A74D24B66',
    name: 'lightbulb-off-outline',
    codepoint: 'F0E50',
    deprecated: 'FALSE',
  },
  {
    id: '4B3F67C4-02C3-4083-B47A-645AD24992FA',
    name: 'lightbulb-on',
    codepoint: 'F06E8',
    deprecated: 'FALSE',
  },
  {
    id: '5AF7B110-A35F-437B-B4F2-5DAED54DE86F',
    name: 'lightbulb-on-10',
    codepoint: 'F1A4E',
    deprecated: 'FALSE',
  },
  {
    id: '00B793C1-0C24-417A-8332-EAECCB47021D',
    name: 'lightbulb-on-20',
    codepoint: 'F1A4F',
    deprecated: 'FALSE',
  },
  {
    id: 'F0353E04-7AA3-4C04-981B-FE96F8659B23',
    name: 'lightbulb-on-30',
    codepoint: 'F1A50',
    deprecated: 'FALSE',
  },
  {
    id: 'D0ADCBE7-2C7B-4A9F-A20B-F094AE655227',
    name: 'lightbulb-on-40',
    codepoint: 'F1A51',
    deprecated: 'FALSE',
  },
  {
    id: '5E462F24-AED9-49A6-ACAE-6137F79179D3',
    name: 'lightbulb-on-50',
    codepoint: 'F1A52',
    deprecated: 'FALSE',
  },
  {
    id: 'A2914A9E-96D7-4B17-9340-8194656F4201',
    name: 'lightbulb-on-60',
    codepoint: 'F1A53',
    deprecated: 'FALSE',
  },
  {
    id: '20E12565-5814-472B-8F9D-B37296DF345E',
    name: 'lightbulb-on-70',
    codepoint: 'F1A54',
    deprecated: 'FALSE',
  },
  {
    id: 'F3C06B83-1208-4E69-A039-692D4B870B5A',
    name: 'lightbulb-on-80',
    codepoint: 'F1A55',
    deprecated: 'FALSE',
  },
  {
    id: '33D9D6A7-250C-46B6-9FD2-81A6477CC8EC',
    name: 'lightbulb-on-90',
    codepoint: 'F1A56',
    deprecated: 'FALSE',
  },
  {
    id: '1FDA626F-5858-43AE-92A0-9CCE7168F08F',
    name: 'lightbulb-on-outline',
    codepoint: 'F06E9',
    deprecated: 'FALSE',
  },
  {
    id: 'BF6C3B65-0795-43E0-B373-058414FDD485',
    name: 'lightbulb-outline',
    codepoint: 'F0336',
    deprecated: 'FALSE',
  },
  {
    id: 'CB600C6A-E43E-4723-A8F0-963222692C23',
    name: 'lightbulb-question',
    codepoint: 'F19E3',
    deprecated: 'FALSE',
  },
  {
    id: '8EFBF4AB-73DD-41EC-BF3C-2503A5BDF93E',
    name: 'lightbulb-question-outline',
    codepoint: 'F19E4',
    deprecated: 'FALSE',
  },
  {
    id: '2D6C2BB9-CF5E-4BD1-A44B-262F2A61FAC2',
    name: 'lightbulb-spot',
    codepoint: 'F17F4',
    deprecated: 'FALSE',
  },
  {
    id: 'A4DF4624-753B-440B-98C9-A25EE330B0AE',
    name: 'lightbulb-spot-off',
    codepoint: 'F17F5',
    deprecated: 'FALSE',
  },
  {
    id: '52A21A41-EC71-4807-BE6B-CF5ED8BBAD10',
    name: 'lightbulb-variant',
    codepoint: 'F1802',
    deprecated: 'FALSE',
  },
  {
    id: '70DD894F-1717-4C05-9C34-D7CE1ACF36CC',
    name: 'lightbulb-variant-outline',
    codepoint: 'F1803',
    deprecated: 'FALSE',
  },
  {
    id: '95534506-8ECD-49B3-9F87-64D52CC874BA',
    name: 'lighthouse',
    codepoint: 'F09FF',
    deprecated: 'FALSE',
  },
  {
    id: '85B287CF-D036-4E36-934F-B6FA28892D9C',
    name: 'lighthouse-on',
    codepoint: 'F0A00',
    deprecated: 'FALSE',
  },
  {
    id: 'A4A9C031-02B0-45C7-B9C8-EA1541DB9A38',
    name: 'lightning-bolt',
    codepoint: 'F140B',
    deprecated: 'FALSE',
  },
  {
    id: 'F055DA49-5992-4DDC-8C71-E5D331520DEA',
    name: 'lightning-bolt-circle',
    codepoint: 'F0820',
    deprecated: 'FALSE',
  },
  {
    id: '908D1C1F-8D3A-4316-8CF5-ABDF16747DA1',
    name: 'lightning-bolt-outline',
    codepoint: 'F140C',
    deprecated: 'FALSE',
  },
  {
    id: 'A19711E4-E339-4016-970B-F81D14B303AC',
    name: 'line-scan',
    codepoint: 'F0624',
    deprecated: 'FALSE',
  },
  {
    id: 'F718A4A1-9D7B-45E5-B0AD-50FDA06BA3C3',
    name: 'lingerie',
    codepoint: 'F1476',
    deprecated: 'FALSE',
  },
  {
    id: '462769C2-0A30-4B31-B66C-55168B10D705',
    name: 'link',
    codepoint: 'F0337',
    deprecated: 'FALSE',
  },
  {
    id: 'A1FC74FE-BCA4-447F-AA0D-837A8E33C945',
    name: 'link-box',
    codepoint: 'F0D1A',
    deprecated: 'FALSE',
  },
  {
    id: '7CBB0F91-4D15-414D-8623-EB84F13AFA75',
    name: 'link-box-outline',
    codepoint: 'F0D1B',
    deprecated: 'FALSE',
  },
  {
    id: '76AB74B7-D753-4AE9-9FDD-5A0C2B8AB823',
    name: 'link-box-variant',
    codepoint: 'F0D1C',
    deprecated: 'FALSE',
  },
  {
    id: '8BEB1816-449A-43E8-8A08-0030E1D4DB93',
    name: 'link-box-variant-outline',
    codepoint: 'F0D1D',
    deprecated: 'FALSE',
  },
  {
    id: 'CDEA2CC8-FD08-49E6-AC29-A5DD70956C35',
    name: 'link-lock',
    codepoint: 'F10BA',
    deprecated: 'FALSE',
  },
  {
    id: '0DACA19D-EFEB-4D22-BE20-9E8FB8C1BBEB',
    name: 'link-off',
    codepoint: 'F0338',
    deprecated: 'FALSE',
  },
  {
    id: '0436D77E-F53E-4A1E-A402-916D7D90A1BE',
    name: 'link-plus',
    codepoint: 'F0C94',
    deprecated: 'FALSE',
  },
  {
    id: '4D09DDBE-9716-4162-9DA9-B50892241E15',
    name: 'link-variant',
    codepoint: 'F0339',
    deprecated: 'FALSE',
  },
  {
    id: '1D8C8A91-65E7-4FED-B4C6-C29550080C43',
    name: 'link-variant-minus',
    codepoint: 'F10FF',
    deprecated: 'FALSE',
  },
  {
    id: 'BC8F07E8-9157-49BB-B8DF-CCCDB19C5467',
    name: 'link-variant-off',
    codepoint: 'F033A',
    deprecated: 'FALSE',
  },
  {
    id: '9132A003-2D40-49A6-9D8B-F2AD2EC98788',
    name: 'link-variant-plus',
    codepoint: 'F1100',
    deprecated: 'FALSE',
  },
  {
    id: 'E1733CFB-12E3-436F-A0EC-F75AE38BAEDB',
    name: 'link-variant-remove',
    codepoint: 'F1101',
    deprecated: 'FALSE',
  },
  {
    id: '248F9A0F-7732-4FC9-8E64-2E4B47BBFDCD',
    name: 'linkedin',
    codepoint: 'F033B',
    deprecated: 'TRUE',
  },
  {
    id: 'A58FE3A3-BC2C-48A4-8146-0D15F1B80128',
    name: 'linux',
    codepoint: 'F033D',
    deprecated: 'TRUE',
  },
  {
    id: '608E356A-A5F0-4992-BE8D-E99CCC533FC7',
    name: 'linux-mint',
    codepoint: 'F08ED',
    deprecated: 'TRUE',
  },
  {
    id: '95390315-999E-4E82-8D89-CDF89AE6478F',
    name: 'lipstick',
    codepoint: 'F13B5',
    deprecated: 'FALSE',
  },
  {
    id: 'BAB8D475-0C50-46EB-9B05-8101442C4F33',
    name: 'liquid-spot',
    codepoint: 'F1826',
    deprecated: 'FALSE',
  },
  {
    id: '48C6596F-07B5-495C-82E2-683B09DD7040',
    name: 'liquor',
    codepoint: 'F191E',
    deprecated: 'FALSE',
  },
  {
    id: 'E1277D63-636E-479A-974A-83901A84A8F0',
    name: 'list-box',
    codepoint: 'F1B7B',
    deprecated: 'FALSE',
  },
  {
    id: '3EE285E0-8234-4B20-BFD4-B82AEA64EA57',
    name: 'list-box-outline',
    codepoint: 'F1B7C',
    deprecated: 'FALSE',
  },
  {
    id: 'CC9CEDB3-55DE-4CCA-B31F-888383F6C69E',
    name: 'list-status',
    codepoint: 'F15AB',
    deprecated: 'FALSE',
  },
  {
    id: '1EB3C314-463F-488D-9DC6-3589C661984E',
    name: 'litecoin',
    codepoint: 'F0A61',
    deprecated: 'TRUE',
  },
  {
    id: '205A2FF1-8827-4D3D-8580-FF7FFCD5232C',
    name: 'loading',
    codepoint: 'F0772',
    deprecated: 'FALSE',
  },
  {
    id: '2D8B2899-C765-48CF-9AEA-64327CB14542',
    name: 'location-enter',
    codepoint: 'F0FC4',
    deprecated: 'FALSE',
  },
  {
    id: '83A1C782-63D7-476B-8A6D-A694F29AE3A9',
    name: 'location-exit',
    codepoint: 'F0FC5',
    deprecated: 'FALSE',
  },
  {
    id: 'D711AFDE-2B8D-4932-82AC-A04E7B2810AE',
    name: 'lock',
    codepoint: 'F033E',
    deprecated: 'FALSE',
  },
  {
    id: '6AFF2FDC-D4F8-41F6-8B80-908D1AB87424',
    name: 'lock-alert',
    codepoint: 'F08EE',
    deprecated: 'FALSE',
  },
  {
    id: '6DE0B795-B0C1-4CFC-A47E-0D1C3A203248',
    name: 'lock-alert-outline',
    codepoint: 'F15D1',
    deprecated: 'FALSE',
  },
  {
    id: 'CA254138-2001-4A37-8D52-E65D608E0E10',
    name: 'lock-check',
    codepoint: 'F139A',
    deprecated: 'FALSE',
  },
  {
    id: '4A17CD48-4EFA-41A5-9A40-4BF9C01F2B18',
    name: 'lock-check-outline',
    codepoint: 'F16A8',
    deprecated: 'FALSE',
  },
  {
    id: 'C16C2563-9EA4-4545-9CF4-56C5EA9FD9F9',
    name: 'lock-clock',
    codepoint: 'F097F',
    deprecated: 'FALSE',
  },
  {
    id: '1C00C37C-183D-41D7-ACEF-18ACFF70DE80',
    name: 'lock-minus',
    codepoint: 'F16A9',
    deprecated: 'FALSE',
  },
  {
    id: '073A5F44-B5D1-4112-AD20-1F86F94ADF60',
    name: 'lock-minus-outline',
    codepoint: 'F16AA',
    deprecated: 'FALSE',
  },
  {
    id: '481E2C2A-E70D-4323-867E-5632966B9555',
    name: 'lock-off',
    codepoint: 'F1671',
    deprecated: 'FALSE',
  },
  {
    id: 'AAC1BB51-BA46-4FB6-B901-F602E7C55351',
    name: 'lock-off-outline',
    codepoint: 'F1672',
    deprecated: 'FALSE',
  },
  {
    id: 'E357D5CD-7E42-463B-899A-7D7B038A3E31',
    name: 'lock-open',
    codepoint: 'F033F',
    deprecated: 'FALSE',
  },
  {
    id: 'D8707612-8DFB-4F83-9FAE-2058254A651F',
    name: 'lock-open-alert',
    codepoint: 'F139B',
    deprecated: 'FALSE',
  },
  {
    id: '47EFC7F8-698E-4466-8EDE-DB6334FE6C18',
    name: 'lock-open-alert-outline',
    codepoint: 'F15D2',
    deprecated: 'FALSE',
  },
  {
    id: '5ED06AF8-75C7-4AC3-962E-F742236AFDA6',
    name: 'lock-open-check',
    codepoint: 'F139C',
    deprecated: 'FALSE',
  },
  {
    id: 'FA8CFB4F-319F-42BE-8615-B4FECDA27719',
    name: 'lock-open-check-outline',
    codepoint: 'F16AB',
    deprecated: 'FALSE',
  },
  {
    id: '5487A862-47C1-445A-B6F4-16AAAD6D058B',
    name: 'lock-open-minus',
    codepoint: 'F16AC',
    deprecated: 'FALSE',
  },
  {
    id: 'A268FC79-9328-4B33-A734-9A4614BB3776',
    name: 'lock-open-minus-outline',
    codepoint: 'F16AD',
    deprecated: 'FALSE',
  },
  {
    id: 'DCE8B967-BCDE-4130-8BF3-DDA78A78326F',
    name: 'lock-open-outline',
    codepoint: 'F0340',
    deprecated: 'FALSE',
  },
  {
    id: '8062D7E8-C9C1-42E1-B42F-E5A9618F1DCD',
    name: 'lock-open-plus',
    codepoint: 'F16AE',
    deprecated: 'FALSE',
  },
  {
    id: '804F39C9-F4EB-4663-8EA6-67F668C8CCDF',
    name: 'lock-open-plus-outline',
    codepoint: 'F16AF',
    deprecated: 'FALSE',
  },
  {
    id: 'DBA50293-BCD6-4CC6-81E7-E63AF1EB56AE',
    name: 'lock-open-remove',
    codepoint: 'F16B0',
    deprecated: 'FALSE',
  },
  {
    id: '73C80D40-9B24-42A8-A22A-94DDE61DDCFC',
    name: 'lock-open-remove-outline',
    codepoint: 'F16B1',
    deprecated: 'FALSE',
  },
  {
    id: '4171E1EE-CA1A-4885-B41B-3EAE135BA8FE',
    name: 'lock-open-variant',
    codepoint: 'F0FC6',
    deprecated: 'FALSE',
  },
  {
    id: 'D3253799-FA57-4E62-967B-088FD6F1BF62',
    name: 'lock-open-variant-outline',
    codepoint: 'F0FC7',
    deprecated: 'FALSE',
  },
  {
    id: '86AD97CA-6028-45C2-8C9A-8C4A562A8E49',
    name: 'lock-outline',
    codepoint: 'F0341',
    deprecated: 'FALSE',
  },
  {
    id: '6921C166-7D1E-44E4-90B9-ADF6668DDFB9',
    name: 'lock-pattern',
    codepoint: 'F06EA',
    deprecated: 'FALSE',
  },
  {
    id: '2FFF3573-6379-4712-AC2D-0FDDFECE2103',
    name: 'lock-percent',
    codepoint: 'F1C12',
    deprecated: 'FALSE',
  },
  {
    id: 'D37BC87E-8B6E-4D69-A35C-2C2EE9B85243',
    name: 'lock-percent-open',
    codepoint: 'F1C13',
    deprecated: 'FALSE',
  },
  {
    id: 'AF474E3E-6D21-4C03-9D59-F784287C35AC',
    name: 'lock-percent-open-outline',
    codepoint: 'F1C14',
    deprecated: 'FALSE',
  },
  {
    id: '4F6E68BA-D1BA-4AD7-AA6D-A80E04CC2D6F',
    name: 'lock-percent-open-variant',
    codepoint: 'F1C15',
    deprecated: 'FALSE',
  },
  {
    id: '8E4A2141-8B43-4CC8-9DC4-C48251A0ECCF',
    name: 'lock-percent-open-variant-outline',
    codepoint: 'F1C16',
    deprecated: 'FALSE',
  },
  {
    id: 'E83F528B-42AE-49BE-9236-18C6212ACA9D',
    name: 'lock-percent-outline',
    codepoint: 'F1C17',
    deprecated: 'FALSE',
  },
  {
    id: 'BA4D2E1E-2BCB-4B17-AC02-52C8F34FAEAE',
    name: 'lock-plus',
    codepoint: 'F05FB',
    deprecated: 'FALSE',
  },
  {
    id: 'A4B343ED-8C07-4BD5-9F01-B360F971D23C',
    name: 'lock-plus-outline',
    codepoint: 'F16B2',
    deprecated: 'FALSE',
  },
  {
    id: '390C2479-98DD-46FB-AA39-CD816E7982C8',
    name: 'lock-question',
    codepoint: 'F08EF',
    deprecated: 'FALSE',
  },
  {
    id: '201B2126-8481-413A-884F-BE6C8C62D531',
    name: 'lock-remove',
    codepoint: 'F16B3',
    deprecated: 'FALSE',
  },
  {
    id: 'D5069461-8385-4424-8E19-CE2BEF2C1BAC',
    name: 'lock-remove-outline',
    codepoint: 'F16B4',
    deprecated: 'FALSE',
  },
  {
    id: '82312B48-E128-494E-9D84-DAACB41986F4',
    name: 'lock-reset',
    codepoint: 'F0773',
    deprecated: 'FALSE',
  },
  {
    id: '1D8C9A6B-26F4-45E1-B922-E0BAED0A5FBD',
    name: 'lock-smart',
    codepoint: 'F08B2',
    deprecated: 'FALSE',
  },
  {
    id: 'B8B0EAF4-369F-412A-A092-4AA85BBE77FD',
    name: 'locker',
    codepoint: 'F07D7',
    deprecated: 'FALSE',
  },
  {
    id: '4A5533A8-402C-45F2-9D7A-A8949C22C33B',
    name: 'locker-multiple',
    codepoint: 'F07D8',
    deprecated: 'FALSE',
  },
  {
    id: '63D8BFA5-7AE4-4002-A4C2-C464638C38FC',
    name: 'login',
    codepoint: 'F0342',
    deprecated: 'FALSE',
  },
  {
    id: 'B590AA80-D315-4344-8036-8FBC25C87D2C',
    name: 'login-variant',
    codepoint: 'F05FC',
    deprecated: 'FALSE',
  },
  {
    id: '47DF41F2-49C7-4081-8419-F49C7894E7F1',
    name: 'logout',
    codepoint: 'F0343',
    deprecated: 'FALSE',
  },
  {
    id: 'FD2A4CE0-AD1F-45D4-B487-BB6E57F2143F',
    name: 'logout-variant',
    codepoint: 'F05FD',
    deprecated: 'FALSE',
  },
  {
    id: 'F4604109-DEBB-424C-9972-9ACFF76BBEC7',
    name: 'longitude',
    codepoint: 'F0F5A',
    deprecated: 'FALSE',
  },
  {
    id: '3633613A-B8A7-49D0-8C1B-5415EC76F426',
    name: 'looks',
    codepoint: 'F0344',
    deprecated: 'FALSE',
  },
  {
    id: '8DD7B5D3-1361-460A-8029-6948337CC153',
    name: 'lotion',
    codepoint: 'F1582',
    deprecated: 'FALSE',
  },
  {
    id: 'DB23DCE8-81D6-449E-B6C2-E29804FFF3BD',
    name: 'lotion-outline',
    codepoint: 'F1583',
    deprecated: 'FALSE',
  },
  {
    id: '74C0DC3D-4DE3-4E67-9100-0B59F2B9A6B2',
    name: 'lotion-plus',
    codepoint: 'F1584',
    deprecated: 'FALSE',
  },
  {
    id: '85DF6891-1BE0-4D66-8728-553846F98B1F',
    name: 'lotion-plus-outline',
    codepoint: 'F1585',
    deprecated: 'FALSE',
  },
  {
    id: 'DB75FD27-01F6-4232-AA23-86C115751A0C',
    name: 'loupe',
    codepoint: 'F0345',
    deprecated: 'FALSE',
  },
  {
    id: '235EF258-40BB-47C3-9D5F-1497A7FD34D9',
    name: 'lumx',
    codepoint: 'F0346',
    deprecated: 'TRUE',
  },
  {
    id: '6E54F325-2998-4301-9062-8770A4868453',
    name: 'lungs',
    codepoint: 'F1084',
    deprecated: 'FALSE',
  },
  {
    id: '7AF70024-F9FA-4B50-BD29-3073B6E508B4',
    name: 'mace',
    codepoint: 'F1843',
    deprecated: 'FALSE',
  },
  {
    id: 'DC51467E-30C0-4144-9AE6-80762ACA972C',
    name: 'magazine-pistol',
    codepoint: 'F0324',
    deprecated: 'FALSE',
  },
  {
    id: '70793340-E8F2-43A0-8AB1-0DAB70CC8B9A',
    name: 'magazine-rifle',
    codepoint: 'F0323',
    deprecated: 'FALSE',
  },
  {
    id: 'A7255F1C-0207-4202-B1E5-11DC2BED7161',
    name: 'magic-staff',
    codepoint: 'F1844',
    deprecated: 'FALSE',
  },
  {
    id: '65B3A914-578A-468F-95C1-9C2B6D0C3A52',
    name: 'magnet',
    codepoint: 'F0347',
    deprecated: 'FALSE',
  },
  {
    id: '3A386A5A-35AC-4760-9384-33A9A43CF584',
    name: 'magnet-on',
    codepoint: 'F0348',
    deprecated: 'FALSE',
  },
  {
    id: 'C2889545-7F08-4AE1-9142-ECF9B79957E1',
    name: 'magnify',
    codepoint: 'F0349',
    deprecated: 'FALSE',
  },
  {
    id: 'C48FDEBE-952C-4B4A-9EB8-47A18186EB0B',
    name: 'magnify-close',
    codepoint: 'F0980',
    deprecated: 'FALSE',
  },
  {
    id: 'D6842910-49FD-4F74-8D67-86865549097A',
    name: 'magnify-expand',
    codepoint: 'F1874',
    deprecated: 'FALSE',
  },
  {
    id: '36D3966F-BFA2-407B-BF36-D21FBA930930',
    name: 'magnify-minus',
    codepoint: 'F034A',
    deprecated: 'FALSE',
  },
  {
    id: '616EA8BF-F84A-41BC-A933-5A4AF1E4E793',
    name: 'magnify-minus-cursor',
    codepoint: 'F0A62',
    deprecated: 'FALSE',
  },
  {
    id: '9E259697-7035-4E17-82D6-431C74215E67',
    name: 'magnify-minus-outline',
    codepoint: 'F06EC',
    deprecated: 'FALSE',
  },
  {
    id: '798F7C9D-2EC7-47EF-8729-845B4E2DDD16',
    name: 'magnify-plus',
    codepoint: 'F034B',
    deprecated: 'FALSE',
  },
  {
    id: '950E718A-089B-4266-AAFA-B9FA8BB0D3B3',
    name: 'magnify-plus-cursor',
    codepoint: 'F0A63',
    deprecated: 'FALSE',
  },
  {
    id: '521EC414-ECAD-435D-BAE3-4CF772659845',
    name: 'magnify-plus-outline',
    codepoint: 'F06ED',
    deprecated: 'FALSE',
  },
  {
    id: '845BBEA5-F20E-4E1B-AB42-FE7BF45FCDAB',
    name: 'magnify-remove-cursor',
    codepoint: 'F120C',
    deprecated: 'FALSE',
  },
  {
    id: '1465D0D4-32FE-4495-B137-CFE49A390474',
    name: 'magnify-remove-outline',
    codepoint: 'F120D',
    deprecated: 'FALSE',
  },
  {
    id: 'DF9ACF3F-5AF0-4F55-845C-569018942B95',
    name: 'magnify-scan',
    codepoint: 'F1276',
    deprecated: 'FALSE',
  },
  {
    id: '269E49C3-261D-42CD-8EFA-FD76C0BA7B7C',
    name: 'mail',
    codepoint: 'F0EBB',
    deprecated: 'FALSE',
  },
  {
    id: 'CCEA599C-8CED-4D49-83FB-7AC452AADFA0',
    name: 'mailbox',
    codepoint: 'F06EE',
    deprecated: 'FALSE',
  },
  {
    id: 'E1072975-827C-4320-A7D9-AEEADA1BCAD9',
    name: 'mailbox-open',
    codepoint: 'F0D88',
    deprecated: 'FALSE',
  },
  {
    id: 'B5D3F2A9-4CF7-4030-8909-482E23D3A8DC',
    name: 'mailbox-open-outline',
    codepoint: 'F0D89',
    deprecated: 'FALSE',
  },
  {
    id: '01EC4EC7-B90D-4E1E-B3CF-E9B2E9C16077',
    name: 'mailbox-open-up',
    codepoint: 'F0D8A',
    deprecated: 'FALSE',
  },
  {
    id: '793AC378-1C34-4882-B3A7-6ACA108ACE76',
    name: 'mailbox-open-up-outline',
    codepoint: 'F0D8B',
    deprecated: 'FALSE',
  },
  {
    id: '69A2883B-C904-4769-898E-8BAEB8F8309C',
    name: 'mailbox-outline',
    codepoint: 'F0D8C',
    deprecated: 'FALSE',
  },
  {
    id: 'C378F407-C2C4-48CD-9AA3-22B6C4C0E0AF',
    name: 'mailbox-up',
    codepoint: 'F0D8D',
    deprecated: 'FALSE',
  },
  {
    id: 'D9ECA2BA-AFCB-46C8-9EC4-3155063670CC',
    name: 'mailbox-up-outline',
    codepoint: 'F0D8E',
    deprecated: 'FALSE',
  },
  {
    id: '75EE1BFB-90E7-4500-A7E1-72E898059E16',
    name: 'manjaro',
    codepoint: 'F160A',
    deprecated: 'TRUE',
  },
  {
    id: '99738FF8-01AE-420D-8481-8B9E1402255B',
    name: 'map',
    codepoint: 'F034D',
    deprecated: 'FALSE',
  },
  {
    id: '39D94D7A-D161-409F-8FFC-889474A4DCE9',
    name: 'map-check',
    codepoint: 'F0EBC',
    deprecated: 'FALSE',
  },
  {
    id: '8EF339C7-1106-40FC-B2CC-3387F915824E',
    name: 'map-check-outline',
    codepoint: 'F0EBD',
    deprecated: 'FALSE',
  },
  {
    id: 'A3A8668B-BEF0-48CC-AEB1-ACC765D10CC0',
    name: 'map-clock',
    codepoint: 'F0D1E',
    deprecated: 'FALSE',
  },
  {
    id: 'EEB984B2-6639-452D-AD56-06A8EC862EE4',
    name: 'map-clock-outline',
    codepoint: 'F0D1F',
    deprecated: 'FALSE',
  },
  {
    id: '69651FB0-089C-4D57-8465-46716D86DBCA',
    name: 'map-legend',
    codepoint: 'F0A01',
    deprecated: 'FALSE',
  },
  {
    id: '379B9D93-434B-46E7-9ABC-CAFAB694B209',
    name: 'map-marker',
    codepoint: 'F034E',
    deprecated: 'FALSE',
  },
  {
    id: '518FF04A-58EC-4917-BF7F-BBAFDD9B00DD',
    name: 'map-marker-account',
    codepoint: 'F18E3',
    deprecated: 'FALSE',
  },
  {
    id: '9EE1A46D-F991-45D7-A170-2DE8AED0D1EA',
    name: 'map-marker-account-outline',
    codepoint: 'F18E4',
    deprecated: 'FALSE',
  },
  {
    id: 'DE87F14C-101D-47F2-87C5-118BE76F7C58',
    name: 'map-marker-alert',
    codepoint: 'F0F05',
    deprecated: 'FALSE',
  },
  {
    id: '65B4F317-EB12-4BD4-8E9B-C6D3A153B868',
    name: 'map-marker-alert-outline',
    codepoint: 'F0F06',
    deprecated: 'FALSE',
  },
  {
    id: 'FA10B579-AC1E-48F7-BF8C-AB7F1380F3F9',
    name: 'map-marker-check',
    codepoint: 'F0C95',
    deprecated: 'FALSE',
  },
  {
    id: '76C82A3D-F79E-46D9-8234-456F39A35567',
    name: 'map-marker-check-outline',
    codepoint: 'F12FB',
    deprecated: 'FALSE',
  },
  {
    id: '03F79839-314C-4F29-B3E3-43B97D7F7FA6',
    name: 'map-marker-circle',
    codepoint: 'F034F',
    deprecated: 'FALSE',
  },
  {
    id: 'B2FF0F5A-4B2C-480C-9022-D84AD78E72ED',
    name: 'map-marker-distance',
    codepoint: 'F08F0',
    deprecated: 'FALSE',
  },
  {
    id: '6E4368BF-7150-40D2-861B-375421A7774B',
    name: 'map-marker-down',
    codepoint: 'F1102',
    deprecated: 'FALSE',
  },
  {
    id: '5FFC5505-B029-4487-A68E-EDE4B708E5B9',
    name: 'map-marker-left',
    codepoint: 'F12DB',
    deprecated: 'FALSE',
  },
  {
    id: '0283CCF3-BC73-4642-92FA-38E62D635368',
    name: 'map-marker-left-outline',
    codepoint: 'F12DD',
    deprecated: 'FALSE',
  },
  {
    id: '34DD1168-3875-4F77-9C0B-5BCFFB80FBBD',
    name: 'map-marker-minus',
    codepoint: 'F0650',
    deprecated: 'FALSE',
  },
  {
    id: '21A7EBBF-D884-4A4E-B722-01600B89FFB4',
    name: 'map-marker-minus-outline',
    codepoint: 'F12F9',
    deprecated: 'FALSE',
  },
  {
    id: '6283577D-6B31-4297-8747-4F466580E79A',
    name: 'map-marker-multiple',
    codepoint: 'F0350',
    deprecated: 'FALSE',
  },
  {
    id: '360866E5-D407-4E54-9470-7ECF6759F941',
    name: 'map-marker-multiple-outline',
    codepoint: 'F1277',
    deprecated: 'FALSE',
  },
  {
    id: '92410469-09D9-4C29-AC79-CD0DF882D05F',
    name: 'map-marker-off',
    codepoint: 'F0351',
    deprecated: 'FALSE',
  },
  {
    id: '48218DC3-F2A6-4C4B-9B5E-0648815B8864',
    name: 'map-marker-off-outline',
    codepoint: 'F12FD',
    deprecated: 'FALSE',
  },
  {
    id: 'DE8F4623-A6D4-4DD4-B61A-5936A0538FD2',
    name: 'map-marker-outline',
    codepoint: 'F07D9',
    deprecated: 'FALSE',
  },
  {
    id: 'A20F1915-26FB-41BD-A407-B73A90936E76',
    name: 'map-marker-path',
    codepoint: 'F0D20',
    deprecated: 'FALSE',
  },
  {
    id: '40CBD1A1-11E1-44A6-8627-6F7C658F5E0F',
    name: 'map-marker-plus',
    codepoint: 'F0651',
    deprecated: 'FALSE',
  },
  {
    id: 'C1F80E9D-2408-46FC-9E92-AE61A8D5C650',
    name: 'map-marker-plus-outline',
    codepoint: 'F12F8',
    deprecated: 'FALSE',
  },
  {
    id: 'C9930FE9-E831-4194-A4FA-905CE141CFEA',
    name: 'map-marker-question',
    codepoint: 'F0F07',
    deprecated: 'FALSE',
  },
  {
    id: '0F3F436D-95BF-4578-A209-CF8B26DEDA15',
    name: 'map-marker-question-outline',
    codepoint: 'F0F08',
    deprecated: 'FALSE',
  },
  {
    id: '8F346B27-607A-4C98-B83E-C32E921D0468',
    name: 'map-marker-radius',
    codepoint: 'F0352',
    deprecated: 'FALSE',
  },
  {
    id: '865AB612-D546-431D-85A8-52C305B263F8',
    name: 'map-marker-radius-outline',
    codepoint: 'F12FC',
    deprecated: 'FALSE',
  },
  {
    id: '8BC7ABEC-FE5F-4C2F-889C-8C8804DD12E4',
    name: 'map-marker-remove',
    codepoint: 'F0F09',
    deprecated: 'FALSE',
  },
  {
    id: '75FDE93C-EB66-4824-9F96-1B4212BDA785',
    name: 'map-marker-remove-outline',
    codepoint: 'F12FA',
    deprecated: 'FALSE',
  },
  {
    id: 'D19361A0-41AB-4E11-B4D4-AE50D42B3B2C',
    name: 'map-marker-remove-variant',
    codepoint: 'F0F0A',
    deprecated: 'FALSE',
  },
  {
    id: '445C4B6D-5A56-42F3-845F-92C22A11BB64',
    name: 'map-marker-right',
    codepoint: 'F12DC',
    deprecated: 'FALSE',
  },
  {
    id: '9456009F-0387-4A76-8B0F-22CCBBA53071',
    name: 'map-marker-right-outline',
    codepoint: 'F12DE',
    deprecated: 'FALSE',
  },
  {
    id: 'B4FB6A62-C598-4E96-8E09-C6A2145EE24C',
    name: 'map-marker-star',
    codepoint: 'F1608',
    deprecated: 'FALSE',
  },
  {
    id: '9B256D01-4CB0-4736-BBB7-6D44417DD02B',
    name: 'map-marker-star-outline',
    codepoint: 'F1609',
    deprecated: 'FALSE',
  },
  {
    id: '0C1B9015-C0F4-4710-87D2-BA7B710943F2',
    name: 'map-marker-up',
    codepoint: 'F1103',
    deprecated: 'FALSE',
  },
  {
    id: '3C2F6429-F2F9-49A9-AF23-DD33413D2619',
    name: 'map-minus',
    codepoint: 'F0981',
    deprecated: 'FALSE',
  },
  {
    id: '164AE88B-F990-471C-8663-EFDC05BA14F0',
    name: 'map-outline',
    codepoint: 'F0982',
    deprecated: 'FALSE',
  },
  {
    id: '3D7A000E-3E94-4B9B-8CAB-2F6923D425B7',
    name: 'map-plus',
    codepoint: 'F0983',
    deprecated: 'FALSE',
  },
  {
    id: '6FB911AA-DE27-441D-8C62-CD97ECB99DA7',
    name: 'map-search',
    codepoint: 'F0984',
    deprecated: 'FALSE',
  },
  {
    id: '4EDC2B6E-3E09-41BF-B08C-EB75CEBFFB96',
    name: 'map-search-outline',
    codepoint: 'F0985',
    deprecated: 'FALSE',
  },
  {
    id: '061C11C7-55DC-4F79-989C-81756790342A',
    name: 'mapbox',
    codepoint: 'F0BAA',
    deprecated: 'TRUE',
  },
  {
    id: '4A7710D4-41B8-4D98-A5B9-591943C3B3EF',
    name: 'margin',
    codepoint: 'F0353',
    deprecated: 'FALSE',
  },
  {
    id: '71E6D8F0-A3D2-43CB-8940-16AA4E1D9825',
    name: 'marker',
    codepoint: 'F0652',
    deprecated: 'FALSE',
  },
  {
    id: 'CFB791C3-6476-418F-80D4-7B8C5A6D1350',
    name: 'marker-cancel',
    codepoint: 'F0DD9',
    deprecated: 'FALSE',
  },
  {
    id: '74C5E15E-D227-4272-94E6-EDFDD0F66C9A',
    name: 'marker-check',
    codepoint: 'F0355',
    deprecated: 'FALSE',
  },
  {
    id: '7DA03280-B018-4813-973A-9CBC0F6C462B',
    name: 'mastodon',
    codepoint: 'F0AD1',
    deprecated: 'TRUE',
  },
  {
    id: '224604D9-8F3D-454A-B11F-D4C00231B15B',
    name: 'material-design',
    codepoint: 'F0986',
    deprecated: 'TRUE',
  },
  {
    id: '878C7F4D-DAD0-4334-A3AA-695FD3AD5391',
    name: 'material-ui',
    codepoint: 'F0357',
    deprecated: 'TRUE',
  },
  {
    id: '10E7FA7C-8451-44B9-979F-1916AAC7FE28',
    name: 'math-compass',
    codepoint: 'F0358',
    deprecated: 'FALSE',
  },
  {
    id: '6C00C0F3-F537-4591-B7BA-752A1CCD7E38',
    name: 'math-cos',
    codepoint: 'F0C96',
    deprecated: 'FALSE',
  },
  {
    id: 'E911AE3C-8505-42CD-889B-0EE3BEDC3B0D',
    name: 'math-integral',
    codepoint: 'F0FC8',
    deprecated: 'FALSE',
  },
  {
    id: '0C6E8DE8-731B-451C-809A-22BFF1DADE36',
    name: 'math-integral-box',
    codepoint: 'F0FC9',
    deprecated: 'FALSE',
  },
  {
    id: 'DD9D199B-D026-4771-8028-A813A3893CDE',
    name: 'math-log',
    codepoint: 'F1085',
    deprecated: 'FALSE',
  },
  {
    id: '6C9569E6-55F1-48AF-A11C-2284315D0CE6',
    name: 'math-norm',
    codepoint: 'F0FCA',
    deprecated: 'FALSE',
  },
  {
    id: '4FBD0287-AD44-452B-A97A-E5FDD3542A1F',
    name: 'math-norm-box',
    codepoint: 'F0FCB',
    deprecated: 'FALSE',
  },
  {
    id: 'CB473E87-BD2F-4416-A36E-6C4C12194D14',
    name: 'math-sin',
    codepoint: 'F0C97',
    deprecated: 'FALSE',
  },
  {
    id: '47194CCD-AF78-430F-AD95-4D564D6E1D18',
    name: 'math-tan',
    codepoint: 'F0C98',
    deprecated: 'FALSE',
  },
  {
    id: '5D5ED3F2-71BD-44B6-B4BB-61D73211335F',
    name: 'matrix',
    codepoint: 'F0628',
    deprecated: 'FALSE',
  },
  {
    id: '626FA95C-E655-44A6-BA6A-4B60CED44B6D',
    name: 'medal',
    codepoint: 'F0987',
    deprecated: 'FALSE',
  },
  {
    id: '22F9B575-7D95-46A3-BA55-625B12E75AC2',
    name: 'medal-outline',
    codepoint: 'F1326',
    deprecated: 'FALSE',
  },
  {
    id: '7F6DF58A-C9A3-4CE7-9664-28C04218C628',
    name: 'medical-bag',
    codepoint: 'F06EF',
    deprecated: 'FALSE',
  },
  {
    id: 'EC9D77A4-739A-4641-BE6C-86050DD25FFE',
    name: 'medical-cotton-swab',
    codepoint: 'F1AB8',
    deprecated: 'FALSE',
  },
  {
    id: '57211BE3-0055-4D43-B05C-3CD0FE10322C',
    name: 'medication',
    codepoint: 'F1B14',
    deprecated: 'FALSE',
  },
  {
    id: '8C286097-F93C-490C-A01F-BB1445A6953C',
    name: 'medication-outline',
    codepoint: 'F1B15',
    deprecated: 'FALSE',
  },
  {
    id: 'B68AA118-B8B5-4A2A-BCA2-A12C8C119319',
    name: 'meditation',
    codepoint: 'F117B',
    deprecated: 'FALSE',
  },
  {
    id: 'E404434E-17DB-4E90-9D97-5355258E1FCF',
    name: 'memory',
    codepoint: 'F035B',
    deprecated: 'FALSE',
  },
  {
    id: '7D2EBF4F-6B48-4DAE-B5B5-005B4FC7C9BC',
    name: 'menorah',
    codepoint: 'F17D4',
    deprecated: 'FALSE',
  },
  {
    id: '05D5D57F-2565-4D15-8241-F778B49430CD',
    name: 'menorah-fire',
    codepoint: 'F17D5',
    deprecated: 'FALSE',
  },
  {
    id: '0BD2CD08-CCFB-4EC3-B96D-08B0B8230A91',
    name: 'menu',
    codepoint: 'F035C',
    deprecated: 'FALSE',
  },
  {
    id: '624A47B1-947E-45AD-A34F-D5DCC1143C5A',
    name: 'menu-down',
    codepoint: 'F035D',
    deprecated: 'FALSE',
  },
  {
    id: 'EE6F8AAE-7856-4E4C-AC01-3589A2C66D98',
    name: 'menu-down-outline',
    codepoint: 'F06B6',
    deprecated: 'FALSE',
  },
  {
    id: '91F83E23-FD44-4277-BD91-037B3D7EB72E',
    name: 'menu-left',
    codepoint: 'F035E',
    deprecated: 'FALSE',
  },
  {
    id: 'BC54AA3D-A450-4079-B40C-457FAE2211D5',
    name: 'menu-left-outline',
    codepoint: 'F0A02',
    deprecated: 'FALSE',
  },
  {
    id: 'AEB414A7-E7A2-46D6-BAA3-CAFA4E06DA38',
    name: 'menu-open',
    codepoint: 'F0BAB',
    deprecated: 'FALSE',
  },
  {
    id: '51157881-5A20-4D7D-8E5F-FB0E815C9B3D',
    name: 'menu-right',
    codepoint: 'F035F',
    deprecated: 'FALSE',
  },
  {
    id: 'DFE8FD81-B967-4E07-A1C9-44A608613E26',
    name: 'menu-right-outline',
    codepoint: 'F0A03',
    deprecated: 'FALSE',
  },
  {
    id: '6B937FF1-FF63-4829-8BAE-D5E4D1EB64A0',
    name: 'menu-swap',
    codepoint: 'F0A64',
    deprecated: 'FALSE',
  },
  {
    id: 'AAADBDE7-CC0D-4F69-A795-B38C0B00DC62',
    name: 'menu-swap-outline',
    codepoint: 'F0A65',
    deprecated: 'FALSE',
  },
  {
    id: 'A526C6AC-3E29-49D1-91FA-07F95B470626',
    name: 'menu-up',
    codepoint: 'F0360',
    deprecated: 'FALSE',
  },
  {
    id: '33B1FB6B-D4F4-4B76-820C-4A2B075DB1B3',
    name: 'menu-up-outline',
    codepoint: 'F06B7',
    deprecated: 'FALSE',
  },
  {
    id: '0D0E7E15-9615-4A66-8933-8BC98675AE3D',
    name: 'merge',
    codepoint: 'F0F5C',
    deprecated: 'FALSE',
  },
  {
    id: '99DA4752-8C79-44D8-8286-6EBD696F0B45',
    name: 'message',
    codepoint: 'F0361',
    deprecated: 'FALSE',
  },
  {
    id: '213A797A-41C0-4804-A5B1-A841898CCC11',
    name: 'message-alert',
    codepoint: 'F0362',
    deprecated: 'FALSE',
  },
  {
    id: '3487FE3E-2B9B-4C69-B64C-B3E699E9EC8C',
    name: 'message-alert-outline',
    codepoint: 'F0A04',
    deprecated: 'FALSE',
  },
  {
    id: 'F359CA31-2575-4648-B1E1-466A757F2184',
    name: 'message-arrow-left',
    codepoint: 'F12F2',
    deprecated: 'FALSE',
  },
  {
    id: 'EAF47F59-C768-4B46-841F-1D8C216D15F2',
    name: 'message-arrow-left-outline',
    codepoint: 'F12F3',
    deprecated: 'FALSE',
  },
  {
    id: '3292CBC5-7E1D-405F-A8CD-BAB528B88D45',
    name: 'message-arrow-right',
    codepoint: 'F12F4',
    deprecated: 'FALSE',
  },
  {
    id: 'BEDCDC33-9EAE-46D2-B380-80A6B47B400C',
    name: 'message-arrow-right-outline',
    codepoint: 'F12F5',
    deprecated: 'FALSE',
  },
  {
    id: 'B34D5D57-7A9B-43CD-B58F-D18CD484DCC9',
    name: 'message-badge',
    codepoint: 'F1941',
    deprecated: 'FALSE',
  },
  {
    id: 'E3DAA0CA-4F39-49E0-9D3D-5306D764167D',
    name: 'message-badge-outline',
    codepoint: 'F1942',
    deprecated: 'FALSE',
  },
  {
    id: '80D7EBEA-4C3B-43B9-8181-BEEE728D5D90',
    name: 'message-bookmark',
    codepoint: 'F15AC',
    deprecated: 'FALSE',
  },
  {
    id: '94793E49-C5C4-47A8-87FD-215A923ACA2A',
    name: 'message-bookmark-outline',
    codepoint: 'F15AD',
    deprecated: 'FALSE',
  },
  {
    id: '1EC9427A-2A02-4F74-9C1B-C4EC5E62B793',
    name: 'message-bulleted',
    codepoint: 'F06A2',
    deprecated: 'FALSE',
  },
  {
    id: '5AAB267C-9A9C-4966-862D-A2AC910494E5',
    name: 'message-bulleted-off',
    codepoint: 'F06A3',
    deprecated: 'FALSE',
  },
  {
    id: '84A64220-7304-4667-AAA4-1A347B997199',
    name: 'message-check',
    codepoint: 'F1B8A',
    deprecated: 'FALSE',
  },
  {
    id: '1F5EB171-8FDB-4C8A-B748-6B4EAF022243',
    name: 'message-check-outline',
    codepoint: 'F1B8B',
    deprecated: 'FALSE',
  },
  {
    id: 'F0F27B00-3C37-4AC0-B8D7-DB2554894079',
    name: 'message-cog',
    codepoint: 'F06F1',
    deprecated: 'FALSE',
  },
  {
    id: 'B4FCC7EE-5464-4713-A4F4-8C0CA0E9472E',
    name: 'message-cog-outline',
    codepoint: 'F1172',
    deprecated: 'FALSE',
  },
  {
    id: '6434A4B3-F803-4C8B-A03C-BE39E947166B',
    name: 'message-draw',
    codepoint: 'F0363',
    deprecated: 'FALSE',
  },
  {
    id: '1FAB8199-286D-4D00-9AA5-7D4D14AC1B74',
    name: 'message-fast',
    codepoint: 'F19CC',
    deprecated: 'FALSE',
  },
  {
    id: '13E100B1-AEB4-4398-9A8D-404F8C765642',
    name: 'message-fast-outline',
    codepoint: 'F19CD',
    deprecated: 'FALSE',
  },
  {
    id: 'A9181976-10D1-4CB7-993E-AE8B7DEDEC64',
    name: 'message-flash',
    codepoint: 'F15A9',
    deprecated: 'FALSE',
  },
  {
    id: 'DA7B2430-9A55-42D5-AE19-92E108303E79',
    name: 'message-flash-outline',
    codepoint: 'F15AA',
    deprecated: 'FALSE',
  },
  {
    id: 'C4C9B194-78C3-47FB-ABF6-5129230527AC',
    name: 'message-image',
    codepoint: 'F0364',
    deprecated: 'FALSE',
  },
  {
    id: '9C0BC648-202E-476E-A034-48C03F7554BF',
    name: 'message-image-outline',
    codepoint: 'F116C',
    deprecated: 'FALSE',
  },
  {
    id: '953DF020-3B6E-44ED-B858-A220EC7BE82A',
    name: 'message-lock',
    codepoint: 'F0FCC',
    deprecated: 'FALSE',
  },
  {
    id: 'D7D6AAFB-1292-4EA1-A067-728532693834',
    name: 'message-lock-outline',
    codepoint: 'F116D',
    deprecated: 'FALSE',
  },
  {
    id: 'CF8F9CE5-0B38-4412-A3DD-F9E333C23EF6',
    name: 'message-minus',
    codepoint: 'F116E',
    deprecated: 'FALSE',
  },
  {
    id: 'B5CAE2E8-8D8A-4E7C-AAD3-F5EFDE92A8F7',
    name: 'message-minus-outline',
    codepoint: 'F116F',
    deprecated: 'FALSE',
  },
  {
    id: '1F9E05D2-75B6-4039-B0A8-9EF2B647882E',
    name: 'message-off',
    codepoint: 'F164D',
    deprecated: 'FALSE',
  },
  {
    id: '2B210400-A3CE-4EE4-8A9B-A88ACDF47061',
    name: 'message-off-outline',
    codepoint: 'F164E',
    deprecated: 'FALSE',
  },
  {
    id: '2AE3707D-5DE3-41E6-968B-A56D3D9ADD40',
    name: 'message-outline',
    codepoint: 'F0365',
    deprecated: 'FALSE',
  },
  {
    id: 'C0B4CC94-B6B5-462F-93C2-98F07093614C',
    name: 'message-plus',
    codepoint: 'F0653',
    deprecated: 'FALSE',
  },
  {
    id: '47A1A8D9-33C6-4EF4-9F8B-C327A7F45F65',
    name: 'message-plus-outline',
    codepoint: 'F10BB',
    deprecated: 'FALSE',
  },
  {
    id: '602EE454-C61C-4608-919E-FCCF3B58DE65',
    name: 'message-processing',
    codepoint: 'F0366',
    deprecated: 'FALSE',
  },
  {
    id: '21EDB8E6-9829-4028-9A40-F03BF23C14DA',
    name: 'message-processing-outline',
    codepoint: 'F1170',
    deprecated: 'FALSE',
  },
  {
    id: '088D1765-3A4B-4BCB-A17B-B07970B3DDAA',
    name: 'message-question',
    codepoint: 'F173A',
    deprecated: 'FALSE',
  },
  {
    id: '32AA3ACF-FADE-4ECB-8A16-DECB4CB9ECB9',
    name: 'message-question-outline',
    codepoint: 'F173B',
    deprecated: 'FALSE',
  },
  {
    id: '6657779A-7238-4686-B3D5-1244862D7B2C',
    name: 'message-reply',
    codepoint: 'F0367',
    deprecated: 'FALSE',
  },
  {
    id: '9B0263DD-74DB-4AC6-BF1C-0C7C0170C845',
    name: 'message-reply-outline',
    codepoint: 'F173D',
    deprecated: 'FALSE',
  },
  {
    id: 'FF34C5A8-4433-4B47-A9E5-EB836CFFCEC9',
    name: 'message-reply-text',
    codepoint: 'F0368',
    deprecated: 'FALSE',
  },
  {
    id: '56D756E1-57CB-4EA5-9AC3-409D0046C643',
    name: 'message-reply-text-outline',
    codepoint: 'F173E',
    deprecated: 'FALSE',
  },
  {
    id: '4B15BF16-CD96-4427-9EA4-5A8B09F3A2B6',
    name: 'message-settings',
    codepoint: 'F06F0',
    deprecated: 'FALSE',
  },
  {
    id: '28C31415-ECC5-4192-A04A-C3C1F810019B',
    name: 'message-settings-outline',
    codepoint: 'F1171',
    deprecated: 'FALSE',
  },
  {
    id: 'FF8D4F38-8A77-4FDD-8C6D-D17F6A44516D',
    name: 'message-star',
    codepoint: 'F069A',
    deprecated: 'FALSE',
  },
  {
    id: 'F2B4075E-3226-40CB-9165-2C66A4BB4BBD',
    name: 'message-star-outline',
    codepoint: 'F1250',
    deprecated: 'FALSE',
  },
  {
    id: 'D2AFF074-2CE4-442D-AF1A-64B168BC622A',
    name: 'message-text',
    codepoint: 'F0369',
    deprecated: 'FALSE',
  },
  {
    id: 'BF83F7B1-BA68-4443-9FD1-FB0A9C8D9D60',
    name: 'message-text-clock',
    codepoint: 'F1173',
    deprecated: 'FALSE',
  },
  {
    id: '461285B8-FAB1-46BA-95C5-611476333EE4',
    name: 'message-text-clock-outline',
    codepoint: 'F1174',
    deprecated: 'FALSE',
  },
  {
    id: '9CD08DBB-70E0-41B6-8BAA-79B9D4FE11AA',
    name: 'message-text-fast',
    codepoint: 'F19CE',
    deprecated: 'FALSE',
  },
  {
    id: '3EA07D0B-1C28-4140-9CF3-60E741D34A65',
    name: 'message-text-fast-outline',
    codepoint: 'F19CF',
    deprecated: 'FALSE',
  },
  {
    id: '362401F1-947F-45FD-8047-F515CB2F7C44',
    name: 'message-text-lock',
    codepoint: 'F0FCD',
    deprecated: 'FALSE',
  },
  {
    id: '223FE0D4-F0C4-426A-8784-72F28455AB67',
    name: 'message-text-lock-outline',
    codepoint: 'F1175',
    deprecated: 'FALSE',
  },
  {
    id: 'E8C7D2D2-5B78-40E9-A3E6-51B9C31A43F8',
    name: 'message-text-outline',
    codepoint: 'F036A',
    deprecated: 'FALSE',
  },
  {
    id: '7AEB07C3-0EFC-4D74-BB5B-2EB1288DC27D',
    name: 'message-video',
    codepoint: 'F036B',
    deprecated: 'FALSE',
  },
  {
    id: '78644A46-55EA-4208-B3DC-89C79B5F96A5',
    name: 'meteor',
    codepoint: 'F0629',
    deprecated: 'TRUE',
  },
  {
    id: 'A42A0B48-3570-4056-9745-6FEC4CE8EB8E',
    name: 'meter-electric',
    codepoint: 'F1A57',
    deprecated: 'FALSE',
  },
  {
    id: '463AE2E1-1BA3-444B-9182-D252575B2C8E',
    name: 'meter-electric-outline',
    codepoint: 'F1A58',
    deprecated: 'FALSE',
  },
  {
    id: '44BDA87A-AB07-4DC1-89FB-0D1F55B6622F',
    name: 'meter-gas',
    codepoint: 'F1A59',
    deprecated: 'FALSE',
  },
  {
    id: '7960EDB3-0C0D-47C8-B837-C606FEA6B524',
    name: 'meter-gas-outline',
    codepoint: 'F1A5A',
    deprecated: 'FALSE',
  },
  {
    id: '13256CBF-E284-4229-AAB9-5612FBADD060',
    name: 'metronome',
    codepoint: 'F07DA',
    deprecated: 'FALSE',
  },
  {
    id: '43A2B10E-F7DF-4181-B607-EC2039AFFA0D',
    name: 'metronome-tick',
    codepoint: 'F07DB',
    deprecated: 'FALSE',
  },
  {
    id: '6A3136A3-62AD-4DE6-A783-D9D69AAD8919',
    name: 'micro-sd',
    codepoint: 'F07DC',
    deprecated: 'FALSE',
  },
  {
    id: '18C99959-2A0E-4095-BAEA-F5B266377648',
    name: 'microphone',
    codepoint: 'F036C',
    deprecated: 'FALSE',
  },
  {
    id: '5B1FA77D-0384-4931-AFD8-C4FF5D73D372',
    name: 'microphone-message',
    codepoint: 'F050A',
    deprecated: 'FALSE',
  },
  {
    id: '1AE8F7A9-5454-44D2-B16D-1D383E33978D',
    name: 'microphone-message-off',
    codepoint: 'F050B',
    deprecated: 'FALSE',
  },
  {
    id: 'FFAA9F2E-96F9-43A3-BC4E-F7562703E6A1',
    name: 'microphone-minus',
    codepoint: 'F08B3',
    deprecated: 'FALSE',
  },
  {
    id: '90AD0141-2243-49F0-8B84-FF909244D237',
    name: 'microphone-off',
    codepoint: 'F036D',
    deprecated: 'FALSE',
  },
  {
    id: 'E152DB47-F7EE-42A5-A01B-DC5E54A525D8',
    name: 'microphone-outline',
    codepoint: 'F036E',
    deprecated: 'FALSE',
  },
  {
    id: '61F84878-9DAD-45D9-8A79-65143EA32F37',
    name: 'microphone-plus',
    codepoint: 'F08B4',
    deprecated: 'FALSE',
  },
  {
    id: 'C209FA9B-CE60-4CC6-853D-0180D05CDF93',
    name: 'microphone-question',
    codepoint: 'F1989',
    deprecated: 'FALSE',
  },
  {
    id: '89B1E3B0-1970-4ACC-B2DC-B45DFC33DE4B',
    name: 'microphone-question-outline',
    codepoint: 'F198A',
    deprecated: 'FALSE',
  },
  {
    id: 'E8F70177-01CF-4C20-9956-DCA7B331FED6',
    name: 'microphone-settings',
    codepoint: 'F036F',
    deprecated: 'FALSE',
  },
  {
    id: '5824EE2F-01BA-42E7-A943-B0D1C569B2C5',
    name: 'microphone-variant',
    codepoint: 'F0370',
    deprecated: 'FALSE',
  },
  {
    id: 'B0811263-2D40-44D9-945D-13393A170311',
    name: 'microphone-variant-off',
    codepoint: 'F0371',
    deprecated: 'FALSE',
  },
  {
    id: 'CFCFD9FE-AFF3-4D4F-A7C6-A40E0886B064',
    name: 'microscope',
    codepoint: 'F0654',
    deprecated: 'FALSE',
  },
  {
    id: '4636AAFF-A0E5-494C-8630-31142B4A2F48',
    name: 'microsoft',
    codepoint: 'F0372',
    deprecated: 'TRUE',
  },
  {
    id: '94560EAB-E027-4462-BBD8-C6921D90CC07',
    name: 'microsoft-access',
    codepoint: 'F138E',
    deprecated: 'TRUE',
  },
  {
    id: '7701BECC-343D-4BE8-B33A-B88ABACA8F26',
    name: 'microsoft-azure',
    codepoint: 'F0805',
    deprecated: 'TRUE',
  },
  {
    id: '67025D74-AB54-43B5-82EE-085CCDA019E6',
    name: 'microsoft-azure-devops',
    codepoint: 'F0FD5',
    deprecated: 'TRUE',
  },
  {
    id: 'CB038F2A-85DE-4F59-B694-D4805D66322B',
    name: 'microsoft-bing',
    codepoint: 'F00A4',
    deprecated: 'TRUE',
  },
  {
    id: '9846C329-0B20-40A4-9362-D350C063F53C',
    name: 'microsoft-dynamics-365',
    codepoint: 'F0988',
    deprecated: 'TRUE',
  },
  {
    id: 'C2D33448-5C6E-41D9-89B4-65184FF5EE15',
    name: 'microsoft-edge',
    codepoint: 'F01E9',
    deprecated: 'TRUE',
  },
  {
    id: 'ACD47746-F44B-496D-990B-58FC729F372E',
    name: 'microsoft-excel',
    codepoint: 'F138F',
    deprecated: 'TRUE',
  },
  {
    id: '255BB365-7B2F-46C7-B85E-EFDAD527BB54',
    name: 'microsoft-internet-explorer',
    codepoint: 'F0300',
    deprecated: 'TRUE',
  },
  {
    id: '7E2456A3-891B-43F4-B233-AA0D890815CF',
    name: 'microsoft-office',
    codepoint: 'F03C6',
    deprecated: 'TRUE',
  },
  {
    id: '455893F6-D0C4-4257-ABE4-85B1D68F60FF',
    name: 'microsoft-onedrive',
    codepoint: 'F03CA',
    deprecated: 'TRUE',
  },
  {
    id: 'E95EC3DF-2A48-4167-9F22-F4E735D4F298',
    name: 'microsoft-onenote',
    codepoint: 'F0747',
    deprecated: 'TRUE',
  },
  {
    id: '0AE81D55-4520-4975-B406-145C74ECF033',
    name: 'microsoft-outlook',
    codepoint: 'F0D22',
    deprecated: 'TRUE',
  },
  {
    id: '6EA8C442-3C67-4AC3-89F9-C628942CA8E0',
    name: 'microsoft-powerpoint',
    codepoint: 'F1390',
    deprecated: 'TRUE',
  },
  {
    id: 'D4F99553-AAF4-406A-8C02-71850F75E07E',
    name: 'microsoft-sharepoint',
    codepoint: 'F1391',
    deprecated: 'TRUE',
  },
  {
    id: '390FC04F-F2A9-44F5-9808-6370A647BAF0',
    name: 'microsoft-teams',
    codepoint: 'F02BB',
    deprecated: 'TRUE',
  },
  {
    id: 'E009DA08-136C-465C-8481-308E99BCA3BF',
    name: 'microsoft-visual-studio',
    codepoint: 'F0610',
    deprecated: 'TRUE',
  },
  {
    id: '6D1D5836-B731-41C4-A829-86E5741A0784',
    name: 'microsoft-visual-studio-code',
    codepoint: 'F0A1E',
    deprecated: 'TRUE',
  },
  {
    id: '6E06B49E-5127-4D5A-9C8B-F58BA8943BC4',
    name: 'microsoft-windows',
    codepoint: 'F05B3',
    deprecated: 'TRUE',
  },
  {
    id: 'AD21ACB2-11A5-4C30-907B-FFF1A5904738',
    name: 'microsoft-windows-classic',
    codepoint: 'F0A21',
    deprecated: 'TRUE',
  },
  {
    id: 'BBB336AA-A225-44EE-B8AA-14AB109C505D',
    name: 'microsoft-word',
    codepoint: 'F1392',
    deprecated: 'TRUE',
  },
  {
    id: 'B7B4ECD9-2895-4E36-88A9-5F3AC5D6C264',
    name: 'microsoft-xbox',
    codepoint: 'F05B9',
    deprecated: 'TRUE',
  },
  {
    id: '74BB58AC-EF8B-447C-A15D-C444F1F67D93',
    name: 'microsoft-xbox-controller',
    codepoint: 'F05BA',
    deprecated: 'FALSE',
  },
  {
    id: '57F11EDC-2759-4D62-BDD2-C7C3F5199623',
    name: 'microsoft-xbox-controller-battery-alert',
    codepoint: 'F074B',
    deprecated: 'FALSE',
  },
  {
    id: '738FB781-E590-444B-85B8-1773DDC814E9',
    name: 'microsoft-xbox-controller-battery-charging',
    codepoint: 'F0A22',
    deprecated: 'FALSE',
  },
  {
    id: '51BB7455-5893-4F47-92D1-B3FD408C2ADC',
    name: 'microsoft-xbox-controller-battery-empty',
    codepoint: 'F074C',
    deprecated: 'FALSE',
  },
  {
    id: 'CC73B4E7-6B14-459D-AED5-35E50655C05C',
    name: 'microsoft-xbox-controller-battery-full',
    codepoint: 'F074D',
    deprecated: 'FALSE',
  },
  {
    id: 'B65873D1-4808-4C3C-8AD6-A242D61B1985',
    name: 'microsoft-xbox-controller-battery-low',
    codepoint: 'F074E',
    deprecated: 'FALSE',
  },
  {
    id: '2B434E26-4ECE-4654-BBDC-4FE13CCCEE3E',
    name: 'microsoft-xbox-controller-battery-medium',
    codepoint: 'F074F',
    deprecated: 'FALSE',
  },
  {
    id: '9CC18C3C-57B6-470C-9F09-80B62ECFABD7',
    name: 'microsoft-xbox-controller-battery-unknown',
    codepoint: 'F0750',
    deprecated: 'FALSE',
  },
  {
    id: '03720A4C-EA6B-4D58-B7D8-5A8DA745B125',
    name: 'microsoft-xbox-controller-menu',
    codepoint: 'F0E6F',
    deprecated: 'FALSE',
  },
  {
    id: 'F73FF165-CE6C-4E27-B492-93F3B82C70EE',
    name: 'microsoft-xbox-controller-off',
    codepoint: 'F05BB',
    deprecated: 'FALSE',
  },
  {
    id: '38E0BCE2-2A91-4D68-B921-9854E18FFB20',
    name: 'microsoft-xbox-controller-view',
    codepoint: 'F0E70',
    deprecated: 'FALSE',
  },
  {
    id: '62F69C7A-4CBA-444A-8BFE-E5B7A891CF5D',
    name: 'microwave',
    codepoint: 'F0C99',
    deprecated: 'FALSE',
  },
  {
    id: '8A96C224-F70E-4DBC-996C-57B8591A0D85',
    name: 'microwave-off',
    codepoint: 'F1423',
    deprecated: 'FALSE',
  },
  {
    id: 'EF5AA157-F8E2-47FB-9A9C-8ACC66A9C2FB',
    name: 'middleware',
    codepoint: 'F0F5D',
    deprecated: 'FALSE',
  },
  {
    id: '3F74E489-26F5-4BC9-8567-8935E8E8559A',
    name: 'middleware-outline',
    codepoint: 'F0F5E',
    deprecated: 'FALSE',
  },
  {
    id: '57B4C4BB-A8D9-409D-A3A4-E512A4E50CA2',
    name: 'midi',
    codepoint: 'F08F1',
    deprecated: 'TRUE',
  },
  {
    id: '9AA75C8F-4091-4C92-81A9-DA58CC95E794',
    name: 'midi-port',
    codepoint: 'F08F2',
    deprecated: 'FALSE',
  },
  {
    id: 'F1D9E59B-F221-46E3-91E9-1D98C478CBF3',
    name: 'mine',
    codepoint: 'F0DDA',
    deprecated: 'FALSE',
  },
  {
    id: '84402452-AA4A-43D6-A26C-A0946B48310C',
    name: 'minecraft',
    codepoint: 'F0373',
    deprecated: 'TRUE',
  },
  {
    id: 'A2E311D2-225E-4AD7-948D-D27AD0362D9F',
    name: 'mini-sd',
    codepoint: 'F0A05',
    deprecated: 'FALSE',
  },
  {
    id: '1BFCC4B4-5BA5-4E64-889D-D64B61EA8D81',
    name: 'minidisc',
    codepoint: 'F0A06',
    deprecated: 'FALSE',
  },
  {
    id: '2CEA6B7B-E7C9-4D52-9D9D-286E50001B70',
    name: 'minus',
    codepoint: 'F0374',
    deprecated: 'FALSE',
  },
  {
    id: '6BFAE994-AF2D-4B04-965D-9734631D5F0D',
    name: 'minus-box',
    codepoint: 'F0375',
    deprecated: 'FALSE',
  },
  {
    id: '8B150753-A2AC-4750-B6EC-686D213805DE',
    name: 'minus-box-multiple',
    codepoint: 'F1141',
    deprecated: 'FALSE',
  },
  {
    id: 'FCAD03B0-9332-41AF-9992-AF53DDDF6BBA',
    name: 'minus-box-multiple-outline',
    codepoint: 'F1142',
    deprecated: 'FALSE',
  },
  {
    id: 'D37AECEA-5BC3-4C57-8886-BC6C1A86987D',
    name: 'minus-box-outline',
    codepoint: 'F06F2',
    deprecated: 'FALSE',
  },
  {
    id: 'DDEB98E7-E016-44CA-8DE7-A31969C2CC04',
    name: 'minus-circle',
    codepoint: 'F0376',
    deprecated: 'FALSE',
  },
  {
    id: 'C7561067-F057-4E26-B8F1-791BD24E0A2C',
    name: 'minus-circle-multiple',
    codepoint: 'F035A',
    deprecated: 'FALSE',
  },
  {
    id: '313B60B3-A740-4F5B-A63E-A5772C20AC0D',
    name: 'minus-circle-multiple-outline',
    codepoint: 'F0AD3',
    deprecated: 'FALSE',
  },
  {
    id: '6A57F7D9-D5F6-42B2-A5E2-D160F7E0DB4B',
    name: 'minus-circle-off',
    codepoint: 'F1459',
    deprecated: 'FALSE',
  },
  {
    id: '2A82D040-8856-437D-B478-DF448C7BE6A6',
    name: 'minus-circle-off-outline',
    codepoint: 'F145A',
    deprecated: 'FALSE',
  },
  {
    id: 'E1568BA6-EA0E-478F-933F-102F76996D6C',
    name: 'minus-circle-outline',
    codepoint: 'F0377',
    deprecated: 'FALSE',
  },
  {
    id: 'F48994C7-4A76-43B8-B851-F7389F8B2B59',
    name: 'minus-network',
    codepoint: 'F0378',
    deprecated: 'FALSE',
  },
  {
    id: '27A3D3DC-42D9-43CF-B783-8B779BE9AEE6',
    name: 'minus-network-outline',
    codepoint: 'F0C9A',
    deprecated: 'FALSE',
  },
  {
    id: '7761450F-6D9D-452D-B604-1853124C09E2',
    name: 'minus-thick',
    codepoint: 'F1639',
    deprecated: 'FALSE',
  },
  {
    id: 'AB90BAF0-E671-4418-9CBB-74C3F03A94DB',
    name: 'mirror',
    codepoint: 'F11FD',
    deprecated: 'FALSE',
  },
  {
    id: '91FE9FDB-B4E7-41CC-ABA2-01FF868F612D',
    name: 'mirror-rectangle',
    codepoint: 'F179F',
    deprecated: 'FALSE',
  },
  {
    id: '5E742652-9430-4B8E-9C4C-19F326A74523',
    name: 'mirror-variant',
    codepoint: 'F17A0',
    deprecated: 'FALSE',
  },
  {
    id: 'BF0B21D2-7266-4639-B2C4-24199863849D',
    name: 'mixed-martial-arts',
    codepoint: 'F0D8F',
    deprecated: 'FALSE',
  },
  {
    id: '5E441030-6DAA-42F9-AF03-A4EB86AF0A51',
    name: 'mixed-reality',
    codepoint: 'F087F',
    deprecated: 'FALSE',
  },
  {
    id: '9DD7B3EE-91B8-4D42-A781-2B12B0343C0B',
    name: 'molecule',
    codepoint: 'F0BAC',
    deprecated: 'FALSE',
  },
  {
    id: 'F3FDFD25-C4F6-4ECF-8F8B-C460E4C6BD7C',
    name: 'molecule-co',
    codepoint: 'F12FE',
    deprecated: 'FALSE',
  },
  {
    id: '6F7A0011-054A-4805-A970-A48BEAA9454B',
    name: 'molecule-co2',
    codepoint: 'F07E4',
    deprecated: 'FALSE',
  },
  {
    id: '35091AEB-2AF7-4F92-A249-2955F01404A3',
    name: 'monitor',
    codepoint: 'F0379',
    deprecated: 'FALSE',
  },
  {
    id: 'F40F74F4-288D-495A-9B2E-1C9D3160B272',
    name: 'monitor-account',
    codepoint: 'F1A5B',
    deprecated: 'FALSE',
  },
  {
    id: '8C772F38-CA90-4CAD-BC26-DE68A84FD6F4',
    name: 'monitor-arrow-down',
    codepoint: 'F19D0',
    deprecated: 'FALSE',
  },
  {
    id: 'D3299EA1-1120-4457-86B0-7162B329B826',
    name: 'monitor-arrow-down-variant',
    codepoint: 'F19D1',
    deprecated: 'FALSE',
  },
  {
    id: 'B2A9CF60-30E4-46DC-9116-ED206912B156',
    name: 'monitor-cellphone',
    codepoint: 'F0989',
    deprecated: 'FALSE',
  },
  {
    id: '6665F86B-B97E-4AB8-8476-06B045706183',
    name: 'monitor-cellphone-star',
    codepoint: 'F098A',
    deprecated: 'FALSE',
  },
  {
    id: 'B4B96C77-6C5D-40A5-83E4-548E48CBECED',
    name: 'monitor-dashboard',
    codepoint: 'F0A07',
    deprecated: 'FALSE',
  },
  {
    id: 'E04B6974-751D-4A55-9309-02336DDB5070',
    name: 'monitor-edit',
    codepoint: 'F12C6',
    deprecated: 'FALSE',
  },
  {
    id: '598E13C9-CC60-4FAF-A13A-024B1B4CF1EE',
    name: 'monitor-eye',
    codepoint: 'F13B4',
    deprecated: 'FALSE',
  },
  {
    id: '59544B54-B690-47F4-AE30-4A9D9FE08FD1',
    name: 'monitor-lock',
    codepoint: 'F0DDB',
    deprecated: 'FALSE',
  },
  {
    id: '7768BF7B-0A52-4492-88BD-5A482E4D61C3',
    name: 'monitor-multiple',
    codepoint: 'F037A',
    deprecated: 'FALSE',
  },
  {
    id: '73F67174-7800-44CB-9850-61A01F9DAD34',
    name: 'monitor-off',
    codepoint: 'F0D90',
    deprecated: 'FALSE',
  },
  {
    id: '9211237F-8920-49C2-90A6-63D97045F5EB',
    name: 'monitor-screenshot',
    codepoint: 'F0E51',
    deprecated: 'FALSE',
  },
  {
    id: '905C2321-6A99-46C7-8EE7-D7193A95E5E7',
    name: 'monitor-share',
    codepoint: 'F1483',
    deprecated: 'FALSE',
  },
  {
    id: 'E02B1C8D-DA14-4AF2-8EB3-397AD9DAF1AB',
    name: 'monitor-shimmer',
    codepoint: 'F1104',
    deprecated: 'FALSE',
  },
  {
    id: 'CE677999-FD3D-4120-A755-2EF579A82528',
    name: 'monitor-small',
    codepoint: 'F1876',
    deprecated: 'FALSE',
  },
  {
    id: '0D49AB2B-17E4-43B8-B72E-05C4A11500F6',
    name: 'monitor-speaker',
    codepoint: 'F0F5F',
    deprecated: 'FALSE',
  },
  {
    id: '56879DCE-4616-45D0-A1C2-B9262776840B',
    name: 'monitor-speaker-off',
    codepoint: 'F0F60',
    deprecated: 'FALSE',
  },
  {
    id: '6E372D46-ECFB-4275-B058-036C89A3413B',
    name: 'monitor-star',
    codepoint: 'F0DDC',
    deprecated: 'FALSE',
  },
  {
    id: 'F9A81054-5651-47DE-9507-E0743AE78E77',
    name: 'moon-first-quarter',
    codepoint: 'F0F61',
    deprecated: 'FALSE',
  },
  {
    id: '536FA8E5-13DF-49A6-9C43-9874847AA01A',
    name: 'moon-full',
    codepoint: 'F0F62',
    deprecated: 'FALSE',
  },
  {
    id: '690C3986-7EEB-4DD2-87EE-BEB93A917B14',
    name: 'moon-last-quarter',
    codepoint: 'F0F63',
    deprecated: 'FALSE',
  },
  {
    id: '1F3CFDCC-7391-4AA6-994C-DCC6F3D28A46',
    name: 'moon-new',
    codepoint: 'F0F64',
    deprecated: 'FALSE',
  },
  {
    id: 'B324215D-A609-4B93-A859-1A0A24391C01',
    name: 'moon-waning-crescent',
    codepoint: 'F0F65',
    deprecated: 'FALSE',
  },
  {
    id: 'B03A16BE-0679-4635-B2F7-6553C3761211',
    name: 'moon-waning-gibbous',
    codepoint: 'F0F66',
    deprecated: 'FALSE',
  },
  {
    id: 'FAE0DF65-BA66-4F45-B246-35EFD1DF6FF2',
    name: 'moon-waxing-crescent',
    codepoint: 'F0F67',
    deprecated: 'FALSE',
  },
  {
    id: '6DF419CE-770C-4662-8681-5D8ADE9C789E',
    name: 'moon-waxing-gibbous',
    codepoint: 'F0F68',
    deprecated: 'FALSE',
  },
  {
    id: 'DC294862-E625-444E-83A4-7D5F2255EE0C',
    name: 'moped',
    codepoint: 'F1086',
    deprecated: 'FALSE',
  },
  {
    id: '5DA43EA9-3DE7-49D8-8884-BB4D8ED31305',
    name: 'moped-electric',
    codepoint: 'F15B7',
    deprecated: 'FALSE',
  },
  {
    id: 'FF5A5D35-99C8-475C-B672-362AF0FA9FC6',
    name: 'moped-electric-outline',
    codepoint: 'F15B8',
    deprecated: 'FALSE',
  },
  {
    id: '2C84B4E2-F872-423E-B9E2-918DD013E131',
    name: 'moped-outline',
    codepoint: 'F15B9',
    deprecated: 'FALSE',
  },
  {
    id: '6284690E-A12C-4209-A779-18284E241DE3',
    name: 'more',
    codepoint: 'F037B',
    deprecated: 'FALSE',
  },
  {
    id: 'C810823C-6678-43F7-8DBB-5D32F503BC59',
    name: 'mortar-pestle',
    codepoint: 'F1748',
    deprecated: 'FALSE',
  },
  {
    id: '71BD315A-B0C1-4B8B-93CB-2A952C3A7C16',
    name: 'mortar-pestle-plus',
    codepoint: 'F03F1',
    deprecated: 'FALSE',
  },
  {
    id: '55847C89-2AE5-4F91-85AA-761FD8CE57F0',
    name: 'mosque',
    codepoint: 'F0D45',
    deprecated: 'FALSE',
  },
  {
    id: '60E1CDBF-4EB9-4240-A7F8-B0476CC62CBC',
    name: 'mosque-outline',
    codepoint: 'F1827',
    deprecated: 'FALSE',
  },
  {
    id: '12E07384-2645-4F67-9A4F-4DA26FA1F75C',
    name: 'mother-heart',
    codepoint: 'F1314',
    deprecated: 'FALSE',
  },
  {
    id: 'ACE8B129-F064-47BF-9FD5-AB472736ED43',
    name: 'mother-nurse',
    codepoint: 'F0D21',
    deprecated: 'FALSE',
  },
  {
    id: 'D5136ED4-FC45-44D8-9DE4-C266844D5131',
    name: 'motion',
    codepoint: 'F15B2',
    deprecated: 'FALSE',
  },
  {
    id: '0D1E834E-0B33-49ED-B02B-1EA0E966ADE1',
    name: 'motion-outline',
    codepoint: 'F15B3',
    deprecated: 'FALSE',
  },
  {
    id: 'B0DBB2D0-8C87-4DD3-AB7A-4BF35A9BFC6C',
    name: 'motion-pause',
    codepoint: 'F1590',
    deprecated: 'FALSE',
  },
  {
    id: 'DE3F5084-9C1D-459E-B3EC-514C040A9C22',
    name: 'motion-pause-outline',
    codepoint: 'F1592',
    deprecated: 'FALSE',
  },
  {
    id: '04CF9E56-DAFA-474B-8405-2F554F66AEEC',
    name: 'motion-play',
    codepoint: 'F158F',
    deprecated: 'FALSE',
  },
  {
    id: '520E825E-1F91-4EFA-B16F-488E61C02091',
    name: 'motion-play-outline',
    codepoint: 'F1591',
    deprecated: 'FALSE',
  },
  {
    id: '2607909D-AA27-448A-B0E5-E5898790414A',
    name: 'motion-sensor',
    codepoint: 'F0D91',
    deprecated: 'FALSE',
  },
  {
    id: '94EEC5E7-5BF7-456E-8A9C-970E3CD46F55',
    name: 'motion-sensor-off',
    codepoint: 'F1435',
    deprecated: 'FALSE',
  },
  {
    id: 'D6AADAF8-D44E-44C0-8647-E578B277441D',
    name: 'motorbike',
    codepoint: 'F037C',
    deprecated: 'FALSE',
  },
  {
    id: '91B25A35-38AC-497B-A0D8-82053E09E43A',
    name: 'motorbike-electric',
    codepoint: 'F15BA',
    deprecated: 'FALSE',
  },
  {
    id: 'E048C1A8-480D-4289-B026-9D1C64BA4236',
    name: 'motorbike-off',
    codepoint: 'F1B16',
    deprecated: 'FALSE',
  },
  {
    id: '965CDBD7-9E52-4169-B363-E0428C75C19D',
    name: 'mouse',
    codepoint: 'F037D',
    deprecated: 'FALSE',
  },
  {
    id: '82AF407C-1290-4636-9299-E4FBA02EC25F',
    name: 'mouse-bluetooth',
    codepoint: 'F098B',
    deprecated: 'FALSE',
  },
  {
    id: '63EB8966-D3A4-40C1-BC5C-BD4B34069D17',
    name: 'mouse-move-down',
    codepoint: 'F1550',
    deprecated: 'FALSE',
  },
  {
    id: '63B2417B-4DCD-4DE3-A899-10851795B46F',
    name: 'mouse-move-up',
    codepoint: 'F1551',
    deprecated: 'FALSE',
  },
  {
    id: 'FD9503DC-1ADF-4E78-B0E9-DC3831C6B849',
    name: 'mouse-move-vertical',
    codepoint: 'F1552',
    deprecated: 'FALSE',
  },
  {
    id: '2FB92E91-9B25-46BF-85D2-BC0A2DB877B1',
    name: 'mouse-off',
    codepoint: 'F037E',
    deprecated: 'FALSE',
  },
  {
    id: '2E7ADC17-384C-4763-9D52-31AFF16BC864',
    name: 'mouse-variant',
    codepoint: 'F037F',
    deprecated: 'FALSE',
  },
  {
    id: '7542DE09-B799-4ED5-8ADB-AE809808FAE1',
    name: 'mouse-variant-off',
    codepoint: 'F0380',
    deprecated: 'FALSE',
  },
  {
    id: '4E3B74C0-3E85-4A50-B5FF-3D6AED1CF016',
    name: 'move-resize',
    codepoint: 'F0655',
    deprecated: 'FALSE',
  },
  {
    id: '7A5B3547-16C4-4000-9082-E13A9139C737',
    name: 'move-resize-variant',
    codepoint: 'F0656',
    deprecated: 'FALSE',
  },
  {
    id: '122C53AA-C8BC-41B3-89B2-B9A9F8BB56DC',
    name: 'movie',
    codepoint: 'F0381',
    deprecated: 'FALSE',
  },
  {
    id: '8B5A9F9F-D0F3-4B9A-844F-D6EE34154837',
    name: 'movie-check',
    codepoint: 'F16F3',
    deprecated: 'FALSE',
  },
  {
    id: '74A2D617-040C-4313-9D9B-CDB2FFDCC0D7',
    name: 'movie-check-outline',
    codepoint: 'F16F4',
    deprecated: 'FALSE',
  },
  {
    id: 'B06E7709-247C-4F57-BFA6-345C1ABAE8C3',
    name: 'movie-cog',
    codepoint: 'F16F5',
    deprecated: 'FALSE',
  },
  {
    id: '388C5417-3BC7-491F-B708-AD81DCB237C8',
    name: 'movie-cog-outline',
    codepoint: 'F16F6',
    deprecated: 'FALSE',
  },
  {
    id: 'EBABD564-B269-4D31-995B-062C7EBEFC15',
    name: 'movie-edit',
    codepoint: 'F1122',
    deprecated: 'FALSE',
  },
  {
    id: '16912257-3D89-4630-9CC5-1FB0EB4A112C',
    name: 'movie-edit-outline',
    codepoint: 'F1123',
    deprecated: 'FALSE',
  },
  {
    id: '89C25EF5-4F0B-43A3-9B70-8B87E71FAB8A',
    name: 'movie-filter',
    codepoint: 'F1124',
    deprecated: 'FALSE',
  },
  {
    id: '90E11F43-169D-46A2-868F-3EE7B8238371',
    name: 'movie-filter-outline',
    codepoint: 'F1125',
    deprecated: 'FALSE',
  },
  {
    id: '8437938B-8D4E-4CE9-BE88-D5BBBC39140A',
    name: 'movie-minus',
    codepoint: 'F16F7',
    deprecated: 'FALSE',
  },
  {
    id: '44B3B4FB-C569-4D4D-A463-C2F6B87A584C',
    name: 'movie-minus-outline',
    codepoint: 'F16F8',
    deprecated: 'FALSE',
  },
  {
    id: '2260571C-D9DD-4720-AFB7-C54A32585767',
    name: 'movie-off',
    codepoint: 'F16F9',
    deprecated: 'FALSE',
  },
  {
    id: '6E94BD0C-39BF-4D74-99BB-738062232A19',
    name: 'movie-off-outline',
    codepoint: 'F16FA',
    deprecated: 'FALSE',
  },
  {
    id: 'F23718ED-A1EE-42D7-84B2-773E110AAB63',
    name: 'movie-open',
    codepoint: 'F0FCE',
    deprecated: 'FALSE',
  },
  {
    id: 'C3078403-9C18-4F30-983B-E755B9CBA37B',
    name: 'movie-open-check',
    codepoint: 'F16FB',
    deprecated: 'FALSE',
  },
  {
    id: '3CA87DEB-5A40-4DAF-960B-2AA9A4385F1D',
    name: 'movie-open-check-outline',
    codepoint: 'F16FC',
    deprecated: 'FALSE',
  },
  {
    id: '20ADB8D7-DA55-463B-A523-9FA0377A1C74',
    name: 'movie-open-cog',
    codepoint: 'F16FD',
    deprecated: 'FALSE',
  },
  {
    id: 'F4936EA7-C151-4D41-84CC-4E02BCA4E01B',
    name: 'movie-open-cog-outline',
    codepoint: 'F16FE',
    deprecated: 'FALSE',
  },
  {
    id: 'BC0C6C6B-C6BE-4E96-BFAF-782866BCF8C2',
    name: 'movie-open-edit',
    codepoint: 'F16FF',
    deprecated: 'FALSE',
  },
  {
    id: '83B13E8A-4202-47CF-B67E-34335B0E5B29',
    name: 'movie-open-edit-outline',
    codepoint: 'F1700',
    deprecated: 'FALSE',
  },
  {
    id: '6AF20375-5B60-41D4-B63C-7572F590741C',
    name: 'movie-open-minus',
    codepoint: 'F1701',
    deprecated: 'FALSE',
  },
  {
    id: '61DDE079-6865-4609-B2F6-2770A3A039E1',
    name: 'movie-open-minus-outline',
    codepoint: 'F1702',
    deprecated: 'FALSE',
  },
  {
    id: 'A58F23CF-24CA-42B3-B877-FA9DEA317A3B',
    name: 'movie-open-off',
    codepoint: 'F1703',
    deprecated: 'FALSE',
  },
  {
    id: 'AF946982-D5F6-4E17-8B0A-B091A873F86E',
    name: 'movie-open-off-outline',
    codepoint: 'F1704',
    deprecated: 'FALSE',
  },
  {
    id: '17E4DACC-FDBF-4206-9F03-428157B91803',
    name: 'movie-open-outline',
    codepoint: 'F0FCF',
    deprecated: 'FALSE',
  },
  {
    id: 'F3CB37FD-2C97-4CD1-83C0-18677B81DAC6',
    name: 'movie-open-play',
    codepoint: 'F1705',
    deprecated: 'FALSE',
  },
  {
    id: 'C855F888-FB96-40F5-9346-6B7A993C960A',
    name: 'movie-open-play-outline',
    codepoint: 'F1706',
    deprecated: 'FALSE',
  },
  {
    id: 'C9068163-63A6-44B7-BE11-2FCE6B427CF6',
    name: 'movie-open-plus',
    codepoint: 'F1707',
    deprecated: 'FALSE',
  },
  {
    id: 'A060D40D-A702-4EA0-9B66-95C31103C223',
    name: 'movie-open-plus-outline',
    codepoint: 'F1708',
    deprecated: 'FALSE',
  },
  {
    id: '1AA27DAA-CD6F-41C5-BB25-8860B368DC0B',
    name: 'movie-open-remove',
    codepoint: 'F1709',
    deprecated: 'FALSE',
  },
  {
    id: '320C9DCC-5845-46F6-B3E6-412D22B8BF47',
    name: 'movie-open-remove-outline',
    codepoint: 'F170A',
    deprecated: 'FALSE',
  },
  {
    id: 'FC6ECE54-744A-4A77-B032-897B24064048',
    name: 'movie-open-settings',
    codepoint: 'F170B',
    deprecated: 'FALSE',
  },
  {
    id: 'C481209F-DE3F-4D25-8C4F-0D21CB8D3385',
    name: 'movie-open-settings-outline',
    codepoint: 'F170C',
    deprecated: 'FALSE',
  },
  {
    id: 'D370E43D-3692-4654-B67E-12AA40956EF3',
    name: 'movie-open-star',
    codepoint: 'F170D',
    deprecated: 'FALSE',
  },
  {
    id: '85F303FB-9847-450E-BF4B-7A38C86386AF',
    name: 'movie-open-star-outline',
    codepoint: 'F170E',
    deprecated: 'FALSE',
  },
  {
    id: '8198AA61-F267-4A56-B9D8-5D492B519545',
    name: 'movie-outline',
    codepoint: 'F0DDD',
    deprecated: 'FALSE',
  },
  {
    id: '44B019DA-A010-4B5F-AF03-61DBCC6206DD',
    name: 'movie-play',
    codepoint: 'F170F',
    deprecated: 'FALSE',
  },
  {
    id: '48071069-C2BD-4B35-97A1-0167DB7B1B13',
    name: 'movie-play-outline',
    codepoint: 'F1710',
    deprecated: 'FALSE',
  },
  {
    id: '543E83B1-B4CD-4D26-9813-A7F23C44AC5F',
    name: 'movie-plus',
    codepoint: 'F1711',
    deprecated: 'FALSE',
  },
  {
    id: '1171D3A1-94AD-4DF5-81E0-D7800C05A0AC',
    name: 'movie-plus-outline',
    codepoint: 'F1712',
    deprecated: 'FALSE',
  },
  {
    id: 'A55FA4A8-DD49-4A4E-8DC0-ED7979F2469B',
    name: 'movie-remove',
    codepoint: 'F1713',
    deprecated: 'FALSE',
  },
  {
    id: '6FC268E0-F315-4818-B25F-0576DA4B9A84',
    name: 'movie-remove-outline',
    codepoint: 'F1714',
    deprecated: 'FALSE',
  },
  {
    id: '21D0F9FB-6814-4CE7-B37D-FC97EBF1D0D2',
    name: 'movie-roll',
    codepoint: 'F07DE',
    deprecated: 'FALSE',
  },
  {
    id: '83E13559-040A-4EB7-BBA5-A2455CCEBBB9',
    name: 'movie-search',
    codepoint: 'F11D2',
    deprecated: 'FALSE',
  },
  {
    id: '8BF9EEDF-1AE8-4382-8544-3F650525371B',
    name: 'movie-search-outline',
    codepoint: 'F11D3',
    deprecated: 'FALSE',
  },
  {
    id: '0A90A107-BB52-4DBA-BC4F-E285D87EB4DF',
    name: 'movie-settings',
    codepoint: 'F1715',
    deprecated: 'FALSE',
  },
  {
    id: 'D90C8E93-5B99-4604-A597-5DA45FC3CEB2',
    name: 'movie-settings-outline',
    codepoint: 'F1716',
    deprecated: 'FALSE',
  },
  {
    id: '07D0534A-0D01-428A-8915-0C3930BE8C41',
    name: 'movie-star',
    codepoint: 'F1717',
    deprecated: 'FALSE',
  },
  {
    id: '31542EFD-68FE-435F-8B00-1BDFB218D9E2',
    name: 'movie-star-outline',
    codepoint: 'F1718',
    deprecated: 'FALSE',
  },
  {
    id: '27842852-CDEB-4575-AAEB-D7F15F0928F8',
    name: 'mower',
    codepoint: 'F166F',
    deprecated: 'FALSE',
  },
  {
    id: '1AF71C6B-087D-493D-81B4-8BDD6A07B2ED',
    name: 'mower-bag',
    codepoint: 'F1670',
    deprecated: 'FALSE',
  },
  {
    id: 'E6E507D5-3440-4E48-A346-3EAA70810BA6',
    name: 'mower-bag-on',
    codepoint: 'F1B60',
    deprecated: 'FALSE',
  },
  {
    id: '6DE40A54-9555-4B66-A698-0B22894BDE68',
    name: 'mower-on',
    codepoint: 'F1B5F',
    deprecated: 'FALSE',
  },
  {
    id: '730FEADE-1281-422D-9E31-9DF7D06AD4E3',
    name: 'muffin',
    codepoint: 'F098C',
    deprecated: 'FALSE',
  },
  {
    id: '8D4D600F-56EB-488D-992A-24B393EE6D47',
    name: 'multicast',
    codepoint: 'F1893',
    deprecated: 'FALSE',
  },
  {
    id: '2EE192A7-497B-41B8-B68A-34A32B8445F2',
    name: 'multimedia',
    codepoint: 'F1B97',
    deprecated: 'FALSE',
  },
  {
    id: '489C9004-CF90-483E-83E3-CD40DD5B9ACF',
    name: 'multiplication',
    codepoint: 'F0382',
    deprecated: 'FALSE',
  },
  {
    id: '38DF4F2F-85CF-4F71-B170-AAAAF25799E0',
    name: 'multiplication-box',
    codepoint: 'F0383',
    deprecated: 'FALSE',
  },
  {
    id: 'FCA4F099-5FCB-43DE-8166-2CE30508C1EC',
    name: 'mushroom',
    codepoint: 'F07DF',
    deprecated: 'FALSE',
  },
  {
    id: 'F061399A-D4F9-49C6-B70A-B4D2603699AC',
    name: 'mushroom-off',
    codepoint: 'F13FA',
    deprecated: 'FALSE',
  },
  {
    id: '5421FAF8-CBB9-43C7-A8C1-EE90C949DD56',
    name: 'mushroom-off-outline',
    codepoint: 'F13FB',
    deprecated: 'FALSE',
  },
  {
    id: 'D6C17EB7-0A54-4ECA-81AA-86311E311934',
    name: 'mushroom-outline',
    codepoint: 'F07E0',
    deprecated: 'FALSE',
  },
  {
    id: 'E7DA3CF2-C345-441D-BFC2-ABC4DB195A6A',
    name: 'music',
    codepoint: 'F075A',
    deprecated: 'FALSE',
  },
  {
    id: '0273309C-CE51-4243-8EDF-F86B0B73AAE4',
    name: 'music-accidental-double-flat',
    codepoint: 'F0F69',
    deprecated: 'FALSE',
  },
  {
    id: '89EC2121-104F-4814-849A-AF9AA744E239',
    name: 'music-accidental-double-sharp',
    codepoint: 'F0F6A',
    deprecated: 'FALSE',
  },
  {
    id: '1ED310B2-EE64-4011-B725-3D4FFB10E26A',
    name: 'music-accidental-flat',
    codepoint: 'F0F6B',
    deprecated: 'FALSE',
  },
  {
    id: '4AA4E035-3A6F-40FA-8355-A38F9294E399',
    name: 'music-accidental-natural',
    codepoint: 'F0F6C',
    deprecated: 'FALSE',
  },
  {
    id: '6FD5E083-A276-4433-8D67-FBC8D9163719',
    name: 'music-accidental-sharp',
    codepoint: 'F0F6D',
    deprecated: 'FALSE',
  },
  {
    id: '2EDBEBA6-8590-4049-BB28-8959378DC44C',
    name: 'music-box',
    codepoint: 'F0384',
    deprecated: 'FALSE',
  },
  {
    id: '56F24AF4-9E5B-4E53-89ED-3472E08C9466',
    name: 'music-box-multiple',
    codepoint: 'F0333',
    deprecated: 'FALSE',
  },
  {
    id: '16F694B0-E5B4-4262-91AE-DB3D0C73EEC0',
    name: 'music-box-multiple-outline',
    codepoint: 'F0F04',
    deprecated: 'FALSE',
  },
  {
    id: '9E8ADF62-9136-40F3-BED9-54A2934B4B3B',
    name: 'music-box-outline',
    codepoint: 'F0385',
    deprecated: 'FALSE',
  },
  {
    id: '33A79A85-7985-4E08-8AA8-D6242A9C2700',
    name: 'music-circle',
    codepoint: 'F0386',
    deprecated: 'FALSE',
  },
  {
    id: 'C8E3DFB3-2F22-489D-90F5-D8C47B66DB3C',
    name: 'music-circle-outline',
    codepoint: 'F0AD4',
    deprecated: 'FALSE',
  },
  {
    id: '9AA4CF34-3341-41F7-AC35-E8556A91787B',
    name: 'music-clef-alto',
    codepoint: 'F0F6E',
    deprecated: 'FALSE',
  },
  {
    id: '279EAB4C-7D62-4C41-A67F-2928723476DA',
    name: 'music-clef-bass',
    codepoint: 'F0F6F',
    deprecated: 'FALSE',
  },
  {
    id: '7ED69559-58ED-432E-800F-9B8DE4CD53A2',
    name: 'music-clef-treble',
    codepoint: 'F0F70',
    deprecated: 'FALSE',
  },
  {
    id: '05F3F5DD-59CC-4FBD-9428-0D0CB125A8AE',
    name: 'music-note',
    codepoint: 'F0387',
    deprecated: 'FALSE',
  },
  {
    id: '40A9BA37-B1BD-4869-A3F9-8F534258DB3F',
    name: 'music-note-bluetooth',
    codepoint: 'F05FE',
    deprecated: 'FALSE',
  },
  {
    id: '98CC5D8B-5D4B-4379-8FFF-1C7FEA82672C',
    name: 'music-note-bluetooth-off',
    codepoint: 'F05FF',
    deprecated: 'FALSE',
  },
  {
    id: '44EDC206-CF12-4DA5-8DA6-E72FDC35BBD2',
    name: 'music-note-eighth',
    codepoint: 'F0388',
    deprecated: 'FALSE',
  },
  {
    id: '9E5589FE-17DF-4167-9DDE-D41D6503B433',
    name: 'music-note-eighth-dotted',
    codepoint: 'F0F71',
    deprecated: 'FALSE',
  },
  {
    id: '2F2FC9A9-FAC3-4736-B8EC-B7DF9548586C',
    name: 'music-note-half',
    codepoint: 'F0389',
    deprecated: 'FALSE',
  },
  {
    id: '53666387-B7D7-4C48-96B9-3D6CAA6E2468',
    name: 'music-note-half-dotted',
    codepoint: 'F0F72',
    deprecated: 'FALSE',
  },
  {
    id: 'F6A55EE0-3A9F-4A76-9711-D752F54005FA',
    name: 'music-note-minus',
    codepoint: 'F1B89',
    deprecated: 'FALSE',
  },
  {
    id: '08305F79-B7E4-4FC9-9B5B-8E76920C3DCC',
    name: 'music-note-off',
    codepoint: 'F038A',
    deprecated: 'FALSE',
  },
  {
    id: 'C47B2AE6-FB1F-4E54-A951-AD26609ECB80',
    name: 'music-note-off-outline',
    codepoint: 'F0F73',
    deprecated: 'FALSE',
  },
  {
    id: '5888AA23-7D87-46D3-83F3-1AB820E9F77D',
    name: 'music-note-outline',
    codepoint: 'F0F74',
    deprecated: 'FALSE',
  },
  {
    id: 'ED5D1835-D69E-458A-9CCE-F1B81EB831D2',
    name: 'music-note-plus',
    codepoint: 'F0DDE',
    deprecated: 'FALSE',
  },
  {
    id: 'D71DFC98-3401-4268-9956-3EFEEE336235',
    name: 'music-note-quarter',
    codepoint: 'F038B',
    deprecated: 'FALSE',
  },
  {
    id: '56364DE5-01AE-4702-BC79-F85D07E485C7',
    name: 'music-note-quarter-dotted',
    codepoint: 'F0F75',
    deprecated: 'FALSE',
  },
  {
    id: '882DF0E2-C223-4B3F-A538-81EC05421BA9',
    name: 'music-note-sixteenth',
    codepoint: 'F038C',
    deprecated: 'FALSE',
  },
  {
    id: 'F98989F8-DE11-42A7-A814-5C988CBD7989',
    name: 'music-note-sixteenth-dotted',
    codepoint: 'F0F76',
    deprecated: 'FALSE',
  },
  {
    id: '13454604-C50C-4381-AC96-552848F0DF9E',
    name: 'music-note-whole',
    codepoint: 'F038D',
    deprecated: 'FALSE',
  },
  {
    id: '6A2B3740-C02F-4097-9713-80C34636CA12',
    name: 'music-note-whole-dotted',
    codepoint: 'F0F77',
    deprecated: 'FALSE',
  },
  {
    id: 'E9D8BB99-B31B-4940-9EEE-B69569060B0D',
    name: 'music-off',
    codepoint: 'F075B',
    deprecated: 'FALSE',
  },
  {
    id: 'D77BE879-A1D5-48F5-9068-B74A2ED4017A',
    name: 'music-rest-eighth',
    codepoint: 'F0F78',
    deprecated: 'FALSE',
  },
  {
    id: 'C9124F85-BB56-414C-BEAF-EE02E921B6D2',
    name: 'music-rest-half',
    codepoint: 'F0F79',
    deprecated: 'FALSE',
  },
  {
    id: '64309B5C-51CC-42D0-B2D1-F42454CC1E2E',
    name: 'music-rest-quarter',
    codepoint: 'F0F7A',
    deprecated: 'FALSE',
  },
  {
    id: 'E7CCD9CF-3FC1-44F0-996B-841B293B1793',
    name: 'music-rest-sixteenth',
    codepoint: 'F0F7B',
    deprecated: 'FALSE',
  },
  {
    id: 'E11E01A9-8231-456F-AF61-82A069BBDAAB',
    name: 'music-rest-whole',
    codepoint: 'F0F7C',
    deprecated: 'FALSE',
  },
  {
    id: '766E10EA-23CD-42BD-9DB8-633FBF8C584A',
    name: 'mustache',
    codepoint: 'F15DE',
    deprecated: 'FALSE',
  },
  {
    id: 'D1A8C00E-5FE4-4016-9C38-40CD9804D7C9',
    name: 'nail',
    codepoint: 'F0DDF',
    deprecated: 'FALSE',
  },
  {
    id: '001157C4-1AAA-41FF-B204-906ACDB427C3',
    name: 'nas',
    codepoint: 'F08F3',
    deprecated: 'FALSE',
  },
  {
    id: 'A69196BD-730F-4684-8604-8668FD60183A',
    name: 'nativescript',
    codepoint: 'F0880',
    deprecated: 'TRUE',
  },
  {
    id: 'C5FCAD4F-C7E6-4BDB-9F2F-A665CB68B7FD',
    name: 'nature',
    codepoint: 'F038E',
    deprecated: 'FALSE',
  },
  {
    id: 'B0635A70-D2A9-41CE-82C6-3FB1D1D353B4',
    name: 'nature-people',
    codepoint: 'F038F',
    deprecated: 'FALSE',
  },
  {
    id: 'DAAF6988-6EE9-4595-A758-294C83C7304B',
    name: 'navigation',
    codepoint: 'F0390',
    deprecated: 'FALSE',
  },
  {
    id: '8CEE19E5-13B9-4AFF-8880-B04D36B144BA',
    name: 'navigation-outline',
    codepoint: 'F1607',
    deprecated: 'FALSE',
  },
  {
    id: 'FAF89CE7-022F-4AD0-BCB6-0F4F22EE0B26',
    name: 'navigation-variant',
    codepoint: 'F18F0',
    deprecated: 'FALSE',
  },
  {
    id: '23292DC7-A187-4818-8944-1144B38E183C',
    name: 'navigation-variant-outline',
    codepoint: 'F18F1',
    deprecated: 'FALSE',
  },
  {
    id: '6766F53D-88E4-452A-A222-7F50DDFB757C',
    name: 'near-me',
    codepoint: 'F05CD',
    deprecated: 'FALSE',
  },
  {
    id: '70C6DD45-DD16-4629-8BBA-B60FB5D60382',
    name: 'necklace',
    codepoint: 'F0F0B',
    deprecated: 'FALSE',
  },
  {
    id: '2B76647F-92FE-4F8E-B193-7EC20DADD5D2',
    name: 'needle',
    codepoint: 'F0391',
    deprecated: 'FALSE',
  },
  {
    id: 'EF95D4D3-9095-412A-A109-BF170FA2A1D9',
    name: 'needle-off',
    codepoint: 'F19D2',
    deprecated: 'FALSE',
  },
  {
    id: '7AEF7097-EC88-4D09-A65E-5244DDA99599',
    name: 'netflix',
    codepoint: 'F0746',
    deprecated: 'TRUE',
  },
  {
    id: '089C6F75-2FF5-4B48-B4B4-651AEAD3D24F',
    name: 'network',
    codepoint: 'F06F3',
    deprecated: 'FALSE',
  },
  {
    id: 'B4F8AA0D-E5C6-4FC1-A91C-A7E47269FB31',
    name: 'network-off',
    codepoint: 'F0C9B',
    deprecated: 'FALSE',
  },
  {
    id: '6CFA3244-3420-4234-BFC6-80DEA8F38CCC',
    name: 'network-off-outline',
    codepoint: 'F0C9C',
    deprecated: 'FALSE',
  },
  {
    id: 'D6F66231-B4F7-4E89-80D5-D9DDD2354D02',
    name: 'network-outline',
    codepoint: 'F0C9D',
    deprecated: 'FALSE',
  },
  {
    id: 'D9BB8F7B-1014-42A4-BBC3-3ED704847943',
    name: 'network-pos',
    codepoint: 'F1ACB',
    deprecated: 'FALSE',
  },
  {
    id: '7AEB6E6A-4B4E-42C3-9AB2-1F47FE1BD29B',
    name: 'network-strength-1',
    codepoint: 'F08F4',
    deprecated: 'FALSE',
  },
  {
    id: '9C2C41A2-7BA9-46C3-B017-941C7B2C2E60',
    name: 'network-strength-1-alert',
    codepoint: 'F08F5',
    deprecated: 'FALSE',
  },
  {
    id: 'DF3CEC8C-F6B7-467B-833C-92FE260C6A73',
    name: 'network-strength-2',
    codepoint: 'F08F6',
    deprecated: 'FALSE',
  },
  {
    id: 'DB8EEB5C-3705-4DE7-A770-D63ACE14F1BD',
    name: 'network-strength-2-alert',
    codepoint: 'F08F7',
    deprecated: 'FALSE',
  },
  {
    id: '7FAA15D1-1E1D-41F5-98F0-1B640DFB0688',
    name: 'network-strength-3',
    codepoint: 'F08F8',
    deprecated: 'FALSE',
  },
  {
    id: '99771A39-B621-45BA-96BE-7589E5A85B9E',
    name: 'network-strength-3-alert',
    codepoint: 'F08F9',
    deprecated: 'FALSE',
  },
  {
    id: '87FF7D68-6F78-44AB-80C4-DC7FE976E3A3',
    name: 'network-strength-4',
    codepoint: 'F08FA',
    deprecated: 'FALSE',
  },
  {
    id: '1173684B-E68C-4BE5-BD9C-53C01A619896',
    name: 'network-strength-4-alert',
    codepoint: 'F08FB',
    deprecated: 'FALSE',
  },
  {
    id: 'AB7248F1-C9F3-4EF3-ACD5-8B7EE11771C3',
    name: 'network-strength-4-cog',
    codepoint: 'F191A',
    deprecated: 'FALSE',
  },
  {
    id: 'E3FEE803-9C91-4BAE-9528-D016588E99AD',
    name: 'network-strength-off',
    codepoint: 'F08FC',
    deprecated: 'FALSE',
  },
  {
    id: 'E7A4CEE7-C6E2-4FD6-B0BF-B3DD131100CB',
    name: 'network-strength-off-outline',
    codepoint: 'F08FD',
    deprecated: 'FALSE',
  },
  {
    id: '5A80D579-DF12-4B68-AAD8-AFE8039E6657',
    name: 'network-strength-outline',
    codepoint: 'F08FE',
    deprecated: 'FALSE',
  },
  {
    id: '1ABEE375-A0DC-4262-9CCD-3B9C78B5FE7D',
    name: 'new-box',
    codepoint: 'F0394',
    deprecated: 'FALSE',
  },
  {
    id: 'EE440B14-2E25-4C8E-99A7-A3C98CBC9151',
    name: 'newspaper',
    codepoint: 'F0395',
    deprecated: 'FALSE',
  },
  {
    id: 'F329B5C0-F6CC-4402-B4BF-A8744C9D802E',
    name: 'newspaper-check',
    codepoint: 'F1943',
    deprecated: 'FALSE',
  },
  {
    id: '4949C4CF-3395-466E-B459-9FEBA1BFD1AC',
    name: 'newspaper-minus',
    codepoint: 'F0F0C',
    deprecated: 'FALSE',
  },
  {
    id: '7B4B8A85-6E8F-4C2B-938C-58DF98E8823B',
    name: 'newspaper-plus',
    codepoint: 'F0F0D',
    deprecated: 'FALSE',
  },
  {
    id: '9E4ABD6C-A1C6-4209-B977-83A7A03667A8',
    name: 'newspaper-remove',
    codepoint: 'F1944',
    deprecated: 'FALSE',
  },
  {
    id: 'B7E3A571-DF01-4CBF-A3AE-CDEF835F2A21',
    name: 'newspaper-variant',
    codepoint: 'F1001',
    deprecated: 'FALSE',
  },
  {
    id: '2672B224-C14E-41B7-B7F2-632CA02F9D3B',
    name: 'newspaper-variant-multiple',
    codepoint: 'F1002',
    deprecated: 'FALSE',
  },
  {
    id: '687B9CDE-25C6-49DF-B28F-051B30FD11D3',
    name: 'newspaper-variant-multiple-outline',
    codepoint: 'F1003',
    deprecated: 'FALSE',
  },
  {
    id: '1859420B-45AE-4AC1-AC8D-91AB0FF84BBA',
    name: 'newspaper-variant-outline',
    codepoint: 'F1004',
    deprecated: 'FALSE',
  },
  {
    id: '961106B7-BD56-462C-936C-E2CDC83051B5',
    name: 'nfc',
    codepoint: 'F0396',
    deprecated: 'TRUE',
  },
  {
    id: '2AD73512-8D95-4CCB-8A7E-528E70F510B9',
    name: 'nfc-search-variant',
    codepoint: 'F0E53',
    deprecated: 'FALSE',
  },
  {
    id: '656C274C-CDC8-4FB6-AFFA-F704784BC997',
    name: 'nfc-tap',
    codepoint: 'F0397',
    deprecated: 'FALSE',
  },
  {
    id: '4DFF5414-0EA3-4255-BC50-B40B0E3ADB15',
    name: 'nfc-variant',
    codepoint: 'F0398',
    deprecated: 'FALSE',
  },
  {
    id: '2BD17DBC-5002-4378-B885-A1952D740C0B',
    name: 'nfc-variant-off',
    codepoint: 'F0E54',
    deprecated: 'FALSE',
  },
  {
    id: '8E7C1B4B-C4E8-4072-9FB7-90E207038FFB',
    name: 'ninja',
    codepoint: 'F0774',
    deprecated: 'FALSE',
  },
  {
    id: 'A067CF52-3D9E-43DB-9542-44BE780B4117',
    name: 'nintendo-game-boy',
    codepoint: 'F1393',
    deprecated: 'FALSE',
  },
  {
    id: 'D57C5D23-1122-4DEB-BEC4-77D5A4D685FE',
    name: 'nintendo-switch',
    codepoint: 'F07E1',
    deprecated: 'TRUE',
  },
  {
    id: 'AA2F2BCF-A9BD-4B3A-83A4-880E518F9428',
    name: 'nintendo-wii',
    codepoint: 'F05AB',
    deprecated: 'TRUE',
  },
  {
    id: '7D322B2B-5B7D-46B4-ACC3-A8900CFED5C9',
    name: 'nintendo-wiiu',
    codepoint: 'F072D',
    deprecated: 'TRUE',
  },
  {
    id: '525A43D5-4292-4C31-9E7D-7B65DB386BF9',
    name: 'nix',
    codepoint: 'F1105',
    deprecated: 'TRUE',
  },
  {
    id: '46A696BA-7A78-40C1-A685-C9B9851286A6',
    name: 'nodejs',
    codepoint: 'F0399',
    deprecated: 'TRUE',
  },
  {
    id: '1655675D-A197-44BD-83E9-76E2BAD31740',
    name: 'noodles',
    codepoint: 'F117E',
    deprecated: 'FALSE',
  },
  {
    id: 'A301F5C0-B760-4EA0-B803-DEF84D1B01CD',
    name: 'not-equal',
    codepoint: 'F098D',
    deprecated: 'FALSE',
  },
  {
    id: 'AF2B1665-3D54-4017-BF7C-AE03A7C6AA52',
    name: 'not-equal-variant',
    codepoint: 'F098E',
    deprecated: 'FALSE',
  },
  {
    id: '5E1DF73F-AEF5-48F8-B766-419FBB05C5D0',
    name: 'note',
    codepoint: 'F039A',
    deprecated: 'FALSE',
  },
  {
    id: '1673B5E0-CB7A-4430-9D36-9B42F1189D4F',
    name: 'note-alert',
    codepoint: 'F177D',
    deprecated: 'FALSE',
  },
  {
    id: '52710BFA-E892-49B7-9157-8C9A5E08BC21',
    name: 'note-alert-outline',
    codepoint: 'F177E',
    deprecated: 'FALSE',
  },
  {
    id: 'A4B7F3B4-25B2-4786-A02F-FDA4F963E580',
    name: 'note-check',
    codepoint: 'F177F',
    deprecated: 'FALSE',
  },
  {
    id: '827B10E4-F154-4101-BB5C-492EA37B7D08',
    name: 'note-check-outline',
    codepoint: 'F1780',
    deprecated: 'FALSE',
  },
  {
    id: '80075448-6EB3-4318-9887-6C199DB9A86E',
    name: 'note-edit',
    codepoint: 'F1781',
    deprecated: 'FALSE',
  },
  {
    id: 'DD82356F-AF1D-4A08-A740-880F31AE563D',
    name: 'note-edit-outline',
    codepoint: 'F1782',
    deprecated: 'FALSE',
  },
  {
    id: '5F9984C8-D7D1-42A8-9167-52B0B849CF22',
    name: 'note-minus',
    codepoint: 'F164F',
    deprecated: 'FALSE',
  },
  {
    id: '244AEF5D-38E8-42E6-9C4F-8D4AFF831651',
    name: 'note-minus-outline',
    codepoint: 'F1650',
    deprecated: 'FALSE',
  },
  {
    id: '0A0E42BA-FE9D-44C6-BF6A-D29D6013F2E0',
    name: 'note-multiple',
    codepoint: 'F06B8',
    deprecated: 'FALSE',
  },
  {
    id: '6294246C-A524-4121-B141-5262D89FFD4D',
    name: 'note-multiple-outline',
    codepoint: 'F06B9',
    deprecated: 'FALSE',
  },
  {
    id: '9EE3CDDD-BFF7-4BCF-B07E-B120D646DE0D',
    name: 'note-off',
    codepoint: 'F1783',
    deprecated: 'FALSE',
  },
  {
    id: '321C9AFB-4A83-4B8F-A361-ADFA32318258',
    name: 'note-off-outline',
    codepoint: 'F1784',
    deprecated: 'FALSE',
  },
  {
    id: 'DD6D5075-A7F5-4183-9F99-93C295F18B0B',
    name: 'note-outline',
    codepoint: 'F039B',
    deprecated: 'FALSE',
  },
  {
    id: '753F2E09-A32F-4790-9C07-7CD9AB69104D',
    name: 'note-plus',
    codepoint: 'F039C',
    deprecated: 'FALSE',
  },
  {
    id: '706F4C63-412B-44CD-81DE-612D4483D09C',
    name: 'note-plus-outline',
    codepoint: 'F039D',
    deprecated: 'FALSE',
  },
  {
    id: '948C92D0-A16F-48FD-8A25-37898D6B9768',
    name: 'note-remove',
    codepoint: 'F1651',
    deprecated: 'FALSE',
  },
  {
    id: '4F471FC1-C060-4081-9866-21830957AE90',
    name: 'note-remove-outline',
    codepoint: 'F1652',
    deprecated: 'FALSE',
  },
  {
    id: 'D914BB12-8F9A-4B71-86A0-DA016AA91B90',
    name: 'note-search',
    codepoint: 'F1653',
    deprecated: 'FALSE',
  },
  {
    id: 'FAA55692-47B7-492B-897A-9225E7F0C766',
    name: 'note-search-outline',
    codepoint: 'F1654',
    deprecated: 'FALSE',
  },
  {
    id: '2ADC4253-F51B-4BC2-B04C-0DDD139425CF',
    name: 'note-text',
    codepoint: 'F039E',
    deprecated: 'FALSE',
  },
  {
    id: 'E39F6865-3E35-4BE8-8440-643FC83351F7',
    name: 'note-text-outline',
    codepoint: 'F11D7',
    deprecated: 'FALSE',
  },
  {
    id: '2D28230E-DAF3-4A49-ACB6-7D9C3E9E6495',
    name: 'notebook',
    codepoint: 'F082E',
    deprecated: 'FALSE',
  },
  {
    id: '7DBD97BD-376C-45C2-85BA-74BE45C03F2E',
    name: 'notebook-check',
    codepoint: 'F14F5',
    deprecated: 'FALSE',
  },
  {
    id: '0C11F6D4-B9DE-4927-AB54-B17695167B25',
    name: 'notebook-check-outline',
    codepoint: 'F14F6',
    deprecated: 'FALSE',
  },
  {
    id: 'EFBA00EA-395F-47DF-A0B9-8F42C1A697E1',
    name: 'notebook-edit',
    codepoint: 'F14E7',
    deprecated: 'FALSE',
  },
  {
    id: '31A232F5-6134-4A8D-A9D1-41DC4404922D',
    name: 'notebook-edit-outline',
    codepoint: 'F14E9',
    deprecated: 'FALSE',
  },
  {
    id: 'BA533C71-48B2-4D1D-8069-98F53EC6224B',
    name: 'notebook-heart',
    codepoint: 'F1A0B',
    deprecated: 'FALSE',
  },
  {
    id: '0F8BCA10-0144-4507-850B-777AE12B3A80',
    name: 'notebook-heart-outline',
    codepoint: 'F1A0C',
    deprecated: 'FALSE',
  },
  {
    id: '60952B51-85EE-45B0-9834-7117F8DED9D7',
    name: 'notebook-minus',
    codepoint: 'F1610',
    deprecated: 'FALSE',
  },
  {
    id: '0B3667B2-5DB3-4C7B-8E01-4601666EE765',
    name: 'notebook-minus-outline',
    codepoint: 'F1611',
    deprecated: 'FALSE',
  },
  {
    id: '3F75C3D8-9360-4C31-AF58-1C01321620F0',
    name: 'notebook-multiple',
    codepoint: 'F0E55',
    deprecated: 'FALSE',
  },
  {
    id: '71D72BE3-C04C-40EF-87B7-A08E74045DC0',
    name: 'notebook-outline',
    codepoint: 'F0EBF',
    deprecated: 'FALSE',
  },
  {
    id: '03D29CD4-AFB3-41E3-9B35-80F27ABFDD13',
    name: 'notebook-plus',
    codepoint: 'F1612',
    deprecated: 'FALSE',
  },
  {
    id: '8652F3A3-ED31-48CA-B051-A4ADCFC722F6',
    name: 'notebook-plus-outline',
    codepoint: 'F1613',
    deprecated: 'FALSE',
  },
  {
    id: '318A0AA0-55F5-4242-B377-BA972C7B3AD3',
    name: 'notebook-remove',
    codepoint: 'F1614',
    deprecated: 'FALSE',
  },
  {
    id: '92BD7E81-2B29-48FB-B0B0-F38C94BE4B51',
    name: 'notebook-remove-outline',
    codepoint: 'F1615',
    deprecated: 'FALSE',
  },
  {
    id: 'E758A025-3B14-4A2F-A1D7-2D12C38B47E4',
    name: 'notification-clear-all',
    codepoint: 'F039F',
    deprecated: 'FALSE',
  },
  {
    id: '5E072845-A46E-4D62-A65D-28CD7DAD976B',
    name: 'npm',
    codepoint: 'F06F7',
    deprecated: 'TRUE',
  },
  {
    id: 'DB44684C-B1E7-4039-8BAA-C7C0BC4EB075',
    name: 'nuke',
    codepoint: 'F06A4',
    deprecated: 'FALSE',
  },
  {
    id: '5004722B-DE0B-4F37-BCAD-36B46477E80F',
    name: 'null',
    codepoint: 'F07E2',
    deprecated: 'FALSE',
  },
  {
    id: '1293DBCD-E538-4AD1-97C9-3F17BBD28457',
    name: 'numeric',
    codepoint: 'F03A0',
    deprecated: 'FALSE',
  },
  {
    id: '3396CDF3-94D7-4F1B-B0FF-58FA3935468C',
    name: 'numeric-0',
    codepoint: 'F0B39',
    deprecated: 'FALSE',
  },
  {
    id: 'C676CDFC-A808-4BCA-A670-49EE8478ED32',
    name: 'numeric-0-box',
    codepoint: 'F03A1',
    deprecated: 'FALSE',
  },
  {
    id: 'D54CC81B-F785-4896-BA17-454BBBE96EC5',
    name: 'numeric-0-box-multiple',
    codepoint: 'F0F0E',
    deprecated: 'FALSE',
  },
  {
    id: 'B3652E81-9CA5-4B7C-B603-FE94CB2D1323',
    name: 'numeric-0-box-multiple-outline',
    codepoint: 'F03A2',
    deprecated: 'FALSE',
  },
  {
    id: '1EB53615-9A62-4377-A2D0-246C8F42CAF8',
    name: 'numeric-0-box-outline',
    codepoint: 'F03A3',
    deprecated: 'FALSE',
  },
  {
    id: '802F5332-8865-46C9-890D-130209044E17',
    name: 'numeric-0-circle',
    codepoint: 'F0C9E',
    deprecated: 'FALSE',
  },
  {
    id: 'BB859C27-AAAC-4ED4-A50B-45427C15CF05',
    name: 'numeric-0-circle-outline',
    codepoint: 'F0C9F',
    deprecated: 'FALSE',
  },
  {
    id: 'DF225E4A-DED0-43C3-83B1-4867ABA9D45B',
    name: 'numeric-1',
    codepoint: 'F0B3A',
    deprecated: 'FALSE',
  },
  {
    id: '0EF5316E-7978-4F6C-B38E-297837B3CFF5',
    name: 'numeric-1-box',
    codepoint: 'F03A4',
    deprecated: 'FALSE',
  },
  {
    id: '9CF3C6FF-4E68-478B-85B9-02ABA9A7980C',
    name: 'numeric-1-box-multiple',
    codepoint: 'F0F0F',
    deprecated: 'FALSE',
  },
  {
    id: '55513DD6-D73E-438E-8834-D6761C47BA4B',
    name: 'numeric-1-box-multiple-outline',
    codepoint: 'F03A5',
    deprecated: 'FALSE',
  },
  {
    id: '50AEDC65-99B1-45CA-8078-CD6D8633995A',
    name: 'numeric-1-box-outline',
    codepoint: 'F03A6',
    deprecated: 'FALSE',
  },
  {
    id: '77E2B22B-C6FB-4759-B2BB-0528DAFA6210',
    name: 'numeric-1-circle',
    codepoint: 'F0CA0',
    deprecated: 'FALSE',
  },
  {
    id: '7621E523-93F4-47A9-A7A5-9BC2A01A4DD4',
    name: 'numeric-1-circle-outline',
    codepoint: 'F0CA1',
    deprecated: 'FALSE',
  },
  {
    id: '59F26CDB-B593-4675-941C-68F0E99F2CFF',
    name: 'numeric-10',
    codepoint: 'F0FE9',
    deprecated: 'FALSE',
  },
  {
    id: 'BD0566FC-D422-4ABD-8D6E-7E08EED3708D',
    name: 'numeric-10-box',
    codepoint: 'F0F7D',
    deprecated: 'FALSE',
  },
  {
    id: '0ABCD0B7-C95E-4BD9-AFBB-C1CF415FE53C',
    name: 'numeric-10-box-multiple',
    codepoint: 'F0FEA',
    deprecated: 'FALSE',
  },
  {
    id: '4C455257-74B0-4140-BA07-71582A9750FD',
    name: 'numeric-10-box-multiple-outline',
    codepoint: 'F0FEB',
    deprecated: 'FALSE',
  },
  {
    id: '12AB352E-27A7-43D6-BF2E-EF35BC4CA288',
    name: 'numeric-10-box-outline',
    codepoint: 'F0F7E',
    deprecated: 'FALSE',
  },
  {
    id: '487FE19B-CDA9-4895-B904-606B73BB66E7',
    name: 'numeric-10-circle',
    codepoint: 'F0FEC',
    deprecated: 'FALSE',
  },
  {
    id: 'FAABED75-FE76-4109-824D-F16C54E43FA2',
    name: 'numeric-10-circle-outline',
    codepoint: 'F0FED',
    deprecated: 'FALSE',
  },
  {
    id: '55F915B1-69CB-4394-9313-F8A0B7A8EBB9',
    name: 'numeric-2',
    codepoint: 'F0B3B',
    deprecated: 'FALSE',
  },
  {
    id: '0F35B058-84BF-42A1-8EC2-4DF8A5F2CB6D',
    name: 'numeric-2-box',
    codepoint: 'F03A7',
    deprecated: 'FALSE',
  },
  {
    id: '4950EF31-6A39-4EFE-91CD-10CB750A3634',
    name: 'numeric-2-box-multiple',
    codepoint: 'F0F10',
    deprecated: 'FALSE',
  },
  {
    id: '989BAC0E-21F6-4AF2-849A-3DA35BA5958F',
    name: 'numeric-2-box-multiple-outline',
    codepoint: 'F03A8',
    deprecated: 'FALSE',
  },
  {
    id: '0C255402-1176-46B5-B38C-60118732FCC7',
    name: 'numeric-2-box-outline',
    codepoint: 'F03A9',
    deprecated: 'FALSE',
  },
  {
    id: 'C8CF7706-2522-4EB4-A304-C25BAD47C1C7',
    name: 'numeric-2-circle',
    codepoint: 'F0CA2',
    deprecated: 'FALSE',
  },
  {
    id: '36906959-9333-4F37-9BB3-BE1481F2A99A',
    name: 'numeric-2-circle-outline',
    codepoint: 'F0CA3',
    deprecated: 'FALSE',
  },
  {
    id: 'E7B3F9D9-BC8D-4BE8-B02D-F2E20B3B357A',
    name: 'numeric-3',
    codepoint: 'F0B3C',
    deprecated: 'FALSE',
  },
  {
    id: 'F34D170B-4DF1-470D-ABC4-AE0A93035227',
    name: 'numeric-3-box',
    codepoint: 'F03AA',
    deprecated: 'FALSE',
  },
  {
    id: '9D8EBAFA-19A3-4E74-9800-570AFD910814',
    name: 'numeric-3-box-multiple',
    codepoint: 'F0F11',
    deprecated: 'FALSE',
  },
  {
    id: '394B1DFD-7009-4057-AD14-7F7D99B3A658',
    name: 'numeric-3-box-multiple-outline',
    codepoint: 'F03AB',
    deprecated: 'FALSE',
  },
  {
    id: '0A0F7443-4234-4990-9C0B-A8AC88F924E5',
    name: 'numeric-3-box-outline',
    codepoint: 'F03AC',
    deprecated: 'FALSE',
  },
  {
    id: '07FDE137-E119-45E9-8100-3C0194FFF51D',
    name: 'numeric-3-circle',
    codepoint: 'F0CA4',
    deprecated: 'FALSE',
  },
  {
    id: '4AF81318-EC08-4E9D-9853-F7C8AD694E10',
    name: 'numeric-3-circle-outline',
    codepoint: 'F0CA5',
    deprecated: 'FALSE',
  },
  {
    id: '099C1A7A-865B-4BA2-951B-EED8F8665083',
    name: 'numeric-4',
    codepoint: 'F0B3D',
    deprecated: 'FALSE',
  },
  {
    id: '9C9EEFA4-B764-41CE-A679-3927454AE59A',
    name: 'numeric-4-box',
    codepoint: 'F03AD',
    deprecated: 'FALSE',
  },
  {
    id: 'DB253DAA-77F7-41F0-A1A4-76388FEFA092',
    name: 'numeric-4-box-multiple',
    codepoint: 'F0F12',
    deprecated: 'FALSE',
  },
  {
    id: 'EB320CFB-296A-4C6F-9585-76CA21DDCD04',
    name: 'numeric-4-box-multiple-outline',
    codepoint: 'F03B2',
    deprecated: 'FALSE',
  },
  {
    id: '18423C69-0B97-4590-A690-1AAF99615875',
    name: 'numeric-4-box-outline',
    codepoint: 'F03AE',
    deprecated: 'FALSE',
  },
  {
    id: '3C8A2386-5BBE-495F-A1BE-BFBB3385D857',
    name: 'numeric-4-circle',
    codepoint: 'F0CA6',
    deprecated: 'FALSE',
  },
  {
    id: '898E368A-092D-45E4-B84D-9C99ABFFF2AC',
    name: 'numeric-4-circle-outline',
    codepoint: 'F0CA7',
    deprecated: 'FALSE',
  },
  {
    id: 'EFB5FA9A-E828-4D71-A35F-011D873DE490',
    name: 'numeric-5',
    codepoint: 'F0B3E',
    deprecated: 'FALSE',
  },
  {
    id: '1F91841C-B56D-486E-8113-187E28F1E53A',
    name: 'numeric-5-box',
    codepoint: 'F03B1',
    deprecated: 'FALSE',
  },
  {
    id: 'C3FBC921-E4A1-4B04-BDEA-E883EE5FB695',
    name: 'numeric-5-box-multiple',
    codepoint: 'F0F13',
    deprecated: 'FALSE',
  },
  {
    id: 'A39788D6-B09D-42CF-ADC3-7575B14DBE79',
    name: 'numeric-5-box-multiple-outline',
    codepoint: 'F03AF',
    deprecated: 'FALSE',
  },
  {
    id: '38CB37B2-2BF9-4890-8060-5576DDAE6FC6',
    name: 'numeric-5-box-outline',
    codepoint: 'F03B0',
    deprecated: 'FALSE',
  },
  {
    id: 'B4079A0A-5D66-4982-B31F-4145B49E5512',
    name: 'numeric-5-circle',
    codepoint: 'F0CA8',
    deprecated: 'FALSE',
  },
  {
    id: '1FFAD3CC-0B61-4A7A-90F0-E5A5D78D7D3A',
    name: 'numeric-5-circle-outline',
    codepoint: 'F0CA9',
    deprecated: 'FALSE',
  },
  {
    id: '8B73A4D3-4AC9-448B-8C60-497EC2167898',
    name: 'numeric-6',
    codepoint: 'F0B3F',
    deprecated: 'FALSE',
  },
  {
    id: 'DC1FE999-3DD7-423B-95A5-89005C66875E',
    name: 'numeric-6-box',
    codepoint: 'F03B3',
    deprecated: 'FALSE',
  },
  {
    id: '231F08AC-E082-407D-9EC9-55C2E654EF16',
    name: 'numeric-6-box-multiple',
    codepoint: 'F0F14',
    deprecated: 'FALSE',
  },
  {
    id: 'F3AA6A15-D060-40D7-A301-9EB1BAAF2F12',
    name: 'numeric-6-box-multiple-outline',
    codepoint: 'F03B4',
    deprecated: 'FALSE',
  },
  {
    id: '8ED3719B-D733-46ED-BFCF-0BDE0819F7EF',
    name: 'numeric-6-box-outline',
    codepoint: 'F03B5',
    deprecated: 'FALSE',
  },
  {
    id: 'E15A2826-796D-439A-9A10-4F068C76C6FC',
    name: 'numeric-6-circle',
    codepoint: 'F0CAA',
    deprecated: 'FALSE',
  },
  {
    id: '6D644C9F-A3C4-45E6-A2E8-E3077A170E4A',
    name: 'numeric-6-circle-outline',
    codepoint: 'F0CAB',
    deprecated: 'FALSE',
  },
  {
    id: '19BDA673-8B20-4A75-87B9-7286F0B14730',
    name: 'numeric-7',
    codepoint: 'F0B40',
    deprecated: 'FALSE',
  },
  {
    id: 'C01DF00A-5B63-4B15-B6A5-FEA9E3E084B1',
    name: 'numeric-7-box',
    codepoint: 'F03B6',
    deprecated: 'FALSE',
  },
  {
    id: 'C69F2D2A-7226-4D0A-87A8-3D53753C0572',
    name: 'numeric-7-box-multiple',
    codepoint: 'F0F15',
    deprecated: 'FALSE',
  },
  {
    id: 'CF43DB99-4419-491B-A5E3-2486A36F24DB',
    name: 'numeric-7-box-multiple-outline',
    codepoint: 'F03B7',
    deprecated: 'FALSE',
  },
  {
    id: '373DD873-E15F-4B7C-8328-76389DC81DB0',
    name: 'numeric-7-box-outline',
    codepoint: 'F03B8',
    deprecated: 'FALSE',
  },
  {
    id: 'FE25714F-69B5-463B-8C11-5E4E057C83C3',
    name: 'numeric-7-circle',
    codepoint: 'F0CAC',
    deprecated: 'FALSE',
  },
  {
    id: '67707F91-E498-46C6-816B-2A6D1BE437F6',
    name: 'numeric-7-circle-outline',
    codepoint: 'F0CAD',
    deprecated: 'FALSE',
  },
  {
    id: 'F92BFF4C-3D23-466A-BBE6-AF98DB0F43A9',
    name: 'numeric-8',
    codepoint: 'F0B41',
    deprecated: 'FALSE',
  },
  {
    id: '863E0667-F376-4303-9261-CAA4B7F073C6',
    name: 'numeric-8-box',
    codepoint: 'F03B9',
    deprecated: 'FALSE',
  },
  {
    id: 'D032F5F3-670D-4576-AD76-A17562377466',
    name: 'numeric-8-box-multiple',
    codepoint: 'F0F16',
    deprecated: 'FALSE',
  },
  {
    id: '869A508B-5F8E-41A7-BCE5-E205FC66C80F',
    name: 'numeric-8-box-multiple-outline',
    codepoint: 'F03BA',
    deprecated: 'FALSE',
  },
  {
    id: 'DC2B6D38-A6B3-4171-AAC6-E08EF2C23710',
    name: 'numeric-8-box-outline',
    codepoint: 'F03BB',
    deprecated: 'FALSE',
  },
  {
    id: '26382400-22B1-40A6-9C1A-46C649C36783',
    name: 'numeric-8-circle',
    codepoint: 'F0CAE',
    deprecated: 'FALSE',
  },
  {
    id: 'C1306EC5-E7F0-4152-A19D-84E851537171',
    name: 'numeric-8-circle-outline',
    codepoint: 'F0CAF',
    deprecated: 'FALSE',
  },
  {
    id: '11F0ADB8-0CC8-48A3-8653-11E7F58B5C9B',
    name: 'numeric-9',
    codepoint: 'F0B42',
    deprecated: 'FALSE',
  },
  {
    id: '12DCC44D-8BA2-4D25-A88B-665920C8B730',
    name: 'numeric-9-box',
    codepoint: 'F03BC',
    deprecated: 'FALSE',
  },
  {
    id: '3DAA435E-D837-4AD3-9937-0D554F409BB0',
    name: 'numeric-9-box-multiple',
    codepoint: 'F0F17',
    deprecated: 'FALSE',
  },
  {
    id: 'A41F44A0-816E-497E-A08C-C9646FE161BE',
    name: 'numeric-9-box-multiple-outline',
    codepoint: 'F03BD',
    deprecated: 'FALSE',
  },
  {
    id: 'EE2E9486-0C32-4EC9-9D4F-9B8A7287F696',
    name: 'numeric-9-box-outline',
    codepoint: 'F03BE',
    deprecated: 'FALSE',
  },
  {
    id: 'FB06011B-B2CC-4F3A-8ADE-2F7E78F0AE7C',
    name: 'numeric-9-circle',
    codepoint: 'F0CB0',
    deprecated: 'FALSE',
  },
  {
    id: 'F27ABCBB-C6D6-4C1E-AF0C-C406057245AA',
    name: 'numeric-9-circle-outline',
    codepoint: 'F0CB1',
    deprecated: 'FALSE',
  },
  {
    id: 'CF313E88-0D2F-49B9-B2BC-6651ACEAA677',
    name: 'numeric-9-plus',
    codepoint: 'F0FEE',
    deprecated: 'FALSE',
  },
  {
    id: '7CF309F0-D751-44A3-A313-BA7F2F8865FD',
    name: 'numeric-9-plus-box',
    codepoint: 'F03BF',
    deprecated: 'FALSE',
  },
  {
    id: '20E22DC1-795D-432F-9CB9-D339EA3B96DB',
    name: 'numeric-9-plus-box-multiple',
    codepoint: 'F0F18',
    deprecated: 'FALSE',
  },
  {
    id: '46A850FC-F6E6-4000-A93F-6B954DA62886',
    name: 'numeric-9-plus-box-multiple-outline',
    codepoint: 'F03C0',
    deprecated: 'FALSE',
  },
  {
    id: 'C1F38C40-BD01-45FE-A94C-B3C1432707E0',
    name: 'numeric-9-plus-box-outline',
    codepoint: 'F03C1',
    deprecated: 'FALSE',
  },
  {
    id: '757FDF81-DCCF-4B75-9A2A-9ED3D2DB966E',
    name: 'numeric-9-plus-circle',
    codepoint: 'F0CB2',
    deprecated: 'FALSE',
  },
  {
    id: '00BD21E8-FAF1-470D-8F64-0030C1C222F3',
    name: 'numeric-9-plus-circle-outline',
    codepoint: 'F0CB3',
    deprecated: 'FALSE',
  },
  {
    id: 'B198FE0B-15C3-4FFB-B5C9-2D6E60E825A1',
    name: 'numeric-negative-1',
    codepoint: 'F1052',
    deprecated: 'FALSE',
  },
  {
    id: '560615BB-A023-4B98-88A6-F38FBF3272E2',
    name: 'numeric-off',
    codepoint: 'F19D3',
    deprecated: 'FALSE',
  },
  {
    id: '73DE8F6E-A301-42D1-B2C3-3699265BA2BB',
    name: 'numeric-positive-1',
    codepoint: 'F15CB',
    deprecated: 'FALSE',
  },
  {
    id: '8B702257-4232-459E-BDA8-C4E698DF8E8E',
    name: 'nut',
    codepoint: 'F06F8',
    deprecated: 'FALSE',
  },
  {
    id: '723E0BBB-78CC-4192-98B1-426B17D694B9',
    name: 'nutrition',
    codepoint: 'F03C2',
    deprecated: 'FALSE',
  },
  {
    id: 'EDCDD59F-BD6B-49C9-B93A-36879031CEA3',
    name: 'nuxt',
    codepoint: 'F1106',
    deprecated: 'TRUE',
  },
  {
    id: 'A065A53E-118C-424A-8A81-0D300975AB04',
    name: 'oar',
    codepoint: 'F067C',
    deprecated: 'FALSE',
  },
  {
    id: '1C1A3850-DB11-4F6D-B031-8E4CE14E9760',
    name: 'ocarina',
    codepoint: 'F0DE0',
    deprecated: 'FALSE',
  },
  {
    id: '53021908-1FB5-4293-896E-94C5330E6101',
    name: 'oci',
    codepoint: 'F12E9',
    deprecated: 'TRUE',
  },
  {
    id: '2943D362-A548-448C-A868-19E6D37C8B2D',
    name: 'ocr',
    codepoint: 'F113A',
    deprecated: 'FALSE',
  },
  {
    id: '5E3C88DE-A208-471A-9096-7C7868CC957C',
    name: 'octagon',
    codepoint: 'F03C3',
    deprecated: 'FALSE',
  },
  {
    id: '3CF63E10-42E7-4E13-8BFF-10E9257953AB',
    name: 'octagon-outline',
    codepoint: 'F03C4',
    deprecated: 'FALSE',
  },
  {
    id: '455C8475-5555-49B7-825B-206581104AB2',
    name: 'octagram',
    codepoint: 'F06F9',
    deprecated: 'FALSE',
  },
  {
    id: '8316E0F1-5F66-4C4E-ACA8-EE61AD93A42A',
    name: 'octagram-outline',
    codepoint: 'F0775',
    deprecated: 'FALSE',
  },
  {
    id: '45CD61D9-B0D0-43FA-B50E-F6E71A95128A',
    name: 'octahedron',
    codepoint: 'F1950',
    deprecated: 'FALSE',
  },
  {
    id: 'AD67A41D-5524-4F72-8658-C3F78A06333E',
    name: 'octahedron-off',
    codepoint: 'F1951',
    deprecated: 'FALSE',
  },
  {
    id: '8BB24B94-491F-4465-8E87-45FF8FED7C4A',
    name: 'odnoklassniki',
    codepoint: 'F03C5',
    deprecated: 'TRUE',
  },
  {
    id: '64C3CB40-BBDF-4A82-9B07-62CA527A258C',
    name: 'offer',
    codepoint: 'F121B',
    deprecated: 'FALSE',
  },
  {
    id: 'C6BEE3A0-C734-48D4-A2E4-622E9B16CA06',
    name: 'office-building',
    codepoint: 'F0991',
    deprecated: 'FALSE',
  },
  {
    id: '4A51F91C-857B-454A-8753-7AACF3B085A9',
    name: 'office-building-cog',
    codepoint: 'F1949',
    deprecated: 'FALSE',
  },
  {
    id: '09BF7DAE-15CA-4539-BD8A-A741EB7E6405',
    name: 'office-building-cog-outline',
    codepoint: 'F194A',
    deprecated: 'FALSE',
  },
  {
    id: '8409886F-203E-45A5-8A70-C1670406BBA2',
    name: 'office-building-marker',
    codepoint: 'F1520',
    deprecated: 'FALSE',
  },
  {
    id: 'B9C8DF7B-34D7-4D58-B769-B3ABAD0990FC',
    name: 'office-building-marker-outline',
    codepoint: 'F1521',
    deprecated: 'FALSE',
  },
  {
    id: 'F328F94A-8174-49FC-9FB9-C2F28B1F7A4E',
    name: 'office-building-minus',
    codepoint: 'F1BAA',
    deprecated: 'FALSE',
  },
  {
    id: 'C993C6FF-1C20-4E52-BC40-A62004B89D56',
    name: 'office-building-minus-outline',
    codepoint: 'F1BAB',
    deprecated: 'FALSE',
  },
  {
    id: '1171525F-C510-44B9-9E5C-0F5CAC7A71D2',
    name: 'office-building-outline',
    codepoint: 'F151F',
    deprecated: 'FALSE',
  },
  {
    id: 'DD2249E8-1290-41DB-9D68-DD3142413A2A',
    name: 'office-building-plus',
    codepoint: 'F1BA8',
    deprecated: 'FALSE',
  },
  {
    id: '061CBB0D-D91D-4E6C-9C69-BCE3D119D45F',
    name: 'office-building-plus-outline',
    codepoint: 'F1BA9',
    deprecated: 'FALSE',
  },
  {
    id: '9FDDD96F-B993-47DD-BE60-1995CBBA973C',
    name: 'office-building-remove',
    codepoint: 'F1BAC',
    deprecated: 'FALSE',
  },
  {
    id: '1B9F3A92-AC49-4D8E-8CE9-07A70164548F',
    name: 'office-building-remove-outline',
    codepoint: 'F1BAD',
    deprecated: 'FALSE',
  },
  {
    id: 'DE398350-B7B7-4E6B-AE4C-F99AAE56C7CF',
    name: 'oil',
    codepoint: 'F03C7',
    deprecated: 'FALSE',
  },
  {
    id: '790DAFDA-8291-47F5-A5B9-9563AE201832',
    name: 'oil-lamp',
    codepoint: 'F0F19',
    deprecated: 'FALSE',
  },
  {
    id: 'A6579EB0-C38D-4052-A6C9-C1ECD97E482C',
    name: 'oil-level',
    codepoint: 'F1053',
    deprecated: 'FALSE',
  },
  {
    id: 'C8743DBF-39CD-43E8-8EC3-B99D422422FC',
    name: 'oil-temperature',
    codepoint: 'F0FF8',
    deprecated: 'FALSE',
  },
  {
    id: 'F1EE1708-7EAF-4929-97AA-D4BAC093D157',
    name: 'om',
    codepoint: 'F0973',
    deprecated: 'FALSE',
  },
  {
    id: 'A98F63CB-AB27-4A2D-B76C-6994507BCDBB',
    name: 'omega',
    codepoint: 'F03C9',
    deprecated: 'FALSE',
  },
  {
    id: 'DF8CCF67-8BD2-4632-8647-2B42BA2047A5',
    name: 'one-up',
    codepoint: 'F0BAD',
    deprecated: 'FALSE',
  },
  {
    id: '2B9B64C7-B799-45C5-BDCC-BEC3B8672CFE',
    name: 'onepassword',
    codepoint: 'F0881',
    deprecated: 'TRUE',
  },
  {
    id: '27C36043-E269-4223-ADCE-568B1EFB4623',
    name: 'opacity',
    codepoint: 'F05CC',
    deprecated: 'FALSE',
  },
  {
    id: 'AF69E2E1-8119-49A2-8E24-915E2254522A',
    name: 'open-in-app',
    codepoint: 'F03CB',
    deprecated: 'FALSE',
  },
  {
    id: '12B95238-1D74-4C6C-9BBD-C055B4DD0E4D',
    name: 'open-in-new',
    codepoint: 'F03CC',
    deprecated: 'FALSE',
  },
  {
    id: 'DA48F078-4BE9-42DC-AF35-F557E16DD2AE',
    name: 'open-source-initiative',
    codepoint: 'F0BAE',
    deprecated: 'TRUE',
  },
  {
    id: 'C9525FF9-FE74-467C-99CE-F8DD8FE3576C',
    name: 'openid',
    codepoint: 'F03CD',
    deprecated: 'TRUE',
  },
  {
    id: 'A0BCE6D1-D558-487E-9629-F7CCC495C27B',
    name: 'opera',
    codepoint: 'F03CE',
    deprecated: 'TRUE',
  },
  {
    id: 'D672819E-C3D1-49FC-A1E7-9F4732D034DC',
    name: 'orbit',
    codepoint: 'F0018',
    deprecated: 'FALSE',
  },
  {
    id: '4A0C34D7-1D3D-472B-9E5D-858DBBCBC7B2',
    name: 'orbit-variant',
    codepoint: 'F15DB',
    deprecated: 'FALSE',
  },
  {
    id: 'B6FBB02F-98A0-4521-BA9B-D00EA8A2FC57',
    name: 'order-alphabetical-ascending',
    codepoint: 'F020D',
    deprecated: 'FALSE',
  },
  {
    id: 'D9CEAAB0-32D3-4A42-A0F3-74B1FC50E9F0',
    name: 'order-alphabetical-descending',
    codepoint: 'F0D07',
    deprecated: 'FALSE',
  },
  {
    id: '2D12922D-3C06-4916-9575-CA9A1014C4BF',
    name: 'order-bool-ascending',
    codepoint: 'F02BE',
    deprecated: 'FALSE',
  },
  {
    id: 'FA5DB7BA-69CB-4DF5-9DE9-B0681889CB2D',
    name: 'order-bool-ascending-variant',
    codepoint: 'F098F',
    deprecated: 'FALSE',
  },
  {
    id: '62006FDB-1540-4E32-B74D-A758C8974661',
    name: 'order-bool-descending',
    codepoint: 'F1384',
    deprecated: 'FALSE',
  },
  {
    id: '56E79669-E557-4D22-A21D-E07FFDB1C2B6',
    name: 'order-bool-descending-variant',
    codepoint: 'F0990',
    deprecated: 'FALSE',
  },
  {
    id: '1ADB886B-B791-42BF-AC04-5EB73E665EA6',
    name: 'order-numeric-ascending',
    codepoint: 'F0545',
    deprecated: 'FALSE',
  },
  {
    id: '3979C805-4C53-4F45-A6E5-9CAADE1C2D80',
    name: 'order-numeric-descending',
    codepoint: 'F0546',
    deprecated: 'FALSE',
  },
  {
    id: 'E41855D5-FB0E-4FAD-8CD8-D085B1577D2F',
    name: 'origin',
    codepoint: 'F0B43',
    deprecated: 'TRUE',
  },
  {
    id: 'ADC4F1DD-B840-4C28-8D5C-08F3BE15A424',
    name: 'ornament',
    codepoint: 'F03CF',
    deprecated: 'FALSE',
  },
  {
    id: '9C3E1D75-FB73-44AA-8BCC-9B30692D8D79',
    name: 'ornament-variant',
    codepoint: 'F03D0',
    deprecated: 'FALSE',
  },
  {
    id: '09F61849-C1AA-4755-B49E-75DB800B5273',
    name: 'outdoor-lamp',
    codepoint: 'F1054',
    deprecated: 'FALSE',
  },
  {
    id: '059B5D2A-9B8B-412D-BAF1-F3F9B7E14A44',
    name: 'overscan',
    codepoint: 'F1005',
    deprecated: 'FALSE',
  },
  {
    id: '1F963457-7A4F-4BEE-AE9C-0E54CF804DD3',
    name: 'owl',
    codepoint: 'F03D2',
    deprecated: 'FALSE',
  },
  {
    id: '29FC6427-61B9-4961-9004-FCD4A6E6522D',
    name: 'pac-man',
    codepoint: 'F0BAF',
    deprecated: 'FALSE',
  },
  {
    id: '57771FFC-0E2C-40DE-AF7C-F3F51EFFE2C0',
    name: 'package',
    codepoint: 'F03D3',
    deprecated: 'FALSE',
  },
  {
    id: 'B1DB46BF-FA20-40F6-88CD-A5B06CB3E488',
    name: 'package-check',
    codepoint: 'F1B51',
    deprecated: 'FALSE',
  },
  {
    id: '0C18B335-700A-4D68-BF88-C95A69479F2E',
    name: 'package-down',
    codepoint: 'F03D4',
    deprecated: 'FALSE',
  },
  {
    id: '383EF914-6014-473D-8C54-0314432007E3',
    name: 'package-up',
    codepoint: 'F03D5',
    deprecated: 'FALSE',
  },
  {
    id: '558C8C17-E4E1-4921-874D-F9ACB26C35C8',
    name: 'package-variant',
    codepoint: 'F03D6',
    deprecated: 'FALSE',
  },
  {
    id: '290F16B3-147C-446D-B14B-56AECCA76276',
    name: 'package-variant-closed',
    codepoint: 'F03D7',
    deprecated: 'FALSE',
  },
  {
    id: 'CAA724DF-E0C7-4010-8265-0C2E594D3094',
    name: 'package-variant-closed-check',
    codepoint: 'F1B52',
    deprecated: 'FALSE',
  },
  {
    id: 'DF9F88A2-7B9A-4881-A2E4-6BC6F4387A0F',
    name: 'package-variant-closed-minus',
    codepoint: 'F19D4',
    deprecated: 'FALSE',
  },
  {
    id: '749AB237-2B69-4BB9-9B2D-6F22B1F85D50',
    name: 'package-variant-closed-plus',
    codepoint: 'F19D5',
    deprecated: 'FALSE',
  },
  {
    id: '425F62CE-0308-47E5-9825-CEE711EE34B2',
    name: 'package-variant-closed-remove',
    codepoint: 'F19D6',
    deprecated: 'FALSE',
  },
  {
    id: 'C204643B-C7FA-48A9-9910-C2258F77E707',
    name: 'package-variant-minus',
    codepoint: 'F19D7',
    deprecated: 'FALSE',
  },
  {
    id: '8E0543FE-6EC4-4D0F-9109-E77254414DD7',
    name: 'package-variant-plus',
    codepoint: 'F19D8',
    deprecated: 'FALSE',
  },
  {
    id: '1E482E98-310D-4A28-B461-DD48ADE915E8',
    name: 'package-variant-remove',
    codepoint: 'F19D9',
    deprecated: 'FALSE',
  },
  {
    id: 'DF23FF47-4DD3-4CB3-9BEC-6EE986B0FA65',
    name: 'page-first',
    codepoint: 'F0600',
    deprecated: 'FALSE',
  },
  {
    id: 'CF9B4635-FE27-4DA5-BE51-F5F4116AFC54',
    name: 'page-last',
    codepoint: 'F0601',
    deprecated: 'FALSE',
  },
  {
    id: '39AF8334-4244-49DD-A8A4-D4F638B9D939',
    name: 'page-layout-body',
    codepoint: 'F06FA',
    deprecated: 'FALSE',
  },
  {
    id: '74636C38-381A-46B8-97FD-B312735DE60D',
    name: 'page-layout-footer',
    codepoint: 'F06FB',
    deprecated: 'FALSE',
  },
  {
    id: '3606AC64-ABC8-4DA6-ACE8-64D58E0B0A46',
    name: 'page-layout-header',
    codepoint: 'F06FC',
    deprecated: 'FALSE',
  },
  {
    id: 'C1258752-7002-4714-81E3-D90828B5A896',
    name: 'page-layout-header-footer',
    codepoint: 'F0F7F',
    deprecated: 'FALSE',
  },
  {
    id: '6E39564D-2B4B-4940-9E74-5274ED12E800',
    name: 'page-layout-sidebar-left',
    codepoint: 'F06FD',
    deprecated: 'FALSE',
  },
  {
    id: '29C8714B-1CAD-41B8-829B-2A14F6F87684',
    name: 'page-layout-sidebar-right',
    codepoint: 'F06FE',
    deprecated: 'FALSE',
  },
  {
    id: '760D3DDF-F710-4BE1-A4E6-CC5AD6AF49DB',
    name: 'page-next',
    codepoint: 'F0BB0',
    deprecated: 'FALSE',
  },
  {
    id: '9213C60D-0232-4ECB-91C6-8D2BF812FE6A',
    name: 'page-next-outline',
    codepoint: 'F0BB1',
    deprecated: 'FALSE',
  },
  {
    id: 'AF1A8DA7-B3AC-4E11-A50B-59FBA8B34CF5',
    name: 'page-previous',
    codepoint: 'F0BB2',
    deprecated: 'FALSE',
  },
  {
    id: 'A26AE0CE-1223-4A40-B7B6-E4FDCC5AE820',
    name: 'page-previous-outline',
    codepoint: 'F0BB3',
    deprecated: 'FALSE',
  },
  {
    id: 'C4C17ED6-0DB6-4A13-9C6F-0C3146B0CD80',
    name: 'pail',
    codepoint: 'F1417',
    deprecated: 'FALSE',
  },
  {
    id: 'ECD25F41-E1C8-438E-BFF3-54F529504071',
    name: 'pail-minus',
    codepoint: 'F1437',
    deprecated: 'FALSE',
  },
  {
    id: '599354BE-5249-40F9-8E62-466F89203E5B',
    name: 'pail-minus-outline',
    codepoint: 'F143C',
    deprecated: 'FALSE',
  },
  {
    id: '2F1D4972-0025-4EB4-ADFC-2ACBEF34C7EA',
    name: 'pail-off',
    codepoint: 'F1439',
    deprecated: 'FALSE',
  },
  {
    id: '896E500B-797D-4687-917C-063906BB503F',
    name: 'pail-off-outline',
    codepoint: 'F143E',
    deprecated: 'FALSE',
  },
  {
    id: '02152202-9609-4E5D-A6C5-1C7FE35F97E8',
    name: 'pail-outline',
    codepoint: 'F143A',
    deprecated: 'FALSE',
  },
  {
    id: 'ED64CE31-8F78-4383-A3BF-2AFBE5485E38',
    name: 'pail-plus',
    codepoint: 'F1436',
    deprecated: 'FALSE',
  },
  {
    id: '63B16204-CDD1-425F-8DDD-7DD80F2639D9',
    name: 'pail-plus-outline',
    codepoint: 'F143B',
    deprecated: 'FALSE',
  },
  {
    id: 'FC5A2451-43C6-40D1-905D-7E473ACA134C',
    name: 'pail-remove',
    codepoint: 'F1438',
    deprecated: 'FALSE',
  },
  {
    id: '48413062-A335-454F-9349-04CD2B7BC628',
    name: 'pail-remove-outline',
    codepoint: 'F143D',
    deprecated: 'FALSE',
  },
  {
    id: '954CCE2B-7FEE-4F9C-9923-4E1D567607C3',
    name: 'palette',
    codepoint: 'F03D8',
    deprecated: 'FALSE',
  },
  {
    id: '3617B5EE-9A4A-4C3C-9C9A-8C69C7569EB4',
    name: 'palette-advanced',
    codepoint: 'F03D9',
    deprecated: 'FALSE',
  },
  {
    id: '6C41ED7D-CA9F-45BA-B94D-F20ABB54519B',
    name: 'palette-outline',
    codepoint: 'F0E0C',
    deprecated: 'FALSE',
  },
  {
    id: '158F8306-CE63-45E8-94E5-58484FF5F050',
    name: 'palette-swatch',
    codepoint: 'F08B5',
    deprecated: 'FALSE',
  },
  {
    id: '3D2690B4-4A3D-4B7C-B384-1C0AB180DD0B',
    name: 'palette-swatch-outline',
    codepoint: 'F135C',
    deprecated: 'FALSE',
  },
  {
    id: 'C4B3CA22-1868-4216-807B-ED852BAA3C21',
    name: 'palette-swatch-variant',
    codepoint: 'F195A',
    deprecated: 'FALSE',
  },
  {
    id: '787F9145-5272-4028-94C8-46B0623FE039',
    name: 'palm-tree',
    codepoint: 'F1055',
    deprecated: 'FALSE',
  },
  {
    id: 'FEDAC98D-95D1-44FE-81DE-2BAACA196408',
    name: 'pan',
    codepoint: 'F0BB4',
    deprecated: 'FALSE',
  },
  {
    id: 'F5AFD529-AD6F-4A1B-AB69-E3E10FD0E654',
    name: 'pan-bottom-left',
    codepoint: 'F0BB5',
    deprecated: 'FALSE',
  },
  {
    id: 'BAAF741C-C242-49A8-8A16-DA1EC373385D',
    name: 'pan-bottom-right',
    codepoint: 'F0BB6',
    deprecated: 'FALSE',
  },
  {
    id: '408FB11D-9531-4C8F-A592-48CD10A1AF4B',
    name: 'pan-down',
    codepoint: 'F0BB7',
    deprecated: 'FALSE',
  },
  {
    id: '9881BB2E-CCAF-4698-83B5-452ED749C314',
    name: 'pan-horizontal',
    codepoint: 'F0BB8',
    deprecated: 'FALSE',
  },
  {
    id: '59CEB0A5-75F5-4D3D-AF4D-2F0D8AEAEB0C',
    name: 'pan-left',
    codepoint: 'F0BB9',
    deprecated: 'FALSE',
  },
  {
    id: 'D563253F-1895-4DB7-95E9-A0C4C5562D12',
    name: 'pan-right',
    codepoint: 'F0BBA',
    deprecated: 'FALSE',
  },
  {
    id: 'C497D005-A9A3-4B1A-B440-CE574E81AF2F',
    name: 'pan-top-left',
    codepoint: 'F0BBB',
    deprecated: 'FALSE',
  },
  {
    id: 'E9886955-5257-4E95-A017-C903F5F850C2',
    name: 'pan-top-right',
    codepoint: 'F0BBC',
    deprecated: 'FALSE',
  },
  {
    id: '989DC863-1210-44A7-88A3-B414994983F6',
    name: 'pan-up',
    codepoint: 'F0BBD',
    deprecated: 'FALSE',
  },
  {
    id: 'A27AD714-E988-4970-B995-8EEADCDA5D55',
    name: 'pan-vertical',
    codepoint: 'F0BBE',
    deprecated: 'FALSE',
  },
  {
    id: 'A797FE46-DF68-4179-85BE-172BD79F0667',
    name: 'panda',
    codepoint: 'F03DA',
    deprecated: 'FALSE',
  },
  {
    id: '24BCD03F-8D84-4154-95F5-086230CE3BBA',
    name: 'pandora',
    codepoint: 'F03DB',
    deprecated: 'TRUE',
  },
  {
    id: '4054A322-F348-49BA-B85F-B978EFC1BDAD',
    name: 'panorama',
    codepoint: 'F03DC',
    deprecated: 'FALSE',
  },
  {
    id: '6D0BD840-6985-440A-9C55-782B5C3F4F9C',
    name: 'panorama-fisheye',
    codepoint: 'F03DD',
    deprecated: 'FALSE',
  },
  {
    id: 'FCE8C530-5626-4B4A-9260-ECD7C1E93956',
    name: 'panorama-horizontal',
    codepoint: 'F1928',
    deprecated: 'FALSE',
  },
  {
    id: '42B1DAD8-8731-49DB-B415-9BFD78A8DDEA',
    name: 'panorama-horizontal-outline',
    codepoint: 'F03DE',
    deprecated: 'FALSE',
  },
  {
    id: 'E676EFD3-32EC-4F24-9A9A-65EF5518AA01',
    name: 'panorama-outline',
    codepoint: 'F198C',
    deprecated: 'FALSE',
  },
  {
    id: 'B0B17758-277D-4AA6-8567-E87451EF1139',
    name: 'panorama-sphere',
    codepoint: 'F198D',
    deprecated: 'FALSE',
  },
  {
    id: 'F364E6E7-1129-4076-B915-0DA77003C891',
    name: 'panorama-sphere-outline',
    codepoint: 'F198E',
    deprecated: 'FALSE',
  },
  {
    id: '06830F7E-C93D-445A-AED5-7832A9E885C5',
    name: 'panorama-variant',
    codepoint: 'F198F',
    deprecated: 'FALSE',
  },
  {
    id: 'BE67BFD1-97AD-4EC3-B3E7-19BA48708172',
    name: 'panorama-variant-outline',
    codepoint: 'F1990',
    deprecated: 'FALSE',
  },
  {
    id: 'DB54A44E-20B6-435E-9CFE-D53966AE0855',
    name: 'panorama-vertical',
    codepoint: 'F1929',
    deprecated: 'FALSE',
  },
  {
    id: '7EC1E0AF-1AC5-4CC8-BDCC-5E2D3BF2AC70',
    name: 'panorama-vertical-outline',
    codepoint: 'F03DF',
    deprecated: 'FALSE',
  },
  {
    id: '64F3926D-42E9-4819-92A2-C524031CD9BA',
    name: 'panorama-wide-angle',
    codepoint: 'F195F',
    deprecated: 'FALSE',
  },
  {
    id: '12D83006-87DE-49CE-B8C3-7D6492934CDA',
    name: 'panorama-wide-angle-outline',
    codepoint: 'F03E0',
    deprecated: 'FALSE',
  },
  {
    id: '4E8B4DBC-83EE-4CBD-BEDD-5866DE057324',
    name: 'paper-cut-vertical',
    codepoint: 'F03E1',
    deprecated: 'FALSE',
  },
  {
    id: '1666E605-F46A-4AD2-8345-A599811B7D45',
    name: 'paper-roll',
    codepoint: 'F1157',
    deprecated: 'FALSE',
  },
  {
    id: '2A7CBB78-4672-450C-8578-48AFB13243CD',
    name: 'paper-roll-outline',
    codepoint: 'F1158',
    deprecated: 'FALSE',
  },
  {
    id: '27BCEC7F-ABF8-4506-B339-EE2425E1BAF9',
    name: 'paperclip',
    codepoint: 'F03E2',
    deprecated: 'FALSE',
  },
  {
    id: '8B0FAA58-E315-4474-B9B0-E7E10CBC9542',
    name: 'paperclip-check',
    codepoint: 'F1AC6',
    deprecated: 'FALSE',
  },
  {
    id: '3A005420-791A-45DA-A903-EED63E35C1C6',
    name: 'paperclip-lock',
    codepoint: 'F19DA',
    deprecated: 'FALSE',
  },
  {
    id: '9D2D90D0-260E-4465-817D-875B741B62F3',
    name: 'paperclip-minus',
    codepoint: 'F1AC7',
    deprecated: 'FALSE',
  },
  {
    id: '1DA3EF2F-D91E-40AD-B325-FF4E42BF76DD',
    name: 'paperclip-off',
    codepoint: 'F1AC8',
    deprecated: 'FALSE',
  },
  {
    id: '3AC39192-3F6B-4BCB-9F13-110C362619FA',
    name: 'paperclip-plus',
    codepoint: 'F1AC9',
    deprecated: 'FALSE',
  },
  {
    id: 'BF4A933D-776E-4533-B9ED-88F9795E1598',
    name: 'paperclip-remove',
    codepoint: 'F1ACA',
    deprecated: 'FALSE',
  },
  {
    id: '47070C5A-B989-49C6-A635-3B764DC112CB',
    name: 'parachute',
    codepoint: 'F0CB4',
    deprecated: 'FALSE',
  },
  {
    id: '739633DB-1136-4AD6-8E4E-80077562E9F1',
    name: 'parachute-outline',
    codepoint: 'F0CB5',
    deprecated: 'FALSE',
  },
  {
    id: '24CD918E-9458-4D8D-A00C-746F8AB5D17E',
    name: 'paragliding',
    codepoint: 'F1745',
    deprecated: 'FALSE',
  },
  {
    id: '07C8266A-A135-443C-B2DD-4148CD718C51',
    name: 'parking',
    codepoint: 'F03E3',
    deprecated: 'FALSE',
  },
  {
    id: 'AED614C4-13E2-4009-9D9F-950258DB5124',
    name: 'party-popper',
    codepoint: 'F1056',
    deprecated: 'FALSE',
  },
  {
    id: '31BE5768-FCE0-46D5-A20B-E8CBFED471E0',
    name: 'passport',
    codepoint: 'F07E3',
    deprecated: 'FALSE',
  },
  {
    id: '86A974C4-6E5A-48F6-A3B9-35B3DCD1DE7A',
    name: 'passport-biometric',
    codepoint: 'F0DE1',
    deprecated: 'FALSE',
  },
  {
    id: '8288CA76-67E5-4EEA-82CD-DE0E0842F34D',
    name: 'pasta',
    codepoint: 'F1160',
    deprecated: 'FALSE',
  },
  {
    id: '03B35003-B782-475E-AE4C-13021109AD97',
    name: 'patio-heater',
    codepoint: 'F0F80',
    deprecated: 'FALSE',
  },
  {
    id: '9B72D845-E704-489C-A447-D02306AF5228',
    name: 'patreon',
    codepoint: 'F0882',
    deprecated: 'TRUE',
  },
  {
    id: '2A1F0831-13CA-4419-BBD7-1F1FAF62A32B',
    name: 'pause',
    codepoint: 'F03E4',
    deprecated: 'FALSE',
  },
  {
    id: '6B7FCAFD-C14A-49FC-A959-A2B6C7C2C5BC',
    name: 'pause-box',
    codepoint: 'F00BC',
    deprecated: 'FALSE',
  },
  {
    id: '8F48F520-011A-4B09-853E-33E1C7D5714A',
    name: 'pause-box-outline',
    codepoint: 'F1B7A',
    deprecated: 'FALSE',
  },
  {
    id: '7AA13CBC-D472-4462-8263-88DA2BDCD6BB',
    name: 'pause-circle',
    codepoint: 'F03E5',
    deprecated: 'FALSE',
  },
  {
    id: '6F271406-B475-45D0-9B37-EF3DBE7D0DAA',
    name: 'pause-circle-outline',
    codepoint: 'F03E6',
    deprecated: 'FALSE',
  },
  {
    id: 'D0A95719-9B9F-4E86-A6E5-8013C1A6D4A7',
    name: 'pause-octagon',
    codepoint: 'F03E7',
    deprecated: 'FALSE',
  },
  {
    id: '95AA0ED2-4221-4123-B3BF-9FACF3702CA4',
    name: 'pause-octagon-outline',
    codepoint: 'F03E8',
    deprecated: 'FALSE',
  },
  {
    id: '802EBFA2-6D86-4E93-BEBF-7F69F6C98C01',
    name: 'paw',
    codepoint: 'F03E9',
    deprecated: 'FALSE',
  },
  {
    id: '039D7C7E-AB09-4364-B08D-1FC120442AFE',
    name: 'paw-off',
    codepoint: 'F0657',
    deprecated: 'FALSE',
  },
  {
    id: 'EADDFE7F-B2A0-40AD-B060-555080D02BCB',
    name: 'paw-off-outline',
    codepoint: 'F1676',
    deprecated: 'FALSE',
  },
  {
    id: 'C0FB173F-3D34-4DC2-9D54-C82BEE350FFB',
    name: 'paw-outline',
    codepoint: 'F1675',
    deprecated: 'FALSE',
  },
  {
    id: '7E574E80-7DB1-4722-9BC4-60F53509AACD',
    name: 'peace',
    codepoint: 'F0884',
    deprecated: 'FALSE',
  },
  {
    id: '87A5BD64-6069-4C5B-8D50-69D9049B80BC',
    name: 'peanut',
    codepoint: 'F0FFC',
    deprecated: 'FALSE',
  },
  {
    id: 'D8BD17A2-5CE7-4219-8363-E87D80C2E7DF',
    name: 'peanut-off',
    codepoint: 'F0FFD',
    deprecated: 'FALSE',
  },
  {
    id: 'A3B86034-EAD9-4DE1-AF76-A11F21285DF9',
    name: 'peanut-off-outline',
    codepoint: 'F0FFF',
    deprecated: 'FALSE',
  },
  {
    id: 'C3883026-99A7-48ED-898E-37AC117A6C4F',
    name: 'peanut-outline',
    codepoint: 'F0FFE',
    deprecated: 'FALSE',
  },
  {
    id: '054B1710-EE28-4AA8-9D7E-705B5703E33E',
    name: 'pen',
    codepoint: 'F03EA',
    deprecated: 'FALSE',
  },
  {
    id: 'BDCCD15F-231E-4449-AADF-E2D21286396A',
    name: 'pen-lock',
    codepoint: 'F0DE2',
    deprecated: 'FALSE',
  },
  {
    id: '19D98B8F-B4D1-49B2-A870-20F7E72E4133',
    name: 'pen-minus',
    codepoint: 'F0DE3',
    deprecated: 'FALSE',
  },
  {
    id: '9D2F36EB-BE54-482B-9EBD-083699503E16',
    name: 'pen-off',
    codepoint: 'F0DE4',
    deprecated: 'FALSE',
  },
  {
    id: '299ED912-E240-41D8-AA28-5FB0025BC782',
    name: 'pen-plus',
    codepoint: 'F0DE5',
    deprecated: 'FALSE',
  },
  {
    id: '71997C15-3E04-4212-B08D-3F03AA110261',
    name: 'pen-remove',
    codepoint: 'F0DE6',
    deprecated: 'FALSE',
  },
  {
    id: 'BB27CE6C-769A-4C0C-A53C-F1B10B9C4423',
    name: 'pencil',
    codepoint: 'F03EB',
    deprecated: 'FALSE',
  },
  {
    id: '56CC04F4-4E58-434D-962A-C824F5959D20',
    name: 'pencil-box',
    codepoint: 'F03EC',
    deprecated: 'FALSE',
  },
  {
    id: '3E7031C9-56EE-458C-BE57-B1A737E09998',
    name: 'pencil-box-multiple',
    codepoint: 'F1144',
    deprecated: 'FALSE',
  },
  {
    id: '071E2A53-1647-4747-98BE-A7787AF39C15',
    name: 'pencil-box-multiple-outline',
    codepoint: 'F1145',
    deprecated: 'FALSE',
  },
  {
    id: '48A9B269-08B8-4DCB-85F1-68F2334DC004',
    name: 'pencil-box-outline',
    codepoint: 'F03ED',
    deprecated: 'FALSE',
  },
  {
    id: 'F5C5B903-7160-4B46-AC15-28C965289469',
    name: 'pencil-circle',
    codepoint: 'F06FF',
    deprecated: 'FALSE',
  },
  {
    id: '5D68DE43-969F-40BE-8A2D-F5C25F2B0853',
    name: 'pencil-circle-outline',
    codepoint: 'F0776',
    deprecated: 'FALSE',
  },
  {
    id: '33BBFD25-1E97-49AA-BA9A-0B42369DF4A6',
    name: 'pencil-lock',
    codepoint: 'F03EE',
    deprecated: 'FALSE',
  },
  {
    id: '0EDDA4FC-DC06-4621-AE19-5E0CF693997B',
    name: 'pencil-lock-outline',
    codepoint: 'F0DE7',
    deprecated: 'FALSE',
  },
  {
    id: '07FDD339-D255-4ED3-BDEB-00B57F521A0E',
    name: 'pencil-minus',
    codepoint: 'F0DE8',
    deprecated: 'FALSE',
  },
  {
    id: '030723AA-4B87-4648-8A6A-CECB909A3140',
    name: 'pencil-minus-outline',
    codepoint: 'F0DE9',
    deprecated: 'FALSE',
  },
  {
    id: 'AEB0F9CD-B3FF-4F64-9D6E-4916D5C720C3',
    name: 'pencil-off',
    codepoint: 'F03EF',
    deprecated: 'FALSE',
  },
  {
    id: '5B4952DA-7867-403A-AC98-12CFA78B52C0',
    name: 'pencil-off-outline',
    codepoint: 'F0DEA',
    deprecated: 'FALSE',
  },
  {
    id: 'A17D67B7-C5FF-45E2-91AE-975584067388',
    name: 'pencil-outline',
    codepoint: 'F0CB6',
    deprecated: 'FALSE',
  },
  {
    id: 'F6194D2A-B09D-416D-98B9-C80FDA45D831',
    name: 'pencil-plus',
    codepoint: 'F0DEB',
    deprecated: 'FALSE',
  },
  {
    id: '831CF37A-6530-48BB-8CB7-B3FF50D61860',
    name: 'pencil-plus-outline',
    codepoint: 'F0DEC',
    deprecated: 'FALSE',
  },
  {
    id: 'B536A740-BC15-4F6B-A923-28450B1ED3BD',
    name: 'pencil-remove',
    codepoint: 'F0DED',
    deprecated: 'FALSE',
  },
  {
    id: 'EEEC97B0-5E06-4D77-9934-6AC66B8F8538',
    name: 'pencil-remove-outline',
    codepoint: 'F0DEE',
    deprecated: 'FALSE',
  },
  {
    id: 'B8F0B132-5AD7-4E4B-ADDB-311FDBDD35CC',
    name: 'pencil-ruler',
    codepoint: 'F1353',
    deprecated: 'FALSE',
  },
  {
    id: '1D79B079-87DB-4956-A68D-DE69EE3C371E',
    name: 'pencil-ruler-outline',
    codepoint: 'F1C11',
    deprecated: 'FALSE',
  },
  {
    id: 'B2649A8A-D1EC-43AF-9C99-F233AF709155',
    name: 'penguin',
    codepoint: 'F0EC0',
    deprecated: 'FALSE',
  },
  {
    id: 'A1E0EA60-79AF-42FD-966D-485276671F9C',
    name: 'pentagon',
    codepoint: 'F0701',
    deprecated: 'FALSE',
  },
  {
    id: '7251D20A-5A23-456D-9A68-0107EF2BF01B',
    name: 'pentagon-outline',
    codepoint: 'F0700',
    deprecated: 'FALSE',
  },
  {
    id: 'EA9C6D1A-79AE-4114-9AF6-59A2A39FC8DF',
    name: 'pentagram',
    codepoint: 'F1667',
    deprecated: 'FALSE',
  },
  {
    id: '3AB307BF-41D9-4CA4-9D44-58C82304C7BB',
    name: 'percent',
    codepoint: 'F03F0',
    deprecated: 'FALSE',
  },
  {
    id: 'DD3C1F53-8265-4133-8C96-BC9EFF0A30D5',
    name: 'percent-box',
    codepoint: 'F1A02',
    deprecated: 'FALSE',
  },
  {
    id: '4B5165F6-736B-44F7-961C-420A1F2EEFE7',
    name: 'percent-box-outline',
    codepoint: 'F1A03',
    deprecated: 'FALSE',
  },
  {
    id: 'CA4A3F7A-B20E-4937-9FD6-207B1F974DE8',
    name: 'percent-circle',
    codepoint: 'F1A04',
    deprecated: 'FALSE',
  },
  {
    id: '30332F97-18B5-4866-A31F-912C262ACDBE',
    name: 'percent-circle-outline',
    codepoint: 'F1A05',
    deprecated: 'FALSE',
  },
  {
    id: '0625AC7C-AD52-4221-8F53-A9AE6DAA57BD',
    name: 'percent-outline',
    codepoint: 'F1278',
    deprecated: 'FALSE',
  },
  {
    id: '1DB2BD6D-830D-4A4F-8E04-94D14901895A',
    name: 'periodic-table',
    codepoint: 'F08B6',
    deprecated: 'FALSE',
  },
  {
    id: '408FA22D-2054-4579-A991-DF061CDE5A00',
    name: 'perspective-less',
    codepoint: 'F0D23',
    deprecated: 'FALSE',
  },
  {
    id: '6F54CAE0-D2B7-4E00-83AA-0B98A7601CF6',
    name: 'perspective-more',
    codepoint: 'F0D24',
    deprecated: 'FALSE',
  },
  {
    id: '57738B55-57CB-47EF-9758-32686AA78212',
    name: 'ph',
    codepoint: 'F17C5',
    deprecated: 'FALSE',
  },
  {
    id: 'B5BA809F-FCE8-476C-8388-6A9CFFF73015',
    name: 'phone',
    codepoint: 'F03F2',
    deprecated: 'FALSE',
  },
  {
    id: 'B7AE9DEE-6F1D-4CAF-9FD8-C6A6BF5D739D',
    name: 'phone-alert',
    codepoint: 'F0F1A',
    deprecated: 'FALSE',
  },
  {
    id: 'A4D67201-7E48-469A-A424-0D772DBAD344',
    name: 'phone-alert-outline',
    codepoint: 'F118E',
    deprecated: 'FALSE',
  },
  {
    id: '34F52268-8571-4501-93C1-D4BF606BB10E',
    name: 'phone-bluetooth',
    codepoint: 'F03F3',
    deprecated: 'FALSE',
  },
  {
    id: '64F560E8-6BEE-4CDC-81D4-FE87B76C906C',
    name: 'phone-bluetooth-outline',
    codepoint: 'F118F',
    deprecated: 'FALSE',
  },
  {
    id: 'C97B6119-5EB1-4930-AEFA-7E3E216D3875',
    name: 'phone-cancel',
    codepoint: 'F10BC',
    deprecated: 'FALSE',
  },
  {
    id: '70F0895F-EF78-404A-9CBE-D3FCAD39CE32',
    name: 'phone-cancel-outline',
    codepoint: 'F1190',
    deprecated: 'FALSE',
  },
  {
    id: '7FA6178B-0275-4545-BBCB-F10B77E9603D',
    name: 'phone-check',
    codepoint: 'F11A9',
    deprecated: 'FALSE',
  },
  {
    id: 'A91DFA4F-355A-4CB2-9013-F2ADFAA482D4',
    name: 'phone-check-outline',
    codepoint: 'F11AA',
    deprecated: 'FALSE',
  },
  {
    id: 'A9ED94F7-0ADB-4CB3-AAF1-35B87D9B7724',
    name: 'phone-classic',
    codepoint: 'F0602',
    deprecated: 'FALSE',
  },
  {
    id: '4D938684-537A-4ED1-B227-6859EA3DADAF',
    name: 'phone-classic-off',
    codepoint: 'F1279',
    deprecated: 'FALSE',
  },
  {
    id: '1B8EA976-32FD-4A71-94FA-EF40FC346FBD',
    name: 'phone-clock',
    codepoint: 'F19DB',
    deprecated: 'FALSE',
  },
  {
    id: '8F4DBE23-BD53-447F-917D-5343B4DDE8B8',
    name: 'phone-dial',
    codepoint: 'F1559',
    deprecated: 'FALSE',
  },
  {
    id: '90BA0013-C6A8-47E9-960C-A1DE13516B25',
    name: 'phone-dial-outline',
    codepoint: 'F155A',
    deprecated: 'FALSE',
  },
  {
    id: '5EF77B27-31D7-47F8-8D72-8BC040CD154E',
    name: 'phone-forward',
    codepoint: 'F03F4',
    deprecated: 'FALSE',
  },
  {
    id: 'B168C56E-76CD-47CC-AD20-BA116911E3F8',
    name: 'phone-forward-outline',
    codepoint: 'F1191',
    deprecated: 'FALSE',
  },
  {
    id: '2018B4C8-A296-4D62-A30A-85F988B206EA',
    name: 'phone-hangup',
    codepoint: 'F03F5',
    deprecated: 'FALSE',
  },
  {
    id: '56BBAAEB-F773-4C5D-B122-74F698048F5C',
    name: 'phone-hangup-outline',
    codepoint: 'F1192',
    deprecated: 'FALSE',
  },
  {
    id: 'F9BA7066-C1F8-4B19-9860-37BDCEB6F11C',
    name: 'phone-in-talk',
    codepoint: 'F03F6',
    deprecated: 'FALSE',
  },
  {
    id: 'BC90A08C-75FB-4E14-9795-3E408CB021AB',
    name: 'phone-in-talk-outline',
    codepoint: 'F1182',
    deprecated: 'FALSE',
  },
  {
    id: 'AA8EEDF8-EB34-46C5-8117-9258E1349197',
    name: 'phone-incoming',
    codepoint: 'F03F7',
    deprecated: 'FALSE',
  },
  {
    id: '2B5D9AA8-0D2D-455F-8585-B8BB5D69717B',
    name: 'phone-incoming-outgoing',
    codepoint: 'F1B3F',
    deprecated: 'FALSE',
  },
  {
    id: '3F877B20-07A5-43A8-BAAE-C89686C4E043',
    name: 'phone-incoming-outgoing-outline',
    codepoint: 'F1B40',
    deprecated: 'FALSE',
  },
  {
    id: '75D9229A-5E93-4E44-8259-0808D47D6458',
    name: 'phone-incoming-outline',
    codepoint: 'F1193',
    deprecated: 'FALSE',
  },
  {
    id: '31349A83-A803-48DF-98C8-55D5E27B81E4',
    name: 'phone-lock',
    codepoint: 'F03F8',
    deprecated: 'FALSE',
  },
  {
    id: '436AB859-CF22-4225-9606-5D2F603C102C',
    name: 'phone-lock-outline',
    codepoint: 'F1194',
    deprecated: 'FALSE',
  },
  {
    id: 'B87821E3-2131-4C50-BB88-880BE6D0066F',
    name: 'phone-log',
    codepoint: 'F03F9',
    deprecated: 'FALSE',
  },
  {
    id: '232EE7DD-4AC1-442D-B8E4-B914038E92CB',
    name: 'phone-log-outline',
    codepoint: 'F1195',
    deprecated: 'FALSE',
  },
  {
    id: '16930FBE-3379-43F2-BEA6-057D64D18348',
    name: 'phone-message',
    codepoint: 'F1196',
    deprecated: 'FALSE',
  },
  {
    id: 'CBA2C764-2B58-4914-A5C9-F0DAB190BB4C',
    name: 'phone-message-outline',
    codepoint: 'F1197',
    deprecated: 'FALSE',
  },
  {
    id: '7F431DF5-245C-4572-962C-CC57177BD8D0',
    name: 'phone-minus',
    codepoint: 'F0658',
    deprecated: 'FALSE',
  },
  {
    id: 'F27193C4-051E-4142-A17D-9AFD5D15687D',
    name: 'phone-minus-outline',
    codepoint: 'F1198',
    deprecated: 'FALSE',
  },
  {
    id: 'BF6662C4-D1D0-42E6-8C0A-B071F12975F2',
    name: 'phone-missed',
    codepoint: 'F03FA',
    deprecated: 'FALSE',
  },
  {
    id: '1E87E90C-5DCD-4250-81EF-61138B268FBB',
    name: 'phone-missed-outline',
    codepoint: 'F11A5',
    deprecated: 'FALSE',
  },
  {
    id: '43E0E6E7-ABC0-4146-A3D3-B2355FA84A5E',
    name: 'phone-off',
    codepoint: 'F0DEF',
    deprecated: 'FALSE',
  },
  {
    id: 'DDD4308D-34BE-4708-B9B6-E547DDAFC6B3',
    name: 'phone-off-outline',
    codepoint: 'F11A6',
    deprecated: 'FALSE',
  },
  {
    id: '52280181-270B-4624-97FE-F46126C65BE7',
    name: 'phone-outgoing',
    codepoint: 'F03FB',
    deprecated: 'FALSE',
  },
  {
    id: 'AE3A49AE-0125-448B-8EFF-0146293FD123',
    name: 'phone-outgoing-outline',
    codepoint: 'F1199',
    deprecated: 'FALSE',
  },
  {
    id: '79252282-1FDF-4DE8-A00F-51CD9D23311D',
    name: 'phone-outline',
    codepoint: 'F0DF0',
    deprecated: 'FALSE',
  },
  {
    id: '1ABFB2C1-72CB-46C7-8E8B-B8A24C0285EA',
    name: 'phone-paused',
    codepoint: 'F03FC',
    deprecated: 'FALSE',
  },
  {
    id: '3DF9D1F2-FE29-4385-9741-4B7F4B381EE6',
    name: 'phone-paused-outline',
    codepoint: 'F119A',
    deprecated: 'FALSE',
  },
  {
    id: 'E58A5CD8-2D42-4A3A-BCC2-70870B43752C',
    name: 'phone-plus',
    codepoint: 'F0659',
    deprecated: 'FALSE',
  },
  {
    id: '64C853E0-F60C-435D-9E93-261CCE84B0EB',
    name: 'phone-plus-outline',
    codepoint: 'F119B',
    deprecated: 'FALSE',
  },
  {
    id: 'F8A826E0-7094-488F-ADF4-ACC23B865B8E',
    name: 'phone-refresh',
    codepoint: 'F1993',
    deprecated: 'FALSE',
  },
  {
    id: '7B9734DE-AFAE-451B-B4DD-D10361B8148C',
    name: 'phone-refresh-outline',
    codepoint: 'F1994',
    deprecated: 'FALSE',
  },
  {
    id: '7AE0A1C1-0FFD-4182-93B5-95F2419AEE8E',
    name: 'phone-remove',
    codepoint: 'F152F',
    deprecated: 'FALSE',
  },
  {
    id: 'E829DF09-29F7-4FEF-A934-9C7A2119A628',
    name: 'phone-remove-outline',
    codepoint: 'F1530',
    deprecated: 'FALSE',
  },
  {
    id: 'B0FC2BB4-6C44-42CA-8F90-52CBCD0F1AD4',
    name: 'phone-return',
    codepoint: 'F082F',
    deprecated: 'FALSE',
  },
  {
    id: '7D826B1C-8945-48EA-95F8-30F864E4EA7D',
    name: 'phone-return-outline',
    codepoint: 'F119C',
    deprecated: 'FALSE',
  },
  {
    id: '280ECA2D-5D38-4B9E-81FA-58EEB580481A',
    name: 'phone-ring',
    codepoint: 'F11AB',
    deprecated: 'FALSE',
  },
  {
    id: '172E080A-5125-42C8-83FB-980DDE41715C',
    name: 'phone-ring-outline',
    codepoint: 'F11AC',
    deprecated: 'FALSE',
  },
  {
    id: '48667BD8-2891-45D0-92E9-F4FF292527A4',
    name: 'phone-rotate-landscape',
    codepoint: 'F0885',
    deprecated: 'FALSE',
  },
  {
    id: 'B839A0B6-9E89-40C2-811F-2253514267C9',
    name: 'phone-rotate-portrait',
    codepoint: 'F0886',
    deprecated: 'FALSE',
  },
  {
    id: 'C9E81E97-C4DA-4612-AC4D-0F81C7986CD8',
    name: 'phone-settings',
    codepoint: 'F03FD',
    deprecated: 'FALSE',
  },
  {
    id: 'E4A0F700-DF2C-4A72-AE99-8249ADDBB5FD',
    name: 'phone-settings-outline',
    codepoint: 'F119D',
    deprecated: 'FALSE',
  },
  {
    id: '038DA763-AA65-43B6-B6D6-F6967EE0DC4C',
    name: 'phone-sync',
    codepoint: 'F1995',
    deprecated: 'FALSE',
  },
  {
    id: 'E463CA1E-92BB-4389-9EE8-BBA74EDA159F',
    name: 'phone-sync-outline',
    codepoint: 'F1996',
    deprecated: 'FALSE',
  },
  {
    id: '43DAD9DC-C484-452A-9450-1F9C83C6B50E',
    name: 'phone-voip',
    codepoint: 'F03FE',
    deprecated: 'FALSE',
  },
  {
    id: '0E3F11DA-8458-4A89-BB7B-D3A6972D3E30',
    name: 'pi',
    codepoint: 'F03FF',
    deprecated: 'FALSE',
  },
  {
    id: '7C14B1F3-D836-42D6-AC5F-9FD31ADEBFFD',
    name: 'pi-box',
    codepoint: 'F0400',
    deprecated: 'FALSE',
  },
  {
    id: 'C41264FE-89C3-47A7-AE24-36F4B419B4E4',
    name: 'pi-hole',
    codepoint: 'F0DF1',
    deprecated: 'TRUE',
  },
  {
    id: 'E7651219-1BF9-4D06-9B4A-7599A2E0A407',
    name: 'piano',
    codepoint: 'F067D',
    deprecated: 'FALSE',
  },
  {
    id: 'F33DB278-2B2B-4731-9FDD-6D053EC2CBAB',
    name: 'piano-off',
    codepoint: 'F0698',
    deprecated: 'FALSE',
  },
  {
    id: 'A4DE211E-02E2-4514-9059-17AEFADA22F7',
    name: 'pickaxe',
    codepoint: 'F08B7',
    deprecated: 'FALSE',
  },
  {
    id: 'D184F762-16C9-4266-A485-8AA14A83A575',
    name: 'picture-in-picture-bottom-right',
    codepoint: 'F0E57',
    deprecated: 'FALSE',
  },
  {
    id: 'C9D5809D-900A-418A-98C3-2EF4106E620E',
    name: 'picture-in-picture-bottom-right-outline',
    codepoint: 'F0E58',
    deprecated: 'FALSE',
  },
  {
    id: 'C75700F6-A468-4452-BFFB-FFB4A496563B',
    name: 'picture-in-picture-top-right',
    codepoint: 'F0E59',
    deprecated: 'FALSE',
  },
  {
    id: '3910AABC-4BEB-4D91-B35D-AAE58A1115C8',
    name: 'picture-in-picture-top-right-outline',
    codepoint: 'F0E5A',
    deprecated: 'FALSE',
  },
  {
    id: '68AE7D9F-95E4-4AAC-B39F-748FD8677ACF',
    name: 'pier',
    codepoint: 'F0887',
    deprecated: 'FALSE',
  },
  {
    id: '547D5950-A129-411D-92AF-B8477D21230D',
    name: 'pier-crane',
    codepoint: 'F0888',
    deprecated: 'FALSE',
  },
  {
    id: '5E4A3F96-7779-44C8-86EF-948EDBD53C20',
    name: 'pig',
    codepoint: 'F0401',
    deprecated: 'FALSE',
  },
  {
    id: 'A25A5576-C967-444E-989D-750CD275BF27',
    name: 'pig-variant',
    codepoint: 'F1006',
    deprecated: 'FALSE',
  },
  {
    id: '04A432B2-ABA5-49D7-9650-D6518D5D0A94',
    name: 'pig-variant-outline',
    codepoint: 'F1678',
    deprecated: 'FALSE',
  },
  {
    id: '7168E9C8-32AE-4E0F-B3A4-EA05452C63C0',
    name: 'piggy-bank',
    codepoint: 'F1007',
    deprecated: 'FALSE',
  },
  {
    id: '37D67BA6-70A1-44C4-9C62-0647BBB7CDA2',
    name: 'piggy-bank-outline',
    codepoint: 'F1679',
    deprecated: 'FALSE',
  },
  {
    id: '6487E4ED-C501-416C-9DCE-1B7C1F676050',
    name: 'pill',
    codepoint: 'F0402',
    deprecated: 'FALSE',
  },
  {
    id: 'F4398329-8140-4053-A17D-D96501E7C467',
    name: 'pill-multiple',
    codepoint: 'F1B4C',
    deprecated: 'FALSE',
  },
  {
    id: '70E290E6-75B8-4976-8206-C066C02C5344',
    name: 'pill-off',
    codepoint: 'F1A5C',
    deprecated: 'FALSE',
  },
  {
    id: 'AD451D24-BA4B-466E-8146-D3FBBDA6E275',
    name: 'pillar',
    codepoint: 'F0702',
    deprecated: 'FALSE',
  },
  {
    id: 'B1337135-0E57-465F-8C0C-E658C9981A19',
    name: 'pin',
    codepoint: 'F0403',
    deprecated: 'FALSE',
  },
  {
    id: '8C59FE68-D89C-4A86-8E01-5C8109D1B028',
    name: 'pin-off',
    codepoint: 'F0404',
    deprecated: 'FALSE',
  },
  {
    id: '0ED785A3-7387-4989-BF3A-11F2F53C81B5',
    name: 'pin-off-outline',
    codepoint: 'F0930',
    deprecated: 'FALSE',
  },
  {
    id: 'BF736212-EE8D-49D0-8C8B-63A463488E88',
    name: 'pin-outline',
    codepoint: 'F0931',
    deprecated: 'FALSE',
  },
  {
    id: 'F053196C-66BC-4D0D-B75F-24773CA618E5',
    name: 'pine-tree',
    codepoint: 'F0405',
    deprecated: 'FALSE',
  },
  {
    id: 'A8F37CBB-10AA-42DE-9CD6-BDCE10C2AF8C',
    name: 'pine-tree-box',
    codepoint: 'F0406',
    deprecated: 'FALSE',
  },
  {
    id: '707539BB-56A1-4346-B907-95DEE51799A3',
    name: 'pine-tree-fire',
    codepoint: 'F141A',
    deprecated: 'FALSE',
  },
  {
    id: '6BAC7A7E-340B-474D-8448-2DEC0FE66146',
    name: 'pinterest',
    codepoint: 'F0407',
    deprecated: 'TRUE',
  },
  {
    id: 'BB298846-B608-4289-987C-A8F2A904409F',
    name: 'pinwheel',
    codepoint: 'F0AD5',
    deprecated: 'FALSE',
  },
  {
    id: '279BF513-8015-4F28-83FD-B69CE7E0EB78',
    name: 'pinwheel-outline',
    codepoint: 'F0AD6',
    deprecated: 'FALSE',
  },
  {
    id: '840AF950-F514-452B-AD1F-18EC878CB1F8',
    name: 'pipe',
    codepoint: 'F07E5',
    deprecated: 'FALSE',
  },
  {
    id: '01D59BE4-7863-471D-840D-8C810F934344',
    name: 'pipe-disconnected',
    codepoint: 'F07E6',
    deprecated: 'FALSE',
  },
  {
    id: 'A39D910A-D5C9-4A9D-86CF-F61A60486758',
    name: 'pipe-leak',
    codepoint: 'F0889',
    deprecated: 'FALSE',
  },
  {
    id: 'CAF49DCD-781B-4F6A-B692-9E2ED6D68786',
    name: 'pipe-valve',
    codepoint: 'F184D',
    deprecated: 'FALSE',
  },
  {
    id: '5767EA8C-592F-4CEF-A01D-52F3CD0D111E',
    name: 'pipe-wrench',
    codepoint: 'F1354',
    deprecated: 'FALSE',
  },
  {
    id: '5CDFFABD-F3B4-4E4D-97A3-F8B1AE403809',
    name: 'pirate',
    codepoint: 'F0A08',
    deprecated: 'FALSE',
  },
  {
    id: '34C9947D-5943-4C3E-9E7A-2CD95061222B',
    name: 'pistol',
    codepoint: 'F0703',
    deprecated: 'FALSE',
  },
  {
    id: '212476A4-B98A-4A84-BE27-37F28847A7D2',
    name: 'piston',
    codepoint: 'F088A',
    deprecated: 'FALSE',
  },
  {
    id: 'BD8E12AD-25AA-42FA-9C9C-EA023794A93D',
    name: 'pitchfork',
    codepoint: 'F1553',
    deprecated: 'FALSE',
  },
  {
    id: 'E617E8BD-2C63-4E59-B1F4-EB3F9F3916D3',
    name: 'pizza',
    codepoint: 'F0409',
    deprecated: 'FALSE',
  },
  {
    id: '604EF539-0143-4862-957A-AA04EFF9CD4E',
    name: 'plane-car',
    codepoint: 'F1AFF',
    deprecated: 'FALSE',
  },
  {
    id: 'F485C550-203A-48D9-BFFC-35400109374C',
    name: 'plane-train',
    codepoint: 'F1B00',
    deprecated: 'FALSE',
  },
  {
    id: '90AD6D2A-7986-49D0-8DA5-DC92F9F92A15',
    name: 'play',
    codepoint: 'F040A',
    deprecated: 'FALSE',
  },
  {
    id: 'B60DF8C7-F693-4E2B-B5AB-65CA6DC21A8E',
    name: 'play-box',
    codepoint: 'F127A',
    deprecated: 'FALSE',
  },
  {
    id: '0B180C29-3ADF-47C2-ACC6-C5EE5E744AD4',
    name: 'play-box-lock',
    codepoint: 'F1A16',
    deprecated: 'FALSE',
  },
  {
    id: '560E6993-9435-4447-8A14-235913D80579',
    name: 'play-box-lock-open',
    codepoint: 'F1A17',
    deprecated: 'FALSE',
  },
  {
    id: '3C88B8D8-AFDD-4A9F-A976-BCD74895D1F5',
    name: 'play-box-lock-open-outline',
    codepoint: 'F1A18',
    deprecated: 'FALSE',
  },
  {
    id: 'F7617311-2473-4D67-A62D-1B06033CFAE7',
    name: 'play-box-lock-outline',
    codepoint: 'F1A19',
    deprecated: 'FALSE',
  },
  {
    id: '9700DCD7-8658-479A-A817-1241A09F9654',
    name: 'play-box-multiple',
    codepoint: 'F0D19',
    deprecated: 'FALSE',
  },
  {
    id: '73F0C681-0A20-484A-B276-3EE052090089',
    name: 'play-box-multiple-outline',
    codepoint: 'F13E6',
    deprecated: 'FALSE',
  },
  {
    id: 'D0431F42-A1C2-409B-9D44-2EDDEF3F9AB3',
    name: 'play-box-outline',
    codepoint: 'F040B',
    deprecated: 'FALSE',
  },
  {
    id: '79F68E60-010F-4A8E-8E30-06CB64A5DBF6',
    name: 'play-circle',
    codepoint: 'F040C',
    deprecated: 'FALSE',
  },
  {
    id: 'E0C875C2-16F3-4C92-BABF-DAB2D08258DC',
    name: 'play-circle-outline',
    codepoint: 'F040D',
    deprecated: 'FALSE',
  },
  {
    id: '5B10237B-B7AA-4B35-B8C0-C97747F1E3E7',
    name: 'play-network',
    codepoint: 'F088B',
    deprecated: 'FALSE',
  },
  {
    id: '951FE34F-7D2E-45FF-8014-FB4EBC49BED1',
    name: 'play-network-outline',
    codepoint: 'F0CB7',
    deprecated: 'FALSE',
  },
  {
    id: '554AC834-1F9C-401A-86F5-E26FFD201683',
    name: 'play-outline',
    codepoint: 'F0F1B',
    deprecated: 'FALSE',
  },
  {
    id: '93DAA69E-9A21-4D5A-B6B1-0AF0A24B7FEB',
    name: 'play-pause',
    codepoint: 'F040E',
    deprecated: 'FALSE',
  },
  {
    id: 'FEB9507A-DA7D-4CB3-918A-E64BBB9D4CC9',
    name: 'play-protected-content',
    codepoint: 'F040F',
    deprecated: 'FALSE',
  },
  {
    id: '72410FAF-5315-452B-ACE1-C0FEB3B897F8',
    name: 'play-speed',
    codepoint: 'F08FF',
    deprecated: 'FALSE',
  },
  {
    id: 'F3E26F6E-9F9A-490B-A28A-1BD32A60D143',
    name: 'playlist-check',
    codepoint: 'F05C7',
    deprecated: 'FALSE',
  },
  {
    id: 'EBBE4C11-9C6D-40AA-844A-167B274A4E81',
    name: 'playlist-edit',
    codepoint: 'F0900',
    deprecated: 'FALSE',
  },
  {
    id: '1D472295-8F79-4F51-B68A-63A2CD2DF634',
    name: 'playlist-minus',
    codepoint: 'F0410',
    deprecated: 'FALSE',
  },
  {
    id: 'F7BFD179-3B1E-4347-BD64-C51686B00E65',
    name: 'playlist-music',
    codepoint: 'F0CB8',
    deprecated: 'FALSE',
  },
  {
    id: '97526C1D-E3B9-4900-8C13-3969E80949B3',
    name: 'playlist-music-outline',
    codepoint: 'F0CB9',
    deprecated: 'FALSE',
  },
  {
    id: '313918D8-1CB9-40FC-B24F-3A974F1A6EAF',
    name: 'playlist-play',
    codepoint: 'F0411',
    deprecated: 'FALSE',
  },
  {
    id: '350D7B60-3F36-4FB0-9959-B90625F5267C',
    name: 'playlist-plus',
    codepoint: 'F0412',
    deprecated: 'FALSE',
  },
  {
    id: '526856F3-6DF1-454A-B905-3DA120370563',
    name: 'playlist-remove',
    codepoint: 'F0413',
    deprecated: 'FALSE',
  },
  {
    id: '80A0AA10-9B8E-4AAA-BDEA-EDDADB94A7CC',
    name: 'playlist-star',
    codepoint: 'F0DF2',
    deprecated: 'FALSE',
  },
  {
    id: 'D390D1E2-571E-49DB-BBB9-9D3821EAF819',
    name: 'plex',
    codepoint: 'F06BA',
    deprecated: 'TRUE',
  },
  {
    id: '7AD950E9-0961-4D65-9B78-40FC56D5D206',
    name: 'pliers',
    codepoint: 'F19A4',
    deprecated: 'FALSE',
  },
  {
    id: '2DE4F8E6-F25C-4FCA-8405-109FA8918B78',
    name: 'plus',
    codepoint: 'F0415',
    deprecated: 'FALSE',
  },
  {
    id: '87EE041D-81D2-4F1A-A757-F76E9D8686EF',
    name: 'plus-box',
    codepoint: 'F0416',
    deprecated: 'FALSE',
  },
  {
    id: 'BF1B666E-57AC-41B0-950B-4F4060BDF601',
    name: 'plus-box-multiple',
    codepoint: 'F0334',
    deprecated: 'FALSE',
  },
  {
    id: '910CB053-6597-49C2-A20D-EBC310D03364',
    name: 'plus-box-multiple-outline',
    codepoint: 'F1143',
    deprecated: 'FALSE',
  },
  {
    id: '75A7BDE6-40BC-4938-AFCD-D4DDF1B65B2A',
    name: 'plus-box-outline',
    codepoint: 'F0704',
    deprecated: 'FALSE',
  },
  {
    id: '0F76DADF-2A8D-4C1F-B43E-DC3C6D282BBF',
    name: 'plus-circle',
    codepoint: 'F0417',
    deprecated: 'FALSE',
  },
  {
    id: '93BBA1EA-76F8-4D2E-B4D0-98F684B15AC3',
    name: 'plus-circle-multiple',
    codepoint: 'F034C',
    deprecated: 'FALSE',
  },
  {
    id: '0C5565E9-9E74-4E50-BF9E-6F904BB83E52',
    name: 'plus-circle-multiple-outline',
    codepoint: 'F0418',
    deprecated: 'FALSE',
  },
  {
    id: 'EFB8C700-D60B-4995-B8DE-F3A3E2DA75F3',
    name: 'plus-circle-outline',
    codepoint: 'F0419',
    deprecated: 'FALSE',
  },
  {
    id: '04ACEC00-DCEE-4FC8-9608-A2875F7A92A1',
    name: 'plus-lock',
    codepoint: 'F1A5D',
    deprecated: 'FALSE',
  },
  {
    id: 'A147BEF7-1952-44F9-98EB-BF2D2F4F625E',
    name: 'plus-lock-open',
    codepoint: 'F1A5E',
    deprecated: 'FALSE',
  },
  {
    id: '34BDA78C-9BF6-441A-9924-E8619719C333',
    name: 'plus-minus',
    codepoint: 'F0992',
    deprecated: 'FALSE',
  },
  {
    id: 'C8A8A7E4-7745-4CA1-A99B-724C2471CAF5',
    name: 'plus-minus-box',
    codepoint: 'F0993',
    deprecated: 'FALSE',
  },
  {
    id: 'D86C99FA-DFA7-4DD2-9EB8-CBB094BD1366',
    name: 'plus-minus-variant',
    codepoint: 'F14C9',
    deprecated: 'FALSE',
  },
  {
    id: '14F6A9EC-BD40-4181-B084-05A9FCFA3973',
    name: 'plus-network',
    codepoint: 'F041A',
    deprecated: 'FALSE',
  },
  {
    id: '349600D3-B39C-46C2-8E61-5A256C4BA98C',
    name: 'plus-network-outline',
    codepoint: 'F0CBA',
    deprecated: 'FALSE',
  },
  {
    id: '680B6AD6-C9AD-44D1-9228-EDDB8C242343',
    name: 'plus-outline',
    codepoint: 'F0705',
    deprecated: 'FALSE',
  },
  {
    id: '2FD9933D-7226-4745-B5DB-C8E6245FB170',
    name: 'plus-thick',
    codepoint: 'F11EC',
    deprecated: 'FALSE',
  },
  {
    id: '56B37BA8-E973-4B53-812A-7F47BCFF217A',
    name: 'podcast',
    codepoint: 'F0994',
    deprecated: 'FALSE',
  },
  {
    id: '9C9AF6D4-1E8A-414A-9935-B7B8BE9F0962',
    name: 'podium',
    codepoint: 'F0D25',
    deprecated: 'FALSE',
  },
  {
    id: '4F2BE6B3-071D-4EE3-8E86-71F0AF06619F',
    name: 'podium-bronze',
    codepoint: 'F0D26',
    deprecated: 'FALSE',
  },
  {
    id: 'CB4B60CC-1B22-4288-997F-D59FE2AFC198',
    name: 'podium-gold',
    codepoint: 'F0D27',
    deprecated: 'FALSE',
  },
  {
    id: 'FF783CFF-DD54-492C-A1E1-323AB6CAAFC1',
    name: 'podium-silver',
    codepoint: 'F0D28',
    deprecated: 'FALSE',
  },
  {
    id: '1DDDF76A-2C40-4BEE-87B1-0B95D4E233DA',
    name: 'point-of-sale',
    codepoint: 'F0D92',
    deprecated: 'FALSE',
  },
  {
    id: '48329A9B-7B16-469C-9D64-B2E9381620BF',
    name: 'pokeball',
    codepoint: 'F041D',
    deprecated: 'FALSE',
  },
  {
    id: 'A1D5B3DC-2A72-4548-AB36-985E98BB6FCE',
    name: 'pokemon-go',
    codepoint: 'F0A09',
    deprecated: 'TRUE',
  },
  {
    id: 'D73F724D-EEE6-43EC-BA3A-E185ABD7A258',
    name: 'poker-chip',
    codepoint: 'F0830',
    deprecated: 'FALSE',
  },
  {
    id: '8408CE08-26C2-49B9-95CE-C5A9AAF4455E',
    name: 'polaroid',
    codepoint: 'F041E',
    deprecated: 'FALSE',
  },
  {
    id: '80E26409-5A5C-4BDC-9722-8A8CC6981B90',
    name: 'police-badge',
    codepoint: 'F1167',
    deprecated: 'FALSE',
  },
  {
    id: '9262CC3D-85E6-4881-AE14-B760E93DBBFE',
    name: 'police-badge-outline',
    codepoint: 'F1168',
    deprecated: 'FALSE',
  },
  {
    id: 'E0ED9455-85A4-4C77-9C79-533CE242ABA5',
    name: 'police-station',
    codepoint: 'F1839',
    deprecated: 'FALSE',
  },
  {
    id: 'C4A1DAE5-04E0-47EF-84D8-CB81FA58EF87',
    name: 'poll',
    codepoint: 'F041F',
    deprecated: 'FALSE',
  },
  {
    id: '5ACE4E72-9576-405A-A17F-314A1FF864C8',
    name: 'polo',
    codepoint: 'F14C3',
    deprecated: 'FALSE',
  },
  {
    id: '528FF16A-C55C-43F9-8E0B-3879A209AEDE',
    name: 'polymer',
    codepoint: 'F0421',
    deprecated: 'TRUE',
  },
  {
    id: 'F42C2F0C-BEFC-46EB-A46C-665032BD6641',
    name: 'pool',
    codepoint: 'F0606',
    deprecated: 'FALSE',
  },
  {
    id: '7216DA73-CAB3-46A6-8940-809FFE3DBD26',
    name: 'pool-thermometer',
    codepoint: 'F1A5F',
    deprecated: 'FALSE',
  },
  {
    id: '3401BCC7-DECF-4A92-9B6C-34AF063B2FED',
    name: 'popcorn',
    codepoint: 'F0422',
    deprecated: 'FALSE',
  },
  {
    id: '9B1DF082-5266-408A-A057-92E9AEB81BFA',
    name: 'post',
    codepoint: 'F1008',
    deprecated: 'FALSE',
  },
  {
    id: 'DA9F3CE2-81E2-4113-B8E7-64CE579D0890',
    name: 'post-lamp',
    codepoint: 'F1A60',
    deprecated: 'FALSE',
  },
  {
    id: 'A7CB61B3-B3C5-4EEE-AC94-D0B2347FFA1B',
    name: 'post-outline',
    codepoint: 'F1009',
    deprecated: 'FALSE',
  },
  {
    id: 'D76042A4-6370-4DF4-BF35-B00BE339805A',
    name: 'postage-stamp',
    codepoint: 'F0CBB',
    deprecated: 'FALSE',
  },
  {
    id: '267AF23F-D48A-493E-91BD-E78CD3EFCCBA',
    name: 'pot',
    codepoint: 'F02E5',
    deprecated: 'FALSE',
  },
  {
    id: 'C264A721-9CFD-4AE5-BD97-404E7DCDC6C2',
    name: 'pot-mix',
    codepoint: 'F065B',
    deprecated: 'FALSE',
  },
  {
    id: '857C302E-0AC9-438D-A8E5-DE3DC9680FAB',
    name: 'pot-mix-outline',
    codepoint: 'F0677',
    deprecated: 'FALSE',
  },
  {
    id: 'EB68865A-2308-4667-9B2F-C550923180A9',
    name: 'pot-outline',
    codepoint: 'F02FF',
    deprecated: 'FALSE',
  },
  {
    id: '444286FD-F41C-4945-BF9D-7C2913EC663E',
    name: 'pot-steam',
    codepoint: 'F065A',
    deprecated: 'FALSE',
  },
  {
    id: 'A6C14603-8189-4CC5-82CF-1231422B6F70',
    name: 'pot-steam-outline',
    codepoint: 'F0326',
    deprecated: 'FALSE',
  },
  {
    id: '9344AA1D-FF8B-432F-A314-C416DFA37F12',
    name: 'pound',
    codepoint: 'F0423',
    deprecated: 'FALSE',
  },
  {
    id: '597DF175-F0F5-4892-9F13-EA712DBC947E',
    name: 'pound-box',
    codepoint: 'F0424',
    deprecated: 'FALSE',
  },
  {
    id: 'BC212E3B-C9D9-4FC5-B73B-DDA77D94A5C9',
    name: 'pound-box-outline',
    codepoint: 'F117F',
    deprecated: 'FALSE',
  },
  {
    id: 'C945D203-9737-4C25-87CA-29D97B862DD2',
    name: 'power',
    codepoint: 'F0425',
    deprecated: 'FALSE',
  },
  {
    id: 'DD119EDC-92B2-48F6-8014-73AD2AB65DA7',
    name: 'power-cycle',
    codepoint: 'F0901',
    deprecated: 'FALSE',
  },
  {
    id: '39D3928C-2739-4A57-A81C-3DF67328A160',
    name: 'power-off',
    codepoint: 'F0902',
    deprecated: 'FALSE',
  },
  {
    id: '566141F9-DB64-4210-8384-EB77CFA9E8A0',
    name: 'power-on',
    codepoint: 'F0903',
    deprecated: 'FALSE',
  },
  {
    id: '60E77DB8-2D58-462F-8512-41D9E0E42795',
    name: 'power-plug',
    codepoint: 'F06A5',
    deprecated: 'FALSE',
  },
  {
    id: 'ACF38F54-4726-4F8F-9916-8C582137FA69',
    name: 'power-plug-off',
    codepoint: 'F06A6',
    deprecated: 'FALSE',
  },
  {
    id: 'D4F5AEAB-DD00-4101-8661-13260BE9D087',
    name: 'power-plug-off-outline',
    codepoint: 'F1424',
    deprecated: 'FALSE',
  },
  {
    id: 'E439723C-E8DA-4BD6-8AE8-F944FD96B31D',
    name: 'power-plug-outline',
    codepoint: 'F1425',
    deprecated: 'FALSE',
  },
  {
    id: 'B5587844-E70A-4F55-A2DA-CE85D8F79689',
    name: 'power-settings',
    codepoint: 'F0426',
    deprecated: 'FALSE',
  },
  {
    id: 'AA011772-1BE4-4E00-A838-9D0D1DCA2773',
    name: 'power-sleep',
    codepoint: 'F0904',
    deprecated: 'FALSE',
  },
  {
    id: '175A9E67-3723-4D49-85EC-8D34B4BA0C14',
    name: 'power-socket',
    codepoint: 'F0427',
    deprecated: 'FALSE',
  },
  {
    id: 'CFCF8409-65AC-4E1C-885B-107F9D8B4413',
    name: 'power-socket-au',
    codepoint: 'F0905',
    deprecated: 'FALSE',
  },
  {
    id: '27B28F1C-C311-49C2-B3EE-6D288A9F1165',
    name: 'power-socket-ch',
    codepoint: 'F0FB3',
    deprecated: 'FALSE',
  },
  {
    id: '14654BFB-0D87-43E0-8A47-7CF1EE1CF418',
    name: 'power-socket-de',
    codepoint: 'F1107',
    deprecated: 'FALSE',
  },
  {
    id: '07F4FD1C-A171-4088-BE30-53758CFF45E5',
    name: 'power-socket-eu',
    codepoint: 'F07E7',
    deprecated: 'FALSE',
  },
  {
    id: '85338D53-181D-46E9-AEA7-D524B0DFBA4A',
    name: 'power-socket-fr',
    codepoint: 'F1108',
    deprecated: 'FALSE',
  },
  {
    id: 'D7106221-1ABC-4E8E-9345-E2B23D5D74B9',
    name: 'power-socket-it',
    codepoint: 'F14FF',
    deprecated: 'FALSE',
  },
  {
    id: '98D7A146-D68E-4E80-B8F1-5235DAECD976',
    name: 'power-socket-jp',
    codepoint: 'F1109',
    deprecated: 'FALSE',
  },
  {
    id: 'E9F0AC95-A660-4FB1-BE5D-20153B709E15',
    name: 'power-socket-uk',
    codepoint: 'F07E8',
    deprecated: 'FALSE',
  },
  {
    id: 'C4F7F8FF-3465-4F85-B615-B852A6919100',
    name: 'power-socket-us',
    codepoint: 'F07E9',
    deprecated: 'FALSE',
  },
  {
    id: '3AC74CF9-0EC1-475D-A164-7C9CA0D48B58',
    name: 'power-standby',
    codepoint: 'F0906',
    deprecated: 'FALSE',
  },
  {
    id: '2DB269A5-0CD6-4139-8C24-A4A1F7D96596',
    name: 'powershell',
    codepoint: 'F0A0A',
    deprecated: 'FALSE',
  },
  {
    id: 'DEF7F9EA-ACA8-452E-B587-6524BE7D67EE',
    name: 'prescription',
    codepoint: 'F0706',
    deprecated: 'FALSE',
  },
  {
    id: 'DAEB5F87-858A-4677-B023-C9EC71F99F6C',
    name: 'presentation',
    codepoint: 'F0428',
    deprecated: 'FALSE',
  },
  {
    id: 'E182F4B3-B2B0-41CD-90DC-5ADFBE25DA90',
    name: 'presentation-play',
    codepoint: 'F0429',
    deprecated: 'FALSE',
  },
  {
    id: '607F7E94-DBF5-4D5D-8F3A-5E1F90E1FF61',
    name: 'pretzel',
    codepoint: 'F1562',
    deprecated: 'FALSE',
  },
  {
    id: '7365D689-4C10-4EBA-AD9A-09189B8091EE',
    name: 'printer',
    codepoint: 'F042A',
    deprecated: 'FALSE',
  },
  {
    id: '86A3D6B7-6C8B-4883-B51B-753DEF0F4A62',
    name: 'printer-3d',
    codepoint: 'F042B',
    deprecated: 'FALSE',
  },
  {
    id: '3A3AA67C-5DB4-40CA-9D0B-43843190CCDC',
    name: 'printer-3d-nozzle',
    codepoint: 'F0E5B',
    deprecated: 'FALSE',
  },
  {
    id: '5AB50A00-46F7-4294-8462-EDD32EA7309A',
    name: 'printer-3d-nozzle-alert',
    codepoint: 'F11C0',
    deprecated: 'FALSE',
  },
  {
    id: '5EEF9AB7-11DF-4612-A037-55705DA3AB19',
    name: 'printer-3d-nozzle-alert-outline',
    codepoint: 'F11C1',
    deprecated: 'FALSE',
  },
  {
    id: 'EB4E1E2E-C462-43D0-8D25-4853F62A5397',
    name: 'printer-3d-nozzle-heat',
    codepoint: 'F18B8',
    deprecated: 'FALSE',
  },
  {
    id: '94D5AB5D-C507-4C0A-9C1C-A25C0ADCEC1E',
    name: 'printer-3d-nozzle-heat-outline',
    codepoint: 'F18B9',
    deprecated: 'FALSE',
  },
  {
    id: '6172358F-1F0D-4735-B880-613E2E69FFBA',
    name: 'printer-3d-nozzle-off',
    codepoint: 'F1B19',
    deprecated: 'FALSE',
  },
  {
    id: '188B9B62-C20F-4813-AAAE-F4FA6DE3A6BC',
    name: 'printer-3d-nozzle-off-outline',
    codepoint: 'F1B1A',
    deprecated: 'FALSE',
  },
  {
    id: '7B1B16E0-AB42-490D-8D84-62B9AA0AB4BD',
    name: 'printer-3d-nozzle-outline',
    codepoint: 'F0E5C',
    deprecated: 'FALSE',
  },
  {
    id: '887D316B-F8E0-40D4-8961-4A6C00111CDB',
    name: 'printer-3d-off',
    codepoint: 'F1B0E',
    deprecated: 'FALSE',
  },
  {
    id: 'DD64AF52-2610-43D3-A992-9F3FCCB4A576',
    name: 'printer-alert',
    codepoint: 'F042C',
    deprecated: 'FALSE',
  },
  {
    id: '3C750D11-158A-4FED-A7A8-22B4D74DF4BC',
    name: 'printer-check',
    codepoint: 'F1146',
    deprecated: 'FALSE',
  },
  {
    id: '22BB79BD-B17E-4002-923E-5B6766B967DC',
    name: 'printer-eye',
    codepoint: 'F1458',
    deprecated: 'FALSE',
  },
  {
    id: '328D470F-EEB4-486D-953B-6F84CE809C3C',
    name: 'printer-off',
    codepoint: 'F0E5D',
    deprecated: 'FALSE',
  },
  {
    id: '6AE8D5BF-CEFD-40DD-A764-9C9FFE38A003',
    name: 'printer-off-outline',
    codepoint: 'F1785',
    deprecated: 'FALSE',
  },
  {
    id: '514C36A5-3B40-4493-A1CE-275B9C482012',
    name: 'printer-outline',
    codepoint: 'F1786',
    deprecated: 'FALSE',
  },
  {
    id: 'F9D30BAE-7AF6-4C02-83AD-18045895B8B0',
    name: 'printer-pos',
    codepoint: 'F1057',
    deprecated: 'FALSE',
  },
  {
    id: '8303B276-1999-4F62-8C48-22EBA47B5781',
    name: 'printer-pos-alert',
    codepoint: 'F1BBC',
    deprecated: 'FALSE',
  },
  {
    id: 'EACC7108-609E-4681-BAE9-BEB8EBBFF77F',
    name: 'printer-pos-alert-outline',
    codepoint: 'F1BBD',
    deprecated: 'FALSE',
  },
  {
    id: 'CF5532E5-1956-430A-A661-A5361A8BEBF1',
    name: 'printer-pos-cancel',
    codepoint: 'F1BBE',
    deprecated: 'FALSE',
  },
  {
    id: '7D88DD62-B00E-4950-8A0A-CF5C895578E7',
    name: 'printer-pos-cancel-outline',
    codepoint: 'F1BBF',
    deprecated: 'FALSE',
  },
  {
    id: '77D3FA23-E73B-4FF9-89A3-D507B44D113C',
    name: 'printer-pos-check',
    codepoint: 'F1BC0',
    deprecated: 'FALSE',
  },
  {
    id: 'A48714B0-4E0D-480D-9766-C73919F28B73',
    name: 'printer-pos-check-outline',
    codepoint: 'F1BC1',
    deprecated: 'FALSE',
  },
  {
    id: '6566732F-0DDF-4F23-B478-AC3A93B822BE',
    name: 'printer-pos-cog',
    codepoint: 'F1BC2',
    deprecated: 'FALSE',
  },
  {
    id: '7D634FE7-3A63-4850-9AC4-B62775160D58',
    name: 'printer-pos-cog-outline',
    codepoint: 'F1BC3',
    deprecated: 'FALSE',
  },
  {
    id: '954D4E9A-A4EF-414E-9AC3-0B07DA479BE9',
    name: 'printer-pos-edit',
    codepoint: 'F1BC4',
    deprecated: 'FALSE',
  },
  {
    id: '401FC546-94DF-4C01-9B08-24EEB602A4C3',
    name: 'printer-pos-edit-outline',
    codepoint: 'F1BC5',
    deprecated: 'FALSE',
  },
  {
    id: '636E0D3E-A92D-4369-9C04-00C92DB0C1B2',
    name: 'printer-pos-minus',
    codepoint: 'F1BC6',
    deprecated: 'FALSE',
  },
  {
    id: 'DD9AE6D3-6DF8-4CBC-A40F-CA25E4E6B310',
    name: 'printer-pos-minus-outline',
    codepoint: 'F1BC7',
    deprecated: 'FALSE',
  },
  {
    id: 'B91F589E-8733-47ED-8B29-7D1BBCFBC98F',
    name: 'printer-pos-network',
    codepoint: 'F1BC8',
    deprecated: 'FALSE',
  },
  {
    id: '3FD6D2F6-5BBB-45D9-BE28-1B216BEB4D5E',
    name: 'printer-pos-network-outline',
    codepoint: 'F1BC9',
    deprecated: 'FALSE',
  },
  {
    id: '50590358-DEC9-4F92-B1AF-0F005C35A3DA',
    name: 'printer-pos-off',
    codepoint: 'F1BCA',
    deprecated: 'FALSE',
  },
  {
    id: 'B5A792CB-A191-44B7-AC15-148FC242803E',
    name: 'printer-pos-off-outline',
    codepoint: 'F1BCB',
    deprecated: 'FALSE',
  },
  {
    id: '4206F292-9583-42F9-91CD-2DEA9F273C9F',
    name: 'printer-pos-outline',
    codepoint: 'F1BCC',
    deprecated: 'FALSE',
  },
  {
    id: 'FC8CF9C9-3DCD-46DC-8B3C-A4000A7B00DD',
    name: 'printer-pos-pause',
    codepoint: 'F1BCD',
    deprecated: 'FALSE',
  },
  {
    id: 'FCF57215-34E5-44F7-9DF9-BDFF862CAAF6',
    name: 'printer-pos-pause-outline',
    codepoint: 'F1BCE',
    deprecated: 'FALSE',
  },
  {
    id: '6737548E-6689-4D50-B84A-186B16B1CE9C',
    name: 'printer-pos-play',
    codepoint: 'F1BCF',
    deprecated: 'FALSE',
  },
  {
    id: 'C725674B-6092-43B7-9F0D-D878910CE943',
    name: 'printer-pos-play-outline',
    codepoint: 'F1BD0',
    deprecated: 'FALSE',
  },
  {
    id: '45895F8B-BD11-410E-859F-E48AC25AA0EF',
    name: 'printer-pos-plus',
    codepoint: 'F1BD1',
    deprecated: 'FALSE',
  },
  {
    id: 'B842534E-FF25-45A4-B2E1-F5FC581FD017',
    name: 'printer-pos-plus-outline',
    codepoint: 'F1BD2',
    deprecated: 'FALSE',
  },
  {
    id: '9C26E188-105E-45DE-A8D3-42DA8829FE34',
    name: 'printer-pos-refresh',
    codepoint: 'F1BD3',
    deprecated: 'FALSE',
  },
  {
    id: 'D5843C28-5203-42D5-A874-8BE28012EFA8',
    name: 'printer-pos-refresh-outline',
    codepoint: 'F1BD4',
    deprecated: 'FALSE',
  },
  {
    id: '21A677FB-E227-4D63-A4FE-08FE9A1D7065',
    name: 'printer-pos-remove',
    codepoint: 'F1BD5',
    deprecated: 'FALSE',
  },
  {
    id: '796FA5DE-0B9F-4CC4-9FC9-6361639B8751',
    name: 'printer-pos-remove-outline',
    codepoint: 'F1BD6',
    deprecated: 'FALSE',
  },
  {
    id: '6771B76B-C7D3-4F4A-B8AA-1656DF5C445A',
    name: 'printer-pos-star',
    codepoint: 'F1BD7',
    deprecated: 'FALSE',
  },
  {
    id: 'E7889DB0-6E76-4CA9-BD0B-92B7E8304381',
    name: 'printer-pos-star-outline',
    codepoint: 'F1BD8',
    deprecated: 'FALSE',
  },
  {
    id: '8DE1FE3F-D679-44B4-AECA-7A9B5F0CC2A7',
    name: 'printer-pos-stop',
    codepoint: 'F1BD9',
    deprecated: 'FALSE',
  },
  {
    id: 'E789327B-828B-4E02-976E-1522B0E92151',
    name: 'printer-pos-stop-outline',
    codepoint: 'F1BDA',
    deprecated: 'FALSE',
  },
  {
    id: 'E8726305-549E-45BE-9E41-D624A57B5D6A',
    name: 'printer-pos-sync',
    codepoint: 'F1BDB',
    deprecated: 'FALSE',
  },
  {
    id: '0F86AA93-8706-4290-B11A-FDC58392F543',
    name: 'printer-pos-sync-outline',
    codepoint: 'F1BDC',
    deprecated: 'FALSE',
  },
  {
    id: '458EB3B4-89F2-4E46-A6D6-485A2081F627',
    name: 'printer-pos-wrench',
    codepoint: 'F1BDD',
    deprecated: 'FALSE',
  },
  {
    id: 'C998386E-C3FA-44DC-9513-D2BFA68D7D12',
    name: 'printer-pos-wrench-outline',
    codepoint: 'F1BDE',
    deprecated: 'FALSE',
  },
  {
    id: '18383BEA-BF9A-43AD-87BB-F47CEC92F9C3',
    name: 'printer-search',
    codepoint: 'F1457',
    deprecated: 'FALSE',
  },
  {
    id: '33E4D9A3-F8C1-4C58-9E88-977AE4F4E4CA',
    name: 'printer-settings',
    codepoint: 'F0707',
    deprecated: 'FALSE',
  },
  {
    id: 'E9B8B42B-CA7D-4499-B610-CABBDCF51C0E',
    name: 'printer-wireless',
    codepoint: 'F0A0B',
    deprecated: 'FALSE',
  },
  {
    id: 'FA96A5AC-D499-4C13-93C7-7A422A5DE4B7',
    name: 'priority-high',
    codepoint: 'F0603',
    deprecated: 'FALSE',
  },
  {
    id: 'DFC698A7-3040-48F4-A341-C1E667A4FE22',
    name: 'priority-low',
    codepoint: 'F0604',
    deprecated: 'FALSE',
  },
  {
    id: '53EA516B-544D-4DC3-90BD-3A5C4838923C',
    name: 'professional-hexagon',
    codepoint: 'F042D',
    deprecated: 'FALSE',
  },
  {
    id: '82FBA8C9-B8BE-4CA6-883C-E07C36723627',
    name: 'progress-alert',
    codepoint: 'F0CBC',
    deprecated: 'FALSE',
  },
  {
    id: '943E027B-964A-4CE2-BDD7-44A9703A73B1',
    name: 'progress-check',
    codepoint: 'F0995',
    deprecated: 'FALSE',
  },
  {
    id: 'D7A33F97-FA45-4719-901A-A1F91E671FC2',
    name: 'progress-clock',
    codepoint: 'F0996',
    deprecated: 'FALSE',
  },
  {
    id: '294CC691-6D3C-47DB-B142-8F4DE633DE92',
    name: 'progress-close',
    codepoint: 'F110A',
    deprecated: 'FALSE',
  },
  {
    id: '9D94B21B-52A0-4F73-AEFB-85FEA76C8624',
    name: 'progress-download',
    codepoint: 'F0997',
    deprecated: 'FALSE',
  },
  {
    id: '25466EC4-FF10-4925-A285-0356FA093FC2',
    name: 'progress-helper',
    codepoint: 'F1BA2',
    deprecated: 'FALSE',
  },
  {
    id: '2434DDA0-2B39-428D-9764-E8F9A46BF22F',
    name: 'progress-pencil',
    codepoint: 'F1787',
    deprecated: 'FALSE',
  },
  {
    id: 'DBF80F38-9B3D-4390-B366-51827F97CC0F',
    name: 'progress-question',
    codepoint: 'F1522',
    deprecated: 'FALSE',
  },
  {
    id: 'FF05067C-B5B4-48B1-B63C-75D3CBB24E45',
    name: 'progress-star',
    codepoint: 'F1788',
    deprecated: 'FALSE',
  },
  {
    id: '699B5A10-1C4C-448C-A3F4-36A3CACD82EA',
    name: 'progress-upload',
    codepoint: 'F0998',
    deprecated: 'FALSE',
  },
  {
    id: 'D0721C85-4507-43CE-8E0B-21522D641993',
    name: 'progress-wrench',
    codepoint: 'F0CBD',
    deprecated: 'FALSE',
  },
  {
    id: 'D23095BD-C1C0-4FED-B114-CE9CF9DD5DB6',
    name: 'projector',
    codepoint: 'F042E',
    deprecated: 'FALSE',
  },
  {
    id: 'BCC3FC58-92BE-4C05-9273-FA1EB03C3E3E',
    name: 'projector-off',
    codepoint: 'F1A23',
    deprecated: 'FALSE',
  },
  {
    id: 'A152DD64-B013-4AE7-B89F-49FC90B2E471',
    name: 'projector-screen',
    codepoint: 'F042F',
    deprecated: 'FALSE',
  },
  {
    id: '074F1A3B-91A7-47EE-8C68-8C3B64321AC2',
    name: 'projector-screen-off',
    codepoint: 'F180D',
    deprecated: 'FALSE',
  },
  {
    id: '2E4DBF28-60B7-43E2-8786-376D26DDC431',
    name: 'projector-screen-off-outline',
    codepoint: 'F180E',
    deprecated: 'FALSE',
  },
  {
    id: 'F2AC4968-73F6-4D91-BF2B-D8653E6863E2',
    name: 'projector-screen-outline',
    codepoint: 'F1724',
    deprecated: 'FALSE',
  },
  {
    id: '96090E84-D472-4A0E-B65C-27E1FAB4BCC8',
    name: 'projector-screen-variant',
    codepoint: 'F180F',
    deprecated: 'FALSE',
  },
  {
    id: 'BE2FFED1-BB12-4CBD-A809-7E5BEA984E2F',
    name: 'projector-screen-variant-off',
    codepoint: 'F1810',
    deprecated: 'FALSE',
  },
  {
    id: '4EED4028-BD43-40F8-B087-D28BCB46DC3F',
    name: 'projector-screen-variant-off-outline',
    codepoint: 'F1811',
    deprecated: 'FALSE',
  },
  {
    id: 'DCA776E3-A4EF-4B2F-A3F4-D8BEC9236502',
    name: 'projector-screen-variant-outline',
    codepoint: 'F1812',
    deprecated: 'FALSE',
  },
  {
    id: 'F6911BCA-14B1-4DD4-9407-20E138FAB878',
    name: 'propane-tank',
    codepoint: 'F1357',
    deprecated: 'FALSE',
  },
  {
    id: '03A9105D-F79F-454C-9EE2-4E96A1BBA908',
    name: 'propane-tank-outline',
    codepoint: 'F1358',
    deprecated: 'FALSE',
  },
  {
    id: '16D49AC9-5D43-4401-980B-C9DF4593838A',
    name: 'protocol',
    codepoint: 'F0FD8',
    deprecated: 'FALSE',
  },
  {
    id: '7E1AC29D-46E3-4C7C-9A00-D8803E502795',
    name: 'publish',
    codepoint: 'F06A7',
    deprecated: 'FALSE',
  },
  {
    id: '57375CB0-5F7F-492E-BB25-3EE90669FE6D',
    name: 'publish-off',
    codepoint: 'F1945',
    deprecated: 'FALSE',
  },
  {
    id: '2A0F7B5D-5D23-4E76-B97D-9D2281D3C4EA',
    name: 'pulse',
    codepoint: 'F0430',
    deprecated: 'FALSE',
  },
  {
    id: '4FDD16EB-0E78-46BF-B077-A9C077C3C1B8',
    name: 'pump',
    codepoint: 'F1402',
    deprecated: 'FALSE',
  },
  {
    id: 'C7CB1E0B-D85F-42EF-8A06-0BD4A1E11102',
    name: 'pump-off',
    codepoint: 'F1B22',
    deprecated: 'FALSE',
  },
  {
    id: 'CAA05089-AC62-40FF-96F1-82E34B93F18F',
    name: 'pumpkin',
    codepoint: 'F0BBF',
    deprecated: 'FALSE',
  },
  {
    id: '4B431C82-5923-49D9-8849-6E7EF6C7661D',
    name: 'purse',
    codepoint: 'F0F1C',
    deprecated: 'FALSE',
  },
  {
    id: '16CB0045-C446-4384-8031-B3CEDDE555EE',
    name: 'purse-outline',
    codepoint: 'F0F1D',
    deprecated: 'FALSE',
  },
  {
    id: '0773D18C-C6A2-41B5-9685-F3E0507E1770',
    name: 'puzzle',
    codepoint: 'F0431',
    deprecated: 'FALSE',
  },
  {
    id: '034B7820-702C-45F8-ABE3-473AC1770BB2',
    name: 'puzzle-check',
    codepoint: 'F1426',
    deprecated: 'FALSE',
  },
  {
    id: 'BA363BBC-F40B-4D14-B677-6FEFD1D48B87',
    name: 'puzzle-check-outline',
    codepoint: 'F1427',
    deprecated: 'FALSE',
  },
  {
    id: 'DA95A80F-202E-48F3-9BCD-302C5AAD3BA2',
    name: 'puzzle-edit',
    codepoint: 'F14D3',
    deprecated: 'FALSE',
  },
  {
    id: '824D28CA-335E-4C36-BAD7-8685FF2FB8A7',
    name: 'puzzle-edit-outline',
    codepoint: 'F14D9',
    deprecated: 'FALSE',
  },
  {
    id: 'F489D7C7-0696-4970-86B0-6216D73177D0',
    name: 'puzzle-heart',
    codepoint: 'F14D4',
    deprecated: 'FALSE',
  },
  {
    id: 'B588380E-4BAD-4787-B699-906B8926068D',
    name: 'puzzle-heart-outline',
    codepoint: 'F14DA',
    deprecated: 'FALSE',
  },
  {
    id: '8DAE6108-0411-473A-B835-528DC330D1DF',
    name: 'puzzle-minus',
    codepoint: 'F14D1',
    deprecated: 'FALSE',
  },
  {
    id: '56014EED-5E51-46CE-B231-D48463CFBF30',
    name: 'puzzle-minus-outline',
    codepoint: 'F14D7',
    deprecated: 'FALSE',
  },
  {
    id: 'BBAC40CD-6A3A-4EEF-A381-DB7C8950995E',
    name: 'puzzle-outline',
    codepoint: 'F0A66',
    deprecated: 'FALSE',
  },
  {
    id: '72A20545-216C-4CD8-B1E4-808B8B2B96B6',
    name: 'puzzle-plus',
    codepoint: 'F14D0',
    deprecated: 'FALSE',
  },
  {
    id: '46577FE3-F2BF-4B41-B50D-3E6ABD7484B0',
    name: 'puzzle-plus-outline',
    codepoint: 'F14D6',
    deprecated: 'FALSE',
  },
  {
    id: '29CE71EA-2734-48D2-9FFD-FEB8ADF50C12',
    name: 'puzzle-remove',
    codepoint: 'F14D2',
    deprecated: 'FALSE',
  },
  {
    id: '05B602BB-4734-4D4B-A268-FE31171DBD66',
    name: 'puzzle-remove-outline',
    codepoint: 'F14D8',
    deprecated: 'FALSE',
  },
  {
    id: '0DA18E4C-075B-4DE5-A428-31FB0BA791E4',
    name: 'puzzle-star',
    codepoint: 'F14D5',
    deprecated: 'FALSE',
  },
  {
    id: '72BEE187-7B9C-4106-92EA-CD040D56815F',
    name: 'puzzle-star-outline',
    codepoint: 'F14DB',
    deprecated: 'FALSE',
  },
  {
    id: 'EB4E13BA-AB0B-4D32-BAAE-2D37A685768B',
    name: 'pyramid',
    codepoint: 'F1952',
    deprecated: 'FALSE',
  },
  {
    id: '2E5C5383-D159-4741-8970-2E9CCAD0D27D',
    name: 'pyramid-off',
    codepoint: 'F1953',
    deprecated: 'FALSE',
  },
  {
    id: '1F897ED3-17CC-43F5-9A0B-F7804A8DFABF',
    name: 'qi',
    codepoint: 'F0999',
    deprecated: 'FALSE',
  },
  {
    id: '6EAADAE4-30AE-4F69-888F-364BBAFFF5E7',
    name: 'qqchat',
    codepoint: 'F0605',
    deprecated: 'TRUE',
  },
  {
    id: '779E7DDE-0ACE-4752-9966-22761E2A128A',
    name: 'qrcode',
    codepoint: 'F0432',
    deprecated: 'FALSE',
  },
  {
    id: '603BF7E7-03A6-4141-8A50-CA124B62C621',
    name: 'qrcode-edit',
    codepoint: 'F08B8',
    deprecated: 'FALSE',
  },
  {
    id: '2DD6D091-2E48-41D6-A9E1-67BF485B103E',
    name: 'qrcode-minus',
    codepoint: 'F118C',
    deprecated: 'FALSE',
  },
  {
    id: 'B86DA56E-59D6-4B0B-BE63-D66496C8E6EB',
    name: 'qrcode-plus',
    codepoint: 'F118B',
    deprecated: 'FALSE',
  },
  {
    id: 'D41EE63E-AFC3-4A60-891F-841AF0AEBF22',
    name: 'qrcode-remove',
    codepoint: 'F118D',
    deprecated: 'FALSE',
  },
  {
    id: 'C8A7580B-5288-4A60-8033-797EB07A05AC',
    name: 'qrcode-scan',
    codepoint: 'F0433',
    deprecated: 'FALSE',
  },
  {
    id: 'E81082B7-E851-4407-B0D2-08CC105C6A5D',
    name: 'quadcopter',
    codepoint: 'F0434',
    deprecated: 'FALSE',
  },
  {
    id: 'C07BA504-080D-4B47-AD4D-7F772E5C92F3',
    name: 'quality-high',
    codepoint: 'F0435',
    deprecated: 'FALSE',
  },
  {
    id: '51E44ABD-8132-4758-905E-4F46784ED768',
    name: 'quality-low',
    codepoint: 'F0A0C',
    deprecated: 'FALSE',
  },
  {
    id: 'C987C0CA-2D23-427B-8F9C-F3E10B32156C',
    name: 'quality-medium',
    codepoint: 'F0A0D',
    deprecated: 'FALSE',
  },
  {
    id: 'F4C8FDC5-0F7D-4EDA-8242-B356F5E542A4',
    name: 'quora',
    codepoint: 'F0D29',
    deprecated: 'FALSE',
  },
  {
    id: '1D7338F8-4F44-43E3-906B-571178E63E49',
    name: 'rabbit',
    codepoint: 'F0907',
    deprecated: 'FALSE',
  },
  {
    id: '8CEA8CC8-F495-4F1B-B28B-BAA2D2F2EA44',
    name: 'rabbit-variant',
    codepoint: 'F1A61',
    deprecated: 'FALSE',
  },
  {
    id: '3A22546F-5A22-4E53-A13D-05FB82BAB08F',
    name: 'rabbit-variant-outline',
    codepoint: 'F1A62',
    deprecated: 'FALSE',
  },
  {
    id: '59B7D141-A76F-4794-A484-CE089FCF7C46',
    name: 'racing-helmet',
    codepoint: 'F0D93',
    deprecated: 'FALSE',
  },
  {
    id: '0E46DC98-E185-4B26-A4E9-FC1CEC750172',
    name: 'racquetball',
    codepoint: 'F0D94',
    deprecated: 'FALSE',
  },
  {
    id: '73BD6C2A-0D5B-4DF6-9FA3-EC489AFBF947',
    name: 'radar',
    codepoint: 'F0437',
    deprecated: 'FALSE',
  },
  {
    id: 'BFFA61BF-959B-4E15-BE31-4D9AFB5E3FDF',
    name: 'radiator',
    codepoint: 'F0438',
    deprecated: 'FALSE',
  },
  {
    id: '79B0F153-88BE-4FCD-94CB-E44D648DAAE2',
    name: 'radiator-disabled',
    codepoint: 'F0AD7',
    deprecated: 'FALSE',
  },
  {
    id: 'F1BF1F5D-82C7-41EA-B9CD-325265C3D760',
    name: 'radiator-off',
    codepoint: 'F0AD8',
    deprecated: 'FALSE',
  },
  {
    id: '2FA21C1E-99C3-4C9B-AF46-8DF6B9F2A8FC',
    name: 'radio',
    codepoint: 'F0439',
    deprecated: 'FALSE',
  },
  {
    id: 'D0AEFF89-C011-4445-B685-A1366099D920',
    name: 'radio-am',
    codepoint: 'F0CBE',
    deprecated: 'FALSE',
  },
  {
    id: '55BADC53-06EE-4199-8761-80AF2240DE1D',
    name: 'radio-fm',
    codepoint: 'F0CBF',
    deprecated: 'FALSE',
  },
  {
    id: '6EE69D5F-CF42-4257-B974-470E397E0DC1',
    name: 'radio-handheld',
    codepoint: 'F043A',
    deprecated: 'FALSE',
  },
  {
    id: 'B39962EE-105B-4093-9B4C-6078FEA43DA2',
    name: 'radio-off',
    codepoint: 'F121C',
    deprecated: 'FALSE',
  },
  {
    id: '8E5FC366-E9F8-40ED-A16D-CD6C87B0C5B1',
    name: 'radio-tower',
    codepoint: 'F043B',
    deprecated: 'FALSE',
  },
  {
    id: 'D70016AF-1FEE-4E66-8D51-3DE1BAAC0031',
    name: 'radioactive',
    codepoint: 'F043C',
    deprecated: 'FALSE',
  },
  {
    id: 'DBCBBE8F-7DFA-4C05-8A2C-8BDE851A0322',
    name: 'radioactive-circle',
    codepoint: 'F185D',
    deprecated: 'FALSE',
  },
  {
    id: '1C482356-F93E-46FB-B4FF-6142BB859A7F',
    name: 'radioactive-circle-outline',
    codepoint: 'F185E',
    deprecated: 'FALSE',
  },
  {
    id: '3AD51A23-9040-4D23-AE50-B28C6DFF82F6',
    name: 'radioactive-off',
    codepoint: 'F0EC1',
    deprecated: 'FALSE',
  },
  {
    id: '10BF2326-3190-4C27-9040-E2F8F4961238',
    name: 'radiobox-blank',
    codepoint: 'F043D',
    deprecated: 'FALSE',
  },
  {
    id: 'BA101E5E-CDA9-45AA-B249-BD176AF11D95',
    name: 'radiobox-marked',
    codepoint: 'F043E',
    deprecated: 'FALSE',
  },
  {
    id: '17B9888B-F36F-4D44-9318-BBB6ADA0F61B',
    name: 'radiology-box',
    codepoint: 'F14C5',
    deprecated: 'FALSE',
  },
  {
    id: '75708207-7E52-443D-B1DE-5BA088AED95E',
    name: 'radiology-box-outline',
    codepoint: 'F14C6',
    deprecated: 'FALSE',
  },
  {
    id: 'DFD4C66B-CD4A-4524-A6DB-E3C2331C5DC6',
    name: 'radius',
    codepoint: 'F0CC0',
    deprecated: 'FALSE',
  },
  {
    id: '51C82800-F946-45C3-A88B-B5D52BD6B80B',
    name: 'radius-outline',
    codepoint: 'F0CC1',
    deprecated: 'FALSE',
  },
  {
    id: '7FC3FA6E-ED0B-4BCD-AF44-1125CC2CE3C4',
    name: 'railroad-light',
    codepoint: 'F0F1E',
    deprecated: 'FALSE',
  },
  {
    id: '0523157C-6328-499C-A114-FDAD94CFDDAE',
    name: 'rake',
    codepoint: 'F1544',
    deprecated: 'FALSE',
  },
  {
    id: 'AFC49CE3-9442-4F15-B3EE-6C841A226040',
    name: 'raspberry-pi',
    codepoint: 'F043F',
    deprecated: 'FALSE',
  },
  {
    id: '0B304D53-8218-4F49-9330-1AB9CCB11C01',
    name: 'raw',
    codepoint: 'F1A0F',
    deprecated: 'FALSE',
  },
  {
    id: '8882E66A-7C74-4F2A-B38F-94AF59CFB92B',
    name: 'raw-off',
    codepoint: 'F1A10',
    deprecated: 'FALSE',
  },
  {
    id: '935371AF-0FF0-4D1E-86F8-DB6E5C5510F9',
    name: 'ray-end',
    codepoint: 'F0440',
    deprecated: 'FALSE',
  },
  {
    id: '16DEE563-B4C3-4B6D-A47F-B23151965007',
    name: 'ray-end-arrow',
    codepoint: 'F0441',
    deprecated: 'FALSE',
  },
  {
    id: '932F0926-4F5D-4ACA-B4E3-461837361212',
    name: 'ray-start',
    codepoint: 'F0442',
    deprecated: 'FALSE',
  },
  {
    id: '5D6F58F7-61B8-4461-B284-4B0D6B031CA2',
    name: 'ray-start-arrow',
    codepoint: 'F0443',
    deprecated: 'FALSE',
  },
  {
    id: '0C572F61-2EF1-4F4D-9B53-8FE285BC0BFB',
    name: 'ray-start-end',
    codepoint: 'F0444',
    deprecated: 'FALSE',
  },
  {
    id: 'B911CCA3-1237-45C3-BCE1-34CB034F8739',
    name: 'ray-start-vertex-end',
    codepoint: 'F15D8',
    deprecated: 'FALSE',
  },
  {
    id: 'B391C303-5456-4746-83DD-7862952E281E',
    name: 'ray-vertex',
    codepoint: 'F0445',
    deprecated: 'FALSE',
  },
  {
    id: '5BA6A47A-C308-46D9-B991-1B79CDF92000',
    name: 'razor-double-edge',
    codepoint: 'F1997',
    deprecated: 'FALSE',
  },
  {
    id: 'FE06C355-A6BB-464A-9B7D-B07CE7D551FC',
    name: 'razor-single-edge',
    codepoint: 'F1998',
    deprecated: 'FALSE',
  },
  {
    id: 'F13DC81C-DF03-4A33-99D6-489FBDB5B29C',
    name: 'react',
    codepoint: 'F0708',
    deprecated: 'TRUE',
  },
  {
    id: '36EB4754-ADCF-47B6-9863-4EFFB819FC93',
    name: 'read',
    codepoint: 'F0447',
    deprecated: 'FALSE',
  },
  {
    id: 'EEB46F1F-4996-4B65-A1C6-70DC4D38C34E',
    name: 'receipt',
    codepoint: 'F0824',
    deprecated: 'FALSE',
  },
  {
    id: '179831B0-F175-4694-9313-3B745C133F96',
    name: 'receipt-outline',
    codepoint: 'F04F7',
    deprecated: 'FALSE',
  },
  {
    id: '80E9E435-2478-4B48-99B8-D8BEF353AE5F',
    name: 'receipt-text',
    codepoint: 'F0449',
    deprecated: 'FALSE',
  },
  {
    id: '9B57706E-4682-4A41-8E2E-D05601DE5FC4',
    name: 'receipt-text-check',
    codepoint: 'F1A63',
    deprecated: 'FALSE',
  },
  {
    id: '14E01759-3C9F-42FE-8831-4634FFF35029',
    name: 'receipt-text-check-outline',
    codepoint: 'F1A64',
    deprecated: 'FALSE',
  },
  {
    id: 'E923B212-FD3F-40F0-AFD5-1C91F3649E68',
    name: 'receipt-text-minus',
    codepoint: 'F1A65',
    deprecated: 'FALSE',
  },
  {
    id: '0AED24B5-1FC4-4E34-8617-A80675E9B2E4',
    name: 'receipt-text-minus-outline',
    codepoint: 'F1A66',
    deprecated: 'FALSE',
  },
  {
    id: 'C5A53971-FD74-4C82-8CA6-9C1216713365',
    name: 'receipt-text-outline',
    codepoint: 'F19DC',
    deprecated: 'FALSE',
  },
  {
    id: '044F6733-211D-496D-B957-F523D80A6F91',
    name: 'receipt-text-plus',
    codepoint: 'F1A67',
    deprecated: 'FALSE',
  },
  {
    id: 'E376A548-0B7B-4493-9984-842CFC18A87E',
    name: 'receipt-text-plus-outline',
    codepoint: 'F1A68',
    deprecated: 'FALSE',
  },
  {
    id: '66F972AC-ADC8-4583-B358-C5D6FD241316',
    name: 'receipt-text-remove',
    codepoint: 'F1A69',
    deprecated: 'FALSE',
  },
  {
    id: '5FDC843B-FE27-461B-B511-D78947092DF3',
    name: 'receipt-text-remove-outline',
    codepoint: 'F1A6A',
    deprecated: 'FALSE',
  },
  {
    id: 'F84F1E59-868C-42CE-80EA-159588E83462',
    name: 'record',
    codepoint: 'F044A',
    deprecated: 'FALSE',
  },
  {
    id: '4B8F147B-1AE7-4CB5-BAD1-4F6793B79303',
    name: 'record-circle',
    codepoint: 'F0EC2',
    deprecated: 'FALSE',
  },
  {
    id: 'E9D05E0B-362E-4D08-A812-D8A6C8D8F0DB',
    name: 'record-circle-outline',
    codepoint: 'F0EC3',
    deprecated: 'FALSE',
  },
  {
    id: 'E62E5C13-5DC0-4881-BBD6-EFDEF13B73BB',
    name: 'record-player',
    codepoint: 'F099A',
    deprecated: 'FALSE',
  },
  {
    id: '8C452244-41D8-46F9-AF74-665F62EF5E50',
    name: 'record-rec',
    codepoint: 'F044B',
    deprecated: 'FALSE',
  },
  {
    id: '795894B8-2599-4FC8-9B4C-0A0896C6BBB4',
    name: 'rectangle',
    codepoint: 'F0E5E',
    deprecated: 'FALSE',
  },
  {
    id: '14FABBBE-A903-4400-AC7A-338B46DA0342',
    name: 'rectangle-outline',
    codepoint: 'F0E5F',
    deprecated: 'FALSE',
  },
  {
    id: '93C67522-76EA-4A1D-8C9D-29B0901BE55A',
    name: 'recycle',
    codepoint: 'F044C',
    deprecated: 'FALSE',
  },
  {
    id: 'E4222CF9-9449-4325-A296-1E2897AF0720',
    name: 'recycle-variant',
    codepoint: 'F139D',
    deprecated: 'FALSE',
  },
  {
    id: '8CDBB9B4-921B-47CC-9A06-D0F391666654',
    name: 'reddit',
    codepoint: 'F044D',
    deprecated: 'TRUE',
  },
  {
    id: 'C225AD71-CD53-4F22-8A24-7D055457E5A5',
    name: 'redhat',
    codepoint: 'F111B',
    deprecated: 'FALSE',
  },
  {
    id: '7061BAB7-4413-4666-A919-9DD8C4F405F9',
    name: 'redo',
    codepoint: 'F044E',
    deprecated: 'FALSE',
  },
  {
    id: '72717CF3-6D1D-4032-9799-1F553CAD067E',
    name: 'redo-variant',
    codepoint: 'F044F',
    deprecated: 'FALSE',
  },
  {
    id: '345B3651-D634-4069-8D6F-CB4A2CAD63B9',
    name: 'reflect-horizontal',
    codepoint: 'F0A0E',
    deprecated: 'FALSE',
  },
  {
    id: '998E1E1F-98A2-4DA8-9FAD-E31C2677B8D6',
    name: 'reflect-vertical',
    codepoint: 'F0A0F',
    deprecated: 'FALSE',
  },
  {
    id: 'AD3C7A2B-0B64-4183-9452-C96EAAF3E08E',
    name: 'refresh',
    codepoint: 'F0450',
    deprecated: 'FALSE',
  },
  {
    id: 'AD1242A1-160E-4DF0-9E29-15F0A0ECF974',
    name: 'refresh-auto',
    codepoint: 'F18F2',
    deprecated: 'FALSE',
  },
  {
    id: '9D88AEE3-9749-4B74-8F60-2995BD811AAA',
    name: 'refresh-circle',
    codepoint: 'F1377',
    deprecated: 'FALSE',
  },
  {
    id: '3313CE4A-E2EF-4E46-8110-C0BF297D0C16',
    name: 'regex',
    codepoint: 'F0451',
    deprecated: 'FALSE',
  },
  {
    id: '7FDB507F-A062-479D-8401-AFD4B723BA60',
    name: 'registered-trademark',
    codepoint: 'F0A67',
    deprecated: 'FALSE',
  },
  {
    id: 'DBD21D6F-818C-46D0-860A-89257B1BAB90',
    name: 'reiterate',
    codepoint: 'F1588',
    deprecated: 'FALSE',
  },
  {
    id: 'FF422947-C9DA-45C1-BEBF-47A450A035CA',
    name: 'relation-many-to-many',
    codepoint: 'F1496',
    deprecated: 'FALSE',
  },
  {
    id: 'DC16CBA8-F156-4EF8-A18C-56A0DBA8B2D5',
    name: 'relation-many-to-one',
    codepoint: 'F1497',
    deprecated: 'FALSE',
  },
  {
    id: 'A7E9839D-C578-4FE4-ABEB-84FA70BC1926',
    name: 'relation-many-to-one-or-many',
    codepoint: 'F1498',
    deprecated: 'FALSE',
  },
  {
    id: '2F3ABDAA-9F84-45DC-8913-51EEE0769D13',
    name: 'relation-many-to-only-one',
    codepoint: 'F1499',
    deprecated: 'FALSE',
  },
  {
    id: '187F026D-5528-4DDB-AEDD-B921AD57DEEF',
    name: 'relation-many-to-zero-or-many',
    codepoint: 'F149A',
    deprecated: 'FALSE',
  },
  {
    id: '0389409A-7A71-49DC-8296-2F38C2A6E7FE',
    name: 'relation-many-to-zero-or-one',
    codepoint: 'F149B',
    deprecated: 'FALSE',
  },
  {
    id: 'C3AABB15-5720-463D-B37C-4552C1F4BE34',
    name: 'relation-one-or-many-to-many',
    codepoint: 'F149C',
    deprecated: 'FALSE',
  },
  {
    id: '5EABC280-A332-4EA2-A6EE-D5D1E5DE6812',
    name: 'relation-one-or-many-to-one',
    codepoint: 'F149D',
    deprecated: 'FALSE',
  },
  {
    id: 'F6B51EA0-D90A-4B65-9B0C-8AD08392C943',
    name: 'relation-one-or-many-to-one-or-many',
    codepoint: 'F149E',
    deprecated: 'FALSE',
  },
  {
    id: '2DC9A1E1-BE10-4643-B02E-7DE27F7F140C',
    name: 'relation-one-or-many-to-only-one',
    codepoint: 'F149F',
    deprecated: 'FALSE',
  },
  {
    id: 'E19D3A76-6993-4948-8F18-F777E3CA7383',
    name: 'relation-one-or-many-to-zero-or-many',
    codepoint: 'F14A0',
    deprecated: 'FALSE',
  },
  {
    id: 'F7CAF815-B3D6-490B-8AC6-B2FAB8F5134D',
    name: 'relation-one-or-many-to-zero-or-one',
    codepoint: 'F14A1',
    deprecated: 'FALSE',
  },
  {
    id: '9FCECCB1-41CC-4569-9912-58256B627F37',
    name: 'relation-one-to-many',
    codepoint: 'F14A2',
    deprecated: 'FALSE',
  },
  {
    id: 'BDF61FAA-2D63-4114-93A8-5E13404E7B03',
    name: 'relation-one-to-one',
    codepoint: 'F14A3',
    deprecated: 'FALSE',
  },
  {
    id: '757670CA-87BA-452B-A0D9-ECBB3EF21AA8',
    name: 'relation-one-to-one-or-many',
    codepoint: 'F14A4',
    deprecated: 'FALSE',
  },
  {
    id: '56A95C81-F579-4D28-A5E5-32F44CF67BD3',
    name: 'relation-one-to-only-one',
    codepoint: 'F14A5',
    deprecated: 'FALSE',
  },
  {
    id: '0FC49382-3ED0-437F-8522-29C5F5352C30',
    name: 'relation-one-to-zero-or-many',
    codepoint: 'F14A6',
    deprecated: 'FALSE',
  },
  {
    id: '543B9D69-53A1-4DC3-8074-86123BC0CC70',
    name: 'relation-one-to-zero-or-one',
    codepoint: 'F14A7',
    deprecated: 'FALSE',
  },
  {
    id: '55569D10-FC5C-4E3C-8A35-876DCC12D543',
    name: 'relation-only-one-to-many',
    codepoint: 'F14A8',
    deprecated: 'FALSE',
  },
  {
    id: '26A310F2-347A-45E5-AD73-1D92443A1827',
    name: 'relation-only-one-to-one',
    codepoint: 'F14A9',
    deprecated: 'FALSE',
  },
  {
    id: '57C9B4B7-CE27-4769-9D2F-50926D1F1A29',
    name: 'relation-only-one-to-one-or-many',
    codepoint: 'F14AA',
    deprecated: 'FALSE',
  },
  {
    id: '23DF7622-E2D4-45E0-A646-CA10B71EBC10',
    name: 'relation-only-one-to-only-one',
    codepoint: 'F14AB',
    deprecated: 'FALSE',
  },
  {
    id: '53287B7C-9B73-48F6-B558-BBFC529D1D48',
    name: 'relation-only-one-to-zero-or-many',
    codepoint: 'F14AC',
    deprecated: 'FALSE',
  },
  {
    id: 'F93B3000-FF04-4374-94AB-7E703AE780AD',
    name: 'relation-only-one-to-zero-or-one',
    codepoint: 'F14AD',
    deprecated: 'FALSE',
  },
  {
    id: '3F67D802-700C-4692-9B30-88129AF5C954',
    name: 'relation-zero-or-many-to-many',
    codepoint: 'F14AE',
    deprecated: 'FALSE',
  },
  {
    id: '8884AF49-A6E9-4442-A648-72C365E8B7B8',
    name: 'relation-zero-or-many-to-one',
    codepoint: 'F14AF',
    deprecated: 'FALSE',
  },
  {
    id: 'DB4A4065-5FCF-4563-A725-7FF82BF05524',
    name: 'relation-zero-or-many-to-one-or-many',
    codepoint: 'F14B0',
    deprecated: 'FALSE',
  },
  {
    id: 'D5FECDF0-F04F-40DA-A4EE-5F1659883460',
    name: 'relation-zero-or-many-to-only-one',
    codepoint: 'F14B1',
    deprecated: 'FALSE',
  },
  {
    id: '1E86EED5-2458-46D6-8AD0-E14DC65FE7FA',
    name: 'relation-zero-or-many-to-zero-or-many',
    codepoint: 'F14B2',
    deprecated: 'FALSE',
  },
  {
    id: '26C96921-576B-42F4-B424-2E3F3FF83346',
    name: 'relation-zero-or-many-to-zero-or-one',
    codepoint: 'F14B3',
    deprecated: 'FALSE',
  },
  {
    id: 'C09E5EAE-BC42-480C-842F-EBADFD035886',
    name: 'relation-zero-or-one-to-many',
    codepoint: 'F14B4',
    deprecated: 'FALSE',
  },
  {
    id: 'E262BBDE-079C-4DF6-8C5C-00F9FB3503B5',
    name: 'relation-zero-or-one-to-one',
    codepoint: 'F14B5',
    deprecated: 'FALSE',
  },
  {
    id: '4A6CE6A7-7F49-42E3-8EC5-DEA80D1A2559',
    name: 'relation-zero-or-one-to-one-or-many',
    codepoint: 'F14B6',
    deprecated: 'FALSE',
  },
  {
    id: '78BC5AAC-0D19-49F6-AF3F-523739CA933C',
    name: 'relation-zero-or-one-to-only-one',
    codepoint: 'F14B7',
    deprecated: 'FALSE',
  },
  {
    id: '79676BBB-E83B-4661-AEAB-E3FC622BDD00',
    name: 'relation-zero-or-one-to-zero-or-many',
    codepoint: 'F14B8',
    deprecated: 'FALSE',
  },
  {
    id: '1B446467-7655-425C-A7C1-ED97D7599D80',
    name: 'relation-zero-or-one-to-zero-or-one',
    codepoint: 'F14B9',
    deprecated: 'FALSE',
  },
  {
    id: '912AD329-9B77-4959-9407-0F64BABFAC7D',
    name: 'relative-scale',
    codepoint: 'F0452',
    deprecated: 'FALSE',
  },
  {
    id: '8E25B2CE-4DAF-4BD1-9D3F-27F86DA55D21',
    name: 'reload',
    codepoint: 'F0453',
    deprecated: 'FALSE',
  },
  {
    id: '01D5FD19-E2FA-4BD0-9F6C-ECA058F469F1',
    name: 'reload-alert',
    codepoint: 'F110B',
    deprecated: 'FALSE',
  },
  {
    id: '1BD93F0A-87D1-4B55-A0EF-D9F05A8B3826',
    name: 'reminder',
    codepoint: 'F088C',
    deprecated: 'FALSE',
  },
  {
    id: '49C7BBC2-6C7F-442E-A6EE-B58AB758A8D4',
    name: 'remote',
    codepoint: 'F0454',
    deprecated: 'FALSE',
  },
  {
    id: 'A237199B-0B22-475F-93D1-DB45FA4D3205',
    name: 'remote-desktop',
    codepoint: 'F08B9',
    deprecated: 'FALSE',
  },
  {
    id: '6DC5640C-42D8-45A0-8CEC-B78E045600FE',
    name: 'remote-off',
    codepoint: 'F0EC4',
    deprecated: 'FALSE',
  },
  {
    id: '7B245C49-09E7-425A-AFF2-714E9634436E',
    name: 'remote-tv',
    codepoint: 'F0EC5',
    deprecated: 'FALSE',
  },
  {
    id: 'D0485B00-63FF-44F5-8DE2-480F970D5ADD',
    name: 'remote-tv-off',
    codepoint: 'F0EC6',
    deprecated: 'FALSE',
  },
  {
    id: '43D55245-34B8-4F1B-953A-CCE75AF35866',
    name: 'rename',
    codepoint: 'F1C18',
    deprecated: 'FALSE',
  },
  {
    id: '1DFADA84-47E4-40EF-B1F8-D0A34D1482BA',
    name: 'rename-box',
    codepoint: 'F0455',
    deprecated: 'FALSE',
  },
  {
    id: '7EDB6900-E536-4424-96D4-71C36753BE76',
    name: 'rename-box-outline',
    codepoint: 'F1C19',
    deprecated: 'FALSE',
  },
  {
    id: '1F4744E0-37F4-4D51-82FF-B332A0CCE347',
    name: 'rename-outline',
    codepoint: 'F1C1A',
    deprecated: 'FALSE',
  },
  {
    id: '08CB23B7-EC19-4FE5-9CBB-C308CC89C10B',
    name: 'reorder-horizontal',
    codepoint: 'F0688',
    deprecated: 'FALSE',
  },
  {
    id: 'F189DB12-9323-47A1-89C9-451EF2A886CB',
    name: 'reorder-vertical',
    codepoint: 'F0689',
    deprecated: 'FALSE',
  },
  {
    id: '9AEE0CB7-46B9-414F-A8A8-AFB127CB7057',
    name: 'repeat',
    codepoint: 'F0456',
    deprecated: 'FALSE',
  },
  {
    id: '06540FC6-0AF2-40C7-895A-B8C98BD143D1',
    name: 'repeat-off',
    codepoint: 'F0457',
    deprecated: 'FALSE',
  },
  {
    id: 'D27E266C-5F69-4E4F-9DE3-65A47B5208F5',
    name: 'repeat-once',
    codepoint: 'F0458',
    deprecated: 'FALSE',
  },
  {
    id: '2788034C-2A06-46C0-82B9-A34CB23C88E0',
    name: 'repeat-variant',
    codepoint: 'F0547',
    deprecated: 'FALSE',
  },
  {
    id: 'A15D2CB0-E938-4894-8BF3-BC6C628E6433',
    name: 'replay',
    codepoint: 'F0459',
    deprecated: 'FALSE',
  },
  {
    id: 'CC3BEE2C-0FEA-46D2-BC53-F7D248AAF47C',
    name: 'reply',
    codepoint: 'F045A',
    deprecated: 'FALSE',
  },
  {
    id: '0BDA0697-946D-4B4C-AD02-2FC6ECA4A528',
    name: 'reply-all',
    codepoint: 'F045B',
    deprecated: 'FALSE',
  },
  {
    id: 'C89953B0-3E41-4F03-B0C3-4ED8F96C1234',
    name: 'reply-all-outline',
    codepoint: 'F0F1F',
    deprecated: 'FALSE',
  },
  {
    id: '25084C11-A1EE-4158-BC41-5E80E00E837E',
    name: 'reply-circle',
    codepoint: 'F11AE',
    deprecated: 'FALSE',
  },
  {
    id: '7FBB6798-7E14-45FC-973A-8F4FD184F92A',
    name: 'reply-outline',
    codepoint: 'F0F20',
    deprecated: 'FALSE',
  },
  {
    id: 'A522A51F-EE61-473F-97D6-9D5106FD11CA',
    name: 'reproduction',
    codepoint: 'F045C',
    deprecated: 'FALSE',
  },
  {
    id: '37D62FB9-6BB0-49D6-8C3E-DF75338DD275',
    name: 'resistor',
    codepoint: 'F0B44',
    deprecated: 'FALSE',
  },
  {
    id: '8DB0A5C4-31AD-42A8-A42E-4690B20D5AA0',
    name: 'resistor-nodes',
    codepoint: 'F0B45',
    deprecated: 'FALSE',
  },
  {
    id: '5D2EF9DD-38C1-4D2B-A24B-7270C66A6667',
    name: 'resize',
    codepoint: 'F0A68',
    deprecated: 'FALSE',
  },
  {
    id: '84916A68-B413-4D71-B4E7-5FD64914B354',
    name: 'resize-bottom-right',
    codepoint: 'F045D',
    deprecated: 'FALSE',
  },
  {
    id: '0C72C131-779D-460F-A059-B9DF2B79E40C',
    name: 'responsive',
    codepoint: 'F045E',
    deprecated: 'FALSE',
  },
  {
    id: '15954B5D-3ADA-4B6B-BE15-F95B6A5D4659',
    name: 'restart',
    codepoint: 'F0709',
    deprecated: 'FALSE',
  },
  {
    id: '08CBB3AA-B72C-4381-A295-37EC2550AF63',
    name: 'restart-alert',
    codepoint: 'F110C',
    deprecated: 'FALSE',
  },
  {
    id: 'CE31B2C3-939B-4039-9ADC-797509A68F35',
    name: 'restart-off',
    codepoint: 'F0D95',
    deprecated: 'FALSE',
  },
  {
    id: 'FF1CAAB4-2192-442F-88F1-08DEB67E21F0',
    name: 'restore',
    codepoint: 'F099B',
    deprecated: 'FALSE',
  },
  {
    id: 'A9EF3AB2-700F-422A-AE07-0F6C3DB5BDB6',
    name: 'restore-alert',
    codepoint: 'F110D',
    deprecated: 'FALSE',
  },
  {
    id: '28BCDF41-AA93-4C25-B87A-95A3F5DD8805',
    name: 'rewind',
    codepoint: 'F045F',
    deprecated: 'FALSE',
  },
  {
    id: '3A7C53D9-B7BB-49A7-9393-38F16B0F5D52',
    name: 'rewind-10',
    codepoint: 'F0D2A',
    deprecated: 'FALSE',
  },
  {
    id: '2DB4A76F-04DC-4E7A-88F0-72C936921B48',
    name: 'rewind-15',
    codepoint: 'F1946',
    deprecated: 'FALSE',
  },
  {
    id: '21F6D751-0E5A-41F8-980C-2C4385A59CC4',
    name: 'rewind-30',
    codepoint: 'F0D96',
    deprecated: 'FALSE',
  },
  {
    id: '6F87706E-D3BC-4789-9274-D80FA47FE5E1',
    name: 'rewind-45',
    codepoint: 'F1B13',
    deprecated: 'FALSE',
  },
  {
    id: '167E7244-A6F8-400E-8CF7-F71B3FAEEEBE',
    name: 'rewind-5',
    codepoint: 'F11F9',
    deprecated: 'FALSE',
  },
  {
    id: '7C7A1566-44FB-4A53-AE61-95F5A03F6978',
    name: 'rewind-60',
    codepoint: 'F160C',
    deprecated: 'FALSE',
  },
  {
    id: '61E83433-F6D1-442D-9EDE-EDEF27AB789D',
    name: 'rewind-outline',
    codepoint: 'F070A',
    deprecated: 'FALSE',
  },
  {
    id: '68CFD178-A6FE-4DB5-9C39-FCA6A3EA83A5',
    name: 'rhombus',
    codepoint: 'F070B',
    deprecated: 'FALSE',
  },
  {
    id: 'F6FA48B9-85C9-4287-8883-6A549AC290D2',
    name: 'rhombus-medium',
    codepoint: 'F0A10',
    deprecated: 'FALSE',
  },
  {
    id: '01D071A4-DA5C-4735-A72F-53D0B8EC9EDF',
    name: 'rhombus-medium-outline',
    codepoint: 'F14DC',
    deprecated: 'FALSE',
  },
  {
    id: '101A7558-A38E-48E8-93EA-A21F37238E78',
    name: 'rhombus-outline',
    codepoint: 'F070C',
    deprecated: 'FALSE',
  },
  {
    id: 'A19BB3EC-ED3D-41C6-9088-ACB372959E01',
    name: 'rhombus-split',
    codepoint: 'F0A11',
    deprecated: 'FALSE',
  },
  {
    id: 'E9499838-BF7F-48DB-843A-F98796B0D8BB',
    name: 'rhombus-split-outline',
    codepoint: 'F14DD',
    deprecated: 'FALSE',
  },
  {
    id: '1C09CB9B-162B-486B-966C-1FCB18C0F633',
    name: 'ribbon',
    codepoint: 'F0460',
    deprecated: 'FALSE',
  },
  {
    id: 'B90B4B7C-9FDD-427A-834C-F3DFB5EC1823',
    name: 'rice',
    codepoint: 'F07EA',
    deprecated: 'FALSE',
  },
  {
    id: '5B4966BE-753B-4777-A8D8-B67B3BC57C0D',
    name: 'rickshaw',
    codepoint: 'F15BB',
    deprecated: 'FALSE',
  },
  {
    id: '8869DCB9-04E3-41B9-B56C-D4BC5CF2DD7A',
    name: 'rickshaw-electric',
    codepoint: 'F15BC',
    deprecated: 'FALSE',
  },
  {
    id: '7D845AED-5EA2-44C0-8F38-6A2C4E08C8A3',
    name: 'ring',
    codepoint: 'F07EB',
    deprecated: 'FALSE',
  },
  {
    id: 'DA8B7A3A-5971-4875-AA0A-ED17E8A69E22',
    name: 'rivet',
    codepoint: 'F0E60',
    deprecated: 'FALSE',
  },
  {
    id: '2AA1C93A-17BE-4BB6-A85C-C477A4FF1069',
    name: 'road',
    codepoint: 'F0461',
    deprecated: 'FALSE',
  },
  {
    id: '0BBDD64A-3C67-43DD-9B3A-580E12DDA2D7',
    name: 'road-variant',
    codepoint: 'F0462',
    deprecated: 'FALSE',
  },
  {
    id: '45B05AE1-4E78-431F-AAB3-669A72AED11E',
    name: 'robber',
    codepoint: 'F1058',
    deprecated: 'FALSE',
  },
  {
    id: 'EC6C8D3D-0315-4C3A-B37A-97411758DF34',
    name: 'robot',
    codepoint: 'F06A9',
    deprecated: 'FALSE',
  },
  {
    id: 'D77B4057-E06B-49B7-BBC7-DD4A7B39BC2A',
    name: 'robot-angry',
    codepoint: 'F169D',
    deprecated: 'FALSE',
  },
  {
    id: 'D922E043-E4B6-4FAA-9717-51C1935DD20A',
    name: 'robot-angry-outline',
    codepoint: 'F169E',
    deprecated: 'FALSE',
  },
  {
    id: '535C4A09-5555-45A6-8409-BF12E1CDA2DE',
    name: 'robot-confused',
    codepoint: 'F169F',
    deprecated: 'FALSE',
  },
  {
    id: '8361794A-9B3E-44CA-9AA4-EA842227D7C2',
    name: 'robot-confused-outline',
    codepoint: 'F16A0',
    deprecated: 'FALSE',
  },
  {
    id: '04405E02-7C38-409E-8F92-785431ECC76F',
    name: 'robot-dead',
    codepoint: 'F16A1',
    deprecated: 'FALSE',
  },
  {
    id: '15D146E0-3683-43D8-8500-905EEAC98010',
    name: 'robot-dead-outline',
    codepoint: 'F16A2',
    deprecated: 'FALSE',
  },
  {
    id: 'B32D5968-FE0C-46FB-916E-52D0103DA2C7',
    name: 'robot-excited',
    codepoint: 'F16A3',
    deprecated: 'FALSE',
  },
  {
    id: '8013E3FE-34B6-49F3-919B-85A5E0A5873C',
    name: 'robot-excited-outline',
    codepoint: 'F16A4',
    deprecated: 'FALSE',
  },
  {
    id: '6AE4D050-1B64-4AAB-B57B-DD738276E82F',
    name: 'robot-happy',
    codepoint: 'F1719',
    deprecated: 'FALSE',
  },
  {
    id: '62023A79-6DF7-4B3B-BDCA-FEEAE73C9F89',
    name: 'robot-happy-outline',
    codepoint: 'F171A',
    deprecated: 'FALSE',
  },
  {
    id: 'C008FBCE-4D0A-4003-8811-FF15D08C3313',
    name: 'robot-industrial',
    codepoint: 'F0B46',
    deprecated: 'FALSE',
  },
  {
    id: '318E8495-2191-4315-A58D-77D697743290',
    name: 'robot-industrial-outline',
    codepoint: 'F1A1A',
    deprecated: 'FALSE',
  },
  {
    id: '2EFA6B95-27BD-4A52-90AF-991A3574FD2C',
    name: 'robot-love',
    codepoint: 'F16A5',
    deprecated: 'FALSE',
  },
  {
    id: 'FA128C47-3C41-4B0D-8F18-8E28E9F3EA0C',
    name: 'robot-love-outline',
    codepoint: 'F16A6',
    deprecated: 'FALSE',
  },
  {
    id: '2029DE02-EC25-4242-945F-D7495F35D071',
    name: 'robot-mower',
    codepoint: 'F11F7',
    deprecated: 'FALSE',
  },
  {
    id: 'D40FCE9F-E14E-4F2E-83BD-39704B38E446',
    name: 'robot-mower-outline',
    codepoint: 'F11F3',
    deprecated: 'FALSE',
  },
  {
    id: 'B62D8FF2-AF07-4D20-8C8B-93E585EF6EFB',
    name: 'robot-off',
    codepoint: 'F16A7',
    deprecated: 'FALSE',
  },
  {
    id: '0BA0757D-0E1F-4484-86AF-7476790AE21C',
    name: 'robot-off-outline',
    codepoint: 'F167B',
    deprecated: 'FALSE',
  },
  {
    id: 'C0F3E15B-2DFB-4EAF-B41A-2D18EEA22C25',
    name: 'robot-outline',
    codepoint: 'F167A',
    deprecated: 'FALSE',
  },
  {
    id: 'E091C285-7AA8-4907-9398-EBFDAC7A179D',
    name: 'robot-vacuum',
    codepoint: 'F070D',
    deprecated: 'FALSE',
  },
  {
    id: '05DDCBE1-561A-4961-87F7-CA9BE0C182BE',
    name: 'robot-vacuum-alert',
    codepoint: 'F1B5D',
    deprecated: 'FALSE',
  },
  {
    id: '0EF94574-EBEE-40D6-8D73-613C2D108B75',
    name: 'robot-vacuum-off',
    codepoint: 'F1C01',
    deprecated: 'FALSE',
  },
  {
    id: 'C7938632-FA58-4686-9B82-5E9CCDD0EC83',
    name: 'robot-vacuum-variant',
    codepoint: 'F0908',
    deprecated: 'FALSE',
  },
  {
    id: 'DC45B355-D0D4-4616-B1A0-34D22285FE1F',
    name: 'robot-vacuum-variant-alert',
    codepoint: 'F1B5E',
    deprecated: 'FALSE',
  },
  {
    id: '03508026-E2C8-4A8F-A13E-9D685EF26E14',
    name: 'robot-vacuum-variant-off',
    codepoint: 'F1C02',
    deprecated: 'FALSE',
  },
  {
    id: '96BE0E43-42AB-4F93-8045-0C7B61BF69CC',
    name: 'rocket',
    codepoint: 'F0463',
    deprecated: 'FALSE',
  },
  {
    id: 'E2791217-9CAB-41CA-9899-9BD0964B4DF9',
    name: 'rocket-launch',
    codepoint: 'F14DE',
    deprecated: 'FALSE',
  },
  {
    id: 'F6DA21B8-C4AD-48D1-9F97-9A59F721956B',
    name: 'rocket-launch-outline',
    codepoint: 'F14DF',
    deprecated: 'FALSE',
  },
  {
    id: '934FA95E-C5B3-4A5E-B99D-85B0E65CBDBA',
    name: 'rocket-outline',
    codepoint: 'F13AF',
    deprecated: 'FALSE',
  },
  {
    id: 'AA8B7EA9-2DB2-4591-94E0-A457373E64C1',
    name: 'rodent',
    codepoint: 'F1327',
    deprecated: 'FALSE',
  },
  {
    id: '223BB690-0799-4F91-A756-5425358A7C4D',
    name: 'roller-shade',
    codepoint: 'F1A6B',
    deprecated: 'FALSE',
  },
  {
    id: '796C645E-B8C8-4898-AE15-981077DEC280',
    name: 'roller-shade-closed',
    codepoint: 'F1A6C',
    deprecated: 'FALSE',
  },
  {
    id: '6EFB213D-87A6-454A-AB37-AB87CE853E4B',
    name: 'roller-skate',
    codepoint: 'F0D2B',
    deprecated: 'FALSE',
  },
  {
    id: 'A6387660-7DB0-41DC-9ECF-EBBA5A78FC82',
    name: 'roller-skate-off',
    codepoint: 'F0145',
    deprecated: 'FALSE',
  },
  {
    id: '366C920F-6DC6-46FF-94C6-44A8D22A7D1D',
    name: 'rollerblade',
    codepoint: 'F0D2C',
    deprecated: 'FALSE',
  },
  {
    id: 'BC1934AD-28E6-4E7C-8C7E-1557CE482CCB',
    name: 'rollerblade-off',
    codepoint: 'F002E',
    deprecated: 'FALSE',
  },
  {
    id: '9BBAB3C0-02B2-436A-94C8-0EBD388289D8',
    name: 'rollupjs',
    codepoint: 'F0BC0',
    deprecated: 'TRUE',
  },
  {
    id: '19AA4DCD-7270-48A6-ADB1-819098F2250A',
    name: 'rolodex',
    codepoint: 'F1AB9',
    deprecated: 'FALSE',
  },
  {
    id: 'A00CFDF9-821F-4F98-9767-22163517ED0F',
    name: 'rolodex-outline',
    codepoint: 'F1ABA',
    deprecated: 'FALSE',
  },
  {
    id: '7E900912-C3AE-4D82-BCF0-1162ACC7EB15',
    name: 'roman-numeral-1',
    codepoint: 'F1088',
    deprecated: 'FALSE',
  },
  {
    id: '76685845-20ED-49A8-A8ED-EA967F25B5CF',
    name: 'roman-numeral-10',
    codepoint: 'F1091',
    deprecated: 'FALSE',
  },
  {
    id: '4C14E293-1951-4481-9ECC-3D868B764444',
    name: 'roman-numeral-2',
    codepoint: 'F1089',
    deprecated: 'FALSE',
  },
  {
    id: '6594655A-5BB0-43B5-B3BD-CF04D4B59562',
    name: 'roman-numeral-3',
    codepoint: 'F108A',
    deprecated: 'FALSE',
  },
  {
    id: '9D572A82-B0D2-4961-B467-32AD78FF551C',
    name: 'roman-numeral-4',
    codepoint: 'F108B',
    deprecated: 'FALSE',
  },
  {
    id: 'EF5F2939-4E4A-4688-B7F5-DD6BC5C14CD1',
    name: 'roman-numeral-5',
    codepoint: 'F108C',
    deprecated: 'FALSE',
  },
  {
    id: '8E746FAF-3C9D-48B9-8378-A73EAB7C69F4',
    name: 'roman-numeral-6',
    codepoint: 'F108D',
    deprecated: 'FALSE',
  },
  {
    id: 'D725A70F-2F5D-43B1-99B5-A70220CCAD7A',
    name: 'roman-numeral-7',
    codepoint: 'F108E',
    deprecated: 'FALSE',
  },
  {
    id: 'CD729113-BA16-44AE-A012-D8C5BE2D2D7F',
    name: 'roman-numeral-8',
    codepoint: 'F108F',
    deprecated: 'FALSE',
  },
  {
    id: 'A53BFEB9-4F94-42AA-928D-0A0A28A44FD9',
    name: 'roman-numeral-9',
    codepoint: 'F1090',
    deprecated: 'FALSE',
  },
  {
    id: 'C525BD2B-2BA8-4158-AC0A-FAEE9E353047',
    name: 'room-service',
    codepoint: 'F088D',
    deprecated: 'FALSE',
  },
  {
    id: 'A94493FF-30FD-4490-8ABC-F6F03F958D17',
    name: 'room-service-outline',
    codepoint: 'F0D97',
    deprecated: 'FALSE',
  },
  {
    id: '38F235DA-809D-4F32-B88E-870A02826E75',
    name: 'rotate-360',
    codepoint: 'F1999',
    deprecated: 'FALSE',
  },
  {
    id: 'E288FD83-7397-4DF3-BAB8-DF7094955B83',
    name: 'rotate-3d',
    codepoint: 'F0EC7',
    deprecated: 'FALSE',
  },
  {
    id: 'D09D0551-14A0-470B-A24C-0AC38F739FFA',
    name: 'rotate-3d-variant',
    codepoint: 'F0464',
    deprecated: 'FALSE',
  },
  {
    id: '1E960D83-0632-47D2-8CC9-D8A137887BAB',
    name: 'rotate-left',
    codepoint: 'F0465',
    deprecated: 'FALSE',
  },
  {
    id: 'CC6E0E23-9F7B-40F5-B00F-0690A5F7B400',
    name: 'rotate-left-variant',
    codepoint: 'F0466',
    deprecated: 'FALSE',
  },
  {
    id: '41DBDBBC-7E7A-4E2A-A7E9-A22707957334',
    name: 'rotate-orbit',
    codepoint: 'F0D98',
    deprecated: 'FALSE',
  },
  {
    id: '5324E881-FACF-4CAC-9118-FD963EDCDD27',
    name: 'rotate-right',
    codepoint: 'F0467',
    deprecated: 'FALSE',
  },
  {
    id: '02105B39-715B-4989-93DC-1DDC338A4552',
    name: 'rotate-right-variant',
    codepoint: 'F0468',
    deprecated: 'FALSE',
  },
  {
    id: 'FEBD153A-F07E-4329-A727-D75F13F3133D',
    name: 'rounded-corner',
    codepoint: 'F0607',
    deprecated: 'FALSE',
  },
  {
    id: '7D8AE8B4-4A72-45DE-9EA7-B4B6502C0FDD',
    name: 'router',
    codepoint: 'F11E2',
    deprecated: 'FALSE',
  },
  {
    id: '8CCE5060-8983-4F75-B672-32B7599F1082',
    name: 'router-network',
    codepoint: 'F1087',
    deprecated: 'FALSE',
  },
  {
    id: '3244EAC8-8349-4CFB-BE35-A0812368CEDA',
    name: 'router-wireless',
    codepoint: 'F0469',
    deprecated: 'FALSE',
  },
  {
    id: 'E6CF3E53-C5EF-48AF-BA3B-AD339E6A3FA3',
    name: 'router-wireless-off',
    codepoint: 'F15A3',
    deprecated: 'FALSE',
  },
  {
    id: '3881E4FA-15D1-4051-991C-B4B4F0791576',
    name: 'router-wireless-settings',
    codepoint: 'F0A69',
    deprecated: 'FALSE',
  },
  {
    id: '4E7234E7-AB5F-491D-BA6F-674B3BAD9027',
    name: 'routes',
    codepoint: 'F046A',
    deprecated: 'FALSE',
  },
  {
    id: '70E2EE84-23BF-4C5A-9AE7-06D1156042CA',
    name: 'routes-clock',
    codepoint: 'F1059',
    deprecated: 'FALSE',
  },
  {
    id: '72F11BA0-1557-4500-8235-2E3F2346FE69',
    name: 'rowing',
    codepoint: 'F0608',
    deprecated: 'FALSE',
  },
  {
    id: '9E877D76-9BC5-496D-99E4-1E6029DE1B4D',
    name: 'rss',
    codepoint: 'F046B',
    deprecated: 'FALSE',
  },
  {
    id: '649343BD-3C91-43EA-9000-AE10520CAA2D',
    name: 'rss-box',
    codepoint: 'F046C',
    deprecated: 'FALSE',
  },
  {
    id: '45AA2B72-8538-41CB-BAD4-20C43E40D21C',
    name: 'rss-off',
    codepoint: 'F0F21',
    deprecated: 'FALSE',
  },
  {
    id: 'C43D28FD-C647-41B4-A710-E8FFE81D8EAF',
    name: 'rug',
    codepoint: 'F1475',
    deprecated: 'FALSE',
  },
  {
    id: '19489FCE-DD25-4670-BC6F-4FC3AF1E66D3',
    name: 'rugby',
    codepoint: 'F0D99',
    deprecated: 'FALSE',
  },
  {
    id: '66A2EA92-0743-4740-B3F2-254C38E9027F',
    name: 'ruler',
    codepoint: 'F046D',
    deprecated: 'FALSE',
  },
  {
    id: 'A94D1ED1-576F-4E2C-B165-ABAD797E8192',
    name: 'ruler-square',
    codepoint: 'F0CC2',
    deprecated: 'FALSE',
  },
  {
    id: '2E24CF4F-2EC9-46C2-ABB9-341869D562EE',
    name: 'ruler-square-compass',
    codepoint: 'F0EBE',
    deprecated: 'FALSE',
  },
  {
    id: '2A869029-60EB-4965-9BE4-9B3DB964C707',
    name: 'run',
    codepoint: 'F070E',
    deprecated: 'FALSE',
  },
  {
    id: 'D541EB73-227A-419B-A5F7-7242EB02D916',
    name: 'run-fast',
    codepoint: 'F046E',
    deprecated: 'FALSE',
  },
  {
    id: '43C3E250-2842-4973-8D19-CA1E48BCF61C',
    name: 'rv-truck',
    codepoint: 'F11D4',
    deprecated: 'FALSE',
  },
  {
    id: '025FEA9C-17F9-4293-BD59-A8D53B7B4124',
    name: 'sack',
    codepoint: 'F0D2E',
    deprecated: 'FALSE',
  },
  {
    id: '9DA8E933-0065-43E6-9416-0EAA104BEF6F',
    name: 'sack-percent',
    codepoint: 'F0D2F',
    deprecated: 'FALSE',
  },
  {
    id: '013E121E-A100-48D2-941B-854832E70793',
    name: 'safe',
    codepoint: 'F0A6A',
    deprecated: 'FALSE',
  },
  {
    id: 'B12FE612-BEB7-45A2-BB7D-B04C6F33AE1B',
    name: 'safe-square',
    codepoint: 'F127C',
    deprecated: 'FALSE',
  },
  {
    id: '6F471894-793E-4F7F-848D-CE2AA2F4C67D',
    name: 'safe-square-outline',
    codepoint: 'F127D',
    deprecated: 'FALSE',
  },
  {
    id: '438888DA-342B-4861-95EA-026A3F4E2F95',
    name: 'safety-goggles',
    codepoint: 'F0D30',
    deprecated: 'FALSE',
  },
  {
    id: 'F1921E38-A25D-41F6-94B6-B6D01C3E0593',
    name: 'sail-boat',
    codepoint: 'F0EC8',
    deprecated: 'FALSE',
  },
  {
    id: 'BEF26F95-E584-4585-BBD7-3766740FBEBD',
    name: 'sail-boat-sink',
    codepoint: 'F1AEF',
    deprecated: 'FALSE',
  },
  {
    id: '688543EC-EC01-474C-883C-C2EA2EDE75BF',
    name: 'sale',
    codepoint: 'F046F',
    deprecated: 'FALSE',
  },
  {
    id: 'EC5767A6-FA73-4A93-AA73-3EE66CB7374B',
    name: 'sale-outline',
    codepoint: 'F1A06',
    deprecated: 'FALSE',
  },
  {
    id: 'D7963D7C-396F-45DB-A5D7-AA18AE32FE53',
    name: 'salesforce',
    codepoint: 'F088E',
    deprecated: 'TRUE',
  },
  {
    id: '2AFE6E3E-61AB-48D2-A333-E5324AD534CD',
    name: 'sass',
    codepoint: 'F07EC',
    deprecated: 'TRUE',
  },
  {
    id: 'D77BED79-19CC-45A9-9940-5EB621E95C11',
    name: 'satellite',
    codepoint: 'F0470',
    deprecated: 'FALSE',
  },
  {
    id: '9627DDC0-A33C-4B0E-A14F-915809E377AA',
    name: 'satellite-uplink',
    codepoint: 'F0909',
    deprecated: 'FALSE',
  },
  {
    id: '3945E491-7E92-4A9F-BD2A-C436200B3716',
    name: 'satellite-variant',
    codepoint: 'F0471',
    deprecated: 'FALSE',
  },
  {
    id: '191AE0C8-5DBD-4B51-8D52-2872CA89E376',
    name: 'sausage',
    codepoint: 'F08BA',
    deprecated: 'FALSE',
  },
  {
    id: 'AA9C348E-83F3-4EC8-87D5-0E7C8BAAA2DB',
    name: 'sausage-off',
    codepoint: 'F1789',
    deprecated: 'FALSE',
  },
  {
    id: '65F29350-AC15-4F53-B3D0-B7304BA8791A',
    name: 'saw-blade',
    codepoint: 'F0E61',
    deprecated: 'FALSE',
  },
  {
    id: '2C8473E2-22E8-4E6E-B929-99D2FCE9937B',
    name: 'sawtooth-wave',
    codepoint: 'F147A',
    deprecated: 'FALSE',
  },
  {
    id: '64F494E1-5B39-488D-B46E-0FC1B9567478',
    name: 'saxophone',
    codepoint: 'F0609',
    deprecated: 'FALSE',
  },
  {
    id: '3FE1D769-A292-44DB-BB8A-E60F3A020D2A',
    name: 'scale',
    codepoint: 'F0472',
    deprecated: 'FALSE',
  },
  {
    id: '82048BA4-F949-4572-B59B-23197F9B36D6',
    name: 'scale-balance',
    codepoint: 'F05D1',
    deprecated: 'FALSE',
  },
  {
    id: 'F4501EC3-6AB0-4A69-B840-F07DDFC45A19',
    name: 'scale-bathroom',
    codepoint: 'F0473',
    deprecated: 'FALSE',
  },
  {
    id: '69824E77-BE70-49EB-9F26-C06042E86F1D',
    name: 'scale-off',
    codepoint: 'F105A',
    deprecated: 'FALSE',
  },
  {
    id: '18C68B6F-89AD-4F9D-B0AF-AF6185C86AD6',
    name: 'scale-unbalanced',
    codepoint: 'F19B8',
    deprecated: 'FALSE',
  },
  {
    id: 'C74BA4DC-A838-471E-AA2D-A5C2D0B74BF3',
    name: 'scan-helper',
    codepoint: 'F13D8',
    deprecated: 'FALSE',
  },
  {
    id: 'BE9A1BD3-1DF7-407F-98B7-24070994D472',
    name: 'scanner',
    codepoint: 'F06AB',
    deprecated: 'FALSE',
  },
  {
    id: 'B2E1C852-B55A-40D0-969B-0CD7389660A4',
    name: 'scanner-off',
    codepoint: 'F090A',
    deprecated: 'FALSE',
  },
  {
    id: '5DE8E9D7-420A-43BE-AF94-B1364BC46D41',
    name: 'scatter-plot',
    codepoint: 'F0EC9',
    deprecated: 'FALSE',
  },
  {
    id: 'C438C63A-0E60-4EA8-B795-EE6D329253F1',
    name: 'scatter-plot-outline',
    codepoint: 'F0ECA',
    deprecated: 'FALSE',
  },
  {
    id: 'B473C27C-5557-4B1F-89F1-46DC7700E73A',
    name: 'scent',
    codepoint: 'F1958',
    deprecated: 'FALSE',
  },
  {
    id: '2263B3A2-8BC3-4E1B-B86F-46E9E7CAA92A',
    name: 'scent-off',
    codepoint: 'F1959',
    deprecated: 'FALSE',
  },
  {
    id: 'A671C11E-0A75-4F41-AA32-EAA99955DEDE',
    name: 'school',
    codepoint: 'F0474',
    deprecated: 'FALSE',
  },
  {
    id: '282BA7AF-AFFD-491F-BA34-8DFD88378AFF',
    name: 'school-outline',
    codepoint: 'F1180',
    deprecated: 'FALSE',
  },
  {
    id: 'D5D8FF62-F955-4DB5-8EC3-1C314F401ECD',
    name: 'scissors-cutting',
    codepoint: 'F0A6B',
    deprecated: 'FALSE',
  },
  {
    id: '0299DD49-341B-4979-91A9-FB3BF6AEFB87',
    name: 'scooter',
    codepoint: 'F15BD',
    deprecated: 'FALSE',
  },
  {
    id: '09653048-04BF-4EDE-A6F5-7DAAE4157D21',
    name: 'scooter-electric',
    codepoint: 'F15BE',
    deprecated: 'FALSE',
  },
  {
    id: 'C20508AC-CC67-48DF-9B05-72DE40E417AF',
    name: 'scoreboard',
    codepoint: 'F127E',
    deprecated: 'FALSE',
  },
  {
    id: '3E4214BB-1283-436F-A658-7F6015745C88',
    name: 'scoreboard-outline',
    codepoint: 'F127F',
    deprecated: 'FALSE',
  },
  {
    id: '913EA106-BCB3-410B-A5F4-6FAAA1B79880',
    name: 'screen-rotation',
    codepoint: 'F0475',
    deprecated: 'FALSE',
  },
  {
    id: '0C15E49D-5D29-4A3D-803B-0B9695295E04',
    name: 'screen-rotation-lock',
    codepoint: 'F0478',
    deprecated: 'FALSE',
  },
  {
    id: '744539B5-EC00-481D-9A78-274A157CF130',
    name: 'screw-flat-top',
    codepoint: 'F0DF3',
    deprecated: 'FALSE',
  },
  {
    id: '34D5E55D-131E-4EB2-9053-53B10F6F40D5',
    name: 'screw-lag',
    codepoint: 'F0DF4',
    deprecated: 'FALSE',
  },
  {
    id: 'AD403C97-C12B-4590-A156-EEF43629F655',
    name: 'screw-machine-flat-top',
    codepoint: 'F0DF5',
    deprecated: 'FALSE',
  },
  {
    id: 'C19DAE9D-F5EE-4968-99AD-7F1C55B6ACB4',
    name: 'screw-machine-round-top',
    codepoint: 'F0DF6',
    deprecated: 'FALSE',
  },
  {
    id: 'AC9042F5-C2D0-4C97-AA6E-C364026950B0',
    name: 'screw-round-top',
    codepoint: 'F0DF7',
    deprecated: 'FALSE',
  },
  {
    id: '2F75C2DC-FAAD-4D59-BAAB-3922EF9AF376',
    name: 'screwdriver',
    codepoint: 'F0476',
    deprecated: 'FALSE',
  },
  {
    id: '89D63ACC-0EB2-4C0C-BAFF-65846D32B937',
    name: 'script',
    codepoint: 'F0BC1',
    deprecated: 'FALSE',
  },
  {
    id: '6E18A0EA-1F19-47E3-83DD-25732C1D318D',
    name: 'script-outline',
    codepoint: 'F0477',
    deprecated: 'FALSE',
  },
  {
    id: '100A6987-09AC-4A60-B6D6-E9DA1E4FCBCE',
    name: 'script-text',
    codepoint: 'F0BC2',
    deprecated: 'FALSE',
  },
  {
    id: '2789A595-8195-448F-BD8A-2FB71B712BD8',
    name: 'script-text-key',
    codepoint: 'F1725',
    deprecated: 'FALSE',
  },
  {
    id: '1343F1A4-86DB-432D-BE9D-F3EFEB1321F8',
    name: 'script-text-key-outline',
    codepoint: 'F1726',
    deprecated: 'FALSE',
  },
  {
    id: 'DF9F9175-8E37-4241-8AFD-360606A444A5',
    name: 'script-text-outline',
    codepoint: 'F0BC3',
    deprecated: 'FALSE',
  },
  {
    id: '0F931BB6-3336-430E-85C2-9EDEDCC4E9B4',
    name: 'script-text-play',
    codepoint: 'F1727',
    deprecated: 'FALSE',
  },
  {
    id: '7357F674-281E-49F1-8161-424877AC6DD3',
    name: 'script-text-play-outline',
    codepoint: 'F1728',
    deprecated: 'FALSE',
  },
  {
    id: '68B68EDB-6CDA-4488-A213-5E6DF4146EA6',
    name: 'sd',
    codepoint: 'F0479',
    deprecated: 'FALSE',
  },
  {
    id: '82C413A0-A055-4527-87B7-F977B6CD3F74',
    name: 'seal',
    codepoint: 'F047A',
    deprecated: 'FALSE',
  },
  {
    id: '9B878309-6513-4C41-B2F5-B69D24F6024B',
    name: 'seal-variant',
    codepoint: 'F0FD9',
    deprecated: 'FALSE',
  },
  {
    id: 'F6E7F332-1625-4A46-8E10-B6DEC4059E7E',
    name: 'search-web',
    codepoint: 'F070F',
    deprecated: 'FALSE',
  },
  {
    id: 'C9A796D6-1A7D-47AD-A036-B3B9035FBDA3',
    name: 'seat',
    codepoint: 'F0CC3',
    deprecated: 'FALSE',
  },
  {
    id: 'FDDF112E-DFF5-4D31-9821-69902F92E6CF',
    name: 'seat-flat',
    codepoint: 'F047B',
    deprecated: 'FALSE',
  },
  {
    id: '22BC78FF-895C-497C-B6F0-143F60901C20',
    name: 'seat-flat-angled',
    codepoint: 'F047C',
    deprecated: 'FALSE',
  },
  {
    id: 'A8F9F185-D2AE-4DEE-845C-3C74801E2CD0',
    name: 'seat-individual-suite',
    codepoint: 'F047D',
    deprecated: 'FALSE',
  },
  {
    id: 'F73EE06A-6898-40A7-9B0E-784A4280C075',
    name: 'seat-legroom-extra',
    codepoint: 'F047E',
    deprecated: 'FALSE',
  },
  {
    id: '1FC39B1E-567E-452C-B923-581F9A90F06D',
    name: 'seat-legroom-normal',
    codepoint: 'F047F',
    deprecated: 'FALSE',
  },
  {
    id: '4306743D-7833-44E7-88AB-659DD694C8B0',
    name: 'seat-legroom-reduced',
    codepoint: 'F0480',
    deprecated: 'FALSE',
  },
  {
    id: '2F61EAD1-DF0E-4E90-8260-875EA5221F35',
    name: 'seat-outline',
    codepoint: 'F0CC4',
    deprecated: 'FALSE',
  },
  {
    id: 'BF015C93-A5AD-47BF-9451-A9B5DFFAD50C',
    name: 'seat-passenger',
    codepoint: 'F1249',
    deprecated: 'FALSE',
  },
  {
    id: 'EB83D5D9-0439-476C-9BFD-04B5D6789940',
    name: 'seat-recline-extra',
    codepoint: 'F0481',
    deprecated: 'FALSE',
  },
  {
    id: 'B6642D7A-EE36-4C22-945E-88A218708CB6',
    name: 'seat-recline-normal',
    codepoint: 'F0482',
    deprecated: 'FALSE',
  },
  {
    id: '00F06E5E-5A05-4D5E-9D07-0573CA62E0BA',
    name: 'seatbelt',
    codepoint: 'F0CC5',
    deprecated: 'FALSE',
  },
  {
    id: '0C2F3617-9710-4265-AFB5-29C52AD2FBE0',
    name: 'security',
    codepoint: 'F0483',
    deprecated: 'FALSE',
  },
  {
    id: 'C9FA8517-7CD1-4AB7-86AD-0A2BF1016466',
    name: 'security-network',
    codepoint: 'F0484',
    deprecated: 'FALSE',
  },
  {
    id: '75A03574-02AA-4377-8DE3-86CBE77A0957',
    name: 'seed',
    codepoint: 'F0E62',
    deprecated: 'FALSE',
  },
  {
    id: '22CC568F-81CD-4DEA-A4E1-7DE207BD15F3',
    name: 'seed-off',
    codepoint: 'F13FD',
    deprecated: 'FALSE',
  },
  {
    id: '372ACCAF-E322-40AC-8CE9-1F2066D3BF97',
    name: 'seed-off-outline',
    codepoint: 'F13FE',
    deprecated: 'FALSE',
  },
  {
    id: '80AE8DF8-0E9B-4A56-B2EF-2CA1244D99D3',
    name: 'seed-outline',
    codepoint: 'F0E63',
    deprecated: 'FALSE',
  },
  {
    id: '06E48E27-F112-4D54-ABE4-95FB216A5DEB',
    name: 'seed-plus',
    codepoint: 'F1A6D',
    deprecated: 'FALSE',
  },
  {
    id: '01263D30-0B40-46AA-A17B-31EABA51DD48',
    name: 'seed-plus-outline',
    codepoint: 'F1A6E',
    deprecated: 'FALSE',
  },
  {
    id: 'B2FB9E28-A275-4BC8-AFD9-8A8D90153ED9',
    name: 'seesaw',
    codepoint: 'F15A4',
    deprecated: 'FALSE',
  },
  {
    id: '5AFE282B-CAE1-4D7F-A2BF-37E8655537DD',
    name: 'segment',
    codepoint: 'F0ECB',
    deprecated: 'FALSE',
  },
  {
    id: 'B350D031-599B-4BB7-9D21-EC955B7FEF43',
    name: 'select',
    codepoint: 'F0485',
    deprecated: 'FALSE',
  },
  {
    id: '7C2879B0-6BFE-42A3-9EDF-4A42AC3C9D70',
    name: 'select-all',
    codepoint: 'F0486',
    deprecated: 'FALSE',
  },
  {
    id: '9CBEF68E-FE84-4690-A9E3-9505E496FF5F',
    name: 'select-arrow-down',
    codepoint: 'F1B59',
    deprecated: 'FALSE',
  },
  {
    id: '92D297D5-0F0F-4D37-A292-FCDA5D3F05C2',
    name: 'select-arrow-up',
    codepoint: 'F1B58',
    deprecated: 'FALSE',
  },
  {
    id: '8B757895-25B7-4C24-B6A2-7C71C9C12138',
    name: 'select-color',
    codepoint: 'F0D31',
    deprecated: 'FALSE',
  },
  {
    id: '4BC4C702-7484-4B80-95EB-F98125F1332D',
    name: 'select-compare',
    codepoint: 'F0AD9',
    deprecated: 'FALSE',
  },
  {
    id: 'BA35374B-03BB-421D-ABCD-5F8C4B492C4C',
    name: 'select-drag',
    codepoint: 'F0A6C',
    deprecated: 'FALSE',
  },
  {
    id: 'FEDE670C-F1CD-493D-B201-0C0410DB04C8',
    name: 'select-group',
    codepoint: 'F0F82',
    deprecated: 'FALSE',
  },
  {
    id: 'DAA46B9D-9215-4BF8-BDA7-52E575817ABC',
    name: 'select-inverse',
    codepoint: 'F0487',
    deprecated: 'FALSE',
  },
  {
    id: 'A312AB96-5A12-4223-AF67-7067653E17E5',
    name: 'select-marker',
    codepoint: 'F1280',
    deprecated: 'FALSE',
  },
  {
    id: '1958E6ED-3D6C-4341-8F21-DDE80D030CEF',
    name: 'select-multiple',
    codepoint: 'F1281',
    deprecated: 'FALSE',
  },
  {
    id: '0A8AB6EF-B388-4086-BF8D-1F0015ADC972',
    name: 'select-multiple-marker',
    codepoint: 'F1282',
    deprecated: 'FALSE',
  },
  {
    id: 'B2C462E1-44F0-456A-8A73-919DB480D978',
    name: 'select-off',
    codepoint: 'F0488',
    deprecated: 'FALSE',
  },
  {
    id: '0090BCFA-124B-484B-BFA1-AA39413F0156',
    name: 'select-place',
    codepoint: 'F0FDA',
    deprecated: 'FALSE',
  },
  {
    id: '1421D2ED-7592-4771-81F9-53A9EADFA95A',
    name: 'select-remove',
    codepoint: 'F17C1',
    deprecated: 'FALSE',
  },
  {
    id: '5D558A02-EC98-47D7-AD04-D02E225AF4C6',
    name: 'select-search',
    codepoint: 'F1204',
    deprecated: 'FALSE',
  },
  {
    id: '33A5D3FF-E2A7-4E3A-95B5-86D4C6604911',
    name: 'selection',
    codepoint: 'F0489',
    deprecated: 'FALSE',
  },
  {
    id: '62E94CCE-BCD0-4DF3-9AFB-B14169345612',
    name: 'selection-drag',
    codepoint: 'F0A6D',
    deprecated: 'FALSE',
  },
  {
    id: 'F1A52E3C-0E56-48FA-ADC4-A20A6CE2E4DF',
    name: 'selection-ellipse',
    codepoint: 'F0D32',
    deprecated: 'FALSE',
  },
  {
    id: 'DD7E2731-0D5F-42EC-8F73-99DC2E80398C',
    name: 'selection-ellipse-arrow-inside',
    codepoint: 'F0F22',
    deprecated: 'FALSE',
  },
  {
    id: '44145803-0893-44BE-9B63-8BC586FFCCAB',
    name: 'selection-ellipse-remove',
    codepoint: 'F17C2',
    deprecated: 'FALSE',
  },
  {
    id: 'DA670815-123E-4443-B813-5BB4A556AB1C',
    name: 'selection-marker',
    codepoint: 'F1283',
    deprecated: 'FALSE',
  },
  {
    id: '31DDE08D-A49D-4B2D-9D90-3E79FDDF49C9',
    name: 'selection-multiple',
    codepoint: 'F1285',
    deprecated: 'FALSE',
  },
  {
    id: '5B06BC81-DA70-41F2-B05C-31E9BD7F571E',
    name: 'selection-multiple-marker',
    codepoint: 'F1284',
    deprecated: 'FALSE',
  },
  {
    id: 'DE8BF55F-05A8-4CB8-86FC-63D6EAC57A6C',
    name: 'selection-off',
    codepoint: 'F0777',
    deprecated: 'FALSE',
  },
  {
    id: '374F7C71-E33D-47CD-9A4E-898B853094C0',
    name: 'selection-remove',
    codepoint: 'F17C3',
    deprecated: 'FALSE',
  },
  {
    id: '2123E467-F48C-4AC6-8061-FEEB686ADB19',
    name: 'selection-search',
    codepoint: 'F1205',
    deprecated: 'FALSE',
  },
  {
    id: '43AB1D06-4033-4F02-8147-B692F4534174',
    name: 'semantic-web',
    codepoint: 'F1316',
    deprecated: 'TRUE',
  },
  {
    id: '6791A1E4-DBF8-471C-AA27-0D4540A23678',
    name: 'send',
    codepoint: 'F048A',
    deprecated: 'FALSE',
  },
  {
    id: '688C66E3-B436-4900-83EF-ADCEBBEDE627',
    name: 'send-check',
    codepoint: 'F1161',
    deprecated: 'FALSE',
  },
  {
    id: '0C6541E7-CC47-4FF5-BEB4-F261296D7DAD',
    name: 'send-check-outline',
    codepoint: 'F1162',
    deprecated: 'FALSE',
  },
  {
    id: '52E3C504-34AB-4EE5-954F-768B1044B2A6',
    name: 'send-circle',
    codepoint: 'F0DF8',
    deprecated: 'FALSE',
  },
  {
    id: 'DE0BE96B-9A65-4B97-B3F3-67FBDF240B7A',
    name: 'send-circle-outline',
    codepoint: 'F0DF9',
    deprecated: 'FALSE',
  },
  {
    id: '1225563F-5E41-4B85-8E01-FF53C5453B56',
    name: 'send-clock',
    codepoint: 'F1163',
    deprecated: 'FALSE',
  },
  {
    id: '281F054F-8F0A-4790-9165-154E297B9283',
    name: 'send-clock-outline',
    codepoint: 'F1164',
    deprecated: 'FALSE',
  },
  {
    id: '343A4B08-D77B-453B-A8A7-AF40D1A98DD2',
    name: 'send-lock',
    codepoint: 'F07ED',
    deprecated: 'FALSE',
  },
  {
    id: '23F855E5-3F55-48BA-8C2A-7FBA5EAE8CED',
    name: 'send-lock-outline',
    codepoint: 'F1166',
    deprecated: 'FALSE',
  },
  {
    id: 'B4F0331A-3E21-40C4-AED4-A28FD929AD21',
    name: 'send-outline',
    codepoint: 'F1165',
    deprecated: 'FALSE',
  },
  {
    id: '5D18BB49-3214-49C0-BD5C-B4581172C650',
    name: 'serial-port',
    codepoint: 'F065C',
    deprecated: 'FALSE',
  },
  {
    id: '692F85E2-79A8-4207-ACF7-DEC8D675BE38',
    name: 'server',
    codepoint: 'F048B',
    deprecated: 'FALSE',
  },
  {
    id: '09F53FF1-31D7-44CA-A00E-8CDDF55DB716',
    name: 'server-minus',
    codepoint: 'F048C',
    deprecated: 'FALSE',
  },
  {
    id: '21F268BE-CC44-49CE-B59A-121D073ADBA5',
    name: 'server-network',
    codepoint: 'F048D',
    deprecated: 'FALSE',
  },
  {
    id: 'E01B3638-DE80-4D1E-93D1-1A0C5E9AFCF4',
    name: 'server-network-off',
    codepoint: 'F048E',
    deprecated: 'FALSE',
  },
  {
    id: '578A1213-5F0D-4C6B-A929-9157D126A9F8',
    name: 'server-off',
    codepoint: 'F048F',
    deprecated: 'FALSE',
  },
  {
    id: '99AC1D59-A2F0-4B53-872D-0374D0804E0A',
    name: 'server-plus',
    codepoint: 'F0490',
    deprecated: 'FALSE',
  },
  {
    id: 'C7C1A659-403B-44E0-9EF5-94B5C722CAFD',
    name: 'server-remove',
    codepoint: 'F0491',
    deprecated: 'FALSE',
  },
  {
    id: 'E42A523C-D28D-4AAB-8A86-4816DD872B78',
    name: 'server-security',
    codepoint: 'F0492',
    deprecated: 'FALSE',
  },
  {
    id: '501D1421-A50E-470A-A05B-87864461C4ED',
    name: 'set-all',
    codepoint: 'F0778',
    deprecated: 'FALSE',
  },
  {
    id: '0267D77C-9968-45EF-87FC-CD3368D56383',
    name: 'set-center',
    codepoint: 'F0779',
    deprecated: 'FALSE',
  },
  {
    id: '614D2F8D-5170-4FE8-87C7-419903F205CB',
    name: 'set-center-right',
    codepoint: 'F077A',
    deprecated: 'FALSE',
  },
  {
    id: 'FB4645C1-DF04-49E0-BA0B-7C02C87AF3A9',
    name: 'set-left',
    codepoint: 'F077B',
    deprecated: 'FALSE',
  },
  {
    id: '9C04991C-6A68-4634-885F-A4D6E2816AFF',
    name: 'set-left-center',
    codepoint: 'F077C',
    deprecated: 'FALSE',
  },
  {
    id: 'E1BE2EC4-D5D5-475C-A562-9855DA1612FB',
    name: 'set-left-right',
    codepoint: 'F077D',
    deprecated: 'FALSE',
  },
  {
    id: '414C136F-7BB5-47BB-BB58-136C5E1C2E5B',
    name: 'set-merge',
    codepoint: 'F14E0',
    deprecated: 'FALSE',
  },
  {
    id: '9674B809-B544-45E8-BBD8-4E8D9E1A966E',
    name: 'set-none',
    codepoint: 'F077E',
    deprecated: 'FALSE',
  },
  {
    id: '27D4B799-E231-49DF-AB06-0201493C83E9',
    name: 'set-right',
    codepoint: 'F077F',
    deprecated: 'FALSE',
  },
  {
    id: '7100DDA3-3A7A-4A02-A717-D33D1A170E41',
    name: 'set-split',
    codepoint: 'F14E1',
    deprecated: 'FALSE',
  },
  {
    id: 'C57170BC-B363-470F-B209-D713700B7C89',
    name: 'set-square',
    codepoint: 'F145D',
    deprecated: 'FALSE',
  },
  {
    id: '944304C3-3513-4544-8DC2-B14307049311',
    name: 'set-top-box',
    codepoint: 'F099F',
    deprecated: 'FALSE',
  },
  {
    id: '6AE3B6F5-8896-4AED-A4B2-B1B021DE1DE9',
    name: 'settings-helper',
    codepoint: 'F0A6E',
    deprecated: 'FALSE',
  },
  {
    id: '4E49931C-2FF9-4957-942A-F2F0B8485E68',
    name: 'shaker',
    codepoint: 'F110E',
    deprecated: 'FALSE',
  },
  {
    id: '1A2E13B0-421D-460F-87C2-63C1DEC6525A',
    name: 'shaker-outline',
    codepoint: 'F110F',
    deprecated: 'FALSE',
  },
  {
    id: 'B29246C8-6014-4019-8CCC-F803DBC0FA95',
    name: 'shape',
    codepoint: 'F0831',
    deprecated: 'FALSE',
  },
  {
    id: 'C989421F-7F77-45D9-9BB4-97D3DE8C8FB4',
    name: 'shape-circle-plus',
    codepoint: 'F065D',
    deprecated: 'FALSE',
  },
  {
    id: 'FC3A1E7D-F8CD-443E-A685-9C7F0FA9FC10',
    name: 'shape-outline',
    codepoint: 'F0832',
    deprecated: 'FALSE',
  },
  {
    id: 'CE4CFCD5-20D8-4248-A3E8-0B1EFDCC8EC4',
    name: 'shape-oval-plus',
    codepoint: 'F11FA',
    deprecated: 'FALSE',
  },
  {
    id: 'C91D9DCD-5ADA-4D48-857C-39D38CD37A6B',
    name: 'shape-plus',
    codepoint: 'F0495',
    deprecated: 'FALSE',
  },
  {
    id: 'CFE631FF-AAD1-49D9-BA22-84BAC90B8787',
    name: 'shape-polygon-plus',
    codepoint: 'F065E',
    deprecated: 'FALSE',
  },
  {
    id: 'C92D4248-E908-4B39-A6D0-296E6FB5FFE1',
    name: 'shape-rectangle-plus',
    codepoint: 'F065F',
    deprecated: 'FALSE',
  },
  {
    id: '56A31026-C5C6-45D5-A9D4-1C660357F430',
    name: 'shape-square-plus',
    codepoint: 'F0660',
    deprecated: 'FALSE',
  },
  {
    id: '783621A3-B8D0-4340-BE29-0399B02EAE6B',
    name: 'shape-square-rounded-plus',
    codepoint: 'F14FA',
    deprecated: 'FALSE',
  },
  {
    id: 'F0053E35-64B2-45D5-92D6-EE9F8A8839A1',
    name: 'share',
    codepoint: 'F0496',
    deprecated: 'FALSE',
  },
  {
    id: '3A9176D6-A724-4E82-9F8B-00CEEC8A9D64',
    name: 'share-all',
    codepoint: 'F11F4',
    deprecated: 'FALSE',
  },
  {
    id: 'E9397228-BC9E-4B65-9301-948CBB1D0905',
    name: 'share-all-outline',
    codepoint: 'F11F5',
    deprecated: 'FALSE',
  },
  {
    id: '2381C112-2883-4B31-829E-D656430AB707',
    name: 'share-circle',
    codepoint: 'F11AD',
    deprecated: 'FALSE',
  },
  {
    id: '8F694E89-2460-4790-820F-BCFA1A4CB660',
    name: 'share-off',
    codepoint: 'F0F23',
    deprecated: 'FALSE',
  },
  {
    id: 'E65D2036-B0D1-4957-B114-A6FA6DFC67A5',
    name: 'share-off-outline',
    codepoint: 'F0F24',
    deprecated: 'FALSE',
  },
  {
    id: 'B8DDA534-033E-42FF-9F81-62EC636DD3C6',
    name: 'share-outline',
    codepoint: 'F0932',
    deprecated: 'FALSE',
  },
  {
    id: 'C25D5913-39C7-4215-B372-88F90EE2B6E5',
    name: 'share-variant',
    codepoint: 'F0497',
    deprecated: 'FALSE',
  },
  {
    id: '82009437-57EA-4B83-97A2-A6AA6F305CBE',
    name: 'share-variant-outline',
    codepoint: 'F1514',
    deprecated: 'FALSE',
  },
  {
    id: '4D7D5865-0AEE-40A3-9015-86661A60DA8C',
    name: 'shark',
    codepoint: 'F18BA',
    deprecated: 'FALSE',
  },
  {
    id: '7A618386-2E3E-4B23-BF63-184B76C9CF61',
    name: 'shark-fin',
    codepoint: 'F1673',
    deprecated: 'FALSE',
  },
  {
    id: '15736C41-B312-436C-B081-67F0C561A97A',
    name: 'shark-fin-outline',
    codepoint: 'F1674',
    deprecated: 'FALSE',
  },
  {
    id: '4CF1A54B-034F-4AB6-A3B2-6ED16E550261',
    name: 'shark-off',
    codepoint: 'F18BB',
    deprecated: 'FALSE',
  },
  {
    id: 'DEA01DF4-6FBD-45B7-AC58-CBF70545FBBA',
    name: 'sheep',
    codepoint: 'F0CC6',
    deprecated: 'FALSE',
  },
  {
    id: 'A3709A80-3219-4E83-9D4F-EF74F31EEAAE',
    name: 'shield',
    codepoint: 'F0498',
    deprecated: 'FALSE',
  },
  {
    id: 'CA71D276-C056-45E5-883E-B3D15868D859',
    name: 'shield-account',
    codepoint: 'F088F',
    deprecated: 'FALSE',
  },
  {
    id: 'E74ADD51-598F-4194-868C-3A9F7B62BCFA',
    name: 'shield-account-outline',
    codepoint: 'F0A12',
    deprecated: 'FALSE',
  },
  {
    id: '54FF705E-9453-4D1A-B2AC-1EB2F815F518',
    name: 'shield-account-variant',
    codepoint: 'F15A7',
    deprecated: 'FALSE',
  },
  {
    id: '8229B887-45A8-496A-9A8D-0140F438CB65',
    name: 'shield-account-variant-outline',
    codepoint: 'F15A8',
    deprecated: 'FALSE',
  },
  {
    id: 'B8FB8784-2E21-4D35-9DE8-95E8E2A42591',
    name: 'shield-airplane',
    codepoint: 'F06BB',
    deprecated: 'FALSE',
  },
  {
    id: 'C14BAFFB-948A-45C3-ABFD-4F6215318D65',
    name: 'shield-airplane-outline',
    codepoint: 'F0CC7',
    deprecated: 'FALSE',
  },
  {
    id: 'B5DC36AC-6F79-4CDE-B1CD-02F221BF8CE4',
    name: 'shield-alert',
    codepoint: 'F0ECC',
    deprecated: 'FALSE',
  },
  {
    id: '51584101-DF6C-49FF-8ACB-13F7064DEE89',
    name: 'shield-alert-outline',
    codepoint: 'F0ECD',
    deprecated: 'FALSE',
  },
  {
    id: '1FB00AC4-94AE-4677-8F7F-0B6A47F7382C',
    name: 'shield-bug',
    codepoint: 'F13DA',
    deprecated: 'FALSE',
  },
  {
    id: 'DCD9B7FB-8730-4C65-8805-50B6B51A5EF7',
    name: 'shield-bug-outline',
    codepoint: 'F13DB',
    deprecated: 'FALSE',
  },
  {
    id: '2A7B43E0-4BA1-41EB-9040-F943AA9FD02C',
    name: 'shield-car',
    codepoint: 'F0F83',
    deprecated: 'FALSE',
  },
  {
    id: 'FC112FD8-5338-49C0-B4E0-FE1816A71E74',
    name: 'shield-check',
    codepoint: 'F0565',
    deprecated: 'FALSE',
  },
  {
    id: '26E9DF2C-21C8-48FF-B402-733E33673C7E',
    name: 'shield-check-outline',
    codepoint: 'F0CC8',
    deprecated: 'FALSE',
  },
  {
    id: '8D47ED6A-831A-49F2-8E43-67F16F8351E6',
    name: 'shield-cross',
    codepoint: 'F0CC9',
    deprecated: 'FALSE',
  },
  {
    id: '6C722CF2-67CA-4CEC-9197-56C4F99D2866',
    name: 'shield-cross-outline',
    codepoint: 'F0CCA',
    deprecated: 'FALSE',
  },
  {
    id: 'E251CF58-8A10-4069-AB6C-CF9DD2383335',
    name: 'shield-crown',
    codepoint: 'F18BC',
    deprecated: 'FALSE',
  },
  {
    id: '97DA53AC-FC83-467F-A814-752C981BFEDE',
    name: 'shield-crown-outline',
    codepoint: 'F18BD',
    deprecated: 'FALSE',
  },
  {
    id: '8CF352CF-2ED0-4DFB-ADCF-AD77FDA6DD25',
    name: 'shield-edit',
    codepoint: 'F11A0',
    deprecated: 'FALSE',
  },
  {
    id: 'F55E4962-5730-4735-B362-42FFB640F202',
    name: 'shield-edit-outline',
    codepoint: 'F11A1',
    deprecated: 'FALSE',
  },
  {
    id: 'DC82606F-2ABB-4C09-BAC5-1C3C4DA38BEC',
    name: 'shield-half',
    codepoint: 'F1360',
    deprecated: 'FALSE',
  },
  {
    id: '9E908727-9325-4FC6-B8B8-C5CA52A7A827',
    name: 'shield-half-full',
    codepoint: 'F0780',
    deprecated: 'FALSE',
  },
  {
    id: '2A5A98F5-ED95-40DE-8EC5-1293795CF661',
    name: 'shield-home',
    codepoint: 'F068A',
    deprecated: 'FALSE',
  },
  {
    id: '4D4B922B-D9AA-47AC-8E02-33F88EC69B38',
    name: 'shield-home-outline',
    codepoint: 'F0CCB',
    deprecated: 'FALSE',
  },
  {
    id: '9894EABF-10A1-4A07-BB4B-D94F596F5D6A',
    name: 'shield-key',
    codepoint: 'F0BC4',
    deprecated: 'FALSE',
  },
  {
    id: '90122FEA-99CC-4B64-BD21-E3DD8CB83948',
    name: 'shield-key-outline',
    codepoint: 'F0BC5',
    deprecated: 'FALSE',
  },
  {
    id: '9F5D8008-32E1-4A90-B040-2C8AF2C00F81',
    name: 'shield-link-variant',
    codepoint: 'F0D33',
    deprecated: 'FALSE',
  },
  {
    id: '8A6D2649-CF2C-43AF-A90B-79B554B1385A',
    name: 'shield-link-variant-outline',
    codepoint: 'F0D34',
    deprecated: 'FALSE',
  },
  {
    id: 'C2E5EBC9-F1AD-4D93-BA3F-C81E18411E09',
    name: 'shield-lock',
    codepoint: 'F099D',
    deprecated: 'FALSE',
  },
  {
    id: '5B854DDA-2B72-49FC-881D-E88ED51EA636',
    name: 'shield-lock-open',
    codepoint: 'F199A',
    deprecated: 'FALSE',
  },
  {
    id: 'F9C92AC8-D26D-4AAD-8C34-22759EA2CA2D',
    name: 'shield-lock-open-outline',
    codepoint: 'F199B',
    deprecated: 'FALSE',
  },
  {
    id: '7F797AEE-648F-4FB8-BA7F-4EF60235A061',
    name: 'shield-lock-outline',
    codepoint: 'F0CCC',
    deprecated: 'FALSE',
  },
  {
    id: '622FF7DE-FDD6-4831-9D3D-09157A5FC8AE',
    name: 'shield-moon',
    codepoint: 'F1828',
    deprecated: 'FALSE',
  },
  {
    id: 'C70DD8EF-9F17-41E0-825C-C5AAC60DBF1E',
    name: 'shield-moon-outline',
    codepoint: 'F1829',
    deprecated: 'FALSE',
  },
  {
    id: 'BC537F52-4A49-433B-A031-5C698A234A35',
    name: 'shield-off',
    codepoint: 'F099E',
    deprecated: 'FALSE',
  },
  {
    id: '8ED2BB68-83C4-49BE-A730-1853C228781B',
    name: 'shield-off-outline',
    codepoint: 'F099C',
    deprecated: 'FALSE',
  },
  {
    id: 'A8FF94DD-6BA5-4DBA-AE23-59BB22DD733B',
    name: 'shield-outline',
    codepoint: 'F0499',
    deprecated: 'FALSE',
  },
  {
    id: '872F02FE-ABEF-4187-B6B2-87A548988EAA',
    name: 'shield-plus',
    codepoint: 'F0ADA',
    deprecated: 'FALSE',
  },
  {
    id: '4348C9BA-B7EE-4DC9-9B75-102726C978F7',
    name: 'shield-plus-outline',
    codepoint: 'F0ADB',
    deprecated: 'FALSE',
  },
  {
    id: '2E0E0624-1F5D-4998-B0F9-A957EC2ADD49',
    name: 'shield-refresh',
    codepoint: 'F00AA',
    deprecated: 'FALSE',
  },
  {
    id: 'D3FD64E5-2836-4527-8861-29D53D101672',
    name: 'shield-refresh-outline',
    codepoint: 'F01E0',
    deprecated: 'FALSE',
  },
  {
    id: 'F6E9D9DF-7266-4D03-BE3E-4F3B4612B77F',
    name: 'shield-remove',
    codepoint: 'F0ADC',
    deprecated: 'FALSE',
  },
  {
    id: '4C54DF65-6F23-4FEE-A579-C329A32192BA',
    name: 'shield-remove-outline',
    codepoint: 'F0ADD',
    deprecated: 'FALSE',
  },
  {
    id: '89E736DC-2E57-413E-A044-40871A51CB72',
    name: 'shield-search',
    codepoint: 'F0D9A',
    deprecated: 'FALSE',
  },
  {
    id: '9C6B0512-02BF-4C33-ADAF-54D75B3AD2FB',
    name: 'shield-star',
    codepoint: 'F113B',
    deprecated: 'FALSE',
  },
  {
    id: '122D14B5-1EB6-4ED3-8765-7C8A58D6071B',
    name: 'shield-star-outline',
    codepoint: 'F113C',
    deprecated: 'FALSE',
  },
  {
    id: '144E5B1F-A406-440D-B8B0-CC662F359D12',
    name: 'shield-sun',
    codepoint: 'F105D',
    deprecated: 'FALSE',
  },
  {
    id: '36B9C70C-5576-459A-AF44-A8E8F97B5DA8',
    name: 'shield-sun-outline',
    codepoint: 'F105E',
    deprecated: 'FALSE',
  },
  {
    id: '614C104E-8424-4D0B-AAFB-3D35CCBDF291',
    name: 'shield-sword',
    codepoint: 'F18BE',
    deprecated: 'FALSE',
  },
  {
    id: '3F8FADE9-864C-4B66-921A-A0DC30683E2A',
    name: 'shield-sword-outline',
    codepoint: 'F18BF',
    deprecated: 'FALSE',
  },
  {
    id: 'AE87ED6F-DF56-449E-A3EF-D010AB6F4D17',
    name: 'shield-sync',
    codepoint: 'F11A2',
    deprecated: 'FALSE',
  },
  {
    id: '55F6BCDE-A55A-4206-B079-1684F6E1FF0F',
    name: 'shield-sync-outline',
    codepoint: 'F11A3',
    deprecated: 'FALSE',
  },
  {
    id: 'C09567B5-B5C0-4C51-A246-0CA46C928F96',
    name: 'shimmer',
    codepoint: 'F1545',
    deprecated: 'FALSE',
  },
  {
    id: '1F35A20C-CF6C-4C2A-9A72-82269F4ECCF7',
    name: 'ship-wheel',
    codepoint: 'F0833',
    deprecated: 'FALSE',
  },
  {
    id: 'E5A72C59-D7AF-47F4-939E-F9F882E3F2FF',
    name: 'shipping-pallet',
    codepoint: 'F184E',
    deprecated: 'FALSE',
  },
  {
    id: 'B5B68323-60DD-45BF-83FC-892B436D0400',
    name: 'shoe-ballet',
    codepoint: 'F15CA',
    deprecated: 'FALSE',
  },
  {
    id: 'F6831C58-3C18-44F4-A2A8-4AD96444F012',
    name: 'shoe-cleat',
    codepoint: 'F15C7',
    deprecated: 'FALSE',
  },
  {
    id: 'DFEA1FD2-F6A5-4670-83D6-9861A0FA272C',
    name: 'shoe-formal',
    codepoint: 'F0B47',
    deprecated: 'FALSE',
  },
  {
    id: 'E97AD67E-DD75-4FD8-9E25-44F3042B4172',
    name: 'shoe-heel',
    codepoint: 'F0B48',
    deprecated: 'FALSE',
  },
  {
    id: '4008AB1C-4D92-4D8B-9423-5182FC505664',
    name: 'shoe-print',
    codepoint: 'F0DFA',
    deprecated: 'FALSE',
  },
  {
    id: 'EA78AFA5-70F2-4878-A1D5-746B410E1DA0',
    name: 'shoe-sneaker',
    codepoint: 'F15C8',
    deprecated: 'FALSE',
  },
  {
    id: '7A5ED547-B142-4921-8050-647B2B9F4DC0',
    name: 'shopping',
    codepoint: 'F049A',
    deprecated: 'FALSE',
  },
  {
    id: 'E3FDDEB1-6D36-4E18-914E-06E2887E7D96',
    name: 'shopping-music',
    codepoint: 'F049B',
    deprecated: 'FALSE',
  },
  {
    id: 'EBE771F9-2EF2-41D9-AE7D-8FDBA1B4C684',
    name: 'shopping-outline',
    codepoint: 'F11D5',
    deprecated: 'FALSE',
  },
  {
    id: '6FB38390-3C7C-423A-AC1C-691C3358E04B',
    name: 'shopping-search',
    codepoint: 'F0F84',
    deprecated: 'FALSE',
  },
  {
    id: '68885584-9904-492E-AEC4-0FEC2C266F6E',
    name: 'shopping-search-outline',
    codepoint: 'F1A6F',
    deprecated: 'FALSE',
  },
  {
    id: '9F0501F0-7F2C-4806-8AC4-F8D76AA194CD',
    name: 'shore',
    codepoint: 'F14F9',
    deprecated: 'FALSE',
  },
  {
    id: '16D35D19-90C2-42DD-9E83-8F47E21A53DF',
    name: 'shovel',
    codepoint: 'F0710',
    deprecated: 'FALSE',
  },
  {
    id: '01E7F800-E769-4A3F-9A30-D1B8E96F617B',
    name: 'shovel-off',
    codepoint: 'F0711',
    deprecated: 'FALSE',
  },
  {
    id: '1F29D513-0EFE-454A-B766-579A010C51B6',
    name: 'shower',
    codepoint: 'F09A0',
    deprecated: 'FALSE',
  },
  {
    id: 'F0E6B2EB-7E10-42C8-AEF1-84A461E1E2B3',
    name: 'shower-head',
    codepoint: 'F09A1',
    deprecated: 'FALSE',
  },
  {
    id: 'BCE4CF23-0A2B-430F-89C9-7597F1132919',
    name: 'shredder',
    codepoint: 'F049C',
    deprecated: 'FALSE',
  },
  {
    id: '99537665-2115-40D6-9725-0F4B4730C99A',
    name: 'shuffle',
    codepoint: 'F049D',
    deprecated: 'FALSE',
  },
  {
    id: 'DAA3B240-E394-46A8-83FE-6EE6712BF806',
    name: 'shuffle-disabled',
    codepoint: 'F049E',
    deprecated: 'FALSE',
  },
  {
    id: '84D958BE-5145-4490-BA8F-D00E6870CC9D',
    name: 'shuffle-variant',
    codepoint: 'F049F',
    deprecated: 'FALSE',
  },
  {
    id: '70BBF348-E5D1-4C4C-AD93-17246A78DE13',
    name: 'shuriken',
    codepoint: 'F137F',
    deprecated: 'FALSE',
  },
  {
    id: 'AEA5AABF-3305-44A8-9E85-67F2351CB2E6',
    name: 'sickle',
    codepoint: 'F18C0',
    deprecated: 'FALSE',
  },
  {
    id: '59A98F47-9067-492A-8454-42BEFA2391C1',
    name: 'sigma',
    codepoint: 'F04A0',
    deprecated: 'FALSE',
  },
  {
    id: 'DD1EAF89-C6AC-4E61-BF78-93425B16DD24',
    name: 'sigma-lower',
    codepoint: 'F062B',
    deprecated: 'FALSE',
  },
  {
    id: '159F7B23-A336-4F8C-A3BB-D8AB143D6661',
    name: 'sign-caution',
    codepoint: 'F04A1',
    deprecated: 'FALSE',
  },
  {
    id: '93E3355E-36F9-4B26-A337-C5F794D98941',
    name: 'sign-direction',
    codepoint: 'F0781',
    deprecated: 'FALSE',
  },
  {
    id: '110700C4-EF32-4835-B9C6-ECE259438B80',
    name: 'sign-direction-minus',
    codepoint: 'F1000',
    deprecated: 'FALSE',
  },
  {
    id: '298F86D9-24E7-4780-A891-1A7C36D65D77',
    name: 'sign-direction-plus',
    codepoint: 'F0FDC',
    deprecated: 'FALSE',
  },
  {
    id: '93DA424E-0D38-4DD4-B06A-2E3D0754EEC0',
    name: 'sign-direction-remove',
    codepoint: 'F0FDD',
    deprecated: 'FALSE',
  },
  {
    id: '7E8BA92D-403E-41B0-BA79-7E22E36ADFA8',
    name: 'sign-language',
    codepoint: 'F1B4D',
    deprecated: 'FALSE',
  },
  {
    id: '8EA84803-C50A-463F-8061-606F0CBF08A8',
    name: 'sign-language-outline',
    codepoint: 'F1B4E',
    deprecated: 'FALSE',
  },
  {
    id: 'AE565EF0-BC09-44FB-B94A-A5F2CF2B2CB2',
    name: 'sign-pole',
    codepoint: 'F14F8',
    deprecated: 'FALSE',
  },
  {
    id: 'A24BD314-F1AF-4BAF-A33B-F9BAD9D54089',
    name: 'sign-real-estate',
    codepoint: 'F1118',
    deprecated: 'FALSE',
  },
  {
    id: '6AC6C6C2-8925-4ECC-BD95-6B961B2CD0A5',
    name: 'sign-text',
    codepoint: 'F0782',
    deprecated: 'FALSE',
  },
  {
    id: 'C325938C-B13A-47A1-BD51-7F6F34B6CE16',
    name: 'sign-yield',
    codepoint: 'F1BAF',
    deprecated: 'FALSE',
  },
  {
    id: '7099FDF8-B653-4C49-BE2B-5B96147A709D',
    name: 'signal',
    codepoint: 'F04A2',
    deprecated: 'FALSE',
  },
  {
    id: 'C281EBBF-23FD-4F0A-8908-BFBA18F75FDD',
    name: 'signal-2g',
    codepoint: 'F0712',
    deprecated: 'FALSE',
  },
  {
    id: 'F0ACC092-8F79-43C9-9F8F-72E41AE557F2',
    name: 'signal-3g',
    codepoint: 'F0713',
    deprecated: 'FALSE',
  },
  {
    id: '7D633D1D-C265-4D2F-860E-43263B34A06F',
    name: 'signal-4g',
    codepoint: 'F0714',
    deprecated: 'FALSE',
  },
  {
    id: '5E2711B0-C417-434F-BE9A-F4FA6A666A56',
    name: 'signal-5g',
    codepoint: 'F0A6F',
    deprecated: 'FALSE',
  },
  {
    id: 'F5CC42C0-FA38-488C-AD3F-DCF5329DFBB8',
    name: 'signal-cellular-1',
    codepoint: 'F08BC',
    deprecated: 'FALSE',
  },
  {
    id: '5E21DB8A-BFFC-4038-8EBF-D3E462A43CD9',
    name: 'signal-cellular-2',
    codepoint: 'F08BD',
    deprecated: 'FALSE',
  },
  {
    id: '85A2D38F-5AB3-430C-9794-032E991A4956',
    name: 'signal-cellular-3',
    codepoint: 'F08BE',
    deprecated: 'FALSE',
  },
  {
    id: 'AA11AD00-4A6D-4D7F-911C-137CDDCF34B6',
    name: 'signal-cellular-outline',
    codepoint: 'F08BF',
    deprecated: 'FALSE',
  },
  {
    id: 'D0767E67-DC33-4B2B-9715-7408FD4F502D',
    name: 'signal-distance-variant',
    codepoint: 'F0E64',
    deprecated: 'FALSE',
  },
  {
    id: 'A960C38F-7EFE-4FE6-94AB-C7B0CD07DD50',
    name: 'signal-hspa',
    codepoint: 'F0715',
    deprecated: 'FALSE',
  },
  {
    id: 'CDCB7012-AD69-4B80-A64E-FDA1375271AD',
    name: 'signal-hspa-plus',
    codepoint: 'F0716',
    deprecated: 'FALSE',
  },
  {
    id: '9DAFE242-BF4B-43F8-8A6E-89EBB0EEDCAA',
    name: 'signal-off',
    codepoint: 'F0783',
    deprecated: 'FALSE',
  },
  {
    id: 'F4D628E8-ADAA-48B7-9ED2-83560FF4583F',
    name: 'signal-variant',
    codepoint: 'F060A',
    deprecated: 'FALSE',
  },
  {
    id: 'CA1E5FB5-D8FA-4B61-9C96-988F6829C4F5',
    name: 'signature',
    codepoint: 'F0DFB',
    deprecated: 'FALSE',
  },
  {
    id: '4DD4EB10-012B-4A70-8C9A-256B3F575BD5',
    name: 'signature-freehand',
    codepoint: 'F0DFC',
    deprecated: 'FALSE',
  },
  {
    id: '3A24A33E-C173-4E3C-8D8B-BA7E7E3E093A',
    name: 'signature-image',
    codepoint: 'F0DFD',
    deprecated: 'FALSE',
  },
  {
    id: '70DE882E-E115-42F9-A023-FBEE394DA396',
    name: 'signature-text',
    codepoint: 'F0DFE',
    deprecated: 'FALSE',
  },
  {
    id: '120915C9-09F2-48A5-99FE-6BF045917A56',
    name: 'silo',
    codepoint: 'F1B9F',
    deprecated: 'FALSE',
  },
  {
    id: '73A40E8E-7F62-454C-BFD9-A10796BA4A0A',
    name: 'silo-outline',
    codepoint: 'F0B49',
    deprecated: 'FALSE',
  },
  {
    id: 'F7C26535-863F-4295-9E48-9847B740FEE7',
    name: 'silverware',
    codepoint: 'F04A3',
    deprecated: 'FALSE',
  },
  {
    id: '57F11CAE-4E4B-4406-A5A8-2F5A283ABF99',
    name: 'silverware-clean',
    codepoint: 'F0FDE',
    deprecated: 'FALSE',
  },
  {
    id: '7B00A99C-3766-4939-993F-657333EE430B',
    name: 'silverware-fork',
    codepoint: 'F04A4',
    deprecated: 'FALSE',
  },
  {
    id: 'C3D19BD6-D474-4F86-94AA-1A4B04FE8E3A',
    name: 'silverware-fork-knife',
    codepoint: 'F0A70',
    deprecated: 'FALSE',
  },
  {
    id: '5FCBB812-F381-4E83-B60E-E126992910FB',
    name: 'silverware-spoon',
    codepoint: 'F04A5',
    deprecated: 'FALSE',
  },
  {
    id: 'A91FC6E1-2613-41E7-A8CA-58740B3322DA',
    name: 'silverware-variant',
    codepoint: 'F04A6',
    deprecated: 'FALSE',
  },
  {
    id: '93020F21-A0FE-427D-8685-B74EE39B9921',
    name: 'sim',
    codepoint: 'F04A7',
    deprecated: 'FALSE',
  },
  {
    id: '43CD286A-D183-474F-9B8A-93D43A355117',
    name: 'sim-alert',
    codepoint: 'F04A8',
    deprecated: 'FALSE',
  },
  {
    id: '23E2DADA-D539-422C-B925-9DCB1445B344',
    name: 'sim-alert-outline',
    codepoint: 'F15D3',
    deprecated: 'FALSE',
  },
  {
    id: '65B6C060-C1A9-4049-A6B6-FEAC02A899D3',
    name: 'sim-off',
    codepoint: 'F04A9',
    deprecated: 'FALSE',
  },
  {
    id: 'D7CAB5D9-2494-4528-B45E-A8EA49EA8262',
    name: 'sim-off-outline',
    codepoint: 'F15D4',
    deprecated: 'FALSE',
  },
  {
    id: 'C886D757-3CAE-4BAF-9F51-0747142FC508',
    name: 'sim-outline',
    codepoint: 'F15D5',
    deprecated: 'FALSE',
  },
  {
    id: 'A062A6A1-E475-41CD-AFB2-283199B67CC8',
    name: 'simple-icons',
    codepoint: 'F131D',
    deprecated: 'TRUE',
  },
  {
    id: '2AA993E0-4C1C-4993-84E6-D0BA0AC89EB0',
    name: 'sina-weibo',
    codepoint: 'F0ADF',
    deprecated: 'TRUE',
  },
  {
    id: '2099C23C-E6F6-4C9B-AA3C-49A693E73E2E',
    name: 'sine-wave',
    codepoint: 'F095B',
    deprecated: 'FALSE',
  },
  {
    id: '673CE0A7-8AA2-4BFD-9308-B51589FBF520',
    name: 'sitemap',
    codepoint: 'F04AA',
    deprecated: 'FALSE',
  },
  {
    id: '34B0F8F6-2E20-4B64-B683-2B1789140CA9',
    name: 'sitemap-outline',
    codepoint: 'F199C',
    deprecated: 'FALSE',
  },
  {
    id: '81D1F53A-2A63-4C9A-9CA9-547E68BB1329',
    name: 'size-l',
    codepoint: 'F13A6',
    deprecated: 'FALSE',
  },
  {
    id: 'D0FECA65-0432-4231-AFA5-EDD331188CD6',
    name: 'size-m',
    codepoint: 'F13A5',
    deprecated: 'FALSE',
  },
  {
    id: 'AE5079DB-D7D6-4F38-85C1-FAE6CDF14CC2',
    name: 'size-s',
    codepoint: 'F13A4',
    deprecated: 'FALSE',
  },
  {
    id: 'E7FCE878-E3CA-4EF8-B0B5-D926ACE33122',
    name: 'size-xl',
    codepoint: 'F13A7',
    deprecated: 'FALSE',
  },
  {
    id: 'D0173A61-33D3-43A2-8DB3-2E95EEEF7D7E',
    name: 'size-xs',
    codepoint: 'F13A3',
    deprecated: 'FALSE',
  },
  {
    id: '5E24686F-4675-4E74-93B4-DA7886270F3B',
    name: 'size-xxl',
    codepoint: 'F13A8',
    deprecated: 'FALSE',
  },
  {
    id: '97AAC6A1-8F86-49CD-AAE2-3F9CFDDCBD82',
    name: 'size-xxs',
    codepoint: 'F13A2',
    deprecated: 'FALSE',
  },
  {
    id: '3B3E6BCE-E0D1-4A4E-B720-D73C03C88C64',
    name: 'size-xxxl',
    codepoint: 'F13A9',
    deprecated: 'FALSE',
  },
  {
    id: 'CB0C6DC2-0A4E-41C8-9E6A-9A8531F754CF',
    name: 'skate',
    codepoint: 'F0D35',
    deprecated: 'FALSE',
  },
  {
    id: '092B5CD3-BB99-492C-9784-4BE71B0C7F5B',
    name: 'skate-off',
    codepoint: 'F0699',
    deprecated: 'FALSE',
  },
  {
    id: '56BF6222-BEC1-44B6-845C-AD6F84552D90',
    name: 'skateboard',
    codepoint: 'F14C2',
    deprecated: 'FALSE',
  },
  {
    id: '47F1C290-ED78-4459-B33C-6E2ACC867CC6',
    name: 'skateboarding',
    codepoint: 'F0501',
    deprecated: 'FALSE',
  },
  {
    id: '43E5E22F-AEEB-406D-BEF3-898BE3C91FC1',
    name: 'skew-less',
    codepoint: 'F0D36',
    deprecated: 'FALSE',
  },
  {
    id: 'D84076D2-8C7C-4CD7-98EA-05108B10FF1A',
    name: 'skew-more',
    codepoint: 'F0D37',
    deprecated: 'FALSE',
  },
  {
    id: '197B0D1A-34DB-4FD7-825B-FFE5C28297BB',
    name: 'ski',
    codepoint: 'F1304',
    deprecated: 'FALSE',
  },
  {
    id: 'BD9C05A6-55EA-4F72-A216-85F990F65EDA',
    name: 'ski-cross-country',
    codepoint: 'F1305',
    deprecated: 'FALSE',
  },
  {
    id: '51D8DB1F-FA7C-4527-AD8E-495024CD4E2D',
    name: 'ski-water',
    codepoint: 'F1306',
    deprecated: 'FALSE',
  },
  {
    id: '45260E4C-E8EB-4E35-85A8-F19A91FE1A2C',
    name: 'skip-backward',
    codepoint: 'F04AB',
    deprecated: 'FALSE',
  },
  {
    id: '22E55747-AE80-4DE9-8BC8-10B61C69F161',
    name: 'skip-backward-outline',
    codepoint: 'F0F25',
    deprecated: 'FALSE',
  },
  {
    id: '760EA0AB-7906-4FA4-86E0-CD18A4073302',
    name: 'skip-forward',
    codepoint: 'F04AC',
    deprecated: 'FALSE',
  },
  {
    id: '1EB18C03-3143-42C6-ACA9-D62086DA371D',
    name: 'skip-forward-outline',
    codepoint: 'F0F26',
    deprecated: 'FALSE',
  },
  {
    id: 'D2F19A25-4EF4-41A7-A91A-C03C77E32186',
    name: 'skip-next',
    codepoint: 'F04AD',
    deprecated: 'FALSE',
  },
  {
    id: '0D688B5D-27F8-47CF-9234-87DB3F25D28E',
    name: 'skip-next-circle',
    codepoint: 'F0661',
    deprecated: 'FALSE',
  },
  {
    id: '5AE3DE91-B2E8-48DD-A170-1F26E391F830',
    name: 'skip-next-circle-outline',
    codepoint: 'F0662',
    deprecated: 'FALSE',
  },
  {
    id: 'FB14BE1B-5BAD-44CD-9E68-CABE4A79F527',
    name: 'skip-next-outline',
    codepoint: 'F0F27',
    deprecated: 'FALSE',
  },
  {
    id: '8D8B924F-0AD5-4458-9BCE-0A5887D935B6',
    name: 'skip-previous',
    codepoint: 'F04AE',
    deprecated: 'FALSE',
  },
  {
    id: '3584845F-971C-45BB-8184-CFEBE0044474',
    name: 'skip-previous-circle',
    codepoint: 'F0663',
    deprecated: 'FALSE',
  },
  {
    id: 'FF83529F-FAA5-4FAD-9EE8-9F9A74409F93',
    name: 'skip-previous-circle-outline',
    codepoint: 'F0664',
    deprecated: 'FALSE',
  },
  {
    id: 'F1415D50-592B-4480-94D6-1FA82CDBA7B9',
    name: 'skip-previous-outline',
    codepoint: 'F0F28',
    deprecated: 'FALSE',
  },
  {
    id: 'AD1B00BA-C98D-4961-A444-9484EF434BEC',
    name: 'skull',
    codepoint: 'F068C',
    deprecated: 'FALSE',
  },
  {
    id: 'C85F31B5-3C18-480D-B3A6-7F4FB7095273',
    name: 'skull-crossbones',
    codepoint: 'F0BC6',
    deprecated: 'FALSE',
  },
  {
    id: '5DBA18AE-EED4-4F73-A0FD-ACC5E731A70C',
    name: 'skull-crossbones-outline',
    codepoint: 'F0BC7',
    deprecated: 'FALSE',
  },
  {
    id: '31E17B6D-1AD2-43C5-9FF3-6A8D2FA32C17',
    name: 'skull-outline',
    codepoint: 'F0BC8',
    deprecated: 'FALSE',
  },
  {
    id: '3FF20BCB-0BFF-4F46-ABE9-954C8F2EB154',
    name: 'skull-scan',
    codepoint: 'F14C7',
    deprecated: 'FALSE',
  },
  {
    id: 'FFA72D4C-C2EE-4492-B556-95C873FF1677',
    name: 'skull-scan-outline',
    codepoint: 'F14C8',
    deprecated: 'FALSE',
  },
  {
    id: '07E9D89D-2D58-40C6-9D41-49722CFC0DAE',
    name: 'skype',
    codepoint: 'F04AF',
    deprecated: 'TRUE',
  },
  {
    id: '813333B0-4A34-4AC6-819C-0FBEABC06246',
    name: 'skype-business',
    codepoint: 'F04B0',
    deprecated: 'TRUE',
  },
  {
    id: 'EA0E061F-B48E-4443-BBE8-6080C2258730',
    name: 'slack',
    codepoint: 'F04B1',
    deprecated: 'TRUE',
  },
  {
    id: '355F6D86-E6B6-4EA4-91BA-95370240E9CF',
    name: 'slash-forward',
    codepoint: 'F0FDF',
    deprecated: 'FALSE',
  },
  {
    id: 'D6A5CF49-38A9-4911-ACAC-FF9A09BBEC76',
    name: 'slash-forward-box',
    codepoint: 'F0FE0',
    deprecated: 'FALSE',
  },
  {
    id: 'E67044BA-8025-4D61-916C-56DF4D6FFE6D',
    name: 'sledding',
    codepoint: 'F041B',
    deprecated: 'FALSE',
  },
  {
    id: 'C9CF20D8-55F4-4997-8019-F8056ED86702',
    name: 'sleep',
    codepoint: 'F04B2',
    deprecated: 'FALSE',
  },
  {
    id: '0801B02C-2187-40D1-8A03-D521D0214662',
    name: 'sleep-off',
    codepoint: 'F04B3',
    deprecated: 'FALSE',
  },
  {
    id: '799D9561-AC12-4E30-8CA8-2FBF9E5597B8',
    name: 'slide',
    codepoint: 'F15A5',
    deprecated: 'FALSE',
  },
  {
    id: '34D7190A-2B00-4B61-9A55-1E97E6F962A5',
    name: 'slope-downhill',
    codepoint: 'F0DFF',
    deprecated: 'FALSE',
  },
  {
    id: '99641F5F-1036-4092-B230-C1CBC7E6A746',
    name: 'slope-uphill',
    codepoint: 'F0E00',
    deprecated: 'FALSE',
  },
  {
    id: '8DAEB736-3A62-475D-81A5-47BD881EA912',
    name: 'slot-machine',
    codepoint: 'F1114',
    deprecated: 'FALSE',
  },
  {
    id: '5A083823-77FD-4C5E-98B8-EF320E8493CD',
    name: 'slot-machine-outline',
    codepoint: 'F1115',
    deprecated: 'FALSE',
  },
  {
    id: '9682A6D2-A7FA-47BF-A94E-6CDEC8B55446',
    name: 'smart-card',
    codepoint: 'F10BD',
    deprecated: 'FALSE',
  },
  {
    id: '4A41F4FC-7E7C-4EA6-80F7-61274B13CBF4',
    name: 'smart-card-off',
    codepoint: 'F18F7',
    deprecated: 'FALSE',
  },
  {
    id: '8DB35D1C-0F5C-4E09-BB38-45B6F57943B4',
    name: 'smart-card-off-outline',
    codepoint: 'F18F8',
    deprecated: 'FALSE',
  },
  {
    id: 'D5EED689-19F3-4198-9A00-27C6DAE518E7',
    name: 'smart-card-outline',
    codepoint: 'F10BE',
    deprecated: 'FALSE',
  },
  {
    id: '143C16AD-B22C-4E1D-915D-3FE9C962DD2F',
    name: 'smart-card-reader',
    codepoint: 'F10BF',
    deprecated: 'FALSE',
  },
  {
    id: 'FCC195C4-2DB3-45DE-B9D2-4535A63EDB0E',
    name: 'smart-card-reader-outline',
    codepoint: 'F10C0',
    deprecated: 'FALSE',
  },
  {
    id: 'FD344F98-3CED-4CDD-88FF-8A21A1DFDE47',
    name: 'smog',
    codepoint: 'F0A71',
    deprecated: 'FALSE',
  },
  {
    id: 'EE8752CF-6D3B-4D58-AFD4-1868AAA30933',
    name: 'smoke',
    codepoint: 'F1799',
    deprecated: 'FALSE',
  },
  {
    id: '1DDC71A0-A86A-4E8F-BF1D-CD33C0B0BEA4',
    name: 'smoke-detector',
    codepoint: 'F0392',
    deprecated: 'FALSE',
  },
  {
    id: '1BF163FB-02C8-4CE6-98AA-87EFBA04294D',
    name: 'smoke-detector-alert',
    codepoint: 'F192E',
    deprecated: 'FALSE',
  },
  {
    id: '60E74F0E-5537-4021-8223-BAD2B0130463',
    name: 'smoke-detector-alert-outline',
    codepoint: 'F192F',
    deprecated: 'FALSE',
  },
  {
    id: 'E523AF46-986A-4E8F-9662-45C61A70A887',
    name: 'smoke-detector-off',
    codepoint: 'F1809',
    deprecated: 'FALSE',
  },
  {
    id: '50719FA7-F144-4C4E-9A1E-DF4F431B117C',
    name: 'smoke-detector-off-outline',
    codepoint: 'F180A',
    deprecated: 'FALSE',
  },
  {
    id: 'D1429C1F-766F-4F96-B784-15E6C3ECC588',
    name: 'smoke-detector-outline',
    codepoint: 'F1808',
    deprecated: 'FALSE',
  },
  {
    id: '99D5165C-0609-4349-9046-E3180B32E684',
    name: 'smoke-detector-variant',
    codepoint: 'F180B',
    deprecated: 'FALSE',
  },
  {
    id: '1159B2CB-5BF1-4DB6-AC9D-80CEA88FC227',
    name: 'smoke-detector-variant-alert',
    codepoint: 'F1930',
    deprecated: 'FALSE',
  },
  {
    id: 'C3D42E4D-5DBB-4E57-AF01-4D8E948F050E',
    name: 'smoke-detector-variant-off',
    codepoint: 'F180C',
    deprecated: 'FALSE',
  },
  {
    id: '20A9F2BB-C53E-410E-A433-4CEE2305AD49',
    name: 'smoking',
    codepoint: 'F04B4',
    deprecated: 'FALSE',
  },
  {
    id: '5F974B83-AEEC-4C52-B281-1A8663B70027',
    name: 'smoking-off',
    codepoint: 'F04B5',
    deprecated: 'FALSE',
  },
  {
    id: '8F75261A-E650-4B58-84AE-C805677DCC44',
    name: 'smoking-pipe',
    codepoint: 'F140D',
    deprecated: 'FALSE',
  },
  {
    id: 'D43EF949-5B56-488A-91AE-1B19CDE64ED8',
    name: 'smoking-pipe-off',
    codepoint: 'F1428',
    deprecated: 'FALSE',
  },
  {
    id: '529CCAF2-D35F-4A40-BEF2-213159AE5DD9',
    name: 'snail',
    codepoint: 'F1677',
    deprecated: 'FALSE',
  },
  {
    id: 'C525609F-8CAF-40A8-A3FC-724F6C699E0A',
    name: 'snake',
    codepoint: 'F150E',
    deprecated: 'FALSE',
  },
  {
    id: '6DA57C23-2ADA-4CA1-8D1D-AFBBFA59C4FA',
    name: 'snapchat',
    codepoint: 'F04B6',
    deprecated: 'TRUE',
  },
  {
    id: 'C2A03E16-166E-4FAA-A5DB-462CE38C44FE',
    name: 'snowboard',
    codepoint: 'F1307',
    deprecated: 'FALSE',
  },
  {
    id: '5675A747-D467-473E-9A1D-1C61E1B5C619',
    name: 'snowflake',
    codepoint: 'F0717',
    deprecated: 'FALSE',
  },
  {
    id: '5F856EA3-F987-439D-AAFF-ED545ECBBA75',
    name: 'snowflake-alert',
    codepoint: 'F0F29',
    deprecated: 'FALSE',
  },
  {
    id: 'F489E6B1-12CD-4847-B039-8577751B2DFE',
    name: 'snowflake-check',
    codepoint: 'F1A70',
    deprecated: 'FALSE',
  },
  {
    id: '017BAEB6-E5B4-4BEF-8CA3-D0ABA689DE2C',
    name: 'snowflake-melt',
    codepoint: 'F12CB',
    deprecated: 'FALSE',
  },
  {
    id: 'C80A9F84-F736-4E92-8D93-05F9F72D32B8',
    name: 'snowflake-off',
    codepoint: 'F14E3',
    deprecated: 'FALSE',
  },
  {
    id: 'C9E4ABED-D38C-45EC-9340-C6C8ADAD1CDF',
    name: 'snowflake-thermometer',
    codepoint: 'F1A71',
    deprecated: 'FALSE',
  },
  {
    id: '50B6D484-E7DC-4B88-9981-9C962C9A9EB6',
    name: 'snowflake-variant',
    codepoint: 'F0F2A',
    deprecated: 'FALSE',
  },
  {
    id: '06497E01-D87F-479B-90DC-B4E33497C8D8',
    name: 'snowman',
    codepoint: 'F04B7',
    deprecated: 'FALSE',
  },
  {
    id: 'A4C7D4DF-2B1F-424B-9502-6618F88C9A65',
    name: 'snowmobile',
    codepoint: 'F06DD',
    deprecated: 'FALSE',
  },
  {
    id: 'D9F59FE2-7B27-4B54-8935-AC2A83CE084B',
    name: 'snowshoeing',
    codepoint: 'F1A72',
    deprecated: 'FALSE',
  },
  {
    id: 'B4AFB6A4-C315-4B06-AE4D-AC633F3ED0A7',
    name: 'soccer',
    codepoint: 'F04B8',
    deprecated: 'FALSE',
  },
  {
    id: 'C24D4262-700B-46E1-82C1-01449C16205E',
    name: 'soccer-field',
    codepoint: 'F0834',
    deprecated: 'FALSE',
  },
  {
    id: 'D6384EF6-9386-4287-BE34-7038841AABD5',
    name: 'social-distance-2-meters',
    codepoint: 'F1579',
    deprecated: 'FALSE',
  },
  {
    id: '59F69A6A-5DB4-4938-A90A-B186655409C5',
    name: 'social-distance-6-feet',
    codepoint: 'F157A',
    deprecated: 'FALSE',
  },
  {
    id: '5560ABF9-E635-4D73-A39B-5404443F099F',
    name: 'sofa',
    codepoint: 'F04B9',
    deprecated: 'FALSE',
  },
  {
    id: '37B31F21-3190-4E2D-A824-C5CC4FF66493',
    name: 'sofa-outline',
    codepoint: 'F156D',
    deprecated: 'FALSE',
  },
  {
    id: '61A9F431-1946-4F8F-9DB4-19E75151D397',
    name: 'sofa-single',
    codepoint: 'F156E',
    deprecated: 'FALSE',
  },
  {
    id: 'C4595FA1-E495-4BFE-900A-A1D764B6019E',
    name: 'sofa-single-outline',
    codepoint: 'F156F',
    deprecated: 'FALSE',
  },
  {
    id: '46CD8278-94CE-46F4-B2A5-01D4595E48A0',
    name: 'solar-panel',
    codepoint: 'F0D9B',
    deprecated: 'FALSE',
  },
  {
    id: '13BD6DBE-90E0-42A0-8FE6-A168974483CA',
    name: 'solar-panel-large',
    codepoint: 'F0D9C',
    deprecated: 'FALSE',
  },
  {
    id: '727F32FC-051B-43FE-9888-4616927AE828',
    name: 'solar-power',
    codepoint: 'F0A72',
    deprecated: 'FALSE',
  },
  {
    id: '9A0563D4-0CEB-4F7F-8CA2-27606F24603F',
    name: 'solar-power-variant',
    codepoint: 'F1A73',
    deprecated: 'FALSE',
  },
  {
    id: '3EF2EC63-DBE4-4825-9AFD-5567C0A081E2',
    name: 'solar-power-variant-outline',
    codepoint: 'F1A74',
    deprecated: 'FALSE',
  },
  {
    id: '4A90CB52-2637-4E5D-8333-467DD85B36F6',
    name: 'soldering-iron',
    codepoint: 'F1092',
    deprecated: 'FALSE',
  },
  {
    id: 'F0E0017F-DD8C-4811-9428-613238CA66A4',
    name: 'solid',
    codepoint: 'F068D',
    deprecated: 'FALSE',
  },
  {
    id: 'FF92F5A9-DE89-4E7D-BCAB-98920EA96FFE',
    name: 'sony-playstation',
    codepoint: 'F0414',
    deprecated: 'TRUE',
  },
  {
    id: '3052FDE9-791A-43E8-ACF4-A5EB7DF8296F',
    name: 'sort',
    codepoint: 'F04BA',
    deprecated: 'FALSE',
  },
  {
    id: '496476A3-F614-4925-90E6-3BB83C1C4E37',
    name: 'sort-alphabetical-ascending',
    codepoint: 'F05BD',
    deprecated: 'FALSE',
  },
  {
    id: '96571AB7-B3D4-4A76-ADE0-47D931FF1F0A',
    name: 'sort-alphabetical-ascending-variant',
    codepoint: 'F1148',
    deprecated: 'FALSE',
  },
  {
    id: '7F545D3A-5E66-42D4-88AB-8A2BD817D731',
    name: 'sort-alphabetical-descending',
    codepoint: 'F05BF',
    deprecated: 'FALSE',
  },
  {
    id: 'D4F8234D-4EE3-4825-861E-C46E8FE6F438',
    name: 'sort-alphabetical-descending-variant',
    codepoint: 'F1149',
    deprecated: 'FALSE',
  },
  {
    id: 'C2687EF2-6AEB-48BC-98E5-9A010C6E2BE7',
    name: 'sort-alphabetical-variant',
    codepoint: 'F04BB',
    deprecated: 'FALSE',
  },
  {
    id: 'F36D1111-54BF-4BB8-AE34-DE4809105551',
    name: 'sort-ascending',
    codepoint: 'F04BC',
    deprecated: 'FALSE',
  },
  {
    id: 'BDB3C0CD-D017-4895-82AD-41FEE4351FA6',
    name: 'sort-bool-ascending',
    codepoint: 'F1385',
    deprecated: 'FALSE',
  },
  {
    id: '9F0F11BF-8F55-45CD-B185-7C8BC278954C',
    name: 'sort-bool-ascending-variant',
    codepoint: 'F1386',
    deprecated: 'FALSE',
  },
  {
    id: '36E782AF-0396-45C7-B5C2-61FB37C01E2F',
    name: 'sort-bool-descending',
    codepoint: 'F1387',
    deprecated: 'FALSE',
  },
  {
    id: '549371B9-36F9-45E7-918A-72EEB98F9572',
    name: 'sort-bool-descending-variant',
    codepoint: 'F1388',
    deprecated: 'FALSE',
  },
  {
    id: '87CE4E85-7911-415C-89AB-133A2AB8BC57',
    name: 'sort-calendar-ascending',
    codepoint: 'F1547',
    deprecated: 'FALSE',
  },
  {
    id: 'D01B3C7C-64DC-4486-A656-55F7024C96AA',
    name: 'sort-calendar-descending',
    codepoint: 'F1548',
    deprecated: 'FALSE',
  },
  {
    id: '2A4F548A-F4C1-48F2-BFB1-A6A1ACB794A6',
    name: 'sort-clock-ascending',
    codepoint: 'F1549',
    deprecated: 'FALSE',
  },
  {
    id: '85579FDA-7E54-483B-B1DD-A1920DFBEED6',
    name: 'sort-clock-ascending-outline',
    codepoint: 'F154A',
    deprecated: 'FALSE',
  },
  {
    id: '8064DE58-939A-4179-A65E-0F5FA4AD6A18',
    name: 'sort-clock-descending',
    codepoint: 'F154B',
    deprecated: 'FALSE',
  },
  {
    id: 'B574E857-7AE0-493E-82E1-36DC10882508',
    name: 'sort-clock-descending-outline',
    codepoint: 'F154C',
    deprecated: 'FALSE',
  },
  {
    id: 'F0028BDF-0589-400F-8596-100CCB029D6E',
    name: 'sort-descending',
    codepoint: 'F04BD',
    deprecated: 'FALSE',
  },
  {
    id: '68CF5B27-ACB2-468B-BD75-1DAE92A7D242',
    name: 'sort-numeric-ascending',
    codepoint: 'F1389',
    deprecated: 'FALSE',
  },
  {
    id: 'D3472197-9E32-440B-9F56-E84F2D35C657',
    name: 'sort-numeric-ascending-variant',
    codepoint: 'F090D',
    deprecated: 'FALSE',
  },
  {
    id: 'E0ABC4A1-3840-48EF-82F8-BFF825D02F64',
    name: 'sort-numeric-descending',
    codepoint: 'F138A',
    deprecated: 'FALSE',
  },
  {
    id: '618ED59F-832C-4DA8-A8F6-421CC6A10305',
    name: 'sort-numeric-descending-variant',
    codepoint: 'F0AD2',
    deprecated: 'FALSE',
  },
  {
    id: '2CD8E0F3-0AF4-4BBD-B1C8-EEF6391F62C3',
    name: 'sort-numeric-variant',
    codepoint: 'F04BE',
    deprecated: 'FALSE',
  },
  {
    id: 'A00FD3F3-01E7-489E-AB22-66F3B94AF6C0',
    name: 'sort-reverse-variant',
    codepoint: 'F033C',
    deprecated: 'FALSE',
  },
  {
    id: '9E32B54D-9759-4233-B358-C00A11300713',
    name: 'sort-variant',
    codepoint: 'F04BF',
    deprecated: 'FALSE',
  },
  {
    id: '450895A4-8BFF-4F63-A567-2010226F7464',
    name: 'sort-variant-lock',
    codepoint: 'F0CCD',
    deprecated: 'FALSE',
  },
  {
    id: '30DC202C-F695-4A76-B3BB-293BCC995A6E',
    name: 'sort-variant-lock-open',
    codepoint: 'F0CCE',
    deprecated: 'FALSE',
  },
  {
    id: '9F5F2EFA-5AED-4914-9ADC-3C2C7EC60386',
    name: 'sort-variant-off',
    codepoint: 'F1ABB',
    deprecated: 'FALSE',
  },
  {
    id: '51D1A98E-7CD7-4BD7-88C9-333B5435C580',
    name: 'sort-variant-remove',
    codepoint: 'F1147',
    deprecated: 'FALSE',
  },
  {
    id: '1A5FBE33-5A69-4ED4-8A33-416EFA9B24CD',
    name: 'soundbar',
    codepoint: 'F17DB',
    deprecated: 'FALSE',
  },
  {
    id: '5952089F-872D-454C-AD1F-18B205938245',
    name: 'soundcloud',
    codepoint: 'F04C0',
    deprecated: 'TRUE',
  },
  {
    id: 'AADA1B60-0161-4417-9726-75AFB5C7AC31',
    name: 'source-branch',
    codepoint: 'F062C',
    deprecated: 'FALSE',
  },
  {
    id: '66701C6C-D1D4-42FF-AA03-74C3F4E65AC1',
    name: 'source-branch-check',
    codepoint: 'F14CF',
    deprecated: 'FALSE',
  },
  {
    id: '288DC509-ABA1-436D-8D8D-F54FEE2DA4B8',
    name: 'source-branch-minus',
    codepoint: 'F14CB',
    deprecated: 'FALSE',
  },
  {
    id: 'D5EAA72B-6FF0-4520-8207-A7E3E47B35F5',
    name: 'source-branch-plus',
    codepoint: 'F14CA',
    deprecated: 'FALSE',
  },
  {
    id: '58A72E01-DBCC-4C66-AAFE-FB37845FB50E',
    name: 'source-branch-refresh',
    codepoint: 'F14CD',
    deprecated: 'FALSE',
  },
  {
    id: 'EB47CC8C-8EF2-4FE2-9530-2360C26378B6',
    name: 'source-branch-remove',
    codepoint: 'F14CC',
    deprecated: 'FALSE',
  },
  {
    id: '0A420930-2315-468C-B639-0D7E96D6937A',
    name: 'source-branch-sync',
    codepoint: 'F14CE',
    deprecated: 'FALSE',
  },
  {
    id: 'B0400135-C84A-47D8-AD30-B6BD8292E6A4',
    name: 'source-commit',
    codepoint: 'F0718',
    deprecated: 'FALSE',
  },
  {
    id: '5558E497-77AD-48F4-9456-E6D6B4CA991B',
    name: 'source-commit-end',
    codepoint: 'F0719',
    deprecated: 'FALSE',
  },
  {
    id: '5681B6C8-CC7F-42B0-9B6F-B53D9A72D12F',
    name: 'source-commit-end-local',
    codepoint: 'F071A',
    deprecated: 'FALSE',
  },
  {
    id: 'DF889541-FEF0-42C0-BF6E-E29D4503C3FB',
    name: 'source-commit-local',
    codepoint: 'F071B',
    deprecated: 'FALSE',
  },
  {
    id: '42A5B80B-C7A6-42F4-8437-458F504B7AF5',
    name: 'source-commit-next-local',
    codepoint: 'F071C',
    deprecated: 'FALSE',
  },
  {
    id: '6647D5DC-4BF4-47FB-9B11-F1ABB94B3769',
    name: 'source-commit-start',
    codepoint: 'F071D',
    deprecated: 'FALSE',
  },
  {
    id: '64F8200C-7866-46A6-9E8B-1CAF621517CC',
    name: 'source-commit-start-next-local',
    codepoint: 'F071E',
    deprecated: 'FALSE',
  },
  {
    id: 'F2A480EB-B06A-4472-88B9-C881754675FE',
    name: 'source-fork',
    codepoint: 'F04C1',
    deprecated: 'FALSE',
  },
  {
    id: 'C4A094D6-A68C-41F8-A2EC-C87997DACE70',
    name: 'source-merge',
    codepoint: 'F062D',
    deprecated: 'FALSE',
  },
  {
    id: '6555FD23-E5EE-4F40-91CF-B72476ADED54',
    name: 'source-pull',
    codepoint: 'F04C2',
    deprecated: 'FALSE',
  },
  {
    id: '1D318024-029E-4654-A318-6647D265CDD9',
    name: 'source-repository',
    codepoint: 'F0CCF',
    deprecated: 'FALSE',
  },
  {
    id: '16D37AE7-409E-48A5-BE24-9DCBA0792FD7',
    name: 'source-repository-multiple',
    codepoint: 'F0CD0',
    deprecated: 'FALSE',
  },
  {
    id: '62A5E551-73E0-4C84-89E4-C0F34BDACEFA',
    name: 'soy-sauce',
    codepoint: 'F07EE',
    deprecated: 'FALSE',
  },
  {
    id: '31D89882-A6D2-4E79-B441-582B1497C3D2',
    name: 'soy-sauce-off',
    codepoint: 'F13FC',
    deprecated: 'FALSE',
  },
  {
    id: '84F00C86-0380-4554-B142-286C3B80BA3A',
    name: 'spa',
    codepoint: 'F0CD1',
    deprecated: 'FALSE',
  },
  {
    id: 'B0D344FE-8924-48EF-BFBA-93DB1C77DFD1',
    name: 'spa-outline',
    codepoint: 'F0CD2',
    deprecated: 'FALSE',
  },
  {
    id: '0C50BCF5-076C-4DBB-BBFC-8B74B3CC64E7',
    name: 'space-invaders',
    codepoint: 'F0BC9',
    deprecated: 'FALSE',
  },
  {
    id: '0F961F30-F687-4232-98E7-C27F2FC64293',
    name: 'space-station',
    codepoint: 'F1383',
    deprecated: 'FALSE',
  },
  {
    id: '7D15FB8A-6AD7-47C9-93AD-4E968C39CDBF',
    name: 'spade',
    codepoint: 'F0E65',
    deprecated: 'FALSE',
  },
  {
    id: '43C37FC5-E271-40FA-BB50-C139DDD5B15B',
    name: 'speaker',
    codepoint: 'F04C3',
    deprecated: 'FALSE',
  },
  {
    id: 'E9F560A8-FFD9-44CD-8736-00C627756591',
    name: 'speaker-bluetooth',
    codepoint: 'F09A2',
    deprecated: 'FALSE',
  },
  {
    id: '57CDD6CF-360C-4B79-B9D3-1AADC9B969AD',
    name: 'speaker-message',
    codepoint: 'F1B11',
    deprecated: 'FALSE',
  },
  {
    id: '6CBEB113-DF44-4DCE-A490-5AAD4766CE28',
    name: 'speaker-multiple',
    codepoint: 'F0D38',
    deprecated: 'FALSE',
  },
  {
    id: '9B55ECCE-270B-4A52-9A96-80BEECAD1E0B',
    name: 'speaker-off',
    codepoint: 'F04C4',
    deprecated: 'FALSE',
  },
  {
    id: '2B755A5C-4AC3-4077-9D11-29DC807BBEBA',
    name: 'speaker-pause',
    codepoint: 'F1B73',
    deprecated: 'FALSE',
  },
  {
    id: 'CB32FC8E-E6ED-4CAB-A92B-2F922EF02AD8',
    name: 'speaker-play',
    codepoint: 'F1B72',
    deprecated: 'FALSE',
  },
  {
    id: '8B5A1E60-E54F-4416-AB60-6138113BBFA7',
    name: 'speaker-stop',
    codepoint: 'F1B74',
    deprecated: 'FALSE',
  },
  {
    id: 'F9EA980F-2C75-48DE-9D56-9A15CFE52865',
    name: 'speaker-wireless',
    codepoint: 'F071F',
    deprecated: 'FALSE',
  },
  {
    id: 'B8B83A21-D267-47BD-868F-BECB081CEC1C',
    name: 'spear',
    codepoint: 'F1845',
    deprecated: 'FALSE',
  },
  {
    id: 'F0EE6F6A-2B43-4880-8DC9-6E7515B3BEDE',
    name: 'speedometer',
    codepoint: 'F04C5',
    deprecated: 'FALSE',
  },
  {
    id: '86833899-057F-4099-AF77-0EECD2BAE698',
    name: 'speedometer-medium',
    codepoint: 'F0F85',
    deprecated: 'FALSE',
  },
  {
    id: '9500D7AF-3917-41AB-8694-94FABA5F94ED',
    name: 'speedometer-slow',
    codepoint: 'F0F86',
    deprecated: 'FALSE',
  },
  {
    id: '72253002-9545-40A4-95A4-49401269B553',
    name: 'spellcheck',
    codepoint: 'F04C6',
    deprecated: 'FALSE',
  },
  {
    id: 'C599941B-1EE8-44E8-8FE9-D8AD503BB57B',
    name: 'sphere',
    codepoint: 'F1954',
    deprecated: 'FALSE',
  },
  {
    id: '9E6A501E-659C-4C6B-8ADE-4BF61DAADDD2',
    name: 'sphere-off',
    codepoint: 'F1955',
    deprecated: 'FALSE',
  },
  {
    id: 'BCDD4A6F-3064-4FF3-9172-0BBF8BED2D2C',
    name: 'spider',
    codepoint: 'F11EA',
    deprecated: 'FALSE',
  },
  {
    id: '1F613123-9D4B-4C5A-84FB-F6B804BBFB21',
    name: 'spider-thread',
    codepoint: 'F11EB',
    deprecated: 'FALSE',
  },
  {
    id: '8464C75D-EA57-4E89-80E9-BE6A48793809',
    name: 'spider-web',
    codepoint: 'F0BCA',
    deprecated: 'FALSE',
  },
  {
    id: 'B647E730-0D7E-4F13-A051-74D92C2A6F0A',
    name: 'spirit-level',
    codepoint: 'F14F1',
    deprecated: 'FALSE',
  },
  {
    id: 'D68EF8DE-C9F5-47FB-BCD4-C0DD4D7FCBFD',
    name: 'spoon-sugar',
    codepoint: 'F1429',
    deprecated: 'FALSE',
  },
  {
    id: '51C1DE39-1C25-49B7-A295-DD13B874C48B',
    name: 'spotify',
    codepoint: 'F04C7',
    deprecated: 'TRUE',
  },
  {
    id: 'A4956491-730F-4BC9-A6C1-03AF3C54C134',
    name: 'spotlight',
    codepoint: 'F04C8',
    deprecated: 'FALSE',
  },
  {
    id: '13AE04D2-BB67-4CA4-89F6-11C88C2A217E',
    name: 'spotlight-beam',
    codepoint: 'F04C9',
    deprecated: 'FALSE',
  },
  {
    id: '0CDAFE95-E28B-4A8D-9634-C6BBB99EE598',
    name: 'spray',
    codepoint: 'F0665',
    deprecated: 'FALSE',
  },
  {
    id: '997059BA-96F4-456C-8B53-8355521AE536',
    name: 'spray-bottle',
    codepoint: 'F0AE0',
    deprecated: 'FALSE',
  },
  {
    id: '3B348D41-66D2-4CC7-894A-1C2E9EE64C7F',
    name: 'sprinkler',
    codepoint: 'F105F',
    deprecated: 'FALSE',
  },
  {
    id: 'ECE05EC2-192B-4450-A0CB-78249EC9140A',
    name: 'sprinkler-fire',
    codepoint: 'F199D',
    deprecated: 'FALSE',
  },
  {
    id: '4CE94553-ECA2-4C72-B66B-EEE5106F1438',
    name: 'sprinkler-variant',
    codepoint: 'F1060',
    deprecated: 'FALSE',
  },
  {
    id: '58F40CA4-567A-42EF-A5D9-DD6A1BB42437',
    name: 'sprout',
    codepoint: 'F0E66',
    deprecated: 'FALSE',
  },
  {
    id: 'DDE9D3A4-FF81-4182-8FEE-3A0DD30F88F8',
    name: 'sprout-outline',
    codepoint: 'F0E67',
    deprecated: 'FALSE',
  },
  {
    id: '5659F8EC-377D-41D2-AB3A-52DC40815DC7',
    name: 'square',
    codepoint: 'F0764',
    deprecated: 'FALSE',
  },
  {
    id: 'F49668FC-6B0F-43E9-BB4E-044C3C9229AA',
    name: 'square-circle',
    codepoint: 'F1500',
    deprecated: 'FALSE',
  },
  {
    id: '22469E65-F1CB-408D-B6B5-1E599A85E368',
    name: 'square-edit-outline',
    codepoint: 'F090C',
    deprecated: 'FALSE',
  },
  {
    id: '0267FB77-EB23-41D4-92B4-FF65796BDF40',
    name: 'square-medium',
    codepoint: 'F0A13',
    deprecated: 'FALSE',
  },
  {
    id: '1EC70A02-36D5-4C3B-A721-018653864C6D',
    name: 'square-medium-outline',
    codepoint: 'F0A14',
    deprecated: 'FALSE',
  },
  {
    id: '19F3DC9F-4861-4220-944C-8BE8D543902B',
    name: 'square-off',
    codepoint: 'F12EE',
    deprecated: 'FALSE',
  },
  {
    id: '32823532-5FAC-4894-8577-EAED930DE4C2',
    name: 'square-off-outline',
    codepoint: 'F12EF',
    deprecated: 'FALSE',
  },
  {
    id: '6F9AA4F8-ECC6-495E-87C0-84DCE1327F4D',
    name: 'square-opacity',
    codepoint: 'F1854',
    deprecated: 'FALSE',
  },
  {
    id: '5ED659B5-F175-445F-950D-0B4DDAB3F8B3',
    name: 'square-outline',
    codepoint: 'F0763',
    deprecated: 'FALSE',
  },
  {
    id: 'D0835CDD-0255-4A0D-B86F-DAB300C5ADCC',
    name: 'square-root',
    codepoint: 'F0784',
    deprecated: 'FALSE',
  },
  {
    id: '4DD48496-5BE6-4866-A298-1D9D399651B0',
    name: 'square-root-box',
    codepoint: 'F09A3',
    deprecated: 'FALSE',
  },
  {
    id: '56B32490-2AEB-4EF7-B0F8-72D918829BE2',
    name: 'square-rounded',
    codepoint: 'F14FB',
    deprecated: 'FALSE',
  },
  {
    id: 'D8486DF6-E4E4-4EAF-B362-8A9FD1CF27C3',
    name: 'square-rounded-badge',
    codepoint: 'F1A07',
    deprecated: 'FALSE',
  },
  {
    id: '336D96D3-5E97-434B-BEF7-651BF94FA347',
    name: 'square-rounded-badge-outline',
    codepoint: 'F1A08',
    deprecated: 'FALSE',
  },
  {
    id: '9E46259E-F519-4CCA-BE7E-0DAC8230B3A4',
    name: 'square-rounded-outline',
    codepoint: 'F14FC',
    deprecated: 'FALSE',
  },
  {
    id: 'E4490267-ACAE-4E86-8747-8D2D620DC707',
    name: 'square-small',
    codepoint: 'F0A15',
    deprecated: 'FALSE',
  },
  {
    id: 'F55F6161-5EE4-4168-881C-A50E671AC25F',
    name: 'square-wave',
    codepoint: 'F147B',
    deprecated: 'FALSE',
  },
  {
    id: '363A0CD3-194D-4F7F-9287-82B8D565B064',
    name: 'squeegee',
    codepoint: 'F0AE1',
    deprecated: 'FALSE',
  },
  {
    id: 'EC87D6AA-77FC-462A-82AC-715550CD98AB',
    name: 'ssh',
    codepoint: 'F08C0',
    deprecated: 'FALSE',
  },
  {
    id: 'D76A8752-ED97-4E90-95EC-B1D6E81ABA9E',
    name: 'stack-exchange',
    codepoint: 'F060B',
    deprecated: 'TRUE',
  },
  {
    id: '72E7037B-1C3B-44BB-A9EC-FC2D6B3EA745',
    name: 'stack-overflow',
    codepoint: 'F04CC',
    deprecated: 'TRUE',
  },
  {
    id: '7FB125A0-875A-4766-BFAF-02CC45226530',
    name: 'stackpath',
    codepoint: 'F0359',
    deprecated: 'TRUE',
  },
  {
    id: '5FDB24B6-FF16-4575-A794-E5CCEB95CC81',
    name: 'stadium',
    codepoint: 'F0FF9',
    deprecated: 'FALSE',
  },
  {
    id: 'AB07DE31-2215-49E8-925E-8E4B235664FA',
    name: 'stadium-outline',
    codepoint: 'F1B03',
    deprecated: 'FALSE',
  },
  {
    id: '0407BE27-099D-4170-BA1C-A87A4239F9DA',
    name: 'stadium-variant',
    codepoint: 'F0720',
    deprecated: 'FALSE',
  },
  {
    id: 'D5BBEF8B-FC7A-4FA5-9DA6-4D970AC6A530',
    name: 'stairs',
    codepoint: 'F04CD',
    deprecated: 'FALSE',
  },
  {
    id: '58C2BC63-FCF5-445B-B351-23CF48253FEF',
    name: 'stairs-box',
    codepoint: 'F139E',
    deprecated: 'FALSE',
  },
  {
    id: '5B344682-C177-40CC-97EC-418D010EAE07',
    name: 'stairs-down',
    codepoint: 'F12BE',
    deprecated: 'FALSE',
  },
  {
    id: '8F09C6B0-DFE8-4700-A90A-476EFA6454F4',
    name: 'stairs-up',
    codepoint: 'F12BD',
    deprecated: 'FALSE',
  },
  {
    id: '2D9C68D6-6DFA-42CC-B69C-E28011FC2BF7',
    name: 'stamper',
    codepoint: 'F0D39',
    deprecated: 'FALSE',
  },
  {
    id: 'DAA79A7B-03FB-431A-85DD-AB9749783DC0',
    name: 'standard-definition',
    codepoint: 'F07EF',
    deprecated: 'FALSE',
  },
  {
    id: '53D81A11-D8FF-46F4-A0CB-B7F668BA720D',
    name: 'star',
    codepoint: 'F04CE',
    deprecated: 'FALSE',
  },
  {
    id: '07CA9ED6-5D94-4145-AB3E-EA9FD7283E0A',
    name: 'star-box',
    codepoint: 'F0A73',
    deprecated: 'FALSE',
  },
  {
    id: '9270C3C4-1ADB-4965-9DAE-444F9448B39E',
    name: 'star-box-multiple',
    codepoint: 'F1286',
    deprecated: 'FALSE',
  },
  {
    id: '2B9ACC0A-EA3D-478D-935E-EAFA8F8580CF',
    name: 'star-box-multiple-outline',
    codepoint: 'F1287',
    deprecated: 'FALSE',
  },
  {
    id: 'B22FEE66-3EC9-466A-9014-EF53030FA3D9',
    name: 'star-box-outline',
    codepoint: 'F0A74',
    deprecated: 'FALSE',
  },
  {
    id: '6AB2A77F-C9ED-4EF1-BDD3-4E060E690A7E',
    name: 'star-check',
    codepoint: 'F1566',
    deprecated: 'FALSE',
  },
  {
    id: '94E37A05-1F0E-49EE-92D8-5FE57B54123F',
    name: 'star-check-outline',
    codepoint: 'F156A',
    deprecated: 'FALSE',
  },
  {
    id: '76D19C8B-0302-436A-A19F-5AC5EE8AC88C',
    name: 'star-circle',
    codepoint: 'F04CF',
    deprecated: 'FALSE',
  },
  {
    id: '9E034CD9-F400-4D4A-A2CB-6030313F2CED',
    name: 'star-circle-outline',
    codepoint: 'F09A4',
    deprecated: 'FALSE',
  },
  {
    id: '82407F8C-2E0B-4F79-9409-0C267A248470',
    name: 'star-cog',
    codepoint: 'F1668',
    deprecated: 'FALSE',
  },
  {
    id: 'D1100539-0788-4C74-AD03-8C5F8383DF2E',
    name: 'star-cog-outline',
    codepoint: 'F1669',
    deprecated: 'FALSE',
  },
  {
    id: '94C88D65-EE50-4DC5-920D-FA4181DE0413',
    name: 'star-crescent',
    codepoint: 'F0979',
    deprecated: 'FALSE',
  },
  {
    id: '7EF95029-012C-456C-A1E8-BB96961071FD',
    name: 'star-david',
    codepoint: 'F097A',
    deprecated: 'FALSE',
  },
  {
    id: '20FBE06D-72C3-4E1C-945D-C7E69DC0736D',
    name: 'star-face',
    codepoint: 'F09A5',
    deprecated: 'FALSE',
  },
  {
    id: '4F7E1FD5-FE11-4509-8253-D0B2EB7E3CFF',
    name: 'star-four-points',
    codepoint: 'F0AE2',
    deprecated: 'FALSE',
  },
  {
    id: 'F74484E2-76FB-4671-9960-AE18E95F04FA',
    name: 'star-four-points-outline',
    codepoint: 'F0AE3',
    deprecated: 'FALSE',
  },
  {
    id: 'E86BFC0D-B4A4-41F2-A8E3-D1AE79072A45',
    name: 'star-half',
    codepoint: 'F0246',
    deprecated: 'FALSE',
  },
  {
    id: '52E97617-C7F4-45D0-98E0-E9B4407EC179',
    name: 'star-half-full',
    codepoint: 'F04D0',
    deprecated: 'FALSE',
  },
  {
    id: 'CA24758C-3B0C-44DE-9395-C5CAF2981E5D',
    name: 'star-minus',
    codepoint: 'F1564',
    deprecated: 'FALSE',
  },
  {
    id: '24A0E7F1-32AA-4B4B-B0BC-93AD9F7E2F8A',
    name: 'star-minus-outline',
    codepoint: 'F1568',
    deprecated: 'FALSE',
  },
  {
    id: '3CE96C58-D12F-474D-916F-D0509E3BA408',
    name: 'star-off',
    codepoint: 'F04D1',
    deprecated: 'FALSE',
  },
  {
    id: '297E76E3-B7A2-44A1-A340-F479AEFEF282',
    name: 'star-off-outline',
    codepoint: 'F155B',
    deprecated: 'FALSE',
  },
  {
    id: 'D443AD2F-291D-496E-ACD2-109891446FF2',
    name: 'star-outline',
    codepoint: 'F04D2',
    deprecated: 'FALSE',
  },
  {
    id: 'A782B3BE-28DC-4BF8-A209-42DC8F1CE287',
    name: 'star-plus',
    codepoint: 'F1563',
    deprecated: 'FALSE',
  },
  {
    id: '0CBFD9AD-12B1-4AAF-A9CC-9CA46FEB47FD',
    name: 'star-plus-outline',
    codepoint: 'F1567',
    deprecated: 'FALSE',
  },
  {
    id: '49254980-CD72-42B5-A87B-F24EFC2E4EA5',
    name: 'star-remove',
    codepoint: 'F1565',
    deprecated: 'FALSE',
  },
  {
    id: '40769B91-9676-413A-8D6D-9D21132637E2',
    name: 'star-remove-outline',
    codepoint: 'F1569',
    deprecated: 'FALSE',
  },
  {
    id: '30D8C8F6-3BDE-40B8-A015-6E607C370360',
    name: 'star-settings',
    codepoint: 'F166A',
    deprecated: 'FALSE',
  },
  {
    id: 'AA6C9E76-AC9C-4373-8AC4-F5C706741FC9',
    name: 'star-settings-outline',
    codepoint: 'F166B',
    deprecated: 'FALSE',
  },
  {
    id: '18267D80-A569-4013-84F4-7024F3990F47',
    name: 'star-shooting',
    codepoint: 'F1741',
    deprecated: 'FALSE',
  },
  {
    id: '27A6F1B6-E095-494B-8CC7-660695DC6E70',
    name: 'star-shooting-outline',
    codepoint: 'F1742',
    deprecated: 'FALSE',
  },
  {
    id: '815B35DE-0C4D-46AD-90EC-2AFEE0A5FC17',
    name: 'star-three-points',
    codepoint: 'F0AE4',
    deprecated: 'FALSE',
  },
  {
    id: '3BA12DC6-1D79-4B2A-BCF2-52FAF2160E23',
    name: 'star-three-points-outline',
    codepoint: 'F0AE5',
    deprecated: 'FALSE',
  },
  {
    id: '77FB59F8-81D3-4919-BBEE-3B897EC24C4E',
    name: 'state-machine',
    codepoint: 'F11EF',
    deprecated: 'FALSE',
  },
  {
    id: 'FBD721A3-7CD8-4769-902C-2B434CA107EE',
    name: 'steam',
    codepoint: 'F04D3',
    deprecated: 'TRUE',
  },
  {
    id: 'A2FE9DC5-BC17-4265-9D2C-4CA20135C4BB',
    name: 'steering',
    codepoint: 'F04D4',
    deprecated: 'FALSE',
  },
  {
    id: '1489D950-AD3A-47BD-A0CD-2E0A33943F54',
    name: 'steering-off',
    codepoint: 'F090E',
    deprecated: 'FALSE',
  },
  {
    id: '3056B0E6-E30C-4F6D-8647-AC5559FD7DCF',
    name: 'step-backward',
    codepoint: 'F04D5',
    deprecated: 'FALSE',
  },
  {
    id: '9815CAC2-7390-4AE1-AE72-2B49546987EC',
    name: 'step-backward-2',
    codepoint: 'F04D6',
    deprecated: 'FALSE',
  },
  {
    id: '6773EDAB-CBAA-4C56-80D6-E029EB45115E',
    name: 'step-forward',
    codepoint: 'F04D7',
    deprecated: 'FALSE',
  },
  {
    id: '890D584F-45C1-4D2C-96C0-373465DB6477',
    name: 'step-forward-2',
    codepoint: 'F04D8',
    deprecated: 'FALSE',
  },
  {
    id: '641202BA-294A-4A0F-851D-FB83FEC9D7EE',
    name: 'stethoscope',
    codepoint: 'F04D9',
    deprecated: 'FALSE',
  },
  {
    id: 'D2115A9A-4F13-4F9E-888D-3E8CE835A7C3',
    name: 'sticker',
    codepoint: 'F1364',
    deprecated: 'FALSE',
  },
  {
    id: '6E585638-20B9-4347-B991-62027390FCE4',
    name: 'sticker-alert',
    codepoint: 'F1365',
    deprecated: 'FALSE',
  },
  {
    id: '795CD51D-0887-4AC1-B7C1-137248AE0EB3',
    name: 'sticker-alert-outline',
    codepoint: 'F1366',
    deprecated: 'FALSE',
  },
  {
    id: 'A972E9E6-C525-4162-96DA-6A66951A2547',
    name: 'sticker-check',
    codepoint: 'F1367',
    deprecated: 'FALSE',
  },
  {
    id: '4239323B-AA14-4177-8F94-AB7F1577D111',
    name: 'sticker-check-outline',
    codepoint: 'F1368',
    deprecated: 'FALSE',
  },
  {
    id: 'F4C92E8A-A238-4F7C-B2FC-CBA914C5CE04',
    name: 'sticker-circle-outline',
    codepoint: 'F05D0',
    deprecated: 'FALSE',
  },
  {
    id: '9E28CC83-5155-4620-80C1-62875D117A79',
    name: 'sticker-emoji',
    codepoint: 'F0785',
    deprecated: 'FALSE',
  },
  {
    id: 'D0DB0495-F23A-4DF1-AE7B-704259C648F1',
    name: 'sticker-minus',
    codepoint: 'F1369',
    deprecated: 'FALSE',
  },
  {
    id: 'F8C4F362-C8D2-40FD-A631-B79E0341572C',
    name: 'sticker-minus-outline',
    codepoint: 'F136A',
    deprecated: 'FALSE',
  },
  {
    id: '6F7361D9-E44F-4BBC-B5E6-D6862467A849',
    name: 'sticker-outline',
    codepoint: 'F136B',
    deprecated: 'FALSE',
  },
  {
    id: '48F11F1C-7501-4EDB-B4C9-FEA63EF1C090',
    name: 'sticker-plus',
    codepoint: 'F136C',
    deprecated: 'FALSE',
  },
  {
    id: 'A28B1EF0-2538-4104-980A-D6F044F461AF',
    name: 'sticker-plus-outline',
    codepoint: 'F136D',
    deprecated: 'FALSE',
  },
  {
    id: '27A4E71C-CF92-4AA2-9B1B-4CC6FC06B58C',
    name: 'sticker-remove',
    codepoint: 'F136E',
    deprecated: 'FALSE',
  },
  {
    id: 'CA6D437A-FDC6-48F6-9414-A5B7832053F1',
    name: 'sticker-remove-outline',
    codepoint: 'F136F',
    deprecated: 'FALSE',
  },
  {
    id: '9BEC4C74-48A6-4A63-94E5-2F5FF0C06173',
    name: 'sticker-text',
    codepoint: 'F178E',
    deprecated: 'FALSE',
  },
  {
    id: '7AFB88BB-BCCF-4A79-AFC1-92BEB8E2EDBA',
    name: 'sticker-text-outline',
    codepoint: 'F178F',
    deprecated: 'FALSE',
  },
  {
    id: '3C90040A-0B3F-4849-B21D-E16240D7A699',
    name: 'stocking',
    codepoint: 'F04DA',
    deprecated: 'FALSE',
  },
  {
    id: '675A613A-F713-458A-970C-86883FF6C2B0',
    name: 'stomach',
    codepoint: 'F1093',
    deprecated: 'FALSE',
  },
  {
    id: '411BA388-1AF1-4197-AD0A-CF2313071836',
    name: 'stool',
    codepoint: 'F195D',
    deprecated: 'FALSE',
  },
  {
    id: '5237A946-C396-4C75-B369-751CD362AB5E',
    name: 'stool-outline',
    codepoint: 'F195E',
    deprecated: 'FALSE',
  },
  {
    id: 'F1057179-EA45-4D87-A14D-D05442C06503',
    name: 'stop',
    codepoint: 'F04DB',
    deprecated: 'FALSE',
  },
  {
    id: 'EC57EC60-1C8E-45CE-BF4A-D019AFD1F148',
    name: 'stop-circle',
    codepoint: 'F0666',
    deprecated: 'FALSE',
  },
  {
    id: 'B343DEEB-A0ED-41BF-8C86-37D04A1040A6',
    name: 'stop-circle-outline',
    codepoint: 'F0667',
    deprecated: 'FALSE',
  },
  {
    id: '2C19B9EA-AB7C-401A-B84F-A0DCD2336BBE',
    name: 'storage-tank',
    codepoint: 'F1A75',
    deprecated: 'FALSE',
  },
  {
    id: '6140C9BE-C66B-4ADC-957F-74A59541EA1A',
    name: 'storage-tank-outline',
    codepoint: 'F1A76',
    deprecated: 'FALSE',
  },
  {
    id: '3C3AEDE0-B6DA-4CB9-A686-869634678D5D',
    name: 'store',
    codepoint: 'F04DC',
    deprecated: 'FALSE',
  },
  {
    id: '417393DA-3C7F-4BF2-9283-AB1429FFA068',
    name: 'store-24-hour',
    codepoint: 'F04DD',
    deprecated: 'FALSE',
  },
  {
    id: '84CE3480-8C26-4B78-9524-CB8E6AA23F1D',
    name: 'store-alert',
    codepoint: 'F18C1',
    deprecated: 'FALSE',
  },
  {
    id: '3D34BFA8-56E0-4FDB-A8CF-7C19AFE14277',
    name: 'store-alert-outline',
    codepoint: 'F18C2',
    deprecated: 'FALSE',
  },
  {
    id: '37B5401A-4A00-47AF-AF1D-678AB4C502FD',
    name: 'store-check',
    codepoint: 'F18C3',
    deprecated: 'FALSE',
  },
  {
    id: '35E8862F-A95A-4B1E-AD0A-FAD4C408D4F6',
    name: 'store-check-outline',
    codepoint: 'F18C4',
    deprecated: 'FALSE',
  },
  {
    id: 'F1AABCBC-7F1F-4803-B2B9-CC5E8EF11FEE',
    name: 'store-clock',
    codepoint: 'F18C5',
    deprecated: 'FALSE',
  },
  {
    id: '78BFCE81-D4A5-48E9-812D-82417EE9CDC9',
    name: 'store-clock-outline',
    codepoint: 'F18C6',
    deprecated: 'FALSE',
  },
  {
    id: '0D8DEFE4-8A1E-4106-96FA-4663891F40FD',
    name: 'store-cog',
    codepoint: 'F18C7',
    deprecated: 'FALSE',
  },
  {
    id: 'E6D19187-D3DA-42DE-B61F-AF703112D8E3',
    name: 'store-cog-outline',
    codepoint: 'F18C8',
    deprecated: 'FALSE',
  },
  {
    id: '738C22C4-6605-437A-821A-10CF8E151422',
    name: 'store-edit',
    codepoint: 'F18C9',
    deprecated: 'FALSE',
  },
  {
    id: '02C64DBE-89C9-4190-80FF-1EB52327BFB5',
    name: 'store-edit-outline',
    codepoint: 'F18CA',
    deprecated: 'FALSE',
  },
  {
    id: '9F2B1901-978F-4186-93BA-2B2871A1858A',
    name: 'store-marker',
    codepoint: 'F18CB',
    deprecated: 'FALSE',
  },
  {
    id: 'EA9439C2-511C-4A27-BABD-D0C1F8E824A0',
    name: 'store-marker-outline',
    codepoint: 'F18CC',
    deprecated: 'FALSE',
  },
  {
    id: 'D52803B6-8580-4C06-AA9F-96B4748286DB',
    name: 'store-minus',
    codepoint: 'F165E',
    deprecated: 'FALSE',
  },
  {
    id: 'A5A36489-1F54-4A27-AF99-A0D77723FD1F',
    name: 'store-minus-outline',
    codepoint: 'F18CD',
    deprecated: 'FALSE',
  },
  {
    id: '93E71904-C645-4682-B4C9-B61124B5C468',
    name: 'store-off',
    codepoint: 'F18CE',
    deprecated: 'FALSE',
  },
  {
    id: '2681BADF-4EE8-4290-A8C9-1BE86077BEC1',
    name: 'store-off-outline',
    codepoint: 'F18CF',
    deprecated: 'FALSE',
  },
  {
    id: '6BF9FB77-DF50-40B5-9A93-43E2A22E8633',
    name: 'store-outline',
    codepoint: 'F1361',
    deprecated: 'FALSE',
  },
  {
    id: '00DB5451-A5BF-4F9C-AC74-041916EACCB6',
    name: 'store-plus',
    codepoint: 'F165F',
    deprecated: 'FALSE',
  },
  {
    id: '585744D8-92B8-4874-81F5-70F659DA0DFE',
    name: 'store-plus-outline',
    codepoint: 'F18D0',
    deprecated: 'FALSE',
  },
  {
    id: 'FB5C0EA4-DAA2-4415-856F-2A4E6FA78BA5',
    name: 'store-remove',
    codepoint: 'F1660',
    deprecated: 'FALSE',
  },
  {
    id: '2EDD35EF-2F8B-4EA9-BED4-D211B29C0012',
    name: 'store-remove-outline',
    codepoint: 'F18D1',
    deprecated: 'FALSE',
  },
  {
    id: 'C631A061-FDAF-4686-9136-FD37C05FA12B',
    name: 'store-search',
    codepoint: 'F18D2',
    deprecated: 'FALSE',
  },
  {
    id: '1FD8091B-5C35-4157-89E0-7211C77B1E19',
    name: 'store-search-outline',
    codepoint: 'F18D3',
    deprecated: 'FALSE',
  },
  {
    id: 'A8AE1FBA-2DA6-47AA-9B12-910E7FCD8D43',
    name: 'store-settings',
    codepoint: 'F18D4',
    deprecated: 'FALSE',
  },
  {
    id: '3FC392DC-44A8-475E-8082-7DBF80A61519',
    name: 'store-settings-outline',
    codepoint: 'F18D5',
    deprecated: 'FALSE',
  },
  {
    id: '8ABE8FB2-F704-4095-9E65-F614F86FCE99',
    name: 'storefront',
    codepoint: 'F07C7',
    deprecated: 'FALSE',
  },
  {
    id: '9FA49C4A-746E-4055-A94C-DBBBEB416FB0',
    name: 'storefront-check',
    codepoint: 'F1B7D',
    deprecated: 'FALSE',
  },
  {
    id: '9E46C613-2CD0-4110-8F13-07855DFDBC5B',
    name: 'storefront-check-outline',
    codepoint: 'F1B7E',
    deprecated: 'FALSE',
  },
  {
    id: 'E1FEA0E4-C612-47D1-9776-0692900AFD9A',
    name: 'storefront-edit',
    codepoint: 'F1B7F',
    deprecated: 'FALSE',
  },
  {
    id: '1070748B-46F5-4657-99E2-8B1BF87FC5E6',
    name: 'storefront-edit-outline',
    codepoint: 'F1B80',
    deprecated: 'FALSE',
  },
  {
    id: '9D9F2374-F718-4122-84E4-AD90697F9833',
    name: 'storefront-minus',
    codepoint: 'F1B83',
    deprecated: 'FALSE',
  },
  {
    id: '4F0A88BC-20A5-44D7-898C-258941B91CA0',
    name: 'storefront-minus-outline',
    codepoint: 'F1B84',
    deprecated: 'FALSE',
  },
  {
    id: 'CFC9D5C9-A60F-48D0-880E-71F2A06BB94D',
    name: 'storefront-outline',
    codepoint: 'F10C1',
    deprecated: 'FALSE',
  },
  {
    id: '7DBC0D08-5218-4DA7-970C-3A4974124F98',
    name: 'storefront-plus',
    codepoint: 'F1B81',
    deprecated: 'FALSE',
  },
  {
    id: 'A9D0C9F5-059E-4EF1-8927-0203B5CE4AE8',
    name: 'storefront-plus-outline',
    codepoint: 'F1B82',
    deprecated: 'FALSE',
  },
  {
    id: '94EF8FB9-635E-4293-946B-C544594A16B0',
    name: 'storefront-remove',
    codepoint: 'F1B85',
    deprecated: 'FALSE',
  },
  {
    id: '71DE2725-2E14-4A65-ADC8-B7DF63038DA0',
    name: 'storefront-remove-outline',
    codepoint: 'F1B86',
    deprecated: 'FALSE',
  },
  {
    id: '18EF6E1E-8EF9-4A62-AAB9-6E8BE591408A',
    name: 'stove',
    codepoint: 'F04DE',
    deprecated: 'FALSE',
  },
  {
    id: '854C16E8-58EA-49E3-B1AE-7EC84B313005',
    name: 'strategy',
    codepoint: 'F11D6',
    deprecated: 'FALSE',
  },
  {
    id: '8E85E624-D1D8-4BA3-A99C-3067863D355C',
    name: 'stretch-to-page',
    codepoint: 'F0F2B',
    deprecated: 'FALSE',
  },
  {
    id: '7F50D690-0790-42F3-B9C3-B0AB9338DAC7',
    name: 'stretch-to-page-outline',
    codepoint: 'F0F2C',
    deprecated: 'FALSE',
  },
  {
    id: '6CCDB34F-9B02-4712-B615-A7E1056B534D',
    name: 'string-lights',
    codepoint: 'F12BA',
    deprecated: 'FALSE',
  },
  {
    id: 'F1B6F6EB-6D20-4C27-A631-8FAF759D183A',
    name: 'string-lights-off',
    codepoint: 'F12BB',
    deprecated: 'FALSE',
  },
  {
    id: 'FC46F7BD-E8E2-452C-868C-E168E13B278D',
    name: 'subdirectory-arrow-left',
    codepoint: 'F060C',
    deprecated: 'FALSE',
  },
  {
    id: 'AFD661FE-31E3-4BB5-8977-10113C7AC3F4',
    name: 'subdirectory-arrow-right',
    codepoint: 'F060D',
    deprecated: 'FALSE',
  },
  {
    id: 'C6D1D0D8-7FEF-4F4B-B0E8-1B87B9413209',
    name: 'submarine',
    codepoint: 'F156C',
    deprecated: 'FALSE',
  },
  {
    id: '9808EEC5-35FB-45B1-AFCB-065E9CF469E4',
    name: 'subtitles',
    codepoint: 'F0A16',
    deprecated: 'FALSE',
  },
  {
    id: '451E9683-F30C-4B0A-95EE-5AD92B012F26',
    name: 'subtitles-outline',
    codepoint: 'F0A17',
    deprecated: 'FALSE',
  },
  {
    id: '769FB684-BA81-4E2A-B623-4C8B6F3DCD2C',
    name: 'subway',
    codepoint: 'F06AC',
    deprecated: 'FALSE',
  },
  {
    id: '486507F3-9E59-4037-B0EA-8EE514AFFABB',
    name: 'subway-alert-variant',
    codepoint: 'F0D9D',
    deprecated: 'FALSE',
  },
  {
    id: 'EBD55581-BE34-4D0D-A151-974FD5FD7D92',
    name: 'subway-variant',
    codepoint: 'F04DF',
    deprecated: 'FALSE',
  },
  {
    id: '052D93D8-A1F1-450C-99F3-AEEE07D77CF4',
    name: 'summit',
    codepoint: 'F0786',
    deprecated: 'FALSE',
  },
  {
    id: '18B8F0B3-FEE0-4DF6-A6FB-701BCAB19F3A',
    name: 'sun-angle',
    codepoint: 'F1B27',
    deprecated: 'FALSE',
  },
  {
    id: 'EFBF30D4-A767-490C-883D-9C94E0789C0D',
    name: 'sun-angle-outline',
    codepoint: 'F1B28',
    deprecated: 'FALSE',
  },
  {
    id: 'B467AA69-1E9D-444C-B6E4-764F1D180140',
    name: 'sun-clock',
    codepoint: 'F1A77',
    deprecated: 'FALSE',
  },
  {
    id: '39AB9880-94EC-44BF-96FC-D5ACA1AA3356',
    name: 'sun-clock-outline',
    codepoint: 'F1A78',
    deprecated: 'FALSE',
  },
  {
    id: '1F513D40-444E-475D-9C1F-E5A95CE61A02',
    name: 'sun-compass',
    codepoint: 'F19A5',
    deprecated: 'FALSE',
  },
  {
    id: '2294E545-CE17-4605-8DBD-FFAE9CFDFED6',
    name: 'sun-snowflake',
    codepoint: 'F1796',
    deprecated: 'FALSE',
  },
  {
    id: '57D0B351-8C4C-4F9C-9D62-23DE9E00C9AA',
    name: 'sun-snowflake-variant',
    codepoint: 'F1A79',
    deprecated: 'FALSE',
  },
  {
    id: 'A915898D-3218-4A0E-9732-CEB0701DADA1',
    name: 'sun-thermometer',
    codepoint: 'F18D6',
    deprecated: 'FALSE',
  },
  {
    id: 'A92400D5-89B6-46B0-9D55-4980BE45C51D',
    name: 'sun-thermometer-outline',
    codepoint: 'F18D7',
    deprecated: 'FALSE',
  },
  {
    id: '1A5DE4DC-5B3B-4378-ADB2-5D51AA64896D',
    name: 'sun-wireless',
    codepoint: 'F17FE',
    deprecated: 'FALSE',
  },
  {
    id: '2CFA10C3-9284-4120-B104-D196DBF0BF07',
    name: 'sun-wireless-outline',
    codepoint: 'F17FF',
    deprecated: 'FALSE',
  },
  {
    id: 'E52FC33A-09BD-4B34-AF94-92C891020CDC',
    name: 'sunglasses',
    codepoint: 'F04E0',
    deprecated: 'FALSE',
  },
  {
    id: '13371A33-E911-4D02-B921-CECC9D1BDB50',
    name: 'surfing',
    codepoint: 'F1746',
    deprecated: 'FALSE',
  },
  {
    id: 'AB38BBD9-58A6-4D21-8A76-8940B7717550',
    name: 'surround-sound',
    codepoint: 'F05C5',
    deprecated: 'FALSE',
  },
  {
    id: '13009B66-7459-4C86-8EB0-3CAED977BB98',
    name: 'surround-sound-2-0',
    codepoint: 'F07F0',
    deprecated: 'FALSE',
  },
  {
    id: '901A06AA-7C77-43B3-90CD-10B329C42DB7',
    name: 'surround-sound-2-1',
    codepoint: 'F1729',
    deprecated: 'FALSE',
  },
  {
    id: '4B744BE0-A739-44FF-ADA6-CFC9BC520B1D',
    name: 'surround-sound-3-1',
    codepoint: 'F07F1',
    deprecated: 'FALSE',
  },
  {
    id: 'CFD48DE8-0DA8-455C-9C4C-337EDC54D7C0',
    name: 'surround-sound-5-1',
    codepoint: 'F07F2',
    deprecated: 'FALSE',
  },
  {
    id: '0CA5BC19-D66A-4126-B2DB-A90546826760',
    name: 'surround-sound-5-1-2',
    codepoint: 'F172A',
    deprecated: 'FALSE',
  },
  {
    id: 'BC85CD47-E52C-4A7D-8E05-DD45596C5822',
    name: 'surround-sound-7-1',
    codepoint: 'F07F3',
    deprecated: 'FALSE',
  },
  {
    id: '5E34C73B-E200-4BD3-A10E-A00D1D47BFF4',
    name: 'svg',
    codepoint: 'F0721',
    deprecated: 'TRUE',
  },
  {
    id: 'D1E543C5-6ECA-43AA-B04D-438319BC5562',
    name: 'swap-horizontal',
    codepoint: 'F04E1',
    deprecated: 'FALSE',
  },
  {
    id: '3082F9ED-7ADE-491E-9FA5-39BC937D1F95',
    name: 'swap-horizontal-bold',
    codepoint: 'F0BCD',
    deprecated: 'FALSE',
  },
  {
    id: '11867345-3F46-40E5-91A4-4EBBE393226D',
    name: 'swap-horizontal-circle',
    codepoint: 'F0FE1',
    deprecated: 'FALSE',
  },
  {
    id: '4A41DEBC-9D40-4C27-A48E-EDF8E64ADBC2',
    name: 'swap-horizontal-circle-outline',
    codepoint: 'F0FE2',
    deprecated: 'FALSE',
  },
  {
    id: '23A48C18-9950-436D-9948-29EE7EE13054',
    name: 'swap-horizontal-variant',
    codepoint: 'F08C1',
    deprecated: 'FALSE',
  },
  {
    id: 'DA7FB76D-58FA-4AED-9DF9-033E994AE000',
    name: 'swap-vertical',
    codepoint: 'F04E2',
    deprecated: 'FALSE',
  },
  {
    id: '0903A829-EB64-4B39-B066-FECF7908DA8A',
    name: 'swap-vertical-bold',
    codepoint: 'F0BCE',
    deprecated: 'FALSE',
  },
  {
    id: 'F47E2ADA-9203-4D5A-9537-D0C93E45F1B3',
    name: 'swap-vertical-circle',
    codepoint: 'F0FE3',
    deprecated: 'FALSE',
  },
  {
    id: '3B14BF34-4B82-4CAD-8F11-55E4013F7734',
    name: 'swap-vertical-circle-outline',
    codepoint: 'F0FE4',
    deprecated: 'FALSE',
  },
  {
    id: 'FC1C62DB-A06D-48F8-962F-F60FE41E4F65',
    name: 'swap-vertical-variant',
    codepoint: 'F08C2',
    deprecated: 'FALSE',
  },
  {
    id: '668BF75C-2B3B-46CB-8731-AE6BF10244BD',
    name: 'swim',
    codepoint: 'F04E3',
    deprecated: 'FALSE',
  },
  {
    id: 'A2ED8CF0-EAE1-4B83-9469-448260288DDE',
    name: 'switch',
    codepoint: 'F04E4',
    deprecated: 'FALSE',
  },
  {
    id: 'D73618B2-062C-40B2-BF8D-43DC48809F9B',
    name: 'sword',
    codepoint: 'F04E5',
    deprecated: 'FALSE',
  },
  {
    id: '37B336A5-5827-4929-93B5-C12373966731',
    name: 'sword-cross',
    codepoint: 'F0787',
    deprecated: 'FALSE',
  },
  {
    id: '9201098E-A360-4BF2-8EC7-683EB2AA7FEF',
    name: 'syllabary-hangul',
    codepoint: 'F1333',
    deprecated: 'FALSE',
  },
  {
    id: '8B8AF349-F9B5-483B-90CC-387F3791AF69',
    name: 'syllabary-hiragana',
    codepoint: 'F1334',
    deprecated: 'FALSE',
  },
  {
    id: '220577A0-1D92-46EA-8116-DF964F6C57F0',
    name: 'syllabary-katakana',
    codepoint: 'F1335',
    deprecated: 'FALSE',
  },
  {
    id: 'BEDFC0D3-5AC2-46E4-BD69-07048CC47710',
    name: 'syllabary-katakana-halfwidth',
    codepoint: 'F1336',
    deprecated: 'FALSE',
  },
  {
    id: '6A604429-C9A6-45F3-8042-D29AB7828789',
    name: 'symbol',
    codepoint: 'F1501',
    deprecated: 'FALSE',
  },
  {
    id: '2BCCEE64-583D-4EC7-9BBA-72FF58354181',
    name: 'symfony',
    codepoint: 'F0AE6',
    deprecated: 'TRUE',
  },
  {
    id: '9013848A-21D4-4AF4-BA8A-6CF76128FE17',
    name: 'synagogue',
    codepoint: 'F1B04',
    deprecated: 'FALSE',
  },
  {
    id: 'D070E9A9-6327-4F19-ADBC-A24A15398C7F',
    name: 'synagogue-outline',
    codepoint: 'F1B05',
    deprecated: 'FALSE',
  },
  {
    id: 'E8CE455E-8C08-48F4-B989-8D91E185E8AE',
    name: 'sync',
    codepoint: 'F04E6',
    deprecated: 'FALSE',
  },
  {
    id: '66A07118-4C78-44D0-8B73-930144C74BD1',
    name: 'sync-alert',
    codepoint: 'F04E7',
    deprecated: 'FALSE',
  },
  {
    id: '26250B6D-8879-4DAA-8DFA-A04738E855FE',
    name: 'sync-circle',
    codepoint: 'F1378',
    deprecated: 'FALSE',
  },
  {
    id: '538457F5-44DB-404B-92FE-5FB8597B258E',
    name: 'sync-off',
    codepoint: 'F04E8',
    deprecated: 'FALSE',
  },
  {
    id: '76D6AAA9-7D7F-4C7A-818E-D9947744A23C',
    name: 'tab',
    codepoint: 'F04E9',
    deprecated: 'FALSE',
  },
  {
    id: 'BA5FCCBE-ED06-4819-B2F3-532034AB09BB',
    name: 'tab-minus',
    codepoint: 'F0B4B',
    deprecated: 'FALSE',
  },
  {
    id: '9E23B225-E972-4369-A94A-C2AAF4C93870',
    name: 'tab-plus',
    codepoint: 'F075C',
    deprecated: 'FALSE',
  },
  {
    id: 'D851AFED-34E5-4166-9923-CC4A5D6A1A44',
    name: 'tab-remove',
    codepoint: 'F0B4C',
    deprecated: 'FALSE',
  },
  {
    id: 'BF6060AC-37F8-4236-B9D1-0C37A9AF09E9',
    name: 'tab-search',
    codepoint: 'F199E',
    deprecated: 'FALSE',
  },
  {
    id: '9A9059F0-CADA-47EF-82DC-4B1FB2B9E4C5',
    name: 'tab-unselected',
    codepoint: 'F04EA',
    deprecated: 'FALSE',
  },
  {
    id: '984D8348-AB61-4DD8-838C-3F2542577F73',
    name: 'table',
    codepoint: 'F04EB',
    deprecated: 'FALSE',
  },
  {
    id: '4E3E7C78-5217-4B8A-8B95-9DE8433CFADA',
    name: 'table-account',
    codepoint: 'F13B9',
    deprecated: 'FALSE',
  },
  {
    id: '5957DCA4-9A4D-4BC1-98EC-58065564EBFF',
    name: 'table-alert',
    codepoint: 'F13BA',
    deprecated: 'FALSE',
  },
  {
    id: '846F0539-E30D-40E8-BC00-3F10A0C0321E',
    name: 'table-arrow-down',
    codepoint: 'F13BB',
    deprecated: 'FALSE',
  },
  {
    id: '44768ACF-C1A3-477D-91BC-74DA3B0A746E',
    name: 'table-arrow-left',
    codepoint: 'F13BC',
    deprecated: 'FALSE',
  },
  {
    id: 'A4A68FD7-82B8-4BE8-8110-DA54D1A888D4',
    name: 'table-arrow-right',
    codepoint: 'F13BD',
    deprecated: 'FALSE',
  },
  {
    id: '2A9EE017-5EDD-4A0B-A531-E552D4C8AB66',
    name: 'table-arrow-up',
    codepoint: 'F13BE',
    deprecated: 'FALSE',
  },
  {
    id: '4AB13D38-7B5C-49E6-B41C-C48C0F8E7DA3',
    name: 'table-border',
    codepoint: 'F0A18',
    deprecated: 'FALSE',
  },
  {
    id: '989D1B4B-5D28-4335-B410-8DAF917F788F',
    name: 'table-cancel',
    codepoint: 'F13BF',
    deprecated: 'FALSE',
  },
  {
    id: '6EB52FC2-32D9-427D-80DE-32C60C8F78AD',
    name: 'table-chair',
    codepoint: 'F1061',
    deprecated: 'FALSE',
  },
  {
    id: '15FE548D-D08F-44C4-AF25-B853452E1AFE',
    name: 'table-check',
    codepoint: 'F13C0',
    deprecated: 'FALSE',
  },
  {
    id: '8C3DCF5F-7E50-48A6-A596-0BA2D299B37F',
    name: 'table-clock',
    codepoint: 'F13C1',
    deprecated: 'FALSE',
  },
  {
    id: '540E0DD7-BA28-46B1-A0A7-2A0820E73432',
    name: 'table-cog',
    codepoint: 'F13C2',
    deprecated: 'FALSE',
  },
  {
    id: 'D45D7513-AA4C-43CA-8435-CA6AD2B7F868',
    name: 'table-column',
    codepoint: 'F0835',
    deprecated: 'FALSE',
  },
  {
    id: '0EB91CE4-3223-4799-9BB7-3BA0C923BC40',
    name: 'table-column-plus-after',
    codepoint: 'F04EC',
    deprecated: 'FALSE',
  },
  {
    id: '67B4875F-B08F-4006-AFE1-9C243F8B873A',
    name: 'table-column-plus-before',
    codepoint: 'F04ED',
    deprecated: 'FALSE',
  },
  {
    id: '455B9D6F-1842-434F-8C31-16F68A2C1BA9',
    name: 'table-column-remove',
    codepoint: 'F04EE',
    deprecated: 'FALSE',
  },
  {
    id: '2343FF2B-E5D3-4BEC-90EA-FB3F5291D4B0',
    name: 'table-column-width',
    codepoint: 'F04EF',
    deprecated: 'FALSE',
  },
  {
    id: 'DDC0FFBC-73D2-49E8-8B8C-22D65A5719A6',
    name: 'table-edit',
    codepoint: 'F04F0',
    deprecated: 'FALSE',
  },
  {
    id: '6F81B89D-EBF1-41BF-9619-90E148AD9951',
    name: 'table-eye',
    codepoint: 'F1094',
    deprecated: 'FALSE',
  },
  {
    id: '2F3C45C9-277B-4751-8F5C-4356739AC3A4',
    name: 'table-eye-off',
    codepoint: 'F13C3',
    deprecated: 'FALSE',
  },
  {
    id: '1CFB10BF-E5C8-4401-B491-3BC830082D6E',
    name: 'table-filter',
    codepoint: 'F1B8C',
    deprecated: 'FALSE',
  },
  {
    id: '9A7A9F69-9BC4-4619-981D-DF8423A22FF2',
    name: 'table-furniture',
    codepoint: 'F05BC',
    deprecated: 'FALSE',
  },
  {
    id: '9AB8E55A-A51C-4384-A6C6-1F88BFBBD63B',
    name: 'table-headers-eye',
    codepoint: 'F121D',
    deprecated: 'FALSE',
  },
  {
    id: '2F8C23A5-717B-4483-9C22-A8C5D4F7C31A',
    name: 'table-headers-eye-off',
    codepoint: 'F121E',
    deprecated: 'FALSE',
  },
  {
    id: '550B70E0-414F-44F5-A09F-DF418ABB7D26',
    name: 'table-heart',
    codepoint: 'F13C4',
    deprecated: 'FALSE',
  },
  {
    id: '8B04C6AF-6886-4693-8806-6C4BC8D60E9A',
    name: 'table-key',
    codepoint: 'F13C5',
    deprecated: 'FALSE',
  },
  {
    id: '99BE0AB4-A123-4745-839F-DC355DA8C3D4',
    name: 'table-large',
    codepoint: 'F04F1',
    deprecated: 'FALSE',
  },
  {
    id: '3C206937-03CF-4690-B022-30099AEAE0F3',
    name: 'table-large-plus',
    codepoint: 'F0F87',
    deprecated: 'FALSE',
  },
  {
    id: 'E6F6EF7E-C78C-4670-9E96-43AD92F24429',
    name: 'table-large-remove',
    codepoint: 'F0F88',
    deprecated: 'FALSE',
  },
  {
    id: '370E7D91-6ACA-464F-A30C-DCB35B8DFC55',
    name: 'table-lock',
    codepoint: 'F13C6',
    deprecated: 'FALSE',
  },
  {
    id: 'BE891E33-2018-4619-BF4C-C006B4063785',
    name: 'table-merge-cells',
    codepoint: 'F09A6',
    deprecated: 'FALSE',
  },
  {
    id: 'C40DA2CA-4FCA-4144-AD38-0DD457D6D6CF',
    name: 'table-minus',
    codepoint: 'F13C7',
    deprecated: 'FALSE',
  },
  {
    id: 'FB123A84-A0AE-41F6-8C61-B86E93EEA08C',
    name: 'table-multiple',
    codepoint: 'F13C8',
    deprecated: 'FALSE',
  },
  {
    id: '94745B4E-14D8-4B8C-B8C4-E25D1A123B27',
    name: 'table-network',
    codepoint: 'F13C9',
    deprecated: 'FALSE',
  },
  {
    id: '70A0953D-9FEF-4858-8ACB-A90C5613360A',
    name: 'table-of-contents',
    codepoint: 'F0836',
    deprecated: 'FALSE',
  },
  {
    id: 'AF58D08D-14CD-41F6-9CF1-53D002D7EEB1',
    name: 'table-off',
    codepoint: 'F13CA',
    deprecated: 'FALSE',
  },
  {
    id: '68ABC903-3D76-4C38-8165-4DB5F0353A20',
    name: 'table-picnic',
    codepoint: 'F1743',
    deprecated: 'FALSE',
  },
  {
    id: '95F556B1-87B0-4219-8E96-B5AF5D652DF4',
    name: 'table-pivot',
    codepoint: 'F183C',
    deprecated: 'FALSE',
  },
  {
    id: '4547E3CF-042C-431F-8B99-4DBE22F1ADEC',
    name: 'table-plus',
    codepoint: 'F0A75',
    deprecated: 'FALSE',
  },
  {
    id: 'DD9EA6CC-9992-418C-8664-2254FC64A41F',
    name: 'table-question',
    codepoint: 'F1B21',
    deprecated: 'FALSE',
  },
  {
    id: '202CC1A0-B339-493E-99B0-607A209E8A53',
    name: 'table-refresh',
    codepoint: 'F13A0',
    deprecated: 'FALSE',
  },
  {
    id: 'E04307B8-EA93-41C0-B7B8-6AAC6C9B580F',
    name: 'table-remove',
    codepoint: 'F0A76',
    deprecated: 'FALSE',
  },
  {
    id: '8EA23E11-D670-4D09-8AA6-7DAFBEAA54FD',
    name: 'table-row',
    codepoint: 'F0837',
    deprecated: 'FALSE',
  },
  {
    id: '5B9A6186-2C24-4D16-9FE4-F0E23C8E5B5F',
    name: 'table-row-height',
    codepoint: 'F04F2',
    deprecated: 'FALSE',
  },
  {
    id: '0B2B45F7-3597-4049-9643-9E972826F67C',
    name: 'table-row-plus-after',
    codepoint: 'F04F3',
    deprecated: 'FALSE',
  },
  {
    id: '784BF375-370F-4DAE-985D-7BEF2FDA6951',
    name: 'table-row-plus-before',
    codepoint: 'F04F4',
    deprecated: 'FALSE',
  },
  {
    id: '017D1949-234A-4C27-A653-FF0EAF33AD92',
    name: 'table-row-remove',
    codepoint: 'F04F5',
    deprecated: 'FALSE',
  },
  {
    id: '6053118D-7921-45FC-BC83-9F08BF2AE6DF',
    name: 'table-search',
    codepoint: 'F090F',
    deprecated: 'FALSE',
  },
  {
    id: 'B506843C-4832-4F30-8266-9B02632AB582',
    name: 'table-settings',
    codepoint: 'F0838',
    deprecated: 'FALSE',
  },
  {
    id: '6E502BA0-5D71-4607-BA94-CDEF31BBF07C',
    name: 'table-split-cell',
    codepoint: 'F142A',
    deprecated: 'FALSE',
  },
  {
    id: '646B67D0-16F5-429C-93ED-C57131031B63',
    name: 'table-star',
    codepoint: 'F13CB',
    deprecated: 'FALSE',
  },
  {
    id: 'DEC516BA-2102-4CE7-B77E-6D95A9A525EC',
    name: 'table-sync',
    codepoint: 'F13A1',
    deprecated: 'FALSE',
  },
  {
    id: '4E13AA68-FF88-415B-A813-A9D5023F71E3',
    name: 'table-tennis',
    codepoint: 'F0E68',
    deprecated: 'FALSE',
  },
  {
    id: '703B639C-E17D-40F1-8025-2B2950B1961D',
    name: 'tablet',
    codepoint: 'F04F6',
    deprecated: 'FALSE',
  },
  {
    id: '804E3CCD-99B5-4F54-898A-9477D69E6951',
    name: 'tablet-cellphone',
    codepoint: 'F09A7',
    deprecated: 'FALSE',
  },
  {
    id: '71DAA530-D02E-45D9-84AB-68CE1CD1E44E',
    name: 'tablet-dashboard',
    codepoint: 'F0ECE',
    deprecated: 'FALSE',
  },
  {
    id: '9E259FF6-513C-44B0-BB10-7F930DFA47DD',
    name: 'taco',
    codepoint: 'F0762',
    deprecated: 'FALSE',
  },
  {
    id: '0BE85AB2-0D51-411C-B119-789E9E317216',
    name: 'tag',
    codepoint: 'F04F9',
    deprecated: 'FALSE',
  },
  {
    id: '141B8C02-DA96-4054-AA99-C83CF2B10118',
    name: 'tag-arrow-down',
    codepoint: 'F172B',
    deprecated: 'FALSE',
  },
  {
    id: '8C304479-B37A-4837-8887-F4880359C9BB',
    name: 'tag-arrow-down-outline',
    codepoint: 'F172C',
    deprecated: 'FALSE',
  },
  {
    id: '56E3ECFD-CE76-4CE2-9075-7673ABEA78D1',
    name: 'tag-arrow-left',
    codepoint: 'F172D',
    deprecated: 'FALSE',
  },
  {
    id: 'BCD665AC-5B08-4E99-8BF4-7F43030447F6',
    name: 'tag-arrow-left-outline',
    codepoint: 'F172E',
    deprecated: 'FALSE',
  },
  {
    id: '98AEA4C4-D0E2-4B94-9EE3-47F97A7AFC0D',
    name: 'tag-arrow-right',
    codepoint: 'F172F',
    deprecated: 'FALSE',
  },
  {
    id: 'B9FED1D0-B219-4483-A30E-AA201561DCB4',
    name: 'tag-arrow-right-outline',
    codepoint: 'F1730',
    deprecated: 'FALSE',
  },
  {
    id: '5040B027-3283-413C-8424-E2E02108E69D',
    name: 'tag-arrow-up',
    codepoint: 'F1731',
    deprecated: 'FALSE',
  },
  {
    id: 'A632F2B8-4D1B-4F60-AB06-EB79D45E13A3',
    name: 'tag-arrow-up-outline',
    codepoint: 'F1732',
    deprecated: 'FALSE',
  },
  {
    id: 'A15E3DAA-A545-434C-A876-C141B28A074D',
    name: 'tag-check',
    codepoint: 'F1A7A',
    deprecated: 'FALSE',
  },
  {
    id: '1E060329-9C85-4470-8140-DD96F6877E76',
    name: 'tag-check-outline',
    codepoint: 'F1A7B',
    deprecated: 'FALSE',
  },
  {
    id: '5DCE0F4D-F740-4D9A-AC93-281D3477E9AF',
    name: 'tag-faces',
    codepoint: 'F04FA',
    deprecated: 'FALSE',
  },
  {
    id: '2D9E735C-EA23-4EB2-9AC4-C92D69218577',
    name: 'tag-heart',
    codepoint: 'F068B',
    deprecated: 'FALSE',
  },
  {
    id: '1A877919-79F5-4B22-A309-6681E10B4A06',
    name: 'tag-heart-outline',
    codepoint: 'F0BCF',
    deprecated: 'FALSE',
  },
  {
    id: '1F2E41E2-6509-4C1B-B95B-145C9A8375DA',
    name: 'tag-minus',
    codepoint: 'F0910',
    deprecated: 'FALSE',
  },
  {
    id: 'E8EF5D5C-B3EE-459C-9C6D-B6A045B8052C',
    name: 'tag-minus-outline',
    codepoint: 'F121F',
    deprecated: 'FALSE',
  },
  {
    id: '408AB5A3-F100-4175-96CD-8602BE101150',
    name: 'tag-multiple',
    codepoint: 'F04FB',
    deprecated: 'FALSE',
  },
  {
    id: '340D1192-13CE-4752-86C3-186FEA8DC315',
    name: 'tag-multiple-outline',
    codepoint: 'F12F7',
    deprecated: 'FALSE',
  },
  {
    id: 'A85425D5-61FC-46C8-B604-4105C407967B',
    name: 'tag-off',
    codepoint: 'F1220',
    deprecated: 'FALSE',
  },
  {
    id: 'E6157F0C-1143-4F4D-B308-CCA13CC24BFC',
    name: 'tag-off-outline',
    codepoint: 'F1221',
    deprecated: 'FALSE',
  },
  {
    id: '2022546E-45D4-4AC5-B24F-6D6AAEA0F452',
    name: 'tag-outline',
    codepoint: 'F04FC',
    deprecated: 'FALSE',
  },
  {
    id: '83C0C7C5-26FF-4EF7-A05B-B05472FC40FC',
    name: 'tag-plus',
    codepoint: 'F0722',
    deprecated: 'FALSE',
  },
  {
    id: '0BCB9F00-8D30-4DD7-85CB-31930035DF0E',
    name: 'tag-plus-outline',
    codepoint: 'F1222',
    deprecated: 'FALSE',
  },
  {
    id: 'E90C088F-8DFD-4B7D-962C-BE2DD9DB61BD',
    name: 'tag-remove',
    codepoint: 'F0723',
    deprecated: 'FALSE',
  },
  {
    id: 'DD1E4B8E-E8C2-4B72-A56D-F42B3259AD49',
    name: 'tag-remove-outline',
    codepoint: 'F1223',
    deprecated: 'FALSE',
  },
  {
    id: '2728D780-6100-4AD5-B5B3-67C64DAF983C',
    name: 'tag-search',
    codepoint: 'F1907',
    deprecated: 'FALSE',
  },
  {
    id: '4747C432-2B54-4686-8689-A8B931F417DE',
    name: 'tag-search-outline',
    codepoint: 'F1908',
    deprecated: 'FALSE',
  },
  {
    id: '92B97B44-052D-48B5-993F-1AB16F76DC65',
    name: 'tag-text',
    codepoint: 'F1224',
    deprecated: 'FALSE',
  },
  {
    id: '8ED927D3-84B9-43D1-82B4-3859653006D6',
    name: 'tag-text-outline',
    codepoint: 'F04FD',
    deprecated: 'FALSE',
  },
  {
    id: '7D58F2F1-A97E-4EBE-A0E6-E8F9999F1799',
    name: 'tailwind',
    codepoint: 'F13FF',
    deprecated: 'TRUE',
  },
  {
    id: '1F0D9425-0B92-46C6-AEBE-39FB5E062AA5',
    name: 'tally-mark-1',
    codepoint: 'F1ABC',
    deprecated: 'FALSE',
  },
  {
    id: '3324BD04-3F49-4759-9C4E-8C94F513F977',
    name: 'tally-mark-2',
    codepoint: 'F1ABD',
    deprecated: 'FALSE',
  },
  {
    id: '37C95AE2-2AB4-4800-9683-49806B29599A',
    name: 'tally-mark-3',
    codepoint: 'F1ABE',
    deprecated: 'FALSE',
  },
  {
    id: '85D71807-B7C3-48C8-8E8D-951AA54B68C7',
    name: 'tally-mark-4',
    codepoint: 'F1ABF',
    deprecated: 'FALSE',
  },
  {
    id: '0D7E150C-A65F-4A77-86A4-B0F5F2B60347',
    name: 'tally-mark-5',
    codepoint: 'F1AC0',
    deprecated: 'FALSE',
  },
  {
    id: 'A7147BE3-0B04-4B61-94F3-48B0D9C5551E',
    name: 'tangram',
    codepoint: 'F04F8',
    deprecated: 'FALSE',
  },
  {
    id: 'C673D464-FC7A-4574-8EE6-6CCF7E39902F',
    name: 'tank',
    codepoint: 'F0D3A',
    deprecated: 'FALSE',
  },
  {
    id: 'E7134093-7DFD-48A0-A687-299B70E6D42D',
    name: 'tanker-truck',
    codepoint: 'F0FE5',
    deprecated: 'FALSE',
  },
  {
    id: '76B2D13C-4AA4-4AA1-A0CA-F4E5CA53FFE2',
    name: 'tape-drive',
    codepoint: 'F16DF',
    deprecated: 'FALSE',
  },
  {
    id: 'C9C3F657-65A6-418E-91EF-E6FD8F0357C1',
    name: 'tape-measure',
    codepoint: 'F0B4D',
    deprecated: 'FALSE',
  },
  {
    id: '363D29C5-9F0F-43EA-BE7E-D1C2C1418517',
    name: 'target',
    codepoint: 'F04FE',
    deprecated: 'FALSE',
  },
  {
    id: '9B5EC357-642F-4790-BD58-E45A217DD9B9',
    name: 'target-account',
    codepoint: 'F0BD0',
    deprecated: 'FALSE',
  },
  {
    id: 'C8208D76-55E5-41A2-9540-AF3731D16D74',
    name: 'target-variant',
    codepoint: 'F0A77',
    deprecated: 'FALSE',
  },
  {
    id: '32F3835A-EBD0-4927-9497-6F67F7075EAF',
    name: 'taxi',
    codepoint: 'F04FF',
    deprecated: 'FALSE',
  },
  {
    id: 'D1759BBF-821F-4902-AAF5-8323A179912F',
    name: 'tea',
    codepoint: 'F0D9E',
    deprecated: 'FALSE',
  },
  {
    id: '002B1000-5D8B-4EC1-9D10-21E9EE62EE4C',
    name: 'tea-outline',
    codepoint: 'F0D9F',
    deprecated: 'FALSE',
  },
  {
    id: '3335C183-CD35-4D66-87AB-C5E3A7FCAFBF',
    name: 'teamviewer',
    codepoint: 'F0500',
    deprecated: 'TRUE',
  },
  {
    id: 'A9D12403-70AF-4022-9AB1-B9CE5EF1F9E9',
    name: 'teddy-bear',
    codepoint: 'F18FB',
    deprecated: 'FALSE',
  },
  {
    id: '66FE04C6-5941-4728-A256-17F9B25534F1',
    name: 'telescope',
    codepoint: 'F0B4E',
    deprecated: 'FALSE',
  },
  {
    id: '1B699A99-578E-450C-9523-934F4D281F72',
    name: 'television',
    codepoint: 'F0502',
    deprecated: 'FALSE',
  },
  {
    id: '09374787-27B8-43D7-92CA-B35050B1FA63',
    name: 'television-ambient-light',
    codepoint: 'F1356',
    deprecated: 'FALSE',
  },
  {
    id: 'CB446614-AE63-403E-BBF8-0B0ED3E367AE',
    name: 'television-box',
    codepoint: 'F0839',
    deprecated: 'FALSE',
  },
  {
    id: 'EB7FD483-EAF9-4FEE-8B82-25186D26827A',
    name: 'television-classic',
    codepoint: 'F07F4',
    deprecated: 'FALSE',
  },
  {
    id: 'C8E89001-2566-4676-B12B-7AAFAFCA0276',
    name: 'television-classic-off',
    codepoint: 'F083A',
    deprecated: 'FALSE',
  },
  {
    id: '6E5CAF6F-F00B-4613-B520-BDC1E5DD2832',
    name: 'television-guide',
    codepoint: 'F0503',
    deprecated: 'FALSE',
  },
  {
    id: '9E9D949B-13EC-43FB-9068-2BED76224BD8',
    name: 'television-off',
    codepoint: 'F083B',
    deprecated: 'FALSE',
  },
  {
    id: 'EE3F2E38-D8CD-42BD-B50F-187A77FBC630',
    name: 'television-pause',
    codepoint: 'F0F89',
    deprecated: 'FALSE',
  },
  {
    id: 'BFCE431D-2F97-4023-A690-F34199CA79F8',
    name: 'television-play',
    codepoint: 'F0ECF',
    deprecated: 'FALSE',
  },
  {
    id: '9132E544-8FAA-43BA-98B4-8D752CB3E251',
    name: 'television-shimmer',
    codepoint: 'F1110',
    deprecated: 'FALSE',
  },
  {
    id: '5C82B28E-DA98-4A40-B03A-9E309018F8C6',
    name: 'television-speaker',
    codepoint: 'F1B1B',
    deprecated: 'FALSE',
  },
  {
    id: '1E85CDE3-96F9-4652-8E25-7B91F50E4D1C',
    name: 'television-speaker-off',
    codepoint: 'F1B1C',
    deprecated: 'FALSE',
  },
  {
    id: '52228864-9BDD-4D12-82EC-AF730D68E47B',
    name: 'television-stop',
    codepoint: 'F0F8A',
    deprecated: 'FALSE',
  },
  {
    id: 'A94E333D-F8C5-4726-B81A-6DDF4CC37FD0',
    name: 'temperature-celsius',
    codepoint: 'F0504',
    deprecated: 'FALSE',
  },
  {
    id: '9A1DF8BB-627A-47C9-B609-BAC145D320DF',
    name: 'temperature-fahrenheit',
    codepoint: 'F0505',
    deprecated: 'FALSE',
  },
  {
    id: 'DF9D47EE-157B-4E1E-85CE-A043E5F2AE51',
    name: 'temperature-kelvin',
    codepoint: 'F0506',
    deprecated: 'FALSE',
  },
  {
    id: '8645763E-4D58-4BD2-99F0-1E1C3CA3F254',
    name: 'temple-buddhist',
    codepoint: 'F1B06',
    deprecated: 'FALSE',
  },
  {
    id: '8CE5A3E3-845D-479F-99F1-BAB02BEAAFC7',
    name: 'temple-buddhist-outline',
    codepoint: 'F1B07',
    deprecated: 'FALSE',
  },
  {
    id: '5271162F-477F-4B2C-9E8D-4078A9B7861C',
    name: 'temple-hindu',
    codepoint: 'F1B08',
    deprecated: 'FALSE',
  },
  {
    id: '4FB9F00B-42E2-4DA4-B1DF-E2895866B7CF',
    name: 'temple-hindu-outline',
    codepoint: 'F1B09',
    deprecated: 'FALSE',
  },
  {
    id: 'CE166270-170C-4292-8D6F-FE787AF6B021',
    name: 'tennis',
    codepoint: 'F0DA0',
    deprecated: 'FALSE',
  },
  {
    id: 'DE92B1F1-31FB-4551-84A2-32F5B5D842EC',
    name: 'tennis-ball',
    codepoint: 'F0507',
    deprecated: 'FALSE',
  },
  {
    id: '688277DE-082A-4BE2-8E59-129245E02DD7',
    name: 'tent',
    codepoint: 'F0508',
    deprecated: 'FALSE',
  },
  {
    id: 'D3339724-6ECB-4328-83C8-7E956C0271C0',
    name: 'terraform',
    codepoint: 'F1062',
    deprecated: 'TRUE',
  },
  {
    id: '1F98A781-6023-4368-AC06-5A421D166FCC',
    name: 'terrain',
    codepoint: 'F0509',
    deprecated: 'FALSE',
  },
  {
    id: '0B88F54B-7522-4D7D-85EC-3C0C3E7D36FE',
    name: 'test-tube',
    codepoint: 'F0668',
    deprecated: 'FALSE',
  },
  {
    id: 'A6F17875-0B9B-467B-85F5-4C4804D3A367',
    name: 'test-tube-empty',
    codepoint: 'F0911',
    deprecated: 'FALSE',
  },
  {
    id: '8B2E6A86-9CC3-4F9B-8B77-F5DD9BAE7898',
    name: 'test-tube-off',
    codepoint: 'F0912',
    deprecated: 'FALSE',
  },
  {
    id: 'E56EAB24-6D70-49E0-BEC8-D9164A93CB63',
    name: 'text',
    codepoint: 'F09A8',
    deprecated: 'FALSE',
  },
  {
    id: '15B2BA3F-53A2-4151-9B90-20000264FE54',
    name: 'text-account',
    codepoint: 'F1570',
    deprecated: 'FALSE',
  },
  {
    id: '32E03B3D-EAFA-4483-AE94-E43944F0F0AE',
    name: 'text-box',
    codepoint: 'F021A',
    deprecated: 'FALSE',
  },
  {
    id: '291424C4-8874-4D0D-8D89-0C3349C021ED',
    name: 'text-box-check',
    codepoint: 'F0EA6',
    deprecated: 'FALSE',
  },
  {
    id: 'A24C1161-73F2-43F0-B955-7D4E5F398372',
    name: 'text-box-check-outline',
    codepoint: 'F0EA7',
    deprecated: 'FALSE',
  },
  {
    id: '1AB9A345-F896-42F2-A7AF-5E61A8F17D15',
    name: 'text-box-edit',
    codepoint: 'F1A7C',
    deprecated: 'FALSE',
  },
  {
    id: '694846A9-15AD-4FA3-9130-20863F5ED8EE',
    name: 'text-box-edit-outline',
    codepoint: 'F1A7D',
    deprecated: 'FALSE',
  },
  {
    id: '60E9BECF-1FDF-4077-9BD7-F7B1863C2F66',
    name: 'text-box-minus',
    codepoint: 'F0EA8',
    deprecated: 'FALSE',
  },
  {
    id: '89E44C7C-4A52-4BBE-8EF4-2127A320E444',
    name: 'text-box-minus-outline',
    codepoint: 'F0EA9',
    deprecated: 'FALSE',
  },
  {
    id: '3F7FCD03-4E12-4D4D-AB63-C8D51F938284',
    name: 'text-box-multiple',
    codepoint: 'F0AB7',
    deprecated: 'FALSE',
  },
  {
    id: '02EF54D9-4D5D-463C-A3E8-0B44BDCB9523',
    name: 'text-box-multiple-outline',
    codepoint: 'F0AB8',
    deprecated: 'FALSE',
  },
  {
    id: 'E12AE178-0546-4CD3-81F6-BD1F17AA50EE',
    name: 'text-box-outline',
    codepoint: 'F09ED',
    deprecated: 'FALSE',
  },
  {
    id: '8979EEB0-B475-4229-A7D8-F5D48A58DB85',
    name: 'text-box-plus',
    codepoint: 'F0EAA',
    deprecated: 'FALSE',
  },
  {
    id: '467C2198-D7C5-4B95-88F1-71B269D2AEAC',
    name: 'text-box-plus-outline',
    codepoint: 'F0EAB',
    deprecated: 'FALSE',
  },
  {
    id: 'B599787B-9A9F-45C1-AD76-45FB4B00EFFC',
    name: 'text-box-remove',
    codepoint: 'F0EAC',
    deprecated: 'FALSE',
  },
  {
    id: 'C822984D-33BC-4A48-A9A3-E679ABF13595',
    name: 'text-box-remove-outline',
    codepoint: 'F0EAD',
    deprecated: 'FALSE',
  },
  {
    id: '08B85EF9-81EC-4DEE-B1C7-1C14D4CBECA5',
    name: 'text-box-search',
    codepoint: 'F0EAE',
    deprecated: 'FALSE',
  },
  {
    id: 'D32F390E-2888-4C63-99FA-9662902B6694',
    name: 'text-box-search-outline',
    codepoint: 'F0EAF',
    deprecated: 'FALSE',
  },
  {
    id: 'CA8CE1CF-1F6E-4B8B-9972-B3F36E865E67',
    name: 'text-long',
    codepoint: 'F09AA',
    deprecated: 'FALSE',
  },
  {
    id: '8F37BBCB-35D2-4FAF-88ED-59252659885D',
    name: 'text-recognition',
    codepoint: 'F113D',
    deprecated: 'FALSE',
  },
  {
    id: '3C1714C4-ACE1-45F2-ADE8-D90DD2E9742C',
    name: 'text-search',
    codepoint: 'F13B8',
    deprecated: 'FALSE',
  },
  {
    id: 'B1B403FA-FBA6-4219-8561-23DE3833029F',
    name: 'text-search-variant',
    codepoint: 'F1A7E',
    deprecated: 'FALSE',
  },
  {
    id: '4595BB97-16B7-4B19-8AC5-9C33167A6D20',
    name: 'text-shadow',
    codepoint: 'F0669',
    deprecated: 'FALSE',
  },
  {
    id: 'F80F249A-898B-4690-888A-EC11F79B4E56',
    name: 'text-short',
    codepoint: 'F09A9',
    deprecated: 'FALSE',
  },
  {
    id: 'E3A06403-51B5-4D00-9349-53517553A324',
    name: 'texture',
    codepoint: 'F050C',
    deprecated: 'FALSE',
  },
  {
    id: '7B46F16A-AF86-4A7A-B3AB-1D2297831078',
    name: 'texture-box',
    codepoint: 'F0FE6',
    deprecated: 'FALSE',
  },
  {
    id: '37350655-CB4D-4D9F-8C90-B8049CA0E48E',
    name: 'theater',
    codepoint: 'F050D',
    deprecated: 'FALSE',
  },
  {
    id: 'AE66BEF4-7040-4A15-8BF3-639B116853CC',
    name: 'theme-light-dark',
    codepoint: 'F050E',
    deprecated: 'FALSE',
  },
  {
    id: 'F41863ED-B66A-4FF4-B436-0876C8D3665B',
    name: 'thermometer',
    codepoint: 'F050F',
    deprecated: 'FALSE',
  },
  {
    id: 'DB3D5088-E7A7-47AD-87E3-43D5B41BF0C2',
    name: 'thermometer-alert',
    codepoint: 'F0E01',
    deprecated: 'FALSE',
  },
  {
    id: 'EBC857F8-EDAD-46AF-9F34-0DCB03E27998',
    name: 'thermometer-auto',
    codepoint: 'F1B0F',
    deprecated: 'FALSE',
  },
  {
    id: 'D3767EC9-5C06-42A0-B6BE-F5D2CF89FFD1',
    name: 'thermometer-bluetooth',
    codepoint: 'F1895',
    deprecated: 'FALSE',
  },
  {
    id: 'E01A628C-01EA-4F7E-B150-FCCDBED99EF9',
    name: 'thermometer-check',
    codepoint: 'F1A7F',
    deprecated: 'FALSE',
  },
  {
    id: 'C888E2C1-306A-4BCB-821E-5B33AC93572D',
    name: 'thermometer-chevron-down',
    codepoint: 'F0E02',
    deprecated: 'FALSE',
  },
  {
    id: '79E7C071-7AEE-48B1-802E-922925E94247',
    name: 'thermometer-chevron-up',
    codepoint: 'F0E03',
    deprecated: 'FALSE',
  },
  {
    id: 'DF51BF5D-831D-4FD1-8786-498CAC97DBB6',
    name: 'thermometer-high',
    codepoint: 'F10C2',
    deprecated: 'FALSE',
  },
  {
    id: 'A9FC898C-8CC5-44F2-822D-05C5625E95C8',
    name: 'thermometer-lines',
    codepoint: 'F0510',
    deprecated: 'FALSE',
  },
  {
    id: 'DBD8C754-85C4-43C4-BEA8-BCFEDB30B382',
    name: 'thermometer-low',
    codepoint: 'F10C3',
    deprecated: 'FALSE',
  },
  {
    id: '310F50E9-D74E-4753-A6D8-60EDBFC332B7',
    name: 'thermometer-minus',
    codepoint: 'F0E04',
    deprecated: 'FALSE',
  },
  {
    id: 'BF73A396-DB1C-46BC-8078-3CF0D7E0277F',
    name: 'thermometer-off',
    codepoint: 'F1531',
    deprecated: 'FALSE',
  },
  {
    id: '511387A0-FD3A-4335-B82E-9C68E02BABDD',
    name: 'thermometer-plus',
    codepoint: 'F0E05',
    deprecated: 'FALSE',
  },
  {
    id: '75048CA6-0E85-45B6-9DC4-3F4CDC45E14E',
    name: 'thermometer-probe',
    codepoint: 'F1B2B',
    deprecated: 'FALSE',
  },
  {
    id: 'F4CAA724-0072-475C-82A8-BD8AD3DEE74B',
    name: 'thermometer-probe-off',
    codepoint: 'F1B2C',
    deprecated: 'FALSE',
  },
  {
    id: '23736622-5488-4BE7-BB70-225F5F17AF2B',
    name: 'thermometer-water',
    codepoint: 'F1A80',
    deprecated: 'FALSE',
  },
  {
    id: '628D16E2-7E57-43CA-8451-012B8AB852DA',
    name: 'thermostat',
    codepoint: 'F0393',
    deprecated: 'FALSE',
  },
  {
    id: '35160CD5-6140-45AE-BE6B-AC73C11DE3E1',
    name: 'thermostat-auto',
    codepoint: 'F1B17',
    deprecated: 'FALSE',
  },
  {
    id: 'E0A6898F-3F08-4E13-8B61-DA63E5CBF949',
    name: 'thermostat-box',
    codepoint: 'F0891',
    deprecated: 'FALSE',
  },
  {
    id: '9753B373-A5AD-4E33-B5F1-133B30FAFD55',
    name: 'thermostat-box-auto',
    codepoint: 'F1B18',
    deprecated: 'FALSE',
  },
  {
    id: '46ACA864-4B1C-4DBD-94FB-7A0D6B9A500E',
    name: 'thought-bubble',
    codepoint: 'F07F6',
    deprecated: 'FALSE',
  },
  {
    id: '2C82E0CA-9872-431C-8739-0EA33825EBF7',
    name: 'thought-bubble-outline',
    codepoint: 'F07F7',
    deprecated: 'FALSE',
  },
  {
    id: '83D606E3-69A1-4583-854D-5C44477D038D',
    name: 'thumb-down',
    codepoint: 'F0511',
    deprecated: 'FALSE',
  },
  {
    id: '00EE8027-00FE-43B8-ACEC-7779E39D6E29',
    name: 'thumb-down-outline',
    codepoint: 'F0512',
    deprecated: 'FALSE',
  },
  {
    id: 'C32E4FC9-F3DC-4C67-81FB-62648E3F1AB5',
    name: 'thumb-up',
    codepoint: 'F0513',
    deprecated: 'FALSE',
  },
  {
    id: 'BFA3B3A3-0D00-459A-8BDB-DAC2D4233023',
    name: 'thumb-up-outline',
    codepoint: 'F0514',
    deprecated: 'FALSE',
  },
  {
    id: 'C38D6CEC-4D8E-44DB-B704-9A76552733D4',
    name: 'thumbs-up-down',
    codepoint: 'F0515',
    deprecated: 'FALSE',
  },
  {
    id: 'B5F74959-A897-4A3F-9E76-2154AB4B8930',
    name: 'thumbs-up-down-outline',
    codepoint: 'F1914',
    deprecated: 'FALSE',
  },
  {
    id: '18F55984-07FD-4630-BB11-8285F83AA838',
    name: 'ticket',
    codepoint: 'F0516',
    deprecated: 'FALSE',
  },
  {
    id: 'DE2E8A6C-33F1-4148-A228-C512C26B3159',
    name: 'ticket-account',
    codepoint: 'F0517',
    deprecated: 'FALSE',
  },
  {
    id: 'F19DA1EC-CBDA-456E-BC19-F03C635C886F',
    name: 'ticket-confirmation',
    codepoint: 'F0518',
    deprecated: 'FALSE',
  },
  {
    id: 'A1B45743-22F5-49E8-B466-120024F2C4C3',
    name: 'ticket-confirmation-outline',
    codepoint: 'F13AA',
    deprecated: 'FALSE',
  },
  {
    id: '6D7F0E99-A86F-4AAB-A468-F1D3214E1B60',
    name: 'ticket-outline',
    codepoint: 'F0913',
    deprecated: 'FALSE',
  },
  {
    id: '297864D0-935F-4EF0-9A2C-28F018544581',
    name: 'ticket-percent',
    codepoint: 'F0724',
    deprecated: 'FALSE',
  },
  {
    id: '43EDCFCF-4AFB-45E7-8F83-56CC40FC93B7',
    name: 'ticket-percent-outline',
    codepoint: 'F142B',
    deprecated: 'FALSE',
  },
  {
    id: '475062F1-407D-43B6-8FBF-1E0D4C7D690C',
    name: 'tie',
    codepoint: 'F0519',
    deprecated: 'FALSE',
  },
  {
    id: '708986EE-2BC9-401D-8ACD-0BC5A3E20EA3',
    name: 'tilde',
    codepoint: 'F0725',
    deprecated: 'FALSE',
  },
  {
    id: '06A80087-C9FF-4A60-A370-BD183676DC0A',
    name: 'tilde-off',
    codepoint: 'F18F3',
    deprecated: 'FALSE',
  },
  {
    id: '348DB89A-B4E2-4CB9-A7A4-B86973243ADE',
    name: 'timelapse',
    codepoint: 'F051A',
    deprecated: 'FALSE',
  },
  {
    id: '087D891A-1B90-42FA-97E4-9381D8D7CCF8',
    name: 'timeline',
    codepoint: 'F0BD1',
    deprecated: 'FALSE',
  },
  {
    id: '3965A310-F8FE-4077-8DAB-722CF2A44E78',
    name: 'timeline-alert',
    codepoint: 'F0F95',
    deprecated: 'FALSE',
  },
  {
    id: 'E6B39705-7058-4C26-BE2B-D85FAF69F614',
    name: 'timeline-alert-outline',
    codepoint: 'F0F98',
    deprecated: 'FALSE',
  },
  {
    id: '56EABFF0-9689-4078-98F6-8E0EDAAD6A19',
    name: 'timeline-check',
    codepoint: 'F1532',
    deprecated: 'FALSE',
  },
  {
    id: '0A75C1CB-0AF6-4A5E-BBE2-C14C31DDBBB6',
    name: 'timeline-check-outline',
    codepoint: 'F1533',
    deprecated: 'FALSE',
  },
  {
    id: '2671B127-348C-47F5-B016-F30389D649CF',
    name: 'timeline-clock',
    codepoint: 'F11FB',
    deprecated: 'FALSE',
  },
  {
    id: '2C73EA7B-CAD0-439D-8D8F-8779B100F927',
    name: 'timeline-clock-outline',
    codepoint: 'F11FC',
    deprecated: 'FALSE',
  },
  {
    id: '4086C75B-4BB4-4307-A953-22F4F7AEDE9F',
    name: 'timeline-minus',
    codepoint: 'F1534',
    deprecated: 'FALSE',
  },
  {
    id: 'E984C3EB-C8F3-4357-BAA4-93F9ED3049BC',
    name: 'timeline-minus-outline',
    codepoint: 'F1535',
    deprecated: 'FALSE',
  },
  {
    id: '82363A48-4E61-4594-A3DA-AA94FBBA4BE8',
    name: 'timeline-outline',
    codepoint: 'F0BD2',
    deprecated: 'FALSE',
  },
  {
    id: '6DDA8E22-8819-4EA4-A239-57349E7B1C7C',
    name: 'timeline-plus',
    codepoint: 'F0F96',
    deprecated: 'FALSE',
  },
  {
    id: '230196A7-AFE4-4722-810C-B845246A42A8',
    name: 'timeline-plus-outline',
    codepoint: 'F0F97',
    deprecated: 'FALSE',
  },
  {
    id: '92637DDF-E9A3-43E1-986D-DA5DE7FA8CDE',
    name: 'timeline-question',
    codepoint: 'F0F99',
    deprecated: 'FALSE',
  },
  {
    id: 'E844C160-56F3-431B-B971-00178D4B5D59',
    name: 'timeline-question-outline',
    codepoint: 'F0F9A',
    deprecated: 'FALSE',
  },
  {
    id: '1855D5A7-8C35-4621-BFFB-E10D9F0C0726',
    name: 'timeline-remove',
    codepoint: 'F1536',
    deprecated: 'FALSE',
  },
  {
    id: '748EF30E-992C-41C9-94DA-A95F4F80A4EF',
    name: 'timeline-remove-outline',
    codepoint: 'F1537',
    deprecated: 'FALSE',
  },
  {
    id: 'DBC01596-0759-4095-9BAA-3D582324A836',
    name: 'timeline-text',
    codepoint: 'F0BD3',
    deprecated: 'FALSE',
  },
  {
    id: 'F949D410-50B0-49DD-B4CE-E2FA9160052F',
    name: 'timeline-text-outline',
    codepoint: 'F0BD4',
    deprecated: 'FALSE',
  },
  {
    id: '7F6AFDDE-8C90-44FB-A000-D5F6EF93A943',
    name: 'timer',
    codepoint: 'F13AB',
    deprecated: 'FALSE',
  },
  {
    id: 'BDC89918-A38F-41C6-997E-DE9F779D4435',
    name: 'timer-10',
    codepoint: 'F051C',
    deprecated: 'FALSE',
  },
  {
    id: '3DEFEFB6-57CE-4C82-9D0C-16DBDBEF5950',
    name: 'timer-3',
    codepoint: 'F051D',
    deprecated: 'FALSE',
  },
  {
    id: '7A645EAB-94F5-49FA-8A73-D9D1045D3B34',
    name: 'timer-alert',
    codepoint: 'F1ACC',
    deprecated: 'FALSE',
  },
  {
    id: '4F3E2A6F-20C4-4EB4-8086-C8A7D1B55283',
    name: 'timer-alert-outline',
    codepoint: 'F1ACD',
    deprecated: 'FALSE',
  },
  {
    id: 'B155A30F-D5A6-4981-A072-B565DA53872D',
    name: 'timer-cancel',
    codepoint: 'F1ACE',
    deprecated: 'FALSE',
  },
  {
    id: 'F36D3C1A-6148-491D-AED6-B6F1883229BD',
    name: 'timer-cancel-outline',
    codepoint: 'F1ACF',
    deprecated: 'FALSE',
  },
  {
    id: 'C9193A95-3EFC-420B-A97A-2B688BE03647',
    name: 'timer-check',
    codepoint: 'F1AD0',
    deprecated: 'FALSE',
  },
  {
    id: 'AA368E7E-E808-48E4-9DFF-826156E12D8B',
    name: 'timer-check-outline',
    codepoint: 'F1AD1',
    deprecated: 'FALSE',
  },
  {
    id: '94B9E2FB-1589-418F-A128-C071B10B10EB',
    name: 'timer-cog',
    codepoint: 'F1925',
    deprecated: 'FALSE',
  },
  {
    id: '78B70624-89F7-4041-B247-9A39EA39957F',
    name: 'timer-cog-outline',
    codepoint: 'F1926',
    deprecated: 'FALSE',
  },
  {
    id: '046D48AE-58AE-472A-826A-F13F4859C0A3',
    name: 'timer-edit',
    codepoint: 'F1AD2',
    deprecated: 'FALSE',
  },
  {
    id: 'D16F03BE-957E-44F1-AD89-C18C340311BD',
    name: 'timer-edit-outline',
    codepoint: 'F1AD3',
    deprecated: 'FALSE',
  },
  {
    id: 'E2B4F7F7-FF17-4CCE-A1C7-BD677E2F32D5',
    name: 'timer-lock',
    codepoint: 'F1AD4',
    deprecated: 'FALSE',
  },
  {
    id: 'DEA728EB-8545-4E23-B31B-C9B1AA2229FD',
    name: 'timer-lock-open',
    codepoint: 'F1AD5',
    deprecated: 'FALSE',
  },
  {
    id: '2972C8F4-C75F-4AF0-AE70-2FFEBE34461E',
    name: 'timer-lock-open-outline',
    codepoint: 'F1AD6',
    deprecated: 'FALSE',
  },
  {
    id: '5849E09F-FAFC-40A5-9A30-D4B9BFB87BCC',
    name: 'timer-lock-outline',
    codepoint: 'F1AD7',
    deprecated: 'FALSE',
  },
  {
    id: '821CEDA0-CD59-45AF-BF1A-EE56399BC374',
    name: 'timer-marker',
    codepoint: 'F1AD8',
    deprecated: 'FALSE',
  },
  {
    id: 'D31E92C3-4D5C-4029-9D91-0C41DDFDB164',
    name: 'timer-marker-outline',
    codepoint: 'F1AD9',
    deprecated: 'FALSE',
  },
  {
    id: 'B6BD7263-1491-4D66-9847-D9E7F1B78967',
    name: 'timer-minus',
    codepoint: 'F1ADA',
    deprecated: 'FALSE',
  },
  {
    id: '4F6F9FA4-0D4F-4543-95D4-9FF0AA8678BE',
    name: 'timer-minus-outline',
    codepoint: 'F1ADB',
    deprecated: 'FALSE',
  },
  {
    id: '2BE4E342-BEAB-4B48-8CE5-E1B83D86E0D7',
    name: 'timer-music',
    codepoint: 'F1ADC',
    deprecated: 'FALSE',
  },
  {
    id: 'CAEA0928-8B03-4139-B58F-DAD1865FDC12',
    name: 'timer-music-outline',
    codepoint: 'F1ADD',
    deprecated: 'FALSE',
  },
  {
    id: 'CC698648-1A71-4EC8-A1E3-B5ACC42F2FDB',
    name: 'timer-off',
    codepoint: 'F13AC',
    deprecated: 'FALSE',
  },
  {
    id: 'BE74C70B-7E47-4E25-957C-C83CFEB73D6D',
    name: 'timer-off-outline',
    codepoint: 'F051E',
    deprecated: 'FALSE',
  },
  {
    id: '017E0965-CD2E-4B39-B0C5-410335848B12',
    name: 'timer-outline',
    codepoint: 'F051B',
    deprecated: 'FALSE',
  },
  {
    id: '3BF45E47-3FE7-43FE-AE4B-FFD605289C60',
    name: 'timer-pause',
    codepoint: 'F1ADE',
    deprecated: 'FALSE',
  },
  {
    id: '7C566FCC-EC47-409A-8E7B-5769C82CEF01',
    name: 'timer-pause-outline',
    codepoint: 'F1ADF',
    deprecated: 'FALSE',
  },
  {
    id: '81E15BC1-262E-435E-82BD-C4342A92D6E1',
    name: 'timer-play',
    codepoint: 'F1AE0',
    deprecated: 'FALSE',
  },
  {
    id: '0E6F9270-1D27-4E36-9352-0BACC428A548',
    name: 'timer-play-outline',
    codepoint: 'F1AE1',
    deprecated: 'FALSE',
  },
  {
    id: '2C80851E-E2B2-4CB3-9B95-A7807473F48C',
    name: 'timer-plus',
    codepoint: 'F1AE2',
    deprecated: 'FALSE',
  },
  {
    id: '5068DEF2-6E6D-469B-8770-2540F781A8BF',
    name: 'timer-plus-outline',
    codepoint: 'F1AE3',
    deprecated: 'FALSE',
  },
  {
    id: '131A1665-D2D6-4C5B-B30B-DBDD097EA773',
    name: 'timer-refresh',
    codepoint: 'F1AE4',
    deprecated: 'FALSE',
  },
  {
    id: '406B38E4-1E8C-4493-A1DF-804BB1FCD620',
    name: 'timer-refresh-outline',
    codepoint: 'F1AE5',
    deprecated: 'FALSE',
  },
  {
    id: '558B0DE6-0A7D-4BC6-ACAD-EB52A8B58A86',
    name: 'timer-remove',
    codepoint: 'F1AE6',
    deprecated: 'FALSE',
  },
  {
    id: 'B4E196AF-73B9-4693-806F-1267ECA46EB6',
    name: 'timer-remove-outline',
    codepoint: 'F1AE7',
    deprecated: 'FALSE',
  },
  {
    id: '64EFBA89-F8CB-4193-8656-A83CCBC66A76',
    name: 'timer-sand',
    codepoint: 'F051F',
    deprecated: 'FALSE',
  },
  {
    id: 'A3EC03DF-2DAD-4B48-AFA8-681324AE2460',
    name: 'timer-sand-complete',
    codepoint: 'F199F',
    deprecated: 'FALSE',
  },
  {
    id: 'DD31FFF2-A93B-41F5-BA6C-1732E8A8DEFF',
    name: 'timer-sand-empty',
    codepoint: 'F06AD',
    deprecated: 'FALSE',
  },
  {
    id: 'E603301B-7DF4-43C2-9035-0DDB2A50B88E',
    name: 'timer-sand-full',
    codepoint: 'F078C',
    deprecated: 'FALSE',
  },
  {
    id: '24C73172-9253-4C99-B214-B1C523115D79',
    name: 'timer-sand-paused',
    codepoint: 'F19A0',
    deprecated: 'FALSE',
  },
  {
    id: 'F8FF1136-69B0-4DCA-9BC6-092AE2CE23F2',
    name: 'timer-settings',
    codepoint: 'F1923',
    deprecated: 'FALSE',
  },
  {
    id: '33E186AB-8B36-40C1-BC72-D9614A115B74',
    name: 'timer-settings-outline',
    codepoint: 'F1924',
    deprecated: 'FALSE',
  },
  {
    id: 'EEC275B0-442C-4C00-A1AE-662EF4477A13',
    name: 'timer-star',
    codepoint: 'F1AE8',
    deprecated: 'FALSE',
  },
  {
    id: '8C07F7EA-8924-43A8-91E2-55A7D0DAE7AF',
    name: 'timer-star-outline',
    codepoint: 'F1AE9',
    deprecated: 'FALSE',
  },
  {
    id: '8D48AE22-A80B-4DFA-9F37-01C48E8D42DD',
    name: 'timer-stop',
    codepoint: 'F1AEA',
    deprecated: 'FALSE',
  },
  {
    id: '6692667B-8BD3-4234-92CA-F51D796CF521',
    name: 'timer-stop-outline',
    codepoint: 'F1AEB',
    deprecated: 'FALSE',
  },
  {
    id: '38A07095-B521-4D7C-8021-FD6CEAD49F10',
    name: 'timer-sync',
    codepoint: 'F1AEC',
    deprecated: 'FALSE',
  },
  {
    id: 'D5BE4AB4-3E09-4D9C-9329-C32E34796677',
    name: 'timer-sync-outline',
    codepoint: 'F1AED',
    deprecated: 'FALSE',
  },
  {
    id: 'F06ED6E0-0EB1-4B39-8E85-1A82A273F473',
    name: 'timetable',
    codepoint: 'F0520',
    deprecated: 'FALSE',
  },
  {
    id: '5F99B55B-0B1C-4FD7-B9F6-DD35A16DBCF1',
    name: 'tire',
    codepoint: 'F1896',
    deprecated: 'FALSE',
  },
  {
    id: '5042FF4E-78E5-43D5-B992-1AAEAE51AB25',
    name: 'toaster',
    codepoint: 'F1063',
    deprecated: 'FALSE',
  },
  {
    id: '97660B13-F700-455E-9C04-CACAC4450118',
    name: 'toaster-off',
    codepoint: 'F11B7',
    deprecated: 'FALSE',
  },
  {
    id: 'BA8D8327-7F94-4BB9-8199-9C012BAAF549',
    name: 'toaster-oven',
    codepoint: 'F0CD3',
    deprecated: 'FALSE',
  },
  {
    id: 'D1AD4F4E-3CFE-4F51-932D-D3942A26C418',
    name: 'toggle-switch',
    codepoint: 'F0521',
    deprecated: 'FALSE',
  },
  {
    id: 'A54ADA14-0917-432E-9288-3364FBAEBCE2',
    name: 'toggle-switch-off',
    codepoint: 'F0522',
    deprecated: 'FALSE',
  },
  {
    id: '1DCEDC73-941F-4412-B0B7-B7042888F91A',
    name: 'toggle-switch-off-outline',
    codepoint: 'F0A19',
    deprecated: 'FALSE',
  },
  {
    id: '2645C66C-41C1-4536-9BB5-4530B8C8E89B',
    name: 'toggle-switch-outline',
    codepoint: 'F0A1A',
    deprecated: 'FALSE',
  },
  {
    id: '7B449AE2-593A-435F-A5C2-FEA8E1BE0FBB',
    name: 'toggle-switch-variant',
    codepoint: 'F1A25',
    deprecated: 'FALSE',
  },
  {
    id: 'E22551ED-0CEC-46B4-9CAE-FF9CC5C11CF2',
    name: 'toggle-switch-variant-off',
    codepoint: 'F1A26',
    deprecated: 'FALSE',
  },
  {
    id: '5A1815F9-FC9E-4420-B723-AF4B324262A4',
    name: 'toilet',
    codepoint: 'F09AB',
    deprecated: 'FALSE',
  },
  {
    id: '79D1B3B8-FC3E-4978-AA8E-C78A1360B880',
    name: 'toolbox',
    codepoint: 'F09AC',
    deprecated: 'FALSE',
  },
  {
    id: 'F016813A-4390-482A-8FFF-D081FDD21362',
    name: 'toolbox-outline',
    codepoint: 'F09AD',
    deprecated: 'FALSE',
  },
  {
    id: 'CF5ABC25-55E6-4B19-B338-85F45926DEE1',
    name: 'tools',
    codepoint: 'F1064',
    deprecated: 'FALSE',
  },
  {
    id: 'D6AD70EE-33F4-4C3A-8ED1-3CD3236222F8',
    name: 'tooltip',
    codepoint: 'F0523',
    deprecated: 'FALSE',
  },
  {
    id: 'CA087C29-F27F-4DBE-8617-1369C18DF51D',
    name: 'tooltip-account',
    codepoint: 'F000C',
    deprecated: 'FALSE',
  },
  {
    id: 'F2CBC47D-4716-44EB-81E0-71915241860E',
    name: 'tooltip-cellphone',
    codepoint: 'F183B',
    deprecated: 'FALSE',
  },
  {
    id: 'B2FDABD3-4E66-461C-8DC0-10D9A18441B8',
    name: 'tooltip-check',
    codepoint: 'F155C',
    deprecated: 'FALSE',
  },
  {
    id: '255D95B4-1A63-408F-9CED-17AE2D05F38B',
    name: 'tooltip-check-outline',
    codepoint: 'F155D',
    deprecated: 'FALSE',
  },
  {
    id: 'C1684CDD-9C71-4A10-B3BA-5B26706B3B28',
    name: 'tooltip-edit',
    codepoint: 'F0524',
    deprecated: 'FALSE',
  },
  {
    id: 'B9D8D642-EFDD-4AFF-B1CD-86AB7E914347',
    name: 'tooltip-edit-outline',
    codepoint: 'F12C5',
    deprecated: 'FALSE',
  },
  {
    id: '1DF33E50-EBEA-4A62-8455-84C28271C8D2',
    name: 'tooltip-image',
    codepoint: 'F0525',
    deprecated: 'FALSE',
  },
  {
    id: '9E49D099-C7CD-42A3-BFA1-8AC8966898B5',
    name: 'tooltip-image-outline',
    codepoint: 'F0BD5',
    deprecated: 'FALSE',
  },
  {
    id: 'A8D3907B-9528-4EB0-AB75-EA6013F042F1',
    name: 'tooltip-minus',
    codepoint: 'F155E',
    deprecated: 'FALSE',
  },
  {
    id: '674242D7-81B2-4C9E-BC60-3F96AD379C52',
    name: 'tooltip-minus-outline',
    codepoint: 'F155F',
    deprecated: 'FALSE',
  },
  {
    id: 'C14A3C70-357F-4AE8-963C-B45ED543A4DB',
    name: 'tooltip-outline',
    codepoint: 'F0526',
    deprecated: 'FALSE',
  },
  {
    id: 'E1ED044C-BD73-4844-A33D-7ED9A1D232F5',
    name: 'tooltip-plus',
    codepoint: 'F0BD6',
    deprecated: 'FALSE',
  },
  {
    id: 'CAA5F3A3-2044-48AC-94CA-04899F9459ED',
    name: 'tooltip-plus-outline',
    codepoint: 'F0527',
    deprecated: 'FALSE',
  },
  {
    id: '99CABA6D-9506-4EC7-8721-8CE38B157E75',
    name: 'tooltip-question',
    codepoint: 'F1BBA',
    deprecated: 'FALSE',
  },
  {
    id: 'CD2A99C9-F223-42EB-BD90-5786EACF9A52',
    name: 'tooltip-question-outline',
    codepoint: 'F1BBB',
    deprecated: 'FALSE',
  },
  {
    id: 'A54A45EF-9C4B-4AFB-8734-610B14D521A5',
    name: 'tooltip-remove',
    codepoint: 'F1560',
    deprecated: 'FALSE',
  },
  {
    id: '192D99F7-B04B-476A-B638-70F27A7FF1E6',
    name: 'tooltip-remove-outline',
    codepoint: 'F1561',
    deprecated: 'FALSE',
  },
  {
    id: 'B608ACE2-279D-4C66-9169-32BD86BDF5F3',
    name: 'tooltip-text',
    codepoint: 'F0528',
    deprecated: 'FALSE',
  },
  {
    id: 'B0E2F01D-A8A8-4945-A0C2-FB1866C2C1F2',
    name: 'tooltip-text-outline',
    codepoint: 'F0BD7',
    deprecated: 'FALSE',
  },
  {
    id: '486D33E1-B181-40D4-B088-BBB49221B91F',
    name: 'tooth',
    codepoint: 'F08C3',
    deprecated: 'FALSE',
  },
  {
    id: 'BFD05B50-CF42-49C4-B76D-447DB0AEFEEE',
    name: 'tooth-outline',
    codepoint: 'F0529',
    deprecated: 'FALSE',
  },
  {
    id: '1BA32EF0-94A2-4F7E-BD93-5A9102143F8E',
    name: 'toothbrush',
    codepoint: 'F1129',
    deprecated: 'FALSE',
  },
  {
    id: '6CC31B35-04D6-4883-A0CF-3CD35E81AB98',
    name: 'toothbrush-electric',
    codepoint: 'F112C',
    deprecated: 'FALSE',
  },
  {
    id: 'E06A1B1A-ACE7-43AD-BC75-5C3191D0AB1D',
    name: 'toothbrush-paste',
    codepoint: 'F112A',
    deprecated: 'FALSE',
  },
  {
    id: '5E37234B-E874-4FB7-AC74-BD836129C433',
    name: 'torch',
    codepoint: 'F1606',
    deprecated: 'FALSE',
  },
  {
    id: 'E66ECEE5-0382-4406-B0AB-CFE819AE7684',
    name: 'tortoise',
    codepoint: 'F0D3B',
    deprecated: 'FALSE',
  },
  {
    id: '207A1D36-F4A1-4ACB-B588-7BEA51C2E285',
    name: 'toslink',
    codepoint: 'F12B8',
    deprecated: 'FALSE',
  },
  {
    id: 'E2623132-7507-4FFD-8382-E192DDC62D17',
    name: 'tournament',
    codepoint: 'F09AE',
    deprecated: 'FALSE',
  },
  {
    id: 'BC8926AA-02C6-4755-B5A8-D3BD29C2CDE0',
    name: 'tow-truck',
    codepoint: 'F083C',
    deprecated: 'FALSE',
  },
  {
    id: '70D21CFE-DC3C-4615-9524-4CD2F2FDC44E',
    name: 'tower-beach',
    codepoint: 'F0681',
    deprecated: 'FALSE',
  },
  {
    id: '11E0C03B-7522-4247-B0D3-F04AE1ECB166',
    name: 'tower-fire',
    codepoint: 'F0682',
    deprecated: 'FALSE',
  },
  {
    id: 'C0E839BC-5537-4219-8040-24466FB2F311',
    name: 'town-hall',
    codepoint: 'F1875',
    deprecated: 'FALSE',
  },
  {
    id: 'DDCE3E75-1E43-45A9-8D94-E24CFD8284B0',
    name: 'toy-brick',
    codepoint: 'F1288',
    deprecated: 'FALSE',
  },
  {
    id: '602DF6D1-21C6-4540-960D-79D7C37AECF9',
    name: 'toy-brick-marker',
    codepoint: 'F1289',
    deprecated: 'FALSE',
  },
  {
    id: '6EE7FD45-3389-4007-A362-0EDB4B9DF9BD',
    name: 'toy-brick-marker-outline',
    codepoint: 'F128A',
    deprecated: 'FALSE',
  },
  {
    id: '03BB26E3-61C3-4FCF-9532-056FC8DC1E4D',
    name: 'toy-brick-minus',
    codepoint: 'F128B',
    deprecated: 'FALSE',
  },
  {
    id: 'BA71CE05-DEA6-4638-AF5C-2194D6FB946E',
    name: 'toy-brick-minus-outline',
    codepoint: 'F128C',
    deprecated: 'FALSE',
  },
  {
    id: 'E178F91C-A54C-41B1-892F-F17A96283D14',
    name: 'toy-brick-outline',
    codepoint: 'F128D',
    deprecated: 'FALSE',
  },
  {
    id: '9426C325-5C35-40D8-9CA4-DA230CCC2736',
    name: 'toy-brick-plus',
    codepoint: 'F128E',
    deprecated: 'FALSE',
  },
  {
    id: 'A710B239-7C1A-4BDE-B337-AFEF3CD9E067',
    name: 'toy-brick-plus-outline',
    codepoint: 'F128F',
    deprecated: 'FALSE',
  },
  {
    id: 'F5773738-AE3C-423C-8B64-6C951B9A4B3A',
    name: 'toy-brick-remove',
    codepoint: 'F1290',
    deprecated: 'FALSE',
  },
  {
    id: '497261EB-0B21-4829-8659-1B1EB39043D1',
    name: 'toy-brick-remove-outline',
    codepoint: 'F1291',
    deprecated: 'FALSE',
  },
  {
    id: 'AEED3DA4-5EAF-4630-BA5B-6E4675E29FA1',
    name: 'toy-brick-search',
    codepoint: 'F1292',
    deprecated: 'FALSE',
  },
  {
    id: '86074918-B854-4C06-AAD5-C02EA910A6A2',
    name: 'toy-brick-search-outline',
    codepoint: 'F1293',
    deprecated: 'FALSE',
  },
  {
    id: '6BF0AEDF-F822-460F-8CEB-AEF0D83815F5',
    name: 'track-light',
    codepoint: 'F0914',
    deprecated: 'FALSE',
  },
  {
    id: '04986CC2-FE3B-4997-935E-EEE86DEA657A',
    name: 'track-light-off',
    codepoint: 'F1B01',
    deprecated: 'FALSE',
  },
  {
    id: 'C06AFD8C-C769-41B1-8E5C-355CE5DBEE8E',
    name: 'trackpad',
    codepoint: 'F07F8',
    deprecated: 'FALSE',
  },
  {
    id: '92AB470E-CDB3-43F8-A299-D32C94043D83',
    name: 'trackpad-lock',
    codepoint: 'F0933',
    deprecated: 'FALSE',
  },
  {
    id: 'D5993895-5ECF-4B4A-8C84-7E3D9C1F6ADB',
    name: 'tractor',
    codepoint: 'F0892',
    deprecated: 'FALSE',
  },
  {
    id: 'C80B9924-A19D-440E-8543-E1E232FAA90B',
    name: 'tractor-variant',
    codepoint: 'F14C4',
    deprecated: 'FALSE',
  },
  {
    id: 'E1849947-175A-4347-BFC7-D8C7811F0B2B',
    name: 'trademark',
    codepoint: 'F0A78',
    deprecated: 'FALSE',
  },
  {
    id: 'BA597840-6D3A-4D89-B8DC-63C5B2DA6638',
    name: 'traffic-cone',
    codepoint: 'F137C',
    deprecated: 'FALSE',
  },
  {
    id: '073B4489-5787-432C-9D10-50B270182D34',
    name: 'traffic-light',
    codepoint: 'F052B',
    deprecated: 'FALSE',
  },
  {
    id: '912B0C1C-8E8A-4AA4-9166-C1BCCC85BDF9',
    name: 'traffic-light-outline',
    codepoint: 'F182A',
    deprecated: 'FALSE',
  },
  {
    id: 'B6E1232A-3A43-4200-BE34-1BC436B34BF1',
    name: 'train',
    codepoint: 'F052C',
    deprecated: 'FALSE',
  },
  {
    id: '20588836-2EC7-467D-9E72-15D3C4FE7C93',
    name: 'train-car',
    codepoint: 'F0BD8',
    deprecated: 'FALSE',
  },
  {
    id: 'BDD26393-231D-4F57-ACEE-302525377A8D',
    name: 'train-car-autorack',
    codepoint: 'F1B2D',
    deprecated: 'FALSE',
  },
  {
    id: '8DF65675-7278-40D7-BA32-B7A3251051F4',
    name: 'train-car-box',
    codepoint: 'F1B2E',
    deprecated: 'FALSE',
  },
  {
    id: 'C419A958-3875-46FC-BED3-07C409C4FC82',
    name: 'train-car-box-full',
    codepoint: 'F1B2F',
    deprecated: 'FALSE',
  },
  {
    id: '3891AAAF-6681-44D3-955A-B0099F136716',
    name: 'train-car-box-open',
    codepoint: 'F1B30',
    deprecated: 'FALSE',
  },
  {
    id: 'F358F596-31EA-4304-A06E-1AAB158DA527',
    name: 'train-car-caboose',
    codepoint: 'F1B31',
    deprecated: 'FALSE',
  },
  {
    id: 'D8D0E296-67AA-4BA1-9268-46D29BF7113E',
    name: 'train-car-centerbeam',
    codepoint: 'F1B32',
    deprecated: 'FALSE',
  },
  {
    id: '58F04478-F917-48D8-974E-C02CCA88085A',
    name: 'train-car-centerbeam-full',
    codepoint: 'F1B33',
    deprecated: 'FALSE',
  },
  {
    id: '6EC41725-E54E-405F-AFCC-6CB7B1D1E529',
    name: 'train-car-container',
    codepoint: 'F1B34',
    deprecated: 'FALSE',
  },
  {
    id: '10D3216B-36F7-4151-B875-53136AE56E27',
    name: 'train-car-flatbed',
    codepoint: 'F1B35',
    deprecated: 'FALSE',
  },
  {
    id: 'A8C14CC3-D012-4822-8D89-A53EBAFA503E',
    name: 'train-car-flatbed-car',
    codepoint: 'F1B36',
    deprecated: 'FALSE',
  },
  {
    id: 'D2CF0BD5-C3D2-47E9-B1D4-C90F3D22050D',
    name: 'train-car-flatbed-tank',
    codepoint: 'F1B37',
    deprecated: 'FALSE',
  },
  {
    id: '89CDC858-78E9-4358-8FC7-19E3CAA0F2F3',
    name: 'train-car-gondola',
    codepoint: 'F1B38',
    deprecated: 'FALSE',
  },
  {
    id: '5B2EA1B8-10D5-4770-812F-3CC3ADF33B59',
    name: 'train-car-gondola-full',
    codepoint: 'F1B39',
    deprecated: 'FALSE',
  },
  {
    id: 'BADFBFC4-21C7-4BDA-B6A7-16C83B32C37C',
    name: 'train-car-hopper',
    codepoint: 'F1B3A',
    deprecated: 'FALSE',
  },
  {
    id: '154D64C8-79CC-4389-87B0-BDC5941D3B47',
    name: 'train-car-hopper-covered',
    codepoint: 'F1B3B',
    deprecated: 'FALSE',
  },
  {
    id: '76561F30-B98D-49D1-9369-9B95DF14D863',
    name: 'train-car-hopper-full',
    codepoint: 'F1B3C',
    deprecated: 'FALSE',
  },
  {
    id: 'E4050AC7-118E-45F6-8A87-571F9C52A02B',
    name: 'train-car-intermodal',
    codepoint: 'F1B3D',
    deprecated: 'FALSE',
  },
  {
    id: '04821E67-5037-46E7-8F5E-740C7EBFAC1D',
    name: 'train-car-passenger',
    codepoint: 'F1733',
    deprecated: 'FALSE',
  },
  {
    id: '9F9E31CB-361B-40D6-8754-157401124ED2',
    name: 'train-car-passenger-door',
    codepoint: 'F1734',
    deprecated: 'FALSE',
  },
  {
    id: '042CE4C4-087F-49EC-9F67-A5467318C068',
    name: 'train-car-passenger-door-open',
    codepoint: 'F1735',
    deprecated: 'FALSE',
  },
  {
    id: '1A9FFFAE-5581-4E22-B87C-3798A9E97FD1',
    name: 'train-car-passenger-variant',
    codepoint: 'F1736',
    deprecated: 'FALSE',
  },
  {
    id: '58D39303-215B-4530-AA97-74BCC0AE450B',
    name: 'train-car-tank',
    codepoint: 'F1B3E',
    deprecated: 'FALSE',
  },
  {
    id: 'C0A4E604-3829-4605-A7BC-CA0BFF138B18',
    name: 'train-variant',
    codepoint: 'F08C4',
    deprecated: 'FALSE',
  },
  {
    id: '13AE3C37-5323-4FE6-86F7-3B30B635969F',
    name: 'tram',
    codepoint: 'F052D',
    deprecated: 'FALSE',
  },
  {
    id: 'BEB0D58A-A99B-40FE-9E6C-C81218E683F8',
    name: 'tram-side',
    codepoint: 'F0FE7',
    deprecated: 'FALSE',
  },
  {
    id: '6DD88A18-582D-400D-A5AB-12996F26A05D',
    name: 'transcribe',
    codepoint: 'F052E',
    deprecated: 'FALSE',
  },
  {
    id: '2D2FB32D-B253-4D3E-BA04-7AD5D5FE2C2E',
    name: 'transcribe-close',
    codepoint: 'F052F',
    deprecated: 'FALSE',
  },
  {
    id: 'E9B887E6-76B3-45D5-8F69-43982EE59423',
    name: 'transfer',
    codepoint: 'F1065',
    deprecated: 'FALSE',
  },
  {
    id: 'D4CB36CD-4F30-45BE-940D-CC2AFFCA078A',
    name: 'transfer-down',
    codepoint: 'F0DA1',
    deprecated: 'FALSE',
  },
  {
    id: '03FA1C8F-8808-4293-A12A-7D9B7F8890D4',
    name: 'transfer-left',
    codepoint: 'F0DA2',
    deprecated: 'FALSE',
  },
  {
    id: 'D2895587-8DBA-4EDC-8624-3A32C20AB638',
    name: 'transfer-right',
    codepoint: 'F0530',
    deprecated: 'FALSE',
  },
  {
    id: 'A7928BA3-AC13-42A2-9AC2-5AFBD60E3243',
    name: 'transfer-up',
    codepoint: 'F0DA3',
    deprecated: 'FALSE',
  },
  {
    id: '370C931A-E40F-4950-B9CE-87D337720400',
    name: 'transit-connection',
    codepoint: 'F0D3C',
    deprecated: 'FALSE',
  },
  {
    id: '5F428B0A-E91D-48E5-8896-0CE072C919E4',
    name: 'transit-connection-horizontal',
    codepoint: 'F1546',
    deprecated: 'FALSE',
  },
  {
    id: '9E27C6FE-B44C-4A92-BDD7-A546A4F83E9D',
    name: 'transit-connection-variant',
    codepoint: 'F0D3D',
    deprecated: 'FALSE',
  },
  {
    id: '247F5D41-FA03-4D63-97AF-B39A4D4A7BFC',
    name: 'transit-detour',
    codepoint: 'F0F8B',
    deprecated: 'FALSE',
  },
  {
    id: 'A52E6E56-7AE7-45E3-B854-A2DF41F3141B',
    name: 'transit-skip',
    codepoint: 'F1515',
    deprecated: 'FALSE',
  },
  {
    id: 'CE6451FA-EF46-40B4-B87B-BCF93DDE6E54',
    name: 'transit-transfer',
    codepoint: 'F06AE',
    deprecated: 'FALSE',
  },
  {
    id: '2650CA0F-8423-4DE2-A821-9D7D1E5547C0',
    name: 'transition',
    codepoint: 'F0915',
    deprecated: 'FALSE',
  },
  {
    id: 'D254EE82-37CA-4897-BE12-6FD0ED708E38',
    name: 'transition-masked',
    codepoint: 'F0916',
    deprecated: 'FALSE',
  },
  {
    id: '4486DD26-4110-457E-BBD7-B5D19DF72E4E',
    name: 'translate',
    codepoint: 'F05CA',
    deprecated: 'FALSE',
  },
  {
    id: 'B65945C4-51D4-464A-985F-E6F9995BE40D',
    name: 'translate-off',
    codepoint: 'F0E06',
    deprecated: 'FALSE',
  },
  {
    id: 'F2BD1021-2575-4F57-A18A-F746EE262E15',
    name: 'translate-variant',
    codepoint: 'F1B99',
    deprecated: 'FALSE',
  },
  {
    id: 'DBA97655-B2ED-4FA6-95E0-02E7273549C9',
    name: 'transmission-tower',
    codepoint: 'F0D3E',
    deprecated: 'FALSE',
  },
  {
    id: 'F1AC7C0E-CA03-4ED0-AFE9-CE0CEB3C9806',
    name: 'transmission-tower-export',
    codepoint: 'F192C',
    deprecated: 'FALSE',
  },
  {
    id: '7CCBC365-A3F5-46BA-869B-AADA93F5D027',
    name: 'transmission-tower-import',
    codepoint: 'F192D',
    deprecated: 'FALSE',
  },
  {
    id: 'C54CC9F5-7BCA-4368-B379-E0CCD221A567',
    name: 'transmission-tower-off',
    codepoint: 'F19DD',
    deprecated: 'FALSE',
  },
  {
    id: '260712C5-252B-484C-B5A7-33827BBC5487',
    name: 'trash-can',
    codepoint: 'F0A79',
    deprecated: 'FALSE',
  },
  {
    id: 'A586B5B1-1D48-4BC8-B31C-F8E93DC8CD49',
    name: 'trash-can-outline',
    codepoint: 'F0A7A',
    deprecated: 'FALSE',
  },
  {
    id: 'F9A7F6AA-7379-4215-A97A-0E2FCAF3A9ED',
    name: 'tray',
    codepoint: 'F1294',
    deprecated: 'FALSE',
  },
  {
    id: 'A1CCEE33-5CCA-43B9-B056-23F3EAFBE506',
    name: 'tray-alert',
    codepoint: 'F1295',
    deprecated: 'FALSE',
  },
  {
    id: '8B1B6ED1-8AA5-4888-8477-B122FB236E1D',
    name: 'tray-arrow-down',
    codepoint: 'F0120',
    deprecated: 'FALSE',
  },
  {
    id: 'C633DDA7-FB93-4041-98C2-92117B970114',
    name: 'tray-arrow-up',
    codepoint: 'F011D',
    deprecated: 'FALSE',
  },
  {
    id: 'B81435D3-9FE4-470D-A46A-7FEDA1773D15',
    name: 'tray-full',
    codepoint: 'F1296',
    deprecated: 'FALSE',
  },
  {
    id: 'F7F07C92-4531-466D-AC3E-F7822D2FDFBD',
    name: 'tray-minus',
    codepoint: 'F1297',
    deprecated: 'FALSE',
  },
  {
    id: 'BF55355C-56CC-4C1F-9136-D92AA0800706',
    name: 'tray-plus',
    codepoint: 'F1298',
    deprecated: 'FALSE',
  },
  {
    id: 'FACF04CD-2633-47EC-97BE-59A3341289C3',
    name: 'tray-remove',
    codepoint: 'F1299',
    deprecated: 'FALSE',
  },
  {
    id: 'A0678E6D-C3D3-42AB-B9E7-C1B12D368B70',
    name: 'treasure-chest',
    codepoint: 'F0726',
    deprecated: 'FALSE',
  },
  {
    id: '58E298BD-F32E-420D-9E55-BDB44D583AB7',
    name: 'tree',
    codepoint: 'F0531',
    deprecated: 'FALSE',
  },
  {
    id: '81548212-EBFA-48B0-B0EB-D95EB5C0CC91',
    name: 'tree-outline',
    codepoint: 'F0E69',
    deprecated: 'FALSE',
  },
  {
    id: '25CA8276-A876-459D-B203-C81C58F88342',
    name: 'trello',
    codepoint: 'F0532',
    deprecated: 'TRUE',
  },
  {
    id: 'ACC5BB4D-9439-472F-9DD5-F026FF36D023',
    name: 'trending-down',
    codepoint: 'F0533',
    deprecated: 'FALSE',
  },
  {
    id: 'A39F489B-ABB1-4420-A6DC-20AF0E2CF6DA',
    name: 'trending-neutral',
    codepoint: 'F0534',
    deprecated: 'FALSE',
  },
  {
    id: '4277434D-74AD-48E7-9C5F-BB7226C4FC39',
    name: 'trending-up',
    codepoint: 'F0535',
    deprecated: 'FALSE',
  },
  {
    id: '78EC62FE-EC63-407F-BCF3-92C441398DDA',
    name: 'triangle',
    codepoint: 'F0536',
    deprecated: 'FALSE',
  },
  {
    id: '74A6BF3E-CEC2-40C3-A900-109D5E05E218',
    name: 'triangle-outline',
    codepoint: 'F0537',
    deprecated: 'FALSE',
  },
  {
    id: 'C5F5CD46-5905-406B-8C0B-7109E8393218',
    name: 'triangle-small-down',
    codepoint: 'F1A09',
    deprecated: 'FALSE',
  },
  {
    id: 'D7C0A028-070C-4E9B-88B8-910365A3FE41',
    name: 'triangle-small-up',
    codepoint: 'F1A0A',
    deprecated: 'FALSE',
  },
  {
    id: '59CAC75D-7028-4672-9EC2-1B9E797BA9D1',
    name: 'triangle-wave',
    codepoint: 'F147C',
    deprecated: 'FALSE',
  },
  {
    id: '835C3AA9-800B-4767-BDED-97FB875B19DF',
    name: 'triforce',
    codepoint: 'F0BD9',
    deprecated: 'FALSE',
  },
  {
    id: '0B9368A1-FC31-4D0D-8909-C2D44DCC0B83',
    name: 'trophy',
    codepoint: 'F0538',
    deprecated: 'FALSE',
  },
  {
    id: '39F70CE8-5C56-4AAB-A4F7-93F871B1CB96',
    name: 'trophy-award',
    codepoint: 'F0539',
    deprecated: 'FALSE',
  },
  {
    id: '3AC48F23-1321-4B8E-80D7-B050CCBA0255',
    name: 'trophy-broken',
    codepoint: 'F0DA4',
    deprecated: 'FALSE',
  },
  {
    id: 'D7B01958-C212-49B9-B58B-146B4017348C',
    name: 'trophy-outline',
    codepoint: 'F053A',
    deprecated: 'FALSE',
  },
  {
    id: 'C6664923-EE26-4175-90CB-AD87B517F2FF',
    name: 'trophy-variant',
    codepoint: 'F053B',
    deprecated: 'FALSE',
  },
  {
    id: 'C3A5133F-0462-40DB-863A-3659FC5DFE0E',
    name: 'trophy-variant-outline',
    codepoint: 'F053C',
    deprecated: 'FALSE',
  },
  {
    id: 'A0B91DEA-DB9A-4737-B2F4-26B805B88648',
    name: 'truck',
    codepoint: 'F053D',
    deprecated: 'FALSE',
  },
  {
    id: '8650D94A-71BC-4B6B-9BA9-A6A2A756063C',
    name: 'truck-alert',
    codepoint: 'F19DE',
    deprecated: 'FALSE',
  },
  {
    id: 'FCE10225-CA09-4B2E-8BE3-5B9D718B13D5',
    name: 'truck-alert-outline',
    codepoint: 'F19DF',
    deprecated: 'FALSE',
  },
  {
    id: '8C535C82-EAA1-431A-90AE-D0A5032024EB',
    name: 'truck-cargo-container',
    codepoint: 'F18D8',
    deprecated: 'FALSE',
  },
  {
    id: '39C645BD-15AE-42BE-B571-5744CB77E97D',
    name: 'truck-check',
    codepoint: 'F0CD4',
    deprecated: 'FALSE',
  },
  {
    id: 'BCEE78F4-69D9-40FC-8174-D299610C0690',
    name: 'truck-check-outline',
    codepoint: 'F129A',
    deprecated: 'FALSE',
  },
  {
    id: 'D0EB091D-C62C-4A93-9AE4-AE942F5738C7',
    name: 'truck-delivery',
    codepoint: 'F053E',
    deprecated: 'FALSE',
  },
  {
    id: 'E65E8E41-4516-4C25-B3C1-F6B71894B5B2',
    name: 'truck-delivery-outline',
    codepoint: 'F129B',
    deprecated: 'FALSE',
  },
  {
    id: '401AC514-6441-4DDC-80DC-48CF851C5B5E',
    name: 'truck-fast',
    codepoint: 'F0788',
    deprecated: 'FALSE',
  },
  {
    id: '42696369-BAF9-4854-A57D-355F89630929',
    name: 'truck-fast-outline',
    codepoint: 'F129C',
    deprecated: 'FALSE',
  },
  {
    id: '4A29028B-25F3-423F-B924-BB93FAEC2DCF',
    name: 'truck-flatbed',
    codepoint: 'F1891',
    deprecated: 'FALSE',
  },
  {
    id: '3F802DD6-094E-4C91-B78B-9790D1F39D9F',
    name: 'truck-minus',
    codepoint: 'F19AE',
    deprecated: 'FALSE',
  },
  {
    id: '583767E2-4272-4483-99B5-5677E6EAB027',
    name: 'truck-minus-outline',
    codepoint: 'F19BD',
    deprecated: 'FALSE',
  },
  {
    id: '09111629-7960-46BA-A70E-DC85128E4F4A',
    name: 'truck-outline',
    codepoint: 'F129D',
    deprecated: 'FALSE',
  },
  {
    id: 'CE4CCBED-7E6E-4F0C-ADF9-AC3347BF897D',
    name: 'truck-plus',
    codepoint: 'F19AD',
    deprecated: 'FALSE',
  },
  {
    id: 'E920C53D-E340-42C1-86D6-9A1317D9A584',
    name: 'truck-plus-outline',
    codepoint: 'F19BC',
    deprecated: 'FALSE',
  },
  {
    id: 'AD0B6233-8DDB-4E1F-85E4-63EB669C8E79',
    name: 'truck-remove',
    codepoint: 'F19AF',
    deprecated: 'FALSE',
  },
  {
    id: '537D027D-BD0C-43DC-B772-1F0FD0E0041A',
    name: 'truck-remove-outline',
    codepoint: 'F19BE',
    deprecated: 'FALSE',
  },
  {
    id: 'EEE6A113-50D3-4216-B103-C60D857D7E31',
    name: 'truck-snowflake',
    codepoint: 'F19A6',
    deprecated: 'FALSE',
  },
  {
    id: '6F488FA3-B882-443D-8619-0124217E267C',
    name: 'truck-trailer',
    codepoint: 'F0727',
    deprecated: 'FALSE',
  },
  {
    id: 'CCE02F94-2930-488B-A39C-17A8DD8BE591',
    name: 'trumpet',
    codepoint: 'F1096',
    deprecated: 'FALSE',
  },
  {
    id: 'E46BD04B-78BF-4243-8608-46930051A694',
    name: 'tshirt-crew',
    codepoint: 'F0A7B',
    deprecated: 'FALSE',
  },
  {
    id: '6B67D149-AE33-40CF-997F-C57A7ECB8D42',
    name: 'tshirt-crew-outline',
    codepoint: 'F053F',
    deprecated: 'FALSE',
  },
  {
    id: '618A5FB1-7308-4C2B-BCE1-461BA9DBFD13',
    name: 'tshirt-v',
    codepoint: 'F0A7C',
    deprecated: 'FALSE',
  },
  {
    id: '94791D5D-EEC5-4562-98DD-DF285E972EC8',
    name: 'tshirt-v-outline',
    codepoint: 'F0540',
    deprecated: 'FALSE',
  },
  {
    id: '23DCD596-6B2A-4556-AF71-A37AE9FE4D99',
    name: 'tsunami',
    codepoint: 'F1A81',
    deprecated: 'FALSE',
  },
  {
    id: '7796F195-34DA-4CAA-8F58-1179343EFB18',
    name: 'tumble-dryer',
    codepoint: 'F0917',
    deprecated: 'FALSE',
  },
  {
    id: 'D21E17B9-0D84-4C21-977C-D1056E934449',
    name: 'tumble-dryer-alert',
    codepoint: 'F11BA',
    deprecated: 'FALSE',
  },
  {
    id: 'E9DC2320-5FD4-403B-8445-9EB68B4468B6',
    name: 'tumble-dryer-off',
    codepoint: 'F11BB',
    deprecated: 'FALSE',
  },
  {
    id: 'B1F7C2ED-4017-4162-A4C7-31D8761D8DC3',
    name: 'tune',
    codepoint: 'F062E',
    deprecated: 'FALSE',
  },
  {
    id: 'B0AFC3B0-B718-407F-A51C-F4D932D7FCB2',
    name: 'tune-variant',
    codepoint: 'F1542',
    deprecated: 'FALSE',
  },
  {
    id: 'AB147073-9D20-4DA1-A108-3856FC1764C1',
    name: 'tune-vertical',
    codepoint: 'F066A',
    deprecated: 'FALSE',
  },
  {
    id: 'FF706504-28DF-4752-BD75-E50FFE794421',
    name: 'tune-vertical-variant',
    codepoint: 'F1543',
    deprecated: 'FALSE',
  },
  {
    id: '316E6516-22BC-4191-9DF6-BB4FCA8ECCDE',
    name: 'tunnel',
    codepoint: 'F183D',
    deprecated: 'FALSE',
  },
  {
    id: '1F64ACCA-5D8A-40FF-9E9A-CC640E9B9002',
    name: 'tunnel-outline',
    codepoint: 'F183E',
    deprecated: 'FALSE',
  },
  {
    id: '1F12944F-0802-431A-AA2C-7E0FEB23829B',
    name: 'turbine',
    codepoint: 'F1A82',
    deprecated: 'FALSE',
  },
  {
    id: '2BD0CF2B-70B8-4CFB-9350-A613C189EE7D',
    name: 'turkey',
    codepoint: 'F171B',
    deprecated: 'FALSE',
  },
  {
    id: '40E06CC0-82EE-4EE0-976B-C6388BBFDD9D',
    name: 'turnstile',
    codepoint: 'F0CD5',
    deprecated: 'FALSE',
  },
  {
    id: '22E6056D-46AF-4249-93DF-D0D5465DD6CA',
    name: 'turnstile-outline',
    codepoint: 'F0CD6',
    deprecated: 'FALSE',
  },
  {
    id: '36BB53AA-DFB4-46DB-AAD7-FE4D266D8EC7',
    name: 'turtle',
    codepoint: 'F0CD7',
    deprecated: 'FALSE',
  },
  {
    id: '8BA5EB2D-7C4F-4336-8994-20CAA433BB26',
    name: 'twitch',
    codepoint: 'F0543',
    deprecated: 'TRUE',
  },
  {
    id: 'A95F7C7C-DE85-4564-BA65-DEDCF3BE105A',
    name: 'twitter',
    codepoint: 'F0544',
    deprecated: 'TRUE',
  },
  {
    id: 'B4BC8D17-225E-44B1-A44F-3A6DC21C9A7B',
    name: 'two-factor-authentication',
    codepoint: 'F09AF',
    deprecated: 'FALSE',
  },
  {
    id: 'C5841F2E-EFD9-4589-BDB1-554DFE6A623C',
    name: 'typewriter',
    codepoint: 'F0F2D',
    deprecated: 'FALSE',
  },
  {
    id: 'F8C3B22E-D72C-4A1C-BCB8-A24CBB3CAF46',
    name: 'ubisoft',
    codepoint: 'F0BDA',
    deprecated: 'TRUE',
  },
  {
    id: 'D0B38B4B-389B-49A8-9797-BE1092231DE9',
    name: 'ubuntu',
    codepoint: 'F0548',
    deprecated: 'TRUE',
  },
  {
    id: 'D2609FE7-60FE-4372-94A9-7BB3C7AD18DE',
    name: 'ufo',
    codepoint: 'F10C4',
    deprecated: 'FALSE',
  },
  {
    id: 'A0C08D07-838C-426F-8EA5-EBCA5C117684',
    name: 'ufo-outline',
    codepoint: 'F10C5',
    deprecated: 'FALSE',
  },
  {
    id: 'A7C08392-F82F-4910-8D0F-6A1907AE19E3',
    name: 'ultra-high-definition',
    codepoint: 'F07F9',
    deprecated: 'FALSE',
  },
  {
    id: 'EB029748-89CF-4E75-BBC9-9747BEF7AE29',
    name: 'umbraco',
    codepoint: 'F0549',
    deprecated: 'TRUE',
  },
  {
    id: '6E7A679E-94FE-4BBD-931F-59B9F25B54EE',
    name: 'umbrella',
    codepoint: 'F054A',
    deprecated: 'FALSE',
  },
  {
    id: '20EBCC12-192B-49F0-9641-C61D7B8CD33A',
    name: 'umbrella-beach',
    codepoint: 'F188A',
    deprecated: 'FALSE',
  },
  {
    id: 'C48A57EA-07D6-40E8-9DFC-189BE630A7ED',
    name: 'umbrella-beach-outline',
    codepoint: 'F188B',
    deprecated: 'FALSE',
  },
  {
    id: '591C025F-58CC-4E5D-9D63-FB43A8B7BA78',
    name: 'umbrella-closed',
    codepoint: 'F09B0',
    deprecated: 'FALSE',
  },
  {
    id: '4B483491-948F-4126-9041-DE8D3A4A9A10',
    name: 'umbrella-closed-outline',
    codepoint: 'F13E2',
    deprecated: 'FALSE',
  },
  {
    id: 'B17B3489-56A4-490D-828E-61A64754EB1A',
    name: 'umbrella-closed-variant',
    codepoint: 'F13E1',
    deprecated: 'FALSE',
  },
  {
    id: '7E42C11A-74C3-4C0F-BE6B-9794F343925A',
    name: 'umbrella-outline',
    codepoint: 'F054B',
    deprecated: 'FALSE',
  },
  {
    id: 'C9D6F6DE-E9F7-4AC9-B9F1-41160A4C2B0E',
    name: 'undo',
    codepoint: 'F054C',
    deprecated: 'FALSE',
  },
  {
    id: 'C262C766-4474-4BC5-AE66-23E5735F86F1',
    name: 'undo-variant',
    codepoint: 'F054D',
    deprecated: 'FALSE',
  },
  {
    id: 'E533CB7E-48C2-4D9D-B935-B51C918703C0',
    name: 'unfold-less-horizontal',
    codepoint: 'F054E',
    deprecated: 'FALSE',
  },
  {
    id: 'E4DFDADF-60F8-4E90-A501-38381F4346A0',
    name: 'unfold-less-vertical',
    codepoint: 'F0760',
    deprecated: 'FALSE',
  },
  {
    id: 'E85F4170-11D3-4757-8247-17EBD07A08D4',
    name: 'unfold-more-horizontal',
    codepoint: 'F054F',
    deprecated: 'FALSE',
  },
  {
    id: 'AE8D43D2-00EC-46D9-A21C-4D0024A539D7',
    name: 'unfold-more-vertical',
    codepoint: 'F0761',
    deprecated: 'FALSE',
  },
  {
    id: '87E56CEE-67B1-427E-9A28-303F8B6D29A7',
    name: 'ungroup',
    codepoint: 'F0550',
    deprecated: 'FALSE',
  },
  {
    id: '0A232580-AB10-422D-B5B0-D0F4EFE7AD6A',
    name: 'unicode',
    codepoint: 'F0ED0',
    deprecated: 'TRUE',
  },
  {
    id: 'B3A5FE32-259C-4FBA-B6EB-A4A1CE7590DE',
    name: 'unicorn',
    codepoint: 'F15C2',
    deprecated: 'FALSE',
  },
  {
    id: 'D0825387-637B-4EB5-BCF5-470A7FCE1F05',
    name: 'unicorn-variant',
    codepoint: 'F15C3',
    deprecated: 'FALSE',
  },
  {
    id: 'A0632550-1C97-49AF-B0B3-463625A8C3FB',
    name: 'unicycle',
    codepoint: 'F15E5',
    deprecated: 'FALSE',
  },
  {
    id: '50CFC57C-9C70-4953-BDC4-F6340EB7A243',
    name: 'unity',
    codepoint: 'F06AF',
    deprecated: 'TRUE',
  },
  {
    id: '4AC79EF3-5BCA-41EC-976E-CB821416A905',
    name: 'unreal',
    codepoint: 'F09B1',
    deprecated: 'TRUE',
  },
  {
    id: '1B274396-AAE7-4CD8-8D57-27E31D915F68',
    name: 'update',
    codepoint: 'F06B0',
    deprecated: 'FALSE',
  },
  {
    id: '08F8FCE9-67A0-4696-9B83-0B1F11EA959E',
    name: 'upload',
    codepoint: 'F0552',
    deprecated: 'FALSE',
  },
  {
    id: '961ECD2E-2849-42C0-909A-5431B0273DA0',
    name: 'upload-lock',
    codepoint: 'F1373',
    deprecated: 'FALSE',
  },
  {
    id: 'AB9A1FD5-FAAC-4E6F-8B6D-D642D7066E96',
    name: 'upload-lock-outline',
    codepoint: 'F1374',
    deprecated: 'FALSE',
  },
  {
    id: '2B1A438E-4795-43D1-B4FD-3997C8E1BA9F',
    name: 'upload-multiple',
    codepoint: 'F083D',
    deprecated: 'FALSE',
  },
  {
    id: 'B473675B-DF1E-4450-B85E-786B483C9FA4',
    name: 'upload-network',
    codepoint: 'F06F6',
    deprecated: 'FALSE',
  },
  {
    id: '279E2BEC-B829-49A3-A9C5-0FC58781975C',
    name: 'upload-network-outline',
    codepoint: 'F0CD8',
    deprecated: 'FALSE',
  },
  {
    id: 'B6A90F94-944A-4B64-B551-1163605C6A30',
    name: 'upload-off',
    codepoint: 'F10C6',
    deprecated: 'FALSE',
  },
  {
    id: '432A3BF4-56B2-4567-890C-F60CE8CE2F34',
    name: 'upload-off-outline',
    codepoint: 'F10C7',
    deprecated: 'FALSE',
  },
  {
    id: '02F82A66-CEB3-4F54-825A-811342975170',
    name: 'upload-outline',
    codepoint: 'F0E07',
    deprecated: 'FALSE',
  },
  {
    id: '25033E0B-3AD4-414D-9972-559F2690FC1D',
    name: 'usb',
    codepoint: 'F0553',
    deprecated: 'FALSE',
  },
  {
    id: 'DB728530-EAF2-488E-8971-9567A31354DE',
    name: 'usb-flash-drive',
    codepoint: 'F129E',
    deprecated: 'FALSE',
  },
  {
    id: '9FD568CE-B873-45FC-8F8A-8D8172FB4C7E',
    name: 'usb-flash-drive-outline',
    codepoint: 'F129F',
    deprecated: 'FALSE',
  },
  {
    id: 'B271CD5A-6340-440A-896E-6A53BAE3B685',
    name: 'usb-port',
    codepoint: 'F11F0',
    deprecated: 'FALSE',
  },
  {
    id: '280D39D7-C3B6-4C48-827F-D944B4773B0C',
    name: 'vacuum',
    codepoint: 'F19A1',
    deprecated: 'FALSE',
  },
  {
    id: 'DE1B37E2-0CDF-4998-8E91-8A84F3D084BC',
    name: 'vacuum-outline',
    codepoint: 'F19A2',
    deprecated: 'FALSE',
  },
  {
    id: '304B1BC9-DF83-41FF-B0F9-1FF4D83A95CB',
    name: 'valve',
    codepoint: 'F1066',
    deprecated: 'FALSE',
  },
  {
    id: '753E83CB-F6D2-4162-B3B4-6D2B8E0DC642',
    name: 'valve-closed',
    codepoint: 'F1067',
    deprecated: 'FALSE',
  },
  {
    id: 'E9E77FBB-F72A-4A82-9565-6BE2885D5138',
    name: 'valve-open',
    codepoint: 'F1068',
    deprecated: 'FALSE',
  },
  {
    id: '293262AA-69E7-4D04-B441-AAA13D22C63C',
    name: 'van-passenger',
    codepoint: 'F07FA',
    deprecated: 'FALSE',
  },
  {
    id: '517A8F96-14C9-4FAB-8AC2-27321C91E1BF',
    name: 'van-utility',
    codepoint: 'F07FB',
    deprecated: 'FALSE',
  },
  {
    id: '437A175D-CA2F-44C9-9FA0-A59A43E7C590',
    name: 'vanish',
    codepoint: 'F07FC',
    deprecated: 'FALSE',
  },
  {
    id: '2BEF3471-36F9-4813-AC45-3C3FF9FC329C',
    name: 'vanish-quarter',
    codepoint: 'F1554',
    deprecated: 'FALSE',
  },
  {
    id: 'D8231C86-FA8B-4450-96FC-5C2820DD7DCC',
    name: 'vanity-light',
    codepoint: 'F11E1',
    deprecated: 'FALSE',
  },
  {
    id: '6D34F114-FA91-4815-8148-A4C6B3BF128C',
    name: 'variable',
    codepoint: 'F0AE7',
    deprecated: 'FALSE',
  },
  {
    id: 'AAC0D31F-ABF0-4A36-862E-BAEF2979E243',
    name: 'variable-box',
    codepoint: 'F1111',
    deprecated: 'FALSE',
  },
  {
    id: '576A1B80-550B-46BD-90A6-A497D8A29BC5',
    name: 'vector-arrange-above',
    codepoint: 'F0554',
    deprecated: 'FALSE',
  },
  {
    id: 'C2EC8280-2EFC-44F4-BA6D-89DE72F06CA3',
    name: 'vector-arrange-below',
    codepoint: 'F0555',
    deprecated: 'FALSE',
  },
  {
    id: '1D649850-7C33-41FE-8FBA-E6DFAB384AEC',
    name: 'vector-bezier',
    codepoint: 'F0AE8',
    deprecated: 'FALSE',
  },
  {
    id: '5C73E245-C6D4-42F0-A5D6-C14F53982661',
    name: 'vector-circle',
    codepoint: 'F0556',
    deprecated: 'FALSE',
  },
  {
    id: 'D5741976-7530-4859-97C1-F170BCE7C8B3',
    name: 'vector-circle-variant',
    codepoint: 'F0557',
    deprecated: 'FALSE',
  },
  {
    id: '7845F254-C78F-44D3-84E3-421B0B2AFF31',
    name: 'vector-combine',
    codepoint: 'F0558',
    deprecated: 'FALSE',
  },
  {
    id: '546B7A4F-6D63-46CD-B74D-DEF09CE8D8FB',
    name: 'vector-curve',
    codepoint: 'F0559',
    deprecated: 'FALSE',
  },
  {
    id: 'C4CA626C-23C3-495A-A73B-4B55A6FBC1F9',
    name: 'vector-difference',
    codepoint: 'F055A',
    deprecated: 'FALSE',
  },
  {
    id: '9D39AD1D-F643-43EF-B55B-6CF2EC4637EC',
    name: 'vector-difference-ab',
    codepoint: 'F055B',
    deprecated: 'FALSE',
  },
  {
    id: 'A327D00A-F451-4B88-AEBA-EA87016806CB',
    name: 'vector-difference-ba',
    codepoint: 'F055C',
    deprecated: 'FALSE',
  },
  {
    id: 'EE98FF75-8AAA-4912-AFDE-6D5290FD6800',
    name: 'vector-ellipse',
    codepoint: 'F0893',
    deprecated: 'FALSE',
  },
  {
    id: 'DACB8CA9-A0A9-4766-B1A3-EC334425514A',
    name: 'vector-intersection',
    codepoint: 'F055D',
    deprecated: 'FALSE',
  },
  {
    id: '8C2D4FBB-00B1-4815-971F-03F0788093C2',
    name: 'vector-line',
    codepoint: 'F055E',
    deprecated: 'FALSE',
  },
  {
    id: 'FC1F3637-2FBC-4A77-AB1F-FF74FE2B7D78',
    name: 'vector-link',
    codepoint: 'F0FE8',
    deprecated: 'FALSE',
  },
  {
    id: '4B4C339D-D581-44A3-95DE-4254CC42AC8D',
    name: 'vector-point',
    codepoint: 'F01C4',
    deprecated: 'FALSE',
  },
  {
    id: 'A3CC4F14-35C0-421B-A818-9B8E4E852522',
    name: 'vector-point-edit',
    codepoint: 'F09E8',
    deprecated: 'FALSE',
  },
  {
    id: 'A1B2C0E5-8F8A-40B8-8EE4-A619DE840E9A',
    name: 'vector-point-minus',
    codepoint: 'F1B78',
    deprecated: 'FALSE',
  },
  {
    id: '8722C5FB-0644-4837-B497-D16517B9F08D',
    name: 'vector-point-plus',
    codepoint: 'F1B79',
    deprecated: 'FALSE',
  },
  {
    id: 'C8B18CF5-BD7E-41FC-A653-6B46D85F982A',
    name: 'vector-point-select',
    codepoint: 'F055F',
    deprecated: 'FALSE',
  },
  {
    id: '6CD0A5FB-8FB8-45E7-9955-A876E4B2ABB4',
    name: 'vector-polygon',
    codepoint: 'F0560',
    deprecated: 'FALSE',
  },
  {
    id: '26BC525C-08EA-4929-9114-8FB8F35B32AD',
    name: 'vector-polygon-variant',
    codepoint: 'F1856',
    deprecated: 'FALSE',
  },
  {
    id: '1D1C3847-C645-42AE-A93C-DE1E64685762',
    name: 'vector-polyline',
    codepoint: 'F0561',
    deprecated: 'FALSE',
  },
  {
    id: 'D2CE645F-5DC0-4E94-AD3C-346D59DD6AB1',
    name: 'vector-polyline-edit',
    codepoint: 'F1225',
    deprecated: 'FALSE',
  },
  {
    id: 'ADB52436-4BAD-40E2-8A13-48C76188FCE6',
    name: 'vector-polyline-minus',
    codepoint: 'F1226',
    deprecated: 'FALSE',
  },
  {
    id: 'EFE8791E-593A-4B6F-8EFE-553A00C8E5A4',
    name: 'vector-polyline-plus',
    codepoint: 'F1227',
    deprecated: 'FALSE',
  },
  {
    id: '0B173B1A-A4BE-4B96-A33F-2C332150B5AE',
    name: 'vector-polyline-remove',
    codepoint: 'F1228',
    deprecated: 'FALSE',
  },
  {
    id: '1B112A31-E2A7-4BAD-B1F1-5289B8811F08',
    name: 'vector-radius',
    codepoint: 'F074A',
    deprecated: 'FALSE',
  },
  {
    id: '2A12555B-0CBC-4FC9-9F86-88F7785B3B2C',
    name: 'vector-rectangle',
    codepoint: 'F05C6',
    deprecated: 'FALSE',
  },
  {
    id: 'B7C380C9-13D8-4A0E-8EEC-1B4FB23F1FB9',
    name: 'vector-selection',
    codepoint: 'F0562',
    deprecated: 'FALSE',
  },
  {
    id: '039be9b8-08ad-11e4-bf19-842b2b6cfe1b',
    name: 'vector-square',
    codepoint: 'F0001',
    deprecated: 'FALSE',
  },
  {
    id: '4B18056D-33ED-4AD5-888D-AF90713CBC73',
    name: 'vector-square-close',
    codepoint: 'F1857',
    deprecated: 'FALSE',
  },
  {
    id: '613DA7AD-4B76-4514-96D8-4F6964222FF0',
    name: 'vector-square-edit',
    codepoint: 'F18D9',
    deprecated: 'FALSE',
  },
  {
    id: '5B415AB4-7F0B-458F-8CB5-CA456D6A48A5',
    name: 'vector-square-minus',
    codepoint: 'F18DA',
    deprecated: 'FALSE',
  },
  {
    id: 'B2FDC7FE-A762-45BE-9405-77F41514BAED',
    name: 'vector-square-open',
    codepoint: 'F1858',
    deprecated: 'FALSE',
  },
  {
    id: '9373C6A3-8FDD-499A-97EB-34855A15C937',
    name: 'vector-square-plus',
    codepoint: 'F18DB',
    deprecated: 'FALSE',
  },
  {
    id: '453AE0C4-2846-4EF0-AD04-D3981FC002A0',
    name: 'vector-square-remove',
    codepoint: 'F18DC',
    deprecated: 'FALSE',
  },
  {
    id: 'CE6EAE27-922A-48B9-83AF-5C333B20724F',
    name: 'vector-triangle',
    codepoint: 'F0563',
    deprecated: 'FALSE',
  },
  {
    id: '2FD284B6-3DC1-4733-B08E-BAD459E87B87',
    name: 'vector-union',
    codepoint: 'F0564',
    deprecated: 'FALSE',
  },
  {
    id: '551637A8-2091-4439-9710-71199B7AC0C1',
    name: 'vhs',
    codepoint: 'F0A1B',
    deprecated: 'FALSE',
  },
  {
    id: '68E64FD4-A020-4DEF-B2CB-5EA5BAA80E42',
    name: 'vibrate',
    codepoint: 'F0566',
    deprecated: 'FALSE',
  },
  {
    id: '36F2725C-4346-4EB3-9AA8-288DC3E27AFD',
    name: 'vibrate-off',
    codepoint: 'F0CD9',
    deprecated: 'FALSE',
  },
  {
    id: 'B1337ECC-EF1F-481B-9E75-6A8DB7A01BB9',
    name: 'video',
    codepoint: 'F0567',
    deprecated: 'FALSE',
  },
  {
    id: '0E676B63-2A2E-4182-91BD-F2A5D8D89595',
    name: 'video-2d',
    codepoint: 'F1A1C',
    deprecated: 'FALSE',
  },
  {
    id: '3F4D3E00-72CA-4167-A269-F9DD1A94540D',
    name: 'video-3d',
    codepoint: 'F07FD',
    deprecated: 'FALSE',
  },
  {
    id: 'F9B5EF59-C66B-445A-B37D-0CD3FDD5F47A',
    name: 'video-3d-off',
    codepoint: 'F13D9',
    deprecated: 'FALSE',
  },
  {
    id: '29FC25BE-88B2-4311-ACD7-F85EFB43D0E9',
    name: 'video-3d-variant',
    codepoint: 'F0ED1',
    deprecated: 'FALSE',
  },
  {
    id: 'FD1999E4-4780-48E5-8F18-606871BA0581',
    name: 'video-4k-box',
    codepoint: 'F083E',
    deprecated: 'FALSE',
  },
  {
    id: 'F02BE782-F323-40E5-B245-DF7B6AFB2B15',
    name: 'video-account',
    codepoint: 'F0919',
    deprecated: 'FALSE',
  },
  {
    id: '9E0AEA4A-7A90-43F7-8A7F-D49F374F11FF',
    name: 'video-box',
    codepoint: 'F00FD',
    deprecated: 'FALSE',
  },
  {
    id: 'A8AD0C27-54CD-4362-A862-C6865B1EE750',
    name: 'video-box-off',
    codepoint: 'F00FE',
    deprecated: 'FALSE',
  },
  {
    id: '9E4CFE98-3E4E-4C70-B1F1-7316F21427E3',
    name: 'video-check',
    codepoint: 'F1069',
    deprecated: 'FALSE',
  },
  {
    id: '206175B7-4215-412C-A30B-979BADCCCEE3',
    name: 'video-check-outline',
    codepoint: 'F106A',
    deprecated: 'FALSE',
  },
  {
    id: 'C8098652-E839-4E39-8465-8488B2D6502E',
    name: 'video-high-definition',
    codepoint: 'F152E',
    deprecated: 'FALSE',
  },
  {
    id: '1A06058D-FA25-4AAE-A7C8-225CD0D79F06',
    name: 'video-image',
    codepoint: 'F091A',
    deprecated: 'FALSE',
  },
  {
    id: 'D7B7AA15-66CB-4AA5-AD75-E96B78B89AB9',
    name: 'video-input-antenna',
    codepoint: 'F083F',
    deprecated: 'FALSE',
  },
  {
    id: '55F413A7-319D-4419-87D4-97FF9ED96791',
    name: 'video-input-component',
    codepoint: 'F0840',
    deprecated: 'FALSE',
  },
  {
    id: 'D371C4A6-40A8-4C03-95DA-2ACF2A5ABC4B',
    name: 'video-input-hdmi',
    codepoint: 'F0841',
    deprecated: 'FALSE',
  },
  {
    id: '7F6FD88C-27A6-4E0D-BD8D-FDF34AAEB1A9',
    name: 'video-input-scart',
    codepoint: 'F0F8C',
    deprecated: 'FALSE',
  },
  {
    id: 'EA3FB3E6-9696-4F01-AD34-5253E19EA34C',
    name: 'video-input-svideo',
    codepoint: 'F0842',
    deprecated: 'FALSE',
  },
  {
    id: '08B1B983-F8CC-4CE2-B9FA-4A292766BE31',
    name: 'video-marker',
    codepoint: 'F19A9',
    deprecated: 'FALSE',
  },
  {
    id: 'D2C8C1CD-F565-4549-8030-8549512C6905',
    name: 'video-marker-outline',
    codepoint: 'F19AA',
    deprecated: 'FALSE',
  },
  {
    id: '9453CFB6-B0CA-469B-87A0-48C51EA1B64D',
    name: 'video-minus',
    codepoint: 'F09B2',
    deprecated: 'FALSE',
  },
  {
    id: 'DEC0A182-36E6-47D2-ABC4-AF717946C412',
    name: 'video-minus-outline',
    codepoint: 'F02BA',
    deprecated: 'FALSE',
  },
  {
    id: 'F4C3A814-205E-4F0A-9B3D-34B5777A4237',
    name: 'video-off',
    codepoint: 'F0568',
    deprecated: 'FALSE',
  },
  {
    id: '4CD11F14-5EB9-4532-910A-D9329CB1CE48',
    name: 'video-off-outline',
    codepoint: 'F0BDB',
    deprecated: 'FALSE',
  },
  {
    id: '993D76B5-D3E5-4360-986D-3F13A04F29A7',
    name: 'video-outline',
    codepoint: 'F0BDC',
    deprecated: 'FALSE',
  },
  {
    id: '7C399890-F840-44F0-834E-409C1D9F1337',
    name: 'video-plus',
    codepoint: 'F09B3',
    deprecated: 'FALSE',
  },
  {
    id: '66BF0ABD-9688-49F1-B260-382B15D53432',
    name: 'video-plus-outline',
    codepoint: 'F01D3',
    deprecated: 'FALSE',
  },
  {
    id: 'DF10672F-0064-47B8-A327-797095F0F6A1',
    name: 'video-stabilization',
    codepoint: 'F091B',
    deprecated: 'FALSE',
  },
  {
    id: '465D18D8-ABEA-4D2B-8A05-C6555C36803F',
    name: 'video-switch',
    codepoint: 'F0569',
    deprecated: 'FALSE',
  },
  {
    id: 'FF441E7F-8F7E-4B03-876E-FD65C2A847C9',
    name: 'video-switch-outline',
    codepoint: 'F0790',
    deprecated: 'FALSE',
  },
  {
    id: '1A8E4CC1-97A7-4593-BE20-0E9C5686C2D3',
    name: 'video-vintage',
    codepoint: 'F0A1C',
    deprecated: 'FALSE',
  },
  {
    id: 'CB8F2A33-9238-40E0-9B1E-935586CF1FBA',
    name: 'video-wireless',
    codepoint: 'F0ED2',
    deprecated: 'FALSE',
  },
  {
    id: '178F6478-2B6F-43B7-AC0C-050481FA0BF2',
    name: 'video-wireless-outline',
    codepoint: 'F0ED3',
    deprecated: 'FALSE',
  },
  {
    id: 'E0B1CD58-0403-430F-AA9A-30B9E0122314',
    name: 'view-agenda',
    codepoint: 'F056A',
    deprecated: 'FALSE',
  },
  {
    id: '33C2B7B8-82DB-41C9-8B4E-2EFEE60C0D6E',
    name: 'view-agenda-outline',
    codepoint: 'F11D8',
    deprecated: 'FALSE',
  },
  {
    id: 'B3E07750-AF3A-49FA-BA72-7CE5D4A3C7AB',
    name: 'view-array',
    codepoint: 'F056B',
    deprecated: 'FALSE',
  },
  {
    id: 'C9B8EF68-CB6B-4B4E-BEEC-4BC30C04BE5F',
    name: 'view-array-outline',
    codepoint: 'F1485',
    deprecated: 'FALSE',
  },
  {
    id: '99C269BA-B1CD-4BC0-9BCC-7C5D95F881AC',
    name: 'view-carousel',
    codepoint: 'F056C',
    deprecated: 'FALSE',
  },
  {
    id: '27DE61C6-7B1F-429C-A396-AF9895CE5AD0',
    name: 'view-carousel-outline',
    codepoint: 'F1486',
    deprecated: 'FALSE',
  },
  {
    id: '163CB77E-4663-4F8E-B73E-20FCC5C5BED3',
    name: 'view-column',
    codepoint: 'F056D',
    deprecated: 'FALSE',
  },
  {
    id: '73732965-3E0C-4746-8D18-785E318F005D',
    name: 'view-column-outline',
    codepoint: 'F1487',
    deprecated: 'FALSE',
  },
  {
    id: 'CBBB6642-EA10-4B28-8D7B-7FD2E5252A2C',
    name: 'view-comfy',
    codepoint: 'F0E6A',
    deprecated: 'FALSE',
  },
  {
    id: '31233545-9224-4135-87F7-7B1257F23767',
    name: 'view-comfy-outline',
    codepoint: 'F1488',
    deprecated: 'FALSE',
  },
  {
    id: 'CD9C04DB-710E-4FD1-A679-535C5EF50BFB',
    name: 'view-compact',
    codepoint: 'F0E6B',
    deprecated: 'FALSE',
  },
  {
    id: '8FD1F3B0-682D-47BD-A4C8-C2526C674229',
    name: 'view-compact-outline',
    codepoint: 'F0E6C',
    deprecated: 'FALSE',
  },
  {
    id: 'B6D8B213-DC5F-40D3-97E4-EFA9B499A19D',
    name: 'view-dashboard',
    codepoint: 'F056E',
    deprecated: 'FALSE',
  },
  {
    id: 'F55B37EB-4DE9-49E8-88BC-1D339FA3EF4D',
    name: 'view-dashboard-edit',
    codepoint: 'F1947',
    deprecated: 'FALSE',
  },
  {
    id: '89F1A6E9-103A-4ABE-8A01-4A60A7781734',
    name: 'view-dashboard-edit-outline',
    codepoint: 'F1948',
    deprecated: 'FALSE',
  },
  {
    id: 'B44B2BB0-0BF9-42B7-BF70-ECC7FF102520',
    name: 'view-dashboard-outline',
    codepoint: 'F0A1D',
    deprecated: 'FALSE',
  },
  {
    id: '26B319A5-9CF7-4637-94A2-DB633BD22ABA',
    name: 'view-dashboard-variant',
    codepoint: 'F0843',
    deprecated: 'FALSE',
  },
  {
    id: 'F05DF9FC-A193-444F-8B6E-24862443BA63',
    name: 'view-dashboard-variant-outline',
    codepoint: 'F1489',
    deprecated: 'FALSE',
  },
  {
    id: 'AD31242E-143E-475E-822B-E363C9E9F2F4',
    name: 'view-day',
    codepoint: 'F056F',
    deprecated: 'FALSE',
  },
  {
    id: 'B478F822-6D6B-4A47-BCFE-0236D5766711',
    name: 'view-day-outline',
    codepoint: 'F148A',
    deprecated: 'FALSE',
  },
  {
    id: '39D66F7E-C38A-4CDC-9A50-6C4A60F70B82',
    name: 'view-gallery',
    codepoint: 'F1888',
    deprecated: 'FALSE',
  },
  {
    id: 'EBE654D8-89C1-4DAC-92E5-3534C55281F0',
    name: 'view-gallery-outline',
    codepoint: 'F1889',
    deprecated: 'FALSE',
  },
  {
    id: 'E788F50B-B654-4AEC-ACD7-6E9F5AE8B789',
    name: 'view-grid',
    codepoint: 'F0570',
    deprecated: 'FALSE',
  },
  {
    id: '33566CF8-43DC-48FF-9891-557946141997',
    name: 'view-grid-outline',
    codepoint: 'F11D9',
    deprecated: 'FALSE',
  },
  {
    id: '74FA0104-E5C4-4FE8-A9CD-45DC574D64C9',
    name: 'view-grid-plus',
    codepoint: 'F0F8D',
    deprecated: 'FALSE',
  },
  {
    id: '120B7778-EAE8-444B-AEDF-9F7793BF6A35',
    name: 'view-grid-plus-outline',
    codepoint: 'F11DA',
    deprecated: 'FALSE',
  },
  {
    id: '86DC0560-F109-4301-ACC7-46A6F74F441A',
    name: 'view-headline',
    codepoint: 'F0571',
    deprecated: 'FALSE',
  },
  {
    id: 'D9FCEB1D-004D-442E-A979-320101768F99',
    name: 'view-list',
    codepoint: 'F0572',
    deprecated: 'FALSE',
  },
  {
    id: 'FA9A5868-724C-429E-897B-72AD25186E2D',
    name: 'view-list-outline',
    codepoint: 'F148B',
    deprecated: 'FALSE',
  },
  {
    id: '467C52AB-0DEA-43AF-BAE4-76FD66D6C7F0',
    name: 'view-module',
    codepoint: 'F0573',
    deprecated: 'FALSE',
  },
  {
    id: '69AAF987-9993-4E5D-952D-ED815244B1F6',
    name: 'view-module-outline',
    codepoint: 'F148C',
    deprecated: 'FALSE',
  },
  {
    id: 'A085C7C7-F5C5-441C-84C0-05EB3B6A8767',
    name: 'view-parallel',
    codepoint: 'F0728',
    deprecated: 'FALSE',
  },
  {
    id: 'C14FCE1C-4ACB-4876-94AA-93C2E5DB362B',
    name: 'view-parallel-outline',
    codepoint: 'F148D',
    deprecated: 'FALSE',
  },
  {
    id: 'F3640F38-D10F-42C8-8A68-751D965B1F65',
    name: 'view-quilt',
    codepoint: 'F0574',
    deprecated: 'FALSE',
  },
  {
    id: '2797C36A-5A29-4679-AAB7-4F4832FA7DC5',
    name: 'view-quilt-outline',
    codepoint: 'F148E',
    deprecated: 'FALSE',
  },
  {
    id: '350F115F-0C20-4AB3-8206-81D2A25A2EF4',
    name: 'view-sequential',
    codepoint: 'F0729',
    deprecated: 'FALSE',
  },
  {
    id: '1B8CD437-1CE6-4493-9C57-5E367B47A0BF',
    name: 'view-sequential-outline',
    codepoint: 'F148F',
    deprecated: 'FALSE',
  },
  {
    id: '7FE1CF30-B16F-4626-8450-1881934D2FDF',
    name: 'view-split-horizontal',
    codepoint: 'F0BCB',
    deprecated: 'FALSE',
  },
  {
    id: '25E0A405-7A96-4159-8A63-9088BFA330F1',
    name: 'view-split-vertical',
    codepoint: 'F0BCC',
    deprecated: 'FALSE',
  },
  {
    id: '1B42A4BC-3B55-4C21-BDEF-94DC970012F9',
    name: 'view-stream',
    codepoint: 'F0575',
    deprecated: 'FALSE',
  },
  {
    id: '26CE4165-E7A4-425A-8175-8A8C392F08D3',
    name: 'view-stream-outline',
    codepoint: 'F1490',
    deprecated: 'FALSE',
  },
  {
    id: '8ED74862-BA7D-43FC-91EA-283D655B451F',
    name: 'view-week',
    codepoint: 'F0576',
    deprecated: 'FALSE',
  },
  {
    id: '163700DD-5F95-4BEC-82AC-7E5F1FD20BC8',
    name: 'view-week-outline',
    codepoint: 'F1491',
    deprecated: 'FALSE',
  },
  {
    id: '0FF21C45-66B4-492A-829C-9F46778323A9',
    name: 'vimeo',
    codepoint: 'F0577',
    deprecated: 'TRUE',
  },
  {
    id: 'D6702650-990F-416E-BF27-F6F2B86BFB8D',
    name: 'violin',
    codepoint: 'F060F',
    deprecated: 'FALSE',
  },
  {
    id: 'AE3F0BAE-DE0A-4BFE-82B6-3268D1B21006',
    name: 'virtual-reality',
    codepoint: 'F0894',
    deprecated: 'FALSE',
  },
  {
    id: 'B413B8B5-E20E-41BF-B6F1-696050D47421',
    name: 'virus',
    codepoint: 'F13B6',
    deprecated: 'FALSE',
  },
  {
    id: '7DE3EC50-BFF3-4402-85ED-DC43EE995DCB',
    name: 'virus-off',
    codepoint: 'F18E1',
    deprecated: 'FALSE',
  },
  {
    id: 'E2FA3916-A37A-4BB9-8892-DD0079581DAC',
    name: 'virus-off-outline',
    codepoint: 'F18E2',
    deprecated: 'FALSE',
  },
  {
    id: '0F7EE89B-C9E3-4365-B9DC-D3A28D328F66',
    name: 'virus-outline',
    codepoint: 'F13B7',
    deprecated: 'FALSE',
  },
  {
    id: '3695CD89-BCB2-4D10-A8C5-9190F31F7C92',
    name: 'vlc',
    codepoint: 'F057C',
    deprecated: 'TRUE',
  },
  {
    id: '04CEF7C6-E77F-4A66-89F3-2F5B544C482B',
    name: 'voicemail',
    codepoint: 'F057D',
    deprecated: 'FALSE',
  },
  {
    id: 'F319008C-CAC3-4D75-8DED-8164D252A015',
    name: 'volcano',
    codepoint: 'F1A83',
    deprecated: 'FALSE',
  },
  {
    id: 'EF1E667D-78DD-4460-BD7B-B5E7A70BDF54',
    name: 'volcano-outline',
    codepoint: 'F1A84',
    deprecated: 'FALSE',
  },
  {
    id: '3978DABC-FC98-4D3E-A971-88716AD5D600',
    name: 'volleyball',
    codepoint: 'F09B4',
    deprecated: 'FALSE',
  },
  {
    id: 'CF2F1BF8-9CBF-41EC-91EF-26B8F2B6A80E',
    name: 'volume-equal',
    codepoint: 'F1B10',
    deprecated: 'FALSE',
  },
  {
    id: 'CE6E08CF-2476-4BBB-AF91-6D081F1CBEF9',
    name: 'volume-high',
    codepoint: 'F057E',
    deprecated: 'FALSE',
  },
  {
    id: '213621FE-E003-4B40-9385-B9BCE19DB035',
    name: 'volume-low',
    codepoint: 'F057F',
    deprecated: 'FALSE',
  },
  {
    id: '0E37E506-8AD8-42C7-87E4-F8A2AEE03972',
    name: 'volume-medium',
    codepoint: 'F0580',
    deprecated: 'FALSE',
  },
  {
    id: 'D8BFD10F-6D55-4EE1-8D30-5B5CB064CBBF',
    name: 'volume-minus',
    codepoint: 'F075E',
    deprecated: 'FALSE',
  },
  {
    id: 'A78C1259-0DB5-4793-9EA9-DD69FEB337A8',
    name: 'volume-mute',
    codepoint: 'F075F',
    deprecated: 'FALSE',
  },
  {
    id: '74834C94-6AE1-493B-A9FB-4EE2A8FD989C',
    name: 'volume-off',
    codepoint: 'F0581',
    deprecated: 'FALSE',
  },
  {
    id: 'DECF3A51-F972-4D34-815D-D103BCE3888B',
    name: 'volume-plus',
    codepoint: 'F075D',
    deprecated: 'FALSE',
  },
  {
    id: '95D3325D-257A-433C-8B2E-D674C21BA268',
    name: 'volume-source',
    codepoint: 'F1120',
    deprecated: 'FALSE',
  },
  {
    id: 'E5699A8D-84C4-41F2-A48A-397F8153B4A1',
    name: 'volume-variant-off',
    codepoint: 'F0E08',
    deprecated: 'FALSE',
  },
  {
    id: '9CDA3BD8-77A5-4669-BFB7-785EECAE2FCA',
    name: 'volume-vibrate',
    codepoint: 'F1121',
    deprecated: 'FALSE',
  },
  {
    id: '61CDB722-3238-4F21-9A3F-77DC47A51755',
    name: 'vote',
    codepoint: 'F0A1F',
    deprecated: 'FALSE',
  },
  {
    id: '392D2435-0B0E-4CDA-A542-FAB319411B8A',
    name: 'vote-outline',
    codepoint: 'F0A20',
    deprecated: 'FALSE',
  },
  {
    id: 'A9EE1532-2932-4F71-95DC-1EFB56BA4DFC',
    name: 'vpn',
    codepoint: 'F0582',
    deprecated: 'FALSE',
  },
  {
    id: 'CB3BC222-1858-41C6-A488-0C2BB1FB2EA8',
    name: 'vuejs',
    codepoint: 'F0844',
    deprecated: 'TRUE',
  },
  {
    id: '2603306D-9691-469C-B468-6E53FFA2D0DE',
    name: 'vuetify',
    codepoint: 'F0E6D',
    deprecated: 'TRUE',
  },
  {
    id: 'DA42DA16-21E0-4A08-89E4-F634EBBCF85A',
    name: 'walk',
    codepoint: 'F0583',
    deprecated: 'FALSE',
  },
  {
    id: '5DAB0112-3768-4ADC-8796-A88B4473FE56',
    name: 'wall',
    codepoint: 'F07FE',
    deprecated: 'FALSE',
  },
  {
    id: '436644E2-0AFD-47DE-A15F-EF6BD02C5F5A',
    name: 'wall-fire',
    codepoint: 'F1A11',
    deprecated: 'FALSE',
  },
  {
    id: 'EABFE131-4690-406C-9042-EFCA33E97A00',
    name: 'wall-sconce',
    codepoint: 'F091C',
    deprecated: 'FALSE',
  },
  {
    id: '4F0EF72A-F2A8-4BFA-9E6F-279E159FC888',
    name: 'wall-sconce-flat',
    codepoint: 'F091D',
    deprecated: 'FALSE',
  },
  {
    id: '46E92A75-74CB-4DCD-A3B0-0D7B685E49E4',
    name: 'wall-sconce-flat-outline',
    codepoint: 'F17C9',
    deprecated: 'FALSE',
  },
  {
    id: 'DEAC0604-6FD1-496C-99E1-74068FC27713',
    name: 'wall-sconce-flat-variant',
    codepoint: 'F041C',
    deprecated: 'FALSE',
  },
  {
    id: '591CE496-AFCE-4274-A15F-E7574307D6FD',
    name: 'wall-sconce-flat-variant-outline',
    codepoint: 'F17CA',
    deprecated: 'FALSE',
  },
  {
    id: 'E033BCF1-3B41-4885-9700-CE4B85A7106C',
    name: 'wall-sconce-outline',
    codepoint: 'F17CB',
    deprecated: 'FALSE',
  },
  {
    id: '05EDCFBB-803B-4DA9-BDC5-17A4E43DE584',
    name: 'wall-sconce-round',
    codepoint: 'F0748',
    deprecated: 'FALSE',
  },
  {
    id: 'A91CF4C0-CC99-4429-BE37-54774E420A9F',
    name: 'wall-sconce-round-outline',
    codepoint: 'F17CC',
    deprecated: 'FALSE',
  },
  {
    id: 'D599F298-0E64-47A7-AD5B-B1AD5CEE1B1E',
    name: 'wall-sconce-round-variant',
    codepoint: 'F091E',
    deprecated: 'FALSE',
  },
  {
    id: '6A9EE0EE-A559-4A78-9313-0B7DED29C757',
    name: 'wall-sconce-round-variant-outline',
    codepoint: 'F17CD',
    deprecated: 'FALSE',
  },
  {
    id: 'C5ABA786-D897-4770-AA78-CE4CB2A9230E',
    name: 'wallet',
    codepoint: 'F0584',
    deprecated: 'FALSE',
  },
  {
    id: '0D4DD4F1-0915-4D3D-8F21-A96C2DDAF6E0',
    name: 'wallet-giftcard',
    codepoint: 'F0585',
    deprecated: 'FALSE',
  },
  {
    id: 'BBE325B3-416A-4160-8E95-162B076B8839',
    name: 'wallet-membership',
    codepoint: 'F0586',
    deprecated: 'FALSE',
  },
  {
    id: 'BE3A6C7B-398B-4B7D-B7F3-7EDE9220F489',
    name: 'wallet-outline',
    codepoint: 'F0BDD',
    deprecated: 'FALSE',
  },
  {
    id: 'A53A1EF4-13E1-4807-A20E-A0DA2F52392D',
    name: 'wallet-plus',
    codepoint: 'F0F8E',
    deprecated: 'FALSE',
  },
  {
    id: '1BDED2D9-31E7-457D-AA32-6CE990645545',
    name: 'wallet-plus-outline',
    codepoint: 'F0F8F',
    deprecated: 'FALSE',
  },
  {
    id: '790A03D3-B40A-468A-A6D6-CA3679FDBD8D',
    name: 'wallet-travel',
    codepoint: 'F0587',
    deprecated: 'FALSE',
  },
  {
    id: '350FE9C5-BFF1-462D-9318-BA2DDC55E11E',
    name: 'wallpaper',
    codepoint: 'F0E09',
    deprecated: 'FALSE',
  },
  {
    id: '0E468855-81AF-4CA1-ACF0-D166D07F188C',
    name: 'wan',
    codepoint: 'F0588',
    deprecated: 'FALSE',
  },
  {
    id: 'A6E4DFBB-920D-4A21-AE54-6378FEBDD767',
    name: 'wardrobe',
    codepoint: 'F0F90',
    deprecated: 'FALSE',
  },
  {
    id: 'A1AA70DE-C9FD-4791-91D0-27FC025F7A81',
    name: 'wardrobe-outline',
    codepoint: 'F0F91',
    deprecated: 'FALSE',
  },
  {
    id: 'DBF95B71-CC94-4672-8356-A09B96644694',
    name: 'warehouse',
    codepoint: 'F0F81',
    deprecated: 'FALSE',
  },
  {
    id: '42C06A23-81D5-4EF6-8CFA-B4FBF66E7B17',
    name: 'washing-machine',
    codepoint: 'F072A',
    deprecated: 'FALSE',
  },
  {
    id: 'A00D0B3E-D24A-4BC9-915C-7B264B63F2F4',
    name: 'washing-machine-alert',
    codepoint: 'F11BC',
    deprecated: 'FALSE',
  },
  {
    id: '9B318E52-E7BE-4BE1-94DF-96CA020CE55D',
    name: 'washing-machine-off',
    codepoint: 'F11BD',
    deprecated: 'FALSE',
  },
  {
    id: '3389F6CD-3E46-47DE-B26A-341C1B65D6BF',
    name: 'watch',
    codepoint: 'F0589',
    deprecated: 'FALSE',
  },
  {
    id: '4FAFD079-50D1-4FD3-9427-CF0C08148D84',
    name: 'watch-export',
    codepoint: 'F058A',
    deprecated: 'FALSE',
  },
  {
    id: '8213DABD-5CCB-415F-9457-A0A68B0F8179',
    name: 'watch-export-variant',
    codepoint: 'F0895',
    deprecated: 'FALSE',
  },
  {
    id: '0D93B47F-C9F9-4C87-B63C-656184A87C74',
    name: 'watch-import',
    codepoint: 'F058B',
    deprecated: 'FALSE',
  },
  {
    id: '383F089B-340D-43BD-A64C-4F81B8DF2A1F',
    name: 'watch-import-variant',
    codepoint: 'F0896',
    deprecated: 'FALSE',
  },
  {
    id: 'B6ADE62B-5D0C-4D24-87F4-9D8A0F1C7A04',
    name: 'watch-variant',
    codepoint: 'F0897',
    deprecated: 'FALSE',
  },
  {
    id: 'AE556BFC-A1A0-4B42-9123-B48DC7A3CBF3',
    name: 'watch-vibrate',
    codepoint: 'F06B1',
    deprecated: 'FALSE',
  },
  {
    id: '391763A7-BCF6-4C13-920B-115A7A5092B6',
    name: 'watch-vibrate-off',
    codepoint: 'F0CDA',
    deprecated: 'FALSE',
  },
  {
    id: '17EEE663-92E4-4AB1-87FE-94F2CD296C4B',
    name: 'water',
    codepoint: 'F058C',
    deprecated: 'FALSE',
  },
  {
    id: 'DDBEC0DB-97A9-439B-9FC0-5A05F51ED5E3',
    name: 'water-alert',
    codepoint: 'F1502',
    deprecated: 'FALSE',
  },
  {
    id: '4741B9A5-3E15-45B5-BD7B-B812C7E7644F',
    name: 'water-alert-outline',
    codepoint: 'F1503',
    deprecated: 'FALSE',
  },
  {
    id: '02A9C002-3B54-415E-8199-067139DF771A',
    name: 'water-boiler',
    codepoint: 'F0F92',
    deprecated: 'FALSE',
  },
  {
    id: 'EC21EC05-F45B-4560-A37E-3E721219A728',
    name: 'water-boiler-alert',
    codepoint: 'F11B3',
    deprecated: 'FALSE',
  },
  {
    id: 'E7E8E8F3-F414-440D-9CC1-F96CD5533040',
    name: 'water-boiler-auto',
    codepoint: 'F1B98',
    deprecated: 'FALSE',
  },
  {
    id: 'E7515B82-DE9D-4053-9D48-297511ED48E5',
    name: 'water-boiler-off',
    codepoint: 'F11B4',
    deprecated: 'FALSE',
  },
  {
    id: '30A9ED7F-3D06-476B-A4F1-B9F85786FF00',
    name: 'water-check',
    codepoint: 'F1504',
    deprecated: 'FALSE',
  },
  {
    id: 'E555BA93-F6A7-4B19-A4F8-132585E14850',
    name: 'water-check-outline',
    codepoint: 'F1505',
    deprecated: 'FALSE',
  },
  {
    id: '1A50CD86-0127-4730-A090-ED5110A9825F',
    name: 'water-circle',
    codepoint: 'F1806',
    deprecated: 'FALSE',
  },
  {
    id: 'C3824C0D-87B1-4577-A060-EDE628A694BC',
    name: 'water-minus',
    codepoint: 'F1506',
    deprecated: 'FALSE',
  },
  {
    id: '8536A2D7-1203-44B5-BBBE-6C37AABAD5B6',
    name: 'water-minus-outline',
    codepoint: 'F1507',
    deprecated: 'FALSE',
  },
  {
    id: '6C9777A0-967D-4242-91B2-E4D6B5596498',
    name: 'water-off',
    codepoint: 'F058D',
    deprecated: 'FALSE',
  },
  {
    id: '8B8E5074-5348-4856-BBF0-461BA5022BF3',
    name: 'water-off-outline',
    codepoint: 'F1508',
    deprecated: 'FALSE',
  },
  {
    id: '97D5ABBB-9410-4891-B06C-40121506BAEC',
    name: 'water-opacity',
    codepoint: 'F1855',
    deprecated: 'FALSE',
  },
  {
    id: 'DC3CFDE4-8FA9-497A-A937-6F7182B560E1',
    name: 'water-outline',
    codepoint: 'F0E0A',
    deprecated: 'FALSE',
  },
  {
    id: '2CE24A47-D798-4FFD-A53D-65FE9D452E2F',
    name: 'water-percent',
    codepoint: 'F058E',
    deprecated: 'FALSE',
  },
  {
    id: '55C44783-89D5-4A31-86B8-863B719EC876',
    name: 'water-percent-alert',
    codepoint: 'F1509',
    deprecated: 'FALSE',
  },
  {
    id: 'B7B4ACBA-F24A-48D7-A409-965A32860CE9',
    name: 'water-plus',
    codepoint: 'F150A',
    deprecated: 'FALSE',
  },
  {
    id: '74B7C41B-554D-453A-8646-FFAFDF78C3CA',
    name: 'water-plus-outline',
    codepoint: 'F150B',
    deprecated: 'FALSE',
  },
  {
    id: '09C341A8-067F-4CE2-96BC-D96E18334AB3',
    name: 'water-polo',
    codepoint: 'F12A0',
    deprecated: 'FALSE',
  },
  {
    id: '06862D93-608A-4B21-9193-83FA8C6BBDCD',
    name: 'water-pump',
    codepoint: 'F058F',
    deprecated: 'FALSE',
  },
  {
    id: 'A66B4E6E-70F9-4136-BE33-D02C002F7B30',
    name: 'water-pump-off',
    codepoint: 'F0F93',
    deprecated: 'FALSE',
  },
  {
    id: '2B0BAF1E-C9B8-42E9-84EB-D095EE2B0C39',
    name: 'water-remove',
    codepoint: 'F150C',
    deprecated: 'FALSE',
  },
  {
    id: 'C90739A3-CEDE-4524-A736-BA5A5B5F0CCB',
    name: 'water-remove-outline',
    codepoint: 'F150D',
    deprecated: 'FALSE',
  },
  {
    id: '14D06EC0-32F9-4CCE-B890-89DE73A106EA',
    name: 'water-sync',
    codepoint: 'F17C6',
    deprecated: 'FALSE',
  },
  {
    id: '276FDABE-30C6-406C-BAED-FF6BFC10B6BA',
    name: 'water-thermometer',
    codepoint: 'F1A85',
    deprecated: 'FALSE',
  },
  {
    id: 'D69A4155-E2D7-4A9F-82CD-904D346E8A6E',
    name: 'water-thermometer-outline',
    codepoint: 'F1A86',
    deprecated: 'FALSE',
  },
  {
    id: '4EE62E99-C30B-4A90-B89D-ADF4C26EF888',
    name: 'water-well',
    codepoint: 'F106B',
    deprecated: 'FALSE',
  },
  {
    id: '55A09921-7B26-49DB-AB0E-47E2C133D68C',
    name: 'water-well-outline',
    codepoint: 'F106C',
    deprecated: 'FALSE',
  },
  {
    id: 'DF6B67F9-DE8F-4183-B7E8-CE99C3B863EE',
    name: 'waterfall',
    codepoint: 'F1849',
    deprecated: 'FALSE',
  },
  {
    id: '5B017D32-0BE9-4A6A-999C-32777F995FBB',
    name: 'watering-can',
    codepoint: 'F1481',
    deprecated: 'FALSE',
  },
  {
    id: '67419E50-B2CE-4B36-A137-705FD6404252',
    name: 'watering-can-outline',
    codepoint: 'F1482',
    deprecated: 'FALSE',
  },
  {
    id: '20907A4A-11CA-447E-A4F5-D0F0901D197E',
    name: 'watermark',
    codepoint: 'F0612',
    deprecated: 'FALSE',
  },
  {
    id: '34D870BF-048B-44DF-93F9-8EBE7AF4C263',
    name: 'wave',
    codepoint: 'F0F2E',
    deprecated: 'FALSE',
  },
  {
    id: 'B677F1CB-606B-4DB5-9A9D-73E4FC0AC3F0',
    name: 'waveform',
    codepoint: 'F147D',
    deprecated: 'FALSE',
  },
  {
    id: '547979BF-6FF4-4CFF-BD55-1E5793C9DAA3',
    name: 'waves',
    codepoint: 'F078D',
    deprecated: 'FALSE',
  },
  {
    id: 'FC4A7A1E-A32E-432E-8EB3-79FFA3E012C1',
    name: 'waves-arrow-left',
    codepoint: 'F1859',
    deprecated: 'FALSE',
  },
  {
    id: '69DDD960-779E-4BD9-9B96-B73ED9CAF55D',
    name: 'waves-arrow-right',
    codepoint: 'F185A',
    deprecated: 'FALSE',
  },
  {
    id: '9C20E25F-2499-4B5F-BC47-74DDA23F6DC3',
    name: 'waves-arrow-up',
    codepoint: 'F185B',
    deprecated: 'FALSE',
  },
  {
    id: '51E32DD0-F286-4039-8242-2BF03E917C37',
    name: 'waze',
    codepoint: 'F0BDE',
    deprecated: 'TRUE',
  },
  {
    id: '9286B6B3-09EE-4465-9A70-D7874E26F3DF',
    name: 'weather-cloudy',
    codepoint: 'F0590',
    deprecated: 'FALSE',
  },
  {
    id: 'EF2214BE-8126-4458-A17A-74C58B30C2C5',
    name: 'weather-cloudy-alert',
    codepoint: 'F0F2F',
    deprecated: 'FALSE',
  },
  {
    id: '522BA775-D569-4615-AABE-40B9E638EA18',
    name: 'weather-cloudy-arrow-right',
    codepoint: 'F0E6E',
    deprecated: 'FALSE',
  },
  {
    id: 'D7526077-1254-4F32-9496-3B8E5D957F0F',
    name: 'weather-cloudy-clock',
    codepoint: 'F18F6',
    deprecated: 'FALSE',
  },
  {
    id: 'CFF329EE-07C3-48E8-B21D-7B31F36875E6',
    name: 'weather-dust',
    codepoint: 'F1B5A',
    deprecated: 'FALSE',
  },
  {
    id: '9E1AC9CA-0FA4-44C6-BA5D-2EB3CED4899D',
    name: 'weather-fog',
    codepoint: 'F0591',
    deprecated: 'FALSE',
  },
  {
    id: 'BED775CF-13BD-4D5A-A06F-7B026E820CB1',
    name: 'weather-hail',
    codepoint: 'F0592',
    deprecated: 'FALSE',
  },
  {
    id: '87410436-C351-4FF7-91E4-9A5F52D49F69',
    name: 'weather-hazy',
    codepoint: 'F0F30',
    deprecated: 'FALSE',
  },
  {
    id: '880967C4-85F9-4953-A6D0-73C9370BC987',
    name: 'weather-hurricane',
    codepoint: 'F0898',
    deprecated: 'FALSE',
  },
  {
    id: '6AF7BD8A-088F-4B07-806D-7456EB382D76',
    name: 'weather-lightning',
    codepoint: 'F0593',
    deprecated: 'FALSE',
  },
  {
    id: 'F69033FD-E6F0-48E7-ADA7-7CDF71B1673C',
    name: 'weather-lightning-rainy',
    codepoint: 'F067E',
    deprecated: 'FALSE',
  },
  {
    id: '1C3BA3AB-E86E-481B-A3C2-867B37A6E55A',
    name: 'weather-night',
    codepoint: 'F0594',
    deprecated: 'FALSE',
  },
  {
    id: '19D690AD-38F9-45CF-A092-D62A53098A68',
    name: 'weather-night-partly-cloudy',
    codepoint: 'F0F31',
    deprecated: 'FALSE',
  },
  {
    id: '80E6A6FB-1524-4A7D-9551-17D3BDFBD7C5',
    name: 'weather-partly-cloudy',
    codepoint: 'F0595',
    deprecated: 'FALSE',
  },
  {
    id: '93C1E28D-0C94-4B55-8B26-BACE3F777ECC',
    name: 'weather-partly-lightning',
    codepoint: 'F0F32',
    deprecated: 'FALSE',
  },
  {
    id: 'B01CFC20-40C4-4647-A20D-0181E5AB4952',
    name: 'weather-partly-rainy',
    codepoint: 'F0F33',
    deprecated: 'FALSE',
  },
  {
    id: '0E45DB37-DF54-422B-AE18-2AA35BC54109',
    name: 'weather-partly-snowy',
    codepoint: 'F0F34',
    deprecated: 'FALSE',
  },
  {
    id: 'CAF9B1D0-1095-4A34-AC07-78C937BDA618',
    name: 'weather-partly-snowy-rainy',
    codepoint: 'F0F35',
    deprecated: 'FALSE',
  },
  {
    id: '220955AA-770D-4884-A2ED-F21D2DC2CCDA',
    name: 'weather-pouring',
    codepoint: 'F0596',
    deprecated: 'FALSE',
  },
  {
    id: '45EF5E01-7499-4964-A897-5602F45A822E',
    name: 'weather-rainy',
    codepoint: 'F0597',
    deprecated: 'FALSE',
  },
  {
    id: '88999373-43C5-4851-BBCF-5335D8DFBDA0',
    name: 'weather-snowy',
    codepoint: 'F0598',
    deprecated: 'FALSE',
  },
  {
    id: 'D97DA5D6-5A65-4ADB-B124-48C22F32B07A',
    name: 'weather-snowy-heavy',
    codepoint: 'F0F36',
    deprecated: 'FALSE',
  },
  {
    id: '0938BCD1-BCF8-4439-B6B3-A590150878BC',
    name: 'weather-snowy-rainy',
    codepoint: 'F067F',
    deprecated: 'FALSE',
  },
  {
    id: '7F85265E-304C-4575-A73F-F0FCF0CA951B',
    name: 'weather-sunny',
    codepoint: 'F0599',
    deprecated: 'FALSE',
  },
  {
    id: '3AACF641-3923-4DBA-9568-0680999A6548',
    name: 'weather-sunny-alert',
    codepoint: 'F0F37',
    deprecated: 'FALSE',
  },
  {
    id: '2C884CEC-BA06-491D-9A7D-17B8B2E74CA6',
    name: 'weather-sunny-off',
    codepoint: 'F14E4',
    deprecated: 'FALSE',
  },
  {
    id: '5EF29669-A342-46D1-AE1B-47917E44311D',
    name: 'weather-sunset',
    codepoint: 'F059A',
    deprecated: 'FALSE',
  },
  {
    id: '032B7D10-9218-4B71-B190-C486832BEF61',
    name: 'weather-sunset-down',
    codepoint: 'F059B',
    deprecated: 'FALSE',
  },
  {
    id: 'AC82F126-0773-44CE-A004-77E7DDD130C9',
    name: 'weather-sunset-up',
    codepoint: 'F059C',
    deprecated: 'FALSE',
  },
  {
    id: 'BE149D04-25E9-4CE8-BD75-2D6D49A03FEA',
    name: 'weather-tornado',
    codepoint: 'F0F38',
    deprecated: 'FALSE',
  },
  {
    id: '7544E7E7-02F7-405C-9ADB-2E2540B07343',
    name: 'weather-windy',
    codepoint: 'F059D',
    deprecated: 'FALSE',
  },
  {
    id: '454DE99F-BA48-4A7B-A2C6-C0E162065D10',
    name: 'weather-windy-variant',
    codepoint: 'F059E',
    deprecated: 'FALSE',
  },
  {
    id: 'DA9C32CA-8C31-4462-B123-479169C31587',
    name: 'web',
    codepoint: 'F059F',
    deprecated: 'FALSE',
  },
  {
    id: '42B55CB7-DC3D-4804-A848-1A9CA7DCD533',
    name: 'web-box',
    codepoint: 'F0F94',
    deprecated: 'FALSE',
  },
  {
    id: 'A959571D-7AA8-4DD9-8407-D78D4DFBDD79',
    name: 'web-cancel',
    codepoint: 'F1790',
    deprecated: 'FALSE',
  },
  {
    id: 'B74213B2-B220-4D15-AFA5-CAE0E40D6CFE',
    name: 'web-check',
    codepoint: 'F0789',
    deprecated: 'FALSE',
  },
  {
    id: 'FABF6519-0CE7-461D-986D-60B4BF7374A7',
    name: 'web-clock',
    codepoint: 'F124A',
    deprecated: 'FALSE',
  },
  {
    id: '9C32EF89-4919-4CF1-A266-A0AB4C1C11EA',
    name: 'web-minus',
    codepoint: 'F10A0',
    deprecated: 'FALSE',
  },
  {
    id: '46DE4030-CBE2-401D-82A0-50B99DE514D1',
    name: 'web-off',
    codepoint: 'F0A8E',
    deprecated: 'FALSE',
  },
  {
    id: 'F9923FF1-E31E-4C03-BBB7-438704CF3909',
    name: 'web-plus',
    codepoint: 'F0033',
    deprecated: 'FALSE',
  },
  {
    id: 'D0CCF2D3-F7AC-4591-B31E-37B03A843661',
    name: 'web-refresh',
    codepoint: 'F1791',
    deprecated: 'FALSE',
  },
  {
    id: 'BC7B3CEA-B4D3-4F52-8378-5ACF408A3D9B',
    name: 'web-remove',
    codepoint: 'F0551',
    deprecated: 'FALSE',
  },
  {
    id: '3B639E91-D1C4-439A-A1D9-84779901F763',
    name: 'web-sync',
    codepoint: 'F1792',
    deprecated: 'FALSE',
  },
  {
    id: '03483AFB-9D53-4E10-B00C-1095C1873276',
    name: 'webcam',
    codepoint: 'F05A0',
    deprecated: 'FALSE',
  },
  {
    id: 'F8D156C4-16AA-47AD-B06B-970E27F30FE6',
    name: 'webcam-off',
    codepoint: 'F1737',
    deprecated: 'FALSE',
  },
  {
    id: '5B415522-B2A4-4D34-BEE3-D81D607BBD0B',
    name: 'webhook',
    codepoint: 'F062F',
    deprecated: 'FALSE',
  },
  {
    id: '65235220-D361-4E99-BEC6-E62B231E4384',
    name: 'webpack',
    codepoint: 'F072B',
    deprecated: 'TRUE',
  },
  {
    id: '972B66F0-ED66-42B4-BEE8-63EF28F4658B',
    name: 'webrtc',
    codepoint: 'F1248',
    deprecated: 'TRUE',
  },
  {
    id: '31CBB823-2223-40D5-BCF7-470F91159757',
    name: 'wechat',
    codepoint: 'F0611',
    deprecated: 'TRUE',
  },
  {
    id: 'F5DECC39-2E4C-4574-B141-832DFBA8CB43',
    name: 'weight',
    codepoint: 'F05A1',
    deprecated: 'FALSE',
  },
  {
    id: 'ABC7A229-B0E6-4F6C-9A00-C1340D95DEAF',
    name: 'weight-gram',
    codepoint: 'F0D3F',
    deprecated: 'FALSE',
  },
  {
    id: '5EDFF36D-4C8E-4B8D-BE3F-76C27DB804DF',
    name: 'weight-kilogram',
    codepoint: 'F05A2',
    deprecated: 'FALSE',
  },
  {
    id: '4ECFBE4E-52B2-4A60-BD6C-9B7DAB38E7A5',
    name: 'weight-lifter',
    codepoint: 'F115D',
    deprecated: 'FALSE',
  },
  {
    id: '47B5C7D8-105F-4420-B911-50693081EA76',
    name: 'weight-pound',
    codepoint: 'F09B5',
    deprecated: 'FALSE',
  },
  {
    id: 'C58497CE-5E84-483C-9C85-3007ADF9BC9A',
    name: 'whatsapp',
    codepoint: 'F05A3',
    deprecated: 'TRUE',
  },
  {
    id: '2D35B573-0B04-4E72-84CA-6BE431EE5AE5',
    name: 'wheel-barrow',
    codepoint: 'F14F2',
    deprecated: 'FALSE',
  },
  {
    id: '1984D53A-267C-4E05-956A-ABED5B2029BE',
    name: 'wheelchair',
    codepoint: 'F1A87',
    deprecated: 'FALSE',
  },
  {
    id: 'FB4E1390-EFB1-4364-B6EE-F936C5715163',
    name: 'wheelchair-accessibility',
    codepoint: 'F05A4',
    deprecated: 'FALSE',
  },
  {
    id: '2DADA4F6-D9AD-454D-BC75-1D8DE507FA82',
    name: 'whistle',
    codepoint: 'F09B6',
    deprecated: 'FALSE',
  },
  {
    id: '93CDBDF3-A4BE-4ADE-9179-171FA94726A6',
    name: 'whistle-outline',
    codepoint: 'F12BC',
    deprecated: 'FALSE',
  },
  {
    id: '2FE12EF0-B5CE-4ADB-9B1B-3F796F0EDE0E',
    name: 'white-balance-auto',
    codepoint: 'F05A5',
    deprecated: 'FALSE',
  },
  {
    id: '4464050A-1A97-46CF-BBA5-CE52D600CB45',
    name: 'white-balance-incandescent',
    codepoint: 'F05A6',
    deprecated: 'FALSE',
  },
  {
    id: 'E88B06D9-C60D-4084-9AE9-742266F0E8FB',
    name: 'white-balance-iridescent',
    codepoint: 'F05A7',
    deprecated: 'FALSE',
  },
  {
    id: '7DCAB3C3-C32F-483D-98F1-1728919AA17B',
    name: 'white-balance-sunny',
    codepoint: 'F05A8',
    deprecated: 'FALSE',
  },
  {
    id: '419C0A49-C751-4204-8C82-7E43B0896BD9',
    name: 'widgets',
    codepoint: 'F072C',
    deprecated: 'FALSE',
  },
  {
    id: '94C40C14-7BBA-4DF6-824B-6EFC9327F7E5',
    name: 'widgets-outline',
    codepoint: 'F1355',
    deprecated: 'FALSE',
  },
  {
    id: 'E77CF681-7DD0-4EDF-AA35-0DB80790BC03',
    name: 'wifi',
    codepoint: 'F05A9',
    deprecated: 'FALSE',
  },
  {
    id: '9B9FF835-20F9-49BD-A11F-403786BC42A2',
    name: 'wifi-alert',
    codepoint: 'F16B5',
    deprecated: 'FALSE',
  },
  {
    id: '8778FE48-83F3-484D-A1DE-C12000DB3170',
    name: 'wifi-arrow-down',
    codepoint: 'F16B6',
    deprecated: 'FALSE',
  },
  {
    id: '79955442-BBC4-4B1D-84EE-69990BF30331',
    name: 'wifi-arrow-left',
    codepoint: 'F16B7',
    deprecated: 'FALSE',
  },
  {
    id: 'BD5239F0-5659-4E99-9860-F28D58BDF788',
    name: 'wifi-arrow-left-right',
    codepoint: 'F16B8',
    deprecated: 'FALSE',
  },
  {
    id: 'F245A78A-C6F3-45CF-B4C0-131280576715',
    name: 'wifi-arrow-right',
    codepoint: 'F16B9',
    deprecated: 'FALSE',
  },
  {
    id: '20394D81-3E4C-4963-88C7-BB015ECA8D72',
    name: 'wifi-arrow-up',
    codepoint: 'F16BA',
    deprecated: 'FALSE',
  },
  {
    id: 'EE028441-38B6-40F9-B3E8-C673241BD39D',
    name: 'wifi-arrow-up-down',
    codepoint: 'F16BB',
    deprecated: 'FALSE',
  },
  {
    id: '06F860BA-78F4-4F9C-8A90-E6ABEF1FA064',
    name: 'wifi-cancel',
    codepoint: 'F16BC',
    deprecated: 'FALSE',
  },
  {
    id: '41D2061C-CFC9-4686-B916-4BEC07AB1553',
    name: 'wifi-check',
    codepoint: 'F16BD',
    deprecated: 'FALSE',
  },
  {
    id: '17572DB9-20E6-4B83-A6B8-C66282523BC8',
    name: 'wifi-cog',
    codepoint: 'F16BE',
    deprecated: 'FALSE',
  },
  {
    id: '3E5F6D19-E6C7-48BD-990F-C7D10598B2F7',
    name: 'wifi-lock',
    codepoint: 'F16BF',
    deprecated: 'FALSE',
  },
  {
    id: 'F9450F5D-FCF2-4E89-B234-FDA2F72C6B18',
    name: 'wifi-lock-open',
    codepoint: 'F16C0',
    deprecated: 'FALSE',
  },
  {
    id: 'A6FB83C2-13FE-4B23-AE9B-7F2696CA2835',
    name: 'wifi-marker',
    codepoint: 'F16C1',
    deprecated: 'FALSE',
  },
  {
    id: 'DFEC628C-E105-456D-8F73-71F54116DBC2',
    name: 'wifi-minus',
    codepoint: 'F16C2',
    deprecated: 'FALSE',
  },
  {
    id: 'A7CAE187-B853-41DD-9D8D-FB9A28C3F764',
    name: 'wifi-off',
    codepoint: 'F05AA',
    deprecated: 'FALSE',
  },
  {
    id: '3CF89702-EA3B-48A1-9B5E-5188A80DCBA8',
    name: 'wifi-plus',
    codepoint: 'F16C3',
    deprecated: 'FALSE',
  },
  {
    id: '540437B4-6901-4F18-A60F-CAA10FD74B49',
    name: 'wifi-refresh',
    codepoint: 'F16C4',
    deprecated: 'FALSE',
  },
  {
    id: '5989CC44-8A90-4D17-ADB8-F7AC12A0CF4E',
    name: 'wifi-remove',
    codepoint: 'F16C5',
    deprecated: 'FALSE',
  },
  {
    id: 'BA9356E6-E4D6-4A64-923C-395114E0DBE3',
    name: 'wifi-settings',
    codepoint: 'F16C6',
    deprecated: 'FALSE',
  },
  {
    id: '30694858-4607-48C1-A533-861E4968A0CE',
    name: 'wifi-star',
    codepoint: 'F0E0B',
    deprecated: 'FALSE',
  },
  {
    id: 'E4665C87-DB69-45CC-B922-1FCB0F0A54A3',
    name: 'wifi-strength-1',
    codepoint: 'F091F',
    deprecated: 'FALSE',
  },
  {
    id: '31F993F8-3BC0-4818-AE1C-91DF005766F3',
    name: 'wifi-strength-1-alert',
    codepoint: 'F0920',
    deprecated: 'FALSE',
  },
  {
    id: '035F0E2C-C952-40AB-BC29-B8CE120C389D',
    name: 'wifi-strength-1-lock',
    codepoint: 'F0921',
    deprecated: 'FALSE',
  },
  {
    id: '91890A07-398B-41A0-B91C-647C7BE9F5DA',
    name: 'wifi-strength-1-lock-open',
    codepoint: 'F16CB',
    deprecated: 'FALSE',
  },
  {
    id: '92D075FB-99D3-4926-A4E9-F25EAB70E78E',
    name: 'wifi-strength-2',
    codepoint: 'F0922',
    deprecated: 'FALSE',
  },
  {
    id: '38D73A8E-CEA5-4E6F-960B-BD2A0D482D9F',
    name: 'wifi-strength-2-alert',
    codepoint: 'F0923',
    deprecated: 'FALSE',
  },
  {
    id: 'CD7B760F-1A3C-4DDE-AB00-9EF11DF12073',
    name: 'wifi-strength-2-lock',
    codepoint: 'F0924',
    deprecated: 'FALSE',
  },
  {
    id: '22E5DDDA-12A4-4087-B1FF-5C3E8807CE0B',
    name: 'wifi-strength-2-lock-open',
    codepoint: 'F16CC',
    deprecated: 'FALSE',
  },
  {
    id: '736B9B99-3A60-4BCA-A79A-40DE34D17F43',
    name: 'wifi-strength-3',
    codepoint: 'F0925',
    deprecated: 'FALSE',
  },
  {
    id: '780617FD-FBFA-4F88-98CA-2DEA43409312',
    name: 'wifi-strength-3-alert',
    codepoint: 'F0926',
    deprecated: 'FALSE',
  },
  {
    id: 'CD4F72AA-B86D-4133-8EB8-145F457A1F06',
    name: 'wifi-strength-3-lock',
    codepoint: 'F0927',
    deprecated: 'FALSE',
  },
  {
    id: '249BF1E5-B9AC-47D6-9FC4-72678EE933C4',
    name: 'wifi-strength-3-lock-open',
    codepoint: 'F16CD',
    deprecated: 'FALSE',
  },
  {
    id: '41B86B22-7245-4A97-9BAA-3E9EBD44CEB0',
    name: 'wifi-strength-4',
    codepoint: 'F0928',
    deprecated: 'FALSE',
  },
  {
    id: 'B37308A1-15BE-4F23-82F4-B360C8BA63C1',
    name: 'wifi-strength-4-alert',
    codepoint: 'F0929',
    deprecated: 'FALSE',
  },
  {
    id: '11518A63-4E2B-477D-8D93-9B6C708DE5E1',
    name: 'wifi-strength-4-lock',
    codepoint: 'F092A',
    deprecated: 'FALSE',
  },
  {
    id: '332A10AE-2396-460B-819F-7149D0BE4085',
    name: 'wifi-strength-4-lock-open',
    codepoint: 'F16CE',
    deprecated: 'FALSE',
  },
  {
    id: '50E531C4-A82A-4F0C-A8BB-A2208B546E81',
    name: 'wifi-strength-alert-outline',
    codepoint: 'F092B',
    deprecated: 'FALSE',
  },
  {
    id: '40B4EE9D-5DA4-428C-BEF3-C75FD6740CA3',
    name: 'wifi-strength-lock-open-outline',
    codepoint: 'F16CF',
    deprecated: 'FALSE',
  },
  {
    id: '718740BB-FC95-47E4-856B-0A469966C8DD',
    name: 'wifi-strength-lock-outline',
    codepoint: 'F092C',
    deprecated: 'FALSE',
  },
  {
    id: '122332EC-08DD-438C-BD47-AB332E1E04F6',
    name: 'wifi-strength-off',
    codepoint: 'F092D',
    deprecated: 'FALSE',
  },
  {
    id: 'E4D2EA69-5F99-4D0B-BC12-BD22A2835B19',
    name: 'wifi-strength-off-outline',
    codepoint: 'F092E',
    deprecated: 'FALSE',
  },
  {
    id: '0DAAFF13-5A8D-44C3-AE46-9DDE62493C60',
    name: 'wifi-strength-outline',
    codepoint: 'F092F',
    deprecated: 'FALSE',
  },
  {
    id: '73D9D138-DCB0-4CA7-9AB9-5C3BCB4D2165',
    name: 'wifi-sync',
    codepoint: 'F16C7',
    deprecated: 'FALSE',
  },
  {
    id: '19DE621D-F082-42B5-9FD3-FEF8D84A2BB9',
    name: 'wikipedia',
    codepoint: 'F05AC',
    deprecated: 'TRUE',
  },
  {
    id: '4A21CDF6-CF3E-46DB-8A1E-A5F51225DDE3',
    name: 'wind-power',
    codepoint: 'F1A88',
    deprecated: 'FALSE',
  },
  {
    id: '4ED15D1F-9A58-4E07-BA71-3DCEAC67F59D',
    name: 'wind-power-outline',
    codepoint: 'F1A89',
    deprecated: 'FALSE',
  },
  {
    id: '9DABF133-FBE0-416C-82D8-F47E8A559E12',
    name: 'wind-turbine',
    codepoint: 'F0DA5',
    deprecated: 'FALSE',
  },
  {
    id: 'E77F8623-2F40-49A2-AE26-40B7B9AFA6AF',
    name: 'wind-turbine-alert',
    codepoint: 'F19AB',
    deprecated: 'FALSE',
  },
  {
    id: '6B86211C-130B-4A90-A9C7-9B58A5228D30',
    name: 'wind-turbine-check',
    codepoint: 'F19AC',
    deprecated: 'FALSE',
  },
  {
    id: '0CB92EB7-2EE0-401C-9113-7D52E78DB51E',
    name: 'window-close',
    codepoint: 'F05AD',
    deprecated: 'FALSE',
  },
  {
    id: '42DDDE71-7AA4-4A61-98F5-2A3B0AD486FF',
    name: 'window-closed',
    codepoint: 'F05AE',
    deprecated: 'FALSE',
  },
  {
    id: '8A71B80F-BEE0-4603-8EB3-06699D1140FF',
    name: 'window-closed-variant',
    codepoint: 'F11DB',
    deprecated: 'FALSE',
  },
  {
    id: 'AB365770-45CF-44AC-941E-8E1396FAF428',
    name: 'window-maximize',
    codepoint: 'F05AF',
    deprecated: 'FALSE',
  },
  {
    id: 'C329F2CA-AC52-45CD-87D7-253AB115D47F',
    name: 'window-minimize',
    codepoint: 'F05B0',
    deprecated: 'FALSE',
  },
  {
    id: '5617DB90-FDD7-4909-B322-D1EC44EB1372',
    name: 'window-open',
    codepoint: 'F05B1',
    deprecated: 'FALSE',
  },
  {
    id: '47841D12-3D7E-40C1-9230-08C8890E0067',
    name: 'window-open-variant',
    codepoint: 'F11DC',
    deprecated: 'FALSE',
  },
  {
    id: '405C8463-0E67-48A3-B824-7C5E74423125',
    name: 'window-restore',
    codepoint: 'F05B2',
    deprecated: 'FALSE',
  },
  {
    id: '0893E339-7750-4CAB-8C19-6496711F3C37',
    name: 'window-shutter',
    codepoint: 'F111C',
    deprecated: 'FALSE',
  },
  {
    id: '8D6FB7B2-191F-459C-A41A-1D8881EA286B',
    name: 'window-shutter-alert',
    codepoint: 'F111D',
    deprecated: 'FALSE',
  },
  {
    id: '20BDF96D-E685-450D-8555-199115B7628F',
    name: 'window-shutter-auto',
    codepoint: 'F1BA3',
    deprecated: 'FALSE',
  },
  {
    id: 'E71049A9-FDB3-47BD-A1CA-9CA952627DDE',
    name: 'window-shutter-cog',
    codepoint: 'F1A8A',
    deprecated: 'FALSE',
  },
  {
    id: '955A5813-3414-4712-ADF4-62878A42D3B9',
    name: 'window-shutter-open',
    codepoint: 'F111E',
    deprecated: 'FALSE',
  },
  {
    id: '1361AC2A-CE62-44C5-99BE-3DBA2B14D318',
    name: 'window-shutter-settings',
    codepoint: 'F1A8B',
    deprecated: 'FALSE',
  },
  {
    id: '0D2A52AA-2CAE-427F-887E-6198929D6855',
    name: 'windsock',
    codepoint: 'F15FA',
    deprecated: 'FALSE',
  },
  {
    id: '70D590C8-8B7E-4F4A-A9FC-31765D35F349',
    name: 'wiper',
    codepoint: 'F0AE9',
    deprecated: 'FALSE',
  },
  {
    id: 'CC8D7DD6-699C-4746-ACAB-84DCA7BBC5E9',
    name: 'wiper-wash',
    codepoint: 'F0DA6',
    deprecated: 'FALSE',
  },
  {
    id: '0BC113C0-8EC6-479E-86B6-A8F88C6B0371',
    name: 'wiper-wash-alert',
    codepoint: 'F18DF',
    deprecated: 'FALSE',
  },
  {
    id: '464D9AC5-8A79-4E35-89E3-D84DDC0056A3',
    name: 'wizard-hat',
    codepoint: 'F1477',
    deprecated: 'FALSE',
  },
  {
    id: '0059A2CA-E266-4A57-9EE8-4520B7330D5C',
    name: 'wordpress',
    codepoint: 'F05B4',
    deprecated: 'TRUE',
  },
  {
    id: '79D47101-F8CB-496B-99B0-957DADED5D44',
    name: 'wrap',
    codepoint: 'F05B6',
    deprecated: 'FALSE',
  },
  {
    id: '44C4926C-7F82-4A6A-8F55-A7B23E56A9D7',
    name: 'wrap-disabled',
    codepoint: 'F0BDF',
    deprecated: 'FALSE',
  },
  {
    id: 'CACAAE64-D38D-423E-8C84-68EFF0EA0F8A',
    name: 'wrench',
    codepoint: 'F05B7',
    deprecated: 'FALSE',
  },
  {
    id: 'ADF2E260-DDB1-4561-B453-0293C8AA773F',
    name: 'wrench-check',
    codepoint: 'F1B8F',
    deprecated: 'FALSE',
  },
  {
    id: '319DEB6A-C5F2-4787-BAE1-33BA7BE23BE3',
    name: 'wrench-check-outline',
    codepoint: 'F1B90',
    deprecated: 'FALSE',
  },
  {
    id: '922891A9-0422-4BCD-9CE7-2FA1E7134EAB',
    name: 'wrench-clock',
    codepoint: 'F19A3',
    deprecated: 'FALSE',
  },
  {
    id: '5D30C3F8-E717-4D12-AA8A-E6E9461BF849',
    name: 'wrench-clock-outline',
    codepoint: 'F1B93',
    deprecated: 'FALSE',
  },
  {
    id: '321D5871-1A24-4817-9B36-AFBA993D34A0',
    name: 'wrench-cog',
    codepoint: 'F1B91',
    deprecated: 'FALSE',
  },
  {
    id: 'D13707EF-6219-4BCC-93EF-2B11BC627EDE',
    name: 'wrench-cog-outline',
    codepoint: 'F1B92',
    deprecated: 'FALSE',
  },
  {
    id: '040BA3F6-86B3-4597-9EBE-C6D6A916091D',
    name: 'wrench-outline',
    codepoint: 'F0BE0',
    deprecated: 'FALSE',
  },
  {
    id: '958B8A8B-A4D7-45A4-8C80-39F7140443C0',
    name: 'xamarin',
    codepoint: 'F0845',
    deprecated: 'TRUE',
  },
  {
    id: '994C18D8-DAF1-4311-A30B-CD93F5ADC233',
    name: 'xml',
    codepoint: 'F05C0',
    deprecated: 'FALSE',
  },
  {
    id: 'F2F74E7E-9EC8-4748-AF3B-A14D6158770D',
    name: 'xmpp',
    codepoint: 'F07FF',
    deprecated: 'TRUE',
  },
  {
    id: '63AB3732-B3D1-4AFA-81B5-5D0F40E5D0E0',
    name: 'yahoo',
    codepoint: 'F0B4F',
    deprecated: 'TRUE',
  },
  {
    id: '17C0DA66-AB24-4935-A928-26BE13FDC3C0',
    name: 'yeast',
    codepoint: 'F05C1',
    deprecated: 'FALSE',
  },
  {
    id: '068670F6-B9DF-40C5-8C50-C9A3953F6201',
    name: 'yin-yang',
    codepoint: 'F0680',
    deprecated: 'FALSE',
  },
  {
    id: '82F65F53-8458-4F6D-8151-D20FFDDC09C2',
    name: 'yoga',
    codepoint: 'F117C',
    deprecated: 'FALSE',
  },
  {
    id: '0A078909-90AF-41F5-8E76-CAB565D49AAA',
    name: 'youtube',
    codepoint: 'F05C3',
    deprecated: 'TRUE',
  },
  {
    id: '9C80F67A-8A15-4109-BA37-7B92FE1F67DB',
    name: 'youtube-gaming',
    codepoint: 'F0848',
    deprecated: 'TRUE',
  },
  {
    id: '7150F7D3-B162-41DE-80A8-5DFD930EDF1B',
    name: 'youtube-studio',
    codepoint: 'F0847',
    deprecated: 'TRUE',
  },
  {
    id: 'E06EE7FD-6CC5-4BB4-9B00-1397D6E18DDF',
    name: 'youtube-subscription',
    codepoint: 'F0D40',
    deprecated: 'TRUE',
  },
  {
    id: '011BAC94-8FD5-4335-AFF3-120C04299E78',
    name: 'youtube-tv',
    codepoint: 'F0448',
    deprecated: 'TRUE',
  },
  {
    id: '9BCCFDB1-42DE-4807-BF9B-36AD3B40B996',
    name: 'yurt',
    codepoint: 'F1516',
    deprecated: 'FALSE',
  },
  {
    id: '6A2E5A75-34CC-4B32-A1F6-156803328935',
    name: 'z-wave',
    codepoint: 'F0AEA',
    deprecated: 'TRUE',
  },
  {
    id: '989885C5-DF4B-4B6E-B17B-7E74F2AEE1DB',
    name: 'zend',
    codepoint: 'F0AEB',
    deprecated: 'TRUE',
  },
  {
    id: 'C4D3EAAC-C54B-4406-BCB4-8C08E2B07451',
    name: 'zigbee',
    codepoint: 'F0D41',
    deprecated: 'TRUE',
  },
  {
    id: '00A83C35-A726-464B-ACEE-F0D690045C25',
    name: 'zip-box',
    codepoint: 'F05C4',
    deprecated: 'FALSE',
  },
  {
    id: '19E804D8-154F-4250-82D3-76F5401B5674',
    name: 'zip-box-outline',
    codepoint: 'F0FFA',
    deprecated: 'FALSE',
  },
  {
    id: '775EA566-CF0B-473E-B43E-59319FC83D38',
    name: 'zip-disk',
    codepoint: 'F0A23',
    deprecated: 'FALSE',
  },
  {
    id: '8E27593E-7C4E-41F7-A238-865DA00CDEE7',
    name: 'zodiac-aquarius',
    codepoint: 'F0A7D',
    deprecated: 'FALSE',
  },
  {
    id: '8DC63282-63B3-4F4A-B976-2346FC3B7CFA',
    name: 'zodiac-aries',
    codepoint: 'F0A7E',
    deprecated: 'FALSE',
  },
  {
    id: 'DCBC9ECE-641C-40E1-BD15-2F02F8412780',
    name: 'zodiac-cancer',
    codepoint: 'F0A7F',
    deprecated: 'FALSE',
  },
  {
    id: '6310BE95-A7A4-4A4C-A478-059DCA6C4E0B',
    name: 'zodiac-capricorn',
    codepoint: 'F0A80',
    deprecated: 'FALSE',
  },
  {
    id: 'ECDB16AC-832D-446E-974E-9F99657685D0',
    name: 'zodiac-gemini',
    codepoint: 'F0A81',
    deprecated: 'FALSE',
  },
  {
    id: 'D280F50D-92FC-4DA2-BBAD-CE93140E6FEA',
    name: 'zodiac-leo',
    codepoint: 'F0A82',
    deprecated: 'FALSE',
  },
  {
    id: '142861F0-C930-43ED-B186-4BD97C175394',
    name: 'zodiac-libra',
    codepoint: 'F0A83',
    deprecated: 'FALSE',
  },
  {
    id: '3410C5F7-CAEF-405C-9159-86F38ACE9619',
    name: 'zodiac-pisces',
    codepoint: 'F0A84',
    deprecated: 'FALSE',
  },
  {
    id: '1FC91C87-E02C-4830-B0A6-6EA7CDABB8D3',
    name: 'zodiac-sagittarius',
    codepoint: 'F0A85',
    deprecated: 'FALSE',
  },
  {
    id: '0AEE35B6-4A9F-422A-BCC8-395174DCBA71',
    name: 'zodiac-scorpio',
    codepoint: 'F0A86',
    deprecated: 'FALSE',
  },
  {
    id: '40205151-C976-49D8-98F1-A0E120086E07',
    name: 'zodiac-taurus',
    codepoint: 'F0A87',
    deprecated: 'FALSE',
  },
  {
    id: '41D13BD9-7CB3-4015-A845-485B9915FA65',
    name: 'zodiac-virgo',
    codepoint: 'F0A88',
    deprecated: 'FALSE',
  },
]
