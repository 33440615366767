<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="internalValue.tallySheet"
      item-key="index"
      hide-default-footer
      class="elevation-1 mb-2"
      show-select
      :footer-props="{'items-per-page-options':[-1]}"
      :options="options"
    >
      <template v-slot:no-data>
        <h3
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h3>
      </template>

      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            />

            <v-col
              cols="12"
              sm="8"
              class="pa-0 ma-0 text-center"
            >
              <h3
                class="primary--text"
              >
                Tally Sheet
              </h3>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-right"
            >
              <v-btn
                id="newTally"
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-0 pb-0"
                @click="new_onClick"
              >
                New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.data-table-select="{ isSelected, select }">
        <v-simple-checkbox
          color="primary"
          :value="isSelected"
          @input="select($event)"
        />
      </template>

      <template v-slot:item.quantity="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-text-field
            v-model="item.quantity"
            dense
            class="font-weight-medium text-body-1 numeric-input"
            :rules="[appRules.required]"
            @change="setUpdated(item)"
            @click="input_onClick"
          />
        </div>
      </template>

      <template v-slot:item.linearFeet="{ item }">
        <div
          class="font-weight-medium text-body-1"
        >
          <v-text-field
            v-model="item.linearFeet"
            dense
            class="font-weight-medium text-body-1 centered-input"
            :rules="[appRules.required]"
            @change="setUpdated(item)"
            @click="input_onClick"
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          @click="item_onDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { mapActions } from 'vuex'

  export default {
    name: 'TallySheet',

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
        options: {
          itemsPerPage: -1,
        },
        displayAsSingular: 'Tally',
        displayAsPlural: 'Tallies',
        selected: [],
        headers: [
          {
            text: 'Quantity',
            align: 'end',
            value: 'quantity',
            sortable: true,
            filterable: false,
            width: '40%',
            class: 'primary--text font-weight-bold text-no-wrap',
          },
          {
            text: 'Linear Footage',
            align: 'center',
            value: 'linearFeet',
            sortable: true,
            filterable: false,
            width: '58%',
            class: 'primary--text font-weight-bold text-no-wrap',
          },
          {
            text: 'Actions',
            align: 'end',
            value: 'actions',
            sortable: false,
            filterable: false,
            width: '2%',
            class: 'primary--text font-weight-bold',
          },
        ],
      }
    },

    computed: {
      appRules: get('app/rules'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          this.$emit('input', this.internalValue)
        },
        deep: true,
      },
      value: {
        handler (val, oldVal) {
          this.internalValue = val
        },
        deep: true,
      },
    },

    methods: {
      emitChange (dataItem) {
        this.$nextTick(() => {
          this.$emit('change', dataItem)
        })
      },

      recalculateTallySheet (dataItem) {
        this.$store.dispatch('takeoff/recalculateTallySheet', dataItem)
      },

      setFocus () {
        document.getElementById('newTally').focus()
      },

      getMaxIndex (arrayWithIndex) {
        let maxIndex = 0

        for (const thisItem of arrayWithIndex) {
          if (thisItem.index > maxIndex) {
            maxIndex = thisItem.index
          }
        }

        return maxIndex
      },

      addItem () {
        const newIndex = this.getMaxIndex(this.internalValue.tallySheet) + 1
        this.internalValue.tallySheet.push({ index: newIndex, quantity: '0', linearFeet: '0' })
      },

      setUpdated (item) {
        if (item) {
          if (this.internalValue.tallySheet.indexOf(item) === (this.internalValue.tallySheet.length - 1)) {
            if (parseInt(item.linearFeet, 0) > 0 && parseInt(item.quantity, 0) > 0) {
              this.addItem()
            }
          }
        }

        this.$store.dispatch('user/setStateAsUpdated', this.internalValue)
        this.recalculateTallySheet(this.internalValue)

        this.emitChange(this.internalValue)
      },

      new_onClick () {
        this.addItem()
        this.setUpdated()
      },

      deleteItem (dataItem) {
        this.internalValue.tallySheet.splice(this.internalValue.tallySheet.indexOf(dataItem), 1)

        if (this.internalValue.tallySheet.length <= 0) {
          this.internalValue.manual_quantity = false
        }

        this.setUpdated()
      },

      item_onDelete (dataItem) {
        if (this.selected.length > 0) {
          for (const item2Delete of this.selected) {
            this.deleteItem(item2Delete)
          }
        } else {
          this.deleteItem(dataItem)
        }
      },

      cleanup () {
        const newTally = []
        for (const dataItem of this.internalValue.tallySheet) {
          if (parseInt(dataItem.linearFeet, 0) !== 0 && parseInt(dataItem.quantity, 0) !== 0) {
            newTally.push(dataItem)
          }
        }

        this.internalValue.tallySheet = newTally
        this.recalculateTallySheet(this.internalValue)
      },
    },
  }
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center
  }
  .numeric-input >>> input {
    text-align: right
  }
</style>
