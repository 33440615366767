// Pathify
import { make } from 'vuex-pathify'
import { importChunk } from '@/util/helpers'

const _ = require('lodash')

const endpoint = 'https://6nu7ddtndg.execute-api.us-east-2.amazonaws.com'
const setIsBusy = 'setIsBusy'

const state = {
  displayAsSingular: 'Division',
  displayAsPlural: 'Divisions',
  divisions: {
    all: [],
    data: [],
    originalData: [],
    toImport: [],
    isBusy: false,
    isFirst: false,
    isLast: false,
    selected: [],
    selectedItem: { id: '', description: '' },
    setLast: false,
    empty: {
      id: null,
      phase_id: null,
      client_id: null,
      plan_id: null,
      description: null,
      note: '',
      days_from_start: 0,
      duration: 0,
      updated: false,
      update_program: null,
      update_user_id: null,
    },
    headers: {
      headerDescription: {
        text: 'Description',
        align: 'start',
        value: 'description',
        sortable: true,
        filterable: true,
        groupable: false,
        width: '1%',
        class: 'primary--text font-weight-bold',
      },
    },
    tabbedColumns: {
      id_header: 'Division ID',
      description_header: 'Division Description',
    },
  },
}

const mutations = {
  ...make.mutations(state),

  setIsBusy: (state, isBusy) => {
    state.divisions.isBusy = isBusy
  },

  setIsFirst: (state) => {
    let isFirst = false
    const selectedObject = state.divisions.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.divisions.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[0], 'id')) {
          const firstId = thisData[0].id

          if (firstId === selectedId) {
            isFirst = true
          }
        }
      } else {
        isFirst = true
      }
    } else {
      isFirst = true
    }

    state.divisions.isFirst = isFirst
  },

  setIsLast: (state) => {
    let isLast = false
    const selectedObject = state.divisions.selectedItem

    if (selectedObject && {}.hasOwnProperty.call(selectedObject, 'id')) {
      const selectedId = selectedObject.id
      const thisData = state.divisions.data

      if (thisData && thisData.length > 0) {
        if ({}.hasOwnProperty.call(thisData[thisData.length - 1], 'id')) {
          const lastId = thisData[thisData.length - 1].id

          if (lastId === selectedId) {
            isLast = true
          }
        }
      } else {
        isLast = true
      }
    } else {
      isLast = true
    }

    state.divisions.isLast = isLast
  },

  push2All: (state, dataFromDB) => {
    state.divisions.all = []

    for (const currentData of dataFromDB) {
      state.divisions.all.push({ ...currentData })
    }

    state.divisions.isBusy = false
  },

  push2Data: (state, forPlanId) => {
    state.divisions.data = []

    // Get a copy of the original data, not a pointer to it
    state.divisions.originalData = []
    for (const currentData of state.divisions.all) {
      if (currentData.plan_id === forPlanId) {
        state.divisions.data.push({ ...currentData })
        state.divisions.originalData.push({ ...currentData })
      }
    }

    state.divisions.selected = []

    state.divisions.isBusy = false
  },

  push2Import: (state, data2Import) => {
    state.divisions.toImport = []
    state.divisions.toImport = data2Import
    state.divisions.isBusy = false
  },

  importCompleted: (state) => {},
}

const actions = {
  ...make.actions(state),

  init: async () => {
    //
  },

  setEmpty: ({ commit, dispatch }) => {
    state.divisions.selected = []
    state.divisions.selectedItem = { id: '', description: '' }
    state.divisions.data = []
    dispatch('assembly/setEmpty', null, { root: true })
    commit('setIsFirst')
    commit('setIsLast')
  },

  setSelected: ({ commit, dispatch }, payload) => {
    dispatch('assembly/setEmpty', null, { root: true })

    if (payload) {
      state.divisions.selected = []
      state.divisions.selectedItem = { ...payload }
      dispatch('assembly/retrieve', payload, { root: true })
    } else {
      state.divisions.selectedItem = { id: '', description: '' }
    }
    commit('setIsFirst')
    commit('setIsLast')
  },

  previous: ({ dispatch, rootState }) => {
    let previousObject = null
    const selectedId = state.divisions.selectedItem.id

    for (const existingObject of state.divisions.data) {
      if (existingObject.id === selectedId) {
        break
      }

      previousObject = { ...existingObject }
    }

    if (previousObject) {
      rootState.assembly.assemblies.setLast = true
      dispatch('setSelected', previousObject)
    } else {
      dispatch('plan/previous', null, { root: true })
    }
  },

  next: ({ dispatch }) => {
    let nextObject = null
    const selectedId = state.divisions.selectedItem.id

    for (let thisIndex = state.divisions.data.length - 1; thisIndex >= 0; thisIndex--) {
      const existingObject = state.divisions.data[thisIndex]

      if (existingObject.id === selectedId) {
        break
      }

      nextObject = { ...existingObject }
    }

    if (nextObject) {
      dispatch('setSelected', nextObject)
    } else {
      dispatch('plan/next', null, { root: true })
    }
  },

  retrieveAll: ({ commit, dispatch, rootState }) => {
    if (rootState.customer.customers.selectedItem.client_id.length > 0) {
      const thisAction = 'retrieveAllPhases'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'get',
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            commit('push2All', jsonResponse.data)
          } else {
            // toastColor = 'danger'
            // toastMessage = 'was not updated successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
            commit(setIsBusy, false)
          }
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  retrieve: async ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'Retrieve Division'
    commit(setIsBusy, true)

    try {
      await commit('push2Data', payload.plan_id)

      // Previous/Next logic
      if (state.divisions.data.length > 0) {
        if (state.divisions.setLast) {
          state.divisions.setLast = false
          dispatch('setSelected', state.divisions.data[state.divisions.data.length - 1])
        } else {
          const selectedParent = rootState.plan.plans.selectedItem
          const selectedItem = state.divisions.selectedItem

          // If we have a selected parent object and selectedItem is empty, select the first data item
          if (selectedParent && {}.hasOwnProperty.call(selectedParent, 'id') && selectedItem && {}.hasOwnProperty.call(selectedItem, 'id')) {
            if (selectedParent.id.trim().length > 0 && selectedItem.id.trim().length <= 0) {
              dispatch('setSelected', state.divisions.data[0])
            }
          }
        }
      }
    } catch (error) {
      console.error(`${thisAction} failed`)
      commit(setIsBusy, false)
      dispatch('error/setError', { name: thisAction, details: error }, { root: true })
    }
  },

  update: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = 'updatePhases'
    commit(setIsBusy, true)

    payload.phase_id = payload.id

    const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
    const url = `${actionEndPoint}/${payload.client_id}`

    // ToDo: Temp fix for Dan's demo. Trev will need to figure out the best way to do this.
    if (payload.plan_id === null) {
      if (payload.id != null) {
        payload.plan_id = payload.id
      }
    }

    const options = {
      method: 'post',
      body: JSON.stringify(payload),
    }

    let toastColor = 'success'
    let toastMessage = 'updated successfully'

    fetch(url, options)
      .then(response => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then(jsonResponse => {
        if (_.isEmpty(jsonResponse.error)) {
          // Update selected
          if (state.divisions.selectedItem && {}.hasOwnProperty.call(state.divisions.selectedItem, 'id')) {
            if (payload.phase_id === state.divisions.selectedItem.id) {
              dispatch('setSelected', payload)
            }
          }

          dispatch('retrieveAll')
        } else {
          toastColor = 'danger'
          toastMessage = 'was not updated successfully'
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
        }
      })
      .then(() => {
        console.info(`${state.displayAsSingular} Updated - '${payload.description}' ${toastMessage}, variant: ${toastColor}`)
        /*
        dispatch('user/showToast',
          {
            title: `${state.displayAsSingular} Updated`,
            messages: [`'${payload.description}' ${toastMessage}`],
            variant: toastColor,
          },
          { root: true },
        )
         */
      })
      .catch(error => {
        console.error(`${thisAction} failed with url: ${url}`)
        commit(setIsBusy, false)
        dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  },

  delete: ({ commit, dispatch, rootState }) => {
    if (state.divisions.selected.length > 0) {
      const thisAction = 'deletePhases'
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const options = {
        method: 'post',
        body: JSON.stringify(state.divisions.selected),
      }

      let toastColor = 'success'
      let toastPrefix = ''
      let toastMessage = 'successfully removed'
      if (state.divisions.selected.length > 1) {
        toastPrefix = `${state.divisions.selected.length} ${state.displayAsPlural} were`
      } else {
        toastPrefix = `'${state.divisions.selected[0].description}'`
      }

      fetch(url, options)
        .then(response => {
          const statusMessage = `${response.status}: "${response.statusText}"`

          if (!response.ok) {
            throw Error(statusMessage)
          }

          return response.json()
        })
        .then(jsonResponse => {
          if (_.isEmpty(jsonResponse.error)) {
            // Remove deleted from selected
            if (state.divisions.selectedItem && {}.hasOwnProperty.call(state.divisions.selectedItem, 'id')) {
              for (const currentData of state.divisions.selected) {
                if (currentData.phase_id === state.divisions.selectedItem.id) {
                  dispatch('setSelected', state.divisions.data[0])
                  break
                }
              }
            }

            dispatch('retrieveAll')
          } else {
            toastColor = 'danger'
            toastMessage = 'was not deleted successfully'
            console.error(`${thisAction} failed with url: ${url}`)
            commit(setIsBusy, false)
            dispatch('error/setError', { name: thisAction, details: jsonResponse.error }, { root: true })
          }
        })
        .then(() => {
          console.info(`${state.displayAsSingular} Deleted - ${toastPrefix} ${toastMessage}, variant: ${toastColor}`)
          /*
          dispatch('user/showToast',
            {
              title: `${state.displayAsSingular} Deleted`,
              messages: [`'${payload.description}' ${toastMessage}`],
              variant: toastColor,
            },
            { root: true },
          )
          */
        })
        .catch(error => {
          console.error(`${thisAction} failed with url: ${url}`)
          commit(setIsBusy, false)
          dispatch('error/setError', { name: thisAction, details: error }, { root: true })
        })
    }
  },

  importAll: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'updatePhases'

    if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.length > 0) {
      commit(setIsBusy, true)

      const actionEndPoint = `${endpoint}/${rootState.app.build_environment}/${thisAction}`
      const url = `${actionEndPoint}/${rootState.customer.customers.selectedItem.client_id}`

      const emptyItem = { ...state.divisions.empty }
      dispatch('user/setStateAsUpdated', emptyItem, { root: true })

      // Get an even number of records in each chunk
      const numberOfChunks = Math.ceil(payload.length / 100)
      const maxSize = Math.ceil(payload.length / numberOfChunks)
      let chunkSize = 0
      const chunks2Import = []
      let totalAttempted = 0
      let items2Import = []
      let item2Import = {}

      for (const currentItem of payload) {
        // Ensure we have all required data and the proper selected client
        item2Import = { ...emptyItem }

        item2Import.id = currentItem.id
        item2Import.phase_id = currentItem.id
        item2Import.plan_id = currentItem.plan_id
        item2Import.description = currentItem.description

        items2Import.push(item2Import)
        ++chunkSize

        if (chunkSize >= maxSize) {
          chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))

          totalAttempted += chunkSize
          chunkSize = 0
          items2Import = []
        }
      }

      if (chunkSize > 0) {
        chunks2Import.push(importChunk(state.displayAsPlural, url, [...items2Import]))
        totalAttempted += chunkSize
      }

      let toastColor = 'success'
      let toastMessage = 'imported successfully'

      if (chunks2Import.length > 0) {
        // Once all promises are resolved we have a successful import, or display rejected error
        Promise.all(chunks2Import)
          .then(responses => {
            dispatch('importData/setImportCount', { sheetName: state.displayAsPlural, importCount: totalAttempted }, { root: true })
            dispatch('importData/setSelectedIsComplete', state.displayAsPlural, { root: true })
            commit('importCompleted')
            dispatch('retrieveAll')

            for (const thisResponse of responses) {
              if (_.isEmpty(thisResponse.error)) {
                console.info(`${thisResponse.details} { ${toastMessage}, variant: ${toastColor} }`)
              } else {
                toastColor = 'danger'
                toastMessage = 'were not imported successfully'
                console.error(`${thisAction} failed with url: ${url}`)
                commit(setIsBusy, false)
                dispatch('importData/setIsBusy', false, { root: true })
                dispatch('error/setError', thisResponse, { root: true })
                break
              }
            }
          })
          .catch(error => {
            console.error(`${thisAction} failed for ${state.displayAsSingular}`)
            commit(setIsBusy, false)
            dispatch('importData/setIsBusy', false, { root: true })
            dispatch('error/setError', { name: thisAction, details: error }, { root: true })
          })
      } else {
        console.error(`${thisAction} failed for ${state.displayAsSingular} due to empty chunks`)
        commit(setIsBusy, false)
        dispatch('importData/setIsBusy', false, { root: true })

        toastColor = 'warning'

        dispatch('user/showToast',
          {
            title: `Import ${state.displayAsPlural}`,
            messages: [`No ${state.displayAsPlural.toLowerCase()} were imported`],
            variant: toastColor,
          },
          { root: true },
        )
      }
    } // client_id & payload length > 0
  },

  push2Import: ({ commit, dispatch, rootState }, payload) => {
    const thisAction = `Push ${state.displayAsPlural} to Import`

    if (payload && {}.hasOwnProperty.call(payload, 'sheetData')) {
      if (rootState.customer.customers.selectedItem.client_id.length > 0 && payload.sheetData.length > 0) {
        commit(setIsBusy, true)

        const emptyItem = { id: null, phase_id: null, plan_id: null, description: null, note: '' }
        const items2Import = []
        let item2Import = {}

        for (const currentItem of payload.sheetData) {
          item2Import = { ...emptyItem }

          item2Import.id = currentItem[state.divisions.tabbedColumns.id_header]
          item2Import.phase_id = currentItem[state.divisions.tabbedColumns.id_header]
          item2Import.plan_id = currentItem[rootState.plan.plans.tabbedColumns.id_header]
          item2Import.description = currentItem[state.divisions.tabbedColumns.description_header]

          items2Import.push(item2Import)
        }

        dispatch('importData/setRows', { sheetName: state.displayAsPlural, sheetRows: items2Import.length }, { root: true })
        commit('push2Import', items2Import)
      }
    }
  },

  executeImport: ({ dispatch }) => {
    dispatch('importAll', state.divisions.toImport)
  },
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
