<template>
  <div>
    <v-data-table
      ref="tableDashboardPlans"
      v-model="plans.selected"
      :headers="headers"
      :items="planData"
      item-key="client_plan_id"
      :item-class="getRowClass"
      sort-by="client_plan_id"
      class="elevation-2"
      :show-expand="headersContains(headers,'data-table-expand')"
      :loading="getIsBusy"
      :search="search"
      :footer-props="{'items-per-page-options':[5,10,15,30,-1]}"
      :options="options"
      :expanded.sync="expanded"
      @contextmenu:row="openContextMenu"
      @dblclick:row="row_onDblClick"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No Dashboard {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching dashboard {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading Dashboard {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <!-- top & Search -->
      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="12"
              class="pa-0 ma-0 text-left"
            >
              <h2
                class="primary--text font-weight-bold pl-3 pt-2 pr-1"
              >
                {{ displayAsPlural }}
              </h2>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="planCount > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
                @click="input_onClick"
              />
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="2"
              class="pa-0 ma-0 text-right"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="secondary"
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh_onClick"
                  >
                    <v-icon class="pr-8">mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Refresh {{ displayAsPlural }}</span>
              </v-tooltip>

              <v-btn
                small
                color="primary"
                class="ml-0 mt-1 mr-1 mb-0 pt-4 pb-4"
                :disabled="disableNew"
                @click="menuNew_onClick"
              >
                Upload New {{ displayAsSingular }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <!-- Header slots -->
      <template v-slot:header.client_id="{ header }">
        {{ header.text }}
        <customer-filter
          v-model="customers.filtered_ids"
          :items="customers.data"
          :header="header"
        />
      </template>

      <template v-slot:header.status_cd="{ header }">
        {{ header.text }}
        <status-filter
          v-model="plans.filter_guest_status_codes"
          :items="plans.GuestStatusCode"
          :header="header"
        />
      </template>

      <!-- Item slots -->
      <template v-slot:item.client_id="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="font-weight-medium text-body-1"
              v-on="on"
            >
              {{ getCustomerName(item.client_id) }}
            </div>
          </template>
          <span>{{ displayAsSingularCustomer }} ID: {{ item.client_id }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.status_cd="{ item }">
        <div
          v-if="item.submitted_date || item.received_date || item.finish_date || item.invoice_date || item.paid_date"
          class="font-weight-medium text-body-1"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
                v-bind="attrs"
                v-on="on"
              >
                {{ (item.status_cd ? plans.GuestStatusCode[item.status_cd] : '') }}
              </span>
            </template>
            <div
              v-if="item.submitted_date"
            >
              Submitted: {{ displayDate(item.submitted_date) }}
            </div>
            <div
              v-if="item.received_date"
            >
              Received: {{ displayDate(item.received_date) }}
            </div>
            <div
              v-if="item.finish_date"
            >
              Completed: {{ displayDate(item.finish_date) }}
            </div>
          </v-tooltip>
        </div>
        <div
          v-else
          class="font-weight-medium text-body-1"
          :style="`${getStatusColor(item.status_cd, $vuetify.theme.dark)}`"
        >
          {{ (item.status_cd ? plans.GuestStatusCode[item.status_cd] : '') }}
        </div>
      </template>

      <template v-slot:item.documents_count="{ item }">
        <v-tooltip
          v-if="getPlanAttachmentCount(item) > 0"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="secondary"
              small
            >
              mdi-paperclip
            </v-icon>
          </template>
          <span>{{ getPlanAttachmentCount(item) }} Attachment(s)</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="primary"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length+1"
        >
          <v-container
            class="py-0"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="text-right pt-5 pb-5"
              >
                <v-data-table
                  :headers="detailHeaders"
                  :items="[item]"
                  :hide-default-footer="true"
                  class="elevation-2"
                >
                  <template v-slot:top>
                    <h2
                      class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
                    >
                      {{ item.description }} - Dashboard {{ displayAsSingular }} Details
                    </h2>
                  </template>

                  <template v-slot:item.address>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address1 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ item.address2 }}
                    </div>
                    <div
                      class="font-weight-medium text-body-1"
                    >
                      {{ (item.city ? `${item.city},` : '') }} {{ item.state }} {{ item.zip }}
                    </div>
                  </template>

                  <template v-slot:footer>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        sm="12"
                        class="ma-0 pa-0"
                      >
                        <list-documents
                          :key="item.documents_count"
                          label="Documents"
                          :value="item"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="item.url"
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        offset-sm="3"
                        sm="5"
                        class="mb-2 text-left"
                      >
                        <url-value
                          ref="displayDocumentsUrl"
                          :value="item"
                          :label="`Link to ${displayAsSingular} Documents`"
                          :editable="false"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <!-- Dialogs -->
    <v-dialog
      ref="editDialog"
      :key="renderKey"
      v-model="dialogEdit"
      :width="dialogWidth"
      scrollable
      @keydown.esc.prevent="cancelEdit"
      @keydown.enter.prevent="saveEdit"
    >
      <edit-plan
        ref="formEditPlan"
        v-model="editedItem"
        :edited-index="editedIndex"
        :show-title="true"
        :show-select-customer="true"
        :show-actions="true"
        @cancel="cancelEdit"
        @close="closeDialog"
        @save="planSaved"
      />
    </v-dialog>

    <!-- Menus -->
    <context-menu
      ref="maintenanceContextMenu"
      :maintenance-items="plans"
      :display-as-singular="displayAsSingular"
      :display-as-plural="displayAsPlural"
      :show-delete-option="false"
      @newitem="menuNew_onClick"
      @edititem="menuEdit_onClick"
    />
  </div>
</template>

<style lang="scss" scoped>
.rowClass {
  text-align: left;
}
</style>

<script>
  import { get } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  import ContextMenu from '@/components/maintenance/ContextMenu'
  import CustomerFilter from '@/components/maintenance/filters/CustomerFilter'
  import EditPlan from '@/components/maintenance/forms/EditPlan'
  import StatusFilter from '@/components/maintenance/filters/StatusFilter'
  import ListDocuments from '@/components/maintenance/controls/ListDocuments'
  import UrlValue from '@/components/maintenance/controls/UrlValue'

  export default {
    name: 'PlansDashboardTable',

    components: {
      ContextMenu,
      CustomerFilter,
      EditPlan,
      StatusFilter,
      ListDocuments,
      UrlValue,
    },

    data () {
      return {
        menuFilterStatuses: false,
        dialogEdit: false,
        editedIndex: -1,
        editedItem: {},
        disableEdit: false,
        renderKey: 0,
        search: null,
        options: {
          itemsPerPage: 10,
        },
        expanded: [],
        headers: [],
        detailHeaders: [],
      }
    },

    computed: {
      ...mapGetters('app', [
        'getValidDate',
        'formatDateForTimezone',
        'generatePlanId',
        'addDays',
      ]),
      ...mapGetters('user', [
        'hasValidTakeoffStatusCode',
      ]),
      ...mapGetters('customer', [
        'getCustomerName',
      ]),
      ...mapGetters('plan', [
        'filteredPlans',
        'getPlanAttachmentCount',
      ]),
      appHeaders: get('app/headers'),
      appRules: get('app/rules'),
      displayAsSingularCustomer: get('customer/displayAsSingular'),
      displayAsSingular: get('plan/displayAsSingular'),
      displayAsPlural: get('plan/displayAsPlural'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      planData () {
        let useData = []
        let useFilteredClients = []

        if (this.getLengthOf(this.customers.data) > 1) {
          useData = this.plans.all

          useFilteredClients = this.customers.filtered_ids
        } else {
          useData = this.plans.data

          for (const currentData of this.customers.data) {
            useFilteredClients.push(currentData.client_id)
          }
        }

        return this.filteredPlans({
          plans: useData,
          client_ids: useFilteredClients,
          status_codes: this.plans.filter_guest_status_codes,
        })
      },
      getIsBusy () {
        return this.plans.isBusy
      },
      dialogWidth () {
        let newWidth = null
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            newWidth = null
            break
          case 'sm':
            newWidth = '80%'
            break
          case 'md':
            newWidth = '65%'
            break
          case 'lg':
            newWidth = '55%'
            break
          case 'xl':
            newWidth = '50%'
            break
        }
        return newWidth
      },
      disableNew () {
        // TODO: Future use of disable new Plan may be needed
        return false
      },
      planCount () {
        let thisLength = 0

        if (this.planData) {
          thisLength = this.planData.length
        }

        return thisLength
      },
    },

    watch: {
      dialogEdit (val) {
        if (val) {
          this.forceRerender()
        }

        if (this.editedIndex < 0) {
          this.addItem()
        }
        val || this.cancelEdit()
      },
    },

    created () {
      this.initialize()

      this.unsubscribe = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'plan/successfulUpdate':
            this.refresh_onClick()
            break
          default:
            break
        }
      })
    },

    mounted () {
      this.collapseGroups()
    },

    beforeDestroy () {
      this.unsubscribe()
    },

    methods: {
      setupHeaders () {
        const headerCustomer = { ...this.plans.headers.headerCustomer }
        const headerId = { ...this.appHeaders.headerId }
        const headerDescription = { ...this.plans.headers.headerDescription }
        const headerDocCount = { ...this.plans.headers.headerDocCount }
        const headerStatus = { ...this.plans.headers.headerStatus }
        const headerSalesperson = { ...this.plans.headers.headerSalesperson }

        this.headers = []

        headerDocCount.width = '1%'
        headerStatus.width = '20%'
        headerSalesperson.width = '28%'

        if (this.getLengthOf(this.customers.data) > 1) {
          headerCustomer.width = '19%'
          headerDescription.width = '30%'

          this.headers.push(headerCustomer)
        } else {
          headerId.width = '10%'
          headerDescription.width = '39%'

          this.headers.push(headerId)
        }

        this.headers.push(headerDescription)
        this.headers.push(headerDocCount)
        this.headers.push(headerStatus)
        this.headers.push(headerSalesperson)
        this.headers.push(this.appHeaders.headerActions)
        this.headers.push(this.appHeaders.headerExpandDetail)
      },

      setupDetailHeaders () {
        this.plans.headers.headerAddress.width = '25%'
        this.plans.headers.headerNote.width = '75%'

        this.detailHeaders = []

        this.detailHeaders.push(this.plans.headers.headerAddress)
        this.detailHeaders.push(this.plans.headers.headerNote)
      },

      retrieveSalespeople () {
        this.$store.dispatch('salesperson/retrieveAll')
      },

      retrievePlans () {
        if (this.getLengthOf(this.customers.data) > 1) {
          this.$store.dispatch('plan/retrieveAll')
        } else {
          this.$store.dispatch('plan/retrieve')
        }
      },

      initialize () {
        this.retrievePlans()
        this.retrieveSalespeople()

        this.setupHeaders()

        this.setupDetailHeaders()
      },

      collapseGroups () {
        const table = this.$refs.tableDashboardPlans
        const keys = Object.keys(table.$vnode.componentInstance.openCache)
        keys.forEach(dataGroup => {
          if (dataGroup) {
            table.$vnode.componentInstance.openCache[dataGroup] = false
          }
        })
      },

      getRowClass () {
        return 'rowClass'
      },

      refresh_onClick () {
        this.retrievePlans()
        this.retrieveSalespeople()
      },

      displayDate (thisDate) {
        return this.formatDateForTimezone(thisDate)
      },

      addItem () {
        this.disableEdit = true
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.plans.empty)
        this.editedItem.id = this.generatePlanId(this.plans.originalData)
        this.editedItem.plan_id = this.editedItem.id
        this.editedItem.status_cd = 'submitted'
        this.editedItem.submitted_date = this.getValidDate(null)
        this.editedItem.due_date = this.addDays(this.editedItem.submitted_date, this.editedItem.return_cd).toString()

        this.$store.dispatch('user/setStateAsUpdated', this.editedItem)
      },

      forceRerender () {
        this.renderKey += 1
      },

      openEditDialog (dataItem) {
        this.editedIndex = this.planData.indexOf(dataItem)
        this.editedItem = Object.assign({}, dataItem)
        this.disableEdit = true
        this.dialogEdit = true
      },

      openContextMenu (evt, selectedRow) {
        this.$refs.maintenanceContextMenu.openContextMenu(evt, selectedRow.item)
      },

      menuNew_onClick () {
        this.addItem()
        this.dialogEdit = true
      },

      menuEdit_onClick (dataItem) {
        this.openEditDialog(dataItem)
      },

      cancelEdit () {
        this.dialogEdit = false
        this.$refs.maintenanceContextMenu.setSelectedMenu(null)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.plans.empty)
          this.editedIndex = -1
        })
      },

      saveEdit () {
        this.$refs.formEditPlan.saveEdit()
      },

      closeDialog () {
        this.dialogEdit = false
      },

      planSaved () {
        this.dialogEdit = false

        this.$nextTick(() => {
          this.refresh_onClick()
        })
      },

      row_onDblClick (event, { item }) {
        if (this.hasValidTakeoffStatusCode(item)) {
          this.$store.dispatch('plan/setSelectedCustomerPlan', item)

          this.$nextTick(() => {
            this.$router.push('/components/reports/').catch(() => {})
          })
        }
      },
    },
  }
</script>
