<template>
  <div>
    <navigation-label
      :label="($attrs.label ? $attrs.label : displayAsSingular)"
      :displayAsSingular="displayAsSingular"
      :displayAsPlural="displayAsPlural"
      :tooltip="`${displayAsSingular} Maintenance`"
      :routeTo="{ name: 'Estimators' }"
    />

    <v-container
      class="pa-0 ma-0"
    >
      <v-row
        no-gutters
      >
        <v-col
          cols="12"
          sm="12"
          md="11"
        >
          <v-combobox
            :id="`key-estimator-${$attrs.id}`"
            v-model="internalValue"
            :items="estimators.distinct"
            item-text="name"
            item-value="key_id"
            autofocus
            outlined
            dense
            clearable
            class="ml-1 mt-0 mb-0"
            :hint="`Select an ${displayAsSingular} ${$attrs.appendhint}`"
            :loading="estimators.isBusy"
            :rules="[appRules.validOption(internalValue, estimators.distinct, `${displayAsSingular} does not exist in the list.`)]"
            @change="onChange"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import NavigationLabel from '@/components/maintenance/controls/NavigationLabel'

  export default {
    name: 'SelectEstimator',

    components: {
      NavigationLabel,
    },

    props: {
      value: {
        type: Object,
        default: null,
      },
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    computed: {
      estimators: get('estimator/estimators'),
      appRules: get('app/rules'),
      displayAsSingular: get('estimator/displayAsSingular'),
      displayAsPlural: get('estimator/displayAsPlural'),
    },

    watch: {
      internalValue: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.name === oldVal.name) return

            this.$emit('input', this.internalValue)
          }
        },
      },
      value: {
        handler (val, oldVal) {
          if (val && {}.hasOwnProperty.call(val, 'id')) {
            if (oldVal &&
              val.id === oldVal.id &&
              val.name === oldVal.name) return

            this.internalValue = val
          }
        },
      },
    },

    created () {
      this.$store.dispatch('estimator/retrieveAllClientEstimators')
    },

    mounted () {
      this.initialize()
    },

    methods: {
      setSelected (selectedItem) {
        this.$store.dispatch('estimator/setSelected', selectedItem)
      },

      initialize () {
        let setDefault = true

        if (this.estimators.selectedItem && {}.hasOwnProperty.call(this.estimators.selectedItem, 'id')) {
          if (this.estimators.selectedItem.id.length > 0) {
            setDefault = false
          }
        }

        if (setDefault && this.getLengthOf(this.estimators.distinct) > 0) {
          this.setSelected(this.estimators.distinct[0])
        }
      },

      onChange (selectedItem) {
        this.setSelected(selectedItem)

        this.$emit('change', selectedItem)
      },
    },
  }
</script>

<style scoped>

</style>
